import { AuthorizationService, Course, CourseFinder, CourseId, GroupId } from '@/base/domains';
import { AbstractUseCase, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

export interface GetCourseByTrainerRequest {
  id: CourseId;
  groupId: GroupId;
}

export type GetCourseByTrainerResponse = {
  course?: Course;
};

/**
 * トレーナーがコースを取得する
 */
export interface GetCourseByTrainer
  extends UseCase<GetCourseByTrainerRequest, GetCourseByTrainerResponse> {
  execute(request: GetCourseByTrainerRequest): Promise<UseCaseResponse<GetCourseByTrainerResponse>>;
}

export class GetCourseByTrainerImpl
  extends AbstractUseCase<GetCourseByTrainerRequest, GetCourseByTrainerResponse>
  implements GetCourseByTrainer
{
  private authorizationService: AuthorizationService;

  private courseFinder: CourseFinder;

  constructor(authorizationService: AuthorizationService, courseFinder: CourseFinder) {
    super('training.GetCourseByTrainer');
    this.authorizationService = authorizationService;
    this.courseFinder = courseFinder;
  }

  async internalExecute(request: GetCourseByTrainerRequest): Promise<GetCourseByTrainerResponse> {
    const { id, groupId } = request;
    this.authorizationService.assertTrainerAccessible(groupId);
    const course = await this.courseFinder.findActiveCourseById(id);
    return {
      course,
    };
  }
}

export const GetCourseByTrainerKey = injectionKeyOf<GetCourseByTrainer>({
  boundedContext: 'training',
  type: 'usecase',
  name: 'GetCourseByTrainer',
});

export function useGetCourseByTrainer(): GetCourseByTrainer {
  return requiredInject(GetCourseByTrainerKey);
}
