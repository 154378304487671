import { UserId } from '@/base/domains';
import { LocalDateTime, Optional } from '@/base/types';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { EditingCourseId } from '..';
import { EditingCourseAttributes } from '../EditingCourse';

export type EditingCourseWithLastContentUpdated = EditingCourseAttributes & {
  id: EditingCourseId;
  contentLastUpdatedBy?: UserId;
  contentLastUpdatedAt?: LocalDateTime;
};

export interface EditingCourseQueries {
  findEditingCourseWithLastContentUpdatedById(
    id: EditingCourseId
  ): Promise<Optional<EditingCourseWithLastContentUpdated>>;
}

export const EditingCourseQueriesKey = injectionKeyOf<EditingCourseQueries>({
  boundedContext: 'contents',
  type: 'adapter',
  name: 'EditingCourseQueries',
});

export function useEditingCourseQueries(): EditingCourseQueries {
  return requiredInject(EditingCourseQueriesKey);
}
