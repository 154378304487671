import { Problem, ProblemHeader, ProblemHeaderId, ProblemIndex, Workbook } from '@/base/domains';
import { Optional, PlainData } from '@/base/types';

/**
 * 練習データ
 */
export type WorkbookData = PlainData<Workbook>;

/**
 * 練習問題実装
 */
export class WorkbookImpl implements Workbook {
  problems: Array<Problem>;

  problemHeaders: Array<ProblemHeader>;

  constructor(data: WorkbookData) {
    this.problems = data.problems;
    this.problemHeaders = data.problemHeaders;
  }

  findProblemByIndex(index: ProblemIndex): Optional<Problem> {
    return this.problems.find((p) => p.index === index);
  }

  findProblemHeaderById(id: ProblemHeaderId): Optional<ProblemHeader> {
    return this.problemHeaders.find((h) => h.id === id);
  }
}
