/* eslint-disable @typescript-eslint/no-unused-vars */

import { TenantOwnTermsOfService } from '@/base/domains';
import { TenantOwnTermsOfServiceQueries } from '@/base/domains/queries';
import { Optional } from '@/base/types';

export class InMemoryTenantOwnTermsOfServiceQueries implements TenantOwnTermsOfServiceQueries {
  constructor(private tenantOwnTermsOfService: TenantOwnTermsOfService) {}

  findTenantOwnTermsOfService(): Promise<Optional<TenantOwnTermsOfService>> {
    return Promise.resolve(this.tenantOwnTermsOfService);
  }
}
