import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { Tenant, TenantDataAdapter } from '../domains';
import { AbstractUseCase, UseCase, UseCaseResponse } from './UseCase';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface GetTenantRequest {}

export type GetTenantResponse = {
  tenant: Tenant;
};

export interface GetTenant extends UseCase<GetTenantRequest, GetTenantResponse> {
  execute(request: GetTenantRequest): Promise<UseCaseResponse<GetTenantResponse>>;
}

export class GetTenantImpl
  extends AbstractUseCase<GetTenantRequest, GetTenantResponse>
  implements GetTenant
{
  private tenantDataAdapter: TenantDataAdapter;

  constructor(tenantDataAdapter: TenantDataAdapter) {
    super('base.GetTenant');
    this.tenantDataAdapter = tenantDataAdapter;
  }

  async internalExecute(_: GetTenantRequest): Promise<GetTenantResponse> {
    const tenant = await this.tenantDataAdapter.get();
    return {
      tenant,
    };
  }
}

export const GetTenantKey = injectionKeyOf<GetTenant>({
  boundedContext: 'base',
  type: 'usecase',
  name: 'GetTenant',
});

export function useGetTenant(): GetTenant {
  return requiredInject(GetTenantKey);
}
