/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const inviteUser = /* GraphQL */ `
  mutation InviteUser($input: InviteUserInput!) {
    inviteUser(input: $input) {
      signUpReservationId
    }
  }
`;
export const upload = /* GraphQL */ `
  mutation Upload($filename: String!, $body: String!) {
    upload(filename: $filename, body: $body) {
      url
      key
    }
  }
`;
export const changePasswordByAdmin = /* GraphQL */ `
  mutation ChangePasswordByAdmin(
    $id: String!
    $password: String!
    $forcePasswordChange: Boolean!
  ) {
    changePasswordByAdmin(
      id: $id
      password: $password
      forcePasswordChange: $forcePasswordChange
    )
  }
`;
export const createGroup = /* GraphQL */ `
  mutation CreateGroup($input: CreateGroupInput!) {
    createGroup(input: $input) {
      id
      name
      users {
        id
        role
        removed
      }
      extensionConfigJsonList
      tenantCode
      limitations
      enabled
      description
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
`;
export const updateGroup = /* GraphQL */ `
  mutation UpdateGroup($input: UpdateGroupInput!) {
    updateGroup(input: $input) {
      id
      name
      users {
        id
        role
        removed
      }
      extensionConfigJsonList
      tenantCode
      limitations
      enabled
      description
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
`;
export const updateEditingCourse = /* GraphQL */ `
  mutation UpdateEditingCourse($input: UpdateEditingCourseInput!) {
    updateEditingCourse(input: $input) {
      id
      tenantCode
      name
      version
      status
      description
      contents {
        id
        name
        requiredTime
        type
      }
      color
      image
      fontColorOnImage
      createdBy
      createdAt
      updatedBy
      updatedAt
    }
  }
`;
export const createCourseHeader = /* GraphQL */ `
  mutation CreateCourseHeader($input: CreateCourseHeaderInput!) {
    createCourseHeader(input: $input) {
      id
      name
      activeVersion
      status
      originalCourseId
      originalCourseVersion
      tenantCode
      createdBy
      createdAt
      updatedAt
    }
  }
`;
export const createUserByAdmin = /* GraphQL */ `
  mutation CreateUserByAdmin($input: CreateUserByAdminInput!) {
    createUserByAdmin(input: $input) {
      userId
    }
  }
`;
export const changeConfirmedCourseName = /* GraphQL */ `
  mutation ChangeConfirmedCourseName($input: ChangeConfirmedCourseNameInput!) {
    changeConfirmedCourseName(input: $input)
  }
`;
export const addUserDevice = /* GraphQL */ `
  mutation AddUserDevice($input: AddUserDeviceInput!) {
    addUserDevice(input: $input)
  }
`;
export const deleteGroup = /* GraphQL */ `
  mutation DeleteGroup(
    $input: DeleteGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    deleteGroup(input: $input, condition: $condition) {
      id
      name
      users {
        id
        role
        removed
      }
      extensionConfigJsonList
      tenantCode
      limitations
      enabled
      description
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
`;
export const updateNotification = /* GraphQL */ `
  mutation UpdateNotification(
    $input: UpdateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    updateNotification(input: $input, condition: $condition) {
      id
      type
      payloadJson
      userId
      read
      createdAt
      tenantCode
      updatedAt
    }
  }
`;
export const deleteNotification = /* GraphQL */ `
  mutation DeleteNotification(
    $input: DeleteNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    deleteNotification(input: $input, condition: $condition) {
      id
      type
      payloadJson
      userId
      read
      createdAt
      tenantCode
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      name
      role
      extensionConfigJsonList
      email
      code
      tenantCode
      displaySettings {
        theme
        color
      }
      locale
      avatar
      enabled
      signedInAtLeastOnce
      emailConfirmed
      confirmedTermsOfServiceVersions {
        termsOfServiceVersion
        privacyPolicyVersion
        tenantOwnTermsOfServiceVersion
      }
      createdAt
      updatedAt
      tags {
        items {
          id
          userId
          userTagId
          tenantCode
          createdAt
          updatedAt
          user {
            id
            name
            role
            extensionConfigJsonList
            email
            code
            tenantCode
            displaySettings {
              theme
              color
            }
            locale
            avatar
            enabled
            signedInAtLeastOnce
            emailConfirmed
            confirmedTermsOfServiceVersions {
              termsOfServiceVersion
              privacyPolicyVersion
              tenantOwnTermsOfServiceVersion
            }
            createdAt
            updatedAt
            tags {
              items {
                id
                userId
                userTagId
                tenantCode
                createdAt
                updatedAt
                user {
                  id
                  name
                  role
                  extensionConfigJsonList
                  email
                  code
                  tenantCode
                  displaySettings {
                    theme
                    color
                  }
                  locale
                  avatar
                  enabled
                  signedInAtLeastOnce
                  emailConfirmed
                  confirmedTermsOfServiceVersions {
                    termsOfServiceVersion
                    privacyPolicyVersion
                    tenantOwnTermsOfServiceVersion
                  }
                  createdAt
                  updatedAt
                  tags {
                    nextToken
                  }
                  statuses {
                    nextToken
                  }
                }
                userTag {
                  id
                  color
                  text
                  tenantCode
                  createdAt
                  updatedAt
                  users {
                    nextToken
                  }
                }
              }
              nextToken
            }
            statuses {
              items {
                id
                tenantCode
                status
                userId
                createdAt
                updatedAt
                user {
                  id
                  name
                  role
                  extensionConfigJsonList
                  email
                  code
                  tenantCode
                  displaySettings {
                    theme
                    color
                  }
                  locale
                  avatar
                  enabled
                  signedInAtLeastOnce
                  emailConfirmed
                  confirmedTermsOfServiceVersions {
                    termsOfServiceVersion
                    privacyPolicyVersion
                    tenantOwnTermsOfServiceVersion
                  }
                  createdAt
                  updatedAt
                  tags {
                    nextToken
                  }
                  statuses {
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
          userTag {
            id
            color
            text
            tenantCode
            createdAt
            updatedAt
            users {
              items {
                id
                userId
                userTagId
                tenantCode
                createdAt
                updatedAt
                user {
                  id
                  name
                  role
                  extensionConfigJsonList
                  email
                  code
                  tenantCode
                  displaySettings {
                    theme
                    color
                  }
                  locale
                  avatar
                  enabled
                  signedInAtLeastOnce
                  emailConfirmed
                  confirmedTermsOfServiceVersions {
                    termsOfServiceVersion
                    privacyPolicyVersion
                    tenantOwnTermsOfServiceVersion
                  }
                  createdAt
                  updatedAt
                  tags {
                    nextToken
                  }
                  statuses {
                    nextToken
                  }
                }
                userTag {
                  id
                  color
                  text
                  tenantCode
                  createdAt
                  updatedAt
                  users {
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
        }
        nextToken
      }
      statuses {
        items {
          id
          tenantCode
          status
          userId
          createdAt
          updatedAt
          user {
            id
            name
            role
            extensionConfigJsonList
            email
            code
            tenantCode
            displaySettings {
              theme
              color
            }
            locale
            avatar
            enabled
            signedInAtLeastOnce
            emailConfirmed
            confirmedTermsOfServiceVersions {
              termsOfServiceVersion
              privacyPolicyVersion
              tenantOwnTermsOfServiceVersion
            }
            createdAt
            updatedAt
            tags {
              items {
                id
                userId
                userTagId
                tenantCode
                createdAt
                updatedAt
                user {
                  id
                  name
                  role
                  extensionConfigJsonList
                  email
                  code
                  tenantCode
                  displaySettings {
                    theme
                    color
                  }
                  locale
                  avatar
                  enabled
                  signedInAtLeastOnce
                  emailConfirmed
                  confirmedTermsOfServiceVersions {
                    termsOfServiceVersion
                    privacyPolicyVersion
                    tenantOwnTermsOfServiceVersion
                  }
                  createdAt
                  updatedAt
                  tags {
                    nextToken
                  }
                  statuses {
                    nextToken
                  }
                }
                userTag {
                  id
                  color
                  text
                  tenantCode
                  createdAt
                  updatedAt
                  users {
                    nextToken
                  }
                }
              }
              nextToken
            }
            statuses {
              items {
                id
                tenantCode
                status
                userId
                createdAt
                updatedAt
                user {
                  id
                  name
                  role
                  extensionConfigJsonList
                  email
                  code
                  tenantCode
                  displaySettings {
                    theme
                    color
                  }
                  locale
                  avatar
                  enabled
                  signedInAtLeastOnce
                  emailConfirmed
                  confirmedTermsOfServiceVersions {
                    termsOfServiceVersion
                    privacyPolicyVersion
                    tenantOwnTermsOfServiceVersion
                  }
                  createdAt
                  updatedAt
                  tags {
                    nextToken
                  }
                  statuses {
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      name
      role
      extensionConfigJsonList
      email
      code
      tenantCode
      displaySettings {
        theme
        color
      }
      locale
      avatar
      enabled
      signedInAtLeastOnce
      emailConfirmed
      confirmedTermsOfServiceVersions {
        termsOfServiceVersion
        privacyPolicyVersion
        tenantOwnTermsOfServiceVersion
      }
      createdAt
      updatedAt
      tags {
        items {
          id
          userId
          userTagId
          tenantCode
          createdAt
          updatedAt
          user {
            id
            name
            role
            extensionConfigJsonList
            email
            code
            tenantCode
            displaySettings {
              theme
              color
            }
            locale
            avatar
            enabled
            signedInAtLeastOnce
            emailConfirmed
            confirmedTermsOfServiceVersions {
              termsOfServiceVersion
              privacyPolicyVersion
              tenantOwnTermsOfServiceVersion
            }
            createdAt
            updatedAt
            tags {
              items {
                id
                userId
                userTagId
                tenantCode
                createdAt
                updatedAt
                user {
                  id
                  name
                  role
                  extensionConfigJsonList
                  email
                  code
                  tenantCode
                  displaySettings {
                    theme
                    color
                  }
                  locale
                  avatar
                  enabled
                  signedInAtLeastOnce
                  emailConfirmed
                  confirmedTermsOfServiceVersions {
                    termsOfServiceVersion
                    privacyPolicyVersion
                    tenantOwnTermsOfServiceVersion
                  }
                  createdAt
                  updatedAt
                  tags {
                    nextToken
                  }
                  statuses {
                    nextToken
                  }
                }
                userTag {
                  id
                  color
                  text
                  tenantCode
                  createdAt
                  updatedAt
                  users {
                    nextToken
                  }
                }
              }
              nextToken
            }
            statuses {
              items {
                id
                tenantCode
                status
                userId
                createdAt
                updatedAt
                user {
                  id
                  name
                  role
                  extensionConfigJsonList
                  email
                  code
                  tenantCode
                  displaySettings {
                    theme
                    color
                  }
                  locale
                  avatar
                  enabled
                  signedInAtLeastOnce
                  emailConfirmed
                  confirmedTermsOfServiceVersions {
                    termsOfServiceVersion
                    privacyPolicyVersion
                    tenantOwnTermsOfServiceVersion
                  }
                  createdAt
                  updatedAt
                  tags {
                    nextToken
                  }
                  statuses {
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
          userTag {
            id
            color
            text
            tenantCode
            createdAt
            updatedAt
            users {
              items {
                id
                userId
                userTagId
                tenantCode
                createdAt
                updatedAt
                user {
                  id
                  name
                  role
                  extensionConfigJsonList
                  email
                  code
                  tenantCode
                  displaySettings {
                    theme
                    color
                  }
                  locale
                  avatar
                  enabled
                  signedInAtLeastOnce
                  emailConfirmed
                  confirmedTermsOfServiceVersions {
                    termsOfServiceVersion
                    privacyPolicyVersion
                    tenantOwnTermsOfServiceVersion
                  }
                  createdAt
                  updatedAt
                  tags {
                    nextToken
                  }
                  statuses {
                    nextToken
                  }
                }
                userTag {
                  id
                  color
                  text
                  tenantCode
                  createdAt
                  updatedAt
                  users {
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
        }
        nextToken
      }
      statuses {
        items {
          id
          tenantCode
          status
          userId
          createdAt
          updatedAt
          user {
            id
            name
            role
            extensionConfigJsonList
            email
            code
            tenantCode
            displaySettings {
              theme
              color
            }
            locale
            avatar
            enabled
            signedInAtLeastOnce
            emailConfirmed
            confirmedTermsOfServiceVersions {
              termsOfServiceVersion
              privacyPolicyVersion
              tenantOwnTermsOfServiceVersion
            }
            createdAt
            updatedAt
            tags {
              items {
                id
                userId
                userTagId
                tenantCode
                createdAt
                updatedAt
                user {
                  id
                  name
                  role
                  extensionConfigJsonList
                  email
                  code
                  tenantCode
                  displaySettings {
                    theme
                    color
                  }
                  locale
                  avatar
                  enabled
                  signedInAtLeastOnce
                  emailConfirmed
                  confirmedTermsOfServiceVersions {
                    termsOfServiceVersion
                    privacyPolicyVersion
                    tenantOwnTermsOfServiceVersion
                  }
                  createdAt
                  updatedAt
                  tags {
                    nextToken
                  }
                  statuses {
                    nextToken
                  }
                }
                userTag {
                  id
                  color
                  text
                  tenantCode
                  createdAt
                  updatedAt
                  users {
                    nextToken
                  }
                }
              }
              nextToken
            }
            statuses {
              items {
                id
                tenantCode
                status
                userId
                createdAt
                updatedAt
                user {
                  id
                  name
                  role
                  extensionConfigJsonList
                  email
                  code
                  tenantCode
                  displaySettings {
                    theme
                    color
                  }
                  locale
                  avatar
                  enabled
                  signedInAtLeastOnce
                  emailConfirmed
                  confirmedTermsOfServiceVersions {
                    termsOfServiceVersion
                    privacyPolicyVersion
                    tenantOwnTermsOfServiceVersion
                  }
                  createdAt
                  updatedAt
                  tags {
                    nextToken
                  }
                  statuses {
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const createUserDevice = /* GraphQL */ `
  mutation CreateUserDevice(
    $input: CreateUserDeviceInput!
    $condition: ModelUserDeviceConditionInput
  ) {
    createUserDevice(input: $input, condition: $condition) {
      userId
      devices
      dataVersion
      tenantCode
      createdAt
      updatedAt
    }
  }
`;
export const updateUserDevice = /* GraphQL */ `
  mutation UpdateUserDevice(
    $input: UpdateUserDeviceInput!
    $condition: ModelUserDeviceConditionInput
  ) {
    updateUserDevice(input: $input, condition: $condition) {
      userId
      devices
      dataVersion
      tenantCode
      createdAt
      updatedAt
    }
  }
`;
export const deleteUserDevice = /* GraphQL */ `
  mutation DeleteUserDevice(
    $input: DeleteUserDeviceInput!
    $condition: ModelUserDeviceConditionInput
  ) {
    deleteUserDevice(input: $input, condition: $condition) {
      userId
      devices
      dataVersion
      tenantCode
      createdAt
      updatedAt
    }
  }
`;
export const createSignUpReservation = /* GraphQL */ `
  mutation CreateSignUpReservation(
    $input: CreateSignUpReservationInput!
    $condition: ModelSignUpReservationConditionInput
  ) {
    createSignUpReservation(input: $input, condition: $condition) {
      id
      name
      key
      userCode
      email
      tenantCode
      type
      role
      userId
      signUpInput {
        name
        tenantName
      }
      status
      groups {
        id
        role
        removed
      }
      createdBy
      createdAt
      updatedAt
    }
  }
`;
export const updateSignUpReservation = /* GraphQL */ `
  mutation UpdateSignUpReservation(
    $input: UpdateSignUpReservationInput!
    $condition: ModelSignUpReservationConditionInput
  ) {
    updateSignUpReservation(input: $input, condition: $condition) {
      id
      name
      key
      userCode
      email
      tenantCode
      type
      role
      userId
      signUpInput {
        name
        tenantName
      }
      status
      groups {
        id
        role
        removed
      }
      createdBy
      createdAt
      updatedAt
    }
  }
`;
export const deleteSignUpReservation = /* GraphQL */ `
  mutation DeleteSignUpReservation(
    $input: DeleteSignUpReservationInput!
    $condition: ModelSignUpReservationConditionInput
  ) {
    deleteSignUpReservation(input: $input, condition: $condition) {
      id
      name
      key
      userCode
      email
      tenantCode
      type
      role
      userId
      signUpInput {
        name
        tenantName
      }
      status
      groups {
        id
        role
        removed
      }
      createdBy
      createdAt
      updatedAt
    }
  }
`;
export const updateTenant = /* GraphQL */ `
  mutation UpdateTenant(
    $input: UpdateTenantInput!
    $condition: ModelTenantConditionInput
  ) {
    updateTenant(input: $input, condition: $condition) {
      id
      code
      name
      signUpType
      playground
      userLimit
      limitations
      createdAt
      updatedAt
    }
  }
`;
export const deleteTenant = /* GraphQL */ `
  mutation DeleteTenant(
    $input: DeleteTenantInput!
    $condition: ModelTenantConditionInput
  ) {
    deleteTenant(input: $input, condition: $condition) {
      id
      code
      name
      signUpType
      playground
      userLimit
      limitations
      createdAt
      updatedAt
    }
  }
`;
export const updateTenantOwnTermsOfService = /* GraphQL */ `
  mutation UpdateTenantOwnTermsOfService(
    $input: UpdateTenantOwnTermsOfServiceInput!
    $condition: ModelTenantOwnTermsOfServiceConditionInput
  ) {
    updateTenantOwnTermsOfService(input: $input, condition: $condition) {
      id
      tenantCode
      type
      body
      createdBy
      createdAt
      updatedBy
      updatedAt
      version
    }
  }
`;
export const deleteTenantOwnTermsOfService = /* GraphQL */ `
  mutation DeleteTenantOwnTermsOfService(
    $input: DeleteTenantOwnTermsOfServiceInput!
    $condition: ModelTenantOwnTermsOfServiceConditionInput
  ) {
    deleteTenantOwnTermsOfService(input: $input, condition: $condition) {
      id
      tenantCode
      type
      body
      createdBy
      createdAt
      updatedBy
      updatedAt
      version
    }
  }
`;
export const createEvent = /* GraphQL */ `
  mutation CreateEvent(
    $input: CreateEventInput!
    $condition: ModelEventConditionInput
  ) {
    createEvent(input: $input, condition: $condition) {
      id
      type
      event
      version
      tenantCode
      occurredBy
      createdAt
      updatedAt
    }
  }
`;
export const updateEvent = /* GraphQL */ `
  mutation UpdateEvent(
    $input: UpdateEventInput!
    $condition: ModelEventConditionInput
  ) {
    updateEvent(input: $input, condition: $condition) {
      id
      type
      event
      version
      tenantCode
      occurredBy
      createdAt
      updatedAt
    }
  }
`;
export const deleteEvent = /* GraphQL */ `
  mutation DeleteEvent(
    $input: DeleteEventInput!
    $condition: ModelEventConditionInput
  ) {
    deleteEvent(input: $input, condition: $condition) {
      id
      type
      event
      version
      tenantCode
      occurredBy
      createdAt
      updatedAt
    }
  }
`;
export const createTermsOfService = /* GraphQL */ `
  mutation CreateTermsOfService(
    $input: CreateTermsOfServiceInput!
    $condition: ModelTermsOfServiceConditionInput
  ) {
    createTermsOfService(input: $input, condition: $condition) {
      id
      body
      createdAt
      updatedAt
    }
  }
`;
export const updateTermsOfService = /* GraphQL */ `
  mutation UpdateTermsOfService(
    $input: UpdateTermsOfServiceInput!
    $condition: ModelTermsOfServiceConditionInput
  ) {
    updateTermsOfService(input: $input, condition: $condition) {
      id
      body
      createdAt
      updatedAt
    }
  }
`;
export const deleteTermsOfService = /* GraphQL */ `
  mutation DeleteTermsOfService(
    $input: DeleteTermsOfServiceInput!
    $condition: ModelTermsOfServiceConditionInput
  ) {
    deleteTermsOfService(input: $input, condition: $condition) {
      id
      body
      createdAt
      updatedAt
    }
  }
`;
export const createLive = /* GraphQL */ `
  mutation CreateLive(
    $input: CreateLiveInput!
    $condition: ModelLiveConditionInput
  ) {
    createLive(input: $input, condition: $condition) {
      id
      userId
      url
      applicationSessionId
      tenantCode
      createdAt
      updatedAt
    }
  }
`;
export const updateLive = /* GraphQL */ `
  mutation UpdateLive(
    $input: UpdateLiveInput!
    $condition: ModelLiveConditionInput
  ) {
    updateLive(input: $input, condition: $condition) {
      id
      userId
      url
      applicationSessionId
      tenantCode
      createdAt
      updatedAt
    }
  }
`;
export const deleteLive = /* GraphQL */ `
  mutation DeleteLive(
    $input: DeleteLiveInput!
    $condition: ModelLiveConditionInput
  ) {
    deleteLive(input: $input, condition: $condition) {
      id
      userId
      url
      applicationSessionId
      tenantCode
      createdAt
      updatedAt
    }
  }
`;
export const createGroupStatusUser = /* GraphQL */ `
  mutation CreateGroupStatusUser(
    $input: CreateGroupStatusUserInput!
    $condition: ModelGroupStatusUserConditionInput
  ) {
    createGroupStatusUser(input: $input, condition: $condition) {
      id
      userId
      groupId
      handStatus
      tenantCode
      createdAt
      updatedAt
    }
  }
`;
export const createGroupStatus = /* GraphQL */ `
  mutation CreateGroupStatus(
    $input: CreateGroupStatusInput!
    $condition: ModelGroupStatusConditionInput
  ) {
    createGroupStatus(input: $input, condition: $condition) {
      groupId
      tenantCode
      createdAt
      updatedAt
      users {
        items {
          id
          userId
          groupId
          handStatus
          tenantCode
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateGroupStatus = /* GraphQL */ `
  mutation UpdateGroupStatus(
    $input: UpdateGroupStatusInput!
    $condition: ModelGroupStatusConditionInput
  ) {
    updateGroupStatus(input: $input, condition: $condition) {
      groupId
      tenantCode
      createdAt
      updatedAt
      users {
        items {
          id
          userId
          groupId
          handStatus
          tenantCode
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteGroupStatus = /* GraphQL */ `
  mutation DeleteGroupStatus(
    $input: DeleteGroupStatusInput!
    $condition: ModelGroupStatusConditionInput
  ) {
    deleteGroupStatus(input: $input, condition: $condition) {
      groupId
      tenantCode
      createdAt
      updatedAt
      users {
        items {
          id
          userId
          groupId
          handStatus
          tenantCode
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createQuestionnaire = /* GraphQL */ `
  mutation CreateQuestionnaire(
    $input: CreateQuestionnaireInput!
    $condition: ModelQuestionnaireConditionInput
  ) {
    createQuestionnaire(input: $input, condition: $condition) {
      id
      groupId
      tenantCode
      title
      userIds
      text
      createdBy
      createdAt
      options
      respondent {
        items {
          id
          questionnaireId
          userId
          selectedIndex
          tenantCode
          createdAt
          updatedAt
        }
        nextToken
      }
      status
      finishedAt
      updatedAt
    }
  }
`;
export const updateQuestionnaire = /* GraphQL */ `
  mutation UpdateQuestionnaire(
    $input: UpdateQuestionnaireInput!
    $condition: ModelQuestionnaireConditionInput
  ) {
    updateQuestionnaire(input: $input, condition: $condition) {
      id
      groupId
      tenantCode
      title
      userIds
      text
      createdBy
      createdAt
      options
      respondent {
        items {
          id
          questionnaireId
          userId
          selectedIndex
          tenantCode
          createdAt
          updatedAt
        }
        nextToken
      }
      status
      finishedAt
      updatedAt
    }
  }
`;
export const deleteQuestionnaire = /* GraphQL */ `
  mutation DeleteQuestionnaire(
    $input: DeleteQuestionnaireInput!
    $condition: ModelQuestionnaireConditionInput
  ) {
    deleteQuestionnaire(input: $input, condition: $condition) {
      id
      groupId
      tenantCode
      title
      userIds
      text
      createdBy
      createdAt
      options
      respondent {
        items {
          id
          questionnaireId
          userId
          selectedIndex
          tenantCode
          createdAt
          updatedAt
        }
        nextToken
      }
      status
      finishedAt
      updatedAt
    }
  }
`;
export const createQuestionnaireRespondent = /* GraphQL */ `
  mutation CreateQuestionnaireRespondent(
    $input: CreateQuestionnaireRespondentInput!
    $condition: ModelQuestionnaireRespondentConditionInput
  ) {
    createQuestionnaireRespondent(input: $input, condition: $condition) {
      id
      questionnaireId
      userId
      selectedIndex
      tenantCode
      createdAt
      updatedAt
    }
  }
`;
export const updateQuestionnaireRespondent = /* GraphQL */ `
  mutation UpdateQuestionnaireRespondent(
    $input: UpdateQuestionnaireRespondentInput!
    $condition: ModelQuestionnaireRespondentConditionInput
  ) {
    updateQuestionnaireRespondent(input: $input, condition: $condition) {
      id
      questionnaireId
      userId
      selectedIndex
      tenantCode
      createdAt
      updatedAt
    }
  }
`;
export const deleteQuestionnaireRespondent = /* GraphQL */ `
  mutation DeleteQuestionnaireRespondent(
    $input: DeleteQuestionnaireRespondentInput!
    $condition: ModelQuestionnaireRespondentConditionInput
  ) {
    deleteQuestionnaireRespondent(input: $input, condition: $condition) {
      id
      questionnaireId
      userId
      selectedIndex
      tenantCode
      createdAt
      updatedAt
    }
  }
`;
export const updateContent = /* GraphQL */ `
  mutation UpdateContent(
    $input: UpdateContentInput!
    $condition: ModelContentConditionInput
  ) {
    updateContent(input: $input, condition: $condition) {
      id
      contentId
      version
      name
      requiredTime
      body {
        type
        text {
          body
        }
        exam {
          problems {
            index
            body
            commentary
            type
            choice {
              options {
                index
                text
              }
              multiple
              answer
            }
            headerId
          }
          problemHeaders {
            id
            body
            createdAt
          }
          passingStandard
        }
      }
      workbook {
        problems {
          index
          body
          commentary
          type
          choice {
            options {
              index
              text
            }
            multiple
            answer
          }
          headerId
        }
        problemHeaders {
          id
          body
          createdAt
        }
      }
      courseId
      courseVersion
      tenantCode
      latest
      versionDescription
      createdAt
      updatedAt
    }
  }
`;
export const deleteContent = /* GraphQL */ `
  mutation DeleteContent(
    $input: DeleteContentInput!
    $condition: ModelContentConditionInput
  ) {
    deleteContent(input: $input, condition: $condition) {
      id
      contentId
      version
      name
      requiredTime
      body {
        type
        text {
          body
        }
        exam {
          problems {
            index
            body
            commentary
            type
            choice {
              options {
                index
                text
              }
              multiple
              answer
            }
            headerId
          }
          problemHeaders {
            id
            body
            createdAt
          }
          passingStandard
        }
      }
      workbook {
        problems {
          index
          body
          commentary
          type
          choice {
            options {
              index
              text
            }
            multiple
            answer
          }
          headerId
        }
        problemHeaders {
          id
          body
          createdAt
        }
      }
      courseId
      courseVersion
      tenantCode
      latest
      versionDescription
      createdAt
      updatedAt
    }
  }
`;
export const updateCourse = /* GraphQL */ `
  mutation UpdateCourse(
    $input: UpdateCourseInput!
    $condition: ModelCourseConditionInput
  ) {
    updateCourse(input: $input, condition: $condition) {
      id
      version
      name
      contents {
        id
        type
        name
        requiredTime
      }
      description
      tenantCode
      color
      image
      fontColorOnImage
      confirmedBy
      createdAt
      versionCreatedBy
      versionCreatedAt
      contentLastUpdatedBy
      contentLastUpdatedAt
      updatedAt
    }
  }
`;
export const deleteCourse = /* GraphQL */ `
  mutation DeleteCourse(
    $input: DeleteCourseInput!
    $condition: ModelCourseConditionInput
  ) {
    deleteCourse(input: $input, condition: $condition) {
      id
      version
      name
      contents {
        id
        type
        name
        requiredTime
      }
      description
      tenantCode
      color
      image
      fontColorOnImage
      confirmedBy
      createdAt
      versionCreatedBy
      versionCreatedAt
      contentLastUpdatedBy
      contentLastUpdatedAt
      updatedAt
    }
  }
`;
export const updateEditingCourseContentWorkbook = /* GraphQL */ `
  mutation UpdateEditingCourseContentWorkbook(
    $input: UpdateEditingCourseContentWorkbookInput!
    $condition: ModelEditingCourseContentWorkbookConditionInput
  ) {
    updateEditingCourseContentWorkbook(input: $input, condition: $condition) {
      contentId
      problems {
        index
        body
        commentary
        type
        choice {
          options {
            index
            text
          }
          multiple
          answer
        }
        headerId
      }
      problemHeaders {
        id
        body
        createdAt
      }
      dataVersion
      createdBy
      updatedBy
      createdAt
      updatedAt
      tenantCode
      courseId
    }
  }
`;
export const updateEditingCourseContentBody = /* GraphQL */ `
  mutation UpdateEditingCourseContentBody(
    $input: UpdateEditingCourseContentBodyInput!
    $condition: ModelEditingCourseContentBodyConditionInput
  ) {
    updateEditingCourseContentBody(input: $input, condition: $condition) {
      contentId
      body {
        type
        text {
          body
        }
        exam {
          problems {
            index
            body
            commentary
            type
            choice {
              options {
                index
                text
              }
              multiple
              answer
            }
            headerId
          }
          problemHeaders {
            id
            body
            createdAt
          }
          passingStandard
        }
      }
      dataVersion
      createdBy
      updatedBy
      createdAt
      updatedAt
      tenantCode
      courseId
    }
  }
`;
export const createEditingConfirmedContent = /* GraphQL */ `
  mutation CreateEditingConfirmedContent(
    $input: CreateEditingConfirmedContentInput!
    $condition: ModelEditingConfirmedContentConditionInput
  ) {
    createEditingConfirmedContent(input: $input, condition: $condition) {
      id
      version
      name
      requiredTime
      body {
        type
        text {
          body
        }
        exam {
          problems {
            index
            body
            commentary
            type
            choice {
              options {
                index
                text
              }
              multiple
              answer
            }
            headerId
          }
          problemHeaders {
            id
            body
            createdAt
          }
          passingStandard
        }
      }
      workbook {
        problems {
          index
          body
          commentary
          type
          choice {
            options {
              index
              text
            }
            multiple
            answer
          }
          headerId
        }
        problemHeaders {
          id
          body
          createdAt
        }
      }
      courseId
      courseVersion
      status
      tenantCode
      createdBy
      updatedBy
      dataVersion
      versionDescription
      createdAt
      updatedAt
    }
  }
`;
export const updateEditingConfirmedContent = /* GraphQL */ `
  mutation UpdateEditingConfirmedContent(
    $input: UpdateEditingConfirmedContentInput!
    $condition: ModelEditingConfirmedContentConditionInput
  ) {
    updateEditingConfirmedContent(input: $input, condition: $condition) {
      id
      version
      name
      requiredTime
      body {
        type
        text {
          body
        }
        exam {
          problems {
            index
            body
            commentary
            type
            choice {
              options {
                index
                text
              }
              multiple
              answer
            }
            headerId
          }
          problemHeaders {
            id
            body
            createdAt
          }
          passingStandard
        }
      }
      workbook {
        problems {
          index
          body
          commentary
          type
          choice {
            options {
              index
              text
            }
            multiple
            answer
          }
          headerId
        }
        problemHeaders {
          id
          body
          createdAt
        }
      }
      courseId
      courseVersion
      status
      tenantCode
      createdBy
      updatedBy
      dataVersion
      versionDescription
      createdAt
      updatedAt
    }
  }
`;
export const updateGroupTraining = /* GraphQL */ `
  mutation UpdateGroupTraining(
    $input: UpdateGroupTrainingInput!
    $condition: ModelGroupTrainingConditionInput
  ) {
    updateGroupTraining(input: $input, condition: $condition) {
      id
      groupId
      tenantCode
      createdAt
      updatedAt
    }
  }
`;
export const deleteGroupTraining = /* GraphQL */ `
  mutation DeleteGroupTraining(
    $input: DeleteGroupTrainingInput!
    $condition: ModelGroupTrainingConditionInput
  ) {
    deleteGroupTraining(input: $input, condition: $condition) {
      id
      groupId
      tenantCode
      createdAt
      updatedAt
    }
  }
`;
export const createGroupTrainingCourse = /* GraphQL */ `
  mutation CreateGroupTrainingCourse(
    $input: CreateGroupTrainingCourseInput!
    $condition: ModelGroupTrainingCourseConditionInput
  ) {
    createGroupTrainingCourse(input: $input, condition: $condition) {
      id
      groupTrainingId
      courseId
      courseVersion
      courseName
      contents {
        id
        name
        requiredTime
        type
        recommendedDateTime
        open
        version
      }
      groupId
      tenantCode
      color
      image
      fontColorOnImage
      displayName
      index
      createdAt
      updatedAt
      groupExams {
        items {
          id
          groupId
          contentId
          content {
            id
            version
            name
            timeLimit
            requiredTime
            indexInCourse
            passingStandard
          }
          problemsJson
          courseId
          course {
            id
            version
            name
            color
            image
            fontColorOnImage
          }
          scheduledStart
          scheduledFinish
          createdBy
          finishedAt
          visibilityLevel
          tenantCode
          times
          groupTrainingCourseId
          problemHeaders {
            id
            body
            createdAt
          }
          timeLimit
          passingStandard
          userIdsToBeTested
          createdAt
          updatedAt
          userExams {
            items {
              id
              userId
              status
              tenantCode
              groupExamId
              groupId
              startedAt
              finishedAt
              createdAt
              updatedAt
              groupExam {
                id
                groupId
                contentId
                content {
                  id
                  version
                  name
                  timeLimit
                  requiredTime
                  indexInCourse
                  passingStandard
                }
                problemsJson
                courseId
                course {
                  id
                  version
                  name
                  color
                  image
                  fontColorOnImage
                }
                scheduledStart
                scheduledFinish
                createdBy
                finishedAt
                visibilityLevel
                tenantCode
                times
                groupTrainingCourseId
                problemHeaders {
                  id
                  body
                  createdAt
                }
                timeLimit
                passingStandard
                userIdsToBeTested
                createdAt
                updatedAt
                userExams {
                  items {
                    id
                    userId
                    status
                    tenantCode
                    groupExamId
                    groupId
                    startedAt
                    finishedAt
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
              }
            }
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const updateGroupTrainingCourse = /* GraphQL */ `
  mutation UpdateGroupTrainingCourse(
    $input: UpdateGroupTrainingCourseInput!
    $condition: ModelGroupTrainingCourseConditionInput
  ) {
    updateGroupTrainingCourse(input: $input, condition: $condition) {
      id
      groupTrainingId
      courseId
      courseVersion
      courseName
      contents {
        id
        name
        requiredTime
        type
        recommendedDateTime
        open
        version
      }
      groupId
      tenantCode
      color
      image
      fontColorOnImage
      displayName
      index
      createdAt
      updatedAt
      groupExams {
        items {
          id
          groupId
          contentId
          content {
            id
            version
            name
            timeLimit
            requiredTime
            indexInCourse
            passingStandard
          }
          problemsJson
          courseId
          course {
            id
            version
            name
            color
            image
            fontColorOnImage
          }
          scheduledStart
          scheduledFinish
          createdBy
          finishedAt
          visibilityLevel
          tenantCode
          times
          groupTrainingCourseId
          problemHeaders {
            id
            body
            createdAt
          }
          timeLimit
          passingStandard
          userIdsToBeTested
          createdAt
          updatedAt
          userExams {
            items {
              id
              userId
              status
              tenantCode
              groupExamId
              groupId
              startedAt
              finishedAt
              createdAt
              updatedAt
              groupExam {
                id
                groupId
                contentId
                content {
                  id
                  version
                  name
                  timeLimit
                  requiredTime
                  indexInCourse
                  passingStandard
                }
                problemsJson
                courseId
                course {
                  id
                  version
                  name
                  color
                  image
                  fontColorOnImage
                }
                scheduledStart
                scheduledFinish
                createdBy
                finishedAt
                visibilityLevel
                tenantCode
                times
                groupTrainingCourseId
                problemHeaders {
                  id
                  body
                  createdAt
                }
                timeLimit
                passingStandard
                userIdsToBeTested
                createdAt
                updatedAt
                userExams {
                  items {
                    id
                    userId
                    status
                    tenantCode
                    groupExamId
                    groupId
                    startedAt
                    finishedAt
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
              }
            }
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const deleteGroupTrainingCourse = /* GraphQL */ `
  mutation DeleteGroupTrainingCourse(
    $input: DeleteGroupTrainingCourseInput!
    $condition: ModelGroupTrainingCourseConditionInput
  ) {
    deleteGroupTrainingCourse(input: $input, condition: $condition) {
      id
      groupTrainingId
      courseId
      courseVersion
      courseName
      contents {
        id
        name
        requiredTime
        type
        recommendedDateTime
        open
        version
      }
      groupId
      tenantCode
      color
      image
      fontColorOnImage
      displayName
      index
      createdAt
      updatedAt
      groupExams {
        items {
          id
          groupId
          contentId
          content {
            id
            version
            name
            timeLimit
            requiredTime
            indexInCourse
            passingStandard
          }
          problemsJson
          courseId
          course {
            id
            version
            name
            color
            image
            fontColorOnImage
          }
          scheduledStart
          scheduledFinish
          createdBy
          finishedAt
          visibilityLevel
          tenantCode
          times
          groupTrainingCourseId
          problemHeaders {
            id
            body
            createdAt
          }
          timeLimit
          passingStandard
          userIdsToBeTested
          createdAt
          updatedAt
          userExams {
            items {
              id
              userId
              status
              tenantCode
              groupExamId
              groupId
              startedAt
              finishedAt
              createdAt
              updatedAt
              groupExam {
                id
                groupId
                contentId
                content {
                  id
                  version
                  name
                  timeLimit
                  requiredTime
                  indexInCourse
                  passingStandard
                }
                problemsJson
                courseId
                course {
                  id
                  version
                  name
                  color
                  image
                  fontColorOnImage
                }
                scheduledStart
                scheduledFinish
                createdBy
                finishedAt
                visibilityLevel
                tenantCode
                times
                groupTrainingCourseId
                problemHeaders {
                  id
                  body
                  createdAt
                }
                timeLimit
                passingStandard
                userIdsToBeTested
                createdAt
                updatedAt
                userExams {
                  items {
                    id
                    userId
                    status
                    tenantCode
                    groupExamId
                    groupId
                    startedAt
                    finishedAt
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
              }
            }
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const createScheduleTag = /* GraphQL */ `
  mutation CreateScheduleTag(
    $input: CreateScheduleTagInput!
    $condition: ModelScheduleTagConditionInput
  ) {
    createScheduleTag(input: $input, condition: $condition) {
      id
      color
      text
      groupId
      tenantCode
      createdAt
      updatedAt
      schedules {
        items {
          id
          scheduleId
          scheduleTagId
          scheduleTag {
            id
            color
            text
            groupId
            tenantCode
            createdAt
            updatedAt
            schedules {
              items {
                id
                scheduleId
                scheduleTagId
                scheduleTag {
                  id
                  color
                  text
                  groupId
                  tenantCode
                  createdAt
                  updatedAt
                  schedules {
                    nextToken
                  }
                }
                tenantCode
                createdAt
                updatedAt
                schedule {
                  id
                  groupId
                  start
                  end
                  title
                  body
                  tenantCode
                  createdBy
                  updatedBy
                  createdAt
                  updatedAt
                  tags {
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
          tenantCode
          createdAt
          updatedAt
          schedule {
            id
            groupId
            start
            end
            title
            body
            tenantCode
            createdBy
            updatedBy
            createdAt
            updatedAt
            tags {
              items {
                id
                scheduleId
                scheduleTagId
                scheduleTag {
                  id
                  color
                  text
                  groupId
                  tenantCode
                  createdAt
                  updatedAt
                  schedules {
                    nextToken
                  }
                }
                tenantCode
                createdAt
                updatedAt
                schedule {
                  id
                  groupId
                  start
                  end
                  title
                  body
                  tenantCode
                  createdBy
                  updatedBy
                  createdAt
                  updatedAt
                  tags {
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const updateScheduleTag = /* GraphQL */ `
  mutation UpdateScheduleTag(
    $input: UpdateScheduleTagInput!
    $condition: ModelScheduleTagConditionInput
  ) {
    updateScheduleTag(input: $input, condition: $condition) {
      id
      color
      text
      groupId
      tenantCode
      createdAt
      updatedAt
      schedules {
        items {
          id
          scheduleId
          scheduleTagId
          scheduleTag {
            id
            color
            text
            groupId
            tenantCode
            createdAt
            updatedAt
            schedules {
              items {
                id
                scheduleId
                scheduleTagId
                scheduleTag {
                  id
                  color
                  text
                  groupId
                  tenantCode
                  createdAt
                  updatedAt
                  schedules {
                    nextToken
                  }
                }
                tenantCode
                createdAt
                updatedAt
                schedule {
                  id
                  groupId
                  start
                  end
                  title
                  body
                  tenantCode
                  createdBy
                  updatedBy
                  createdAt
                  updatedAt
                  tags {
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
          tenantCode
          createdAt
          updatedAt
          schedule {
            id
            groupId
            start
            end
            title
            body
            tenantCode
            createdBy
            updatedBy
            createdAt
            updatedAt
            tags {
              items {
                id
                scheduleId
                scheduleTagId
                scheduleTag {
                  id
                  color
                  text
                  groupId
                  tenantCode
                  createdAt
                  updatedAt
                  schedules {
                    nextToken
                  }
                }
                tenantCode
                createdAt
                updatedAt
                schedule {
                  id
                  groupId
                  start
                  end
                  title
                  body
                  tenantCode
                  createdBy
                  updatedBy
                  createdAt
                  updatedAt
                  tags {
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const deleteScheduleTag = /* GraphQL */ `
  mutation DeleteScheduleTag(
    $input: DeleteScheduleTagInput!
    $condition: ModelScheduleTagConditionInput
  ) {
    deleteScheduleTag(input: $input, condition: $condition) {
      id
      color
      text
      groupId
      tenantCode
      createdAt
      updatedAt
      schedules {
        items {
          id
          scheduleId
          scheduleTagId
          scheduleTag {
            id
            color
            text
            groupId
            tenantCode
            createdAt
            updatedAt
            schedules {
              items {
                id
                scheduleId
                scheduleTagId
                scheduleTag {
                  id
                  color
                  text
                  groupId
                  tenantCode
                  createdAt
                  updatedAt
                  schedules {
                    nextToken
                  }
                }
                tenantCode
                createdAt
                updatedAt
                schedule {
                  id
                  groupId
                  start
                  end
                  title
                  body
                  tenantCode
                  createdBy
                  updatedBy
                  createdAt
                  updatedAt
                  tags {
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
          tenantCode
          createdAt
          updatedAt
          schedule {
            id
            groupId
            start
            end
            title
            body
            tenantCode
            createdBy
            updatedBy
            createdAt
            updatedAt
            tags {
              items {
                id
                scheduleId
                scheduleTagId
                scheduleTag {
                  id
                  color
                  text
                  groupId
                  tenantCode
                  createdAt
                  updatedAt
                  schedules {
                    nextToken
                  }
                }
                tenantCode
                createdAt
                updatedAt
                schedule {
                  id
                  groupId
                  start
                  end
                  title
                  body
                  tenantCode
                  createdBy
                  updatedBy
                  createdAt
                  updatedAt
                  tags {
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const createScheduleScheduleTag = /* GraphQL */ `
  mutation CreateScheduleScheduleTag(
    $input: CreateScheduleScheduleTagInput!
    $condition: ModelScheduleScheduleTagConditionInput
  ) {
    createScheduleScheduleTag(input: $input, condition: $condition) {
      id
      scheduleId
      scheduleTagId
      scheduleTag {
        id
        color
        text
        groupId
        tenantCode
        createdAt
        updatedAt
        schedules {
          items {
            id
            scheduleId
            scheduleTagId
            scheduleTag {
              id
              color
              text
              groupId
              tenantCode
              createdAt
              updatedAt
              schedules {
                items {
                  id
                  scheduleId
                  scheduleTagId
                  scheduleTag {
                    id
                    color
                    text
                    groupId
                    tenantCode
                    createdAt
                    updatedAt
                  }
                  tenantCode
                  createdAt
                  updatedAt
                  schedule {
                    id
                    groupId
                    start
                    end
                    title
                    body
                    tenantCode
                    createdBy
                    updatedBy
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
            tenantCode
            createdAt
            updatedAt
            schedule {
              id
              groupId
              start
              end
              title
              body
              tenantCode
              createdBy
              updatedBy
              createdAt
              updatedAt
              tags {
                items {
                  id
                  scheduleId
                  scheduleTagId
                  scheduleTag {
                    id
                    color
                    text
                    groupId
                    tenantCode
                    createdAt
                    updatedAt
                  }
                  tenantCode
                  createdAt
                  updatedAt
                  schedule {
                    id
                    groupId
                    start
                    end
                    title
                    body
                    tenantCode
                    createdBy
                    updatedBy
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }
      tenantCode
      createdAt
      updatedAt
      schedule {
        id
        groupId
        start
        end
        title
        body
        tenantCode
        createdBy
        updatedBy
        createdAt
        updatedAt
        tags {
          items {
            id
            scheduleId
            scheduleTagId
            scheduleTag {
              id
              color
              text
              groupId
              tenantCode
              createdAt
              updatedAt
              schedules {
                items {
                  id
                  scheduleId
                  scheduleTagId
                  scheduleTag {
                    id
                    color
                    text
                    groupId
                    tenantCode
                    createdAt
                    updatedAt
                  }
                  tenantCode
                  createdAt
                  updatedAt
                  schedule {
                    id
                    groupId
                    start
                    end
                    title
                    body
                    tenantCode
                    createdBy
                    updatedBy
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
            tenantCode
            createdAt
            updatedAt
            schedule {
              id
              groupId
              start
              end
              title
              body
              tenantCode
              createdBy
              updatedBy
              createdAt
              updatedAt
              tags {
                items {
                  id
                  scheduleId
                  scheduleTagId
                  scheduleTag {
                    id
                    color
                    text
                    groupId
                    tenantCode
                    createdAt
                    updatedAt
                  }
                  tenantCode
                  createdAt
                  updatedAt
                  schedule {
                    id
                    groupId
                    start
                    end
                    title
                    body
                    tenantCode
                    createdBy
                    updatedBy
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const updateScheduleScheduleTag = /* GraphQL */ `
  mutation UpdateScheduleScheduleTag(
    $input: UpdateScheduleScheduleTagInput!
    $condition: ModelScheduleScheduleTagConditionInput
  ) {
    updateScheduleScheduleTag(input: $input, condition: $condition) {
      id
      scheduleId
      scheduleTagId
      scheduleTag {
        id
        color
        text
        groupId
        tenantCode
        createdAt
        updatedAt
        schedules {
          items {
            id
            scheduleId
            scheduleTagId
            scheduleTag {
              id
              color
              text
              groupId
              tenantCode
              createdAt
              updatedAt
              schedules {
                items {
                  id
                  scheduleId
                  scheduleTagId
                  scheduleTag {
                    id
                    color
                    text
                    groupId
                    tenantCode
                    createdAt
                    updatedAt
                  }
                  tenantCode
                  createdAt
                  updatedAt
                  schedule {
                    id
                    groupId
                    start
                    end
                    title
                    body
                    tenantCode
                    createdBy
                    updatedBy
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
            tenantCode
            createdAt
            updatedAt
            schedule {
              id
              groupId
              start
              end
              title
              body
              tenantCode
              createdBy
              updatedBy
              createdAt
              updatedAt
              tags {
                items {
                  id
                  scheduleId
                  scheduleTagId
                  scheduleTag {
                    id
                    color
                    text
                    groupId
                    tenantCode
                    createdAt
                    updatedAt
                  }
                  tenantCode
                  createdAt
                  updatedAt
                  schedule {
                    id
                    groupId
                    start
                    end
                    title
                    body
                    tenantCode
                    createdBy
                    updatedBy
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }
      tenantCode
      createdAt
      updatedAt
      schedule {
        id
        groupId
        start
        end
        title
        body
        tenantCode
        createdBy
        updatedBy
        createdAt
        updatedAt
        tags {
          items {
            id
            scheduleId
            scheduleTagId
            scheduleTag {
              id
              color
              text
              groupId
              tenantCode
              createdAt
              updatedAt
              schedules {
                items {
                  id
                  scheduleId
                  scheduleTagId
                  scheduleTag {
                    id
                    color
                    text
                    groupId
                    tenantCode
                    createdAt
                    updatedAt
                  }
                  tenantCode
                  createdAt
                  updatedAt
                  schedule {
                    id
                    groupId
                    start
                    end
                    title
                    body
                    tenantCode
                    createdBy
                    updatedBy
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
            tenantCode
            createdAt
            updatedAt
            schedule {
              id
              groupId
              start
              end
              title
              body
              tenantCode
              createdBy
              updatedBy
              createdAt
              updatedAt
              tags {
                items {
                  id
                  scheduleId
                  scheduleTagId
                  scheduleTag {
                    id
                    color
                    text
                    groupId
                    tenantCode
                    createdAt
                    updatedAt
                  }
                  tenantCode
                  createdAt
                  updatedAt
                  schedule {
                    id
                    groupId
                    start
                    end
                    title
                    body
                    tenantCode
                    createdBy
                    updatedBy
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const createSchedule = /* GraphQL */ `
  mutation CreateSchedule(
    $input: CreateScheduleInput!
    $condition: ModelScheduleConditionInput
  ) {
    createSchedule(input: $input, condition: $condition) {
      id
      groupId
      start
      end
      title
      body
      tenantCode
      createdBy
      updatedBy
      createdAt
      updatedAt
      tags {
        items {
          id
          scheduleId
          scheduleTagId
          scheduleTag {
            id
            color
            text
            groupId
            tenantCode
            createdAt
            updatedAt
            schedules {
              items {
                id
                scheduleId
                scheduleTagId
                scheduleTag {
                  id
                  color
                  text
                  groupId
                  tenantCode
                  createdAt
                  updatedAt
                  schedules {
                    nextToken
                  }
                }
                tenantCode
                createdAt
                updatedAt
                schedule {
                  id
                  groupId
                  start
                  end
                  title
                  body
                  tenantCode
                  createdBy
                  updatedBy
                  createdAt
                  updatedAt
                  tags {
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
          tenantCode
          createdAt
          updatedAt
          schedule {
            id
            groupId
            start
            end
            title
            body
            tenantCode
            createdBy
            updatedBy
            createdAt
            updatedAt
            tags {
              items {
                id
                scheduleId
                scheduleTagId
                scheduleTag {
                  id
                  color
                  text
                  groupId
                  tenantCode
                  createdAt
                  updatedAt
                  schedules {
                    nextToken
                  }
                }
                tenantCode
                createdAt
                updatedAt
                schedule {
                  id
                  groupId
                  start
                  end
                  title
                  body
                  tenantCode
                  createdBy
                  updatedBy
                  createdAt
                  updatedAt
                  tags {
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const updateSchedule = /* GraphQL */ `
  mutation UpdateSchedule(
    $input: UpdateScheduleInput!
    $condition: ModelScheduleConditionInput
  ) {
    updateSchedule(input: $input, condition: $condition) {
      id
      groupId
      start
      end
      title
      body
      tenantCode
      createdBy
      updatedBy
      createdAt
      updatedAt
      tags {
        items {
          id
          scheduleId
          scheduleTagId
          scheduleTag {
            id
            color
            text
            groupId
            tenantCode
            createdAt
            updatedAt
            schedules {
              items {
                id
                scheduleId
                scheduleTagId
                scheduleTag {
                  id
                  color
                  text
                  groupId
                  tenantCode
                  createdAt
                  updatedAt
                  schedules {
                    nextToken
                  }
                }
                tenantCode
                createdAt
                updatedAt
                schedule {
                  id
                  groupId
                  start
                  end
                  title
                  body
                  tenantCode
                  createdBy
                  updatedBy
                  createdAt
                  updatedAt
                  tags {
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
          tenantCode
          createdAt
          updatedAt
          schedule {
            id
            groupId
            start
            end
            title
            body
            tenantCode
            createdBy
            updatedBy
            createdAt
            updatedAt
            tags {
              items {
                id
                scheduleId
                scheduleTagId
                scheduleTag {
                  id
                  color
                  text
                  groupId
                  tenantCode
                  createdAt
                  updatedAt
                  schedules {
                    nextToken
                  }
                }
                tenantCode
                createdAt
                updatedAt
                schedule {
                  id
                  groupId
                  start
                  end
                  title
                  body
                  tenantCode
                  createdBy
                  updatedBy
                  createdAt
                  updatedAt
                  tags {
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const deleteSchedule = /* GraphQL */ `
  mutation DeleteSchedule(
    $input: DeleteScheduleInput!
    $condition: ModelScheduleConditionInput
  ) {
    deleteSchedule(input: $input, condition: $condition) {
      id
      groupId
      start
      end
      title
      body
      tenantCode
      createdBy
      updatedBy
      createdAt
      updatedAt
      tags {
        items {
          id
          scheduleId
          scheduleTagId
          scheduleTag {
            id
            color
            text
            groupId
            tenantCode
            createdAt
            updatedAt
            schedules {
              items {
                id
                scheduleId
                scheduleTagId
                scheduleTag {
                  id
                  color
                  text
                  groupId
                  tenantCode
                  createdAt
                  updatedAt
                  schedules {
                    nextToken
                  }
                }
                tenantCode
                createdAt
                updatedAt
                schedule {
                  id
                  groupId
                  start
                  end
                  title
                  body
                  tenantCode
                  createdBy
                  updatedBy
                  createdAt
                  updatedAt
                  tags {
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
          tenantCode
          createdAt
          updatedAt
          schedule {
            id
            groupId
            start
            end
            title
            body
            tenantCode
            createdBy
            updatedBy
            createdAt
            updatedAt
            tags {
              items {
                id
                scheduleId
                scheduleTagId
                scheduleTag {
                  id
                  color
                  text
                  groupId
                  tenantCode
                  createdAt
                  updatedAt
                  schedules {
                    nextToken
                  }
                }
                tenantCode
                createdAt
                updatedAt
                schedule {
                  id
                  groupId
                  start
                  end
                  title
                  body
                  tenantCode
                  createdBy
                  updatedBy
                  createdAt
                  updatedAt
                  tags {
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const createComment = /* GraphQL */ `
  mutation CreateComment(
    $input: CreateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    createComment(input: $input, condition: $condition) {
      id
      questionId
      body
      createdBy
      createdAt
      editedBy
      editedAt
      tenantCode
      groupId
      updatedAt
    }
  }
`;
export const updateComment = /* GraphQL */ `
  mutation UpdateComment(
    $input: UpdateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    updateComment(input: $input, condition: $condition) {
      id
      questionId
      body
      createdBy
      createdAt
      editedBy
      editedAt
      tenantCode
      groupId
      updatedAt
    }
  }
`;
export const deleteComment = /* GraphQL */ `
  mutation DeleteComment(
    $input: DeleteCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    deleteComment(input: $input, condition: $condition) {
      id
      questionId
      body
      createdBy
      createdAt
      editedBy
      editedAt
      tenantCode
      groupId
      updatedAt
    }
  }
`;
export const createQuestion = /* GraphQL */ `
  mutation CreateQuestion(
    $input: CreateQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    createQuestion(input: $input, condition: $condition) {
      id
      createdBy
      resolved
      resolvedAt
      resolvedBy
      groupId
      title
      referTo {
        contentId
        contentVersion
        options {
          text {
            selectionJson
          }
          workbook {
            problemIndex
            selectionJson
          }
        }
      }
      tenantCode
      createdAt
      assignees
      updatedAt
      comments {
        items {
          id
          questionId
          body
          createdBy
          createdAt
          editedBy
          editedAt
          tenantCode
          groupId
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateQuestion = /* GraphQL */ `
  mutation UpdateQuestion(
    $input: UpdateQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    updateQuestion(input: $input, condition: $condition) {
      id
      createdBy
      resolved
      resolvedAt
      resolvedBy
      groupId
      title
      referTo {
        contentId
        contentVersion
        options {
          text {
            selectionJson
          }
          workbook {
            problemIndex
            selectionJson
          }
        }
      }
      tenantCode
      createdAt
      assignees
      updatedAt
      comments {
        items {
          id
          questionId
          body
          createdBy
          createdAt
          editedBy
          editedAt
          tenantCode
          groupId
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteQuestion = /* GraphQL */ `
  mutation DeleteQuestion(
    $input: DeleteQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    deleteQuestion(input: $input, condition: $condition) {
      id
      createdBy
      resolved
      resolvedAt
      resolvedBy
      groupId
      title
      referTo {
        contentId
        contentVersion
        options {
          text {
            selectionJson
          }
          workbook {
            problemIndex
            selectionJson
          }
        }
      }
      tenantCode
      createdAt
      assignees
      updatedAt
      comments {
        items {
          id
          questionId
          body
          createdBy
          createdAt
          editedBy
          editedAt
          tenantCode
          groupId
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteContentLearning = /* GraphQL */ `
  mutation DeleteContentLearning(
    $input: DeleteContentLearningInput!
    $condition: ModelContentLearningConditionInput
  ) {
    deleteContentLearning(input: $input, condition: $condition) {
      groupId
      courseId
      contentId
      contentType
      userId
      status
      usageTime
      lastStartedAt
      dataVersion
      tenantCode
      completedAt
      completedUsageTime
      createdAt
      updatedAt
    }
  }
`;
export const createGroupExam = /* GraphQL */ `
  mutation CreateGroupExam(
    $input: CreateGroupExamInput!
    $condition: ModelGroupExamConditionInput
  ) {
    createGroupExam(input: $input, condition: $condition) {
      id
      groupId
      contentId
      content {
        id
        version
        name
        timeLimit
        requiredTime
        indexInCourse
        passingStandard
      }
      problemsJson
      courseId
      course {
        id
        version
        name
        color
        image
        fontColorOnImage
      }
      scheduledStart
      scheduledFinish
      createdBy
      finishedAt
      visibilityLevel
      tenantCode
      times
      groupTrainingCourseId
      problemHeaders {
        id
        body
        createdAt
      }
      timeLimit
      passingStandard
      userIdsToBeTested
      createdAt
      updatedAt
      userExams {
        items {
          id
          userId
          status
          tenantCode
          groupExamId
          groupId
          startedAt
          finishedAt
          createdAt
          updatedAt
          groupExam {
            id
            groupId
            contentId
            content {
              id
              version
              name
              timeLimit
              requiredTime
              indexInCourse
              passingStandard
            }
            problemsJson
            courseId
            course {
              id
              version
              name
              color
              image
              fontColorOnImage
            }
            scheduledStart
            scheduledFinish
            createdBy
            finishedAt
            visibilityLevel
            tenantCode
            times
            groupTrainingCourseId
            problemHeaders {
              id
              body
              createdAt
            }
            timeLimit
            passingStandard
            userIdsToBeTested
            createdAt
            updatedAt
            userExams {
              items {
                id
                userId
                status
                tenantCode
                groupExamId
                groupId
                startedAt
                finishedAt
                createdAt
                updatedAt
                groupExam {
                  id
                  groupId
                  contentId
                  content {
                    id
                    version
                    name
                    timeLimit
                    requiredTime
                    indexInCourse
                    passingStandard
                  }
                  problemsJson
                  courseId
                  course {
                    id
                    version
                    name
                    color
                    image
                    fontColorOnImage
                  }
                  scheduledStart
                  scheduledFinish
                  createdBy
                  finishedAt
                  visibilityLevel
                  tenantCode
                  times
                  groupTrainingCourseId
                  problemHeaders {
                    id
                    body
                    createdAt
                  }
                  timeLimit
                  passingStandard
                  userIdsToBeTested
                  createdAt
                  updatedAt
                  userExams {
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const updateGroupExam = /* GraphQL */ `
  mutation UpdateGroupExam(
    $input: UpdateGroupExamInput!
    $condition: ModelGroupExamConditionInput
  ) {
    updateGroupExam(input: $input, condition: $condition) {
      id
      groupId
      contentId
      content {
        id
        version
        name
        timeLimit
        requiredTime
        indexInCourse
        passingStandard
      }
      problemsJson
      courseId
      course {
        id
        version
        name
        color
        image
        fontColorOnImage
      }
      scheduledStart
      scheduledFinish
      createdBy
      finishedAt
      visibilityLevel
      tenantCode
      times
      groupTrainingCourseId
      problemHeaders {
        id
        body
        createdAt
      }
      timeLimit
      passingStandard
      userIdsToBeTested
      createdAt
      updatedAt
      userExams {
        items {
          id
          userId
          status
          tenantCode
          groupExamId
          groupId
          startedAt
          finishedAt
          createdAt
          updatedAt
          groupExam {
            id
            groupId
            contentId
            content {
              id
              version
              name
              timeLimit
              requiredTime
              indexInCourse
              passingStandard
            }
            problemsJson
            courseId
            course {
              id
              version
              name
              color
              image
              fontColorOnImage
            }
            scheduledStart
            scheduledFinish
            createdBy
            finishedAt
            visibilityLevel
            tenantCode
            times
            groupTrainingCourseId
            problemHeaders {
              id
              body
              createdAt
            }
            timeLimit
            passingStandard
            userIdsToBeTested
            createdAt
            updatedAt
            userExams {
              items {
                id
                userId
                status
                tenantCode
                groupExamId
                groupId
                startedAt
                finishedAt
                createdAt
                updatedAt
                groupExam {
                  id
                  groupId
                  contentId
                  content {
                    id
                    version
                    name
                    timeLimit
                    requiredTime
                    indexInCourse
                    passingStandard
                  }
                  problemsJson
                  courseId
                  course {
                    id
                    version
                    name
                    color
                    image
                    fontColorOnImage
                  }
                  scheduledStart
                  scheduledFinish
                  createdBy
                  finishedAt
                  visibilityLevel
                  tenantCode
                  times
                  groupTrainingCourseId
                  problemHeaders {
                    id
                    body
                    createdAt
                  }
                  timeLimit
                  passingStandard
                  userIdsToBeTested
                  createdAt
                  updatedAt
                  userExams {
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const deleteGroupExam = /* GraphQL */ `
  mutation DeleteGroupExam(
    $input: DeleteGroupExamInput!
    $condition: ModelGroupExamConditionInput
  ) {
    deleteGroupExam(input: $input, condition: $condition) {
      id
      groupId
      contentId
      content {
        id
        version
        name
        timeLimit
        requiredTime
        indexInCourse
        passingStandard
      }
      problemsJson
      courseId
      course {
        id
        version
        name
        color
        image
        fontColorOnImage
      }
      scheduledStart
      scheduledFinish
      createdBy
      finishedAt
      visibilityLevel
      tenantCode
      times
      groupTrainingCourseId
      problemHeaders {
        id
        body
        createdAt
      }
      timeLimit
      passingStandard
      userIdsToBeTested
      createdAt
      updatedAt
      userExams {
        items {
          id
          userId
          status
          tenantCode
          groupExamId
          groupId
          startedAt
          finishedAt
          createdAt
          updatedAt
          groupExam {
            id
            groupId
            contentId
            content {
              id
              version
              name
              timeLimit
              requiredTime
              indexInCourse
              passingStandard
            }
            problemsJson
            courseId
            course {
              id
              version
              name
              color
              image
              fontColorOnImage
            }
            scheduledStart
            scheduledFinish
            createdBy
            finishedAt
            visibilityLevel
            tenantCode
            times
            groupTrainingCourseId
            problemHeaders {
              id
              body
              createdAt
            }
            timeLimit
            passingStandard
            userIdsToBeTested
            createdAt
            updatedAt
            userExams {
              items {
                id
                userId
                status
                tenantCode
                groupExamId
                groupId
                startedAt
                finishedAt
                createdAt
                updatedAt
                groupExam {
                  id
                  groupId
                  contentId
                  content {
                    id
                    version
                    name
                    timeLimit
                    requiredTime
                    indexInCourse
                    passingStandard
                  }
                  problemsJson
                  courseId
                  course {
                    id
                    version
                    name
                    color
                    image
                    fontColorOnImage
                  }
                  scheduledStart
                  scheduledFinish
                  createdBy
                  finishedAt
                  visibilityLevel
                  tenantCode
                  times
                  groupTrainingCourseId
                  problemHeaders {
                    id
                    body
                    createdAt
                  }
                  timeLimit
                  passingStandard
                  userIdsToBeTested
                  createdAt
                  updatedAt
                  userExams {
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const createUserExam = /* GraphQL */ `
  mutation CreateUserExam(
    $input: CreateUserExamInput!
    $condition: ModelUserExamConditionInput
  ) {
    createUserExam(input: $input, condition: $condition) {
      id
      userId
      status
      tenantCode
      groupExamId
      groupId
      startedAt
      finishedAt
      createdAt
      updatedAt
      groupExam {
        id
        groupId
        contentId
        content {
          id
          version
          name
          timeLimit
          requiredTime
          indexInCourse
          passingStandard
        }
        problemsJson
        courseId
        course {
          id
          version
          name
          color
          image
          fontColorOnImage
        }
        scheduledStart
        scheduledFinish
        createdBy
        finishedAt
        visibilityLevel
        tenantCode
        times
        groupTrainingCourseId
        problemHeaders {
          id
          body
          createdAt
        }
        timeLimit
        passingStandard
        userIdsToBeTested
        createdAt
        updatedAt
        userExams {
          items {
            id
            userId
            status
            tenantCode
            groupExamId
            groupId
            startedAt
            finishedAt
            createdAt
            updatedAt
            groupExam {
              id
              groupId
              contentId
              content {
                id
                version
                name
                timeLimit
                requiredTime
                indexInCourse
                passingStandard
              }
              problemsJson
              courseId
              course {
                id
                version
                name
                color
                image
                fontColorOnImage
              }
              scheduledStart
              scheduledFinish
              createdBy
              finishedAt
              visibilityLevel
              tenantCode
              times
              groupTrainingCourseId
              problemHeaders {
                id
                body
                createdAt
              }
              timeLimit
              passingStandard
              userIdsToBeTested
              createdAt
              updatedAt
              userExams {
                items {
                  id
                  userId
                  status
                  tenantCode
                  groupExamId
                  groupId
                  startedAt
                  finishedAt
                  createdAt
                  updatedAt
                  groupExam {
                    id
                    groupId
                    contentId
                    problemsJson
                    courseId
                    scheduledStart
                    scheduledFinish
                    createdBy
                    finishedAt
                    visibilityLevel
                    tenantCode
                    times
                    groupTrainingCourseId
                    timeLimit
                    passingStandard
                    userIdsToBeTested
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteUserExam = /* GraphQL */ `
  mutation DeleteUserExam(
    $input: DeleteUserExamInput!
    $condition: ModelUserExamConditionInput
  ) {
    deleteUserExam(input: $input, condition: $condition) {
      id
      userId
      status
      tenantCode
      groupExamId
      groupId
      startedAt
      finishedAt
      createdAt
      updatedAt
      groupExam {
        id
        groupId
        contentId
        content {
          id
          version
          name
          timeLimit
          requiredTime
          indexInCourse
          passingStandard
        }
        problemsJson
        courseId
        course {
          id
          version
          name
          color
          image
          fontColorOnImage
        }
        scheduledStart
        scheduledFinish
        createdBy
        finishedAt
        visibilityLevel
        tenantCode
        times
        groupTrainingCourseId
        problemHeaders {
          id
          body
          createdAt
        }
        timeLimit
        passingStandard
        userIdsToBeTested
        createdAt
        updatedAt
        userExams {
          items {
            id
            userId
            status
            tenantCode
            groupExamId
            groupId
            startedAt
            finishedAt
            createdAt
            updatedAt
            groupExam {
              id
              groupId
              contentId
              content {
                id
                version
                name
                timeLimit
                requiredTime
                indexInCourse
                passingStandard
              }
              problemsJson
              courseId
              course {
                id
                version
                name
                color
                image
                fontColorOnImage
              }
              scheduledStart
              scheduledFinish
              createdBy
              finishedAt
              visibilityLevel
              tenantCode
              times
              groupTrainingCourseId
              problemHeaders {
                id
                body
                createdAt
              }
              timeLimit
              passingStandard
              userIdsToBeTested
              createdAt
              updatedAt
              userExams {
                items {
                  id
                  userId
                  status
                  tenantCode
                  groupExamId
                  groupId
                  startedAt
                  finishedAt
                  createdAt
                  updatedAt
                  groupExam {
                    id
                    groupId
                    contentId
                    problemsJson
                    courseId
                    scheduledStart
                    scheduledFinish
                    createdBy
                    finishedAt
                    visibilityLevel
                    tenantCode
                    times
                    groupTrainingCourseId
                    timeLimit
                    passingStandard
                    userIdsToBeTested
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const createMemo = /* GraphQL */ `
  mutation CreateMemo(
    $input: CreateMemoInput!
    $condition: ModelMemoConditionInput
  ) {
    createMemo(input: $input, condition: $condition) {
      id
      body
      referTo {
        contentId
        contentVersion
        options {
          text {
            selectionJson
          }
          workbook {
            problemIndex
            selectionJson
          }
        }
      }
      scope
      groupId
      createdBy
      createdAt
      updatedBy
      updatedAt
      tenantCode
    }
  }
`;
export const updateMemo = /* GraphQL */ `
  mutation UpdateMemo(
    $input: UpdateMemoInput!
    $condition: ModelMemoConditionInput
  ) {
    updateMemo(input: $input, condition: $condition) {
      id
      body
      referTo {
        contentId
        contentVersion
        options {
          text {
            selectionJson
          }
          workbook {
            problemIndex
            selectionJson
          }
        }
      }
      scope
      groupId
      createdBy
      createdAt
      updatedBy
      updatedAt
      tenantCode
    }
  }
`;
export const deleteMemo = /* GraphQL */ `
  mutation DeleteMemo(
    $input: DeleteMemoInput!
    $condition: ModelMemoConditionInput
  ) {
    deleteMemo(input: $input, condition: $condition) {
      id
      body
      referTo {
        contentId
        contentVersion
        options {
          text {
            selectionJson
          }
          workbook {
            problemIndex
            selectionJson
          }
        }
      }
      scope
      groupId
      createdBy
      createdAt
      updatedBy
      updatedAt
      tenantCode
    }
  }
`;
export const createReviewProblem = /* GraphQL */ `
  mutation CreateReviewProblem(
    $input: CreateReviewProblemInput!
    $condition: ModelReviewProblemConditionInput
  ) {
    createReviewProblem(input: $input, condition: $condition) {
      id
      userId
      groupId
      courseId
      problems
      tenantCode
      createdAt
      updatedAt
    }
  }
`;
export const updateReviewProblem = /* GraphQL */ `
  mutation UpdateReviewProblem(
    $input: UpdateReviewProblemInput!
    $condition: ModelReviewProblemConditionInput
  ) {
    updateReviewProblem(input: $input, condition: $condition) {
      id
      userId
      groupId
      courseId
      problems
      tenantCode
      createdAt
      updatedAt
    }
  }
`;
export const deleteReviewProblem = /* GraphQL */ `
  mutation DeleteReviewProblem(
    $input: DeleteReviewProblemInput!
    $condition: ModelReviewProblemConditionInput
  ) {
    deleteReviewProblem(input: $input, condition: $condition) {
      id
      userId
      groupId
      courseId
      problems
      tenantCode
      createdAt
      updatedAt
    }
  }
`;
export const createNotification = /* GraphQL */ `
  mutation CreateNotification(
    $input: CreateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    createNotification(input: $input, condition: $condition) {
      id
      type
      payloadJson
      userId
      read
      createdAt
      tenantCode
      updatedAt
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      name
      role
      extensionConfigJsonList
      email
      code
      tenantCode
      displaySettings {
        theme
        color
      }
      locale
      avatar
      enabled
      signedInAtLeastOnce
      emailConfirmed
      confirmedTermsOfServiceVersions {
        termsOfServiceVersion
        privacyPolicyVersion
        tenantOwnTermsOfServiceVersion
      }
      createdAt
      updatedAt
      tags {
        items {
          id
          userId
          userTagId
          tenantCode
          createdAt
          updatedAt
          user {
            id
            name
            role
            extensionConfigJsonList
            email
            code
            tenantCode
            displaySettings {
              theme
              color
            }
            locale
            avatar
            enabled
            signedInAtLeastOnce
            emailConfirmed
            confirmedTermsOfServiceVersions {
              termsOfServiceVersion
              privacyPolicyVersion
              tenantOwnTermsOfServiceVersion
            }
            createdAt
            updatedAt
            tags {
              items {
                id
                userId
                userTagId
                tenantCode
                createdAt
                updatedAt
                user {
                  id
                  name
                  role
                  extensionConfigJsonList
                  email
                  code
                  tenantCode
                  displaySettings {
                    theme
                    color
                  }
                  locale
                  avatar
                  enabled
                  signedInAtLeastOnce
                  emailConfirmed
                  confirmedTermsOfServiceVersions {
                    termsOfServiceVersion
                    privacyPolicyVersion
                    tenantOwnTermsOfServiceVersion
                  }
                  createdAt
                  updatedAt
                  tags {
                    nextToken
                  }
                  statuses {
                    nextToken
                  }
                }
                userTag {
                  id
                  color
                  text
                  tenantCode
                  createdAt
                  updatedAt
                  users {
                    nextToken
                  }
                }
              }
              nextToken
            }
            statuses {
              items {
                id
                tenantCode
                status
                userId
                createdAt
                updatedAt
                user {
                  id
                  name
                  role
                  extensionConfigJsonList
                  email
                  code
                  tenantCode
                  displaySettings {
                    theme
                    color
                  }
                  locale
                  avatar
                  enabled
                  signedInAtLeastOnce
                  emailConfirmed
                  confirmedTermsOfServiceVersions {
                    termsOfServiceVersion
                    privacyPolicyVersion
                    tenantOwnTermsOfServiceVersion
                  }
                  createdAt
                  updatedAt
                  tags {
                    nextToken
                  }
                  statuses {
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
          userTag {
            id
            color
            text
            tenantCode
            createdAt
            updatedAt
            users {
              items {
                id
                userId
                userTagId
                tenantCode
                createdAt
                updatedAt
                user {
                  id
                  name
                  role
                  extensionConfigJsonList
                  email
                  code
                  tenantCode
                  displaySettings {
                    theme
                    color
                  }
                  locale
                  avatar
                  enabled
                  signedInAtLeastOnce
                  emailConfirmed
                  confirmedTermsOfServiceVersions {
                    termsOfServiceVersion
                    privacyPolicyVersion
                    tenantOwnTermsOfServiceVersion
                  }
                  createdAt
                  updatedAt
                  tags {
                    nextToken
                  }
                  statuses {
                    nextToken
                  }
                }
                userTag {
                  id
                  color
                  text
                  tenantCode
                  createdAt
                  updatedAt
                  users {
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
        }
        nextToken
      }
      statuses {
        items {
          id
          tenantCode
          status
          userId
          createdAt
          updatedAt
          user {
            id
            name
            role
            extensionConfigJsonList
            email
            code
            tenantCode
            displaySettings {
              theme
              color
            }
            locale
            avatar
            enabled
            signedInAtLeastOnce
            emailConfirmed
            confirmedTermsOfServiceVersions {
              termsOfServiceVersion
              privacyPolicyVersion
              tenantOwnTermsOfServiceVersion
            }
            createdAt
            updatedAt
            tags {
              items {
                id
                userId
                userTagId
                tenantCode
                createdAt
                updatedAt
                user {
                  id
                  name
                  role
                  extensionConfigJsonList
                  email
                  code
                  tenantCode
                  displaySettings {
                    theme
                    color
                  }
                  locale
                  avatar
                  enabled
                  signedInAtLeastOnce
                  emailConfirmed
                  confirmedTermsOfServiceVersions {
                    termsOfServiceVersion
                    privacyPolicyVersion
                    tenantOwnTermsOfServiceVersion
                  }
                  createdAt
                  updatedAt
                  tags {
                    nextToken
                  }
                  statuses {
                    nextToken
                  }
                }
                userTag {
                  id
                  color
                  text
                  tenantCode
                  createdAt
                  updatedAt
                  users {
                    nextToken
                  }
                }
              }
              nextToken
            }
            statuses {
              items {
                id
                tenantCode
                status
                userId
                createdAt
                updatedAt
                user {
                  id
                  name
                  role
                  extensionConfigJsonList
                  email
                  code
                  tenantCode
                  displaySettings {
                    theme
                    color
                  }
                  locale
                  avatar
                  enabled
                  signedInAtLeastOnce
                  emailConfirmed
                  confirmedTermsOfServiceVersions {
                    termsOfServiceVersion
                    privacyPolicyVersion
                    tenantOwnTermsOfServiceVersion
                  }
                  createdAt
                  updatedAt
                  tags {
                    nextToken
                  }
                  statuses {
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const createTenant = /* GraphQL */ `
  mutation CreateTenant(
    $input: CreateTenantInput!
    $condition: ModelTenantConditionInput
  ) {
    createTenant(input: $input, condition: $condition) {
      id
      code
      name
      signUpType
      playground
      userLimit
      limitations
      createdAt
      updatedAt
    }
  }
`;
export const createTenantOwnTermsOfService = /* GraphQL */ `
  mutation CreateTenantOwnTermsOfService(
    $input: CreateTenantOwnTermsOfServiceInput!
    $condition: ModelTenantOwnTermsOfServiceConditionInput
  ) {
    createTenantOwnTermsOfService(input: $input, condition: $condition) {
      id
      tenantCode
      type
      body
      createdBy
      createdAt
      updatedBy
      updatedAt
      version
    }
  }
`;
export const createUserTag = /* GraphQL */ `
  mutation CreateUserTag(
    $input: CreateUserTagInput!
    $condition: ModelUserTagConditionInput
  ) {
    createUserTag(input: $input, condition: $condition) {
      id
      color
      text
      tenantCode
      createdAt
      updatedAt
      users {
        items {
          id
          userId
          userTagId
          tenantCode
          createdAt
          updatedAt
          user {
            id
            name
            role
            extensionConfigJsonList
            email
            code
            tenantCode
            displaySettings {
              theme
              color
            }
            locale
            avatar
            enabled
            signedInAtLeastOnce
            emailConfirmed
            confirmedTermsOfServiceVersions {
              termsOfServiceVersion
              privacyPolicyVersion
              tenantOwnTermsOfServiceVersion
            }
            createdAt
            updatedAt
            tags {
              items {
                id
                userId
                userTagId
                tenantCode
                createdAt
                updatedAt
                user {
                  id
                  name
                  role
                  extensionConfigJsonList
                  email
                  code
                  tenantCode
                  displaySettings {
                    theme
                    color
                  }
                  locale
                  avatar
                  enabled
                  signedInAtLeastOnce
                  emailConfirmed
                  confirmedTermsOfServiceVersions {
                    termsOfServiceVersion
                    privacyPolicyVersion
                    tenantOwnTermsOfServiceVersion
                  }
                  createdAt
                  updatedAt
                  tags {
                    nextToken
                  }
                  statuses {
                    nextToken
                  }
                }
                userTag {
                  id
                  color
                  text
                  tenantCode
                  createdAt
                  updatedAt
                  users {
                    nextToken
                  }
                }
              }
              nextToken
            }
            statuses {
              items {
                id
                tenantCode
                status
                userId
                createdAt
                updatedAt
                user {
                  id
                  name
                  role
                  extensionConfigJsonList
                  email
                  code
                  tenantCode
                  displaySettings {
                    theme
                    color
                  }
                  locale
                  avatar
                  enabled
                  signedInAtLeastOnce
                  emailConfirmed
                  confirmedTermsOfServiceVersions {
                    termsOfServiceVersion
                    privacyPolicyVersion
                    tenantOwnTermsOfServiceVersion
                  }
                  createdAt
                  updatedAt
                  tags {
                    nextToken
                  }
                  statuses {
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
          userTag {
            id
            color
            text
            tenantCode
            createdAt
            updatedAt
            users {
              items {
                id
                userId
                userTagId
                tenantCode
                createdAt
                updatedAt
                user {
                  id
                  name
                  role
                  extensionConfigJsonList
                  email
                  code
                  tenantCode
                  displaySettings {
                    theme
                    color
                  }
                  locale
                  avatar
                  enabled
                  signedInAtLeastOnce
                  emailConfirmed
                  confirmedTermsOfServiceVersions {
                    termsOfServiceVersion
                    privacyPolicyVersion
                    tenantOwnTermsOfServiceVersion
                  }
                  createdAt
                  updatedAt
                  tags {
                    nextToken
                  }
                  statuses {
                    nextToken
                  }
                }
                userTag {
                  id
                  color
                  text
                  tenantCode
                  createdAt
                  updatedAt
                  users {
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const updateUserTag = /* GraphQL */ `
  mutation UpdateUserTag(
    $input: UpdateUserTagInput!
    $condition: ModelUserTagConditionInput
  ) {
    updateUserTag(input: $input, condition: $condition) {
      id
      color
      text
      tenantCode
      createdAt
      updatedAt
      users {
        items {
          id
          userId
          userTagId
          tenantCode
          createdAt
          updatedAt
          user {
            id
            name
            role
            extensionConfigJsonList
            email
            code
            tenantCode
            displaySettings {
              theme
              color
            }
            locale
            avatar
            enabled
            signedInAtLeastOnce
            emailConfirmed
            confirmedTermsOfServiceVersions {
              termsOfServiceVersion
              privacyPolicyVersion
              tenantOwnTermsOfServiceVersion
            }
            createdAt
            updatedAt
            tags {
              items {
                id
                userId
                userTagId
                tenantCode
                createdAt
                updatedAt
                user {
                  id
                  name
                  role
                  extensionConfigJsonList
                  email
                  code
                  tenantCode
                  displaySettings {
                    theme
                    color
                  }
                  locale
                  avatar
                  enabled
                  signedInAtLeastOnce
                  emailConfirmed
                  confirmedTermsOfServiceVersions {
                    termsOfServiceVersion
                    privacyPolicyVersion
                    tenantOwnTermsOfServiceVersion
                  }
                  createdAt
                  updatedAt
                  tags {
                    nextToken
                  }
                  statuses {
                    nextToken
                  }
                }
                userTag {
                  id
                  color
                  text
                  tenantCode
                  createdAt
                  updatedAt
                  users {
                    nextToken
                  }
                }
              }
              nextToken
            }
            statuses {
              items {
                id
                tenantCode
                status
                userId
                createdAt
                updatedAt
                user {
                  id
                  name
                  role
                  extensionConfigJsonList
                  email
                  code
                  tenantCode
                  displaySettings {
                    theme
                    color
                  }
                  locale
                  avatar
                  enabled
                  signedInAtLeastOnce
                  emailConfirmed
                  confirmedTermsOfServiceVersions {
                    termsOfServiceVersion
                    privacyPolicyVersion
                    tenantOwnTermsOfServiceVersion
                  }
                  createdAt
                  updatedAt
                  tags {
                    nextToken
                  }
                  statuses {
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
          userTag {
            id
            color
            text
            tenantCode
            createdAt
            updatedAt
            users {
              items {
                id
                userId
                userTagId
                tenantCode
                createdAt
                updatedAt
                user {
                  id
                  name
                  role
                  extensionConfigJsonList
                  email
                  code
                  tenantCode
                  displaySettings {
                    theme
                    color
                  }
                  locale
                  avatar
                  enabled
                  signedInAtLeastOnce
                  emailConfirmed
                  confirmedTermsOfServiceVersions {
                    termsOfServiceVersion
                    privacyPolicyVersion
                    tenantOwnTermsOfServiceVersion
                  }
                  createdAt
                  updatedAt
                  tags {
                    nextToken
                  }
                  statuses {
                    nextToken
                  }
                }
                userTag {
                  id
                  color
                  text
                  tenantCode
                  createdAt
                  updatedAt
                  users {
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const deleteUserTag = /* GraphQL */ `
  mutation DeleteUserTag(
    $input: DeleteUserTagInput!
    $condition: ModelUserTagConditionInput
  ) {
    deleteUserTag(input: $input, condition: $condition) {
      id
      color
      text
      tenantCode
      createdAt
      updatedAt
      users {
        items {
          id
          userId
          userTagId
          tenantCode
          createdAt
          updatedAt
          user {
            id
            name
            role
            extensionConfigJsonList
            email
            code
            tenantCode
            displaySettings {
              theme
              color
            }
            locale
            avatar
            enabled
            signedInAtLeastOnce
            emailConfirmed
            confirmedTermsOfServiceVersions {
              termsOfServiceVersion
              privacyPolicyVersion
              tenantOwnTermsOfServiceVersion
            }
            createdAt
            updatedAt
            tags {
              items {
                id
                userId
                userTagId
                tenantCode
                createdAt
                updatedAt
                user {
                  id
                  name
                  role
                  extensionConfigJsonList
                  email
                  code
                  tenantCode
                  displaySettings {
                    theme
                    color
                  }
                  locale
                  avatar
                  enabled
                  signedInAtLeastOnce
                  emailConfirmed
                  confirmedTermsOfServiceVersions {
                    termsOfServiceVersion
                    privacyPolicyVersion
                    tenantOwnTermsOfServiceVersion
                  }
                  createdAt
                  updatedAt
                  tags {
                    nextToken
                  }
                  statuses {
                    nextToken
                  }
                }
                userTag {
                  id
                  color
                  text
                  tenantCode
                  createdAt
                  updatedAt
                  users {
                    nextToken
                  }
                }
              }
              nextToken
            }
            statuses {
              items {
                id
                tenantCode
                status
                userId
                createdAt
                updatedAt
                user {
                  id
                  name
                  role
                  extensionConfigJsonList
                  email
                  code
                  tenantCode
                  displaySettings {
                    theme
                    color
                  }
                  locale
                  avatar
                  enabled
                  signedInAtLeastOnce
                  emailConfirmed
                  confirmedTermsOfServiceVersions {
                    termsOfServiceVersion
                    privacyPolicyVersion
                    tenantOwnTermsOfServiceVersion
                  }
                  createdAt
                  updatedAt
                  tags {
                    nextToken
                  }
                  statuses {
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
          userTag {
            id
            color
            text
            tenantCode
            createdAt
            updatedAt
            users {
              items {
                id
                userId
                userTagId
                tenantCode
                createdAt
                updatedAt
                user {
                  id
                  name
                  role
                  extensionConfigJsonList
                  email
                  code
                  tenantCode
                  displaySettings {
                    theme
                    color
                  }
                  locale
                  avatar
                  enabled
                  signedInAtLeastOnce
                  emailConfirmed
                  confirmedTermsOfServiceVersions {
                    termsOfServiceVersion
                    privacyPolicyVersion
                    tenantOwnTermsOfServiceVersion
                  }
                  createdAt
                  updatedAt
                  tags {
                    nextToken
                  }
                  statuses {
                    nextToken
                  }
                }
                userTag {
                  id
                  color
                  text
                  tenantCode
                  createdAt
                  updatedAt
                  users {
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const createUserUserTag = /* GraphQL */ `
  mutation CreateUserUserTag(
    $input: CreateUserUserTagInput!
    $condition: ModelUserUserTagConditionInput
  ) {
    createUserUserTag(input: $input, condition: $condition) {
      id
      userId
      userTagId
      tenantCode
      createdAt
      updatedAt
      user {
        id
        name
        role
        extensionConfigJsonList
        email
        code
        tenantCode
        displaySettings {
          theme
          color
        }
        locale
        avatar
        enabled
        signedInAtLeastOnce
        emailConfirmed
        confirmedTermsOfServiceVersions {
          termsOfServiceVersion
          privacyPolicyVersion
          tenantOwnTermsOfServiceVersion
        }
        createdAt
        updatedAt
        tags {
          items {
            id
            userId
            userTagId
            tenantCode
            createdAt
            updatedAt
            user {
              id
              name
              role
              extensionConfigJsonList
              email
              code
              tenantCode
              displaySettings {
                theme
                color
              }
              locale
              avatar
              enabled
              signedInAtLeastOnce
              emailConfirmed
              confirmedTermsOfServiceVersions {
                termsOfServiceVersion
                privacyPolicyVersion
                tenantOwnTermsOfServiceVersion
              }
              createdAt
              updatedAt
              tags {
                items {
                  id
                  userId
                  userTagId
                  tenantCode
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                  userTag {
                    id
                    color
                    text
                    tenantCode
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
              statuses {
                items {
                  id
                  tenantCode
                  status
                  userId
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
            userTag {
              id
              color
              text
              tenantCode
              createdAt
              updatedAt
              users {
                items {
                  id
                  userId
                  userTagId
                  tenantCode
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                  userTag {
                    id
                    color
                    text
                    tenantCode
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
          }
          nextToken
        }
        statuses {
          items {
            id
            tenantCode
            status
            userId
            createdAt
            updatedAt
            user {
              id
              name
              role
              extensionConfigJsonList
              email
              code
              tenantCode
              displaySettings {
                theme
                color
              }
              locale
              avatar
              enabled
              signedInAtLeastOnce
              emailConfirmed
              confirmedTermsOfServiceVersions {
                termsOfServiceVersion
                privacyPolicyVersion
                tenantOwnTermsOfServiceVersion
              }
              createdAt
              updatedAt
              tags {
                items {
                  id
                  userId
                  userTagId
                  tenantCode
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                  userTag {
                    id
                    color
                    text
                    tenantCode
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
              statuses {
                items {
                  id
                  tenantCode
                  status
                  userId
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }
      userTag {
        id
        color
        text
        tenantCode
        createdAt
        updatedAt
        users {
          items {
            id
            userId
            userTagId
            tenantCode
            createdAt
            updatedAt
            user {
              id
              name
              role
              extensionConfigJsonList
              email
              code
              tenantCode
              displaySettings {
                theme
                color
              }
              locale
              avatar
              enabled
              signedInAtLeastOnce
              emailConfirmed
              confirmedTermsOfServiceVersions {
                termsOfServiceVersion
                privacyPolicyVersion
                tenantOwnTermsOfServiceVersion
              }
              createdAt
              updatedAt
              tags {
                items {
                  id
                  userId
                  userTagId
                  tenantCode
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                  userTag {
                    id
                    color
                    text
                    tenantCode
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
              statuses {
                items {
                  id
                  tenantCode
                  status
                  userId
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
            userTag {
              id
              color
              text
              tenantCode
              createdAt
              updatedAt
              users {
                items {
                  id
                  userId
                  userTagId
                  tenantCode
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                  userTag {
                    id
                    color
                    text
                    tenantCode
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const updateUserUserTag = /* GraphQL */ `
  mutation UpdateUserUserTag(
    $input: UpdateUserUserTagInput!
    $condition: ModelUserUserTagConditionInput
  ) {
    updateUserUserTag(input: $input, condition: $condition) {
      id
      userId
      userTagId
      tenantCode
      createdAt
      updatedAt
      user {
        id
        name
        role
        extensionConfigJsonList
        email
        code
        tenantCode
        displaySettings {
          theme
          color
        }
        locale
        avatar
        enabled
        signedInAtLeastOnce
        emailConfirmed
        confirmedTermsOfServiceVersions {
          termsOfServiceVersion
          privacyPolicyVersion
          tenantOwnTermsOfServiceVersion
        }
        createdAt
        updatedAt
        tags {
          items {
            id
            userId
            userTagId
            tenantCode
            createdAt
            updatedAt
            user {
              id
              name
              role
              extensionConfigJsonList
              email
              code
              tenantCode
              displaySettings {
                theme
                color
              }
              locale
              avatar
              enabled
              signedInAtLeastOnce
              emailConfirmed
              confirmedTermsOfServiceVersions {
                termsOfServiceVersion
                privacyPolicyVersion
                tenantOwnTermsOfServiceVersion
              }
              createdAt
              updatedAt
              tags {
                items {
                  id
                  userId
                  userTagId
                  tenantCode
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                  userTag {
                    id
                    color
                    text
                    tenantCode
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
              statuses {
                items {
                  id
                  tenantCode
                  status
                  userId
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
            userTag {
              id
              color
              text
              tenantCode
              createdAt
              updatedAt
              users {
                items {
                  id
                  userId
                  userTagId
                  tenantCode
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                  userTag {
                    id
                    color
                    text
                    tenantCode
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
          }
          nextToken
        }
        statuses {
          items {
            id
            tenantCode
            status
            userId
            createdAt
            updatedAt
            user {
              id
              name
              role
              extensionConfigJsonList
              email
              code
              tenantCode
              displaySettings {
                theme
                color
              }
              locale
              avatar
              enabled
              signedInAtLeastOnce
              emailConfirmed
              confirmedTermsOfServiceVersions {
                termsOfServiceVersion
                privacyPolicyVersion
                tenantOwnTermsOfServiceVersion
              }
              createdAt
              updatedAt
              tags {
                items {
                  id
                  userId
                  userTagId
                  tenantCode
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                  userTag {
                    id
                    color
                    text
                    tenantCode
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
              statuses {
                items {
                  id
                  tenantCode
                  status
                  userId
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }
      userTag {
        id
        color
        text
        tenantCode
        createdAt
        updatedAt
        users {
          items {
            id
            userId
            userTagId
            tenantCode
            createdAt
            updatedAt
            user {
              id
              name
              role
              extensionConfigJsonList
              email
              code
              tenantCode
              displaySettings {
                theme
                color
              }
              locale
              avatar
              enabled
              signedInAtLeastOnce
              emailConfirmed
              confirmedTermsOfServiceVersions {
                termsOfServiceVersion
                privacyPolicyVersion
                tenantOwnTermsOfServiceVersion
              }
              createdAt
              updatedAt
              tags {
                items {
                  id
                  userId
                  userTagId
                  tenantCode
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                  userTag {
                    id
                    color
                    text
                    tenantCode
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
              statuses {
                items {
                  id
                  tenantCode
                  status
                  userId
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
            userTag {
              id
              color
              text
              tenantCode
              createdAt
              updatedAt
              users {
                items {
                  id
                  userId
                  userTagId
                  tenantCode
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                  userTag {
                    id
                    color
                    text
                    tenantCode
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteUserUserTag = /* GraphQL */ `
  mutation DeleteUserUserTag(
    $input: DeleteUserUserTagInput!
    $condition: ModelUserUserTagConditionInput
  ) {
    deleteUserUserTag(input: $input, condition: $condition) {
      id
      userId
      userTagId
      tenantCode
      createdAt
      updatedAt
      user {
        id
        name
        role
        extensionConfigJsonList
        email
        code
        tenantCode
        displaySettings {
          theme
          color
        }
        locale
        avatar
        enabled
        signedInAtLeastOnce
        emailConfirmed
        confirmedTermsOfServiceVersions {
          termsOfServiceVersion
          privacyPolicyVersion
          tenantOwnTermsOfServiceVersion
        }
        createdAt
        updatedAt
        tags {
          items {
            id
            userId
            userTagId
            tenantCode
            createdAt
            updatedAt
            user {
              id
              name
              role
              extensionConfigJsonList
              email
              code
              tenantCode
              displaySettings {
                theme
                color
              }
              locale
              avatar
              enabled
              signedInAtLeastOnce
              emailConfirmed
              confirmedTermsOfServiceVersions {
                termsOfServiceVersion
                privacyPolicyVersion
                tenantOwnTermsOfServiceVersion
              }
              createdAt
              updatedAt
              tags {
                items {
                  id
                  userId
                  userTagId
                  tenantCode
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                  userTag {
                    id
                    color
                    text
                    tenantCode
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
              statuses {
                items {
                  id
                  tenantCode
                  status
                  userId
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
            userTag {
              id
              color
              text
              tenantCode
              createdAt
              updatedAt
              users {
                items {
                  id
                  userId
                  userTagId
                  tenantCode
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                  userTag {
                    id
                    color
                    text
                    tenantCode
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
          }
          nextToken
        }
        statuses {
          items {
            id
            tenantCode
            status
            userId
            createdAt
            updatedAt
            user {
              id
              name
              role
              extensionConfigJsonList
              email
              code
              tenantCode
              displaySettings {
                theme
                color
              }
              locale
              avatar
              enabled
              signedInAtLeastOnce
              emailConfirmed
              confirmedTermsOfServiceVersions {
                termsOfServiceVersion
                privacyPolicyVersion
                tenantOwnTermsOfServiceVersion
              }
              createdAt
              updatedAt
              tags {
                items {
                  id
                  userId
                  userTagId
                  tenantCode
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                  userTag {
                    id
                    color
                    text
                    tenantCode
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
              statuses {
                items {
                  id
                  tenantCode
                  status
                  userId
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }
      userTag {
        id
        color
        text
        tenantCode
        createdAt
        updatedAt
        users {
          items {
            id
            userId
            userTagId
            tenantCode
            createdAt
            updatedAt
            user {
              id
              name
              role
              extensionConfigJsonList
              email
              code
              tenantCode
              displaySettings {
                theme
                color
              }
              locale
              avatar
              enabled
              signedInAtLeastOnce
              emailConfirmed
              confirmedTermsOfServiceVersions {
                termsOfServiceVersion
                privacyPolicyVersion
                tenantOwnTermsOfServiceVersion
              }
              createdAt
              updatedAt
              tags {
                items {
                  id
                  userId
                  userTagId
                  tenantCode
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                  userTag {
                    id
                    color
                    text
                    tenantCode
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
              statuses {
                items {
                  id
                  tenantCode
                  status
                  userId
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
            userTag {
              id
              color
              text
              tenantCode
              createdAt
              updatedAt
              users {
                items {
                  id
                  userId
                  userTagId
                  tenantCode
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                  userTag {
                    id
                    color
                    text
                    tenantCode
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const createUserStatusTable = /* GraphQL */ `
  mutation CreateUserStatusTable(
    $input: CreateUserStatusTableInput!
    $condition: ModelUserStatusTableConditionInput
  ) {
    createUserStatusTable(input: $input, condition: $condition) {
      id
      tenantCode
      status
      userId
      createdAt
      updatedAt
      user {
        id
        name
        role
        extensionConfigJsonList
        email
        code
        tenantCode
        displaySettings {
          theme
          color
        }
        locale
        avatar
        enabled
        signedInAtLeastOnce
        emailConfirmed
        confirmedTermsOfServiceVersions {
          termsOfServiceVersion
          privacyPolicyVersion
          tenantOwnTermsOfServiceVersion
        }
        createdAt
        updatedAt
        tags {
          items {
            id
            userId
            userTagId
            tenantCode
            createdAt
            updatedAt
            user {
              id
              name
              role
              extensionConfigJsonList
              email
              code
              tenantCode
              displaySettings {
                theme
                color
              }
              locale
              avatar
              enabled
              signedInAtLeastOnce
              emailConfirmed
              confirmedTermsOfServiceVersions {
                termsOfServiceVersion
                privacyPolicyVersion
                tenantOwnTermsOfServiceVersion
              }
              createdAt
              updatedAt
              tags {
                items {
                  id
                  userId
                  userTagId
                  tenantCode
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                  userTag {
                    id
                    color
                    text
                    tenantCode
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
              statuses {
                items {
                  id
                  tenantCode
                  status
                  userId
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
            userTag {
              id
              color
              text
              tenantCode
              createdAt
              updatedAt
              users {
                items {
                  id
                  userId
                  userTagId
                  tenantCode
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                  userTag {
                    id
                    color
                    text
                    tenantCode
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
          }
          nextToken
        }
        statuses {
          items {
            id
            tenantCode
            status
            userId
            createdAt
            updatedAt
            user {
              id
              name
              role
              extensionConfigJsonList
              email
              code
              tenantCode
              displaySettings {
                theme
                color
              }
              locale
              avatar
              enabled
              signedInAtLeastOnce
              emailConfirmed
              confirmedTermsOfServiceVersions {
                termsOfServiceVersion
                privacyPolicyVersion
                tenantOwnTermsOfServiceVersion
              }
              createdAt
              updatedAt
              tags {
                items {
                  id
                  userId
                  userTagId
                  tenantCode
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                  userTag {
                    id
                    color
                    text
                    tenantCode
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
              statuses {
                items {
                  id
                  tenantCode
                  status
                  userId
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const updateUserStatusTable = /* GraphQL */ `
  mutation UpdateUserStatusTable(
    $input: UpdateUserStatusTableInput!
    $condition: ModelUserStatusTableConditionInput
  ) {
    updateUserStatusTable(input: $input, condition: $condition) {
      id
      tenantCode
      status
      userId
      createdAt
      updatedAt
      user {
        id
        name
        role
        extensionConfigJsonList
        email
        code
        tenantCode
        displaySettings {
          theme
          color
        }
        locale
        avatar
        enabled
        signedInAtLeastOnce
        emailConfirmed
        confirmedTermsOfServiceVersions {
          termsOfServiceVersion
          privacyPolicyVersion
          tenantOwnTermsOfServiceVersion
        }
        createdAt
        updatedAt
        tags {
          items {
            id
            userId
            userTagId
            tenantCode
            createdAt
            updatedAt
            user {
              id
              name
              role
              extensionConfigJsonList
              email
              code
              tenantCode
              displaySettings {
                theme
                color
              }
              locale
              avatar
              enabled
              signedInAtLeastOnce
              emailConfirmed
              confirmedTermsOfServiceVersions {
                termsOfServiceVersion
                privacyPolicyVersion
                tenantOwnTermsOfServiceVersion
              }
              createdAt
              updatedAt
              tags {
                items {
                  id
                  userId
                  userTagId
                  tenantCode
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                  userTag {
                    id
                    color
                    text
                    tenantCode
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
              statuses {
                items {
                  id
                  tenantCode
                  status
                  userId
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
            userTag {
              id
              color
              text
              tenantCode
              createdAt
              updatedAt
              users {
                items {
                  id
                  userId
                  userTagId
                  tenantCode
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                  userTag {
                    id
                    color
                    text
                    tenantCode
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
          }
          nextToken
        }
        statuses {
          items {
            id
            tenantCode
            status
            userId
            createdAt
            updatedAt
            user {
              id
              name
              role
              extensionConfigJsonList
              email
              code
              tenantCode
              displaySettings {
                theme
                color
              }
              locale
              avatar
              enabled
              signedInAtLeastOnce
              emailConfirmed
              confirmedTermsOfServiceVersions {
                termsOfServiceVersion
                privacyPolicyVersion
                tenantOwnTermsOfServiceVersion
              }
              createdAt
              updatedAt
              tags {
                items {
                  id
                  userId
                  userTagId
                  tenantCode
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                  userTag {
                    id
                    color
                    text
                    tenantCode
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
              statuses {
                items {
                  id
                  tenantCode
                  status
                  userId
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteUserStatusTable = /* GraphQL */ `
  mutation DeleteUserStatusTable(
    $input: DeleteUserStatusTableInput!
    $condition: ModelUserStatusTableConditionInput
  ) {
    deleteUserStatusTable(input: $input, condition: $condition) {
      id
      tenantCode
      status
      userId
      createdAt
      updatedAt
      user {
        id
        name
        role
        extensionConfigJsonList
        email
        code
        tenantCode
        displaySettings {
          theme
          color
        }
        locale
        avatar
        enabled
        signedInAtLeastOnce
        emailConfirmed
        confirmedTermsOfServiceVersions {
          termsOfServiceVersion
          privacyPolicyVersion
          tenantOwnTermsOfServiceVersion
        }
        createdAt
        updatedAt
        tags {
          items {
            id
            userId
            userTagId
            tenantCode
            createdAt
            updatedAt
            user {
              id
              name
              role
              extensionConfigJsonList
              email
              code
              tenantCode
              displaySettings {
                theme
                color
              }
              locale
              avatar
              enabled
              signedInAtLeastOnce
              emailConfirmed
              confirmedTermsOfServiceVersions {
                termsOfServiceVersion
                privacyPolicyVersion
                tenantOwnTermsOfServiceVersion
              }
              createdAt
              updatedAt
              tags {
                items {
                  id
                  userId
                  userTagId
                  tenantCode
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                  userTag {
                    id
                    color
                    text
                    tenantCode
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
              statuses {
                items {
                  id
                  tenantCode
                  status
                  userId
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
            userTag {
              id
              color
              text
              tenantCode
              createdAt
              updatedAt
              users {
                items {
                  id
                  userId
                  userTagId
                  tenantCode
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                  userTag {
                    id
                    color
                    text
                    tenantCode
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
          }
          nextToken
        }
        statuses {
          items {
            id
            tenantCode
            status
            userId
            createdAt
            updatedAt
            user {
              id
              name
              role
              extensionConfigJsonList
              email
              code
              tenantCode
              displaySettings {
                theme
                color
              }
              locale
              avatar
              enabled
              signedInAtLeastOnce
              emailConfirmed
              confirmedTermsOfServiceVersions {
                termsOfServiceVersion
                privacyPolicyVersion
                tenantOwnTermsOfServiceVersion
              }
              createdAt
              updatedAt
              tags {
                items {
                  id
                  userId
                  userTagId
                  tenantCode
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                  userTag {
                    id
                    color
                    text
                    tenantCode
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
              statuses {
                items {
                  id
                  tenantCode
                  status
                  userId
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const updateGroupStatusUser = /* GraphQL */ `
  mutation UpdateGroupStatusUser(
    $input: UpdateGroupStatusUserInput!
    $condition: ModelGroupStatusUserConditionInput
  ) {
    updateGroupStatusUser(input: $input, condition: $condition) {
      id
      userId
      groupId
      handStatus
      tenantCode
      createdAt
      updatedAt
    }
  }
`;
export const deleteGroupStatusUser = /* GraphQL */ `
  mutation DeleteGroupStatusUser(
    $input: DeleteGroupStatusUserInput!
    $condition: ModelGroupStatusUserConditionInput
  ) {
    deleteGroupStatusUser(input: $input, condition: $condition) {
      id
      userId
      groupId
      handStatus
      tenantCode
      createdAt
      updatedAt
    }
  }
`;
export const createInternalTask = /* GraphQL */ `
  mutation CreateInternalTask(
    $input: CreateInternalTaskInput!
    $condition: ModelInternalTaskConditionInput
  ) {
    createInternalTask(input: $input, condition: $condition) {
      id
      type
      createdAt
      createdBy
      finishedAt
      status
      taskVersion
      payload
      tenantCode
      updatedAt
    }
  }
`;
export const updateInternalTask = /* GraphQL */ `
  mutation UpdateInternalTask(
    $input: UpdateInternalTaskInput!
    $condition: ModelInternalTaskConditionInput
  ) {
    updateInternalTask(input: $input, condition: $condition) {
      id
      type
      createdAt
      createdBy
      finishedAt
      status
      taskVersion
      payload
      tenantCode
      updatedAt
    }
  }
`;
export const deleteInternalTask = /* GraphQL */ `
  mutation DeleteInternalTask(
    $input: DeleteInternalTaskInput!
    $condition: ModelInternalTaskConditionInput
  ) {
    deleteInternalTask(input: $input, condition: $condition) {
      id
      type
      createdAt
      createdBy
      finishedAt
      status
      taskVersion
      payload
      tenantCode
      updatedAt
    }
  }
`;
export const createPlayground = /* GraphQL */ `
  mutation CreatePlayground(
    $input: CreatePlaygroundInput!
    $condition: ModelPlaygroundConditionInput
  ) {
    createPlayground(input: $input, condition: $condition) {
      id
      status
      email
      tenantCode
      tenantName
      userCode
      temporaryPassword
      createdAt
      updatedAt
    }
  }
`;
export const updatePlayground = /* GraphQL */ `
  mutation UpdatePlayground(
    $input: UpdatePlaygroundInput!
    $condition: ModelPlaygroundConditionInput
  ) {
    updatePlayground(input: $input, condition: $condition) {
      id
      status
      email
      tenantCode
      tenantName
      userCode
      temporaryPassword
      createdAt
      updatedAt
    }
  }
`;
export const deletePlayground = /* GraphQL */ `
  mutation DeletePlayground(
    $input: DeletePlaygroundInput!
    $condition: ModelPlaygroundConditionInput
  ) {
    deletePlayground(input: $input, condition: $condition) {
      id
      status
      email
      tenantCode
      tenantName
      userCode
      temporaryPassword
      createdAt
      updatedAt
    }
  }
`;
export const createContent = /* GraphQL */ `
  mutation CreateContent(
    $input: CreateContentInput!
    $condition: ModelContentConditionInput
  ) {
    createContent(input: $input, condition: $condition) {
      id
      contentId
      version
      name
      requiredTime
      body {
        type
        text {
          body
        }
        exam {
          problems {
            index
            body
            commentary
            type
            choice {
              options {
                index
                text
              }
              multiple
              answer
            }
            headerId
          }
          problemHeaders {
            id
            body
            createdAt
          }
          passingStandard
        }
      }
      workbook {
        problems {
          index
          body
          commentary
          type
          choice {
            options {
              index
              text
            }
            multiple
            answer
          }
          headerId
        }
        problemHeaders {
          id
          body
          createdAt
        }
      }
      courseId
      courseVersion
      tenantCode
      latest
      versionDescription
      createdAt
      updatedAt
    }
  }
`;
export const createCourse = /* GraphQL */ `
  mutation CreateCourse(
    $input: CreateCourseInput!
    $condition: ModelCourseConditionInput
  ) {
    createCourse(input: $input, condition: $condition) {
      id
      version
      name
      contents {
        id
        type
        name
        requiredTime
      }
      description
      tenantCode
      color
      image
      fontColorOnImage
      confirmedBy
      createdAt
      versionCreatedBy
      versionCreatedAt
      contentLastUpdatedBy
      contentLastUpdatedAt
      updatedAt
    }
  }
`;
export const deleteEditingCourse = /* GraphQL */ `
  mutation DeleteEditingCourse(
    $input: DeleteEditingCourseInput!
    $condition: ModelEditingCourseConditionInput
  ) {
    deleteEditingCourse(input: $input, condition: $condition) {
      id
      tenantCode
      name
      version
      status
      description
      contents {
        id
        name
        requiredTime
        type
      }
      color
      image
      fontColorOnImage
      createdBy
      createdAt
      updatedBy
      updatedAt
    }
  }
`;
export const createEditingCourseContentWorkbook = /* GraphQL */ `
  mutation CreateEditingCourseContentWorkbook(
    $input: CreateEditingCourseContentWorkbookInput!
    $condition: ModelEditingCourseContentWorkbookConditionInput
  ) {
    createEditingCourseContentWorkbook(input: $input, condition: $condition) {
      contentId
      problems {
        index
        body
        commentary
        type
        choice {
          options {
            index
            text
          }
          multiple
          answer
        }
        headerId
      }
      problemHeaders {
        id
        body
        createdAt
      }
      dataVersion
      createdBy
      updatedBy
      createdAt
      updatedAt
      tenantCode
      courseId
    }
  }
`;
export const deleteEditingCourseContentWorkbook = /* GraphQL */ `
  mutation DeleteEditingCourseContentWorkbook(
    $input: DeleteEditingCourseContentWorkbookInput!
    $condition: ModelEditingCourseContentWorkbookConditionInput
  ) {
    deleteEditingCourseContentWorkbook(input: $input, condition: $condition) {
      contentId
      problems {
        index
        body
        commentary
        type
        choice {
          options {
            index
            text
          }
          multiple
          answer
        }
        headerId
      }
      problemHeaders {
        id
        body
        createdAt
      }
      dataVersion
      createdBy
      updatedBy
      createdAt
      updatedAt
      tenantCode
      courseId
    }
  }
`;
export const createEditingCourseContentBody = /* GraphQL */ `
  mutation CreateEditingCourseContentBody(
    $input: CreateEditingCourseContentBodyInput!
    $condition: ModelEditingCourseContentBodyConditionInput
  ) {
    createEditingCourseContentBody(input: $input, condition: $condition) {
      contentId
      body {
        type
        text {
          body
        }
        exam {
          problems {
            index
            body
            commentary
            type
            choice {
              options {
                index
                text
              }
              multiple
              answer
            }
            headerId
          }
          problemHeaders {
            id
            body
            createdAt
          }
          passingStandard
        }
      }
      dataVersion
      createdBy
      updatedBy
      createdAt
      updatedAt
      tenantCode
      courseId
    }
  }
`;
export const deleteEditingCourseContentBody = /* GraphQL */ `
  mutation DeleteEditingCourseContentBody(
    $input: DeleteEditingCourseContentBodyInput!
    $condition: ModelEditingCourseContentBodyConditionInput
  ) {
    deleteEditingCourseContentBody(input: $input, condition: $condition) {
      contentId
      body {
        type
        text {
          body
        }
        exam {
          problems {
            index
            body
            commentary
            type
            choice {
              options {
                index
                text
              }
              multiple
              answer
            }
            headerId
          }
          problemHeaders {
            id
            body
            createdAt
          }
          passingStandard
        }
      }
      dataVersion
      createdBy
      updatedBy
      createdAt
      updatedAt
      tenantCode
      courseId
    }
  }
`;
export const updateCourseHeader = /* GraphQL */ `
  mutation UpdateCourseHeader(
    $input: UpdateCourseHeaderInput!
    $condition: ModelCourseHeaderConditionInput
  ) {
    updateCourseHeader(input: $input, condition: $condition) {
      id
      name
      activeVersion
      status
      originalCourseId
      originalCourseVersion
      tenantCode
      createdBy
      createdAt
      updatedAt
    }
  }
`;
export const deleteCourseHeader = /* GraphQL */ `
  mutation DeleteCourseHeader(
    $input: DeleteCourseHeaderInput!
    $condition: ModelCourseHeaderConditionInput
  ) {
    deleteCourseHeader(input: $input, condition: $condition) {
      id
      name
      activeVersion
      status
      originalCourseId
      originalCourseVersion
      tenantCode
      createdBy
      createdAt
      updatedAt
    }
  }
`;
export const deleteEditingConfirmedContent = /* GraphQL */ `
  mutation DeleteEditingConfirmedContent(
    $input: DeleteEditingConfirmedContentInput!
    $condition: ModelEditingConfirmedContentConditionInput
  ) {
    deleteEditingConfirmedContent(input: $input, condition: $condition) {
      id
      version
      name
      requiredTime
      body {
        type
        text {
          body
        }
        exam {
          problems {
            index
            body
            commentary
            type
            choice {
              options {
                index
                text
              }
              multiple
              answer
            }
            headerId
          }
          problemHeaders {
            id
            body
            createdAt
          }
          passingStandard
        }
      }
      workbook {
        problems {
          index
          body
          commentary
          type
          choice {
            options {
              index
              text
            }
            multiple
            answer
          }
          headerId
        }
        problemHeaders {
          id
          body
          createdAt
        }
      }
      courseId
      courseVersion
      status
      tenantCode
      createdBy
      updatedBy
      dataVersion
      versionDescription
      createdAt
      updatedAt
    }
  }
`;
export const createEditingCourse = /* GraphQL */ `
  mutation CreateEditingCourse($input: CreateEditingCourseInput!) {
    createEditingCourse(input: $input) {
      id
      tenantCode
      name
      version
      status
      description
      contents {
        id
        name
        requiredTime
        type
      }
      color
      image
      fontColorOnImage
      createdBy
      createdAt
      updatedBy
      updatedAt
    }
  }
`;
export const createExamResult = /* GraphQL */ `
  mutation CreateExamResult(
    $input: CreateExamResultInput!
    $condition: ModelExamResultConditionInput
  ) {
    createExamResult(input: $input, condition: $condition) {
      id
      groupId
      courseId
      contentId
      contentVersion
      userId
      start
      end
      score
      problemCount
      tenantCode
      answers
      visibilityLevel
      groupExamId
      times
      passingStandard
      version
      createdAt
      updatedAt
    }
  }
`;
export const updateExamResult = /* GraphQL */ `
  mutation UpdateExamResult(
    $input: UpdateExamResultInput!
    $condition: ModelExamResultConditionInput
  ) {
    updateExamResult(input: $input, condition: $condition) {
      id
      groupId
      courseId
      contentId
      contentVersion
      userId
      start
      end
      score
      problemCount
      tenantCode
      answers
      visibilityLevel
      groupExamId
      times
      passingStandard
      version
      createdAt
      updatedAt
    }
  }
`;
export const deleteExamResult = /* GraphQL */ `
  mutation DeleteExamResult(
    $input: DeleteExamResultInput!
    $condition: ModelExamResultConditionInput
  ) {
    deleteExamResult(input: $input, condition: $condition) {
      id
      groupId
      courseId
      contentId
      contentVersion
      userId
      start
      end
      score
      problemCount
      tenantCode
      answers
      visibilityLevel
      groupExamId
      times
      passingStandard
      version
      createdAt
      updatedAt
    }
  }
`;
export const createGroupTraining = /* GraphQL */ `
  mutation CreateGroupTraining(
    $input: CreateGroupTrainingInput!
    $condition: ModelGroupTrainingConditionInput
  ) {
    createGroupTraining(input: $input, condition: $condition) {
      id
      groupId
      tenantCode
      createdAt
      updatedAt
    }
  }
`;
export const deleteScheduleScheduleTag = /* GraphQL */ `
  mutation DeleteScheduleScheduleTag(
    $input: DeleteScheduleScheduleTagInput!
    $condition: ModelScheduleScheduleTagConditionInput
  ) {
    deleteScheduleScheduleTag(input: $input, condition: $condition) {
      id
      scheduleId
      scheduleTagId
      scheduleTag {
        id
        color
        text
        groupId
        tenantCode
        createdAt
        updatedAt
        schedules {
          items {
            id
            scheduleId
            scheduleTagId
            scheduleTag {
              id
              color
              text
              groupId
              tenantCode
              createdAt
              updatedAt
              schedules {
                items {
                  id
                  scheduleId
                  scheduleTagId
                  scheduleTag {
                    id
                    color
                    text
                    groupId
                    tenantCode
                    createdAt
                    updatedAt
                  }
                  tenantCode
                  createdAt
                  updatedAt
                  schedule {
                    id
                    groupId
                    start
                    end
                    title
                    body
                    tenantCode
                    createdBy
                    updatedBy
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
            tenantCode
            createdAt
            updatedAt
            schedule {
              id
              groupId
              start
              end
              title
              body
              tenantCode
              createdBy
              updatedBy
              createdAt
              updatedAt
              tags {
                items {
                  id
                  scheduleId
                  scheduleTagId
                  scheduleTag {
                    id
                    color
                    text
                    groupId
                    tenantCode
                    createdAt
                    updatedAt
                  }
                  tenantCode
                  createdAt
                  updatedAt
                  schedule {
                    id
                    groupId
                    start
                    end
                    title
                    body
                    tenantCode
                    createdBy
                    updatedBy
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }
      tenantCode
      createdAt
      updatedAt
      schedule {
        id
        groupId
        start
        end
        title
        body
        tenantCode
        createdBy
        updatedBy
        createdAt
        updatedAt
        tags {
          items {
            id
            scheduleId
            scheduleTagId
            scheduleTag {
              id
              color
              text
              groupId
              tenantCode
              createdAt
              updatedAt
              schedules {
                items {
                  id
                  scheduleId
                  scheduleTagId
                  scheduleTag {
                    id
                    color
                    text
                    groupId
                    tenantCode
                    createdAt
                    updatedAt
                  }
                  tenantCode
                  createdAt
                  updatedAt
                  schedule {
                    id
                    groupId
                    start
                    end
                    title
                    body
                    tenantCode
                    createdBy
                    updatedBy
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
            tenantCode
            createdAt
            updatedAt
            schedule {
              id
              groupId
              start
              end
              title
              body
              tenantCode
              createdBy
              updatedBy
              createdAt
              updatedAt
              tags {
                items {
                  id
                  scheduleId
                  scheduleTagId
                  scheduleTag {
                    id
                    color
                    text
                    groupId
                    tenantCode
                    createdAt
                    updatedAt
                  }
                  tenantCode
                  createdAt
                  updatedAt
                  schedule {
                    id
                    groupId
                    start
                    end
                    title
                    body
                    tenantCode
                    createdBy
                    updatedBy
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const createContentLearning = /* GraphQL */ `
  mutation CreateContentLearning(
    $input: CreateContentLearningInput!
    $condition: ModelContentLearningConditionInput
  ) {
    createContentLearning(input: $input, condition: $condition) {
      groupId
      courseId
      contentId
      contentType
      userId
      status
      usageTime
      lastStartedAt
      dataVersion
      tenantCode
      completedAt
      completedUsageTime
      createdAt
      updatedAt
    }
  }
`;
export const updateContentLearning = /* GraphQL */ `
  mutation UpdateContentLearning(
    $input: UpdateContentLearningInput!
    $condition: ModelContentLearningConditionInput
  ) {
    updateContentLearning(input: $input, condition: $condition) {
      groupId
      courseId
      contentId
      contentType
      userId
      status
      usageTime
      lastStartedAt
      dataVersion
      tenantCode
      completedAt
      completedUsageTime
      createdAt
      updatedAt
    }
  }
`;
export const updateUserExam = /* GraphQL */ `
  mutation UpdateUserExam(
    $input: UpdateUserExamInput!
    $condition: ModelUserExamConditionInput
  ) {
    updateUserExam(input: $input, condition: $condition) {
      id
      userId
      status
      tenantCode
      groupExamId
      groupId
      startedAt
      finishedAt
      createdAt
      updatedAt
      groupExam {
        id
        groupId
        contentId
        content {
          id
          version
          name
          timeLimit
          requiredTime
          indexInCourse
          passingStandard
        }
        problemsJson
        courseId
        course {
          id
          version
          name
          color
          image
          fontColorOnImage
        }
        scheduledStart
        scheduledFinish
        createdBy
        finishedAt
        visibilityLevel
        tenantCode
        times
        groupTrainingCourseId
        problemHeaders {
          id
          body
          createdAt
        }
        timeLimit
        passingStandard
        userIdsToBeTested
        createdAt
        updatedAt
        userExams {
          items {
            id
            userId
            status
            tenantCode
            groupExamId
            groupId
            startedAt
            finishedAt
            createdAt
            updatedAt
            groupExam {
              id
              groupId
              contentId
              content {
                id
                version
                name
                timeLimit
                requiredTime
                indexInCourse
                passingStandard
              }
              problemsJson
              courseId
              course {
                id
                version
                name
                color
                image
                fontColorOnImage
              }
              scheduledStart
              scheduledFinish
              createdBy
              finishedAt
              visibilityLevel
              tenantCode
              times
              groupTrainingCourseId
              problemHeaders {
                id
                body
                createdAt
              }
              timeLimit
              passingStandard
              userIdsToBeTested
              createdAt
              updatedAt
              userExams {
                items {
                  id
                  userId
                  status
                  tenantCode
                  groupExamId
                  groupId
                  startedAt
                  finishedAt
                  createdAt
                  updatedAt
                  groupExam {
                    id
                    groupId
                    contentId
                    problemsJson
                    courseId
                    scheduledStart
                    scheduledFinish
                    createdBy
                    finishedAt
                    visibilityLevel
                    tenantCode
                    times
                    groupTrainingCourseId
                    timeLimit
                    passingStandard
                    userIdsToBeTested
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }
    }
  }
`;
