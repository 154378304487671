import * as customMutations from '@/amplify/customMutations';
import * as customQueries from '@/amplify/customQueries';
import { CreateScheduleTagInput, UpdateScheduleTagInput } from '@/API';
import {
  AppContextProvider,
  EntityData,
  GroupId,
  hasId,
  ScheduleColor,
  ScheduleTagId,
} from '@/base/domains';
import { Optional } from '@/base/types';
import {
  ScheduleTagAttributes,
  ScheduleTagEntity,
  ScheduleTagEntityImpl,
  ScheduleTagRepository,
} from '@/training/domains';
import { graphql } from '@/utils/AmplifyUtils';
import { requiredNonNull } from '@/utils/TsUtils';

type AmplifyScheduleTag = {
  id: ScheduleTagId;
  color: ScheduleColor;
  text: string;
  groupId: GroupId;
};

function toScheduleTag(e: AmplifyScheduleTag): ScheduleTagEntity {
  return new ScheduleTagEntityImpl(e);
}

export class AmplifyScheduleTagRepository implements ScheduleTagRepository {
  private appContextProvider: AppContextProvider;

  constructor(appContextProvider: AppContextProvider) {
    this.appContextProvider = appContextProvider;
  }

  async save(
    args: ScheduleTagEntity | EntityData<string, ScheduleTagAttributes>
  ): Promise<ScheduleTagEntity> {
    const tenantCode = requiredNonNull(
      this.appContextProvider.get().tenantCode,
      'appContext.tenantCode'
    );

    if (hasId(args)) {
      const input: UpdateScheduleTagInput = {
        id: args.id,
        color: args.color,
        text: args.text,
        groupId: args.groupId,
        tenantCode,
      };
      const response = await graphql<{ updateScheduleTag: AmplifyScheduleTag }>(
        customMutations.updateScheduleTag,
        { input }
      );
      return toScheduleTag(response.updateScheduleTag);
    }
    const input: CreateScheduleTagInput = {
      color: args.color,
      text: args.text,
      groupId: args.groupId,
      tenantCode,
    };
    const response = await graphql<{ createScheduleTag: AmplifyScheduleTag }>(
      customMutations.createScheduleTag,
      { input }
    );
    return toScheduleTag(response.createScheduleTag);
  }

  async findById(id: ScheduleTagId): Promise<Optional<ScheduleTagEntity>> {
    const response = await graphql<{ getScheduleTag: Optional<AmplifyScheduleTag> }>(
      customQueries.getScheduleTag,
      { id }
    );
    return response.getScheduleTag ? toScheduleTag(response.getScheduleTag) : undefined;
  }

  async remove(id: ScheduleTagId): Promise<void> {
    await graphql(customMutations.deleteScheduleTag, { input: { id } });
  }

  async findByGroupId(groupId: GroupId): Promise<Array<ScheduleTagEntity>> {
    const res = await graphql<{ scheduleTagsByGroupId: { items: Array<AmplifyScheduleTag> } }>(
      customQueries.scheduleTagsByGroupId,
      { groupId }
    );
    return res.scheduleTagsByGroupId.items.map(toScheduleTag);
  }
}
