import {
  AuthorizationService,
  CourseDisplayName,
  CourseId,
  GroupId,
  GroupTrainingFinder,
} from '@/base/domains';
import { AbstractUseCase, assertEntityExists, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { GroupCourseProgresses, ReportService } from '../domains';

export type GetGroupCourseProgressesRequest = {
  groupId: GroupId;
  courseId: CourseId;
};

export type GetGroupCourseProgressesResponse = {
  reports: Array<GroupCourseProgresses>;
  courseName: CourseDisplayName;
};

/**
 * グループ内受講生のコース進捗リストを取得する
 */
export interface GetGroupCourseProgresses
  extends UseCase<GetGroupCourseProgressesRequest, GetGroupCourseProgressesResponse> {
  execute(
    request: GetGroupCourseProgressesRequest
  ): Promise<UseCaseResponse<GetGroupCourseProgressesResponse>>;
}

export class GetGroupCourseProgressesImpl
  extends AbstractUseCase<GetGroupCourseProgressesRequest, GetGroupCourseProgressesResponse>
  implements GetGroupCourseProgresses
{
  constructor(
    private authorizationService: AuthorizationService,
    private reportService: ReportService,
    private groupTrainingFinder: GroupTrainingFinder
  ) {
    super('report.GetGroupCourseProgresses');
  }

  async internalExecute(
    request: GetGroupCourseProgressesRequest
  ): Promise<GetGroupCourseProgressesResponse> {
    const { groupId, courseId } = request;
    this.authorizationService.assertGroupReportAccessible(groupId);
    const [reports, course] = await Promise.all([
      this.reportService.findGroupCourseProgresses(groupId, courseId),
      this.groupTrainingFinder.findCourseByGroupIdAndCourseId(groupId, courseId),
    ]);
    assertEntityExists(course, 'course');
    return { reports, courseName: course.displayName };
  }
}

export const GetGroupCourseProgressesKey = injectionKeyOf<GetGroupCourseProgresses>({
  boundedContext: 'report',
  type: 'usecase',
  name: 'GetGroupCourseProgresses',
});

export function useGetGroupCourseProgresses(): GetGroupCourseProgresses {
  return requiredInject(GetGroupCourseProgressesKey);
}
