import {
  ContentId,
  ContentVersion,
  CourseId,
  Event,
  eventEnvelopFactoryOf,
  ExamId,
  GroupId,
} from '@/base/domains';

export interface ExamStarted extends Event {
  examId: ExamId;
}

export const examStartedFactory = eventEnvelopFactoryOf<ExamStarted>({
  type: 'EXAM_STARTED',
  version: 2,
});

export type Answer = {
  index: number;
  values: Array<string | number>;
};

export interface ExamFinished extends Event {
  examId: ExamId;
  answers: Array<Answer>;
}

export const examFinishedFactory = eventEnvelopFactoryOf<ExamFinished>({
  type: 'EXAM_FINISHED',
  version: 1,
});

/**
 * コンテンツを開いた version 2
 */
export interface ContentOpened extends Event {
  groupId: GroupId;
  courseId: CourseId;
  contentId: ContentId;
  /**
   * @since v1.3
   */
  contentVersion: ContentVersion;
}

export const contentOpenedFactory = eventEnvelopFactoryOf<ContentOpened>({
  type: 'CONTENT_OPENED',
  version: 2,
});

/**
 * コンテンツを閉じた version 2
 */
export interface ContentExited extends Event {
  groupId: GroupId;
  courseId: CourseId;
  contentId: ContentId;
  /**
   * @since v1.3
   */
  contentVersion: ContentVersion;
}

export const contentExitedFactory = eventEnvelopFactoryOf<ContentExited>({
  type: 'CONTENT_EXITED',
  version: 2,
});

/**
 * コンテンツを完了した version 2
 */
export interface ContentFinished extends Event {
  groupId: GroupId;
  courseId: CourseId;
  contentId: ContentId;
  /**
   * @since v1.3
   */
  contentVersion: ContentVersion;
}

export const contentFinishedFactory = eventEnvelopFactoryOf<ContentFinished>({
  type: 'CONTENT_FINISHED',
  version: 2,
});
