import { AuthorizationService, CourseId, GroupId } from '@/base/domains';
import { AbstractUseCase, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { GroupTrainingCourseReference, GroupTrainingCourseRepository } from '../domains';

export type ChangeGroupTrainingCoursesSortRequest = {
  id: GroupId;
  courseIds: Array<CourseId>;
};

export type ChangeGroupTrainingCoursesSortResponse = {
  courses: Array<GroupTrainingCourseReference>;
};

export interface ChangeGroupTrainingCoursesSort
  extends UseCase<ChangeGroupTrainingCoursesSortRequest, ChangeGroupTrainingCoursesSortResponse> {
  execute(
    request: ChangeGroupTrainingCoursesSortRequest
  ): Promise<UseCaseResponse<ChangeGroupTrainingCoursesSortResponse>>;
}

export class ChangeGroupTrainingCoursesSortImpl
  extends AbstractUseCase<
    ChangeGroupTrainingCoursesSortRequest,
    ChangeGroupTrainingCoursesSortResponse
  >
  implements ChangeGroupTrainingCoursesSort
{
  constructor(
    private authorizationService: AuthorizationService,
    private groupTrainingCourseRepository: GroupTrainingCourseRepository
  ) {
    super('training.ChangeGroupTrainingCoursesSort');
  }

  async internalExecute(
    request: ChangeGroupTrainingCoursesSortRequest
  ): Promise<ChangeGroupTrainingCoursesSortResponse> {
    const { id, courseIds } = request;
    this.authorizationService.assertTrainerOrMentorInGroup(id);
    const saved = await this.groupTrainingCourseRepository.sortGroupTrainingCourses(id, courseIds);
    return {
      courses: saved,
    };
  }
}

export const ChangeGroupTrainingCoursesSortKey = injectionKeyOf<ChangeGroupTrainingCoursesSort>({
  boundedContext: 'training',
  type: 'usecase',
  name: 'ChangeGroupTrainingCoursesSort',
});

export function useChangeGroupTrainingCoursesSort(): ChangeGroupTrainingCoursesSort {
  return requiredInject(ChangeGroupTrainingCoursesSortKey);
}
