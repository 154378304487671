import {
  ChangePasswordImpl,
  ChangePasswordKey,
  ConfirmUserImpl,
  ConfirmUserKey,
  CreateSupervisorImpl,
  CreateSupervisorKey,
  CreateUserByAdminImpl,
  CreateUserByAdminKey,
  DisableUsersImpl,
  DisableUsersKey,
  EnableUsersImpl,
  EnableUsersKey,
  ForgotPasswordImpl,
  ForgotPasswordKey,
  GetSignUpReservationImpl,
  GetSignUpReservationKey,
  GetUsersIncludingSignUpReservationImpl,
  GetUsersIncludingSignUpReservationKey,
  InviteUserImpl,
  InviteUserKey,
  RemoveSignUpReservationsImpl,
  RemoveSignUpReservationsKey,
  RemoveUsersImpl,
  RemoveUsersKey,
  ResendConfirmCodeImpl,
  ResendConfirmCodeKey,
  SignUpImpl,
  SignUpKey,
} from '@/account/usecases';
import {
  useAuthorizationService,
  useAuthService,
  useGroupFinder,
  useSignUpReservationQueries,
  useTenantDataAdapter,
  useUserFinder,
  useUserRepository,
} from '@/base/domains';
import { provide } from '@/utils/VueUtils';

export function useAccountUseCases() {
  const authService = useAuthService();
  const authorizationService = useAuthorizationService();
  const userFinder = useUserFinder();
  const signUpReservationQueries = useSignUpReservationQueries();
  const userRepository = useUserRepository();
  const tenantDataAdapter = useTenantDataAdapter();
  const groupFinder = useGroupFinder();

  provide(CreateSupervisorKey, new CreateSupervisorImpl(authService));
  provide(ConfirmUserKey, new ConfirmUserImpl(authService));
  provide(ResendConfirmCodeKey, new ResendConfirmCodeImpl(authService));
  provide(
    InviteUserKey,
    new InviteUserImpl(
      authorizationService,
      authService,
      userRepository,
      signUpReservationQueries,
      tenantDataAdapter,
      groupFinder
    )
  );
  provide(SignUpKey, new SignUpImpl(authService));
  provide(ForgotPasswordKey, new ForgotPasswordImpl(authService));
  provide(ChangePasswordKey, new ChangePasswordImpl(authService));
  provide(GetSignUpReservationKey, new GetSignUpReservationImpl(signUpReservationQueries));
  provide(
    GetUsersIncludingSignUpReservationKey,
    new GetUsersIncludingSignUpReservationImpl(
      authorizationService,
      userFinder,
      signUpReservationQueries
    )
  );
  provide(DisableUsersKey, new DisableUsersImpl(userRepository, authorizationService));
  provide(
    EnableUsersKey,
    new EnableUsersImpl(
      userRepository,
      authorizationService,
      signUpReservationQueries,
      tenantDataAdapter
    )
  );
  provide(
    RemoveSignUpReservationsKey,
    new RemoveSignUpReservationsImpl(authService, authorizationService)
  );
  provide(RemoveUsersKey, new RemoveUsersImpl(userRepository, authorizationService));
  provide(
    CreateUserByAdminKey,
    new CreateUserByAdminImpl(
      authorizationService,
      authService,
      userRepository,
      signUpReservationQueries,
      tenantDataAdapter,
      groupFinder
    )
  );
}
