import { GroupId } from '@/base/domains';
import { GroupTrainingId } from '@/base/domains/GroupTraining';

import { GroupTrainingEntity } from './GroupTraining';

export class GroupTrainingEntityImpl implements GroupTrainingEntity {
  id: GroupTrainingId;

  groupId: GroupId;

  constructor(args: { id: GroupTrainingId; groupId: GroupId }) {
    this.id = args.id;
    this.groupId = args.groupId;
  }
}
