import {
  AuthService,
  Email,
  Password,
  TenantCode,
  TenantName,
  TermsOfServiceId,
  UserCode,
  UserId,
  UserName,
} from '@/base/domains';
import { AbstractUseCase, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

export interface CreateSupervisorRequest {
  tenantCode: TenantCode;
  tenantName: TenantName;
  userCode?: UserCode;
  userName: UserName;
  password: Password;
  email: Email;
  termsOfServiceId: TermsOfServiceId;
}

export type CreateSupervisorResponse = {
  userId: UserId;
};

/**
 * スーパーバイザーを作成する
 */
export interface CreateSupervisor
  extends UseCase<CreateSupervisorRequest, CreateSupervisorResponse> {
  execute(request: CreateSupervisorRequest): Promise<UseCaseResponse<CreateSupervisorResponse>>;
}

export class CreateSupervisorImpl
  extends AbstractUseCase<CreateSupervisorRequest, CreateSupervisorResponse>
  implements CreateSupervisor
{
  constructor(private authService: AuthService) {
    super('account.CreateSupervisor');
  }

  async internalExecute(request: CreateSupervisorRequest): Promise<CreateSupervisorResponse> {
    const userId = await this.authService.signUp(request);
    return {
      userId,
    };
  }
}

export const CreateSupervisorKey = injectionKeyOf<CreateSupervisor>({
  boundedContext: 'account',
  type: 'usecase',
  name: 'CreateSupervisor',
});

export function useCreateSupervisor(): CreateSupervisor {
  return requiredInject(CreateSupervisorKey);
}
