// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const Role = {
  "GENERAL": "GENERAL",
  "ADMIN": "ADMIN",
  "SUPERVISOR": "SUPERVISOR"
};

const GroupRole = {
  "TRAINER": "TRAINER",
  "TRAINEE": "TRAINEE",
  "MENTOR": "MENTOR"
};

const EditingStatus = {
  "EDITING": "EDITING",
  "CONFIRMED": "CONFIRMED"
};

const ContentType = {
  "EXAM": "EXAM",
  "SLIDE": "SLIDE",
  "TEXT": "TEXT"
};

const CourseFontColorOnImage = {
  "BLACK": "BLACK",
  "WHITE": "WHITE"
};

const CourseStatus = {
  "EDITING": "EDITING",
  "ENABLED": "ENABLED",
  "DISABLED": "DISABLED"
};

const UserStatus = {
  "ACTIVE": "ACTIVE",
  "INACTIVE": "INACTIVE"
};

const SignUpType = {
  "USER_CODE": "USER_CODE",
  "EMAIL": "EMAIL"
};

const SignUpReservationStatus = {
  "NOT_SIGNED_UP": "NOT_SIGNED_UP",
  "SIGNED_UP": "SIGNED_UP",
  "CONFIRMED": "CONFIRMED"
};

const TenantOwnTermsOfServiceType = {
  "EDITING": "EDITING",
  "CONFIRMED": "CONFIRMED"
};

const HandStatus = {
  "YES": "YES",
  "NO": "NO",
  "NONE": "NONE"
};

const InternalTaskStatus = {
  "CREATED": "CREATED",
  "IN_PROGRESS": "IN_PROGRESS",
  "FAILED": "FAILED",
  "SUCCEEDED": "SUCCEEDED"
};

const PlaygroundStatus = {
  "RESERVED": "RESERVED",
  "CREATING": "CREATING",
  "CREATED": "CREATED",
  "USED": "USED",
  "REMOVED": "REMOVED"
};

const QuestionnaireStatus = {
  "FINISHED": "FINISHED",
  "ACTIVE": "ACTIVE"
};

const ProblemType = {
  "CHOICE": "CHOICE"
};

const ExamResultVisibilityLevel = {
  "INVISIBLE_TO_USER": "INVISIBLE_TO_USER",
  "DETAILS": "DETAILS",
  "SCORE": "SCORE"
};

const UserExamStatus = {
  "NOT_STARTED": "NOT_STARTED",
  "IN_PROGRESS": "IN_PROGRESS",
  "FINISHED": "FINISHED",
  "SKIPPED": "SKIPPED"
};

const LearningStatus = {
  "NOT_BEGUN": "NOT_BEGUN",
  "IN_PROGRESS": "IN_PROGRESS",
  "COMPLETED": "COMPLETED"
};

const { Group, EditingCourse, CourseHeader, Notification, User, UserUserTag, UserTag, UserStatusTable, UserDevice, SignUpReservation, Tenant, TenantOwnTermsOfService, Event, TermsOfService, Live, GroupStatusUser, GroupStatus, InternalTask, Playground, Questionnaire, QuestionnaireRespondent, Content, Course, EditingCourseContentWorkbook, EditingCourseContentBody, EditingConfirmedContent, ExamResult, GroupTraining, GroupTrainingCourse, GroupExam, UserExam, ScheduleTag, ScheduleScheduleTag, Schedule, Comment, Question, ContentLearning, Memo, ReviewProblem, Policy, SignedUrl, PrivacyPolicyVersion, InviteUserResponse, UploadResponse, GroupUser, EditingCourseContentHeader, CreateUserByAdminResponse, DisplaySettings, ConfirmedTermsOfServiceVersions, SignUpInput, Problem, ChoiceProblem, ChoiceProblemOption, Workbook, ProblemHeader, ContentBody, TextContent, ExamContent, CourseContent, Error, EncodeAudioResponse, GroupTrainingCourseContent, GroupExamContent, GroupExamCourse, TextContentReferenceOptions, WorkbookReferenceOptions, ContentReferenceOptions, ContentReference } = initSchema(schema);

export {
  Group,
  EditingCourse,
  CourseHeader,
  Notification,
  User,
  UserUserTag,
  UserTag,
  UserStatusTable,
  UserDevice,
  SignUpReservation,
  Tenant,
  TenantOwnTermsOfService,
  Event,
  TermsOfService,
  Live,
  GroupStatusUser,
  GroupStatus,
  InternalTask,
  Playground,
  Questionnaire,
  QuestionnaireRespondent,
  Content,
  Course,
  EditingCourseContentWorkbook,
  EditingCourseContentBody,
  EditingConfirmedContent,
  ExamResult,
  GroupTraining,
  GroupTrainingCourse,
  GroupExam,
  UserExam,
  ScheduleTag,
  ScheduleScheduleTag,
  Schedule,
  Comment,
  Question,
  ContentLearning,
  Memo,
  ReviewProblem,
  Role,
  GroupRole,
  EditingStatus,
  ContentType,
  CourseFontColorOnImage,
  CourseStatus,
  UserStatus,
  SignUpType,
  SignUpReservationStatus,
  TenantOwnTermsOfServiceType,
  HandStatus,
  InternalTaskStatus,
  PlaygroundStatus,
  QuestionnaireStatus,
  ProblemType,
  ExamResultVisibilityLevel,
  UserExamStatus,
  LearningStatus,
  Policy,
  SignedUrl,
  PrivacyPolicyVersion,
  InviteUserResponse,
  UploadResponse,
  GroupUser,
  EditingCourseContentHeader,
  CreateUserByAdminResponse,
  DisplaySettings,
  ConfirmedTermsOfServiceVersions,
  SignUpInput,
  Problem,
  ChoiceProblem,
  ChoiceProblemOption,
  Workbook,
  ProblemHeader,
  ContentBody,
  TextContent,
  ExamContent,
  CourseContent,
  Error,
  EncodeAudioResponse,
  GroupTrainingCourseContent,
  GroupExamContent,
  GroupExamCourse,
  TextContentReferenceOptions,
  WorkbookReferenceOptions,
  ContentReferenceOptions,
  ContentReference
};