import { AuthorizationService, CourseDisplayName } from '@/base/domains';
import { AbstractUseCase, assertEntityExists, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import {
  GroupTrainingCourseId,
  GroupTrainingCourseReference,
  GroupTrainingCourseRepository,
} from '../domains';

export type ChangeGroupCourseDisplayNameRequest = {
  id: GroupTrainingCourseId;
  displayName: CourseDisplayName;
};

export type ChangeGroupCourseDisplayNameResponse = {
  groupTrainingCourse: GroupTrainingCourseReference;
};

export interface ChangeGroupCourseDisplayName
  extends UseCase<ChangeGroupCourseDisplayNameRequest, ChangeGroupCourseDisplayNameResponse> {
  execute(
    request: ChangeGroupCourseDisplayNameRequest
  ): Promise<UseCaseResponse<ChangeGroupCourseDisplayNameResponse>>;
}

export class ChangeGroupCourseDisplayNameImpl
  extends AbstractUseCase<ChangeGroupCourseDisplayNameRequest, ChangeGroupCourseDisplayNameResponse>
  implements ChangeGroupCourseDisplayName
{
  constructor(
    private authorizationService: AuthorizationService,
    private groupTrainingCourseRepository: GroupTrainingCourseRepository
  ) {
    super('training.ChangeGroupCourseDisplayName');
  }

  async internalExecute({
    id,
    displayName,
  }: ChangeGroupCourseDisplayNameRequest): Promise<ChangeGroupCourseDisplayNameResponse> {
    const groupTrainingCourse = await this.groupTrainingCourseRepository.findById(id);
    assertEntityExists(groupTrainingCourse, 'groupTrainingCourse');
    this.authorizationService.assertTrainerOrMentorInGroup(groupTrainingCourse.groupId);
    const saved = await this.groupTrainingCourseRepository.save(
      groupTrainingCourse.changeDisplayName(displayName)
    );
    return {
      groupTrainingCourse: saved,
    };
  }
}

export const ChangeGroupCourseDisplayNameKey = injectionKeyOf<ChangeGroupCourseDisplayName>({
  boundedContext: 'training',
  type: 'usecase',
  name: 'ChangeGroupCourseDisplayName',
});

export function useChangeGroupCourseDisplayName(): ChangeGroupCourseDisplayName {
  return requiredInject(ChangeGroupCourseDisplayNameKey);
}
