import { Minute, Optional } from '@/base/types';
import { hasProperty } from '@/utils/TsUtils';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { Exam } from './Exam';
import { Slide } from './Slide';
import { Text } from './Text';
import { Workbook } from './Workbook';

export type ContentId = string;
export type ContentCode = string;
export type ContentName = string;
export type ContentType = 'text' | 'exam';
export type ContentVersion = number;
export type CourseId = string;
export type CourseName = string;
export type CourseDisplayName = string;
export type CourseVersion = number;

type ContentBase = {
  /** ID */
  readonly id: ContentId;
  /** バージョン */
  readonly version: ContentVersion;
  /** 最新 */
  readonly latest: boolean;
  /** 名前 */
  readonly name: ContentName;
  /** 所要時間 */
  readonly requiredTime: Minute;
  /** コースID */
  readonly courseId: CourseId;
  /** コースバージョン */
  readonly courseVersion: CourseVersion;
};

/**
 * テストコンテンツ
 */
export type ExamContent = ContentBase & {
  /** コンテンツタイプ */
  readonly type: 'exam';
  /** コンテンツ */
  readonly body: Exam;
};

/**
 * テキストコンテンツ
 */
export type TextContent = ContentBase & {
  /** コンテンツタイプ */
  readonly type: 'text';
  /** コンテンツ */
  readonly body: Text;
  /** 練習問題 */
  readonly workbook?: Workbook;
};

/**
 * コンテンツ
 */
export type Content = ExamContent | TextContent;

export type ContentHeader = {
  id: ContentId;
  version: ContentVersion;
  versionDescription?: string;
};

/**
 * コンテンツ検索。
 */
export interface ContentFinder {
  findById(id: ContentId, version?: ContentVersion): Promise<Optional<Content>>;
  findTenantContents(): Promise<Array<Content>>;
  findByCourseId(courseId: CourseId, courseVersion: CourseVersion): Promise<Array<Content>>;
  findTenantContentHeaders(options?: { latest: boolean }): Promise<Array<ContentHeader>>;
  findContentHeadersByCourse(args: {
    courseId: CourseId;
    courseVersion: CourseVersion;
  }): Promise<Array<ContentHeader>>;
}

/**
 * コンテンツ検索のインジェクションキー。
 */
export const ContentFinderKey = injectionKeyOf<ContentFinder>({
  boundedContext: 'base',
  type: 'service',
  name: 'ContentFinder',
});

export function useContentFinder(): ContentFinder {
  return requiredInject(ContentFinderKey);
}

export type ContentBody = Text | Slide | Exam;

export function isText(body: ContentBody): body is Text {
  return hasProperty(body, 'body');
}

export function isExam(body: ContentBody): body is Exam {
  return hasProperty(body, 'problems');
}
