import {
  AuthorizationService,
  CourseId,
  CourseVersion,
  GroupFinder,
  GroupId,
  GroupName,
  GroupTrainingFinder,
} from '@/base/domains';
import { AbstractUseCase, UseCase, UseCaseResponse } from '@/base/usecases';
import { requiredNonNull } from '@/utils/TsUtils';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

export interface GetGroupsByCourseRequest {
  id: CourseId;
}

export type GetGroupsByCourseGroup = {
  id: GroupId;
  name: GroupName;
  courseVersion: CourseVersion;
  enabled: boolean;
};

export type GetGroupsByCourseResponse = {
  groups: Array<GetGroupsByCourseGroup>;
};

/**
 * コースが登録されているグループリストを取得する
 */
export interface GetGroupsByCourse
  extends UseCase<GetGroupsByCourseRequest, GetGroupsByCourseResponse> {
  execute(request: GetGroupsByCourseRequest): Promise<UseCaseResponse<GetGroupsByCourseResponse>>;
}

export class GetGroupsByCourseImpl
  extends AbstractUseCase<GetGroupsByCourseRequest, GetGroupsByCourseResponse>
  implements GetGroupsByCourse
{
  private authorizationService: AuthorizationService;

  private groupTrainingFinder: GroupTrainingFinder;

  private groupFinder: GroupFinder;

  constructor(
    authorizationService: AuthorizationService,
    groupTrainingFinder: GroupTrainingFinder,
    groupFinder: GroupFinder
  ) {
    super('contents.GetGroupsByCourse');
    this.authorizationService = authorizationService;
    this.groupTrainingFinder = groupTrainingFinder;
    this.groupFinder = groupFinder;
  }

  async internalExecute(request: GetGroupsByCourseRequest): Promise<GetGroupsByCourseResponse> {
    const { id } = request;
    this.authorizationService.assertContentEditable();
    const results = await Promise.all([
      this.groupTrainingFinder.findByCourseId(id),
      this.groupFinder.findTenantGroups({ includeDisabledGroup: true }),
    ]);
    const gts = results[0];
    const groupNames = new Map(results[1].map((g) => [g.id, g.name]));
    const groupsEnabled = new Map(results[1].map((g) => [g.id, g.enabled]));
    return {
      groups: gts.map((gt) => ({
        id: gt.groupId,
        name: requiredNonNull(groupNames.get(gt.groupId), 'group'),
        courseVersion: gt.courseVersion,
        enabled: requiredNonNull(groupsEnabled.get(gt.groupId), 'group'),
      })),
    };
  }
}

export const GetGroupsByCourseKey = injectionKeyOf<GetGroupsByCourse>({
  boundedContext: 'contents',
  type: 'usecase',
  name: 'GetGroupsByCourse',
});

export function useGetGroupsByCourse(): GetGroupsByCourse {
  return requiredInject(GetGroupsByCourseKey);
}
