import { ContentId, CourseId } from '@/base/domains';
import { ExamEditingCourseContentBodyReference } from '@/contents/domains';
import { EditingCourseContentBodyQueries } from '@/contents/domains/queries';
import * as queries from '@/graphql/queries';
import { graphqlQuery } from '@/utils/AmplifyUtils';

import {
  AmplifyEditingCourseContentBody,
  toEditingCourseContentBody,
} from './AmplifyEditingCourseContentBodyRepository';

export class AmplifyEditingCourseContentBodyQueries implements EditingCourseContentBodyQueries {
  async findExamContentsByCourse(id: CourseId): Promise<ExamEditingCourseContentBodyReference[]> {
    const response = await graphqlQuery<{
      editingCourseContentBodiesByCourse: { items: Array<AmplifyEditingCourseContentBody> };
    }>(queries.editingCourseContentBodiesByCourse, { courseId: id });
    return response.editingCourseContentBodiesByCourse.items
      .map(toEditingCourseContentBody)
      .filter((cn) => cn.type === 'exam') as ExamEditingCourseContentBodyReference[];
  }

  async getEmptyProblemExamContentIdByCourse(id: CourseId): Promise<Array<ContentId>> {
    const exams = await this.findExamContentsByCourse(id);
    return exams.filter((cn) => cn.body.problems.length === 0).map((cn) => cn.id);
  }
}
