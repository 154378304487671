import { assertIsDefined } from '@/utils/Asserts';
import { createLogger } from '@/utils/log';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import {
  AppContextProvider,
  GroupId,
  NotificationEntity,
  NotificationReference,
  NotificationRepository,
  ReadType,
} from '../domains';
import { AbstractUseCase, UseCase, UseCaseResponse } from './UseCase';

const logger = createLogger({ boundedContext: 'base', name: 'GetNotifications' });
export interface GetNotificationsRequest {
  /** 指定したグループIDのリストでフィルタする。未指定の場合はアクティブなグループIDが利用される。SV/ADMIN以外は指定できない */
  groupIds?: Array<GroupId>;
  /** 指定したReadTypeでフィルタする。未指定の場合は条件にしない。 */
  read?: ReadType;
  /** 指定した件数でフィルタする。 */
  limit?: number;
}

export type GetNotificationsResponse = {
  notifications: Array<NotificationReference>;
};

/**
 * 通知リストを取得する
 */
export interface GetNotifications
  extends UseCase<GetNotificationsRequest, GetNotificationsResponse> {
  execute(request: GetNotificationsRequest): Promise<UseCaseResponse<GetNotificationsResponse>>;
}

export class GetNotificationsImpl
  extends AbstractUseCase<GetNotificationsRequest, GetNotificationsResponse>
  implements GetNotifications
{
  private appContextProvider: AppContextProvider;

  private notificationRepository: NotificationRepository;

  constructor(
    appContextProvider: AppContextProvider,
    notificationRepository: NotificationRepository
  ) {
    super('base.GetNotifications');
    this.appContextProvider = appContextProvider;
    this.notificationRepository = notificationRepository;
  }

  async internalExecute(request: GetNotificationsRequest): Promise<GetNotificationsResponse> {
    const { read } = request;
    const appContext = this.appContextProvider.get();
    logger.debug({
      message: 'exec',
      appContext,
    });
    const userId = appContext.user?.id;
    assertIsDefined(userId, 'appContext.user.id');

    const notifications = await this.notificationRepository.findByUserId(userId);

    // TODO limit, groupIdによるフィルタは未実装
    const filter = (() => {
      const filters = [(n: NotificationEntity) => n.userId === userId];
      if (read) {
        filters.push((n: NotificationEntity) => n.read === read);
      }
      return (n: NotificationEntity) => filters.every((f) => f(n));
    })();

    return {
      notifications: notifications.filter(filter),
    };
  }
}

export const GetNotificationsKey = injectionKeyOf<GetNotifications>({
  boundedContext: 'base',
  type: 'usecase',
  name: 'GetNotifications',
});

export function useGetNotifications(): GetNotifications {
  return requiredInject(GetNotificationsKey);
}
