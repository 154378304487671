import {
  ContentType as AmplifyContentType,
  CourseFontColorOnImage as AmplifyCourseFontColorOnImage,
  CreateEditingCourseInput,
  EditingStatus as AmplifyEditingStatus,
  UpdateEditingCourseInput,
} from '@/API';
import {
  AppContextProvider,
  ContentId,
  ContentName,
  ContentType,
  CourseColor,
  CourseFontColorOnImage,
  CourseId,
  CourseName,
  CourseVersion,
  EntityData,
  UserId,
} from '@/base/domains';
import { ISODateTimeString, Minute, Optional, URI } from '@/base/types';
import {
  EditingCourseAttributes,
  EditingCourseContentHeader,
  EditingCourseEntity,
  EditingCourseEntityImpl,
  EditingCourseId,
  EditingCourseReference,
  EditingCourseRepository,
  EditingStatus,
} from '@/contents/domains';
import { COURSE_DUPLICATED_NAME } from '@/contents/ErrorCodes';
import * as mutations from '@/graphql/mutations';
import * as queries from '@/graphql/queries';
import { graphql, graphqlQuery } from '@/utils/AmplifyUtils';
import { localDateTimeFromString } from '@/utils/DateUtils';
import {
  hasNonNullProperty,
  hasProperty,
  ifDefined,
  isDefined,
  requiredNonNull,
} from '@/utils/TsUtils';

type AmplifyEditingCourseContentHeader = {
  id: ContentId;
  type: AmplifyContentType;
  name: ContentName;
  requiredTime: Minute;
};

export type AmplifyEditingCourse = {
  id: CourseId;
  name: CourseName;
  version: CourseVersion;
  status: AmplifyEditingStatus;
  description?: string;
  contents: Array<AmplifyEditingCourseContentHeader>;
  createdBy: UserId;
  createdAt: ISODateTimeString;
  updatedBy: UserId;
  updatedAt: ISODateTimeString;
  color?: CourseColor;
  image?: URI;
  fontColorOnImage?: AmplifyCourseFontColorOnImage;
};

function toAmplifyEditingCourseContentHeader(
  e: EditingCourseContentHeader
): AmplifyEditingCourseContentHeader {
  return {
    ...e,
    type: e.type.toUpperCase() as AmplifyContentType,
  };
}

function toEditingCourseContentHeader(
  e: AmplifyEditingCourseContentHeader
): EditingCourseContentHeader {
  return {
    ...e,
    type: e.type.toLocaleLowerCase() as ContentType,
  };
}

export function toEditingCourse(e: AmplifyEditingCourse): EditingCourseEntity {
  const color = (() => {
    if (isDefined(e.color)) {
      return e.color;
    }
    if (isDefined(e.image)) {
      return undefined;
    }
    return 'default';
  })();

  return new EditingCourseEntityImpl({
    id: e.id,
    name: e.name,
    version: e.version,
    status: e.status.toLowerCase() as EditingStatus,
    description: e.description,
    contents: e.contents.map(toEditingCourseContentHeader),
    createdBy: e.createdBy,
    createdAt: localDateTimeFromString(e.createdAt),
    updatedBy: e.updatedBy,
    updatedAt: localDateTimeFromString(e.updatedAt),
    color,
    image: e.image,
    fontColorOnImage: ifDefined(
      e.fontColorOnImage,
      (v) => v.toLowerCase() as CourseFontColorOnImage
    ),
  });
}

export class AmplifyEditingCourseRepository implements EditingCourseRepository {
  private appContextProvider: AppContextProvider;

  constructor(appContextProvider: AppContextProvider) {
    this.appContextProvider = appContextProvider;
  }

  async save(
    args: EditingCourseEntity | EntityData<string, EditingCourseAttributes>
  ): Promise<EditingCourseEntity> {
    const tenantCode = requiredNonNull(
      this.appContextProvider.get().tenantCode,
      'appContext.tenantCode'
    );
    const userId = requiredNonNull(this.appContextProvider.get().user?.id, 'appContext.user.id');
    const fontColorOnImage = ifDefined(
      args.fontColorOnImage,
      (v) => v.toUpperCase() as AmplifyCourseFontColorOnImage
    );

    if (hasProperty(args, 'createdBy')) {
      const input: UpdateEditingCourseInput = {
        id: args.id,
        name: args.name,
        version: args.version,
        description: args.description,
        contents: args.contents.map(toAmplifyEditingCourseContentHeader),
        status: args.status.toUpperCase() as AmplifyEditingStatus,
        createdBy: args.createdBy,
        updatedBy: userId,
        tenantCode,
        color: isDefined(args.color) ? args.color : null,
        image: isDefined(args.image) ? args.image : null,
        fontColorOnImage,
      };
      try {
        const response = await graphql<{ updateEditingCourse: AmplifyEditingCourse }>(
          mutations.updateEditingCourse,
          { input }
        );
        return toEditingCourse(response.updateEditingCourse);
      } catch (e) {
        if (hasNonNullProperty(e, 'message') && e.message === 'Duplicated editing-course name') {
          throw COURSE_DUPLICATED_NAME.toApplicationError();
        }
        throw e;
      }
    }
    const input: CreateEditingCourseInput = {
      id: args.id,
      name: args.name,
      version: args.version,
      description: args.description,
      contents: args.contents.map(toAmplifyEditingCourseContentHeader),
      status: args.status.toUpperCase() as AmplifyEditingStatus,
      createdBy: userId,
      updatedBy: userId,
      tenantCode,
      color: args.color,
      image: args.image,
      fontColorOnImage,
    };
    try {
      const response = await graphql<{ createEditingCourse: AmplifyEditingCourse }>(
        mutations.createEditingCourse,
        { input }
      );
      return toEditingCourse(response.createEditingCourse);
    } catch (e) {
      if (hasNonNullProperty(e, 'message') && e.message === 'Duplicated editing-course name') {
        throw COURSE_DUPLICATED_NAME.toApplicationError();
      }
      throw e;
    }
  }

  async findById(id: EditingCourseId): Promise<Optional<EditingCourseEntity>> {
    const response = await graphql<{ getEditingCourse: Optional<AmplifyEditingCourse> }>(
      queries.getEditingCourse,
      { id }
    );
    return response.getEditingCourse ? toEditingCourse(response.getEditingCourse) : undefined;
  }

  async remove(id: EditingCourseId): Promise<void> {
    await graphql(mutations.deleteEditingCourse, { input: { id } });
  }

  async tenantEditingCourses(): Promise<EditingCourseReference[]> {
    const tenantCode = requiredNonNull(
      this.appContextProvider.get().tenantCode,
      'appContext.tenantCode'
    );
    const response = await graphqlQuery<{
      editingCoursesByTenantCode: { items: Array<AmplifyEditingCourse> };
    }>(
      queries.editingCoursesByTenantCode,
      {
        tenantCode,
      },
      {
        limit: 1000,
      }
    );
    return response.editingCoursesByTenantCode.items.map(toEditingCourse);
  }
}
