import { AuthorizationService, CourseId } from '@/base/domains';
import { AbstractUseCase, assertEntityExists, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { CourseHeaderReference, CourseHeaderRepository } from '../domains';

export interface EnableCourseRequest {
  id: CourseId;
}

export type EnableCourseResponse = {
  course: CourseHeaderReference;
};

/**
 * コースを有効化する
 */
export interface EnableCourse extends UseCase<EnableCourseRequest, EnableCourseResponse> {
  execute(request: EnableCourseRequest): Promise<UseCaseResponse<EnableCourseResponse>>;
}

export class EnableCourseImpl
  extends AbstractUseCase<EnableCourseRequest, EnableCourseResponse>
  implements EnableCourse
{
  private authorizationService: AuthorizationService;

  private courseHeaderRepository: CourseHeaderRepository;

  constructor(
    authorizationService: AuthorizationService,
    courseHeaderRepository: CourseHeaderRepository
  ) {
    super('contents.EnableCourse');
    this.authorizationService = authorizationService;
    this.courseHeaderRepository = courseHeaderRepository;
  }

  async internalExecute(request: EnableCourseRequest): Promise<EnableCourseResponse> {
    const { id } = request;
    this.authorizationService.assertContentEditable();
    const course = await this.courseHeaderRepository.findById(id);
    assertEntityExists(course, 'courseHeader');
    const saved = await this.courseHeaderRepository.save(course.enable());
    return {
      course: saved,
    };
  }
}

export const EnableCourseKey = injectionKeyOf<EnableCourse>({
  boundedContext: 'contents',
  type: 'usecase',
  name: 'EnableCourse',
});

export function useEnableCourse(): EnableCourse {
  return requiredInject(EnableCourseKey);
}
