import { AuthorizationService, ContentId } from '@/base/domains';
import { AbstractUseCase, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { EditingConfirmedContentReference, EditingConfirmedContentRepository } from '../domains';

export type GetEditingConfirmedContentRequest = {
  id: ContentId;
};

export type GetEditingConfirmedContentResponse = {
  editingConfirmedContent?: EditingConfirmedContentReference;
};

/**
 * 編集中確定済みコンテンツを取得する
 */
export interface GetEditingConfirmedContent
  extends UseCase<GetEditingConfirmedContentRequest, GetEditingConfirmedContentResponse> {
  execute(
    request: GetEditingConfirmedContentRequest
  ): Promise<UseCaseResponse<GetEditingConfirmedContentResponse>>;
}

export class GetEditingConfirmedContentImpl
  extends AbstractUseCase<GetEditingConfirmedContentRequest, GetEditingConfirmedContentResponse>
  implements GetEditingConfirmedContent
{
  constructor(
    private authorizationService: AuthorizationService,
    private editingConfirmedContentRepository: EditingConfirmedContentRepository
  ) {
    super('contents.GetEditingConfirmed');
  }

  async internalExecute({
    id,
  }: GetEditingConfirmedContentRequest): Promise<GetEditingConfirmedContentResponse> {
    this.authorizationService.assertContentEditable();
    const editingConfirmedContent = await this.editingConfirmedContentRepository.findById(id);
    return {
      editingConfirmedContent,
    };
  }
}

export const GetEditingConfirmedContentKey = injectionKeyOf<GetEditingConfirmedContent>({
  boundedContext: 'contents',
  type: 'usecase',
  name: 'GetEditingConfirmedContent',
});

export function useGetEditingConfirmedContent(): GetEditingConfirmedContent {
  return requiredInject(GetEditingConfirmedContentKey);
}
