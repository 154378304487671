/* eslint-disable @typescript-eslint/no-unused-vars */

import { v4 } from 'uuid';

import { GroupId, isEntityLike, Subscription, UserId } from '@/base/domains';
import { Optional } from '@/base/types';
import {
  QuestionnaireAttributes,
  QuestionnaireByAnswerer,
  QuestionnaireByAnswererArgs,
  QuestionnaireData,
  QuestionnaireEntity,
  QuestionnaireEntityImpl,
  QuestionnaireId,
  QuestionnaireReference,
  QuestionnaireRepository,
  QuestionnaireRespondent,
} from '@/training/domains';

export class InMemoryQuestionnaireRepository implements QuestionnaireRepository {
  constructor(
    private questionnaires: Array<QuestionnaireReference>,
    private questionnaireRespondent: QuestionnaireRespondent,
    private unansweredQuestionnaires: Array<QuestionnaireByAnswererArgs>,
    private questionnaireByAnswerer: QuestionnaireByAnswerer,
    private questionnairesByAnswerer: Array<QuestionnaireByAnswerer>
  ) {}

  private store: Map<QuestionnaireId, QuestionnaireEntity> = new Map();

  save(entity: QuestionnaireAttributes): Promise<QuestionnaireEntity> {
    const e = isEntityLike<QuestionnaireData>(entity)
      ? new QuestionnaireEntityImpl(entity)
      : new QuestionnaireEntityImpl({ ...entity, id: v4() });
    this.store.set(e.id, e);
    return Promise.resolve(e);
  }

  findById(id: QuestionnaireId): Promise<Optional<QuestionnaireEntity>> {
    return Promise.resolve(this.store.get(id));
  }

  remove(id: QuestionnaireId): Promise<void> {
    this.store.delete(id);
    return Promise.resolve();
  }

  findByGroupId(groupId: GroupId): Promise<Array<QuestionnaireReference>> {
    return Promise.resolve(this.questionnaires);
  }

  findActiveQuestionnairesByGroupId(groupId: GroupId): Promise<Array<QuestionnaireReference>> {
    return Promise.resolve(this.questionnaires);
  }

  findUnansweredQuestionnaires(
    groupId: GroupId,
    userId: UserId
  ): Promise<Array<QuestionnaireByAnswererArgs>> {
    return Promise.resolve(this.unansweredQuestionnaires);
  }

  answerQuestionnaire(
    questionnaireId: QuestionnaireId,
    userId: UserId,
    selectedIndex: number
  ): Promise<QuestionnaireRespondent> {
    return Promise.resolve(this.questionnaireRespondent);
  }

  findByAnswerer(
    questionnaireId: QuestionnaireId,
    userId: UserId
  ): Promise<Optional<QuestionnaireByAnswerer>> {
    return Promise.resolve(this.questionnaireByAnswerer);
  }

  findQuestionnairesByAnswerer(
    groupId: GroupId,
    userId: UserId
  ): Promise<Array<QuestionnaireByAnswerer>> {
    return Promise.resolve(this.questionnairesByAnswerer);
  }

  subscribeQuestionnaireStatusChanged(_: {
    questionnaireId: string;
    onChange: (questionnaireRespondent: QuestionnaireRespondent, removed: boolean) => void;
    onError: (e: Error) => void;
  }): Subscription {
    return {
      unsubscribe: () => {
        // do nothing
      },
      isClosed: () => false,
    };
  }
}
