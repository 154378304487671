import {
  ContentId,
  ContentName,
  ContentVersion,
  CourseId,
  CourseVersion,
  Exam,
} from '@/base/domains';
import { Minute } from '@/base/types';

import { ContentEntityUpdateArgs, ExamContentEntity } from './Content';

export type ExamContentEntityArgs = {
  id: ContentId;
  /** 最新 */
  latest: boolean;
  /** 名前 */
  name: ContentName;
  /** 所要時間 */
  requiredTime: Minute;
  /** コースID */
  courseId: CourseId;
  /** コースバージョン */
  courseVersion: CourseVersion;
  /** コンテンツタイプ */
  type: 'exam';
  /** コンテンツの本体 */
  body: Exam;
  /** バージョン */
  version: ContentVersion;
  /** バージョン説明 */
  versionDescription?: string;
  /** 合格基準 */
  passingStandard?: number;
};

/**
 * テストコンテンツエンティティの実装
 */
export class ExamContentEntityImpl implements ExamContentEntity {
  id: ContentId;

  type!: 'exam';

  latest: boolean;

  name: ContentName;

  requiredTime: Minute;

  body: Exam;

  courseId: CourseId;

  courseVersion: CourseVersion;

  version: ContentVersion;

  versionDescription?: string;

  passingStandard?: number;

  constructor(args: ExamContentEntityArgs) {
    this.id = args.id;
    this.latest = args.latest;
    this.type = args.type;
    this.body = args.body;
    this.name = args.name;
    this.requiredTime = args.requiredTime;
    this.body = args.body;
    this.courseId = args.courseId;
    this.courseVersion = args.courseVersion;
    this.version = args.version;
    this.versionDescription = args.versionDescription;
    this.passingStandard = args.passingStandard;
  }

  update(args: ContentEntityUpdateArgs): ExamContentEntity {
    // args.workbookは利用されない
    return new ExamContentEntityImpl({
      ...this,
      ...args,
    });
  }

  changeVersionDescription(versionDescription: string): ExamContentEntity {
    return new ExamContentEntityImpl({
      ...this,
      versionDescription,
    });
  }

  changePassingStandard(passingStandard: number): ExamContentEntity {
    return new ExamContentEntityImpl({
      ...this,
      passingStandard,
    });
  }

  clearPassingStandard(): ExamContentEntity {
    return new ExamContentEntityImpl({
      ...this,
      passingStandard: undefined,
    });
  }
}
