import {
  AppContextProvider,
  AuthorizationService,
  ContentId,
  ContentLearning,
  ContentLearningDataAdapter,
  ContentName,
  ContentType,
  CourseColor,
  CourseId,
  CourseName,
  ExamDataAdapter,
  ExamId,
  GroupExamId,
  GroupTrainingCourse,
  GroupTrainingFinder,
  LearningStatus,
  UserExamStatus,
} from '@/base/domains';
import { LocalDateTime, Minute, URI } from '@/base/types';
import { AbstractUseCase, UseCase, UseCaseResponse } from '@/base/usecases';
import { assertIsDefined } from '@/utils/Asserts';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

export type HomeContent = {
  /** コースにおけるコンテンツのindex */
  readonly index: number;
  /** コンテンツタイプ */
  readonly type: ContentType;
  /** 名前。マスタとは異なる名前が付けられる。 */
  readonly name: ContentName;
  /** コンテンツID */
  readonly id: ContentId;
  /** 推奨日時 */
  readonly recommendedDateTime?: LocalDateTime;
  /** コースID */
  readonly courseId: CourseId;
  /** コース名 */
  readonly courseName: CourseName;
  /** 学習ステータス */
  readonly learningStatus: LearningStatus;
  /** コースカラー */
  readonly courseColor?: CourseColor;
  /** コースイメージ */
  readonly courseImage?: URI;
  /** 公開中 */
  readonly open: boolean;
  /** 所要時間 */
  readonly requiredTime?: Minute;
};

export type GetHomeContentsTraineeExam = {
  /** 試験ID */
  readonly id: ExamId;
  /** 名前 */
  readonly name: ContentName;
  /** コンテンツID */
  readonly contentId: ContentId;
  /** コースID */
  readonly courseId: CourseId;
  /** コース名 */
  readonly courseName: CourseName;
  /** コースカラー */
  readonly courseColor?: CourseColor;
  /** コースイメージ */
  readonly courseImage?: URI;
  /** 予定開始日時 */
  readonly scheduledStart: LocalDateTime;
  /** 予定終了日時 */
  readonly scheduledFinish?: LocalDateTime;
  /** ステータス */
  readonly status: UserExamStatus;
  /** 回数 */
  readonly times: number;
  /** 制限時間 */
  readonly timeLimit?: Minute;
};

export type GetHomeContentsTrainerExam = {
  /** グループ試験ID */
  readonly id: GroupExamId;
  /** 名前 */
  readonly name: ContentName;
  /** コンテンツID */
  readonly contentId: ContentId;
  /** コースID */
  readonly courseId: CourseId;
  /** コース名 */
  readonly courseName: CourseName;
  /** コースカラー */
  readonly courseColor?: CourseColor;
  /** コースイメージ */
  readonly courseImage?: URI;
  /** 予定開始日時 */
  readonly scheduledStart: LocalDateTime;
  /** 予定終了日時 */
  readonly scheduledFinish?: LocalDateTime;
  /** 回数 */
  readonly times: number;
  /** 制限時間 */
  readonly timeLimit?: Minute;
};

export type GetHomeContentsResponse =
  | {
      forTrainerOrAdmin: true;
      contents: Array<HomeContent>;
      exams: Array<GetHomeContentsTrainerExam>;
    }
  | {
      forTrainerOrAdmin: false;
      contents: Array<HomeContent>;
      exams: Array<GetHomeContentsTraineeExam>;
    };

/**
 * ホームコンテンツを取得する
 */
export interface GetHomeContents extends UseCase<{}, GetHomeContentsResponse> {
  execute(): Promise<UseCaseResponse<GetHomeContentsResponse>>;
}

function homeContentsOf(
  courses: Array<GroupTrainingCourse>,
  contentLearnings: Array<ContentLearning>,
  isTrainer: boolean
): Array<HomeContent> {
  const statusMap = new Map(contentLearnings.map((cl) => [cl.contentId, cl.status]));
  let index = -1;
  return courses.flatMap((cr) =>
    cr.contents
      .filter((cn) => isTrainer || cn.open)
      .map((cn) => {
        index += 1;
        return {
          index,
          type: cn.type as ContentType,
          name: cn.name,
          id: cn.id,
          recommendedDateTime: cn.recommendedDateTime,
          courseId: cr.id,
          courseName: cr.name,
          learningStatus: statusMap.get(cn.id) ?? 'not_begun',
          courseColor: cr.color,
          courseImage: cr.image,
          open: cn.open,
          requiredTime: cn.requiredTime,
        };
      })
  );
}
export class GetHomeContentsImpl
  extends AbstractUseCase<{}, GetHomeContentsResponse>
  implements GetHomeContents
{
  private authorizationService: AuthorizationService;

  private groupTrainingFinder: GroupTrainingFinder;

  private appContextProvider: AppContextProvider;

  private contentLearningDataAdapter: ContentLearningDataAdapter;

  private examDataAdapter: ExamDataAdapter;

  constructor(
    authorizationService: AuthorizationService,
    groupTrainingFinder: GroupTrainingFinder,
    appContextProvider: AppContextProvider,
    contentLearningDataAdapter: ContentLearningDataAdapter,
    examDataAdapter: ExamDataAdapter
  ) {
    super('home.GetHomeContents');
    this.authorizationService = authorizationService;
    this.groupTrainingFinder = groupTrainingFinder;
    this.appContextProvider = appContextProvider;
    this.contentLearningDataAdapter = contentLearningDataAdapter;
    this.examDataAdapter = examDataAdapter;
  }

  async internalExecute(): Promise<GetHomeContentsResponse> {
    const appContext = this.appContextProvider.get();
    assertIsDefined(appContext.groupId, 'appContext.groupId');
    assertIsDefined(appContext.user, 'appContext.user');
    const { groupId } = appContext;
    const { role } = appContext.user;
    const groupRole = appContext.roleInGroup(groupId);
    if (
      groupRole === 'trainer' ||
      groupRole === 'mentor' ||
      role === 'supervisor' ||
      role === 'admin'
    ) {
      const [courses, contentLearnings, groupExams] = await Promise.all([
        this.groupTrainingFinder.findCoursesByGroupId(groupId),
        this.contentLearningDataAdapter.findByGroupIdAndUserId(groupId, appContext.user.id),
        this.examDataAdapter.findGroupExamsByGroupId(groupId, { inProgress: true }),
      ]);
      const contents = homeContentsOf(courses, contentLearnings, true);
      const exams: Array<GetHomeContentsTrainerExam> = groupExams.map((ge) => ({
        id: ge.id,
        name: ge.content.name,
        contentId: ge.content.id,
        courseId: ge.course.id,
        courseName: ge.course.name,
        courseColor: ge.course.color,
        courseImage: ge.course.image,
        scheduledStart: ge.scheduledStart,
        scheduledFinish: ge.scheduledFinish,
        times: ge.times,
        timeLimit: ge.timeLimit,
      }));
      return {
        forTrainerOrAdmin: true,
        contents,
        exams,
      };
    }
    this.authorizationService.assertGroupReadAccessible(groupId);
    const [courses, contentLearnings, userExams] = await Promise.all([
      this.groupTrainingFinder.findCoursesByGroupId(groupId),
      this.contentLearningDataAdapter.findByGroupIdAndUserId(groupId, appContext.user.id),
      this.examDataAdapter.findNotFinishedUserExams({
        groupId: appContext.groupId,
        userId: appContext.user.id,
      }),
    ]);
    const contents = homeContentsOf(courses, contentLearnings, false);
    const exams: Array<GetHomeContentsTraineeExam> = userExams.map((ue) => ({
      id: ue.id,
      name: ue.groupExam.content.name,
      contentId: ue.groupExam.content.id,
      courseId: ue.groupExam.course.id,
      courseName: ue.groupExam.course.name,
      courseColor: ue.groupExam.course.color,
      courseImage: ue.groupExam.course.image,
      scheduledStart: ue.groupExam.scheduledStart,
      scheduledFinish: ue.groupExam.scheduledFinish,
      status: ue.status,
      times: ue.groupExam.times,
      timeLimit: ue.groupExam.timeLimit,
    }));
    return {
      forTrainerOrAdmin: false,
      contents,
      exams,
    };
  }
}

export const GetHomeContentsKey = injectionKeyOf<GetHomeContents>({
  boundedContext: 'home',
  type: 'usecase',
  name: 'GetHomeContents',
});

export function useGetHomeContents(): GetHomeContents {
  return requiredInject(GetHomeContentsKey);
}
