import {
  CreateEditingConfirmedContentInput,
  EditingStatus as AmplifyEditingStatus,
  UpdateEditingConfirmedContentInput,
} from '@/API';
import {
  AppContextProvider,
  ContentId,
  ContentName,
  ContentType,
  ContentVersion,
  CourseId,
  CourseVersion,
  DataVersion,
  UserId,
} from '@/base/domains';
import { ISODateTimeString, Minute, Optional } from '@/base/types';
import {
  createEditingConfirmedContentEntity,
  EditingConfirmedContentData,
  EditingConfirmedContentEntity,
  EditingConfirmedContentRepository,
  EditingStatus,
  WorkbookData,
} from '@/contents/domains';
import * as mutations from '@/graphql/mutations';
import * as queries from '@/graphql/queries';
import { graphql, graphqlQuery } from '@/utils/AmplifyUtils';
import { hasNonNullProperty, ifDefined, isDefined, requiredNonNull } from '@/utils/TsUtils';

import {
  AmplifyContentBody,
  AmplifyWorkbook,
  toAmplifyContentBody,
  toAmplifyWorkbook,
  toExamContentBody,
  toTextContentBody,
  toWorkbook,
} from './Content';

export type AmplifyEditingConfirmedContent = {
  id: ContentId;
  version: ContentVersion;
  name: ContentName;
  requiredTime: Minute;
  body: AmplifyContentBody;
  workbook?: AmplifyWorkbook;
  createdAt: ISODateTimeString;
  createdBy: UserId;
  updatedAt: ISODateTimeString;
  updatedBy: UserId;
  courseId: CourseId;
  courseVersion: CourseVersion;
  dataVersion: DataVersion;
  status: AmplifyEditingStatus;
  versionDescription?: string;
};

export function toEditingConfirmedContent(
  e: AmplifyEditingConfirmedContent
): EditingConfirmedContentEntity {
  const type = e.body.type.toLocaleLowerCase() as ContentType;

  if (type === 'exam') {
    return createEditingConfirmedContentEntity({
      id: e.id,
      type,
      name: e.name,
      requiredTime: e.requiredTime,
      body: toExamContentBody(e.body),
      courseId: e.courseId,
      courseVersion: e.courseVersion,
      version: e.version,
      dataVersion: e.dataVersion,
      status: e.status.toLowerCase() as EditingStatus,
      versionDescription: e.versionDescription,
    });
  }
  if (type === 'text') {
    return createEditingConfirmedContentEntity({
      id: e.id,
      type,
      name: e.name,
      requiredTime: e.requiredTime,
      body: toTextContentBody(e.body),
      courseId: e.courseId,
      courseVersion: e.courseVersion,
      workbook: ifDefined(e.workbook, toWorkbook),
      version: e.version,
      dataVersion: e.dataVersion,
      status: e.status.toLowerCase() as EditingStatus,
      versionDescription: e.versionDescription,
    });
  }
  throw new Error('Unsupported type');
}

export class AmplifyEditingConfirmedContentRepository implements EditingConfirmedContentRepository {
  private appContextProvider: AppContextProvider;

  constructor(appContextProvider: AppContextProvider) {
    this.appContextProvider = appContextProvider;
  }

  async save(
    args: EditingConfirmedContentEntity | EditingConfirmedContentData
  ): Promise<EditingConfirmedContentEntity> {
    const tenantCode = requiredNonNull(
      this.appContextProvider.get().tenantCode,
      'appContext.tenantCode'
    );
    const userId = requiredNonNull(this.appContextProvider.get().user?.id, 'appContext.user.id');

    if (isDefined(args.dataVersion)) {
      const input: UpdateEditingConfirmedContentInput = {
        id: args.id,
        requiredTime: args.requiredTime,
        body: toAmplifyContentBody(args.body),
        workbook: hasNonNullProperty(args, 'workbook')
          ? toAmplifyWorkbook(args.workbook as WorkbookData)
          : undefined,
        updatedBy: userId,
        dataVersion: args.dataVersion + 1,
        expectedDataVersion: args.dataVersion,
        status: args.status.toUpperCase() as AmplifyEditingStatus,
        versionDescription: args.versionDescription,
      };
      const response = await graphql<{
        updateEditingConfirmedContent: AmplifyEditingConfirmedContent;
      }>(mutations.updateEditingConfirmedContent, { input });
      return toEditingConfirmedContent(response.updateEditingConfirmedContent);
    }
    const input: CreateEditingConfirmedContentInput = {
      id: args.id,
      version: args.version,
      name: args.name,
      requiredTime: args.requiredTime,
      body: toAmplifyContentBody(args.body),
      workbook: hasNonNullProperty(args, 'workbook')
        ? toAmplifyWorkbook(args.workbook as WorkbookData)
        : undefined,
      courseId: args.courseId,
      courseVersion: args.courseVersion,
      tenantCode,
      createdBy: userId,
      updatedBy: userId,
      status: AmplifyEditingStatus.EDITING,
      versionDescription: args.versionDescription,
    };
    const response = await graphql<{
      createEditingConfirmedContent: AmplifyEditingConfirmedContent;
    }>(mutations.createEditingConfirmedContent, { input });
    return toEditingConfirmedContent(response.createEditingConfirmedContent);
  }

  async findById(id: ContentId): Promise<Optional<EditingConfirmedContentEntity>> {
    const res = await graphqlQuery<{ getEditingConfirmedContent: AmplifyEditingConfirmedContent }>(
      queries.getEditingConfirmedContent,
      {
        id,
      }
    );
    return ifDefined(res.getEditingConfirmedContent, toEditingConfirmedContent);
  }

  async remove(id: ContentId): Promise<void> {
    await graphql(mutations.deleteEditingConfirmedContent, { input: { id } });
  }
}
