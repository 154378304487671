import { EditingTenantOwnTermsOfServiceRepositoryKey } from '@/admin/domains';
import {
  AmplifyEditingTenantOwnTermsOfServiceRepository,
  AmplifyTenantOwnTermsOfServiceQueries,
} from '@/admin/infrastructures/amplify';
import {
  AuthServiceKey,
  ContentLearningDataAdapterKey,
  DateTimeServiceKey,
  EventStoreKey,
  ExamDataAdapterKey,
  FileStorageKey,
  GroupRepositoryKey,
  GroupStatusServiceKey,
  InternalTaskServiceKey,
  NotificationRepositoryKey,
  PrivacyPolicyDataAdapterKey,
  ScheduleTagDataAdapterKey,
  SignUpReservationQueriesKey,
  SurviverKey,
  TenantDataAdapterKey,
  TermsOfServiceDataAdapterKey,
  useAppContextProvider,
  UserRepositoryKey,
  UserTagRepositoryKey,
} from '@/base/domains';
import { GroupStatusQueriesKey, TenantOwnTermsOfServiceQueriesKey } from '@/base/domains/queries';
import {
  AmplifyAuthService,
  AmplifyDateTimeService,
  AmplifyEventStore,
  AmplifyExamDataAdapter,
  AmplifyFileStorage,
  AmplifyGroupRepository,
  AmplifyGroupStatusQueries,
  AmplifyGroupStatusService,
  AmplifyInternalTaskService,
  AmplifyNotificationRepository,
  AmplifyPrivacyPolicyDataAdapter,
  AmplifySignUpReservationQueries,
  AmplifySurviver,
  AmplifyTenantDataAdapter,
  AmplifyTermsOfServiceDataAdapter,
  AmplifyUserRepository,
  AmplifyUserTagRepository,
} from '@/base/infrastructures/amplify';
import {
  ContentRepositoryKey,
  CourseHeaderRepositoryKey,
  CourseRepositoryKey,
  EditingConfirmedContentRepositoryKey,
  EditingCourseContentBodyRepositoryKey,
  EditingCourseContentWorkbookRepositoryKey,
  EditingCourseRepositoryKey,
} from '@/contents/domains';
import {
  ContentQueriesKey,
  EditingConfirmedContentQueriesKey,
  EditingCourseContentBodyQueriesKey,
  EditingCourseQueriesKey,
} from '@/contents/domains/queries';
import {
  AmplifyContentQueries,
  AmplifyContentRepository,
  AmplifyCourseHeaderRepository,
  AmplifyCourseRepository,
  AmplifyEditingConfirmedContentQueries,
  AmplifyEditingConfirmedContentRepository,
  AmplifyEditingCourseContentBodyQueries,
  AmplifyEditingCourseContentBodyRepository,
  AmplifyEditingCourseContentWorkbookRepository,
  AmplifyEditingCourseQueries,
  AmplifyEditingCourseRepository,
} from '@/contents/infrastructures/amplify';
import { PlaygroundRepositoryKey } from '@/playground/domains';
import { AmplifyPlaygroundRepository } from '@/playground/infrastructures/amplify';
import {
  GroupExamRepositoryKey,
  GroupTrainingCourseRepositoryKey,
  GroupTrainingRepositoryKey,
  MemoRepositoryKey,
  QuestionnaireRepositoryKey,
  QuestionRepositoryKey,
  ReviewProblemRepositoryKey,
  ScheduleRepositoryKey,
  ScheduleTagRepositoryKey,
} from '@/training/domains';
import { QuestionQueriesKey } from '@/training/domains/queries';
import {
  AmplifyContentLearningDataAdapter,
  AmplifyGroupExamRepository,
  AmplifyGroupTrainingCourseRepository,
  AmplifyGroupTrainingRepository,
  AmplifyMemoRepository,
  AmplifyQuestionnaireRepository,
  AmplifyQuestionQueries,
  AmplifyQuestionRepository,
  AmplifyReviewProblemRepository,
  AmplifyScheduleRepository,
  AmplifyScheduleTagDataAdapter,
  AmplifyScheduleTagRepository,
} from '@/training/infrastructures/amplify';
import { provide } from '@/utils/VueUtils';

export function amplifyAdapters() {
  const appContextProvider = useAppContextProvider();
  const tenantDataAdapter = new AmplifyTenantDataAdapter(appContextProvider);
  // admin
  provide(
    EditingTenantOwnTermsOfServiceRepositoryKey,
    new AmplifyEditingTenantOwnTermsOfServiceRepository(appContextProvider)
  );
  provide(
    TenantOwnTermsOfServiceQueriesKey,
    new AmplifyTenantOwnTermsOfServiceQueries(appContextProvider)
  );

  // base
  const userRepository = new AmplifyUserRepository(appContextProvider);
  provide(UserRepositoryKey, userRepository);
  provide(AuthServiceKey, new AmplifyAuthService(userRepository, appContextProvider));
  provide(GroupRepositoryKey, new AmplifyGroupRepository(appContextProvider));
  provide(NotificationRepositoryKey, new AmplifyNotificationRepository(appContextProvider));
  const internalTaskService = new AmplifyInternalTaskService(appContextProvider);
  provide(InternalTaskServiceKey, internalTaskService);
  provide(FileStorageKey, new AmplifyFileStorage(internalTaskService));
  provide(EventStoreKey, new AmplifyEventStore(appContextProvider));
  provide(ExamDataAdapterKey, new AmplifyExamDataAdapter(appContextProvider));
  provide(TenantDataAdapterKey, tenantDataAdapter);
  provide(
    EditingCourseContentBodyRepositoryKey,
    new AmplifyEditingCourseContentBodyRepository(appContextProvider)
  );
  provide(
    EditingCourseContentWorkbookRepositoryKey,
    new AmplifyEditingCourseContentWorkbookRepository(appContextProvider)
  );
  provide(EditingCourseRepositoryKey, new AmplifyEditingCourseRepository(appContextProvider));
  provide(SignUpReservationQueriesKey, new AmplifySignUpReservationQueries(appContextProvider));
  provide(DateTimeServiceKey, new AmplifyDateTimeService());
  provide(TermsOfServiceDataAdapterKey, new AmplifyTermsOfServiceDataAdapter());
  provide(SurviverKey, new AmplifySurviver(appContextProvider));
  provide(UserTagRepositoryKey, new AmplifyUserTagRepository(appContextProvider));
  const groupStatusQueries = new AmplifyGroupStatusQueries();
  provide(GroupStatusQueriesKey, groupStatusQueries);
  provide(GroupStatusServiceKey, new AmplifyGroupStatusService(groupStatusQueries));
  provide(QuestionnaireRepositoryKey, new AmplifyQuestionnaireRepository(appContextProvider));
  provide(PrivacyPolicyDataAdapterKey, new AmplifyPrivacyPolicyDataAdapter());

  // contents
  provide(CourseHeaderRepositoryKey, new AmplifyCourseHeaderRepository(appContextProvider));
  provide(ContentQueriesKey, new AmplifyContentQueries(appContextProvider));
  provide(EditingCourseContentBodyQueriesKey, new AmplifyEditingCourseContentBodyQueries());

  // training
  provide(CourseRepositoryKey, new AmplifyCourseRepository(appContextProvider));
  provide(ContentRepositoryKey, new AmplifyContentRepository(appContextProvider));
  provide(GroupTrainingRepositoryKey, new AmplifyGroupTrainingRepository(appContextProvider));
  provide(
    GroupTrainingCourseRepositoryKey,
    new AmplifyGroupTrainingCourseRepository(appContextProvider)
  );
  provide(QuestionRepositoryKey, new AmplifyQuestionRepository(appContextProvider));
  provide(ScheduleRepositoryKey, new AmplifyScheduleRepository(appContextProvider));
  provide(ContentLearningDataAdapterKey, new AmplifyContentLearningDataAdapter());
  provide(ScheduleTagRepositoryKey, new AmplifyScheduleTagRepository(appContextProvider));
  provide(ScheduleTagDataAdapterKey, new AmplifyScheduleTagDataAdapter());
  provide(GroupExamRepositoryKey, new AmplifyGroupExamRepository(appContextProvider));
  provide(EditingCourseQueriesKey, new AmplifyEditingCourseQueries());
  provide(
    EditingConfirmedContentRepositoryKey,
    new AmplifyEditingConfirmedContentRepository(appContextProvider)
  );
  provide(
    EditingConfirmedContentQueriesKey,
    new AmplifyEditingConfirmedContentQueries(appContextProvider)
  );
  provide(QuestionQueriesKey, new AmplifyQuestionQueries(appContextProvider));
  provide(MemoRepositoryKey, new AmplifyMemoRepository(appContextProvider));
  provide(ReviewProblemRepositoryKey, new AmplifyReviewProblemRepository(appContextProvider));

  // playground
  provide(PlaygroundRepositoryKey, new AmplifyPlaygroundRepository());
}
