import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { AuthService, FileStorage } from '../domains';
import { AbstractUseCase, UseCase, UseCaseResponse } from './UseCase';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SignOutRequest {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export type SignOutResponse = {};

export interface SignOut extends UseCase<SignOutRequest, SignOutResponse> {
  execute(request: SignOutRequest): Promise<UseCaseResponse<SignOutResponse>>;
}

export class SignOutImpl
  extends AbstractUseCase<SignOutRequest, SignOutResponse>
  implements SignOut
{
  private authService: AuthService;

  private fileStorage: FileStorage;

  constructor(authService: AuthService, fileStorage: FileStorage) {
    super('base.SignOut');
    this.authService = authService;
    this.fileStorage = fileStorage;
  }

  async internalExecute(_: SignOutRequest): Promise<SignOutResponse> {
    this.fileStorage.clearSignedCookie();
    await this.authService.signOut();
    return {};
  }
}

export const SignOutKey = injectionKeyOf<SignOut>({
  boundedContext: 'base',
  type: 'usecase',
  name: 'SignOut',
});

export function useSignOut(): SignOut {
  return requiredInject(SignOutKey);
}
