import {
  ContentId,
  ContentName,
  CourseDisplayName,
  CourseId,
  CourseName,
  GroupId,
  ScheduleTagId,
} from '@/base/domains';
import { errorCodeOf } from '@/base/error';

export const FAILED_FINISHING_EXAM = errorCodeOf(
  'TR0001',
  '予期せぬエラーによりテストが完了できませんでした'
);

export const GROUP_EXAM_ALREADY_STARTED = errorCodeOf('TR0002', 'テストはすでに開催されています');

export const FAILED_STARTING_EXAM = errorCodeOf('TR0003', 'テストが開始できません');

export const GROUP_TRAINING_DUPLICATED_COURSE = errorCodeOf<{
  groupId: GroupId;
  courseId: CourseId;
}>('TR0004', 'コースがすでにコースに登録されています');

export const GROUP_COURSE_LIMIT_EXCEEDED = errorCodeOf<{ groupId: GroupId }>(
  'TR0005',
  'これ以上コースをグループに登録できません'
);

export const GROUP_COURSE_REMOVE_FAILED_GROUP_EXAM_IS_OPEN = errorCodeOf(
  'TR0006',
  '開催中または予約中のテストがあるためグループからコースを削除できません'
);

export const SCHEDULE_TAGS_DO_NOT_EXIST = errorCodeOf<{ tagIds: Array<ScheduleTagId> }>(
  'TR0007',
  'タグが削除されているため利用できません'
);

export const GROUP_TRAINING_DUPLICATED_COURSE_DISPLAY_NAME = errorCodeOf<{
  groupId: GroupId;
  courseDisplayName: CourseDisplayName;
}>('TR0008', 'コースの表示名が重複しています');

export const GROUP_EXAM_REQUEST_INCLUDES_NO_AFFILIATION_USERS = errorCodeOf<{
  groupId: GroupId;
  courseId: CourseId;
  courseName: CourseName;
  courseDisplayName: CourseDisplayName;
  contentId: ContentId;
  contentName: ContentName;
}>('TR0009', 'グループに所属していないユーザーを指定してテストを開始できません');

export const SCHEDULE_TAG_LIMITATION_EXCEEDED = errorCodeOf<{}>(
  'TR0010',
  'ユーザーにつけるタグの上限を超過しています'
);

export const GROUP_EXAM_ALREADY_FINISHED = errorCodeOf('TR0011', 'テストはすでに終了しています');
