import {
  ContentBody,
  ContentId,
  ContentName,
  ContentVersion,
  CourseId,
  CourseVersion,
  Entity,
  Exam,
  Text,
  Workbook,
} from '@/base/domains';
import { Minute, Optional } from '@/base/types';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { WorkbookData } from './Workbook';

type ContentBase = {
  /** 名前 */
  name: ContentName;
  /** 最新 */
  latest: boolean;
  /** 所要時間 */
  requiredTime: Minute;
  /** コースID */
  courseId: CourseId;
  /** コースバージョン */
  courseVersion: CourseVersion;
  /** バージョン */
  version: ContentVersion;
  /** バージョン説明 */
  versionDescription?: string;
};

export type ExamContentAttributes = ContentBase & {
  /** コンテンツタイプ */
  type: 'exam';
  /** コンテンツの本体 */
  body: Exam;
};

export type TextContentAttributes = ContentBase & {
  /** コンテンツタイプ */
  type: 'text';
  /** コンテンツの本体 */
  body: Text;
  /** 練習問題 */
  workbook?: Workbook;
};

/**
 * コンテンツの属性。
 */
export type ContentAttributes = ExamContentAttributes | TextContentAttributes;

/**
 * コンテンツデータ
 */
export type ContentData = { id?: ContentId } & (
  | ExamContentAttributes
  | (Omit<TextContentAttributes, 'workbook'> & { workbook?: WorkbookData })
);

export type ContentEntityUpdateArgs = {
  name: ContentName;
  requiredTime: Minute;
  body: ContentBody;
  workbook?: WorkbookData;
};

export type ContentQueries = {};

/**
 * テストコンテンツコマンド
 */
export type ExamContentCommands = {
  update(args: ContentEntityUpdateArgs): ExamContentEntity;
  changeVersionDescription(versionDescription: string): ExamContentEntity;
  changePassingStandard(passingStandard: number): ExamContentEntity;
  clearPassingStandard(): ExamContentEntity;
};

/**
 * テキストコンテンツコマンド
 */
export type TextContentCommands = {
  update(args: ContentEntityUpdateArgs): ContentEntity;
  changeVersionDescription(versionDescription: string): ContentEntity;
};

/**
 * コンテンツコマンド
 */
export type ContentCommands = ExamContentCommands | TextContentCommands;

/**
 * コンテンツリファレンス
 */
export type ContentReference = Readonly<ContentAttributes> &
  ContentQueries & {
    readonly id: ContentId;
  };

export type ExamContentEntity = Entity<ContentId, ExamContentAttributes> &
  ContentQueries &
  ExamContentCommands;

export type TextContentEntity = Entity<ContentId, TextContentAttributes> &
  ContentQueries &
  TextContentCommands;

/**
 * コンテンツエンティティ
 */
export type ContentEntity = ExamContentEntity | TextContentEntity;
export const ContentRepositoryKey = injectionKeyOf<ContentRepository>({
  boundedContext: 'contents',
  type: 'adapter',
  name: 'ContentRepository',
});

export function useContentRepository(): ContentRepository {
  return requiredInject(ContentRepositoryKey);
}

/**
 * コンテンツリポジトリ。
 */
export interface ContentRepository {
  findById(id: ContentId, version?: ContentVersion): Promise<Optional<ContentEntity>>;
  findTenantContents(): Promise<Array<ContentEntity>>;
  findByCourseId(courseId: CourseId, courseVersion: CourseVersion): Promise<Array<ContentEntity>>;
  save(entity: ContentEntity): Promise<ContentEntity>;
}
