import { CourseId, CourseVersion, GroupId } from '@/base/domains';
import {
  GroupTraining,
  GroupTrainingCourse,
  GroupTrainingFinder,
} from '@/base/domains/GroupTraining';
import { Optional } from '@/base/types';
import { assertEntityExists } from '@/base/usecases';
import { isDefined } from '@/utils/TsUtils';

import { GroupTrainingRepository } from './GroupTraining';
import { GroupTrainingCourseRepository } from './GroupTrainingCourse';

export class GroupTrainingFinderImpl implements GroupTrainingFinder {
  private groupTrainingRepository: GroupTrainingRepository;

  private groupTrainingCourseRepository: GroupTrainingCourseRepository;

  constructor(
    groupTrainingRepository: GroupTrainingRepository,
    groupTrainingCourseRepository: GroupTrainingCourseRepository
  ) {
    this.groupTrainingRepository = groupTrainingRepository;
    this.groupTrainingCourseRepository = groupTrainingCourseRepository;
  }

  async findByCourseId(
    courseId: CourseId
  ): Promise<Array<GroupTraining & { courseVersion: CourseVersion }>> {
    const courses = await this.groupTrainingCourseRepository.findCoursesByCourseId(courseId);
    const list = await Promise.all(
      courses.map((cr) =>
        this.groupTrainingRepository.findById(cr.groupTrainingId).then((gt) => {
          assertEntityExists(gt, 'groupTraining');
          return {
            ...gt,
            courseVersion: cr.courseVersion,
          };
        })
      )
    );
    return list;
  }

  async findCoursesByGroupId(groupId: GroupId): Promise<Array<GroupTrainingCourse>> {
    const courses = await this.groupTrainingCourseRepository.findCoursesByGroupId(groupId);
    return courses.map((cr) => ({
      id: cr.courseId,
      name: cr.courseName,
      displayName: cr.displayName,
      version: cr.courseVersion,
      contents: cr.contents.map((cn) => ({
        type: cn.type,
        name: cn.name,
        id: cn.id,
        requiredTime: cn.requiredTime,
        recommendedDateTime: cn.recommendedDateTime,
        open: cn.open,
        version: cn.version,
      })),
      color: cr.color,
      image: cr.image,
      fontColorOnImage: cr.fontColorOnImage,
      index: cr.index,
    }));
  }

  async findCourseByGroupIdAndCourseId(
    groupId: GroupId,
    courseId: CourseId
  ): Promise<Optional<GroupTrainingCourse>> {
    const course = await this.groupTrainingCourseRepository.findByGroupIdAndCourseId(
      groupId,
      courseId
    );
    if (isDefined(course)) {
      return {
        id: course.courseId,
        contents: course.contents,
        name: course.courseName,
        displayName: course.displayName,
        version: course.courseVersion,
        color: course.color,
        image: course.image,
        fontColorOnImage: course.fontColorOnImage,
      };
    }
    return undefined;
  }

  async findCourseByGroupIdAndCourseDisplayName(
    groupId: string,
    courseDisplayName: string
  ): Promise<Optional<GroupTrainingCourse>> {
    const course = await this.groupTrainingCourseRepository.findByGroupIdAndCourseDisplayName(
      groupId,
      courseDisplayName
    );
    if (isDefined(course)) {
      return {
        id: course.courseId,
        contents: course.contents,
        name: course.courseName,
        displayName: course.displayName,
        version: course.courseVersion,
        color: course.color,
        image: course.image,
        fontColorOnImage: course.fontColorOnImage,
      };
    }
    return undefined;
  }

  findByGroupId(groupId: GroupId): Promise<Optional<GroupTraining>> {
    return this.groupTrainingRepository.findByGroupId(groupId);
  }
}
