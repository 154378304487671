import {
  AuthorizationService,
  ContentFinder,
  ContentId,
  ContentVersion,
  CourseColor,
  CourseDisplayName,
  CourseId,
  CourseName,
  CourseVersion,
  GroupId,
  GroupTrainingId,
} from '@/base/domains';
import { Optional, URI } from '@/base/types';
import { AbstractUseCase, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import {
  GroupTrainingCourseContent,
  GroupTrainingCourseId,
  GroupTrainingCourseRepository,
} from '../domains';

export interface GetGroupTrainingCourseRequest {
  groupId: GroupId;
  courseId: CourseId;
}

export type GetGroupTrainingCourseGroupTrainingCourse = {
  id: GroupTrainingCourseId;
  groupTrainingId: GroupTrainingId;
  courseId: CourseId;
  courseVersion: CourseVersion;
  courseName: CourseName;
  contents: Array<
    GroupTrainingCourseContent & {
      versions: Array<{ version: ContentVersion; versionDescription?: string }>;
    }
  >;
  groupId: GroupId;
  color?: CourseColor;
  image?: URI;
  displayName: CourseDisplayName;
  hasNewContentVersion: boolean;
};

export type GetGroupTrainingCourseResponse = {
  groupTrainingCourse: Optional<GetGroupTrainingCourseGroupTrainingCourse>;
};

export interface GetGroupTrainingCourse
  extends UseCase<GetGroupTrainingCourseRequest, GetGroupTrainingCourseResponse> {
  execute(
    request: GetGroupTrainingCourseRequest
  ): Promise<UseCaseResponse<GetGroupTrainingCourseResponse>>;
}

export class GetGroupTrainingCourseImpl
  extends AbstractUseCase<GetGroupTrainingCourseRequest, GetGroupTrainingCourseResponse>
  implements GetGroupTrainingCourse
{
  constructor(
    private authorizationService: AuthorizationService,
    private groupTrainingCourseRepository: GroupTrainingCourseRepository,
    private contentFinder: ContentFinder
  ) {
    super('training.GetGroupTrainingCourse');
  }

  async internalExecute(
    request: GetGroupTrainingCourseRequest
  ): Promise<GetGroupTrainingCourseResponse> {
    const { groupId, courseId } = request;
    this.authorizationService.assertGroupReadAccessible(groupId);
    const groupTrainingCourse = await this.groupTrainingCourseRepository.findByGroupIdAndCourseId(
      groupId,
      courseId
    );
    if (groupTrainingCourse) {
      const contentHeaders = await this.contentFinder.findContentHeadersByCourse({
        courseId: groupTrainingCourse.courseId,
        courseVersion: groupTrainingCourse.courseVersion,
      });
      const contentVersions = contentHeaders.reduce((acc, v) => {
        const vs = acc.get(v.id) ?? [];
        acc.set(v.id, [
          ...vs,
          {
            version: v.version,
            versionDescription: v.versionDescription,
          },
        ]);
        return acc;
      }, new Map<ContentId, Array<{ version: ContentVersion; versionDescription?: string }>>());

      const sortContentVersions = (
        versions: Array<{ version: ContentVersion; versionDescription?: string }>
      ) => {
        const vs = [...versions];
        vs.sort((a, b) => a.version - b.version);
        return vs;
      };
      const contents = groupTrainingCourse.contents.map((cn) => ({
        ...cn,
        versions: sortContentVersions(contentVersions.get(cn.id) ?? []),
      }));
      return {
        groupTrainingCourse: {
          ...groupTrainingCourse,
          contents,
          hasNewContentVersion: !!contents.find(
            (cn) => (cn.versions[cn.versions.length - 1]?.version ?? 0) > cn.version
          ),
        },
      };
    }
    return {
      groupTrainingCourse: undefined,
    };
  }
}

export const GetGroupTrainingCourseKey = injectionKeyOf<GetGroupTrainingCourse>({
  boundedContext: 'training',
  type: 'usecase',
  name: 'GetGroupTrainingCourse',
});

export function useGetGroupTrainingCourse(): GetGroupTrainingCourse {
  return requiredInject(GetGroupTrainingCourseKey);
}
