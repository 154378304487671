import { PrivacyPolicyDataAdapter, PrivacyPolicyVersion } from '@/base/domains';
import { Optional } from '@/base/types';
import * as queries from '@/graphql/queries';
import { graphql } from '@/utils/AmplifyUtils';

type AmplifyPrivacyPolicyVersion = {
  version: string;
};

export class AmplifyPrivacyPolicyDataAdapter implements PrivacyPolicyDataAdapter {
  async findPrivacyPolicyVersion(): Promise<Optional<PrivacyPolicyVersion>> {
    const res = await graphql<{ getPrivacyPolicyVersion: AmplifyPrivacyPolicyVersion }>(
      queries.getPrivacyPolicyVersion
    );
    return res.getPrivacyPolicyVersion.version;
  }
}
