import { Subscription } from '@/base/domains';
import { AbstractUseCase, assertEntityExists, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { PlaygroundId, PlaygroundReference, PlaygroundRepository } from '../domains';
import { PLAYGROUND_ALREADY_USED, PLAYGROUND_SHOULD_START_CREATING } from '../ErrorCodes';

export type SubscribePlaygroundCreatedRequest = {
  id: PlaygroundId;
  onSucceeded: (playground: PlaygroundReference) => void;
  onFailed: (e: Error) => void;
};

export type SubscribePlaygroundCreatedResponse = {
  /**
   * 終了時は自動でunsubscribeする。
   * クライアントの都合で購読不要になった場合はunsubscribeすること。
   */
  subscription: Subscription;
};

/**
 * プレイグラウンド作成を開始する
 */
export interface SubscribePlaygroundCreated
  extends UseCase<SubscribePlaygroundCreatedRequest, SubscribePlaygroundCreatedResponse> {
  execute(
    request: SubscribePlaygroundCreatedRequest
  ): Promise<UseCaseResponse<SubscribePlaygroundCreatedResponse>>;
}

export class SubscribePlaygroundCreatedImpl
  extends AbstractUseCase<SubscribePlaygroundCreatedRequest, SubscribePlaygroundCreatedResponse>
  implements SubscribePlaygroundCreated
{
  constructor(private playgroundRepository: PlaygroundRepository) {
    super('playground.SubscribePlaygroundCreated');
  }

  async internalExecute({
    id,
    onSucceeded,
    onFailed,
  }: SubscribePlaygroundCreatedRequest): Promise<SubscribePlaygroundCreatedResponse> {
    try {
      const playground = await this.playgroundRepository.findById(id);
      assertEntityExists(playground, 'playground');
      if (playground.status === 'used') {
        throw PLAYGROUND_ALREADY_USED.toApplicationError({ playgroundId: id });
      }
      if (playground.status === 'created') {
        onSucceeded(playground);
        return {
          subscription: {
            unsubscribe: () => {
              // do nothing
            },
            isClosed: () => true,
          },
        };
      }
      if (playground.status === 'reserved') {
        throw PLAYGROUND_SHOULD_START_CREATING.toApplicationError({ playgroundId: id });
      }

      const subscription = this.playgroundRepository.onCreated({
        id,
        onSucceeded,
        onFailed,
      });
      return {
        subscription,
      };
    } catch (e) {
      if (e instanceof Error) {
        onFailed(e);
      } else {
        onFailed(new Error(`unexpected error type; ${JSON.stringify(e)}`));
      }
      return {
        subscription: {
          unsubscribe: () => {
            // do nothing
          },
          isClosed: () => true,
        },
      };
    }
  }
}

export const SubscribePlaygroundCreatedKey = injectionKeyOf<SubscribePlaygroundCreated>({
  boundedContext: 'playground',
  type: 'usecase',
  name: 'SubscribePlaygroundCreated',
});

export function useSubscribePlaygroundCreated(): SubscribePlaygroundCreated {
  return requiredInject(SubscribePlaygroundCreatedKey);
}
