import { HandStatus as AmplifyHandStatus } from '@/API';
import { GroupId, UserId } from '@/base/domains';
import {
  GroupStatus,
  GroupStatusQueries,
  GroupStatusUser,
  HandStatus,
} from '@/base/domains/queries';
import { Optional } from '@/base/types';
import { getGroupStatus } from '@/graphql/queries';
import { graphqlQuery } from '@/utils/AmplifyUtils';
import { ifDefined } from '@/utils/TsUtils';

type GroupStatusUserId = string;

type AmplifyGroupStatusUser = {
  id: GroupStatusUserId;
  userId: UserId;
  groupId: GroupId;
  handStatus: AmplifyHandStatus;
};

type AmplifyGroupStatus = {
  groupId: GroupId;
  users: {
    items: Array<AmplifyGroupStatusUser>;
  };
};

function toGroupStatusUser(groupStatusUser: AmplifyGroupStatusUser): GroupStatusUser {
  return {
    userId: groupStatusUser.userId,
    handStatus: groupStatusUser.handStatus.toLocaleLowerCase() as HandStatus,
  };
}

function toGroupStatus(groupStatus: AmplifyGroupStatus): GroupStatus {
  return {
    groupId: groupStatus.groupId,
    users: groupStatus.users.items.map(toGroupStatusUser),
  };
}

export class AmplifyGroupStatusQueries implements GroupStatusQueries {
  async findByGroupId(groupId: GroupId): Promise<Optional<GroupStatus>> {
    const res = await graphqlQuery<{ getGroupStatus?: AmplifyGroupStatus }>(getGroupStatus, {
      groupId,
    });
    return ifDefined(res.getGroupStatus, toGroupStatus);
  }
}
