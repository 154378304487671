import { CommentId, ContentId, GroupId, Question, QuestionId, UserId } from '@/base/domains';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

export interface QuestionQueries {
  findRelatedQuestions(userId: UserId): Promise<Array<Question>>;
  findCommentHeaders(args: { groupId: GroupId }): Promise<
    Array<{
      id: CommentId;
      questionId: QuestionId;
      groupId: GroupId;
      contentId?: ContentId;
      createdBy: UserId;
    }>
  >;
}

export const QuestionQueriesKey = injectionKeyOf<QuestionQueries>({
  boundedContext: 'training',
  type: 'adapter',
  name: 'QuestionQueries',
});

export function useQuestionQueries(): QuestionQueries {
  return requiredInject(QuestionQueriesKey);
}
