import moment from 'moment';

import { TenantOwnTermsOfService } from '@/base/domains';

export const tenantOwnTermsOfService: TenantOwnTermsOfService = {
  tenantCode: 'tenant',
  body: '# 利用規約(v1)\n\n## インデックス\nテキストテキストテキストテキストテキストテキストテキストテキスト\nテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト\n## インデックス\nテキストテキストテキストテキストテキストテキストテキストテキスト\nテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト\n',
  version: 1,
  createdBy: 'user01',
  createdAt: moment().add(-3, 'hours'),
};
