import { v4 } from 'uuid';

import { EntityData, isEntityLike, QueryResponse, ScheduleId, ScheduleTagId } from '@/base/domains';
import { Optional } from '@/base/types';
import { assertEntityExists } from '@/base/usecases';
import {
  ScheduleAttributes,
  ScheduleEntity,
  ScheduleEntityImpl,
  ScheduleReference,
  ScheduleRepository,
  ScheduleRepositoryFindArgs,
  ScheduleRepositoryFindRecentlyArgs,
  ScheduleTagRepository,
} from '@/training/domains';
import { localDateTimeNow } from '@/utils/DateUtils';
import { hasNonNullProperty, isDefined } from '@/utils/TsUtils';

export class InMemoryScheduleRepository implements ScheduleRepository {
  private store: Map<ScheduleId, ScheduleEntity> = new Map();

  private scheduleTagRepository: ScheduleTagRepository;

  constructor(scheduleTagRepository: ScheduleTagRepository) {
    this.scheduleTagRepository = scheduleTagRepository;
  }

  save(args: ScheduleEntity | EntityData<ScheduleId, ScheduleAttributes>): Promise<ScheduleEntity> {
    const id = isEntityLike(args) ? args.id : v4();
    const entity = new ScheduleEntityImpl({
      ...args,
      id,
    });
    this.store.set(id, entity);
    return Promise.resolve(entity);
  }

  findById(id: ScheduleId): Promise<Optional<ScheduleEntity>> {
    return Promise.resolve(this.store.get(id));
  }

  remove(id: ScheduleId): Promise<void> {
    this.store.delete(id);
    return Promise.resolve();
  }

  find(args: ScheduleRepositoryFindArgs): Promise<QueryResponse<Array<ScheduleReference>>> {
    const { groupId, limit = 20, startFrom, startTo } = args;

    const filter = (() => {
      const l: Array<(e: ScheduleEntity) => boolean> = [];
      l.push((e) => e.groupId === groupId);
      l.push((e) => e.start.isSameOrAfter(startFrom) && e.start.isSameOrBefore(startTo));
      return (e: ScheduleEntity) => l.every((f) => f(e));
    })();

    const filtered = Array.from(this.store.values()).filter(filter);

    return Promise.resolve({
      data: filtered.slice(0, limit),
    });
  }

  findRecently(
    args: ScheduleRepositoryFindRecentlyArgs
  ): Promise<QueryResponse<ScheduleReference[]>> {
    const { limit = 20 } = args;
    const groupIds = hasNonNullProperty(args, 'groupIds') ? args.groupIds : [args.groupId];

    const now = localDateTimeNow();
    const filter = (() => {
      const l: Array<(e: ScheduleEntity) => boolean> = [];
      l.push((e) => groupIds.includes(e.groupId));
      l.push(
        (e) =>
          (!isDefined(e.end) && e.start.isSameOrAfter(now.add(-1, 'hour'))) ||
          (isDefined(e.end) && e.start.isSameOrAfter(now))
      );
      return (e: ScheduleEntity) => l.every((f) => f(e));
    })();

    const filtered = Array.from(this.store.values()).filter(filter);

    return Promise.resolve({
      data: filtered.slice(0, limit),
    });
  }

  async addTag(id: ScheduleId, tagId: ScheduleTagId): Promise<ScheduleReference> {
    const schedule = await this.findById(id);
    assertEntityExists(schedule, 'schedule');
    const tag = await this.scheduleTagRepository.findById(tagId);
    assertEntityExists(tag, 'scheduleTag');
    if (!schedule.tags.find((t) => t.id === tagId)) {
      return new ScheduleEntityImpl({
        ...schedule,
        tags: [...schedule.tags, tag],
      });
    }
    return schedule;
  }

  async removeTag(id: ScheduleId, tagId: ScheduleTagId): Promise<ScheduleReference> {
    const schedule = await this.findById(id);
    assertEntityExists(schedule, 'schedule');
    return new ScheduleEntityImpl({
      ...schedule,
      tags: schedule.tags.filter((t) => t.id !== tagId),
    });
  }
}
