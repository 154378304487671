import { hasNonNullProperty, hasProperty, isFunction } from '@/utils/TsUtils';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import {
  AuthService,
  Email,
  FileStorage,
  Locale,
  LocaleProvider,
  Password,
  SignedInUser,
  TenantCode,
  TenantDataAdapter,
  UserCode,
  UserId,
  UserRepository,
} from '../domains';
import { AbstractUseCase, UseCase, UseCaseResponse } from './UseCase';
import { assertEntityExists } from './UseCaseAsserts';

type SignInWithEmailRequest = {
  email: Email;
  password: Password;
  token?: string;
};

type SignInWithUserCodeRequest = {
  tenantCode: TenantCode;
  userCode: UserCode;
  password: Password;
};

export type SignInRequest = SignInWithEmailRequest | SignInWithUserCodeRequest;

function isSignInWithUserCodeRequest(request: SignInRequest): request is SignInWithUserCodeRequest {
  return hasProperty(request, 'userCode');
}

export type CompleteNewPasswordChallenge = (
  newPassword: Password
) => Promise<Omit<SignedInUser, 'groups'>>;

export type SignInResponse =
  | {
      user: Omit<SignedInUser, 'groups'>;
    }
  | {
      completeNewPasswordChallenge: CompleteNewPasswordChallenge;
    };

export function isNewPasswordChallengeResponse(res: SignInResponse): res is {
  completeNewPasswordChallenge: CompleteNewPasswordChallenge;
} {
  return hasNonNullProperty(res, 'completeNewPasswordChallenge');
}

export interface SignIn extends UseCase<SignInRequest, SignInResponse> {
  execute(request: SignInRequest): Promise<UseCaseResponse<SignInResponse>>;
}

export class SignInImpl extends AbstractUseCase<SignInRequest, SignInResponse> implements SignIn {
  constructor(
    private authService: AuthService,
    private fileStorage: FileStorage,
    private userRepository: UserRepository,
    private tenantDataAdapter: TenantDataAdapter,
    private localeProvider: LocaleProvider
  ) {
    super('base.SignIn');
  }

  private async setSignedInAtLeastOnce(id: UserId, tenantCode: TenantCode): Promise<Locale> {
    const domainUser = await this.userRepository.findById(id);
    assertEntityExists(domainUser, 'user');
    const tenant = await this.tenantDataAdapter.findByTenantCode(tenantCode);
    const locale =
      tenant.limitations.multilingualSupport === 'enabled' ? this.localeProvider.get() : 'ja';
    await this.userRepository.save(domainUser.firstSignIn(locale));
    return locale;
  }

  async internalExecute(request: SignInRequest): Promise<SignInResponse> {
    const { password } = request;
    const req = isSignInWithUserCodeRequest(request)
      ? {
          tenantCode: request.tenantCode,
          userCode: request.userCode,
          password,
        }
      : {
          email: request.email,
          password,
          userEmailConformationToken: request.token,
        };
    const res = await this.authService.signIn(req);

    const postSignedIn = async (
      user: Omit<SignedInUser, 'groups'> & { signedInAtLeastOnce: boolean }
    ) => {
      // ドメインのファイルを参照するため認証付きCookieを設定する。
      await this.fileStorage.setSignedCookie();
      if (user.signedInAtLeastOnce) {
        return {
          id: user.id,
          name: user.name,
          email: user.email,
          code: user.code,
          role: user.role,
          tenantCode: user.tenantCode,
          locale: user.locale,
          displaySettings: user.displaySettings,
          avatar: user.avatar,
          enabled: user.enabled,
          confirmedTermsOfServiceVersions: user.confirmedTermsOfServiceVersions,
        };
      }
      // 初回サインイン時はLocaleを初期設定する
      const locale = await this.setSignedInAtLeastOnce(user.id, user.tenantCode);
      return {
        id: user.id,
        name: user.name,
        email: user.email,
        code: user.code,
        role: user.role,
        tenantCode: user.tenantCode,
        locale,
        displaySettings: user.displaySettings,
        avatar: user.avatar,
        enabled: user.enabled,
        confirmedTermsOfServiceVersions: user.confirmedTermsOfServiceVersions,
      };
    };
    if (isFunction(res)) {
      return {
        completeNewPasswordChallenge: async (newPassword: Password) => {
          const user = await res(newPassword);
          const postSignedInResult = await postSignedIn(user);
          return postSignedInResult;
        },
      };
    }
    const postSignedInResult = await postSignedIn(res);
    return { user: postSignedInResult };
  }
}

export const SignInKey = injectionKeyOf<SignIn>({
  boundedContext: 'base',
  type: 'usecase',
  name: 'SignIn',
});

export function useSignIn(): SignIn {
  return requiredInject(SignInKey);
}
