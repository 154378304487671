import { AuthService, Email, Password } from '@/base/domains';
import { AbstractUseCase, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

export interface ChangePasswordRequest {
  email: Email;
  password: Password;
  code: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export type ChangePasswordResponse = {};

export interface ChangePassword extends UseCase<ChangePasswordRequest, ChangePasswordResponse> {
  execute(request: ChangePasswordRequest): Promise<UseCaseResponse<ChangePasswordResponse>>;
}

export class ChangePasswordImpl
  extends AbstractUseCase<ChangePasswordRequest, ChangePasswordResponse>
  implements ChangePassword
{
  private authService: AuthService;

  constructor(authService: AuthService) {
    super('account.ChangePassword');
    this.authService = authService;
  }

  async internalExecute(request: ChangePasswordRequest): Promise<ChangePasswordResponse> {
    await this.authService.changePassword(request);
    return {};
  }
}

export const ChangePasswordKey = injectionKeyOf<ChangePassword>({
  boundedContext: 'account',
  type: 'usecase',
  name: 'ChangePassword',
});

export function useChangePassword(): ChangePassword {
  return requiredInject(ChangePasswordKey);
}
