import * as customQueries from '@/amplify/customQueries';
import {
  GroupId,
  ScheduleColor,
  ScheduleTag,
  ScheduleTagDataAdapter,
  ScheduleTagId,
} from '@/base/domains';
import { graphql } from '@/utils/AmplifyUtils';

type AmplifyScheduleTag = {
  id: ScheduleTagId;
  color: ScheduleColor;
  text: string;
  groupId: GroupId;
};

function toScheduleTag(e: AmplifyScheduleTag): ScheduleTag {
  return {
    id: e.id,
    color: e.color,
    text: e.text,
  };
}

export class AmplifyScheduleTagDataAdapter implements ScheduleTagDataAdapter {
  async findScheduleTagsByGroupId(groupId: GroupId): Promise<Array<ScheduleTag>> {
    const res = await graphql<{ scheduleTagsByGroupId: { items: Array<AmplifyScheduleTag> } }>(
      customQueries.scheduleTagsByGroupId,
      { groupId }
    );
    return res.scheduleTagsByGroupId.items.map(toScheduleTag);
  }
}
