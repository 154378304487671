import { User, UserRepository } from '@/base/domains';
import { AbstractUseCase, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

export type GetUsersResponse = {
  users: Array<User>;
};

/**
 * ユーザー取得
 */
export interface GetUsers extends UseCase<{}, GetUsersResponse> {
  execute(): Promise<UseCaseResponse<GetUsersResponse>>;
}

export class GetUsersImpl extends AbstractUseCase<{}, GetUsersResponse> implements GetUsers {
  private userRepository: UserRepository;

  constructor(userRepository: UserRepository) {
    super('admin.GetUsers');
    this.userRepository = userRepository;
  }

  async internalExecute(): Promise<GetUsersResponse> {
    const users = await this.userRepository.findTenantEnabledUsers();
    return {
      users,
    };
  }
}

export const GetUsersKey = injectionKeyOf<GetUsers>({
  boundedContext: 'admin',
  type: 'usecase',
  name: 'GetUsers',
});

export function useGetUsers(): GetUsers {
  return requiredInject(GetUsersKey);
}
