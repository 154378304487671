import {
  computed,
  ComputedRef,
  getCurrentInstance,
  inject as vueInject,
  InjectionKey,
  isRef,
  provide as vueProvide,
  reactive,
  Ref,
  ref,
  UnwrapRef,
  watch,
} from '@vue/composition-api';
import {
  useLocalStorage as originalUseLocalStorage,
  useSessionStorage as originalUseSessionStorage,
} from '@vueuse/core';
import VueRouter, { Route } from 'vue-router';

import * as c from '@/config';
import { config } from '@/config';

import { assertIsDefined } from './Asserts';
import { isDefined, isObject } from './TsUtils';

const testServiceLocator = new Map<symbol, unknown>();

export function clearTestServiceLocator() {
  if (config().diType !== 'test') {
    throw new Error('this function is not callable in production mode');
  }
  testServiceLocator.clear();
}

// vue.provideのラッパー。
// Vueインスタンスを前提とした実装なのでテスト用に別の実装を使う。
export function provide<T>(key: InjectionKey<T>, value: T) {
  if (c.config().diType === 'test') {
    testServiceLocator.set(key as symbol, value);
    return;
  }
  vueProvide(key, value);
}

// vue.injectのラッパー。
// Vueインスタンスを前提とした実装なのでテスト用に別の実装を使う。
export function inject<T>(key: InjectionKey<T>): T | undefined {
  if (c.config().diType === 'test') {
    return testServiceLocator.get(key as symbol) as T;
  }

  return vueInject<T>(key);
}

export function requiredInject<T>(key: InjectionKey<T>): T {
  const v = inject<T>(key);
  if (v === undefined) {
    throw new Error(`key=${key.toString()} must be provided`);
  }
  return v;
}

// vue.readonlyの代替関数。
// vue3ではディープリードオンリーなRefが返されるが、
// この実装ではシャローリードオンリーとなっている。
// Storeで利用することを想定しているが、
// Componentでプロパティを変更しないように注意。
type UnwrapNestedRefs<T> = T extends Ref ? T : UnwrapRef<T>;
export function readonly<T extends object>(obj: T): Readonly<UnwrapNestedRefs<T>> {
  return computed(() => {
    if (isRef(obj)) {
      return obj.value;
    }
    return obj;
  }) as unknown as Readonly<UnwrapNestedRefs<T>>;
}

type InjectionKeyType = 'adapter' | 'usecase' | 'component' | 'store' | 'service';
export function injectionKeyOf<T>({
  type = 'component',
  name,
  boundedContext,
}: {
  type: InjectionKeyType;
  name: string;
  boundedContext: string;
}): InjectionKey<T> {
  return Symbol(`${boundedContext}.${type}.${name}`);
}

export const VueRouterKey = injectionKeyOf<VueRouter>({
  boundedContext: 'utils',
  type: 'component',
  name: 'VueRouter',
});

export const VueRouteKey = injectionKeyOf<Route>({
  boundedContext: 'utils',
  type: 'component',
  name: 'VueRoute',
});

/**
 * vue.useRouterの代替関数
 */
export function useRouter(): VueRouter {
  return requiredInject(VueRouterKey);
}

export type RouteLocationNormalizedLoaded = Route;
const ROUTE_KEYS = [
  'path',
  'name',
  'hash',
  'query',
  'params',
  'fullPath',
  'matched',
  'redirectedFrom',
  'meta',
];
/**
 * vue.useRouteの代替関数
 */
export function useRoute(): RouteLocationNormalizedLoaded {
  const currentInstance = getCurrentInstance();
  assertIsDefined(currentInstance, 'currentInstance');

  const currentRoute = computed(() => currentInstance.proxy.$route);
  const reactiveRoute = {} as {
    [k in keyof RouteLocationNormalizedLoaded]: ComputedRef<RouteLocationNormalizedLoaded[k]>;
  };
  ROUTE_KEYS.forEach((key) => {
    reactiveRoute[key] = computed(() => currentRoute.value[key]);
  });
  return reactive(reactiveRoute) as RouteLocationNormalizedLoaded;
}

export function setStoragePartitionKey(partitionKey: string): void {
  localStorage.setItem('PARTITION_KEY', partitionKey);
}

export function clearStoragePartitionKey(): void {
  localStorage.removeItem('PARTITION_KEY');
}

function getStoragePartitionKey(): string {
  const pKey = localStorage.getItem('PARTITION_KEY');
  return pKey ?? 'anonymous';
}

function useStorage<T>(
  key: string,
  defaultValue: T,
  internalRefFactory: () => Ref<Record<string, T>>
): Ref<T> {
  const pKey = getStoragePartitionKey();
  type Wrapped = Record<string, T>;
  function wrap(v: T): Wrapped {
    return { [pKey]: v };
  }
  function unwrap(v: Wrapped): T {
    return v[pKey];
  }
  const internalRef = internalRefFactory();
  // internalRefはオブジェクトであることを前提としている。
  // 他の型の値が入っている場合は初期化する。
  if (!isObject(internalRef.value)) {
    internalRef.value = {};
  }
  const currentValue = unwrap(internalRef.value);
  if (!isDefined(currentValue)) {
    internalRef.value = {
      ...internalRef.value,
      ...wrap(defaultValue),
    };
  }
  const dataRef = ref(currentValue ?? defaultValue) as Ref<T>;
  watch(dataRef, () => {
    internalRef.value = {
      ...internalRef.value,
      ...wrap(dataRef.value),
    };
  });
  watch(internalRef, () => {
    const v = unwrap(internalRef.value);
    if (dataRef.value !== v) {
      dataRef.value = v;
    }
  });
  return dataRef;
}

export function useLocalStorage(key: string, defaultValue: string, storage?: Storage): Ref<string>;
export function useLocalStorage(
  key: string,
  defaultValue: boolean,
  storage?: Storage
): Ref<boolean>;
export function useLocalStorage(key: string, defaultValue: number, storage?: Storage): Ref<number>;
export function useLocalStorage<T>(key: string, defaultValue: T, storage?: Storage): Ref<T>;
export function useLocalStorage<T = unknown>(
  key: string,
  defaultValue: null,
  storage?: Storage
): Ref<T>;
export function useLocalStorage<T extends string | number | boolean | object | null>(
  key: string,
  defaultValue: T,
  storage?: Storage
) {
  return useStorage(key, defaultValue, () =>
    originalUseLocalStorage<Record<string, T>>(key, {}, storage)
  );
}

export function useSessionStorage(
  key: string,
  defaultValue: string,
  storage?: Storage
): Ref<string>;
export function useSessionStorage(
  key: string,
  defaultValue: boolean,
  storage?: Storage
): Ref<boolean>;
export function useSessionStorage(
  key: string,
  defaultValue: number,
  storage?: Storage
): Ref<number>;
export function useSessionStorage<T>(key: string, defaultValue: T, storage?: Storage): Ref<T>;
export function useSessionStorage<T = unknown>(
  key: string,
  defaultValue: null,
  storage?: Storage
): Ref<T>;
export function useSessionStorage<T extends string | number | boolean | object | null>(
  key: string,
  defaultValue: T,
  storage?: Storage
) {
  return useStorage(key, defaultValue, () =>
    originalUseSessionStorage<Record<string, T>>(key, {}, storage)
  );
}
