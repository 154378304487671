import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { ErrorCode } from '../error';
import { LocalDateTime } from '../types';
import { UserId } from './Core';

export type InternalTaskId = string;

export type InternalTaskVersion = number;

export type InternalTaskError = { code: ErrorCode<{}>; payload?: Record<string, unknown> };

/**
 * 内部タスクのペイロード。
 * ペイロードのプロパティにはJSON.stringify => JSON.parseで壊れない型を利用すること。
 */
export type InternalTaskPayload = {
  errors: InternalTaskError[];
};

// export type InternalTaskType<P extends InternalTaskPayload> = string;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface InternalTaskType<T extends InternalTaskPayload> extends String {}

export function internalTaskTypeOf<T extends InternalTaskPayload>(
  key: string
): InternalTaskType<T> {
  return key;
}

export interface InternalTaskServiceStartRunner<
  T extends InternalTaskType<P>,
  P extends InternalTaskPayload
> {
  runWith(args: {
    payload: Omit<P, 'errors'> & { errors?: InternalTaskError[] };
    onFinished?: (task: InternalTask<T, P>) => void;
    onError?: (errors: InternalTaskError[]) => void;
  }): Promise<InternalTask<T, P>>;
}

export type InternalTaskStatus = 'in_progress' | 'failed' | 'succeeded';

export interface InternalTask<T extends InternalTaskType<P>, P extends InternalTaskPayload> {
  id: InternalTaskId;
  type: T;
  createdAt: LocalDateTime;
  createdBy: UserId | 'system';
  finishedAt?: LocalDateTime;
  status: InternalTaskStatus;
  payload: P;
  taskVersion: InternalTaskVersion;
}

export interface InternalTaskService {
  start<P extends InternalTaskPayload>(
    type: InternalTaskType<P>,
    taskVersion?: InternalTaskVersion
  ): InternalTaskServiceStartRunner<InternalTaskType<P>, P>;

  get<T extends InternalTaskType<P>, P extends InternalTaskPayload>(
    id: InternalTaskId
  ): Promise<InternalTask<T, P>>;

  subscribeTaskFinished<T extends InternalTaskType<P>, P extends InternalTaskPayload>(args: {
    id: InternalTaskId;
    onFinished: (task: InternalTask<T, P>) => void;
    onError: (errors: InternalTaskError[]) => void;
  }): void;
}

export const InternalTaskServiceKey = injectionKeyOf<InternalTaskService>({
  boundedContext: 'base',
  type: 'adapter',
  name: 'InternalTaskService',
});

export function useInternalTaskService(): InternalTaskService {
  return requiredInject(InternalTaskServiceKey);
}
