import { CourseId } from '@/base/domains';
import { EditingConfirmedContentReference } from '@/contents/domains';
import { EditingConfirmedContentQueries } from '@/contents/domains/queries';

export class InMemoryEditingConfirmedContentQueries implements EditingConfirmedContentQueries {
  async findTenantEditingConfirmedContents(): Promise<EditingConfirmedContentReference[]> {
    return [];
  }

  async findByCourseId(_: CourseId): Promise<EditingConfirmedContentReference[]> {
    return [];
  }
}
