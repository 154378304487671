import { ReviewProblemAttributes } from '@/training/domains';

export const reviewProblems: Array<ReviewProblemAttributes> = [
  {
    userId: 'admin01',
    groupId: 'group00',
    courseId: 'course-0',
    problems: [
      {
        index: 0,
        body: 'body',
        commentary: 'commentary',
        origin: {
          contentId: 'content-1',
          contentVersion: 1,
          problemIndex: 0,
        },
        hash: 'hash',
        type: 'choice',
        options: [
          { index: 0, text: 'option1' },
          { index: 1, text: 'option2' },
          { index: 2, text: 'option3' },
        ],
        multiple: true,
        answer: [0, 1, 2],
      },
    ],
  },
  {
    userId: 'admin02',
    groupId: 'group00',
    courseId: 'course-0',
    problems: [],
  },
  {
    userId: 'admin03',
    groupId: 'group00',
    courseId: 'course-0',
    problems: [],
  },
];
