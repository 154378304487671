import { computed, onMounted, ref, watch } from '@vue/composition-api';

import {
  Email,
  localEventBus,
  Password,
  Subscription,
  TenantCode,
  useLocaleProvider,
  UserCode,
} from '@/base/domains';
import { GroupId } from '@/base/domains/Group';
import {
  groupCreated,
  groupDescriptionChanged,
  groupDisabled,
  groupEnabled,
  groupExtensionConfigSaved,
  groupLimitationsChanged,
  groupNameChanged,
  groupRemoved,
  groupUserAdded,
  groupUserRemoved,
  termsOfServiceVersionsConfirmed,
  userAvatarChanged,
  userDisplaySettingsChanged,
  userLocaleChanged,
  userNameChanged,
  userRoleChangedByAdmin,
  usersDisabled,
} from '@/base/domains/LocalEvents';
import { Optional } from '@/base/types';
import {
  assertIsSucceeded,
  isNewPasswordChallengeResponse,
  useLive,
  useScheduleResetSignedCookie,
  useSignIn,
  useSignOut,
  useSubscribeUserStatusChanged,
} from '@/base/usecases';
import { config } from '@/config';
import { createLogger } from '@/utils/log';
import { isDefined } from '@/utils/TsUtils';
import { uuid } from '@/utils/UniqueIdGenerator';
import {
  clearStoragePartitionKey,
  injectionKeyOf,
  setStoragePartitionKey,
  useLocalStorage,
} from '@/utils/VueUtils';

import { useLimitation } from './Limitation';
import { useNotificationData } from './NotificationData';
import { useTenantData } from './TenantData';
import { GlobalStoreGroup } from './types';
import { useUserData } from './UserData';
import { useVersion } from './Version';

const logger = createLogger({ boundedContext: 'base', name: 'GlobalStore' });

/**
 * グローバルストア作成
 */
export function createGlobalStore() {
  const signInUsecase = useSignIn();
  const signOutUsecase = useSignOut();
  const localeProvider = useLocaleProvider();

  const {
    tenant,
    groups,
    groupsIncludeRemovedGroupUser,
    users,
    enabledUsers,
    tenantDataLoaded,
    fetchTenantData,
    findUserAndGroupRole,
    findUser,
    findGroup,
    onTenantDataFirstFetched,
    putUser,
  } = useTenantData();

  const groupId = ref<GroupId>();
  const group = computed<Optional<GlobalStoreGroup>>(() =>
    groups.value.find((gr) => gr.id === groupId.value)
  );
  const tenantName = computed(() => (isDefined(tenant.value) ? tenant.value.name : undefined));

  const {
    user,
    currentUserStatus,
    locale: userLocale,
    displaySettings,
    fetchUser,
    setUser,
    clearUser,
  } = useUserData(groups);

  const groupRole = computed(() => user.value?.groups.find((g) => g.id === groupId.value)?.role);
  const isSignedIn = computed(() => !!user.value);

  const applicationSessionId = useLocalStorage<string>(
    'base.globalStore.applicationSessionId',
    uuid(),
    sessionStorage
  );

  /**
   * ユーザーが所属するグループリスト
   */
  const userAssignedGroups = computed(() => {
    if (user.value) {
      const userId = user.value.id;
      return groups.value
        .map((gr) => {
          const userInGroup = gr.users.find((u) => u.id === userId);
          return userInGroup
            ? {
                ...gr,
                groupRole: userInGroup.role,
              }
            : undefined;
        })
        .filter(isDefined);
    }
    return [];
  });

  /**
   * アクセス可能なグループリスト
   */
  const accessibleGroups = computed(() => {
    if (user.value) {
      if (user.value.role === 'general') {
        return userAssignedGroups.value;
      }
      return groups.value;
    }
    return [];
  });

  /**
   * 過去のユーザーを含むグループリスト
   */
  const groupsIncludeRemovedUser = computed(() => {
    if (user.value) {
      if (user.value.role === 'general') {
        return userAssignedGroups.value;
      }
      return groupsIncludeRemovedGroupUser.value;
    }
    return [];
  });

  /**
   * サインイン
   * @param req リクエスト
   */
  async function signIn(
    req:
      | { email: Email; password: Password; token?: string }
      | {
          tenantCode: TenantCode;
          userCode: UserCode;
          password: Password;
        }
  ): Promise<string | ((newPassword: string) => Promise<string>)> {
    const res = await signInUsecase.execute(req);
    assertIsSucceeded(res);
    if (isNewPasswordChallengeResponse(res)) {
      return async (newPassword: string) => {
        const u = await res.completeNewPasswordChallenge(newPassword);
        setUser(u);
        return u.id;
      };
    }
    setUser(res.user);
    setStoragePartitionKey(res.user.id);
    return res.user.id;
  }

  /**
   * セッションクリア
   *
   * 何らかの理由でuserのトークンが有効でなくなった場合に実行する
   */
  function clearSession() {
    clearUser();
    groupId.value = undefined;
    clearStoragePartitionKey();
    sessionStorage.clear();
  }

  /**
   * サインアウト
   */
  async function signOut(): Promise<void> {
    await signOutUsecase.execute({});
    clearSession();
  }

  /**
   * グループId変更
   * @param value グループId
   */
  function changeGroupId(value: string) {
    groupId.value = value;
    logger.debug({
      message: 'groupId changed',
      groupId: value,
    });
  }

  watch(user, async (value, oldValue) => {
    if (value && value.id !== oldValue?.id) {
      fetchTenantData();
    }
  });

  onMounted(() => {
    logger.debug({
      message: 'on mounted',
    });
    if (user.value) {
      fetchTenantData();
    }
  });

  const isReady = computed(() => currentUserStatus.value !== 'unchecked');

  function onReady(f: () => void) {
    if (isReady.value) {
      f();
    } else {
      watch(isReady, (v) => {
        if (v) {
          f();
        }
      });
    }
  }

  localEventBus.subscribe(groupUserAdded, fetchTenantData);
  localEventBus.subscribe(groupNameChanged, fetchTenantData);
  localEventBus.subscribe(groupLimitationsChanged, fetchTenantData);
  localEventBus.subscribe(groupDescriptionChanged, fetchTenantData);
  localEventBus.subscribe(groupDisabled, fetchTenantData);
  localEventBus.subscribe(groupEnabled, fetchTenantData);
  localEventBus.subscribe(groupCreated, fetchTenantData);
  localEventBus.subscribe(groupRemoved, fetchTenantData);
  localEventBus.subscribe(groupUserRemoved, fetchTenantData);
  localEventBus.subscribe(groupExtensionConfigSaved, fetchTenantData);
  localEventBus.subscribe(usersDisabled, fetchTenantData);
  async function fetch(): Promise<void> {
    await Promise.all([fetchTenantData(), fetchUser()]);
  }
  localEventBus.subscribe(userAvatarChanged, fetch);
  localEventBus.subscribe(userDisplaySettingsChanged, fetch);
  localEventBus.subscribe(userLocaleChanged, fetch);
  localEventBus.subscribe(userNameChanged, fetch);
  localEventBus.subscribe(userRoleChangedByAdmin, fetch);
  localEventBus.subscribe(termsOfServiceVersionsConfirmed, fetch);

  // ファイルアクセスのための署名付きポリシーの設定
  // 署名付きポリシーの設定は次のイベントで行う
  //   - アプリケーション初期化（サインインしている かつ 期限が切れている場合のみ）
  //   - サインイン
  //   - 期限切れ前（スケジュール実行）*1
  // *1 スケジュール実行はwindow.blurで解除し、window.focusで再設定する
  // 署名付きポリシーのクリアは次のイベントで行う
  //   - サインアウト
  (() => {
    if (config().local) {
      return;
    }
    const scheduleResetSignedCookie = useScheduleResetSignedCookie();

    let cancel: (() => void) | undefined;
    watch(currentUserStatus, async (value, oldValue) => {
      if (value === 'signedIn') {
        if (oldValue === 'unchecked') {
          logger.debug({
            message: 'on ready;signed in',
          });
          const res = await scheduleResetSignedCookie.execute({});
          assertIsSucceeded(res);
          cancel = res.cancel;
          return;
        }
        logger.debug({
          message: 'on signed in',
        });
        const res = await scheduleResetSignedCookie.execute({});
        assertIsSucceeded(res);
        cancel = res.cancel;
        return;
      }
      if (value === 'notSignedIn') {
        logger.debug({
          message: 'on sign out',
        });
        if (cancel) {
          cancel();
          cancel = undefined;
        }
      }
    });
    window.addEventListener('blur', async () => {
      logger.debug({
        message: 'window.blur',
      });
      if (cancel) {
        cancel();
        cancel = undefined;
      }
    });
    window.addEventListener('focus', async () => {
      if (isSignedIn.value) {
        logger.debug({
          message: 'window.focus; isSignedIn=true',
        });
        // https://github.com/citycom/knowte/issues/850
        if (cancel) {
          return;
        }
        const res = await scheduleResetSignedCookie.execute({});
        assertIsSucceeded(res);
        cancel = res.cancel;
      }
    });
  })();

  // ユーザーの利用状況を記録する
  (() => {
    const live = useLive();
    const execLive = async () => {
      if (isSignedIn.value) {
        await live.execute({});
      }
    };
    onReady(() => {
      execLive();
      window.setInterval(() => {
        if (document.hasFocus()) {
          execLive();
        }
      }, 1000 * 60);
    });
  })();

  // ユーザーステータスの変更を検知してユーザーを更新する
  (() => {
    const subscribeUserStatusChanged = useSubscribeUserStatusChanged();
    let subscription: Optional<Subscription>;
    const subscribe = () => {
      const res = subscribeUserStatusChanged.execute({
        onChange: (u) => {
          putUser(u);
          logger.debug({
            message: 'onUserStatusChanged',
            u,
            users: users.value,
          });
        },
      });
      assertIsSucceeded(res);
      logger.debug({
        message: 'subscribe UserStatusChanged',
        users: users.value,
      });
      subscription = res.subscription;
    };

    const subscribeIfSignedIn = () => {
      if (isSignedIn.value) {
        if (subscription) subscription.unsubscribe();
        subscribe();
      } else {
        if (subscription) subscription.unsubscribe();
        subscription = undefined;
      }
    };

    const resubscribeIfClosed = () => {
      if (subscription && !subscription.isClosed()) {
        return;
      }
      if (!document.hasFocus()) {
        return;
      }
      subscribeIfSignedIn();
    };

    watch(isSignedIn, subscribeIfSignedIn);
    window.setInterval(resubscribeIfClosed, 10 * 1000);
  })();

  const isPlayground = computed(() => tenant.value?.playground ?? false);

  const locale = computed(() => {
    if (isSignedIn.value) {
      if (tenant.value?.limitations.multilingualSupport === 'enabled') return userLocale.value;
      return 'ja';
    }
    return localeProvider.get();
  });

  return {
    tenant,
    tenantName,
    groupId,
    group,
    user,
    locale,
    displaySettings,
    groupRole,
    isSignedIn,
    currentUserStatus,
    tenantDataLoaded,
    signIn,
    signOut,
    fetch,
    enabledUsers,
    users,
    groups,
    userAssignedGroups,
    accessibleGroups,
    groupsIncludeRemovedUser,
    findUser,
    findGroup,
    findUserAndGroupRole,
    changeGroupId,
    onReady,
    onTenantDataFirstFetched,
    applicationSessionId,
    isPlayground,
    ...useNotificationData(),
    ...useVersion(),
    ...useLimitation({ tenant, groups: accessibleGroups, group, user }),
  };
}

export type GlobalStore = ReturnType<typeof createGlobalStore>;

export const GlobalStoreKey = injectionKeyOf<GlobalStore>({
  boundedContext: 'base',
  type: 'store',
  name: 'GlobalStore',
});

let _globalStore: GlobalStore;
export function useGlobalStore() {
  // domain層からも参照の必要があるため実装を変更した
  // return requiredInject(GlobalStoreKey);
  if (!_globalStore) {
    _globalStore = createGlobalStore();
  }
  return _globalStore;
}
