import {
  Entity,
  EntityData,
  EntityReference,
  GroupId,
  GroupTrainingId,
  StandardRepository,
} from '@/base/domains';
import { Optional } from '@/base/types';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

/**
 * 属性
 */
export interface GroupTrainingAttributes {
  groupId: GroupId;
}

/**
 * データ
 */
export type GroupTrainingData = EntityData<GroupTrainingId, GroupTrainingAttributes>;

/**
 * コマンド
 */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface GroupTrainingCommand {}

/**
 * クエリ
 */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface GroupTrainingQueries {}

/**
 * リファレンス
 */
export type GroupTrainingReference = EntityReference<
  GroupTrainingId,
  GroupTrainingAttributes,
  GroupTrainingQueries
>;

/**
 * エンティティ
 */
export interface GroupTrainingEntity
  extends Entity<GroupTrainingId, GroupTrainingAttributes>,
    GroupTrainingQueries,
    GroupTrainingCommand {}

/**
 * リポジトリ
 */
export interface GroupTrainingRepository
  extends StandardRepository<GroupTrainingId, GroupTrainingAttributes, GroupTrainingEntity> {
  findByGroupId(groupId: GroupId): Promise<Optional<GroupTrainingEntity>>;
  findTenantGroupTrainings(): Promise<Array<GroupTrainingEntity>>;
}

export const GroupTrainingRepositoryKey = injectionKeyOf<GroupTrainingRepository>({
  boundedContext: 'training',
  type: 'adapter',
  name: 'GroupTrainingRepositoryKey',
});

export function useGroupTrainingRepository(): GroupTrainingRepository {
  return requiredInject(GroupTrainingRepositoryKey);
}
