import { AuthorizationService, GroupRole, Memo, MemoId } from '@/base/domains';
import { AbstractUseCase, assertEntityExists, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { MemoRepository } from '../domains';

export type ChangeScopeRolesOfMemoRequest = {
  id: MemoId;
  roles: GroupRole[];
};

export type ChangeScopeRolesOfMemoResponse = {
  memo: Memo;
};

export interface ChangeScopeRolesOfMemo
  extends UseCase<ChangeScopeRolesOfMemoRequest, ChangeScopeRolesOfMemoResponse> {
  execute(
    request: ChangeScopeRolesOfMemoRequest
  ): Promise<UseCaseResponse<ChangeScopeRolesOfMemoResponse>>;
}

export class ChangeScopeRolesOfMemoImpl
  extends AbstractUseCase<ChangeScopeRolesOfMemoRequest, ChangeScopeRolesOfMemoResponse>
  implements ChangeScopeRolesOfMemo
{
  constructor(
    private authorizationService: AuthorizationService,
    private memoRepository: MemoRepository
  ) {
    super('training.ChangeScopeRolesOfMemo');
  }

  async internalExecute(
    request: ChangeScopeRolesOfMemoRequest
  ): Promise<ChangeScopeRolesOfMemoResponse> {
    const { id, roles } = request;
    const memo = await this.memoRepository.findById(id);
    assertEntityExists(memo, 'memo');
    if (memo.scope.type === 'group') {
      this.authorizationService.assertTrainerOrMentorInGroup(memo.groupId);
    }
    const saved = await this.memoRepository.save(memo.updateScopeRoles(roles));
    return { memo: saved };
  }
}

export const ChangeScopeRolesOfMemoKey = injectionKeyOf<ChangeScopeRolesOfMemo>({
  boundedContext: 'training',
  type: 'usecase',
  name: 'ChangeScopeRolesOfMemo',
});

export function useChangeScopeRolesOfMemo(): ChangeScopeRolesOfMemo {
  return requiredInject(ChangeScopeRolesOfMemoKey);
}
