import { EditingCourseContentWorkbookData } from '@/contents/domains';

export const editingCourseContentWorkbooks: Array<EditingCourseContentWorkbookData> = [
  {
    id: 'content-1-0',
    courseId: 'course-1',
    problems: [
      {
        index: 0,
        type: 'choice',
        multiple: false,
        body: '問題文1\n[Google](https://www.google.com/?hl=ja)\n問題文1\n問題文1\n問題文1\n問題文1\n問題文1\n問題文1\n',
        commentary: '解説',
        answer: [0],
        options: [
          { index: 0, text: '選択肢1' },
          { index: 1, text: '選択肢2' },
        ],
      },
      {
        index: 1,
        type: 'choice',
        multiple: true,
        body: '問題文2',
        commentary: '解説',
        answer: [0],
        options: [
          { index: 0, text: '選択肢1' },
          { index: 1, text: '選択肢2' },
          { index: 2, text: '選択肢3' },
        ],
      },
    ],
    problemHeaders: [],
    dataVersion: 1,
  },
];
