import { AuthorizationService, GroupId, UserId } from '@/base/domains';
import { AbstractUseCase, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { CourseStatusRecord, ReportService } from '../domains';

export interface GetCourseStatusReportRequest {
  groupId: GroupId;
  userId: UserId;
}

export type GetCourseStatusReportResponse = {
  records: Array<CourseStatusRecord>;
};

/**
 * コースステータスレポートを取得する
 */
export interface GetCourseStatusReport
  extends UseCase<GetCourseStatusReportRequest, GetCourseStatusReportResponse> {
  execute(
    request: GetCourseStatusReportRequest
  ): Promise<UseCaseResponse<GetCourseStatusReportResponse>>;
}

export class GetCourseStatusReportImpl
  extends AbstractUseCase<GetCourseStatusReportRequest, GetCourseStatusReportResponse>
  implements GetCourseStatusReport
{
  private authorizationService: AuthorizationService;

  private reportService: ReportService;

  constructor(authorizationService: AuthorizationService, reportService: ReportService) {
    super('report.GetCourseStatusReport');
    this.authorizationService = authorizationService;
    this.reportService = reportService;
  }

  async internalExecute(
    request: GetCourseStatusReportRequest
  ): Promise<GetCourseStatusReportResponse> {
    const { groupId, userId } = request;
    this.authorizationService.assertGroupReadAccessible(groupId);
    const records = await this.reportService.findCourseStatusRecords(groupId, userId);
    return {
      records,
    };
  }
}

export const GetCourseStatusReportKey = injectionKeyOf<GetCourseStatusReport>({
  boundedContext: 'report',
  type: 'usecase',
  name: 'GetCourseStatusReport',
});

export function useGetCourseStatusReport(): GetCourseStatusReport {
  return requiredInject(GetCourseStatusReportKey);
}
