import {
  AuthorizationService,
  Group,
  GroupId,
  GroupRepository,
  localEventBus,
  UserId,
} from '@/base/domains';
import { groupUserRemoved } from '@/base/domains/LocalEvents';
import { AbstractUseCase, assertEntityExists, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

export interface RemoveGroupUserRequest {
  id: GroupId;
  userIds: Array<UserId>;
}

export type RemoveGroupUserResponse = {
  group: Group;
};

/**
 * ユーザー削除
 */
export interface RemoveGroupUser extends UseCase<RemoveGroupUserRequest, RemoveGroupUserResponse> {
  execute(request: RemoveGroupUserRequest): Promise<UseCaseResponse<RemoveGroupUserResponse>>;
}

export class RemoveGroupUserImpl
  extends AbstractUseCase<RemoveGroupUserRequest, RemoveGroupUserResponse>
  implements RemoveGroupUser
{
  private authorizationService: AuthorizationService;

  private groupRepository: GroupRepository;

  constructor(authorizationService: AuthorizationService, groupRepository: GroupRepository) {
    super('admin.RemoveGroupUser');
    this.authorizationService = authorizationService;
    this.groupRepository = groupRepository;
  }

  async internalExecute(request: RemoveGroupUserRequest): Promise<RemoveGroupUserResponse> {
    this.authorizationService.assertRole('supervisor', 'admin');
    const { id, userIds } = request;
    const group = await this.groupRepository.findById(id, { includeRemovedGroupUser: true });
    assertEntityExists(group, 'group');
    const saved = await this.groupRepository.save(group.removeUser(userIds));
    localEventBus.publish(groupUserRemoved({ groupId: id, userIds }));
    return {
      group: saved,
    };
  }
}

export const RemoveGroupUserKey = injectionKeyOf<RemoveGroupUser>({
  boundedContext: 'admin',
  type: 'usecase',
  name: 'RemoveGroupUser',
});

export function useRemoveGroupUser(): RemoveGroupUser {
  return requiredInject(RemoveGroupUserKey);
}
