import { config } from '@/config';

import { BoundedContext } from '../types';
import { AndroidLogCatTransporter } from './AndroidLogCatTransporter';
import { BrowserConsoleTransporter } from './BrowserConsoleTransporter';
import { ConsoleTransporter } from './ConsoleTransporter';
import { LogEntry, Logger } from './core';
import { LoggerImpl } from './LoggerImpl';

type ExtendedBoundedContext = BoundedContext | 'utils' | 'dev' | 'root' | 'di';

type CreateLoggerRequest = {
  boundedContext?: ExtendedBoundedContext;
  name: string;
};

const optionsBase = (() => {
  if (config().envType === 'node') {
    return {
      transporters: [
        new ConsoleTransporter({
          format: (entry: LogEntry) => {
            const label = (() => {
              if (entry.boundedContext) {
                return `${entry.boundedContext}.${entry.name}`;
              }
              return `${entry.name}`;
            })();
            return `${label} [${entry.level}] ${entry.message}`;
          },
          level: config().logLevel,
        }),
      ],
    };
  }
  if (window.$androidWebAppBridge) {
    return {
      transporters: [
        new AndroidLogCatTransporter({
          format: (entry: LogEntry) => {
            const label = (() => {
              if (entry.boundedContext) {
                return `${entry.boundedContext}.${entry.name}`;
              }
              return `${entry.name}`;
            })();
            // Converting circular structure to JSONが出るのでobjectは対象外とする
            const o = Object.keys(entry)
              .filter(
                (key) =>
                  !['level', 'boundedContext', 'name', 'message'].includes(key) &&
                  typeof entry[key] !== 'object'
              )
              .reduce((p, key) => ({ ...p, [key]: entry[key] }), {});
            return `${label} ${entry.message} ${JSON.stringify(o)}`;
          },
          level: config().logLevel,
        }),
      ],
    };
  }
  return {
    transporters: [
      new BrowserConsoleTransporter({
        format: (entry: LogEntry) => {
          return entry;
        },
        level: config().logLevel,
      }),
    ],
  };
})();

export function createLogger(request: CreateLoggerRequest): Logger {
  const { boundedContext, name } = request;
  const options = {
    ...optionsBase,
    defaultMeta: { boundedContext, name },
  };
  return new LoggerImpl(options);
}
