import {
  CreatePlaygroundInput,
  PlaygroundStatus as AmplifyPlaygroundStatus,
  UpdatePlaygroundInput,
} from '@/API';
import {
  Email,
  hasId,
  Password,
  Subscription,
  TenantCode,
  TenantName,
  UserCode,
} from '@/base/domains';
import { Optional } from '@/base/types';
import { assertEntityExists } from '@/base/usecases';
import * as mutations from '@/graphql/mutations';
import * as queries from '@/graphql/queries';
import {
  PlaygroundAttributes,
  PlaygroundEntity,
  PlaygroundEntityImpl,
  PlaygroundId,
  PlaygroundRepository,
  PlaygroundStatus,
} from '@/playground/domains';
import { graphql, graphqlQuery } from '@/utils/AmplifyUtils';
import { uuid } from '@/utils/UniqueIdGenerator';

type AmplifyPlayground = {
  id: PlaygroundId;
  status: AmplifyPlaygroundStatus;
  email: Email;
  tenantCode: TenantCode;
  tenantName: TenantName;
  userCode: UserCode;
  temporaryPassword?: Password;
};

function toPlayground(e: AmplifyPlayground): PlaygroundEntity {
  return new PlaygroundEntityImpl({
    id: e.id,
    status: e.status.toLocaleLowerCase() as PlaygroundStatus,
    email: e.email,
    tenantCode: e.tenantCode,
    tenantName: e.tenantName,
    userCode: e.userCode,
    temporaryPassword: e.temporaryPassword,
  });
}

export class AmplifyPlaygroundRepository implements PlaygroundRepository {
  async save(args: PlaygroundAttributes | PlaygroundEntity): Promise<PlaygroundEntity> {
    if (hasId(args)) {
      const input: UpdatePlaygroundInput = {
        id: args.id,
        email: args.email,
        tenantCode: args.tenantCode,
        tenantName: args.tenantName,
        status: args.status.toUpperCase() as AmplifyPlaygroundStatus,
        userCode: args.userCode,
      };
      const response = await graphql<{ updatePlayground: AmplifyPlayground }>(
        mutations.updatePlayground,
        { input },
        {},
        { publicAccess: true }
      );
      return toPlayground(response.updatePlayground);
    }
    const input: CreatePlaygroundInput = {
      id: uuid(),
      email: args.email,
      tenantCode: args.tenantCode,
      tenantName: args.tenantName,
      status: args.status.toUpperCase() as AmplifyPlaygroundStatus,
      userCode: args.userCode,
    };
    const response = await graphql<{ createPlayground: AmplifyPlayground }>(
      mutations.createPlayground,
      { input },
      {},
      { publicAccess: true }
    );
    return toPlayground(response.createPlayground);
  }

  async findById(id: PlaygroundId): Promise<Optional<PlaygroundEntity>> {
    const response = await graphqlQuery<{ getPlayground: Optional<AmplifyPlayground> }>(
      queries.getPlayground,
      { id },
      { publicAccess: true }
    );
    return response.getPlayground ? toPlayground(response.getPlayground) : undefined;
  }

  async findByTenantCode(tenantCode: TenantCode): Promise<Optional<PlaygroundEntity>> {
    const response = await graphqlQuery<{
      playgroundsByTenantCode: { items: Array<AmplifyPlayground> };
    }>(queries.playgroundsByTenantCode, { tenantCode }, { publicAccess: true });
    return response.playgroundsByTenantCode.items.map(toPlayground)[0];
  }

  // TODO https://github.com/citycom/knowte/issues/2166
  // これを回避するためポーリングで実装する
  // onCreated({
  //   id,
  //   onSucceeded,
  //   onFailed,
  // }: {
  //   id: string;
  //   onSucceeded: (playground: PlaygroundEntity) => void;
  //   onFailed: (e: Error) => void;
  // }): Subscription {
  //   const res = graphqlSubscribe<{
  //     value: { data: { onUpdatePlaygroundById: AmplifyPlayground } };
  //   }>(subscriptions.onUpdatePlaygroundById, { id }, { publicAccess: true });
  //   const subscription = res.subscribe(
  //     (x: { value: { data: { onUpdatePlaygroundById: AmplifyPlayground } } }) => {
  //       const playground = toPlayground(x.value.data.onUpdatePlaygroundById);
  //       if (playground.status === 'created') {
  //         onSucceeded(playground);
  //         subscription.unsubscribe();
  //       }
  //       if (playground.status === 'used') {
  //         onFailed(new Error('playground has already been used'));
  //         subscription.unsubscribe();
  //       }
  //     }
  //   );
  //   return {
  //     unsubscribe: () => subscription.unsubscribe(),
  //     isClosed: () => subscription.closed,
  //   };
  // }

  onCreated({
    id,
    onSucceeded,
    onFailed,
  }: {
    id: string;
    onSucceeded: (playground: PlaygroundEntity) => void;
    onFailed: (e: Error) => void;
  }): Subscription {
    let intervalId: number;
    let closed = false;
    const clear = () => {
      if (intervalId !== undefined) {
        clearInterval(intervalId);
        closed = true;
      }
    };
    const doProcess = async () => {
      try {
        const playground = await this.findById(id);
        assertEntityExists(playground, 'playground');
        if (playground.status === 'created') {
          onSucceeded(playground);
          clear();
          return;
        }
        if (playground.status === 'used') {
          throw new Error('playground has already been used');
        }
      } catch (e) {
        if (e instanceof Error) {
          onFailed(e);
        } else {
          onFailed(new Error(JSON.stringify(e)));
        }
        clear();
      }
    };

    intervalId = window.setInterval(doProcess, 20 * 1000);
    return {
      unsubscribe: () => clear(),
      isClosed: () => closed,
    };
  }
}
