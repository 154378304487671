import { AppContextProvider, ContentId } from '@/base/domains';
import { Optional } from '@/base/types';
import {
  createEditingCourseContentBody,
  EditingCourseContentBodyData,
  EditingCourseContentBodyEntity,
  EditingCourseContentBodyRepository,
} from '@/contents/domains';
import { hasNonNullProperty, requiredNonNull } from '@/utils/TsUtils';

export class InMemoryEditingCourseContentBodyRepository
  implements EditingCourseContentBodyRepository
{
  private appContextProvider: AppContextProvider;

  private store: Map<ContentId, EditingCourseContentBodyEntity> = new Map();

  constructor(appContextProvider: AppContextProvider) {
    this.appContextProvider = appContextProvider;
  }

  save(
    args: EditingCourseContentBodyEntity | EditingCourseContentBodyData
  ): Promise<EditingCourseContentBodyEntity> {
    const userId = requiredNonNull(this.appContextProvider.get().user?.id, 'appContext.user.id');

    if (hasNonNullProperty(args, 'dataVersion') && hasNonNullProperty(args, 'createdBy')) {
      const e = createEditingCourseContentBody({
        ...args,
        dataVersion: args.dataVersion + 1,
        updatedBy: userId,
      });
      this.store.set(e.id, e);
      return Promise.resolve(e);
    }
    const e = createEditingCourseContentBody({
      ...args,
      dataVersion: 1,
      createdBy: userId,
      updatedBy: userId,
    });
    this.store.set(e.id, e);
    return Promise.resolve(e);
  }

  findById(id: ContentId): Promise<Optional<EditingCourseContentBodyEntity>> {
    return Promise.resolve(this.store.get(id));
  }

  remove(id: ContentId): Promise<void> {
    this.store.delete(id);
    return Promise.resolve();
  }
}
