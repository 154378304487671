import { Group, GroupId, GroupName, GroupRepository, localEventBus } from '@/base/domains';
import { groupNameChanged } from '@/base/domains/LocalEvents';
import { AbstractUseCase, assertEntityExists, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

export interface ChangeGroupNameRequest {
  id: GroupId;
  name: GroupName;
}

export type ChangeGroupNameResponse = {
  group: Group;
};

/**
 * グループ名を変更する
 */
export interface ChangeGroupName extends UseCase<ChangeGroupNameRequest, ChangeGroupNameResponse> {
  execute(request: ChangeGroupNameRequest): Promise<UseCaseResponse<ChangeGroupNameResponse>>;
}

export class ChangeGroupNameImpl
  extends AbstractUseCase<ChangeGroupNameRequest, ChangeGroupNameResponse>
  implements ChangeGroupName
{
  private groupRepository: GroupRepository;

  constructor(groupRepository: GroupRepository) {
    super('admin.ChangeGroupName');
    this.groupRepository = groupRepository;
  }

  async internalExecute(request: ChangeGroupNameRequest): Promise<ChangeGroupNameResponse> {
    const { id, name } = request;
    const group = await this.groupRepository.findById(id);
    assertEntityExists(group, 'group');
    const saved = await this.groupRepository.save(group.changeName(name));
    localEventBus.publish(groupNameChanged({ groupId: id, groupName: name }));
    return {
      group: saved,
    };
  }
}

export const ChangeGroupNameKey = injectionKeyOf<ChangeGroupName>({
  boundedContext: 'admin',
  type: 'usecase',
  name: 'ChangeGroupName',
});

export function useChangeGroupName(): ChangeGroupName {
  return requiredInject(ChangeGroupNameKey);
}
