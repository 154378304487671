import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { LocalDateTime, Minute, Optional, URI } from '../types';
import {
  ContentId,
  ContentName,
  ContentType,
  ContentVersion,
  CourseDisplayName,
  CourseId,
  CourseName,
  CourseVersion,
} from './content';
import { CourseColor, CourseFontColorOnImage } from './Course';
import { GroupExamStatus } from './Exam';
import { GroupId } from './Group';

export type GroupTrainingId = string;

export type GroupCourseContent =
  | {
      /** コンテンツタイプ */
      readonly type: Omit<ContentType, 'exam'>;
      /** 名前 */
      readonly name: ContentName;
      /** コンテンツID */
      readonly id: ContentId;
      /** 所要時間 */
      readonly requiredTime: Minute;
      /** 推奨日時 */
      readonly recommendedDateTime?: LocalDateTime;
      /** 公開中 */
      readonly open: boolean;
      /** コンテンツバージョン */
      readonly version: ContentVersion;
    }
  | {
      /** コンテンツタイプ */
      readonly type: 'exam';
      /** 名前 */
      readonly name: ContentName;
      /** コンテンツID */
      readonly id: ContentId;
      /** 所要時間 */
      readonly requiredTime: Minute;
      /** 推奨日時 */
      readonly recommendedDateTime?: LocalDateTime;
      /** 公開中 */
      readonly open: boolean;
      /** テストステータス */
      readonly examStatus: GroupExamStatus;
      /** コンテンツバージョン */
      readonly version: ContentVersion;
    };

export type GroupTrainingCourse = Readonly<{
  /** コースID */
  id: CourseId;
  /** コース名 */
  name: CourseName;
  /** コース表示名 */
  displayName: CourseDisplayName;
  /** グループにおけるコースコンテンツのリスト */
  contents: Array<GroupCourseContent>;
  /** バージョン */
  version: CourseVersion;
  /** カラー */
  color?: CourseColor;
  /** イメージ */
  image?: URI;
  /** フォントカラー */
  fontColorOnImage?: CourseFontColorOnImage;
  /** コース順のインデックス */
  index?: number;
}>;

export type GroupTraining = Readonly<{
  id: GroupTrainingId;
  groupId: GroupId;
}>;

export interface GroupTrainingFinder {
  findByGroupId(groupId: GroupId): Promise<Optional<GroupTraining>>;

  findByCourseId(
    courseId: CourseId
  ): Promise<Array<GroupTraining & { courseVersion: CourseVersion }>>;

  findCoursesByGroupId(groupId: GroupId): Promise<Array<GroupTrainingCourse>>;

  findCourseByGroupIdAndCourseId(
    groupId: GroupId,
    courseId: CourseId
  ): Promise<Optional<GroupTrainingCourse>>;

  findCourseByGroupIdAndCourseDisplayName(
    groupId: GroupId,
    courseDisplayName: CourseName
  ): Promise<Optional<GroupTrainingCourse>>;
}

export const GroupTrainingFinderKey = injectionKeyOf<GroupTrainingFinder>({
  boundedContext: 'base',
  type: 'component',
  name: 'GroupTrainingFinder',
});

export function useGroupTrainingFinder(): GroupTrainingFinder {
  return requiredInject(GroupTrainingFinderKey);
}
