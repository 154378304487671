import {
  AuthorizationService,
  localEventBus,
  Role,
  User,
  UserId,
  UserRepository,
} from '@/base/domains';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { userRoleChangedByAdmin } from '../domains/LocalEvents';
import { AbstractUseCase, UseCase, UseCaseResponse } from './UseCase';
import { assertEntityExists } from './UseCaseAsserts';

export interface ChangeUserRoleByAdminRequest {
  id: UserId;
  role: Role;
}

export type ChangeUserRoleByAdminResponse = {
  user: User;
};

export interface ChangeUserRoleByAdmin
  extends UseCase<ChangeUserRoleByAdminRequest, ChangeUserRoleByAdminResponse> {
  execute(
    request: ChangeUserRoleByAdminRequest
  ): Promise<UseCaseResponse<ChangeUserRoleByAdminResponse>>;
}

export class ChangeUserRoleByAdminImpl
  extends AbstractUseCase<ChangeUserRoleByAdminRequest, ChangeUserRoleByAdminResponse>
  implements ChangeUserRoleByAdmin
{
  private authorizationService: AuthorizationService;

  private userRepository: UserRepository;

  constructor(authorizationService: AuthorizationService, userRepository: UserRepository) {
    super('base.ChangeUserRoleByAdmin');
    this.authorizationService = authorizationService;
    this.userRepository = userRepository;
  }

  async internalExecute(
    request: ChangeUserRoleByAdminRequest
  ): Promise<ChangeUserRoleByAdminResponse> {
    const { id, role } = request;
    this.authorizationService.assertRole('supervisor', 'admin');
    const user = await this.userRepository.findById(id);
    assertEntityExists(user, 'user');
    const saved = await this.userRepository.save(user.changeRole(role));
    localEventBus.publish(userRoleChangedByAdmin({ userId: id }));
    return {
      user: saved,
    };
  }
}

export const ChangeUserRoleByAdminKey = injectionKeyOf<ChangeUserRoleByAdmin>({
  boundedContext: 'account',
  type: 'usecase',
  name: 'ChangeUserRoleByAdmin',
});

export function useChangeUserRoleByAdmin(): ChangeUserRoleByAdmin {
  return requiredInject(ChangeUserRoleByAdminKey);
}
