import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { AuthorizationService, UserTagId, UserTagRepository } from '../domains';
import { AbstractUseCase, UseCase, UseCaseResponse } from './UseCase';

export type RemoveUserTagRequest = {
  id: UserTagId;
};

export type RemoveUserTagResponse = {};

/**
 * ユーザータグを削除する
 */
export interface RemoveUserTag extends UseCase<RemoveUserTagRequest, RemoveUserTagResponse> {
  execute(request: RemoveUserTagRequest): Promise<UseCaseResponse<RemoveUserTagResponse>>;
}

export class RemoveUserTagImpl
  extends AbstractUseCase<RemoveUserTagRequest, RemoveUserTagResponse>
  implements RemoveUserTag
{
  constructor(
    private authorizationService: AuthorizationService,
    private userTagRepository: UserTagRepository
  ) {
    super('base.RemoveUserTag');
  }

  async internalExecute(request: RemoveUserTagRequest): Promise<RemoveUserTagResponse> {
    const { id } = request;
    this.authorizationService.assertRole('supervisor', 'admin');
    const userTag = await this.userTagRepository.findById(id);
    if (!userTag) {
      return {};
    }
    await this.userTagRepository.remove(id);
    return {};
  }
}

export const RemoveUserTagKey = injectionKeyOf<RemoveUserTag>({
  boundedContext: 'training',
  type: 'usecase',
  name: 'RemoveUserTag',
});

export function useRemoveUserTag(): RemoveUserTag {
  return requiredInject(RemoveUserTagKey);
}
