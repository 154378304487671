import { ref } from '@vue/composition-api';

import { localEventBus } from '@/base/domains';
import {
  amplifyQueryNetworkErrorOccurred,
  amplifyQuerySucceeded,
  showErrorToast,
} from '@/base/domains/LocalEvents';

import { useMessages } from '../Messages';

export const useNetworkErrorHandler = () => {
  const msgs = useMessages({ prefix: 'base.organisms' });
  const networkErrorMsg = msgs.of('networkError');
  const isNetworkConnected = ref(true);
  localEventBus.subscribe(amplifyQueryNetworkErrorOccurred, ({ payload }) => {
    const { queryType } = payload;
    if (queryType === 'query' && isNetworkConnected.value) {
      localEventBus.publish(showErrorToast({ messages: [networkErrorMsg.value] }));
    }
    isNetworkConnected.value = false;
  });
  localEventBus.subscribe(amplifyQuerySucceeded, () => {
    isNetworkConnected.value = true;
  });
};
