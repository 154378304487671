import { GroupTrainingFinder, QuestionId } from '@/base/domains';
import { AbstractUseCase, QuestionWithNames, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { QuestionRepository } from '../domains';

export interface GetQuestionRequest {
  id: QuestionId;
}

export type GetQuestionResponse = {
  question?: QuestionWithNames;
};

/**
 * 質問を取得する
 */
export interface GetQuestion extends UseCase<GetQuestionRequest, GetQuestionResponse> {
  execute(request: GetQuestionRequest): Promise<UseCaseResponse<GetQuestionResponse>>;
}

export class GetQuestionImpl
  extends AbstractUseCase<GetQuestionRequest, GetQuestionResponse>
  implements GetQuestion
{
  constructor(
    private questionRepository: QuestionRepository,
    private groupTrainingFinder: GroupTrainingFinder
  ) {
    super('training.GetQuestion');
  }

  async internalExecute(request: GetQuestionRequest): Promise<GetQuestionResponse> {
    const { id } = request;
    const question = await this.questionRepository.findById(id);
    if (question) {
      if (question.referTo) {
        const { contentId } = question.referTo;
        // groupId, contentIdから効率的にcourseName, contentNameにアクセスする手段がないため
        // このような実装になっている。
        // パフォーマンスに大きな問題が出るなら対応を考える。
        const groupTrainingCourses = await this.groupTrainingFinder.findCoursesByGroupId(
          question.groupId
        );
        const name = groupTrainingCourses
          .flatMap((cr) =>
            cr.contents.map((cn) => ({
              id: cn.id,
              name: cn.name,
              courseId: cr.id,
              courseName: cr.displayName,
            }))
          )
          .find((e) => e.id === contentId);

        if (name) {
          return {
            question: {
              ...question,
              referTo: {
                ...question.referTo,
                courseId: name.courseId,
                courseName: name.courseName,
                contentName: name.name,
              },
            },
          };
        }

        return {
          question: {
            ...question,
            referTo: undefined,
          },
        };
      }
      return {
        question: {
          ...question,
          referTo: undefined,
        },
      };
    }

    return {
      question: undefined,
    };
  }
}

export const GetQuestionKey = injectionKeyOf<GetQuestion>({
  boundedContext: 'training',
  type: 'usecase',
  name: 'GetQuestion',
});

export function useGetQuestion(): GetQuestion {
  return requiredInject(GetQuestionKey);
}
