import { SignUpType as AmplifySignUpType } from '@/API';
import {
  AppContextProvider,
  SignUpType,
  Tenant,
  TenantCode,
  TenantDataAdapter,
  TenantLimitation,
  TenantName,
} from '@/base/domains';
import { JSONString } from '@/base/types';
import * as queries from '@/graphql/queries';
import { graphql } from '@/utils/AmplifyUtils';
import { assertIsDefined } from '@/utils/Asserts';

type AmplifyTenant = {
  code: TenantCode;
  name: TenantName;
  signUpType: AmplifySignUpType;
  playground: boolean;
  userLimit?: number;
  limitations: JSONString;
};

type AmplifyTenantLimitation = {
  question: boolean;
  schedule: boolean;
  questionnaire: boolean;
  multilingualSupport: boolean;
  tenantOwnTermsOfService: boolean;
};

function toLimitation(limitations: AmplifyTenantLimitation): TenantLimitation {
  return {
    question: limitations.question ? 'enabled' : 'disabled',
    questionnaire: limitations.questionnaire ? 'enabled' : 'disabled',
    schedule: limitations.schedule ? 'enabled' : 'disabled',
    multilingualSupport: limitations.multilingualSupport ? 'enabled' : 'disabled',
    tenantOwnTermsOfService: limitations.tenantOwnTermsOfService ? 'enabled' : 'disabled',
  };
}

function toTenant(tenant: AmplifyTenant): Tenant {
  const signUpType = tenant.signUpType.toLowerCase() as SignUpType;
  return {
    ...tenant,
    limitations: toLimitation(JSON.parse(tenant.limitations)) as TenantLimitation,
    signUpType,
  };
}

export class AmplifyTenantDataAdapter implements TenantDataAdapter {
  private appContextProvider: AppContextProvider;

  constructor(appContextProvider: AppContextProvider) {
    this.appContextProvider = appContextProvider;
  }

  async get(): Promise<Tenant> {
    const { tenantCode } = this.appContextProvider.get();
    assertIsDefined(tenantCode, 'appContext.tenantCode');
    const res = await graphql<{ tenantByCode: { items: Array<AmplifyTenant> } }>(
      queries.tenantByCode,
      {
        code: tenantCode,
      }
    );
    const tenant = res.tenantByCode.items[0];
    assertIsDefined(tenant, 'tenant');
    return toTenant(tenant);
  }

  async findByTenantCode(tenantCode: TenantCode): Promise<Tenant> {
    const res = await graphql<{ tenantByCode: { items: Array<AmplifyTenant> } }>(
      queries.tenantByCode,
      {
        code: tenantCode,
      }
    );
    const tenant = res.tenantByCode.items[0];
    assertIsDefined(tenant, 'tenant');
    return toTenant(tenant);
  }
}
