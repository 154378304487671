import {
  AuthorizationService,
  Group,
  GroupId,
  GroupRepository,
  localEventBus,
} from '@/base/domains';
import { GroupExtensionConfig } from '@/base/domains/extensions/Extension';
import { groupExtensionConfigSaved } from '@/base/domains/LocalEvents';
import { AbstractUseCase, assertEntityExists, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

export interface SaveGroupExtensionConfigRequest {
  id: GroupId;
  extensionConfig: GroupExtensionConfig;
}

export type SaveGroupExtensionConfigResponse = {
  group: Group;
};

export interface SaveGroupExtensionConfig
  extends UseCase<SaveGroupExtensionConfigRequest, SaveGroupExtensionConfigResponse> {
  execute(
    request: SaveGroupExtensionConfigRequest
  ): Promise<UseCaseResponse<SaveGroupExtensionConfigResponse>>;
}

export class SaveGroupExtensionConfigImpl
  extends AbstractUseCase<SaveGroupExtensionConfigRequest, SaveGroupExtensionConfigResponse>
  implements SaveGroupExtensionConfig
{
  private authorizationService: AuthorizationService;

  private groupRepository: GroupRepository;

  constructor(authorizationService: AuthorizationService, groupRepository: GroupRepository) {
    super('admin.SaveGroupExtensionConfig');
    this.authorizationService = authorizationService;
    this.groupRepository = groupRepository;
  }

  async internalExecute(
    request: SaveGroupExtensionConfigRequest
  ): Promise<SaveGroupExtensionConfigResponse> {
    this.authorizationService.assertRole('supervisor', 'admin');
    const { id, extensionConfig } = request;
    const group = await this.groupRepository.findById(id);
    assertEntityExists(group, 'group');
    const saved = await this.groupRepository.save(group.saveExtensionConfig(extensionConfig));
    localEventBus.publish(groupExtensionConfigSaved({ groupId: group.id }));
    return {
      group: saved,
    };
  }
}

export const SaveGroupExtensionConfigKey = injectionKeyOf<SaveGroupExtensionConfig>({
  boundedContext: 'admin',
  type: 'usecase',
  name: 'SaveGroupExtensionConfig',
});

export function useSaveGroupExtensionConfig(): SaveGroupExtensionConfig {
  return requiredInject(SaveGroupExtensionConfigKey);
}
