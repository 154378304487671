import { ContentLearning } from '@/base/domains';
import { localDateTimeFromString } from '@/utils/DateUtils';

export const contentLearnings: Array<ContentLearning> = [
  {
    groupId: 'group00',
    courseId: 'course-0',
    contentId: 'content00-0',
    contentType: 'text',
    userId: 'admin01',
    status: 'in_progress',
    usageTime: 25,
    startedAt: localDateTimeFromString('2021-01-01T00:00:00.000Z'),
  },
  {
    groupId: 'group01',
    courseId: 'course-0',
    contentId: 'content00-0',
    contentType: 'text',
    userId: 'admin01',
    status: 'in_progress',
    usageTime: 25,
    startedAt: localDateTimeFromString('2021-01-01T00:00:00.000Z'),
  },
  {
    groupId: 'group00',
    courseId: 'course-12',
    contentId: 'content120-0',
    contentType: 'text',
    userId: 'admin01',
    status: 'in_progress',
    usageTime: 25,
    startedAt: localDateTimeFromString('2021-01-01T00:00:00.000Z'),
  },
  {
    groupId: 'group00',
    courseId: 'course-13',
    contentId: 'content130-0',
    contentType: 'text',
    userId: 'admin01',
    status: 'in_progress',
    usageTime: 25,
    startedAt: localDateTimeFromString('2021-01-01T00:00:00.000Z'),
  },
  {
    groupId: 'group00',
    courseId: 'course-0',
    contentId: 'content00-0',
    contentType: 'text',
    userId: 'user02',
    status: 'completed',
    usageTime: 25,
    startedAt: localDateTimeFromString('2021-01-01T00:00:00.000Z'),
  },
  {
    groupId: 'group00',
    courseId: 'course-0',
    contentId: 'content01-0',
    contentType: 'text',
    userId: 'user02',
    status: 'completed',
    usageTime: 15,
    startedAt: localDateTimeFromString('2021-01-01T00:00:00.000Z'),
  },
  {
    groupId: 'group00',
    courseId: 'course-11',
    contentId: 'content011-0',
    contentType: 'text',
    userId: 'user02',
    status: 'completed',
    usageTime: 15,
    startedAt: localDateTimeFromString('2021-01-01T00:00:00.000Z'),
  },
  {
    groupId: 'group00',
    courseId: 'course-0',
    contentId: 'content00-0',
    contentType: 'text',
    userId: 'user03',
    status: 'completed',
    usageTime: 11,
    startedAt: localDateTimeFromString('2021-01-01T00:00:00.000Z'),
  },
];
