import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import {
  AuthorizationService,
  UserTagColor,
  UserTagId,
  UserTagReference,
  UserTagRepository,
} from '../domains';
import { AbstractUseCase, UseCase, UseCaseResponse } from './UseCase';
import { assertEntityExists } from './UseCaseAsserts';

export type ChangeUserTagRequest = {
  id: UserTagId;
  text: string;
  color: UserTagColor;
};

export type ChangeUserTagResponse = {
  userTag: UserTagReference;
};

/**
 * ユーザータグを更新する
 */
export interface ChangeUserTag extends UseCase<ChangeUserTagRequest, ChangeUserTagResponse> {
  execute(request: ChangeUserTagRequest): Promise<UseCaseResponse<ChangeUserTagResponse>>;
}

export class ChangeUserTagImpl
  extends AbstractUseCase<ChangeUserTagRequest, ChangeUserTagResponse>
  implements ChangeUserTag
{
  constructor(
    private authorizationRepository: AuthorizationService,
    private userTagRepository: UserTagRepository
  ) {
    super('base.ChangeUserTag');
  }

  async internalExecute(request: ChangeUserTagRequest): Promise<ChangeUserTagResponse> {
    const { id, text, color } = request;
    this.authorizationRepository.assertRole('supervisor', 'admin');
    const userTag = await this.userTagRepository.findById(id);
    assertEntityExists(userTag, 'userTag');
    const saved = await this.userTagRepository.save(userTag.change({ text, color }));
    return {
      userTag: saved,
    };
  }
}

export const ChangeUserTagKey = injectionKeyOf<ChangeUserTag>({
  boundedContext: 'training',
  type: 'usecase',
  name: 'ChangeUserTag',
});

export function useChangeUserTag(): ChangeUserTag {
  return requiredInject(ChangeUserTagKey);
}
