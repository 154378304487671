import { config } from '@/config';
import { localDateTimeNow } from '@/utils/DateUtils';
import { isDefined } from '@/utils/TsUtils';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { FileStorage } from '../domains';
import { LocalDateTime } from '../types';
import { AbstractUseCase, UseCase, UseCaseResponse } from './UseCase';

export type ScheduleResetSignedCookieRequest = {};

export type ScheduleResetSignedCookieResponse = {
  cancel: () => void;
};

export interface ScheduleResetSignedCookie
  extends UseCase<ScheduleResetSignedCookieRequest, ScheduleResetSignedCookieResponse> {
  execute(
    request: ScheduleResetSignedCookieRequest
  ): Promise<UseCaseResponse<ScheduleResetSignedCookieResponse>>;
}

export class ScheduleResetSignedCookieImpl
  extends AbstractUseCase<ScheduleResetSignedCookieRequest, ScheduleResetSignedCookieResponse>
  implements ScheduleResetSignedCookie
{
  private fileStorage: FileStorage;

  constructor(fileStorage: FileStorage) {
    super('base.ScheduleResetSignedCookie');
    this.fileStorage = fileStorage;
  }

  async internalExecute(
    _: ScheduleResetSignedCookieRequest
  ): Promise<ScheduleResetSignedCookieResponse> {
    if (!this.fileStorage.isValidSignedCookie()) {
      await this.fileStorage.setSignedCookie();
    }

    const getTimeout = (expire: LocalDateTime) => {
      const now = localDateTimeNow();
      const diff = expire.diff(now, 'milliseconds');
      return diff - config().app.signedPolicyRefreshBeforeLimit;
    };

    const { proc, cancel } = (() => {
      let expireBeforeProc: LocalDateTime;
      let timeoutId: number;
      expireBeforeProc = this.fileStorage.getExpire();

      const p = () => {
        timeoutId = window.setTimeout(async () => {
          const currentExpire = this.fileStorage.getExpire();
          if (currentExpire.isSame(expireBeforeProc)) {
            await this.fileStorage.setSignedCookie();
            expireBeforeProc = this.fileStorage.getExpire();
            p();
          }
        }, getTimeout(expireBeforeProc));
      };
      const c = () => {
        if (isDefined(timeoutId)) {
          window.clearTimeout(timeoutId);
        }
      };
      return { proc: p, cancel: c };
    })();

    proc();
    return {
      cancel,
    };
  }
}

export const ScheduleResetSignedCookieKey = injectionKeyOf<ScheduleResetSignedCookie>({
  boundedContext: 'base',
  type: 'usecase',
  name: 'ScheduleResetSignedCookie',
});

export function useScheduleResetSignedCookie(): ScheduleResetSignedCookie {
  return requiredInject(ScheduleResetSignedCookieKey);
}
