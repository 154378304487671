/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  Email,
  SignUpReservation,
  SignUpReservationQueries,
  TenantCode,
  UserCode,
} from '@/base/domains';

export class InMemorySignUpReservationQueries implements SignUpReservationQueries {
  private signUpReservations: Array<SignUpReservation>;

  private signUpReservation: SignUpReservation;

  constructor(signUpReservations: Array<SignUpReservation>, signUpReservation: SignUpReservation) {
    this.signUpReservations = signUpReservations;
    this.signUpReservation = signUpReservation;
  }

  findByEmail(email: Email): Promise<SignUpReservation> {
    return Promise.resolve(this.signUpReservation);
  }

  findByUserCode(userCode: UserCode, tenantCode: TenantCode): Promise<SignUpReservation> {
    return Promise.resolve(this.signUpReservation);
  }

  findTenantSignUpReservations(): Promise<Array<SignUpReservation>> {
    return Promise.resolve(this.signUpReservations);
  }
}
