import * as customQueries from '@/amplify/customQueries';
import {
  CreateQuestionnaireInput,
  CreateQuestionnaireRespondentInput,
  DeleteQuestionnaireInput,
  QuestionnaireStatus as AmplifyQuestionnaireStatus,
  UpdateQuestionnaireInput,
} from '@/API';
import {
  AppContextProvider,
  GroupId,
  QuestionnaireByAnswerer,
  Subscription,
  UserId,
} from '@/base/domains';
import { ISODateTimeString, Optional } from '@/base/types';
import * as mutations from '@/graphql/mutations';
import * as queries from '@/graphql/queries';
import {
  onCreateQuestionnaireRespondentByQuestionnaireId,
  onDeleteQuestionnaireRespondentByQuestionnaireId,
  onUpdateQuestionnaireRespondentByQuestionnaireId,
} from '@/graphql/subscriptions';
import {
  QuestionnaireByAnswererArgs,
  QuestionnaireEntity,
  QuestionnaireEntityImpl,
  QuestionnaireId,
  QuestionnaireRepository,
  QuestionnaireRespondent,
  QuestionnaireStatus,
} from '@/training/domains';
import { graphql, graphqlSubscribe } from '@/utils/AmplifyUtils';
import { assertIsDefined } from '@/utils/Asserts';
import { localDateTimeFromString } from '@/utils/DateUtils';
import { isDefined, requiredNonNull } from '@/utils/TsUtils';

type AmplifyQuestionnaire = {
  id: QuestionnaireId;
  groupId: GroupId;
  title: string;
  text?: string;
  userIds: Array<UserId>;
  createdBy: UserId;
  createdAt: ISODateTimeString;
  options: Array<string>;
  respondent: {
    items: Array<QuestionnaireRespondent>;
  };
  status: AmplifyQuestionnaireStatus;
  finishedAt?: ISODateTimeString;
};

type AmplifyQuestionnaireByAnswer = {
  id: QuestionnaireId;
  groupId: GroupId;
  title: string;
  text?: string;
  userIds: Array<UserId>;
  createdBy: UserId;
  createdAt: ISODateTimeString;
  options: Array<string>;
};

function toQuestionnaire(amplifyQuestionnaire: AmplifyQuestionnaire): QuestionnaireEntity {
  return new QuestionnaireEntityImpl({
    id: amplifyQuestionnaire.id,
    groupId: amplifyQuestionnaire.groupId,
    title: amplifyQuestionnaire.title,
    text: amplifyQuestionnaire.text ?? undefined,
    userIds: amplifyQuestionnaire.userIds,
    createdBy: amplifyQuestionnaire.createdBy,
    createdAt: localDateTimeFromString(amplifyQuestionnaire.createdAt),
    options: amplifyQuestionnaire.options,
    respondent: amplifyQuestionnaire.respondent.items,
    status: amplifyQuestionnaire.status.toLocaleLowerCase() as QuestionnaireStatus,
    finishedAt: amplifyQuestionnaire.finishedAt
      ? localDateTimeFromString(amplifyQuestionnaire.finishedAt)
      : undefined,
  });
}

function toQuestionnaireByAnswer(
  amplifyQuestionnaireByAnswer: AmplifyQuestionnaireByAnswer
): QuestionnaireByAnswererArgs {
  return {
    id: amplifyQuestionnaireByAnswer.id,
    groupId: amplifyQuestionnaireByAnswer.groupId,
    title: amplifyQuestionnaireByAnswer.title,
    text: amplifyQuestionnaireByAnswer.text ?? undefined,
    userIds: amplifyQuestionnaireByAnswer.userIds,
    createdBy: amplifyQuestionnaireByAnswer.createdBy,
    createdAt: localDateTimeFromString(amplifyQuestionnaireByAnswer.createdAt),
    options: amplifyQuestionnaireByAnswer.options,
  };
}

export class AmplifyQuestionnaireRepository implements QuestionnaireRepository {
  constructor(private appContextProvider: AppContextProvider) {}

  async save(entity: QuestionnaireEntity): Promise<QuestionnaireEntity> {
    const tenantCode = requiredNonNull(
      this.appContextProvider.get().tenantCode,
      'appContext.tenantCode'
    );
    if (isDefined(entity.id)) {
      const input: UpdateQuestionnaireInput = {
        id: entity.id,
        groupId: entity.groupId,
        tenantCode,
        title: entity.title,
        text: entity.text,
        userIds: entity.userIds,
        createdBy: entity.createdBy,
        options: entity.options,
        status: entity.status.toUpperCase() as AmplifyQuestionnaireStatus,
        finishedAt: isDefined(entity.finishedAt)
          ? entity.finishedAt.toISOString()
          : entity.finishedAt,
      };
      const res = await graphql<{ updateQuestionnaire: AmplifyQuestionnaire }>(
        mutations.updateQuestionnaire,
        {
          input,
        }
      );
      assertIsDefined(res);
      return toQuestionnaire(res.updateQuestionnaire);
    }
    const createdBy = requiredNonNull(this.appContextProvider.get().user?.id, 'appContext.user');
    const input: CreateQuestionnaireInput = {
      groupId: entity.groupId,
      tenantCode,
      title: entity.title,
      text: entity.text,
      userIds: entity.userIds,
      createdBy,
      options: entity.options,
      status: AmplifyQuestionnaireStatus.ACTIVE,
    };
    const res = await graphql<{ createQuestionnaire: AmplifyQuestionnaire }>(
      mutations.createQuestionnaire,
      {
        input,
      }
    );
    assertIsDefined(res);
    return toQuestionnaire(res.createQuestionnaire);
  }

  async findById(id: QuestionnaireId): Promise<Optional<QuestionnaireEntity>> {
    const res = await graphql<{ getQuestionnaire: AmplifyQuestionnaire }>(
      queries.getQuestionnaire,
      { id }
    );
    return res?.getQuestionnaire ? toQuestionnaire(res.getQuestionnaire) : undefined;
  }

  async remove(id: QuestionnaireId): Promise<void> {
    const input: DeleteQuestionnaireInput = {
      id,
    };
    await graphql<{ deleteQuestionnaire: AmplifyQuestionnaire }>(mutations.deleteQuestionnaire, {
      input,
    });
  }

  async findByGroupId(groupId: GroupId): Promise<Array<QuestionnaireEntity>> {
    const appContext = this.appContextProvider.get();
    const limitations = appContext.groupLimitationOf(groupId);
    const role = appContext.roleInGroup(groupId);
    if (!role) return [];
    if (limitations.questionnaire.includes(role)) {
      const res = await graphql<{
        questionnaireByGroup: {
          items: Array<AmplifyQuestionnaire>;
        };
      }>(queries.questionnaireByGroup, {
        groupId,
      });
      return res.questionnaireByGroup.items.map(toQuestionnaire);
    }
    return [];
  }

  async findActiveQuestionnairesByGroupId(groupId: GroupId): Promise<Array<QuestionnaireEntity>> {
    const appContext = this.appContextProvider.get();
    const limitations = appContext.groupLimitationOf(groupId);
    const role = appContext.roleInGroup(groupId);
    if (!role) return [];
    if (limitations.questionnaire.includes(role)) {
      const res = await graphql<{
        questionnaireByGroup: {
          items: Array<AmplifyQuestionnaire>;
        };
      }>(queries.questionnaireByGroup, {
        groupId,
        filter: {
          status: { eq: 'ACTIVE' },
        },
      });
      return res.questionnaireByGroup.items.map(toQuestionnaire);
    }
    return [];
  }

  async findQuestionnaireRespondentByQuestionnaireId(
    questionnaireId: QuestionnaireId
  ): Promise<Array<QuestionnaireRespondent>> {
    const res = await graphql<{
      getQuestionnaireRespondent: {
        items: Array<QuestionnaireRespondent>;
      };
    }>(queries.getQuestionnaireRespondent, {
      questionnaireId,
    });
    return res.getQuestionnaireRespondent.items.filter(
      (q) => q.questionnaireId === questionnaireId
    );
  }

  async findQuestionnaireRespondentByUserId(
    userId: UserId
  ): Promise<Array<QuestionnaireRespondent>> {
    const res = await graphql<{
      questionnaireRespondentByUserId: {
        items: Array<QuestionnaireRespondent>;
      };
    }>(queries.questionnaireRespondentByUserId, {
      userId,
    });
    return res.questionnaireRespondentByUserId.items;
  }

  async findUnansweredQuestionnaires(
    groupId: GroupId,
    userId: UserId
  ): Promise<Array<QuestionnaireByAnswererArgs>> {
    const appContext = this.appContextProvider.get();
    const limitations = appContext.groupLimitationOf(groupId);
    const role = appContext.roleInGroup(groupId);
    if (!role) return [];
    if (limitations.questionnaire.includes(role)) {
      const groupQuestionnaires = await graphql<{
        questionnaireByGroup: {
          items: Array<AmplifyQuestionnaireByAnswer>;
        };
      }>(customQueries.questionnairesByAnswerer, {
        groupId,
        filter: {
          status: { eq: 'ACTIVE' },
        },
      });
      const respondents = await graphql<{
        questionnaireRespondentByUserId: {
          items: Array<QuestionnaireRespondent>;
        };
      }>(queries.questionnaireRespondentByUserId, {
        userId,
      });
      const res = groupQuestionnaires.questionnaireByGroup.items
        .filter(
          (q) =>
            q.userIds.includes(userId) &&
            !respondents.questionnaireRespondentByUserId.items
              .map((r) => r.questionnaireId)
              .includes(q.id)
        )
        .map(toQuestionnaireByAnswer);
      return res;
    }
    return [];
  }

  async answerQuestionnaire(
    questionnaireId: QuestionnaireId,
    userId: UserId,
    selectedIndex: number
  ): Promise<QuestionnaireRespondent> {
    const tenantCode = requiredNonNull(
      this.appContextProvider.get().tenantCode,
      'appContext.tenantCode'
    );
    const input: CreateQuestionnaireRespondentInput = {
      questionnaireId,
      userId,
      selectedIndex,
      tenantCode,
    };
    const res = await graphql<{ createQuestionnaireRespondent: QuestionnaireRespondent }>(
      mutations.createQuestionnaireRespondent,
      {
        input,
      }
    );
    return res.createQuestionnaireRespondent;
  }

  async findByAnswerer(
    questionnaireId: QuestionnaireId,
    userId: UserId
  ): Promise<Optional<QuestionnaireByAnswerer>> {
    const res = await graphql<{
      getQuestionnaire: AmplifyQuestionnaireByAnswer;
      questionnaireRespondentByQuestionnaireIdAndUserId: {
        items: Array<QuestionnaireRespondent>;
      };
    }>(customQueries.getQuestionnaireByAnswerer, {
      id: questionnaireId,
      userId: { eq: userId },
    });
    const questionnaire = toQuestionnaireByAnswer(res.getQuestionnaire);
    const answered = !!res.questionnaireRespondentByQuestionnaireIdAndUserId.items[0];
    const questionnaireByAnswerer: QuestionnaireByAnswerer = {
      ...questionnaire,
      answered,
    };
    return questionnaireByAnswerer;
  }

  async findQuestionnairesByAnswerer(
    groupId: GroupId,
    userId: UserId
  ): Promise<Array<QuestionnaireByAnswerer>> {
    const appContext = this.appContextProvider.get();
    const limitations = appContext.groupLimitationOf(groupId);
    const role = appContext.roleInGroup(groupId);
    if (!role) return [];
    if (limitations.questionnaire.includes(role)) {
      const res = await graphql<{
        questionnaireByGroup: {
          items: Array<AmplifyQuestionnaire>;
        };
      }>(queries.questionnaireByGroup, {
        groupId,
        filter: {
          status: { eq: 'ACTIVE' },
        },
      });
      const respondents = await this.findQuestionnaireRespondentByUserId(userId);
      return res.questionnaireByGroup.items
        .map(toQuestionnaire)
        .filter((item) => item.userIds.find((u) => u === userId))
        .map((q) => {
          const answered = !!respondents.find((r) => r.questionnaireId === q.id);
          return {
            ...q,
            answered,
          };
        });
    }
    return [];
  }

  subscribeQuestionnaireStatusChanged({
    questionnaireId,
    onChange,
    onError,
  }: {
    questionnaireId: string;
    onChange: (questionnaireRespondent: QuestionnaireRespondent, removed: boolean) => void;
    onError: (e: Error) => void;
  }): Subscription {
    const onCreateSubscription = graphqlSubscribe<{
      value: {
        data: {
          onCreateQuestionnaireRespondentByQuestionnaireId: QuestionnaireRespondent;
        };
      };
    }>(onCreateQuestionnaireRespondentByQuestionnaireId, {
      questionnaireId,
    }).subscribe(
      (value) => onChange(value.value.data.onCreateQuestionnaireRespondentByQuestionnaireId, false),
      onError
    );
    const onUpdateSubscription = graphqlSubscribe<{
      value: {
        data: {
          onUpdateQuestionnaireRespondentByQuestionnaireId: QuestionnaireRespondent;
        };
      };
    }>(onUpdateQuestionnaireRespondentByQuestionnaireId, {
      questionnaireId,
    }).subscribe(
      (value) => onChange(value.value.data.onUpdateQuestionnaireRespondentByQuestionnaireId, false),
      onError
    );
    const onDeleteSubscription = graphqlSubscribe<{
      value: {
        data: {
          onDeleteQuestionnaireRespondentByQuestionnaireId: QuestionnaireRespondent;
        };
      };
    }>(onDeleteQuestionnaireRespondentByQuestionnaireId, {
      questionnaireId,
    }).subscribe(
      (value) => onChange(value.value.data.onDeleteQuestionnaireRespondentByQuestionnaireId, true),
      onError
    );
    return {
      unsubscribe: () => {
        onCreateSubscription.unsubscribe();
        onUpdateSubscription.unsubscribe();
        onDeleteSubscription.unsubscribe();
      },
      isClosed: () =>
        onCreateSubscription.closed || onUpdateSubscription.closed || onDeleteSubscription.closed,
    };
  }
}
