import {
  AuthorizationService,
  SCHEDULE_TAGS_LIMITATION,
  ScheduleId,
  ScheduleTagId,
} from '@/base/domains';
import { AbstractUseCase, assertEntityExists, UseCase, UseCaseResponse } from '@/base/usecases';
import { assertCheckIllegalState } from '@/utils/Asserts';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { ScheduleReference, ScheduleRepository, ScheduleTagRepository } from '../domains';
import { SCHEDULE_TAG_LIMITATION_EXCEEDED } from '../ErrorCodes';

export interface AddTagToScheduleRequest {
  id: ScheduleId;
  tagId: ScheduleTagId;
}

export type AddTagToScheduleResponse = {
  schedule: ScheduleReference;
};

/**
 * タグをスケジュールに追加する
 */
export interface AddTagToSchedule
  extends UseCase<AddTagToScheduleRequest, AddTagToScheduleResponse> {
  execute(request: AddTagToScheduleRequest): Promise<UseCaseResponse<AddTagToScheduleResponse>>;
}

export class AddTagToScheduleImpl
  extends AbstractUseCase<AddTagToScheduleRequest, AddTagToScheduleResponse>
  implements AddTagToSchedule
{
  private authorizationService: AuthorizationService;

  private scheduleRepository: ScheduleRepository;

  private scheduleTagRepository: ScheduleTagRepository;

  constructor(
    authorizationService: AuthorizationService,
    scheduleRepository: ScheduleRepository,
    scheduleTagRepository: ScheduleTagRepository
  ) {
    super('training.AddTagToSchedule');
    this.authorizationService = authorizationService;
    this.scheduleRepository = scheduleRepository;
    this.scheduleTagRepository = scheduleTagRepository;
  }

  async internalExecute(request: AddTagToScheduleRequest): Promise<AddTagToScheduleResponse> {
    const { id, tagId } = request;
    const [schedule, scheduleTag] = await Promise.all([
      this.scheduleRepository.findById(id),
      this.scheduleTagRepository.findById(tagId),
    ]);
    assertEntityExists(schedule, 'schedule');
    assertEntityExists(scheduleTag, 'scheduleTag');
    assertCheckIllegalState(schedule.groupId === scheduleTag.groupId, 'unmatched groupId');
    this.authorizationService.assertGroupWriteAccessible(schedule.groupId);

    if (schedule.tags.length === SCHEDULE_TAGS_LIMITATION) {
      throw SCHEDULE_TAG_LIMITATION_EXCEEDED.toApplicationError();
    }
    const saved = await this.scheduleRepository.addTag(id, tagId);
    return {
      schedule: saved,
    };
  }
}

export const AddTagToScheduleKey = injectionKeyOf<AddTagToSchedule>({
  boundedContext: 'training',
  type: 'usecase',
  name: 'AddTagToSchedule',
});

export function useAddTagToSchedule(): AddTagToSchedule {
  return requiredInject(AddTagToScheduleKey);
}
