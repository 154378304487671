import moment from 'moment';

import { ExamResult } from '@/base/domains';

const contentId = 'content03-0';

export const examResults: Array<ExamResult> = [
  {
    id: 'erId0',
    start: moment().add(-1, 'days'),
    end: moment().add(-1, 'days').add(10, 'minute'),
    contentId,
    userId: 'user00',
    groupId: 'group00',
    courseId: 'course-0',
    score: 10,
    problemCount: 20,
    answers: [
      { index: 0, values: [0], correct: true },
      { index: 1, values: [1], correct: false },
      { index: 2, values: [0, 1], correct: true },
    ],
    visibilityLevel: 'details',
    times: 1,
    groupExamId: 'geId',
    version: 2,
  },
  {
    id: 'erId1',
    start: moment(),
    end: moment().add(10, 'minute'),
    contentId,
    userId: 'user01',
    groupId: 'group00',
    courseId: 'course-0',
    score: 15,
    problemCount: 20,
    answers: [
      { index: 0, values: [0], correct: true },
      { index: 1, values: [0], correct: true },
      { index: 2, values: [1], correct: false },
    ],
    visibilityLevel: 'details',
    times: 1,
    groupExamId: 'geId',
    version: 2,
  },
  {
    id: 'erId2',
    start: moment(),
    end: moment().add(10, 'minute'),
    contentId,
    userId: 'user00',
    groupId: 'group00',
    courseId: 'course-0',
    score: 10,
    problemCount: 20,
    answers: [
      { index: 0, values: [0], correct: true },
      { index: 1, values: [0], correct: true },
      { index: 2, values: [0, 1], correct: true },
    ],
    visibilityLevel: 'details',
    times: 1,
    groupExamId: 'geId',
    version: 2,
  },
];
