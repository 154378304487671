import {
  AbstractUseCase,
  SubscribeQuestionnaireStatusChanged,
  SubscribeQuestionnaireStatusChangedRequest,
  SubscribeQuestionnaireStatusChangedResponse,
} from '@/base/usecases';
import { createLogger } from '@/utils/log';

import { QuestionnaireRepository } from '../domains';

const logger = createLogger({
  boundedContext: 'base',
  name: 'SubscribeQuestionnaireStatusChanged',
});

export class SubscribeQuestionnaireStatusChangedImpl
  extends AbstractUseCase<
    SubscribeQuestionnaireStatusChangedRequest,
    SubscribeQuestionnaireStatusChangedResponse
  >
  implements SubscribeQuestionnaireStatusChanged
{
  constructor(private questionnaireRepository: QuestionnaireRepository) {
    super('training.SubscribeQuestionnaireStatusChanged');
  }

  async internalExecute(
    request: SubscribeQuestionnaireStatusChangedRequest
  ): Promise<SubscribeQuestionnaireStatusChangedResponse> {
    const {
      questionnaireId,
      onChange,
      onError = (error) =>
        logger.debug({
          message: 'an error occurred at subscription on QuestionnaireStatusChanged',
          error,
        }),
    } = request;
    const subscription = this.questionnaireRepository.subscribeQuestionnaireStatusChanged({
      questionnaireId,
      onChange,
      onError,
    });
    return { subscription };
  }
}
