import { AuthorizationService, AuthService, SignUpReservationId } from '@/base/domains';
import { AbstractUseCase, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

export type RemoveSignUpReservationsRequest = {
  signUpReservationIds: Array<SignUpReservationId>;
};

export type RemoveSignUpReservationsResponse = {};

export interface RemoveSignUpReservations
  extends UseCase<RemoveSignUpReservationsRequest, RemoveSignUpReservationsResponse> {
  execute(
    request: RemoveSignUpReservationsRequest
  ): Promise<UseCaseResponse<RemoveSignUpReservationsResponse>>;
}

export class RemoveSignUpReservationsImpl
  extends AbstractUseCase<RemoveSignUpReservationsRequest, RemoveSignUpReservationsResponse>
  implements RemoveSignUpReservations
{
  constructor(
    private authService: AuthService,
    private authorizationService: AuthorizationService
  ) {
    super('account.RemoveSignUpReservations');
  }

  async internalExecute(
    request: RemoveSignUpReservationsRequest
  ): Promise<RemoveSignUpReservationsResponse> {
    this.authorizationService.assertNotPlayground();
    this.authorizationService.assertRole('supervisor', 'admin');
    const { signUpReservationIds } = request;
    await Promise.all(
      signUpReservationIds.map((signUpReservationId) =>
        this.authService.removeSignUpReservation(signUpReservationId)
      )
    );
    return {};
  }
}

export const RemoveSignUpReservationsKey = injectionKeyOf<RemoveSignUpReservations>({
  boundedContext: 'account',
  type: 'usecase',
  name: 'RemoveSignUpReservations',
});

export function useRemoveSignUpReservations(): RemoveSignUpReservations {
  return requiredInject(RemoveSignUpReservationsKey);
}
