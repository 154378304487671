import { GroupId } from '@/base/domains';
import { errorCodeOf } from '@/base/error';

export const DISABLE_FAILED_USER_ROLE_IS_SUPERVISOR = errorCodeOf(
  'AC0001',
  '主管は無効化できません'
);

export const REMOVE_USER_SUPERVISOR_IS_UNREMOVAL = errorCodeOf<{ userId: string }>(
  'AC0002',
  '主管は削除できません'
);

export const INVITE_USER_ERROR_GROUP_USER_LIMIT_EXCEEDED = errorCodeOf<{ groupIds: GroupId[] }>(
  'AC0003',
  'これ以上ユーザーをグループに登録できません'
);
