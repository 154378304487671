import {
  ChangeConfirmedCourseNameInput,
  CourseStatus as AmplifyCourseStatus,
  CreateCourseHeaderInput,
  UpdateCourseHeaderInput,
} from '@/API';
import {
  AppContextProvider,
  CourseId,
  CourseName,
  CourseVersion,
  EntityData,
  hasId,
  TenantCode,
  UserId,
} from '@/base/domains';
import { Optional } from '@/base/types';
import {
  CourseHeaderAttributes,
  CourseHeaderEntity,
  CourseHeaderEntityImpl,
  CourseHeaderReference,
  CourseHeaderRepository,
  CourseStatus,
} from '@/contents/domains';
import { COURSE_DUPLICATED_NAME } from '@/contents/ErrorCodes';
import * as mutations from '@/graphql/mutations';
import * as queries from '@/graphql/queries';
import { graphql, graphqlQuery } from '@/utils/AmplifyUtils';
import { hasNonNullProperty, isObject, requiredNonNull } from '@/utils/TsUtils';

type AmplifyCourseHeader = {
  id: CourseId;
  name: CourseName;
  activeVersion: CourseVersion;
  status: AmplifyCourseStatus;
  originalCourseId?: CourseId;
  originalCourseVersion?: CourseVersion;
  tenantCode: TenantCode;
  createdBy: UserId;
};

function toCourseHeader(e: AmplifyCourseHeader): CourseHeaderEntity {
  return new CourseHeaderEntityImpl({
    ...e,
    status: e.status.toLowerCase() as CourseStatus,
  });
}

export class AmplifyCourseHeaderRepository implements CourseHeaderRepository {
  private appContextProvider: AppContextProvider;

  constructor(appContextProvider: AppContextProvider) {
    this.appContextProvider = appContextProvider;
  }

  async save(
    args: CourseHeaderEntity | EntityData<string, CourseHeaderAttributes>
  ): Promise<CourseHeaderEntity> {
    const tenantCode = requiredNonNull(
      this.appContextProvider.get().tenantCode,
      'appContext.tenantCode'
    );
    if (hasId(args)) {
      const input: UpdateCourseHeaderInput = {
        id: args.id,
        name: args.name,
        activeVersion: args.activeVersion,
        status: args.status.toUpperCase() as AmplifyCourseStatus,
        originalCourseId: args.originalCourseId,
        originalCourseVersion: args.originalCourseVersion,
        tenantCode,
        createdBy: args.createdBy,
      };
      const response = await graphql<{ updateCourseHeader: AmplifyCourseHeader }>(
        mutations.updateCourseHeader,
        { input }
      );
      return toCourseHeader(response.updateCourseHeader);
    }
    const createdBy = requiredNonNull(this.appContextProvider.get().user?.id, 'appContext.user.id');
    const input: CreateCourseHeaderInput = {
      name: args.name,
      activeVersion: args.activeVersion,
      status: args.status.toUpperCase() as AmplifyCourseStatus,
      originalCourseId: args.originalCourseId,
      originalCourseVersion: args.originalCourseVersion,
      tenantCode,
      createdBy,
    };
    try {
      const response = await graphql<{ createCourseHeader: AmplifyCourseHeader }>(
        mutations.createCourseHeader,
        { input }
      );
      return toCourseHeader(response.createCourseHeader);
    } catch (e) {
      if (
        isObject(e) &&
        hasNonNullProperty(e, 'message') &&
        e.message === 'Duplicated editing-course name'
      ) {
        throw COURSE_DUPLICATED_NAME.toApplicationError();
      }
      throw e;
    }
  }

  async findById(id: CourseId): Promise<Optional<CourseHeaderEntity>> {
    const response = await graphql<{ getCourseHeader: Optional<AmplifyCourseHeader> }>(
      queries.getCourseHeader,
      { id }
    );
    return response.getCourseHeader ? toCourseHeader(response.getCourseHeader) : undefined;
  }

  async remove(id: CourseId): Promise<void> {
    await graphql(mutations.deleteCourseHeader, { input: { id } });
  }

  async tenantCourses(): Promise<CourseHeaderReference[]> {
    const tenantCode = requiredNonNull(
      this.appContextProvider.get().tenantCode,
      'appContext.tenantCode'
    );
    const response = await graphqlQuery<{
      courseHeaderByTenantCode: { items: Array<AmplifyCourseHeader> };
    }>(
      queries.courseHeaderByTenantCode,
      {
        tenantCode,
      },
      {
        limit: 1000,
      }
    );
    return response.courseHeaderByTenantCode.items.map(toCourseHeader);
  }

  async findByName(name: CourseName): Promise<Optional<CourseHeaderReference>> {
    const response = await graphql<{
      courseHeaderByName: { items: Array<AmplifyCourseHeader> };
    }>(queries.courseHeaderByName, { name });
    return response.courseHeaderByName.items.map(toCourseHeader)[0];
  }

  async changeName(id: CourseId, name: CourseName): Promise<void> {
    const input: ChangeConfirmedCourseNameInput = {
      id,
      name,
    };
    await graphql<{ changeConfirmedCourseName: string }>(mutations.changeConfirmedCourseName, {
      input,
    });
  }
}
