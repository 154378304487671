import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { ActiveExam, ExamDataAdapter } from '../domains';
import { AbstractUseCase, UseCase, UseCaseResponse } from './UseCase';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface GetActiveExamRequest {}

export type GetActiveExamResponse = {
  exam?: ActiveExam;
};

/**
 * 実施中のテストを取得する
 */
export interface GetActiveExam extends UseCase<GetActiveExamRequest, GetActiveExamResponse> {
  execute(request: GetActiveExamRequest): Promise<UseCaseResponse<GetActiveExamResponse>>;
}

export class GetActiveExamImpl
  extends AbstractUseCase<GetActiveExamRequest, GetActiveExamResponse>
  implements GetActiveExam
{
  private examDataAdapter: ExamDataAdapter;

  constructor(examDataAdapter: ExamDataAdapter) {
    super('base.GetActiveExam');
    this.examDataAdapter = examDataAdapter;
  }

  async internalExecute(_: GetActiveExamRequest): Promise<GetActiveExamResponse> {
    const exam = await this.examDataAdapter.findActiveExam();
    return {
      exam,
    };
  }
}

export const GetActiveExamKey = injectionKeyOf<GetActiveExam>({
  boundedContext: 'base',
  type: 'usecase',
  name: 'GetActiveExam',
});

export function useGetActiveExam(): GetActiveExam {
  return requiredInject(GetActiveExamKey);
}
