import {
  AuthorizationService,
  ContentId,
  ContentName,
  ContentType,
  CourseColor,
  CourseId,
  CourseName,
  CourseVersion,
  UserId,
} from '@/base/domains';
import { LocalDateTime, Minute, URI } from '@/base/types';
import { AbstractUseCase, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { CourseRepository } from '../domains';
import { EditingConfirmedContentQueries } from '../domains/queries';

export interface GetCourseHistoriesRequest {
  id: CourseId;
}

export type GetCourseHistoriesContent = {
  id: ContentId;
  type: ContentType;
  name: ContentName;
  requiredTime: Minute;
  hasEditingVersion: boolean;
};

export type GetCourseHistoriesCourse = {
  /** コースID */
  id: CourseId;
  /** コース名 */
  name: CourseName;
  /** バージョン */
  version: CourseVersion;
  /** コンテンツ */
  contents: Array<GetCourseHistoriesContent>;
  /** 説明 */
  description?: string;
  /** カラー */
  color?: CourseColor;
  /** イメージ画像 */
  image?: URI;
  /** 確定日時 */
  confirmedBy?: UserId;
  /** 確定日時 */
  confirmedAt: LocalDateTime;
  /** 作成ユーザー */
  versionCreatedBy?: UserId;
  /** 作成日時 */
  versionCreatedAt?: LocalDateTime;
  /** コンテンツ最終更新ユーザー */
  contentLastUpdatedBy?: UserId;
  /** コンテンツ最終更新日時 */
  contentLastUpdatedAt?: LocalDateTime;
};

export type GetCourseHistoriesResponse = {
  histories: Array<GetCourseHistoriesCourse>;
};

export interface GetCourseHistories
  extends UseCase<GetCourseHistoriesRequest, GetCourseHistoriesResponse> {
  execute(request: GetCourseHistoriesRequest): Promise<UseCaseResponse<GetCourseHistoriesResponse>>;
}

export class GetCourseHistoriesImpl
  extends AbstractUseCase<GetCourseHistoriesRequest, GetCourseHistoriesResponse>
  implements GetCourseHistories
{
  constructor(
    private authorizationService: AuthorizationService,
    private courseRepository: CourseRepository,
    private editingConfirmedContentQueries: EditingConfirmedContentQueries
  ) {
    super('contents.GetCourseHistories');
  }

  async internalExecute(request: GetCourseHistoriesRequest): Promise<GetCourseHistoriesResponse> {
    const { id } = request;
    this.authorizationService.assertContentEditable();
    const [courses, editingConfirmedContents] = await Promise.all([
      this.courseRepository.findCoursesById(id),
      this.editingConfirmedContentQueries.findByCourseId(id),
    ]);
    const editingConfirmedContentIds = new Set(editingConfirmedContents.map((c) => c.id));
    return {
      histories: courses.map((cr) => ({
        id: cr.id,
        name: cr.name,
        version: cr.version,
        description: cr.description,
        contents: cr.contents.map((cn) => ({
          id: cn.id,
          name: cn.name,
          type: cn.type,
          requiredTime: cn.requiredTime,
          hasEditingVersion: editingConfirmedContentIds.has(cn.id),
        })),
        color: cr.color,
        image: cr.image,
        confirmedBy: cr.confirmedBy,
        confirmedAt: cr.confirmedAt,
        versionCreatedBy: cr.versionCreatedBy,
        versionCreatedAt: cr.versionCreatedAt,
        contentLastUpdatedBy: cr.contentLastUpdatedBy,
        contentLastUpdatedAt: cr.contentLastUpdatedAt,
      })),
    };
  }
}

export const GetCourseHistoriesKey = injectionKeyOf<GetCourseHistories>({
  boundedContext: 'contents',
  type: 'usecase',
  name: 'GetCourseHistories',
});

export function useGetCourseHistories(): GetCourseHistories {
  return requiredInject(GetCourseHistoriesKey);
}
