import {
  CourseId,
  EntityData,
  GroupId,
  ReviewProblemId,
  ReviewProblemProblem,
  StandardRepository,
  UserId,
} from '@/base/domains';
import { Optional } from '@/base/types';
import { sha256 } from '@/utils/HashUtils';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

export function reviewProblemIdOf({
  groupId,
  courseId,
  userId,
}: {
  groupId: GroupId;
  courseId: CourseId;
  userId: UserId;
}): ReviewProblemId {
  return `${groupId}#${courseId}#${userId}`;
}

export function attributesFromReviewProblemId(id: ReviewProblemId): {
  groupId: GroupId;
  courseId: CourseId;
  userId: UserId;
} {
  const [groupId, courseId, userId] = id.split('#');
  return {
    groupId,
    courseId,
    userId,
  };
}

/**
 * 復習問題属性
 */
export type ReviewProblemAttributes = {
  /** ユーザーID */
  userId: UserId;
  /** グループID */
  groupId: GroupId;
  /** コースID */
  courseId: CourseId;
  /** 問題リスト */
  problems: ReviewProblemProblem[];
};

/**
 * 復習問題データ
 */
export type ReviewProblemData = EntityData<ReviewProblemId, ReviewProblemAttributes>;

/**
 * 復習問題コマンド
 */
export type ReviewProblemCommands = {
  removeProblems(hashListToRemove: string[]): ReviewProblemEntity;
  finish(hashListToRemove: string[]): ReviewProblemEntity;
  changeProblems(
    problems: Omit<ReviewProblemProblem, 'index' | 'hash'>[],
    problemsToRemove: Pick<ReviewProblemProblem, 'body' | 'answer' | 'options'>[]
  ): ReviewProblemEntity;
};

/**
 * 復習問題クエリ
 */
export type ReviewProblemQueries = {};

/**
 * 復習問題リファレンス
 */
export interface ReviewProblemReference
  extends Readonly<ReviewProblemAttributes>,
    ReviewProblemQueries {
  id: ReviewProblemId;
}

/**
 * 復習問題エンティティ
 */
export interface ReviewProblemEntity extends ReviewProblemReference, ReviewProblemCommands {}

export type ReviewProblemRepositoryFindMyReviewProblemArgs = {
  groupId: GroupId;
  courseId: CourseId;
};

export type ReviewProblemRepositoryFindMyReviewProblemsArgs = {
  groupId: GroupId;
};

/**
 * 復習問題リポジトリ
 */
export type ReviewProblemRepository = StandardRepository<
  ReviewProblemId,
  ReviewProblemAttributes,
  ReviewProblemEntity
> & {
  findMyReviewProblem(
    args: ReviewProblemRepositoryFindMyReviewProblemArgs
  ): Promise<Optional<ReviewProblemEntity>>;

  findMyReviewProblems(
    args: ReviewProblemRepositoryFindMyReviewProblemsArgs
  ): Promise<ReviewProblemEntity[]>;
};

export const ReviewProblemRepositoryKey = injectionKeyOf<ReviewProblemRepository>({
  boundedContext: 'training',
  type: 'adapter',
  name: 'ReviewProblemRepository',
});

export function useReviewProblemRepository(): ReviewProblemRepository {
  return requiredInject(ReviewProblemRepositoryKey);
}

export const problemHashFromProblem: (
  p: Pick<ReviewProblemProblem, 'body' | 'answer' | 'options'>
) => string = (p) =>
  sha256([p.body, JSON.stringify(p.answer), JSON.stringify(p.options)].join('___'));
