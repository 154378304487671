import moment from 'moment';

import { LocalDateTime } from '@/base/types';

export function localDateTimeNow(): LocalDateTime {
  return moment();
}

export function localDateTimeFromString(value: string): LocalDateTime {
  return moment(value);
}
