import { GroupId, ScheduleTag, ScheduleTagDataAdapter } from '@/base/domains';

import { InMemoryScheduleTagRepository } from './InMemoryScheduleTagRepository';

export class InMemoryScheduleTagDataAdapter implements ScheduleTagDataAdapter {
  private inMemoryScheduleTagRepository: InMemoryScheduleTagRepository;

  constructor(inMemoryScheduleTagRepository: InMemoryScheduleTagRepository) {
    this.inMemoryScheduleTagRepository = inMemoryScheduleTagRepository;
  }

  findScheduleTagsByGroupId(groupId: GroupId): Promise<Array<ScheduleTag>> {
    const result = this.inMemoryScheduleTagRepository.all().filter((st) => st.groupId === groupId);
    return Promise.resolve(result);
  }
}
