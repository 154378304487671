import {
  AuthorizationService,
  ContentId,
  ContentName,
  CourseDisplayName,
  CourseId,
  ExamDataAdapter,
  GroupExam,
  GroupFinder,
  GroupId,
  GroupTrainingFinder,
  UserFinder,
  UserId,
  UserName,
} from '@/base/domains';
import { AbstractUseCase, assertEntityExists, UseCase, UseCaseResponse } from '@/base/usecases';
import { requiredNonNull, uniqueArray } from '@/utils/TsUtils';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

export type GetGroupReportSearchConditionsRequest = {
  groupId: GroupId;
};

export type GetGroupReportSearchConditionsContent = {
  id: ContentId;
  name: ContentName;
  courseId: CourseId;
  courseName: CourseDisplayName;
  indexInCourse: number;
  problemCount: number;
};

export type GetGroupReportSearchConditionsUser = {
  id: UserId;
  name?: UserName;
  removed: boolean;
};

export type GetGroupReportSearchConditionsResponse = {
  contents: Array<GetGroupReportSearchConditionsContent>;
  users: Array<GetGroupReportSearchConditionsUser>;
  groupExams: Array<GroupExam>;
};

/**
 * グループリポートの検索条件用データを取得する
 */
export interface GetGroupReportSearchConditions
  extends UseCase<GetGroupReportSearchConditionsRequest, GetGroupReportSearchConditionsResponse> {
  execute(
    request: GetGroupReportSearchConditionsRequest
  ): Promise<UseCaseResponse<GetGroupReportSearchConditionsResponse>>;
}

export class GetGroupReportSearchConditionsImpl
  extends AbstractUseCase<
    GetGroupReportSearchConditionsRequest,
    GetGroupReportSearchConditionsResponse
  >
  implements GetGroupReportSearchConditions
{
  constructor(
    private authorizationService: AuthorizationService,
    private examDataAdapter: ExamDataAdapter,
    private groupTrainingFinder: GroupTrainingFinder,
    private userFinder: UserFinder,
    private groupFinder: GroupFinder
  ) {
    super('report.GetGroupReportSearchConditions');
  }

  async internalExecute(
    request: GetGroupReportSearchConditionsRequest
  ): Promise<GetGroupReportSearchConditionsResponse> {
    const { groupId } = request;
    this.authorizationService.assertGroupReadAccessible(groupId);
    const [users, courses, groupExams, group] = await Promise.all([
      this.userFinder.findTenantUsers(),
      this.groupTrainingFinder.findCoursesByGroupId(groupId),
      this.examDataAdapter.findGroupExamsByGroupId(groupId),
      this.groupFinder.findById(groupId, { includeRemovedGroupUser: true }),
    ]);
    const userNames = new Map(users.map((u) => [u.id, u.name]));
    assertEntityExists(group, 'group');
    const removedUserIds = group.users.filter((user) => user.removed).map((u) => u.id);
    const uniqueUserIds = uniqueArray(
      groupExams.flatMap((ge) => ge.userExams.map((ue) => ue.userId))
    );
    const groupExamContents = new Map(
      groupExams.map((ge) => [ge.content.id, ge.content.problems.length])
    );
    const contents = courses.flatMap((cr) =>
      cr.contents
        .filter((cn) => groupExamContents.has(cn.id))
        .map((cn, i) => ({
          id: cn.id,
          name: cn.name,
          courseId: cr.id,
          courseName: cr.displayName,
          indexInCourse: i,
          problemCount: requiredNonNull(groupExamContents.get(cn.id), 'groupExam.contents'),
        }))
    );
    return {
      users: uniqueUserIds.map((userId) => ({
        id: userId,
        name: userNames.get(userId),
        removed: removedUserIds.includes(userId),
      })),
      contents,
      groupExams,
    };
  }
}

export const GetGroupReportSearchConditionsKey = injectionKeyOf<GetGroupReportSearchConditions>({
  boundedContext: 'report',
  type: 'usecase',
  name: 'GetGroupReportSearchConditions',
});

export function useGetGroupReportSearchConditions(): GetGroupReportSearchConditions {
  return requiredInject(GetGroupReportSearchConditionsKey);
}
