import { User } from '@/API';
import { AuthorizationService, localEventBus, UserId, UserRepository } from '@/base/domains';
import { usersDisabled } from '@/base/domains/LocalEvents';
import { AbstractUseCase, assertEntityExists, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { DISABLE_FAILED_USER_ROLE_IS_SUPERVISOR } from '../ErrorCodes';

export type DisableUsersRequest = {
  userIds: Array<UserId>;
};

export type DisableUsersResponse = {
  users: Array<User>;
};

export interface DisableUsers extends UseCase<DisableUsersRequest, DisableUsersResponse> {
  execute(request: DisableUsersRequest): Promise<UseCaseResponse<DisableUsersResponse>>;
}

export class DisableUsersImpl
  extends AbstractUseCase<DisableUsersRequest, DisableUsersResponse>
  implements DisableUsers
{
  constructor(
    private userRepository: UserRepository,
    private authorizationService: AuthorizationService
  ) {
    super('account.DisableUsers');
  }

  async internalExecute(request: DisableUsersRequest): Promise<DisableUsersResponse> {
    this.authorizationService.assertNotPlayground();
    this.authorizationService.assertRole('supervisor', 'admin');
    const { userIds } = request;
    const users = await Promise.all(
      userIds.map(async (userId) => {
        const user = await this.userRepository.findById(userId);
        assertEntityExists(user, 'user');
        if (user.role === 'supervisor') {
          throw DISABLE_FAILED_USER_ROLE_IS_SUPERVISOR.toApplicationError();
        }
        return user;
      })
    );
    await Promise.all(users.map((user) => this.userRepository.save(user.disable())));
    localEventBus.publish(usersDisabled({ userIds }));
    return {
      users: [],
    };
  }
}

export const DisableUsersKey = injectionKeyOf<DisableUsers>({
  boundedContext: 'account',
  type: 'usecase',
  name: 'DisableUsers',
});

export function useDisableUsers(): DisableUsers {
  return requiredInject(DisableUsersKey);
}
