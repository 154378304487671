import BigNumber from 'bignumber.js';

import {
  AuthorizationService,
  ContentId,
  ContentLearningDataAdapter,
  CourseDisplayName,
  CourseId,
  ExamDataAdapter,
  ExamResult,
  GroupFinder,
  GroupId,
  GroupTrainingFinder,
  QuestionFinder,
  UserId,
} from '@/base/domains';
import { LocalDate, LocalDateTime, Minute, Optional, Percentage } from '@/base/types';
import { AbstractUseCase, assertEntityExists, UseCase, UseCaseResponse } from '@/base/usecases';
import { uniqueArray } from '@/utils/TsUtils';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

export type GetTrainerUserReportRequest = {
  groupId: GroupId;
  userId: UserId;
};

export type GetTrainerUserReportResponseCourse = {
  /** コースID */
  id: CourseId;
  /** コース名 */
  name: CourseDisplayName;
  /** 利用開始日時 */
  startOfUse?: LocalDateTime;
  /** 直近利用日時 */
  recentUsedAt?: LocalDateTime;
  /** 利用時間 */
  usageTime: Minute;
  /** グループ利用時間 */
  groupUsageTime: Minute;
  /** グループ平均利用時間 */
  groupAverageUsageTime: BigNumber;
  /** コメント数 */
  commentCount: number;
  /** グループ平均コメント数 */
  groupAverageCommentCount: BigNumber;
  /** コンテンツ数 */
  contentCount: number;
  /** 未着手コンテンツ数 */
  notBegunContentCount: number;
  /** 実施中コンテンツ数 */
  inProgressContentCount: number;
  /** 完了コンテンツ数 */
  completedContentCount: number;
  /** 進捗率 */
  progressRate: Percentage;
  /** 完了テスト数 */
  completedExamCount: number;
  /** 完了テストコンテンツ数 */
  completedExamContentCount: number;
  /** 正答数 */
  correctAnswerCount: number;
  /** テスト問題数 */
  examProblemCount: number;
  /** 正答率 */
  correctAnswerRate: Percentage;
  /** 最新正答数 */
  latestCorrectAnswerCount: number;
  /** 最新テスト問題数 */
  latestExamProblemCount: number;
  /** 最新正答率 */
  latestCorrectAnswerRate: Percentage;
};

export type GetTrainerUserReportResponse = {
  /** 利用開始日 */
  startOfUse?: LocalDate;
  /** 直近利用日時 */
  recentUsedAt?: LocalDateTime;
  /** 利用時間 */
  usageTime: Minute;
  /** コース数 */
  courseCount: number;
  /** 完了コース数 */
  completedCourseCount: number;
  /** コース進捗率 */
  courseProgressRate: Percentage;
  /** コンテンツ数 */
  contentCount: number;
  /** 完了コンテンツ数 */
  completedContentCount: number;
  /** コンテンツ進捗率 */
  contentProgressRate: Percentage;
  /** コメント数 */
  commentCount: number;
  /** 完了テスト数 */
  completedExamCount: number;
  /** 正答数 */
  correctAnswerCount: number;
  /** テスト問題数 */
  examProblemCount: number;
  /** 正答率 */
  correctAnswerRate: Percentage;
  /** グループ平均コメント数 */
  groupAverageCommentCount: BigNumber;
  /** グループ平均利用時間 */
  groupAverageUsageTime: BigNumber;
  /** コースリスト */
  courses: Array<GetTrainerUserReportResponseCourse>;
  /** テスト結果リスト */
  examResults: Array<ExamResult>;
};

export interface GetTrainerUserReport
  extends UseCase<GetTrainerUserReportRequest, GetTrainerUserReportResponse> {
  execute(
    request: GetTrainerUserReportRequest
  ): Promise<UseCaseResponse<GetTrainerUserReportResponse>>;
}

function rate(a: BigNumber, b: BigNumber): BigNumber {
  if (b.eq(new BigNumber('0'))) {
    return new BigNumber('0');
  }
  return new BigNumber(a).dividedBy(new BigNumber(b)).multipliedBy(new BigNumber('100')).dp(2);
}

function average(a: BigNumber, b: BigNumber): BigNumber {
  if (b.eq(new BigNumber('0'))) {
    return new BigNumber('0');
  }
  return new BigNumber(a).dividedBy(new BigNumber(b)).dp(2);
}

export class GetTrainerUserReportImpl
  extends AbstractUseCase<GetTrainerUserReportRequest, GetTrainerUserReportResponse>
  implements GetTrainerUserReport
{
  constructor(
    private authorizationService: AuthorizationService,
    private questionFinder: QuestionFinder,
    private contentLearningDataAdapter: ContentLearningDataAdapter,
    private groupFinder: GroupFinder,
    private groupTrainingFinder: GroupTrainingFinder,
    private examDataAdapter: ExamDataAdapter
  ) {
    super('report.GetTrainerUserReport');
  }

  async internalExecute({
    groupId,
    userId,
  }: GetTrainerUserReportRequest): Promise<GetTrainerUserReportResponse> {
    this.authorizationService.assertGroupReportAccessible(groupId);
    const [comments, rawContentLearnings, group, groupTrainingCourses, examResults] =
      await Promise.all([
        this.questionFinder.findCommentHeaders({ groupId }),
        this.contentLearningDataAdapter.findByGroupId(groupId),
        this.groupFinder.findById(groupId),
        this.groupTrainingFinder.findCoursesByGroupId(groupId),
        this.examDataAdapter.findExamResults({ groupId, userId }),
      ]);
    assertEntityExists(group, 'group');
    const contentToCourse = new Map(
      groupTrainingCourses.flatMap((cr) => cr.contents.map((cn) => [cn.id, cr.id]))
    );
    const contentLearnings = rawContentLearnings.filter(
      (cl) => !!contentToCourse.get(cl.contentId)
    );
    const traineeIds = group.users.filter((u) => u.role === 'trainee').map((u) => u.id);
    // このユーザーが使用開始した日時
    const startOfUse = rawContentLearnings
      .filter((cl) => cl.userId === userId)
      .reduce<Optional<LocalDate>>(
        (acc, v) => (!acc || v.startedAt.isBefore(acc) ? v.startedAt : acc),
        undefined
      );
    // このユーザーが直近で利用した日時
    const recentUsedAt = rawContentLearnings
      .filter((cl) => cl.userId === userId)
      .reduce<Optional<LocalDate>>(
        (acc, v) =>
          !acc || (v.lastLearnedAt && v.lastLearnedAt.isAfter(acc)) ? v.lastLearnedAt : acc,
        undefined
      );
    const courseLearnings: Map<
      CourseId,
      {
        usageTime: Minute;
        completedContentCount: number;
        inProgressContentCount: number;
        startOfUse: Optional<LocalDateTime>;
        recentUsedAt: Optional<LocalDateTime>;
        groupUsageTime: number;
      }
    > = contentLearnings.reduce((acc, v) => {
      const e = acc.get(v.courseId) ?? {
        usageTime: 0,
        completedContentCount: 0,
        inProgressContentCount: 0,
        startOfUse: undefined,
        recentUsedAt: undefined,
        groupUsageTime: 0,
      };
      if (v.userId === userId) {
        e.usageTime += v.usageTime;
        e.completedContentCount =
          v.status === 'completed' ? e.completedContentCount + 1 : e.completedContentCount;
        e.inProgressContentCount =
          v.status === 'in_progress' ? e.inProgressContentCount + 1 : e.inProgressContentCount;
        e.startOfUse =
          !e.startOfUse || v.startedAt.isBefore(e.startOfUse) ? v.startedAt : e.startOfUse;
        e.recentUsedAt =
          !e.recentUsedAt || (v.lastLearnedAt && v.lastLearnedAt.isAfter(e.recentUsedAt))
            ? v.lastLearnedAt
            : e.recentUsedAt;
      }
      if (traineeIds.includes(v.userId)) {
        // 現在グループに含まれるユーザーのみ計算に含める
        e.groupUsageTime += v.usageTime;
      }
      return acc.set(v.courseId, e);
    }, new Map());
    const commentsGroupedByCourse: Map<
      CourseId,
      { commentCount: number; userCommentCount: number }
    > = comments.reduce((acc, v) => {
      if (!v.contentId) {
        return acc;
      }
      const courseId = contentToCourse.get(v.contentId);
      if (!courseId) {
        return acc;
      }
      let e = acc.get(courseId) ?? { commentCount: 0, userCommentCount: 0 };
      if (v.createdBy === userId) {
        e = { commentCount: e.commentCount, userCommentCount: e.userCommentCount + 1 };
      }
      // 現在グループに所属するユーザーのみ件数に含める
      if (traineeIds.includes(v.createdBy)) {
        e = { commentCount: e.commentCount + 1, userCommentCount: e.userCommentCount };
      }
      acc.set(courseId, e);
      return acc;
    }, new Map());
    const examResultsGroupedByCourse: Map<CourseId, Array<ExamResult>> = examResults.reduce(
      (acc, v) => {
        const ers = acc.get(v.courseId) ?? [];
        acc.set(v.courseId, [...ers, v]);
        return acc;
      },
      new Map()
    );
    const latestExamResultsGroupedByContent: Map<ContentId, ExamResult> = examResults.reduce(
      (acc, v) => {
        const er = acc.get(v.contentId);
        const accTimes = (() => {
          if (!er) {
            return -1;
          }
          if (er.version === 1) {
            return 1;
          }
          return er.times;
        })();
        const times = v.version === 1 ? 1 : v.times;
        if (times > accTimes) {
          acc.set(v.contentId, v);
        }
        return acc;
      },
      new Map()
    );
    const traineeCount = traineeIds.length;
    const courses: Array<GetTrainerUserReportResponseCourse> = groupTrainingCourses.map((cr) => {
      const courseLearning = courseLearnings.get(cr.id) ?? {
        usageTime: 0,
        completedContentCount: 0,
        inProgressContentCount: 0,
        startOfUse: undefined,
        recentUsedAt: undefined,
        groupUsageTime: 0,
      };
      const courseComments = commentsGroupedByCourse.get(cr.id) ?? {
        commentCount: 0,
        userCommentCount: 0,
      };
      const ers = examResultsGroupedByCourse.get(cr.id) ?? [];
      const correctAnswerCount = ers
        .map((er) => er.answers.filter((a) => a.correct).length)
        .reduce((acc, v) => acc + v, 0);
      const examProblemCount = ers.map((er) => er.problemCount).reduce((acc, v) => acc + v, 0);
      const correctAnswerRate = rate(
        new BigNumber(correctAnswerCount),
        new BigNumber(examProblemCount)
      );
      const { latestCorrectAnswerCount, latestExamProblemCount } = cr.contents
        .map((cn) => latestExamResultsGroupedByContent.get(cn.id))
        .reduce(
          (acc, v) => {
            if (v) {
              return {
                latestCorrectAnswerCount:
                  acc.latestCorrectAnswerCount + v.answers.filter((a) => a.correct).length,
                latestExamProblemCount: acc.latestExamProblemCount + v.problemCount,
              };
            }
            return acc;
          },
          {
            latestCorrectAnswerCount: 0,
            latestExamProblemCount: 0,
          }
        );

      return {
        id: cr.id,
        name: cr.displayName,
        startOfUse: courseLearning.startOfUse,
        recentUsedAt: courseLearning.recentUsedAt,
        usageTime: courseLearning.usageTime,
        groupUsageTime: courseLearning.groupUsageTime,
        groupAverageUsageTime: average(
          new BigNumber(courseLearning.groupUsageTime),
          new BigNumber(traineeCount)
        ),
        commentCount: courseComments.userCommentCount,
        groupAverageCommentCount: average(
          new BigNumber(courseComments.commentCount),
          new BigNumber(traineeCount)
        ),
        contentCount: cr.contents.length,
        notBegunContentCount:
          cr.contents.length -
          courseLearning.completedContentCount -
          courseLearning.inProgressContentCount,
        inProgressContentCount: courseLearning.inProgressContentCount,
        completedContentCount: courseLearning.completedContentCount,
        progressRate: rate(
          new BigNumber(courseLearning.completedContentCount),
          new BigNumber(cr.contents.length)
        ),
        completedExamCount: ers.length,
        completedExamContentCount: uniqueArray(ers.map((er) => er.contentId)).length,
        correctAnswerCount,
        examProblemCount,
        correctAnswerRate,
        latestCorrectAnswerCount,
        latestExamProblemCount,
        latestCorrectAnswerRate: rate(
          new BigNumber(latestCorrectAnswerCount),
          new BigNumber(latestExamProblemCount)
        ),
      };
    });
    const completedCourseCount = courses.filter(
      (cr) => cr.completedContentCount === cr.contentCount
    ).length;
    const courseProgressRate = rate(
      new BigNumber(completedCourseCount),
      new BigNumber(courses.length)
    );
    const contentCount = courses.reduce((acc, v) => acc + v.contentCount, 0);
    const completedContentCount = courses.reduce((acc, v) => acc + v.completedContentCount, 0);
    const contentProgressRate = rate(
      new BigNumber(completedContentCount),
      new BigNumber(contentCount)
    );
    const commentCount = Array.from(commentsGroupedByCourse.values())
      .map((cm) => cm.userCommentCount)
      .reduce((acc, v) => acc + v, 0);
    const completedExamCount = examResults.length;
    const correctAnswerCount = courses
      .map((cr) => cr.correctAnswerCount)
      .reduce((acc, v) => acc + v);
    const examProblemCount = courses.map((cr) => cr.examProblemCount).reduce((acc, v) => acc + v);
    const correctAnswerRate = rate(
      new BigNumber(correctAnswerCount),
      new BigNumber(examProblemCount)
    );
    const usageTime = courses.map((cr) => cr.usageTime).reduce((acc, v) => acc + v);
    const totalGroupUsageTime = courses.map((cr) => cr.groupUsageTime).reduce((acc, v) => acc + v);
    const groupAverageUsageTime = average(
      new BigNumber(totalGroupUsageTime),
      new BigNumber(traineeCount)
    );
    const groupAverageCommentCount = average(
      new BigNumber(
        Array.from(commentsGroupedByCourse.values())
          .map((cm) => cm.commentCount)
          .reduce((acc, v) => acc + v, 0)
      ),
      new BigNumber(traineeCount)
    );

    return {
      startOfUse,
      recentUsedAt,
      usageTime,
      groupAverageUsageTime,
      courseCount: courses.length,
      completedCourseCount,
      courseProgressRate,
      contentCount,
      completedContentCount,
      contentProgressRate,
      commentCount,
      groupAverageCommentCount,
      completedExamCount,
      correctAnswerCount,
      examProblemCount,
      correctAnswerRate,
      courses,
      examResults,
    };
  }
}

export const GetTrainerUserReportKey = injectionKeyOf<GetTrainerUserReport>({
  boundedContext: 'report',
  type: 'usecase',
  name: 'GetTrainerUserReport',
});

export function useGetTrainerUserReport(): GetTrainerUserReport {
  return requiredInject(GetTrainerUserReportKey);
}
