import { AuthorizationService, TenantOwnTermsOfServiceFinder } from '@/base/domains';
import { MarkDownString } from '@/base/types';
import { AbstractUseCase, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import {
  EditingTenantOwnTermsOfServiceEntity,
  EditingTenantOwnTermsOfServiceRepository,
} from '../domains';
import { EDITING_TENANT_OWN_DUPLICATED_TERMS_OF_SERVICE } from '../ErrorCodes';

export type CreateEditingTenantOwnTermsOfServiceRequest = {
  body: MarkDownString;
};

export type CreateEditingTenantOwnTermsOfServiceResponse = {
  editingTenantOwnTermsOfService: EditingTenantOwnTermsOfServiceEntity;
};

export interface CreateEditingTenantOwnTermsOfService
  extends UseCase<
    CreateEditingTenantOwnTermsOfServiceRequest,
    CreateEditingTenantOwnTermsOfServiceResponse
  > {
  execute(
    request: CreateEditingTenantOwnTermsOfServiceRequest
  ): Promise<UseCaseResponse<CreateEditingTenantOwnTermsOfServiceResponse>>;
}

export class CreateEditingTenantOwnTermsOfServiceImpl
  extends AbstractUseCase<
    CreateEditingTenantOwnTermsOfServiceRequest,
    CreateEditingTenantOwnTermsOfServiceResponse
  >
  implements CreateEditingTenantOwnTermsOfService
{
  constructor(
    private authorizationService: AuthorizationService,
    private tenantOwnTermsOfServiceFinder: TenantOwnTermsOfServiceFinder,
    private editingTenantOwnTermsOfServiceRepository: EditingTenantOwnTermsOfServiceRepository
  ) {
    super('admin.CreateEditingTenantOwnTermsOfService');
  }

  async internalExecute(
    request: CreateEditingTenantOwnTermsOfServiceRequest
  ): Promise<CreateEditingTenantOwnTermsOfServiceResponse> {
    this.authorizationService.assertRole('supervisor');
    const { body } = request;
    const editingTenantOwnTermsOfService =
      await this.editingTenantOwnTermsOfServiceRepository.findEditingTenantOwnTermsOfService();
    if (editingTenantOwnTermsOfService)
      throw EDITING_TENANT_OWN_DUPLICATED_TERMS_OF_SERVICE.toApplicationError();
    const tenantOwnTermsOfService =
      await this.tenantOwnTermsOfServiceFinder.findTenantOwnTermsOfService();
    const res = await this.editingTenantOwnTermsOfServiceRepository.save(
      body,
      tenantOwnTermsOfService?.version ? tenantOwnTermsOfService.version + 1 : 1
    );
    return { editingTenantOwnTermsOfService: res };
  }
}

export const CreateEditingTenantOwnTermsOfServiceKey =
  injectionKeyOf<CreateEditingTenantOwnTermsOfService>({
    boundedContext: 'admin',
    type: 'usecase',
    name: 'CreateEditingTenantOwnTermsOfService',
  });

export function useCreateEditingTenantOwnTermsOfService(): CreateEditingTenantOwnTermsOfService {
  return requiredInject(CreateEditingTenantOwnTermsOfServiceKey);
}
