import { GroupId, GroupTrainingFinder, UserId } from '@/base/domains';
import {
  AbstractUseCase,
  QuestionWithNames,
  UseCase,
  UseCaseResponse,
  withNamesConverter,
} from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { QuestionRepository } from '../domains';

export interface GetQuestionsRequest {
  groupId: GroupId;
  createdBy?: UserId;
  resolved?: boolean;
}

export type GetQuestionsResponse = {
  questions: Array<QuestionWithNames>;
  limitExceeded: boolean;
};

/**
 * 質問リストを取得する
 */
export interface GetQuestions extends UseCase<GetQuestionsRequest, GetQuestionsResponse> {
  execute(request: GetQuestionsRequest): Promise<UseCaseResponse<GetQuestionsResponse>>;
}

export class GetQuestionsImpl
  extends AbstractUseCase<GetQuestionsRequest, GetQuestionsResponse>
  implements GetQuestions
{
  constructor(
    private questionRepository: QuestionRepository,
    private groupTrainingFinder: GroupTrainingFinder
  ) {
    super('training.GetQuestions');
  }

  async internalExecute(request: GetQuestionsRequest): Promise<GetQuestionsResponse> {
    const [findQuestionResult, courses] = await Promise.all([
      this.questionRepository.findQuestions(request),
      this.groupTrainingFinder.findCoursesByGroupId(request.groupId),
    ]);
    const { questions, limitExceeded } = findQuestionResult;
    const names = new Map(
      courses.flatMap((cr) =>
        cr.contents.map((cn) => [
          cn.id,
          { contentName: cn.name, courseId: cr.id, courseName: cr.displayName },
        ])
      )
    );

    const converter = withNamesConverter(names);
    const questionsWithNames: Array<QuestionWithNames> = questions.map(converter);
    return {
      questions: questionsWithNames,
      limitExceeded,
    };
  }
}

export const GetQuestionsKey = injectionKeyOf<GetQuestions>({
  boundedContext: 'training',
  type: 'usecase',
  name: 'GetQuestions',
});

export function useGetQuestions(): GetQuestions {
  return requiredInject(GetQuestionsKey);
}
