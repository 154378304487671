import { AuthorizationService, GroupId, ScheduleColor } from '@/base/domains';
import { AbstractUseCase, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { ScheduleTagReference, ScheduleTagRepository } from '../domains';

export interface CreateScheduleTagRequest {
  text: string;
  color: ScheduleColor;
  groupId: GroupId;
}

export type CreateScheduleTagResponse = {
  scheduleTag: ScheduleTagReference;
};

/**
 * スケジュールタグを登録する
 */
export interface CreateScheduleTag
  extends UseCase<CreateScheduleTagRequest, CreateScheduleTagResponse> {
  execute(request: CreateScheduleTagRequest): Promise<UseCaseResponse<CreateScheduleTagResponse>>;
}

export class CreateScheduleTagImpl
  extends AbstractUseCase<CreateScheduleTagRequest, CreateScheduleTagResponse>
  implements CreateScheduleTag
{
  private authorizationService: AuthorizationService;

  private scheduleTagRepository: ScheduleTagRepository;

  constructor(
    authorizationService: AuthorizationService,
    scheduleTagRepository: ScheduleTagRepository
  ) {
    super('training.CreateScheduleTag');
    this.authorizationService = authorizationService;
    this.scheduleTagRepository = scheduleTagRepository;
  }

  async internalExecute(request: CreateScheduleTagRequest): Promise<CreateScheduleTagResponse> {
    const { groupId } = request;
    this.authorizationService.assertGroupWriteAccessible(groupId);
    const saved = await this.scheduleTagRepository.save(request);
    return {
      scheduleTag: saved,
    };
  }
}

export const CreateScheduleTagKey = injectionKeyOf<CreateScheduleTag>({
  boundedContext: 'training',
  type: 'usecase',
  name: 'CreateScheduleTag',
});

export function useCreateScheduleTag(): CreateScheduleTag {
  return requiredInject(CreateScheduleTagKey);
}
