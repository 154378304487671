import {
  AuthorizationService,
  CourseFinder,
  CourseId,
  CourseName,
  CourseVersion,
  GroupId,
  UserId,
} from '@/base/domains';
import { LocalDateTime } from '@/base/types';
import { AbstractUseCase, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

export interface GetCoursesByTrainerRequest {
  groupId: GroupId;
}

export type GetCoursesByTrainerCourse = {
  /** コースID */
  id: CourseId;
  /** コース名 */
  name: CourseName;
  /** 確定ユーザー */
  confirmedBy?: UserId;
  /** 確定日時 */
  confirmedAt: LocalDateTime;
  /** コースバージョン */
  version: CourseVersion;
  /** 作成ユーザー */
  versionCreatedBy?: UserId;
  /** 作成日時 */
  versionCreatedAt?: LocalDateTime;
  /** コンテンツ最終更新ユーザー */
  contentLastUpdatedBy?: UserId;
  /** コンテンツ最終更新日時 */
  contentLastUpdatedAt?: LocalDateTime;
};

export type GetCoursesByTrainerResponse = {
  courses: Array<GetCoursesByTrainerCourse>;
};

/**
 * トレーナーがコースリストを取得する
 */
export interface GetCoursesByTrainer
  extends UseCase<GetCoursesByTrainerRequest, GetCoursesByTrainerResponse> {
  execute(
    request: GetCoursesByTrainerRequest
  ): Promise<UseCaseResponse<GetCoursesByTrainerResponse>>;
}

export class GetCoursesByTrainerImpl
  extends AbstractUseCase<GetCoursesByTrainerRequest, GetCoursesByTrainerResponse>
  implements GetCoursesByTrainer
{
  private authorizationService: AuthorizationService;

  private courseFinder: CourseFinder;

  constructor(authorizationService: AuthorizationService, courseFinder: CourseFinder) {
    super('training.GetCoursesByTrainer');
    this.authorizationService = authorizationService;
    this.courseFinder = courseFinder;
  }

  async internalExecute(request: GetCoursesByTrainerRequest): Promise<GetCoursesByTrainerResponse> {
    const { groupId } = request;
    this.authorizationService.assertTrainerAccessible(groupId);
    const courses = await this.courseFinder.findTenantActiveCourses();
    return {
      courses: courses.map((cr) => ({
        id: cr.id,
        name: cr.name,
        confirmedBy: cr.confirmedBy,
        confirmedAt: cr.confirmedAt,
        version: cr.version,
        versionCreatedBy: cr.versionCreatedBy,
        versionCreatedAt: cr.versionCreatedAt,
        contentLastUpdatedBy: cr.contentLastUpdatedBy,
        contentLastUpdatedAt: cr.contentLastUpdatedAt,
      })),
    };
  }
}

export const GetCoursesByTrainerKey = injectionKeyOf<GetCoursesByTrainer>({
  boundedContext: 'training',
  type: 'usecase',
  name: 'GetCoursesByTrainer',
});

export function useGetCoursesByTrainer(): GetCoursesByTrainer {
  return requiredInject(GetCoursesByTrainerKey);
}
