import { createLogger } from '@/utils/log';
import { hasNonNullProperty } from '@/utils/TsUtils';

import { GroupRole, Role } from '../domains';
import { ApplicationError, isApplicationError } from '../error/ApplicationError';
import { UNEXPECTED_ERROR } from '../ErrorCodes';

const logger = createLogger({ boundedContext: 'base', name: 'UseCase' });
export type UseCaseName = string;

export type UseCaseFailed = {
  errors: Array<ApplicationError>;
  useCaseName: UseCaseName;
};

export type UseCaseSucceeded<T> = T & {
  useCaseName: UseCaseName;
};

export type UseCaseResponse<T extends {}> = UseCaseSucceeded<T> | UseCaseFailed;

export function isFailed<T>(res: UseCaseResponse<T>): res is UseCaseFailed {
  return hasNonNullProperty(res, 'errors');
}

export function isSucceeded<T>(res: UseCaseResponse<T>): res is UseCaseSucceeded<T> {
  return !isFailed(res);
}

export function assertIsSucceeded<T>(res: UseCaseResponse<T>): asserts res is UseCaseSucceeded<T> {
  if (isFailed(res)) {
    logger.error({
      message: 'usecase should be succeeded',
      errors: res.errors,
      name: res.useCaseName,
    });
    throw new Error(`usecase should be succeeded; name=${res.useCaseName}`);
  }
}

export type UseCaseOptions = {};

export interface UseCase<A extends {}, B extends {}> {
  execute(request?: A, options?: UseCaseOptions): Promise<UseCaseResponse<B>>;
}

export abstract class AbstractUseCase<A extends {}, B extends {}> implements UseCase<A, B> {
  constructor(private name: UseCaseName) {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async execute(request?: A, options?: UseCaseOptions): Promise<UseCaseResponse<B>> {
    try {
      const response = await this.internalExecute(request);
      logger.debug({
        message: `${this.name} has been successfully executed`,
        request,
        response,
      });
      return {
        ...response,
        useCaseName: this.name,
      };
    } catch (e) {
      logger.debug({
        message: `${this.name} has been failed`,
        request,
        cause: e,
      });
      if (isApplicationError(e)) {
        return {
          errors: [e],
          useCaseName: this.name,
        };
      }
      return {
        errors: [UNEXPECTED_ERROR.toApplicationError({ payload: { cause: e } })],
        useCaseName: this.name,
      };
    }
  }

  abstract internalExecute(request?: A): Promise<B>;

  executableRole(): Array<Role> {
    return ['supervisor', 'admin', 'general'];
  }

  executableGroupRole(): Array<GroupRole> {
    return ['trainer', 'mentor', 'trainee'];
  }
}

export interface SyncUseCase<A extends {}, B extends {}> {
  execute(request?: A, options?: UseCaseOptions): UseCaseResponse<B>;
}
