import { AuthorizationService, GroupId, NextToken, ScheduleTagId } from '@/base/domains';
import { LocalDateTime } from '@/base/types';
import { AbstractUseCase, UseCase, UseCaseResponse } from '@/base/usecases';
import { hasNonNullProperty, isDefined } from '@/utils/TsUtils';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { ScheduleReference, ScheduleRepository } from '../domains';

export type GetSchedulesRequest = {
  groupId: GroupId;
  limit?: number;
  nextToken?: NextToken;
} & (
  | {
      startFrom: LocalDateTime;
      startTo: LocalDateTime;
      tagId?: ScheduleTagId;
    }
  | {
      limit?: number;
      groupId: GroupId;
      recently: true;
      tagId?: ScheduleTagId;
    }
);

export type GetSchedulesResponse = {
  schedules: Array<ScheduleReference>;
  nextToken?: NextToken;
};

/**
 * スケジュールリストを取得する
 */
export interface GetSchedules extends UseCase<GetSchedulesRequest, GetSchedulesResponse> {
  execute(request: GetSchedulesRequest): Promise<UseCaseResponse<GetSchedulesResponse>>;
}

export class GetSchedulesImpl
  extends AbstractUseCase<GetSchedulesRequest, GetSchedulesResponse>
  implements GetSchedules
{
  private scheduleRepository: ScheduleRepository;

  private authorizationService: AuthorizationService;

  constructor(scheduleRepository: ScheduleRepository, authorizationService: AuthorizationService) {
    super('training.GetSchedules');
    this.scheduleRepository = scheduleRepository;
    this.authorizationService = authorizationService;
  }

  async internalExecute(request: GetSchedulesRequest): Promise<GetSchedulesResponse> {
    const { groupId, tagId } = request;
    this.authorizationService.assertGroupReadAccessible(groupId);
    const schedulesResponse = await (() => {
      if (hasNonNullProperty(request, 'recently')) {
        return this.scheduleRepository.findRecently({
          groupId: request.groupId,
          limit: request.limit,
          nextToken: request.nextToken,
        });
      }
      return this.scheduleRepository.find(request);
    })();
    const schedules = schedulesResponse.data;
    return {
      schedules: isDefined(tagId)
        ? schedules.filter((s) => s.tags.find((t) => t.id === tagId))
        : schedules,
      nextToken: schedulesResponse.nextToken,
    };
  }
}

export const GetSchedulesKey = injectionKeyOf<GetSchedules>({
  boundedContext: 'training',
  type: 'usecase',
  name: 'GetSchedules',
});

export function useGetSchedules(): GetSchedules {
  return requiredInject(GetSchedulesKey);
}
