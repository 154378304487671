import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { QuestionnaireId } from '../domains';
import { UseCase, UseCaseResponse } from './UseCase';

export type RemoveQuestionnaireRequest = {
  id: QuestionnaireId;
};

export type RemoveQuestionnaireResponse = {};

export interface RemoveQuestionnaire
  extends UseCase<RemoveQuestionnaireRequest, RemoveQuestionnaireResponse> {
  execute(
    request: RemoveQuestionnaireRequest
  ): Promise<UseCaseResponse<RemoveQuestionnaireResponse>>;
}

export const RemoveQuestionnaireKey = injectionKeyOf<RemoveQuestionnaire>({
  boundedContext: 'training',
  type: 'usecase',
  name: 'RemoveQuestionnaire',
});

export function useRemoveQuestionnaire(): RemoveQuestionnaire {
  return requiredInject(RemoveQuestionnaireKey);
}
