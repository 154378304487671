import { ContentId, ContentVersion } from '@/base/domains';
import { AbstractUseCase, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { ContentReference, ContentRepository } from '../domains';

export type GetContentRequest = {
  id: ContentId;
  version?: ContentVersion;
};

export type GetContentResponse = {
  content?: ContentReference;
};

/**
 * コンテントを取得する
 */
export interface GetContent extends UseCase<GetContentRequest, GetContentResponse> {
  execute(request: GetContentRequest): Promise<UseCaseResponse<GetContentResponse>>;
}

export class GetContentImpl
  extends AbstractUseCase<GetContentRequest, GetContentResponse>
  implements GetContent
{
  private contentRepository: ContentRepository;

  constructor(contentRepository: ContentRepository) {
    super('contents.GetContent');
    this.contentRepository = contentRepository;
  }

  async internalExecute({ id, version }: GetContentRequest): Promise<GetContentResponse> {
    const content = await this.contentRepository.findById(id, version);
    return {
      content,
    };
  }
}

export const GetContentKey = injectionKeyOf<GetContent>({
  boundedContext: 'contents',
  type: 'usecase',
  name: 'GetContent',
});

export function useGetContent(): GetContent {
  return requiredInject(GetContentKey);
}
