import {
  AuthorizationService,
  CourseColor,
  CourseFontColorOnImage,
  CourseId,
  FileStorage,
} from '@/base/domains';
import { AbstractUseCase, assertEntityExists, UseCase, UseCaseResponse } from '@/base/usecases';
import { hasNonNullProperty } from '@/utils/TsUtils';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { EditingCourseReference, EditingCourseRepository } from '../domains';

export type ChangeEditingCourseColorOrImageRequest =
  | {
      id: CourseId;
      color: CourseColor;
    }
  | {
      id: CourseId;
      image: Blob;
    }
  | {
      id: CourseId;
      fontColorOnImage: CourseFontColorOnImage;
    };

export type ChangeEditingCourseColorOrImageResponse = {
  course: EditingCourseReference;
};

/**
 * 編集中コースにカラーかイメージを設定する
 */
export interface ChangeEditingCourseColorOrImage
  extends UseCase<ChangeEditingCourseColorOrImageRequest, ChangeEditingCourseColorOrImageResponse> {
  execute(
    request: ChangeEditingCourseColorOrImageRequest
  ): Promise<UseCaseResponse<ChangeEditingCourseColorOrImageResponse>>;
}

export class ChangeEditingCourseColorOrImageImpl
  extends AbstractUseCase<
    ChangeEditingCourseColorOrImageRequest,
    ChangeEditingCourseColorOrImageResponse
  >
  implements ChangeEditingCourseColorOrImage
{
  private authorizationService: AuthorizationService;

  private fileStorage: FileStorage;

  private editingCourseRepository: EditingCourseRepository;

  constructor(
    authorizationService: AuthorizationService,
    fileStorage: FileStorage,
    editingCourseRepository: EditingCourseRepository
  ) {
    super('contents.ChangeEditingCourseColorOrImage');
    this.authorizationService = authorizationService;
    this.fileStorage = fileStorage;
    this.editingCourseRepository = editingCourseRepository;
  }

  async internalExecute(
    request: ChangeEditingCourseColorOrImageRequest
  ): Promise<ChangeEditingCourseColorOrImageResponse> {
    this.authorizationService.assertContentEditable();
    const { id } = request;
    const course = await this.editingCourseRepository.findById(id);
    assertEntityExists(course, 'editingCourse');

    if (hasNonNullProperty(request, 'color')) {
      const saved = await this.editingCourseRepository.save(course.changeColor(request.color));
      return {
        course: saved,
      };
    }
    if (hasNonNullProperty(request, 'fontColorOnImage')) {
      const saved = await this.editingCourseRepository.save(
        course.changeFontColorOnImage(request.fontColorOnImage)
      );
      return {
        course: saved,
      };
    }
    const uri = await this.fileStorage.upload({
      filename: `${course.id}-${course.version}-image`,
      file: request.image,
    });

    const saved = await this.editingCourseRepository.save(course.changeImage(uri));
    return {
      course: saved,
    };
  }
}

export const ChangeEditingCourseColorOrImageKey = injectionKeyOf<ChangeEditingCourseColorOrImage>({
  boundedContext: 'contents',
  type: 'usecase',
  name: 'ChangeEditingCourseColorOrImage',
});

export function useChangeEditingCourseColorOrImage(): ChangeEditingCourseColorOrImage {
  return requiredInject(ChangeEditingCourseColorOrImageKey);
}
