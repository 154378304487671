import { ContentId, CourseId, DataVersion, UserId } from '@/base/domains';
import { Text } from '@/base/domains/content/Text';
import { hasProperty } from '@/utils/TsUtils';

import {
  EditingCourseContentBodyChangeBodyArgs,
  EditingCourseContentBodyEntity,
  TextEditingCourseContentBodyEntity,
} from './EditingCourseContentBody';

export type TextEditingCourseContentBodyEntityImplArgs = {
  type: 'text';

  body: Text;

  courseId: CourseId;

  dataVersion: DataVersion;

  id: ContentId;

  createdBy: UserId;

  updatedBy: UserId;
};

export class TextEditingCourseContentBodyEntityImpl implements TextEditingCourseContentBodyEntity {
  type!: 'text';

  body: Text;

  courseId: CourseId;

  dataVersion: DataVersion;

  id: ContentId;

  createdBy: UserId;

  updatedBy: UserId;

  constructor(args: TextEditingCourseContentBodyEntityImplArgs) {
    this.id = args.id;
    this.type = args.type;
    this.body = args.body;
    this.courseId = args.courseId;
    this.dataVersion = args.dataVersion;
    this.createdBy = args.createdBy;
    this.updatedBy = args.updatedBy;
  }

  changeBody(body: EditingCourseContentBodyChangeBodyArgs): EditingCourseContentBodyEntity {
    if (hasProperty(body, 'body')) {
      return new TextEditingCourseContentBodyEntityImpl({
        ...this,
        body,
      });
    }
    throw new Error('Unsupported arg type');
  }
}
