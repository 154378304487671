import { GraphQLResult } from '@aws-amplify/api-graphql';
import { Amplify, API, graphqlOperation } from 'aws-amplify';
import { Observable } from 'zen-observable-ts';

import { localEventBus } from '@/base/domains';
import {
  amplifyQueryNetworkErrorOccurred,
  amplifyQuerySucceeded,
} from '@/base/domains/LocalEvents';
import { EXCLUSIVE_CONTROL_ERROR, NETWORK_ERROR } from '@/base/ErrorCodes';

import { assertIsDefined } from './Asserts';
import { createLogger } from './log';
import { hasNonNullProperty, hasProperty, isDefined, isObject, isString } from './TsUtils';

const logger = createLogger({ boundedContext: 'utils', name: 'AmplifyUtils' });

export type GraphQLError = {
  errorType: string;
  message: string;
};

export function isGraphQLError(x: unknown): x is GraphQLError {
  return isObject(x) && hasNonNullProperty(x, 'errorType');
}

function isNetworkError(x: Error) {
  return x.message === 'Network Error';
}

type AmplifyQueryType = 'mutate' | 'query';

function amplifyQueryTypeFromQuery(query: string): AmplifyQueryType {
  if (/^\s*query/.test(query)) return 'query';
  return 'mutate';
}

/**
 * Amplifyネットワークエラー発生イベントを発行する
 */
function publishAmplifyNetworkErrorOccurred(rawQuery: unknown) {
  const query = isString(rawQuery) ? rawQuery : `${rawQuery}`;
  const queryType = amplifyQueryTypeFromQuery(query);
  localEventBus.publish(amplifyQueryNetworkErrorOccurred({ queryType, query }));
}

/**
 * Amplifyクエリ成功イベントを発行する
 */
function publishAmplifyQuerySucceeded(rawQuery: unknown) {
  const query = isString(rawQuery) ? rawQuery : `${rawQuery}`;
  const queryType = amplifyQueryTypeFromQuery(query);
  localEventBus.publish(amplifyQuerySucceeded({ queryType, query }));
}

/**
 * API.graphqlの型付きラッパー
 * @param options graphqlオプション
 * @param additionalHeaders 追加ヘッダ
 */
export async function graphql<T>(
  query: unknown,
  variables?: {},
  additionalHeaders?: {
    [key: string]: string;
  },
  options: { publicAccess?: boolean } = {}
): Promise<T> {
  const { publicAccess = false } = options;
  if (!publicAccess) {
    Amplify.configure({
      // eslint-disable-next-line @typescript-eslint/camelcase
      aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
    });
  } else {
    Amplify.configure({
      // eslint-disable-next-line @typescript-eslint/camelcase
      aws_appsync_authenticationType: 'AWS_IAM',
    });
  }

  try {
    const res: GraphQLResult<T> = (await API.graphql(
      graphqlOperation(query, variables),
      additionalHeaders
    )) as GraphQLResult<T>;

    logger.debug({
      message: 'graphql response',
      query,
      variables,
      response: res,
    });
    publishAmplifyQuerySucceeded(query);
    assertIsDefined(res.data, 'graphql response.data');
    return res.data;
  } catch (e) {
    logger.error({
      message: 'an error has been occurred at graphql',
      query,
      variables,
      cause: e,
    });
    const gResult = e as GraphQLResult<{}>;
    const firstError = (gResult.errors ?? [])[0];
    if (firstError) {
      if (isNetworkError(firstError)) {
        publishAmplifyNetworkErrorOccurred(query);
        throw NETWORK_ERROR.toApplicationError({});
      }
      if (hasNonNullProperty(firstError, 'path') && hasNonNullProperty(firstError, 'errorType')) {
        const firstPath = (firstError.path ?? [])[0];
        if (
          isString(firstPath) &&
          firstPath.startsWith('update') &&
          firstError.errorType === 'DynamoDB:ConditionalCheckFailedException'
        ) {
          throw EXCLUSIVE_CONTROL_ERROR.toApplicationError();
        }
      }
      throw firstError;
    }
    throw e;
  }
}

/**
 * graphqlのクエリ
 * @param options graphqlオプション
 * @param variables 変数
 * @param options オプション
 */
export async function graphqlQuery<T>(
  query: string,
  variables?: {},
  options: { limit?: number; publicAccess?: boolean } = {}
): Promise<T> {
  const limit = options.limit ?? 1000;
  if (limit > 1000) {
    let key: string;
    const getData = async (nextToken?: string, list: Array<object> = []) => {
      const v = {
        ...variables,
        limit: 1000,
        nextToken,
      };
      const res = await graphql<object>(query, v, undefined, {
        publicAccess: options.publicAccess,
      });
      if (!isDefined(key)) {
        const keys = Object.keys(res).filter((k) => k !== 'nextToken');
        if (keys.length !== 1) {
          throw new Error(`a graphql response has multiple keys; ${keys}`);
        }
        const x = keys[0];
        key = x;
      }
      const l = res[key].items as Array<object>;
      const concatList = list.concat(l);
      if (concatList.length >= limit) {
        return { [key]: { items: concatList } } as unknown as T;
      }
      const newNextToken = res[key].nextToken as string | undefined;
      if (isDefined(newNextToken)) {
        return getData(newNextToken, concatList);
      }
      return { [key]: { items: concatList } } as unknown as T;
    };
    return getData();
  }
  const v = {
    ...variables,
    limit,
  };
  return graphql<T>(query, v, undefined, {
    publicAccess: options.publicAccess,
  });
}

export function graphqlSubscribe<T>(
  subscription: string,
  variables?: Record<string, unknown>,
  options: { publicAccess?: boolean } = {}
): Observable<T> {
  const { publicAccess = false } = options;
  if (!publicAccess) {
    Amplify.configure({
      // eslint-disable-next-line @typescript-eslint/camelcase
      aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
    });
  } else {
    Amplify.configure({
      // eslint-disable-next-line @typescript-eslint/camelcase
      aws_appsync_authenticationType: 'AWS_IAM',
    });
  }
  const res = API.graphql(graphqlOperation(subscription, variables));
  if (!hasProperty(res, 'subscribe')) {
    throw new Error('Unexpected graphql response');
  }
  logger.debug({
    message: 'graphql subscribe',
    subscription,
    variables,
  });
  return res as unknown as Observable<T>;
}

/**
 * 1つのリストのみ取得するGraphQLを実行する
 *
 * @remarks
 * クエリ実行結果にnextTokenが含まれる場合は、nextTokenがなくなるまでクエリを繰り返す。
 *
 * @param arg0.query GraphQL Query
 * @param arg0.queryKey GraphQL Queryのキー
 * @param arg0.variables GraphQL Queryの変数
 * @param arg0.limit 取得件数の上限
 * @param arg0.publicAccess パブリックアクセス（認証なし）のクエリする
 * @returns 取得したリスト
 */
export async function singleListGraphqlQuery<T>({
  query,
  queryKey,
  variables,
  limit,
  publicAccess = false,
}: {
  query: string;
  queryKey: string;
  variables: Record<string, unknown>;
  limit?: number;
  publicAccess?: boolean;
}): Promise<T[]> {
  let list: T[] = [];
  let nextToken: unknown | undefined;
  let hasNext = true;
  let aLimit = limit;
  while (hasNext) {
    const v = {
      ...variables,
      nextToken,
      limit: aLimit,
    };
    // AmplifyQueryは1回ずつ実行する必要があるため、ループの中でawaitする。
    // eslint-disable-next-line no-await-in-loop
    const res = await graphql<T>(query, v, undefined, {
      publicAccess,
    });
    const l = res[queryKey].items as T[];
    if (!Array.isArray(l)) {
      throw new Error(`unexpected amplify query response; ${res}`);
    }
    list = list.concat(l);
    nextToken = res[queryKey].nextToken;
    hasNext = !!nextToken;

    if (limit !== undefined) {
      if (hasNext) {
        aLimit = limit - list.length;
      } else {
        list = list.slice(0, limit);
      }
    }
  }
  return list;
}
