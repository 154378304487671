import { ModelContentFilterInput, ModelSortDirection, UpdateContentInput } from '@/API';
import {
  AppContextProvider,
  ContentId,
  ContentType,
  ContentVersion,
  CourseId,
  CourseVersion,
} from '@/base/domains';
import { Minute, Optional } from '@/base/types';
import { ContentEntity, ContentRepository, createContentEntity } from '@/contents/domains';
import { updateContent } from '@/graphql/mutations';
import {
  contentByCourse,
  contentsByContent,
  contentsByTenantCode,
  getContent,
} from '@/graphql/queries';
import { graphql, graphqlQuery } from '@/utils/AmplifyUtils';
import { ifDefined, isDefined, requiredNonNull } from '@/utils/TsUtils';

import {
  AmplifyContentBody,
  AmplifyWorkbook,
  toExamContentBody,
  toTextContentBody,
  toWorkbook,
} from './Content';

type AmplifyContent = {
  id: string;
  contentId: ContentId;
  contentVersion: ContentVersion;
  code: string;
  version: number;
  latest: boolean;
  name: string;
  requiredTime: Minute;
  body: AmplifyContentBody;
  workbook?: AmplifyWorkbook;
  createdAt: string;
  updatedAt: string;
  courseId: CourseId;
  courseVersion: CourseVersion;
  versionDescription?: string;
};

export function toContent(content: AmplifyContent): ContentEntity {
  const type = content.body.type.toLocaleLowerCase() as ContentType;

  if (type === 'exam') {
    return createContentEntity({
      id: content.contentId,
      latest: content.latest,
      type,
      name: content.name,
      requiredTime: content.requiredTime,
      body: toExamContentBody(content.body),
      courseId: content.courseId,
      courseVersion: content.courseVersion,
      version: content.version,
      versionDescription: content.versionDescription,
    });
  }
  if (type === 'text') {
    return createContentEntity({
      id: content.contentId,
      latest: content.latest,
      type,
      name: content.name,
      requiredTime: content.requiredTime,
      body: toTextContentBody(content.body),
      courseId: content.courseId,
      courseVersion: content.courseVersion,
      workbook: ifDefined(content.workbook, toWorkbook),
      version: content.version,
      versionDescription: content.versionDescription,
    });
  }
  throw new Error('Unsupported type');
}

export class AmplifyContentRepository implements ContentRepository {
  private appContextProvider: AppContextProvider;

  constructor(appContextProvider: AppContextProvider) {
    this.appContextProvider = appContextProvider;
  }

  async save(entity: ContentEntity): Promise<ContentEntity> {
    const tenantCode = requiredNonNull(
      this.appContextProvider.get().tenantCode,
      'appContext.tenantCode'
    );
    // versionDescription以外は変更できない
    const input: UpdateContentInput = {
      id: `${entity.id}#${entity.version}`,
      versionDescription: entity.versionDescription,
      tenantCode,
    };
    const res = await graphql<{ updateContent: AmplifyContent }>(updateContent, {
      input,
    });
    return toContent(res.updateContent);
  }

  async findById(id: ContentId, version?: ContentVersion): Promise<Optional<ContentEntity>> {
    if (isDefined(version)) {
      const res = await graphqlQuery<{ getContent: AmplifyContent }>(getContent, {
        id: `${id}#${version}`,
      });
      return ifDefined(res.getContent, toContent);
    }
    const res = await graphqlQuery<{ contentsByContent: { items: Array<AmplifyContent> } }>(
      contentsByContent,
      {
        contentId: id,
        sortDirection: ModelSortDirection.DESC,
      }
    );
    return ifDefined(res.contentsByContent.items[0], toContent);
  }

  async findTenantContents(): Promise<Array<ContentEntity>> {
    const tenantCode = requiredNonNull(
      this.appContextProvider.get().tenantCode,
      'appContext.tenantCode'
    );
    const filter: ModelContentFilterInput = {
      latest: { eq: true },
    };
    const res = await graphqlQuery<{ contentsByTenantCode: { items: Array<AmplifyContent> } }>(
      contentsByTenantCode,
      {
        tenantCode,
        filter,
      },
      {
        limit: 1000,
      }
    );
    return res.contentsByTenantCode.items.map(toContent) ?? [];
  }

  async findByCourseId(courseId: CourseId, courseVersion: CourseVersion): Promise<ContentEntity[]> {
    const filter: ModelContentFilterInput = {
      latest: { eq: true },
    };
    const res = await graphqlQuery<{ contentByCourse: { items: Array<AmplifyContent> } }>(
      contentByCourse,
      {
        courseId,
        courseVersion: { eq: courseVersion },
        filter,
      },
      {
        limit: 1000,
      }
    );
    return res?.contentByCourse.items.map(toContent) ?? [];
  }
}
