import {
  useAppContextProvider,
  useAuthorizationService,
  useContentFinder,
  useContentLearningDataAdapter,
  useExamDataAdapter,
  useGroupFinder,
  useGroupTrainingFinder,
  useQuestionFinder,
  useUserFinder,
} from '@/base/domains';
import { useReportService } from '@/report/domains';
import {
  GetCoursesImpl,
  GetCoursesKey,
  GetCourseStatusReportImpl,
  GetCourseStatusReportKey,
  GetExamContentsGroupedByCourseImpl,
  GetExamContentsGroupedByCourseKey,
  GetExamResultsByTrainerImpl,
  GetExamResultsByTrainerKey,
  GetGroupCourseProgressesImpl,
  GetGroupCourseProgressesKey,
  GetGroupExamCorrectionsImpl,
  GetGroupExamCorrectionsKey,
  GetGroupReportSearchConditionsImpl,
  GetGroupReportSearchConditionsKey,
  GetMyExamResultDetailsImpl,
  GetMyExamResultDetailsKey,
  GetMyExamResultsImpl,
  GetMyExamResultsKey,
  GetTrainerUserReportConditionsImpl,
  GetTrainerUserReportConditionsKey,
  GetTrainerUserReportImpl,
  GetTrainerUserReportKey,
  GetTrainerUsersReportImpl,
  GetTrainerUsersReportKey,
  GetUserCourseProgressesImpl,
  GetUserCourseProgressesKey,
  GetUserCourseProgressReportImpl,
  GetUserCourseProgressReportKey,
  GetUserCourseStatusImpl,
  GetUserCourseStatusKey,
  GetUserExamCorrectionsImpl,
  GetUserExamCorrectionsKey,
  GetUserExamReportImpl,
  GetUserExamReportKey,
} from '@/report/usecases';
import { provide } from '@/utils/VueUtils';

export function useReportUseCases() {
  const groupTrainingFinder = useGroupTrainingFinder();
  const examDataAdapter = useExamDataAdapter();
  const authorizationService = useAuthorizationService();
  const reportService = useReportService();
  const contentFinder = useContentFinder();
  const userFinder = useUserFinder();
  const groupFinder = useGroupFinder();
  const questionFinder = useQuestionFinder();
  const contentLearningDataAdapter = useContentLearningDataAdapter();
  const appContextProvider = useAppContextProvider();

  provide(GetMyExamResultsKey, new GetMyExamResultsImpl(examDataAdapter));
  provide(
    GetExamContentsGroupedByCourseKey,
    new GetExamContentsGroupedByCourseImpl(groupTrainingFinder, authorizationService, contentFinder)
  );
  provide(GetCoursesKey, new GetCoursesImpl(authorizationService, groupTrainingFinder));
  provide(
    GetCourseStatusReportKey,
    new GetCourseStatusReportImpl(authorizationService, reportService)
  );
  provide(
    GetUserCourseProgressReportKey,
    new GetUserCourseProgressReportImpl(authorizationService, reportService)
  );
  provide(GetUserCourseStatusKey, new GetUserCourseStatusImpl(authorizationService, reportService));
  provide(GetUserExamReportKey, new GetUserExamReportImpl(authorizationService, reportService));
  provide(
    GetExamResultsByTrainerKey,
    new GetExamResultsByTrainerImpl(authorizationService, examDataAdapter)
  );
  provide(
    GetGroupReportSearchConditionsKey,
    new GetGroupReportSearchConditionsImpl(
      authorizationService,
      examDataAdapter,
      groupTrainingFinder,
      userFinder,
      groupFinder
    )
  );
  provide(
    GetTrainerUserReportConditionsKey,
    new GetTrainerUserReportConditionsImpl(
      appContextProvider,
      contentLearningDataAdapter,
      authorizationService
    )
  );
  provide(
    GetTrainerUserReportKey,
    new GetTrainerUserReportImpl(
      authorizationService,
      questionFinder,
      contentLearningDataAdapter,
      groupFinder,
      groupTrainingFinder,
      examDataAdapter
    )
  );
  provide(
    GetTrainerUsersReportKey,
    new GetTrainerUsersReportImpl(authorizationService, reportService)
  );
  provide(
    GetUserCourseProgressesKey,
    new GetUserCourseProgressesImpl(authorizationService, userFinder, reportService)
  );
  provide(
    GetUserExamCorrectionsKey,
    new GetUserExamCorrectionsImpl(authorizationService, userFinder, reportService)
  );
  provide(
    GetGroupCourseProgressesKey,
    new GetGroupCourseProgressesImpl(authorizationService, reportService, groupTrainingFinder)
  );
  provide(
    GetGroupExamCorrectionsKey,
    new GetGroupExamCorrectionsImpl(authorizationService, reportService)
  );
  provide(
    GetMyExamResultDetailsKey,
    new GetMyExamResultDetailsImpl(examDataAdapter, contentFinder)
  );
}
