import { config } from '@/config';

import { GROUP_DUPLICATED_USER, GROUP_USER_LIMIT_EXCEEDED } from '../ErrorCodes';
import { UserId } from './Core';
import { GroupExtensionConfig } from './extensions/Extension';
import { GroupData, GroupEntity, GroupId, GroupLimitation, GroupName, GroupUser } from './Group';

export class GroupEntityImpl implements GroupEntity {
  id: GroupId;

  name: GroupName;

  extensionConfigs: Array<GroupExtensionConfig>;

  limitations: GroupLimitation;

  users: Array<GroupUser>;

  enabled: boolean;

  description?: string;

  constructor(data: GroupData) {
    this.id = data.id;
    this.name = data.name;
    this.extensionConfigs = data.extensionConfigs;
    this.limitations = data.limitations;
    this.users = data.users;
    this.enabled = data.enabled;
    this.description = data.description;
  }

  addUser(user: GroupUser): GroupEntity {
    const enableUsers = this.users.filter((u) => !u.removed);
    if (enableUsers.length >= config().app.userLimitInGroup) {
      throw GROUP_USER_LIMIT_EXCEEDED.toApplicationError({
        groupId: this.id,
      });
    }
    const groupUser = this.users.find((u) => u.id === user.id);
    if (groupUser && !groupUser.removed) {
      throw GROUP_DUPLICATED_USER.toApplicationError({
        groupId: this.id,
        userId: user.id,
      });
    }
    if (groupUser && groupUser.removed) {
      return new GroupEntityImpl({
        ...this,
        users: [
          this.users.map((u) => {
            return u.id === user.id ? { id: user.id, role: user.role, removed: false } : u;
          }),
        ],
      });
    }
    return new GroupEntityImpl({
      ...this,
      users: [...this.users, user],
    });
  }

  addUsers(users: Array<GroupUser>): GroupEntity {
    if (this.users.length + users.length > config().app.userLimitInGroup) {
      throw GROUP_USER_LIMIT_EXCEEDED.toApplicationError({
        groupId: this.id,
      });
    }
    const userIds = users.map((u) => u.id);
    const duplicatedUser = this.users.find((u) => userIds.includes(u.id) && !u.removed);
    if (duplicatedUser) {
      throw GROUP_DUPLICATED_USER.toApplicationError({
        groupId: this.id,
        userId: duplicatedUser.id,
      });
    }
    const removedUserIds = this.users
      .filter((u) => u.removed && userIds.includes(u.id))
      .map((u) => u.id);
    if (removedUserIds.length > 0) {
      return new GroupEntityImpl({
        ...this,
        users: [...this.users.filter((u) => !removedUserIds.includes(u.id)), ...users],
      });
    }
    return new GroupEntityImpl({
      ...this,
      users: [...this.users, ...users],
    });
  }

  removeUser(userId: UserId | Array<UserId>): GroupEntity {
    const userIds = Array.isArray(userId) ? userId : [userId];
    return new GroupEntityImpl({
      ...this,
      users: [
        ...this.users.map((u) => {
          return userIds.find((id) => id === u.id) ? { ...u, removed: true } : u;
        }),
      ],
    });
  }

  changeUsers(users: Array<GroupUser>): GroupEntity {
    return new GroupEntityImpl({
      ...this,
      users,
    });
  }

  changeName(name: GroupName): GroupEntity {
    return new GroupEntityImpl({
      ...this,
      name,
    });
  }

  changeLimitations(limitations: GroupLimitation) {
    return new GroupEntityImpl({
      ...this,
      limitations,
    });
  }

  changeDescription(description: string): GroupEntity {
    return new GroupEntityImpl({
      ...this,
      description,
    });
  }

  enable(): GroupEntity {
    return new GroupEntityImpl({
      ...this,
      enabled: true,
    });
  }

  disable(): GroupEntity {
    return new GroupEntityImpl({
      ...this,
      enabled: false,
    });
  }

  saveExtensionConfig(extensionConfig: GroupExtensionConfig): GroupEntity {
    return new GroupEntityImpl({
      ...this,
      extensionConfigs: [
        ...this.extensionConfigs.filter((e) => e.name !== extensionConfig.name),
        extensionConfig,
      ],
    });
  }
}
