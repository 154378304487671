import { Tenant } from '@/base/domains';

export const tenant: Tenant = {
  code: 'dev',
  name: 'CityCom',
  signUpType: 'email',
  playground: false,
  limitations: {
    question: 'enabled',
    schedule: 'enabled',
    questionnaire: 'enabled',
    multilingualSupport: 'enabled',
    tenantOwnTermsOfService: 'enabled',
  },
};
