import { AuthorizationService, ContentId, ContentName, CourseId } from '@/base/domains';
import { EXCLUSIVE_CONTROL_ERROR } from '@/base/ErrorCodes';
import { AbstractUseCase, assertEntityExists, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { EditingCourseRepository } from '../domains/EditingCourse';
import { EditingCourseContentBodyQueries } from '../domains/queries';
import {
  CORSE_CONTENT_EMPTY_PROBLEM_EXAM,
  CORSE_CONTENT_INVALID_PASSING_STANDARD,
  COURSE_EMPTY_CONTENTS,
} from '../ErrorCodes';

export interface ConfirmCourseEditingRequest {
  id: CourseId;
}

export type ConfirmCourseEditingResponse = {};

export interface ConfirmCourseEditing
  extends UseCase<ConfirmCourseEditingRequest, ConfirmCourseEditingResponse> {
  execute(
    request: ConfirmCourseEditingRequest
  ): Promise<UseCaseResponse<ConfirmCourseEditingResponse>>;
}

export class ConfirmCourseEditingImpl
  extends AbstractUseCase<ConfirmCourseEditingRequest, ConfirmCourseEditingResponse>
  implements ConfirmCourseEditing
{
  constructor(
    private authorizationService: AuthorizationService,
    private editingCourseRepository: EditingCourseRepository,
    private editingCourseContentBodyQueries: EditingCourseContentBodyQueries
  ) {
    super('contents.ConfirmCourseEditing');
  }

  async internalExecute(
    request: ConfirmCourseEditingRequest
  ): Promise<ConfirmCourseEditingResponse> {
    const { id } = request;
    this.authorizationService.assertContentEditable();
    const entity = await this.editingCourseRepository.findById(id);
    assertEntityExists(entity, 'editingCourse');
    if (entity.status === 'confirmed') {
      throw EXCLUSIVE_CONTROL_ERROR.toApplicationError({ entity: 'editingCourse' });
    }

    if (entity.contents.length === 0) {
      throw COURSE_EMPTY_CONTENTS.toApplicationError({
        courseId: id,
      });
    }

    const exams = await this.editingCourseContentBodyQueries.findExamContentsByCourse(id);
    const emptyProblemExams = exams.filter((e) => e.body.problems.length === 0);
    if (emptyProblemExams.length > 0) {
      const contentNames = entity.contents.reduce((acc, v) => {
        acc.set(v.id, v.name);
        return acc;
      }, new Map<ContentId, ContentName>());
      throw CORSE_CONTENT_EMPTY_PROBLEM_EXAM.toApplicationError({
        courseId: id,
        contents: emptyProblemExams.map((e) => ({
          id: e.id,
          name: contentNames.get(e.id) ?? '',
        })),
      });
    }
    const invalidPassingStandardExams = exams.filter(
      (e) => e.body.passingStandard && e.body.problems.length < e.body.passingStandard
    );
    if (invalidPassingStandardExams.length > 0) {
      const contentNames = entity.contents.reduce((acc, v) => {
        acc.set(v.id, v.name);
        return acc;
      }, new Map<ContentId, ContentName>());
      throw CORSE_CONTENT_INVALID_PASSING_STANDARD.toApplicationError({
        courseId: id,
        contents: invalidPassingStandardExams.map((e) => ({
          id: e.id,
          name: contentNames.get(e.id) ?? '',
        })),
      });
    }
    await this.editingCourseRepository.save(entity.confirm());
    return {};
  }
}

export const ConfirmCourseEditingKey = injectionKeyOf<ConfirmCourseEditing>({
  boundedContext: 'contents',
  type: 'usecase',
  name: 'ConfirmCourseEditing',
});

export function useConfirmCourseEditing(): ConfirmCourseEditing {
  return requiredInject(ConfirmCourseEditingKey);
}
