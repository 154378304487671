import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { TermsOfService, TermsOfServiceDataAdapter } from '../domains';
import { Optional } from '../types';
import { AbstractUseCase, UseCase, UseCaseResponse } from './UseCase';

export type GetLatestTermsOfServiceRequest = {};

export type GetLatestTermsOfServiceResponse = {
  termsOfService: Optional<TermsOfService>;
};

/**
 * 最新の利用規約を習得する
 */
export interface GetLatestTermsOfService
  extends UseCase<GetLatestTermsOfServiceRequest, GetLatestTermsOfServiceResponse> {
  execute(
    request: GetLatestTermsOfServiceRequest
  ): Promise<UseCaseResponse<GetLatestTermsOfServiceResponse>>;
}

export class GetLatestTermsOfServiceImpl
  extends AbstractUseCase<GetLatestTermsOfServiceRequest, GetLatestTermsOfServiceResponse>
  implements GetLatestTermsOfService
{
  private termsOfServiceDataAdapter: TermsOfServiceDataAdapter;

  constructor(termsOfServiceDataAdapter: TermsOfServiceDataAdapter) {
    super('base.GetLatestTermsOfService');
    this.termsOfServiceDataAdapter = termsOfServiceDataAdapter;
  }

  async internalExecute(
    _: GetLatestTermsOfServiceRequest
  ): Promise<GetLatestTermsOfServiceResponse> {
    const termsOfService = await this.termsOfServiceDataAdapter.findLatest();
    return {
      termsOfService,
    };
  }
}

export const GetLatestTermsOfServiceKey = injectionKeyOf<GetLatestTermsOfService>({
  boundedContext: 'base',
  type: 'usecase',
  name: 'GetLatestTermsOfService',
});

export function useGetLatestTermsOfService(): GetLatestTermsOfService {
  return requiredInject(GetLatestTermsOfServiceKey);
}
