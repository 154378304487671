import { ContentId, ContentVersion, CourseId, EventStore, GroupId } from '@/base/domains';
import { AbstractUseCase, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { ContentOpened, contentOpenedFactory } from '../domains';

/**
 * コンテンツ表示リクエスト
 */
export interface OpenContentRequest {
  /** グループID */
  groupId: GroupId;
  /** コースID */
  courseId: CourseId;
  /** コンテンツID */
  contentId: ContentId;
  /** コンテンツバージョン */
  contentVersion: ContentVersion;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export type OpenContentResponse = {};

/**
 * コンテンツを表示する
 */
export interface OpenContent extends UseCase<OpenContentRequest, OpenContentResponse> {
  execute(request: OpenContentRequest): Promise<UseCaseResponse<OpenContentResponse>>;
}

export class OpenContentImpl
  extends AbstractUseCase<OpenContentRequest, OpenContentResponse>
  implements OpenContent
{
  private eventStore: EventStore;

  constructor(eventStore: EventStore) {
    super('training.OpenContent');
    this.eventStore = eventStore;
  }

  async internalExecute(request: OpenContentRequest): Promise<OpenContentResponse> {
    const event: ContentOpened = request;
    await this.eventStore.save(contentOpenedFactory(event));
    return Promise.resolve({});
  }
}

export const OpenContentKey = injectionKeyOf<OpenContent>({
  boundedContext: 'training',
  type: 'usecase',
  name: 'OpenContent',
});

export function useOpenContent(): OpenContent {
  return requiredInject(OpenContentKey);
}
