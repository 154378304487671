export const getSchedule = /* GraphQL */ `
  query GetSchedule($id: ID!) {
    getSchedule(id: $id) {
      id
      groupId
      start
      end
      title
      body
      tenantCode
      createdBy
      updatedBy
      tags {
        items {
          id
          scheduleTag {
            id
            color
            text
            groupId
            tenantCode
            createdAt
            updatedAt
          }
          tenantCode
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;

export const schedulesByGroupId = /* GraphQL */ `
  query SchedulesByGroupId(
    $groupId: ID
    $start: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelScheduleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    schedulesByGroupId(
      groupId: $groupId
      start: $start
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupId
        start
        end
        title
        body
        tenantCode
        createdBy
        updatedBy
        tags {
          items {
            id
            scheduleTag {
              id
              color
              text
              groupId
              tenantCode
              createdAt
              updatedAt
            }
            tenantCode
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listSchedules = /* GraphQL */ `
  query ListSchedules($filter: ModelScheduleFilterInput, $limit: Int, $nextToken: String) {
    listSchedules(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        groupId
        start
        end
        title
        body
        tenantCode
        createdBy
        updatedBy
        tags {
          items {
            id
            scheduleTag {
              id
              color
              text
              groupId
              tenantCode
              createdAt
              updatedAt
            }
            tenantCode
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getScheduleTag = /* GraphQL */ `
  query GetScheduleTag($id: ID!) {
    getScheduleTag(id: $id) {
      id
      color
      text
      groupId
      tenantCode
      createdAt
      updatedAt
    }
  }
`;

export const listScheduleTags = /* GraphQL */ `
  query ListScheduleTags($filter: ModelScheduleTagFilterInput, $limit: Int, $nextToken: String) {
    listScheduleTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        color
        text
        groupId
        tenantCode
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const scheduleTagsByGroupId = /* GraphQL */ `
  query ScheduleTagsByGroupId(
    $groupId: ID
    $text: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelScheduleTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    scheduleTagsByGroupId(
      groupId: $groupId
      text: $text
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        color
        text
        groupId
        tenantCode
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const schedulesByGroupIdAndEnd = /* GraphQL */ `
  query SchedulesByGroupIdAndEnd(
    $groupId: ID
    $end: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelScheduleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    schedulesByGroupIdAndEnd(
      groupId: $groupId
      end: $end
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupId
        start
        end
        title
        body
        tenantCode
        createdBy
        updatedBy
        createdAt
        updatedAt
        tags {
          items {
            id
            scheduleId
            scheduleTagId
            scheduleTag {
              id
              color
              text
              groupId
              tenantCode
              createdAt
              updatedAt
            }
            tenantCode
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;

export const schedulesByEnd = /* GraphQL */ `
  query SchedulesByEnd(
    $tenantCode: String
    $end: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelScheduleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    schedulesByEnd(
      tenantCode: $tenantCode
      end: $end
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupId
        start
        end
        title
        body
        tenantCode
        createdBy
        updatedBy
        createdAt
        updatedAt
        tags {
          items {
            id
            scheduleId
            scheduleTagId
            scheduleTag {
              id
              color
              text
              groupId
              tenantCode
              createdAt
              updatedAt
            }
            tenantCode
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;

export const userExamsByGroupAndUser = /* GraphQL */ `
  query UserExamsByGroupAndUser(
    $groupId: ID
    $userId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserExamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userExamsByGroupAndUser(
      userId: $userId
      groupId: $groupId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        status
        tenantCode
        groupExamId
        groupId
        startedAt
        finishedAt
        createdAt
        updatedAt
        groupExam {
          id
          groupId
          contentId
          content {
            id
            name
            timeLimit
            requiredTime
            indexInCourse
            passingStandard
          }
          courseId
          course {
            id
            version
            name
            color
            image
            fontColorOnImage
          }
          scheduledStart
          scheduledFinish
          finishedAt
          visibilityLevel
          tenantCode
          times
          createdAt
          updatedAt
          timeLimit
          passingStandard
        }
      }
      nextToken
    }
  }
`;

export const getUserExam = /* GraphQL */ `
  query GetUserExam($id: ID!) {
    getUserExam(id: $id) {
      id
      userId
      status
      tenantCode
      groupExamId
      groupId
      startedAt
      finishedAt
      createdAt
      updatedAt
      groupExam {
        id
        groupId
        contentId
        content {
          id
          name
          timeLimit
          requiredTime
          indexInCourse
          passingStandard
        }
        courseId
        course {
          id
          version
          name
          color
          image
          fontColorOnImage
        }
        scheduledStart
        scheduledFinish
        finishedAt
        visibilityLevel
        tenantCode
        times
        createdAt
        updatedAt
        timeLimit
        passingStandard
        problemsJson
        problemHeaders {
          id
          body
          createdAt
        }
      }
    }
  }
`;

export const userExamsByUser = /* GraphQL */ `
  query UserExamsByUser(
    $userId: ID
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserExamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userExamsByUser(
      userId: $userId
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        status
        tenantCode
        groupExamId
        groupId
        startedAt
        finishedAt
        createdAt
        updatedAt
        groupExam {
          id
          groupId
          contentId
          content {
            id
            name
            timeLimit
            requiredTime
            indexInCourse
            passingStandard
          }
          courseId
          course {
            id
            version
            name
            color
            image
            fontColorOnImage
          }
          scheduledStart
          scheduledFinish
          finishedAt
          visibilityLevel
          tenantCode
          times
          createdAt
          updatedAt
          timeLimit
          passingStandard
        }
      }
      nextToken
    }
  }
`;
// =========================================================================
// GroupTrainingCourse
// =========================================================================
export const getGroupTrainingCourse = /* GraphQL */ `
  query GetGroupTrainingCourse($id: ID!) {
    getGroupTrainingCourse(id: $id) {
      id
      groupTrainingId
      courseId
      courseVersion
      courseName
      contents {
        id
        name
        requiredTime
        type
        recommendedDateTime
        open
        version
      }
      groupId
      tenantCode
      color
      image
      fontColorOnImage
      displayName
      createdAt
      updatedAt
      groupExams {
        items {
          id
          contentId
          scheduledStart
          scheduledFinish
          finishedAt
          times
        }
        nextToken
      }
    }
  }
`;
export const groupTrainingCoursesByGroupIdAndCourseId = /* GraphQL */ `
  query GroupTrainingCoursesByGroupIdAndCourseId(
    $groupId: ID
    $courseId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGroupTrainingCourseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    groupTrainingCoursesByGroupIdAndCourseId(
      groupId: $groupId
      courseId: $courseId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupTrainingId
        courseId
        courseVersion
        courseName
        contents {
          id
          name
          requiredTime
          type
          recommendedDateTime
          open
          version
        }
        groupId
        tenantCode
        color
        image
        fontColorOnImage
        createdAt
        updatedAt
        displayName
        groupExams {
          items {
            id
            contentId
            scheduledStart
            scheduledFinish
            finishedAt
            times
          }
          nextToken
        }
        index
      }
      nextToken
    }
  }
`;
export const groupTrainingCoursesByCourseId = /* GraphQL */ `
  query GroupTrainingCoursesByCourseId(
    $courseId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelGroupTrainingCourseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    groupTrainingCoursesByCourseId(
      courseId: $courseId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupTrainingId
        courseId
        courseVersion
        courseName
        contents {
          id
          name
          requiredTime
          type
          recommendedDateTime
          open
          version
        }
        groupId
        tenantCode
        color
        image
        fontColorOnImage
        displayName
        createdAt
        updatedAt
        groupExams {
          items {
            id
            contentId
            scheduledStart
            scheduledFinish
            finishedAt
            times
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
// =========================================================================
// UserTag
// =========================================================================
export const getUserTag = /* GraphQL */ `
  query GetUserTag($id: ID!) {
    getUserTag(id: $id) {
      id
      color
      text
      tenantCode
      createdAt
      updatedAt
    }
  }
`;

export const userTagsByTenantCode = /* GraphQL */ `
  query UserTagsByTenantCode(
    $tenantCode: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userTagsByTenantCode(
      tenantCode: $tenantCode
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        color
        text
        tenantCode
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

// =========================================================================
// User
// =========================================================================
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      name
      role
      extensionConfigJsonList
      email
      code
      tenantCode
      displaySettings {
        theme
        color
      }
      locale
      avatar
      enabled
      signedInAtLeastOnce
      confirmedTermsOfServiceVersions {
        termsOfServiceVersion
        privacyPolicyVersion
        tenantOwnTermsOfServiceVersion
      }
      createdAt
      updatedAt
      tags {
        items {
          id
          userId
          userTagId
          userTag {
            id
            color
            text
            tenantCode
            createdAt
            updatedAt
          }
          tenantCode
          createdAt
          updatedAt
        }
        nextToken
      }
      statuses {
        items {
          id
          tenantCode
          status
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers($filter: ModelUserFilterInput, $limit: Int, $nextToken: String) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        role
        extensionConfigJsonList
        email
        code
        tenantCode
        displaySettings {
          theme
          color
        }
        locale
        avatar
        enabled
        createdAt
        updatedAt
        tags {
          items {
            id
            userId
            userTagId
            userTag {
              id
              color
              text
              tenantCode
              createdAt
              updatedAt
            }
            tenantCode
            createdAt
            updatedAt
          }
          nextToken
        }
        statuses {
          items {
            id
            tenantCode
            status
            userId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const userByEmail = /* GraphQL */ `
  query UserByEmail(
    $email: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        role
        extensionConfigJsonList
        email
        code
        tenantCode
        displaySettings {
          theme
          color
        }
        locale
        avatar
        enabled
        createdAt
        updatedAt
        tags {
          items {
            id
            userId
            userTagId
            userTag {
              id
              color
              text
              tenantCode
              createdAt
              updatedAt
            }
            tenantCode
            createdAt
            updatedAt
          }
          nextToken
        }
        statuses {
          items {
            id
            tenantCode
            status
            userId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const userByCodeAndTenantCode = /* GraphQL */ `
  query UserByCodeAndTenantCode(
    $code: String
    $tenantCode: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByCodeAndTenantCode(
      code: $code
      tenantCode: $tenantCode
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        role
        extensionConfigJsonList
        email
        code
        tenantCode
        displaySettings {
          theme
          color
        }
        locale
        avatar
        enabled
        createdAt
        updatedAt
        tags {
          items {
            id
            userId
            userTagId
            userTag {
              id
              color
              text
              tenantCode
              createdAt
              updatedAt
            }
            tenantCode
            createdAt
            updatedAt
          }
          nextToken
        }
        statuses {
          items {
            id
            tenantCode
            status
            userId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const usersByTenantCode = /* GraphQL */ `
  query UsersByTenantCode(
    $tenantCode: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByTenantCode(
      tenantCode: $tenantCode
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        role
        extensionConfigJsonList
        email
        code
        tenantCode
        displaySettings {
          theme
          color
        }
        locale
        avatar
        enabled
        createdAt
        updatedAt
        tags {
          items {
            id
            userId
            userTagId
            userTag {
              id
              color
              text
              tenantCode
              createdAt
              updatedAt
            }
            tenantCode
            createdAt
            updatedAt
          }
          nextToken
        }
        statuses {
          items {
            id
            tenantCode
            status
            userId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;

// =========================================================================
// GroupExams
// =========================================================================
export const getGroupExam = /* GraphQL */ `
  query GetGroupExam($id: ID!) {
    getGroupExam(id: $id) {
      id
      groupId
      contentId
      content {
        id
        name
        timeLimit
        requiredTime
        indexInCourse
        passingStandard
      }
      problemsJson
      problemHeaders {
        id
        body
        createdAt
      }
      courseId
      course {
        id
        version
        name
        color
        image
        fontColorOnImage
      }
      scheduledStart
      scheduledFinish
      finishedAt
      visibilityLevel
      tenantCode
      times
      createdAt
      updatedAt
      userExams {
        items {
          id
          userId
          status
          tenantCode
          groupExamId
          groupId
          startedAt
          finishedAt
          createdAt
          updatedAt
        }
        nextToken
      }
      timeLimit
      passingStandard
      userIdsToBeTested
    }
  }
`;

export const groupExamsByGroup = /* GraphQL */ `
  query GroupExamsByGroup(
    $groupId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelGroupExamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    groupExamsByGroup(
      groupId: $groupId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupId
        contentId
        content {
          id
          name
          timeLimit
          requiredTime
          indexInCourse
          passingStandard
        }
        problemsJson
        courseId
        course {
          id
          version
          name
          color
          image
          fontColorOnImage
        }
        scheduledStart
        scheduledFinish
        finishedAt
        visibilityLevel
        tenantCode
        times
        groupTrainingCourseId
        problemHeaders {
          id
          body
          createdAt
        }
        timeLimit
        createdAt
        updatedAt
        userExams {
          items {
            id
            userId
            status
            tenantCode
            groupExamId
            groupId
            startedAt
            finishedAt
            createdAt
            updatedAt
          }
          nextToken
        }
        passingStandard
        userIdsToBeTested
      }
      nextToken
    }
  }
`;

export const groupExamsByGroupAndContent = /* GraphQL */ `
  query GroupExamsByGroupAndContent(
    $groupId: ID
    $contentId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGroupExamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    groupExamsByGroupAndContent(
      groupId: $groupId
      contentId: $contentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupId
        contentId
        content {
          id
          name
          timeLimit
          requiredTime
          indexInCourse
          passingStandard
        }
        problemsJson
        courseId
        course {
          id
          version
          name
          color
          image
          fontColorOnImage
        }
        scheduledStart
        scheduledFinish
        finishedAt
        visibilityLevel
        tenantCode
        times
        groupTrainingCourseId
        problemHeaders {
          id
          body
          createdAt
        }
        timeLimit
        createdAt
        updatedAt
        userExams {
          items {
            id
            userId
            status
            tenantCode
            groupExamId
            groupId
            startedAt
            finishedAt
            createdAt
            updatedAt
          }
          nextToken
        }
        passingStandard
        userIdsToBeTested
      }
      nextToken
    }
  }
`;

export const groupExamsByGroupAndCourse = /* GraphQL */ `
  query GroupExamsByGroupAndCourse(
    $groupId: ID
    $courseId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGroupExamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    groupExamsByGroupAndCourse(
      groupId: $groupId
      courseId: $courseId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupId
        contentId
        content {
          id
          name
          timeLimit
          requiredTime
          indexInCourse
          passingStandard
        }
        problemsJson
        courseId
        course {
          id
          version
          name
          color
          image
          fontColorOnImage
        }
        scheduledStart
        scheduledFinish
        finishedAt
        visibilityLevel
        tenantCode
        times
        groupTrainingCourseId
        problemHeaders {
          id
          body
          createdAt
        }
        timeLimit
        createdAt
        updatedAt
        userExams {
          items {
            id
            userId
            status
            tenantCode
            groupExamId
            groupId
            startedAt
            finishedAt
            createdAt
            updatedAt
          }
          nextToken
        }
        passingStandard
        userIdsToBeTested
      }
      nextToken
    }
  }
`;

export const groupExamsByCourse = /* GraphQL */ `
  query GroupExamsByCourse(
    $courseId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelGroupExamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    groupExamsByCourse(
      courseId: $courseId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupId
        contentId
        content {
          id
          name
          timeLimit
          requiredTime
          indexInCourse
          passingStandard
        }
        problemsJson
        courseId
        course {
          id
          version
          name
          color
          image
          fontColorOnImage
        }
        scheduledStart
        scheduledFinish
        finishedAt
        visibilityLevel
        tenantCode
        times
        groupTrainingCourseId
        problemHeaders {
          id
          body
          createdAt
        }
        timeLimit
        createdAt
        updatedAt
        userExams {
          items {
            id
            userId
            status
            tenantCode
            groupExamId
            groupId
            startedAt
            finishedAt
            createdAt
            updatedAt
          }
          nextToken
        }
        passingStandard
        userIdsToBeTested
      }
      nextToken
    }
  }
`;

// =========================================================================
// AmplifyEditingCourseQueries
// =========================================================================
export const findEditingCourseWithLastContentUpdatedById = /* GraphQL */ `
  query FindEditingCourseWithLastContentUpdatedById($id: ID!) {
    getEditingCourse(id: $id) {
      id
      tenantCode
      name
      version
      status
      description
      contents {
        id
        name
        requiredTime
        type
      }
      createdBy
      updatedBy
      color
      image
      fontColorOnImage
      createdAt
      updatedAt
    }
    editingCourseContentWorkbooksByCourse(courseId: $id) {
      items {
        updatedBy
        updatedAt
      }
      nextToken
    }
    editingCourseContentBodiesByCourse(courseId: $id) {
      items {
        updatedBy
        updatedAt
      }
      nextToken
    }
  }
`;

// =========================================================================
// AmplifyContentQueries
// =========================================================================
export const contentHeadersByCourse = /* GraphQL */ `
  query ContentByCourse(
    $courseId: ID
    $courseVersion: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelContentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    contentByCourse(
      courseId: $courseId
      courseVersion: $courseVersion
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        contentId
        version
        courseId
        courseVersion
        tenantCode
        versionDescription
      }
      nextToken
    }
  }
`;

export const contentHeadersByTenantCode = /* GraphQL */ `
  query ContentsByTenantCode(
    $tenantCode: String
    $sortDirection: ModelSortDirection
    $filter: ModelContentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    contentsByTenantCode(
      tenantCode: $tenantCode
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        contentId
        version
        courseId
        courseVersion
        tenantCode
        versionDescription
      }
      nextToken
    }
  }
`;

// =========================================================================
// GetQuestionnaireByAnswerer
// =========================================================================
export const getQuestionnaireByAnswerer = /* GraphQL */ `
  query GetQuestionnaireByAnswerer($id: ID!, $userId: ModelIDKeyConditionInput) {
    getQuestionnaire(id: $id) {
      id
      groupId
      tenantCode
      title
      userIds
      text
      createdBy
      createdAt
      options
      status
      finishedAt
      updatedAt
    }
    questionnaireRespondentByQuestionnaireIdAndUserId(questionnaireId: $id, userId: $userId) {
      items {
        questionnaireId
        userId
        selectedIndex
        tenantCode
        createdAt
        updatedAt
      }
    }
  }
`;

export const questionnairesByAnswerer = /* GraphQL */ `
  query QuestionnairesByAnswerer(
    $groupId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionnaireFilterInput
    $limit: Int
    $nextToken: String
  ) {
    questionnaireByGroup(
      groupId: $groupId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupId
        tenantCode
        title
        userIds
        text
        createdBy
        createdAt
        options
        status
        finishedAt
        updatedAt
      }
      nextToken
    }
  }
`;
