import { GroupId, Role, Schedule, ScheduleFinder } from '@/base/domains';
import { LocalDateTime } from '@/base/types';
import { AbstractUseCase, UseCase, UseCaseResponse } from '@/base/usecases';
import { hasNonNullProperty } from '@/utils/TsUtils';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

export type GetSchedulesByAdminRequest =
  | {
      groupId: GroupId;
      limit?: number;

      startFrom: LocalDateTime;
      startTo: LocalDateTime;
    }
  | {
      groupId: GroupId;
      limit?: number;
      recently: true;
    };
export type GetSchedulesByAdminResponse = {
  schedules: Array<Schedule>;
};

export interface GetSchedulesByAdmin
  extends UseCase<GetSchedulesByAdminRequest, GetSchedulesByAdminResponse> {
  execute(
    request: GetSchedulesByAdminRequest
  ): Promise<UseCaseResponse<GetSchedulesByAdminResponse>>;
}

export class GetSchedulesByAdminImpl
  extends AbstractUseCase<GetSchedulesByAdminRequest, GetSchedulesByAdminResponse>
  implements GetSchedulesByAdmin
{
  private scheduleFinder: ScheduleFinder;

  constructor(scheduleFinder: ScheduleFinder) {
    super('home.GetSchedulesByAdmin');
    this.scheduleFinder = scheduleFinder;
  }

  executableRole(): Array<Role> {
    return ['supervisor', 'admin'];
  }

  async internalExecute(request: GetSchedulesByAdminRequest): Promise<GetSchedulesByAdminResponse> {
    const schedules = await (async () => {
      if (hasNonNullProperty(request, 'recently')) {
        const res = await this.scheduleFinder.findRecently({
          groupId: request.groupId,
          limit: request.limit,
        });
        return res.data;
      }
      const res = await this.scheduleFinder.find(request);
      return res.data;
    })();
    return {
      schedules,
    };
  }
}

export const GetSchedulesByAdminKey = injectionKeyOf<GetSchedulesByAdmin>({
  boundedContext: 'home',
  type: 'usecase',
  name: 'GetSchedulesByAdmin',
});

export function useGetSchedulesByAdmin(): GetSchedulesByAdmin {
  return requiredInject(GetSchedulesByAdminKey);
}
