import { v4 } from 'uuid';

import { ContentId, CourseId, EntityData, GroupExamId, GroupId, hasId } from '@/base/domains';
import { Optional } from '@/base/types';
import {
  GroupExamAttributes,
  GroupExamEntity,
  GroupExamEntityImpl,
  GroupExamRepository,
} from '@/training/domains';

export class InMemoryGroupExamRepository implements GroupExamRepository {
  private store = new Map<CourseId, GroupExamEntity>();

  save(args: GroupExamEntity | EntityData<string, GroupExamAttributes>): Promise<GroupExamEntity> {
    if (hasId(args)) {
      const e = new GroupExamEntityImpl({
        ...args,
      });
      this.store.set(e.id, e);
      return Promise.resolve(e);
    }
    const e = new GroupExamEntityImpl({
      ...args,
      id: v4(),
    });
    this.store.set(e.id, e);
    return Promise.resolve(e);
  }

  findById(id: GroupExamId): Promise<Optional<GroupExamEntity>> {
    return Promise.resolve(this.store.get(id));
  }

  remove(id: GroupExamId): Promise<void> {
    Promise.resolve(this.store.delete(id));
    return Promise.resolve();
  }

  findGroupExamsByGroupIdAndContentId(
    groupId: GroupId,
    contentId: ContentId
  ): Promise<GroupExamEntity[]> {
    return Promise.resolve(
      Array.from(this.store.values()).filter(
        (e) => e.groupId === groupId && e.content.id === contentId
      )
    );
  }

  findGroupExamsByGroupId(groupId: GroupId): Promise<GroupExamEntity[]> {
    return Promise.resolve(Array.from(this.store.values()).filter((e) => e.groupId === groupId));
  }
}
