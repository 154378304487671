import { AuthorizationService, ContentId, DataVersion } from '@/base/domains';
import {
  AbstractUseCase,
  assertDataVersion,
  assertEntityExists,
  UseCase,
  UseCaseResponse,
} from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { EditingConfirmedContentReference, EditingConfirmedContentRepository } from '../domains';

export type ChangeConfirmedExamPassingStandardRequest = {
  id: ContentId;
  passingStandard?: number;
  expectedDataVersion: DataVersion;
};

export type ChangeConfirmedExamPassingStandardResponse = {
  editingConfirmedContent: EditingConfirmedContentReference;
};

/**
 * 確定済みテストの合格基準を変更する
 */
export interface ChangeConfirmedExamPassingStandard
  extends UseCase<
    ChangeConfirmedExamPassingStandardRequest,
    ChangeConfirmedExamPassingStandardResponse
  > {
  execute(
    request: ChangeConfirmedExamPassingStandardRequest
  ): Promise<UseCaseResponse<ChangeConfirmedExamPassingStandardResponse>>;
}

export class ChangeConfirmedExamPassingStandardImpl
  extends AbstractUseCase<
    ChangeConfirmedExamPassingStandardRequest,
    ChangeConfirmedExamPassingStandardResponse
  >
  implements ChangeConfirmedExamPassingStandard
{
  constructor(
    private authorizationService: AuthorizationService,
    private editingConfirmedContentRepository: EditingConfirmedContentRepository
  ) {
    super('contents.ChangeConfirmedExamPassingStandard');
  }

  async internalExecute(
    request: ChangeConfirmedExamPassingStandardRequest
  ): Promise<ChangeConfirmedExamPassingStandardResponse> {
    const { id, passingStandard, expectedDataVersion } = request;
    this.authorizationService.assertContentEditable();
    const entity = await this.editingConfirmedContentRepository.findById(id);
    assertEntityExists(entity, 'editingConfirmedContent');
    assertDataVersion(entity, expectedDataVersion, 'editingConfirmedContent');
    if (entity.type !== 'exam') {
      throw new Error('content.type should be exam');
    }
    const saved = await this.editingConfirmedContentRepository.save(
      entity.changePassingStandard(passingStandard)
    );
    return {
      editingConfirmedContent: saved,
    };
  }
}

export const ChangeConfirmedExamPassingStandardKey =
  injectionKeyOf<ChangeConfirmedExamPassingStandard>({
    boundedContext: 'contents',
    type: 'usecase',
    name: 'ChangeConfirmedExamPassingStandard',
  });

export function useChangeConfirmedExamPassingStandard(): ChangeConfirmedExamPassingStandard {
  return requiredInject(ChangeConfirmedExamPassingStandardKey);
}
