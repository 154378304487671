import { useGlobalStore } from '@/base/app/store';
import { AppContext, AppContextProvider, GroupId } from '@/base/domains';
import { isDefined, requiredNonNull } from '@/utils/TsUtils';

export class GlobalStoreAppContextProvider implements AppContextProvider {
  get(): AppContext {
    const globalStore = useGlobalStore();

    const user = globalStore.user.value;
    const tenantCode = isDefined(user) ? user.tenantCode : undefined;
    const accessibleGroups = globalStore.accessibleGroups.value ?? [];
    return {
      user,
      tenantCode,
      groupId: globalStore.groupId.value,
      applicationSessionId: requiredNonNull(
        globalStore.applicationSessionId.value,
        'globalStore.applicationSessionId'
      ),
      roleInGroup: (groupId: GroupId) => {
        if (user) {
          const userAndGroupRole = globalStore.findUserAndGroupRole(user.id, groupId);
          if (userAndGroupRole) {
            return userAndGroupRole.groupRole;
          }
        }
        return undefined;
      },
      groupNameOf: (groupId: GroupId) => {
        if (user) {
          const group = globalStore.groups.value.find((g) => g.id === groupId);
          if (group) {
            return group.name;
          }
        }
        return undefined;
      },
      groupLimitationOf: (groupId: GroupId) => {
        const limitations = globalStore.groups.value.find((g) => g.id === groupId)?.limitations;
        if (limitations) return limitations;
        return { question: [], schedule: [], questionnaire: [] };
      },
      groupOf: (groupId: GroupId) => {
        return accessibleGroups.find((g) => g.id === groupId);
      },
      accessibleGroups,
      groupsIncludeRemovedUser: globalStore.groupsIncludeRemovedUser.value ?? [],
      users: globalStore.users.value ?? [],
      playground: globalStore.isPlayground.value,
    };
  }
}
