import { Content, ContentFinder, ExamDataAdapter, ExamId, MyExamResult } from '@/base/domains';
import { AbstractUseCase, assertEntityExists, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

export type GetMyExamResultDetailsRequest = {
  id: ExamId;
};

export type GetMyExamResultDetailsResponse = {
  examResult?: MyExamResult;
  exam?: Content;
};

export interface GetMyExamResultDetails
  extends UseCase<GetMyExamResultDetailsRequest, GetMyExamResultDetailsResponse> {
  execute(
    request: GetMyExamResultDetailsRequest
  ): Promise<UseCaseResponse<GetMyExamResultDetailsResponse>>;
}

export class GetMyExamResultDetailsImpl
  extends AbstractUseCase<GetMyExamResultDetailsRequest, GetMyExamResultDetailsResponse>
  implements GetMyExamResultDetails
{
  constructor(private examDataAdapter: ExamDataAdapter, private contentFinder: ContentFinder) {
    super('report.GetMyExamResultDetails');
  }

  async internalExecute(
    request: GetMyExamResultDetailsRequest
  ): Promise<GetMyExamResultDetailsResponse> {
    const examResult = await this.examDataAdapter.findMyExamResultById(request.id);
    if (!examResult) {
      return {};
    }
    if (examResult.visibilityLevel !== 'details') {
      return {
        examResult,
      };
    }
    const contentVersion = await (async () => {
      if (examResult.version === 1) {
        return undefined;
      }
      if (examResult.version === 3) {
        return examResult.contentVersion;
      }
      const groupExam = await this.examDataAdapter.findGroupExamById(examResult.groupExamId);
      assertEntityExists(groupExam, 'groupExam');
      return groupExam.content.version;
    })();
    const exam = await this.contentFinder.findById(examResult.contentId, contentVersion);
    if (!exam) {
      return {
        examResult,
      };
    }
    if (exam.type !== 'exam') {
      throw new Error('unexpected content type');
    }
    return {
      examResult,
      exam,
    };
  }
}

export const GetMyExamResultDetailsKey = injectionKeyOf<GetMyExamResultDetails>({
  boundedContext: 'report',
  type: 'usecase',
  name: 'GetMyExamResultDetails',
});

export function useGetMyExamResultDetails(): GetMyExamResultDetails {
  return requiredInject(GetMyExamResultDetailsKey);
}
