import {
  ContentFinder,
  ContentId,
  ContentType,
  CourseDisplayName,
  CourseFinder,
  CourseId,
  GroupId,
} from '@/base/domains';
import { AbstractUseCase, assertEntityExists, UseCase, UseCaseResponse } from '@/base/usecases';
import { config } from '@/config';
import { requiredNonNull } from '@/utils/TsUtils';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import {
  GroupTrainingCourseReference,
  GroupTrainingCourseRepository,
  GroupTrainingRepository,
} from '../domains';
import {
  GROUP_COURSE_LIMIT_EXCEEDED,
  GROUP_TRAINING_DUPLICATED_COURSE,
  GROUP_TRAINING_DUPLICATED_COURSE_DISPLAY_NAME,
} from '../ErrorCodes';

export interface AddGroupCourseRequest {
  groupId: GroupId;
  courseId: CourseId;
  contents?: Array<{ id: ContentId; open: boolean }>;
  courseDisplayName?: CourseDisplayName;
}

export type AddGroupCourseResponse = {
  groupTrainingCourse: GroupTrainingCourseReference;
};

/**
 * グループにコースを追加する
 */
export interface AddGroupCourse extends UseCase<AddGroupCourseRequest, AddGroupCourseResponse> {
  execute(request: AddGroupCourseRequest): Promise<UseCaseResponse<AddGroupCourseResponse>>;
}

export class AddGroupCourseImpl
  extends AbstractUseCase<AddGroupCourseRequest, AddGroupCourseResponse>
  implements AddGroupCourse
{
  constructor(
    private groupTrainingRepository: GroupTrainingRepository,
    private groupTrainingCourseRepository: GroupTrainingCourseRepository,
    private courseFinder: CourseFinder,
    private contentFinder: ContentFinder
  ) {
    super('training.AddGroupCourse');
  }

  async internalExecute(request: AddGroupCourseRequest): Promise<AddGroupCourseResponse> {
    const { groupId, courseId, contents = [] } = request;

    const [course, groupTraining, coursesInGroup, courseWithSaveGroupAndCourse] = await Promise.all(
      [
        this.courseFinder.findActiveCourseById(courseId),
        this.groupTrainingRepository.findByGroupId(groupId),
        this.groupTrainingCourseRepository.findCoursesByGroupId(groupId),
        this.groupTrainingCourseRepository.findByGroupIdAndCourseId(groupId, courseId),
      ]
    );

    assertEntityExists(course, 'course');
    assertEntityExists(groupTraining, 'groupTraining');

    const courseDisplayName = request.courseDisplayName ?? course.name;
    const cnts = await this.contentFinder.findByCourseId(courseId, course.version);
    const contentVersions = new Map(cnts.map((cn) => [cn.id, cn.version]));

    if (coursesInGroup.length >= config().app.courseLimitInGroup) {
      throw GROUP_COURSE_LIMIT_EXCEEDED.toApplicationError({ groupId });
    }
    if (courseWithSaveGroupAndCourse) {
      throw GROUP_TRAINING_DUPLICATED_COURSE.toApplicationError({ groupId, courseId });
    }
    if (coursesInGroup.find((cn) => cn.displayName === courseDisplayName)) {
      throw GROUP_TRAINING_DUPLICATED_COURSE_DISPLAY_NAME.toApplicationError({
        groupId,
        courseDisplayName,
      });
    }

    const isOpen = (() => {
      const map = new Map(contents.map((cn) => [cn.id, cn.open]));
      return (id: ContentId, type: ContentType) => map.get(id) ?? type !== 'exam';
    })();

    const saved = await this.groupTrainingCourseRepository.save({
      groupTrainingId: groupTraining.id,
      courseId,
      courseVersion: course.version,
      courseName: course.name,
      contents: course.contents.map((cn) => ({
        ...cn,
        recommendedDateTime: undefined,
        open: isOpen(cn.id, cn.type),
        version: requiredNonNull(contentVersions.get(cn.id), 'content'),
      })),
      groupId: groupTraining.groupId,
      color: course.color,
      image: course.image,
      fontColorOnImage: course.fontColorOnImage,
      displayName: courseDisplayName,
      index: coursesInGroup.length,
    });
    return {
      groupTrainingCourse: saved,
    };
  }
}

export const AddGroupCourseKey = injectionKeyOf<AddGroupCourse>({
  boundedContext: 'contents',
  type: 'usecase',
  name: 'AddGroupCourse',
});

export function useAddGroupCourse(): AddGroupCourse {
  return requiredInject(AddGroupCourseKey);
}
