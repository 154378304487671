import moment from 'moment';
import { v4 } from 'uuid';

import { NotificationData } from '@/base/domains';
import {
  AddCommentToQuestionNotification,
  AskQuestionNotification,
} from '@/base/NotificationTypes';

export const notifications: Array<NotificationData> = [
  {
    id: v4(),
    type: AskQuestionNotification,
    payload: {
      title: 'サンプル1でエラーが発生します',
      createdBy: 'user02',
      commentBody:
        'Java入門のサンプル1を実行してみましたが、エラーがでます。\nどこを調べたらいいかわかりませんでした。\n',
      groupId: 'group00',
      questionId: 'question00',
    },
    userId: 'admin00',
    read: 'read',
    createdAt: moment().add(-3, 'hours'),
  },
  {
    id: v4(),
    type: AddCommentToQuestionNotification,
    payload: {
      title: 'サンプル1でエラーが発生します',
      commentId: 'q00-c01',
      commentBody:
        '@{{user02}}\nエラーの内容がコンソールに表示されていると思います。\nエラーの内容を返信してください。\n\n@{{mentor01}} 対応お願いします。\n',
      commentCreatedBy: 'admin00',
      groupId: 'group00',
      questionId: 'question00',
    },
    userId: 'admin01',
    read: 'unread',
    createdAt: moment().add(-2, 'hours'),
  },
  {
    id: v4(),
    type: AddCommentToQuestionNotification,
    payload: {
      title: 'サンプル1でエラーが発生します',
      commentId: 'q00-c01',
      commentBody:
        '@{{user02}}\nエラーの内容がコンソールに表示されていると思います。\nエラーの内容を返信してください。\n\n@{{mentor01}} 対応お願いします。\n',
      commentCreatedBy: 'admin00',
      groupId: 'group00',
      questionId: 'question00',
    },
    userId: 'mentor01',
    read: 'unread',
    createdAt: moment().add(-2, 'hours'),
  },
  {
    id: v4(),
    type: AddCommentToQuestionNotification,
    payload: {
      title: 'サンプル1でエラーが発生します',
      commentId: 'q00-c02',
      commentBody: '@{{mentor01}} @{{admin00}}\nお願いします。\n```java\nエラーの内容\n```\n',
      commentCreatedBy: 'user02',
      groupId: 'group00',
      questionId: 'question00',
    },
    userId: 'admin01',
    read: 'unread',
    createdAt: moment().add(-2, 'hours'),
  },
  {
    id: v4(),
    type: AddCommentToQuestionNotification,
    payload: {
      title: 'サンプル1でエラーが発生します',
      commentId: 'q00-c02',
      commentBody:
        '@{{mentor01}} @{{admin00}}\nお願いします。\n[Google](https://www.google.com/)\n```java\nエラーの内容\n```\n',
      commentCreatedBy: 'user02',
      groupId: 'group00',
      questionId: 'question00',
    },
    userId: 'admin00',
    read: 'unread',
    createdAt: moment().add(-2, 'hours'),
  },
  {
    id: v4(),
    type: AddCommentToQuestionNotification,
    payload: {
      title: 'サンプル1でエラーが発生します',
      commentId: 'q00-c04',
      commentBody: '解決しました！ありがとうございます！\n',
      commentCreatedBy: 'user02',
      groupId: 'group00',
      questionId: 'question00',
    },
    userId: 'admin00',
    read: 'unread',
    createdAt: moment().add(-1, 'hours'),
  },
];
