import { CreateEventInput } from '@/API';
import { AppContextProvider, Event, EventEnvelop, EventId, EventStore } from '@/base/domains';
import * as mutations from '@/graphql/mutations';
import { graphql } from '@/utils/AmplifyUtils';
import { assertIsDefined } from '@/utils/Asserts';

type AmplifyEvent = {
  id: EventId;
};

export class AmplifyEventStore implements EventStore {
  private appContextProvider: AppContextProvider;

  constructor(appContextProvider: AppContextProvider) {
    this.appContextProvider = appContextProvider;
  }

  async save<E extends Event>(eventEnvelop: EventEnvelop<E>): Promise<EventId> {
    const { type, version, event } = eventEnvelop;
    const { tenantCode, user } = this.appContextProvider.get();
    assertIsDefined(tenantCode, 'appContext.tenantCode');
    assertIsDefined(user, 'appContext.user');
    const input: CreateEventInput = {
      type,
      version,
      event: JSON.stringify(event),
      tenantCode,
      occurredBy: user.id,
    };

    const res = await graphql<{ createEvent: AmplifyEvent }>(mutations.createEvent, { input });
    return res.createEvent.id;
  }
}
