import {
  AppContextProvider,
  localEventBus,
  PrivacyPolicyVersion,
  TermsOfServiceId,
  User,
  UserRepository,
} from '@/base/domains';
import { termsOfServiceVersionsConfirmed } from '@/base/domains/LocalEvents';
import { AbstractUseCase, assertEntityExists, UseCase, UseCaseResponse } from '@/base/usecases';
import { assertIsDefined } from '@/utils/Asserts';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

export type ConfirmTermsOfServiceRequest = {
  termsOfServiceVersion?: TermsOfServiceId;
  privacyPolicyVersion?: PrivacyPolicyVersion;
  tenantOwnTermsOfServiceVersion?: number;
};

export type ConfirmTermsOfServiceResponse = {
  user: User;
};

export interface ConfirmTermsOfService
  extends UseCase<ConfirmTermsOfServiceRequest, ConfirmTermsOfServiceResponse> {
  execute(
    request: ConfirmTermsOfServiceRequest
  ): Promise<UseCaseResponse<ConfirmTermsOfServiceResponse>>;
}

export class ConfirmTermsOfServiceImpl
  extends AbstractUseCase<ConfirmTermsOfServiceRequest, ConfirmTermsOfServiceResponse>
  implements ConfirmTermsOfService
{
  constructor(
    private userRepository: UserRepository,
    private appContextProvider: AppContextProvider
  ) {
    super('base.ConfirmTermsOfService');
  }

  async internalExecute(
    request: ConfirmTermsOfServiceRequest
  ): Promise<ConfirmTermsOfServiceResponse> {
    const { termsOfServiceVersion, privacyPolicyVersion, tenantOwnTermsOfServiceVersion } = request;
    const userId = this.appContextProvider.get().user?.id;
    assertIsDefined(userId, 'appContext.user');
    const user = await this.userRepository.findById(userId);
    assertEntityExists(user, 'user');
    const saved = await this.userRepository.save(
      user.confirmTermsOfService(
        termsOfServiceVersion,
        privacyPolicyVersion,
        tenantOwnTermsOfServiceVersion
      )
    );
    localEventBus.publish(termsOfServiceVersionsConfirmed({ userId }));
    return { user: saved };
  }
}

export const ConfirmTermsOfServiceKey = injectionKeyOf<ConfirmTermsOfService>({
  boundedContext: 'base',
  type: 'usecase',
  name: 'ConfirmTermsOfService',
});

export function useConfirmTermsOfService(): ConfirmTermsOfService {
  return requiredInject(ConfirmTermsOfServiceKey);
}
