import { ContentId, CourseId, DataVersion, Exam, ProblemHeaderId, UserId } from '@/base/domains';
import { LocalDateTime, MarkDownString } from '@/base/types';
import { hasProperty } from '@/utils/TsUtils';
import { uuid } from '@/utils/UniqueIdGenerator';

import { CONTENT_PROBLEM_HEADER_NOT_FOUND } from '../ErrorCodes';
import {
  EditingCourseContentBodyChangeBodyArgs,
  EditingCourseContentBodyEntity,
  ExamEditingCourseContentBodyEntity,
} from './EditingCourseContentBody';

export type ExamEditingCourseContentBodyEntityImplArgs = {
  type: 'exam';

  body: Exam;

  courseId: CourseId;

  dataVersion: DataVersion;

  id: ContentId;

  createdBy: UserId;

  updatedBy: UserId;
};

export class ExamEditingCourseContentBodyEntityImpl implements ExamEditingCourseContentBodyEntity {
  type!: 'exam';

  body: Exam;

  courseId: CourseId;

  dataVersion: DataVersion;

  id: ContentId;

  createdBy: UserId;

  updatedBy: UserId;

  constructor(args: ExamEditingCourseContentBodyEntityImplArgs) {
    this.id = args.id;
    this.type = args.type;
    this.body = args.body;
    this.courseId = args.courseId;
    this.dataVersion = args.dataVersion;
    this.createdBy = args.createdBy;
    this.updatedBy = args.updatedBy;
  }

  addProblemHeader({
    body,
    createdAt,
  }: {
    body: MarkDownString;
    createdAt: LocalDateTime;
  }): EditingCourseContentBodyEntity {
    const problemHeaders = [
      ...this.body.problemHeaders,
      {
        id: uuid(),
        body,
        createdAt,
      },
    ];
    return new ExamEditingCourseContentBodyEntityImpl({
      ...this,
      body: {
        ...this.body,
        problemHeaders,
      },
    });
  }

  removeProblemHeader(id: ProblemHeaderId): EditingCourseContentBodyEntity {
    const problemHeaders = this.body.problemHeaders.filter((h) => h.id !== id);
    return new ExamEditingCourseContentBodyEntityImpl({
      ...this,
      body: {
        ...this.body,
        problemHeaders,
      },
    });
  }

  changeProblemHeader({
    id,
    body,
  }: {
    id: ProblemHeaderId;
    body: MarkDownString;
  }): EditingCourseContentBodyEntity {
    const header = this.body.problemHeaders.find((h) => h.id === id);
    if (!header) {
      throw CONTENT_PROBLEM_HEADER_NOT_FOUND.toApplicationError({
        payload: {
          contentId: this.id,
          problemHeaderId: id,
        },
      });
    }
    const problemHeaders = this.body.problemHeaders.map((h) =>
      h.id === id ? { ...header, body } : h
    );
    return new ExamEditingCourseContentBodyEntityImpl({
      ...this,
      body: {
        ...this.body,
        problemHeaders,
      },
    });
  }

  changeBody(body: EditingCourseContentBodyChangeBodyArgs): EditingCourseContentBodyEntity {
    if (hasProperty(body, 'problems')) {
      return new ExamEditingCourseContentBodyEntityImpl({
        ...this,
        body: {
          ...this.body,
          ...body,
        },
      });
    }
    throw new Error('Unsupported arg type');
  }

  changePassingStandard(passingStandard?: number): EditingCourseContentBodyEntity {
    return new ExamEditingCourseContentBodyEntityImpl({
      ...this,
      body: {
        ...this.body,
        passingStandard,
      },
    });
  }
}
