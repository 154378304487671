import {
  AggregateRootRepository,
  Comment,
  CommentId,
  EntityData,
  EntityLike,
  GroupId,
  QuestionContentReference,
  QuestionId,
  UserId,
} from '@/base/domains';
import { LocalDateTime, MarkDownString, Optional } from '@/base/types';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

export interface QuestionAttributes {
  comments: Array<Comment>;
  // 解決済みになったらtrueにする
  resolved: boolean;
  resolvedBy?: UserId;
  resolvedAt?: LocalDateTime;
  groupId: GroupId;
  title: string;
  // コンテンツに紐づかないのもある想定
  referTo?: QuestionContentReference;
  createdBy: UserId;
  createdAt: LocalDateTime;
  assignees: Array<UserId>;
}

export type CommentData = Omit<Comment, 'id' | 'createdAt'> & {
  id?: CommentId;
  createdAt?: LocalDateTime;
};

export type QuestionData = Omit<EntityData<QuestionId, QuestionAttributes>, 'comments'> & {
  comments: Array<CommentData>;
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface QuestionQueries {}

export interface QuestionReference
  extends Readonly<EntityLike<QuestionId, QuestionAttributes>>,
    QuestionQueries {}

export interface AddCommentRequest {
  body: MarkDownString;
  createdBy: UserId;
}

interface QuestionCommands {
  resolve(args: { by: UserId; at: LocalDateTime }): QuestionEntity;
  // comment.createdAtはサーバーで設定する
  addComment(request: AddCommentRequest): QuestionData;
  changeAssignees(assignees: Array<UserId>): QuestionEntity;
  changeTitle(title: string): QuestionEntity;
}

export interface QuestionEntity extends QuestionReference, QuestionCommands {}

export type FindQuestionsRequest = {
  groupId: GroupId;
  createdBy?: UserId;
  resolved?: boolean;
};

export interface QuestionRepository
  extends AggregateRootRepository<QuestionId, QuestionData, QuestionEntity> {
  findQuestions(
    request: FindQuestionsRequest
  ): Promise<{ questions: Array<QuestionReference>; limitExceeded: boolean }>;

  findCommentById(commentId: CommentId): Promise<Optional<Comment>>;

  changeCommentBody(args: {
    commentId: CommentId;
    body: MarkDownString;
    editedAt: LocalDateTime;
  }): Promise<Comment>;
}

export const QuestionRepositoryKey = injectionKeyOf<QuestionRepository>({
  boundedContext: 'training',
  type: 'adapter',
  name: 'QuestionRepository',
});

export function useQuestionRepository() {
  return requiredInject(QuestionRepositoryKey);
}
