import {
  ContentFinder,
  EventStore,
  ExamDataAdapter,
  ExamId,
  ExamResultVisibilityLevel,
  MyExamResult,
  Problem,
  ProblemHeader,
} from '@/base/domains';
import { Optional } from '@/base/types';
import { AbstractUseCase, assertEntityExists, UseCase, UseCaseResponse } from '@/base/usecases';
import { isDefined } from '@/utils/TsUtils';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { Answer, ExamFinished, examFinishedFactory } from '../domains';
import { FAILED_FINISHING_EXAM } from '../ErrorCodes';

export interface FinishExamRequest {
  examId: ExamId;
  answers: Array<Answer>;
}

export type FinishExamResponse =
  | {
      visibilityLevel: 'details';
      examResult: MyExamResult;
      problems: Array<Problem>;
      problemHeaders: Array<ProblemHeader>;
    }
  | {
      visibilityLevel: ExamResultVisibilityLevel;
      examResult: MyExamResult;
    };

/**
 * 試験を終了する
 */
export interface FinishExam extends UseCase<FinishExamRequest, FinishExamResponse> {
  execute(request: FinishExamRequest): Promise<UseCaseResponse<FinishExamResponse>>;
}

async function waitUntil<T>(
  provider: () => Promise<Optional<T>>,
  condition: (e: T) => boolean,
  limit = 10,
  interval = 1000
): Promise<Optional<T>> {
  return new Promise((resolve, reject) => {
    let count = 0;
    const doProcess = () => {
      count += 1;
      if (count >= limit) {
        resolve(undefined);
      }
      provider()
        .then((e) => {
          if (isDefined(e) && condition(e)) {
            resolve(e);
          } else {
            window.setTimeout(doProcess, interval);
          }
        })
        .catch((e) => {
          reject(e);
        });
    };
    doProcess();
  });
}
export class FinishExamImpl
  extends AbstractUseCase<FinishExamRequest, FinishExamResponse>
  implements FinishExam
{
  private eventStore: EventStore;

  private examDataAdapter: ExamDataAdapter;

  private contentFinder: ContentFinder;

  constructor(
    eventStore: EventStore,
    examDataAdapter: ExamDataAdapter,
    contentFinder: ContentFinder
  ) {
    super('training.FinishExam');
    this.eventStore = eventStore;
    this.examDataAdapter = examDataAdapter;
    this.contentFinder = contentFinder;
  }

  async internalExecute(request: FinishExamRequest): Promise<FinishExamResponse> {
    const event: ExamFinished = request;
    await this.eventStore.save(examFinishedFactory(event));
    const examResult = await waitUntil(
      () => this.examDataAdapter.findMyExamResultById(event.examId),
      (e) => isDefined(e)
    );
    if (examResult) {
      if (examResult.visibilityLevel === 'details') {
        const content = await this.contentFinder.findById(
          examResult.contentId,
          'contentVersion' in examResult ? examResult.contentVersion : undefined
        );
        assertEntityExists(content, 'content');
        if (content.type === 'exam') {
          const exam = content.body;
          return {
            visibilityLevel: examResult.visibilityLevel,
            examResult,
            problems: exam.problems,
            problemHeaders: exam.problemHeaders,
          };
        }
        assertEntityExists(undefined, 'content');
      }
      return {
        visibilityLevel: examResult.visibilityLevel,
        examResult,
      };
    }
    throw FAILED_FINISHING_EXAM.toApplicationError({ payload: { examId: event.examId } });
  }
}

export const FinishExamKey = injectionKeyOf<FinishExam>({
  boundedContext: 'training',
  type: 'usecase',
  name: 'FinishExam',
});

export function useFinishExam(): FinishExam {
  return requiredInject(FinishExamKey);
}
