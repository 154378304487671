import {
  CreateGroupInput,
  DeleteGroupInput,
  GroupRole as AmplifyGroupRole,
  UpdateGroupInput,
} from '@/API';
import {
  AppContextProvider,
  Group,
  GroupAttributes,
  GroupData,
  GroupEntity,
  GroupEntityImpl,
  GroupId,
  GroupLimitation,
  GroupRepository,
  GroupRole,
  GroupUser,
  isEntityLike,
  TenantCode,
  UserId,
} from '@/base/domains';
import { GroupExtensionConfig } from '@/base/domains/extensions/Extension';
import { GROUP_NAME_DUPLICATED } from '@/base/ErrorCodes';
import { JSONString, Optional } from '@/base/types';
import { createGroup, deleteGroup, updateGroup } from '@/graphql/mutations';
import { getGroup, groupsByTenantCode } from '@/graphql/queries';
import { graphql, graphqlQuery } from '@/utils/AmplifyUtils';
import { assertIsDefined } from '@/utils/Asserts';
import { hasNonNullProperty, requiredNonNull } from '@/utils/TsUtils';

function toExtensionConfigInput(config: GroupExtensionConfig): string {
  return JSON.stringify(config);
}

function toGroupUserInput(user: GroupUser): {
  id: UserId;
  role: AmplifyGroupRole;
  removed: boolean;
} {
  return {
    id: user.id,
    role: user.role.toUpperCase() as AmplifyGroupRole,
    removed: user.removed,
  };
}

type AmplifyGroupUser = {
  id: string;
  role: AmplifyGroupRole;
  removed?: boolean;
};
type AmplifyGroup = {
  id: string;
  name: string;
  users: Array<AmplifyGroupUser>;
  extensionConfigJsonList: Array<string>;
  tenantCode: TenantCode;
  limitations: JSONString;
  enabled: boolean;
  description?: string;
  createdAt: string;
  createdBy?: UserId;
  updatedBy?: UserId;
};

function toLimitations(amplifyLimitations: string): GroupLimitation {
  const limitations = JSON.parse(amplifyLimitations) as GroupLimitation;
  return {
    question: limitations.question ?? [],
    schedule: limitations.schedule ?? [],
    questionnaire: limitations.questionnaire ?? [],
  };
}

function toGroup(amplifyGroup: AmplifyGroup, includeRemovedGroupUser?: boolean): GroupEntity {
  return new GroupEntityImpl({
    id: amplifyGroup.id,
    name: amplifyGroup.name,
    users: includeRemovedGroupUser
      ? amplifyGroup.users.map((u) => ({
          id: u.id,
          role: u.role.toLocaleLowerCase() as GroupRole,
          removed: u.removed ?? false,
        }))
      : amplifyGroup.users
          .map((u) => ({
            id: u.id,
            role: u.role.toLocaleLowerCase() as GroupRole,
            removed: u.removed ?? false,
          }))
          .filter((u) => !u.removed),
    extensionConfigs: amplifyGroup.extensionConfigJsonList.map(
      (c) => JSON.parse(c) as GroupExtensionConfig
    ),
    limitations: amplifyGroup.limitations
      ? toLimitations(amplifyGroup.limitations)
      : ({
          question: [],
          schedule: [],
          questionnaire: [],
        } as GroupLimitation),
    enabled: amplifyGroup.enabled,
    description: amplifyGroup.description,
    createdBy: amplifyGroup.createdBy,
    updatedBy: amplifyGroup.updatedBy,
  });
}

export class AmplifyGroupRepository implements GroupRepository {
  private appContextProvider: AppContextProvider;

  constructor(appContextProvider: AppContextProvider) {
    this.appContextProvider = appContextProvider;
  }

  async save(entity: GroupAttributes | GroupData): Promise<GroupEntity> {
    const tenantCode = requiredNonNull(
      this.appContextProvider.get().tenantCode,
      'appContext.tenantCode'
    );
    const userId = requiredNonNull(this.appContextProvider.get().user?.id, 'appContext.user');
    if (isEntityLike(entity)) {
      const input: UpdateGroupInput = {
        id: entity.id,
        name: entity.name,
        extensionConfigJsonList: entity.extensionConfigs.map(toExtensionConfigInput),
        limitations: JSON.stringify(entity.limitations),
        users: entity.users.map(toGroupUserInput),
        tenantCode,
        enabled: entity.enabled,
        description: entity.description,
        createdBy: entity.createdBy ?? '',
        updatedBy: userId,
      };
      try {
        const res = await graphql<{ updateGroup: AmplifyGroup }>(updateGroup, { input });
        assertIsDefined(res);
        return toGroup(res.updateGroup);
      } catch (e) {
        if (hasNonNullProperty(e, 'message') && e.message === 'Duplicated group name') {
          throw GROUP_NAME_DUPLICATED.toApplicationError();
        }
        throw e;
      }
    }
    const input: CreateGroupInput = {
      name: entity.name,
      extensionConfigJsonList: entity.extensionConfigs.map(toExtensionConfigInput),
      limitations: JSON.stringify(entity.limitations),
      users: entity.users.map(toGroupUserInput),
      tenantCode,
      enabled: entity.enabled,
      description: entity.description,
      createdBy: userId,
      updatedBy: userId,
    };
    try {
      const res = await graphql<{ createGroup: AmplifyGroup }>(createGroup, { input });
      assertIsDefined(res);
      return toGroup(res.createGroup);
    } catch (e) {
      if (hasNonNullProperty(e, 'message') && e.message === 'Duplicated group name') {
        throw GROUP_NAME_DUPLICATED.toApplicationError();
      }
      throw e;
    }
  }

  async findById(
    id: GroupId,
    options?: { includeRemovedGroupUser?: boolean; includeDisabledGroup?: boolean }
  ): Promise<Optional<GroupEntity>> {
    if (options?.includeDisabledGroup) {
      const res = await graphql<{ getGroup: AmplifyGroup }>(getGroup, { id });
      return res?.getGroup ? toGroup(res.getGroup, options?.includeRemovedGroupUser) : undefined;
    }
    const res = await graphql<{ getGroup: AmplifyGroup }>(getGroup, {
      id,
      filter: { enabled: { eq: true } },
    });
    return res?.getGroup ? toGroup(res.getGroup, options?.includeRemovedGroupUser) : undefined;
  }

  async remove(id: GroupId): Promise<void> {
    const input: DeleteGroupInput = {
      id,
    };
    await graphql<{ deleteGroup: AmplifyGroup }>(deleteGroup, {
      input,
    });
  }

  async findTenantGroups(options?: {
    includeRemovedGroupUser?: boolean;
    includeDisabledGroup?: boolean;
  }): Promise<Array<Group>> {
    const tenantCode = requiredNonNull(
      this.appContextProvider.get().tenantCode,
      'appContext.tenantCode'
    );

    if (options?.includeDisabledGroup) {
      const res = await graphqlQuery<{ groupsByTenantCode: { items: Array<AmplifyGroup> } }>(
        groupsByTenantCode,
        {
          tenantCode,
        },
        {
          limit: 1000,
        }
      );
      const amplifyGroups = res.groupsByTenantCode.items ?? [];
      amplifyGroups.sort((a, b) => a.createdAt.localeCompare(b.createdAt));
      return amplifyGroups.map((g) => toGroup(g, options?.includeRemovedGroupUser));
    }

    const res = await graphqlQuery<{ groupsByTenantCode: { items: Array<AmplifyGroup> } }>(
      groupsByTenantCode,
      {
        tenantCode,
        filter: {
          enabled: {
            eq: true,
          },
        },
      },
      {
        limit: 1000,
      }
    );
    const amplifyGroups = res.groupsByTenantCode.items ?? [];
    amplifyGroups.sort((a, b) => a.createdAt.localeCompare(b.createdAt));
    return amplifyGroups.map((g) => toGroup(g, options?.includeRemovedGroupUser));
  }
}
