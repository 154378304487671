import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { TenantCode, UserId } from '../../base/domains';
import { LocalDateTime, MarkDownString, Optional } from '../../base/types';
import { TenantOwnTermsOfServiceReference } from './TenantOwnTermsOfService';

export type EditingTenantOwnTermsOfServiceAttributes = {
  body: MarkDownString;
  version: number;
  createdBy: UserId;
  createdAt: LocalDateTime;
  updatedBy: UserId;
  updatedAt: LocalDateTime;
};

export type EditingTenantOwnTermsOfServiceEntityImplArgs = {
  tenantCode: TenantCode;
  body: MarkDownString;
  version: number;
  createdBy: UserId;
  createdAt: LocalDateTime;
  updatedBy: UserId;
  updatedAt: LocalDateTime;
};

export type EditingTenantOwnTermsOfServiceEntity = {
  tenantCode: TenantCode;
} & EditingTenantOwnTermsOfServiceAttributes;

export class EditingTenantOwnTermsOfServiceEntityImpl
  implements EditingTenantOwnTermsOfServiceEntity
{
  readonly tenantCode: TenantCode;

  readonly body: MarkDownString;

  readonly version: number;

  readonly createdBy: UserId;

  readonly createdAt: LocalDateTime;

  readonly updatedBy: UserId;

  readonly updatedAt: LocalDateTime;

  constructor(data: EditingTenantOwnTermsOfServiceEntityImplArgs) {
    this.tenantCode = data.tenantCode;
    this.body = data.body;
    this.version = data.version;
    this.createdBy = data.createdBy;
    this.createdAt = data.createdAt;
    this.updatedBy = data.updatedBy;
    this.updatedAt = data.updatedAt;
  }
}

export interface EditingTenantOwnTermsOfServiceRepository {
  save(body: MarkDownString, version?: number): Promise<EditingTenantOwnTermsOfServiceEntity>;
  findEditingTenantOwnTermsOfService(): Promise<Optional<EditingTenantOwnTermsOfServiceEntity>>;
  confirm(version: number): Promise<TenantOwnTermsOfServiceReference>;
  remove(tenantCode: TenantCode): Promise<void>;
}

export const EditingTenantOwnTermsOfServiceRepositoryKey =
  injectionKeyOf<EditingTenantOwnTermsOfServiceRepository>({
    boundedContext: 'base',
    type: 'adapter',
    name: 'EditingTenantOwnTermsOfServiceRepository',
  });

export function useEditingTenantOwnTermsOfServiceRepository(): EditingTenantOwnTermsOfServiceRepository {
  return requiredInject(EditingTenantOwnTermsOfServiceRepositoryKey);
}
