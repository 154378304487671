import { RouteConfig } from 'vue-router';

const GroupsPage = import(/* webpackChunkName: "admin" */ './pages/GroupsPage.vue');
const GroupPage = import(/* webpackChunkName: "admin" */ './pages/GroupPage.vue');
const TenantOwnTermsOfServicePage = import(
  /* webpackChunkName: "admin" */ './pages/TenantOwnTermsOfServicePage.vue'
);
const TenantOwnTermsOfServiceDraftPage = import(
  /* webpackChunkName: "admin" */ './pages/TenantOwnTermsOfServiceDraftPage.vue'
);

export const adminRoutes: Array<RouteConfig> = [
  {
    path: '/admin/groups',
    name: 'adminGroups',
    component: () => GroupsPage,
  },
  {
    path: '/admin/groups/:id',
    name: 'adminGroup',
    component: () => GroupPage,
    props: (route) => {
      const edit = 'edit' in route.query && route.query.edit !== undefined;
      return { ...route.params, edit };
    },
  },
  {
    path: '/admin/tenant-own-terms-of-service',
    name: 'adminTenantOwnTermsOfService',
    component: () => TenantOwnTermsOfServicePage,
  },
  {
    path: '/admin/tenant-own-terms-of-service-draft',
    name: 'adminTenantOwnTermsOfServiceDraft',
    component: () => TenantOwnTermsOfServiceDraftPage,
  },
];
