import { CommentId, ContentId, GroupId, Question, QuestionId, UserId } from '@/base/domains';
import { QuestionQueries } from '@/training/domains/queries';

export class InMemoryQuestionQueries implements QuestionQueries {
  async findRelatedQuestions(_: UserId): Promise<Array<Question>> {
    return [];
  }

  async findCommentHeaders(_: { groupId: GroupId }): Promise<
    {
      id: CommentId;
      questionId: QuestionId;
      groupId: GroupId;
      contentId?: ContentId;
      createdBy: UserId;
    }[]
  > {
    return [];
  }
}
