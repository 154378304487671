import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { AuthorizationService, GroupId, ScheduleTag, ScheduleTagDataAdapter } from '../domains';
import { AbstractUseCase, UseCase, UseCaseResponse } from './UseCase';

export interface GetScheduleTagsRequest {
  groupId: GroupId;
}

export type GetScheduleTagsResponse = {
  scheduleTags: Array<ScheduleTag>;
};

/**
 * スケジュールタグを取得する
 */
export interface GetScheduleTags extends UseCase<GetScheduleTagsRequest, GetScheduleTagsResponse> {
  execute(request: GetScheduleTagsRequest): Promise<UseCaseResponse<GetScheduleTagsResponse>>;
}

export class GetScheduleTagsImpl
  extends AbstractUseCase<GetScheduleTagsRequest, GetScheduleTagsResponse>
  implements GetScheduleTags
{
  private authorizationService: AuthorizationService;

  private scheduleTagDataAdapter: ScheduleTagDataAdapter;

  constructor(
    authorizationService: AuthorizationService,
    scheduleTagDataAdapter: ScheduleTagDataAdapter
  ) {
    super('base.GetScheduleTags');
    this.authorizationService = authorizationService;
    this.scheduleTagDataAdapter = scheduleTagDataAdapter;
  }

  async internalExecute(request: GetScheduleTagsRequest): Promise<GetScheduleTagsResponse> {
    const { groupId } = request;
    this.authorizationService.assertGroupReadAccessible(groupId);
    const scheduleTags = await this.scheduleTagDataAdapter.findScheduleTagsByGroupId(groupId);
    return {
      scheduleTags,
    };
  }
}

export const GetScheduleTagsKey = injectionKeyOf<GetScheduleTags>({
  boundedContext: 'base',
  type: 'usecase',
  name: 'GetScheduleTags',
});

export function useGetScheduleTags(): GetScheduleTags {
  return requiredInject(GetScheduleTagsKey);
}
