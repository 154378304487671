import { EditingCourseContentBodyData } from '@/contents/domains';

export const editingCourseContentBodies: Array<EditingCourseContentBodyData> = [
  {
    id: 'content-1-0',
    type: 'text',
    body: { body: '本文' },
    courseId: 'course-1',
  },
  {
    id: 'content-1-1',
    type: 'exam',
    body: {
      problems: [
        {
          index: 0,
          type: 'choice',
          body: '問題文',
          commentary: '解説',
          multiple: true,
          options: [
            { index: 0, text: '選択肢1' },
            { index: 1, text: '選択肢2' },
          ],
          answer: [0],
        },
      ],
      problemHeaders: [],
    },
    courseId: 'course-1',
  },
];
