import { assertIsDefined } from '@/utils/Asserts';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import {
  AppContextProvider,
  AuthorizationService,
  GroupId,
  GroupStatusService,
  GroupStatusUser,
} from '../domains';
import { AbstractUseCase, UseCase, UseCaseResponse } from './UseCase';

export type RaiseHandRequest = {
  handStatus: 'yes' | 'no';
  groupId: GroupId;
};

export type RaiseHandResponse = {
  groupStatusUser: GroupStatusUser;
};

export interface RaiseHand extends UseCase<RaiseHandRequest, RaiseHandResponse> {
  execute(request: RaiseHandRequest): Promise<UseCaseResponse<RaiseHandResponse>>;
}

export class RaiseHandImpl
  extends AbstractUseCase<RaiseHandRequest, RaiseHandResponse>
  implements RaiseHand
{
  constructor(
    private appContextProvider: AppContextProvider,
    private authorizationService: AuthorizationService,
    private groupStatusService: GroupStatusService
  ) {
    super('base.RaiseHand');
  }

  async internalExecute({ handStatus, groupId }: RaiseHandRequest): Promise<RaiseHandResponse> {
    this.authorizationService.assertGroupWriteAccessible(groupId);
    const userId = this.appContextProvider.get().user?.id;
    assertIsDefined(userId, 'appContext.user');
    const groupStatusUser = await this.groupStatusService.raiseHand({
      userId,
      handStatus,
      groupId,
    });
    return {
      groupStatusUser,
    };
  }
}

export const RaiseHandKey = injectionKeyOf<RaiseHand>({
  boundedContext: 'base',
  type: 'usecase',
  name: 'RaiseHand',
});

export function useRaiseHand(): RaiseHand {
  return requiredInject(RaiseHandKey);
}
