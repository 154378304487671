import {
  ContentId,
  ContentVersion,
  CourseColor,
  CourseDisplayName,
  CourseFontColorOnImage,
  CourseId,
  CourseName,
  CourseVersion,
  GroupId,
  GroupTrainingCourse,
  GroupTrainingId,
} from '@/base/domains';
import { URI } from '@/base/types';

import {
  GroupTrainingCourseContent,
  GroupTrainingCourseEntity,
  GroupTrainingCourseId,
} from './GroupTrainingCourse';

export type GroupTrainingCourseEntityImplArgs = {
  id: GroupTrainingCourseId;

  groupTrainingId: GroupTrainingId;

  courseId: CourseId;

  courseVersion: CourseVersion;

  courseName: CourseName;

  contents: Array<GroupTrainingCourseContent>;

  groupId: GroupId;

  color?: CourseColor;

  image?: URI;

  fontColorOnImage?: CourseFontColorOnImage;

  displayName: CourseDisplayName;

  index?: number;
};

export class GroupTrainingCourseEntityImpl implements GroupTrainingCourseEntity {
  id: GroupTrainingCourseId;

  groupTrainingId: GroupTrainingId;

  courseId: CourseId;

  courseVersion: CourseVersion;

  courseName: CourseName;

  contents: Array<GroupTrainingCourseContent>;

  groupId: GroupId;

  color?: CourseColor;

  image?: URI;

  fontColorOnImage?: CourseFontColorOnImage;

  displayName: CourseDisplayName;

  index?: number;

  constructor(args: GroupTrainingCourseEntityImplArgs) {
    this.id = args.id;
    this.groupTrainingId = args.groupTrainingId;
    this.courseId = args.courseId;
    this.courseVersion = args.courseVersion;
    this.courseName = args.courseName;
    this.contents = args.contents;
    this.groupId = args.groupId;
    this.color = args.color;
    this.image = args.image;
    this.fontColorOnImage = args.fontColorOnImage;
    this.displayName = args.displayName;
    this.index = args.index;
  }

  openContent(contentId: ContentId): GroupTrainingCourseEntity {
    return new GroupTrainingCourseEntityImpl({
      ...this,
      contents: this.contents.map((cn) => {
        if (cn.id === contentId) {
          return {
            ...cn,
            open: true,
          };
        }
        return cn;
      }),
    });
  }

  closeContent(contentId: ContentId): GroupTrainingCourseEntity {
    return new GroupTrainingCourseEntityImpl({
      ...this,
      contents: this.contents.map((cn) => {
        if (cn.id === contentId) {
          return {
            ...cn,
            open: false,
          };
        }
        return cn;
      }),
    });
  }

  changeDisplayName(displayName: CourseDisplayName): GroupTrainingCourseEntity {
    return new GroupTrainingCourseEntityImpl({
      ...this,
      displayName,
    });
  }

  changeContentVersion({
    contentId,
    contentVersion,
  }: {
    contentId: ContentId;
    contentVersion: ContentVersion;
  }): GroupTrainingCourseEntity {
    const content = this.contents.find((cn) => cn.id === contentId);
    if (!content) {
      throw new Error(
        `the content(id=${contentId}) should have been added in the course(id=${this.id})`
      );
    }
    return new GroupTrainingCourseEntityImpl({
      ...this,
      contents: this.contents.map((cn) =>
        cn.id === contentId
          ? {
              ...cn,
              version: contentVersion,
            }
          : cn
      ),
    });
  }

  toGroupTrainingCourse(): GroupTrainingCourse {
    return {
      id: this.id,
      name: this.courseName,
      displayName: this.displayName,
      version: this.courseVersion,
      contents: this.contents,
      index: this.index,
    };
  }
}
