import { GroupId, GroupTrainingFinder, QuestionFinder } from '@/base/domains';
import {
  AbstractUseCase,
  QuestionWithNames,
  UseCase,
  UseCaseResponse,
  withNamesConverter,
} from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

export interface GetQuestionsByAdminRequest {
  groupId: GroupId;
  resolved?: boolean;
}

export type GetQuestionsResponse = {
  questions: Array<QuestionWithNames>;
  limitExceeded: boolean;
};

/**
 * 質問リストを取得する
 */
export interface GetQuestionsByAdmin
  extends UseCase<GetQuestionsByAdminRequest, GetQuestionsResponse> {
  execute(request: GetQuestionsByAdminRequest): Promise<UseCaseResponse<GetQuestionsResponse>>;
}

export class GetQuestionsByAdminImpl
  extends AbstractUseCase<GetQuestionsByAdminRequest, GetQuestionsResponse>
  implements GetQuestionsByAdmin
{
  private questionFinder: QuestionFinder;

  private groupTrainingFinder: GroupTrainingFinder;

  constructor(questionFinder: QuestionFinder, groupTrainingFinder: GroupTrainingFinder) {
    super('home.GetQuestions');
    this.questionFinder = questionFinder;
    this.groupTrainingFinder = groupTrainingFinder;
  }

  async internalExecute(request: GetQuestionsByAdminRequest): Promise<GetQuestionsResponse> {
    const { groupId } = request;
    const [questionFinderFindResult, courses] = await Promise.all([
      this.questionFinder.find(request),
      this.groupTrainingFinder.findCoursesByGroupId(groupId),
    ]);
    const { questions, limitExceeded } = questionFinderFindResult;
    const names = new Map(
      courses.flatMap((cr) =>
        cr.contents.map((cn) => [
          cn.id,
          { contentName: cn.name, courseId: cr.id, courseName: cr.name },
        ])
      )
    );
    const converter = withNamesConverter(names);
    const questionsWithNames: Array<QuestionWithNames> = questions.map(converter);
    return {
      questions: questionsWithNames,
      limitExceeded,
    };
  }
}

export const GetQuestionsByAdminKey = injectionKeyOf<GetQuestionsByAdmin>({
  boundedContext: 'home',
  type: 'usecase',
  name: 'GetQuestionsByAdmin',
});

export function useGetQuestionsByAdmin(): GetQuestionsByAdmin {
  return requiredInject(GetQuestionsByAdminKey);
}
