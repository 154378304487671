import { computed, onMounted, Ref, ref } from '@vue/composition-api';

import { DisplaySettings, Locale, SignedInUser } from '@/base/domains';
import { Optional } from '@/base/types';
import {
  assertIsSucceeded,
  isSucceeded,
  useGetCurrentUser,
  useGetCurrentUserWhenAppStarted,
} from '@/base/usecases';
import { config } from '@/config';
import { createLogger } from '@/utils/log';
import { requiredNonNull } from '@/utils/TsUtils';
import { useLocalStorage } from '@/utils/VueUtils';

import { GlobalStoreGroup } from './types';

const logger = createLogger({ boundedContext: 'base', name: 'UserData' });

export type CurrentUserStatus = 'unchecked' | 'signedIn' | 'notSignedIn';

export function useUserData(groups: Ref<Array<GlobalStoreGroup>>) {
  const userGroups = (user: Ref<Optional<Omit<SignedInUser, 'groups'>>>) => {
    if (!user.value) {
      return [];
    }
    return groups.value
      .map((gr) => ({
        groupUser: gr.users.find((u1) => u1.id === user.value?.id),
        group: gr,
      }))
      .filter((a) => a.groupUser)
      .map((a) => ({
        id: a.group.id,
        role: requiredNonNull(a.groupUser).role,
        name: a.group.name,
        description: a.group.description,
      }));
  };

  const _currentUserStatus = ref<CurrentUserStatus>('unchecked');
  const currentUserStatus = computed<CurrentUserStatus>(() => _currentUserStatus.value);

  let _user: Ref<Optional<Omit<SignedInUser, 'groups'>>>;

  function setUser(u: Omit<SignedInUser, 'groups'>) {
    _user.value = u;
    _currentUserStatus.value = 'signedIn';
  }
  function clearUser() {
    _user.value = undefined;
    _currentUserStatus.value = 'notSignedIn';
  }

  if (config().repositoryType === 'amplify') {
    const getCurrentUserWhenAppStarted = useGetCurrentUserWhenAppStarted();
    _user = ref<Omit<SignedInUser, 'groups'>>();
    onMounted(async () => {
      const res = await getCurrentUserWhenAppStarted.execute({});
      if (isSucceeded(res)) {
        if (res.currentUser) {
          setUser(res.currentUser);
        } else {
          clearUser();
        }
      } else {
        clearUser();
      }

      logger.debug({
        message: 'set current user',
        _user,
      });
    });
  } else {
    _user = useLocalStorage<Omit<SignedInUser, 'groups'>>('base.globalStore.user', null);
    if (_user.value) {
      _currentUserStatus.value = 'signedIn';
    } else {
      _currentUserStatus.value = 'notSignedIn';
    }
  }

  const user = computed(() =>
    _user.value
      ? {
          ..._user.value,
          groups: userGroups(_user),
        }
      : undefined
  );

  const locale = computed<Locale>(() => user.value?.locale ?? 'ja');

  const displaySettings = computed<DisplaySettings>(
    () => user.value?.displaySettings ?? { theme: 'light', color: '#6600cc' }
  );

  const getCurrentUser = useGetCurrentUser();
  async function fetchUser() {
    const res = await getCurrentUser.execute({});
    assertIsSucceeded(res);
    if (res.currentUser) {
      setUser(res.currentUser);
    } else {
      clearUser();
    }
  }

  return {
    user,
    currentUserStatus,
    locale,
    displaySettings,
    fetchUser,
    setUser,
    clearUser,
  };
}
