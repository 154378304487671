import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { LocalDateTime, Optional } from '../types';
import { UserId } from './Core';
import { GroupId } from './Group';

export type QuestionnaireId = string;

export type QuestionnaireStatus = 'finished' | 'active';

export type QuestionnaireRespondent = {
  readonly questionnaireId: QuestionnaireId;
  readonly userId: UserId;
  readonly selectedIndex: number;
};

export type Questionnaire = {
  readonly id: QuestionnaireId;
  readonly groupId: GroupId;
  readonly title: string;
  readonly text?: string;
  readonly userIds: Array<UserId>;
  readonly createdBy: UserId;
  readonly createdAt: LocalDateTime;
  readonly options: Array<string>;
  readonly respondent: Array<QuestionnaireRespondent>;
  readonly status: QuestionnaireStatus;
  readonly finishedAt?: LocalDateTime;
};

export type QuestionnaireByAnswerer = {
  readonly id: QuestionnaireId;
  readonly groupId: GroupId;
  readonly title: string;
  readonly text?: string;
  readonly userIds: Array<UserId>;
  readonly createdBy: UserId;
  readonly createdAt: LocalDateTime;
  readonly options: Array<string>;
  readonly answered: boolean;
};

export type UnansweredQuestionnaire = {
  readonly id: QuestionnaireId;
  readonly groupId: GroupId;
  readonly title: string;
  readonly text?: string;
  readonly userIds: Array<UserId>;
  readonly createdBy: UserId;
  readonly createdAt: LocalDateTime;
  readonly options: Array<string>;
};

export interface QuestionnaireFinder {
  findById(id: QuestionnaireId): Promise<Optional<Questionnaire>>;
  findByGroupId(groupId: GroupId): Promise<Array<Questionnaire>>;
  findActiveQuestionnairesByGroupId(groupId: GroupId): Promise<Array<Questionnaire>>;
  findUnansweredQuestionnaires(
    groupId: GroupId,
    userId: UserId
  ): Promise<Array<UnansweredQuestionnaire>>;
  findQuestionnairesByAnswerer(
    groupId: GroupId,
    userId: UserId
  ): Promise<Array<QuestionnaireByAnswerer>>;
}

export const QuestionnaireFinderKey = injectionKeyOf<QuestionnaireFinder>({
  boundedContext: 'base',
  type: 'service',
  name: 'QuestionnaireFinder',
});

export function useQuestionnaireFinder(): QuestionnaireFinder {
  return requiredInject(QuestionnaireFinderKey);
}
