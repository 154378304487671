import { AuthService, UserId } from '@/base/domains';
import { AbstractUseCase, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

export interface ResendConfirmCodeRequest {
  userId: UserId;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export type ResendConfirmCodeResponse = {};

/**
 * 検証コードを再送する
 */
export interface ResendConfirmCode
  extends UseCase<ResendConfirmCodeRequest, ResendConfirmCodeResponse> {
  execute(request: ResendConfirmCodeRequest): Promise<UseCaseResponse<ResendConfirmCodeResponse>>;
}

export class ResendConfirmCodeImpl
  extends AbstractUseCase<ResendConfirmCodeRequest, ResendConfirmCodeResponse>
  implements ResendConfirmCode
{
  private authService: AuthService;

  constructor(authService: AuthService) {
    super('account.ResendConfirmCode');
    this.authService = authService;
  }

  async internalExecute(request: ResendConfirmCodeRequest): Promise<ResendConfirmCodeResponse> {
    await this.authService.resendSignUp(request.userId);
    return {};
  }
}

export const ResendConfirmCodeKey = injectionKeyOf<ResendConfirmCode>({
  boundedContext: 'account',
  type: 'usecase',
  name: 'ResendConfirmCode',
});

export function useResendConfirmCode(): ResendConfirmCode {
  return requiredInject(ResendConfirmCodeKey);
}
