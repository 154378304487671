import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { User, UserFinder } from '../domains';
import { AbstractUseCase, UseCase, UseCaseResponse } from './UseCase';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface GetUsersRequest {}

export type GetUsersResponse = {
  users: Array<User>;
};

/**
 * ユーザーリストを取得する
 */
export interface GetUsers extends UseCase<GetUsersRequest, GetUsersResponse> {
  execute(request: GetUsersRequest): Promise<UseCaseResponse<GetUsersResponse>>;
}

export class GetUsersImpl
  extends AbstractUseCase<GetUsersRequest, GetUsersResponse>
  implements GetUsers
{
  private userFinder: UserFinder;

  constructor(userFinder: UserFinder) {
    super('base.GetUsers');
    this.userFinder = userFinder;
  }

  async internalExecute(_: GetUsersRequest): Promise<GetUsersResponse> {
    const users = await this.userFinder.findTenantUsers();
    return {
      users,
    };
  }
}

export const GetUsersKey = injectionKeyOf<GetUsers>({
  boundedContext: 'base',
  type: 'usecase',
  name: 'GetUsers',
});

export function useGetUsers(): GetUsers {
  return requiredInject(GetUsersKey);
}
