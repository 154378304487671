import { User } from '@/API';
import {
  AuthorizationService,
  SignUpReservationQueries,
  TenantDataAdapter,
  UserId,
  UserRepository,
} from '@/base/domains';
import { AbstractUseCase, assertEntityExists, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

export type EnableUsersRequest = {
  userIds: Array<UserId>;
};

export type EnableUsersResponse = {
  users: Array<User>;
};

export interface EnableUsers extends UseCase<EnableUsersRequest, EnableUsersResponse> {
  execute(request: EnableUsersRequest): Promise<UseCaseResponse<EnableUsersResponse>>;
}

export class EnableUsersImpl
  extends AbstractUseCase<EnableUsersRequest, EnableUsersResponse>
  implements EnableUsers
{
  constructor(
    private userRepository: UserRepository,
    private authorizationService: AuthorizationService,
    private signUpReservationQueries: SignUpReservationQueries,
    private tenantDataAdapter: TenantDataAdapter
  ) {
    super('account.EnableUsers');
  }

  async internalExecute(request: EnableUsersRequest): Promise<EnableUsersResponse> {
    this.authorizationService.assertNotPlayground();
    this.authorizationService.assertRole('supervisor', 'admin');
    const { userIds } = request;
    const [enableUsers, signUpReservations, tenant] = await Promise.all([
      this.userRepository.findTenantEnabledUsers(),
      this.signUpReservationQueries.findTenantSignUpReservations(),
      this.tenantDataAdapter.get(),
    ]);
    if (
      tenant.userLimit &&
      tenant.userLimit < enableUsers.length + signUpReservations.length + userIds.length
    ) {
      throw new Error('number of users has exceeded the limit.');
    }
    const users = await Promise.all(
      userIds.map(async (userId) => {
        const user = await this.userRepository.findById(userId);
        assertEntityExists(user, 'user');
        return user;
      })
    );
    await Promise.all(users.map((user) => this.userRepository.save(user.enable())));

    return {
      users: [],
    };
  }
}

export const EnableUsersKey = injectionKeyOf<EnableUsers>({
  boundedContext: 'account',
  type: 'usecase',
  name: 'EnableUsers',
});

export function useEnableUsers(): EnableUsers {
  return requiredInject(EnableUsersKey);
}
