import {
  AppContextProvider,
  AuthorizationService,
  Content,
  ContentFinder,
  ContentId,
  ContentLearningDataAdapter,
  ContentName,
  ContentVersion,
  CourseColor,
  CourseDisplayName,
  CourseFontColorOnImage,
  CourseId,
  CourseVersion,
  Exam,
  GroupId,
  GroupTrainingCourse,
  GroupTrainingFinder,
  LearningStatus,
  Memo,
  Problem,
  ProblemHeaderId,
  Text,
  Workbook,
} from '@/base/domains';
import { Minute, URI } from '@/base/types';
import { AbstractUseCase, UseCase, UseCaseResponse } from '@/base/usecases';
import { assertIsDefined } from '@/utils/Asserts';
import { hasNonNullProperty } from '@/utils/TsUtils';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import {
  GroupTrainingCourseContent,
  MemoRepository,
  problemHashFromProblem,
  ReviewProblemRepository,
} from '../domains';

type GetContentByIdRequest = {
  id: ContentId;
  courseId: CourseId;
  groupId: GroupId;
  version?: ContentVersion;
};

type GetContentByNameRequest = {
  name: ContentName;
  courseDisplayName: CourseDisplayName;
  groupId: GroupId;
  version?: ContentVersion;
};

export type GetContentRequest = GetContentByNameRequest | GetContentByIdRequest;

function isGetContentByIdRequest(request: GetContentRequest): request is GetContentByIdRequest {
  return hasNonNullProperty(request, 'id');
}

export type GetContentCourse = {
  /** コースID */
  id: CourseId;
  /** コースバージョン */
  version: CourseVersion;
  /** コース名 */
  name: CourseDisplayName;
  /** コンテンツリスト */
  contents: Array<GetContentCourseContent>;
  /** カラー */
  color?: CourseColor;
  /** イメージ */
  image?: URI;
  /** イメージ上のフォントカラー */
  fontColorOnImage?: CourseFontColorOnImage;
};

export type GetContentContentWorkbook = Omit<Workbook, 'problems'> & {
  problems: (Problem & { isReviewProblemRegistered: boolean })[];
};

type GetContentTextContent = {
  /** ID */
  id: ContentId;
  /** バージョン */
  version: ContentVersion;
  /** 最新 */
  latest: boolean;
  /** コンテンツタイプ */
  type: 'text';
  /** 名前 */
  name: ContentName;
  /** 所要時間 */
  requiredTime: Minute;
  /** コンテンツ */
  body: Text;
  /** 練習問題 */
  workbook?: GetContentContentWorkbook;
  /** コースID */
  courseId: CourseId;
  /** コースバージョン */
  courseVersion: CourseVersion;
  /** 学習ステータス */
  learningStatus: LearningStatus;
  /** 公開 */
  open: boolean;
};

type GetContentExamContent = {
  /** ID */
  id: ContentId;
  /** バージョン */
  version: ContentVersion;
  /** 最新 */
  latest: boolean;
  /** コンテンツタイプ */
  type: 'exam';
  /** 名前 */
  name: ContentName;
  /** 所要時間 */
  requiredTime: Minute;
  /** コンテンツ */
  body: Exam;
  /** コースID */
  courseId: CourseId;
  /** コースバージョン */
  courseVersion: CourseVersion;
  /** 学習ステータス */
  learningStatus: LearningStatus;
  /** 公開 */
  open: boolean;
};

export type GetContentContent = GetContentTextContent | GetContentExamContent;

export type GetContentCourseContent = GroupTrainingCourseContent & {
  /** 学習ステータス */
  learningStatus: LearningStatus;
};

export type GetContentResponse = {
  content?: GetContentContent;
  course?: GetContentCourse;
  memos: Array<Memo>;
};

/**
 * コンテントを取得する
 */
export interface GetContent extends UseCase<GetContentRequest, GetContentResponse> {
  execute(request: GetContentRequest): Promise<UseCaseResponse<GetContentResponse>>;
}

export class GetContentImpl
  extends AbstractUseCase<GetContentRequest, GetContentResponse>
  implements GetContent
{
  constructor(
    private authorizationService: AuthorizationService,
    private groupTrainingFinder: GroupTrainingFinder,
    private contentFinder: ContentFinder,
    private contentLearningDataAdapter: ContentLearningDataAdapter,
    private appContextProvider: AppContextProvider,
    private memoRepository: MemoRepository,
    private reviewProblemRepository: ReviewProblemRepository
  ) {
    super('training.GetContent');
  }

  private async getGroupTrainingCourse(request: GetContentRequest): Promise<{
    groupTrainingCourse?: GroupTrainingCourse;
    groupTrainingCourseContent?: GroupTrainingCourseContent;
  }> {
    if (isGetContentByIdRequest(request)) {
      const { id, courseId, groupId } = request;
      const groupTrainingCourse = await this.groupTrainingFinder.findCourseByGroupIdAndCourseId(
        groupId,
        courseId
      );
      if (!groupTrainingCourse) {
        return {};
      }
      const groupTrainingCourseContent = groupTrainingCourse.contents.find((cn) => cn.id === id);
      return {
        groupTrainingCourse,
        groupTrainingCourseContent,
      };
    }
    const { name, courseDisplayName, groupId } = request;
    const groupTrainingCourse =
      await this.groupTrainingFinder.findCourseByGroupIdAndCourseDisplayName(
        groupId,
        courseDisplayName
      );

    if (groupTrainingCourse === undefined) {
      return {};
    }
    const groupTrainingCourseContent = groupTrainingCourse.contents.find((cn) => cn.name === name);
    return {
      groupTrainingCourse,
      groupTrainingCourseContent,
    };
  }

  private toResultContent({
    content,
    groupTrainingCourseContent,
    statusMap,
    course,
    reviewProblemHashes,
  }: {
    content: Content;
    groupTrainingCourseContent: GroupTrainingCourseContent;
    statusMap: Map<string, LearningStatus>;
    course: GetContentCourse;
    reviewProblemHashes: Set<string>;
  }): GetContentContent {
    if (content.type === 'exam') {
      const c: GetContentExamContent = {
        ...content,
        latest: groupTrainingCourseContent.version === content.version,
        learningStatus: statusMap.get(content.id) ?? 'not_begun',
        open: course.contents.find((ccn) => ccn.id === content.id)?.open ?? false,
      };
      return c;
    }

    const hashFromProblemAndHeader = (() => {
      const headerBodies: Map<ProblemHeaderId, string> = new Map(
        content.workbook?.problemHeaders?.map((h) => [h.id, h.body])
      );

      return (p: Problem) => {
        if (p.headerId) {
          const headerBody = headerBodies.get(p.headerId) ?? '';
          const appendLf = headerBody.length > 0 && p.body.length > 0;
          const body = `${headerBody}${appendLf ? '\n\n' : ''}${p.body}`;
          return problemHashFromProblem({
            body,
            answer: p.answer,
            options: p.options,
          });
        }
        return problemHashFromProblem(p);
      };
    })();
    const c: GetContentTextContent = {
      ...content,
      workbook: content.workbook
        ? {
            ...content.workbook,
            problems: content.workbook.problems.map((p) => ({
              ...p,
              isReviewProblemRegistered: reviewProblemHashes.has(hashFromProblemAndHeader(p)),
            })),
          }
        : undefined,
      latest: groupTrainingCourseContent.version === content.version,
      learningStatus: statusMap.get(content.id) ?? 'not_begun',
      open: course.contents.find((ccn) => ccn.id === content.id)?.open ?? false,
    };
    return c;
  }

  async internalExecute(request: GetContentRequest): Promise<GetContentResponse> {
    const { groupId, version } = request;
    this.authorizationService.assertGroupReadAccessible(groupId);

    const appContext = this.appContextProvider.get();
    const { user } = appContext;
    assertIsDefined(user, 'appContext.user');
    const groupRole = appContext.roleInGroup(groupId);
    const isTrainer =
      user.role === 'admin' ||
      user.role === 'supervisor' ||
      groupRole === 'trainer' ||
      groupRole === 'mentor';
    const userId = user.id;

    const { groupTrainingCourse, groupTrainingCourseContent } = await this.getGroupTrainingCourse(
      request
    );
    const contentIsOpen = groupTrainingCourseContent?.open;
    if (groupTrainingCourse === undefined) {
      return { memos: [] };
    }
    if (!isTrainer && !contentIsOpen) {
      return { memos: [] };
    }
    const contentLearnings = await this.contentLearningDataAdapter.findByGroupIdAndCourseId({
      groupId,
      courseId: groupTrainingCourse.id,
      userId,
    });

    const statusMap = new Map(contentLearnings.map((cl) => [cl.contentId, cl.status]));
    const course: GetContentCourse = {
      id: groupTrainingCourse.id,
      version: groupTrainingCourse.version,
      name: groupTrainingCourse.displayName,
      color: groupTrainingCourse.color,
      image: groupTrainingCourse.image,
      fontColorOnImage: groupTrainingCourse.fontColorOnImage,
      contents: groupTrainingCourse.contents
        .filter((cn) => cn.open || isTrainer)
        .map((cn) => ({
          ...cn,
          learningStatus: statusMap.get(cn.id) ?? 'not_begun',
        })),
    };

    if (!groupTrainingCourseContent) {
      return { course, memos: [] };
    }
    const cn = await this.contentFinder.findById(
      groupTrainingCourseContent.id,
      version ?? groupTrainingCourseContent.version
    );
    if (!cn) {
      return { course, memos: [] };
    }
    const getReviewProblems = async () => {
      if (cn.type === 'exam') {
        return new Set<string>();
      }
      const reviewProblem = await this.reviewProblemRepository.findMyReviewProblem({
        groupId,
        courseId: cn.courseId,
      });
      if (!reviewProblem) {
        return new Set<string>();
      }
      return new Set(
        reviewProblem.problems
          .filter((p) => p.origin.contentId === cn.id)
          .map((p) => problemHashFromProblem(p))
      );
    };
    const [memos, reviewProblemHashes] = await Promise.all([
      this.memoRepository.findByGroupAndContent(groupId, cn.id),
      getReviewProblems(),
    ]);
    const content = this.toResultContent({
      content: cn,
      groupTrainingCourseContent,
      statusMap,
      course,
      reviewProblemHashes,
    });
    return {
      content,
      course,
      memos,
    };
  }
}

export const GetContentKey = injectionKeyOf<GetContent>({
  boundedContext: 'contents',
  type: 'usecase',
  name: 'GetContent',
});

export function useGetContent(): GetContent {
  return requiredInject(GetContentKey);
}
