import {
  ActiveExam,
  CourseId,
  ExamDataAdapter,
  ExamDataAdapterFindExamResultsArgs,
  ExamDataAdapterFindNotFinishedUserExam,
  ExamId,
  ExamResult,
  GroupExam,
  GroupExamId,
  GroupId,
  MyExamResult,
  UserExam,
  UserId,
} from '@/base/domains';
import { Optional } from '@/base/types';
import { hasNonNullProperty, isDefined } from '@/utils/TsUtils';

export class InMemoryExamDataAdapter implements ExamDataAdapter {
  private examResults: Array<ExamResult>;

  constructor(examResults: Array<ExamResult>) {
    this.examResults = examResults;
  }

  findExamResults(args: ExamDataAdapterFindExamResultsArgs): Promise<ExamResult[]> {
    const { groupId } = args;
    const filter = (() => {
      const l: Array<(er: ExamResult) => boolean> = [];
      if (hasNonNullProperty(args, 'userId')) {
        l.push((er) => er.userId === args.userId);
      }
      if (isDefined(groupId)) {
        l.push((er) => er.groupId === groupId);
      }
      return (er: ExamResult) => l.every((f) => f(er));
    })();
    return Promise.resolve(this.examResults.filter(filter));
  }

  findExamResultById(id: ExamId): Promise<Optional<ExamResult>> {
    return Promise.resolve(this.examResults.find((e) => e.id === id));
  }

  findExamResultsByGroupExamId(groupExamId: GroupExamId): Promise<ExamResult[]> {
    return Promise.resolve(
      this.examResults.filter((e) => e.version === 2 && e.groupExamId === groupExamId)
    );
  }

  findActiveExam(): Promise<Optional<ActiveExam>> {
    return Promise.resolve(undefined);
  }

  findUserExam(
    _: ExamId
  ): Promise<Optional<UserExam & { groupExam: Omit<GroupExam, 'userExams'> }>> {
    return Promise.resolve(undefined);
  }

  findNotFinishedUserExams(_: {
    groupId?: GroupId;
    userId: UserId;
  }): Promise<ExamDataAdapterFindNotFinishedUserExam[]> {
    return Promise.resolve([]);
  }

  findGroupExamsByGroupId(_: GroupId): Promise<GroupExam[]> {
    return Promise.resolve([]);
  }

  findMyExamResults(_: GroupId): Promise<MyExamResult[]> {
    return Promise.resolve([]);
  }

  findMyExamResultById(_: ExamId): Promise<Optional<MyExamResult>> {
    return Promise.resolve(undefined);
  }

  findGroupExamsByGroupIdAndCourseId(_groupId: GroupId, _courseId: CourseId): Promise<GroupExam[]> {
    return Promise.resolve([]);
  }

  findGroupExamById(_: GroupExamId): Promise<Optional<GroupExam>> {
    return Promise.resolve(undefined);
  }

  findOpenGroupExamsByCourseId(_: CourseId): Promise<GroupExam[]> {
    return Promise.resolve([]);
  }
}
