import { AuthorizationService, CourseId, GroupId } from '@/base/domains';
import { AbstractUseCase, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { ReportService, UserCourseStatusRecord } from '../domains';

export interface GetUserCourseStatusRequest {
  groupId: GroupId;
  courseId: CourseId;
}

export type GetUserCourseStatusResponse = {
  records: Array<UserCourseStatusRecord>;
};

/**
 * ユーザーコースステータスを取得する
 */
export interface GetUserCourseStatus
  extends UseCase<GetUserCourseStatusRequest, GetUserCourseStatusResponse> {
  execute(
    request: GetUserCourseStatusRequest
  ): Promise<UseCaseResponse<GetUserCourseStatusResponse>>;
}

export class GetUserCourseStatusImpl
  extends AbstractUseCase<GetUserCourseStatusRequest, GetUserCourseStatusResponse>
  implements GetUserCourseStatus
{
  private authorizationService: AuthorizationService;

  private reportService: ReportService;

  constructor(authorizationService: AuthorizationService, reportService: ReportService) {
    super('report.GetUserCourseStatus');
    this.authorizationService = authorizationService;
    this.reportService = reportService;
  }

  async internalExecute(request: GetUserCourseStatusRequest): Promise<GetUserCourseStatusResponse> {
    const { groupId, courseId } = request;
    this.authorizationService.assertGroupReadAccessible(groupId);
    const records = await this.reportService.findUserCourseStatusRecords(groupId, courseId);
    return {
      records,
    };
  }
}

export const GetUserCourseStatusKey = injectionKeyOf<GetUserCourseStatus>({
  boundedContext: 'report',
  type: 'usecase',
  name: 'GetUserCourseStatus',
});

export function useGetUserCourseStatus(): GetUserCourseStatus {
  return requiredInject(GetUserCourseStatusKey);
}
