import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import {
  AuthorizationService,
  USER_TAGS_LIMITATION,
  UserId,
  UserReference,
  UserRepository,
  UserTagId,
  UserTagRepository,
} from '../domains';
import { USER_TAG_LIMITATION_EXCEEDED, USER_TAGS_DO_NOT_EXIST } from '../ErrorCodes';
import { AbstractUseCase, UseCase, UseCaseResponse } from './UseCase';

export type SetTagsToUsersRequest = {
  userIds: Array<UserId>;
  tagIds: Array<UserTagId>;
};

export type SetTagsToUsersResponse = {
  users: Array<UserReference>;
};

/**
 * 対象のすべてのユーザーにタグを設定する
 */
export interface SetTagsToUsers extends UseCase<SetTagsToUsersRequest, SetTagsToUsersResponse> {
  execute(request: SetTagsToUsersRequest): Promise<UseCaseResponse<SetTagsToUsersResponse>>;
}

export class SetTagsToUsersImpl
  extends AbstractUseCase<SetTagsToUsersRequest, SetTagsToUsersResponse>
  implements SetTagsToUsers
{
  constructor(
    private authorizationService: AuthorizationService,
    private userRepository: UserRepository,
    private userTagRepository: UserTagRepository
  ) {
    super('base.SetTagsToUsers');
  }

  async internalExecute(request: SetTagsToUsersRequest): Promise<SetTagsToUsersResponse> {
    const { userIds, tagIds } = request;
    this.authorizationService.assertRole('supervisor', 'admin');
    if (tagIds.length > USER_TAGS_LIMITATION) {
      throw USER_TAG_LIMITATION_EXCEEDED.toApplicationError();
    }
    if (tagIds.length > 0) {
      const tags = await this.userTagRepository.findTenantUserTags();
      const notExistTagIds = tagIds.filter((tagId) => !tags.find((tag) => tag.id === tagId));
      if (notExistTagIds.length > 0) {
        throw USER_TAGS_DO_NOT_EXIST.toApplicationError({
          tagIds: notExistTagIds,
        });
      }
    }
    const saved = await Promise.all(
      userIds.map((userId) => this.userRepository.replaceTags(userId, tagIds))
    );
    return { users: saved };
  }
}

export const SetTagsToUsersKey = injectionKeyOf<SetTagsToUsers>({
  boundedContext: 'base',
  type: 'usecase',
  name: 'SetTagsToUsers',
});

export function useSetTagsToUsers(): SetTagsToUsers {
  return requiredInject(SetTagsToUsersKey);
}
