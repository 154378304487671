import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { UserRepository } from '../domains';
import { AndroidDeviceToken } from '../domains/Device';
import { AbstractUseCase, UseCase, UseCaseResponse } from './UseCase';

export type AddUserDeviceRequest = {
  deviceType: 'android';
  deviceToken: AndroidDeviceToken;
};

export type AddUserDeviceResponse = {};

/**
 * ユーザーデバイスを追加する
 */
export interface AddUserDevice extends UseCase<AddUserDeviceRequest, AddUserDeviceResponse> {
  execute(request: AddUserDeviceRequest): Promise<UseCaseResponse<AddUserDeviceResponse>>;
}

export class AddUserDeviceImpl
  extends AbstractUseCase<AddUserDeviceRequest, AddUserDeviceResponse>
  implements AddUserDevice
{
  constructor(private userRepository: UserRepository) {
    super('base.AddUserDevice');
  }

  async internalExecute({
    deviceType,
    deviceToken,
  }: AddUserDeviceRequest): Promise<AddUserDeviceResponse> {
    await this.userRepository.addUserDevice({
      type: deviceType,
      token: deviceToken,
    });
    return {};
  }
}

export const AddUserDeviceKey = injectionKeyOf<AddUserDevice>({
  boundedContext: 'base',
  type: 'usecase',
  name: 'AddUserDevice',
});

export function useAddUserDevice(): AddUserDevice {
  return requiredInject(AddUserDeviceKey);
}
