import { CourseId } from '@/base/domains';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { EditingConfirmedContentReference } from '../EditingConfirmedContent';

export interface EditingConfirmedContentQueries {
  findTenantEditingConfirmedContents(): Promise<Array<EditingConfirmedContentReference>>;
  findByCourseId(courseId: CourseId): Promise<Array<EditingConfirmedContentReference>>;
}

export const EditingConfirmedContentQueriesKey = injectionKeyOf<EditingConfirmedContentQueries>({
  boundedContext: 'contents',
  type: 'adapter',
  name: 'EditingConfirmedContentQueries',
});

export function useEditingConfirmedContentQueries(): EditingConfirmedContentQueries {
  return requiredInject(EditingConfirmedContentQueriesKey);
}
