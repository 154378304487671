import {
  ContentId,
  ContentName,
  ContentVersion,
  CourseId,
  CourseVersion,
  Text,
  Workbook,
} from '@/base/domains';
import { Minute } from '@/base/types';
import { ifDefined } from '@/utils/TsUtils';

import { ContentEntity, ContentEntityUpdateArgs, TextContentEntity } from './Content';
import { WorkbookData, WorkbookImpl } from './Workbook';

export type TextContentEntityArgs = {
  id: ContentId;
  /** 最新 */
  latest: boolean;
  /** 名前 */
  name: ContentName;
  /** 所要時間 */
  requiredTime: Minute;
  /** コースID */
  courseId: CourseId;
  /** コースバージョン */
  courseVersion: CourseVersion;
  /** コンテンツタイプ */
  type: 'text';
  /** コンテンツの本体 */
  body: Text;
  /** 練習問題 */
  workbook?: WorkbookData;
  /** バージョン */
  version: ContentVersion;
  /** バージョン説明 */
  versionDescription?: string;
};

export class TextContentEntityImpl implements TextContentEntity {
  id: ContentId;

  latest: boolean;

  type!: 'text';

  name: ContentName;

  requiredTime: Minute;

  courseId: CourseId;

  courseVersion: CourseVersion;

  body: Text;

  workbook?: Workbook;

  version: ContentVersion;

  versionDescription?: string;

  constructor(args: TextContentEntityArgs) {
    this.id = args.id;
    this.latest = args.latest;
    this.type = args.type;
    this.body = args.body;
    this.name = args.name;
    this.requiredTime = args.requiredTime;
    this.body = args.body;
    this.courseId = args.courseId;
    this.courseVersion = args.courseVersion;
    this.workbook = ifDefined(args.workbook, (v) => new WorkbookImpl(v));
    this.version = args.version;
    this.versionDescription = args.versionDescription;
  }

  update(args: ContentEntityUpdateArgs): ContentEntity {
    return new TextContentEntityImpl({
      ...this,
      ...args,
    });
  }

  changeVersionDescription(versionDescription: string): ContentEntity {
    return new TextContentEntityImpl({
      ...this,
      versionDescription,
    });
  }
}
