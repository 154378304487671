import { RouteConfig } from 'vue-router';

const GroupExamResultHistoryPage = import(
  /* webpackChunkName: "report" */ './pages/GroupExamResultHistoryPage.vue'
);
const GroupUserExamResultHistoryPage = import(
  /* webpackChunkName: "report" */ './pages/GroupUserExamResultHistoryPage.vue'
);
const GroupUserExamResultPage = import(
  /* webpackChunkName: "report" */ './pages/GroupUserExamResultPage.vue'
);
const AdminUserUsagePage = import(
  /* webpackChunkName: "report" */ './pages/AdminUserUsagePage.vue'
);
const UserUsagePage = import(/* webpackChunkName: "report" */ './pages/UserUsagePage.vue');

const GroupQuestionnairesPage = import(
  /* webpackChunkName: "report" */ './pages/GroupQuestionnairesPage.vue'
);

const GroupQuestionnairePage = import(
  /* webpackChunkName: "report" */ './pages/GroupQuestionnairePage.vue'
);

export const reportRoutes: Array<RouteConfig> = [
  {
    path: '/groups/:id/report-exam-result-history',
    name: 'groupExamResultHistory',
    component: () => GroupUserExamResultHistoryPage,
    props: (route) => ({ ...route.query, ...route.params }),
  },
  {
    path: '/trainer/groups/:id/report-exam-result-history',
    name: 'trainerGroupExamResultHistory',
    component: () => GroupExamResultHistoryPage,
    props: (route) => ({ ...route.query, ...route.params }),
  },
  {
    path: '/groups/:id/report-user-exams/:examId',
    name: 'groupUserExamResult',
    component: () => GroupUserExamResultPage,
    props: (route) => ({ ...route.query, ...route.params }),
  },
  {
    path: '/trainer/groups/:id/report-questionnaires',
    name: 'trainerGroupQuestionnaires',
    component: () => GroupQuestionnairesPage,
    props: true,
  },
  {
    path: '/trainer/groups/:id/report-questionnaires/:questionnaireId',
    name: 'trainerGroupQuestionnaire',
    component: () => GroupQuestionnairePage,
    props: true,
  },
  {
    path: '/admin/groups/report-user',
    name: 'adminUserReport',
    component: () => AdminUserUsagePage,
  },
  {
    path: '/reports/user-usage',
    name: 'userReport',
    component: () => UserUsagePage,
  },
];
