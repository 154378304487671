import { Format, LogEntry, LogLevel, Transporter } from './core';

export class BrowserConsoleTransporter implements Transporter {
  format: Format;

  level: LogLevel;

  private logLevelMap = {
    debug: 'info',
    info: 'info',
    warn: 'warn',
    error: 'error',
  };

  constructor(args: { format: Format; level: LogLevel }) {
    this.format = args.format;
    this.level = args.level;
  }

  log(entry: LogEntry): void {
    const message = this.format(entry);
    console[this.logLevelMap[entry.level]](message);
  }
}
