import {
  AuthorizationService,
  ContentId,
  ContentName,
  ContentType,
  ContentVersion,
  CourseColor,
  CourseFontColorOnImage,
  CourseId,
  CourseName,
  CourseVersion,
  UserId,
} from '@/base/domains';
import { LocalDateTime, Minute, URI } from '@/base/types';
import { AbstractUseCase, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { CourseHeaderRepository, CourseRepository, CourseStatus, EditingStatus } from '../domains';
import {
  ContentQueries,
  EditingConfirmedContentQueries,
  EditingCourseQueries,
} from '../domains/queries';

export interface GetCourseForEditingRequest {
  id: CourseId;
}

export type GetCourseForEditingContent = {
  id: ContentId;
  type: ContentType;
  name: ContentName;
  requiredTime: Minute;
};

export type GetCourseForEditingConfirmedContent = {
  id: ContentId;
  type: ContentType;
  name: ContentName;
  requiredTime: Minute;
  hasEditingVersion: boolean;
  versions: Array<{
    version: ContentVersion;
    versionDescription?: string;
    status: EditingStatus;
  }>;
};

export type GetCourseForEditingEditingCourse = {
  type: 'editing_course';
  id: CourseId;
  name: CourseName;
  status: CourseStatus;
  editingVersion: CourseVersion;
  activeVersion: CourseVersion;
  contents: Array<GetCourseForEditingContent>;
  description?: string;
  color?: CourseColor;
  image?: URI;
  fontColorOnImage?: CourseFontColorOnImage;
  /** アクティブバージョンの確定ユーザー */
  confirmedBy?: UserId;
  /** アクティブバージョンの確定日時 */
  confirmedAt?: LocalDateTime;
  /** 編集中バージョンの作成ユーザー */
  versionCreatedBy: UserId;
  /** 編集中バージョンの作成日時 */
  versionCreatedAt: LocalDateTime;
  /** 編集中バージョンのコンテンツ最終更新ユーザー */
  contentLastUpdatedBy?: UserId;
  /** 編集中バージョンのコンテンツ最終更新日時 */
  contentLastUpdatedAt?: LocalDateTime;
};

export type GetCourseForEditingConfirmedCourse = {
  type: 'active_course';
  id: CourseId;
  name: CourseName;
  status: CourseStatus;
  activeVersion: CourseVersion;
  contents: Array<GetCourseForEditingConfirmedContent>;
  description?: string;
  color?: CourseColor;
  image?: URI;
  fontColorOnImage?: CourseFontColorOnImage;
  confirmedBy?: UserId;
  confirmedAt: LocalDateTime;
  versionCreatedBy?: UserId;
  versionCreatedAt?: LocalDateTime;
  contentLastUpdatedBy?: UserId;
  contentLastUpdatedAt?: LocalDateTime;
};

export type GetCourseForEditingCourse =
  | GetCourseForEditingEditingCourse
  | GetCourseForEditingConfirmedCourse;

export type GetCourseForEditingResponse = {
  course?: GetCourseForEditingCourse;
};

/**
 * 編集用コースを取得する
 */
export interface GetCourseForEditing
  extends UseCase<GetCourseForEditingRequest, GetCourseForEditingResponse> {
  execute(
    request: GetCourseForEditingRequest
  ): Promise<UseCaseResponse<GetCourseForEditingResponse>>;
}

export class GetCourseForEditingImpl
  extends AbstractUseCase<GetCourseForEditingRequest, GetCourseForEditingResponse>
  implements GetCourseForEditing
{
  constructor(
    private authorizationService: AuthorizationService,
    private courseHeaderRepository: CourseHeaderRepository,
    private editingCourseQueries: EditingCourseQueries,
    private courseRepository: CourseRepository,
    private editingConfirmedContentQueries: EditingConfirmedContentQueries,
    private contentQueries: ContentQueries
  ) {
    super('contents.GetCourseForEditing');
  }

  async internalExecute(request: GetCourseForEditingRequest): Promise<GetCourseForEditingResponse> {
    const { id } = request;
    this.authorizationService.assertContentEditable();
    const [course, editingCourse] = await Promise.all([
      this.courseHeaderRepository.findById(id),
      this.editingCourseQueries.findEditingCourseWithLastContentUpdatedById(id),
    ]);
    if (!course) {
      return {
        course: undefined,
      };
    }
    if (editingCourse) {
      if (course.status === 'editing') {
        return {
          course: {
            type: 'editing_course',
            id: course.id,
            name: course.name,
            status: course.status,
            activeVersion: course.activeVersion,
            editingVersion: editingCourse.version,
            description: editingCourse.description,
            contents: editingCourse.contents,
            color: editingCourse.color,
            image: editingCourse.image,
            fontColorOnImage: editingCourse.fontColorOnImage,
            versionCreatedBy: editingCourse.createdBy,
            versionCreatedAt: editingCourse.createdAt,
            contentLastUpdatedBy: editingCourse.contentLastUpdatedBy,
            contentLastUpdatedAt: editingCourse.contentLastUpdatedAt,
          },
        };
      }
      const activeCourse = await this.courseRepository.findById({
        id,
        version: course.activeVersion,
      });
      return {
        course: {
          type: 'editing_course',
          id: course.id,
          name: course.name,
          status: course.status,
          activeVersion: course.activeVersion,
          editingVersion: editingCourse.version,
          description: editingCourse.description,
          contents: editingCourse.contents,
          color: editingCourse.color,
          image: editingCourse.image,
          fontColorOnImage: editingCourse.fontColorOnImage,
          confirmedBy: activeCourse?.confirmedBy,
          confirmedAt: activeCourse?.confirmedAt,
          versionCreatedBy: editingCourse.createdBy,
          versionCreatedAt: editingCourse.createdAt,
          contentLastUpdatedBy: editingCourse.contentLastUpdatedBy,
          contentLastUpdatedAt: editingCourse.contentLastUpdatedAt,
        },
      };
    }
    const [activeCourse, editingConfirmedContents] = await Promise.all([
      this.courseRepository.findById({
        id,
        version: course.activeVersion,
      }),
      this.editingConfirmedContentQueries.findByCourseId(id),
    ]);
    if (!activeCourse) {
      return {
        course: undefined,
      };
    }
    if (course.status === 'editing') {
      return {
        course: undefined,
      };
    }
    const contentHeaders = await this.contentQueries.findContentHeadersByCourse({
      courseId: id,
      courseVersion: activeCourse.version,
    });
    const editingConfirmedContentIds = new Set(editingConfirmedContents.map((c) => c.id));
    const contentVersions = [
      ...editingConfirmedContents.map((cn) => ({
        id: cn.id,
        version: cn.version,
        status: 'editing' as EditingStatus,
        versionDescription: cn.versionDescription,
      })),
      ...contentHeaders.map((cn) => ({
        id: cn.id,
        version: cn.version,
        status: 'confirmed' as EditingStatus,
        versionDescription: cn.versionDescription,
      })),
    ].reduce(
      (acc, v) => {
        const array = acc.get(v.id) ?? [];
        acc.set(v.id, [...array, v]);
        return acc;
      },
      new Map<
        ContentId,
        Array<{
          version: ContentVersion;
          versionDescription?: string;
          status: EditingStatus;
        }>
      >()
    );
    const sortAndModifyContentVersions = (
      array: Array<{
        version: ContentVersion;
        versionDescription?: string;
        status: EditingStatus;
      }>
    ) => {
      const a0 = [...array];
      a0.sort((a, b) => a.version - b.version);
      return a0.map((a) => ({
        version: a.version,
        versionDescription: a.versionDescription,
        status: a.status,
      }));
    };
    return {
      course: {
        type: 'active_course',
        id: course.id,
        name: course.name,
        status: course.status,
        activeVersion: course.activeVersion,
        description: activeCourse.description,
        contents: activeCourse.contents.map((ac) => ({
          id: ac.id,
          name: ac.name,
          type: ac.type,
          requiredTime: ac.requiredTime,
          hasEditingVersion: editingConfirmedContentIds.has(ac.id),
          versions: sortAndModifyContentVersions(contentVersions.get(ac.id) ?? []),
        })),
        color: activeCourse.color,
        image: activeCourse.image,
        fontColorOnImage: activeCourse.fontColorOnImage,
        confirmedBy: activeCourse.confirmedBy,
        confirmedAt: activeCourse.confirmedAt,
        versionCreatedBy: activeCourse.versionCreatedBy,
        versionCreatedAt: activeCourse.versionCreatedAt,
        contentLastUpdatedBy: activeCourse.contentLastUpdatedBy,
        contentLastUpdatedAt: activeCourse.contentLastUpdatedAt,
      },
    };
  }
}

export const GetCourseForEditingKey = injectionKeyOf<GetCourseForEditing>({
  boundedContext: 'contents',
  type: 'usecase',
  name: 'GetCourseForEditing',
});

export function useGetCourseForEditing(): GetCourseForEditing {
  return requiredInject(GetCourseForEditingKey);
}
