import moment from 'moment';

import {
  CreateCommentInput,
  CreateQuestionInput,
  DeleteQuestionInput,
  ModelQuestionFilterInput,
  UpdateCommentInput,
  UpdateQuestionInput,
} from '@/API';
import {
  AppContextProvider,
  Comment,
  CommentId,
  ContentId,
  ContentVersion,
  GroupId,
  hasId,
  isQuestionWorkbookReference,
  isTextQuestionContentReference,
  QuestionContentReference,
  QuestionId,
  TenantCode,
  UserId,
} from '@/base/domains';
import { ISODateTimeString, LocalDateTime, MarkDownString, Optional } from '@/base/types';
import { assertEntityExists } from '@/base/usecases';
import {
  createComment,
  createQuestion,
  deleteQuestion,
  updateComment,
  updateQuestion,
} from '@/graphql/mutations';
import { getComment, getQuestion, questionsByGroup } from '@/graphql/queries';
import {
  CommentData,
  FindQuestionsRequest,
  QuestionData,
  QuestionEntity,
  QuestionEntityImpl,
  QuestionReference,
  QuestionRepository,
} from '@/training/domains';
import { graphql, graphqlQuery } from '@/utils/AmplifyUtils';
import { localDateTimeFromString } from '@/utils/DateUtils';
import {
  hasNonNullProperty,
  ifDefined,
  isDefined,
  isEmptyObject,
  requiredNonNull,
} from '@/utils/TsUtils';

type AmplifyTextContentReferenceOptions = {
  selectionJson: string;
};

type AmplifyWorkbookReferenceOptions = {
  selectionJson?: string;
  problemIndex: number;
};

type AmplifyContentReferenceOptions = {
  text?: AmplifyTextContentReferenceOptions;
  workbook?: AmplifyWorkbookReferenceOptions;
};

type AmplifyContentReference = {
  contentId: ContentId;
  contentVersion?: ContentVersion;
  options?: AmplifyContentReferenceOptions;
};

type AmplifyComment = {
  id: CommentId;
  questionId: QuestionId;
  body: MarkDownString;
  createdBy: UserId;
  createdAt: ISODateTimeString;
  editedBy?: UserId;
  editedAt?: ISODateTimeString;
  groupId: GroupId;
};

export type AmplifyQuestion = {
  id: QuestionId;
  comments: { items: Array<AmplifyComment> };
  resolved: boolean;
  resolvedAt?: ISODateTimeString;
  resolvedBy?: UserId;
  groupId: GroupId;
  title: string;
  referTo?: AmplifyContentReference;
  createdBy: UserId;
  createdAt: string;
  assignees?: Array<UserId>;
};

function toComment(comment: AmplifyComment): Comment {
  return {
    id: comment.id,
    body: comment.body,
    createdBy: comment.createdBy,
    createdAt: localDateTimeFromString(comment.createdAt),
    editedBy: comment.editedBy,
    editedAt: ifDefined(comment.editedAt, localDateTimeFromString),
    groupId: comment.groupId,
  };
}

function toContentReferTo(referTo?: AmplifyContentReference): Optional<QuestionContentReference> {
  if (referTo) {
    if (referTo.options) {
      if (referTo.options.text) {
        return {
          contentId: referTo.contentId,
          contentVersion: referTo.contentVersion ?? 1,
          selection: JSON.parse(referTo.options.text.selectionJson),
        };
      }
      if (referTo.options.workbook) {
        return {
          contentId: referTo.contentId,
          contentVersion: referTo.contentVersion ?? 1,
          problemIndex: referTo.options.workbook.problemIndex,
          selection: referTo.options.workbook.selectionJson
            ? JSON.parse(referTo.options.workbook.selectionJson)
            : undefined,
        };
      }
    }
    return {
      contentId: referTo.contentId,
      contentVersion: referTo.contentVersion ?? 1,
    };
  }
  return undefined;
}

export function toQuestionEntity(question: AmplifyQuestion): QuestionEntity {
  return new QuestionEntityImpl({
    id: question.id,
    comments: question.comments.items
      .map((c) => toComment(c))
      .sort((a, b) => a.createdAt.toDate().getTime() - b.createdAt.toDate().getTime()),
    resolved: question.resolved,
    resolvedAt: ifDefined(question.resolvedAt, localDateTimeFromString),
    resolvedBy: question.resolvedBy,
    groupId: question.groupId,
    title: question.title,
    referTo: toContentReferTo(question.referTo),
    createdBy: question.createdBy,
    createdAt: moment(question.createdAt),
    assignees: question.assignees ?? [],
  });
}

function toAmplifyContentReference(
  contentReference?: QuestionContentReference
): Optional<AmplifyContentReference> {
  if (contentReference) {
    if (isTextQuestionContentReference(contentReference)) {
      return {
        contentId: contentReference.contentId,
        contentVersion: contentReference.contentVersion,
        options: {
          text: {
            selectionJson: JSON.stringify(contentReference.selection),
          },
        },
      };
    }
    if (isQuestionWorkbookReference(contentReference)) {
      if (contentReference.selection) {
        return {
          contentId: contentReference.contentId,
          contentVersion: contentReference.contentVersion,
          options: {
            workbook: {
              selectionJson: JSON.stringify(contentReference.selection),
              problemIndex: contentReference.problemIndex,
            },
          },
        };
      }
      return {
        contentId: contentReference.contentId,
        contentVersion: contentReference.contentVersion,
        options: {
          workbook: {
            problemIndex: contentReference.problemIndex,
          },
        },
      };
    }
    return {
      contentId: contentReference.contentId,
      contentVersion: contentReference.contentVersion,
    };
  }
  return undefined;
}

async function createAmplifyComment(
  comment: CommentData,
  questionId: QuestionId,
  tenantCode: TenantCode,
  groupId: GroupId
): Promise<void> {
  const input: CreateCommentInput = {
    id: comment.id,
    questionId,
    body: comment.body,
    createdBy: comment.createdBy,
    tenantCode,
    groupId,
  };

  await graphql<{ createComment: AmplifyComment }>(createComment, { input });
}

export class AmplifyQuestionRepository implements QuestionRepository {
  constructor(private appContextProvider: AppContextProvider) {}

  async save(args: QuestionData | QuestionEntity): Promise<QuestionEntity> {
    const tenantCode = requiredNonNull(
      this.appContextProvider.get().tenantCode,
      'appContext.tenantCode'
    );

    const id = await (async () => {
      if (hasId(args)) {
        const input: UpdateQuestionInput = {
          id: args.id,
          createdBy: args.createdBy,
          resolved: args.resolved,
          groupId: args.groupId,
          title: args.title,
          resolvedAt: args.resolvedAt ? args.resolvedAt.toISOString() : null,
          resolvedBy: args.resolvedBy,
          referTo: toAmplifyContentReference(args.referTo),
          tenantCode,
          assignees: args.assignees,
        };
        await graphql<{ updateQuestion: AmplifyQuestion }>(updateQuestion, { input });
        return args.id;
      }
      const input: CreateQuestionInput = {
        createdBy:
          args.createdBy ??
          requiredNonNull(this.appContextProvider.get().user?.id, 'appContext.user.id'),
        resolved: args.resolved,
        resolvedAt: args.resolvedAt ? args.resolvedAt.toISOString() : null,
        resolvedBy: args.resolvedBy,
        groupId: args.groupId,
        title: args.title,
        referTo: toAmplifyContentReference(args.referTo),
        tenantCode,
        assignees: args.assignees,
      };
      const res = await graphql<{ createQuestion: AmplifyQuestion }>(createQuestion, { input });
      return res.createQuestion.id;
    })();
    const { comments } = args;

    await Promise.all(
      comments
        .filter((c) => !hasNonNullProperty(c, 'id'))
        .map((c) => createAmplifyComment(c, id, tenantCode, args.groupId))
    );
    const saved = await this.findById(id);
    assertEntityExists(saved, 'question');
    return saved;
  }

  async findById(id: string): Promise<Optional<QuestionEntity>> {
    const res = await graphql<{ getQuestion: AmplifyQuestion }>(getQuestion, { id });
    return res?.getQuestion ? toQuestionEntity(res.getQuestion) : undefined;
  }

  async remove(id: QuestionId): Promise<void> {
    // TODO コメントはDynamoDB Stream もしくは GraphQL subscribe で消す
    const input: DeleteQuestionInput = {
      id,
    };
    await graphql<{ deleteQuestion: AmplifyQuestion }>(deleteQuestion, {
      input,
    });
  }

  async findQuestions(
    request: FindQuestionsRequest
  ): Promise<{ questions: Array<QuestionReference>; limitExceeded: boolean }> {
    const LIMIT = 1000;
    const filter = (() => {
      const { createdBy, resolved } = request;
      const f: ModelQuestionFilterInput = {};
      if (isDefined(createdBy)) {
        f.createdBy = { eq: createdBy };
      }
      if (isDefined(resolved)) {
        f.resolved = { eq: resolved };
      }
      if (isEmptyObject(f)) {
        return undefined;
      }
      return f;
    })();
    const { groupId } = request;

    async function getData(
      list: Array<QuestionEntity> = [],
      nextToken?: string
    ): Promise<Array<QuestionReference>> {
      const res = await graphql<{
        questionsByGroup: { items: Array<AmplifyQuestion>; nextToken?: string };
      }>(questionsByGroup, {
        groupId,
        filter,
        nextToken,
        limit: LIMIT,
        sortDirection: 'DESC',
      });
      const questions = list.concat(res.questionsByGroup.items.map(toQuestionEntity) ?? []);
      if (questions.length >= LIMIT) {
        return questions;
      }
      if (isDefined(res.questionsByGroup.nextToken)) {
        return getData(questions, res.questionsByGroup.nextToken);
      }
      return questions;
    }

    const questions = await getData();
    return {
      questions,
      limitExceeded: questions.length > LIMIT,
    };
  }

  async findCommentById(commentId: CommentId): Promise<Optional<Comment>> {
    const res = await graphqlQuery<{ getComment?: AmplifyComment }>(getComment, { id: commentId });
    return ifDefined(res.getComment, toComment);
  }

  async changeCommentBody({
    commentId,
    body,
    editedAt,
  }: {
    commentId: CommentId;
    body: MarkDownString;
    editedAt: LocalDateTime;
  }): Promise<Comment> {
    const userId = requiredNonNull(this.appContextProvider.get().user?.id, 'appContext.user');
    const input: UpdateCommentInput = {
      id: commentId,
      body,
      editedAt: editedAt.toISOString(),
      editedBy: userId,
    };
    const res = await graphql<{ updateComment: AmplifyComment }>(updateComment, { input });
    return toComment(res.updateComment);
  }
}
