import { AuthorizationService, DateTimeService, GroupExam, GroupExamId } from '@/base/domains';
import { LocalDateTime } from '@/base/types';
import { AbstractUseCase, assertEntityExists, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { GroupExamRepository } from '../domains';

export type ChangeGroupExamScheduledTimeRequest = {
  id: GroupExamId;
  scheduledStart: LocalDateTime;
  scheduledFinish?: LocalDateTime;
};

export type ChangeGroupExamScheduledTimeResponse = {
  groupExam: GroupExam;
};

/**
 * グループテストの予約日時を変更する
 */
export interface ChangeGroupExamScheduledTime
  extends UseCase<ChangeGroupExamScheduledTimeRequest, ChangeGroupExamScheduledTimeResponse> {
  execute(
    request: ChangeGroupExamScheduledTimeRequest
  ): Promise<UseCaseResponse<ChangeGroupExamScheduledTimeResponse>>;
}

export class ChangeGroupExamScheduledTimeImpl
  extends AbstractUseCase<ChangeGroupExamScheduledTimeRequest, ChangeGroupExamScheduledTimeResponse>
  implements ChangeGroupExamScheduledTime
{
  constructor(
    private authorizationService: AuthorizationService,
    private groupExamRepository: GroupExamRepository,
    private dateTimeService: DateTimeService
  ) {
    super('training.ChangeGroupExamScheduledTime');
  }

  async internalExecute(
    request: ChangeGroupExamScheduledTimeRequest
  ): Promise<ChangeGroupExamScheduledTimeResponse> {
    const { id, scheduledStart, scheduledFinish } = request;
    const [groupExam, now] = await Promise.all([
      this.groupExamRepository.findById(id),
      this.dateTimeService.strictLocalDateTimeNow(),
    ]);
    assertEntityExists(groupExam, 'groupExam');
    this.authorizationService.assertTrainerAccessible(groupExam.groupId);
    const saved = await this.groupExamRepository.save(
      groupExam.changeScheduledTime({ scheduledStart, scheduledFinish, now })
    );
    return {
      groupExam: saved,
    };
  }
}

export const ChangeGroupExamScheduledTimeKey = injectionKeyOf<ChangeGroupExamScheduledTime>({
  boundedContext: 'training',
  type: 'usecase',
  name: 'ChangeGroupExamScheduledTime',
});

export function useChangeGroupExamScheduledTime(): ChangeGroupExamScheduledTime {
  return requiredInject(ChangeGroupExamScheduledTimeKey);
}
