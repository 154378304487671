import { AuthorizationService, ContentBody, ContentId, ContentName } from '@/base/domains';
import { Minute } from '@/base/types';
import { AbstractUseCase, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { ContentReference, ContentRepository, WorkbookData } from '../domains';

export type UpdateContentWorkbook = Omit<WorkbookData, 'id'>;

export interface UpdateContentRequest {
  id: ContentId;
  name: ContentName;
  requiredTime: Minute;
  content: ContentBody;
  workbook?: UpdateContentWorkbook;
}

export type UpdateContentResponse = {
  content: ContentReference;
};

/**
 * コンテントを更新する
 * @deprecated
 */
export interface UpdateContent extends UseCase<UpdateContentRequest, UpdateContentResponse> {
  execute(request: UpdateContentRequest): Promise<UseCaseResponse<UpdateContentResponse>>;
}

export class UpdateContentImpl
  extends AbstractUseCase<UpdateContentRequest, UpdateContentResponse>
  implements UpdateContent
{
  private authorizationService: AuthorizationService;

  private contentRepository: ContentRepository;

  constructor(authorizationService: AuthorizationService, contentRepository: ContentRepository) {
    super('contents.UpdateContent');
    this.authorizationService = authorizationService;
    this.contentRepository = contentRepository;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async internalExecute(request: UpdateContentRequest): Promise<UpdateContentResponse> {
    throw new Error('deprecated; remove soon.');
  }
}

export const UpdateContentKey = injectionKeyOf<UpdateContent>({
  boundedContext: 'admin',
  type: 'usecase',
  name: 'UpdateContent',
});

export function useUpdateContent(): UpdateContent {
  return requiredInject(UpdateContentKey);
}
