import { UserTagColor, UserTagEntity, UserTagId } from './UserTag';

export type UserTagEntityEntityImplArgs = {
  id: UserTagId;

  text: string;

  color: UserTagColor;
};

export class UserTagEntityImpl implements UserTagEntity {
  id: UserTagId;

  text: string;

  color: UserTagColor;

  constructor(args: UserTagEntityEntityImplArgs) {
    this.id = args.id;
    this.text = args.text;
    this.color = args.color;
  }

  change({ text, color }: { text: string; color: UserTagColor }): UserTagEntity {
    return new UserTagEntityImpl({
      ...this,
      text,
      color,
    });
  }
}
