import moment from 'moment';

import { GroupTrainingCourseData } from '@/training/domains';

export const groupTrainingCourses: Array<GroupTrainingCourseData> = [
  {
    groupTrainingId: 'gtId0',
    courseId: 'course-0',
    courseVersion: 1,
    courseName: 'Java入門',
    color: 'red',
    contents: [
      {
        id: 'content00-0',
        type: 'text',
        name: '1) Javaとは',
        requiredTime: 15,
        recommendedDateTime: moment().add(5, 'days').startOf('hour'),
        open: true,
        version: 1,
      },
      {
        id: 'content01-0',
        type: 'text',
        name: '2) メソッドとフィールド',
        requiredTime: 15,
        recommendedDateTime: moment().add(5, 'days').startOf('hour'),
        open: true,
        version: 1,
      },
      {
        id: 'content02-0',
        type: 'text',
        name: '3) リテラル',
        requiredTime: 15,
        recommendedDateTime: moment().add(7, 'days').startOf('hour'),
        open: true,
        version: 1,
      },
      {
        id: 'content03-0',
        type: 'exam',
        name: '4) 模擬試験A',
        requiredTime: 15,
        recommendedDateTime: moment().add(7, 'days').startOf('hour'),
        open: true,
        examStatus: 'announced',
        version: 1,
      },
    ],
    groupId: 'group00',
    displayName: 'Java入門',
  },
  {
    groupTrainingId: 'gtId0',
    courseId: 'course-12',
    courseVersion: 2,
    courseName: 'ビジネス基礎',
    image: 'https://images.pexels.com/photos/416160/pexels-photo-416160.jpeg',
    fontColorOnImage: 'white',
    contents: [
      {
        id: 'content120-0',
        type: 'text',
        name: 'コンテンツ1',
        requiredTime: 10,
        recommendedDateTime: moment().add(15, 'days').startOf('hour'),
        open: true,
        version: 1,
      },
    ],
    groupId: 'group00',
    displayName: 'ビジネス基礎',
  },
  {
    groupTrainingId: 'gtId0',
    courseId: 'course-13',
    courseVersion: 3,
    courseName: '無効化したコース',
    contents: [
      {
        id: 'content130-0',
        type: 'text',
        name: 'コンテンツ1',
        requiredTime: 10,
        recommendedDateTime: moment().add(-10, 'days').startOf('hour'),
        open: true,
        version: 1,
      },
    ],
    groupId: 'group00',
    displayName: 'ビジネス基礎',
  },
  {
    groupTrainingId: 'gtId1',
    courseId: 'course-0',
    courseVersion: 1,
    courseName: 'Java入門',
    contents: [
      {
        id: 'content00-0',
        type: 'text',
        name: '1) Javaとは',
        requiredTime: 15,
        recommendedDateTime: moment().add(5, 'days').startOf('hour'),
        open: true,
        version: 1,
      },
      {
        id: 'content01-0',
        type: 'text',
        name: '2) メソッドとフィールド',
        requiredTime: 15,
        recommendedDateTime: moment().add(5, 'days').startOf('hour'),
        open: true,
        version: 1,
      },
      {
        id: 'content02-0',
        type: 'text',
        name: '3) リテラル',
        requiredTime: 15,
        recommendedDateTime: moment().add(7, 'days').startOf('hour'),
        open: true,
        version: 1,
      },
      {
        id: 'content03-0',
        type: 'text',
        name: '4) 練習問題A',
        requiredTime: 15,
        recommendedDateTime: moment().add(7, 'days').startOf('hour'),
        open: true,
        version: 1,
      },
    ],
    groupId: 'group01',
    displayName: 'Java入門',
  },
];
