import {
  AppContextProvider,
  Email,
  Role,
  SignUpReservation,
  SignUpReservationGroup,
  SignUpReservationId,
  SignUpReservationQueries,
  SignUpReservationStatus,
  TenantCode,
  UserCode,
  UserName,
} from '@/base/domains';
import { Optional } from '@/base/types';
import {
  signUpReservationByKey,
  signUpReservationsByEmail,
  signUpReservationsByTenantCode,
} from '@/graphql/queries';
import { graphqlQuery } from '@/utils/AmplifyUtils';
import { requiredNonNull } from '@/utils/TsUtils';

type AmplifySignUpReservation = {
  id: SignUpReservationId;
  name?: UserName;
  userCode?: UserCode;
  email?: Email;
  role: Role;
  status: SignUpReservationStatus;
  enabled: boolean;
  groups?: SignUpReservationGroup[];
};

function toSignUpReservation(signUpReservation: AmplifySignUpReservation): SignUpReservation {
  return {
    id: signUpReservation.id,
    name: signUpReservation.name,
    userCode: signUpReservation.userCode,
    email: signUpReservation.email,
    role: signUpReservation.role.toLocaleLowerCase() as Role,
    status: signUpReservation.status.toLocaleLowerCase() as SignUpReservationStatus,
    enabled: signUpReservation.enabled,
    groups: signUpReservation.groups ?? [],
  };
}

export class AmplifySignUpReservationQueries implements SignUpReservationQueries {
  appContextProvider: AppContextProvider;

  constructor(appContextProvider: AppContextProvider) {
    this.appContextProvider = appContextProvider;
  }

  async findByEmail(email: Email): Promise<Optional<SignUpReservation>> {
    const res = await graphqlQuery<{
      signUpReservationsByEmail: { items: AmplifySignUpReservation[] };
    }>(
      signUpReservationsByEmail,
      {
        email,
      },
      { publicAccess: true }
    );
    return res.signUpReservationsByEmail.items[0]
      ? toSignUpReservation(res.signUpReservationsByEmail.items[0])
      : undefined;
  }

  async findByUserCode(
    userCode: UserCode,
    tenantCode: TenantCode
  ): Promise<Optional<SignUpReservation>> {
    const key = `${tenantCode}/${userCode}`;
    const res = await graphqlQuery<{
      signUpReservationByKey: { items: AmplifySignUpReservation[] };
    }>(
      signUpReservationByKey,
      {
        key,
      },
      { publicAccess: true }
    );
    return res.signUpReservationByKey.items[0]
      ? toSignUpReservation(res.signUpReservationByKey.items[0])
      : undefined;
  }

  async findTenantSignUpReservations(): Promise<SignUpReservation[]> {
    const tenantCode = requiredNonNull(
      this.appContextProvider.get().tenantCode,
      'appContext.tenantCode'
    );
    const res = await graphqlQuery<{
      signUpReservationsByTenantCode: { items: Array<AmplifySignUpReservation> };
    }>(
      signUpReservationsByTenantCode,
      {
        tenantCode,
      },
      {
        limit: 1000,
      }
    );
    return res.signUpReservationsByTenantCode.items.map(toSignUpReservation) ?? [];
  }
}
