export default {
  badge: 'Huy hiệu',
  close: 'Đóng',
  dataIterator: {
    noResultsText: 'Không tìm thấy kết quả tìm kiếm',
    loadingText: 'Đang tải mục...',
  },
  dataTable: {
    itemsPerPageText: 'Số dòng trên mỗi trang：',
    ariaLabel: {
      sortDescending: 'Sắp xếp theo thứ tự giảm dần.',
      sortAscending: 'Sắp xếp theo thứ tự tăng dần.',
      sortNone: 'Không được sắp xếp.',
      activateNone: 'Vui lòng kích hoạt nó để loại bỏ sự sắp xếp.',
      activateDescending: 'Vui lòng kích hoạt, để sắp xếp theo thứ tự giảm dần.',
      activateAscending: 'Vui lòng kích hoạt, để sắp xếp theo thứ tự tăng dần.',
    },
    sortBy: 'Phương pháp sắp xếp',
  },
  dataFooter: {
    itemsPerPageText: 'Số trường hợp trên mỗi trang：',
    itemsPerPageAll: 'Toàn bộ',
    nextPage: 'Trang sau',
    prevPage: 'Trang trước',
    firstPage: 'Trang đầu tiên',
    lastPage: 'Trang cuối',
    pageText: 'Tổng số trang từ {0}-{1}  / Trang thứ {2}',
  },
  datePicker: {
    itemsSelected: 'Chọn ngày{0}',
    nextMonthAriaLabel: 'Tháng sau',
    nextYearAriaLabel: 'Năm sau',
    prevMonthAriaLabel: 'Tháng trước',
    prevYearAriaLabel: 'Năm trước',
  },
  noDataText: 'Không có dữ liệu.',
  carousel: {
    prev: 'Xem trang trước',
    next: 'Xem trang sau',
    ariaLabel: {
      delimiter: 'Hiệu ứng trượt ảnh số {0} / số{1}',
    },
  },
  calendar: {
    moreEvents: 'Thêm nữa {0}',
  },
  fileInput: {
    counter: '{0} Tập tin',
    counterSize: '{0} Tập tin (Tổng {1})',
  },
  timePicker: {
    am: 'AM',
    pm: 'PM',
  },
  pagination: {
    ariaLabel: {
      wrapper: 'Phân trang, điều hướng nhiều chiều',
      next: 'Trang sau',
      previous: 'Trang trước',
      page: 'Chuyển đến trang {0}',
      currentPage: 'Trang hiện tại, trang {0}',
    },
  },
  rating: {
    ariaLabel: {
      icon: 'Trong số những đánh giá {1} có {0}',
    },
  },
};
