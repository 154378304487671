import { AuthorizationService, CourseId, GroupId } from '@/base/domains';
import { AbstractUseCase, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { UserExamRecord } from '../domains';
import { ReportService } from '../domains/ReportService';

export interface GetUserExamReportRequest {
  groupId: GroupId;
  courseId: CourseId;
}

export type GetUserExamReportResponse = {
  records: Array<UserExamRecord>;
};

/**
 * ユーザーテスト結果レポートを取得する
 */
export interface GetUserExamReport
  extends UseCase<GetUserExamReportRequest, GetUserExamReportResponse> {
  execute(request: GetUserExamReportRequest): Promise<UseCaseResponse<GetUserExamReportResponse>>;
}

export class GetUserExamReportImpl
  extends AbstractUseCase<GetUserExamReportRequest, GetUserExamReportResponse>
  implements GetUserExamReport
{
  private authorizationService: AuthorizationService;

  private reportService: ReportService;

  constructor(authorizationService: AuthorizationService, reportService: ReportService) {
    super('report.GetUserExamReport');
    this.authorizationService = authorizationService;
    this.reportService = reportService;
  }

  async internalExecute(request: GetUserExamReportRequest): Promise<GetUserExamReportResponse> {
    const { groupId, courseId } = request;
    this.authorizationService.assertGroupReportAccessible(groupId);
    const records = await this.reportService.findUserExamRecords(groupId, courseId);
    return {
      records,
    };
  }
}

export const GetUserExamReportKey = injectionKeyOf<GetUserExamReport>({
  boundedContext: 'report',
  type: 'usecase',
  name: 'GetUserExamReport',
});

export function useGetUserExamReport(): GetUserExamReport {
  return requiredInject(GetUserExamReportKey);
}
