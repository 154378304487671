import { Group, GroupId, GroupLimitation, GroupRepository, localEventBus } from '@/base/domains';
import { groupLimitationsChanged } from '@/base/domains/LocalEvents';
import { AbstractUseCase, assertEntityExists, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

export interface ChangeGroupLimitationsRequest {
  id: GroupId;
  limitations: GroupLimitation;
}

export type ChangeGroupLimitationsResponse = {
  group: Group;
};

export interface ChangeGroupLimitations
  extends UseCase<ChangeGroupLimitationsRequest, ChangeGroupLimitationsResponse> {
  execute(
    request: ChangeGroupLimitationsRequest
  ): Promise<UseCaseResponse<ChangeGroupLimitationsResponse>>;
}

export class ChangeGroupLimitationsImpl
  extends AbstractUseCase<ChangeGroupLimitationsRequest, ChangeGroupLimitationsResponse>
  implements ChangeGroupLimitations
{
  constructor(private groupRepository: GroupRepository) {
    super('admin.ChangeGroupLimitations');
    this.groupRepository = groupRepository;
  }

  async internalExecute(
    request: ChangeGroupLimitationsRequest
  ): Promise<ChangeGroupLimitationsResponse> {
    const { id, limitations } = request;
    const group = await this.groupRepository.findById(id);
    assertEntityExists(group, 'group');
    const saved = await this.groupRepository.save(group.changeLimitations(limitations));
    localEventBus.publish(groupLimitationsChanged({ groupId: id, limitations }));
    return {
      group: saved,
    };
  }
}

export const ChangeGroupLimitationsKey = injectionKeyOf<ChangeGroupLimitations>({
  boundedContext: 'admin',
  type: 'usecase',
  name: 'ChangeGroupLimitations',
});

export function useChangeGroupLimitations(): ChangeGroupLimitations {
  return requiredInject(ChangeGroupLimitationsKey);
}
