import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { GroupId, Questionnaire, UserId } from '../domains';
import { UseCase, UseCaseResponse } from './UseCase';

export type CreateQuestionnaireRequest = {
  groupId: GroupId;
  title: string;
  userIds: Array<UserId>;
  text?: string;
  options: Array<string>;
};

export type CreateQuestionnaireResponse = {
  questionnaire: Questionnaire;
};

export interface CreateQuestionnaire
  extends UseCase<CreateQuestionnaireRequest, CreateQuestionnaireResponse> {
  execute(
    request: CreateQuestionnaireRequest
  ): Promise<UseCaseResponse<CreateQuestionnaireResponse>>;
}

export const CreateQuestionnaireKey = injectionKeyOf<CreateQuestionnaire>({
  boundedContext: 'training',
  type: 'usecase',
  name: 'CreateQuestionnaire',
});

export function useCreateQuestionnaire(): CreateQuestionnaire {
  return requiredInject(CreateQuestionnaireKey);
}
