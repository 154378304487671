import {
  AppContextProvider,
  AuthorizationService,
  ContentLearning,
  ContentLearningDataAdapter,
  CourseColor,
  CourseDisplayName,
  CourseFontColorOnImage,
  CourseId,
  ExamDataAdapter,
  GroupId,
  GroupTrainingFinder,
  ReviewProblemFinder,
} from '@/base/domains';
import { LocalDateTime, Optional, URI } from '@/base/types';
import { AbstractUseCase, UseCase, UseCaseResponse } from '@/base/usecases';
import { isDefined, requiredNonNull } from '@/utils/TsUtils';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

export type GetCoursesRequest = {
  groupId: GroupId;
};

export type GetCoursesCourse = {
  /** コースID */
  readonly id: CourseId;
  /** コース名 */
  readonly name: CourseDisplayName;
  /** 学習中 */
  readonly isLearning: boolean;
  /** テスト開催中 */
  readonly isExamOpen: boolean;
  /** テスト予約 */
  readonly isExamScheduled: boolean;
  /** コンテンツ件数 */
  readonly contentsCount: number;
  /** 完了コンテンツ件数 */
  readonly completedContentsCount: number;
  /** グループID */
  readonly groupId: GroupId;
  /** コースカラー */
  readonly courseColor?: CourseColor;
  /** コースイメージ */
  readonly courseImage?: URI;
  /** コース画像上のフォントカラー */
  readonly courseFontColorOnImage?: CourseFontColorOnImage;
  /** 最終学習日時 */
  readonly lastLearnedAt?: LocalDateTime;
  /** コース順のインデックス */
  readonly index?: number;
  /** 復習問題の件数 */
  readonly reviewProblemCount: number;
};

export type GetCoursesResponse = {
  courses: Array<GetCoursesCourse>;
};

/**
 * モバイルホームのコンテンツを取得する。
 */
export interface GetCourses extends UseCase<GetCoursesRequest, GetCoursesResponse> {
  execute(request: GetCoursesRequest): Promise<UseCaseResponse<GetCoursesResponse>>;
}

export class GetCoursesImpl
  extends AbstractUseCase<GetCoursesRequest, GetCoursesResponse>
  implements GetCourses
{
  constructor(
    private appContextProvider: AppContextProvider,
    private groupTrainingFinder: GroupTrainingFinder,
    private contentLearningDataAdapter: ContentLearningDataAdapter,
    private examDataAdapter: ExamDataAdapter,
    private authorizationService: AuthorizationService,
    private reviewProblemFinder: ReviewProblemFinder
  ) {
    super('home.GetCourses');
  }

  async internalExecute({ groupId }: GetCoursesRequest): Promise<GetCoursesResponse> {
    const appContext = this.appContextProvider.get();
    const user = requiredNonNull(appContext.user, 'appContext.user');
    const getTraineeExamsOpen = async () => {
      const traineeExams = await this.examDataAdapter.findNotFinishedUserExams({
        userId: user.id,
        groupId,
      });
      const examOpenByGroupAndCourse = traineeExams.reduce((acc, v) => {
        const key = `${v.groupExam.groupId}#${v.groupExam.course.id}#${v.groupExam.status}`;
        acc.set(key, true);
        return acc;
      }, new Map<CourseId, boolean>());
      return examOpenByGroupAndCourse;
    };
    const getTrainerExamsOpen = async () => {
      const trainerExams = await this.examDataAdapter.findGroupExamsByGroupId(groupId, {
        inProgressOrScheduled: true,
      });
      const examOpenByGroupAndCourse = trainerExams.reduce((acc, v) => {
        const key = `${v.groupId}#${v.course.id}#${v.status}`;
        acc.set(key, true);
        return acc;
      }, new Map<CourseId, boolean>());
      return examOpenByGroupAndCourse;
    };
    this.authorizationService.assertGroupReadAccessible(groupId);
    const [
      groupTrainingCourses,
      contentLearnings,
      notFinishedExamsByGroupAndCourse,
      reviewProblems,
    ] = await Promise.all([
      this.groupTrainingFinder.findCoursesByGroupId(groupId),
      this.contentLearningDataAdapter.findByGroupIdAndUserId(groupId, user.id),
      appContext.roleInGroup(groupId) === 'trainee' ? getTraineeExamsOpen() : getTrainerExamsOpen(),
      this.reviewProblemFinder.findMyReviewProblems({ groupId }),
    ]);
    const reviewProblemsMap: Map<CourseId, number> = new Map(
      reviewProblems.map((rp) => [rp.courseId, rp.problems.length])
    );
    const contentIdsInCourse = new Set(
      groupTrainingCourses
        .flat()
        .flatMap((gtc) => gtc.contents)
        .map((c) => c.id)
    );
    const contentLearningsInGroup = contentLearnings.filter((cl) =>
      contentIdsInCourse.has(cl.contentId)
    );
    const courseLeanings = (() => {
      const contentLearningByGroupAndCourse = contentLearningsInGroup.reduce((acc, v) => {
        const { courseId } = v;
        const key = `${groupId}#${courseId}`;
        const learnings = acc.get(key) ?? [];
        acc.set(key, [...learnings, v]);
        return acc;
      }, new Map<string, Array<ContentLearning>>());
      const groupCourseLearningArray = Array.from(contentLearningByGroupAndCourse.entries()).map(
        (e) =>
          e[1].reduce(
            (acc, v) => ({
              key: e[0],
              isLearning: acc.isLearning || v.status === 'in_progress' || v.status === 'completed',
              completedContentsCount:
                v.status === 'completed'
                  ? acc.completedContentsCount + 1
                  : acc.completedContentsCount,
              lastLearnedAt:
                !isDefined(acc.lastLearnedAt) || v.lastLearnedAt?.isAfter(acc.lastLearnedAt)
                  ? v.lastLearnedAt
                  : acc.lastLearnedAt,
            }),
            {
              key: e[0],
              isLearning: false,
              completedContentsCount: 0,
              lastLearnedAt: undefined as Optional<LocalDateTime>,
            }
          )
      );
      return new Map(
        groupCourseLearningArray.map((e) => [
          e.key,
          {
            isLearning: e.isLearning,
            completedContentsCount: e.completedContentsCount,
            lastLearnedAt: e.lastLearnedAt,
          },
        ])
      );
    })();

    const coursesSort = (x: GetCoursesCourse, y: GetCoursesCourse) => {
      if (x.index !== undefined && y.index !== undefined) return x.index - y.index;
      return (y.lastLearnedAt?.unix() ?? 0) - (x.lastLearnedAt?.unix() ?? 0);
    };

    const courses: GetCoursesCourse[] = groupTrainingCourses
      .flat()
      .map((course) => {
        const courseLearning = courseLeanings.get(`${groupId}#${course.id}`);
        const completedContentsCount = courseLearning?.completedContentsCount ?? 0;
        const contentsCount = course.contents.length;
        const isLearning =
          (courseLearning?.isLearning ?? false) && contentsCount !== completedContentsCount;
        return {
          id: course.id,
          name: course.displayName,
          courseColor: course.color,
          courseImage: course.image,
          courseFontColorOnImage: course.fontColorOnImage,
          isLearning,
          isExamOpen:
            notFinishedExamsByGroupAndCourse.get(`${groupId}#${course.id}#in_progress`) ?? false,
          isExamScheduled:
            notFinishedExamsByGroupAndCourse.get(`${groupId}#${course.id}#announced`) ?? false,
          contentsCount,
          completedContentsCount,
          groupId,
          lastLearnedAt: courseLearning?.lastLearnedAt,
          index: course.index,
          reviewProblemCount: reviewProblemsMap.get(course.id) ?? 0,
        };
      })
      .sort(coursesSort);
    return {
      courses,
    };
  }
}

export const GetCoursesKey = injectionKeyOf<GetCourses>({
  boundedContext: 'home',
  type: 'usecase',
  name: 'GetCourses',
});

export function useGetCourses(): GetCourses {
  return requiredInject(GetCoursesKey);
}
