import { usePlaygroundRepository } from '@/playground/domains';
import {
  GetPlaygroundImpl,
  GetPlaygroundKey,
  RequestToCreatePlaygroundImpl,
  RequestToCreatePlaygroundKey,
  StartPlaygroundCreatingImpl,
  StartPlaygroundCreatingKey,
  SubscribePlaygroundCreatedImpl,
  SubscribePlaygroundCreatedKey,
} from '@/playground/usecases';
import { provide } from '@/utils/VueUtils';

export function usePlaygroundUseCases() {
  const playgroundRepository = usePlaygroundRepository();
  provide(RequestToCreatePlaygroundKey, new RequestToCreatePlaygroundImpl(playgroundRepository));
  provide(StartPlaygroundCreatingKey, new StartPlaygroundCreatingImpl(playgroundRepository));
  provide(SubscribePlaygroundCreatedKey, new SubscribePlaygroundCreatedImpl(playgroundRepository));
  provide(GetPlaygroundKey, new GetPlaygroundImpl(playgroundRepository));
}
