import { computed } from '@vue/composition-api';

const buildNumber = process.env.VUE_APP_BUILD_NUMBER ?? 0;
const version = process.env.PACKAGE_VERSION ?? '0.0.0';

export function useVersion() {
  return {
    version: computed(() => version),
    buildNumber: computed(() => buildNumber),
  };
}
