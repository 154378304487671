import moment from 'moment';

import { TenantOwnTermsOfServiceType as AmplifyTenantOwnTermsOfServiceType } from '@/API';
import { AppContextProvider, TenantCode, TenantOwnTermsOfService, UserId } from '@/base/domains';
import { TenantOwnTermsOfServiceQueries } from '@/base/domains/queries';
import { LocalDateTime, MarkDownString, Optional } from '@/base/types';
import * as queries from '@/graphql/queries';
import { graphql } from '@/utils/AmplifyUtils';
import { requiredNonNull } from '@/utils/TsUtils';

type AmplifyTenantOwnTermsOfService = {
  id: string;
  tenantCode: TenantCode;
  type: AmplifyTenantOwnTermsOfServiceType;
  body: MarkDownString;
  createdBy: UserId;
  createdAt: LocalDateTime;
  updatedAt?: LocalDateTime;
  version?: number;
};

function toTenantOwnTermsOfService(
  tenantOwnTermsOfService: AmplifyTenantOwnTermsOfService
): TenantOwnTermsOfService {
  return {
    tenantCode: tenantOwnTermsOfService.tenantCode,
    body: tenantOwnTermsOfService.body,
    version: tenantOwnTermsOfService.version ?? 1,
    createdAt: moment(tenantOwnTermsOfService.createdAt),
    createdBy: tenantOwnTermsOfService.createdBy,
  };
}
export class AmplifyTenantOwnTermsOfServiceQueries implements TenantOwnTermsOfServiceQueries {
  constructor(private appContextProvider: AppContextProvider) {}

  async findTenantOwnTermsOfService(): Promise<Optional<TenantOwnTermsOfService>> {
    const tenantCode = requiredNonNull(
      this.appContextProvider.get().tenantCode,
      'appContext.tenantCode'
    );
    const res = await graphql<{ getTenantOwnTermsOfService: AmplifyTenantOwnTermsOfService }>(
      queries.getTenantOwnTermsOfService,
      {
        id: `${tenantCode}#CONFIRMED`,
      }
    );
    return res.getTenantOwnTermsOfService
      ? toTenantOwnTermsOfService(res.getTenantOwnTermsOfService)
      : undefined;
  }
}
