import {
  AuthorizationService,
  SCHEDULE_TAGS_LIMITATION,
  ScheduleId,
  ScheduleTagId,
} from '@/base/domains';
import { LocalDateTime, MarkDownString } from '@/base/types';
import { AbstractUseCase, assertEntityExists, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { ScheduleTagRepository } from '../domains';
import { ScheduleReference, ScheduleRepository } from '../domains/Schedule';
import { SCHEDULE_TAG_LIMITATION_EXCEEDED, SCHEDULE_TAGS_DO_NOT_EXIST } from '../ErrorCodes';

export interface UpdateScheduleRequest {
  id: ScheduleId;
  start: LocalDateTime;
  end: LocalDateTime;
  title: string;
  body?: MarkDownString;
  tagIds?: Array<ScheduleTagId>;
}

export type UpdateScheduleResponse = {
  schedule: ScheduleReference;
};

/**
 * スケジュールを更新する
 */
export interface UpdateSchedule extends UseCase<UpdateScheduleRequest, UpdateScheduleResponse> {
  execute(request: UpdateScheduleRequest): Promise<UseCaseResponse<UpdateScheduleResponse>>;
}

export class UpdateScheduleImpl
  extends AbstractUseCase<UpdateScheduleRequest, UpdateScheduleResponse>
  implements UpdateSchedule
{
  constructor(
    private scheduleRepository: ScheduleRepository,
    private scheduleTagRepository: ScheduleTagRepository,
    private authorizationService: AuthorizationService
  ) {
    super('training.UpdateSchedule');
  }

  async internalExecute(request: UpdateScheduleRequest): Promise<UpdateScheduleResponse> {
    const { id, tagIds = [] } = request;
    if (tagIds.length > SCHEDULE_TAGS_LIMITATION) {
      throw SCHEDULE_TAG_LIMITATION_EXCEEDED.toApplicationError();
    }

    const schedule = await this.scheduleRepository.findById(id);
    assertEntityExists(schedule, 'schedule');
    this.authorizationService.assertTrainerOrMentorInGroup(schedule.groupId);
    if (tagIds.length > 0) {
      const scheduleTags = await this.scheduleTagRepository.findByGroupId(schedule.groupId);
      const notExistTagIds = tagIds.filter((tagId) => !scheduleTags.find((st) => st.id === tagId));
      if (notExistTagIds.length > 0) {
        throw SCHEDULE_TAGS_DO_NOT_EXIST.toApplicationError({
          tagIds: notExistTagIds,
        });
      }
    }
    const scheduleTagIds = schedule.tags.map((tag) => tag.id);
    const tagIdsToAdd = tagIds.filter((tagId) => !scheduleTagIds.includes(tagId));
    const tagIdsToRemove = scheduleTagIds.filter((tagId) => !tagIds.includes(tagId));
    await Promise.all(
      tagIdsToAdd
        .map((tagId) => this.scheduleRepository.addTag(id, tagId))
        .concat(tagIdsToRemove.map((tagId) => this.scheduleRepository.removeTag(id, tagId)))
    );
    const saved = await this.scheduleRepository.save(
      schedule.update({
        start: request.start,
        end: request.end,
        title: request.title,
        body: request.body,
      })
    );
    return {
      schedule: saved,
    };
  }
}

export const UpdateScheduleKey = injectionKeyOf<UpdateSchedule>({
  boundedContext: 'training',
  type: 'usecase',
  name: 'UpdateSchedule',
});

export function useUpdateSchedule(): UpdateSchedule {
  return requiredInject(UpdateScheduleKey);
}
