import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { AuthorizationService, UserId, UserName, UserReference, UserRepository } from '../domains';
import { AbstractUseCase, UseCase, UseCaseResponse } from './UseCase';
import { assertEntityExists } from './UseCaseAsserts';

export type ChangeUserNameByAdminRequest = {
  userId: UserId;
  userName: UserName;
};

export type ChangeUserNameByAdminResponse = {
  user: UserReference;
};

/**
 * 管理者がユーザー名を変更する
 */
export interface ChangeUserNameByAdmin
  extends UseCase<ChangeUserNameByAdminRequest, ChangeUserNameByAdminResponse> {
  execute(
    request: ChangeUserNameByAdminRequest
  ): Promise<UseCaseResponse<ChangeUserNameByAdminResponse>>;
}

export class ChangeUserNameByAdminImpl
  extends AbstractUseCase<ChangeUserNameByAdminRequest, ChangeUserNameByAdminResponse>
  implements ChangeUserNameByAdmin
{
  constructor(
    private authorizationService: AuthorizationService,
    private userRepository: UserRepository
  ) {
    super('base.ChangeUserNameByAdmin');
  }

  async internalExecute({
    userId,
    userName,
  }: ChangeUserNameByAdminRequest): Promise<ChangeUserNameByAdminResponse> {
    this.authorizationService.assertRole('supervisor', 'admin');
    const user = await this.userRepository.findById(userId);
    assertEntityExists(user, 'user');
    const saved = await this.userRepository.save(user.changeName(userName));
    return {
      user: saved,
    };
  }
}

export const ChangeUserNameByAdminKey = injectionKeyOf<ChangeUserNameByAdmin>({
  boundedContext: 'base',
  type: 'usecase',
  name: 'ChangeUserNameByAdmin',
});

export function useChangeUserNameByAdmin(): ChangeUserNameByAdmin {
  return requiredInject(ChangeUserNameByAdminKey);
}
