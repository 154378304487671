import { Format, LogEntry, LogLevel, Transporter } from './core';

export class AndroidLogCatTransporter implements Transporter {
  format: Format;

  level: LogLevel;

  constructor(args: { format: Format; level: LogLevel }) {
    this.format = args.format;
    this.level = args.level;
  }

  log(entry: LogEntry): void {
    if (entry.level === 'off') return;
    if (!window.$androidWebAppBridge) return;
    const message = this.format(entry) as string;
    window.$androidWebAppBridge.logging(message, entry.level);
  }
}
