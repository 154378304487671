import moment from 'moment';

import { QuestionData } from '@/training/domains';

export const questions: Array<QuestionData> = [
  {
    id: 'question00',
    comments: [
      {
        id: 'q00-c00',
        body: 'Java入門のサンプル1を実行してみましたが、エラーがでます。\nどこを調べたらいいかわかりませんでした。\n',
        createdBy: 'user02',
        createdAt: moment().add(-3, 'hours'),
        groupId: 'group00',
      },
      {
        id: 'q00-c01',
        body: '@{{user02}}\nエラーの内容がコンソールに表示されていると思います。\nエラーの内容を返信してください。\n\n@{{mentor01}} 対応お願いします。\n',
        createdBy: 'admin00',
        createdAt: moment().add(-2, 'hours'),
        groupId: 'group00',
      },
      {
        id: 'q00-c02',
        body: '@{{mentor01}} @{{admin00}}\nお願いします。\n[Google](https://www.google.com/)\n```java\nエラーの内容\n```\n',
        createdBy: 'user02',
        createdAt: moment().add(-2, 'hours'),
        groupId: 'group00',
      },
    ],
    createdBy: 'user02',
    createdAt: moment().add(-3, 'hours'),
    resolved: false,
    groupId: 'group00',
    title: 'サンプル1でエラーが発生します',
    referTo: { contentId: 'content00-0', contentVersion: 1 },
    assignees: [],
  },
  {
    id: 'question01',
    comments: [
      {
        body: '[Javaとは](/groups/group00/courses/Java%E5%85%A5%E9%96%80/1\\)%20Java%E3%81%A8%E3%81%AF#java%E3%81%A8%E3%81%AF)\n\n[メソッド](/groups/group00/courses/Java%E5%85%A5%E9%96%80/2\\)%20%E3%83%A1%E3%82%BD%E3%83%83%E3%83%89%E3%81%A8%E3%83%95%E3%82%A3%E3%83%BC%E3%83%AB%E3%83%89#%E3%83%A1%E3%82%BD%E3%83%83%E3%83%89)\n\n質問内容質問内容質問内容質問内容質問内容質問内容質問内容質問内容質問内容質問内容質問内容質問内容質問内容質問内容質問内容質問内容質問内容質問内容質問内容質問内容',
        createdBy: 'user01',
        groupId: 'group00',
      },
      {
        body: '答え答え答え答え答え答え',
        createdBy: 'mentor01',
        groupId: 'group00',
      },
    ],
    createdBy: 'user01',
    createdAt: moment().add(-3, 'days'),
    resolved: true,
    groupId: 'group00',
    title: '質問のタイトル質問のタイトル質問のタイトル',
    referTo: { contentId: 'content00-0', contentVersion: 1 },
    assignees: [],
  },
  {
    id: 'question02',
    comments: [
      {
        body: '本文',
        createdBy: 'user01',
        groupId: 'group00',
      },
    ],
    createdBy: 'user01',
    createdAt: moment().add(-3, 'days'),
    resolved: false,
    groupId: 'group00',
    title: 'マーク付きの質問',
    referTo: {
      contentId: 'content01-0',
      contentVersion: 1,
      selection: {
        caption: '一般的に社会人と学生の違いは以下の通りです',
        color: 'success',
        start: { xpath: '/p[2]/text()', offset: 0 },
        end: { xpath: '/p[2]/text()', offset: 21 },
      },
    },
    assignees: [],
  },
];
