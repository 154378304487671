import { errorCodeOf } from '@/base/error';

import { UserId } from './domains/Core';
import { GroupId, GroupName } from './domains/Group';
import { UserTagId } from './domains/UserTag';

export const EXCLUSIVE_CONTROL_ERROR = errorCodeOf<{ entity: string }>(
  'BS0001',
  '他のユーザーがデータを更新しています'
);

export const INVALID_VERIFICATION_CODE = errorCodeOf('BS0002', '不正な認証コードです');

export const INVITE_USER_DUPLICATED_EMAIL = errorCodeOf(
  'BS0003',
  'メールアドレスはすでに使用されています'
);

export const INVITE_USER_DUPLICATED_USER_CODE = errorCodeOf(
  'BS0004',
  'ユーザーコードはすでに使用されています'
);

export const INVITE_USER_ALREADY_INVITED = errorCodeOf(
  'BS0005',
  'ユーザーはすでに招待されています'
);

export const AUTHORIZATION_ERROR = errorCodeOf('BS0006', '許可されていない操作です');

export const UPLOAD_FILE_SIZE_EXCEEDED = errorCodeOf(
  'BS0007',
  'アップロードするファイルサイズが大きすぎます'
);

export const GROUP_NAME_DUPLICATED = errorCodeOf('BS0008', 'グループの名前が重複しています');

export const ENTITY_NOT_FOUND = errorCodeOf(
  'BS0009',
  'データがありません。削除された可能性があります。'
);
export const SIGN_UP_DUPLICATED_EMAIL = errorCodeOf(
  'BS0010',
  'メールアドレスはすでに使用されています'
);

export const SIGN_UP_DUPLICATED_TENANT_CODE = errorCodeOf(
  'BS0011',
  'テナントコードはすでに使用されています'
);

export const GROUP_DUPLICATED_USER = errorCodeOf<{ groupId: GroupId; userId: UserId }>(
  'BS0012',
  '同じユーザーがすでに登録されています'
);

export const GROUP_USER_LIMIT_EXCEEDED = errorCodeOf<{ groupId: GroupId }>(
  'BS0013',
  'これ以上ユーザーをグループに登録できません'
);

export const USER_LIMIT_EXCEEDED = errorCodeOf<{}>(
  'BS0014',
  'これ以上ユーザーをテナントに登録できません'
);

export const COURSE_REMOVE_FAILED_GROUP_EXAM_IS_OPEN = errorCodeOf<{
  groups: Array<{ id: GroupId; name: GroupName }>;
}>('BS0015', '開催中または予約中のテストがあるためコースを削除できません');

export const USER_TAGS_DO_NOT_EXIST = errorCodeOf<{
  tagIds: Array<UserTagId>;
}>('BS0016', 'タグが削除されているため利用できません');

export const AMPLIFY_FILE_STORAGE_ENCODE_AUDIO_FAILED = errorCodeOf<{
  filename: string;
}>('BS0017', 'オーディオファイルのエンコードに失敗しました');

export const AMPLIFY_FILE_STORAGE_ENCODE_VIDEO_FAILED = errorCodeOf<{
  filename: string;
}>('BS0018', 'ビデオファイルのエンコードに失敗しました');

export const CREATE_USER_BY_ADMIN_DUPLICATED_EMAIL = errorCodeOf<{ email: string }>(
  'BS0019',
  'メールアドレスはすでに使用されています'
);

export const CREATE_USER_BY_ADMIN_DUPLICATED_USER_CODE = errorCodeOf<{ userCode: string }>(
  'BS0020',
  'ユーザーコードはすでに使用されています'
);

export const CREATE_USER_BY_ADMIN_ALREADY_INVITED = errorCodeOf<{
  userCode?: string;
  email?: string;
  tenantCode?: string;
}>('BS0021', 'ユーザーはすでに招待されています');

export const SIGN_UP_FAILED = errorCodeOf<{ userCode?: string; email?: string }>(
  'BS0022',
  'ユーザー登録できません。テナント管理者に確認してください。'
);

export const USER_TAG_LIMITATION_EXCEEDED = errorCodeOf<{}>(
  'BS0023',
  'ユーザーにつけるタグの上限を超過しています'
);

export const NETWORK_ERROR = errorCodeOf<{}>('BS0024', 'ネットワークエラーです');

export const UNEXPECTED_ERROR = errorCodeOf('BS9000', '想定されていないエラーが発生しました');
