import { Optional } from '../types';
import { Group, GroupFinder, GroupId, GroupRepository } from './Group';

export class GroupFinderImpl implements GroupFinder {
  private groupRepository: GroupRepository;

  constructor(groupRepository: GroupRepository) {
    this.groupRepository = groupRepository;
  }

  findById(
    id: GroupId,
    options?: { includeRemovedGroupUser?: boolean; includeDisabledGroup?: boolean }
  ): Promise<Optional<Group>> {
    return this.groupRepository.findById(id, options);
  }

  findTenantGroups(options?: {
    includeRemovedGroupUser?: boolean;
    includeDisabledGroup?: boolean;
  }): Promise<Array<Group>> {
    return this.groupRepository.findTenantGroups(options);
  }
}
