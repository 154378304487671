import { Optional } from '@/base/types';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { TenantOwnTermsOfService } from '../TenantOwnTermsOfService';

export interface TenantOwnTermsOfServiceQueries {
  findTenantOwnTermsOfService(): Promise<Optional<TenantOwnTermsOfService>>;
}

export const TenantOwnTermsOfServiceQueriesKey = injectionKeyOf<TenantOwnTermsOfServiceQueries>({
  boundedContext: 'base',
  type: 'adapter',
  name: 'TenantOwnTermsOfServiceQueries',
});

export function useTenantOwnTermsOfServiceQueries(): TenantOwnTermsOfServiceQueries {
  return requiredInject(TenantOwnTermsOfServiceQueriesKey);
}
