import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

export interface Surviver {
  live(): Promise<void>;
}

export const SurviverKey = injectionKeyOf<Surviver>({
  boundedContext: 'base',
  type: 'adapter',
  name: 'Surviver',
});

export function useSurviver(): Surviver {
  return requiredInject(SurviverKey);
}
