import { TermsOfService, TermsOfServiceDataAdapter } from '@/base/domains';
import { Optional } from '@/base/types';

export class InMemoryTermsOfServiceDataAdapter implements TermsOfServiceDataAdapter {
  private termsOfServiceList: Array<TermsOfService>;

  constructor(termsOfServiceList: Array<TermsOfService>) {
    this.termsOfServiceList = termsOfServiceList;
  }

  findLatest(): Promise<Optional<TermsOfService>> {
    return Promise.resolve(
      [...this.termsOfServiceList].sort((a, b) => -a.id.localeCompare(b.id))[0]
    );
  }
}
