import { QuestionnaireByAnswererArgs } from '@/training/domains';
import { localDateTimeFromString } from '@/utils/DateUtils';

export const unansweredQuestionnaires: Array<QuestionnaireByAnswererArgs> = [
  {
    id: 'qid',
    groupId: 'group00',
    title: 'title',
    userIds: ['uid', 'uid1'],
    text: 'text',
    createdAt: localDateTimeFromString('2021-01-01T10:00:00.000Z'),
    createdBy: 'user00',
    options: ['option1', 'option2'],
  },
];
