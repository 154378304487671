import { GroupData } from '@/base/domains';
import { SlackAppType, SlackGroupExtensionConfig } from '@/base/domains/extensions/slack';

const teamId: string = process.env.VUE_APP_DEV_SLACK_TEAM_ID ?? 'team00';
const channelId: string = process.env.VUE_APP_DEV_SLACK_CHANNEL_ID ?? 'channel00';
const appType = (process.env.VUE_APP_DEV_SLACK_APP_TYPE ?? 'web') as SlackAppType;
const slackConfig: SlackGroupExtensionConfig = {
  version: '0.0.1',
  name: 'slack',
  teamId,
  channelIds: [channelId],
  appType,
};

export const groups: Array<GroupData> = [
  {
    id: 'group00',
    name: 'グループ00',
    extensionConfigs: [slackConfig],
    users: [
      { id: 'user00', role: 'trainer', removed: false },
      { id: 'user01', role: 'trainer', removed: false },
      { id: 'user02', role: 'trainee', removed: false },
      { id: 'user03', role: 'trainee', removed: false },
      { id: 'user04', role: 'trainee', removed: false },
      { id: 'user05', role: 'trainee', removed: false },
      { id: 'user06', role: 'trainee', removed: false },
      { id: 'mentor01', role: 'mentor', removed: false },
      { id: 'admin00', role: 'trainer', removed: false },
      { id: 'admin01', role: 'trainer', removed: false },
      { id: 'admin02', role: 'trainer', removed: false },
      { id: 'admin03', role: 'trainer', removed: false },
    ],
    limitations: {
      question: ['trainer', 'mentor'],
      schedule: ['mentor', 'trainer', 'trainee'],
      questionnaire: ['mentor', 'trainer', 'trainee'],
    },
    enabled: true,
    description: 'description',
  },
  {
    id: 'group01',
    name: 'グループ01',
    extensionConfigs: [slackConfig],
    users: [
      { id: 'user02', role: 'trainee', removed: false },
      { id: 'mentor01', role: 'mentor', removed: false },
      { id: 'admin01', role: 'trainer', removed: false },
    ],
    limitations: {
      question: ['trainer', 'mentor'],
      schedule: ['mentor', 'trainer', 'trainee'],
      questionnaire: ['mentor', 'trainer', 'trainee'],
    },
    enabled: true,
    description: 'description',
  },
  {
    id: 'group02',
    name: 'グループ02',
    extensionConfigs: [slackConfig],
    users: [
      { id: 'user02', role: 'trainee', removed: false },
      { id: 'mentor01', role: 'mentor', removed: false },
    ],
    limitations: {
      question: ['trainer', 'mentor'],
      schedule: ['mentor', 'trainer', 'trainee'],
      questionnaire: ['mentor', 'trainer', 'trainee'],
    },
    enabled: true,
    description: 'description',
  },
  {
    id: 'group03',
    name: 'グループ03',
    extensionConfigs: [slackConfig],
    users: [
      { id: 'user02', role: 'trainee', removed: false },
      { id: 'mentor01', role: 'mentor', removed: false },
    ],
    limitations: {
      question: ['trainer', 'mentor'],
      schedule: ['mentor', 'trainer', 'trainee'],
      questionnaire: ['mentor', 'trainer', 'trainee'],
    },
    enabled: true,
    description: 'description',
  },
  {
    id: 'group04',
    name: 'グループ04',
    extensionConfigs: [slackConfig],
    users: [
      { id: 'user02', role: 'trainee', removed: false },
      { id: 'mentor01', role: 'mentor', removed: true },
    ],
    limitations: {
      question: ['trainer', 'mentor'],
      schedule: ['mentor', 'trainer', 'trainee'],
      questionnaire: ['mentor', 'trainer', 'trainee'],
    },
    enabled: false,
    description: 'description',
  },
];
