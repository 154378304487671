import { AppContextProvider, QuestionId } from '@/base/domains';
import { MarkDownString } from '@/base/types';
import { AbstractUseCase, assertEntityExists, UseCase, UseCaseResponse } from '@/base/usecases';
import { assertIsDefined } from '@/utils/Asserts';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { QuestionReference, QuestionRepository } from '../domains';

export type AddCommentToQuestionComment = {
  body: MarkDownString;
};

export interface AddCommentToQuestionRequest {
  id: QuestionId;
  comment: AddCommentToQuestionComment;
}

export type AddCommentToQuestionResponse = {
  question: QuestionReference;
};

/**
 * 質問にコメントする
 */
export interface AddCommentToQuestion
  extends UseCase<AddCommentToQuestionRequest, AddCommentToQuestionResponse> {
  execute(
    request: AddCommentToQuestionRequest
  ): Promise<UseCaseResponse<AddCommentToQuestionResponse>>;
}

export class AddCommentToQuestionImpl
  extends AbstractUseCase<AddCommentToQuestionRequest, AddCommentToQuestionResponse>
  implements AddCommentToQuestion
{
  private appContextProvider: AppContextProvider;

  private questionRepository: QuestionRepository;

  constructor(appContextProvider: AppContextProvider, questionRepository: QuestionRepository) {
    super('training.AddCommentToQuestion');
    this.appContextProvider = appContextProvider;
    this.questionRepository = questionRepository;
  }

  async internalExecute(
    request: AddCommentToQuestionRequest
  ): Promise<AddCommentToQuestionResponse> {
    const { id, comment } = request;
    const question = await this.questionRepository.findById(id);
    assertEntityExists(question, 'question');
    const userId = this.appContextProvider.get().user?.id;
    assertIsDefined(userId, 'userId');

    const saved = await this.questionRepository.save(
      question.addComment({
        body: comment.body,
        createdBy: userId,
      })
    );
    return {
      question: saved,
    };
  }
}

export const AddCommentToQuestionKey = injectionKeyOf<AddCommentToQuestion>({
  boundedContext: 'training',
  type: 'usecase',
  name: 'AddCommentToQuestion',
});

export function useAddCommentToQuestion(): AddCommentToQuestion {
  return requiredInject(AddCommentToQuestionKey);
}
