import { AuthorizationService, ContentId, DataVersion, ProblemHeaderId } from '@/base/domains';
import { MarkDownString } from '@/base/types';
import {
  AbstractUseCase,
  assertDataVersion,
  assertEntityExists,
  UseCase,
  UseCaseResponse,
} from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { EditingConfirmedContentReference, EditingConfirmedContentRepository } from '../domains';

export type ChangeEditingConfirmedContentProblemHeaderRequest = {
  id: ContentId;
  problemHeaderId: ProblemHeaderId;
  body: MarkDownString;
  expectedDataVersion: DataVersion;
};

export type ChangeEditingConfirmedContentProblemHeaderResponse = {
  editingConfirmedContent: EditingConfirmedContentReference;
};

/**
 * 編集中確定済みコンテンツの問題ヘッダーを編集する
 */
export interface ChangeEditingConfirmedContentProblemHeader
  extends UseCase<
    ChangeEditingConfirmedContentProblemHeaderRequest,
    ChangeEditingConfirmedContentProblemHeaderResponse
  > {
  execute(
    request: ChangeEditingConfirmedContentProblemHeaderRequest
  ): Promise<UseCaseResponse<ChangeEditingConfirmedContentProblemHeaderResponse>>;
}

export class ChangeEditingConfirmedContentProblemHeaderImpl
  extends AbstractUseCase<
    ChangeEditingConfirmedContentProblemHeaderRequest,
    ChangeEditingConfirmedContentProblemHeaderResponse
  >
  implements ChangeEditingConfirmedContentProblemHeader
{
  constructor(
    private authorizationService: AuthorizationService,
    private editingConfirmedContentRepository: EditingConfirmedContentRepository
  ) {
    super('contents.ChangeEditingConfirmedContentProblemHeader');
  }

  async internalExecute(
    request: ChangeEditingConfirmedContentProblemHeaderRequest
  ): Promise<ChangeEditingConfirmedContentProblemHeaderResponse> {
    const { id, problemHeaderId, body, expectedDataVersion } = request;
    this.authorizationService.assertContentEditable();
    const content = await this.editingConfirmedContentRepository.findById(id);
    assertEntityExists(content, 'editingConfirmedContent');
    assertDataVersion(content, expectedDataVersion, 'editingConfirmedContent');
    if (content.type === 'exam') {
      const saved = await this.editingConfirmedContentRepository.save(
        content.changeProblemHeader({
          problemHeaderId,
          problemHeaderBody: body,
        })
      );
      return {
        editingConfirmedContent: saved,
      };
    }
    throw new Error('editingConfirmedContent.type should be exam');
  }
}

export const ChangeEditingConfirmedContentProblemHeaderKey =
  injectionKeyOf<ChangeEditingConfirmedContentProblemHeader>({
    boundedContext: 'contents',
    type: 'usecase',
    name: 'ChangeEditingConfirmedContentProblemHeader',
  });

export function useChangeEditingConfirmedContentProblemHeader(): ChangeEditingConfirmedContentProblemHeader {
  return requiredInject(ChangeEditingConfirmedContentProblemHeaderKey);
}
