import BigNumber from 'bignumber.js';

import { AuthorizationService, GroupId } from '@/base/domains';
import { LocalDate, LocalDateTime } from '@/base/types';
import { AbstractUseCase, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import {
  GroupCourseAverage,
  GroupCourseReport,
  GroupExamResultReport,
  ReportService,
  TrainerUserReportRecord,
} from '../domains';

export type GetTrainerUsersReportRequest = {
  groupId: GroupId;
};

export type GetTrainerUsersReportResponse = {
  records: Array<TrainerUserReportRecord>;
  /** コース数 */
  courses: Array<GroupCourseReport>;
  /** コース平均リスト */
  courseAverages: GroupCourseAverage;
  /** テスト結果リスト */
  examResults: Array<GroupExamResultReport>;
  /** グループ平均利用開始日 */
  groupAverageStartOfUse?: LocalDate;
  /** グループ平均直近利用日時 */
  groupAverageRecentUsedAt?: LocalDateTime;
  /** グループ平均利用時間 */
  groupAverageUsageTime: BigNumber;
  /** グループ平均コメント数 */
  groupAverageCommentCount: BigNumber;
  /** グループ平均未着手コース数 */
  groupAverageNotBegunCourseCount: BigNumber;
  /** グループ平均実施中コース数 */
  groupAverageInProgressCourseCount: BigNumber;
  /** グループ平均完了コース数 */
  groupAverageCompletedCourseCount: BigNumber;
  /** グループ平均コース進捗率 */
  groupAverageCourseProgressRate: BigNumber;
  /** グループ平均完了テスト正答率 */
  groupAverageCorrectAnswerRate: BigNumber;
  /** グループ平均未完了テスト数 */
  groupAverageNotBegunExamCount: BigNumber;
  /** グループ平均完了テスト数 */
  groupAverageCompletedExamCount: BigNumber;
};

/**
 * グループ内受講生の利用状況リストを取得する
 */
export interface GetTrainerUsersReport
  extends UseCase<GetTrainerUsersReportRequest, GetTrainerUsersReportResponse> {
  execute(
    request: GetTrainerUsersReportRequest
  ): Promise<UseCaseResponse<GetTrainerUsersReportResponse>>;
}

export class GetTrainerUsersReportImpl
  extends AbstractUseCase<GetTrainerUsersReportRequest, GetTrainerUsersReportResponse>
  implements GetTrainerUsersReport
{
  constructor(
    private authorizationService: AuthorizationService,
    private reportService: ReportService
  ) {
    super('report.GetTrainerUsersReport');
  }

  async internalExecute(
    request: GetTrainerUsersReportRequest
  ): Promise<GetTrainerUsersReportResponse> {
    const { groupId } = request;
    this.authorizationService.assertGroupReportAccessible(groupId);
    const res = await this.reportService.findTraineeRecords(groupId);
    return res;
  }
}

export const GetTrainerUsersReportKey = injectionKeyOf<GetTrainerUsersReport>({
  boundedContext: 'report',
  type: 'usecase',
  name: 'GetTrainerUsersReport',
});

export function useGetTrainerUsersReport(): GetTrainerUsersReport {
  return requiredInject(GetTrainerUsersReportKey);
}
