import { v4 } from 'uuid';

import {
  AuthorizationService,
  ContentId,
  ContentName,
  ContentType,
  CourseId,
} from '@/base/domains';
import { Minute } from '@/base/types';
import { AbstractUseCase, assertEntityExists, UseCase, UseCaseResponse } from '@/base/usecases';
import { isDefined } from '@/utils/TsUtils';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { EditingCourseReference, EditingCourseRepository } from '../domains';

export type ChangeEditingCourseContentsEditingCourseContentHeader = {
  /** コンテンツID */
  id?: ContentId;
  /** コンテンツタイプ */
  type: ContentType;
  /** 名前 */
  name: ContentName;
  /** 所要時間 */
  requiredTime: Minute;
};

export interface ChangeEditingCourseContentsRequest {
  /** コースID */
  id: CourseId;
  /** コンテンツリスト */
  contents: Array<ChangeEditingCourseContentsEditingCourseContentHeader>;
}

export type ChangeEditingCourseContentsResponse = {
  /** コース */
  course: EditingCourseReference;
};

/**
 * コースのコンテンツリストを変更する
 */
export interface ChangeEditingCourseContents
  extends UseCase<ChangeEditingCourseContentsRequest, ChangeEditingCourseContentsResponse> {
  execute(
    request: ChangeEditingCourseContentsRequest
  ): Promise<UseCaseResponse<ChangeEditingCourseContentsResponse>>;
}

export class ChangeEditingCourseContentsImpl
  extends AbstractUseCase<ChangeEditingCourseContentsRequest, ChangeEditingCourseContentsResponse>
  implements ChangeEditingCourseContents
{
  private authorizationService: AuthorizationService;

  private editingCourseRepository: EditingCourseRepository;

  constructor(
    authorizationService: AuthorizationService,
    editingCourseRepository: EditingCourseRepository
  ) {
    super('contents.ChangeEditingCourseContents');
    this.authorizationService = authorizationService;
    this.editingCourseRepository = editingCourseRepository;
  }

  async internalExecute(
    request: ChangeEditingCourseContentsRequest
  ): Promise<ChangeEditingCourseContentsResponse> {
    const { id, contents } = request;
    this.authorizationService.assertContentEditable();
    const course = await this.editingCourseRepository.findById(id);
    assertEntityExists(course, 'editingCourse');
    const modifiedContents = contents.map((cn) => ({
      ...cn,
      id: isDefined(cn.id) ? cn.id : v4(),
    }));
    const saved = await this.editingCourseRepository.save(course.changeContents(modifiedContents));
    return {
      course: saved,
    };
  }
}

export const ChangeEditingCourseContentsKey = injectionKeyOf<ChangeEditingCourseContents>({
  boundedContext: 'contents',
  type: 'usecase',
  name: 'ChangeEditingCourseContents',
});

export function useChangeEditingCourseContents(): ChangeEditingCourseContents {
  return requiredInject(ChangeEditingCourseContentsKey);
}
