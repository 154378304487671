import { Config } from './types';

const configPath = process.env?.STORYBOOK_MODE || process.env?.VUE_APP_MODE || 'production';

// eslint-disable-next-line import/no-dynamic-require,@typescript-eslint/no-var-requires
const _config: Config = require(`./${configPath}`).default;

// テストでモックにしやすように関数にする
export function config(): Config {
  return _config;
}
