import { AuthorizationService, ContentId, CourseId } from '@/base/domains';
import { AbstractUseCase, assertEntityExists, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { CourseReference, CourseRepository } from '../domains';

export type ChangeConfirmedCourseContentsSortRequest = {
  id: CourseId;
  contentIds: Array<ContentId>;
};

export type ChangeConfirmedCourseContentsSortResponse = {
  course: CourseReference;
};

export interface ChangeConfirmedCourseContentsSort
  extends UseCase<
    ChangeConfirmedCourseContentsSortRequest,
    ChangeConfirmedCourseContentsSortResponse
  > {
  execute(
    request: ChangeConfirmedCourseContentsSortRequest
  ): Promise<UseCaseResponse<ChangeConfirmedCourseContentsSortResponse>>;
}

export class ChangeConfirmedCourseContentsSortImpl
  extends AbstractUseCase<
    ChangeConfirmedCourseContentsSortRequest,
    ChangeConfirmedCourseContentsSortResponse
  >
  implements ChangeConfirmedCourseContentsSort
{
  constructor(
    private authorizationService: AuthorizationService,
    private courseRepository: CourseRepository
  ) {
    super('contents.ChangeConfirmedCourseContentsSort');
  }

  async internalExecute(
    request: ChangeConfirmedCourseContentsSortRequest
  ): Promise<ChangeConfirmedCourseContentsSortResponse> {
    const { id, contentIds } = request;
    this.authorizationService.assertContentEditable();
    const version = await this.courseRepository.findLatestVersion(id);
    const course = await this.courseRepository.findById({ id, version });
    assertEntityExists(course, 'course');
    const saved = await this.courseRepository.save(course.changeContentsSort(contentIds));
    return {
      course: saved,
    };
  }
}

export const ChangeConfirmedCourseContentsSortKey =
  injectionKeyOf<ChangeConfirmedCourseContentsSort>({
    boundedContext: 'contents',
    type: 'usecase',
    name: 'ChangeConfirmedCourseContentsSort',
  });

export function useChangeConfirmedCourseContentsSort(): ChangeConfirmedCourseContentsSort {
  return requiredInject(ChangeConfirmedCourseContentsSortKey);
}
