import {
  AuthorizationService,
  CourseId,
  GroupId,
  GroupTrainingCourse,
  GroupTrainingFinder,
} from '@/base/domains';
import { AbstractUseCase, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

export type GetGroupTrainingCourseRequest = {
  courseId: CourseId;
  groupId: GroupId;
};

export type GetGroupTrainingCourseResponse = {
  groupTrainingCourse?: GroupTrainingCourse;
};

export interface GetGroupTrainingCourse
  extends UseCase<GetGroupTrainingCourseRequest, GetGroupTrainingCourseResponse> {
  execute(
    request: GetGroupTrainingCourseRequest
  ): Promise<UseCaseResponse<GetGroupTrainingCourseResponse>>;
}

export class GetGroupTrainingCourseImpl
  extends AbstractUseCase<GetGroupTrainingCourseRequest, GetGroupTrainingCourseResponse>
  implements GetGroupTrainingCourse
{
  constructor(
    private authorizationService: AuthorizationService,
    private groupTrainingFinder: GroupTrainingFinder
  ) {
    super('contents.GetGroupTrainingCourse');
  }

  async internalExecute(
    request: GetGroupTrainingCourseRequest
  ): Promise<GetGroupTrainingCourseResponse> {
    const { courseId, groupId } = request;
    this.authorizationService.assertRole('supervisor', 'admin');
    const res = await this.groupTrainingFinder.findCourseByGroupIdAndCourseId(groupId, courseId);
    return { groupTrainingCourse: res };
  }
}

export const GetGroupTrainingCourseKey = injectionKeyOf<GetGroupTrainingCourse>({
  boundedContext: 'contents',
  type: 'usecase',
  name: 'GetGroupTrainingCourse',
});

export function useGetGroupTrainingCourse(): GetGroupTrainingCourse {
  return requiredInject(GetGroupTrainingCourseKey);
}
