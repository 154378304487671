import { config } from '@/config';

export const devRoutes = config().production
  ? []
  : [
      {
        path: '/dev',
        name: 'devIndex',
        component: () => import(/* webpackChunkName: "dev" */ './pages/IndexPage.vue'),
      },
      {
        path: '/dev/signup',
        name: 'devSignUp',
        component: () => import(/* webpackChunkName: "dev" */ './pages/SignUpPage.vue'),
      },
      {
        path: '/dev/confirm-signup',
        name: 'devConfirmSignUp',
        component: () => import(/* webpackChunkName: "dev" */ './pages/ConfirmSignUpPage.vue'),
      },
      {
        path: '/dev/contents',
        name: 'devContents',
        component: () => import(/* webpackChunkName: "dev" */ './pages/ContentsPage.vue'),
      },
      {
        path: '/dev/groups',
        name: 'devGroups',
        component: () => import(/* webpackChunkName: "dev" */ './pages/GroupsPage.vue'),
      },
      {
        path: '/dev/courses',
        name: 'devCourses',
        component: () => import(/* webpackChunkName: "dev" */ './pages/CoursesPage.vue'),
      },
      {
        path: '/dev/group-trainings',
        name: 'devGroupTrainings',
        component: () => import(/* webpackChunkName: "dev" */ './pages/GroupTrainingsPage.vue'),
      },
      {
        path: '/dev/notifications',
        name: 'devNotifications',
        component: () => import(/* webpackChunkName: "dev" */ './pages/NotificationsPage.vue'),
      },
      {
        path: '/dev/users',
        name: 'devUsers',
        component: () => import(/* webpackChunkName: "dev" */ './pages/UsersPage.vue'),
      },
      {
        path: '/dev/schedules',
        name: 'devSchedules',
        component: () => import(/* webpackChunkName: "dev" */ './pages/SchedulesPage.vue'),
      },
      {
        path: '/dev/questions',
        name: 'devQuestions',
        component: () => import(/* webpackChunkName: "dev" */ './pages/QuestionsPage.vue'),
      },
      {
        path: '/dev/file-storage',
        name: 'devFileStorage',
        component: () => import(/* webpackChunkName: "dev" */ './pages/FileStoragePage.vue'),
      },
      {
        path: '/dev/change-password',
        name: 'devChangePassword',
        component: () => import(/* webpackChunkName: "dev" */ './pages/ChangePasswordPage.vue'),
      },
    ];
