import {
  CommentId,
  ContentId,
  GroupId,
  Question,
  QuestionFinder,
  QuestionFinderFindArgs,
  QuestionId,
  UserId,
} from '@/base/domains';

import { QuestionQueries } from './queries';
import { QuestionRepository } from './Question';

export class QuestionFinderImpl implements QuestionFinder {
  constructor(
    private questionRepository: QuestionRepository,
    private questionQueries: QuestionQueries
  ) {}

  async find(
    args: QuestionFinderFindArgs
  ): Promise<{ questions: Array<Question>; limitExceeded: boolean }> {
    return this.questionRepository.findQuestions(args);
  }

  async findRelatedQuestions(userId: UserId): Promise<Array<Question>> {
    return this.questionQueries.findRelatedQuestions(userId);
  }

  async findCommentHeaders(args: { groupId: GroupId }): Promise<
    Array<{
      id: CommentId;
      questionId: QuestionId;
      groupId: GroupId;
      contentId?: ContentId;
      createdBy: UserId;
    }>
  > {
    return this.questionQueries.findCommentHeaders(args);
  }
}
