import { Email, Password, TenantCode, TenantName, UserCode } from '@/base/domains';

import { PlaygroundEntity, PlaygroundId, PlaygroundStatus } from './Playground';

type PlaygroundEntityImplArgs = {
  id: PlaygroundId;
  email: Email;
  tenantCode: TenantCode;
  tenantName: TenantName;
  status: PlaygroundStatus;
  temporaryPassword?: Password;
  userCode?: UserCode;
};

export class PlaygroundEntityImpl implements PlaygroundEntity {
  id: PlaygroundId;

  email: Email;

  tenantCode: TenantCode;

  tenantName: TenantName;

  status: PlaygroundStatus;

  temporaryPassword?: Password;

  userCode?: UserCode;

  constructor(args: PlaygroundEntityImplArgs) {
    this.id = args.id;
    this.email = args.email;
    this.tenantCode = args.tenantCode;
    this.tenantName = args.tenantName;
    this.status = args.status;
    this.temporaryPassword = args.temporaryPassword;
    this.userCode = args.userCode;
  }

  startCreating(userCode: UserCode): PlaygroundEntity {
    if (this.status !== 'reserved') {
      throw new Error('playground.status should be reserved');
    }
    return new PlaygroundEntityImpl({
      ...this,
      userCode,
      status: 'creating',
    });
  }
}
