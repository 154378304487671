import { AbstractUseCase, UseCase, UseCaseResponse } from '@/base/usecases/UseCase';
import { assertCheckIllegalState, assertIsDefined } from '@/utils/Asserts';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import {
  AppContextProvider,
  NotificationId,
  NotificationReference,
  NotificationRepository,
} from '../domains';
import { assertEntityExists } from './UseCaseAsserts';

export interface ToggleNotificationReadRequest {
  id: NotificationId;
}

export type ToggleNotificationReadResponse = {
  notification: NotificationReference;
};

/**
 * 通知の既読を切り替える
 */
export interface ToggleNotificationRead
  extends UseCase<ToggleNotificationReadRequest, ToggleNotificationReadResponse> {
  execute(
    request: ToggleNotificationReadRequest
  ): Promise<UseCaseResponse<ToggleNotificationReadResponse>>;
}

export class ToggleNotificationReadImpl
  extends AbstractUseCase<ToggleNotificationReadRequest, ToggleNotificationReadResponse>
  implements ToggleNotificationRead
{
  private notificationRepository: NotificationRepository;

  private appContextProvider: AppContextProvider;

  constructor(
    notificationRepository: NotificationRepository,
    appContextProvider: AppContextProvider
  ) {
    super('base.ToggleNotificationRead');
    this.notificationRepository = notificationRepository;
    this.appContextProvider = appContextProvider;
  }

  async internalExecute(
    request: ToggleNotificationReadRequest
  ): Promise<ToggleNotificationReadResponse> {
    const appContext = this.appContextProvider.get();
    const userId = appContext.user?.id;
    assertIsDefined(userId, 'appContext.user.id');

    const { id } = request;
    const notification = await this.notificationRepository.findById(id);
    assertEntityExists(notification, 'notification');
    assertCheckIllegalState(
      userId === notification.userId,
      'notification user is not signed-in user'
    );

    const saved = await this.notificationRepository.save(notification.toggleRead());
    return {
      notification: saved,
    };
  }
}

export const ToggleNotificationReadKey = injectionKeyOf<ToggleNotificationRead>({
  boundedContext: 'base',
  type: 'usecase',
  name: 'ToggleNotificationRead',
});

export function useToggleNotificationRead(): ToggleNotificationRead {
  return requiredInject(ToggleNotificationReadKey);
}
