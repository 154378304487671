import {
  AppContextProvider,
  AuthorizationService,
  ContentId,
  ContentLearning,
  ContentLearningDataAdapter,
  ContentName,
  ContentType,
  CourseColor,
  CourseDisplayName,
  CourseFinder,
  CourseFontColorOnImage,
  CourseId,
  ExamDataAdapter,
  ExamId,
  GroupExamId,
  GroupExamStatus,
  GroupId,
  GroupTrainingCourse,
  GroupTrainingFinder,
  LearningStatus,
  ReviewProblemFinder,
  UserExamStatus,
} from '@/base/domains';
import { LocalDateTime, Minute, URI } from '@/base/types';
import { AbstractUseCase, assertEntityExists, UseCase, UseCaseResponse } from '@/base/usecases';
import { assertIsDefined } from '@/utils/Asserts';
import { requiredNonNull } from '@/utils/TsUtils';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

export type GetCourseDetailsContent = {
  /** コンテンツタイプ */
  readonly type: ContentType;
  /** 名前。マスタとは異なる名前が付けられる。 */
  readonly name: ContentName;
  /** コンテンツID */
  readonly id: ContentId;
  /** 所要時間 */
  readonly requiredTime: Minute;
  /** 推奨日時 */
  readonly recommendedDateTime?: LocalDateTime;
  /** 学習ステータス */
  readonly learningStatus: LearningStatus;
  /** 公開中 */
  readonly open: boolean;
};

export type GetCourseDetailsCourse = {
  /** コースID */
  readonly id: CourseId;
  /** コース名 */
  readonly name: CourseDisplayName;
  /** 説明 */
  readonly description?: string;
  /** コースカラー */
  readonly color?: CourseColor;
  /** コースイメージ */
  readonly image?: URI;
  /** コース画像上のフォントカラー */
  readonly fontColorOnImage?: CourseFontColorOnImage;
  /** 復習問題の数 */
  readonly reviewProblemCount: number;
};

export type GetCourseDetailsTraineeExam = {
  /** 試験ID */
  readonly id: ExamId;
  /** 名前 */
  readonly name: ContentName;
  /** コンテンツID */
  readonly contentId: ContentId;
  /** 予定開始日時 */
  readonly scheduledStart: LocalDateTime;
  /** 予定終了日時 */
  readonly scheduledFinish?: LocalDateTime;
  /** 終了日時 */
  readonly finishedAt?: LocalDateTime;
  /** ステータス */
  readonly status: UserExamStatus;
  /** 回数 */
  readonly times: number;
  /** 制限時間 */
  readonly timeLimit?: Minute;
  /** グループ試験ID */
  readonly groupExamId: GroupExamId;
  /** ステータス */
  readonly groupExamStatus: GroupExamStatus;
};

export type GetCourseDetailsTrainerExam = {
  /** グループ試験ID */
  readonly id: GroupExamId;
  /** 名前 */
  readonly name: ContentName;
  /** コンテンツID */
  readonly contentId: ContentId;
  /** 予定開始日時 */
  readonly scheduledStart: LocalDateTime;
  /** 予定終了日時 */
  readonly scheduledFinish?: LocalDateTime;
  /** ステータス */
  readonly status: GroupExamStatus;
  /** 回数 */
  readonly times: number;
  /** 制限時間 */
  readonly timeLimit?: Minute;
};

export type GetCourseDetailsRequest = {
  groupId: GroupId;
  id: CourseId;
};

export type GetCourseDetailsResponse =
  | {
      forTrainee: false;
      contents: Array<GetCourseDetailsContent>;
      exams: Array<GetCourseDetailsTrainerExam>;
      course?: GetCourseDetailsCourse;
      courses: Array<{ id: CourseId; name: CourseDisplayName; index?: number }>;
    }
  | {
      forTrainee: true;
      contents: Array<GetCourseDetailsContent>;
      exams: Array<GetCourseDetailsTraineeExam>;
      course?: GetCourseDetailsCourse;
      courses: Array<{ id: CourseId; name: CourseDisplayName; index?: number }>;
    };

/**
 * コース詳細を取得する
 */
export interface GetCourseDetails extends UseCase<{}, GetCourseDetailsResponse> {
  execute(request: GetCourseDetailsRequest): Promise<UseCaseResponse<GetCourseDetailsResponse>>;
}

function courseContentsOf(
  course: GroupTrainingCourse,
  contentLearnings: Array<ContentLearning>
): Array<GetCourseDetailsContent> {
  const statusMap = new Map(contentLearnings.map((cl) => [cl.contentId, cl.status]));
  return course.contents.map((cn) => {
    return {
      type: cn.type as ContentType,
      name: cn.name,
      id: cn.id,
      requiredTime: cn.requiredTime,
      recommendedDateTime: cn.recommendedDateTime,
      learningStatus: statusMap.get(cn.id) ?? 'not_begun',
      open: cn.open,
    };
  });
}
export class GetCourseDetailsImpl
  extends AbstractUseCase<{}, GetCourseDetailsResponse>
  implements GetCourseDetails
{
  constructor(
    private authorizationService: AuthorizationService,
    private groupTrainingFinder: GroupTrainingFinder,
    private appContextProvider: AppContextProvider,
    private contentLearningDataAdapter: ContentLearningDataAdapter,
    private examDataAdapter: ExamDataAdapter,
    private courseFinder: CourseFinder,
    private reviewProblemFinder: ReviewProblemFinder
  ) {
    super('home.GetCourseDetails');
  }

  async internalExecute(request: GetCourseDetailsRequest): Promise<GetCourseDetailsResponse> {
    const { groupId, id } = request;
    const appContext = this.appContextProvider.get();
    assertIsDefined(appContext.user, 'appContext.user');
    const userId = requiredNonNull(appContext.user?.id, 'appContext.user.id');
    const groupRole = appContext.roleInGroup(groupId);
    if (groupRole === 'trainee') {
      const [groupCourses, contentLearnings, groupExams, reviewProblem] = await Promise.all([
        this.groupTrainingFinder.findCoursesByGroupId(groupId),
        this.contentLearningDataAdapter.findByGroupIdAndUserId(groupId, userId),
        this.examDataAdapter.findGroupExamsByGroupId(groupId, { inProgressOrScheduled: true }),
        this.reviewProblemFinder.findMyReviewProblem({ groupId, courseId: id }),
      ]);
      const courses = groupCourses.map((gr) => ({
        id: gr.id,
        name: gr.displayName,
        index: gr.index,
      }));
      const groupCourse = groupCourses.find((gr) => gr.id === id);
      if (!groupCourse) {
        return {
          forTrainee: false,
          contents: [],
          exams: [],
          course: undefined,
          courses,
        };
      }
      const course = await this.courseFinder.findByKey(id, groupCourse.version);
      assertEntityExists(course, 'course');
      const contents = courseContentsOf(groupCourse, contentLearnings);
      const exams = groupExams
        .map((ge) => {
          const userExam = ge.userExams.find((ue) => ue.userId === userId);
          const exam = userExam
            ? {
                name: ge.content.name,
                id: userExam?.id,
                contentId: ge.content.id,
                scheduledStart: ge.scheduledStart,
                scheduledFinish: ge.scheduledFinish,
                finishedAt: userExam?.finishedAt,
                status: userExam?.status,
                times: ge.times,
                timeLimit: ge.timeLimit,
                groupExamId: ge.id,
                groupExamStatus: ge.status,
              }
            : undefined;
          return exam;
        })
        .filter((exam) => exam)
        .map((exam) => exam as GetCourseDetailsTraineeExam);
      const returnCourse: GetCourseDetailsCourse = {
        id: groupCourse.id,
        name: groupCourse.displayName,
        color: groupCourse.color,
        image: groupCourse.image,
        fontColorOnImage: groupCourse.fontColorOnImage,
        description: course.description,
        reviewProblemCount: reviewProblem?.problems.length ?? 0,
      };
      return {
        forTrainee: true,
        contents,
        exams,
        course: returnCourse,
        courses,
      };
    }
    this.authorizationService.assertGroupReadAccessible(groupId);
    const [groupCourses, contentLearnings, groupExams, reviewProblem] = await Promise.all([
      this.groupTrainingFinder.findCoursesByGroupId(groupId),
      this.contentLearningDataAdapter.findByGroupIdAndUserId(groupId, userId),
      this.examDataAdapter.findGroupExamsByGroupId(groupId, { inProgressOrScheduled: true }),
      this.reviewProblemFinder.findMyReviewProblem({ groupId, courseId: id }),
    ]);
    const courses = groupCourses
      .map((gr) => ({
        id: gr.id,
        name: gr.displayName,
        index: gr.index,
      }))
      .sort((a, b) => {
        if (a.index !== undefined && b.index !== undefined) return a.index - b.index;
        return a.name < b.name ? -1 : 1;
      });
    const groupCourse = groupCourses.find((gr) => gr.id === id);
    if (!groupCourse) {
      return {
        forTrainee: false,
        contents: [],
        exams: [],
        course: undefined,
        courses,
      };
    }
    const course = await this.courseFinder.findByKey(groupCourse.id, groupCourse.version);
    assertEntityExists(course, 'course');
    const contents = groupCourse ? courseContentsOf(groupCourse, contentLearnings) : [];
    const exams: Array<GetCourseDetailsTrainerExam> = groupExams.map((ge) => ({
      id: ge.id,
      name: ge.content.name,
      contentId: ge.content.id,
      scheduledStart: ge.scheduledStart,
      scheduledFinish: ge.scheduledFinish,
      status: ge.status,
      times: ge.times,
      timeLimit: ge.timeLimit,
    }));
    const returnCourse: GetCourseDetailsCourse = {
      id: groupCourse.id,
      name: groupCourse.displayName,
      color: groupCourse.color,
      image: groupCourse.image,
      fontColorOnImage: groupCourse.fontColorOnImage,
      description: course.description,
      reviewProblemCount: reviewProblem?.problems.length ?? 0,
    };
    return {
      forTrainee: false,
      contents,
      exams,
      course: returnCourse,
      courses,
    };
  }
}

export const GetCourseDetailsKey = injectionKeyOf<GetCourseDetails>({
  boundedContext: 'home',
  type: 'usecase',
  name: 'GetCourseDetails',
});

export function useGetCourseDetails(): GetCourseDetails {
  return requiredInject(GetCourseDetailsKey);
}
