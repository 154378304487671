import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { UserId } from './Core';
import { GroupId } from './Group';
import { Role } from './User';

export interface AuthorizationService {
  /**
   * グループリポートにアクセスできる(trainer, mentor, SV/ADMIN)
   * @param groupId グループID
   */
  assertGroupReportAccessible(groupId: GroupId): void;
  /**
   * グループのtrainer
   * @param groupId グループID
   */
  assertTrainerAccessible(groupId: GroupId): void;
  /**
   * グループに所属しているユーザー
   * @param groupId グループID
   */
  assertGroupWriteAccessible(groupId: GroupId): void;
  /**
   * グループに所属しているユーザーかSV/ADMIN
   * @param groupId グループID
   */
  assertGroupReadAccessible(groupId: GroupId): void;
  assertRole(...executableRoles: Array<Role>): void;
  assertContentEditable(): void;
  assertOwnerAccessible(owner: UserId): void;
  /**
   * グループのtrainer, mentorであることを確認する
   * @param groupId グループID
   */
  assertTrainerOrMentorInGroup(groupId: GroupId): void;
  /**
   * プレイグラウンドでないテナントであることを確認する
   */
  assertNotPlayground(): void;
}

export const AuthorizationServiceKey = injectionKeyOf<AuthorizationService>({
  boundedContext: 'base',
  type: 'service',
  name: 'AuthorizationService',
});

export function useAuthorizationService(): AuthorizationService {
  return requiredInject(AuthorizationServiceKey);
}
