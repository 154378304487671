import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { Questionnaire, QuestionnaireId } from '../domains';
import { UseCase, UseCaseResponse } from './UseCase';

export type FinishQuestionnaireRequest = {
  id: QuestionnaireId;
};

export type FinishQuestionnaireResponse = {
  questionnaire: Questionnaire;
};

export interface FinishQuestionnaire
  extends UseCase<FinishQuestionnaireRequest, FinishQuestionnaireResponse> {
  execute(
    request: FinishQuestionnaireRequest
  ): Promise<UseCaseResponse<FinishQuestionnaireResponse>>;
}

export const FinishQuestionnaireKey = injectionKeyOf<FinishQuestionnaire>({
  boundedContext: 'training',
  type: 'usecase',
  name: 'FinishQuestionnaire',
});

export function useFinishQuestionnaire(): FinishQuestionnaire {
  return requiredInject(FinishQuestionnaireKey);
}
