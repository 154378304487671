import {
  AuthorizationService,
  ExamDataAdapter,
  GroupId,
  GroupRepository,
  localEventBus,
} from '@/base/domains';
import { groupRemoved } from '@/base/domains/LocalEvents';
import { AbstractUseCase, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { GROUP_REMOVE_FAILED_GROUP_EXAM_IS_OPEN } from '../ErrorCodes';

export interface RemoveGroupRequest {
  id: GroupId;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export type RemoveGroupResponse = {};

export interface RemoveGroup extends UseCase<RemoveGroupRequest, RemoveGroupResponse> {
  execute(request: RemoveGroupRequest): Promise<UseCaseResponse<RemoveGroupResponse>>;
}

export class RemoveGroupImpl
  extends AbstractUseCase<RemoveGroupRequest, RemoveGroupResponse>
  implements RemoveGroup
{
  constructor(
    private authorizationService: AuthorizationService,
    private groupRepository: GroupRepository,
    private examDataAdapter: ExamDataAdapter
  ) {
    super('admin.RemoveGroup');
  }

  async internalExecute(request: RemoveGroupRequest): Promise<RemoveGroupResponse> {
    this.authorizationService.assertRole('supervisor', 'admin');
    const inProgressExams = await this.examDataAdapter.findGroupExamsByGroupId(request.id, {
      inProgress: true,
    });
    if (inProgressExams.length > 0) {
      throw GROUP_REMOVE_FAILED_GROUP_EXAM_IS_OPEN.toApplicationError({
        exams: inProgressExams.map((ge) => ({
          groupExamId: ge.id,
          courseId: ge.course.id,
          courseName: ge.course.name,
          contentId: ge.content.id,
          contentName: ge.content.name,
        })),
      });
    }
    await this.groupRepository.remove(request.id);
    localEventBus.publish(groupRemoved({ groupId: request.id }));
    return {};
  }
}

export const RemoveGroupKey = injectionKeyOf<RemoveGroup>({
  boundedContext: 'admin',
  type: 'usecase',
  name: 'RemoveGroup',
});

export function useRemoveGroup(): RemoveGroup {
  return requiredInject(RemoveGroupKey);
}
