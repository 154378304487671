import {
  CourseColor,
  CourseFontColorOnImage,
  CourseId,
  CourseName,
  CourseVersion,
  UserId,
} from '@/base/domains';
import { LocalDateTime, URI } from '@/base/types';
import { config } from '@/config';

import {
  CONTENT_DUPLICATED_NAME,
  CORSE_CONTENT_LIMIT_EXCEEDED,
  COURSE_NAME_UNAVAILABLE_TO_CHANGE_IN_VERSION_2_OR_LATER,
} from '../ErrorCodes';
import { EditingCourseContentHeader, EditingCourseEntity, EditingStatus } from './EditingCourse';

type EditingCourseEntityImplArgs = {
  id: CourseId;
  name: CourseName;
  version: CourseVersion;
  status: EditingStatus;
  description?: string;
  contents: Array<EditingCourseContentHeader>;
  createdBy: UserId;
  createdAt: LocalDateTime;
  updatedBy: UserId;
  updatedAt: LocalDateTime;
  color?: CourseColor;
  image?: URI;
  fontColorOnImage?: CourseFontColorOnImage;
};

export class EditingCourseEntityImpl implements EditingCourseEntity {
  id: CourseId;

  name: CourseName;

  version: CourseVersion;

  status: EditingStatus;

  description?: string;

  contents: Array<EditingCourseContentHeader>;

  createdBy: UserId;

  createdAt: LocalDateTime;

  updatedBy: UserId;

  updatedAt: LocalDateTime;

  color?: CourseColor;

  image?: URI;

  fontColorOnImage?: CourseFontColorOnImage;

  constructor(args: EditingCourseEntityImplArgs) {
    this.id = args.id;
    this.name = args.name;
    this.version = args.version;
    this.status = args.status;
    this.description = args.description;
    this.contents = args.contents;
    this.createdBy = args.createdBy;
    this.createdAt = args.createdAt;
    this.updatedBy = args.updatedBy;
    this.updatedAt = args.updatedAt;
    this.color = args.color;
    this.image = args.image;
    this.fontColorOnImage = args.fontColorOnImage;
  }

  confirm(): EditingCourseEntity {
    return new EditingCourseEntityImpl({
      ...this,
      status: 'confirmed',
    });
  }

  changeContents(contents: Array<EditingCourseContentHeader>): EditingCourseEntity {
    if (this.contents.length >= config().app.contentLimitInCourse) {
      throw CORSE_CONTENT_LIMIT_EXCEEDED.toApplicationError({ courseId: this.id });
    }
    const names = contents.reduce((acc, c) => {
      const count = acc.get(c.name) ?? 0;
      acc.set(c.name, count + 1);
      return acc;
    }, new Map());
    const duplicated = Array.from(names.entries()).find((e) => e[1] > 1);
    if (duplicated) {
      throw CONTENT_DUPLICATED_NAME.toApplicationError({
        courseId: this.id,
        contentName: duplicated[0],
      });
    }
    return new EditingCourseEntityImpl({
      ...this,
      contents,
    });
  }

  changeName(name: CourseName): EditingCourseEntity {
    if (this.version !== 1) {
      throw COURSE_NAME_UNAVAILABLE_TO_CHANGE_IN_VERSION_2_OR_LATER.toApplicationError({
        payload: { id: this.id },
      });
    }
    return new EditingCourseEntityImpl({
      ...this,
      name,
    });
  }

  changeDescription(description: string): EditingCourseEntity {
    return new EditingCourseEntityImpl({
      ...this,
      description,
    });
  }

  changeColor(color: CourseColor): EditingCourseEntity {
    return new EditingCourseEntityImpl({
      ...this,
      color,
      image: undefined,
    });
  }

  changeImage(image: URI): EditingCourseEntity {
    return new EditingCourseEntityImpl({
      ...this,
      color: undefined,
      image,
      fontColorOnImage: 'black',
    });
  }

  changeFontColorOnImage(fontColorOnImage: CourseFontColorOnImage): EditingCourseEntity {
    return new EditingCourseEntityImpl({
      ...this,
      color: undefined,
      fontColorOnImage,
    });
  }
}
