import {
  CreateEditingCourseContentWorkbookInput,
  UpdateEditingCourseContentWorkbookInput,
} from '@/API';
import { AppContextProvider, ContentId } from '@/base/domains';
import { Optional } from '@/base/types';
import {
  EditingCourseContentWorkbookData,
  EditingCourseContentWorkbookEntity,
  EditingCourseContentWorkbookEntityImpl,
  EditingCourseContentWorkbookRepository,
} from '@/contents/domains';
import * as mutations from '@/graphql/mutations';
import * as queries from '@/graphql/queries';
import { EditingCourseContentWorkbook as AmplifyEditingCourseContentWorkbook } from '@/models';
import { graphql } from '@/utils/AmplifyUtils';
import { localDateTimeFromString } from '@/utils/DateUtils';
import { hasProperty, requiredNonNull } from '@/utils/TsUtils';

import { toAmplifyProblem, toProblem } from './Content';

function toEditingCourseContentWorkbook(
  e: AmplifyEditingCourseContentWorkbook
): EditingCourseContentWorkbookEntity {
  return new EditingCourseContentWorkbookEntityImpl({
    ...e,
    id: e.contentId,
    problems: e.problems.map(toProblem),
    problemHeaders: (e.problemHeaders ?? []).map((h) => ({
      id: h.id,
      body: h.body,
      createdAt: localDateTimeFromString(h.createdAt),
    })),
  });
}

export class AmplifyEditingCourseContentWorkbookRepository
  implements EditingCourseContentWorkbookRepository
{
  private appContextProvider: AppContextProvider;

  constructor(appContextProvider: AppContextProvider) {
    this.appContextProvider = appContextProvider;
  }

  async save(
    args: EditingCourseContentWorkbookEntity | EditingCourseContentWorkbookData
  ): Promise<EditingCourseContentWorkbookEntity> {
    const tenantCode = requiredNonNull(
      this.appContextProvider.get().tenantCode,
      'appContext.tenantCode'
    );
    const userId = requiredNonNull(this.appContextProvider.get().user?.id, 'appContext.user.id');

    if (hasProperty(args, 'dataVersion')) {
      const input: UpdateEditingCourseContentWorkbookInput = {
        contentId: args.id,
        problems: args.problems.map(toAmplifyProblem),
        createdBy: args.createdBy,
        updatedBy: userId,
        tenantCode,
        expectedDataVersion: args.dataVersion,
        courseId: args.courseId,
        problemHeaders: args.problemHeaders.map((ph) => ({
          id: ph.id,
          body: ph.body,
          createdAt: ph.createdAt.toISOString(),
        })),
      };
      const response = await graphql<{
        updateEditingCourseContentWorkbook: AmplifyEditingCourseContentWorkbook;
      }>(mutations.updateEditingCourseContentWorkbook, { input });
      return toEditingCourseContentWorkbook(response.updateEditingCourseContentWorkbook);
    }
    const input: CreateEditingCourseContentWorkbookInput = {
      contentId: args.id,
      problems: args.problems.map(toAmplifyProblem),
      createdBy: userId,
      updatedBy: userId,
      tenantCode,
      courseId: args.courseId,
      problemHeaders: args.problemHeaders.map((ph) => ({
        id: ph.id,
        body: ph.body,
        createdAt: ph.createdAt.toISOString(),
      })),
    };
    const response = await graphql<{
      createEditingCourseContentWorkbook: AmplifyEditingCourseContentWorkbook;
    }>(mutations.createEditingCourseContentWorkbook, { input });
    return toEditingCourseContentWorkbook(response.createEditingCourseContentWorkbook);
  }

  async findById(id: ContentId): Promise<Optional<EditingCourseContentWorkbookEntity>> {
    const response = await graphql<{
      getEditingCourseContentWorkbook: Optional<AmplifyEditingCourseContentWorkbook>;
    }>(queries.getEditingCourseContentWorkbook, { contentId: id });
    return response.getEditingCourseContentWorkbook
      ? toEditingCourseContentWorkbook(response.getEditingCourseContentWorkbook)
      : undefined;
  }

  async remove(id: ContentId): Promise<void> {
    await graphql(mutations.deleteEditingCourseContentWorkbook, { input: { contentId: id } });
  }
}
