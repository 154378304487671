import { AuthorizationService, CourseId } from '@/base/domains';
import { AbstractUseCase, assertEntityExists, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { CourseReference, CourseRepository } from '../domains';

export type ChangeConfirmedCourseDescriptionRequest = {
  id: CourseId;
  description: string;
};

export type ChangeConfirmedCourseDescriptionResponse = {
  course: CourseReference;
};

export interface ChangeConfirmedCourseDescription
  extends UseCase<
    ChangeConfirmedCourseDescriptionRequest,
    ChangeConfirmedCourseDescriptionResponse
  > {
  execute(
    request: ChangeConfirmedCourseDescriptionRequest
  ): Promise<UseCaseResponse<ChangeConfirmedCourseDescriptionResponse>>;
}

export class ChangeConfirmedCourseDescriptionImpl
  extends AbstractUseCase<
    ChangeConfirmedCourseDescriptionRequest,
    ChangeConfirmedCourseDescriptionResponse
  >
  implements ChangeConfirmedCourseDescription
{
  constructor(
    private authorizationService: AuthorizationService,
    private courseRepository: CourseRepository
  ) {
    super('contents.ChangeConfirmedCourseDescription');
  }

  async internalExecute(
    request: ChangeConfirmedCourseDescriptionRequest
  ): Promise<ChangeConfirmedCourseDescriptionResponse> {
    const { id, description } = request;
    this.authorizationService.assertContentEditable();
    const version = await this.courseRepository.findLatestVersion(id);
    const course = await this.courseRepository.findById({ id, version });
    assertEntityExists(course, 'course');
    const saved = await this.courseRepository.save(course.changeDescription(description));
    return {
      course: saved,
    };
  }
}

export const ChangeConfirmedCourseDescriptionKey = injectionKeyOf<ChangeConfirmedCourseDescription>(
  {
    boundedContext: 'contents',
    type: 'usecase',
    name: 'ChangeConfirmedCourseDescription',
  }
);

export function useChangeConfirmedCourseDescription(): ChangeConfirmedCourseDescription {
  return requiredInject(ChangeConfirmedCourseDescriptionKey);
}
