import { ContentId, ContentName, CourseId } from '@/base/domains';
import { AbstractUseCase, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { ContentRepository, CourseReference, CourseRepository } from '../domains';

export interface AddCourseContentRequest {
  id: CourseId;
  /** コースにおけるコンテンツのindex */
  index: number | 'first' | 'last';
  /** 名前。マスタとは異なる名前が付けられる。 */
  name: ContentName;
  /** コンテンツID */
  contentId: ContentId;
}

export type AddCourseContentResponse = {
  course: CourseReference;
};

/**
 * コンテンツ追加
 * @deprecated
 */
export interface AddCourseContent
  extends UseCase<AddCourseContentRequest, AddCourseContentResponse> {
  execute(request: AddCourseContentRequest): Promise<UseCaseResponse<AddCourseContentResponse>>;
}

export class AddCourseContentImpl
  extends AbstractUseCase<AddCourseContentRequest, AddCourseContentResponse>
  implements AddCourseContent
{
  private courseRepository: CourseRepository;

  private contentRepository: ContentRepository;

  constructor(courseRepository: CourseRepository, contentRepository: ContentRepository) {
    super('contents.AddCourseContent');
    this.courseRepository = courseRepository;
    this.contentRepository = contentRepository;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async internalExecute(request: AddCourseContentRequest): Promise<AddCourseContentResponse> {
    throw new Error('deprecated; remove soon');
  }
}

export const AddCourseContentKey = injectionKeyOf<AddCourseContent>({
  boundedContext: 'contents',
  type: 'usecase',
  name: 'AddCourseContent',
});

export function useAddCourseContent(): AddCourseContent {
  return requiredInject(AddCourseContentKey);
}
