import { Optional } from '@/base/types';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { GroupId } from '../Group';
import { GroupStatus } from '../GroupStatus';

export interface GroupStatusQueries {
  findByGroupId(groupId: GroupId): Promise<Optional<GroupStatus>>;
}

export const GroupStatusQueriesKey = injectionKeyOf<GroupStatusQueries>({
  boundedContext: 'base',
  type: 'adapter',
  name: 'GroupStatusQueries',
});

export function useGroupStatusQueries(): GroupStatusQueries {
  return requiredInject(GroupStatusQueriesKey);
}
