import { v4 } from 'uuid';

import { AppContextProvider, CourseId, CourseName, EntityData, hasId } from '@/base/domains';
import { Optional } from '@/base/types';
import {
  CourseHeaderAttributes,
  CourseHeaderEntity,
  CourseHeaderEntityImpl,
  CourseHeaderReference,
  CourseHeaderRepository,
} from '@/contents/domains';
import { requiredNonNull } from '@/utils/TsUtils';

export class InMemoryCourseHeaderRepository implements CourseHeaderRepository {
  private store = new Map<CourseId, CourseHeaderEntity>();

  private appContextProvider: AppContextProvider;

  constructor(appContextProvider: AppContextProvider) {
    this.appContextProvider = appContextProvider;
  }

  save(
    args: CourseHeaderEntity | EntityData<string, CourseHeaderAttributes>
  ): Promise<CourseHeaderEntity> {
    const createdBy = requiredNonNull(this.appContextProvider.get().user?.id, 'appContext.user.id');
    if (hasId(args)) {
      const e = new CourseHeaderEntityImpl({
        ...args,
      });
      this.store.set(e.id, e);
      return Promise.resolve(e);
    }
    const e = new CourseHeaderEntityImpl({
      ...args,
      id: v4(),
      createdBy,
    });
    this.store.set(e.id, e);
    return Promise.resolve(e);
  }

  findById(id: CourseId): Promise<Optional<CourseHeaderEntity>> {
    return Promise.resolve(this.store.get(id));
  }

  remove(id: CourseId): Promise<void> {
    this.store.delete(id);
    return Promise.resolve();
  }

  tenantCourses(): Promise<CourseHeaderReference[]> {
    return Promise.resolve(Array.from(this.store.values()));
  }

  async findByName(name: CourseName): Promise<Optional<CourseHeaderReference>> {
    return (await this.tenantCourses()).find((c) => c.name === name);
  }

  async changeName(_id: string, _name: string): Promise<void> {
    // do nothing
  }
}
