import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { Questionnaire, QuestionnaireId } from '../domains';
import { UseCase, UseCaseResponse } from './UseCase';

export type GetQuestionnaireRequest = {
  id: QuestionnaireId;
};

export type GetQuestionnaireResponse = {
  questionnaire?: Questionnaire;
};

export interface GetQuestionnaire
  extends UseCase<GetQuestionnaireRequest, GetQuestionnaireResponse> {
  execute(request: GetQuestionnaireRequest): Promise<UseCaseResponse<GetQuestionnaireResponse>>;
}

export const GetQuestionnaireKey = injectionKeyOf<GetQuestionnaire>({
  boundedContext: 'training',
  type: 'usecase',
  name: 'GetQuestionnaire',
});

export function useGetQuestionnaire(): GetQuestionnaire {
  return requiredInject(GetQuestionnaireKey);
}
