import { computed, Ref, ref } from '@vue/composition-api';
import { useTimeoutFn } from '@vueuse/core';

import { NotificationReference } from '@/base/domains';
import { Optional } from '@/base/types';
import { isSucceeded, useGetNotifications } from '@/base/usecases';
import { config } from '@/config';
import { createLogger } from '@/utils/log';

const logger = createLogger({ boundedContext: 'base', name: 'NotificationsData' });

export type NotificationTimer = {
  start: () => void;
  stop: () => void;
  clear: (id: string) => void;
  fetch: () => void;
};

export function useNotificationData() {
  const newArrivals = ref<NotificationReference[]>([]);
  const unReadNotificationIds = ref<string[]>([]);
  const existsUnRead = computed(() => unReadNotificationIds.value.length > 0);

  const getNotifications = useGetNotifications();
  async function fetchNotifications() {
    const res = await getNotifications.execute({ read: 'unread' });
    if (isSucceeded(res)) {
      const notifications = res.notifications.filter(
        (n) => !unReadNotificationIds.value.includes(n.id)
      );
      newArrivals.value.push(...notifications);
      unReadNotificationIds.value = res.notifications.map((n) => n.id);
    }
  }

  function removeNewArrival(id: string) {
    const index = newArrivals.value.findIndex((n) => n.id === id);
    logger.debug({ message: 'hide notification toast.', index, id });
    if (index === -1) return;
    newArrivals.value.splice(index, 1);
  }

  let timer: Optional<{ ready: Ref<boolean>; start: () => void; stop: () => void }>;
  function start() {
    logger.debug({
      message: 'timer start.',
      interval: config().app.notifications,
      noTimer: !timer,
    });
    fetchNotifications();
    if (timer) timer.stop();
    if (config().app.notifications < 0) return;
    timer = useTimeoutFn(async () => {
      logger.debug({ message: 'timer fired.' });
      await fetchNotifications();
      if (timer) timer.start();
    }, config().app.notifications * 1000);
  }

  function stop() {
    logger.debug({ message: 'timer stop.', timer: !timer, ready: timer?.ready });
    if (timer) timer.stop();
    timer = undefined;
  }

  return {
    existsUnRead,
    notifications: newArrivals,
    notificationTimer: {
      start,
      stop,
      clear: removeNewArrival,
      fetch: fetchNotifications,
    } as NotificationTimer,
  };
}
