import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { CourseReference, CourseRepository } from '../domains';

export const GetCoursesKey = injectionKeyOf<GetCourses>({
  boundedContext: 'contents',
  type: 'usecase',
  name: 'GetCourses',
});

export function useGetCourses(): GetCourses {
  return requiredInject(GetCoursesKey);
}

export interface GetCourses {
  execute(): Promise<Array<CourseReference>>;
}

export class GetCoursesImpl implements GetCourses {
  private courseRepository: CourseRepository;

  constructor(courseRepository: CourseRepository) {
    this.courseRepository = courseRepository;
  }

  execute(): Promise<Array<CourseReference>> {
    return this.courseRepository.findTenantCourses();
  }
}
