import { AuthorizationService, ReviewProblemId } from '@/base/domains';
import { AbstractUseCase, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { ReviewProblemRepository } from '../domains';

export type RemoveReviewProblemProblemsRequest = {
  id: ReviewProblemId;
  hashListToRemove: string[];
};

export type RemoveReviewProblemProblemsResponse = {};

/**
 * 復習問題の問題リストを削除する
 */
export interface RemoveReviewProblemProblems
  extends UseCase<RemoveReviewProblemProblemsRequest, RemoveReviewProblemProblemsResponse> {
  execute(
    request: RemoveReviewProblemProblemsRequest
  ): Promise<UseCaseResponse<RemoveReviewProblemProblemsResponse>>;
}

export class RemoveReviewProblemProblemsImpl
  extends AbstractUseCase<RemoveReviewProblemProblemsRequest, RemoveReviewProblemProblemsResponse>
  implements RemoveReviewProblemProblems
{
  constructor(
    private authorizationService: AuthorizationService,
    private reviewProblemRepository: ReviewProblemRepository
  ) {
    super('training.RemoveReviewProblemProblems');
  }

  async internalExecute({
    id,
    hashListToRemove,
  }: RemoveReviewProblemProblemsRequest): Promise<RemoveReviewProblemProblemsResponse> {
    const reviewProblem = await this.reviewProblemRepository.findById(id);
    if (!reviewProblem) {
      return {};
    }
    this.authorizationService.assertOwnerAccessible(reviewProblem.userId);
    await this.reviewProblemRepository.save(reviewProblem.finish(hashListToRemove));
    return {};
  }
}

export const RemoveReviewProblemProblemsKey = injectionKeyOf<RemoveReviewProblemProblems>({
  boundedContext: 'training',
  type: 'usecase',
  name: 'RemoveReviewProblemProblems',
});

export function useRemoveReviewProblemProblems(): RemoveReviewProblemProblems {
  return requiredInject(RemoveReviewProblemProblemsKey);
}
