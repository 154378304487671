import { PlaygroundEntity } from '@/playground/domains';

export const playgrounds: Array<PlaygroundEntity> = [
  {
    id: 'playground-00',
    status: 'reserved',
    email: 'email@citycom.co.jp',
    tenantCode: 'tenant-code',
    tenantName: 'tenant-name',
    userCode: 'user-code',
    temporaryPassword: 'password',
    startCreating: () => playgrounds[0],
  },
  {
    id: 'playground-01',
    status: 'creating',
    email: 'email@citycom.co.jp',
    tenantCode: 'tenant-code',
    tenantName: 'tenant-name',
    userCode: 'user-code',
    temporaryPassword: 'password',
    startCreating: () => playgrounds[1],
  },
  {
    id: 'playground-02',
    status: 'created',
    email: 'email@citycom.co.jp',
    tenantCode: 'tenant-code',
    tenantName: 'tenant-name',
    userCode: 'user-code',
    temporaryPassword: 'password',
    startCreating: () => playgrounds[2],
  },
  {
    id: 'playground-03',
    status: 'used',
    email: 'email@citycom.co.jp',
    tenantCode: 'tenant-code',
    tenantName: 'tenant-name',
    userCode: 'user-code',
    temporaryPassword: 'password',
    startCreating: () => playgrounds[3],
  },
];
