import {
  ContentBody,
  ContentId,
  ContentName,
  DataVersion,
  Entity,
  Problem,
  ProblemHeaderId,
  Repository,
} from '@/base/domains';
import { LocalDateTime, MarkDownString, Minute } from '@/base/types';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { ExamContentAttributes, TextContentAttributes } from './Content';
import { EditingStatus } from './EditingCourse';
import { WorkbookData } from './Workbook';

type EditingConfirmedContentAttributesOf<T> = Omit<T, 'latest'> & {
  dataVersion: DataVersion;
  status: EditingStatus;
};

type ExamEditingConfirmedContentAttributes =
  EditingConfirmedContentAttributesOf<ExamContentAttributes>;

type TextEditingConfirmedContentAttributes =
  EditingConfirmedContentAttributesOf<TextContentAttributes>;

/**
 * 編集中確定済みコンテンツの属性。
 */
export type EditingConfirmedContentAttributes =
  | ExamEditingConfirmedContentAttributes
  | TextEditingConfirmedContentAttributes;

type EditingConfirmedContentDataOf<T> = Omit<T, 'dataVersion'> & {
  id: ContentId;
  dataVersion?: DataVersion;
};

/**
 * 編集中確定済みコンテンツデータ
 */
export type EditingConfirmedContentData =
  | EditingConfirmedContentDataOf<
      Omit<TextEditingConfirmedContentAttributes, 'workbook'> & { workbook?: WorkbookData }
    >
  | EditingConfirmedContentDataOf<ExamEditingConfirmedContentAttributes>;

export type EditingConfirmedContentEntityUpdateArgs = {
  name: ContentName;
  requiredTime: Minute;
  body: ContentBody;
  workbook?: WorkbookData;
};

export type EditingConfirmedContentQueries = {};

export type EditingConfirmedContentCommands = {
  changeBody(body: ContentBody): EditingConfirmedContentEntity;
  confirm(versionDescription?: string): EditingConfirmedContentEntity;
};

/**
 * 編集中確定済みコンテンツリファレンス
 */
export type EditingConfirmedContentReference = Readonly<EditingConfirmedContentAttributes> &
  EditingConfirmedContentQueries & {
    readonly id: ContentId;
  };

export type ExamEditingConfirmedContentEntity = Entity<
  ContentId,
  ExamEditingConfirmedContentAttributes
> &
  EditingConfirmedContentQueries &
  EditingConfirmedContentCommands & {
    changeProblemHeader(args: {
      problemHeaderId: ProblemHeaderId;
      problemHeaderBody: MarkDownString;
    }): EditingConfirmedContentEntity;
    addProblemHeader(args: {
      problemHeaderBody: MarkDownString;
      createdAt: LocalDateTime;
    }): EditingConfirmedContentEntity;
    removeProblemHeader(problemHeaderId: ProblemHeaderId): EditingConfirmedContentEntity;
    changePassingStandard(passingStandard?: number): EditingConfirmedContentEntity;
    changeProblems(problems: Array<Problem>): EditingConfirmedContentEntity;
  };

export type TextEditingConfirmedContentEntity = Entity<
  ContentId,
  TextEditingConfirmedContentAttributes
> &
  EditingConfirmedContentQueries &
  EditingConfirmedContentCommands & {
    changeWorkbookProblemHeader(args: {
      problemHeaderId: ProblemHeaderId;
      problemHeaderBody: MarkDownString;
    }): EditingConfirmedContentEntity;
    addProblemHeaderToWorkbook(args: {
      problemHeaderBody: MarkDownString;
      createdAt: LocalDateTime;
    }): EditingConfirmedContentEntity;
    removeWorkbookProblemHeader(problemHeaderId: ProblemHeaderId): EditingConfirmedContentEntity;
    changeWorkbookProblems(problems: Array<Problem>): EditingConfirmedContentEntity;
  };

/**
 * 編集中確定済みコンテンツエンティティ
 */
export type EditingConfirmedContentEntity =
  | ExamEditingConfirmedContentEntity
  | TextEditingConfirmedContentEntity;

/**
 * 編集中確定済みコンテンツリポジトリ
 */
export type EditingConfirmedContentRepository = Repository<
  ContentId,
  EditingConfirmedContentEntity,
  EditingConfirmedContentData
>;

export const EditingConfirmedContentRepositoryKey =
  injectionKeyOf<EditingConfirmedContentRepository>({
    boundedContext: 'contents',
    type: 'adapter',
    name: 'EditingConfirmedContentRepository',
  });

export function useEditingConfirmedContentRepository(): EditingConfirmedContentRepository {
  return requiredInject(EditingConfirmedContentRepositoryKey);
}
