import {
  ChoiceReviewProblemProblem,
  CourseId,
  GroupId,
  ReviewProblemId,
  ReviewProblemProblem,
  UserId,
} from '@/base/domains';
import { isString } from '@/utils/TsUtils';

import {
  attributesFromReviewProblemId,
  problemHashFromProblem,
  ReviewProblemEntity,
  reviewProblemIdOf,
} from './ReviewProblem';

/**
 * 復習問題エンティティ実装 コンストラクタ引数
 */
export type ReviewProblemEntityImplArgs = {
  /** 復習問題ID */
  id: string;

  /** ユーザーID */
  userId: string;

  /** グループID */
  groupId: string;

  /** コースID */
  courseId: string;

  /** 復習問題リスト */
  problems: Omit<ReviewProblemProblem, 'index' | 'hash'>[];
};

/**
 * 復習問題エンティティ実装
 */
export class ReviewProblemEntityImpl implements ReviewProblemEntity {
  /** 復習問題ID */
  id: string;

  /** ユーザーID */
  userId: string;

  /** グループID */
  groupId: string;

  /** コースID */
  courseId: string;

  /** 復習問題リスト */
  problems: ChoiceReviewProblemProblem[];

  constructor(args: ReviewProblemEntityImplArgs) {
    this.id = args.id;
    this.userId = args.userId;
    this.groupId = args.groupId;
    this.courseId = args.courseId;
    this.problems = args.problems.map((p, index) => ({
      ...p,
      index,
      hash: problemHashFromProblem(p),
    }));
  }

  removeProblems(hashListToRemove: string[]): ReviewProblemEntity {
    return new ReviewProblemEntityImpl({
      id: this.id,
      userId: this.userId,
      groupId: this.groupId,
      courseId: this.courseId,
      problems: this.problems.filter((p) => !hashListToRemove.includes(p.hash)),
    });
  }

  changeProblems(
    problems: Omit<ReviewProblemProblem, 'index' | 'hash'>[],
    problemsToRemove: Pick<ReviewProblemProblem, 'body' | 'answer' | 'options'>[]
  ): ReviewProblemEntity {
    const hashListToRemove = problemsToRemove.map((p) => problemHashFromProblem(p));
    const changedProblems = this.problems
      .filter((p) => !hashListToRemove.includes(p.hash))
      // hash, indexを除外する
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .map(({ hash, index, ...p }) => p)
      .concat(problems);
    return new ReviewProblemEntityImpl({
      id: this.id,
      userId: this.userId,
      groupId: this.groupId,
      courseId: this.courseId,
      problems: changedProblems,
    });
  }

  finish(hashListToRemove: string[]): ReviewProblemEntity {
    return this.removeProblems(hashListToRemove);
  }
}

export function emptyReviewProblemEntity(
  args:
    | {
        userId: UserId;
        groupId: GroupId;
        courseId: CourseId;
      }
    | ReviewProblemId
): ReviewProblemEntity {
  const { userId, groupId, courseId } = isString(args) ? attributesFromReviewProblemId(args) : args;
  return new ReviewProblemEntityImpl({
    id: reviewProblemIdOf({ groupId, courseId, userId }),
    userId,
    groupId,
    courseId,
    problems: [],
  });
}
