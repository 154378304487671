import './plugins/amplify';
import './plugins/composition-api';
import './plugins/highlight-js';
import './plugins/moment/locales';
import './plugins/vee-validate';
import './scss/index.scss';

import Vue from 'vue';

import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';

Vue.config.productionTip = false;

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
