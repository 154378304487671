import { GroupId, GroupStatusService, GroupStatusUser, Subscription, UserId } from '@/base/domains';

export class InMemoryGroupStatusService implements GroupStatusService {
  async raiseHand({
    userId,
    handStatus,
  }: {
    userId: UserId;
    groupId: GroupId;
    handStatus: 'yes' | 'no';
  }): Promise<GroupStatusUser> {
    return {
      userId,
      handStatus,
    };
  }

  async lowerHand({ userId }: { userId: UserId; groupId: GroupId }): Promise<GroupStatusUser> {
    return {
      userId,
      handStatus: 'none',
    };
  }

  clearHandStatuses(_: GroupId): Promise<void> {
    return Promise.resolve();
  }

  subscribeGroupStatusUserChanged(_: {
    groupId: GroupId;
    onChange: (groupStatusUser: GroupStatusUser, removed: boolean) => void;
    onError: (e: Error) => void;
  }): Subscription {
    return {
      unsubscribe: () => {
        // do nothing
      },
      isClosed: () => false,
    };
  }
}
