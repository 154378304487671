import { ComputedRef } from '@vue/composition-api';

import { GroupId, GroupRole, Tenant, UserId } from '@/base/domains';
import { Optional } from '@/base/types';

import { GlobalStoreGroup, GlobalStoreLimitationName, GlobalStoreLimitationNames } from './types';

const Results = ['enabled', 'disabled', 'unchecked'] as const;
type ValidateLimitationResult = typeof Results[number];

type PropsLimitation = {
  tenant: ComputedRef<Optional<Tenant>>;
  groups: ComputedRef<Optional<GlobalStoreGroup[]>>;
  group: ComputedRef<Optional<GlobalStoreGroup>>;
  user: ComputedRef<Optional<{ id: UserId }>>;
};

export function useLimitation(props: PropsLimitation) {
  function getGroup(groupId?: GroupId) {
    return groupId ? props.groups.value?.find((g) => g.id === groupId) : props.group.value;
  }

  function getGroupRole(groupId?: GroupId) {
    if (!props.user.value) return undefined;
    const { id: uId } = props.user.value;
    const group = getGroup(groupId);
    return group?.users.find((u) => u.id === uId && !u.removed)?.role;
  }

  function checkRole(allowedRoles: GroupRole[], groupId?: GroupId): ValidateLimitationResult {
    const role = getGroupRole(groupId);
    if (!role) return 'unchecked';
    if (allowedRoles.includes(role)) return 'enabled';
    return 'disabled';
  }

  function isLimitationName(x: unknown): x is GlobalStoreLimitationName {
    return typeof x === 'string' && (GlobalStoreLimitationNames as unknown as string[]).includes(x);
  }

  function checkLimitation(
    name: GlobalStoreLimitationName,
    adminMode: boolean,
    groupId?: GroupId
  ): ValidateLimitationResult {
    if (!props.tenant.value || !props.user.value) return 'unchecked';

    const { limitations: tl } = props.tenant.value;
    if (name in tl && tl[name] === 'disabled') return 'disabled';

    const group = getGroup(groupId);
    if (!group) {
      if (groupId) return 'unchecked';
      return 'enabled';
    }

    const { limitations: gl } = group;
    if (name in gl) {
      const roles = gl[name] as GroupRole[];
      if (roles.length === 0) return 'disabled';
      if (adminMode) return 'enabled';
      return checkRole(roles, groupId);
    }

    return 'enabled';
  }

  function getTenantMenusAvailable(): GlobalStoreLimitationName[] {
    return GlobalStoreLimitationNames.filter(
      (name) => name.startsWith('tenant') && checkLimitation(name, true) === 'enabled'
    );
  }

  function getGroupMenusAvailable(
    adminMode: boolean,
    groupId?: GroupId
  ): GlobalStoreLimitationName[] {
    return GlobalStoreLimitationNames.filter(
      (name) =>
        !name.startsWith('tenant') && checkLimitation(name, adminMode, groupId) === 'enabled'
    );
  }

  return {
    checkRole,
    isLimitationName,
    checkLimitation,
    getTenantMenusAvailable,
    getGroupMenusAvailable,
  };
}
