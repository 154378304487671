import { DataVersion } from '../domains';
import { ApplicationError } from '../error';
import { ENTITY_NOT_FOUND, EXCLUSIVE_CONTROL_ERROR } from '../ErrorCodes';

export class EntityNotFoundError extends ApplicationError {
  readonly entityName: string;

  constructor(entityName: string) {
    super({
      code: ENTITY_NOT_FOUND.code,
      message: ENTITY_NOT_FOUND.message,
      payload: {
        entityName,
      },
    });
    this.entityName = entityName;
  }
}

export function assertEntityExists<E>(
  entity: E,
  entityName: string
): asserts entity is NonNullable<E> {
  if (entity === undefined || entity === null) {
    throw new EntityNotFoundError(entityName);
  }
}

export function assertDataVersion(
  entity: { dataVersion: DataVersion },
  expectedDataVersion: DataVersion,
  entityName: string
): void {
  if (entity.dataVersion !== expectedDataVersion) {
    throw EXCLUSIVE_CONTROL_ERROR.toApplicationError({ entity: entityName });
  }
}
