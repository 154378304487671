import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { LocalDateTime } from '../types';
import { UserId } from './Core';
import { EntityLike, StandardRepository } from './Entity';

export type ReadType = 'read' | 'unread';

export type NotificationType<P extends object = {}> = symbol;

export function notificationTypeOf<P extends object = {}>(value: string): NotificationType<P> {
  return Symbol.for(value);
}

export type NotificationId = string;

export interface NotificationAttributes<P extends object = {}> {
  /** 通知タイプ */
  type: NotificationType<P>;
  /** 通知に必要なデータが入る */
  payload: P;
  /** 通知対象のユーザーID */
  userId: UserId;
  /** 既読 */
  read: ReadType;
  /** 作成日時 */
  createdAt: LocalDateTime;
}

export interface NotificationCommands<P extends object> {
  /**
   * 既読切り替え
   */
  toggleRead(): NotificationEntity<P>;
}

export interface NotificationQueries {
  asTypeOf<P extends object>(type: NotificationType<P>): NotificationEntity<P>;
}

export interface NotificationReference<P extends object = {}>
  extends Readonly<EntityLike<NotificationId, NotificationAttributes<P>>>,
    NotificationQueries {}

export interface NotificationEntity<P extends object = {}>
  extends NotificationReference<P>,
    NotificationCommands<P> {
  readonly id: NotificationId;
  readonly createdAt: LocalDateTime;
}

export type NotificationData<P extends object = {}> = EntityLike<
  NotificationId,
  Omit<NotificationAttributes<P>, 'createdAt'>
> & {
  /** 作成日時 */
  createdAt?: LocalDateTime;
};

export interface NotificationRepository
  extends StandardRepository<NotificationId, NotificationAttributes, NotificationEntity> {
  findByUserId(userId: UserId): Promise<Array<NotificationEntity>>;
}

export const NotificationRepositoryKey = injectionKeyOf<NotificationRepository>({
  boundedContext: 'base',
  type: 'adapter',
  name: 'NotificationRepositoryKey',
});

export function useNotificationRepository(): NotificationRepository {
  return requiredInject(NotificationRepositoryKey);
}
