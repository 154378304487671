import { RouteConfig } from 'vue-router';

const groupCourseReviewPage = import(
  /* webpackChunkName: "training" */ './pages/GroupCourseReviewPage.vue'
);
const GroupContentPage = import(/* webpackChunkName: "training" */ './pages/GroupContentPage.vue');
const GroupQuestionsPage = import(
  /* webpackChunkName: "training" */ './pages/GroupQuestionsPage.vue'
);
const GroupQuestionPage = import(
  /* webpackChunkName: "training" */ './pages/GroupQuestionPage.vue'
);
const GroupSchedulesPage = import(
  /* webpackChunkName: "training" */ './pages/GroupSchedulesPage.vue'
);
const GroupUserExamPage = import(
  /* webpackChunkName: "training" */ './pages/GroupUserExamPage.vue'
);
const TrainerGroupCoursesPage = import(
  /* webpackChunkName: "training" */ './pages/TrainerGroupCoursesPage.vue'
);
const TrainerGroupCoursePage = import(
  /* webpackChunkName: "training" */ './pages/TrainerGroupCoursePage.vue'
);
const TrainerGroupExamsPage = import(
  /* webpackChunkName: "training" */ './pages/TrainerGroupExamsPage.vue'
);
const GroupQuestionnairesPage = import(
  /* webpackChunkName: "home" */ './pages/GroupQuestionnairesPage.vue'
);

export const trainingRoutes: Array<RouteConfig> = [
  {
    path: '/groups/:id/courses/:courseId/review',
    name: 'groupCourseReview',
    component: () => groupCourseReviewPage,
    props: true,
  },
  {
    path: '/groups/:id/courses/:courseId/:contentId/v/:contentVersion',
    name: 'groupContentVersion',
    component: () => GroupContentPage,
    props: (route) => ({ ...route.query, ...route.params }),
  },
  {
    path: '/groups/:id/courses/:courseId/:contentId',
    name: 'groupContent',
    component: () => GroupContentPage,
    props: (route) => ({ ...route.query, ...route.params }),
  },
  {
    path: '/groups/:id/exams/:examId',
    name: 'groupUserExam',
    component: () => GroupUserExamPage,
    props: true,
  },
  {
    path: '/groups/:id/questions',
    name: 'groupQuestions',
    component: () => GroupQuestionsPage,
    props: (route) => ({ ...route.query, ...route.params }),
  },
  {
    path: '/groups/:id/questions/:questionId',
    name: 'groupQuestion',
    component: () => GroupQuestionPage,
    props: true,
  },
  {
    path: '/groups/:id/schedules',
    name: 'groupSchedules',
    component: () => GroupSchedulesPage,
    props: true,
  },
  {
    path: '/trainer/groups/:id/courses',
    name: 'trainerGroupCourses',
    component: () => TrainerGroupCoursesPage,
    props: true,
  },
  {
    path: '/trainer/groups/:id/courses/:courseId',
    name: 'trainerGroupCourse',
    component: () => TrainerGroupCoursePage,
    props: (route) => {
      const edit = 'edit' in route.query && route.query.edit !== undefined;
      return { ...route.query, ...route.params, edit };
    },
  },
  {
    path: '/trainer/groups/:id/exams',
    name: 'trainerGroupExams',
    component: () => TrainerGroupExamsPage,
    props: (route) => {
      const edit = 'edit' in route.query && route.query.edit !== undefined;
      return { ...route.query, ...route.params, edit };
    },
  },
  {
    path: '/groups/:id/questionnaires',
    name: 'groupQuestionnaires',
    component: () => GroupQuestionnairesPage,
    props: true,
  },
];
