import moment from 'moment';
import { v4 } from 'uuid';

import {
  EntityData,
  isEntityLike,
  NotificationAttributes,
  NotificationData,
  NotificationEntity,
  NotificationId,
  NotificationRepository,
  UserId,
} from '@/base/domains';
import { NotificationEntityImpl } from '@/base/domains/NotificationEntityImpl';
import { Optional } from '@/base/types';

export class InMemoryNotificationRepository implements NotificationRepository {
  private store: Map<NotificationId, NotificationEntity> = new Map();

  save(
    entity: EntityData<NotificationId, NotificationAttributes>
  ): Promise<NotificationEntity<{}>> {
    const e = isEntityLike<NotificationData>(entity)
      ? new NotificationEntityImpl(entity)
      : new NotificationEntityImpl({ ...entity, id: v4(), createdAt: moment() });
    this.store.set(e.id, e);
    return Promise.resolve(e);
  }

  findById(id: NotificationId): Promise<Optional<NotificationEntity<{}>>> {
    return Promise.resolve(this.store.get(id));
  }

  remove(id: NotificationId): Promise<void> {
    this.store.delete(id);
    return Promise.resolve();
  }

  findByUserId(userId: UserId): Promise<NotificationEntity<{}>[]> {
    return Promise.resolve(Array.from(this.store.values()).filter((n) => n.userId === userId));
  }
}
