import { logLevel } from './env';
import { Config } from './types';

const docsUrl = 'https://docs.knowte-test.link';
const local = window.location.hostname === 'localhost';
const config: Config = {
  envType: 'node',
  repositoryType: 'inMemory',
  diType: 'test',
  logLevel: logLevel ?? 'error',
  production: false,
  app: {
    notifications: -1,
    fetchRetry: { interval: -1, max: 0 },
    transitionDelay: 2,
    transitionDelayLong: 6,
    signedPolicyRefreshBeforeLimit: 0,
    editorTutorialUrl: `${docsUrl}/editor`,
    helpUrl: `${docsUrl}`,
    confirmCourseHelpUrl: `${docsUrl}/for-administrators/e-learning/#_3-1-コース確定時の注意事項`,
    groupLimitationHelpUrl: `${docsUrl}/for-administrators/options`,
    privacyPolicyUrl: `${docsUrl}/privacy-policy`,
    releaseNoteUrl: `${docsUrl}/release-notes`,
    playgroundTermsOfServiceUrl: `${docsUrl}/playground-terms-of-service`,
    userLimit: 1000,
    userLimitInGroup: 200,
    contentLimitInCourse: 100,
    courseLimitInGroup: 100,
    upload: {
      contentFileSizeLimit: 10 * 1024 * 1024,
      videoContentFileSizeLimit: 300 * 1024 * 1024,
      defaultFileSizeLimit: 1 * 1024 * 1024,
    },
  },
  local,
};

export default config;
