import { CreateLiveInput } from '@/API';
import { AppContextProvider, Surviver } from '@/base/domains';
import { createLive } from '@/graphql/mutations';
import { graphql } from '@/utils/AmplifyUtils';
import { assertIsDefined } from '@/utils/Asserts';

export class AmplifySurviver implements Surviver {
  private appContextProvider: AppContextProvider;

  constructor(appContextProvider: AppContextProvider) {
    this.appContextProvider = appContextProvider;
  }

  async live(): Promise<void> {
    const { user } = this.appContextProvider.get();
    if (user) {
      const url = window.location.href;
      const { applicationSessionId, tenantCode } = this.appContextProvider.get();
      assertIsDefined(tenantCode, 'appContext.tenantCode');
      const input: CreateLiveInput = {
        userId: user.id,
        applicationSessionId,
        url,
        tenantCode,
      };
      await graphql(createLive, { input });
    }
  }
}
