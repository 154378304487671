import {
  InternalTask,
  InternalTaskError,
  InternalTaskPayload,
  InternalTaskService,
  InternalTaskServiceStartRunner,
  InternalTaskType,
} from '@/base/domains';

export class InMemoryInternalTaskService implements InternalTaskService {
  start<P extends InternalTaskPayload>(
    _type: InternalTaskType<P>,
    _taskVersion?: number
  ): InternalTaskServiceStartRunner<InternalTaskType<P>, P> {
    throw new Error('Method not implemented.');
  }

  get<T extends InternalTaskType<P>, P extends InternalTaskPayload>(
    _id: string
  ): Promise<InternalTask<T, P>> {
    throw new Error('Method not implemented.');
  }

  subscribeTaskFinished<T extends InternalTaskType<P>, P extends InternalTaskPayload>(_: {
    id: string;
    onFinished: (task: InternalTask<T, P>) => void;
    onError: (errors: InternalTaskError[]) => void;
  }): void {
    throw new Error('Method not implemented.');
  }
}
