import { AuthorizationService, Group, GroupId, GroupRepository } from '@/base/domains';
import { AbstractUseCase, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

export type GetGroupByAdminRequest = {
  groupId: GroupId;
};

export type GetGroupByAdminResponse = {
  group?: Group;
};

export interface GetGroupByAdmin extends UseCase<GetGroupByAdminRequest, GetGroupByAdminResponse> {
  execute(request: GetGroupByAdminRequest): Promise<UseCaseResponse<GetGroupByAdminResponse>>;
}

export class GetGroupByAdminImpl
  extends AbstractUseCase<GetGroupByAdminRequest, GetGroupByAdminResponse>
  implements GetGroupByAdmin
{
  constructor(
    private authorizationService: AuthorizationService,
    private groupRepository: GroupRepository
  ) {
    super('admin.GetGroupByAdmin');
  }

  async internalExecute(request: GetGroupByAdminRequest): Promise<GetGroupByAdminResponse> {
    this.authorizationService.assertRole('supervisor', 'admin');
    const { groupId } = request;
    const group = await this.groupRepository.findById(groupId, { includeDisabledGroup: true });
    return { group };
  }
}

export const GetGroupByAdminKey = injectionKeyOf<GetGroupByAdmin>({
  boundedContext: 'admin',
  type: 'usecase',
  name: 'GetGroupByAdmin',
});

export function useGetGroupByAdmin(): GetGroupByAdmin {
  return requiredInject(GetGroupByAdminKey);
}
