import { config } from '@/config';
import { createLogger } from '@/utils/log';

import { amplifyAdapters } from './AmplifyAdapters';
import { components } from './Components';
import { addMockDataPostSignIn, inMemoryAdapters } from './InMemoryAdapters';
import { services } from './Services';
import { useUseCases } from './usecases';

export { addMockDataPostSignIn };

const logger = createLogger({ boundedContext: 'di', name: 'index' });

export function configureDi() {
  logger.debug(`configure dependency injection; repository type=${config().repositoryType}`);

  components();
  if (config().repositoryType === 'amplify') {
    amplifyAdapters();
  } else {
    inMemoryAdapters();
  }
  services();
  useUseCases();
}
