import {
  AppContextProvider,
  ContentId,
  CourseColor,
  CourseDisplayName,
  CourseFontColorOnImage,
  CourseId,
  GroupId,
  ReviewProblemId,
} from '@/base/domains';
import { Optional, URI } from '@/base/types';
import { AbstractUseCase, assertEntityExists, UseCase, UseCaseResponse } from '@/base/usecases';
import { requiredNonNull } from '@/utils/TsUtils';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { emptyReviewProblemEntity, GroupTrainingCourseRepository } from '../domains';
import { ReviewProblemReference, ReviewProblemRepository } from '../domains/ReviewProblem';

export type GetReviewProblemRequest =
  | {
      id: ReviewProblemId;
      contentId?: ContentId;
    }
  | {
      groupId: GroupId;
      courseId: CourseId;
      contentId?: ContentId;
    };

export type GetReviewProblemResponse = {
  reviewProblem?: ReviewProblemReference;
  course?: {
    name: CourseDisplayName;
    color?: CourseColor;
    image?: URI;
    fontColorOnImage?: CourseFontColorOnImage;
  };
};

/**
 * 復習問題を取得する
 */
export interface GetReviewProblem
  extends UseCase<GetReviewProblemRequest, GetReviewProblemResponse> {
  execute(request: GetReviewProblemRequest): Promise<UseCaseResponse<GetReviewProblemResponse>>;
}

function filterProblemByContentId(
  reviewProblem?: ReviewProblemReference,
  contentId?: ContentId
): Optional<ReviewProblemReference> {
  if (!reviewProblem) {
    return reviewProblem;
  }
  if (contentId) {
    return {
      ...reviewProblem,
      problems: reviewProblem.problems.filter((p) => p.origin.contentId === contentId),
    };
  }
  return reviewProblem;
}

export class GetReviewProblemImpl
  extends AbstractUseCase<GetReviewProblemRequest, GetReviewProblemResponse>
  implements GetReviewProblem
{
  constructor(
    private reviewProblemRepository: ReviewProblemRepository,
    private groupTrainingCourseRepository: GroupTrainingCourseRepository,
    private appContextProvider: AppContextProvider
  ) {
    super('training.GetReviewProblem');
  }

  async internalExecute(request: GetReviewProblemRequest): Promise<GetReviewProblemResponse> {
    if ('id' in request) {
      const { id, contentId } = request;
      const reviewProblem =
        (await this.reviewProblemRepository.findById(id)) ?? emptyReviewProblemEntity(id);
      const course = await (async () => {
        if (reviewProblem) {
          const cr = await this.groupTrainingCourseRepository.findByGroupIdAndCourseId(
            reviewProblem.groupId,
            reviewProblem.courseId
          );
          assertEntityExists(cr, 'course');
          return {
            name: cr.displayName,
            color: cr.color,
            image: cr.image,
            fontColorOnImage: cr.fontColorOnImage,
          };
        }
        return undefined;
      })();
      return {
        reviewProblem: filterProblemByContentId(reviewProblem, contentId),
        course,
      };
    }

    const { groupId, courseId, contentId } = request;
    const [reviewProblem, course] = await Promise.all([
      this.reviewProblemRepository
        .findMyReviewProblem({
          groupId,
          courseId,
        })
        .then(
          (rp) =>
            rp ??
            emptyReviewProblemEntity({
              groupId,
              courseId,
              userId: requiredNonNull(this.appContextProvider.get().user?.id, 'appContext.user'),
            })
        ),
      this.groupTrainingCourseRepository.findByGroupIdAndCourseId(groupId, courseId),
    ]);
    return {
      reviewProblem: filterProblemByContentId(reviewProblem, contentId),
      course: course
        ? {
            name: course.displayName,
            color: course.color,
            image: course.image,
            fontColorOnImage: course.fontColorOnImage,
          }
        : undefined,
    };
  }
}

export const GetReviewProblemKey = injectionKeyOf<GetReviewProblem>({
  boundedContext: 'training',
  type: 'usecase',
  name: 'GetReviewProblem',
});

export function useGetReviewProblem(): GetReviewProblem {
  return requiredInject(GetReviewProblemKey);
}
