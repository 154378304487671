import { AppContextProvider, ContentId } from '@/base/domains';
import { Optional } from '@/base/types';
import {
  EditingCourseContentWorkbookData,
  EditingCourseContentWorkbookEntity,
  EditingCourseContentWorkbookEntityImpl,
  EditingCourseContentWorkbookRepository,
} from '@/contents/domains';
import { hasNonNullProperty, requiredNonNull } from '@/utils/TsUtils';

export class InMemoryEditingCourseContentWorkbookRepository
  implements EditingCourseContentWorkbookRepository
{
  private appContextProvider: AppContextProvider;

  private store: Map<ContentId, EditingCourseContentWorkbookEntity> = new Map();

  constructor(appContextProvider: AppContextProvider) {
    this.appContextProvider = appContextProvider;
  }

  save(
    args: EditingCourseContentWorkbookEntity | EditingCourseContentWorkbookData
  ): Promise<EditingCourseContentWorkbookEntity> {
    if (hasNonNullProperty(args, 'dataVersion')) {
      const updatedBy = requiredNonNull(
        this.appContextProvider.get().user?.id,
        'appContext.user.id'
      );
      const e = new EditingCourseContentWorkbookEntityImpl({
        ...args,
        dataVersion: args.dataVersion + 1,
        updatedBy,
      });
      this.store.set(e.id, e);
      return Promise.resolve(e);
    }
    const createdBy = requiredNonNull(this.appContextProvider.get().user?.id, 'appContext.user.id');
    const e = new EditingCourseContentWorkbookEntityImpl({
      ...args,
      dataVersion: 1,
      createdBy,
      updatedBy: createdBy,
    });
    this.store.set(e.id, e);
    return Promise.resolve(e);
  }

  findById(id: ContentId): Promise<Optional<EditingCourseContentWorkbookEntity>> {
    return Promise.resolve(this.store.get(id));
  }

  remove(id: ContentId): Promise<void> {
    this.store.delete(id);
    return Promise.resolve();
  }
}
