import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { Locale } from './Base';

export interface LocaleProvider {
  get(): Locale;
}

export const LocaleProviderKey = injectionKeyOf<LocaleProvider>({
  boundedContext: 'base',
  type: 'component',
  name: 'LocaleProvider',
});

export function useLocaleProvider(): LocaleProvider {
  return requiredInject(LocaleProviderKey);
}
