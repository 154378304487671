import {
  ContentId,
  ContentName,
  CourseDisplayName,
  CourseId,
  ExamResult,
  GroupExam,
  GroupId,
} from '@/base/domains';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { Minute } from '../types';
import { UseCase, UseCaseResponse } from './UseCase';

export interface GetGroupExamsRequest {
  groupId: GroupId;
  courseId: CourseId;
  contentId: ContentId;
}

export type GetGroupExamsContent = {
  id: ContentId;
  name: ContentName;
  open: boolean;
  requiredTime: Minute;
  problemCount: number;
  passingStandard?: number;
};

export type GetGroupExamsCourse = {
  id: CourseId;
  name: CourseDisplayName;
};

export interface GetGroupExamsResponse {
  course: GetGroupExamsCourse;
  content: GetGroupExamsContent;
  groupExams: Array<GroupExam>;
  examResults: Array<ExamResult>;
}

/**
 * テスト開催リストを取得する
 */
export interface GetGroupExams extends UseCase<GetGroupExamsRequest, GetGroupExamsResponse> {
  execute(request: GetGroupExamsRequest): Promise<UseCaseResponse<GetGroupExamsResponse>>;
}

export const GetGroupExamsKey = injectionKeyOf<GetGroupExams>({
  boundedContext: 'training',
  type: 'usecase',
  name: 'GetGroupExams',
});

export function useGetGroupExams(): GetGroupExams {
  return requiredInject(GetGroupExamsKey);
}
