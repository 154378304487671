import { createLogger } from '@/utils/log';
import { isDefined, isString } from '@/utils/TsUtils';

import { Locale } from '../domains';
import { Optional } from '../types';
import { MessageList, MessageService } from './MessageService';

export type MessageSource = {
  [key in Locale | 'default']: object;
};

const logger = createLogger({ boundedContext: 'base', name: 'MessageServiceImpl' });

function messageInSource(
  source: object,
  key: string,
  variables: { [key: string]: string | number }
): string | undefined {
  const path = key.split('.');
  const value = path.reduce((acc, n) => {
    if (!isDefined(acc)) {
      return undefined;
    }
    return acc[n];
  }, source);
  if (isDefined(value) && isString(value)) {
    return Object.keys(variables).reduce((acc, k) => {
      const v = variables[k];
      return acc.replaceAll(`{{${k}}}`, `${v}`);
    }, value);
  }
  return undefined;
}

function messageOf(
  sources: object[],
  key: string,
  variables: { [key: string]: string | number }
): string {
  const ret = sources.map((s) => messageInSource(s, key, variables)).find(isDefined);
  if (isDefined(ret)) {
    return ret;
  }
  logger.warn({
    message: `message not found or invalid type; key=${key}`,
  });
  return '';
}

function messageListInSource(source: object, key: string): Optional<MessageList> {
  const path = key.split('.');
  const value = path.reduce((acc, n) => {
    if (!isDefined(acc)) {
      return undefined;
    }
    return acc[n];
  }, source);
  if (isDefined(value) && Array.isArray(value)) {
    return value as MessageList;
  }
  return undefined;
}

function messageListOf(sources: object[], key: string): MessageList {
  const ret = sources.map((s) => messageListInSource(s, key)).find(isDefined) ?? [];
  if (isDefined(ret)) {
    return ret;
  }
  logger.warn({
    message: `message not found or invalid type; key=${key}`,
  });
  return [];
}

export class MessageServiceImpl implements MessageService {
  source: MessageSource;

  constructor(source: MessageSource) {
    this.source = source;
  }

  private sourcesOfDefaultAndLocale(locale: Locale | 'default'): object[] {
    const sources: object[] = [];
    if (this.source[locale]) {
      sources.push(this.source[locale]);
    }
    sources.push(this.source.default);
    return sources;
  }

  messageOf(
    key: string,
    variables: { [key: string]: string | number } = {},
    locale: Locale
  ): string {
    return messageOf(this.sourcesOfDefaultAndLocale(locale), key, variables);
  }

  messageListOf(key: string, locale: Locale): MessageList {
    return messageListOf(this.sourcesOfDefaultAndLocale(locale), key);
  }
}
