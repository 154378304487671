import { createLogger } from '@/utils/log';

import { isApplicationError } from '../error';
import { UNEXPECTED_ERROR } from '../ErrorCodes';
import { SyncUseCase, UseCaseName, UseCaseOptions, UseCaseResponse } from './UseCase';

const logger = createLogger({ boundedContext: 'base', name: 'AbstractSyncUseCase' });

export abstract class AbstractSyncUseCase<A extends {}, B extends {}> implements SyncUseCase<A, B> {
  constructor(private name: UseCaseName) {}

  execute(request?: A, _?: UseCaseOptions): UseCaseResponse<B> {
    try {
      const response = this.internalExecute(request);
      logger.debug({
        message: `${this.constructor.name} has been successfully executed`,
        request,
        response,
      });
      return {
        ...response,
        useCaseName: this.name,
      };
    } catch (e) {
      logger.debug({
        message: `${this.constructor.name} has been failed`,
        request,
        cause: e,
      });
      if (isApplicationError(e)) {
        return {
          errors: [e],
          useCaseName: this.name,
        };
      }
      return {
        errors: [UNEXPECTED_ERROR.toApplicationError({ payload: { cause: e } })],
        useCaseName: this.name,
      };
    }
  }

  abstract internalExecute(request?: A): B;
}
