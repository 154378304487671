import { Optional } from '@/base/types';
import { EditingCourseId } from '@/contents/domains';
import {
  EditingCourseQueries,
  EditingCourseWithLastContentUpdated,
} from '@/contents/domains/queries';

export class InMemoryEditingCourseQueries implements EditingCourseQueries {
  async findEditingCourseWithLastContentUpdatedById(
    _: EditingCourseId
  ): Promise<Optional<EditingCourseWithLastContentUpdated>> {
    return undefined;
  }
}
