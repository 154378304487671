import { CreateReviewProblemInput, UpdateReviewProblemInput } from '@/API';
import {
  AppContextProvider,
  CourseId,
  GroupId,
  ReviewProblemId,
  ReviewProblemProblem,
  UserId,
} from '@/base/domains';
import { JSONString, Optional } from '@/base/types';
import * as mutations from '@/graphql/mutations';
import * as queries from '@/graphql/queries';
import {
  ReviewProblemEntity,
  ReviewProblemEntityImpl,
  ReviewProblemEntityImplArgs,
  reviewProblemIdOf,
  ReviewProblemRepository,
  ReviewProblemRepositoryFindMyReviewProblemArgs,
  ReviewProblemRepositoryFindMyReviewProblemsArgs,
} from '@/training/domains';
import { graphql, graphqlQuery } from '@/utils/AmplifyUtils';
import { executeAsync } from '@/utils/Recursive';
import { requiredNonNull } from '@/utils/TsUtils';

type AmplifyReviewProblem = {
  /** 復習問題ID */
  id: ReviewProblemId;
  /** ユーザーID */
  userId: UserId;
  /** グループID */
  groupId: GroupId;
  /** コースID */
  courseId: CourseId;
  /** 問題リスト */
  problems: JSONString;
};

function toReviewProblem({ problems, ...rest }: AmplifyReviewProblem): ReviewProblemEntity {
  const rawProblems = JSON.parse(problems) as Omit<ReviewProblemProblem, 'index' | 'hash'>[];
  const args: ReviewProblemEntityImplArgs = {
    ...rest,
    problems: rawProblems,
  };
  return new ReviewProblemEntityImpl(args);
}

function toAmplifyProblems(problems: ReviewProblemProblem[]): JSONString {
  // hash, indexは除外する
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return JSON.stringify(problems.map(({ hash, index, ...rest }) => rest));
}

export class AmplifyReviewProblemRepository implements ReviewProblemRepository {
  private appContextProvider: AppContextProvider;

  constructor(appContextProvider: AppContextProvider) {
    this.appContextProvider = appContextProvider;
  }

  async save(args: ReviewProblemEntity): Promise<ReviewProblemEntity> {
    const tenantCode = requiredNonNull(
      this.appContextProvider.get().tenantCode,
      'appContext.tenantCode'
    );

    const alreadyExists = !!(await this.findById(args.id));

    if (alreadyExists) {
      const input: UpdateReviewProblemInput = {
        id: args.id,
        userId: args.userId,
        groupId: args.groupId,
        courseId: args.courseId,
        problems: toAmplifyProblems(args.problems),
        tenantCode,
      };
      const response = await graphql<{ updateReviewProblem: AmplifyReviewProblem }>(
        mutations.updateReviewProblem,
        { input }
      );
      return toReviewProblem(response.updateReviewProblem);
    }
    const input: CreateReviewProblemInput = {
      id: args.id,
      userId: args.userId,
      groupId: args.groupId,
      courseId: args.courseId,
      problems: toAmplifyProblems(args.problems),
      tenantCode,
    };
    const response = await graphql<{ createReviewProblem: AmplifyReviewProblem }>(
      mutations.createReviewProblem,
      { input }
    );
    return toReviewProblem(response.createReviewProblem);
  }

  async findById(id: ReviewProblemId): Promise<Optional<ReviewProblemEntity>> {
    const response = await graphql<{ getReviewProblem: Optional<AmplifyReviewProblem> }>(
      queries.getReviewProblem,
      { id }
    );
    return response.getReviewProblem ? toReviewProblem(response.getReviewProblem) : undefined;
  }

  async remove(id: ReviewProblemId): Promise<void> {
    await graphql(mutations.deleteReviewProblem, { input: { id } });
  }

  findMyReviewProblem(
    args: ReviewProblemRepositoryFindMyReviewProblemArgs
  ): Promise<Optional<ReviewProblemEntity>> {
    const userId = requiredNonNull(this.appContextProvider.get().user?.id, 'appContext.user.id');
    const reviewProblemId = reviewProblemIdOf({
      groupId: args.groupId,
      courseId: args.courseId,
      userId,
    });
    return this.findById(reviewProblemId);
  }

  findMyReviewProblems(
    args: ReviewProblemRepositoryFindMyReviewProblemsArgs
  ): Promise<ReviewProblemEntity[]> {
    const { groupId } = args;
    const userId = requiredNonNull(this.appContextProvider.get().user?.id, 'appContext.user.id');
    return executeAsync<ReviewProblemEntity[], { nextToken?: string }>(async (request) => {
      const nextToken = request.first ? undefined : request.previousResult.payload?.nextToken;
      const previousResult = request.first ? [] : request.previousResult.result;
      const res = await graphqlQuery<{
        reviewProblemsByGroupAndUser: { items: AmplifyReviewProblem[]; nextToken?: string };
      }>(queries.reviewProblemsByGroupAndUser, { groupId, userId: { eq: userId }, nextToken });
      const amplifyReviewProblems = res.reviewProblemsByGroupAndUser.items;
      const resultNextToken = res.reviewProblemsByGroupAndUser.nextToken;
      return {
        hasNext: !!resultNextToken,
        result: previousResult.concat(amplifyReviewProblems.map((rp) => toReviewProblem(rp))),
        payload: {
          nextToken: resultNextToken,
        },
      };
    });
  }
}
