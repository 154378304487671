import { ContentEntity } from './Content';
import { ExamContentEntityArgs, ExamContentEntityImpl } from './ExamContentEntityImpl';
import { TextContentEntityArgs, TextContentEntityImpl } from './TextContentEntityImpl';

export function createContentEntity(args: ContentEntityArgs): ContentEntity {
  if (args.type === 'text') {
    return new TextContentEntityImpl(args);
  }
  if (args.type === 'exam') {
    return new ExamContentEntityImpl(args);
  }
  throw new Error(`Unsupported type`);
}

type ContentEntityArgs = ExamContentEntityArgs | TextContentEntityArgs;
