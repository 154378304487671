import {
  AuthorizationService,
  GroupId,
  SCHEDULE_TAGS_LIMITATION,
  ScheduleTagId,
} from '@/base/domains';
import { LocalDateTime, MarkDownString } from '@/base/types';
import { AbstractUseCase, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { ScheduleTagRepository } from '../domains';
import { ScheduleReference, ScheduleRepository } from '../domains/Schedule';
import { SCHEDULE_TAG_LIMITATION_EXCEEDED, SCHEDULE_TAGS_DO_NOT_EXIST } from '../ErrorCodes';

export interface CreateScheduleRequest {
  groupId: GroupId;
  start: LocalDateTime;
  end?: LocalDateTime;
  title: string;
  body?: MarkDownString;
  tagIds?: Array<ScheduleTagId>;
}

export type CreateScheduleResponse = {
  schedule: ScheduleReference;
};

/**
 * スケジュールを作成する
 */
export interface CreateSchedule extends UseCase<CreateScheduleRequest, CreateScheduleResponse> {
  execute(request: CreateScheduleRequest): Promise<UseCaseResponse<CreateScheduleResponse>>;
}

export class CreateScheduleImpl
  extends AbstractUseCase<CreateScheduleRequest, CreateScheduleResponse>
  implements CreateSchedule
{
  constructor(
    private authorizationService: AuthorizationService,
    private scheduleRepository: ScheduleRepository,
    private scheduleTagRepository: ScheduleTagRepository
  ) {
    super('training.CreateSchedule');
  }

  async internalExecute(request: CreateScheduleRequest): Promise<CreateScheduleResponse> {
    const { groupId, start, end, title, body, tagIds = [] } = request;

    this.authorizationService.assertTrainerOrMentorInGroup(groupId);
    if (tagIds.length > SCHEDULE_TAGS_LIMITATION) {
      throw SCHEDULE_TAG_LIMITATION_EXCEEDED.toApplicationError();
    }
    if (tagIds.length > 0) {
      const scheduleTags = await this.scheduleTagRepository.findByGroupId(groupId);
      const notExistTagIds = tagIds.filter((tagId) => !scheduleTags.find((st) => st.id === tagId));
      if (notExistTagIds.length > 0) {
        throw SCHEDULE_TAGS_DO_NOT_EXIST.toApplicationError({
          tagIds: notExistTagIds,
        });
      }
    }
    const saved = await this.scheduleRepository.save({
      groupId,
      start,
      end,
      title,
      body,
      tags: [],
    });
    const results = await Promise.all(
      tagIds.map((tagId) => this.scheduleRepository.addTag(saved.id, tagId))
    );
    const schedule = results[results.length - 1] ?? saved;
    return {
      schedule,
    };
  }
}

export const CreateScheduleKey = injectionKeyOf<CreateSchedule>({
  boundedContext: 'training',
  type: 'usecase',
  name: 'CreateSchedule',
});

export function useCreateSchedule(): CreateSchedule {
  return requiredInject(CreateScheduleKey);
}
