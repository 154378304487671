import { AuthorizationService, ContentFinder, ContentId, ContentVersion } from '@/base/domains';
import { AbstractUseCase, assertEntityExists, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import {
  GroupTrainingCourseId,
  GroupTrainingCourseReference,
  GroupTrainingCourseRepository,
} from '../domains';

export type ChangeGroupTrainingCourseContentVersionRequest = {
  id: GroupTrainingCourseId;
  contentId: ContentId;
  contentVersion: ContentVersion;
};

export type ChangeGroupTrainingCourseContentVersionResponse = {
  groupTrainingCourse: GroupTrainingCourseReference;
};

export interface ChangeGroupTrainingCourseContentVersion
  extends UseCase<
    ChangeGroupTrainingCourseContentVersionRequest,
    ChangeGroupTrainingCourseContentVersionResponse
  > {
  execute(
    request: ChangeGroupTrainingCourseContentVersionRequest
  ): Promise<UseCaseResponse<ChangeGroupTrainingCourseContentVersionResponse>>;
}

export class ChangeGroupTrainingCourseContentVersionImpl
  extends AbstractUseCase<
    ChangeGroupTrainingCourseContentVersionRequest,
    ChangeGroupTrainingCourseContentVersionResponse
  >
  implements ChangeGroupTrainingCourseContentVersion
{
  constructor(
    private authorizationService: AuthorizationService,
    private groupTrainingCourseRepository: GroupTrainingCourseRepository,
    private contentFinder: ContentFinder
  ) {
    super('training.ChangeGroupTrainingCourseContentVersion');
  }

  async internalExecute({
    id,
    contentId,
    contentVersion,
  }: ChangeGroupTrainingCourseContentVersionRequest): Promise<ChangeGroupTrainingCourseContentVersionResponse> {
    const groupTrainingCourse = await this.groupTrainingCourseRepository.findById(id);
    assertEntityExists(groupTrainingCourse, 'groupTrainingCourse');
    this.authorizationService.assertTrainerOrMentorInGroup(groupTrainingCourse.groupId);
    const content = await this.contentFinder.findById(contentId, contentVersion);
    assertEntityExists(content, 'content');
    const saved = await this.groupTrainingCourseRepository.save(
      groupTrainingCourse.changeContentVersion({
        contentId,
        contentVersion,
      })
    );
    return {
      groupTrainingCourse: saved,
    };
  }
}

export const ChangeGroupTrainingCourseContentVersionKey =
  injectionKeyOf<ChangeGroupTrainingCourseContentVersion>({
    boundedContext: 'training',
    type: 'usecase',
    name: 'ChangeGroupTrainingCourseContentVersion',
  });

export function useChangeGroupTrainingCourseContentVersion(): ChangeGroupTrainingCourseContentVersion {
  return requiredInject(ChangeGroupTrainingCourseContentVersionKey);
}
