import { Optional } from '@/base/types';
import { LogLevel } from '@/utils/log';
import { isDefined } from '@/utils/TsUtils';

export const docsUrl: string =
  process.env?.VUE_APP_DOCS_URL ?? 'https://docs.education.knowte-dev.link';
export const logLevel: Optional<LogLevel> = (() => {
  const l = process.env?.VUE_APP_LOG_LEVEL;
  if (isDefined(l)) {
    if (['debug', 'info', 'warn', 'error', 'off'].includes(l)) {
      return l as LogLevel;
    }
  }
  return undefined;
})();

export const googlePlayUrl: string = process.env?.VUE_APP_GOOGLE_PLAY_URL ?? '';
export const requiredVerCodeOfAndroidApp: string =
  process.env?.VUE_APP_REQUIRED_VER_CODE_OF_ANDROID_APP ?? '4';
