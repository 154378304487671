import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { AuthorizationService, GroupId, GroupStatusService } from '../domains';
import { AbstractUseCase, UseCase, UseCaseResponse } from './UseCase';

export type ClearGroupHandStatusRequest = {
  groupId: GroupId;
};

export type ClearGroupHandStatusResponse = {};

export interface ClearGroupHandStatus
  extends UseCase<ClearGroupHandStatusRequest, ClearGroupHandStatusResponse> {
  execute(
    request: ClearGroupHandStatusRequest
  ): Promise<UseCaseResponse<ClearGroupHandStatusResponse>>;
}

export class ClearGroupHandStatusImpl
  extends AbstractUseCase<ClearGroupHandStatusRequest, ClearGroupHandStatusResponse>
  implements ClearGroupHandStatus
{
  constructor(
    private authorizationService: AuthorizationService,
    private groupStatusService: GroupStatusService
  ) {
    super('base.ClearGroupHandStatus');
  }

  async internalExecute({
    groupId,
  }: ClearGroupHandStatusRequest): Promise<ClearGroupHandStatusResponse> {
    this.authorizationService.assertTrainerOrMentorInGroup(groupId);
    await this.groupStatusService.clearHandStatuses(groupId);
    return {};
  }
}

export const ClearGroupHandStatusKey = injectionKeyOf<ClearGroupHandStatus>({
  boundedContext: 'base',
  type: 'usecase',
  name: 'ClearGroupHandStatus',
});

export function useClearGroupHandStatus(): ClearGroupHandStatus {
  return requiredInject(ClearGroupHandStatusKey);
}
