import { ContentId, CourseId } from '@/base/domains';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { ExamEditingCourseContentBodyReference } from '../EditingCourseContentBody';

export interface EditingCourseContentBodyQueries {
  getEmptyProblemExamContentIdByCourse(id: CourseId): Promise<Array<ContentId>>;
  findExamContentsByCourse(id: CourseId): Promise<Array<ExamEditingCourseContentBodyReference>>;
}

export const EditingCourseContentBodyQueriesKey = injectionKeyOf<EditingCourseContentBodyQueries>({
  boundedContext: 'contents',
  type: 'adapter',
  name: 'EditingCourseContentBodyQueries',
});

export function useEditingCourseContentBodyQueries(): EditingCourseContentBodyQueries {
  return requiredInject(EditingCourseContentBodyQueriesKey);
}
