import { Course, CourseFinder, CourseId, CourseName, CourseVersion } from '@/base/domains';
import { Optional } from '@/base/types';
import { isDefined } from '@/utils/TsUtils';

import { CourseRepository } from './Course';
import { CourseHeaderRepository } from './CourseHeader';

export class CourseFinderImpl implements CourseFinder {
  private courseRepository: CourseRepository;

  private courseHeaderRepository: CourseHeaderRepository;

  constructor(courseRepository: CourseRepository, courseHeaderRepository: CourseHeaderRepository) {
    this.courseRepository = courseRepository;
    this.courseHeaderRepository = courseHeaderRepository;
  }

  findByKey(id: CourseId, version: CourseVersion): Promise<Optional<Course>> {
    return this.courseRepository.findById({ id, version });
  }

  async findActiveCourseById(id: CourseId): Promise<Optional<Course>> {
    const h = await this.courseHeaderRepository.findById(id);
    if (isDefined(h)) {
      const course = await this.courseRepository.findById({ id, version: h?.activeVersion });
      return course;
    }
    return undefined;
  }

  async findActiveCourseByName(name: CourseName): Promise<Optional<Course>> {
    const h = await this.courseHeaderRepository.findByName(name);
    if (isDefined(h)) {
      const course = await this.courseRepository.findById({ id: h.id, version: h?.activeVersion });
      return course;
    }
    return undefined;
  }

  findTenantCourses(): Promise<Array<Course>> {
    return this.courseRepository.findTenantCourses();
  }

  async findTenantActiveCourses(): Promise<Course[]> {
    const [headers, courses] = await Promise.all([
      this.courseHeaderRepository.tenantCourses(),
      this.findTenantCourses(),
    ]);
    const headersMap = new Map(
      headers.map((h) => [h.id, { activeVersion: h.activeVersion, status: h.status }])
    );
    return courses.filter((cr) => {
      const header = headersMap.get(cr.id);
      return header && header.status === 'enabled' && cr.version === header.activeVersion;
    });
  }
}
