import { EditingConfirmedContentEntity } from './EditingConfirmedContent';
import {
  ExamEditingConfirmedContentEntityArgs,
  ExamEditingConfirmedContentEntityImpl,
} from './ExamEditingConfirmedContentEntityImpl';
import {
  TextEditingConfirmedContentEntityArgs,
  TextEditingConfirmedContentEntityImpl,
} from './TextEditingConfirmedContentEntityImpl';

export function createEditingConfirmedContentEntity(
  args: EditingConfirmedContentEntityArgs
): EditingConfirmedContentEntity {
  if (args.type === 'text') {
    return new TextEditingConfirmedContentEntityImpl(args);
  }
  if (args.type === 'exam') {
    return new ExamEditingConfirmedContentEntityImpl(args);
  }
  throw new Error(`Unsupported type`);
}

type EditingConfirmedContentEntityArgs =
  | ExamEditingConfirmedContentEntityArgs
  | TextEditingConfirmedContentEntityArgs;
