import {
  AuthorizationService,
  Group,
  GroupId,
  GroupRepository,
  localEventBus,
} from '@/base/domains';
import { groupDescriptionChanged } from '@/base/domains/LocalEvents';
import { AbstractUseCase, assertEntityExists, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

export type ChangeGroupDescriptionRequest = {
  groupId: GroupId;
  description: string;
};

export type ChangeGroupDescriptionResponse = {
  group: Group;
};

export interface ChangeGroupDescription
  extends UseCase<ChangeGroupDescriptionRequest, ChangeGroupDescriptionResponse> {
  execute(
    request: ChangeGroupDescriptionRequest
  ): Promise<UseCaseResponse<ChangeGroupDescriptionResponse>>;
}

export class ChangeGroupDescriptionImpl
  extends AbstractUseCase<ChangeGroupDescriptionRequest, ChangeGroupDescriptionResponse>
  implements ChangeGroupDescription
{
  constructor(
    private authorizationService: AuthorizationService,
    private groupRepository: GroupRepository
  ) {
    super('admin.ChangeGroupDescription');
  }

  async internalExecute(
    request: ChangeGroupDescriptionRequest
  ): Promise<ChangeGroupDescriptionResponse> {
    this.authorizationService.assertRole('supervisor', 'admin');
    const { groupId, description } = request;
    const group = await this.groupRepository.findById(groupId);
    assertEntityExists(group, 'group');
    const saved = await this.groupRepository.save(group.changeDescription(description));
    localEventBus.publish(groupDescriptionChanged({ groupId, description }));
    return {
      group: saved,
    };
  }
}

export const ChangeGroupDescriptionKey = injectionKeyOf<ChangeGroupDescription>({
  boundedContext: 'admin',
  type: 'usecase',
  name: 'ChangeGroupDescription',
});

export function useChangeGroupDescription(): ChangeGroupDescription {
  return requiredInject(ChangeGroupDescriptionKey);
}
