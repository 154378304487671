/* eslint-disable @typescript-eslint/no-unused-vars */
import { v4 } from 'uuid';

import {
  Group,
  GroupAttributes,
  GroupData,
  GroupEntity,
  GroupEntityImpl,
  GroupId,
  GroupRepository,
  isEntityLike,
  UserData,
  UserId,
} from '@/base/domains';
import { Optional } from '@/base/types';

export class InMemoryGroupRepository implements GroupRepository {
  private store: Map<GroupId, GroupEntity> = new Map();

  save(entity: GroupAttributes | GroupData): Promise<GroupEntity> {
    const e = isEntityLike<UserData>(entity)
      ? new GroupEntityImpl(entity)
      : new GroupEntityImpl({ ...entity, id: v4() });
    this.store.set(e.id, e);
    return Promise.resolve(e);
  }

  findById(id: UserId): Promise<Optional<GroupEntity>> {
    return Promise.resolve(this.store.get(id));
  }

  findGroupIncludeRemovedUsers(id: UserId): Promise<Optional<GroupEntity>> {
    return Promise.resolve(this.store.get(id));
  }

  remove(id: UserId): Promise<void> {
    this.store.delete(id);
    return Promise.resolve();
  }

  findTenantGroups(): Promise<Group[]> {
    return Promise.resolve(Array.from(this.store.values()));
  }
}
