import * as customMutations from '@/amplify/customMutations';
import * as customQueries from '@/amplify/customQueries';
import {
  CreateScheduleInput,
  CreateScheduleScheduleTagInput,
  DeleteScheduleInput,
  DeleteScheduleScheduleTagInput,
  ModelScheduleFilterInput,
  ModelSortDirection,
  UpdateScheduleInput,
} from '@/API';
import {
  AppContextProvider,
  EntityData,
  GroupId,
  hasId,
  NextToken,
  QueryResponse,
  ScheduleColor,
  ScheduleFinderFindRecentlyArgs,
  ScheduleId,
  ScheduleTagId,
  UserId,
} from '@/base/domains';
import { MarkDownString, Optional } from '@/base/types';
import { assertEntityExists } from '@/base/usecases';
import * as mutations from '@/graphql/mutations';
import {
  ScheduleAttributes,
  ScheduleEntity,
  ScheduleEntityImpl,
  ScheduleReference,
  ScheduleRepository,
  ScheduleRepositoryFindArgs,
} from '@/training/domains';
import { graphql, graphqlQuery } from '@/utils/AmplifyUtils';
import { assertIsDefined } from '@/utils/Asserts';
import { localDateTimeFromString, localDateTimeNow } from '@/utils/DateUtils';
import { hasNonNullProperty, isDefined, requiredNonNull } from '@/utils/TsUtils';

export type AmplifySchedule = {
  id: ScheduleId;
  groupId: GroupId;
  start: string;
  end: string;
  title: string;
  body?: MarkDownString;
  createdBy?: UserId;
  updatedBy?: UserId;
  tags?: {
    items?: Array<{
      id: string;
      scheduleTag: {
        id: ScheduleTagId;
        color: ScheduleColor;
        text: string;
      };
    }>;
  };
};

export function toScheduleEntity(schedule: AmplifySchedule): ScheduleEntity {
  return new ScheduleEntityImpl({
    id: schedule.id,
    groupId: schedule.groupId,
    start: localDateTimeFromString(schedule.start),
    end: localDateTimeFromString(schedule.end),
    title: schedule.title,
    body: schedule.body,
    createdBy: schedule.createdBy,
    // 作成時にcreatedByしか設定していなかったのでそのデータの場合はupdatedByにcreatedByを使う。
    // 現在は作成時にupdatedByも設定している。
    updatedBy: schedule.updatedBy ?? schedule.createdBy,
    tags: (schedule.tags?.items ?? [])
      .map((e) => ({
        id: e.scheduleTag.id,
        text: e.scheduleTag.text,
        color: e.scheduleTag.color,
      }))
      .sort((a, b) => a.text.localeCompare(b.text)),
  });
}

export class AmplifyScheduleRepository implements ScheduleRepository {
  private appContextProvider: AppContextProvider;

  constructor(appContextProvider: AppContextProvider) {
    this.appContextProvider = appContextProvider;
  }

  async find(args: ScheduleRepositoryFindArgs): Promise<QueryResponse<Array<ScheduleReference>>> {
    const { groupId, startFrom, startTo, limit, nextToken } = args;
    const res = await graphql<{
      schedulesByGroupId: {
        items: Array<AmplifySchedule>;
        nextToken?: NextToken;
      };
    }>(customQueries.schedulesByGroupId, {
      groupId,
      start: { between: [startFrom.toISOString(), startTo.toISOString()] },
      limit,
      sortDirection: ModelSortDirection.ASC,
      nextToken,
    });
    return {
      data: res.schedulesByGroupId.items.map((s) => toScheduleEntity(s)) ?? [],
      nextToken: res.schedulesByGroupId.nextToken,
    };
  }

  async findRecently(
    args: ScheduleFinderFindRecentlyArgs
  ): Promise<QueryResponse<Array<ScheduleReference>>> {
    const now = localDateTimeNow();
    const { limit = 1000, nextToken } = args;
    const groupIds = (() => {
      if (hasNonNullProperty(args, 'groupId')) {
        return [args.groupId];
      }
      return args.groupIds;
    })();
    if (groupIds.length === 1) {
      const res = await graphqlQuery<{
        schedulesByGroupIdAndEnd: {
          items: Array<AmplifySchedule>;
          nextToken?: NextToken;
        };
      }>(
        customQueries.schedulesByGroupIdAndEnd,
        {
          groupId: groupIds[0],
          end: { ge: now.toISOString() },
          nextToken,
        },
        {
          limit,
        }
      );
      return {
        data: (res.schedulesByGroupIdAndEnd.items.map((s) => toScheduleEntity(s)) ?? []).sort(
          (a, b) => a.start.toDate().getTime() - b.start.toDate().getTime()
        ),
        nextToken: res.schedulesByGroupIdAndEnd.nextToken,
      };
    }
    const tenantCode = requiredNonNull(
      this.appContextProvider.get().tenantCode,
      'appContext.tenantCode'
    );
    const filter: ModelScheduleFilterInput = {
      or: groupIds.map((groupId) => ({ groupId: { eq: groupId } })),
    };
    const res = await graphqlQuery<{
      schedulesByEnd: {
        items: Array<AmplifySchedule>;
        nextToken?: NextToken;
      };
    }>(
      customQueries.schedulesByEnd,
      {
        tenantCode,
        end: { ge: now.toISOString() },
        filter,
        nextToken,
      },
      {
        limit,
      }
    );

    return {
      data: (res.schedulesByEnd.items.map((s) => toScheduleEntity(s)) ?? []).sort(
        (a, b) => a.start.toDate().getTime() - b.start.toDate().getTime()
      ),
      nextToken: res.schedulesByEnd.nextToken,
    };
  }

  async save(
    args: ScheduleEntity | EntityData<ScheduleId, ScheduleAttributes>
  ): Promise<ScheduleEntity> {
    const tenantCode = requiredNonNull(
      this.appContextProvider.get().tenantCode,
      'appContext.tenantCode'
    );
    const userId = this.appContextProvider.get().user?.id;
    assertIsDefined(userId, 'userId');
    if (hasId(args)) {
      const input: UpdateScheduleInput = {
        id: args.id,
        groupId: args.groupId,
        start: args.start.toISOString(),
        end: isDefined(args.end) ? args.end.toISOString() : args.end,
        title: args.title,
        body: args.body,
        createdBy: args.createdBy,
        updatedBy: userId,
        tenantCode,
      };
      await graphql<{ updateSchedule: AmplifySchedule }>(mutations.updateSchedule, {
        input,
      });
      // 最新のタグリストを取得するため
      const saved = await this.findById(args.id);
      assertIsDefined(saved);
      return saved;
    }
    const input: CreateScheduleInput = {
      groupId: args.groupId,
      start: args.start.toISOString(),
      end: isDefined(args.end) ? args.end.toISOString() : args.end,
      title: args.title,
      body: args.body,
      createdBy: userId,
      updatedBy: userId,
      tenantCode,
    };
    const res = await graphql<{ createSchedule: AmplifySchedule }>(mutations.createSchedule, {
      input,
    });
    assertIsDefined(res);
    return toScheduleEntity(res.createSchedule);
  }

  async findById(id: ScheduleId): Promise<Optional<ScheduleEntity>> {
    const res = await graphql<{ getSchedule: AmplifySchedule }>(customQueries.getSchedule, { id });
    return res?.getSchedule ? toScheduleEntity(res.getSchedule) : undefined;
  }

  async remove(id: ScheduleId): Promise<void> {
    const input: DeleteScheduleInput = {
      id,
    };
    await graphql<{ deleteSchedule: AmplifySchedule }>(customMutations.deleteSchedule, {
      input,
    });
  }

  async addTag(id: ScheduleId, tagId: ScheduleTagId): Promise<ScheduleReference> {
    const { tenantCode } = this.appContextProvider.get();
    assertIsDefined(tenantCode, 'appContext.tenantCode');
    const input: CreateScheduleScheduleTagInput = {
      scheduleId: id,
      scheduleTagId: tagId,
      tenantCode,
    };
    await graphql(mutations.createScheduleScheduleTag, { input });
    const schedule = await this.findById(id);
    assertEntityExists(schedule, 'schedule');
    return schedule;
  }

  async removeTag(id: ScheduleId, tagId: ScheduleTagId): Promise<ScheduleReference> {
    const getScheduleRes = await graphql<{ getSchedule: Optional<AmplifySchedule> }>(
      customQueries.getSchedule,
      { id }
    );
    const amplifySchedule = getScheduleRes.getSchedule;
    assertEntityExists(amplifySchedule, 'amplifySchedule');

    const join = amplifySchedule.tags?.items?.find((e) => e.scheduleTag.id === tagId);
    if (join) {
      const input: DeleteScheduleScheduleTagInput = {
        id: join.id,
      };
      await graphql(mutations.deleteScheduleScheduleTag, { input });
      const schedule = await this.findById(id);
      assertEntityExists(schedule, 'schedule');
      return schedule;
    }
    return toScheduleEntity(amplifySchedule);
  }
}
