import {
  AuthorizationService,
  ENABLED_GROUP_LIMITATION,
  Group,
  GroupId,
  GroupRepository,
  localEventBus,
} from '@/base/domains';
import { groupEnabled } from '@/base/domains/LocalEvents';
import { AbstractUseCase, assertEntityExists, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { ENABLED_GROUP_LIMITATION_EXCEEDED } from '../ErrorCodes';

export type EnableGroupRequest = {
  groupId: GroupId;
};

export type EnableGroupResponse = {
  group: Group;
};

export interface EnableGroup extends UseCase<EnableGroupRequest, EnableGroupResponse> {
  execute(request: EnableGroupRequest): Promise<UseCaseResponse<EnableGroupResponse>>;
}

export class EnableGroupImpl
  extends AbstractUseCase<EnableGroupRequest, EnableGroupResponse>
  implements EnableGroup
{
  constructor(
    private authorizationService: AuthorizationService,
    private groupRepository: GroupRepository
  ) {
    super('admin.EnableGroup');
  }

  async internalExecute(request: EnableGroupRequest): Promise<EnableGroupResponse> {
    this.authorizationService.assertRole('supervisor', 'admin');

    const tenantGroups = await this.groupRepository.findTenantGroups();
    const enableGroups = tenantGroups.filter((g) => g.enabled);
    if (enableGroups.length >= ENABLED_GROUP_LIMITATION) {
      throw ENABLED_GROUP_LIMITATION_EXCEEDED.toApplicationError({});
    }

    const { groupId } = request;
    const group = await this.groupRepository.findById(groupId, { includeDisabledGroup: true });
    assertEntityExists(group, 'group');
    const saved = await this.groupRepository.save(group.enable());
    localEventBus.publish(groupEnabled({ groupId }));
    return {
      group: saved,
    };
  }
}

export const EnableGroupKey = injectionKeyOf<EnableGroup>({
  boundedContext: 'admin',
  type: 'usecase',
  name: 'EnableGroup',
});

export function useEnableGroup(): EnableGroup {
  return requiredInject(EnableGroupKey);
}
