import { DateTimeService, ExamDataAdapter, ExamId, GroupExam, UserExam } from '@/base/domains';
import { LocalDateTime } from '@/base/types';
import { AbstractUseCase, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

export interface GetMyExamRequest {
  id: ExamId;
}

export type GetMyExamExam = UserExam & { groupExam: Omit<GroupExam, 'userExams'> };

export type GetMyExamResponse = {
  exam?: GetMyExamExam;
  now: LocalDateTime;
};

/**
 * テストを取得する
 */
export interface GetMyExam extends UseCase<GetMyExamRequest, GetMyExamResponse> {
  execute(request: GetMyExamRequest): Promise<UseCaseResponse<GetMyExamResponse>>;
}

export class GetMyExamImpl
  extends AbstractUseCase<GetMyExamRequest, GetMyExamResponse>
  implements GetMyExam
{
  private examDataAdapter: ExamDataAdapter;

  private dateTimeService: DateTimeService;

  constructor(examDataAdapter: ExamDataAdapter, dateTimeService: DateTimeService) {
    super('training.GetMyExam');
    this.examDataAdapter = examDataAdapter;
    this.dateTimeService = dateTimeService;
  }

  async internalExecute(request: GetMyExamRequest): Promise<GetMyExamResponse> {
    const { id } = request;
    const [userExam, now] = await Promise.all([
      this.examDataAdapter.findUserExam(id),
      this.dateTimeService.strictLocalDateTimeNow(),
    ]);
    return {
      exam: userExam,
      now,
    };
  }
}

export const GetMyExamKey = injectionKeyOf<GetMyExam>({
  boundedContext: 'training',
  type: 'usecase',
  name: 'GetMyExam',
});

export function useGetMyExam(): GetMyExam {
  return requiredInject(GetMyExamKey);
}
