import { TermsOfService, TermsOfServiceDataAdapter } from '@/base/domains';
import { Optional } from '@/base/types';
import * as queries from '@/graphql/queries';
import { graphqlQuery } from '@/utils/AmplifyUtils';

export class AmplifyTermsOfServiceDataAdapter implements TermsOfServiceDataAdapter {
  async findLatest(): Promise<Optional<TermsOfService>> {
    const res = await graphqlQuery<{ listTermsOfServices: { items: Array<TermsOfService> } }>(
      queries.listTermsOfServices,
      {},
      {
        publicAccess: true,
      }
    );
    return res.listTermsOfServices.items.sort((a, b) => -a.id.localeCompare(b.id))[0];
  }
}
