import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { UserId } from './Core';
import { GroupId } from './Group';
import { GroupStatusUser } from './GroupStatus';
import { Subscription } from './Subscription';

export interface GroupStatusService {
  raiseHand(args: {
    userId: UserId;
    groupId: GroupId;
    handStatus: 'yes' | 'no';
  }): Promise<GroupStatusUser>;
  lowerHand(args: { userId: UserId; groupId: GroupId }): Promise<GroupStatusUser>;
  clearHandStatuses(groupId: GroupId): Promise<void>;
  subscribeGroupStatusUserChanged(args: {
    groupId: GroupId;
    onChange: (groupStatusUser: GroupStatusUser, removed: boolean) => void;
    onError: (e: Error) => void;
  }): Subscription;
}

export const GroupStatusServiceKey = injectionKeyOf<GroupStatusService>({
  boundedContext: 'base',
  type: 'adapter',
  name: 'GroupStatusService',
});

export function useGroupStatusService(): GroupStatusService {
  return requiredInject(GroupStatusServiceKey);
}
