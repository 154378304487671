import { CourseHeaderData } from '@/contents/domains';

export const courseHeaders: Array<CourseHeaderData> = [
  {
    id: 'course-1',
    name: 'コンピュータ基礎',
    status: 'editing',
    activeVersion: 0,
  },
  {
    id: 'course-0',
    name: 'Java入門',
    status: 'enabled',
    activeVersion: 1,
  },
  {
    id: 'course-11',
    name: 'SQL入門',
    status: 'enabled',
    activeVersion: 1,
  },
  {
    id: 'course-12',
    name: 'ビジネス基礎',
    status: 'enabled',
    activeVersion: 2,
  },
  {
    id: 'course-13',
    name: '無効化したコース',
    status: 'disabled',
    activeVersion: 3,
  },
];
