import { AuthorizationService, CourseId, CourseName } from '@/base/domains';
import { AbstractUseCase, assertEntityExists, UseCase, UseCaseResponse } from '@/base/usecases';
import { isDefined } from '@/utils/TsUtils';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import {
  CourseHeaderRepository,
  EditingCourseReference,
  EditingCourseRepository,
} from '../domains';
import { COURSE_DUPLICATED_NAME } from '../ErrorCodes';

export interface ChangeEditingCourseNameRequest {
  id: CourseId;
  name: CourseName;
}

export type ChangeEditingCourseNameResponse = {
  course: EditingCourseReference;
};

export interface ChangeEditingCourseName
  extends UseCase<ChangeEditingCourseNameRequest, ChangeEditingCourseNameResponse> {
  execute(
    request: ChangeEditingCourseNameRequest
  ): Promise<UseCaseResponse<ChangeEditingCourseNameResponse>>;
}

/**
 * 編集中コースの名前を変更する
 */
export class ChangeEditingCourseNameImpl
  extends AbstractUseCase<ChangeEditingCourseNameRequest, ChangeEditingCourseNameResponse>
  implements ChangeEditingCourseName
{
  private authorizationService: AuthorizationService;

  private editingCourseRepository: EditingCourseRepository;

  private courseHeaderRepository: CourseHeaderRepository;

  constructor(
    authorizationService: AuthorizationService,
    editingCourseRepository: EditingCourseRepository,
    courseHeaderRepository: CourseHeaderRepository
  ) {
    super('contents.ChangeEditingCourseName');
    this.authorizationService = authorizationService;
    this.editingCourseRepository = editingCourseRepository;
    this.courseHeaderRepository = courseHeaderRepository;
  }

  async internalExecute(
    request: ChangeEditingCourseNameRequest
  ): Promise<ChangeEditingCourseNameResponse> {
    const { id, name } = request;
    this.authorizationService.assertContentEditable();
    const course = await this.editingCourseRepository.findById(id);
    assertEntityExists(course, 'course');
    if (isDefined(await this.courseHeaderRepository.findByName(name))) {
      throw COURSE_DUPLICATED_NAME.toApplicationError({ payload: { id, name } });
    }
    const saved = await this.editingCourseRepository.save(course.changeName(name));
    return {
      course: saved,
    };
  }
}

export const ChangeEditingCourseNameKey = injectionKeyOf<ChangeEditingCourseName>({
  boundedContext: 'contents',
  type: 'usecase',
  name: 'ChangeEditingCourseName',
});

export function useChangeEditingCourseName(): ChangeEditingCourseName {
  return requiredInject(ChangeEditingCourseNameKey);
}
