import {
  AppContextProvider,
  PrivacyPolicyDataAdapter,
  PrivacyPolicyVersion,
  TenantOwnTermsOfServiceFinder,
  TermsOfServiceDataAdapter,
  TermsOfServiceId,
} from '@/base/domains';
import { AbstractUseCase, UseCase, UseCaseResponse } from '@/base/usecases';
import { assertIsDefined } from '@/utils/Asserts';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

export type GetTermsServiceLatestVersionsRequest = {};

export type GetTermsServiceLatestVersionsResponse = {
  termsOfServiceVersion?: TermsOfServiceId;
  privacyPolicyVersion?: PrivacyPolicyVersion;
  tenantOwnTermsOfServiceVersion?: number;
};

export interface GetTermsServiceLatestVersions
  extends UseCase<GetTermsServiceLatestVersionsRequest, GetTermsServiceLatestVersionsResponse> {
  execute(
    request: GetTermsServiceLatestVersionsRequest
  ): Promise<UseCaseResponse<GetTermsServiceLatestVersionsResponse>>;
}

export class GetTermsServiceLatestVersionsImpl
  extends AbstractUseCase<
    GetTermsServiceLatestVersionsRequest,
    GetTermsServiceLatestVersionsResponse
  >
  implements GetTermsServiceLatestVersions
{
  constructor(
    private appContextProvider: AppContextProvider,
    private termsOfServiceDataAdapter: TermsOfServiceDataAdapter,
    private tenantOwnTermsOfServiceFinder: TenantOwnTermsOfServiceFinder,
    private privacyPolicyDataAdapter: PrivacyPolicyDataAdapter
  ) {
    super('base.GetTermsServiceLatestVersions');
  }

  async internalExecute(
    _: GetTermsServiceLatestVersionsRequest
  ): Promise<GetTermsServiceLatestVersionsResponse> {
    const { user } = this.appContextProvider.get();
    assertIsDefined(user, 'appContext.user');
    const [privacyPolicyVersion, termsOfService, tenantOwnTermsOfService] = await Promise.all([
      this.privacyPolicyDataAdapter.findPrivacyPolicyVersion(),
      this.termsOfServiceDataAdapter.findLatest(),
      this.tenantOwnTermsOfServiceFinder.findTenantOwnTermsOfService(),
    ]);
    return {
      termsOfServiceVersion: termsOfService?.id,
      tenantOwnTermsOfServiceVersion: tenantOwnTermsOfService?.version,
      privacyPolicyVersion,
    };
  }
}

export const GetTermsServiceLatestVersionsKey = injectionKeyOf<GetTermsServiceLatestVersions>({
  boundedContext: 'base',
  type: 'usecase',
  name: 'GetTermsServiceLatestVersions',
});

export function useGetTermsServiceLatestVersions(): GetTermsServiceLatestVersions {
  return requiredInject(GetTermsServiceLatestVersionsKey);
}
