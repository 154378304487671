import { AuthorizationService, ContentId, GroupId } from '@/base/domains';
import { AbstractUseCase, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { GroupExamCorrections, ReportService } from '../domains';

export type GetGroupExamCorrectionsRequest = {
  groupId: GroupId;
  contentId: ContentId;
  times: number;
};

export type GetGroupExamCorrectionsResponse = {
  report: GroupExamCorrections;
};

/**
 * グループ内受講生のテスト正答率リストを取得する
 */
export interface GetGroupExamCorrections
  extends UseCase<GetGroupExamCorrectionsRequest, GetGroupExamCorrectionsResponse> {
  execute(
    request: GetGroupExamCorrectionsRequest
  ): Promise<UseCaseResponse<GetGroupExamCorrectionsResponse>>;
}

export class GetGroupExamCorrectionsImpl
  extends AbstractUseCase<GetGroupExamCorrectionsRequest, GetGroupExamCorrectionsResponse>
  implements GetGroupExamCorrections
{
  constructor(
    private authorizationService: AuthorizationService,
    private reportService: ReportService
  ) {
    super('report.GetGroupExamCorrections');
  }

  async internalExecute(
    request: GetGroupExamCorrectionsRequest
  ): Promise<GetGroupExamCorrectionsResponse> {
    const { groupId, contentId, times } = request;
    this.authorizationService.assertGroupReportAccessible(groupId);
    const report = await this.reportService.getGroupExamCorrections(groupId, contentId, times);
    return { report };
  }
}

export const GetGroupExamCorrectionsKey = injectionKeyOf<GetGroupExamCorrections>({
  boundedContext: 'report',
  type: 'usecase',
  name: 'GetGroupExamCorrections',
});

export function useGetGroupExamCorrections(): GetGroupExamCorrections {
  return requiredInject(GetGroupExamCorrectionsKey);
}
