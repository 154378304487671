/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  EditingTenantOwnTermsOfServiceEntity,
  EditingTenantOwnTermsOfServiceRepository,
  TenantOwnTermsOfServiceReference,
} from '@/admin/domains';
import { TenantCode, TenantOwnTermsOfService } from '@/base/domains';
import { MarkDownString, Optional } from '@/base/types';

export class InMemoryEditingTenantOwnTermsOfServiceRepository
  implements EditingTenantOwnTermsOfServiceRepository
{
  constructor(
    private editingTenantTermsOfService: EditingTenantOwnTermsOfServiceEntity,
    private tenantOwnTermsOfService: TenantOwnTermsOfService
  ) {}

  save(body: MarkDownString, version?: number): Promise<EditingTenantOwnTermsOfServiceEntity> {
    return Promise.resolve({
      ...this.editingTenantTermsOfService,
    });
  }

  findEditingTenantOwnTermsOfService(): Promise<Optional<EditingTenantOwnTermsOfServiceEntity>> {
    return Promise.resolve(undefined);
  }

  remove(tenantCode: TenantCode): Promise<void> {
    return Promise.resolve();
  }

  confirm(version: number): Promise<TenantOwnTermsOfServiceReference> {
    return Promise.resolve({
      ...this.tenantOwnTermsOfService,
    });
  }
}
