import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { LocalDateTime, Minute, Optional, URI } from '../types';
import {
  ContentId,
  ContentName,
  ContentType,
  CourseId,
  CourseName,
  CourseVersion,
} from './content';
import { UserId } from './Core';

/**
 * コースにおけるコンテンツ。
 */
export interface CourseContent {
  /** コンテンツタイプ */
  readonly type: ContentType;
  /** 名前 */
  readonly name: ContentName;
  /** コンテンツID */
  readonly id: ContentId;
  /** 所要時間 */
  readonly requiredTime: Minute;
}

export type CourseColor = string;

/** コース イメージ上のフォントカラー */
export type CourseFontColorOnImage = 'black' | 'white';

/**
 * コース。データと振る舞い。
 */
export interface Course {
  /** ID */
  readonly id: CourseId;
  /** 名前 */
  readonly name: CourseName;
  /** コースにおけるコンテンツのリスト */
  readonly contents: Array<CourseContent>;
  /** 説明 */
  readonly description?: string;
  /** バージョン */
  readonly version: CourseVersion;
  /** カラー */
  readonly color?: CourseColor;
  /** イメージ */
  readonly image?: URI;
  /** フォントカラー */
  readonly fontColorOnImage?: CourseFontColorOnImage;
  /** 確定ユーザー */
  readonly confirmedBy?: UserId;
  /** 確定日時 */
  readonly confirmedAt: LocalDateTime;
  /** バージョン作成ユーザー */
  readonly versionCreatedBy?: UserId;
  /** バージョン作成日時 */
  readonly versionCreatedAt?: LocalDateTime;
  /** コンテンツ最終更新ユーザー */
  readonly contentLastUpdatedBy?: UserId;
  /** コンテンツ最終更新日時 */
  readonly contentLastUpdatedAt?: LocalDateTime;

  /**
   * 名前でコンテンツを検索する。
   *
   * @param contentName 名前
   */
  findContentByName(contentName: ContentName): Optional<CourseContent>;

  /**
   * IDでコンテンツを検索する。
   *
   * @param contentId コンテンツID
   */
  findContentById(contentId: ContentId): Optional<CourseContent>;
}

export interface CourseFinder {
  findByKey(id: CourseId, version: CourseVersion): Promise<Optional<Course>>;
  findActiveCourseById(id: CourseId): Promise<Optional<Course>>;
  findActiveCourseByName(name: CourseName): Promise<Optional<Course>>;
  findTenantCourses(): Promise<Array<Course>>;
  findTenantActiveCourses(): Promise<Array<Course>>;
}

export const CourseFinderKey = injectionKeyOf<CourseFinder>({
  boundedContext: 'base',
  type: 'adapter',
  name: 'CourseFinder',
});

export function useCourseFinder(): CourseFinder {
  return requiredInject(CourseFinderKey);
}
