/* eslint-disable @typescript-eslint/no-unused-vars */
import { v4 } from 'uuid';

import {
  AppContextProvider,
  Email,
  GroupRole,
  Role,
  SignedInUser,
  SignUpReservationId,
  UserCode,
  UserId,
} from '@/base/domains';
import {
  AuthService,
  AuthServiceChangePasswordArgs,
  AuthServiceReserveSignUpArgs,
  AuthServiceSignInRequest,
  AuthServiceSignUpRequest,
  ConfirmSignUpRequest,
  isAuthServiceSignInWithEmailRequest,
} from '@/base/domains/AuthService';

export class InMemoryAuthService implements AuthService {
  private users: Array<SignedInUser>;

  private appContextProvider: AppContextProvider;

  constructor(users: Array<SignedInUser>, appContextProvider: AppContextProvider) {
    this.users = users;
    this.appContextProvider = appContextProvider;
  }

  currentUser(): Promise<(SignedInUser & { signedInAtLeastOnce: boolean }) | undefined> {
    const { user } = this.appContextProvider.get();
    if (user) {
      return Promise.resolve({
        ...user,
        signedInAtLeastOnce: true,
      });
    }
    return Promise.resolve(undefined);
  }

  signUp(request: AuthServiceSignUpRequest): Promise<string> {
    return Promise.resolve(v4());
  }

  resendSignUp(userName: string): Promise<void> {
    return Promise.resolve();
  }

  confirmSignUp(request: ConfirmSignUpRequest): Promise<void> {
    return Promise.resolve();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  signIn(
    request: AuthServiceSignInRequest
  ): Promise<SignedInUser & { signedInAtLeastOnce: boolean }> {
    if (!isAuthServiceSignInWithEmailRequest(request)) {
      throw new Error('userCode sign-in is unsupported in mock mode');
    }
    const { email } = request;
    const user = this.users.find((u) => u.email === email);
    if (user) {
      return Promise.resolve({
        ...user,
        signedInAtLeastOnce: true,
      });
    }
    return Promise.reject();
  }

  signOut(): Promise<void> {
    return Promise.resolve();
  }

  reserveSignUp(args: AuthServiceReserveSignUpArgs): Promise<void> {
    return Promise.resolve();
  }

  removeSignUpReservation(id: SignUpReservationId): Promise<void> {
    return Promise.resolve();
  }

  forgotPassword(email: Email): Promise<void> {
    return Promise.resolve();
  }

  changePassword(args: AuthServiceChangePasswordArgs): Promise<void> {
    return Promise.resolve();
  }

  changePasswordByAdmin(args: {
    id: string;
    password: string;
    forcePasswordChange: boolean;
  }): Promise<void> {
    return Promise.resolve();
  }

  createUserByAdmin(args: {
    name: string;
    email?: Email;
    code?: UserCode;
    password: string;
    role: Role;
    groups?: {
      id: string;
      role: GroupRole;
    }[];
    forcePasswordChange: boolean;
  }): Promise<UserId> {
    return Promise.resolve(v4());
  }
}
