import { UserData } from '@/base/domains';
import { SlackAppType, SlackUserExtensionConfig } from '@/base/domains/extensions/slack';

const slackUserId = process.env.VUE_APP_DEV_SLACK_USER_ID ?? 'user00';
const slackAppType = (process.env.VUE_APP_DEV_SLACK_APP_TYPE ?? 'web') as SlackAppType;
const slackExtensionConfig: SlackUserExtensionConfig = {
  version: '0.0.1',
  name: 'slack',
  userId: slackUserId,
  appType: slackAppType,
};
export const users: Array<UserData> = [
  {
    id: 'user00',
    code: 'user00',
    name: '一般ユーザー00',
    role: 'general',
    extensionConfigs: [slackExtensionConfig],
    email: 'user00@knowte.com',
    tenantCode: 'dev',
    locale: 'ja',
    avatar: 'https://picsum.photos/id/1074/48/48',
    tags: [{ id: 'tag0', text: 'tag-text', color: 'indigo' }],
    status: 'active',
    enabled: true,
    signedInAtLeastOnce: true,
    confirmedTermsOfServiceVersions: {},
  },
  {
    id: 'user01',
    code: 'user01',
    name: '一般ユーザー01',
    role: 'general',
    extensionConfigs: [slackExtensionConfig],
    email: 'user01@knowte.com',
    tenantCode: 'dev',
    locale: 'ja',
    avatar: 'https://picsum.photos/id/1075/48/48',
    tags: [{ id: 'tag1', text: 'tag-text', color: 'red' }],
    status: 'active',
    enabled: true,
    signedInAtLeastOnce: true,
    confirmedTermsOfServiceVersions: {},
  },
  {
    id: 'user02',
    code: 'user02',
    name: '一般ユーザー02',
    role: 'general',
    extensionConfigs: [slackExtensionConfig],
    email: 'user02@knowte.com',
    tenantCode: 'dev',
    locale: 'ja',
    avatar: 'https://picsum.photos/id/1070/48/48',
    tags: [],
    status: 'active',
    enabled: true,
    signedInAtLeastOnce: true,
    confirmedTermsOfServiceVersions: {},
  },
  {
    id: 'user03',
    code: 'user03',
    name: '一般ユーザー03',
    role: 'general',
    extensionConfigs: [slackExtensionConfig],
    email: 'user03@knowte.com',
    tenantCode: 'dev',
    locale: 'ja',
    avatar: 'https://picsum.photos/id/1076/48/48',
    tags: [],
    status: 'active',
    enabled: true,
    signedInAtLeastOnce: true,
    confirmedTermsOfServiceVersions: {},
  },
  {
    id: 'user04',
    code: 'user04',
    name: '一般ユーザー04',
    role: 'general',
    extensionConfigs: [slackExtensionConfig],
    email: 'user04@knowte.com',
    tenantCode: 'dev',
    locale: 'ja',
    tags: [],
    status: 'active',
    enabled: true,
    signedInAtLeastOnce: true,
    confirmedTermsOfServiceVersions: {},
  },
  {
    id: 'user05',
    code: 'user05',
    name: '一般ユーザー05',
    role: 'general',
    extensionConfigs: [slackExtensionConfig],
    email: 'user05@knowte.com',
    tenantCode: 'dev',
    locale: 'ja',
    tags: [],
    status: 'active',
    enabled: true,
    signedInAtLeastOnce: true,
    confirmedTermsOfServiceVersions: {},
  },
  {
    id: 'user06',
    code: 'user06',
    name: '一般ユーザー06',
    role: 'general',
    extensionConfigs: [slackExtensionConfig],
    email: 'user06@knowte.com',
    tenantCode: 'dev',
    locale: 'ja',
    tags: [],
    status: 'active',
    enabled: true,
    signedInAtLeastOnce: true,
    confirmedTermsOfServiceVersions: {},
  },
  {
    id: 'trainer01',
    code: 'trainer01',
    name: '講師ユーザー00',
    role: 'admin',
    extensionConfigs: [slackExtensionConfig],
    email: 'trainer01@knowte.com',
    tenantCode: 'dev',
    locale: 'ja',
    avatar: 'https://picsum.photos/id/1077/48/48',
    tags: [],
    status: 'active',
    enabled: true,
    signedInAtLeastOnce: true,
    confirmedTermsOfServiceVersions: {},
  },
  {
    id: 'mentor01',
    code: 'mentor01',
    name: 'メンターユーザー01',
    role: 'general',
    extensionConfigs: [slackExtensionConfig],
    email: 'mentor01@knowte.com',
    tenantCode: 'dev',
    locale: 'ja',
    tags: [],
    status: 'active',
    enabled: true,
    signedInAtLeastOnce: true,
    confirmedTermsOfServiceVersions: {},
  },
  {
    id: 'admin00',
    code: 'admin00',
    name: '管理者00',
    role: 'admin',
    extensionConfigs: [slackExtensionConfig],
    email: 'admin00@knowte.com',
    tenantCode: 'dev',
    locale: 'ja',
    avatar: 'https://picsum.photos/id/1081/48/48',
    tags: [],
    status: 'active',
    enabled: true,
    signedInAtLeastOnce: true,
    confirmedTermsOfServiceVersions: {},
  },
  {
    id: 'admin01',
    code: 'admin-ja',
    name: '管理者(JA)',
    role: 'admin',
    extensionConfigs: [slackExtensionConfig],
    email: 'admin-ja@knowte.com',
    tenantCode: 'dev',
    locale: 'ja',
    tags: [],
    status: 'active',
    enabled: true,
    signedInAtLeastOnce: true,
    confirmedTermsOfServiceVersions: {},
  },
  {
    id: 'admin02',
    code: 'admin-en',
    name: 'Administrator(EN)',
    role: 'admin',
    extensionConfigs: [slackExtensionConfig],
    email: 'admin-en@knowte.com',
    tenantCode: 'dev',
    locale: 'en',
    tags: [],
    status: 'active',
    enabled: true,
    signedInAtLeastOnce: true,
    confirmedTermsOfServiceVersions: {},
  },
  {
    id: 'admin03',
    code: 'admin-vi',
    name: 'Administrator(VI)',
    role: 'admin',
    extensionConfigs: [slackExtensionConfig],
    email: 'admin-vi@knowte.com',
    tenantCode: 'dev',
    locale: 'vi',
    tags: [],
    status: 'active',
    enabled: true,
    signedInAtLeastOnce: true,
    confirmedTermsOfServiceVersions: {},
  },
  {
    id: 'admin04',
    code: 'admin-id',
    name: 'Administrator(ID)',
    role: 'admin',
    extensionConfigs: [slackExtensionConfig],
    email: 'admin-id@knowte.com',
    tenantCode: 'dev',
    locale: 'id',
    tags: [],
    status: 'active',
    enabled: true,
    signedInAtLeastOnce: true,
    confirmedTermsOfServiceVersions: {},
  },
  {
    id: 'sv',
    code: 'sv',
    name: 'SV',
    role: 'supervisor',
    extensionConfigs: [slackExtensionConfig],
    email: 'sv@knowte.com',
    tenantCode: 'dev',
    locale: 'ja',
    avatar: 'https://picsum.photos/id/1080/48/48',
    tags: [],
    status: 'active',
    enabled: true,
    signedInAtLeastOnce: true,
    confirmedTermsOfServiceVersions: {},
  },
];
