import { AuthorizationService, CourseId, ExamDataAdapter, GroupId } from '@/base/domains';
import { AbstractUseCase, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { GroupTrainingCourseRepository } from '../domains';
import { GROUP_COURSE_REMOVE_FAILED_GROUP_EXAM_IS_OPEN } from '../ErrorCodes';

export interface RemoveGroupCourseRequest {
  groupId: GroupId;
  courseId: CourseId;
}

/**
 * グループコースを削除する
 */
export interface RemoveGroupCourse extends UseCase<RemoveGroupCourseRequest, {}> {
  execute(request: RemoveGroupCourseRequest): Promise<UseCaseResponse<{}>>;
}

export class RemoveGroupCourseImpl
  extends AbstractUseCase<RemoveGroupCourseRequest, {}>
  implements RemoveGroupCourse
{
  constructor(
    private authorizationService: AuthorizationService,
    private groupTrainingCourseRepository: GroupTrainingCourseRepository,
    private examDataAdapter: ExamDataAdapter
  ) {
    super('training.RemoveGroupCourse');
  }

  async internalExecute(request: RemoveGroupCourseRequest): Promise<{}> {
    const { groupId, courseId } = request;
    this.authorizationService.assertTrainerAccessible(groupId);
    const groupExams = await this.examDataAdapter.findOpenGroupExamsByCourseId(courseId, groupId);
    if (groupExams.length > 0) {
      throw GROUP_COURSE_REMOVE_FAILED_GROUP_EXAM_IS_OPEN.toApplicationError();
    }

    const groupTrainingCourse = await this.groupTrainingCourseRepository.findByGroupIdAndCourseId(
      groupId,
      courseId
    );
    if (groupTrainingCourse) {
      await this.groupTrainingCourseRepository.remove(groupTrainingCourse.id);
    }
    return {};
  }
}

export const RemoveGroupCourseKey = injectionKeyOf<RemoveGroupCourse>({
  boundedContext: 'contents',
  type: 'usecase',
  name: 'RemoveGroupCourse',
});

export function useRemoveGroupCourse(): RemoveGroupCourse {
  return requiredInject(RemoveGroupCourseKey);
}
