import { assertIsDefined } from '@/utils/Asserts';
import { hasProperty } from '@/utils/TsUtils';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import {
  AuthorizationService,
  ExamDataAdapter,
  GroupExam,
  GroupExamContent,
  GroupExamId,
  GroupTrainingFinder,
  UserExam,
  UserId,
} from '../domains';
import { AbstractUseCase, UseCase, UseCaseResponse } from './UseCase';
import { assertEntityExists } from './UseCaseAsserts';

export type GetGroupExamByTrainerRequest = {
  id: GroupExamId;
};

export type GetGroupExamByTrainerGroupExam = Omit<GroupExam, 'content' | 'userExams'> & {
  content: GroupExamContent & { open: boolean };
  userExams: Array<UserExam & { score?: number }>;
};

export type GetGroupExamByTrainerResponse = {
  groupExam?: GetGroupExamByTrainerGroupExam;
};

/**
 * トレーナーがグループテストを取得する
 */
export interface GetGroupExamByTrainer
  extends UseCase<GetGroupExamByTrainerRequest, GetGroupExamByTrainerResponse> {
  execute(
    request: GetGroupExamByTrainerRequest
  ): Promise<UseCaseResponse<GetGroupExamByTrainerResponse>>;
}

export class GetGroupExamByTrainerImpl
  extends AbstractUseCase<GetGroupExamByTrainerRequest, GetGroupExamByTrainerResponse>
  implements GetGroupExamByTrainer
{
  private authorizationService: AuthorizationService;

  private groupTrainingFinder: GroupTrainingFinder;

  private examDataAdapter: ExamDataAdapter;

  constructor(
    authorizationService: AuthorizationService,
    groupTrainingFinder: GroupTrainingFinder,
    examDataAdapter: ExamDataAdapter
  ) {
    super('base.GetGroupExamByTrainer');
    this.authorizationService = authorizationService;
    this.groupTrainingFinder = groupTrainingFinder;
    this.examDataAdapter = examDataAdapter;
  }

  async internalExecute(
    request: GetGroupExamByTrainerRequest
  ): Promise<GetGroupExamByTrainerResponse> {
    const { id } = request;
    const groupExam = await this.examDataAdapter.findGroupExamById(id);
    if (!groupExam) {
      return {};
    }
    const { groupId, content } = groupExam;
    this.authorizationService.assertGroupReportAccessible(groupId);
    const [course, examResults] = await Promise.all([
      this.groupTrainingFinder.findCourseByGroupIdAndCourseId(groupId, groupExam.course.id),
      this.examDataAdapter.findExamResultsByGroupExamId(id),
    ]);
    assertEntityExists(course, 'groupTrainingCourse');
    const c = course.contents.find((cn) => cn.id === content.id);
    assertIsDefined(c, 'groupTrainingCourse.content');
    const examResultMap: Map<UserId, { score: number; isPassed?: boolean }> = new Map(
      examResults.map((er) => [
        er.userId,
        { score: er.score, isPassed: hasProperty(er, 'isPassed') ? er.isPassed : undefined },
      ])
    );
    return {
      groupExam: {
        ...groupExam,
        content: {
          id: groupExam.content.id,
          version: groupExam.content.version,
          name: groupExam.content.name,
          requiredTime: groupExam.content.requiredTime,
          indexInCourse: groupExam.content.indexInCourse,
          problems: groupExam.content.problems,
          problemHeaders: groupExam.content.problemHeaders,
          open: c.open,
        },
        userExams: groupExam.userExams.map((ue) => ({
          ...ue,
          ...examResultMap.get(ue.userId),
        })),
      },
    };
  }
}

export const GetGroupExamByTrainerKey = injectionKeyOf<GetGroupExamByTrainer>({
  boundedContext: 'base',
  type: 'usecase',
  name: 'GetGroupExamByTrainer',
});

export function useGetGroupExamByTrainer(): GetGroupExamByTrainer {
  return requiredInject(GetGroupExamByTrainerKey);
}
