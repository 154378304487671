// =========================================================================
// User
// =========================================================================
export const onCreateUserStatusTableByTenantCode = /* GraphQL */ `
  subscription OnCreateUserStatusTableByTenantCode($tenantCode: String!) {
    onCreateUserStatusTableByTenantCode(tenantCode: $tenantCode) {
      id
      tenantCode
      status
      userId
      createdAt
      updatedAt
      user {
        id
        name
        role
        extensionConfigJsonList
        email
        code
        tenantCode
        displaySettings {
          theme
          color
        }
        locale
        avatar
        enabled
        createdAt
        updatedAt
        tags {
          items {
            id
            userId
            userTagId
            userTag {
              id
              color
              text
              tenantCode
              createdAt
              updatedAt
            }
            tenantCode
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onUpdateUserStatusTableByTenantCode = /* GraphQL */ `
  subscription OnUpdateUserStatusTableByTenantCode($tenantCode: String!) {
    onUpdateUserStatusTableByTenantCode(tenantCode: $tenantCode) {
      id
      tenantCode
      status
      userId
      createdAt
      updatedAt
      user {
        id
        name
        role
        extensionConfigJsonList
        email
        code
        tenantCode
        displaySettings {
          theme
          color
        }
        locale
        avatar
        enabled
        createdAt
        updatedAt
        tags {
          items {
            id
            userId
            userTagId
            userTag {
              id
              color
              text
              tenantCode
              createdAt
              updatedAt
            }
            tenantCode
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onDeleteUserStatusTableByTenantCode = /* GraphQL */ `
  subscription OnDeleteUserStatusTableByTenantCode($tenantCode: String!) {
    onDeleteUserStatusTableByTenantCode(tenantCode: $tenantCode) {
      id
      tenantCode
      status
      userId
      createdAt
      updatedAt
      user {
        id
        name
        role
        extensionConfigJsonList
        email
        code
        tenantCode
        displaySettings {
          theme
          color
        }
        locale
        avatar
        enabled
        createdAt
        updatedAt
        tags {
          items {
            id
            userId
            userTagId
            userTag {
              id
              color
              text
              tenantCode
              createdAt
              updatedAt
            }
            tenantCode
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
