import BigNumber from 'bignumber.js';

import {
  ContentId,
  ContentLearning,
  ContentLearningDataAdapter,
  ContentType,
  CourseId,
  GroupId,
  LearningStatus,
  UserId,
} from '@/base/domains';
import { ISODateTimeString, Optional } from '@/base/types';
import * as queries from '@/graphql/queries';
import { graphql, graphqlQuery } from '@/utils/AmplifyUtils';
import { localDateTimeFromString } from '@/utils/DateUtils';
import { ifDefined, isDefined } from '@/utils/TsUtils';

type AmplifyContentLearning = {
  groupId: GroupId;
  courseId: CourseId;
  contentId: ContentId;
  contentType: ContentType;
  userId: UserId;
  status: LearningStatus;
  usageTime: number;
  completedAt?: string;
  completedUsageTime?: number;
  createdAt: ISODateTimeString;
  updatedAt: ISODateTimeString;
};

function toContentLearning(e: AmplifyContentLearning): ContentLearning {
  return {
    groupId: e.groupId,
    courseId: e.courseId,
    contentId: e.contentId,
    contentType: e.contentType.toLowerCase() as ContentType,
    userId: e.userId,
    status: e.status.toLowerCase() as LearningStatus,
    usageTime: new BigNumber(e.usageTime)
      .dividedBy(1000 * 60)
      .dp(0, BigNumber.ROUND_HALF_UP)
      .toNumber(),
    completedAt: ifDefined(e.completedAt, (v) => localDateTimeFromString(v)),
    completedUsageTime: ifDefined(e.completedUsageTime, (v) => v / (1000 * 60)),
    lastLearnedAt: localDateTimeFromString(e.updatedAt),
    // TODO #812 厳密にはContentLearning作成時に別途設定した方がよい
    startedAt: localDateTimeFromString(e.createdAt),
  };
}

export class AmplifyContentLearningDataAdapter implements ContentLearningDataAdapter {
  async findByGroupId(groupId: GroupId): Promise<Array<ContentLearning>> {
    const res = await graphqlQuery<{
      contentLearningsByGroupIdAndCourseId: { items: Array<AmplifyContentLearning> };
    }>(queries.contentLearningsByGroupIdAndCourseId, { groupId }, { limit: 10000 });
    return res.contentLearningsByGroupIdAndCourseId.items.map(toContentLearning);
  }

  async findByGroupIdAndUserId(groupId: GroupId, userId: UserId): Promise<Array<ContentLearning>> {
    const res = await graphqlQuery<{
      contentLearningsByGroupIdAndUserId: { items: Array<AmplifyContentLearning> };
    }>(
      queries.contentLearningsByGroupIdAndUserId,
      {
        groupId,
        userId: { eq: userId },
      },
      {
        limit: 10000,
      }
    );
    return res.contentLearningsByGroupIdAndUserId.items.map(toContentLearning);
  }

  async findByGroupIdAndCourseId({
    groupId,
    courseId,
    userId,
  }: {
    groupId: GroupId;
    courseId: CourseId;
    userId: UserId;
  }): Promise<Array<ContentLearning>> {
    const filter = (() => {
      if (isDefined(userId)) {
        return {
          userId: { eq: userId },
        };
      }
      return undefined;
    })();
    const res = await graphqlQuery<{
      contentLearningsByGroupIdAndCourseId: { items: Array<AmplifyContentLearning> };
    }>(
      queries.contentLearningsByGroupIdAndCourseId,
      {
        groupId,
        courseId: { eq: courseId },
        filter,
      },
      {
        limit: 10000,
      }
    );
    return res.contentLearningsByGroupIdAndCourseId.items.map(toContentLearning);
  }

  async findByUserId(userId: UserId): Promise<Array<ContentLearning>> {
    const res = await graphqlQuery<{
      contentLearningsByUser: { items: Array<AmplifyContentLearning> };
    }>(
      queries.contentLearningsByUser,
      {
        userId,
      },
      {
        limit: 10000,
      }
    );
    return res.contentLearningsByUser.items.map(toContentLearning);
  }

  async findByKey({
    groupId,
    userId,
    contentId,
  }: {
    groupId: GroupId;
    userId: UserId;
    contentId: ContentId;
  }): Promise<Optional<ContentLearning>> {
    const res = await graphql<{
      getContentLearning: Optional<AmplifyContentLearning>;
    }>(queries.getContentLearning, {
      groupId,
      contentId,
      userId,
    });
    return ifDefined(res.getContentLearning, toContentLearning);
  }
}
