import { AuthService, Email } from '@/base/domains';
import { AbstractUseCase, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

export interface ForgotPasswordRequest {
  email: Email;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export type ForgotPasswordResponse = {};

export interface ForgotPassword extends UseCase<ForgotPasswordRequest, ForgotPasswordResponse> {
  execute(request: ForgotPasswordRequest): Promise<UseCaseResponse<ForgotPasswordResponse>>;
}

export class ForgotPasswordImpl
  extends AbstractUseCase<ForgotPasswordRequest, ForgotPasswordResponse>
  implements ForgotPassword
{
  private authService: AuthService;

  constructor(authService: AuthService) {
    super('account.ForgotPassword');
    this.authService = authService;
  }

  async internalExecute(request: ForgotPasswordRequest): Promise<ForgotPasswordResponse> {
    await this.authService.forgotPassword(request.email);
    return {};
  }
}

export const ForgotPasswordKey = injectionKeyOf<ForgotPassword>({
  boundedContext: 'account',
  type: 'usecase',
  name: 'ForgotPassword',
});

export function useForgotPassword(): ForgotPassword {
  return requiredInject(ForgotPasswordKey);
}
