import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { Surviver } from '../domains';
import { AbstractUseCase, UseCase, UseCaseResponse } from './UseCase';

export type LiveRequest = {};

export type LiveResponse = {};

export interface Live extends UseCase<LiveRequest, LiveResponse> {
  execute(request: LiveRequest): Promise<UseCaseResponse<LiveResponse>>;
}

export class LiveImpl extends AbstractUseCase<LiveRequest, LiveResponse> implements Live {
  private surviver: Surviver;

  constructor(surviver: Surviver) {
    super('base.Live');
    this.surviver = surviver;
  }

  async internalExecute(_: LiveRequest): Promise<LiveResponse> {
    await this.surviver.live();
    return {};
  }
}

export const LiveKey = injectionKeyOf<Live>({
  boundedContext: 'base',
  type: 'usecase',
  name: 'Live',
});

export function useLive(): Live {
  return requiredInject(LiveKey);
}
