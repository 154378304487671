import { AuthorizationService, ContentId, CourseId, GroupId } from '@/base/domains';
import { AbstractUseCase, assertEntityExists, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { GroupTrainingCourseReference, GroupTrainingCourseRepository } from '../domains';

export interface OpenGroupContentRequest {
  groupId: GroupId;
  courseId: CourseId;
  contentId: ContentId;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export type OpenGroupContentResponse = {
  course: GroupTrainingCourseReference;
};

export interface OpenGroupContent
  extends UseCase<OpenGroupContentRequest, OpenGroupContentResponse> {
  execute(request: OpenGroupContentRequest): Promise<UseCaseResponse<OpenGroupContentResponse>>;
}

export class OpenGroupContentImpl
  extends AbstractUseCase<OpenGroupContentRequest, OpenGroupContentResponse>
  implements OpenGroupContent
{
  private authorizationService: AuthorizationService;

  private groupTrainingCourseRepository: GroupTrainingCourseRepository;

  constructor(
    authorizationService: AuthorizationService,
    groupTrainingCourseRepository: GroupTrainingCourseRepository
  ) {
    super('training.OpenGroupContent');
    this.authorizationService = authorizationService;
    this.groupTrainingCourseRepository = groupTrainingCourseRepository;
  }

  async internalExecute(request: OpenGroupContentRequest): Promise<OpenGroupContentResponse> {
    const { groupId, courseId, contentId } = request;
    this.authorizationService.assertTrainerAccessible(groupId);
    const course = await this.groupTrainingCourseRepository.findByGroupIdAndCourseId(
      groupId,
      courseId
    );
    assertEntityExists(course, 'groupTrainingCourse');
    const saved = await this.groupTrainingCourseRepository.save(course.openContent(contentId));
    return {
      course: saved,
    };
  }
}

export const OpenGroupContentKey = injectionKeyOf<OpenGroupContent>({
  boundedContext: 'training',
  type: 'usecase',
  name: 'OpenGroupContent',
});

export function useOpenGroupContent(): OpenGroupContent {
  return requiredInject(OpenGroupContentKey);
}
