import {
  ContentLearning,
  ContentLearningDataAdapter,
  CourseId,
  GroupId,
  UserId,
} from '@/base/domains';
import { Optional } from '@/base/types';
import { isDefined } from '@/utils/TsUtils';

export class InMemoryContentLearningDataAdapter implements ContentLearningDataAdapter {
  private contentLearning: Array<ContentLearning>;

  constructor(contentLearning: Array<ContentLearning>) {
    this.contentLearning = contentLearning;
  }

  findByKey({
    groupId,
    userId,
    contentId,
  }: {
    groupId: string;
    userId: string;
    contentId: string;
  }): Promise<Optional<ContentLearning>> {
    return Promise.resolve(
      this.contentLearning.find(
        (cl) => cl.groupId === groupId && cl.contentId === contentId && cl.userId === userId
      )
    );
  }

  findByGroupId(groupId: GroupId): Promise<Array<ContentLearning>> {
    return Promise.resolve(this.contentLearning.filter((cl) => cl.groupId === groupId));
  }

  findByGroupIdAndCourseId({
    groupId,
    courseId,
    userId,
  }: {
    groupId: GroupId;
    courseId: CourseId;
    userId: UserId;
  }): Promise<Array<ContentLearning>> {
    return Promise.resolve(
      this.contentLearning.filter(
        (cl) =>
          cl.groupId === groupId &&
          cl.courseId === courseId &&
          (!isDefined(userId) || cl.userId === userId)
      )
    );
  }

  findByGroupIdAndUserId(groupId: GroupId, userId: UserId): Promise<Array<ContentLearning>> {
    return Promise.resolve(
      this.contentLearning.filter((cl) => cl.groupId === groupId && cl.userId === userId)
    );
  }

  findByUserId(userId: UserId): Promise<ContentLearning[]> {
    return Promise.resolve(this.contentLearning.filter((cl) => cl.userId === userId));
  }
}
