import { AuthorizationService, ContentId, DataVersion, ProblemHeaderId } from '@/base/domains';
import { MarkDownString } from '@/base/types';
import {
  AbstractUseCase,
  assertDataVersion,
  assertEntityExists,
  UseCase,
  UseCaseResponse,
} from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { EditingConfirmedContentReference, EditingConfirmedContentRepository } from '../domains';

export type ChangeEditingConfirmedContentWorkbookProblemHeaderRequest = {
  id: ContentId;
  problemHeaderId: ProblemHeaderId;
  body: MarkDownString;
  expectedDataVersion: DataVersion;
};

export type ChangeEditingConfirmedContentWorkbookProblemHeaderResponse = {
  editingConfirmedContent: EditingConfirmedContentReference;
};

/**
 * 編集中確定済みコンテンツ練習問題の問題ヘッダーを編集する
 */
export interface ChangeEditingConfirmedContentWorkbookProblemHeader
  extends UseCase<
    ChangeEditingConfirmedContentWorkbookProblemHeaderRequest,
    ChangeEditingConfirmedContentWorkbookProblemHeaderResponse
  > {
  execute(
    request: ChangeEditingConfirmedContentWorkbookProblemHeaderRequest
  ): Promise<UseCaseResponse<ChangeEditingConfirmedContentWorkbookProblemHeaderResponse>>;
}

export class ChangeEditingConfirmedContentWorkbookProblemHeaderImpl
  extends AbstractUseCase<
    ChangeEditingConfirmedContentWorkbookProblemHeaderRequest,
    ChangeEditingConfirmedContentWorkbookProblemHeaderResponse
  >
  implements ChangeEditingConfirmedContentWorkbookProblemHeader
{
  constructor(
    private authorizationService: AuthorizationService,
    private editingConfirmedContentRepository: EditingConfirmedContentRepository
  ) {
    super('contents.ChangeEditingConfirmedContentWorkbookProblemHeader');
  }

  async internalExecute(
    request: ChangeEditingConfirmedContentWorkbookProblemHeaderRequest
  ): Promise<ChangeEditingConfirmedContentWorkbookProblemHeaderResponse> {
    const { id, problemHeaderId, body, expectedDataVersion } = request;
    this.authorizationService.assertContentEditable();
    const content = await this.editingConfirmedContentRepository.findById(id);
    assertEntityExists(content, 'editingConfirmedContent');
    assertDataVersion(content, expectedDataVersion, 'editingConfirmedContent');
    if (content.type === 'text') {
      const saved = await this.editingConfirmedContentRepository.save(
        content.changeWorkbookProblemHeader({
          problemHeaderId,
          problemHeaderBody: body,
        })
      );
      return {
        editingConfirmedContent: saved,
      };
    }
    throw new Error('editingConfirmedContent.type should be text');
  }
}

export const ChangeEditingConfirmedContentWorkbookProblemHeaderKey =
  injectionKeyOf<ChangeEditingConfirmedContentWorkbookProblemHeader>({
    boundedContext: 'contents',
    type: 'usecase',
    name: 'ChangeEditingConfirmedContentWorkbookProblemHeader',
  });

export function useChangeEditingConfirmedContentWorkbookProblemHeader(): ChangeEditingConfirmedContentWorkbookProblemHeader {
  return requiredInject(ChangeEditingConfirmedContentWorkbookProblemHeaderKey);
}
