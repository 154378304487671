import { assertCheckArgument } from '@/utils/Asserts';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { Optional, PlainData, URI } from '../types';
import { Email, Locale } from './Base';
import { UserId } from './Core';
import { AndroidDeviceToken } from './Device';
import { Entity, EntityReference, StandardRepository } from './Entity';
import { UserExtensionConfig } from './extensions/Extension';
import { PrivacyPolicyVersion } from './PrivacyPolicy';
import type { Subscription } from './Subscription';
import { TenantCode } from './Tenant';
import { TermsOfServiceId } from './TermsOfService';
import { UserTagId, UserTagReference } from './UserTag';

export type UserName = string;

export type UserCode = string;

export type Role = 'supervisor' | 'admin' | 'general';
export type GroupRole = 'trainer' | 'trainee' | 'mentor';
export type UserStatus = 'inactive' | 'active';

export type DisplaySettings = {
  theme: string;
  color: string;
};

export type ConfirmedTermsOfServiceVersions = {
  termsOfServiceVersion?: TermsOfServiceId;
  privacyPolicyVersion?: PrivacyPolicyVersion;
  tenantOwnTermsOfServiceVersion?: number;
};

export interface UserAttributes {
  name: UserName;
  role: Role;
  extensionConfigs: Array<UserExtensionConfig>;
  email?: Email;
  code?: UserCode;
  tenantCode: TenantCode;
  displaySettings?: DisplaySettings;
  locale: Locale;
  avatar?: URI;
  tags: Array<UserTagReference>;
  status: UserStatus;
  enabled: boolean;
  signedInAtLeastOnce: boolean;
  emailConfirmed?: boolean;
  confirmedTermsOfServiceVersions: ConfirmedTermsOfServiceVersions;
}

export interface UserCommands {
  changeName(name: UserName): UserEntity;
  changeDisplaySettings(displaySettings: DisplaySettings): UserEntity;
  changeLocale(locale: Locale): UserEntity;
  changeRole(role: Role): UserEntity;
  changeAvatar(avatar?: URI): UserEntity;
  removeAvatar(): UserEntity;
  enable(): UserEntity;
  disable(): UserEntity;
  firstSignIn(locale: Locale): UserEntity;
  confirmTermsOfService(
    termsOfServiceVersion?: TermsOfServiceId,
    privacyPolicyVersion?: PrivacyPolicyVersion,
    tenantOwnTermsOfServiceVersion?: number
  ): UserEntity;
}

export type UserReference = EntityReference<UserId, UserAttributes, UserCommands>;

export type UserEntity = Entity<UserId, UserAttributes> & UserCommands;

export class UserEntityImpl implements UserEntity {
  id: UserId;

  name: UserName;

  role: Role;

  extensionConfigs: Array<UserExtensionConfig>;

  email?: Email;

  code?: UserCode;

  tenantCode: TenantCode;

  displaySettings?: DisplaySettings;

  locale: Locale;

  avatar?: URI;

  tags: Array<UserTagReference>;

  status: UserStatus;

  enabled: boolean;

  signedInAtLeastOnce: boolean;

  emailConfirmed?: boolean;

  confirmedTermsOfServiceVersions: ConfirmedTermsOfServiceVersions;

  constructor(data: UserData) {
    this.id = data.id;
    this.name = data.name;
    this.role = data.role;
    this.extensionConfigs = data.extensionConfigs;
    this.email = data.email;
    this.code = data.code;
    this.tenantCode = data.tenantCode;
    this.displaySettings = data.displaySettings;
    this.locale = data.locale;
    this.avatar = data.avatar;
    this.tags = data.tags;
    this.status = data.status;
    this.enabled = data.enabled;
    this.signedInAtLeastOnce = data.signedInAtLeastOnce;
    this.emailConfirmed = data.emailConfirmed;
    this.confirmedTermsOfServiceVersions = data.confirmedTermsOfServiceVersions;
  }

  changeName(name: UserName): UserEntity {
    return new UserEntityImpl({
      ...this,
      name,
    });
  }

  changeDisplaySettings(displaySettings: DisplaySettings): UserEntity {
    return new UserEntityImpl({
      ...this,
      displaySettings,
    });
  }

  changeLocale(locale: Locale): UserEntity {
    return new UserEntityImpl({
      ...this,
      locale,
    });
  }

  changeRole(role: Role): UserEntity {
    assertCheckArgument(role !== 'supervisor', 'role should not be supervisor');
    return new UserEntityImpl({
      ...this,
      role,
    });
  }

  changeAvatar(avatar?: URI): UserEntity {
    return new UserEntityImpl({
      ...this,
      avatar,
    });
  }

  removeAvatar(): UserEntity {
    return new UserEntityImpl({
      ...this,
      avatar: undefined,
    });
  }

  enable(): UserEntity {
    return new UserEntityImpl({
      ...this,
      enabled: true,
    });
  }

  disable(): UserEntity {
    return new UserEntityImpl({
      ...this,
      enabled: false,
    });
  }

  firstSignIn(locale: Locale): UserEntity {
    if (this.signedInAtLeastOnce) {
      throw Error('the user should not have been signed in');
    }
    return new UserEntityImpl({
      ...this,
      signedInAtLeastOnce: true,
      locale,
    });
  }

  confirmTermsOfService(
    termsOfServiceVersion?: TermsOfServiceId,
    privacyPolicyVersion?: PrivacyPolicyVersion,
    tenantOwnTermsOfServiceVersion?: number
  ): UserEntity {
    return new UserEntityImpl({
      ...this,
      confirmedTermsOfServiceVersions: {
        termsOfServiceVersion:
          termsOfServiceVersion ?? this.confirmedTermsOfServiceVersions.termsOfServiceVersion,
        privacyPolicyVersion:
          privacyPolicyVersion ?? this.confirmedTermsOfServiceVersions.privacyPolicyVersion,
        tenantOwnTermsOfServiceVersion:
          tenantOwnTermsOfServiceVersion ??
          this.confirmedTermsOfServiceVersions.tenantOwnTermsOfServiceVersion,
      },
    });
  }
}

export type UserData = PlainData<UserEntity>;

export type AndroidUserDevice = {
  type: 'android';
  token: AndroidDeviceToken;
};

export type UserDevice = AndroidUserDevice;

export interface UserRepository extends StandardRepository<UserId, UserAttributes, UserEntity> {
  findTenantUsers(): Promise<Array<UserEntity>>;
  findTenantEnabledUsers(): Promise<Array<UserEntity>>;
  replaceTags(id: UserId, tagsIds: Array<UserTagId>): Promise<UserEntity>;
  subscribeUserStatusChanged(args: {
    onNext: (user: UserEntity) => void;
    onError: (e: Error) => void;
  }): Subscription;
  addUserDevice(userDevice: UserDevice): Promise<void>;
}

export const UserRepositoryKey = injectionKeyOf<UserRepository>({
  boundedContext: 'base',
  type: 'adapter',
  name: 'UserRepository',
});

export function useUserRepository(): UserRepository {
  return requiredInject(UserRepositoryKey);
}

export type User = {
  readonly id: UserId;

  readonly name: UserName;

  readonly role: Role;

  readonly extensionConfigs: Array<UserExtensionConfig>;

  readonly email?: Email;

  readonly code?: UserCode;

  readonly tenantCode: TenantCode;

  readonly locale: Locale;

  readonly avatar?: URI;

  readonly tags: Array<UserTagReference>;

  readonly status: UserStatus;

  readonly enabled: boolean;

  readonly confirmedTermsOfServiceVersions: ConfirmedTermsOfServiceVersions;
};

export interface UserFinder {
  findTenantUsers(): Promise<Array<User>>;
  findTenantEnabledUsers(): Promise<Array<User>>;
  findById(id: UserId): Promise<Optional<User>>;
}

export const UserFinderKey = injectionKeyOf<UserFinder>({
  boundedContext: 'base',
  type: 'service',
  name: 'UserFinder',
});

export function useUserFinder(): UserFinder {
  return requiredInject(UserFinderKey);
}

/**
 * ユーザーにつけるタグの上限
 */
export const USER_TAGS_LIMITATION = 10;
