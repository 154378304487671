import { AuthorizationService, ContentId, DataVersion, DateTimeService } from '@/base/domains';
import { MarkDownString } from '@/base/types';
import {
  AbstractUseCase,
  assertDataVersion,
  assertEntityExists,
  UseCase,
  UseCaseResponse,
} from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { EditingCourseContentBodyReference, EditingCourseContentBodyRepository } from '../domains';
import { CONTENT_TYPE_SHOULD_BE_EXAM } from '../ErrorCodes';

export type AddProblemHeaderToEditingCourseContentRequest = {
  id: ContentId;
  body: MarkDownString;
  expectedDataVersion: DataVersion;
};

export type AddProblemHeaderToEditingCourseContentResponse = {
  content: EditingCourseContentBodyReference;
};

/**
 * 編集中コンテンツに問題ヘッダーを追加する
 */
export interface AddProblemHeaderToEditingCourseContent
  extends UseCase<
    AddProblemHeaderToEditingCourseContentRequest,
    AddProblemHeaderToEditingCourseContentResponse
  > {
  execute(
    request: AddProblemHeaderToEditingCourseContentRequest
  ): Promise<UseCaseResponse<AddProblemHeaderToEditingCourseContentResponse>>;
}

export class AddProblemHeaderToEditingCourseContentImpl
  extends AbstractUseCase<
    AddProblemHeaderToEditingCourseContentRequest,
    AddProblemHeaderToEditingCourseContentResponse
  >
  implements AddProblemHeaderToEditingCourseContent
{
  private authorizationService: AuthorizationService;

  private editingCourseContentBodyRepository: EditingCourseContentBodyRepository;

  private dateTimeService: DateTimeService;

  constructor(
    authorizationService: AuthorizationService,
    editingCourseContentBodyRepository: EditingCourseContentBodyRepository,
    dateTimeService: DateTimeService
  ) {
    super('contents.AddProblemHeaderToEditingCourseContent');
    this.authorizationService = authorizationService;
    this.editingCourseContentBodyRepository = editingCourseContentBodyRepository;
    this.dateTimeService = dateTimeService;
  }

  async internalExecute(
    request: AddProblemHeaderToEditingCourseContentRequest
  ): Promise<AddProblemHeaderToEditingCourseContentResponse> {
    const { id, body, expectedDataVersion } = request;
    this.authorizationService.assertContentEditable();
    const [content, createdAt] = await Promise.all([
      this.editingCourseContentBodyRepository.findById(id),
      this.dateTimeService.strictLocalDateTimeNow(),
    ]);
    assertEntityExists(content, 'editingCourseContentBody');
    assertDataVersion(content, expectedDataVersion, 'editingCourseContentBody');
    if (content.type !== 'exam') {
      throw CONTENT_TYPE_SHOULD_BE_EXAM.toApplicationError({
        payload: {
          id,
        },
      });
    }
    const saved = await this.editingCourseContentBodyRepository.save(
      content.addProblemHeader({
        body,
        createdAt,
      })
    );
    return {
      content: saved,
    };
  }
}

export const AddProblemHeaderToEditingCourseContentKey =
  injectionKeyOf<AddProblemHeaderToEditingCourseContent>({
    boundedContext: 'contents',
    type: 'usecase',
    name: 'AddProblemHeaderToEditingCourseContent',
  });

export function useAddProblemHeaderToEditingCourseContent(): AddProblemHeaderToEditingCourseContent {
  return requiredInject(AddProblemHeaderToEditingCourseContentKey);
}
