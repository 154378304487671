import { CreateInternalTaskInput, InternalTaskStatus as AmplifyInternalTaskStatus } from '@/API';
import { assertEntityExists } from '@/base/usecases';
import * as mutations from '@/graphql/mutations';
import * as queries from '@/graphql/queries';
import * as subscriptions from '@/graphql/subscriptions';
import { graphql, graphqlSubscribe } from '@/utils/AmplifyUtils';
import { localDateTimeFromString } from '@/utils/DateUtils';
import { ifDefined, isDefined, requiredNonNull } from '@/utils/TsUtils';

import {
  AppContextProvider,
  InternalTask,
  InternalTaskError,
  InternalTaskId,
  InternalTaskPayload,
  InternalTaskService,
  InternalTaskServiceStartRunner,
  InternalTaskStatus,
  InternalTaskType,
  InternalTaskVersion,
  UserId,
} from '../../domains';
import { ISODateTimeString, JSONString } from '../../types';

type AmplifyInternalTask = {
  id: InternalTaskId;
  type: string;
  createdAt: ISODateTimeString;
  createdBy: UserId;
  finishedAt?: ISODateTimeString;
  status: AmplifyInternalTaskStatus;
  payload: JSONString;
  taskVersion: InternalTaskVersion;
};

function toInternalTask<T extends InternalTaskType<P>, P extends InternalTaskPayload>(
  amplifyInternalTask: AmplifyInternalTask
): InternalTask<T, P> {
  return {
    id: amplifyInternalTask.id,
    type: amplifyInternalTask.type as unknown as T,
    createdAt: localDateTimeFromString(amplifyInternalTask.createdAt),
    createdBy: amplifyInternalTask.createdBy,
    finishedAt: ifDefined(amplifyInternalTask.finishedAt, localDateTimeFromString),
    status: amplifyInternalTask.status.toLocaleLowerCase() as InternalTaskStatus,
    payload: JSON.parse(amplifyInternalTask.payload) as unknown as P,
    taskVersion: amplifyInternalTask.taskVersion,
  };
}

export class AmplifyInternalTaskService implements InternalTaskService {
  constructor(private appContextProvider: AppContextProvider) {}

  start<P extends InternalTaskPayload>(
    type: InternalTaskType<P>,
    taskVersion: InternalTaskVersion = 1
  ): InternalTaskServiceStartRunner<InternalTaskType<P>, P> {
    const userId = requiredNonNull(this.appContextProvider.get().user?.id, 'appContext.user');
    const tenantCode = requiredNonNull(
      this.appContextProvider.get().tenantCode,
      'appContext.tenantCode'
    );
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;
    const runner: InternalTaskServiceStartRunner<InternalTaskType<P>, P> = {
      async runWith({
        payload,
        onFinished,
        onError,
      }: {
        payload: P;
        onFinished?: (task: InternalTask<InternalTaskType<P>, P>) => void;
        onError?: (errors: InternalTaskError[]) => void;
      }): Promise<InternalTask<InternalTaskType<P>, P>> {
        const p = isDefined(payload.errors)
          ? payload
          : {
              ...payload,
              errors: [],
            };
        const input: CreateInternalTaskInput = {
          type: type as string,
          createdBy: userId,
          status: AmplifyInternalTaskStatus.CREATED,
          payload: JSON.stringify(p),
          tenantCode,
          taskVersion,
        };
        const res = await graphql<{ createInternalTask: AmplifyInternalTask }>(
          mutations.createInternalTask,
          { input }
        );
        const internalTask = toInternalTask<InternalTaskType<P>, P>(res.createInternalTask);
        if (onError || onFinished) {
          const e: (errors: InternalTaskError[]) => void =
            onError ??
            ((_) => {
              // do nothing
            });
          const f: (internalTask: InternalTask<InternalTaskType<P>, P>) => void =
            onFinished ??
            ((_) => {
              // do nothing
            });
          that.subscribeTaskFinished({
            id: internalTask.id,
            onFinished: f,
            onError: e,
          });
        }
        return internalTask;
      },
    };

    return runner;
  }

  async get<T extends InternalTaskType<P>, P extends InternalTaskPayload>(
    id: string
  ): Promise<InternalTask<T, P>> {
    const res = await graphql<{ getInternalTask?: AmplifyInternalTask }>(queries.getInternalTask, {
      id,
    });
    const amplifyInternalTask = res.getInternalTask;
    assertEntityExists(amplifyInternalTask, 'internalTask');
    return toInternalTask(amplifyInternalTask);
  }

  subscribeTaskFinished<T extends InternalTaskType<P>, P extends InternalTaskPayload>({
    id,
    onFinished,
    onError,
  }: {
    id: string;
    onFinished: (task: InternalTask<T, P>) => void;
    onError: (errors: InternalTaskError[]) => void;
  }): void {
    const res = graphqlSubscribe<{
      value: { data: { onUpdateInternalTaskById: AmplifyInternalTask } };
    }>(subscriptions.onUpdateInternalTaskById, { id });
    const subscription = res.subscribe(
      (x: { value: { data: { onUpdateInternalTaskById: AmplifyInternalTask } } }) => {
        const internalTask: InternalTask<T, P> = toInternalTask(
          x.value.data.onUpdateInternalTaskById
        );
        if (internalTask.status === 'failed') {
          onError(internalTask.payload.errors);
          subscription.unsubscribe();
          return;
        }
        if (internalTask.status === 'succeeded') {
          onFinished(internalTask);
          subscription.unsubscribe();
        }
      }
    );
  }
}
