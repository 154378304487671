import { AppContextProvider, AuthorizationService } from '@/base/domains';
import {
  AbstractUseCase,
  GetQuestionnaire,
  GetQuestionnaireRequest,
  GetQuestionnaireResponse,
} from '@/base/usecases';

import { QuestionnaireRepository } from '../domains';

export class GetQuestionnaireImpl
  extends AbstractUseCase<GetQuestionnaireRequest, GetQuestionnaireResponse>
  implements GetQuestionnaire
{
  constructor(
    private appContextProvider: AppContextProvider,
    private authorizationService: AuthorizationService,
    private questionnaireRepository: QuestionnaireRepository
  ) {
    super('training.GetQuestionnaire');
  }

  async internalExecute(request: GetQuestionnaireRequest): Promise<GetQuestionnaireResponse> {
    const { id } = request;
    const questionnaire = await this.questionnaireRepository.findById(id);
    const role = this.appContextProvider.get().user?.role;
    if (role !== 'general') return { questionnaire };
    if (questionnaire)
      this.authorizationService.assertTrainerOrMentorInGroup(questionnaire.groupId);
    return { questionnaire };
  }
}
