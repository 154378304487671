import { AuthorizationService, ContentId } from '@/base/domains';
import { EXCLUSIVE_CONTROL_ERROR } from '@/base/ErrorCodes';
import { AbstractUseCase, assertEntityExists, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { EditingConfirmedContentRepository } from '../domains';

export interface ConfirmConfirmedContentEditingRequest {
  id: ContentId;
  versionDescription?: string;
}

export type ConfirmConfirmedContentEditingResponse = {};

export interface ConfirmConfirmedContentEditing
  extends UseCase<ConfirmConfirmedContentEditingRequest, ConfirmConfirmedContentEditingResponse> {
  execute(
    request: ConfirmConfirmedContentEditingRequest
  ): Promise<UseCaseResponse<ConfirmConfirmedContentEditingResponse>>;
}

export class ConfirmConfirmedContentEditingImpl
  extends AbstractUseCase<
    ConfirmConfirmedContentEditingRequest,
    ConfirmConfirmedContentEditingResponse
  >
  implements ConfirmConfirmedContentEditing
{
  constructor(
    private authorizationService: AuthorizationService,
    private editingConfirmedContentRepository: EditingConfirmedContentRepository
  ) {
    super('contents.ConfirmConfirmedContentEditing');
  }

  async internalExecute(
    request: ConfirmConfirmedContentEditingRequest
  ): Promise<ConfirmConfirmedContentEditingResponse> {
    const { id, versionDescription } = request;
    this.authorizationService.assertContentEditable();
    const entity = await this.editingConfirmedContentRepository.findById(id);
    assertEntityExists(entity, 'editingConfirmedContent');
    if (entity.status === 'confirmed') {
      throw EXCLUSIVE_CONTROL_ERROR.toApplicationError({ entity: 'editingConfirmedContent' });
    }
    await this.editingConfirmedContentRepository.save(entity.confirm(versionDescription));
    return {};
  }
}

export const ConfirmConfirmedContentEditingKey = injectionKeyOf<ConfirmConfirmedContentEditing>({
  boundedContext: 'contents',
  type: 'usecase',
  name: 'ConfirmConfirmedContentEditing',
});

export function useConfirmConfirmedContentEditing(): ConfirmConfirmedContentEditing {
  return requiredInject(ConfirmConfirmedContentEditingKey);
}
