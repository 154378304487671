import { Subscription, UserCode } from '@/base/domains';
import { AbstractUseCase, assertEntityExists, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { PlaygroundId, PlaygroundReference, PlaygroundRepository } from '../domains';
import { PLAYGROUND_ALREADY_USED } from '../ErrorCodes';

export type StartPlaygroundCreatingRequest = {
  id: PlaygroundId;
  userCode: UserCode;
  onSucceeded: (playground: PlaygroundReference) => void;
  onFailed: (e: Error) => void;
};

export type StartPlaygroundCreatingResponse = {
  /**
   * 終了時は自動でunsubscribeする。
   * クライアントの都合で購読不要になった場合はunsubscribeすること。
   */
  subscription: Subscription;
};

/**
 * プレイグラウンド作成を開始する
 */
export interface StartPlaygroundCreating
  extends UseCase<StartPlaygroundCreatingRequest, StartPlaygroundCreatingResponse> {
  execute(
    request: StartPlaygroundCreatingRequest
  ): Promise<UseCaseResponse<StartPlaygroundCreatingResponse>>;
}

export class StartPlaygroundCreatingImpl
  extends AbstractUseCase<StartPlaygroundCreatingRequest, StartPlaygroundCreatingResponse>
  implements StartPlaygroundCreating
{
  constructor(private playgroundRepository: PlaygroundRepository) {
    super('playground.StartPlaygroundCreating');
  }

  async internalExecute({
    id,
    userCode,
    onSucceeded,
    onFailed,
  }: StartPlaygroundCreatingRequest): Promise<StartPlaygroundCreatingResponse> {
    try {
      const playground = await this.playgroundRepository.findById(id);
      assertEntityExists(playground, 'playground');
      if (playground.status === 'used') {
        throw PLAYGROUND_ALREADY_USED.toApplicationError({ playgroundId: id });
      }
      if (playground.status === 'created') {
        onSucceeded(playground);
        return {
          subscription: {
            unsubscribe: () => {
              // do nothing
            },
            isClosed: () => true,
          },
        };
      }
      if (playground.status === 'reserved') {
        await this.playgroundRepository.save(playground.startCreating(userCode));
      }

      const subscription = this.playgroundRepository.onCreated({
        id,
        onSucceeded,
        onFailed,
      });
      return {
        subscription,
      };
    } catch (e) {
      if (e instanceof Error) {
        onFailed(e);
      } else {
        onFailed(new Error(`unexpected error type; ${JSON.stringify(e)}`));
      }
      return {
        subscription: {
          unsubscribe: () => {
            // do nothing
          },
          isClosed: () => true,
        },
      };
    }
  }
}

export const StartPlaygroundCreatingKey = injectionKeyOf<StartPlaygroundCreating>({
  boundedContext: 'playground',
  type: 'usecase',
  name: 'StartPlaygroundCreating',
});

export function useStartPlaygroundCreating(): StartPlaygroundCreating {
  return requiredInject(StartPlaygroundCreatingKey);
}
