import { AuthorizationService } from '@/base/domains';
import {
  AbstractUseCase,
  RemoveQuestionnaire,
  RemoveQuestionnaireRequest,
  RemoveQuestionnaireResponse,
} from '@/base/usecases';

import { QuestionnaireRepository } from '../domains';

export class RemoveQuestionnaireImpl
  extends AbstractUseCase<RemoveQuestionnaireRequest, RemoveQuestionnaireResponse>
  implements RemoveQuestionnaire
{
  constructor(
    private authorizationService: AuthorizationService,
    private questionnaireRepository: QuestionnaireRepository
  ) {
    super('training.RemoveQuestionnaire');
  }

  async internalExecute(request: RemoveQuestionnaireRequest): Promise<RemoveQuestionnaireResponse> {
    const { id } = request;
    const questionnaire = await this.questionnaireRepository.findById(id);
    if (!questionnaire) return {};
    this.authorizationService.assertTrainerOrMentorInGroup(questionnaire.groupId);
    await this.questionnaireRepository.remove(id);
    return {};
  }
}
