import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { Optional } from '../types';
import { Email } from './Base';
import { GroupId } from './Group';
import { TenantCode, TenantName } from './Tenant';
import { GroupRole, Role, UserCode, UserName } from './User';

export type SignUpReservationId = string;

export type SignUpReservationStatus = 'not_signed_up' | 'signed_up' | 'confirmed';

export type SignUpInput = {
  name: UserName;
  tenantName?: TenantName;
};

export type SignUpReservationGroup = { id: GroupId; role: GroupRole };

export interface SignUpReservation {
  id: SignUpReservationId;
  name?: UserName;
  userCode?: UserCode;
  email?: Email;
  role: Role;
  status: SignUpReservationStatus;
  enabled: boolean;
  groups: SignUpReservationGroup[];
}

export interface SignUpReservationQueries {
  findByEmail(email: Email): Promise<Optional<SignUpReservation>>;
  findByUserCode(userCode: UserCode, tenantCode: TenantCode): Promise<Optional<SignUpReservation>>;
  findTenantSignUpReservations(): Promise<Array<SignUpReservation>>;
}

export const SignUpReservationQueriesKey = injectionKeyOf<SignUpReservationQueries>({
  boundedContext: 'base',
  type: 'adapter',
  name: 'SignUpReservationQueries',
});

export function useSignUpReservationQueries(): SignUpReservationQueries {
  return requiredInject(SignUpReservationQueriesKey);
}
