/* eslint-disable @typescript-eslint/no-unused-vars */
import { createLogger } from '@/utils/log';
import { isDefined } from '@/utils/TsUtils';

import { AUTHORIZATION_ERROR } from '../ErrorCodes';
import { AppContextProvider } from './AppContextProvider';
import { AuthorizationService } from './AuthorizationService';
import { GroupId } from './Group';
import { Role } from './User';

const logger = createLogger({ boundedContext: 'base', name: 'AuthorizationServiceImpl' });

function authorizationErrorOf(msg: string, options: Record<string, unknown> = {}): Error {
  return AUTHORIZATION_ERROR.toApplicationError({
    ...options,
    message: msg,
  });
}
export class AuthorizationServiceImpl implements AuthorizationService {
  constructor(private appContextProvider: AppContextProvider) {}

  assertContentEditable(): void {
    this.assertRole('supervisor', 'admin');
  }

  assertRole(...executableRoles: Array<Role>): void {
    const appContext = this.appContextProvider.get();
    const role = appContext.user?.role;

    if (!isDefined(role)) {
      throw authorizationErrorOf('not signed in');
    }

    if (!executableRoles.includes(role)) {
      throw authorizationErrorOf('unauthorized role');
    }
  }

  assertGroupWriteAccessible(groupId: GroupId): void {
    const appContext = this.appContextProvider.get();
    const groupRole = appContext.roleInGroup(groupId);
    if (!isDefined(groupRole)) {
      throw authorizationErrorOf('not in this group', { groupId });
    }
  }

  assertGroupReadAccessible(groupId: GroupId): void {
    const appContext = this.appContextProvider.get();
    const role = appContext.user?.role;

    if (!isDefined(role)) {
      throw authorizationErrorOf('not signed in');
    }

    if (role === 'supervisor' || role === 'admin') {
      return;
    }

    const groupRole = appContext.roleInGroup(groupId);
    if (!isDefined(groupRole)) {
      throw authorizationErrorOf('not in this group', { groupId });
    }
  }

  assertTrainerAccessible(groupId: string): void {
    const appContext = this.appContextProvider.get();
    const groupRole = appContext.roleInGroup(groupId);

    if (groupRole !== 'trainer') {
      throw authorizationErrorOf('this user is not a trainer', {
        groupId,
        actualGroupRole: groupRole,
      });
    }
  }

  assertOwnerAccessible(owner: string): void {
    const appContext = this.appContextProvider.get();
    if (!appContext.user) {
      throw authorizationErrorOf('not signed in');
    }
    if (owner !== appContext.user.id) {
      throw authorizationErrorOf('current user is not owner');
    }
  }

  assertGroupReportAccessible(groupId: string): void {
    const appContext = this.appContextProvider.get();
    const role = appContext.user?.role;
    if (!isDefined(role)) {
      throw authorizationErrorOf('not signed in');
    }
    if (role === 'supervisor' || role === 'admin') {
      return;
    }
    const groupRole = appContext.roleInGroup(groupId);
    if (groupRole === 'trainer' || groupRole === 'mentor') {
      return;
    }
    throw authorizationErrorOf(
      'this user is not a supervisor/admin and is not a trainer/mentor in this group',
      {
        groupId,
        actualRole: role,
        actualGroupRole: groupRole,
      }
    );
  }

  assertTrainerOrMentorInGroup(groupId: GroupId): void {
    const appContext = this.appContextProvider.get();
    const groupRole = appContext.roleInGroup(groupId);
    if (!isDefined(groupRole)) {
      throw authorizationErrorOf('not signed in');
    }
    if (groupRole === 'trainer' || groupRole === 'mentor') {
      return;
    }
    throw authorizationErrorOf('this user is not a trainer or a mentor', {
      groupId,
      actualGroupRole: groupRole,
    });
  }

  assertNotPlayground(): void {
    const appContext = this.appContextProvider.get();
    if (appContext.playground) {
      throw authorizationErrorOf('the tenant should not be playground');
    }
  }
}
