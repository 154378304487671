import { Optional } from '@/base/types';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { GroupId, UserId } from '..';
import {
  AppExtension,
  AppExtensionConfig,
  AppExtensionFactory,
  ExtensionName,
  GroupExtension,
  GroupExtensionConfig,
  GroupExtensionFactory,
  UserExtension,
  UserExtensionConfig,
  UserExtensionFactory,
} from './Extension';

export interface InstallRequest<
  A extends AppExtension,
  G extends GroupExtension,
  U extends UserExtension
> {
  name: ExtensionName;
  appExtensionFactory: AppExtensionFactory<A>;
  groupExtensionFactory: GroupExtensionFactory<G>;
  userExtensionFactory: UserExtensionFactory<U>;
}

export type IsActiveRequest = {
  name: ExtensionName;
  groupId: GroupId;
};

export interface ExtensionService {
  /**
   * 拡張をインストールする
   * @param request リクエスト
   */
  install<A extends AppExtension, G extends GroupExtension, U extends UserExtension>(
    request: InstallRequest<A, G, U>
  ): void;

  saveAppConfig(config: AppExtensionConfig): Promise<void>;

  saveGroupConfig(config: GroupExtensionConfig): Promise<void>;

  saveUserConfig(config: UserExtensionConfig): Promise<void>;

  /**
   * インストールされた拡張の名前リスト
   */
  installedExtensionNames(): Array<ExtensionName>;

  /**
   * 有効な拡張の名前リスト
   */
  availableExtensionNames(): Promise<Array<ExtensionName>>;

  /**
   * 拡張が有効か。グループIDを指定した場合はそのグループで有効かを返す。
   * @param request リクエスト
   */
  isAvailable(request: IsActiveRequest): Promise<boolean>;

  /**
   * 拡張のアプリケーション設定を取得する
   * @param name 拡張名
   */
  getAppExtension<C extends AppExtension>(name: ExtensionName): Promise<Optional<C>>;

  /**
   * 拡張のグループ設定を取得する
   * @param name 拡張名
   * @param groupId グループID
   */
  getGroupExtension<C extends GroupExtension>(
    name: ExtensionName,
    groupId: GroupId
  ): Promise<Optional<C>>;

  /**
   * 拡張のユーザー設定を取得する
   * @param name 拡張名
   * @param userId ユーザーID
   */
  getUserExtension<C extends UserExtension>(
    name: ExtensionName,
    userId: UserId
  ): Promise<Optional<C>>;
}

export const ExtensionServiceKey = injectionKeyOf<ExtensionService>({
  boundedContext: 'base',
  type: 'component',
  name: 'ExtensionService',
});

export function useExtensionService() {
  return requiredInject(ExtensionServiceKey);
}
