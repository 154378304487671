import {
  Group as DomainGroup,
  GroupFinder,
  GroupId,
  GroupName,
  GroupRole,
  Role,
  User,
  UserFinder,
  UserId,
  UserName,
} from '@/base/domains';
import { AbstractUseCase, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface GetGroupsByAdminRequest {}

type GroupUser = {
  id: UserId;
  role: GroupRole;
  name: UserName;
};

type Group = {
  readonly id: GroupId;
  readonly name: GroupName;
  readonly users: Array<GroupUser>;
};

export type GetGroupsByAdminResponse = {
  groups: Array<Group>;
};

export interface GetGroupsByAdmin
  extends UseCase<GetGroupsByAdminRequest, GetGroupsByAdminResponse> {
  execute(request: GetGroupsByAdminRequest): Promise<UseCaseResponse<GetGroupsByAdminResponse>>;
}

export class GetGroupsByAdminImpl
  extends AbstractUseCase<GetGroupsByAdminRequest, GetGroupsByAdminResponse>
  implements GetGroupsByAdmin
{
  private groupFinder: GroupFinder;

  private userFinder: UserFinder;

  constructor(groupFinder: GroupFinder, userFinder: UserFinder) {
    super('home.GetGroupsByAdmin');
    this.groupFinder = groupFinder;
    this.userFinder = userFinder;
  }

  executableRole(): Array<Role> {
    return ['supervisor', 'admin'];
  }

  async internalExecute(_: GetGroupsByAdminRequest): Promise<GetGroupsByAdminResponse> {
    const usersPromise = this.userFinder.findTenantUsers();
    const groupsPromise = this.groupFinder.findTenantGroups();
    const results = await Promise.all([usersPromise, groupsPromise]);
    const userNames = new Map((results[0] as Array<User>).map((u) => [u.id, u.name]));

    const groups = (results[1] as Array<DomainGroup>).map((g) => ({
      id: g.id,
      name: g.name,
      users: g.users.map((u) => ({
        id: u.id,
        name: userNames.get(u.id),
        role: u.role,
      })),
      limitations: g.limitations,
    })) as Array<Group>;
    return {
      groups,
    };
  }
}

export const GetGroupsByAdminKey = injectionKeyOf<GetGroupsByAdmin>({
  boundedContext: 'home',
  type: 'usecase',
  name: 'GetGroupsByAdmin',
});

export function useGetGroupsByAdmin(): GetGroupsByAdmin {
  return requiredInject(GetGroupsByAdminKey);
}
