import { AuthorizationService, ContentId, DataVersion, ProblemHeaderId } from '@/base/domains';
import {
  AbstractUseCase,
  assertDataVersion,
  assertEntityExists,
  UseCase,
  UseCaseResponse,
} from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { EditingCourseContentBodyReference, EditingCourseContentBodyRepository } from '../domains';
import { CONTENT_TYPE_SHOULD_BE_EXAM } from '../ErrorCodes';

export type RemoveProblemHeaderFromEditingCourseContentRequest = {
  id: ContentId;
  problemHeaderId: ProblemHeaderId;
  expectedDataVersion: DataVersion;
};

export type RemoveProblemHeaderFromEditingCourseContentResponse = {
  content: EditingCourseContentBodyReference;
};

/**
 * 編集中コンテンツの問題ヘッダーを削除する
 */
export interface RemoveProblemHeaderFromEditingCourseContent
  extends UseCase<
    RemoveProblemHeaderFromEditingCourseContentRequest,
    RemoveProblemHeaderFromEditingCourseContentResponse
  > {
  execute(
    request: RemoveProblemHeaderFromEditingCourseContentRequest
  ): Promise<UseCaseResponse<RemoveProblemHeaderFromEditingCourseContentResponse>>;
}

export class RemoveProblemHeaderFromEditingCourseContentImpl
  extends AbstractUseCase<
    RemoveProblemHeaderFromEditingCourseContentRequest,
    RemoveProblemHeaderFromEditingCourseContentResponse
  >
  implements RemoveProblemHeaderFromEditingCourseContent
{
  private authorizationService: AuthorizationService;

  private editingCourseContentBodyRepository: EditingCourseContentBodyRepository;

  constructor(
    authorizationService: AuthorizationService,
    editingCourseContentBodyRepository: EditingCourseContentBodyRepository
  ) {
    super('contents.RemoveProblemHeaderFromEditingCourseContent');
    this.authorizationService = authorizationService;
    this.editingCourseContentBodyRepository = editingCourseContentBodyRepository;
  }

  async internalExecute(
    request: RemoveProblemHeaderFromEditingCourseContentRequest
  ): Promise<RemoveProblemHeaderFromEditingCourseContentResponse> {
    const { id, problemHeaderId, expectedDataVersion } = request;
    this.authorizationService.assertContentEditable();
    const content = await this.editingCourseContentBodyRepository.findById(id);
    assertEntityExists(content, 'editingCourseContentBody');
    assertDataVersion(content, expectedDataVersion, 'editingCourseContentBody');
    if (content.type !== 'exam') {
      throw CONTENT_TYPE_SHOULD_BE_EXAM.toApplicationError({
        payload: {
          id,
        },
      });
    }
    const saved = await this.editingCourseContentBodyRepository.save(
      content.removeProblemHeader(problemHeaderId)
    );
    return {
      content: saved,
    };
  }
}

export const RemoveProblemHeaderFromEditingCourseContentKey =
  injectionKeyOf<RemoveProblemHeaderFromEditingCourseContent>({
    boundedContext: 'contents',
    type: 'usecase',
    name: 'RemoveProblemHeaderFromEditingCourseContent',
  });

export function useRemoveProblemHeaderFromEditingCourseContent(): RemoveProblemHeaderFromEditingCourseContent {
  return requiredInject(RemoveProblemHeaderFromEditingCourseContentKey);
}
