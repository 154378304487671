import { AppContextProvider } from '@/base/domains';
import {
  AbstractUseCase,
  AnswerQuestionnaire,
  AnswerQuestionnaireRequest,
  AnswerQuestionnaireResponse,
} from '@/base/usecases';
import { assertIsDefined } from '@/utils/Asserts';

import { QuestionnaireRepository } from '../domains';

export class AnswerQuestionnaireImpl
  extends AbstractUseCase<AnswerQuestionnaireRequest, AnswerQuestionnaireResponse>
  implements AnswerQuestionnaire
{
  constructor(
    private appContextProvider: AppContextProvider,
    private questionnaireRepository: QuestionnaireRepository
  ) {
    super('training.AnswerQuestionnaire');
  }

  async internalExecute(request: AnswerQuestionnaireRequest): Promise<AnswerQuestionnaireResponse> {
    const { questionnaireId, selectedIndex } = request;
    const userId = this.appContextProvider.get().user?.id;
    assertIsDefined(userId, 'appContext.user');
    const questionnaireRespondent = await this.questionnaireRepository.answerQuestionnaire(
      questionnaireId,
      userId,
      selectedIndex
    );
    return { questionnaireRespondent };
  }
}
