import { GroupId, ScheduleColor, ScheduleId } from '@/base/domains';

import { ScheduleTagEntity } from './ScheduleTag';

export type ScheduleTagEntityImplArgs = {
  id: ScheduleId;

  text: string;

  color: ScheduleColor;

  groupId: GroupId;
};

export class ScheduleTagEntityImpl implements ScheduleTagEntity {
  id: ScheduleId;

  text: string;

  color: ScheduleColor;

  groupId: GroupId;

  constructor(args: ScheduleTagEntityImplArgs) {
    this.id = args.id;
    this.text = args.text;
    this.color = args.color;
    this.groupId = args.groupId;
  }

  change({ text, color }: { text: string; color: ScheduleColor }): ScheduleTagEntity {
    return new ScheduleTagEntityImpl({
      ...this,
      text,
      color,
    });
  }
}
