import { AuthorizationService, ContentId, DataVersion, Problem } from '@/base/domains';
import {
  AbstractUseCase,
  assertDataVersion,
  assertEntityExists,
  UseCase,
  UseCaseResponse,
} from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { EditingConfirmedContentReference, EditingConfirmedContentRepository } from '../domains';
import { CONTENT_TYPE_SHOULD_BE_EXAM } from '../ErrorCodes';

export type ChangeEditingConfirmedExamProblemsRequest = {
  id: ContentId;
  problems: Array<Problem>;
  expectedDataVersion: DataVersion;
};

export type ChangeEditingConfirmedExamProblemsResponse = {
  editingConfirmedContent: EditingConfirmedContentReference;
};

/**
 * 確定済みテストコンテンツの問題を変更する
 */
export interface ChangeEditingConfirmedExamProblems
  extends UseCase<
    ChangeEditingConfirmedExamProblemsRequest,
    ChangeEditingConfirmedExamProblemsResponse
  > {
  execute(
    request: ChangeEditingConfirmedExamProblemsRequest
  ): Promise<UseCaseResponse<ChangeEditingConfirmedExamProblemsResponse>>;
}

export class ChangeEditingConfirmedExamProblemsImpl
  extends AbstractUseCase<
    ChangeEditingConfirmedExamProblemsRequest,
    ChangeEditingConfirmedExamProblemsResponse
  >
  implements ChangeEditingConfirmedExamProblems
{
  constructor(
    private authorizationService: AuthorizationService,
    private editingConfirmedContentRepository: EditingConfirmedContentRepository
  ) {
    super('contents.ChangeEditingConfirmedExamProblems');
  }

  async internalExecute(
    request: ChangeEditingConfirmedExamProblemsRequest
  ): Promise<ChangeEditingConfirmedExamProblemsResponse> {
    this.authorizationService.assertContentEditable();
    const editingConfirmedContent = await this.editingConfirmedContentRepository.findById(
      request.id
    );
    assertEntityExists(editingConfirmedContent, 'editingConfirmedContent');
    assertDataVersion(
      editingConfirmedContent,
      request.expectedDataVersion,
      'editingConfirmedContent'
    );
    if (editingConfirmedContent.type !== 'exam') {
      throw CONTENT_TYPE_SHOULD_BE_EXAM.toApplicationError({
        id: editingConfirmedContent.id,
      });
    }
    const saved = await this.editingConfirmedContentRepository.save(
      editingConfirmedContent.changeProblems(request.problems)
    );
    return {
      editingConfirmedContent: saved,
    };
  }
}

export const ChangeEditingConfirmedExamProblemsKey =
  injectionKeyOf<ChangeEditingConfirmedExamProblems>({
    boundedContext: 'contents',
    type: 'usecase',
    name: 'ChangeEditingConfirmedExamProblems',
  });

export function useChangeEditingConfirmedExamProblems(): ChangeEditingConfirmedExamProblems {
  return requiredInject(ChangeEditingConfirmedExamProblemsKey);
}
