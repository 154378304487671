import { AuthorizationService, ScheduleColor, ScheduleTagId } from '@/base/domains';
import { AbstractUseCase, assertEntityExists, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { ScheduleTagReference, ScheduleTagRepository } from '../domains';

export interface UpdateScheduleTagRequest {
  id: ScheduleTagId;
  text: string;
  color: ScheduleColor;
}

export type UpdateScheduleTagResponse = {
  scheduleTag: ScheduleTagReference;
};

/**
 * スケジュールタグを更新する
 */
export interface UpdateScheduleTag
  extends UseCase<UpdateScheduleTagRequest, UpdateScheduleTagResponse> {
  execute(request: UpdateScheduleTagRequest): Promise<UseCaseResponse<UpdateScheduleTagResponse>>;
}

export class UpdateScheduleTagImpl
  extends AbstractUseCase<UpdateScheduleTagRequest, UpdateScheduleTagResponse>
  implements UpdateScheduleTag
{
  private authorizationRepository: AuthorizationService;

  private scheduleTagRepository: ScheduleTagRepository;

  constructor(
    authorizationRepository: AuthorizationService,
    scheduleTagRepository: ScheduleTagRepository
  ) {
    super('training.UpdateScheduleTag');
    this.authorizationRepository = authorizationRepository;
    this.scheduleTagRepository = scheduleTagRepository;
  }

  async internalExecute(request: UpdateScheduleTagRequest): Promise<UpdateScheduleTagResponse> {
    const { id, text, color } = request;
    const scheduleTag = await this.scheduleTagRepository.findById(id);
    assertEntityExists(scheduleTag, 'scheduleTag');
    this.authorizationRepository.assertGroupWriteAccessible(scheduleTag.groupId);
    const saved = await this.scheduleTagRepository.save(scheduleTag.change({ text, color }));
    return {
      scheduleTag: saved,
    };
  }
}

export const UpdateScheduleTagKey = injectionKeyOf<UpdateScheduleTag>({
  boundedContext: 'training',
  type: 'usecase',
  name: 'UpdateScheduleTag',
});

export function useUpdateScheduleTag(): UpdateScheduleTag {
  return requiredInject(UpdateScheduleTagKey);
}
