import { UserId } from './Core';
import { GroupId, GroupLimitation, GroupName } from './Group';
import { createEventDefinition } from './LocalEvent';
import { GroupRole } from './User';

// account
export const usersDisabled = createEventDefinition<{ userIds: Array<UserId> }>()('USERS_DISABLED');

// admin
export const groupUserAdded = createEventDefinition<{
  groupId: GroupId;
  users: Array<{ role: GroupRole; id: UserId }>;
}>()('GROUP_USER_ADDED');
export const groupNameChanged =
  createEventDefinition<{ groupId: GroupId; groupName: GroupName }>()('GROUP_NAME_CHANGED');
export const groupLimitationsChanged = createEventDefinition<{
  groupId: GroupId;
  limitations: GroupLimitation;
}>()('GROUP_LIMITATIONS_CHANGED');
export const groupDescriptionChanged = createEventDefinition<{
  groupId: GroupId;
  description: string;
}>()('GROUP_DESCRIPTION_CHANGED');
export const groupDisabled = createEventDefinition<{ groupId: GroupId }>()('GROUP_DISABLED');
export const groupEnabled = createEventDefinition<{ groupId: GroupId }>()('GROUP_ENABLED');
export const groupCreated = createEventDefinition<{ groupId: GroupId }>()('GROUP_CREATED');
export const groupRemoved = createEventDefinition<{ groupId: GroupId }>()('GROUP_REMOVED');
export const groupUserRemoved = createEventDefinition<{
  groupId: GroupId;
  userIds: Array<UserId>;
}>()('GROUP_USER_REMOVED');
export const groupExtensionConfigSaved = createEventDefinition<{
  groupId: GroupId;
}>()('GROUP_EXTENSION_CONFIG_SAVED');

// base
export const userAvatarChanged = createEventDefinition<{ userId: UserId }>()('USER_AVATAR_CHANGED');
export const userDisplaySettingsChanged = createEventDefinition<{ userId: UserId }>()(
  'USER_DISPLAY_SETTINGS_CHANGED'
);
export const userLocaleChanged = createEventDefinition<{ userId: UserId }>()('USER_LOCALE_CHANGED');
export const userNameChanged = createEventDefinition<{ userId: UserId }>()('USER_NAME_CHANGED');
export const userPasswordChangedByAdmin = createEventDefinition<{ userId: UserId }>()(
  'USER_PASSWORD_CHANGED_BY_ADMIN'
);
export const userRoleChangedByAdmin = createEventDefinition<{ userId: UserId }>()(
  'USER_ROLE_CHANGED_BY_ADMIN'
);
export const amplifyQueryNetworkErrorOccurred = createEventDefinition<{
  queryType: 'mutate' | 'query';
  query: string;
}>()('AMPLIFY_QUERY_NETWORK_ERROR_OCCURRED');
export const amplifyQuerySucceeded =
  createEventDefinition<{ queryType: 'mutate' | 'query'; query: string }>()(
    'AMPLIFY_QUERY_SUCCEEDED'
  );
export const showErrorToast = createEventDefinition<{ messages: string[] }>()('SHOW_ERROR_TOAST');

// home
export const termsOfServiceVersionsConfirmed = createEventDefinition<{ userId: UserId }>()(
  'TERMS_OF_SERVICE_VERSIONS_CONFIRMED'
);
