import { Group, GroupId, GroupRepository, GroupRole, localEventBus, UserId } from '@/base/domains';
import { groupUserAdded } from '@/base/domains/LocalEvents';
import { AbstractUseCase, assertEntityExists, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

export interface AddGroupUserRequest {
  id: GroupId;
  role: GroupRole;
  userIds: Array<UserId>;
}

export type AddGroupUserResponse = {
  group: Group;
};

/**
 * ユーザー追加
 */
export interface AddGroupUser extends UseCase<AddGroupUserRequest, AddGroupUserResponse> {
  execute(request: AddGroupUserRequest): Promise<UseCaseResponse<AddGroupUserResponse>>;
}

export class AddGroupUserImpl
  extends AbstractUseCase<AddGroupUserRequest, AddGroupUserResponse>
  implements AddGroupUser
{
  constructor(private groupRepository: GroupRepository) {
    super('admin.AddGroupUser');
  }

  async internalExecute(request: AddGroupUserRequest): Promise<AddGroupUserResponse> {
    const { id, role, userIds } = request;
    const group = await this.groupRepository.findById(id, { includeRemovedGroupUser: true });
    assertEntityExists(group, 'group');
    const users = userIds.map((userId) => ({
      id: userId,
      role,
      removed: false,
    }));
    const saved = await this.groupRepository.save(group.addUsers(users));

    localEventBus.publish(groupUserAdded({ groupId: id, users }));

    return {
      group: saved,
    };
  }
}

export const AddGroupUserKey = injectionKeyOf<AddGroupUser>({
  boundedContext: 'admin',
  type: 'usecase',
  name: 'AddGroupUser',
});

export function useAddGroupUser(): AddGroupUser {
  return requiredInject(AddGroupUserKey);
}
