import {
  ContentBodyInput,
  ContentType as AmplifyContentType,
  ProblemInput,
  ProblemType as AmplifyProblemType,
  WorkbookInput,
} from '@/API';
import {
  ChoiceProblem,
  ContentBody,
  Exam,
  isExam,
  isText,
  Problem,
  ProblemHeader,
  Text,
  Workbook,
} from '@/base/domains';
import { Optional } from '@/base/types';
import { WorkbookData, WorkbookImpl } from '@/contents/domains';
import {
  ContentBody as AmplifyContentBody,
  Problem as AmplifyProblem,
  ProblemHeader as AmplifyProblemHeader,
  Workbook as AmplifyWorkbook,
} from '@/models';
import { assertIsDefined } from '@/utils/Asserts';
import { localDateTimeFromString } from '@/utils/DateUtils';

export { AmplifyContentBody, AmplifyProblem, AmplifyWorkbook };

export function toAmplifyProblem(problem: Problem): ProblemInput {
  if (problem.type === 'choice') {
    return {
      index: problem.index,
      type: AmplifyProblemType.CHOICE,
      body: problem.body,
      commentary: problem.commentary,
      choice: {
        multiple: problem.multiple,
        answer: problem.answer,
        options: problem.options,
      },
      headerId: problem.headerId,
    };
  }
  throw new Error(`unsupported problem type; ${problem.type}`);
}

export function toAmplifyContentBody(body: ContentBody): ContentBodyInput {
  if (isText(body)) {
    return {
      type: AmplifyContentType.TEXT,
      text: {
        body: body.body,
      },
    };
  }
  if (isExam(body)) {
    return {
      type: AmplifyContentType.EXAM,
      exam: {
        problems: body.problems.map((p) => toAmplifyProblem(p)),
        problemHeaders: body.problemHeaders.map((h) => ({
          id: h.id,
          body: h.body,
          createdAt: h.createdAt.toISOString(),
        })),
        passingStandard: body.passingStandard,
      },
    };
  }
  throw new Error(`unsupported content body`);
}

export function toAmplifyWorkbook(workbook?: WorkbookData): Optional<WorkbookInput> {
  if (workbook === undefined) {
    return undefined;
  }
  return {
    problems: workbook.problems.map(toAmplifyProblem),
    problemHeaders: workbook.problemHeaders.map((h) => ({
      id: h.id,
      body: h.body,
      createdAt: h.createdAt.toISOString(),
    })),
  };
}

export function toProblem(problem: AmplifyProblem): Problem {
  if (problem.type === AmplifyProblemType.CHOICE) {
    const c = problem.choice;
    assertIsDefined(c, 'problem.choice');
    const choice: ChoiceProblem = {
      type: 'choice',
      index: problem.index,
      body: problem.body,
      commentary: problem.commentary ?? undefined,
      multiple: c.multiple,
      options: c.options,
      answer: c.answer,
      headerId: problem.headerId ?? undefined,
    };
    return choice;
  }
  throw new Error(`unsupported problem type; ${problem.type}`);
}

export function toProblemHeader(problemHeader: AmplifyProblemHeader): ProblemHeader {
  return {
    id: problemHeader.id,
    body: problemHeader.body,
    createdAt: localDateTimeFromString(problemHeader.createdAt),
  };
}

export function toExamContentBody(contentBody: AmplifyContentBody): Exam {
  if (contentBody.type === AmplifyContentType.EXAM) {
    const e = contentBody.exam;
    assertIsDefined(e, 'contentBody.exam');
    return {
      problems: (e.problems ?? []).map((p) => toProblem(p)),
      problemHeaders: (e.problemHeaders ?? []).map(toProblemHeader),
      passingStandard: e.passingStandard ?? undefined,
    };
  }
  throw new Error(`unsupported content type; ${contentBody.type}`);
}

export function toTextContentBody(contentBody: AmplifyContentBody): Text {
  if (contentBody.type === AmplifyContentType.TEXT) {
    const t = contentBody.text;
    assertIsDefined(t, 'contentBody.text');
    const text: Text = {
      body: t.body,
    };
    return text;
  }
  throw new Error(`unsupported content type; ${contentBody.type}`);
}

export function toWorkbook(workbook?: AmplifyWorkbook): Optional<Workbook> {
  if (!workbook) {
    return undefined;
  }
  const problems = workbook.problems.map(toProblem);
  const problemHeaders = (workbook.problemHeaders ?? []).map((h) => ({
    id: h.id,
    body: h.body,
    createdAt: localDateTimeFromString(h.createdAt),
  }));
  return new WorkbookImpl({ problems, problemHeaders });
}
