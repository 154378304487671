import { RouteConfig } from 'vue-router';

const AboutThisPage = import(/* webpackChunkName: "base" */ './pages/AboutThisPage.vue');
const NotFoundPage = import(/* webpackChunkName: "base" */ './pages/NotFoundPage.vue');
const RedirectPage = import(/* webpackChunkName: "base" */ './pages/RedirectPage.vue');
const TermsOfServicePage = import(/* webpackChunkName: "base" */ './pages/TermsOfServicePage.vue');
const TenantOwnTermsOfService = import(
  /* webpackChunkName: "base" */ './pages/TenantOwnTermsOfServicePage.vue'
);

export const baseRoutes: Array<RouteConfig> = [
  {
    path: '/about-this',
    name: 'aboutThis',
    component: () => AboutThisPage,
  },
  {
    path: '/not-found',
    name: 'notFound',
    component: () => NotFoundPage,
  },
  {
    path: '/redirect',
    name: 'redirect',
    component: () => RedirectPage,
  },
  {
    path: '/terms-of-service',
    name: 'termsOfService',
    component: () => TermsOfServicePage,
  },
  {
    path: '/tenant-own-terms-of-service',
    name: 'tenantOwnTermsOfService',
    component: () => TenantOwnTermsOfService,
  },
];
