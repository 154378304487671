import {
  ChoiceProblem,
  ContentBody,
  ContentId,
  ContentName,
  ContentVersion,
  CourseId,
  CourseVersion,
  DataVersion,
  Exam,
  ProblemHeaderId,
} from '@/base/domains';
import { EXCLUSIVE_CONTROL_ERROR } from '@/base/ErrorCodes';
import { LocalDateTime, MarkDownString, Minute } from '@/base/types';
import { uuid } from '@/utils/UniqueIdGenerator';

import {
  CORSE_CONTENT_EMPTY_PROBLEM_EXAM,
  CORSE_CONTENT_INVALID_PASSING_STANDARD,
} from '../ErrorCodes';
import {
  EditingConfirmedContentEntity,
  ExamEditingConfirmedContentEntity,
} from './EditingConfirmedContent';
import { EditingStatus } from './EditingCourse';

export type ExamEditingConfirmedContentEntityArgs = {
  id: ContentId;
  /** 名前 */
  name: ContentName;
  /** 所要時間 */
  requiredTime: Minute;
  /** コースID */
  courseId: CourseId;
  /** コースバージョン */
  courseVersion: CourseVersion;
  /** コンテンツタイプ */
  type: 'exam';
  /** コンテンツの本体 */
  body: Exam;
  /** バージョン */
  version: ContentVersion;
  /** データバージョン */
  dataVersion: DataVersion;
  /** ステータス */
  status: EditingStatus;
  /** バージョン説明 */
  versionDescription?: string;
};

/**
 * テストコンテンツエンティティの実装
 */
export class ExamEditingConfirmedContentEntityImpl implements ExamEditingConfirmedContentEntity {
  id: ContentId;

  type!: 'exam';

  name: ContentName;

  requiredTime: Minute;

  body: Exam;

  courseId: CourseId;

  courseVersion: CourseVersion;

  version: ContentVersion;

  dataVersion: DataVersion;

  status: EditingStatus;

  versionDescription?: string;

  constructor(args: ExamEditingConfirmedContentEntityArgs) {
    this.id = args.id;
    this.type = args.type;
    this.body = args.body;
    this.name = args.name;
    this.requiredTime = args.requiredTime;
    this.courseId = args.courseId;
    this.courseVersion = args.courseVersion;
    this.version = args.version;
    this.dataVersion = args.dataVersion;
    this.status = args.status;
    this.versionDescription = args.versionDescription;
  }

  private checkConfirmed(): void {
    if (this.status === 'confirmed') {
      throw EXCLUSIVE_CONTROL_ERROR.toApplicationError({
        entity: 'editingConfirmedContent',
      });
    }
  }

  changeBody(body: ContentBody): EditingConfirmedContentEntity {
    this.checkConfirmed();
    if (this.status === 'confirmed') {
      throw EXCLUSIVE_CONTROL_ERROR.toApplicationError({
        entity: 'editingConfirmedContent',
      });
    }
    return new ExamEditingConfirmedContentEntityImpl({
      ...this,
      body,
    });
  }

  confirm(versionDescription?: string): EditingConfirmedContentEntity {
    this.checkConfirmed();
    if (this.body.problems.length === 0) {
      throw CORSE_CONTENT_EMPTY_PROBLEM_EXAM.toApplicationError({
        courseId: this.courseId,
        contents: [
          {
            id: this.id,
            name: this.name,
          },
        ],
      });
    }
    if (this.body.passingStandard && this.body.problems.length < this.body.passingStandard) {
      throw CORSE_CONTENT_INVALID_PASSING_STANDARD.toApplicationError({
        courseId: this.courseId,
        contents: [
          {
            id: this.id,
            name: this.name,
          },
        ],
      });
    }
    return new ExamEditingConfirmedContentEntityImpl({
      ...this,
      status: 'confirmed',
      versionDescription,
    });
  }

  changeProblemHeader({
    problemHeaderId,
    problemHeaderBody,
  }: {
    problemHeaderId: ProblemHeaderId;
    problemHeaderBody: MarkDownString;
  }): EditingConfirmedContentEntity {
    this.checkConfirmed();
    const problemHeader = this.body.problemHeaders.find((ph) => ph.id === problemHeaderId);
    if (!problemHeader) {
      throw new Error(
        `editingConfirmedContent.body.problemHeader(id=${problemHeaderId}) should be exist`
      );
    }
    return new ExamEditingConfirmedContentEntityImpl({
      ...this,
      body: {
        problems: this.body.problems,
        problemHeaders: this.body.problemHeaders.map((ph) =>
          ph.id === problemHeader.id
            ? {
                ...problemHeader,
                body: problemHeaderBody,
              }
            : problemHeader
        ),
      },
    });
  }

  addProblemHeader({
    problemHeaderBody,
    createdAt,
  }: {
    problemHeaderBody: MarkDownString;
    createdAt: LocalDateTime;
  }): EditingConfirmedContentEntity {
    return new ExamEditingConfirmedContentEntityImpl({
      ...this,
      body: {
        ...this.body,
        problemHeaders: [
          ...this.body.problemHeaders,
          {
            id: uuid(),
            body: problemHeaderBody,
            createdAt,
          },
        ],
      },
    });
  }

  removeProblemHeader(problemHeaderId: ProblemHeaderId): ExamEditingConfirmedContentEntityImpl {
    this.checkConfirmed();
    return new ExamEditingConfirmedContentEntityImpl({
      ...this,
      body: {
        ...this.body,
        problemHeaders: this.body.problemHeaders.filter((ph) => ph.id !== problemHeaderId),
      },
    });
  }

  changePassingStandard(passingStandard?: number): EditingConfirmedContentEntity {
    this.checkConfirmed();
    return new ExamEditingConfirmedContentEntityImpl({
      ...this,
      body: {
        ...this.body,
        passingStandard,
      },
    });
  }

  changeProblems(problems: ChoiceProblem[]): EditingConfirmedContentEntity {
    this.checkConfirmed();
    return new ExamEditingConfirmedContentEntityImpl({
      ...this,
      body: {
        ...this.body,
        problems,
      },
    });
  }
}
