import { ContentCode, CourseId } from '@/base/domains';
import { AbstractUseCase, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { CourseReference, CourseRepository } from '../domains';

export interface RemoveCourseContentRequest {
  id: CourseId;
  contentCode: ContentCode;
}

export type RemoveCourseContentResponse = {
  course: CourseReference;
};

/**
 * コンテンツ削除
 * @deprecated
 */
export interface RemoveCourseContent
  extends UseCase<RemoveCourseContentRequest, RemoveCourseContentResponse> {
  execute(
    request: RemoveCourseContentRequest
  ): Promise<UseCaseResponse<RemoveCourseContentResponse>>;
}

export class RemoveCourseContentImpl
  extends AbstractUseCase<RemoveCourseContentRequest, RemoveCourseContentResponse>
  implements RemoveCourseContent
{
  private courseRepository: CourseRepository;

  constructor(courseRepository: CourseRepository) {
    super('contents.RemoveCourseContent');
    this.courseRepository = courseRepository;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async internalExecute(request: RemoveCourseContentRequest): Promise<RemoveCourseContentResponse> {
    throw new Error('deprecated; remove soon');
  }
}

export const RemoveCourseContentKey = injectionKeyOf<RemoveCourseContent>({
  boundedContext: 'contents',
  type: 'usecase',
  name: 'RemoveCourseContent',
});

export function useRemoveCourseContent(): RemoveCourseContent {
  return requiredInject(RemoveCourseContentKey);
}
