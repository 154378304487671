import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { LocalDateTime, MarkDownString, Optional } from '../types';
import { UserId } from './Core';
import { TenantCode } from './Tenant';

export type TenantOwnTermsOfService = {
  readonly tenantCode: TenantCode;

  readonly body: MarkDownString;

  readonly version: number;

  readonly createdBy: UserId;

  readonly createdAt: LocalDateTime;
};

export interface TenantOwnTermsOfServiceFinder {
  findTenantOwnTermsOfService(): Promise<Optional<TenantOwnTermsOfService>>;
}

export const TenantOwnTermsOfServiceFinderKey = injectionKeyOf<TenantOwnTermsOfServiceFinder>({
  boundedContext: 'base',
  type: 'service',
  name: 'TenantOwnTermsOfServiceFinder',
});

export function useTenantOwnTermsOfServiceFinder(): TenantOwnTermsOfServiceFinder {
  return requiredInject(TenantOwnTermsOfServiceFinderKey);
}
