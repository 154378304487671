import {
  ContentId,
  CourseId,
  DataVersion,
  Entity,
  EntityReference,
  Exam,
  ProblemHeaderId,
  Repository,
  UserId,
} from '@/base/domains';
import { Text } from '@/base/domains/content/Text';
import { LocalDateTime, MarkDownString } from '@/base/types';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

type ExamEditingCourseContentBodyAttributes = {
  type: 'exam';
  body: Exam;
  courseId: CourseId;
  dataVersion: DataVersion;
  createdBy: UserId;
  updatedBy: UserId;
};

type TextEditingCourseContentBodyAttributes = {
  type: 'text';
  body: Text;
  courseId: CourseId;
  dataVersion: DataVersion;
  createdBy: UserId;
  updatedBy: UserId;
};

/**
 * 編集中コースコンテンツボディ属性
 */
export type EditingCourseContentBodyAttributes =
  | ExamEditingCourseContentBodyAttributes
  | TextEditingCourseContentBodyAttributes;

type EditingCourseContentBodyData0f<T> =
  | ({ id: ContentId } & T)
  | ({ id: ContentId } & Omit<T, 'createdBy' | 'updatedBy' | 'dataVersion'>);

/**
 * 編集中コースコンテンツボディデータ
 */
export type EditingCourseContentBodyData =
  | EditingCourseContentBodyData0f<ExamEditingCourseContentBodyAttributes>
  | EditingCourseContentBodyData0f<TextEditingCourseContentBodyAttributes>;

export type EditingCourseContentBodyChangeBodyArgs =
  | Text
  | Omit<Exam, 'headers' | 'passingStandard'>;

/**
 * 編集中コースコンテンツボディコマンド
 */
export interface EditingCourseContentBodyCommand {
  changeBody(body: EditingCourseContentBodyChangeBodyArgs): EditingCourseContentBodyEntity;
}

/**
 * 編集中コースコンテンツボディクエリ
 */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface EditingCourseContentBodyQueries {}

/**
 * テスト編集中コースコンテンツボディリファレンス
 */
export type ExamEditingCourseContentBodyReference = EntityReference<
  ContentId,
  ExamEditingCourseContentBodyAttributes,
  EditingCourseContentBodyQueries
>;

/**
 * テキスト編集中コースコンテンツボディリファレンス
 */
export type TextEditingCourseContentBodyReference = EntityReference<
  ContentId,
  TextEditingCourseContentBodyAttributes,
  EditingCourseContentBodyQueries
>;

/**
 * 編集中コースコンテンツボディリファレンス
 */
export type EditingCourseContentBodyReference =
  | ExamEditingCourseContentBodyReference
  | TextEditingCourseContentBodyReference;

export type ExamEditingCourseContentBodyEntity = Entity<
  ContentId,
  ExamEditingCourseContentBodyAttributes
> &
  EditingCourseContentBodyQueries &
  EditingCourseContentBodyCommand & {
    addProblemHeader({
      body,
      createdAt,
    }: {
      body: MarkDownString;
      createdAt: LocalDateTime;
    }): EditingCourseContentBodyEntity;
    removeProblemHeader(id: ProblemHeaderId): EditingCourseContentBodyEntity;
    changeProblemHeader({
      id,
      body,
    }: {
      id: ProblemHeaderId;
      body: MarkDownString;
    }): EditingCourseContentBodyEntity;
    changePassingStandard(passingStandard?: number): EditingCourseContentBodyEntity;
  };

export type TextEditingCourseContentBodyEntity = Entity<
  ContentId,
  TextEditingCourseContentBodyAttributes
> &
  EditingCourseContentBodyQueries &
  EditingCourseContentBodyCommand;

/**
 * 編集中コースコンテンツボディエンティティ
 */
export type EditingCourseContentBodyEntity =
  | ExamEditingCourseContentBodyEntity
  | TextEditingCourseContentBodyEntity;

/**
 * 編集中コースコンテンツボディリポジトリ
 */
export type EditingCourseContentBodyRepository = Repository<
  ContentId,
  EditingCourseContentBodyEntity,
  EditingCourseContentBodyData
>;

export const EditingCourseContentBodyRepositoryKey =
  injectionKeyOf<EditingCourseContentBodyRepository>({
    boundedContext: 'contents',
    type: 'adapter',
    name: 'EditingCourseContentBodyRepositoryKey',
  });

export function useEditingCourseContentBodyRepository(): EditingCourseContentBodyRepository {
  return requiredInject(EditingCourseContentBodyRepositoryKey);
}
