import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { Group, GroupFinder } from '../domains';
import { AbstractUseCase, UseCase, UseCaseResponse } from './UseCase';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface GetGroupsRequest {}

export type GetGroupsResponse = {
  groups: Array<Group>;
};

/**
 * グループリストを取得する
 */
export interface GetGroups extends UseCase<GetGroupsRequest, GetGroupsResponse> {
  execute(request: GetGroupsRequest): Promise<UseCaseResponse<GetGroupsResponse>>;
}

export class GetGroupsImpl
  extends AbstractUseCase<GetGroupsRequest, GetGroupsResponse>
  implements GetGroups
{
  private groupFinder: GroupFinder;

  constructor(groupFinder: GroupFinder) {
    super('base.GetGroups');
    this.groupFinder = groupFinder;
  }

  async internalExecute(_: GetGroupsRequest): Promise<GetGroupsResponse> {
    const groups = await this.groupFinder.findTenantGroups({ includeDisabledGroup: true });
    return {
      groups,
    };
  }
}

export const GetGroupsKey = injectionKeyOf<GetGroups>({
  boundedContext: 'base',
  type: 'usecase',
  name: 'GetGroups',
});

export function useGetGroups(): GetGroups {
  return requiredInject(GetGroupsKey);
}
