import * as customMutations from '@/amplify/customMutations';
import * as customQueries from '@/amplify/customQueries';
import {
  CourseFontColorOnImage as AmplifyCourseFontColorOnImage,
  CreateGroupExamInput,
  ExamResultVisibilityLevel as AmplifyExamResultVisibilityLevel,
  UpdateGroupExamInput,
} from '@/API';
import {
  AppContextProvider,
  ContentId,
  ContentName,
  ContentVersion,
  CourseColor,
  CourseFontColorOnImage,
  CourseId,
  CourseName,
  CourseVersion,
  createGroupExamId,
  EntityData,
  ExamId,
  ExamResultVisibilityLevel,
  GroupExamId,
  GroupExamProblem,
  GroupExamStatus,
  GroupId,
  hasId,
  ProblemHeaderId,
  TenantCode,
  UserExamStatus,
  UserId,
} from '@/base/domains';
import { MarkDownString, Minute, Optional, URI } from '@/base/types';
import {
  GroupExamAttributes,
  GroupExamEntity,
  GroupExamEntityImpl,
  GroupExamRepository,
  GroupTrainingCourseId,
} from '@/training/domains';
import { graphql } from '@/utils/AmplifyUtils';
import { localDateTimeFromString, localDateTimeNow } from '@/utils/DateUtils';
import { ifDefined, isDefined, requiredNonNull } from '@/utils/TsUtils';

type AmplifyUserExam = {
  id: ExamId;
  userId: UserId;
  status: UserExamStatus;
};

type AmplifyGroupExamContent = {
  id: ContentId;
  version: ContentVersion;
  name: ContentName;
  requiredTime?: Minute;
  indexInCourse: number;
  passingStandard?: number;
};

type AmplifyGroupExamCourse = {
  id: CourseId;
  name: CourseName;
  version: CourseVersion;
  color?: CourseColor;
  image?: URI;
  fontColorOnImage?: AmplifyCourseFontColorOnImage;
};

type AmplifyGroupExam = {
  id: GroupExamId;
  groupId: GroupId;
  contentId: ContentId;
  courseId: CourseId;
  content: AmplifyGroupExamContent;
  course: AmplifyGroupExamCourse;
  problemsJson: string;
  problemHeaders?: Array<{
    id: ProblemHeaderId;
    body: MarkDownString;
    createdAt: string;
  }>;
  scheduledStart: string;
  scheduledFinish?: string;
  finishedAt?: string;
  visibilityLevel: AmplifyExamResultVisibilityLevel;
  userExams: { items: Array<AmplifyUserExam> };
  tenantCode: TenantCode;
  times: number;
  groupTrainingCourseId: GroupTrainingCourseId;
  timeLimit?: Minute;
  passingStandard?: number;
  userIdsToBeTested?: UserId[];
};

function toGroupExam(e: AmplifyGroupExam): GroupExamEntity {
  const now = localDateTimeNow();
  const scheduledStart = localDateTimeFromString(e.scheduledStart);
  const scheduledFinish = ifDefined(e.scheduledFinish, localDateTimeFromString);
  const status: GroupExamStatus = (() => {
    if (isDefined(e.finishedAt) || scheduledFinish?.isSameOrBefore(now)) {
      return 'finished';
    }
    if (scheduledStart.isSameOrBefore(now)) {
      return 'in_progress';
    }
    return 'announced';
  })();
  return new GroupExamEntityImpl({
    id: e.id,
    groupId: e.groupId,
    content: {
      ...e.content,
      version: e.content.version ?? 1,
      problems: JSON.parse(e.problemsJson) as Array<GroupExamProblem>,
      problemHeaders: (e.problemHeaders ?? []).map((h) => ({
        id: h.id,
        body: h.body,
        createdAt: localDateTimeFromString(h.createdAt),
      })),
    },
    course: {
      id: e.course.id,
      name: e.course.name,
      version: e.course.version,
      color: e.course.color,
      image: e.course.image,
      fontColorOnImage: ifDefined(
        e.course.fontColorOnImage,
        (v) => v.toLowerCase() as CourseFontColorOnImage
      ),
    },
    scheduledStart,
    scheduledFinish: e.scheduledFinish ? localDateTimeFromString(e.scheduledFinish) : undefined,
    finishedAt: e.finishedAt ? localDateTimeFromString(e.finishedAt) : undefined,
    status,
    visibilityLevel: e.visibilityLevel.toLocaleLowerCase() as ExamResultVisibilityLevel,
    userExams: e.userExams.items.map((ue) => ({
      id: ue.id,
      userId: ue.userId,
      status: ue.status.toLocaleLowerCase() as UserExamStatus,
    })),
    times: e.times,
    groupTrainingCourseId: e.groupTrainingCourseId,
    timeLimit: e.timeLimit,
    passingStandard: e.passingStandard,
    userIdsToBeTested: e.userIdsToBeTested ?? [],
  });
}

export class AmplifyGroupExamRepository implements GroupExamRepository {
  private appContextProvider: AppContextProvider;

  constructor(appContextProvider: AppContextProvider) {
    this.appContextProvider = appContextProvider;
  }

  async save(
    args: GroupExamEntity | EntityData<string, GroupExamAttributes>
  ): Promise<GroupExamEntity> {
    const tenantCode = requiredNonNull(
      this.appContextProvider.get().tenantCode,
      'appContext.tenantCode'
    );
    if (hasId(args)) {
      // 更新できない項目は設定しない
      const input: UpdateGroupExamInput = {
        id: args.id,
        scheduledStart: args.scheduledStart.toISOString(),
        scheduledFinish: ifDefined(args.scheduledFinish, (v) => v.toISOString()),
        finishedAt: ifDefined(args.finishedAt, (v) => v.toISOString()),
      };
      const response = await graphql<{ updateGroupExam: AmplifyGroupExam }>(
        customMutations.updateGroupExam,
        { input }
      );
      return toGroupExam(response.updateGroupExam);
    }
    const id = createGroupExamId({
      groupId: args.groupId,
      contentId: args.content.id,
      times: args.times,
    });
    const input: CreateGroupExamInput = {
      id,
      groupId: args.groupId,
      contentId: args.content.id,
      content: {
        id: args.content.id,
        version: args.content.version,
        name: args.content.name,
        requiredTime: args.content.requiredTime,
        indexInCourse: args.content.indexInCourse,
        passingStandard: args.content.passingStandard,
      },
      courseId: args.course.id,
      course: {
        id: args.course.id,
        name: args.course.name,
        version: args.course.version,
        color: args.course.color,
        image: args.course.image,
        fontColorOnImage: ifDefined(
          args.course.fontColorOnImage,
          (v) => v.toUpperCase() as AmplifyCourseFontColorOnImage
        ),
      },
      problemsJson: JSON.stringify(args.content.problems),
      problemHeaders: args.content.problemHeaders.map((ph) => ({
        id: ph.id,
        body: ph.body,
        createdAt: ph.createdAt.toISOString(),
      })),
      scheduledStart: args.scheduledStart.toISOString(),
      scheduledFinish: ifDefined(args.scheduledFinish, (v) => v.toISOString()),
      createdBy: requiredNonNull(this.appContextProvider.get().user?.id, 'appContext.user'),
      finishedAt: ifDefined(args.finishedAt, (v) => v.toISOString()),
      visibilityLevel: args.visibilityLevel.toUpperCase() as AmplifyExamResultVisibilityLevel,
      tenantCode,
      times: args.times,
      groupTrainingCourseId: args.groupTrainingCourseId,
      timeLimit: args.timeLimit,
      userIdsToBeTested: args.userIdsToBeTested,
      passingStandard: args.passingStandard,
    };
    const response = await graphql<{ createGroupExam: AmplifyGroupExam }>(
      customMutations.createGroupExam,
      { input }
    );
    return toGroupExam(response.createGroupExam);
  }

  async findById(id: GroupExamId): Promise<Optional<GroupExamEntity>> {
    const response = await graphql<{ getGroupExam: Optional<AmplifyGroupExam> }>(
      customQueries.getGroupExam,
      { id }
    );
    return response.getGroupExam ? toGroupExam(response.getGroupExam) : undefined;
  }

  async remove(id: GroupExamId): Promise<void> {
    await graphql(customMutations.deleteGroupExam, { input: { id } });
  }

  async findGroupExamsByGroupIdAndContentId(
    groupId: GroupId,
    contentId: ContentId
  ): Promise<Array<GroupExamEntity>> {
    const response = await graphql<{
      groupExamsByGroupAndContent: { items: Array<AmplifyGroupExam> };
    }>(customQueries.groupExamsByGroupAndContent, { groupId, contentId: { eq: contentId } });
    return response.groupExamsByGroupAndContent.items.map(toGroupExam);
  }

  async findGroupExamsByGroupId(groupId: string): Promise<GroupExamEntity[]> {
    const response = await graphql<{
      groupExamsByGroupAndContent: { items: Array<AmplifyGroupExam> };
    }>(customQueries.groupExamsByGroupAndContent, { groupId });
    return response.groupExamsByGroupAndContent.items.map(toGroupExam);
  }
}
