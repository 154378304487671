import {
  ContentType as AmplifyContentType,
  CourseFontColorOnImage as AmplifyCourseFontColorOnImage,
  UpdateCourseInput,
} from '@/API';
import {
  AppContextProvider,
  ContentId,
  ContentName,
  ContentType,
  CourseColor,
  CourseFontColorOnImage,
  CourseId,
  CourseName,
  CourseVersion,
  UserId,
} from '@/base/domains';
import { ISODateTimeString, Minute, Optional, URI } from '@/base/types';
import { assertEntityExists } from '@/base/usecases';
import {
  CourseContent,
  CourseData,
  CourseEntity,
  CourseEntityImpl,
  CourseReference,
  CourseRepository,
} from '@/contents/domains';
import * as mutations from '@/graphql/mutations';
import { coursesByTenantCode, getCourse, listCourses } from '@/graphql/queries';
import { graphql, graphqlQuery } from '@/utils/AmplifyUtils';
import { assertIsDefined } from '@/utils/Asserts';
import { localDateTimeFromString } from '@/utils/DateUtils';
import { ifDefined, isDefined, requiredNonNull } from '@/utils/TsUtils';

type AmplifyCourseContent = {
  name: ContentName;
  type: AmplifyContentType;
  requiredTime: Minute;
  id: ContentId;
};

type AmplifyCourse = {
  id: CourseId;
  name: CourseName;
  contents: Array<AmplifyCourseContent>;
  description?: string;
  version: CourseVersion;
  color?: CourseColor;
  image?: URI;
  fontColorOnImage?: CourseFontColorOnImage;
  confirmedBy?: UserId;
  createdAt: ISODateTimeString;
  versionCreatedBy?: UserId;
  versionCreatedAt?: ISODateTimeString;
  contentLastUpdatedBy?: UserId;
  contentLastUpdatedAt?: ISODateTimeString;
};

function toAmplifyCourseContent(e: CourseContent): AmplifyCourseContent {
  return {
    ...e,
    type: e.type.toUpperCase() as AmplifyContentType,
  };
}

function toCourseContent(amplifyCourseContent: AmplifyCourseContent): CourseContent {
  return {
    name: amplifyCourseContent.name,
    type: amplifyCourseContent.type.toLocaleLowerCase() as ContentType,
    requiredTime: amplifyCourseContent.requiredTime,
    id: amplifyCourseContent.id,
  };
}

function toCourse(amplifyCourse: AmplifyCourse): CourseEntity {
  const color = (() => {
    if (isDefined(amplifyCourse.color)) {
      return amplifyCourse.color;
    }
    if (isDefined(amplifyCourse.image)) {
      return undefined;
    }
    return 'default';
  })();
  return new CourseEntityImpl({
    id: amplifyCourse.id,
    name: amplifyCourse.name,
    contents: amplifyCourse.contents.map(toCourseContent),
    version: amplifyCourse.version,
    description: amplifyCourse.description,
    color,
    image: amplifyCourse.image,
    fontColorOnImage: ifDefined(
      amplifyCourse.fontColorOnImage,
      (v) => v.toLowerCase() as CourseFontColorOnImage
    ),
    confirmedBy: amplifyCourse.confirmedBy,
    confirmedAt: localDateTimeFromString(amplifyCourse.createdAt),
    versionCreatedBy: amplifyCourse.versionCreatedBy,
    versionCreatedAt: ifDefined(amplifyCourse.versionCreatedAt, localDateTimeFromString),
    contentLastUpdatedBy: amplifyCourse.contentLastUpdatedBy,
    contentLastUpdatedAt: ifDefined(amplifyCourse.contentLastUpdatedAt, localDateTimeFromString),
  });
}

export class AmplifyCourseRepository implements CourseRepository {
  appContextProvider: AppContextProvider;

  constructor(appContextProvider: AppContextProvider) {
    this.appContextProvider = appContextProvider;
  }

  async save(args: CourseData | CourseEntity): Promise<CourseEntity> {
    const tenantCode = requiredNonNull(
      this.appContextProvider.get().tenantCode,
      'appContext.tenantCode'
    );
    assertIsDefined(args.id);
    const input: UpdateCourseInput = {
      id: args.id,
      version: args.version,
      name: args.name,
      contents: args.contents.map(toAmplifyCourseContent),
      description: args.description,
      confirmedBy: args.confirmedBy,
      versionCreatedBy: args.versionCreatedBy,
      versionCreatedAt: args.versionCreatedAt?.toISOString(),
      contentLastUpdatedBy: args.contentLastUpdatedBy,
      contentLastUpdatedAt: args.contentLastUpdatedAt?.toISOString(),
      tenantCode,
      color: isDefined(args.color) ? args.color : null,
      image: isDefined(args.image) ? args.image : null,
      fontColorOnImage: ifDefined(
        args.fontColorOnImage,
        (v) => v.toUpperCase() as AmplifyCourseFontColorOnImage
      ),
    };
    const res = await graphql<{ updateCourse: AmplifyCourse }>(mutations.updateCourse, {
      input,
    });
    assertIsDefined(res);
    return toCourse(res.updateCourse);
  }

  async findById({
    id,
    version,
  }: {
    id: CourseId;
    version: CourseVersion;
  }): Promise<Optional<CourseEntity>> {
    const res = await graphql<{ getCourse: AmplifyCourse }>(getCourse, { id, version });
    return res?.getCourse ? toCourse(res.getCourse) : undefined;
  }

  async remove(_: { id: CourseId; version: CourseVersion }): Promise<void> {
    throw new Error('unsupported operation');
  }

  async findTenantCourses(): Promise<Array<CourseEntity>> {
    const tenantCode = requiredNonNull(
      this.appContextProvider.get().tenantCode,
      'appContext.tenantCode'
    );
    const res = await graphqlQuery<{ coursesByTenantCode: { items: Array<AmplifyCourse> } }>(
      coursesByTenantCode,
      {
        tenantCode,
      },
      {
        limit: 1000,
      }
    );
    return res?.coursesByTenantCode.items.map(toCourse) ?? [];
  }

  async findLatestVersion(id: CourseId): Promise<number> {
    const l = await this.findCoursesById(id);
    if (l.length === 0) {
      assertEntityExists(undefined, 'course');
    }
    return l[l.length - 1].version;
  }

  async findCoursesById(id: CourseId): Promise<CourseReference[]> {
    const res = await graphql<{ listCourses: { items: Array<AmplifyCourse> } }>(listCourses, {
      id,
    });
    return res.listCourses.items.map(toCourse);
  }
}
