import { AuthorizationService, ContentId, CourseId, GroupId } from '@/base/domains';
import { AbstractUseCase, assertEntityExists, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { GroupTrainingCourseReference, GroupTrainingCourseRepository } from '../domains';

export interface CloseGroupContentRequest {
  groupId: GroupId;
  courseId: CourseId;
  contentId: ContentId;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export type CloseGroupContentResponse = {
  course: GroupTrainingCourseReference;
};

export interface CloseGroupContent
  extends UseCase<CloseGroupContentRequest, CloseGroupContentResponse> {
  execute(request: CloseGroupContentRequest): Promise<UseCaseResponse<CloseGroupContentResponse>>;
}

export class CloseGroupContentImpl
  extends AbstractUseCase<CloseGroupContentRequest, CloseGroupContentResponse>
  implements CloseGroupContent
{
  private authorizationService: AuthorizationService;

  private groupTrainingCourseRepository: GroupTrainingCourseRepository;

  constructor(
    authorizationService: AuthorizationService,
    groupTrainingCourseRepository: GroupTrainingCourseRepository
  ) {
    super('training.CloseGroupContent');
    this.authorizationService = authorizationService;
    this.groupTrainingCourseRepository = groupTrainingCourseRepository;
  }

  async internalExecute(request: CloseGroupContentRequest): Promise<CloseGroupContentResponse> {
    const { groupId, courseId, contentId } = request;
    this.authorizationService.assertTrainerAccessible(groupId);
    const course = await this.groupTrainingCourseRepository.findByGroupIdAndCourseId(
      groupId,
      courseId
    );
    assertEntityExists(course, 'groupTrainingCourse');
    const saved = await this.groupTrainingCourseRepository.save(course.closeContent(contentId));
    return {
      course: saved,
    };
  }
}

export const CloseGroupContentKey = injectionKeyOf<CloseGroupContent>({
  boundedContext: 'training',
  type: 'usecase',
  name: 'CloseGroupContent',
});

export function useCloseGroupContent(): CloseGroupContent {
  return requiredInject(CloseGroupContentKey);
}
