import { GroupId, Schedule, ScheduleFinder } from '@/base/domains';
import { AuthorizationService } from '@/base/domains/AuthorizationService';
import { LocalDateTime } from '@/base/types';
import { AbstractUseCase, UseCase, UseCaseResponse } from '@/base/usecases';
import { hasNonNullProperty } from '@/utils/TsUtils';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

export type GetSchedulesRequest =
  | {
      limit?: number;
      groupId: GroupId;
      startFrom: LocalDateTime;
      startTo: LocalDateTime;
    }
  | {
      limit?: number;
      groupId: GroupId;
      recently: true;
    };

export type GetSchedulesResponse = {
  schedules: Array<Schedule>;
};

export interface GetSchedules extends UseCase<GetSchedulesRequest, GetSchedulesResponse> {
  execute(request: GetSchedulesRequest): Promise<UseCaseResponse<GetSchedulesResponse>>;
}

export class GetSchedulesImpl
  extends AbstractUseCase<GetSchedulesRequest, GetSchedulesResponse>
  implements GetSchedules
{
  private scheduleFinder: ScheduleFinder;

  private authorizationService: AuthorizationService;

  constructor(scheduleFinder: ScheduleFinder, authorizationService: AuthorizationService) {
    super('home.GetSchedules');
    this.scheduleFinder = scheduleFinder;
    this.authorizationService = authorizationService;
  }

  async internalExecute(request: GetSchedulesRequest): Promise<GetSchedulesResponse> {
    const { groupId } = request;
    this.authorizationService.assertGroupReadAccessible(groupId);
    const schedules = await (async () => {
      if (hasNonNullProperty(request, 'recently')) {
        const res = await this.scheduleFinder.findRecently({
          groupId: request.groupId,
          limit: request.limit,
        });
        return res.data;
      }
      const res = await this.scheduleFinder.find(request);
      return res.data;
    })();
    return {
      schedules,
    };
  }
}

export const GetSchedulesKey = injectionKeyOf<GetSchedules>({
  boundedContext: 'home',
  type: 'usecase',
  name: 'GetSchedules',
});

export function useGetSchedules(): GetSchedules {
  return requiredInject(GetSchedulesKey);
}
