import {
  AuthorizationService,
  ContentId,
  ContentName,
  ContentType,
  CourseId,
  CourseName,
  CourseVersion,
} from '@/base/domains';
import { Minute, Optional } from '@/base/types';
import { AbstractUseCase, UseCase, UseCaseResponse } from '@/base/usecases';
import { isDefined } from '@/utils/TsUtils';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { CourseRepository } from '../domains';

export interface GetCourseHistoryRequest {
  id: CourseId;
  version: CourseVersion;
}

export type GetCourseHistoryContent = {
  id: ContentId;
  type: ContentType;
  name: ContentName;
  requiredTime: Minute;
};

export type GetCourseHistoryCourse = {
  id: CourseId;
  name: CourseName;
  version: CourseVersion;
  contents: Array<GetCourseHistoryContent>;
  description?: string;
};

export type GetCourseHistoryResponse = {
  history: Optional<GetCourseHistoryCourse>;
};

export interface GetCourseHistory
  extends UseCase<GetCourseHistoryRequest, GetCourseHistoryResponse> {
  execute(request: GetCourseHistoryRequest): Promise<UseCaseResponse<GetCourseHistoryResponse>>;
}

export class GetCourseHistoryImpl
  extends AbstractUseCase<GetCourseHistoryRequest, GetCourseHistoryResponse>
  implements GetCourseHistory
{
  private authorizationService: AuthorizationService;

  private courseRepository: CourseRepository;

  constructor(authorizationService: AuthorizationService, courseRepository: CourseRepository) {
    super('contents.GetCourseHistory');
    this.authorizationService = authorizationService;
    this.courseRepository = courseRepository;
  }

  async internalExecute(request: GetCourseHistoryRequest): Promise<GetCourseHistoryResponse> {
    const { id, version } = request;
    this.authorizationService.assertContentEditable();
    const course = await this.courseRepository.findById({ id, version });
    if (isDefined(course)) {
      return {
        history: {
          id: course.id,
          name: course.name,
          version: course.version,
          description: course.description,
          contents: course.contents.map((cn) => ({
            id: cn.id,
            name: cn.name,
            type: cn.type,
            requiredTime: cn.requiredTime,
          })),
        },
      };
    }
    return {
      history: undefined,
    };
  }
}

export const GetCourseHistoryKey = injectionKeyOf<GetCourseHistory>({
  boundedContext: 'contents',
  type: 'usecase',
  name: 'GetCourseHistory',
});

export function useGetCourseHistory(): GetCourseHistory {
  return requiredInject(GetCourseHistoryKey);
}
