/* eslint-disable @typescript-eslint/no-unused-vars */
import { Optional } from '@/base/types';

import { GroupRepository, useGroupRepository } from '../Group';
import { UserRepository, useUserRepository } from '../User';
import {
  AppExtension,
  AppExtensionConfig,
  AppExtensionFactory,
  ExtensionName,
  GroupExtension,
  GroupExtensionConfig,
  GroupExtensionFactory,
  UserExtension,
  UserExtensionConfig,
  UserExtensionFactory,
} from './Extension';
import { ExtensionService, InstallRequest } from './ExtensionService';

type Factory = {
  app: AppExtensionFactory<AppExtension>;
  group: GroupExtensionFactory<GroupExtension>;
  user: UserExtensionFactory<UserExtension>;
};

export class ExtensionServiceImpl implements ExtensionService {
  private factories: Map<ExtensionName, Factory> = new Map();

  private groupRepository: GroupRepository;

  private userRepository: UserRepository;

  constructor() {
    this.groupRepository = useGroupRepository();
    this.userRepository = useUserRepository();
  }

  install<A extends AppExtension, G extends GroupExtension, U extends UserExtension>(
    request: InstallRequest<A, G, U>
  ): void {
    this.factories.set(request.name, {
      app: request.appExtensionFactory,
      group: request.groupExtensionFactory,
      user: request.userExtensionFactory,
    });
  }

  saveAppConfig(config: AppExtensionConfig): Promise<void> {
    throw new Error('Method not implemented.');
  }

  saveGroupConfig(config: GroupExtensionConfig): Promise<void> {
    throw new Error('Method not implemented.');
  }

  saveUserConfig(config: UserExtensionConfig): Promise<void> {
    throw new Error('Method not implemented.');
  }

  installedExtensionNames(): Array<ExtensionName> {
    // TODO
    return ['slack'];
  }

  availableExtensionNames(): Promise<Array<ExtensionName>> {
    // TODO
    return Promise.resolve(['slack']);
  }

  isAvailable(request: { name: string; groupId: string }): Promise<boolean> {
    // TODO
    return Promise.resolve(true);
  }

  getAppExtension<C extends AppExtension>(name: string): Promise<Optional<C>> {
    throw new Error('Method not implemented.');
  }

  async getGroupExtension<C extends GroupExtensionConfig>(
    name: string,
    groupId: string
  ): Promise<Optional<C>> {
    const group = await this.groupRepository.findById(groupId);
    const config = group?.extensionConfigs.find((c) => c.name === name);
    if (!config) {
      return undefined;
    }
    const factory = this.factories.get(config.name);
    if (!factory) {
      return undefined;
    }
    return factory.group.create(config) as C;
  }

  async getUserExtension<C extends UserExtensionConfig>(
    name: string,
    userId: string
  ): Promise<Optional<C>> {
    const user = await this.userRepository.findById(userId);
    const config = user?.extensionConfigs.find((c) => c.name === name);
    if (!config) {
      return undefined;
    }
    const factory = this.factories.get(config.name);
    if (!factory) {
      return undefined;
    }
    return factory.user.create(config) as C;
  }
}
