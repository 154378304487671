import { v4 } from 'uuid';

import {
  AndroidUserDevice,
  isEntityLike,
  Subscription,
  UserAttributes,
  UserData,
  UserEntity,
  UserEntityImpl,
  UserId,
  UserRepository,
  UserTagId,
} from '@/base/domains';
import { Optional } from '@/base/types';
import { assertEntityExists } from '@/base/usecases';

export class InMemoryUserRepository implements UserRepository {
  private store: Map<UserId, UserEntity> = new Map();

  save(entity: UserAttributes | UserData): Promise<UserEntity> {
    const e = isEntityLike<UserData>(entity)
      ? new UserEntityImpl(entity)
      : new UserEntityImpl({ ...entity, id: v4() });

    this.store.set(e.id, e);
    return Promise.resolve(e);
  }

  findById(id: UserId): Promise<Optional<UserEntity>> {
    return Promise.resolve(this.store.get(id));
  }

  remove(id: UserId): Promise<void> {
    this.store.delete(id);
    return Promise.resolve();
  }

  findTenantUsers(): Promise<Array<UserEntity>> {
    return Promise.resolve(Array.from(this.store.values()));
  }

  findTenantEnabledUsers(): Promise<Array<UserEntity>> {
    return Promise.resolve(Array.from(this.store.values()));
  }

  async replaceTags(id: UserId, _tagIds: Array<UserTagId>): Promise<UserEntity> {
    const user = await this.findById(id);
    assertEntityExists(user, 'user');
    return user;
  }

  subscribeUserStatusChanged(_: {
    onNext: (user: UserEntity) => void;
    onError: (e: Error) => void;
  }): Subscription {
    return {
      unsubscribe: () => {
        // do nothing
      },
      isClosed: () => false,
    };
  }

  addUserDevice(_userDevice: AndroidUserDevice): Promise<void> {
    return Promise.resolve();
  }
}
