import { DateTimeService } from '@/base/domains';
import { LocalDateTime } from '@/base/types';
import * as queries from '@/graphql/queries';
import { graphql } from '@/utils/AmplifyUtils';
import { localDateTimeFromString } from '@/utils/DateUtils';

export class AmplifyDateTimeService implements DateTimeService {
  async strictLocalDateTimeNow(): Promise<LocalDateTime> {
    const res = await graphql<{ now: string }>(queries.now);
    return localDateTimeFromString(res.now);
  }
}
