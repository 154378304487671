import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { AuthorizationService, GroupId, GroupStatus } from '../domains';
import { GroupStatusQueries } from '../domains/queries';
import { Optional } from '../types';
import { AbstractUseCase, UseCase, UseCaseResponse } from './UseCase';

export type GetGroupStatusRequest = {
  id: GroupId;
};

export type GetGroupStatusResponse = {
  groupStatus: Optional<GroupStatus>;
};

/**
 * グループステータスを取得する
 */
export interface GetGroupStatus extends UseCase<GetGroupStatusRequest, GetGroupStatusResponse> {
  execute(request: GetGroupStatusRequest): Promise<UseCaseResponse<GetGroupStatusResponse>>;
}

export class GetGroupStatusImpl
  extends AbstractUseCase<GetGroupStatusRequest, GetGroupStatusResponse>
  implements GetGroupStatus
{
  constructor(
    private authorizationService: AuthorizationService,
    private groupStatusQueries: GroupStatusQueries
  ) {
    super('base.GetGroupStatus');
  }

  async internalExecute({ id }: GetGroupStatusRequest): Promise<GetGroupStatusResponse> {
    this.authorizationService.assertGroupReadAccessible(id);
    const groupStatus = await this.groupStatusQueries.findByGroupId(id);
    return {
      groupStatus,
    };
  }
}

export const GetGroupStatusKey = injectionKeyOf<GetGroupStatus>({
  boundedContext: 'base',
  type: 'usecase',
  name: 'GetGroupStatus',
});

export function useGetGroupStatus(): GetGroupStatus {
  return requiredInject(GetGroupStatusKey);
}
