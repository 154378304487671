import {
  AuthorizationService,
  EventStore,
  ExamDataAdapter,
  ExamId,
  GroupExam,
  UserExam,
} from '@/base/domains';
import { AbstractUseCase, assertEntityExists, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { ExamStarted, examStartedFactory } from '../domains';
import { FAILED_STARTING_EXAM } from '../ErrorCodes';

export interface StartExamRequest {
  examId: ExamId;
}

export type StartExamResponse = {
  exam: UserExam & { groupExam: Omit<GroupExam, 'userExams'> };
};

/**
 * 試験を開始する
 */
export interface StartExam extends UseCase<StartExamRequest, StartExamResponse> {
  execute(request: StartExamRequest): Promise<UseCaseResponse<StartExamResponse>>;
}

export class StartExamImpl
  extends AbstractUseCase<StartExamRequest, StartExamResponse>
  implements StartExam
{
  private eventStore: EventStore;

  private examDataAdapter: ExamDataAdapter;

  private authorizationService: AuthorizationService;

  constructor(
    eventStore: EventStore,
    examDataAdapter: ExamDataAdapter,
    authorizationService: AuthorizationService
  ) {
    super('training.StartExam');
    this.eventStore = eventStore;
    this.examDataAdapter = examDataAdapter;
    this.authorizationService = authorizationService;
  }

  async internalExecute(request: StartExamRequest): Promise<StartExamResponse> {
    const { examId } = request;
    const activeExam = await this.examDataAdapter.findActiveExam();
    if (activeExam) {
      throw FAILED_STARTING_EXAM.toApplicationError();
    }
    const userExam = await this.examDataAdapter.findUserExam(examId);
    assertEntityExists(userExam, 'userExam');
    this.authorizationService.assertOwnerAccessible(userExam.userId);

    if (userExam.status !== 'not_started') {
      throw FAILED_STARTING_EXAM.toApplicationError({});
    }

    const event: ExamStarted = {
      examId,
    };
    await this.eventStore.save(examStartedFactory(event));
    return {
      exam: userExam,
    };
  }
}

export const StartExamKey = injectionKeyOf<StartExam>({
  boundedContext: 'training',
  type: 'usecase',
  name: 'StartExam',
});

export function useStartExam(): StartExam {
  return requiredInject(StartExamKey);
}
