import {
  useAppContextProvider,
  useAuthorizationService,
  useContentLearningDataAdapter,
  useCourseFinder,
  useExamDataAdapter,
  useGroupFinder,
  useGroupTrainingFinder,
  usePrivacyPolicyDataAdapter,
  useQuestionFinder,
  useReviewProblemFinder,
  useScheduleFinder,
  useTenantDataAdapter,
  useTenantOwnTermsOfServiceFinder,
  useTermsOfServiceDataAdapter,
  useUserFinder,
  useUserRepository,
} from '@/base/domains';
import {
  ConfirmTermsOfServiceImpl,
  ConfirmTermsOfServiceKey,
  GetCourseDetailsImpl,
  GetCourseDetailsKey,
  GetCoursesImpl,
  GetCoursesKey,
  GetGroupsByAdminImpl,
  GetGroupsByAdminKey,
  GetHomeContentsImpl,
  GetHomeContentsKey,
  GetMobileHomeContentsImpl,
  GetMobileHomeContentsKey,
  GetQuestionsByAdminImpl,
  GetQuestionsByAdminKey,
  GetSchedulesByAdminImpl,
  GetSchedulesByAdminKey,
  GetSchedulesImpl,
  GetSchedulesKey,
  GetTermsServiceLatestVersionsImpl,
  GetTermsServiceLatestVersionsKey,
} from '@/home/usecases';
import { useQuestionnaireRepository } from '@/training/domains';
import { provide } from '@/utils/VueUtils';

export function useHomeUseCases() {
  const appContextProvider = useAppContextProvider();
  const groupTrainingFinder = useGroupTrainingFinder();
  const scheduleFinder = useScheduleFinder();
  const questionFinder = useQuestionFinder();
  const questionnaireRepository = useQuestionnaireRepository();
  const groupFinder = useGroupFinder();
  const userFinder = useUserFinder();
  const authorizationService = useAuthorizationService();
  const contentLearningDataAdapter = useContentLearningDataAdapter();
  const examDataAdapter = useExamDataAdapter();
  const courseFinder = useCourseFinder();
  const tenantDataAdapter = useTenantDataAdapter();
  const userRepository = useUserRepository();
  const termsOfServiceDataAdapter = useTermsOfServiceDataAdapter();
  const tenantOwnTermsOfServiceFinder = useTenantOwnTermsOfServiceFinder();
  const privacyPolicyDataAdapter = usePrivacyPolicyDataAdapter();
  const reviewProblemFinder = useReviewProblemFinder();

  provide(
    GetHomeContentsKey,
    new GetHomeContentsImpl(
      authorizationService,
      groupTrainingFinder,
      appContextProvider,
      contentLearningDataAdapter,
      examDataAdapter
    )
  );
  provide(
    GetCourseDetailsKey,
    new GetCourseDetailsImpl(
      authorizationService,
      groupTrainingFinder,
      appContextProvider,
      contentLearningDataAdapter,
      examDataAdapter,
      courseFinder,
      reviewProblemFinder
    )
  );
  provide(GetSchedulesByAdminKey, new GetSchedulesByAdminImpl(scheduleFinder));
  provide(GetQuestionsByAdminKey, new GetQuestionsByAdminImpl(questionFinder, groupTrainingFinder));
  provide(GetGroupsByAdminKey, new GetGroupsByAdminImpl(groupFinder, userFinder));
  provide(GetSchedulesKey, new GetSchedulesImpl(scheduleFinder, authorizationService));
  provide(
    GetMobileHomeContentsKey,
    new GetMobileHomeContentsImpl(
      appContextProvider,
      groupTrainingFinder,
      contentLearningDataAdapter,
      scheduleFinder,
      examDataAdapter,
      questionFinder,
      questionnaireRepository,
      tenantDataAdapter,
      reviewProblemFinder
    )
  );
  provide(
    GetCoursesKey,
    new GetCoursesImpl(
      appContextProvider,
      groupTrainingFinder,
      contentLearningDataAdapter,
      examDataAdapter,
      authorizationService,
      reviewProblemFinder
    )
  );
  provide(
    ConfirmTermsOfServiceKey,
    new ConfirmTermsOfServiceImpl(userRepository, appContextProvider)
  );
  provide(
    GetTermsServiceLatestVersionsKey,
    new GetTermsServiceLatestVersionsImpl(
      appContextProvider,
      termsOfServiceDataAdapter,
      tenantOwnTermsOfServiceFinder,
      privacyPolicyDataAdapter
    )
  );
}
