/* eslint-disable @typescript-eslint/no-unused-vars */

import { v4 } from 'uuid';

import { ContentId, GroupId, isEntityLike } from '@/base/domains';
import { MemoId } from '@/base/domains/Memo';
import { Optional } from '@/base/types';
import {
  MemoAttributes,
  MemoData,
  MemoEntity,
  MemoEntityImpl,
  MemoRepository,
} from '@/training/domains';

export class InMemoryMemoRepository implements MemoRepository {
  constructor(private memos: Array<MemoEntity>) {}

  private store: Map<MemoId, MemoEntity> = new Map();

  save(entity: MemoAttributes): Promise<MemoEntity> {
    const e = isEntityLike<MemoData>(entity)
      ? new MemoEntityImpl(entity)
      : new MemoEntityImpl({ ...entity, id: v4() });
    this.store.set(e.id, e);
    return Promise.resolve(e);
  }

  findById(id: MemoId): Promise<Optional<MemoEntity>> {
    return Promise.resolve(this.store.get(id));
  }

  remove(id: MemoId): Promise<void> {
    this.store.delete(id);
    return Promise.resolve();
  }

  findByGroupId(groupId: GroupId): Promise<Array<MemoEntity>> {
    return Promise.resolve(this.memos);
  }

  findByGroupAndContent(groupId: GroupId, contentId: ContentId): Promise<Array<MemoEntity>> {
    return Promise.resolve(this.memos);
  }
}
