import moment from 'moment';

import {
  EditingTenantOwnTermsOfServiceEntity,
  EditingTenantOwnTermsOfServiceEntityImpl,
  EditingTenantOwnTermsOfServiceRepository,
  TenantOwnTermsOfServiceReference,
} from '@/admin/domains';
import {
  CreateTenantOwnTermsOfServiceInput,
  DeleteTenantOwnTermsOfServiceInput,
  TenantOwnTermsOfServiceType as AmplifyTenantOwnTermsOfServiceType,
  UpdateTenantOwnTermsOfServiceInput,
} from '@/API';
import { AppContextProvider, TenantCode, UserId } from '@/base/domains';
import { LocalDateTime, MarkDownString, Optional } from '@/base/types';
import * as mutations from '@/graphql/mutations';
import * as queries from '@/graphql/queries';
import { graphql } from '@/utils/AmplifyUtils';
import { assertIsDefined } from '@/utils/Asserts';
import { localDateTimeNow } from '@/utils/DateUtils';
import { requiredNonNull } from '@/utils/TsUtils';

type AmplifyTenantOwnTermsOfService = {
  id: string;
  tenantCode: TenantCode;
  type: AmplifyTenantOwnTermsOfServiceType;
  body: MarkDownString;
  createdBy: UserId;
  createdAt: LocalDateTime;
  updatedBy?: UserId;
  updatedAt?: LocalDateTime;
  version?: number;
};

function toEditingTenantOwnTermsOfService(
  tenantOwnTermsOfService: AmplifyTenantOwnTermsOfService
): EditingTenantOwnTermsOfServiceEntity {
  return new EditingTenantOwnTermsOfServiceEntityImpl({
    tenantCode: tenantOwnTermsOfService.tenantCode,
    body: tenantOwnTermsOfService.body,
    version: tenantOwnTermsOfService.version ?? 1,
    createdAt: moment(tenantOwnTermsOfService.createdAt),
    createdBy: tenantOwnTermsOfService.createdBy,
    updatedAt: tenantOwnTermsOfService.updatedAt
      ? moment(tenantOwnTermsOfService.updatedAt)
      : moment(tenantOwnTermsOfService.createdAt),
    updatedBy: tenantOwnTermsOfService.updatedBy ?? tenantOwnTermsOfService.createdBy,
  });
}

function toTenantOwnTermsOfService(
  tenantOwnTermsOfService: AmplifyTenantOwnTermsOfService
): TenantOwnTermsOfServiceReference {
  return {
    tenantCode: tenantOwnTermsOfService.tenantCode,
    body: tenantOwnTermsOfService.body,
    version: tenantOwnTermsOfService.version ?? 1,
    createdAt: tenantOwnTermsOfService.createdAt,
    createdBy: tenantOwnTermsOfService.createdBy,
  };
}

export class AmplifyEditingTenantOwnTermsOfServiceRepository
  implements EditingTenantOwnTermsOfServiceRepository
{
  constructor(private appContextProvider: AppContextProvider) {}

  async save(
    body: MarkDownString,
    version?: number
  ): Promise<EditingTenantOwnTermsOfServiceEntity> {
    const tenantCode = requiredNonNull(
      this.appContextProvider.get().tenantCode,
      'appContext.tenantCode'
    );
    const userId = requiredNonNull(this.appContextProvider.get().user?.id, 'appContext.user');
    const now = localDateTimeNow();
    if (!version) {
      const editingTenantOwnTermsOfService = await this.findEditingTenantOwnTermsOfService();
      assertIsDefined(editingTenantOwnTermsOfService, 'editingTenantOwnTermsOfService');
      const input: UpdateTenantOwnTermsOfServiceInput = {
        id: `${tenantCode}#EDITING`,
        tenantCode,
        type: AmplifyTenantOwnTermsOfServiceType.EDITING,
        body,
        version: editingTenantOwnTermsOfService.version,
        createdBy: editingTenantOwnTermsOfService.createdBy,
        createdAt: editingTenantOwnTermsOfService.createdAt.toISOString(),
        updatedBy: userId,
        updatedAt: now.toISOString(),
      };
      const res = await graphql<{ updateTenantOwnTermsOfService: AmplifyTenantOwnTermsOfService }>(
        mutations.updateTenantOwnTermsOfService,
        { input }
      );
      return {
        ...toEditingTenantOwnTermsOfService(res.updateTenantOwnTermsOfService),
      };
    }
    const input: CreateTenantOwnTermsOfServiceInput = {
      id: `${tenantCode}#EDITING`,
      tenantCode,
      type: AmplifyTenantOwnTermsOfServiceType.EDITING,
      body,
      version,
      createdBy: userId,
      createdAt: now.toISOString(),
      updatedBy: userId,
      updatedAt: now.toISOString(),
    };
    const res = await graphql<{ createTenantOwnTermsOfService: AmplifyTenantOwnTermsOfService }>(
      mutations.createTenantOwnTermsOfService,
      { input }
    );
    return {
      ...toEditingTenantOwnTermsOfService(res.createTenantOwnTermsOfService),
    };
  }

  async findEditingTenantOwnTermsOfService(): Promise<
    Optional<EditingTenantOwnTermsOfServiceEntity>
  > {
    const tenantCode = requiredNonNull(
      this.appContextProvider.get().tenantCode,
      'appContext.tenantCode'
    );
    const res = await graphql<{ getTenantOwnTermsOfService: AmplifyTenantOwnTermsOfService }>(
      queries.getTenantOwnTermsOfService,
      {
        id: `${tenantCode}#EDITING`,
      }
    );
    return res.getTenantOwnTermsOfService
      ? toEditingTenantOwnTermsOfService(res.getTenantOwnTermsOfService)
      : undefined;
  }

  async remove(tenantCode: TenantCode): Promise<void> {
    const id = `${tenantCode}#EDITING`;
    const input: DeleteTenantOwnTermsOfServiceInput = {
      id,
    };
    await graphql<{ deleteEditingTenantOwnTermsOfService: AmplifyTenantOwnTermsOfService }>(
      mutations.deleteTenantOwnTermsOfService,
      {
        input,
      }
    );
  }

  async confirm(version: number): Promise<TenantOwnTermsOfServiceReference> {
    const editingTenantOwnTermsOfService = await this.findEditingTenantOwnTermsOfService();
    assertIsDefined(editingTenantOwnTermsOfService, 'editingTenantOwnTermsOfService');
    const userId = requiredNonNull(this.appContextProvider.get().user?.id, 'appContext.user');
    const now = localDateTimeNow();
    if (version === 1) {
      const input: CreateTenantOwnTermsOfServiceInput = {
        id: `${editingTenantOwnTermsOfService.tenantCode}#CONFIRMED`,
        tenantCode: editingTenantOwnTermsOfService.tenantCode,
        type: AmplifyTenantOwnTermsOfServiceType.CONFIRMED,
        body: editingTenantOwnTermsOfService?.body,
        version,
        createdBy: userId,
        createdAt: now.toISOString(),
      };
      const res = await graphql<{ createTenantOwnTermsOfService: AmplifyTenantOwnTermsOfService }>(
        mutations.createTenantOwnTermsOfService,
        { input }
      );
      await this.remove(res.createTenantOwnTermsOfService.tenantCode);
      return toTenantOwnTermsOfService(res.createTenantOwnTermsOfService);
    }
    const input: UpdateTenantOwnTermsOfServiceInput = {
      id: `${editingTenantOwnTermsOfService.tenantCode}#CONFIRMED`,
      tenantCode: editingTenantOwnTermsOfService.tenantCode,
      type: AmplifyTenantOwnTermsOfServiceType.CONFIRMED,
      body: editingTenantOwnTermsOfService?.body,
      version,
      createdBy: userId,
      createdAt: now.toISOString(),
    };
    const res = await graphql<{ updateTenantOwnTermsOfService: AmplifyTenantOwnTermsOfService }>(
      mutations.updateTenantOwnTermsOfService,
      { input }
    );
    await this.remove(res.updateTenantOwnTermsOfService.tenantCode);
    return toTenantOwnTermsOfService(res.updateTenantOwnTermsOfService);
  }
}
