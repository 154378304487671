import BigNumber from 'bignumber.js';
import moment from 'moment';

import {
  AppContextProvider,
  ContentId,
  ContentLearning,
  ContentLearningDataAdapter,
  CourseDisplayName,
  CourseId,
  createGroupExamId,
  ExamDataAdapter,
  ExamResult,
  GroupFinder,
  GroupId,
  GroupTrainingCourse,
  GroupTrainingFinder,
  QuestionFinder,
  UserFinder,
  UserId,
  UserName,
} from '@/base/domains';
import { LocalDate, LocalDateTime, Minute, Optional } from '@/base/types';
import { assertEntityExists } from '@/base/usecases';
import { ifDefined, uniqueArray } from '@/utils/TsUtils';

import {
  CourseStatusRecord,
  GroupCourseProgresses,
  GroupExamCorrections,
  ReportService,
  TrainerUserReportRecords,
  UserCourseProgresses,
  UserCourseProgressRecord,
  UserCourseReport,
  UserCourseStatusRecord,
  UserExamCorrections,
  UserExamRecord,
} from './ReportService';

function toPercentage(n: BigNumber) {
  return n.multipliedBy(100);
}

function rate(a: BigNumber, b: BigNumber): BigNumber {
  if (b.eq(new BigNumber('0'))) {
    return new BigNumber('0');
  }
  return new BigNumber(a).dividedBy(new BigNumber(b)).multipliedBy(new BigNumber('100')).dp(2);
}

function average(a: BigNumber, b: BigNumber): BigNumber {
  if (b.eq(new BigNumber('0'))) {
    return new BigNumber('0');
  }
  return new BigNumber(a).dividedBy(new BigNumber(b)).dp(2);
}

function toContentCourseMap(
  rawContentLearnings: Array<ContentLearning>,
  groupTrainingCourses: Array<GroupTrainingCourse>
): Array<ContentLearning> {
  const contentToCourse = new Map(
    groupTrainingCourses.flatMap((cr) => cr.contents.map((cn) => [cn.id, cr.id]))
  );
  const res = rawContentLearnings.filter((cl) => !!contentToCourse.get(cl.contentId));
  return res;
}

function toExamResultMap(examResults: Array<ExamResult>): Map<CourseId, Array<ExamResult>> {
  const res = examResults.reduce((acc, v) => {
    const ers = acc.get(v.courseId) ?? [];
    acc.set(v.courseId, [...ers, v]);
    return acc;
  }, new Map());
  return res;
}

export class ReportServiceImpl implements ReportService {
  constructor(
    private examDataAdapter: ExamDataAdapter,
    private groupTrainingFinder: GroupTrainingFinder,
    private groupFinder: GroupFinder,
    private userFinder: UserFinder,
    private contentLearningDataAdapter: ContentLearningDataAdapter,
    private questionFinder: QuestionFinder,
    private appContextProvider: AppContextProvider
  ) {}

  async findUserExamRecords(groupId: GroupId, courseId: CourseId): Promise<Array<UserExamRecord>> {
    const [examResults, groupTraining, group, users] = await Promise.all([
      this.examDataAdapter.findExamResults({ groupId, courseId }),
      this.groupTrainingFinder.findCourseByGroupIdAndCourseId(groupId, courseId),
      this.groupFinder.findById(groupId),
      this.userFinder.findTenantUsers(),
    ]);

    if (!groupTraining || !group) {
      return [];
    }

    const userNames = new Map(users.map((user) => [user.id, user.name]));
    const exams = groupTraining.contents
      .filter((cn) => cn.type === 'exam')
      .map((cn) => ({
        contentId: cn.id,
        contentName: cn.name,
      }));
    const examResultsMap: Map<ContentId, ExamResult> = (() => {
      const timesOf = (e: ExamResult) => {
        if (e.version === 1) {
          return Number.MIN_SAFE_INTEGER;
        }
        return e.times;
      };
      const l = examResults.sort((a, b) => -(timesOf(a) - timesOf(b)));
      const map = new Map();
      l.forEach((e) => {
        const key = `${e.contentId}#${e.userId}`;
        if (!map.has(key)) {
          map.set(key, e);
        }
      });
      return map;
    })();
    return group.users
      .filter((u) => u.role === 'trainee')
      .map((user) => {
        return {
          userId: user.id,
          userName: userNames.get(user.id) ?? '',
          results: exams.map((cn) => {
            const key = `${cn.contentId}#${user.id}`;
            const examResult = examResultsMap.get(key);
            const score = examResult ? new BigNumber(examResult.score) : undefined;
            return {
              ...cn,
              score,
              problemCount: examResult?.problemCount,
            };
          }),
        };
      });
  }

  async findUserCourseProgressRecords(groupId: GroupId): Promise<Array<UserCourseProgressRecord>> {
    const [group, users, groupTrainingCourses, contentLearnings] = await Promise.all([
      this.groupFinder.findById(groupId),
      this.userFinder.findTenantUsers(),
      this.groupTrainingFinder.findCoursesByGroupId(groupId),
      this.contentLearningDataAdapter.findByGroupId(groupId),
    ]);
    if (!group) {
      return [];
    }
    const groupUserIds = group.users.map((user) => user.id);
    const courses = groupTrainingCourses.map((gc) => ({
      courseId: gc.id,
      courseName: gc.name,
      contentIds: gc.contents.map((cn) => cn.id),
    }));
    const completedContentLearnings = contentLearnings.filter((cl) => cl.status === 'completed');
    return users
      .filter((u) => groupUserIds.includes(u.id))
      .map((u) => {
        return {
          userId: u.id,
          userName: u.name,
          results: courses.map((cr) => {
            const completedCount = completedContentLearnings.filter(
              (cl) => cl.userId === u.id && cr.contentIds.includes(cl.contentId)
            ).length;

            return {
              courseId: cr.courseId,
              courseName: cr.courseName,
              progress: toPercentage(
                new BigNumber(completedCount).dividedBy(new BigNumber(cr.contentIds.length))
              ),
            };
          }),
        };
      });
  }

  async findUserCourseStatusRecords(
    groupId: GroupId,
    courseId: CourseId
  ): Promise<Array<UserCourseStatusRecord>> {
    const [group, users, groupTrainingCourse, contentLearnings, questionFinderFindResult] =
      await Promise.all([
        this.groupFinder.findById(groupId),
        this.userFinder.findTenantUsers(),
        this.groupTrainingFinder.findCourseByGroupIdAndCourseId(groupId, courseId),
        this.contentLearningDataAdapter.findByGroupIdAndCourseId({
          groupId,
          courseId,
        }),
        this.questionFinder.find({ groupId }),
      ]);
    const { questions } = questionFinderFindResult;
    if (!group || !groupTrainingCourse) {
      return [];
    }
    const groupUserIds = group.users.filter((u) => u.role === 'trainee').map((user) => user.id);
    const contentIds = groupTrainingCourse.contents.map((cn) => cn.id);
    const contentLearningsByUser = contentLearnings.reduce<{ [key in UserId]: ContentLearning[] }>(
      (acc, cl) => {
        if (!acc[cl.userId]) {
          acc[cl.userId] = [];
        }
        acc[cl.userId].push(cl);
        return acc;
      },
      {}
    );
    return users
      .filter((u) => groupUserIds.includes(u.id))
      .map((u) => {
        const userContentLearnings = contentLearningsByUser[u.id] ?? [];
        const completedCount = userContentLearnings.filter(
          (cl) => contentIds.includes(cl.contentId) && cl.status === 'completed'
        ).length;
        const usageTime = userContentLearnings.reduce((acc, v) => acc + v.usageTime, 0);
        return {
          userId: u.id,
          userName: u.name,
          progress: toPercentage(
            new BigNumber(completedCount).dividedBy(
              new BigNumber(groupTrainingCourse.contents.length)
            )
          ),
          usageTime,
          questionCount: questions.filter(
            (q) => q.createdBy === u.id && q.referTo && contentIds.includes(q.referTo.contentId)
          ).length,
        };
      });
  }

  async findCourseStatusRecords(
    groupId: GroupId,
    userId: UserId
  ): Promise<Array<CourseStatusRecord>> {
    const [user, groupTrainingCourses, contentLearnings, questionFinderFindResult] =
      await Promise.all([
        this.userFinder.findById(userId),
        this.groupTrainingFinder.findCoursesByGroupId(groupId),
        this.contentLearningDataAdapter.findByGroupIdAndUserId(groupId, userId),
        this.questionFinder.find({ groupId }),
      ]);
    const { questions } = questionFinderFindResult;
    if (!user) {
      return [];
    }

    const allActiveContentIds = groupTrainingCourses.flatMap((cr) =>
      cr.contents.map((cn) => cn.id)
    );
    const contentLearningsByCourse = contentLearnings.reduce<{
      [key in CourseId]: ContentLearning[];
    }>((acc, cl) => {
      if (!acc[cl.courseId]) {
        acc[cl.courseId] = [];
      }
      acc[cl.courseId].push(cl);
      return acc;
    }, {});

    return groupTrainingCourses.map((gtc) => {
      const contentIds = gtc.contents.map((cn) => cn.id);
      const courseContentLearnings = contentLearningsByCourse[gtc.id] ?? [];
      const completedCount = courseContentLearnings.filter(
        (cl) => allActiveContentIds.includes(cl.contentId) && cl.status === 'completed'
      ).length;
      const questionCount = questions.filter(
        (q) => q.createdBy === userId && q.referTo && contentIds.includes(q.referTo.contentId)
      ).length;
      return {
        courseId: gtc.id,
        courseName: gtc.name,
        progress: toPercentage(
          new BigNumber(completedCount).dividedBy(new BigNumber(gtc.contents.length))
        ),
        usageTime: courseContentLearnings.reduce((acc, v) => acc + v.usageTime, 0),
        questionCount,
      };
    });
  }

  async findTraineeRecords(groupId: GroupId): Promise<TrainerUserReportRecords> {
    const [comments, rawContentLearnings, group, groupTrainingCourses] = await Promise.all([
      this.questionFinder.findCommentHeaders({ groupId }),
      this.contentLearningDataAdapter.findByGroupId(groupId),
      this.groupFinder.findById(groupId, { includeRemovedGroupUser: true }),
      this.groupTrainingFinder.findCoursesByGroupId(groupId),
    ]);
    assertEntityExists(group, 'group');
    const enableUsers = this.appContextProvider
      .get()
      .users.filter((u) => u.enabled)
      .map((u) => u.id);
    const trainees = group.users
      .filter((u) => u.role === 'trainee' && enableUsers.includes(u.id))
      .map((u) => {
        return {
          id: u.id,
          removed: u.removed,
        };
      });
    const traineeCount = trainees.length;
    if (traineeCount === 0)
      return {
        records: [],
        courses: [],
        examResults: [],
        courseAverages: {
          courseAverageCommentCount: new BigNumber(0),
          courseAverageUsageTime: new BigNumber(0),
          courseAverageNotBegunContentCount: new BigNumber(0),
          courseAverageInProgressContentCount: new BigNumber(0),
          courseAverageCompletedContentCount: new BigNumber(0),
          courseAverageCorrectAnswerRate: new BigNumber(0),
          courseAverageNotBegunExamCount: new BigNumber(0),
          courseAverageCompletedExamCount: new BigNumber(0),
        },
        groupAverageCommentCount: new BigNumber(0),
        groupAverageUsageTime: new BigNumber(0),
        groupAverageNotBegunCourseCount: new BigNumber(0),
        groupAverageInProgressCourseCount: new BigNumber(0),
        groupAverageCompletedCourseCount: new BigNumber(0),
        groupAverageCourseProgressRate: new BigNumber(0),
        groupAverageCorrectAnswerRate: new BigNumber(0),
        groupAverageNotBegunExamCount: new BigNumber(0),
        groupAverageCompletedExamCount: new BigNumber(0),
      };
    const contentLearnings = toContentCourseMap(rawContentLearnings, groupTrainingCourses);
    const records = await Promise.all(
      trainees.map(async (trainee) => {
        const examResults = await this.examDataAdapter.findExamResults({
          groupId,
          userId: trainee.id,
        });
        // このユーザーが使用開始した日時
        const startOfUse = rawContentLearnings
          .filter((cl) => cl.userId === trainee.id)
          .reduce<Optional<LocalDate>>(
            (acc, v) => (!acc || v.startedAt.isBefore(acc) ? v.startedAt : acc),
            undefined
          );
        // このユーザーが直近で利用した日時
        const recentUsedAt = rawContentLearnings
          .filter((cl) => cl.userId === trainee.id)
          .reduce<Optional<LocalDate>>(
            (acc, v) =>
              !acc || (v.lastLearnedAt && v.lastLearnedAt.isAfter(acc)) ? v.lastLearnedAt : acc,
            undefined
          );
        const courseLearnings: Map<
          CourseId,
          {
            usageTime: Minute;
            completedContentCount: number;
            inProgressContentCount: number;
            startOfUse: Optional<LocalDateTime>;
            recentUsedAt: Optional<LocalDateTime>;
            groupUsageTime: number;
          }
        > = contentLearnings.reduce((acc, v) => {
          const e = acc.get(v.courseId) ?? {
            usageTime: 0,
            completedContentCount: 0,
            inProgressContentCount: 0,
            startOfUse: undefined,
            recentUsedAt: undefined,
            groupUsageTime: 0,
          };
          if (v.userId === trainee.id) {
            e.usageTime += v.usageTime;
            e.completedContentCount =
              v.status === 'completed' ? e.completedContentCount + 1 : e.completedContentCount;
            e.inProgressContentCount =
              v.status === 'in_progress' ? e.inProgressContentCount + 1 : e.inProgressContentCount;
            e.startOfUse =
              !e.startOfUse || v.startedAt.isBefore(e.startOfUse) ? v.startedAt : e.startOfUse;
            e.recentUsedAt =
              !e.recentUsedAt || (v.lastLearnedAt && v.lastLearnedAt.isAfter(e.recentUsedAt))
                ? v.lastLearnedAt
                : e.recentUsedAt;
          }
          if (trainees.map((t) => t.id).includes(v.userId)) {
            // 現在グループに含まれるユーザーのみ計算に含める
            e.groupUsageTime += v.usageTime;
          }
          return acc.set(v.courseId, e);
        }, new Map());
        const commentsGroupedByCourse: Map<
          CourseId,
          { commentCount: number; userCommentCount: number }
        > = comments.reduce((acc, v) => {
          if (!v.contentId) {
            return acc;
          }
          const courseId = contentLearnings.find((c) => c.contentId === v.contentId)?.courseId;
          if (!courseId) {
            return acc;
          }
          let e = acc.get(courseId) ?? { commentCount: 0, userCommentCount: 0 };
          if (v.createdBy === trainee.id) {
            e = { commentCount: e.commentCount, userCommentCount: e.userCommentCount + 1 };
          }
          // 現在グループに所属するユーザーのみ件数に含める
          if (trainees.map((t) => t.id).includes(v.createdBy)) {
            e = { commentCount: e.commentCount + 1, userCommentCount: e.userCommentCount };
          }
          acc.set(courseId, e);
          return acc;
        }, new Map());
        const examResultsGroupedByCourse = toExamResultMap(examResults);
        const courses: Array<UserCourseReport> = groupTrainingCourses.map((cr) => {
          const examCount = cr.contents.filter((c) => c.type === 'exam').length;
          const courseLearning = courseLearnings.get(cr.id) ?? {
            usageTime: 0,
            completedContentCount: 0,
            inProgressContentCount: 0,
            startOfUse: undefined,
            recentUsedAt: undefined,
            groupUsageTime: 0,
          };
          const courseComments = commentsGroupedByCourse.get(cr.id) ?? {
            commentCount: 0,
            userCommentCount: 0,
          };
          const ers = examResultsGroupedByCourse.get(cr.id) ?? [];
          const correctAnswerCount = ers
            .map((er) => er.answers.filter((a) => a.correct).length)
            .reduce((acc, v) => acc + v, 0);
          const examProblemCount = ers.map((er) => er.problemCount).reduce((acc, v) => acc + v, 0);
          const correctAnswerRate = rate(
            new BigNumber(correctAnswerCount),
            new BigNumber(examProblemCount)
          );

          return {
            id: cr.id,
            name: cr.displayName,
            startOfUse: courseLearning.startOfUse,
            recentUsedAt: courseLearning.recentUsedAt,
            usageTime: courseLearning.usageTime,
            groupUsageTime: courseLearning.groupUsageTime,
            groupAverageUsageTime: average(
              new BigNumber(courseLearning.groupUsageTime),
              new BigNumber(traineeCount)
            ),
            commentCount: courseComments.userCommentCount,
            groupAverageCommentCount: average(
              new BigNumber(courseComments.commentCount),
              new BigNumber(traineeCount)
            ),
            contentCount: cr.contents.length,
            notBegunContentCount:
              cr.contents.length -
              courseLearning.completedContentCount -
              courseLearning.inProgressContentCount,
            inProgressContentCount: courseLearning.inProgressContentCount,
            completedContentCount: courseLearning.completedContentCount,
            progressRate: rate(
              new BigNumber(courseLearning.completedContentCount),
              new BigNumber(cr.contents.length)
            ),
            completedExamCount: uniqueArray(ers.map((er) => er.contentId)).length,
            notBegunExamCount: examCount - uniqueArray(ers.map((er) => er.contentId)).length,
            correctAnswerCount,
            examProblemCount,
            correctAnswerRate,
          };
        });
        const notBegunCourseCount = courses.filter(
          (cr) => cr.notBegunContentCount === cr.contentCount
        ).length;
        const completedCourseCount = courses.filter(
          (cr) => cr.completedContentCount === cr.contentCount
        ).length;
        const inProgressCourseCount = courses.length - notBegunCourseCount - completedCourseCount;
        const courseProgressRate = rate(
          new BigNumber(completedCourseCount),
          new BigNumber(courses.length)
        );
        const commentCount = Array.from(commentsGroupedByCourse.values())
          .map((cm) => cm.userCommentCount)
          .reduce((acc, v) => acc + v, 0);
        const notBegunContentCount = courses
          .map((cr) => cr.notBegunContentCount)
          .reduce((acc, v) => acc + v, 0);
        const inProgressContentCount = courses
          .map((cr) => cr.inProgressContentCount)
          .reduce((acc, v) => acc + v, 0);
        const completedContentCount = courses
          .map((cr) => cr.completedContentCount)
          .reduce((acc, v) => acc + v, 0);
        const notBegunExamCount = courses
          .map((cr) => cr.notBegunExamCount)
          .reduce((acc, v) => acc + v, 0);
        const completedExamCount = examResults.length;
        const correctAnswerCount = courses
          .map((cr) => cr.correctAnswerCount)
          .reduce((acc, v) => acc + v, 0);
        const examProblemCount = courses
          .map((cr) => cr.examProblemCount)
          .reduce((acc, v) => acc + v, 0);
        const correctAnswerRate = rate(
          new BigNumber(correctAnswerCount),
          new BigNumber(examProblemCount)
        );
        const usageTime = courses.map((cr) => cr.usageTime).reduce((acc, v) => acc + v, 0);
        const user = await this.userFinder.findById(trainee.id);
        assertEntityExists(user, 'user');
        return {
          userId: trainee.id,
          userName: user.name,
          startOfUse,
          recentUsedAt,
          usageTime,
          notBegunContentCount,
          inProgressContentCount,
          completedContentCount,
          notBegunCourseCount,
          inProgressCourseCount,
          completedCourseCount,
          courseProgressRate,
          commentCount,
          notBegunExamCount,
          completedExamCount,
          correctAnswerCount,
          examProblemCount,
          correctAnswerRate,
          courses,
          examResults,
          removed: trainee.removed ?? false,
        };
      })
    );
    const groupUserCourses = records.map((r) => r.courses).flat();
    const groupCoursesMap: Map<
      CourseId,
      {
        courseId: CourseId;
        courseName: CourseDisplayName;
        startOfUse: Optional<LocalDateTime>;
        recentUsedAt: Optional<LocalDateTime>;
        groupUsageTime: number;
        commentCount: number;
        notBegunContentCount: number;
        completedContentCount: number;
        inProgressContentCount: number;
        notBegunExamCount: number;
        completedExamCount: number;
        correctAnswerRate: number;
      }
    > = groupUserCourses.reduce((acc, v) => {
      const e = acc.get(v.id) ?? {
        courseId: v.id,
        courseName: v.name,
        notBegunContentCount: 0,
        completedContentCount: 0,
        inProgressContentCount: 0,
        notBegunExamCount: 0,
        completedExamCount: 0,
        correctAnswerRate: 0,
        startOfUse: undefined,
        recentUsedAt: 0,
        groupUsageTime: v.groupUsageTime,
        commentCount: 0,
      };
      if (v.id === e.courseId) {
        e.completedContentCount += v.completedContentCount;
        e.inProgressContentCount += v.inProgressContentCount;
        e.notBegunContentCount += v.notBegunContentCount;
        e.notBegunExamCount += v.notBegunExamCount;
        e.completedExamCount += v.completedExamCount;
        e.startOfUse =
          v.startOfUse && v.startOfUse.isBefore(e.startOfUse) ? v.startOfUse : e.startOfUse;
        e.recentUsedAt =
          v.recentUsedAt && v.recentUsedAt.isAfter(e.recentUsedAt)
            ? v.recentUsedAt
            : e.recentUsedAt;
        e.commentCount += v.commentCount;
        e.correctAnswerRate += Number(v.correctAnswerRate);
      }
      return acc.set(v.id, e);
    }, new Map());
    const groupCourses = Array.from(groupCoursesMap.values()).map((c) => {
      return {
        courseId: c.courseId,
        courseName: c.courseName,
        startOfUse: c.startOfUse ?? undefined,
        recentUsedAt: c.recentUsedAt ?? undefined,
        groupUsageTime: average(new BigNumber(c.groupUsageTime), new BigNumber(traineeCount)),
        commentCount: average(new BigNumber(c.commentCount), new BigNumber(traineeCount)),
        notBegunContentCount: average(
          new BigNumber(c.notBegunContentCount),
          new BigNumber(traineeCount)
        ),
        completedContentCount: average(
          new BigNumber(c.completedContentCount),
          new BigNumber(traineeCount)
        ),
        inProgressContentCount: average(
          new BigNumber(c.inProgressContentCount),
          new BigNumber(traineeCount)
        ),
        notBegunExamCount: average(new BigNumber(c.notBegunExamCount), new BigNumber(traineeCount)),
        completedExamCount: average(
          new BigNumber(c.completedExamCount),
          new BigNumber(traineeCount)
        ),
        correctAnswerRate: average(
          new BigNumber(c.correctAnswerRate),
          new BigNumber(c.completedExamCount)
        ),
      };
    });

    const groupExamResults = records.map((r) => r.examResults).flat();
    const groupExams = await this.examDataAdapter.findGroupExamsByGroupId(groupId);
    const examResults = groupExams.map((ge) => {
      const examRecords = groupExamResults.filter(
        (g) =>
          (g.version === 2 || g.version === 3) &&
          g.contentId === ge.content.id &&
          g.times === ge.times
      );
      const correctAnswerCount = examRecords
        .map((c) => c.answers.filter((a) => a.correct).length)
        .reduce((acc, v) => acc + v, 0);
      const examProblemCount = examRecords
        .map((c) => c.problemCount)
        .reduce((acc, v) => acc + v, 0);
      const passingStandard =
        examRecords[0]?.version === 3 ? examRecords[0]?.passingStandard : undefined;
      const passedUserCount = ifDefined(
        passingStandard,
        (ps) => examRecords.filter((er) => er.score >= ps).length
      );
      const e = {
        contentId: ge.content.id,
        courseName: ge.course.name,
        examName: ge.content.name,
        times: ge.times,
        correctAnswerRate: rate(new BigNumber(correctAnswerCount), new BigNumber(examProblemCount)),
        examCount: examRecords.length,
        passingStandard,
        passedUserCount,
      };
      return e;
    });

    // コースごとの平均利用状況
    const courseStartOfUse = groupCourses.filter((c) => c.startOfUse);
    const courseStartOfUseRecords = courseStartOfUse
      .map((c) => Number(c.startOfUse))
      .reduce((acc, v) => acc + v, 0);
    const courseAverageStartOfUse = !courseStartOfUseRecords
      ? undefined
      : moment(
          Number(
            average(new BigNumber(courseStartOfUseRecords), new BigNumber(courseStartOfUse.length))
          )
        );
    const courseRecentUsedAt = groupCourses.filter((rc) => rc.recentUsedAt);
    const courseRecentUsedAtRecords = courseRecentUsedAt
      .map((r) => Number(r.recentUsedAt))
      .reduce((acc, v) => acc + v, 0);
    const courseAverageRecentUsedAt = !courseRecentUsedAtRecords
      ? undefined
      : moment(
          Number(
            average(
              new BigNumber(courseRecentUsedAtRecords),
              new BigNumber(courseRecentUsedAt.length)
            )
          )
        );
    const courseUsageTime = groupCourses
      .map((c) => Number(c.groupUsageTime))
      .reduce((acc, v) => acc + v, 0);
    const courseAverageUsageTime = average(
      new BigNumber(courseUsageTime),
      new BigNumber(groupCourses.length)
    );
    const courseAverageCommentCount = average(
      new BigNumber(groupCourses.map((c) => Number(c.commentCount)).reduce((acc, v) => acc + v, 0)),
      new BigNumber(groupCourses.length)
    );
    const courseAverageNotBegunContentCount = average(
      new BigNumber(
        groupCourses.map((c) => Number(c.notBegunContentCount)).reduce((acc, v) => acc + v, 0)
      ),
      new BigNumber(groupCourses.length)
    );
    const courseAverageInProgressContentCount = average(
      new BigNumber(
        groupCourses.map((c) => Number(c.inProgressContentCount)).reduce((acc, v) => acc + v, 0)
      ),
      new BigNumber(groupCourses.length)
    );
    const courseAverageCompletedContentCount = average(
      new BigNumber(
        groupCourses.map((c) => Number(c.completedContentCount)).reduce((acc, v) => acc + v, 0)
      ),
      new BigNumber(groupCourses.length)
    );
    const courseCorrectAnswerRates = groupExams.map((ge) => {
      const examRecord = groupExamResults.filter((g) => g.courseId === ge.course.id);
      const correctAnswerCount = examRecord
        .map((e) => e.answers.filter((a) => a.correct).length)
        .reduce((acc, v) => acc + v, 0);
      const problemCount = examRecord.map((e) => e.problemCount).reduce((acc, v) => acc + v, 0);
      return rate(new BigNumber(correctAnswerCount), new BigNumber(problemCount));
    });
    const courseAverageCorrectAnswerRate = average(
      new BigNumber(courseCorrectAnswerRates.map((c) => Number(c)).reduce((acc, v) => acc + v, 0)),
      new BigNumber(courseCorrectAnswerRates.length)
    );
    const courseAverageNotBegunExamCount = average(
      new BigNumber(
        groupCourses.map((c) => Number(c.notBegunExamCount)).reduce((acc, v) => acc + v, 0)
      ),
      new BigNumber(groupCourses.length)
    );
    const courseAverageCompletedExamCount = average(
      new BigNumber(
        groupCourses.map((c) => Number(c.completedExamCount)).reduce((acc, v) => acc + v, 0)
      ),
      new BigNumber(groupCourses.length)
    );

    // グループごとの平均利用状況
    const groupAverageCommentCount = average(
      new BigNumber(
        Array.from(records.values())
          .map((r) => r.commentCount)
          .reduce((acc, v) => acc + v, 0)
      ),
      new BigNumber(traineeCount)
    );
    const totalGroupUsageTime = records[0].courses
      .map((cr) => cr.groupUsageTime)
      .reduce((acc, v) => acc + v, 0);
    const groupAverageUsageTime = average(
      new BigNumber(totalGroupUsageTime),
      new BigNumber(traineeCount)
    );
    const groupAverageNotBegunCourseCount = average(
      new BigNumber(
        Array.from(records.values())
          .map((r) => r.notBegunCourseCount)
          .reduce((acc, v) => acc + v, 0)
      ),
      new BigNumber(traineeCount)
    );
    const groupAverageInProgressCourseCount = average(
      new BigNumber(
        Array.from(records.values())
          .map((r) => r.inProgressCourseCount)
          .reduce((acc, v) => acc + v, 0)
      ),
      new BigNumber(traineeCount)
    );
    const groupAverageCompletedCourseCount = average(
      new BigNumber(
        Array.from(records.values())
          .map((r) => r.completedCourseCount)
          .reduce((acc, v) => acc + v, 0)
      ),
      new BigNumber(traineeCount)
    );
    const groupAverageCourseProgressRate = average(
      rate(
        new BigNumber(records.map((r) => r.completedCourseCount).reduce((acc, v) => acc + v, 0)),
        new BigNumber(records[0].courses.length)
      ),
      new BigNumber(traineeCount)
    );
    const traineeExamsCount = records.filter((r) => r.completedExamCount > 0).length;
    const groupAverageCorrectAnswerRate = average(
      new BigNumber(records.map((r) => Number(r.correctAnswerRate)).reduce((acc, v) => acc + v, 0)),
      new BigNumber(traineeExamsCount)
    );
    const groupAverageCompletedExamCount = average(
      new BigNumber(
        Array.from(records.values())
          .map((r) => r.completedExamCount)
          .reduce((acc, v) => acc + v, 0)
      ),
      new BigNumber(traineeCount)
    );
    const groupAverageNotBegunExamCount = average(
      new BigNumber(
        Array.from(records.values())
          .map((r) => r.notBegunExamCount)
          .reduce((acc, v) => acc + v, 0)
      ),
      new BigNumber(traineeCount)
    );
    const startOfUseCount = records.filter((rc) => rc.startOfUse).length;
    const startOfUseRecords = records
      .map((r) => (r.startOfUse ? r.startOfUse.valueOf() : undefined))
      .reduce((acc, v) => (acc ?? 0) + (v ?? 0), 0);
    const groupAverageStartOfUse = !startOfUseRecords
      ? undefined
      : moment(Number(average(new BigNumber(startOfUseRecords), new BigNumber(startOfUseCount))));
    const recentUsedAtCount = records.filter((rc) => rc.recentUsedAt).length;
    const recentUsedAtRecords = records
      .map((r) => (r.recentUsedAt ? r.recentUsedAt.valueOf() : undefined))
      .reduce((acc, v) => (acc ?? 0) + (v ?? 0), 0);
    const groupAverageRecentUsedAt = !recentUsedAtRecords
      ? undefined
      : moment(
          Number(average(new BigNumber(recentUsedAtRecords), new BigNumber(recentUsedAtCount)))
        );
    return {
      records: records.filter((r) => !r.removed),
      courses: groupCourses,
      examResults,
      courseAverages: {
        courseAverageStartOfUse,
        courseAverageRecentUsedAt,
        courseAverageUsageTime,
        courseAverageCommentCount,
        courseAverageNotBegunContentCount,
        courseAverageInProgressContentCount,
        courseAverageCompletedContentCount,
        courseAverageCorrectAnswerRate,
        courseAverageNotBegunExamCount,
        courseAverageCompletedExamCount,
      },
      groupAverageStartOfUse,
      groupAverageRecentUsedAt,
      groupAverageCommentCount,
      groupAverageUsageTime,
      groupAverageNotBegunCourseCount,
      groupAverageInProgressCourseCount,
      groupAverageCompletedCourseCount,
      groupAverageCourseProgressRate,
      groupAverageCorrectAnswerRate,
      groupAverageNotBegunExamCount,
      groupAverageCompletedExamCount,
    };
  }

  async findUserCourseProgresses(
    groupId: GroupId,
    userId: UserId
  ): Promise<UserCourseProgresses[]> {
    const [rawContentLearnings, groupTrainingCourses] = await Promise.all([
      this.contentLearningDataAdapter.findByGroupId(groupId),
      this.groupTrainingFinder.findCoursesByGroupId(groupId),
    ]);
    const contentLearnings = toContentCourseMap(rawContentLearnings, groupTrainingCourses);
    const courseLearnings: Map<
      CourseId,
      {
        completedContentCount: number;
        inProgressContentCount: number;
      }
    > = contentLearnings.reduce((acc, v) => {
      const e = acc.get(v.courseId) ?? {
        completedContentCount: 0,
        inProgressContentCount: 0,
      };
      if (v.userId === userId) {
        e.completedContentCount =
          v.status === 'completed' ? e.completedContentCount + 1 : e.completedContentCount;
        e.inProgressContentCount =
          v.status === 'in_progress' ? e.inProgressContentCount + 1 : e.inProgressContentCount;
      }
      return acc.set(v.courseId, e);
    }, new Map());
    const courses: Array<UserCourseProgresses> = groupTrainingCourses.map((cr) => {
      const courseLearning = courseLearnings.get(cr.id) ?? {
        completedContentCount: 0,
        inProgressContentCount: 0,
      };
      return {
        id: cr.id,
        name: cr.displayName,
        contentCount: cr.contents.length,
        notBegunContentCount:
          cr.contents.length -
          courseLearning.completedContentCount -
          courseLearning.inProgressContentCount,
        inProgressContentCount: courseLearning.inProgressContentCount,
        completedContentCount: courseLearning.completedContentCount,
        progressRate: rate(
          new BigNumber(courseLearning.completedContentCount),
          new BigNumber(cr.contents.length)
        ),
      };
    });
    return courses;
  }

  async findUserExamCorrections(groupId: GroupId, userId: UserId): Promise<UserExamCorrections[]> {
    const [groupTrainingCourses, exams, groupExams] = await Promise.all([
      this.groupTrainingFinder.findCoursesByGroupId(groupId),
      this.examDataAdapter.findExamResults({ groupId, userId }),
      this.examDataAdapter.findGroupExamsByGroupId(groupId),
    ]);
    const examResultsGroupedByCourse = toExamResultMap(exams);
    const examResults = groupTrainingCourses.map((cr) => {
      const ers = examResultsGroupedByCourse.get(cr.id) ?? [];
      const res: Array<UserExamCorrections> = cr.contents
        .filter((cn) => cn.type === 'exam' && groupExams.find((g) => g.content.id === cn.id))
        .map((c) => {
          const times = groupExams.find((g) => g.content.id === c.id)?.times;
          assertEntityExists(times, 'times');
          const correctAnswerCount = ers
            .filter((e) => e.contentId === c.id)
            .map((er) => er.answers.filter((a) => a.correct).length)
            .reduce((acc, v) => acc + v, 0);
          const examProblemCount = ers
            .filter((e) => e.contentId === c.id)
            .map((er) => er.problemCount)
            .reduce((acc, v) => acc + v, 0);
          const correctAnswerRate = rate(
            new BigNumber(correctAnswerCount),
            new BigNumber(examProblemCount)
          );
          return {
            id: c.id,
            name: cr.displayName,
            examName: c.name,
            times,
            correctAnswerRate,
          };
        });
      return res;
    });
    return examResults.flat();
  }

  async findGroupCourseProgresses(
    groupId: string,
    courseId: string
  ): Promise<GroupCourseProgresses[]> {
    const [rawContentLearnings, groupTrainingCourses, group] = await Promise.all([
      this.contentLearningDataAdapter
        .findByGroupId(groupId)
        .then((c) => c.filter((cn) => cn.courseId === courseId)),
      this.groupTrainingFinder
        .findCoursesByGroupId(groupId)
        .then((c) => c.filter((gt) => gt.id === courseId)),
      this.groupFinder.findById(groupId),
    ]);
    assertEntityExists(group, 'group');
    const enableUsers = this.appContextProvider
      .get()
      .users.filter((u) => u.enabled)
      .map((u) => u.id);
    const traineeIds = group.users
      .filter((u) => u.role === 'trainee' && enableUsers.includes(u.id))
      .map((u) => u.id);
    const contentToCourse = new Map(
      groupTrainingCourses.flatMap((cr) => cr.contents.map((cn) => [cn.id, cr.id]))
    );
    const contentLearnings = rawContentLearnings.filter(
      (cl) => !!contentToCourse.get(cl.contentId)
    );
    const reports = await Promise.all(
      traineeIds.map(async (userId) => {
        const user = await this.userFinder.findById(userId);
        assertEntityExists(user, 'user');
        const courseLearnings: Map<
          CourseId,
          {
            userId: UserId;
            userName: UserName;
            completedContentCount: number;
            inProgressContentCount: number;
            contentCount: number;
          }
        > = contentLearnings.reduce((acc, v) => {
          const e = acc.get(v.courseId) ?? {
            userId,
            userName: user.name,
            completedContentCount: 0,
            inProgressContentCount: 0,
            contentCount: 0,
          };
          if (v.userId === userId) {
            e.completedContentCount =
              v.status === 'completed' ? e.completedContentCount + 1 : e.completedContentCount;
            e.inProgressContentCount =
              v.status === 'in_progress' ? e.inProgressContentCount + 1 : e.inProgressContentCount;
            e.contentCount += 1;
          }
          return acc.set(v.courseId, e);
        }, new Map());
        const contentProgress = groupTrainingCourses.map((cr) => {
          const courseLearning = courseLearnings.get(cr.id) ?? {
            userId,
            userName: user.name,
            completedContentCount: 0,
            inProgressContentCount: 0,
            notBegunContentCount: 0,
            contentCount: 0,
          };
          return {
            userId: courseLearning.userId,
            userName: courseLearning.userName,
            notBegunContentCount:
              cr.contents.length -
              courseLearning.inProgressContentCount -
              courseLearning.completedContentCount,
            inProgressContentCount: courseLearning.inProgressContentCount,
            completedContentCount: courseLearning.completedContentCount,
          };
        });
        const notBegunContentCount = contentProgress
          .map((c) => c.notBegunContentCount)
          .reduce((acc, v) => acc + v, 0);
        const inProgressContentCount = contentProgress
          .map((c) => c.inProgressContentCount)
          .reduce((acc, v) => acc + v, 0);
        const completedContentCount = contentProgress
          .map((c) => c.completedContentCount)
          .reduce((acc, v) => acc + v, 0);
        const progressRate = rate(
          new BigNumber(completedContentCount),
          new BigNumber(notBegunContentCount + inProgressContentCount + completedContentCount)
        );
        return {
          userId,
          userName: contentProgress[0].userName,
          notBegunContentCount,
          inProgressContentCount,
          completedContentCount,
          progressRate,
        };
      })
    );
    return reports.flat();
  }

  async getGroupExamCorrections(
    groupId: GroupId,
    contentId: ContentId,
    times: number
  ): Promise<GroupExamCorrections> {
    const groupExamId = createGroupExamId({
      groupId,
      contentId,
      times,
    });
    const [groupExam, examResults] = await Promise.all([
      this.examDataAdapter.findGroupExamById(groupExamId),
      this.examDataAdapter.findExamResultsByGroupExamId(groupExamId),
    ]);
    assertEntityExists(groupExam, 'groupExam');
    const userNameById = (() => {
      const appContext = this.appContextProvider.get();
      const userNames = new Map(appContext.users.map((u) => [u.id, u.name]));
      return (userId: UserId) => userNames.get(userId) ?? '';
    })();
    const examResultsByUserId = (() => {
      const examResultsMap = new Map(examResults.map((er) => [er.userId, er]));
      return (userId: UserId) => examResultsMap.get(userId);
    })();
    // 旧データではgroupExam.userIdsToBeTestedが空なので、userExamsで代替する
    const userIds =
      groupExam.userIdsToBeTested.length === 0
        ? groupExam.userExams.map((ue) => ue.userId)
        : groupExam.userIdsToBeTested;
    const reports = await Promise.all(
      userIds.map(async (userId) => {
        const userName = userNameById(userId);
        const exam = examResultsByUserId(userId);
        if (!exam) {
          return {
            userId,
            userName,
            correctAnswerRate: undefined,
          };
        }
        const correctAnswerCount = exam.answers.filter((e) => e.correct).length;
        const correctAnswerRate = rate(
          new BigNumber(correctAnswerCount),
          new BigNumber(exam.problemCount)
        );
        if (exam.version === 1 || exam.version === 2) {
          return {
            userId,
            userName,
            correctAnswerRate,
          };
        }
        return {
          userId,
          userName,
          correctAnswerRate,
          isPassed: ifDefined(
            exam.passingStandard,
            (passingStandard) => correctAnswerCount >= passingStandard
          ),
        };
      })
    );

    return {
      groupExamId: groupExam.id,
      courseName: groupExam.course.name,
      contentName: groupExam.content.name,
      userCorrectAnswerRateList: reports.flat(),
      passingStandardRate: ifDefined(groupExam.passingStandard, (ps) =>
        rate(new BigNumber(ps), new BigNumber(groupExam.content.problems.length))
      ),
    };
  }
}
