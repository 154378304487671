import { CreateGroupTrainingInput, UpdateGroupTrainingInput } from '@/API';
import { AppContextProvider, EntityData, GroupId, GroupTrainingId, hasId } from '@/base/domains';
import { Optional } from '@/base/types';
import * as mutations from '@/graphql/mutations';
import * as queries from '@/graphql/queries';
import {
  GroupTrainingAttributes,
  GroupTrainingEntity,
  GroupTrainingEntityImpl,
  GroupTrainingRepository,
} from '@/training/domains';
import { graphql, graphqlQuery } from '@/utils/AmplifyUtils';
import { requiredNonNull } from '@/utils/TsUtils';

type AmplifyGroupTraining = {
  id: GroupTrainingId;
  groupId: GroupId;
};

function toGroupTraining(e: AmplifyGroupTraining): GroupTrainingEntity {
  return new GroupTrainingEntityImpl(e);
}

export class AmplifyGroupTrainingRepository implements GroupTrainingRepository {
  private appContextProvider: AppContextProvider;

  constructor(appContextProvider: AppContextProvider) {
    this.appContextProvider = appContextProvider;
  }

  async save(
    args: GroupTrainingEntity | EntityData<string, GroupTrainingAttributes>
  ): Promise<GroupTrainingEntity> {
    const tenantCode = requiredNonNull(
      this.appContextProvider.get().tenantCode,
      'appContext.tenantCode'
    );
    if (hasId(args)) {
      const input: UpdateGroupTrainingInput = {
        id: args.id,
        groupId: args.groupId,
        tenantCode,
      };
      const response = await graphql<{ createGroupTrainingEntity: AmplifyGroupTraining }>(
        mutations.updateGroupTraining,
        { input }
      );
      return toGroupTraining(response.createGroupTrainingEntity);
    }
    const input: CreateGroupTrainingInput = {
      groupId: args.groupId,
      tenantCode,
    };
    const response = await graphql<{ createGroupTrainingEntity: AmplifyGroupTraining }>(
      mutations.createGroupTraining,
      { input }
    );
    return toGroupTraining(response.createGroupTrainingEntity);
  }

  async findById(id: GroupTrainingId): Promise<Optional<GroupTrainingEntity>> {
    const response = await graphql<{ getGroupTraining: Optional<AmplifyGroupTraining> }>(
      queries.getGroupTraining,
      { id }
    );
    return response.getGroupTraining ? toGroupTraining(response.getGroupTraining) : undefined;
  }

  async remove(id: GroupTrainingId): Promise<void> {
    await graphql(mutations.deleteGroupTraining, { input: { id } });
  }

  async findByGroupId(groupId: GroupId): Promise<Optional<GroupTrainingEntity>> {
    const res = await graphql<{ groupTrainingByGroupId: { items: Array<AmplifyGroupTraining> } }>(
      queries.groupTrainingByGroupId,
      { groupId }
    );
    const groupTraining = res?.groupTrainingByGroupId?.items[0];
    return groupTraining ? toGroupTraining(groupTraining) : undefined;
  }

  async findTenantGroupTrainings(): Promise<GroupTrainingEntity[]> {
    const tenantCode = requiredNonNull(
      this.appContextProvider.get().tenantCode,
      'appContext.tenantCode'
    );
    const res = await graphqlQuery<{
      groupTrainingsByTenantCode: { items: Array<AmplifyGroupTraining> };
    }>(
      queries.groupTrainingsByTenantCode,
      {
        tenantCode,
      },
      {
        limit: 1000,
      }
    );
    return res.groupTrainingsByTenantCode.items.map(toGroupTraining);
  }
}
