import { hasNonNullProperty } from '@/utils/TsUtils';

import { LocalDateTime, MarkDownString } from '../types';
import { ContentId, ContentVersion, ProblemIndex } from './content';
import { UserId } from './Core';
import { GroupId } from './Group';
import { GroupRole } from './User';

export type MemoId = string;
export type MemoScope = { type: 'private' } | { type: 'group'; roles: GroupRole[] };

export type SimpleMemoContentReference = {
  contentId: ContentId;
  contentVersion: ContentVersion;
};

export type TextMemoContentReference = SimpleMemoContentReference & {
  selection: object;
};

export type MemoWorkbookReference = {
  contentId: ContentId;
  contentVersion: ContentVersion;
  problemIndex: ProblemIndex;
  selection?: object;
};

export type MemoContentReference =
  | SimpleMemoContentReference
  | TextMemoContentReference
  | MemoWorkbookReference;

export function isMemoWorkbookReference(mcr: MemoContentReference): mcr is MemoWorkbookReference {
  return hasNonNullProperty(mcr, 'problemIndex');
}

export function isTextMemoContentReference(
  mcr: MemoContentReference
): mcr is TextMemoContentReference {
  return hasNonNullProperty(mcr, 'selection') && !hasNonNullProperty(mcr, 'problemIndex');
}

export function isSimpleMemoContentReference(
  mcr: MemoContentReference
): mcr is SimpleMemoContentReference {
  return !isTextMemoContentReference(mcr);
}

export type Memo = {
  readonly id: MemoId;
  readonly body: MarkDownString;
  readonly referTo?: MemoContentReference;
  readonly scope: MemoScope;
  readonly groupId: GroupId;
  readonly createdBy: UserId;
  readonly createdAt: LocalDateTime;
  readonly updatedBy: UserId;
  readonly updatedAt: LocalDateTime;
};
