import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { MarkDownString, Optional } from '../types';

/**
 * 利用規約ID {{日付(yyyyMMdd)}}-{{連番(ゼロ埋め2桁)}}
 */
export type TermsOfServiceId = string;

/**
 * 利用規約
 */
export type TermsOfService = {
  id: TermsOfServiceId;
  body: MarkDownString;
};

export type TermsOfServiceDataAdapter = {
  findLatest(): Promise<Optional<TermsOfService>>;
};

export const TermsOfServiceDataAdapterKey = injectionKeyOf<TermsOfServiceDataAdapter>({
  boundedContext: 'base',
  type: 'adapter',
  name: 'TermsOfServiceDataAdapter',
});

export function useTermsOfServiceDataAdapter(): TermsOfServiceDataAdapter {
  return requiredInject(TermsOfServiceDataAdapterKey);
}
