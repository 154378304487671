import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { Entity, EntityData, EntityReference, StandardRepository } from './Entity';

/**
 * ユーザータグID
 */
export type UserTagId = string;

export type UserTagColor = string;

/**
 * ユーザータグ属性
 */
export interface UserTagAttributes {
  text: string;
  color: UserTagColor;
}

/**
 * ユーザータグデータ
 */
export type UserTagData = EntityData<UserTagId, UserTagAttributes>;

/**
 * ユーザータグコマンド
 */
export interface UserTagCommand {
  change(args: { text: string; color: UserTagColor }): UserTagEntity;
}

/**
 * ユーザータグクエリ
 */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UserTagQueries {}

/**
 * ユーザータグリファレンス
 */
export type UserTagReference = EntityReference<UserTagId, UserTagAttributes, UserTagQueries>;

/**
 * ユーザータグエンティティ
 */
export interface UserTagEntity
  extends Entity<UserTagId, UserTagAttributes>,
    UserTagQueries,
    UserTagCommand {}

/**
 * ユーザータグリポジトリ
 */
export interface UserTagRepository
  extends StandardRepository<UserTagId, UserTagAttributes, UserTagEntity> {
  findTenantUserTags(): Promise<Array<UserTagReference>>;
}

export const UserTagRepositoryKey = injectionKeyOf<UserTagRepository>({
  boundedContext: 'base',
  type: 'adapter',
  name: 'UserTagRepositoryKey',
});

export function useUserTagRepository(): UserTagRepository {
  return requiredInject(UserTagRepositoryKey);
}
