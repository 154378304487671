import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import {
  AuthorizationService,
  Locale,
  localEventBus,
  User,
  UserId,
  UserRepository,
} from '../domains';
import { userLocaleChanged } from '../domains/LocalEvents';
import { AbstractUseCase, UseCase, UseCaseResponse } from './UseCase';
import { assertEntityExists } from './UseCaseAsserts';

export interface ChangeUserLocaleRequest {
  id: UserId;
  locale: Locale;
}

export type ChangeUserLocaleResponse = {
  user: User;
};

export interface ChangeUserLocale
  extends UseCase<ChangeUserLocaleRequest, ChangeUserLocaleResponse> {
  execute(request: ChangeUserLocaleRequest): Promise<UseCaseResponse<ChangeUserLocaleResponse>>;
}

export class ChangeUserLocaleImpl
  extends AbstractUseCase<ChangeUserLocaleRequest, ChangeUserLocaleResponse>
  implements ChangeUserLocale
{
  private authorizationService: AuthorizationService;

  private userRepository: UserRepository;

  constructor(authorizationService: AuthorizationService, userRepository: UserRepository) {
    super('base.ChangeUserLocale');
    this.authorizationService = authorizationService;
    this.userRepository = userRepository;
  }

  async internalExecute(request: ChangeUserLocaleRequest): Promise<ChangeUserLocaleResponse> {
    const { id, locale } = request;
    this.authorizationService.assertOwnerAccessible(id);
    const user = await this.userRepository.findById(id);
    assertEntityExists(user, 'user');
    const saved = await this.userRepository.save(user.changeLocale(locale));
    localEventBus.publish(userLocaleChanged({ userId: user.id }));
    return {
      user: saved,
    };
  }
}

export const ChangeUserLocaleKey = injectionKeyOf<ChangeUserLocale>({
  boundedContext: 'base',
  type: 'usecase',
  name: 'ChangeUserLocale',
});

export function useChangeUserLocale(): ChangeUserLocale {
  return requiredInject(ChangeUserLocaleKey);
}
