import { ContentId } from '@/base/domains';
import { AbstractUseCase, assertEntityExists, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { EditingCourseContentBodyReference, EditingCourseContentBodyRepository } from '../domains';

export interface GetEditingCourseContentBodyRequest {
  contentId: ContentId;
}

export type GetEditingCourseContentBodyResponse = {
  body: EditingCourseContentBodyReference;
};

/**
 * 編集中コースコンテントボディを取得する
 */
export interface GetEditingCourseContentBody
  extends UseCase<GetEditingCourseContentBodyRequest, GetEditingCourseContentBodyResponse> {
  execute(
    request: GetEditingCourseContentBodyRequest
  ): Promise<UseCaseResponse<GetEditingCourseContentBodyResponse>>;
}

export class GetEditingCourseContentBodyImpl
  extends AbstractUseCase<GetEditingCourseContentBodyRequest, GetEditingCourseContentBodyResponse>
  implements GetEditingCourseContentBody
{
  private editingCourseContentBodyRepository: EditingCourseContentBodyRepository;

  constructor(editingCourseContentBodyRepository: EditingCourseContentBodyRepository) {
    super('contents.GetEditingCourseContentBody');
    this.editingCourseContentBodyRepository = editingCourseContentBodyRepository;
  }

  async internalExecute(
    request: GetEditingCourseContentBodyRequest
  ): Promise<GetEditingCourseContentBodyResponse> {
    const { contentId } = request;
    const body = await this.editingCourseContentBodyRepository.findById(contentId);
    assertEntityExists(body, 'editingCourseContentBody');
    return {
      body,
    };
  }
}

export const GetEditingCourseContentBodyKey = injectionKeyOf<GetEditingCourseContentBody>({
  boundedContext: 'contents',
  type: 'usecase',
  name: 'GetEditingCourseContentBody',
});

export function useGetEditingCourseContentBody(): GetEditingCourseContentBody {
  return requiredInject(GetEditingCourseContentBodyKey);
}
