import {
  CourseId,
  CourseName,
  CourseVersion,
  Entity,
  EntityData,
  EntityReference,
  StandardRepository,
  UserId,
} from '@/base/domains';
import { Optional } from '@/base/types';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

export type CourseStatus = 'enabled' | 'disabled' | 'editing';

/**
 * コースヘッダ属性
 */
export interface CourseHeaderAttributes {
  name: CourseName;
  activeVersion: CourseVersion;
  status: CourseStatus;
  originalCourseId?: CourseId;
  originalCourseVersion?: CourseVersion;
  createdBy: UserId;
}

/**
 * コースヘッダデータ
 */
export type CourseHeaderData = EntityData<CourseId, CourseHeaderAttributes>;

/**
 * コースヘッダコマンド
 */
export interface CourseHeaderCommand {
  enable(): CourseHeaderEntity;
  disable(): CourseHeaderEntity;
}

/**
 * コースヘッダクエリ
 */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CourseHeaderQueries {}

/**
 * コースヘッダリファレンス
 */
export type CourseHeaderReference = EntityReference<
  CourseId,
  CourseHeaderAttributes,
  CourseHeaderQueries
>;

/**
 * コースヘッダエンティティ
 */
export interface CourseHeaderEntity
  extends Entity<CourseId, CourseHeaderAttributes>,
    CourseHeaderQueries,
    CourseHeaderCommand {}

/**
 * コースヘッダリポジトリ
 */
export interface CourseHeaderRepository
  extends StandardRepository<CourseId, CourseHeaderAttributes, CourseHeaderEntity> {
  changeName(id: CourseId, name: CourseName): Promise<void>;
  tenantCourses(): Promise<Array<CourseHeaderReference>>;
  findByName(name: CourseName): Promise<Optional<CourseHeaderReference>>;
}

export const CourseHeaderRepositoryKey = injectionKeyOf<CourseHeaderRepository>({
  boundedContext: 'contents',
  type: 'adapter',
  name: 'CourseHeaderRepositoryKey',
});

export function useCourseHeaderRepository(): CourseHeaderRepository {
  return requiredInject(CourseHeaderRepositoryKey);
}
