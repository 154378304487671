import * as customQueries from '@/amplify/customQueries';
import { UserId } from '@/base/domains';
import { ISODateTimeString, Optional } from '@/base/types';
import { EditingCourseId } from '@/contents/domains';
import {
  EditingCourseQueries,
  EditingCourseWithLastContentUpdated,
} from '@/contents/domains/queries';
import { graphql } from '@/utils/AmplifyUtils';
import { localDateTimeFromString } from '@/utils/DateUtils';

import { AmplifyEditingCourse, toEditingCourse } from './AmplifyEditingCourseRepository';

type AmplifyContentIdAndUpdated = {
  updatedAt: ISODateTimeString;
  updatedBy: UserId;
};

export class AmplifyEditingCourseQueries implements EditingCourseQueries {
  async findEditingCourseWithLastContentUpdatedById(
    id: EditingCourseId
  ): Promise<Optional<EditingCourseWithLastContentUpdated>> {
    const res = await graphql<{
      getEditingCourse: Optional<AmplifyEditingCourse>;
      editingCourseContentBodiesByCourse: {
        items: Array<AmplifyContentIdAndUpdated>;
      };
      editingCourseContentWorkbooksByCourse: {
        items: Array<AmplifyContentIdAndUpdated>;
      };
    }>(customQueries.findEditingCourseWithLastContentUpdatedById, { id });
    if (res.getEditingCourse) {
      const ec = toEditingCourse(res.getEditingCourse);
      const updatedList = [
        ...res.editingCourseContentBodiesByCourse.items,
        ...res.editingCourseContentWorkbooksByCourse.items,
      ];
      updatedList.sort((a, b) => -a.updatedAt.localeCompare(b.updatedAt));
      const contentLastUpdated = updatedList[0]
        ? {
            contentLastUpdatedBy: updatedList[0].updatedBy,
            contentLastUpdatedAt: localDateTimeFromString(updatedList[0].updatedAt),
          }
        : {
            contentLastUpdatedBy: undefined,
            contentLastUpdatedAt: undefined,
          };
      return {
        id: ec.id,
        name: ec.name,
        version: ec.version,
        status: ec.status,
        description: ec.description,
        contents: ec.contents,
        createdBy: ec.createdBy,
        createdAt: ec.createdAt,
        updatedBy: ec.updatedBy,
        updatedAt: ec.updatedAt,
        color: ec.color,
        image: ec.image,
        fontColorOnImage: ec.fontColorOnImage,
        ...contentLastUpdated,
      };
    }
    return undefined;
  }
}
