import {
  ContentId,
  ContentName,
  ContentType,
  ContentVersion,
  CourseColor,
  CourseDisplayName,
  CourseFontColorOnImage,
  CourseId,
  CourseName,
  CourseVersion,
  Entity,
  EntityData,
  EntityReference,
  GroupExamId,
  GroupExamStatus,
  GroupId,
  GroupTrainingCourse,
  GroupTrainingId,
  StandardRepository,
} from '@/base/domains';
import { LocalDateTime, Minute, Optional, URI } from '@/base/types';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

/**
 * グループトレーニングコースID
 */
export type GroupTrainingCourseId = string;

export type GroupTrainingCourseContent =
  | {
      /** コンテンツタイプ */
      readonly type: Omit<ContentType, 'exam'>;
      /** 名前 */
      readonly name: ContentName;
      /** コンテンツID */
      readonly id: ContentId;
      /** 所要時間 */
      readonly requiredTime: Minute;
      /** 推奨日時 */
      readonly recommendedDateTime?: LocalDateTime;
      /** 公開中 */
      readonly open: boolean;
      /** バージョン */
      readonly version: ContentVersion;
    }
  | {
      /** コンテンツタイプ */
      readonly type: 'exam';
      /** 名前 */
      readonly name: ContentName;
      /** コンテンツID */
      readonly id: ContentId;
      /** 所要時間 */
      readonly requiredTime: Minute;
      /** 推奨日時 */
      readonly recommendedDateTime?: LocalDateTime;
      /** 公開中 */
      readonly open: boolean;
      /** テストステータス */
      readonly examStatus: GroupExamStatus;
      /** 最新のグループテストID */
      readonly latestExamId?: GroupExamId;
      /** バージョン */
      readonly version: ContentVersion;
    };

/**
 * 属性
 */
export interface GroupTrainingCourseAttributes {
  groupTrainingId: GroupTrainingId;
  courseId: CourseId;
  courseVersion: CourseVersion;
  courseName: CourseName;
  contents: Array<GroupTrainingCourseContent>;
  groupId: GroupId;
  color?: CourseColor;
  image?: URI;
  fontColorOnImage?: CourseFontColorOnImage;
  displayName: CourseDisplayName;
  index?: number;
}

/**
 * データ
 */
export type GroupTrainingCourseData = EntityData<
  GroupTrainingCourseId,
  GroupTrainingCourseAttributes
>;

/**
 * コマンド
 */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface GroupTrainingCourseCommand {
  openContent(contentId: ContentId): GroupTrainingCourseEntity;
  closeContent(contentId: ContentId): GroupTrainingCourseEntity;
  changeDisplayName(displayName: CourseDisplayName): GroupTrainingCourseEntity;
  changeContentVersion(args: {
    contentId: ContentId;
    contentVersion: ContentVersion;
  }): GroupTrainingCourseEntity;
}

/**
 * クエリ
 */
export interface GroupTrainingCourseQueries {
  toGroupTrainingCourse(): GroupTrainingCourse;
}

/**
 * リファレンス
 */
export type GroupTrainingCourseReference = EntityReference<
  GroupTrainingCourseId,
  GroupTrainingCourseAttributes,
  GroupTrainingCourseQueries
>;

/**
 * エンティティ
 */
export interface GroupTrainingCourseEntity
  extends Entity<GroupTrainingCourseId, GroupTrainingCourseAttributes>,
    GroupTrainingCourseQueries,
    GroupTrainingCourseCommand {}

/**
 * リポジトリ
 */
export interface GroupTrainingCourseRepository
  extends StandardRepository<
    GroupTrainingCourseId,
    GroupTrainingCourseAttributes,
    GroupTrainingCourseEntity
  > {
  findByGroupIdAndCourseId(
    groupId: GroupId,
    courseId: CourseId
  ): Promise<Optional<GroupTrainingCourseEntity>>;
  findByGroupIdAndCourseDisplayName(
    groupId: GroupId,
    courseDisplayName: CourseName
  ): Promise<Optional<GroupTrainingCourseEntity>>;
  findCoursesByGroupId(groupId: GroupId): Promise<Array<GroupTrainingCourseEntity>>;
  findCoursesByCourseId(courseId: CourseId): Promise<Array<GroupTrainingCourseEntity>>;
  sortGroupTrainingCourses(
    groupId: GroupId,
    courseIds: Array<CourseId>
  ): Promise<Array<GroupTrainingCourseEntity>>;
}

export const GroupTrainingCourseRepositoryKey = injectionKeyOf<GroupTrainingCourseRepository>({
  boundedContext: 'training',
  type: 'adapter',
  name: 'GroupTrainingCourseRepositoryKey',
});

export function useGroupTrainingCourseRepository(): GroupTrainingCourseRepository {
  return requiredInject(GroupTrainingCourseRepositoryKey);
}
