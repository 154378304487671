import { v4 } from 'uuid';

import { CourseId, CourseName, EntityData, GroupId, hasId } from '@/base/domains';
import { Optional } from '@/base/types';
import {
  GroupTrainingCourseAttributes,
  GroupTrainingCourseEntity,
  GroupTrainingCourseEntityImpl,
  GroupTrainingCourseId,
  GroupTrainingCourseRepository,
} from '@/training/domains';

export class InMemoryGroupTrainingCourseRepository implements GroupTrainingCourseRepository {
  private store = new Map<CourseId, GroupTrainingCourseEntity>();

  save(
    args: GroupTrainingCourseEntity | EntityData<string, GroupTrainingCourseAttributes>
  ): Promise<GroupTrainingCourseEntity> {
    if (hasId(args)) {
      const e = new GroupTrainingCourseEntityImpl({
        ...args,
      });
      this.store.set(e.id, e);
      return Promise.resolve(e);
    }
    const e = new GroupTrainingCourseEntityImpl({
      ...args,
      id: v4(),
    });
    this.store.set(e.id, e);
    return Promise.resolve(e);
  }

  findById(id: GroupTrainingCourseId): Promise<Optional<GroupTrainingCourseEntity>> {
    return Promise.resolve(this.store.get(id));
  }

  remove(id: GroupTrainingCourseId): Promise<void> {
    Promise.resolve(this.store.delete(id));
    return Promise.resolve();
  }

  findByGroupIdAndCourseId(
    groupId: GroupId,
    courseId: CourseId
  ): Promise<Optional<GroupTrainingCourseEntity>> {
    const l = Array.from(this.store.values());
    return Promise.resolve(l.find((cr) => cr.courseId === courseId && cr.groupId === groupId));
  }

  findByGroupIdAndCourseDisplayName(
    groupId: GroupId,
    courseDisplayName: CourseName
  ): Promise<Optional<GroupTrainingCourseEntity>> {
    const l = Array.from(this.store.values());
    return Promise.resolve(
      l.find((cr) => cr.displayName === courseDisplayName && cr.groupId === groupId)
    );
  }

  findCoursesByGroupId(groupId: GroupId): Promise<GroupTrainingCourseEntity[]> {
    const l = Array.from(this.store.values());
    return Promise.resolve(l.filter((cr) => cr.groupId === groupId));
  }

  findCoursesByCourseId(courseId: CourseId): Promise<GroupTrainingCourseEntity[]> {
    const l = Array.from(this.store.values());
    return Promise.resolve(l.filter((cr) => cr.courseId === courseId));
  }

  sortGroupTrainingCourses(
    groupId: GroupId,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    courseIds: CourseId[]
  ): Promise<GroupTrainingCourseEntity[]> {
    const l = Array.from(this.store.values());
    return Promise.resolve(l.filter((cr) => cr.groupId === groupId));
  }
}
