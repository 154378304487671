import { RouteConfig } from 'vue-router';

const HomePage = import(/* webpackChunkName: "home" */ './pages/HomePage.vue');
const GroupCoursesPage = import(/* webpackChunkName: "home" */ './pages/GroupCoursesPage.vue');
const GroupCourseContentsPage = import(
  /* webpackChunkName: "home" */ './pages/GroupCourseContentsPage.vue'
);
const GroupUsersPage = import(/* webpackChunkName: "home" */ './pages/GroupUsersPage.vue');

export const homeRoutes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'home',
    component: () => HomePage,
    props: (route) => ({ ...route.query }),
  },
  {
    path: '/groups/:id/courses',
    name: 'groupCourses',
    component: () => GroupCoursesPage,
    props: true,
  },
  {
    path: '/groups/:id/courses/:courseId',
    name: 'groupCourse',
    component: () => GroupCourseContentsPage,
    props: true,
  },
  {
    path: '/groups/:id/users',
    name: 'groupUsers',
    component: () => GroupUsersPage,
    props: true,
  },
];
