import { ReviewProblem, ReviewProblemFinder } from '@/base/domains';
import { Optional } from '@/base/types';

import { ReviewProblemRepository } from './ReviewProblem';

/**
 * 復習問題ファインダー 実装
 */
export class ReviewProblemFinderImpl implements ReviewProblemFinder {
  constructor(private reviewProblemRepository: ReviewProblemRepository) {}

  findMyReviewProblem(args: {
    groupId: string;
    courseId: string;
  }): Promise<Optional<ReviewProblem>> {
    return this.reviewProblemRepository.findMyReviewProblem(args);
  }

  findMyReviewProblems(args: { groupId: string }): Promise<ReviewProblem[]> {
    return this.reviewProblemRepository.findMyReviewProblems(args);
  }
}
