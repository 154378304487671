import { AuthorizationService, CourseId } from '@/base/domains';
import { AbstractUseCase, assertEntityExists, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { EditingCourseReference, EditingCourseRepository } from '../domains';

export interface ChangeEditingCourseDescriptionRequest {
  id: CourseId;
  description: string;
}

export type ChangeEditingCourseDescriptionResponse = {
  course: EditingCourseReference;
};

/**
 * 編集中コースの説明を変更する
 */
export interface ChangeEditingCourseDescription
  extends UseCase<ChangeEditingCourseDescriptionRequest, ChangeEditingCourseDescriptionResponse> {
  execute(
    request: ChangeEditingCourseDescriptionRequest
  ): Promise<UseCaseResponse<ChangeEditingCourseDescriptionResponse>>;
}

export class ChangeEditingCourseDescriptionImpl
  extends AbstractUseCase<
    ChangeEditingCourseDescriptionRequest,
    ChangeEditingCourseDescriptionResponse
  >
  implements ChangeEditingCourseDescription
{
  private authorizationService: AuthorizationService;

  private editingCourseRepository: EditingCourseRepository;

  constructor(
    authorizationService: AuthorizationService,
    editingCourseRepository: EditingCourseRepository
  ) {
    super('contents.ChangeEditingCourseDescription');
    this.authorizationService = authorizationService;
    this.editingCourseRepository = editingCourseRepository;
  }

  async internalExecute(
    request: ChangeEditingCourseDescriptionRequest
  ): Promise<ChangeEditingCourseDescriptionResponse> {
    const { id, description } = request;
    this.authorizationService.assertContentEditable();
    const course = await this.editingCourseRepository.findById(id);
    assertEntityExists(course, 'editingCourse');
    const saved = await this.editingCourseRepository.save(course.changeDescription(description));
    return {
      course: saved,
    };
  }
}

export const ChangeEditingCourseDescriptionKey = injectionKeyOf<ChangeEditingCourseDescription>({
  boundedContext: 'contents',
  type: 'usecase',
  name: 'ChangeEditingCourseDescription',
});

export function useChangeEditingCourseDescription(): ChangeEditingCourseDescription {
  return requiredInject(ChangeEditingCourseDescriptionKey);
}
