import {
  Entity,
  EntityData,
  EntityReference,
  GroupId,
  ScheduleColor,
  ScheduleTagId,
  StandardRepository,
} from '@/base/domains';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

/**
 * スケジュールタグ属性
 */
export interface ScheduleTagAttributes {
  text: string;
  color: ScheduleColor;
  groupId: GroupId;
}

/**
 * スケジュールタグデータ
 */
export type ScheduleTagData = EntityData<ScheduleTagId, ScheduleTagAttributes>;

/**
 * スケジュールタグコマンド
 */
export interface ScheduleTagCommand {
  change(args: { text: string; color: ScheduleColor }): ScheduleTagEntity;
}

/**
 * スケジュールタグクエリ
 */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ScheduleTagQueries {}

/**
 * スケジュールタグリファレンス
 */
export type ScheduleTagReference = EntityReference<
  ScheduleTagId,
  ScheduleTagAttributes,
  ScheduleTagQueries
>;

/**
 * スケジュールタグエンティティ
 */
export interface ScheduleTagEntity
  extends Entity<ScheduleTagId, ScheduleTagAttributes>,
    ScheduleTagQueries,
    ScheduleTagCommand {}

/**
 * スケジュールタグリポジトリ
 */
export interface ScheduleTagRepository
  extends StandardRepository<ScheduleTagId, ScheduleTagAttributes, ScheduleTagEntity> {
  findByGroupId(groupId: GroupId): Promise<Array<ScheduleTagEntity>>;
}

export const ScheduleTagRepositoryKey = injectionKeyOf<ScheduleTagRepository>({
  boundedContext: 'training',
  type: 'adapter',
  name: 'ScheduleTagRepositoryKey',
});

export function useScheduleTagRepository(): ScheduleTagRepository {
  return requiredInject(ScheduleTagRepositoryKey);
}
