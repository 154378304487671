import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { LocalDateTime, MarkDownString, Optional } from '../types';
import { NextToken, QueryResponse, UserId } from './Core';
import { GroupId } from './Group';

export type ScheduleTagId = string;
export type ScheduleColor = string;
export type ScheduleTag = {
  id: ScheduleTagId;
  text: string;
  color: ScheduleColor;
};
export interface ScheduleTagDataAdapter {
  findScheduleTagsByGroupId(groupId: GroupId): Promise<Array<ScheduleTag>>;
}

export const ScheduleTagDataAdapterKey = injectionKeyOf<ScheduleTagDataAdapter>({
  boundedContext: 'base',
  type: 'adapter',
  name: 'ScheduleTagDataAdapter',
});

export function useScheduleTagDataAdapter(): ScheduleTagDataAdapter {
  return requiredInject(ScheduleTagDataAdapterKey);
}

export type ScheduleId = string;

export type Schedule = {
  id: ScheduleId;
  start: LocalDateTime;
  end?: LocalDateTime;
  title: string;
  body?: MarkDownString;
  createdBy?: UserId;
  updatedBy?: UserId;
  tags: Array<ScheduleTag>;
  groupId: GroupId;
};

export type ScheduleFinderFindArgs = {
  groupId: GroupId;
  limit?: number;
  nextToken?: NextToken;
  startFrom: LocalDateTime;
  startTo: LocalDateTime;
};

export type ScheduleFinderFindRecentlyArgs = {
  limit?: number;
  nextToken?: NextToken;
} & ({ groupId: GroupId } | { groupIds: Array<GroupId> });

export interface ScheduleFinder {
  find(args: ScheduleFinderFindArgs): Promise<QueryResponse<Array<Schedule>>>;
  findRecently(args: ScheduleFinderFindRecentlyArgs): Promise<QueryResponse<Array<Schedule>>>;
  findById(id: ScheduleId): Promise<Optional<Schedule>>;
}

export const ScheduleFinderKey = injectionKeyOf<ScheduleFinder>({
  boundedContext: 'base',
  type: 'service',
  name: 'ScheduleFinder',
});

export function useScheduleFinder(): ScheduleFinder {
  return requiredInject(ScheduleFinderKey);
}

/**
 * 予定につけるタグの上限
 */
export const SCHEDULE_TAGS_LIMITATION = 10;
