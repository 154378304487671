import { AppContextProvider, CourseId } from '@/base/domains';
import { EditingConfirmedContentReference } from '@/contents/domains';
import { EditingConfirmedContentQueries } from '@/contents/domains/queries';
import {
  editingConfirmedContentsByCourse,
  editingConfirmedContentsByTenantCode,
} from '@/graphql/queries';
import { graphqlQuery } from '@/utils/AmplifyUtils';
import { requiredNonNull } from '@/utils/TsUtils';

import {
  AmplifyEditingConfirmedContent,
  toEditingConfirmedContent,
} from './AmplifyEditingConfirmedContentRepository';

export class AmplifyEditingConfirmedContentQueries implements EditingConfirmedContentQueries {
  constructor(private appContextProvider: AppContextProvider) {}

  async findTenantEditingConfirmedContents(): Promise<Array<EditingConfirmedContentReference>> {
    const tenantCode = requiredNonNull(
      this.appContextProvider.get().tenantCode,
      'appContext.tenantCode'
    );
    const res = await graphqlQuery<{
      editingConfirmedContentsByTenantCode: { items: Array<AmplifyEditingConfirmedContent> };
    }>(editingConfirmedContentsByTenantCode, {
      tenantCode,
    });
    return res.editingConfirmedContentsByTenantCode.items.map(toEditingConfirmedContent);
  }

  async findByCourseId(courseId: CourseId): Promise<EditingConfirmedContentReference[]> {
    const res = await graphqlQuery<{
      editingConfirmedContentsByCourse: { items: Array<AmplifyEditingConfirmedContent> };
    }>(editingConfirmedContentsByCourse, {
      courseId,
    });
    return res.editingConfirmedContentsByCourse.items.map(toEditingConfirmedContent);
  }
}
