import { errorCodeOf } from '@/base/error';

export const PLAYGROUND_ALREADY_USED = errorCodeOf(
  'PL0001',
  'プレイグラウンドはすでに利用されています'
);

export const PLAYGROUND_SHOULD_START_CREATING = errorCodeOf(
  'PL0002',
  'プレイグラウンドの作成を開始してください'
);
