import moment from 'moment';

import { ScheduleData } from '@/training/domains';

export const schedules: Array<ScheduleData> = [
  {
    start: moment().add(1, 'h'),
    end: moment().add(1, 'h').add(30, 'm'),
    title: 'スケジュールのタイトル',
    body: '本文',
    tags: [{ id: 'tag1', text: 'タグ1', color: 'indigo' }],
    groupId: 'group00',
    createdAt: moment().add(-1, 'd'),
    createdBy: 'user00',
    updatedAt: moment().add(-1, 'd'),
    updatedBy: 'user00',
  },
];
