import {
  AppContextProvider,
  ContentId,
  ContentLearning,
  ContentLearningDataAdapter,
  ContentName,
  CourseColor,
  CourseDisplayName,
  CourseFontColorOnImage,
  CourseId,
  ExamDataAdapter,
  ExamId,
  GroupExamId,
  GroupExamStatus,
  GroupId,
  GroupTrainingFinder,
  Question,
  QuestionFinder,
  Questionnaire,
  QuestionnaireFinder,
  ReviewProblemFinder,
  Schedule,
  ScheduleFinder,
  TenantDataAdapter,
  UnansweredQuestionnaire,
  UserExamStatus,
} from '@/base/domains';
import { LocalDateTime, Minute, Optional, URI } from '@/base/types';
import { AbstractUseCase, UseCase, UseCaseResponse } from '@/base/usecases';
import { QuestionnaireByAnswererArgs } from '@/training/domains';
import { isDefined, requiredNonNull } from '@/utils/TsUtils';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

export type GetMobileHomeContentsRequest = {};

export type GetMobileHomeContentsExam = {
  /** 試験ID */
  readonly id: ExamId;
  /** 名前 */
  readonly name: ContentName;
  /** コンテンツID */
  readonly contentId: ContentId;
  /** コースID */
  readonly courseId: CourseId;
  /** コース名 */
  readonly courseName: CourseDisplayName;
  /** コースカラー */
  readonly courseColor?: CourseColor;
  /** コースイメージ */
  readonly courseImage?: URI;
  /** コースイメージ上のフォントカラー */
  readonly courseFontColorOnImage?: CourseFontColorOnImage;
  /** 予定開始日時 */
  readonly scheduledStart: LocalDateTime;
  /** 予定終了日時 */
  readonly scheduledFinish?: LocalDateTime;
  /** ステータス */
  readonly status: UserExamStatus;
  /** 回数 */
  readonly times: number;
  /** 制限時間 */
  readonly timeLimit?: Minute;
  /** グループID */
  readonly groupId: GroupId;
  /** グループテストID */
  readonly groupExamId: GroupExamId;
  /** ステータス */
  readonly groupExamStatus: GroupExamStatus;
};

export type GetMobileHomeContentsCourse = {
  /** コースID */
  readonly id: CourseId;
  /** コース名 */
  readonly name: CourseDisplayName;
  /** 学習中 */
  readonly isLearning: boolean;
  /** テスト開催中 */
  readonly isExamOpen: boolean;
  /** テスト予約 */
  readonly isExamScheduled: boolean;
  /** コンテンツ件数 */
  readonly contentsCount: number;
  /** 完了コンテンツ件数 */
  readonly completedContentsCount: number;
  /** グループID */
  readonly groupId: GroupId;
  /** コースカラー */
  readonly courseColor?: CourseColor;
  /** コースイメージ */
  readonly courseImage?: URI;
  /** コースイメージ上のフォントカラー */
  readonly courseFontColorOnImage?: CourseFontColorOnImage;
  /** 最終学習日時 */
  readonly lastLearnedAt?: LocalDateTime;
  /** コース順 */
  readonly index?: number;
  /** 復習問題の件数 */
  readonly reviewProblemCount: number;
};

type GetMobileHomeContentsResponseCountMap = Map<
  GroupId,
  {
    notCompletedCourseCount: number;
    questionCount: number;
    scheduleCount: number;
    unansweredQuestionnaireCount: number;
    questionnaireCount: number;
    hasReviewProblemsCourseCount: number;
  }
>;

export type GetMobileHomeContentsResponse = {
  schedules: Array<Schedule>;
  exams: Array<GetMobileHomeContentsExam>;
  courses: Array<GetMobileHomeContentsCourse>;
  questions: Array<Question>;
  unansweredQuestionnaires: Array<QuestionnaireByAnswererArgs>;
  questionnaires: Array<Questionnaire>;
  count: GetMobileHomeContentsResponseCountMap;
};

/**
 * モバイルホームのコンテンツを取得する。
 */
export interface GetMobileHomeContents
  extends UseCase<GetMobileHomeContentsRequest, GetMobileHomeContentsResponse> {
  execute(
    request: GetMobileHomeContentsRequest
  ): Promise<UseCaseResponse<GetMobileHomeContentsResponse>>;
}

export class GetMobileHomeContentsImpl
  extends AbstractUseCase<GetMobileHomeContentsRequest, GetMobileHomeContentsResponse>
  implements GetMobileHomeContents
{
  constructor(
    private appContextProvider: AppContextProvider,
    private groupTrainingFinder: GroupTrainingFinder,
    private contentLearningDataAdapter: ContentLearningDataAdapter,
    private scheduleFinder: ScheduleFinder,
    private examDataAdapter: ExamDataAdapter,
    private questionFinder: QuestionFinder,
    private questionnaireFinder: QuestionnaireFinder,
    private tenantDataAdapter: TenantDataAdapter,
    private reviewProblemFinder: ReviewProblemFinder
  ) {
    super('home.GetMobileHomeContents');
  }

  async internalExecute(_: GetMobileHomeContentsRequest): Promise<GetMobileHomeContentsResponse> {
    const appContext = this.appContextProvider.get();
    const user = requiredNonNull(appContext.user, 'appContext.user');
    const tenant = await this.tenantDataAdapter.get();
    const groupIds = appContext.accessibleGroups.map((g) => g.id);
    const userAssignedGroups = user.groups.map((g) => g.id);
    if (groupIds.length === 0) {
      return {
        schedules: [],
        courses: [],
        exams: [],
        questions: [],
        unansweredQuestionnaires: [],
        questionnaires: [],
        count: new Map(),
      };
    }
    const groupTrainingCoursesFuture = Promise.all(
      groupIds.map((gId) =>
        this.groupTrainingFinder.findCoursesByGroupId(gId).then((courses) =>
          courses.map((course) => ({
            ...course,
            groupId: gId,
          }))
        )
      )
    );
    const reviewProblemsFuture = Promise.all(
      groupIds.map((gId) => this.reviewProblemFinder.findMyReviewProblems({ groupId: gId }))
    );
    const [
      groupTrainingCourses,
      contentLearnings,
      schedules,
      notFinishedUserExams,
      questions,
      unansweredQuestionnaires,
      questionnaires,
      reviewProblems,
    ] = await Promise.all([
      groupTrainingCoursesFuture,
      this.contentLearningDataAdapter.findByUserId(user.id),
      tenant.limitations.schedule === 'enabled'
        ? this.scheduleFinder.findRecently({ groupIds }).then(
            (res) =>
              res.data
                .map((s) => {
                  const limitations = appContext.groupLimitationOf(s.groupId);
                  const role = appContext.roleInGroup(s.groupId);
                  if (!role) return undefined;
                  return limitations.schedule.includes(role) ? s : undefined;
                })
                .filter((s) => !!s) as Schedule[]
          )
        : ([] as Schedule[]),
      this.examDataAdapter.findNotFinishedUserExams({ userId: user.id }),
      tenant.limitations.question === 'enabled'
        ? this.questionFinder.findRelatedQuestions(user.id).then(
            (question) =>
              question
                .map((q) => {
                  const limitations = appContext.groupLimitationOf(q.groupId);
                  const role = appContext.roleInGroup(q.groupId);
                  if (!role) return undefined;
                  return limitations.question.includes(role) ? q : undefined;
                })
                .filter((q) => !!q) as Question[]
          )
        : ([] as Question[]),
      tenant.limitations.questionnaire === 'enabled'
        ? Promise.all(
            groupIds.map((groupId) =>
              this.questionnaireFinder.findUnansweredQuestionnaires(groupId, user.id)
            )
          ).then((u) => u.flat())
        : ([] as UnansweredQuestionnaire[]),
      tenant.limitations.questionnaire === 'enabled'
        ? Promise.all(
            groupIds.map((groupId) => {
              if (
                appContext.roleInGroup(groupId) === 'trainer' ||
                appContext.roleInGroup(groupId) === 'mentor'
              ) {
                return this.questionnaireFinder.findActiveQuestionnairesByGroupId(groupId);
              }
              return [];
            })
          ).then((q) => q.flat())
        : ([] as Questionnaire[]),
      reviewProblemsFuture.then((x) => x.flat().filter((rp) => rp.problems.length > 0)),
    ]);
    const groupIdAndContentIds = new Set(
      groupTrainingCourses.flat().flatMap((cr) => cr.contents.map((cn) => `${cr.groupId}#${cn.id}`))
    );
    const contentLearningsInGroup = contentLearnings.filter((cl) => {
      const key = `${cl.groupId}#${cl.contentId}`;
      return groupIdAndContentIds.has(key);
    });
    const courseLeanings = (() => {
      const contentLearningByGroupAndCourse = contentLearningsInGroup.reduce((acc, v) => {
        const { courseId, groupId } = v;
        const key = `${groupId}#${courseId}`;
        const learnings = acc.get(key) ?? [];
        acc.set(key, [...learnings, v]);
        return acc;
      }, new Map<string, Array<ContentLearning>>());
      const groupCourseLearningArray = Array.from(contentLearningByGroupAndCourse.entries()).map(
        (e) =>
          e[1].reduce(
            (acc, v) => ({
              key: e[0],
              isLearning: acc.isLearning || v.status === 'in_progress' || v.status === 'completed',
              completedContentsCount:
                v.status === 'completed'
                  ? acc.completedContentsCount + 1
                  : acc.completedContentsCount,
              lastLearnedAt:
                !isDefined(acc.lastLearnedAt) || v.lastLearnedAt?.isAfter(acc.lastLearnedAt)
                  ? v.lastLearnedAt
                  : acc.lastLearnedAt,
            }),
            {
              key: e[0],
              isLearning: false,
              completedContentsCount: 0,
              lastLearnedAt: undefined as Optional<LocalDateTime>,
            }
          )
      );
      return new Map(
        groupCourseLearningArray.map((e) => [
          e.key,
          {
            isLearning: e.isLearning,
            completedContentsCount: e.completedContentsCount,
            lastLearnedAt: e.lastLearnedAt,
          },
        ])
      );
    })();

    const examOpenByGroupAndCourse = notFinishedUserExams.reduce((acc, v) => {
      const key = `${v.groupExam.groupId}#${v.groupExam.course.id}#${v.groupExam.status}`;
      acc.set(key, true);
      return acc;
    }, new Map<CourseId, boolean>());

    const coursesSort = (x: GetMobileHomeContentsCourse, y: GetMobileHomeContentsCourse) => {
      return (y.lastLearnedAt?.unix() ?? 0) - (x.lastLearnedAt?.unix() ?? 0);
    };

    const reviewProblemCountByGroupAndCourse: (groupId: GroupId, courseId: CourseId) => number =
      (() => {
        const map = new Map(
          reviewProblems.map((rp) => [`${rp.groupId}#${rp.courseId}`, rp.problems.length])
        );
        return (groupId, courseId) => map.get(`${groupId}#${courseId}`) ?? 0;
      })();

    const courses: GetMobileHomeContentsCourse[] = groupTrainingCourses
      .flat()
      .map((course) => {
        const courseLearning = courseLeanings.get(`${course.groupId}#${course.id}`);
        const completedContentsCount = courseLearning?.completedContentsCount ?? 0;
        const contentsCount = course.contents.length;
        const isLearning =
          (courseLearning?.isLearning ?? false) && contentsCount !== completedContentsCount;
        const cr: GetMobileHomeContentsCourse = {
          id: course.id,
          name: course.displayName,
          courseColor: course.color,
          courseImage: course.image,
          courseFontColorOnImage: course.fontColorOnImage,
          isLearning,
          isExamOpen:
            examOpenByGroupAndCourse.get(`${course.groupId}#${course.id}#in_progress`) ?? false,
          isExamScheduled:
            examOpenByGroupAndCourse.get(`${course.groupId}#${course.id}#announced`) ?? false,
          contentsCount,
          completedContentsCount,
          groupId: course.groupId,
          lastLearnedAt: courseLearning?.lastLearnedAt,
          index: course.index,
          reviewProblemCount: reviewProblemCountByGroupAndCourse(course.groupId, course.id) ?? 0,
        };
        return cr;
      })
      .sort(coursesSort);

    const notCompletedCourseCount: Map<GroupId, number> = courses
      .filter((cr) => cr.contentsCount > cr.completedContentsCount)
      .reduce((acc, v) => {
        const cnt = acc.get(v.groupId) ?? 0;
        acc.set(v.groupId, cnt + 1);
        return acc;
      }, new Map());
    const questionCount: Map<GroupId, number> = questions.reduce((acc, v) => {
      const cnt = acc.get(v.groupId) ?? 0;
      acc.set(v.groupId, cnt + 1);
      return acc;
    }, new Map());
    const scheduleCount: Map<GroupId, number> = schedules.reduce((acc, v) => {
      const cnt = acc.get(v.groupId) ?? 0;
      acc.set(v.groupId, cnt + 1);
      return acc;
    }, new Map());
    const unansweredQuestionnaireCount: Map<GroupId, number> = unansweredQuestionnaires.reduce(
      (acc, v) => {
        const cnt = acc.get(v.groupId) ?? 0;
        acc.set(v.groupId, cnt + 1);
        return acc;
      },
      new Map()
    );
    const questionnaireCount: Map<GroupId, number> = questionnaires.reduce((acc, v) => {
      const cnt = acc.get(v.groupId) ?? 0;
      acc.set(v.groupId, cnt + 1);
      return acc;
    }, new Map());
    const hasReviewProblemsCourseCountByGroup: Map<GroupId, number> = reviewProblems.reduce(
      (acc, rp) => {
        const courseCount = (acc.get(rp.groupId) ?? 0) + 1;
        acc.set(rp.groupId, courseCount);
        return acc;
      },
      new Map()
    );

    const count: GetMobileHomeContentsResponseCountMap = new Map(
      groupIds.map((groupId) => [
        groupId,
        {
          notCompletedCourseCount: notCompletedCourseCount.get(groupId) ?? 0,
          questionCount: questionCount.get(groupId) ?? 0,
          scheduleCount: scheduleCount.get(groupId) ?? 0,
          unansweredQuestionnaireCount: unansweredQuestionnaireCount.get(groupId) ?? 0,
          questionnaireCount: questionnaireCount.get(groupId) ?? 0,
          hasReviewProblemsCourseCount: hasReviewProblemsCourseCountByGroup.get(groupId) ?? 0,
        },
      ])
    );
    return {
      courses: courses.filter((cr) => userAssignedGroups.includes(cr.groupId)),
      exams: notFinishedUserExams
        .filter(
          (ue) =>
            ue.groupExam.status !== 'announced' &&
            !(ue.groupExam.status === 'finished' && ue.status !== 'in_progress')
        )
        .map((ue) => ({
          id: ue.id,
          name: ue.groupExam.content.name,
          contentId: ue.groupExam.content.id,
          courseId: ue.groupExam.course.id,
          courseName: ue.groupExam.course.name,
          courseColor: ue.groupExam.course.color,
          courseImage: ue.groupExam.course.image,
          courseFontImageOnColor: ue.groupExam.course.fontColorOnImage,
          courseFontColorOnImage: ue.groupExam.course.fontColorOnImage,
          scheduledStart: ue.groupExam.scheduledStart,
          scheduledFinish: ue.groupExam.scheduledFinish,
          status: ue.status,
          times: ue.groupExam.times,
          timeLimit: ue.groupExam.timeLimit,
          groupId: ue.groupExam.groupId,
          groupExamId: ue.groupExam.id,
          groupExamStatus: ue.groupExam.status,
        })),
      schedules,
      questions,
      unansweredQuestionnaires,
      questionnaires,
      count,
    };
  }
}

export const GetMobileHomeContentsKey = injectionKeyOf<GetMobileHomeContents>({
  boundedContext: 'home',
  type: 'usecase',
  name: 'GetMobileHomeContents',
});

export function useGetMobileHomeContents(): GetMobileHomeContents {
  return requiredInject(GetMobileHomeContentsKey);
}
