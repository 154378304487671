import moment from 'moment';
import { v4 } from 'uuid';

import { AppContextProvider, Comment, CommentId, isEntityLike, QuestionId } from '@/base/domains';
import { LocalDateTime, Optional } from '@/base/types';
import { assertEntityExists } from '@/base/usecases';
import {
  CommentData,
  FindQuestionsRequest,
  QuestionData,
  QuestionEntity,
  QuestionEntityImpl,
  QuestionReference,
  QuestionRepository,
} from '@/training/domains';
import { isDefined, requiredNonNull } from '@/utils/TsUtils';

function toComment(data: CommentData): Comment {
  return {
    ...data,
    id: data.id ?? v4(),
    createdAt: data.createdAt ?? moment(),
  };
}

export class InMemoryQuestionRepository implements QuestionRepository {
  private appContextProvider: AppContextProvider;

  private store: Map<QuestionId, QuestionEntity> = new Map();

  constructor(appContextProvider: AppContextProvider) {
    this.appContextProvider = appContextProvider;
  }

  save(data: QuestionData): Promise<QuestionEntity> {
    if (
      isEntityLike<Omit<QuestionData, 'id'> & { id: QuestionId; createdAt: LocalDateTime }>(data)
    ) {
      const createdBy =
        data.createdBy ??
        requiredNonNull(this.appContextProvider.get().user?.id, 'appContext.user.id');
      const entity = new QuestionEntityImpl({
        ...data,
        comments: data.comments.map((c) => toComment(c)),
        createdBy,
      });
      this.store.set(entity.id, entity);
      return Promise.resolve(entity);
    }
    const createdBy = requiredNonNull(data.createdBy, 'data.createdBy');
    const entity = new QuestionEntityImpl({
      ...data,
      id: v4(),
      comments: data.comments.map((c) => toComment(c)),
      createdAt: moment(),
      createdBy,
    });
    this.store.set(entity.id, entity);
    return Promise.resolve(entity);
  }

  findById(id: QuestionId): Promise<Optional<QuestionEntity>> {
    return Promise.resolve(this.store.get(id));
  }

  remove(id: QuestionId): Promise<void> {
    this.store.delete(id);
    return Promise.resolve();
  }

  findQuestions(
    request: FindQuestionsRequest
  ): Promise<{ questions: Array<QuestionReference>; limitExceeded: boolean }> {
    const { groupId, createdBy, resolved } = request;
    const filter: (q: QuestionEntity) => boolean = (() => {
      let list = [(q: QuestionEntity) => q.groupId === groupId];
      if (isDefined(createdBy)) {
        list = [...list, (q: QuestionEntity) => q.createdBy === createdBy];
      }
      if (isDefined(resolved)) {
        list = [...list, (q: QuestionEntity) => q.resolved === resolved];
      }
      return (q: QuestionEntity) => list.every((f) => f(q));
    })();
    const questions = Array.from(this.store.values()).filter(filter);
    return Promise.resolve({
      questions,
      limitExceeded: false,
    });
  }

  findCommentById(commentId: CommentId): Promise<Optional<Comment>> {
    return Promise.resolve(
      Array.from(this.store.values())
        .flatMap((q) => q.comments)
        .find((c) => c.id === commentId)
    );
  }

  changeCommentBody(args: {
    commentId: string;
    body: string;
    editedAt: moment.Moment;
  }): Promise<Comment> {
    const question = Array.from(this.store.values()).find((q) =>
      q.comments.find((c) => c.id === args.commentId)
    );
    assertEntityExists(question, 'question');
    const editedBy = requiredNonNull(this.appContextProvider.get().user?.id, 'appContext.user.id');
    const comments = question.comments.map((c) => {
      if (c.id === args.commentId) {
        return {
          ...c,
          body: args.body,
          editedBy,
          editedAt: args.editedAt,
        };
      }
      return c;
    });
    const edited = new QuestionEntityImpl({
      ...question,
      comments,
    });
    this.store.set(edited.id, edited);
    return Promise.resolve(
      requiredNonNull(
        comments.find((c) => c.id === args.commentId),
        'comment'
      )
    );
  }
}
