import { CourseId, CourseName, CourseVersion, UserId } from '@/base/domains';

import { COURSE_NOT_CONFIRMED } from '../ErrorCodes';
import { CourseHeaderEntity, CourseStatus } from './CourseHeader';

export type CourseHeaderEntityImplArgs = {
  name: CourseName;

  activeVersion: CourseVersion;

  status: CourseStatus;

  originalCourseId?: CourseId;

  originalCourseVersion?: CourseVersion;

  id: CourseId;

  createdBy: UserId;
};

export class CourseHeaderEntityImpl implements CourseHeaderEntity {
  name: CourseName;

  activeVersion: CourseVersion;

  status: CourseStatus;

  originalCourseId?: CourseId;

  originalCourseVersion?: CourseVersion;

  id: CourseId;

  createdBy: UserId;

  constructor(args: CourseHeaderEntityImplArgs) {
    this.name = args.name;
    this.activeVersion = args.activeVersion;
    this.status = args.status;
    this.originalCourseId = args.originalCourseId;
    this.originalCourseVersion = args.originalCourseVersion;
    this.id = args.id;
    this.createdBy = args.createdBy;
  }

  enable(): CourseHeaderEntity {
    if (this.status === 'editing') {
      throw COURSE_NOT_CONFIRMED.toApplicationError({ payload: { id: this.id } });
    }
    return new CourseHeaderEntityImpl({
      ...this,
      status: 'enabled',
    });
  }

  disable(): CourseHeaderEntity {
    if (this.status === 'editing') {
      throw COURSE_NOT_CONFIRMED.toApplicationError({ payload: { id: this.id } });
    }
    return new CourseHeaderEntityImpl({
      ...this,
      status: 'disabled',
    });
  }
}
