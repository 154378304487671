import {
  ContentId,
  ContentName,
  ContentType,
  CourseColor,
  CourseFontColorOnImage,
  CourseId,
  CourseName,
  CourseVersion,
  Entity,
  Repository,
  UserId,
} from '@/base/domains';
import { LocalDateTime, Minute, Optional, URI } from '@/base/types';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { ContentReference } from './Content';

/**
 * コースにおけるコンテンツ。
 */
export interface CourseContent {
  /** コンテンツタイプ */
  readonly type: ContentType;
  /** 名前。マスタとは異なる名前が付けられる。 */
  readonly name: ContentName;
  /** コンテンツID */
  readonly id: ContentId;
  /** 所要時間 */
  readonly requiredTime: Minute;
}

/**
 * コースの属性
 */
export interface CourseAttributes {
  /** 名前 */
  readonly name: CourseName;
  /** コースにおけるコンテンツのリスト */
  readonly contents: CourseContent[];
  /** 説明 */
  readonly description?: string;
  /** カラー */
  readonly color?: CourseColor;
  /** イメージ */
  readonly image?: URI;
  /** イメージ上のフォントカラー */
  readonly fontColorOnImage?: CourseFontColorOnImage;
  /** 確定ユーザー */
  readonly confirmedBy?: UserId;
  /** 確定日時 */
  readonly confirmedAt: LocalDateTime;
  /** バージョン作成ユーザー */
  readonly versionCreatedBy?: UserId;
  /** バージョン作成日時 */
  readonly versionCreatedAt?: LocalDateTime;
  /** コンテンツ最終更新ユーザー */
  readonly contentLastUpdatedBy?: UserId;
  /** コンテンツ最終更新日時 */
  readonly contentLastUpdatedAt?: LocalDateTime;
}

/**
 * コースデータ
 */
export type CourseData = { id?: CourseId; version: CourseVersion } & CourseAttributes;

export type AddContentRequest = {
  index: number | 'first' | 'last';
  /** 名前。マスタとは異なる名前が付けられる。 */
  name?: ContentName;
  /** コンテンツ */
  content: ContentReference;
  /** 推奨日時 */
  recommendedTime?: LocalDateTime;
};

export type ChangeContentsRequest = {
  name?: ContentName;
  /** コンテンツ */
  content: ContentReference;
};

/**
 * コースのコマンド
 */
interface CourseCommand {
  changeContentsSort(contentIds: Array<ContentId>): CourseEntity;
  changeDescription(description: string): CourseEntity;
  changeColor(color: CourseColor): CourseEntity;
  changeImage(image: URI): CourseEntity;
  changeFontColorOnImage(fontColorOnImage: CourseFontColorOnImage): CourseEntity;
}

/**
 * コースのクエリ
 */
interface CourseQuery {
  /**
   * 名前でコンテンツを検索する。
   *
   * @param contentName 名前
   */
  findContentByName(contentName: ContentName): Optional<CourseContent>;

  /**
   * Idでコンテンツを検索する。
   *
   * @param contentId コンテントId
   */
  findContentById(contentId: ContentId): Optional<CourseContent>;
}

export type CourseReference = Entity<CourseId, CourseData> & CourseQuery;

/**
 * コースエンティティ
 */
export interface CourseEntity
  extends Readonly<{ id: CourseId; version: CourseVersion } & CourseAttributes>,
    CourseQuery,
    CourseCommand {}

export const CourseRepositoryKey = injectionKeyOf<CourseRepository>({
  boundedContext: 'content',
  type: 'adapter',
  name: 'CourseRepository',
});

export function useCourseRepository(): CourseRepository {
  return requiredInject(CourseRepositoryKey);
}

/**
 * コースリポジトリ
 */
export interface CourseRepository
  extends Repository<{ id: CourseId; version: CourseVersion }, CourseEntity, CourseData> {
  /**
   * テナントのコースリストを取得する。
   */
  findTenantCourses(): Promise<Array<CourseEntity>>;

  /**
   * コースの最新バージョンを取得する
   * @param id コースID
   */
  findLatestVersion(id: CourseId): Promise<CourseVersion>;

  /**
   * IDを指定してコースリストを取得する
   * @param id コースID
   */
  findCoursesById(id: CourseId): Promise<Array<CourseReference>>;
}
