import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { LocalDateTime } from '../types';

export type FileStorageUploadArgs = {
  filename: string;
  file: Blob;
  onProgress?: (progress: { total: number; loaded: number }) => void;
  encode?: boolean;
};

export type SignedPolicy = {
  policy: string;
  keyPairId: string;
  signature: string;
  domain: string;
  expire: LocalDateTime;
};

export interface FileStorage {
  upload(args: FileStorageUploadArgs): Promise<string>;
  setSignedCookie(): Promise<void>;
  clearSignedCookie(): void;
  hasSignedCookie(): boolean;
  isValidSignedCookie(): boolean;
  getExpire(): LocalDateTime;
}

export const FileStorageKey = injectionKeyOf<FileStorage>({
  boundedContext: 'base',
  type: 'adapter',
  name: 'FileStorage',
});

export function useFileStorage(): FileStorage {
  return requiredInject(FileStorageKey);
}
