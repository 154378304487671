import {
  AuthorizationService,
  CourseDisplayName,
  CourseId,
  GroupId,
  GroupTrainingFinder,
} from '@/base/domains';
import { AbstractUseCase, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

export interface GetCoursesRequest {
  groupId: GroupId;
}

export type GetCoursesResponseCourse = {
  id: CourseId;
  name: CourseDisplayName;
};

export type GetCoursesResponse = {
  courses: Array<GetCoursesResponseCourse>;
};

export interface GetCourses extends UseCase<GetCoursesRequest, GetCoursesResponse> {
  execute(request: GetCoursesRequest): Promise<UseCaseResponse<GetCoursesResponse>>;
}

export class GetCoursesImpl
  extends AbstractUseCase<GetCoursesRequest, GetCoursesResponse>
  implements GetCourses
{
  authorizationService: AuthorizationService;

  groupTrainingFinder: GroupTrainingFinder;

  constructor(
    authorizationService: AuthorizationService,
    groupTrainingFinder: GroupTrainingFinder
  ) {
    super('report.GetCourses');
    this.authorizationService = authorizationService;
    this.groupTrainingFinder = groupTrainingFinder;
  }

  async internalExecute(request: GetCoursesRequest): Promise<GetCoursesResponse> {
    const { groupId } = request;
    this.authorizationService.assertGroupReadAccessible(groupId);
    const courses = await this.groupTrainingFinder.findCoursesByGroupId(groupId);
    return {
      courses: courses.map((cr) => ({ id: cr.id, name: cr.displayName })),
    };
  }
}

export const GetCoursesKey = injectionKeyOf<GetCourses>({
  boundedContext: 'report',
  type: 'usecase',
  name: 'GetCourses',
});

export function useGetCourses(): GetCourses {
  return requiredInject(GetCoursesKey);
}
