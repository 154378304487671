import { RouteConfig } from 'vue-router';

const RequestCreatePlaygroundPage = import(
  /* webpackChunkName: "playground" */ './pages/RequestCreatePlaygroundPage.vue'
);

const CreatePlaygroundPage = import(
  /* webpackChunkName: "playground" */ './pages/CreatePlaygroundPage.vue'
);

export const playgroundRoutes: RouteConfig[] = [
  {
    path: '/playgrounds',
    name: 'requestCreatePlayground',
    component: () => RequestCreatePlaygroundPage,
    props: (route) => route.query,
  },
  {
    path: '/playgrounds/:id',
    name: 'createPlayground',
    component: () => CreatePlaygroundPage,
    props: true,
  },
];
