import {
  AppContextProvider,
  ContentId,
  ContentVersion,
  CourseId,
  EventStore,
  GroupId,
  ReviewProblemProblem,
} from '@/base/domains';
import { AbstractUseCase, UseCase, UseCaseResponse } from '@/base/usecases';
import { requiredNonNull } from '@/utils/TsUtils';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import {
  ContentFinished,
  contentFinishedFactory,
  emptyReviewProblemEntity,
  ReviewProblemRepository,
} from '../domains';

export type FinishContentReviewProblemProblem = Omit<
  ReviewProblemProblem,
  'index' | 'hash' | 'origin'
> & { originalIndex: number };

export interface FinishContentRequest {
  /** グループID */
  groupId: GroupId;
  /** コースID */
  courseId: CourseId;
  /** コンテンツID */
  contentId: ContentId;
  /** コンテンツバージョン */
  contentVersion: ContentVersion;
  /** 復習問題に追加・削除する問題 */
  reviewProblem?: {
    problems?: FinishContentReviewProblemProblem[];
    problemsToRemove?: Pick<ReviewProblemProblem, 'body' | 'answer' | 'options'>[];
  };
  /** すでにコンテンツが完了している */
  alreadyFinished: boolean;
}

export type FinishContentResponse = {};

export interface FinishContent extends UseCase<FinishContentRequest, FinishContentResponse> {
  execute(request: FinishContentRequest): Promise<UseCaseResponse<FinishContentResponse>>;
}

export class FinishContentImpl
  extends AbstractUseCase<FinishContentRequest, FinishContentResponse>
  implements FinishContent
{
  constructor(
    private eventStore: EventStore,
    private reviewProblemRepository: ReviewProblemRepository,
    private appContextProvider: AppContextProvider
  ) {
    super('training.FinishContent');
  }

  private async changeReviewProblemProblems(
    rest: {
      groupId: string;
      courseId: string;
      contentId: string;
      contentVersion: number;
    },
    reviewProblem: {
      problems?: FinishContentReviewProblemProblem[] | undefined;
      problemsToRemove?: Pick<ReviewProblemProblem, 'body' | 'answer' | 'options'>[];
    }
  ) {
    const { groupId, courseId, contentId, contentVersion } = rest;
    const userId = requiredNonNull(this.appContextProvider.get().user?.id, 'appContext.user');
    const reviewProblemEntity =
      (await this.reviewProblemRepository.findMyReviewProblem({
        groupId: rest.groupId,
        courseId: rest.courseId,
      })) ??
      emptyReviewProblemEntity({
        userId,
        groupId,
        courseId,
      });
    const { problemsToRemove = [] } = reviewProblem;
    const problems: Omit<ReviewProblemProblem, 'index' | 'hash'>[] = (
      reviewProblem.problems ?? []
    ).map(({ originalIndex, ...p }) => ({
      ...p,
      origin: {
        contentId,
        contentVersion,
        problemIndex: originalIndex,
      },
    }));
    await this.reviewProblemRepository.save(
      reviewProblemEntity.changeProblems(problems, problemsToRemove)
    );
  }

  async internalExecute({
    reviewProblem,
    alreadyFinished,
    ...rest
  }: FinishContentRequest): Promise<FinishContentResponse> {
    return Promise.all([
      (async () => {
        if (reviewProblem) {
          await this.changeReviewProblemProblems(rest, reviewProblem);
        }
      })(),
      (async () => {
        if (!alreadyFinished) {
          const event: ContentFinished = rest;
          await this.eventStore.save(contentFinishedFactory(event));
        }
      })(),
    ]);
  }
}

export const FinishContentKey = injectionKeyOf<FinishContent>({
  boundedContext: 'training',
  type: 'usecase',
  name: 'FinishContent',
});

export function useFinishContent(): FinishContent {
  return requiredInject(FinishContentKey);
}
