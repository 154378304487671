import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { MarkDownString, Optional } from '../types';
import {
  ChoiceProblemOption,
  ChoiceProblemOptionIndex,
  ContentId,
  ContentVersion,
  CourseId,
  ProblemIndex,
} from './content';
import { UserId } from './Core';
import { GroupId } from './Group';

/**
 * 復習問題ID 形式: ${groupId}#${courseId}#${userId}
 */
export type ReviewProblemId = string;

/**
 * 復習問題のオリジナル
 */
export type ReviewProblemProblemOrigin = {
  /** コンテントID */
  contentId: ContentId;
  /** コンテントバージョン */
  contentVersion: ContentVersion;
  /** 問題インデックス */
  problemIndex: ProblemIndex;
};

/**
 * 復習問題問題ベース
 */
type ReviewProblemProblemBase = {
  /** インデックス */
  readonly index: ProblemIndex;
  /** 本文 */
  readonly body: MarkDownString;
  /** 解説 */
  readonly commentary?: MarkDownString;
  /** 練習問題オリジナル */
  readonly origin: ReviewProblemProblemOrigin;
  /** ハッシュ */
  readonly hash: string;
};

/**
 * 選択復習問題問題
 */
export interface ChoiceReviewProblemProblem extends ReviewProblemProblemBase {
  /** 問題タイプ */
  readonly type: 'choice';
  /** 選択肢 */
  readonly options: ChoiceProblemOption[];
  /** 複数選択 */
  readonly multiple: boolean;
  /** 答え */
  readonly answer: ChoiceProblemOptionIndex[];
}

/**
 * 復習問題問題
 */
export type ReviewProblemProblem = ChoiceReviewProblemProblem;

/**
 * 復習問題
 */
export type ReviewProblem = {
  /** 復習問題ID */
  id: ReviewProblemId;
  /** ユーザーID */
  userId: UserId;
  /** グループID */
  groupId: GroupId;
  /** コースID */
  courseId: CourseId;
  /** 問題リスト */
  problems: ReviewProblemProblem[];
};

/**
 * 復習問題ファインダー
 */
export interface ReviewProblemFinder {
  /**
   * 自分の復習問題を取得する
   *
   * @param args0.groupId グループID
   * @param args0.courseId コースID
   */
  findMyReviewProblem(args: {
    groupId: GroupId;
    courseId: CourseId;
  }): Promise<Optional<ReviewProblem>>;

  /**
   * 自分の復習問題リストを取得する
   *
   * @param args0.groupId グループID
   */
  findMyReviewProblems(args: { groupId: GroupId }): Promise<ReviewProblem[]>;
}

export const ReviewProblemFinderKey = injectionKeyOf<ReviewProblemFinder>({
  boundedContext: 'base',
  type: 'service',
  name: 'ReviewProblemFinder',
});

export function useReviewProblemFinder(): ReviewProblemFinder {
  return requiredInject(ReviewProblemFinderKey);
}
