import { HandStatus as AmplifyHandStatus, UpdateGroupStatusUserInput } from '@/API';
import { GroupStatusQueries } from '@/base/domains/queries';
import { ISODateTimeString } from '@/base/types';
import { assertEntityExists } from '@/base/usecases';
import { updateGroupStatusUser } from '@/graphql/mutations';
import {
  onCreateGroupStatusUserByGroupId,
  onDeleteGroupStatusUserByGroupId,
  onUpdateGroupStatusUserByGroupId,
} from '@/graphql/subscriptions';
import { graphql, graphqlSubscribe } from '@/utils/AmplifyUtils';

import {
  GroupId,
  GroupStatusService,
  GroupStatusUser,
  HandStatus,
  Subscription,
  TenantCode,
  UserId,
} from '../../domains';

type AmplifyGroupStatusUser = {
  userId: UserId;
  groupId: GroupId;
  handStatus: HandStatus;
  tenantCode: TenantCode;
  createdAt: ISODateTimeString;
  updatedAt: ISODateTimeString;
};

function toGroupStatusUser(data: AmplifyGroupStatusUser): GroupStatusUser {
  return {
    userId: data.userId,
    handStatus: data.handStatus.toLowerCase() as HandStatus,
  };
}

async function setHandStatus({
  userId,
  groupId,
  handStatus,
}: {
  userId: UserId;
  groupId: GroupId;
  handStatus: HandStatus;
}): Promise<void> {
  const input: UpdateGroupStatusUserInput = {
    id: `${groupId}#${userId}`,
    handStatus: handStatus.toUpperCase() as AmplifyHandStatus,
  };
  await graphql(updateGroupStatusUser, {
    input,
  });
}

export class AmplifyGroupStatusService implements GroupStatusService {
  constructor(private groupStatusQueries: GroupStatusQueries) {}

  async raiseHand({
    userId,
    groupId,
    handStatus,
  }: {
    userId: UserId;
    groupId: GroupId;
    handStatus: 'yes' | 'no';
  }): Promise<GroupStatusUser> {
    await setHandStatus({ userId, groupId, handStatus });
    return {
      userId,
      handStatus,
    };
  }

  async lowerHand({
    userId,
    groupId,
  }: {
    userId: UserId;
    groupId: GroupId;
  }): Promise<GroupStatusUser> {
    await setHandStatus({
      userId,
      groupId,
      handStatus: 'none',
    });
    return {
      userId,
      handStatus: 'none',
    };
  }

  async clearHandStatuses(groupId: GroupId): Promise<void> {
    const groupStatus = await this.groupStatusQueries.findByGroupId(groupId);
    assertEntityExists(groupStatus, 'groupStatus');
    await Promise.all(
      groupStatus.users.map((u) =>
        setHandStatus({
          userId: u.userId,
          groupId,
          handStatus: 'none',
        })
      )
    );
  }

  subscribeGroupStatusUserChanged({
    groupId,
    onChange,
    onError,
  }: {
    groupId: GroupId;
    onChange: (groupStatusUser: GroupStatusUser, removed: boolean) => void;
    onError: (e: Error) => void;
  }): Subscription {
    const onCreateSubscription = graphqlSubscribe<{
      value: {
        data: {
          onCreateGroupStatusUserByGroupId: AmplifyGroupStatusUser;
        };
      };
    }>(onCreateGroupStatusUserByGroupId, {
      groupId,
    }).subscribe(
      (value) =>
        onChange(toGroupStatusUser(value.value.data.onCreateGroupStatusUserByGroupId), false),
      onError
    );
    const onUpdateSubscription = graphqlSubscribe<{
      value: {
        data: {
          onUpdateGroupStatusUserByGroupId: AmplifyGroupStatusUser;
        };
      };
    }>(onUpdateGroupStatusUserByGroupId, {
      groupId,
    }).subscribe(
      (value) =>
        onChange(toGroupStatusUser(value.value.data.onUpdateGroupStatusUserByGroupId), false),
      onError
    );
    const onDeleteSubscription = graphqlSubscribe<{
      value: {
        data: {
          onDeleteGroupStatusUserByGroupId: AmplifyGroupStatusUser;
        };
      };
    }>(onDeleteGroupStatusUserByGroupId, {
      groupId,
    }).subscribe(
      (value) =>
        onChange(toGroupStatusUser(value.value.data.onDeleteGroupStatusUserByGroupId), true),
      onError
    );
    return {
      unsubscribe: () => {
        onCreateSubscription.unsubscribe();
        onUpdateSubscription.unsubscribe();
        onDeleteSubscription.unsubscribe();
      },
      isClosed: () =>
        onCreateSubscription.closed || onUpdateSubscription.closed || onDeleteSubscription.closed,
    };
  }
}
