import {
  Content,
  ContentFinder,
  ContentHeader,
  ContentId,
  ContentVersion,
  CourseId,
  CourseVersion,
} from '@/base/domains';
import { Optional } from '@/base/types';

import { ContentRepository } from './Content';
import { ContentQueries } from './queries/ContentQueries';

export class ContentFinderImpl implements ContentFinder {
  constructor(
    private contentRepository: ContentRepository,
    private contentQueries: ContentQueries
  ) {}

  findById(id: ContentId, version?: ContentVersion): Promise<Optional<Content>> {
    return this.contentRepository.findById(id, version);
  }

  findTenantContents(): Promise<Array<Content>> {
    return this.contentRepository.findTenantContents();
  }

  findByCourseId(courseId: CourseId, courseVersion: CourseVersion): Promise<Content[]> {
    return this.contentRepository.findByCourseId(courseId, courseVersion);
  }

  findTenantContentHeaders(options?: { latest: boolean }): Promise<Array<ContentHeader>> {
    return this.contentQueries.findTenantContentHeaders(options);
  }

  findContentHeadersByCourse(args: {
    courseId: CourseId;
    courseVersion: CourseVersion;
  }): Promise<ContentHeader[]> {
    return this.contentQueries.findContentHeadersByCourse(args);
  }
}
