import { Locale, LocaleProvider, Locales } from '@/base/domains';

export class BrowserLocaleProvider implements LocaleProvider {
  get(): Locale {
    const locale = (
      (window.navigator.languages && window.navigator.languages[0]) ??
      window.navigator.language ??
      'ja'
    ).toLowerCase();
    const left = (locale.includes('-') ? locale.split('-')[0] : locale) as Locale;
    if (Locales.includes(left)) {
      return left;
    }
    return 'ja';
  }
}
