import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { QuestionnaireId, QuestionnaireRespondent } from '../domains';
import { UseCase, UseCaseResponse } from './UseCase';

export type AnswerQuestionnaireRequest = {
  questionnaireId: QuestionnaireId;
  selectedIndex: number;
};

export type AnswerQuestionnaireResponse = {
  questionnaireRespondent: QuestionnaireRespondent;
};

export interface AnswerQuestionnaire
  extends UseCase<AnswerQuestionnaireRequest, AnswerQuestionnaireResponse> {
  execute(
    request: AnswerQuestionnaireRequest
  ): Promise<UseCaseResponse<AnswerQuestionnaireResponse>>;
}

export const AnswerQuestionnaireKey = injectionKeyOf<AnswerQuestionnaire>({
  boundedContext: 'training',
  type: 'usecase',
  name: 'AnswerQuestionnaire',
});

export function useAnswerQuestionnaire(): AnswerQuestionnaire {
  return requiredInject(AnswerQuestionnaireKey);
}
