import { AbstractUseCase, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { ContentReference, ContentRepository } from '../domains';

export type GetContentsResponse = {
  contents: Array<ContentReference>;
};

/**
 * コンテンツリスト取得
 */
export interface GetContents extends UseCase<{}, GetContentsResponse> {
  execute(): Promise<UseCaseResponse<GetContentsResponse>>;
}

export class GetContentsImpl
  extends AbstractUseCase<{}, GetContentsResponse>
  implements GetContents
{
  private contentRepository: ContentRepository;

  constructor(contentRepository: ContentRepository) {
    super('contents.GetContents');
    this.contentRepository = contentRepository;
  }

  async internalExecute(): Promise<GetContentsResponse> {
    const contents = await this.contentRepository.findTenantContents();
    return {
      contents,
    };
  }
}

export const GetContentsKey = injectionKeyOf<GetContents>({
  boundedContext: 'contents',
  type: 'usecase',
  name: 'GetContents',
});

export function useGetContents(): GetContents {
  return requiredInject(GetContentsKey);
}
