import {
  ContentId,
  ContentName,
  CourseColor,
  CourseFontColorOnImage,
  CourseId,
  CourseName,
  CourseVersion,
  UserId,
} from '@/base/domains';
import { LocalDateTime, Optional, URI } from '@/base/types';
import { assertIsDefined } from '@/utils/Asserts';

import { CourseContent, CourseEntity } from './Course';

export type CourseEntityImplArgs = {
  id: CourseId;
  name: CourseName;
  version: CourseVersion;
  contents: Array<CourseContent>;
  description?: string;
  color?: CourseColor;
  image?: URI;
  fontColorOnImage?: CourseFontColorOnImage;
  confirmedBy?: UserId;
  confirmedAt: LocalDateTime;
  versionCreatedBy?: UserId;
  versionCreatedAt?: LocalDateTime;
  contentLastUpdatedBy?: UserId;
  contentLastUpdatedAt?: LocalDateTime;
};

/**
 * コース実装
 */
export class CourseEntityImpl implements CourseEntity {
  id: CourseId;

  name: CourseName;

  version: CourseVersion;

  contents: Array<CourseContent>;

  description?: string;

  color?: CourseColor;

  image?: URI;

  fontColorOnImage?: CourseFontColorOnImage;

  confirmedBy?: UserId;

  confirmedAt: LocalDateTime;

  versionCreatedBy?: UserId;

  versionCreatedAt?: LocalDateTime;

  contentLastUpdatedBy?: UserId;

  contentLastUpdatedAt?: LocalDateTime;

  constructor(data: CourseEntityImplArgs) {
    this.id = data.id;
    this.name = data.name;
    this.contents = data.contents;
    this.version = data.version;
    this.description = data.description;
    this.color = data.color;
    this.image = data.image;
    this.fontColorOnImage = data.fontColorOnImage;
    this.confirmedBy = data.confirmedBy;
    this.confirmedAt = data.confirmedAt;
    this.versionCreatedBy = data.versionCreatedBy;
    this.versionCreatedAt = data.versionCreatedAt;
    this.contentLastUpdatedBy = data.contentLastUpdatedBy;
    this.contentLastUpdatedAt = data.contentLastUpdatedAt;
  }

  findContentByName(contentName: ContentName): Optional<CourseContent> {
    return this.contents.find((c) => c.name === contentName);
  }

  findContentById(contentId: ContentId): Optional<CourseContent> {
    return this.contents.find((c) => c.id === contentId);
  }

  changeContentsSort(contentIds: Array<ContentId>): CourseEntity {
    if (
      this.contents.length !== contentIds.length ||
      this.contents.find((c) => !contentIds.find((id) => id === c.id))
    )
      throw new Error(
        `content is missing; courseId=${this.id}, contentId=${contentIds}, args.contentIds=${contentIds}`
      );
    return new CourseEntityImpl({
      ...this,
      contents: contentIds.map((id) => {
        const content = this.contents.find((c) => c.id === id);
        assertIsDefined(content);
        return content;
      }),
    });
  }

  changeDescription(description: string): CourseEntity {
    return new CourseEntityImpl({
      ...this,
      description,
    });
  }

  changeColor(color: CourseColor): CourseEntity {
    return new CourseEntityImpl({
      ...this,
      color,
      image: undefined,
    });
  }

  changeImage(image: URI): CourseEntity {
    return new CourseEntityImpl({
      ...this,
      color: undefined,
      image,
      fontColorOnImage: this.fontColorOnImage ?? 'black',
    });
  }

  changeFontColorOnImage(fontColorOnImage: CourseFontColorOnImage): CourseEntity {
    return new CourseEntityImpl({
      ...this,
      fontColorOnImage,
    });
  }
}
