import {
  Email,
  Entity,
  EntityData,
  EntityReference,
  Password,
  Subscription,
  TenantCode,
  TenantName,
  UserCode,
} from '@/base/domains';
import { Optional } from '@/base/types';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

export type PlaygroundStatus = 'reserved' | 'creating' | 'created' | 'used';

/**
 * プレイグラウンドID
 */
export type PlaygroundId = string;

/**
 * プレイグラウンド属性
 */
export interface PlaygroundAttributes {
  status: PlaygroundStatus;
  email: Email;
  tenantCode: TenantCode;
  tenantName: TenantName;
  userCode?: UserCode;
  temporaryPassword?: Password;
}

/**
 * プレイグラウンドデータ
 */
export type PlaygroundData = EntityData<PlaygroundId, PlaygroundAttributes>;

/**
 * プレイグラウンドコマンド
 */
export type PlaygroundCommand = {
  startCreating(userCode: UserCode): PlaygroundEntity;
};

/**
 * プレイグラウンドクエリ
 */
export type PlaygroundQueries = {};

/**
 * プレイグラウンドリファレンス
 */
export type PlaygroundReference = EntityReference<
  PlaygroundId,
  PlaygroundAttributes,
  PlaygroundQueries
>;

/**
 * プレイグラウンドエンティティ
 */
export interface PlaygroundEntity
  extends Entity<PlaygroundId, PlaygroundAttributes>,
    PlaygroundQueries,
    PlaygroundCommand {}

/**
 * プレイグラウンドリポジトリ
 */
export type PlaygroundRepository = {
  save(args: PlaygroundAttributes | PlaygroundEntity): Promise<PlaygroundEntity>;
  findById(id: PlaygroundId): Promise<Optional<PlaygroundEntity>>;
  findByTenantCode(tenantCode: TenantCode): Promise<Optional<PlaygroundEntity>>;
  onCreated(args: {
    id: PlaygroundId;
    onSucceeded: (playground: PlaygroundEntity) => void;
    onFailed: (e: Error) => void;
  }): Subscription;
};

export const PlaygroundRepositoryKey = injectionKeyOf<PlaygroundRepository>({
  boundedContext: 'account',
  type: 'adapter',
  name: 'PlaygroundRepositoryKey',
});

export function usePlaygroundRepository(): PlaygroundRepository {
  return requiredInject(PlaygroundRepositoryKey);
}
