import { AuthorizationService, DateTimeService, GroupExamId } from '@/base/domains';
import { AbstractUseCase, UseCase, UseCaseResponse } from '@/base/usecases';
import { isDefined } from '@/utils/TsUtils';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { GroupExamRepository } from '../domains';
import { GROUP_EXAM_ALREADY_STARTED } from '../ErrorCodes';

export type RemoveGroupExamScheduleRequest = {
  id: GroupExamId;
};

export type RemoveGroupExamScheduleResponse = {};

export interface RemoveGroupExamSchedule
  extends UseCase<RemoveGroupExamScheduleRequest, RemoveGroupExamScheduleResponse> {
  execute(
    request: RemoveGroupExamScheduleRequest
  ): Promise<UseCaseResponse<RemoveGroupExamScheduleResponse>>;
}

export class RemoveGroupExamScheduleImpl
  extends AbstractUseCase<RemoveGroupExamScheduleRequest, RemoveGroupExamScheduleResponse>
  implements RemoveGroupExamSchedule
{
  constructor(
    private authorizationService: AuthorizationService,
    private groupExamRepository: GroupExamRepository,
    private dateTimeService: DateTimeService
  ) {
    super('training.RemoveGroupExamSchedule');
  }

  async internalExecute(
    request: RemoveGroupExamScheduleRequest
  ): Promise<RemoveGroupExamScheduleResponse> {
    const { id } = request;

    const [groupExam, now] = await Promise.all([
      this.groupExamRepository.findById(id),
      this.dateTimeService.strictLocalDateTimeNow(),
    ]);
    if (groupExam) {
      this.authorizationService.assertTrainerAccessible(groupExam.groupId);
      if (isDefined(groupExam.finishedAt) || groupExam.scheduledStart.isSameOrBefore(now)) {
        throw GROUP_EXAM_ALREADY_STARTED.toApplicationError();
      }
      await this.groupExamRepository.remove(groupExam.id);
    }
    return {};
  }
}

export const RemoveGroupExamScheduleKey = injectionKeyOf<RemoveGroupExamSchedule>({
  boundedContext: 'training',
  type: 'usecase',
  name: 'RemoveGroupExamSchedule',
});

export function useRemoveGroupExamSchedule(): RemoveGroupExamSchedule {
  return requiredInject(RemoveGroupExamScheduleKey);
}
