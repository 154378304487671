import { GroupId } from '@/base/domains';
import { GroupStatus, GroupStatusQueries } from '@/base/domains/queries';
import { Optional } from '@/base/types';

export class InMemoryGroupStatusQueries implements GroupStatusQueries {
  async findByGroupId(groupId: GroupId): Promise<Optional<GroupStatus>> {
    return {
      groupId,
      users: [],
    };
  }
}
