import { SignUpReservation } from '@/base/domains';

export const signUpReservations: Array<SignUpReservation> = [
  {
    id: 'reserve1',
    email: 'mail@example.com',
    userCode: 'reserve1',
    role: 'general',
    status: 'not_signed_up',
    enabled: true,
    groups: [],
  },
];

export const signUpReservation: SignUpReservation = {
  id: 'reserve1',
  email: 'mail@example.com',
  userCode: 'reserve1',
  role: 'general',
  status: 'not_signed_up',
  enabled: true,
  groups: [],
};
