import { AuthorizationService, ContentId, DataVersion, ProblemHeaderId } from '@/base/domains';
import {
  AbstractUseCase,
  assertDataVersion,
  assertEntityExists,
  UseCase,
  UseCaseResponse,
} from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import {
  EditingCourseContentWorkbookReference,
  EditingCourseContentWorkbookRepository,
} from '../domains';

/**
 * 編集中コンテンツ練習問題の問題ヘッダーを削除する
 */
export type RemoveProblemHeaderToEditingCourseContentWorkbookRequest = {
  id: ContentId;
  problemHeaderId: ProblemHeaderId;
  expectedDataVersion: DataVersion;
};

export type RemoveProblemHeaderToEditingCourseContentWorkbookResponse = {
  workbook: EditingCourseContentWorkbookReference;
};

export interface RemoveProblemHeaderToEditingCourseContentWorkbook
  extends UseCase<
    RemoveProblemHeaderToEditingCourseContentWorkbookRequest,
    RemoveProblemHeaderToEditingCourseContentWorkbookResponse
  > {
  execute(
    request: RemoveProblemHeaderToEditingCourseContentWorkbookRequest
  ): Promise<UseCaseResponse<RemoveProblemHeaderToEditingCourseContentWorkbookResponse>>;
}

export class RemoveProblemHeaderToEditingCourseContentWorkbookImpl
  extends AbstractUseCase<
    RemoveProblemHeaderToEditingCourseContentWorkbookRequest,
    RemoveProblemHeaderToEditingCourseContentWorkbookResponse
  >
  implements RemoveProblemHeaderToEditingCourseContentWorkbook
{
  private authorizationService: AuthorizationService;

  private editingCourseContentWorkbookRepository: EditingCourseContentWorkbookRepository;

  constructor(
    authorizationService: AuthorizationService,
    editingCourseContentWorkbookRepository: EditingCourseContentWorkbookRepository
  ) {
    super('contents.RemoveProblemHeaderToEditingCourseContentWorkbook');
    this.authorizationService = authorizationService;
    this.editingCourseContentWorkbookRepository = editingCourseContentWorkbookRepository;
  }

  async internalExecute(
    request: RemoveProblemHeaderToEditingCourseContentWorkbookRequest
  ): Promise<RemoveProblemHeaderToEditingCourseContentWorkbookResponse> {
    const { id, problemHeaderId, expectedDataVersion } = request;
    this.authorizationService.assertContentEditable();
    const workbook = await this.editingCourseContentWorkbookRepository.findById(id);
    assertEntityExists(workbook, 'editingCourseContentWorkbook');
    assertDataVersion(workbook, expectedDataVersion, 'editingCourseContentWorkbook');
    const saved = await this.editingCourseContentWorkbookRepository.save(
      workbook.removeProblemHeader(problemHeaderId)
    );
    return {
      workbook: saved,
    };
  }
}

export const RemoveProblemHeaderToEditingCourseContentWorkbookKey =
  injectionKeyOf<RemoveProblemHeaderToEditingCourseContentWorkbook>({
    boundedContext: 'contents',
    type: 'usecase',
    name: 'RemoveProblemHeaderToEditingCourseContentWorkbook',
  });

export function useRemoveProblemHeaderToEditingCourseContentWorkbook(): RemoveProblemHeaderToEditingCourseContentWorkbook {
  return requiredInject(RemoveProblemHeaderToEditingCourseContentWorkbookKey);
}
