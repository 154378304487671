import * as customQueries from '@/amplify/customQueries';
import {
  CourseFontColorOnImage as AmplifyCourseFontColorOnImage,
  ExamResultVisibilityLevel as AmplifyExamResultVisibilityLevel,
  ModelGroupExamFilterInput,
  UserExamStatus as AmplifyUserExamStatus,
} from '@/API';
import {
  ActiveExam,
  AppContextProvider,
  ContentId,
  ContentName,
  ContentVersion,
  CourseColor,
  CourseFontColorOnImage,
  CourseId,
  CourseName,
  CourseVersion,
  ExamDataAdapter,
  ExamDataAdapterFindExamResultsArgs,
  ExamDataAdapterFindNotFinishedUserExam,
  ExamId,
  ExamResult,
  ExamResultAnswer,
  ExamResultVisibilityLevel,
  GroupExam,
  GroupExamContent,
  GroupExamId,
  GroupExamProblem,
  GroupId,
  MyExamResult,
  TenantCode,
  UserExam,
  UserExamStatus,
  UserId,
} from '@/base/domains';
import { LocalDateTime, Minute, Optional, URI } from '@/base/types';
import * as queries from '@/graphql/queries';
import { graphql, graphqlQuery } from '@/utils/AmplifyUtils';
import { assertIsDefined } from '@/utils/Asserts';
import { localDateTimeFromString, localDateTimeNow } from '@/utils/DateUtils';
import {
  hasNonNullProperty,
  hasProperty,
  ifDefined,
  isDefined,
  requiredNonNull,
} from '@/utils/TsUtils';

type AmplifyGroupExam = {
  id: GroupExamId;
  groupId: GroupId;
  content: {
    id: ContentId;
    version?: ContentVersion;
    name: ContentName;
    requiredTime?: Minute;
    indexInCourse: number;
  };
  problemsJson: string;
  problemHeaders?: Array<{
    id: string;
    body: string;
    createdAt: string;
  }>;
  course: {
    id: CourseId;
    version: CourseVersion;
    name: CourseName;
    color?: CourseColor;
    image?: URI;
    fontColorOnImage?: AmplifyCourseFontColorOnImage;
  };
  scheduledStart: string;
  scheduledFinish?: string;
  finishedAt?: string;
  visibilityLevel: AmplifyExamResultVisibilityLevel;
  userExams?: { items: Array<AmplifyUserExam> };
  tenantCode: TenantCode;
  times: number;
  timeLimit?: Minute;
  passingStandard?: number;
  userIdsToBeTested?: UserId[];
};

type AmplifyUserExam = {
  id: ExamId;
  userId: UserId;
  status: AmplifyUserExamStatus;
  tenantCode: TenantCode;
  groupExamId: GroupExamId;
  groupExam?: AmplifyGroupExam;
  groupId: GroupId;
  startedAt?: string;
  finishedAt?: string;
};

const groupExamStatusOfProvider = () => {
  const now = localDateTimeNow();
  return (
    scheduledStart: LocalDateTime,
    scheduledFinish?: LocalDateTime,
    finishedAt?: LocalDateTime
  ) => {
    if (isDefined(finishedAt) || scheduledFinish?.isSameOrBefore(now)) {
      return 'finished';
    }
    if (scheduledStart.isSameOrBefore(now)) {
      return 'in_progress';
    }
    return 'announced';
  };
};

function toExamDataAdapterFindNotFinishedUserExam(e: AmplifyUserExam): UserExam & {
  groupExam: Omit<GroupExam, 'userExams' | 'content'> & {
    content: Omit<GroupExamContent, 'problems' | 'problemHeaders'>;
  };
} {
  const groupExamStatusOf = groupExamStatusOfProvider();
  const groupExam = requiredNonNull(e.groupExam, 'userExam.groupExam');
  const scheduledStart = localDateTimeFromString(groupExam.scheduledStart);
  const scheduledFinish = ifDefined(groupExam.scheduledFinish, localDateTimeFromString);
  const finishedAt = ifDefined(groupExam.finishedAt, localDateTimeFromString);
  return {
    id: e.id,
    userId: e.userId,
    status: e.status.toLocaleLowerCase() as UserExamStatus,
    startedAt: ifDefined(e.startedAt, localDateTimeFromString),
    finishedAt: ifDefined(e.finishedAt, localDateTimeFromString),
    groupExam: {
      id: groupExam.id,
      groupId: groupExam.groupId,
      content: {
        ...groupExam.content,
        version: groupExam.content.version ?? 1,
      },
      course: {
        ...groupExam.course,
        fontColorOnImage: ifDefined(
          groupExam.course.fontColorOnImage,
          (v) => v.toLowerCase() as CourseFontColorOnImage
        ),
      },
      scheduledStart,
      scheduledFinish,
      finishedAt,
      visibilityLevel: groupExam.visibilityLevel.toLocaleLowerCase() as ExamResultVisibilityLevel,
      times: groupExam.times,
      status: groupExamStatusOf(scheduledStart, scheduledFinish, finishedAt),
      timeLimit: groupExam.timeLimit,
      passingStandard: groupExam.passingStandard,
      userIdsToBeTested: groupExam.userIdsToBeTested ?? [],
    },
  };
}

function toUserExamWithGroupExam(e: AmplifyUserExam): UserExam & {
  groupExam: Omit<GroupExam, 'userExams'>;
} {
  const groupExamStatusOf = groupExamStatusOfProvider();
  const groupExam = requiredNonNull(e.groupExam, 'userExam.groupExam');
  const scheduledStart = localDateTimeFromString(groupExam.scheduledStart);
  const scheduledFinish = ifDefined(groupExam.scheduledFinish, localDateTimeFromString);
  const finishedAt = ifDefined(groupExam.finishedAt, localDateTimeFromString);
  return {
    id: e.id,
    userId: e.userId,
    status: e.status.toLocaleLowerCase() as UserExamStatus,
    startedAt: ifDefined(e.startedAt, localDateTimeFromString),
    finishedAt: ifDefined(e.finishedAt, localDateTimeFromString),
    groupExam: {
      id: groupExam.id,
      groupId: groupExam.groupId,
      content: {
        ...groupExam.content,
        version: groupExam.content.version ?? 1,
        problems: JSON.parse(groupExam.problemsJson),
        problemHeaders: (groupExam.problemHeaders ?? []).map((h) => ({
          id: h.id,
          body: h.body,
          createdAt: localDateTimeFromString(h.createdAt),
        })),
      },
      course: {
        ...groupExam.course,
        fontColorOnImage: ifDefined(
          groupExam.course.fontColorOnImage,
          (v) => v.toLowerCase() as CourseFontColorOnImage
        ),
      },
      scheduledStart,
      scheduledFinish,
      finishedAt,
      visibilityLevel: groupExam.visibilityLevel.toLocaleLowerCase() as ExamResultVisibilityLevel,
      times: groupExam.times,
      status: groupExamStatusOf(scheduledStart, scheduledFinish, finishedAt),
      timeLimit: groupExam.timeLimit,
      passingStandard: groupExam.passingStandard,
      userIdsToBeTested: groupExam.userIdsToBeTested ?? [],
    },
  };
}

function toActiveExam(userExam: AmplifyUserExam): ActiveExam {
  const groupExam = requiredNonNull(userExam.groupExam, 'userExam.groupExam');
  const startedAt = requiredNonNull(userExam.startedAt, 'userExam.startedAt');
  return {
    groupId: userExam.groupId,
    contentId: groupExam.content.id,
    courseId: groupExam.course.id,
    examId: userExam.id,
    startedAt: localDateTimeFromString(startedAt),
    visibilityLevel: groupExam.visibilityLevel.toLowerCase() as ExamResultVisibilityLevel,
  };
}

type AmplifyExamResultVersion1 = {
  id: ExamId;
  start: string;
  end: string;
  contentId: ContentId;
  userId: UserId;
  groupId: GroupId;
  courseId: CourseId;
  score: number;
  problemCount: number;
  answers: string;
  visibilityLevel: undefined;
  groupExamId: undefined;
  times: undefined;
  version: undefined;
};

type AmplifyExamResultVersion2 = {
  id: ExamId;
  start: string;
  end: string;
  contentId: ContentId;
  userId: UserId;
  groupId: GroupId;
  courseId: CourseId;
  score: number;
  problemCount: number;
  answers: string;
  visibilityLevel: AmplifyExamResultVisibilityLevel;
  groupExamId: GroupExamId;
  times: number;
  version: undefined;
};

type AmplifyExamResultVersion3 = {
  id: ExamId;
  start: string;
  end: string;
  contentId: ContentId;
  contentVersion: ContentVersion;
  userId: UserId;
  groupId: GroupId;
  courseId: CourseId;
  score: number;
  problemCount: number;
  answers: string;
  visibilityLevel: AmplifyExamResultVisibilityLevel;
  groupExamId: GroupExamId;
  times: number;
  version: '3';
  passingStandard?: number;
};

type AmplifyExamResult =
  | AmplifyExamResultVersion1
  | AmplifyExamResultVersion2
  | AmplifyExamResultVersion3;

function toMyExamResult(e: AmplifyExamResult): MyExamResult {
  const visibilityLevel = ifDefined(
    e.visibilityLevel,
    (v) => v.toLocaleLowerCase() as ExamResultVisibilityLevel
  );
  if (!isDefined(visibilityLevel)) {
    return {
      id: e.id,
      contentId: e.contentId,
      userId: e.userId,
      groupId: e.groupId,
      courseId: e.courseId,
      score: e.score,
      problemCount: e.problemCount,
      start: localDateTimeFromString(e.start),
      end: localDateTimeFromString(e.end),
      answers: JSON.parse(e.answers) as Array<ExamResultAnswer>,
      visibilityLevel: 'details',
      version: 1,
    };
  }
  const groupExamId = requiredNonNull(e.groupExamId, 'examResult.groupExamId');
  const times = requiredNonNull(e.times, 'examResult.times');

  if (visibilityLevel === 'invisible_to_user') {
    const versionAndIsPassed:
      | { version: 2 }
      | {
          version: 3;
          isPassed?: boolean;
          contentVersion: ContentVersion;
        } =
      e.version === undefined
        ? { version: 2 }
        : {
            version: 3,
            isPassed: ifDefined(e.passingStandard, (passingStandard) => e.score >= passingStandard),
            contentVersion: e.contentVersion,
          };

    return {
      id: e.id,
      contentId: e.contentId,
      userId: e.userId,
      groupId: e.groupId,
      courseId: e.courseId,
      problemCount: e.problemCount,
      start: localDateTimeFromString(e.start),
      end: localDateTimeFromString(e.end),
      visibilityLevel,
      groupExamId,
      times,
      ...versionAndIsPassed,
    };
  }
  const versionAndIsPassed:
    | { version: 2 }
    | {
        version: 3;
        isPassed?: boolean;
        passingStandard?: number;
        contentVersion: ContentVersion;
      } =
    e.version === undefined
      ? { version: 2 }
      : {
          version: 3,
          isPassed: ifDefined(e.passingStandard, (passingStandard) => e.score >= passingStandard),
          passingStandard: e.passingStandard,
          contentVersion: e.contentVersion,
        };

  if (visibilityLevel === 'details') {
    return {
      id: e.id,
      contentId: e.contentId,
      userId: e.userId,
      groupId: e.groupId,
      courseId: e.courseId,
      score: e.score,
      problemCount: e.problemCount,
      start: localDateTimeFromString(e.start),
      end: localDateTimeFromString(e.end),
      answers: JSON.parse(e.answers) as Array<ExamResultAnswer>,
      visibilityLevel,
      groupExamId,
      times,
      ...versionAndIsPassed,
    };
  }
  return {
    id: e.id,
    contentId: e.contentId,
    userId: e.userId,
    groupId: e.groupId,
    courseId: e.courseId,
    score: e.score,
    problemCount: e.problemCount,
    start: localDateTimeFromString(e.start),
    end: localDateTimeFromString(e.end),
    visibilityLevel,
    groupExamId,
    times,
    ...versionAndIsPassed,
  };
}

function toExamResult(e: AmplifyExamResult): ExamResult {
  const visibilityLevel = ifDefined(
    e.visibilityLevel,
    (v) => v.toLocaleLowerCase() as ExamResultVisibilityLevel
  );
  if (!isDefined(visibilityLevel)) {
    return {
      id: e.id,
      contentId: e.contentId,
      userId: e.userId,
      groupId: e.groupId,
      courseId: e.courseId,
      score: e.score,
      problemCount: e.problemCount,
      start: localDateTimeFromString(e.start),
      end: localDateTimeFromString(e.end),
      answers: JSON.parse(e.answers) as Array<ExamResultAnswer>,
      visibilityLevel: 'details',
      version: 1,
    };
  }
  const groupExamId = requiredNonNull(e.groupExamId, 'examResult.groupExamId');
  const times = requiredNonNull(e.times, 'examResult.times');
  if (e.version === undefined) {
    return {
      id: e.id,
      contentId: e.contentId,
      userId: e.userId,
      groupId: e.groupId,
      courseId: e.courseId,
      score: e.score,
      problemCount: e.problemCount,
      start: localDateTimeFromString(e.start),
      end: localDateTimeFromString(e.end),
      answers: JSON.parse(e.answers) as Array<ExamResultAnswer>,
      visibilityLevel,
      groupExamId,
      times,
      version: 2,
    };
  }
  return {
    id: e.id,
    contentId: e.contentId,
    contentVersion: e.contentVersion,
    userId: e.userId,
    groupId: e.groupId,
    courseId: e.courseId,
    score: e.score,
    problemCount: e.problemCount,
    start: localDateTimeFromString(e.start),
    end: localDateTimeFromString(e.end),
    answers: JSON.parse(e.answers) as Array<ExamResultAnswer>,
    visibilityLevel,
    groupExamId,
    times,
    version: 3,
    passingStandard: e.passingStandard,
    isPassed: ifDefined(e.passingStandard, (passingStandard) => e.score >= passingStandard),
  };
}

function toGroupExamProvider(): (e: AmplifyGroupExam) => GroupExam {
  const groupExamStatusOf = groupExamStatusOfProvider();
  return (e: AmplifyGroupExam) => {
    const scheduledStart = localDateTimeFromString(e.scheduledStart);
    const scheduledFinish = ifDefined(e.scheduledFinish, localDateTimeFromString);
    const finishedAt = ifDefined(e.finishedAt, localDateTimeFromString);
    const status = groupExamStatusOf(scheduledStart, scheduledFinish, finishedAt);
    const userExams = requiredNonNull(e.userExams, 'groupExam.userExams');
    const groupExam: GroupExam = {
      id: e.id,
      groupId: e.groupId,
      content: {
        ...e.content,
        version: e.content.version ?? 1,
        problems: JSON.parse(e.problemsJson) as Array<GroupExamProblem>,
        problemHeaders: (e.problemHeaders ?? []).map((h) => ({
          id: h.id,
          body: h.body,
          createdAt: localDateTimeFromString(h.createdAt),
        })),
      },
      course: {
        id: e.course.id,
        name: e.course.name,
        version: e.course.version,
        color: e.course.color,
        image: e.course.image,
        fontColorOnImage: ifDefined(
          e.course.fontColorOnImage,
          (v) => v.toLowerCase() as CourseFontColorOnImage
        ),
      },
      scheduledStart,
      scheduledFinish,
      finishedAt,
      status,
      visibilityLevel: e.visibilityLevel.toLocaleLowerCase() as ExamResultVisibilityLevel,
      userExams: userExams.items.map((ue) => ({
        id: ue.id,
        userId: ue.userId,
        status: ue.status.toLocaleLowerCase() as UserExamStatus,
        startedAt: ifDefined(ue.startedAt, localDateTimeFromString),
        finishedAt: ifDefined(ue.finishedAt, localDateTimeFromString),
      })),
      times: e.times,
      timeLimit: e.timeLimit,
      passingStandard: e.passingStandard,
      userIdsToBeTested: e.userIdsToBeTested ?? [],
    };
    return groupExam;
  };
}

export class AmplifyExamDataAdapter implements ExamDataAdapter {
  private appContextProvider: AppContextProvider;

  constructor(appContextProvider: AppContextProvider) {
    this.appContextProvider = appContextProvider;
  }

  async findActiveExam(): Promise<Optional<ActiveExam>> {
    const { user } = this.appContextProvider.get();
    assertIsDefined(user, 'globalContext.user');
    const res = await graphql<{ userExamsByUser: { items: Array<AmplifyUserExam> } }>(
      customQueries.userExamsByUser,
      {
        userId: user.id,
        status: {
          eq: 'IN_PROGRESS',
        },
      }
    );
    return res.userExamsByUser.items.map(toActiveExam)[0];
  }

  async findExamResults(args: ExamDataAdapterFindExamResultsArgs): Promise<Array<ExamResult>> {
    const { groupId } = args;

    const filter = {
      score: {
        ne: null,
      },
    };

    if (hasNonNullProperty(args, 'userId')) {
      const { userId } = args;
      const res = await graphqlQuery<{ examResultsByUserId: { items: Array<AmplifyExamResult> } }>(
        queries.examResultsByUserId,
        {
          groupId,
          userId: { eq: userId },
          filter,
        },
        {
          limit: 10000,
        }
      );
      return res.examResultsByUserId.items.map((e) => toExamResult(e));
    }
    if (hasNonNullProperty(args, 'contentId')) {
      const { contentId } = args;
      const res = await graphqlQuery<{
        examResultsByGroupAndContent: { items: Array<AmplifyExamResult> };
      }>(
        queries.examResultsByGroupAndContent,
        {
          groupId,
          contentId: { eq: contentId },
          filter,
        },
        {
          limit: 10000,
        }
      );
      return res.examResultsByGroupAndContent.items.map((e) => toExamResult(e));
    }

    const { courseId } = args;
    const res = await graphqlQuery<{
      examResultsByGroupAndCourse: { items: Array<AmplifyExamResult> };
    }>(
      queries.examResultsByGroupAndCourse,
      {
        groupId,
        courseId: { eq: courseId },
        filter,
      },
      {
        limit: 10000,
      }
    );
    return res.examResultsByGroupAndCourse.items.map((e) => toExamResult(e));
  }

  async findExamResultById(id: ExamId): Promise<Optional<ExamResult>> {
    const res = await graphql<{ getExamResult: Optional<AmplifyExamResult> }>(
      queries.getExamResult,
      {
        id,
      }
    );
    return ifDefined(res.getExamResult, (er) => {
      if (isDefined(er.score)) {
        return toExamResult(er);
      }
      return undefined;
    });
  }

  async findExamResultsByGroupExamId(groupExamId: GroupExamId): Promise<ExamResult[]> {
    const res = await graphqlQuery<{
      examResultsByGroupExam: { items: Array<AmplifyExamResult> };
    }>(
      queries.examResultsByGroupExam,
      {
        groupExamId,
      },
      {
        limit: 10000,
      }
    );
    return res.examResultsByGroupExam.items.map((e) => toExamResult(e));
  }

  async findUserExam(
    id: ExamId
  ): Promise<Optional<UserExam & { groupExam: Omit<GroupExam, 'userExams'> }>> {
    const { user } = this.appContextProvider.get();
    assertIsDefined(user, 'appContext.user');
    const res = await graphqlQuery<{ getUserExam: AmplifyUserExam }>(customQueries.getUserExam, {
      id,
    });
    const userExam = ifDefined(res.getUserExam, toUserExamWithGroupExam);
    if (res.getUserExam.userId === user.id) {
      return userExam;
    }
    return undefined;
  }

  async findNotFinishedUserExams({
    groupId,
    userId,
  }: {
    groupId?: GroupId;
    userId: UserId;
  }): Promise<Array<ExamDataAdapterFindNotFinishedUserExam>> {
    if (groupId) {
      const res = await graphqlQuery<{
        userExamsByGroupAndUser: { items: Array<AmplifyUserExam> };
      }>(
        customQueries.userExamsByGroupAndUser,
        {
          groupId,
          userId: { eq: userId },
          filter: {
            or: [
              {
                status: {
                  eq: 'IN_PROGRESS',
                },
              },
              {
                status: {
                  eq: 'NOT_STARTED',
                },
              },
            ],
          },
        },
        {
          limit: 100,
        }
      );
      return res.userExamsByGroupAndUser.items.map(toExamDataAdapterFindNotFinishedUserExam);
    }
    // UseExam.statusはindexに含まれるため、filterでor条件として指定できない。
    const res = await graphqlQuery<{ userExamsByUser: { items: Array<AmplifyUserExam> } }>(
      customQueries.userExamsByUser,
      {
        userId,
        filter: {
          finishedAt: {
            attributeExists: false,
          },
        },
      },
      {
        limit: 100,
      }
    );
    return res.userExamsByUser.items
      .map(toExamDataAdapterFindNotFinishedUserExam)
      .filter((ue) => ue.status === 'in_progress' || ue.status === 'not_started');
  }

  async findGroupExamsByGroupId(
    groupId: GroupId,
    options: { inProgress: boolean } | { inProgressOrScheduled: boolean } = { inProgress: false }
  ): Promise<Array<GroupExam>> {
    const now = localDateTimeNow().toISOString();
    const res = await (async () => {
      if (hasProperty(options, 'inProgress') && options.inProgress) {
        return graphqlQuery<{ groupExamsByGroup: { items: Array<AmplifyGroupExam> } }>(
          customQueries.groupExamsByGroup,
          {
            groupId,
            filter: {
              scheduledStart: { lt: now },
              scheduledFinish: { gt: now },
              finishedAt: { attributeExists: false },
            },
          },
          {
            limit: 10000,
          }
        );
      }
      if (hasProperty(options, 'inProgressOrScheduled') && options.inProgressOrScheduled) {
        return graphqlQuery<{ groupExamsByGroup: { items: Array<AmplifyGroupExam> } }>(
          customQueries.groupExamsByGroup,
          {
            groupId,
            filter: {
              scheduledFinish: { gt: now },
              finishedAt: { attributeExists: false },
            },
          },
          {
            limit: 10000,
          }
        );
      }
      return graphqlQuery<{ groupExamsByGroup: { items: Array<AmplifyGroupExam> } }>(
        customQueries.groupExamsByGroup,
        {
          groupId,
        },
        {
          limit: 10000,
        }
      );
    })();
    const toGroupExam = toGroupExamProvider();
    return res.groupExamsByGroup.items.map(toGroupExam);
  }

  async findGroupExamById(groupExamId: GroupExamId): Promise<Optional<GroupExam>> {
    const res = await graphqlQuery<{ getGroupExam?: AmplifyGroupExam }>(
      customQueries.getGroupExam,
      {
        id: groupExamId,
      }
    );
    const toGroupExam = toGroupExamProvider();
    return ifDefined(res.getGroupExam, toGroupExam);
  }

  async findMyExamResults(groupId: GroupId): Promise<Array<MyExamResult>> {
    const { user } = this.appContextProvider.get();
    assertIsDefined(user, 'globalContext.user');
    const filter = {
      score: {
        ne: null,
      },
      userId: {
        eq: user.id,
      },
    };
    const res = await graphqlQuery<{
      examResultsByGroupAndContent: { items: Array<AmplifyExamResult> };
    }>(
      queries.examResultsByGroupAndContent,
      {
        groupId,
        filter,
      },
      {
        limit: 10000,
      }
    );
    return res.examResultsByGroupAndContent.items.map((e) => toMyExamResult(e));
  }

  async findMyExamResultById(id: ExamId): Promise<Optional<MyExamResult>> {
    const { user } = this.appContextProvider.get();
    assertIsDefined(user, 'globalContext.user');
    const res = await graphql<{ getExamResult: Optional<AmplifyExamResult> }>(
      queries.getExamResult,
      {
        id,
      }
    );
    return ifDefined(res.getExamResult, (er) => {
      if (isDefined(er.score) && er.userId === user.id) {
        return toMyExamResult(er);
      }
      return undefined;
    });
  }

  async findGroupExamsByGroupIdAndCourseId(
    groupId: GroupId,
    courseId: CourseId
  ): Promise<GroupExam[]> {
    const res = await graphqlQuery<{
      groupExamsByGroupAndCourse: { items: Array<AmplifyGroupExam> };
    }>(
      customQueries.groupExamsByGroupAndCourse,
      {
        groupId,
        courseId: {
          eq: courseId,
        },
      },
      {
        limit: 10000,
      }
    );
    const toGroupExam = toGroupExamProvider();
    return res.groupExamsByGroupAndCourse.items.map(toGroupExam);
  }

  async findOpenGroupExamsByCourseId(courseId: CourseId, groupId?: GroupId): Promise<GroupExam[]> {
    const now = localDateTimeNow().toISOString();

    const filter: ModelGroupExamFilterInput = groupId
      ? {
          scheduledFinish: { gt: now },
          finishedAt: { attributeExists: false },
          groupId: {
            eq: groupId,
          },
        }
      : {
          scheduledFinish: { gt: now },
          finishedAt: { attributeExists: false },
        };
    const res = await graphqlQuery<{
      groupExamsByCourse: { items: Array<AmplifyGroupExam> };
    }>(
      customQueries.groupExamsByCourse,
      {
        courseId,
        filter,
      },
      {
        limit: 10000,
      }
    );
    const toGroupExam = toGroupExamProvider();
    return res.groupExamsByCourse.items.map(toGroupExam);
  }
}
