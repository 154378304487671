import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

export type SignUpType = 'email' | 'user_code';

export type TenantCode = string;
export type TenantName = string;
export type Limitation = 'enabled' | 'disabled';
export type TenantLimitation = {
  question: Limitation;
  schedule: Limitation;
  questionnaire: Limitation;
  multilingualSupport: Limitation;
  tenantOwnTermsOfService: Limitation;
};

export type Tenant = {
  code: TenantCode;
  name: TenantName;
  signUpType: SignUpType;
  playground: boolean;
  userLimit?: number;
  limitations: TenantLimitation;
};

export interface TenantDataAdapter {
  get(): Promise<Tenant>;
  findByTenantCode(tenantCode: TenantCode): Promise<Tenant>;
}

export const TenantDataAdapterKey = injectionKeyOf<TenantDataAdapter>({
  boundedContext: 'base',
  type: 'adapter',
  name: 'TenantDataAdapter',
});

export function useTenantDataAdapter(): TenantDataAdapter {
  return requiredInject(TenantDataAdapterKey);
}
