import { hasNonNullProperty, hasProperty } from '@/utils/TsUtils';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { Optional } from '../types';
import { Email, Password } from './Base';
import { UserId } from './Core';
import { GroupId } from './Group';
import { SignedInUser } from './SignedInUser';
import { SignUpReservationId } from './SignUpReservation';
import { TenantCode, TenantName } from './Tenant';
import { TermsOfServiceId } from './TermsOfService';
import { GroupRole, Role, UserCode, UserName } from './User';

type AuthServiceSvSignUpRequest = {
  tenantCode: TenantCode;
  tenantName: TenantName;
  userCode?: UserCode;
  email: Email;
  userName: UserName;
  password: Password;
  termsOfServiceId: TermsOfServiceId;
};

type AuthServiceSignUpWithUserCodeRequest = {
  tenantCode: TenantCode;
  userCode: UserCode;
  userName: string;
  password: Password;
};

type AuthServiceSignUpWithEmailRequest = {
  tenantCode: TenantCode;
  email: Email;
  userName: string;
  password: Password;
};

export type AuthServiceSignUpRequest =
  | AuthServiceSvSignUpRequest
  | AuthServiceSignUpWithUserCodeRequest
  | AuthServiceSignUpWithEmailRequest;

export function isAuthServiceSvSignUpRequest(
  request: AuthServiceSignUpRequest
): request is AuthServiceSvSignUpRequest {
  return hasNonNullProperty(request, 'tenantName');
}

export function isAuthServiceSignUpWithUserCodeRequest(
  request: AuthServiceSignUpRequest
): request is AuthServiceSignUpWithUserCodeRequest {
  return !isAuthServiceSvSignUpRequest(request) && hasNonNullProperty(request, 'userCode');
}

export function isAuthServiceSignUpWithEmailRequest(
  request: AuthServiceSignUpRequest
): request is AuthServiceSignUpWithEmailRequest {
  return !isAuthServiceSvSignUpRequest(request) && hasNonNullProperty(request, 'email');
}

export type ConfirmSignUpRequest = {
  userId: string;
  code: string;
};

type AuthServiceSignInWithUserCodeRequest = {
  userCode: UserCode;
  password: Password;
  tenantCode: TenantCode;
};

type AuthServiceSignInWithEmailRequest = {
  email: Email;
  password: Password;
  userEmailConformationToken?: string;
};

export type AuthServiceSignInRequest =
  | AuthServiceSignInWithUserCodeRequest
  | AuthServiceSignInWithEmailRequest;

export function isAuthServiceSignInWithEmailRequest(
  request: AuthServiceSignInRequest
): request is AuthServiceSignInWithEmailRequest {
  return hasProperty(request, 'email');
}

export type AuthServiceReserveSignUpArgs =
  | {
      userCode: UserCode;
      role: Role;
      userName?: UserName;
      groups?: { id: GroupId; role: GroupRole }[];
    }
  | {
      email: Email;
      role: Role;
      userName?: UserName;
      groups?: { id: GroupId; role: GroupRole }[];
    };

export type AuthServiceChangePasswordArgs = {
  email: Email;
  code: string;
  password: Password;
};

/**
 * 認証サービス
 */
export interface AuthService {
  /**
   * サインイン
   * @param request リクエスト
   */
  signIn(
    request: AuthServiceSignInRequest
  ): Promise<
    | (Omit<SignedInUser, 'groups'> & { signedInAtLeastOnce: boolean })
    | ((
        newPassword: string
      ) => Promise<Omit<SignedInUser, 'groups'> & { signedInAtLeastOnce: boolean }>)
  >;

  /**
   * サインアウト
   */
  signOut(): Promise<void>;

  /**
   * サインアップ
   * @param request リクエスト
   */
  signUp(request: AuthServiceSignUpRequest): Promise<UserId>;

  /**
   * サインアップ確認
   * @param request リクエスト
   */
  confirmSignUp(request: ConfirmSignUpRequest): Promise<void>;

  /**
   * サインアップトークン再送信
   * @param userId ユーザーID
   */
  resendSignUp(userId: UserId): Promise<void>;

  /**
   * 現在のユーザー
   */
  currentUser(): Promise<Optional<Omit<SignedInUser, 'groups'> & { signedInAtLeastOnce: boolean }>>;

  /**
   * サインアップを予約する
   * @param args 引数
   */
  reserveSignUp(args: AuthServiceReserveSignUpArgs): Promise<void>;

  /**
   * サインアップ予約を削除する
   * @param id サインアップ予約ID
   */
  removeSignUpReservation(id: SignUpReservationId): Promise<void>;

  /**
   * パスワードを忘れた
   * @param email email
   */
  forgotPassword(email: Email): Promise<void>;

  /**
   * パスワードを変更する
   * @param args 引数
   */
  changePassword(args: AuthServiceChangePasswordArgs): Promise<void>;

  /**
   * 管理者がユーザーのパスワードを変更する
   * @param args 引数
   */
  changePasswordByAdmin(args: {
    id: UserId;
    password: Password;
    forcePasswordChange: boolean;
  }): Promise<void>;

  /**
   * 管理者がユーザーを作成する
   * @param args 引数
   */
  createUserByAdmin(args: {
    name: UserName;
    email?: Email;
    code?: UserCode;
    password: Password;
    role: Role;
    groups?: {
      id: GroupId;
      role: GroupRole;
    }[];
    forcePasswordChange: boolean;
  }): Promise<UserId>;
}

export const AuthServiceKey = injectionKeyOf<AuthService>({
  boundedContext: 'base',
  type: 'adapter',
  name: 'AuthService',
});

export function useAuthService(): AuthService {
  return requiredInject(AuthServiceKey);
}
