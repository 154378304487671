import {
  AuthorizationService,
  ContentFinder,
  ExamDataAdapter,
  GroupTrainingFinder,
} from '@/base/domains';
import {
  AbstractUseCase,
  assertEntityExists,
  GetGroupExams,
  GetGroupExamsRequest,
  GetGroupExamsResponse,
} from '@/base/usecases';
import { assertIsDefined } from '@/utils/Asserts';

import { GroupExamRepository } from '../domains';

export class GetGroupExamsImpl
  extends AbstractUseCase<GetGroupExamsRequest, GetGroupExamsResponse>
  implements GetGroupExams
{
  constructor(
    private authorizationService: AuthorizationService,
    private groupExamRepository: GroupExamRepository,
    private groupTrainingFinder: GroupTrainingFinder,
    private examDataAdapter: ExamDataAdapter,
    private contentFinder: ContentFinder
  ) {
    super('training.GetGroupExamsImpl');
  }

  async internalExecute(request: GetGroupExamsRequest): Promise<GetGroupExamsResponse> {
    const { groupId, courseId, contentId } = request;
    this.authorizationService.assertGroupReadAccessible(groupId);
    const [groupExams, course, examResults] = await Promise.all([
      this.groupExamRepository.findGroupExamsByGroupIdAndContentId(groupId, contentId),
      this.groupTrainingFinder.findCourseByGroupIdAndCourseId(groupId, courseId),
      this.examDataAdapter.findExamResults({
        groupId,
        contentId,
      }),
    ]);
    assertEntityExists(course, 'course');
    const courseContent = course.contents.find((cn) => cn.id === contentId);
    assertIsDefined(courseContent, 'course.contents');
    const content = await this.contentFinder.findById(courseContent.id, courseContent.version);
    assertIsDefined(content, 'content');
    if (content.type !== 'exam') {
      throw new Error('content.type should be exam');
    }
    return {
      groupExams,
      course: {
        id: course.id,
        name: course.displayName,
      },
      content: {
        id: content.id,
        name: content.name,
        open: courseContent.open,
        requiredTime: content.requiredTime,
        problemCount: content.body.problems.length,
        passingStandard: content.body.passingStandard,
      },
      examResults,
    };
  }
}
