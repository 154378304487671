import { AppContextProvider, QuestionContentReference, UserId } from '@/base/domains';
import { MarkDownString } from '@/base/types';
import { AbstractUseCase, UseCase, UseCaseResponse } from '@/base/usecases';
import { assertIsDefined } from '@/utils/Asserts';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { QuestionData, QuestionReference, QuestionRepository } from '../domains';

export type AskQuestionComment = {
  body: MarkDownString;
};

export interface AskQuestionRequest {
  comment: AskQuestionComment;
  title: string;
  referTo?: QuestionContentReference;
  assignees: Array<UserId>;
}

export type AskQuestionResponse = {
  question: QuestionReference;
};

/**
 * 質問する
 */
export interface AskQuestion extends UseCase<AskQuestionRequest, AskQuestionResponse> {
  execute(request: AskQuestionRequest): Promise<UseCaseResponse<AskQuestionResponse>>;
}

export class AskQuestionImpl
  extends AbstractUseCase<AskQuestionRequest, AskQuestionResponse>
  implements AskQuestion
{
  private appContextProvider: AppContextProvider;

  private questionRepository: QuestionRepository;

  constructor(appContextProvider: AppContextProvider, questionRepository: QuestionRepository) {
    super('training.AskQuestion');
    this.appContextProvider = appContextProvider;
    this.questionRepository = questionRepository;
  }

  async internalExecute(request: AskQuestionRequest): Promise<AskQuestionResponse> {
    const appContext = this.appContextProvider.get();
    const userId = appContext.user?.id;
    const { groupId } = appContext;
    assertIsDefined(userId, 'appContext.user.id');
    assertIsDefined(groupId, 'appContext.groupId');
    const { comment, title, referTo, assignees } = request;
    const question: QuestionData = {
      groupId,
      referTo,
      resolved: false,
      title,
      comments: [
        {
          body: comment.body,
          createdBy: userId,
          groupId,
        },
      ],
      createdBy: userId,
      assignees,
    };
    const saved = await this.questionRepository.save(question);

    return {
      question: saved,
    };
  }
}

export const AskQuestionKey = injectionKeyOf<AskQuestion>({
  boundedContext: 'training',
  type: 'usecase',
  name: 'AskQuestion',
});

export function useAskQuestion(): AskQuestion {
  return requiredInject(AskQuestionKey);
}
