import {
  GroupId,
  GroupLimitation,
  GroupName,
  GroupRole,
  Role,
  UserId,
  UserName,
  UserStatus,
} from '@/base/domains';
import { URI } from '@/base/types';

export type GlobalStoreUser = {
  id: UserId;
  name: UserName;
  role: Role;
  groups: Array<{ id: GroupId; role: GroupRole }>;
  avatar?: URI;
  status: UserStatus;
  enabled: boolean;
};

export type UserAndGroupRole = {
  id: UserId;
  groupId: GroupId;
  name: UserName;
  groupRole: GroupRole;
  role: Role;
  groupName: GroupName;
};

export type GlobalStoreGroup = {
  id: GroupId;
  name: GroupName;
  description?: string;
  enabled: boolean;
  users: Array<GlobalStoreGroupUser>;
  limitations: GroupLimitation;
};

export type GlobalStoreGroupUser = {
  id: UserId;
  role: GroupRole;
  name: UserName;
  avatar?: URI;
  status?: UserStatus;
  removed: boolean;
};

export const GlobalStoreLimitationNames = [
  'schedule',
  'question',
  'questionnaire',
  'tenantOwnTermsOfService',
] as const;
export type GlobalStoreLimitationName = typeof GlobalStoreLimitationNames[number];
