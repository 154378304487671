import {
  Email,
  SignUpReservation,
  SignUpReservationQueries,
  TenantCode,
  UserCode,
} from '@/base/domains';
import { AbstractUseCase, UseCase, UseCaseResponse } from '@/base/usecases';
import { hasNonNullProperty } from '@/utils/TsUtils';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

export type GetSignUpReservationRequest =
  | {
      email: Email;
    }
  | {
      userCode: UserCode;
      tenantCode: TenantCode;
    };

export type GetSignUpReservationResponse = {
  signUpReservation?: SignUpReservation;
};

export interface GetSignUpReservation
  extends UseCase<GetSignUpReservationRequest, GetSignUpReservationResponse> {
  execute(
    request: GetSignUpReservationRequest
  ): Promise<UseCaseResponse<GetSignUpReservationResponse>>;
}

export class GetSignUpReservationImpl
  extends AbstractUseCase<GetSignUpReservationRequest, GetSignUpReservationResponse>
  implements GetSignUpReservation
{
  constructor(private signUpReservationQueries: SignUpReservationQueries) {
    super('account.GetSignUpReservation');
  }

  async internalExecute(
    request: GetSignUpReservationRequest
  ): Promise<GetSignUpReservationResponse> {
    if (hasNonNullProperty(request, 'email')) {
      const res = await this.signUpReservationQueries.findByEmail(request.email);
      return { signUpReservation: res };
    }
    const res = await this.signUpReservationQueries.findByUserCode(
      request.userCode,
      request.tenantCode
    );
    return { signUpReservation: res };
  }
}

export const GetSignUpReservationKey = injectionKeyOf<GetSignUpReservation>({
  boundedContext: 'account',
  type: 'usecase',
  name: 'GetSignUpReservation',
});

export function useGetSignUpReservation(): GetSignUpReservation {
  return requiredInject(GetSignUpReservationKey);
}
