import { AppContextProvider, QuestionId } from '@/base/domains';
import { AUTHORIZATION_ERROR } from '@/base/ErrorCodes';
import { AbstractUseCase, assertEntityExists, UseCase, UseCaseResponse } from '@/base/usecases';
import { assertIsDefined } from '@/utils/Asserts';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { QuestionReference, QuestionRepository } from '../domains';

export type ChangeQuestionTitleRequest = {
  id: QuestionId;
  title: string;
};

export type ChangeQuestionTitleResponse = {
  question: QuestionReference;
};

export interface ChangeQuestionTitle
  extends UseCase<ChangeQuestionTitleRequest, ChangeQuestionTitleResponse> {
  execute(
    request: ChangeQuestionTitleRequest
  ): Promise<UseCaseResponse<ChangeQuestionTitleResponse>>;
}

export class ChangeQuestionTitleImpl
  extends AbstractUseCase<ChangeQuestionTitleRequest, ChangeQuestionTitleResponse>
  implements ChangeQuestionTitle
{
  constructor(
    private appContextProvider: AppContextProvider,
    private questionRepository: QuestionRepository
  ) {
    super('training.Change');
  }

  async internalExecute({
    id,
    title,
  }: ChangeQuestionTitleRequest): Promise<ChangeQuestionTitleResponse> {
    const question = await this.questionRepository.findById(id);
    assertEntityExists(question, 'question');
    const appContext = this.appContextProvider.get();
    const { user } = appContext;
    assertIsDefined(user, 'appContext.user');
    const groupRole = appContext.roleInGroup(question.groupId);
    const isTrainer = groupRole === 'trainer' || groupRole === 'mentor';
    if (!isTrainer && question.createdBy !== user.id) {
      throw AUTHORIZATION_ERROR.toApplicationError({
        message: 'should be a trainer, a mentor or a creator',
      });
    }
    const saved = await this.questionRepository.save(question.changeTitle(title));
    return {
      question: saved,
    };
  }
}

export const ChangeQuestionTitleKey = injectionKeyOf<ChangeQuestionTitle>({
  boundedContext: 'training',
  type: 'usecase',
  name: 'ChangeQuestionTitle',
});

export function useChangeQuestionTitle(): ChangeQuestionTitle {
  return requiredInject(ChangeQuestionTitleKey);
}
