import { CreateEditingCourseContentBodyInput, UpdateEditingCourseContentBodyInput } from '@/API';
import { AppContextProvider, ContentId, ContentType } from '@/base/domains';
import { Optional } from '@/base/types';
import {
  createEditingCourseContentBody,
  EditingCourseContentBodyData,
  EditingCourseContentBodyEntity,
  EditingCourseContentBodyRepository,
} from '@/contents/domains';
import * as mutations from '@/graphql/mutations';
import * as queries from '@/graphql/queries';
import { EditingCourseContentBody as AnAmplifyEditingCourseContentBody } from '@/models';
import { graphql } from '@/utils/AmplifyUtils';
import { hasNonNullProperty, requiredNonNull } from '@/utils/TsUtils';

import { toAmplifyContentBody, toExamContentBody, toTextContentBody } from './Content';

export type AmplifyEditingCourseContentBody = AnAmplifyEditingCourseContentBody;

export function toEditingCourseContentBody(
  e: AmplifyEditingCourseContentBody
): EditingCourseContentBodyEntity {
  const type = e.body.type.toLowerCase() as ContentType;
  if (type === 'exam') {
    return createEditingCourseContentBody({
      ...e,
      id: e.contentId,
      body: toExamContentBody(e.body),
      type,
      courseId: e.courseId,
    });
  }
  return createEditingCourseContentBody({
    ...e,
    id: e.contentId,
    body: toTextContentBody(e.body),
    type,
    courseId: e.courseId,
  });
}

export class AmplifyEditingCourseContentBodyRepository
  implements EditingCourseContentBodyRepository
{
  constructor(private appContextProvider: AppContextProvider) {}

  async save(
    args: EditingCourseContentBodyEntity | EditingCourseContentBodyData
  ): Promise<EditingCourseContentBodyEntity> {
    const tenantCode = requiredNonNull(
      this.appContextProvider.get().tenantCode,
      'appContext.tenantCode'
    );
    const userId = requiredNonNull(this.appContextProvider.get().user?.id, 'appContext.user.id');

    if (hasNonNullProperty(args, 'dataVersion')) {
      const input: UpdateEditingCourseContentBodyInput = {
        contentId: args.id,
        body: toAmplifyContentBody(args.body),
        createdBy: args.createdBy,
        updatedBy: userId,
        expectedDataVersion: args.dataVersion,
        tenantCode,
        courseId: args.courseId,
      };
      const response = await graphql<{
        updateEditingCourseContentBody: AmplifyEditingCourseContentBody;
      }>(mutations.updateEditingCourseContentBody, { input });
      return toEditingCourseContentBody(response.updateEditingCourseContentBody);
    }
    const input: CreateEditingCourseContentBodyInput = {
      contentId: args.id,
      body: toAmplifyContentBody(args.body),
      createdBy: userId,
      updatedBy: userId,
      tenantCode,
      courseId: args.courseId,
    };
    const response = await graphql<{
      createEditingCourseContentBody: AmplifyEditingCourseContentBody;
    }>(mutations.createEditingCourseContentBody, { input });
    return toEditingCourseContentBody(response.createEditingCourseContentBody);
  }

  async findById(id: ContentId): Promise<Optional<EditingCourseContentBodyEntity>> {
    const response = await graphql<{
      getEditingCourseContentBody: Optional<AmplifyEditingCourseContentBody>;
    }>(queries.getEditingCourseContentBody, { contentId: id });
    return response.getEditingCourseContentBody
      ? toEditingCourseContentBody(response.getEditingCourseContentBody)
      : undefined;
  }

  async remove(id: ContentId): Promise<void> {
    await graphql(mutations.deleteEditingCourseContentBody, { input: { contentId: id } });
  }
}
