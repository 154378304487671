import { AuthorizationService, ReviewProblemId } from '@/base/domains';
import { AbstractUseCase, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { ReviewProblemRepository } from '../domains';

export type FinishReviewProblemRequest = {
  id: ReviewProblemId;
  hashListToRemove: string[];
};

export type FinishReviewProblemResponse = {};

/**
 * 復習問題を終了する
 */
export interface FinishReviewProblem
  extends UseCase<FinishReviewProblemRequest, FinishReviewProblemResponse> {
  execute(
    request: FinishReviewProblemRequest
  ): Promise<UseCaseResponse<FinishReviewProblemResponse>>;
}

export class FinishReviewProblemImpl
  extends AbstractUseCase<FinishReviewProblemRequest, FinishReviewProblemResponse>
  implements FinishReviewProblem
{
  constructor(
    private authorizationService: AuthorizationService,
    private reviewProblemRepository: ReviewProblemRepository
  ) {
    super('training.FinishReviewProblem');
  }

  async internalExecute({
    id,
    hashListToRemove,
  }: FinishReviewProblemRequest): Promise<FinishReviewProblemResponse> {
    const reviewProblem = await this.reviewProblemRepository.findById(id);
    if (!reviewProblem) {
      return {};
    }
    this.authorizationService.assertOwnerAccessible(reviewProblem.userId);
    await this.reviewProblemRepository.save(reviewProblem.finish(hashListToRemove));
    return {};
  }
}

export const FinishReviewProblemKey = injectionKeyOf<FinishReviewProblem>({
  boundedContext: 'training',
  type: 'usecase',
  name: 'FinishReviewProblem',
});

export function useFinishReviewProblem(): FinishReviewProblem {
  return requiredInject(FinishReviewProblemKey);
}
