import {
  ContentId,
  ContentName,
  ContentType,
  CourseColor,
  CourseFontColorOnImage,
  CourseId,
  CourseName,
  CourseVersion,
  DataVersion,
  Entity,
  EntityData,
  EntityReference,
  StandardRepository,
  UserId,
} from '@/base/domains';
import { LocalDateTime, Minute, URI } from '@/base/types';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

export type EditingStatus = 'editing' | 'confirmed';

export type EditingCourseContentHeader = {
  /** コンテンツID */
  id: ContentId;
  /** コンテンツタイプ */
  type: ContentType;
  /** 名前 */
  name: ContentName;
  /** 所要時間 */
  requiredTime: Minute;
};

/**
 * 編集中コースID
 */
export type EditingCourseId = CourseId;

/**
 * 編集中コース属性
 */
export interface EditingCourseAttributes {
  name: CourseName;
  version: CourseVersion;
  status: EditingStatus;
  description?: string;
  contents: Array<EditingCourseContentHeader>;
  createdBy: UserId;
  createdAt: LocalDateTime;
  updatedBy: UserId;
  updatedAt: LocalDateTime;
  color?: CourseColor;
  image?: URI;
  fontColorOnImage?: CourseFontColorOnImage;
}

/**
 * 編集中コースデータ
 */
export type EditingCourseData = EntityData<EditingCourseId, EditingCourseAttributes>;

/**
 * 編集中コースコマンド
 */
export interface EditingCourseCommand {
  confirm(): EditingCourseEntity;
  changeContents(contents: Array<EditingCourseContentHeader>): EditingCourseEntity;
  changeName(name: CourseName): EditingCourseEntity;
  changeDescription(description: string): EditingCourseEntity;
  changeColor(color: CourseColor): EditingCourseEntity;
  changeImage(image: URI): EditingCourseEntity;
  changeFontColorOnImage(fontColorOnImage: CourseFontColorOnImage): EditingCourseEntity;
}

/**
 * 編集中コースクエリ
 */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface EditingCourseQueries {}

/**
 * 編集中コースリファレンス
 */
export type EditingCourseReference = EntityReference<
  CourseId,
  EditingCourseAttributes,
  EditingCourseQueries
>;

export interface EditingCourseEntity
  extends Entity<EditingCourseId, EditingCourseAttributes>,
    EditingCourseQueries,
    EditingCourseCommand {}

export type EditingCourseRepositoryRemoveContentArgs = {
  id: CourseId;
  contentId: ContentId;
  dataVersion: DataVersion;
};

/**
 * 編集中コースリポジトリ
 */
export interface EditingCourseRepository
  extends StandardRepository<EditingCourseId, EditingCourseAttributes, EditingCourseEntity> {
  tenantEditingCourses(): Promise<Array<EditingCourseReference>>;
}

export const EditingCourseRepositoryKey = injectionKeyOf<EditingCourseRepository>({
  boundedContext: 'contents',
  type: 'adapter',
  name: 'EditingCourseRepositoryKey',
});

export function useEditingCourseRepository(): EditingCourseRepository {
  return requiredInject(EditingCourseRepositoryKey);
}
