// =========================================================================
// UserExam
// =========================================================================
export const updateUserExam = /* GraphQL */ `
  mutation UpdateUserExam($input: UpdateUserExamInput!, $condition: ModelUserExamConditionInput) {
    updateUserExam(input: $input, condition: $condition) {
      id
      userId
      status
      tenantCode
      groupExamId
      groupId
      startedAt
      finishedAt
      createdAt
      updatedAt
      groupExam {
        id
        groupId
        contentId
        content {
          id
          name
          timeLimit
          requiredTime
          version
          passingStandard
        }
        problemsJson
        courseId
        course {
          id
          version
          name
          color
          image
        }
        scheduledStart
        scheduledFinish
        finishedAt
        visibilityLevel
        tenantCode
        times
        createdAt
        updatedAt
        passingStandard
      }
    }
  }
`;

// =========================================================================
// GroupTrainingCourse
// =========================================================================
export const createGroupTrainingCourse = /* GraphQL */ `
  mutation CreateGroupTrainingCourse(
    $input: CreateGroupTrainingCourseInput!
    $condition: ModelGroupTrainingCourseConditionInput
  ) {
    createGroupTrainingCourse(input: $input, condition: $condition) {
      id
      groupTrainingId
      courseId
      courseVersion
      courseName
      contents {
        id
        name
        requiredTime
        type
        recommendedDateTime
        open
        version
      }
      groupId
      tenantCode
      color
      image
      createdAt
      updatedAt
      groupExams {
        items {
          id
          contentId
          scheduledStart
          finishedAt
          times
          passingStandard
        }
        nextToken
      }
    }
  }
`;
export const updateGroupTrainingCourse = /* GraphQL */ `
  mutation UpdateGroupTrainingCourse(
    $input: UpdateGroupTrainingCourseInput!
    $condition: ModelGroupTrainingCourseConditionInput
  ) {
    updateGroupTrainingCourse(input: $input, condition: $condition) {
      id
      groupTrainingId
      courseId
      courseVersion
      courseName
      contents {
        id
        name
        requiredTime
        type
        recommendedDateTime
        open
        version
      }
      groupId
      tenantCode
      color
      image
      createdAt
      updatedAt
      groupExams {
        items {
          id
          contentId
          scheduledStart
          finishedAt
          times
          passingStandard
        }
        nextToken
      }
    }
  }
`;
export const deleteGroupTrainingCourse = /* GraphQL */ `
  mutation DeleteGroupTrainingCourse(
    $input: DeleteGroupTrainingCourseInput!
    $condition: ModelGroupTrainingCourseConditionInput
  ) {
    deleteGroupTrainingCourse(input: $input, condition: $condition) {
      id
      groupTrainingId
      courseId
      courseVersion
      courseName
      contents {
        id
        name
        requiredTime
        type
        recommendedDateTime
        open
        version
      }
      groupId
      tenantCode
      color
      image
      createdAt
      updatedAt
      groupExams {
        items {
          id
          contentId
          scheduledStart
          finishedAt
          times
          passingStandard
        }
        nextToken
      }
    }
  }
`;
// =========================================================================
// UserTag
// =========================================================================
export const createUserTag = /* GraphQL */ `
  mutation CreateUserTag($input: CreateUserTagInput!, $condition: ModelUserTagConditionInput) {
    createUserTag(input: $input, condition: $condition) {
      id
      color
      text
      tenantCode
      createdAt
      updatedAt
    }
  }
`;
export const updateUserTag = /* GraphQL */ `
  mutation UpdateUserTag($input: UpdateUserTagInput!, $condition: ModelUserTagConditionInput) {
    updateUserTag(input: $input, condition: $condition) {
      id
      color
      text
      tenantCode
      createdAt
      updatedAt
    }
  }
`;
export const deleteUserTag = /* GraphQL */ `
  mutation DeleteUserTag($input: DeleteUserTagInput!, $condition: ModelUserTagConditionInput) {
    deleteUserTag(input: $input, condition: $condition) {
      id
      color
      text
      tenantCode
      createdAt
      updatedAt
    }
  }
`;

// =========================================================================
// UserUserTag
// =========================================================================
export const updateUserUserTag = /* GraphQL */ `
  mutation UpdateUserUserTag(
    $input: UpdateUserUserTagInput!
    $condition: ModelUserUserTagConditionInput
  ) {
    updateUserUserTag(input: $input, condition: $condition) {
      id
      userId
      userTagId
      tenantCode
      createdAt
      updatedAt
    }
  }
`;
export const createUserUserTag = /* GraphQL */ `
  mutation CreateUserUserTag(
    $input: CreateUserUserTagInput!
    $condition: ModelUserUserTagConditionInput
  ) {
    createUserUserTag(input: $input, condition: $condition) {
      id
      userId
      userTagId
      tenantCode
      createdAt
      updatedAt
    }
  }
`;
export const deleteUserUserTag = /* GraphQL */ `
  mutation DeleteUserUserTag(
    $input: DeleteUserUserTagInput!
    $condition: ModelUserUserTagConditionInput
  ) {
    deleteUserUserTag(input: $input, condition: $condition) {
      id
      userId
      userTagId
      tenantCode
      createdAt
      updatedAt
    }
  }
`;

// =========================================================================
// CreateUser
// =========================================================================
export const createUser = /* GraphQL */ `
  mutation CreateUser($input: CreateUserInput!, $condition: ModelUserConditionInput) {
    createUser(input: $input, condition: $condition) {
      id
      name
      role
      extensionConfigJsonList
      email
      code
      tenantCode
      displaySettings {
        theme
        color
      }
      locale
      avatar
      enabled
      createdAt
      updatedAt
      tags {
        items {
          id
          userId
          userTagId
          userTag {
            id
            color
            text
            tenantCode
            createdAt
            updatedAt
          }
          tenantCode
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;

// =========================================================================
// UpdateUser
// =========================================================================
export const updateUser = /* GraphQL */ `
  mutation UpdateUser($input: UpdateUserInput!, $condition: ModelUserConditionInput) {
    updateUser(input: $input, condition: $condition) {
      id
      name
      role
      extensionConfigJsonList
      email
      code
      tenantCode
      displaySettings {
        theme
        color
      }
      locale
      avatar
      enabled
      createdAt
      updatedAt
      tags {
        items {
          id
          userId
          userTagId
          tenantCode
          createdAt
          updatedAt
          userTag {
            id
            color
            text
            tenantCode
            createdAt
            updatedAt
          }
        }
      }
      statuses {
        items {
          id
          tenantCode
          status
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;

// =========================================================================
// DeleteUser
// =========================================================================
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser($input: DeleteUserInput!, $condition: ModelUserConditionInput) {
    deleteUser(input: $input, condition: $condition) {
      id
      name
      role
      extensionConfigJsonList
      email
      code
      tenantCode
      displaySettings {
        theme
        color
      }
      locale
      avatar
      enabled
      createdAt
      updatedAt
    }
  }
`;

// =========================================================================
// GroupExam
// =========================================================================
export const createGroupExam = /* GraphQL */ `
  mutation CreateGroupExam(
    $input: CreateGroupExamInput!
    $condition: ModelGroupExamConditionInput
  ) {
    createGroupExam(input: $input, condition: $condition) {
      id
      groupId
      contentId
      content {
        id
        name
        timeLimit
        requiredTime
        indexInCourse
        passingStandard
      }
      problemsJson
      courseId
      course {
        id
        version
        name
        color
        image
      }
      scheduledStart
      scheduledFinish
      finishedAt
      visibilityLevel
      tenantCode
      times
      groupTrainingCourseId
      problemHeaders {
        id
        body
        createdAt
      }
      timeLimit
      createdAt
      updatedAt
      userExams {
        items {
          id
          userId
          status
          tenantCode
          groupExamId
          groupId
          startedAt
          finishedAt
          createdAt
          updatedAt
        }
        nextToken
      }
      passingStandard
      userIdsToBeTested
    }
  }
`;
export const updateGroupExam = /* GraphQL */ `
  mutation UpdateGroupExam(
    $input: UpdateGroupExamInput!
    $condition: ModelGroupExamConditionInput
  ) {
    updateGroupExam(input: $input, condition: $condition) {
      id
      groupId
      contentId
      content {
        id
        name
        timeLimit
        requiredTime
        indexInCourse
        passingStandard
      }
      problemsJson
      courseId
      course {
        id
        version
        name
        color
        image
      }
      scheduledStart
      scheduledFinish
      finishedAt
      visibilityLevel
      tenantCode
      times
      groupTrainingCourseId
      problemHeaders {
        id
        body
        createdAt
      }
      timeLimit
      createdAt
      updatedAt
      userExams {
        items {
          id
          userId
          status
          tenantCode
          groupExamId
          groupId
          startedAt
          finishedAt
          createdAt
          updatedAt
        }
        nextToken
      }
      passingStandard
      userIdsToBeTested
    }
  }
`;
export const deleteGroupExam = /* GraphQL */ `
  mutation DeleteGroupExam(
    $input: DeleteGroupExamInput!
    $condition: ModelGroupExamConditionInput
  ) {
    deleteGroupExam(input: $input, condition: $condition) {
      id
      groupId
      contentId
      content {
        id
        name
        timeLimit
        requiredTime
        indexInCourse
        passingStandard
      }
      problemsJson
      courseId
      course {
        id
        version
        name
        color
        image
      }
      scheduledStart
      scheduledFinish
      finishedAt
      visibilityLevel
      tenantCode
      times
      groupTrainingCourseId
      problemHeaders {
        id
        body
        createdAt
      }
      timeLimit
      createdAt
      updatedAt
      userExams {
        items {
          id
          userId
          status
          tenantCode
          groupExamId
          groupId
          startedAt
          finishedAt
          createdAt
          updatedAt
        }
        nextToken
      }
      passingStandard
      userIdsToBeTested
    }
  }
`;

// =========================================================================
// Schedule
// =========================================================================
export const deleteSchedule = /* GraphQL */ `
  mutation DeleteSchedule($input: DeleteScheduleInput!, $condition: ModelScheduleConditionInput) {
    deleteSchedule(input: $input, condition: $condition) {
      id
      groupId
      start
      end
      title
      body
      tenantCode
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
`;

// =========================================================================
// ScheduleTag
// =========================================================================
export const createScheduleTag = /* GraphQL */ `
  mutation CreateScheduleTag(
    $input: CreateScheduleTagInput!
    $condition: ModelScheduleTagConditionInput
  ) {
    createScheduleTag(input: $input, condition: $condition) {
      id
      color
      text
      groupId
      tenantCode
      createdAt
      updatedAt
    }
  }
`;

export const updateScheduleTag = /* GraphQL */ `
  mutation UpdateScheduleTag(
    $input: UpdateScheduleTagInput!
    $condition: ModelScheduleTagConditionInput
  ) {
    updateScheduleTag(input: $input, condition: $condition) {
      id
      color
      text
      groupId
      tenantCode
      createdAt
      updatedAt
    }
  }
`;

export const deleteScheduleTag = /* GraphQL */ `
  mutation DeleteScheduleTag(
    $input: DeleteScheduleTagInput!
    $condition: ModelScheduleTagConditionInput
  ) {
    deleteScheduleTag(input: $input, condition: $condition) {
      id
      color
      text
      groupId
      tenantCode
      createdAt
      updatedAt
    }
  }
`;

// =========================================================================
// UserStatus
// =========================================================================
export const createUserStatusTable = /* GraphQL */ `
  mutation CreateUserStatusTable(
    $input: CreateUserStatusTableInput!
    $condition: ModelUserStatusTableConditionInput
  ) {
    createUserStatusTable(input: $input, condition: $condition) {
      id
      tenantCode
      status
      userId
      createdAt
      updatedAt
      user {
        id
        name
        role
        extensionConfigJsonList
        email
        code
        tenantCode
        displaySettings {
          theme
          color
        }
        locale
        avatar
        enabled
        createdAt
        updatedAt
        tags {
          items {
            id
            userId
            userTagId
            userTag {
              id
              color
              text
              tenantCode
              createdAt
              updatedAt
            }
            tenantCode
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const updateUserStatusTable = /* GraphQL */ `
  mutation UpdateUserStatusTable(
    $input: UpdateUserStatusTableInput!
    $condition: ModelUserStatusTableConditionInput
  ) {
    updateUserStatusTable(input: $input, condition: $condition) {
      id
      tenantCode
      status
      userId
      createdAt
      updatedAt
      user {
        id
        name
        role
        extensionConfigJsonList
        email
        code
        tenantCode
        displaySettings {
          theme
          color
        }
        locale
        avatar
        enabled
        createdAt
        updatedAt
        tags {
          items {
            id
            userId
            userTagId
            userTag {
              id
              color
              text
              tenantCode
              createdAt
              updatedAt
            }
            tenantCode
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteUserStatusTable = /* GraphQL */ `
  mutation DeleteUserStatusTable(
    $input: DeleteUserStatusTableInput!
    $condition: ModelUserStatusTableConditionInput
  ) {
    deleteUserStatusTable(input: $input, condition: $condition) {
      id
      tenantCode
      status
      userId
      createdAt
      updatedAt
      user {
        id
        name
        role
        extensionConfigJsonList
        email
        code
        tenantCode
        displaySettings {
          theme
          color
        }
        locale
        avatar
        enabled
        createdAt
        updatedAt
        tags {
          items {
            id
            userId
            userTagId
            userTag {
              id
              color
              text
              tenantCode
              createdAt
              updatedAt
            }
            tenantCode
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
