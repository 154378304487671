import axios from 'axios';
import Cookies, { CookieAttributes } from 'js-cookie';

import { ENCODE_AUDIO, ENCODE_VIDEO, FileStorage, FileStorageUploadArgs } from '@/base/domains';
import {
  AMPLIFY_FILE_STORAGE_ENCODE_AUDIO_FAILED,
  AMPLIFY_FILE_STORAGE_ENCODE_VIDEO_FAILED,
  UPLOAD_FILE_SIZE_EXCEEDED,
} from '@/base/ErrorCodes';
import { LocalDateTime, URI } from '@/base/types';
import { config } from '@/config';
import * as mutations from '@/graphql/mutations';
import * as queries from '@/graphql/queries';
import { graphql } from '@/utils/AmplifyUtils';
import { assertIsDefined } from '@/utils/Asserts';
import { localDateTimeFromString, localDateTimeNow } from '@/utils/DateUtils';
import { createLogger } from '@/utils/log';
import { isDefined, requiredNonNull } from '@/utils/TsUtils';

import { AmplifyInternalTaskService } from './AmplifyInternalTaskService';

const logger = createLogger({ boundedContext: 'base', name: 'AmplifyFileStorage' });

async function uploadUsingPresignedUrl(
  args: FileStorageUploadArgs
): Promise<{ url: URI; key: string }> {
  const { filename, file, onProgress } = args;
  const signedUrlRes = await graphql<{
    getSignedUrl: { signedUrl: string; path: URI; key: string };
  }>(queries.getSignedUrl, {
    filename,
  });
  const { signedUrl, path, key } = signedUrlRes.getSignedUrl;

  try {
    await axios({
      method: 'PUT',
      url: signedUrl,
      headers: {
        'Content-Type': file.type,
      },
      data: file,
      onUploadProgress: onProgress
        ? (e: { total: number; loaded: number }) => {
            onProgress({ total: e.total, loaded: e.loaded });
          }
        : undefined,
    });
  } catch (e) {
    logger.info({
      message: 'an error has been occurred at uploading by pre-signed url',
      error: e,
    });
    throw e;
  }
  return {
    url: path,
    key,
  };
}

const LIMIT = 6 * 1024 * 1024;

async function base64encode(file: Blob): Promise<string> {
  if (file.size >= LIMIT) {
    throw UPLOAD_FILE_SIZE_EXCEEDED.toApplicationError({
      payload: {
        fileSize: file.size,
      },
    });
  }
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      // ^data:.+/.+;base64,
      const str = (reader.result as string).replace(/^data:.+\/.+;base64,/, '');
      const encodedFileSize = new Blob([str]).size;
      if (encodedFileSize >= LIMIT) {
        reject(
          UPLOAD_FILE_SIZE_EXCEEDED.toApplicationError({
            payload: {
              fileSize: file.size,
              encodedFileSize,
            },
          })
        );
        return;
      }
      resolve(str);
    });
    reader.readAsDataURL(file);
  });
}

async function uploadUsingGraphql(args: FileStorageUploadArgs): Promise<{ url: URI; key: string }> {
  const { filename, file, onProgress } = args;
  const fileSize = file.size;
  const body = await base64encode(file);
  const res = await graphql<{ upload: { url: URI; key: string } }>(mutations.upload, {
    filename,
    body,
  });
  if (onProgress) {
    onProgress({ total: fileSize, loaded: fileSize });
  }
  return {
    url: res.upload.url,
    key: res.upload.key,
  };
}

type AmplifySignedPolicy = {
  policy: string;
  keyPairId: string;
  signature: string;
  domain: string;
  expire: string;
};

export class AmplifyFileStorage implements FileStorage {
  constructor(private internalTaskService: AmplifyInternalTaskService) {}

  async encodeAudio(sourceKey: string, filename: string): Promise<URI> {
    return new Promise<URI>((resolve, reject) => {
      this.internalTaskService.start(ENCODE_AUDIO).runWith({
        payload: { sourceKey, filename },
        onFinished: (task) => {
          logger.debug({
            message: 'encodeAudio.onFinished',
            task,
          });
          resolve(requiredNonNull(task.payload.url, 'internalTask.payload.url'));
        },
        onError: () => {
          logger.debug({
            message: 'encodeAudio.onError',
          });
          reject(AMPLIFY_FILE_STORAGE_ENCODE_AUDIO_FAILED.toApplicationError({ filename }));
        },
      });
    });
  }

  async encodeVideo(sourceKey: string, filename: string): Promise<URI> {
    return new Promise<URI>((resolve, reject) => {
      this.internalTaskService.start(ENCODE_VIDEO).runWith({
        payload: { sourceKey, filename },
        onFinished: (task) => {
          logger.debug({
            message: 'encodeVideo.onFinished',
            task,
          });
          resolve(requiredNonNull(task.payload.url, 'internalTask.payload.url'));
        },
        onError: () => {
          logger.debug({
            message: 'encodeVideo.onError',
          });
          reject(AMPLIFY_FILE_STORAGE_ENCODE_VIDEO_FAILED.toApplicationError({ filename }));
        },
      });
    });
  }

  async setSignedCookie(): Promise<void> {
    if (config().local) {
      return;
    }
    const res = await graphql<{ getSignedPolicy?: AmplifySignedPolicy }>(queries.getSignedPolicy);
    assertIsDefined(res.getSignedPolicy, 'signedPolicy');
    const signedPolicy = res.getSignedPolicy;

    // 署名付きクッキーの有効なドメインは変更すれば次のような実装でローカルでも動作するかも？
    // const options = config().local
    //   ? {
    //       secure: false,
    //       domain: 'localhost',
    //     }
    //   : {
    //       secure: true,
    //       domain: signedPolicy.domain,
    //     };
    const options: CookieAttributes = {
      secure: true,
      domain: signedPolicy.domain,
      sameSite: 'None',
    };
    Cookies.set('CloudFront-Policy', signedPolicy.policy, options);
    Cookies.set('CloudFront-Signature', signedPolicy.signature, options);
    Cookies.set('CloudFront-Key-Pair-Id', signedPolicy.keyPairId, options);
    Cookies.set('CloudFront-Expire', signedPolicy.expire, options);
  }

  clearSignedCookie(): void {
    // テナントのファイルを参照するための署名付きCookieを削除する
    Cookies.remove('CloudFront-Policy');
    Cookies.remove('CloudFront-Signature');
    Cookies.remove('CloudFront-Key-Pair-Id');
    Cookies.remove('CloudFront-Expire');
  }

  async upload(args: FileStorageUploadArgs): Promise<string> {
    const filename = args.filename.replaceAll(' ', '.');
    const res = await (async () => {
      try {
        return await uploadUsingPresignedUrl({
          ...args,
          filename,
        });
      } catch (e) {
        return uploadUsingGraphql({
          ...args,
          filename,
        });
      }
    })();
    const shouldEncode = args.encode ?? false;
    if (shouldEncode) {
      const lFilename = filename.toLowerCase();
      const audioExtList = ['.mp3', '.m4a'];
      if (audioExtList.find((ext) => lFilename.endsWith(ext))) {
        return this.encodeAudio(res.key, filename);
      }
      const videoExtList = ['.mov', '.mp4'];
      if (videoExtList.find((ext) => lFilename.endsWith(ext))) {
        return this.encodeVideo(res.key, filename);
      }
    }
    return res.url;
  }

  hasSignedCookie(): boolean {
    return (
      isDefined(Cookies.get('CloudFront-Policy')) &&
      isDefined(Cookies.get('CloudFront-Signature')) &&
      isDefined(Cookies.get('CloudFront-Key-Pair-Id')) &&
      isDefined(Cookies.get('CloudFront-Expire'))
    );
  }

  isValidSignedCookie(): boolean {
    if (!this.hasSignedCookie()) {
      return false;
    }
    const expire = localDateTimeFromString(
      requiredNonNull(Cookies.get('CloudFront-Expire'), 'Cookie.CloudFront-Expire')
    );
    const now = localDateTimeNow();
    return now.isBefore(expire);
  }

  getExpire(): LocalDateTime {
    const s = Cookies.get('CloudFront-Expire');
    assertIsDefined(s, 'Cookie.CloudFront-Expire');
    return localDateTimeFromString(s);
  }
}
