import { AppContextProvider, CourseId, ExamDataAdapter } from '@/base/domains';
import { COURSE_REMOVE_FAILED_GROUP_EXAM_IS_OPEN } from '@/base/ErrorCodes';
import { AbstractUseCase, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { CourseHeaderRepository } from '../domains';

export interface RemoveCourseRequest {
  id: CourseId;
}

/**
 * コース削除
 */
export interface RemoveCourse extends UseCase<RemoveCourseRequest, {}> {
  execute(request: RemoveCourseRequest): Promise<UseCaseResponse<{}>>;
}

export class RemoveCourseImpl
  extends AbstractUseCase<RemoveCourseRequest, {}>
  implements RemoveCourse
{
  constructor(
    private courseHeaderRepository: CourseHeaderRepository,
    private examDataAdapter: ExamDataAdapter,
    private appContextProvider: AppContextProvider
  ) {
    super('contents.RemoveCourse');
  }

  async internalExecute(request: RemoveCourseRequest): Promise<{}> {
    const { id } = request;
    const groupExams = await this.examDataAdapter.findOpenGroupExamsByCourseId(id);
    if (groupExams.length > 0) {
      const appContext = this.appContextProvider.get();
      throw COURSE_REMOVE_FAILED_GROUP_EXAM_IS_OPEN.toApplicationError({
        groups: groupExams.map((ge) => ({
          id: ge.groupId,
          name: appContext.groupNameOf(ge.groupId) ?? '',
        })),
      });
    }
    await this.courseHeaderRepository.remove(id);
    return {};
  }
}

export const RemoveCourseKey = injectionKeyOf<RemoveCourse>({
  boundedContext: 'contents',
  type: 'usecase',
  name: 'RemoveCourse',
});

export function useRemoveCourse(): RemoveCourse {
  return requiredInject(RemoveCourseKey);
}
