import {
  ExamResultVisibilityLevel,
  GroupExamContent,
  GroupExamCourse,
  GroupExamId,
  GroupExamStatus,
  GroupId,
  UserExam,
  UserId,
} from '@/base/domains';
import { LocalDateTime, Minute } from '@/base/types';
import { isDefined } from '@/utils/TsUtils';

import { GROUP_EXAM_ALREADY_FINISHED, GROUP_EXAM_ALREADY_STARTED } from '../ErrorCodes';
import { GroupExamEntity } from './GroupExam';
import { GroupTrainingCourseId } from './GroupTrainingCourse';

export type GroupExamEntityArgs = {
  id: GroupExamId;

  groupId: GroupId;

  content: GroupExamContent;

  course: GroupExamCourse;

  scheduledStart: LocalDateTime;

  scheduledFinish?: LocalDateTime;

  finishedAt?: LocalDateTime;

  status: GroupExamStatus;

  visibilityLevel: ExamResultVisibilityLevel;

  userExams: Array<UserExam>;

  times: number;

  groupTrainingCourseId: GroupTrainingCourseId;

  timeLimit?: Minute;

  passingStandard?: number;

  userIdsToBeTested: UserId[];
};

export class GroupExamEntityImpl implements GroupExamEntity {
  id: GroupExamId;

  groupId: GroupId;

  content: GroupExamContent;

  course: GroupExamCourse;

  scheduledStart: LocalDateTime;

  scheduledFinish?: LocalDateTime;

  finishedAt?: LocalDateTime;

  status: GroupExamStatus;

  visibilityLevel: ExamResultVisibilityLevel;

  userExams: Array<UserExam>;

  times: number;

  groupTrainingCourseId: GroupTrainingCourseId;

  timeLimit?: Minute;

  passingStandard?: number;

  userIdsToBeTested: UserId[];

  constructor(args: GroupExamEntityArgs) {
    this.id = args.id;
    this.groupId = args.groupId;
    this.content = args.content;
    this.course = args.course;
    this.scheduledStart = args.scheduledStart;
    this.scheduledFinish = args.scheduledFinish;
    this.finishedAt = args.finishedAt;
    this.status = args.status;
    this.visibilityLevel = args.visibilityLevel;
    this.userExams = args.userExams;
    this.times = args.times;
    this.groupTrainingCourseId = args.groupTrainingCourseId;
    this.timeLimit = args.timeLimit;
    this.passingStandard = args.passingStandard;
    this.userIdsToBeTested = args.userIdsToBeTested;
  }

  restart(): GroupExamEntity {
    return new GroupExamEntityImpl({
      ...this,
      finishedAt: undefined,
      status: 'in_progress',
    });
  }

  finish(now: LocalDateTime): GroupExamEntity {
    return new GroupExamEntityImpl({
      ...this,
      finishedAt: now,
      status: 'finished',
    });
  }

  changeScheduledTime(args: {
    scheduledStart: LocalDateTime;
    scheduledFinish?: LocalDateTime;
    now: LocalDateTime;
  }): GroupExamEntity {
    if (isDefined(this.finishedAt)) {
      throw GROUP_EXAM_ALREADY_FINISHED.toApplicationError();
    }
    if (
      !this.scheduledStart.isSame(args.scheduledStart) &&
      this.scheduledStart.isBefore(args.now)
    ) {
      throw GROUP_EXAM_ALREADY_STARTED.toApplicationError();
    }
    return new GroupExamEntityImpl({
      ...this,
      scheduledStart: args.scheduledStart,
      scheduledFinish: args.scheduledFinish,
    });
  }
}
