import { shuffle } from '@/utils/ArrayUtils';
import { isDefined } from '@/utils/TsUtils';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { AuthorizationService, AuthService, localEventBus, Password, UserId } from '../domains';
import { userPasswordChangedByAdmin } from '../domains/LocalEvents';
import { AbstractUseCase, UseCase, UseCaseResponse } from './UseCase';

export type ChangeUserPasswordByAdminRequest =
  | {
      id: UserId;
      /**
       * パスワード。設定されていない場合は生成される。
       */
      password?: Password;
      /**
       * 次回サインイン時にパスワード変更を強制する
       */
      forcePasswordChange?: true;
    }
  | {
      id: UserId;
      /**
       * パスワード
       */
      password: Password;
      /**
       * 次回サインイン時にパスワード変更を強制する
       */
      forcePasswordChange: false;
    };

export type ChangeUserPasswordByAdminResponse = {
  temporaryPassword?: Password;
};

export interface ChangeUserPasswordByAdmin
  extends UseCase<ChangeUserPasswordByAdminRequest, ChangeUserPasswordByAdminResponse> {
  execute(
    request: ChangeUserPasswordByAdminRequest
  ): Promise<UseCaseResponse<ChangeUserPasswordByAdminResponse>>;
}

function generatePassword(): string {
  const length = 8;
  const lowerAlphabets = 'abcdefghijklmnopqrstuvwxyz';
  const upperAlphabets = lowerAlphabets.toUpperCase();
  const numbers = '0123456789';
  const charset = lowerAlphabets + upperAlphabets + numbers;

  let v =
    lowerAlphabets.charAt(Math.floor(Math.random() * lowerAlphabets.length)) +
    upperAlphabets.charAt(Math.floor(Math.random() * upperAlphabets.length)) +
    numbers.charAt(Math.floor(Math.random() * numbers.length));
  for (let i = 0; i < length - 3; i += 1) {
    v += charset.charAt(Math.floor(Math.random() * charset.length));
  }
  return shuffle(v.split('')).join('');
}

export class ChangeUserPasswordByAdminImpl
  extends AbstractUseCase<ChangeUserPasswordByAdminRequest, ChangeUserPasswordByAdminResponse>
  implements ChangeUserPasswordByAdmin
{
  private authorizationService: AuthorizationService;

  private authService: AuthService;

  constructor(authorizationService: AuthorizationService, authService: AuthService) {
    super('base.ChangeUserPasswordByAdmin');
    this.authorizationService = authorizationService;
    this.authService = authService;
  }

  async internalExecute({
    id,
    password,
    forcePasswordChange = true,
  }: ChangeUserPasswordByAdminRequest): Promise<ChangeUserPasswordByAdminResponse> {
    this.authorizationService.assertRole('supervisor', 'admin');
    const p = isDefined(password) ? password : generatePassword();
    await this.authService.changePasswordByAdmin({
      id,
      password: p,
      forcePasswordChange,
    });
    localEventBus.publish(userPasswordChangedByAdmin({ userId: id }));
    if (forcePasswordChange) {
      return {
        temporaryPassword: p,
      };
    }
    return {};
  }
}

export const ChangeUserPasswordByAdminKey = injectionKeyOf<ChangeUserPasswordByAdmin>({
  boundedContext: 'base',
  type: 'usecase',
  name: 'ChangeUserPasswordByAdmin',
});

export function useChangeUserPasswordByAdmin(): ChangeUserPasswordByAdmin {
  return requiredInject(ChangeUserPasswordByAdminKey);
}
