import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

import { accountRoutes } from '@/account/app/router';
import { adminRoutes } from '@/admin/app/router';
import { baseRoutes } from '@/base/app/router';
import { contentsRoutes } from '@/contents/app/router';
import { devRoutes } from '@/dev/app/router';
import { homeRoutes } from '@/home/app/router';
import { playgroundRoutes } from '@/playground/app/router';
import { reportRoutes } from '@/report/app/router';
import { trainingRoutes } from '@/training/app/router';

import { createLogger } from './utils/log';

Vue.use(VueRouter);

const logger = createLogger({
  boundedContext: 'base',
  name: 'router',
});

const routes: Array<RouteConfig> = [
  ...baseRoutes,
  ...accountRoutes,
  ...homeRoutes,
  ...contentsRoutes,
  ...playgroundRoutes,
  ...trainingRoutes,
  ...reportRoutes,
  ...adminRoutes,
  ...devRoutes,
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash && (!to.meta || to.meta.scrollToHash)) {
      return { selector: to.hash, offset: { x: 0, y: 64 } };
    }
    if (savedPosition) return savedPosition;
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  logger.debug({
    message: 'Vue Router routing',
    to,
    from,
  });
  if (to.matched.length === 0) {
    next({ name: 'notFound' });
    return;
  }
  // https://github.com/citycom/knowte/issues/278
  next();
});

export default router;
