export function isDefined<T>(x: T): x is NonNullable<T> {
  return x !== undefined && x !== null;
}

export function isObject(x: unknown): x is object {
  return x !== null && (typeof x === 'object' || typeof x === 'function');
}

export function isString(x: unknown): x is string {
  return typeof x === 'string';
}

export function isNumber(x: unknown): x is number {
  return typeof x === 'number';
}

export function isFunction(x: unknown): x is Function {
  return x instanceof Function;
}

export function hasProperty<X extends {}, P extends PropertyKey>(
  x: X,
  propertyKey: P
): x is X & Record<P, unknown> {
  return x instanceof Object && propertyKey in x;
}

export function hasNonNullProperty<X, P extends PropertyKey>(
  x: X,
  propertyKey: P
): x is X & Record<P, NonNullable<unknown>> {
  return isObject(x) && hasProperty<X, P>(x, propertyKey) && isDefined(x[propertyKey]);
}

export function isEmptyObject(x: {}): boolean {
  return Object.keys(x).length === 0 && x.constructor === Object;
}

export function requiredNonNull<T>(x: T, name = 'arg'): NonNullable<T> {
  if (!isDefined(x)) {
    throw new Error(`${name} should be defined`);
  }
  return x;
}

export function ifDefined<T, R>(x: T, f: (v: NonNullable<T>) => R): R | undefined {
  if (isDefined(x)) {
    return f(x);
  }
  return undefined;
}

export function uniqueArray<T>(array: Array<T>): Array<T> {
  return Array.from(new Set(array));
}

export function mergeDeep(target: object, ...sources: object[]): object {
  if (sources.length === 0) return target;
  const source = sources.shift();

  if (isDefined(source)) {
    Object.keys(source).forEach((key) => {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    });
  }

  return mergeDeep(target, ...sources);
}
