import {
  Entity,
  EntityReference,
  GroupId,
  StandardRepository,
  Subscription,
  UserId,
} from '@/base/domains';
import { LocalDateTime, MarkDownString, Optional, PlainData } from '@/base/types';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

export type QuestionnaireId = string;

export type QuestionnaireStatus = 'finished' | 'active';

export type QuestionnaireRespondent = {
  questionnaireId: QuestionnaireId;
  userId: UserId;
  selectedIndex: number;
};

export interface QuestionnaireAttributes {
  groupId: GroupId;
  title: string;
  text?: MarkDownString;
  userIds: Array<UserId>;
  createdBy: UserId;
  createdAt: LocalDateTime;
  options: Array<MarkDownString>;
  respondent: Array<QuestionnaireRespondent>;
  status: QuestionnaireStatus;
  finishedAt?: LocalDateTime;
}

export type QuestionnaireByAnswererArgs = {
  id: QuestionnaireId;
  groupId: GroupId;
  title: string;
  text?: MarkDownString;
  userIds: Array<UserId>;
  createdBy: UserId;
  createdAt: LocalDateTime;
  options: Array<MarkDownString>;
};

export type QuestionnaireByAnswererRespondent = {
  answered: boolean;
};

export interface QuestionnaireByAnswerer
  extends QuestionnaireByAnswererArgs,
    QuestionnaireByAnswererRespondent {}

/**
 * アンケートクエリ
 */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface QuestionnaireQueries {}

export interface QuestionnaireCommand {
  finish(now: LocalDateTime): QuestionnaireEntity;
}

export type QuestionnaireReference = EntityReference<
  QuestionnaireId,
  QuestionnaireAttributes,
  QuestionnaireQueries
>;

export type QuestionnaireArgs = {
  id: QuestionnaireId;
  groupId: GroupId;
  title: string;
  text?: MarkDownString;
  userIds: Array<UserId>;
  createdBy: UserId;
  createdAt: LocalDateTime;
  options: Array<MarkDownString>;
  respondent: Array<QuestionnaireRespondent>;
  status: QuestionnaireStatus;
  finishedAt?: LocalDateTime;
};

export type QuestionnaireEntity = Entity<QuestionnaireId, QuestionnaireAttributes> &
  QuestionnaireCommand;

export type QuestionnaireData = PlainData<QuestionnaireEntity>;

export class QuestionnaireEntityImpl implements QuestionnaireEntity {
  id: QuestionnaireId;

  groupId: GroupId;

  title: string;

  text?: string;

  userIds: Array<UserId>;

  createdBy: UserId;

  createdAt: LocalDateTime;

  options: Array<string>;

  respondent: Array<QuestionnaireRespondent>;

  status: QuestionnaireStatus;

  finishedAt?: LocalDateTime;

  constructor(data: QuestionnaireArgs) {
    this.id = data.id;
    this.groupId = data.groupId;
    this.title = data.title;
    this.text = data.text;
    this.userIds = data.userIds;
    this.createdBy = data.createdBy;
    this.createdAt = data.createdAt;
    this.options = data.options;
    this.respondent = data.respondent;
    this.status = data.status;
    this.finishedAt = data.finishedAt;
  }

  finish(now: LocalDateTime): QuestionnaireEntity {
    return new QuestionnaireEntityImpl({
      ...this,
      finishedAt: now,
      status: 'finished',
    });
  }
}

export interface QuestionnaireRepository
  extends StandardRepository<QuestionnaireId, QuestionnaireAttributes, QuestionnaireEntity> {
  findByGroupId(groupId: GroupId): Promise<Array<QuestionnaireReference>>;
  findById(id: QuestionnaireId): Promise<Optional<QuestionnaireEntity>>;
  findActiveQuestionnairesByGroupId(groupId: GroupId): Promise<Array<QuestionnaireReference>>;
  findByAnswerer(
    questionnaireId: QuestionnaireId,
    userId: UserId
  ): Promise<Optional<QuestionnaireByAnswerer>>;
  findQuestionnairesByAnswerer(
    groupId: GroupId,
    userId: UserId
  ): Promise<Array<QuestionnaireByAnswerer>>;
  findUnansweredQuestionnaires(
    groupId: GroupId,
    userId: UserId
  ): Promise<Array<QuestionnaireByAnswererArgs>>;
  answerQuestionnaire(
    questionnaireId: QuestionnaireId,
    userId: UserId,
    selectedIndex: number
  ): Promise<QuestionnaireRespondent>;
  subscribeQuestionnaireStatusChanged(args: {
    questionnaireId: QuestionnaireId;
    onChange: (questionnaireRespondent: QuestionnaireRespondent, removed: boolean) => void;
    onError: (e: Error) => void;
  }): Subscription;
}

export const QuestionnaireRepositoryKey = injectionKeyOf<QuestionnaireRepository>({
  boundedContext: 'training',
  type: 'adapter',
  name: 'QuestionnaireRepository',
});

export function useQuestionnaireRepository(): QuestionnaireRepository {
  return requiredInject(QuestionnaireRepositoryKey);
}
