import { AuthorizationService, TenantOwnTermsOfServiceFinder } from '@/base/domains';
import { AbstractUseCase, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import {
  EditingTenantOwnTermsOfServiceEntity,
  EditingTenantOwnTermsOfServiceRepository,
  TenantOwnTermsOfServiceReference,
} from '../domains';

export type GetEditingTenantOwnTermsOfServiceRequest = {};

export type GetEditingTenantOwnTermsOfServiceResponse = {
  tenantOwnTermsOfService?: TenantOwnTermsOfServiceReference;
  editingTenantOwnTermsOfService?: EditingTenantOwnTermsOfServiceEntity;
};

export interface GetEditingTenantOwnTermsOfService
  extends UseCase<
    GetEditingTenantOwnTermsOfServiceRequest,
    GetEditingTenantOwnTermsOfServiceResponse
  > {
  execute(
    request: GetEditingTenantOwnTermsOfServiceRequest
  ): Promise<UseCaseResponse<GetEditingTenantOwnTermsOfServiceResponse>>;
}

export class GetEditingTenantOwnTermsOfServiceImpl
  extends AbstractUseCase<
    GetEditingTenantOwnTermsOfServiceRequest,
    GetEditingTenantOwnTermsOfServiceResponse
  >
  implements GetEditingTenantOwnTermsOfService
{
  constructor(
    private authorizationService: AuthorizationService,
    private tenantOwnTermsOfServiceFinder: TenantOwnTermsOfServiceFinder,
    private editingTenantOwnTermsOfServiceRepository: EditingTenantOwnTermsOfServiceRepository
  ) {
    super('admin.GetEditingTenantOwnTermsOfService');
  }

  async internalExecute(
    _: GetEditingTenantOwnTermsOfServiceRequest
  ): Promise<GetEditingTenantOwnTermsOfServiceResponse> {
    this.authorizationService.assertRole('supervisor');
    const editingTenantOwnTermsOfService =
      await this.editingTenantOwnTermsOfServiceRepository.findEditingTenantOwnTermsOfService();
    const tenantOwnTermsOfService =
      await this.tenantOwnTermsOfServiceFinder.findTenantOwnTermsOfService();

    return {
      editingTenantOwnTermsOfService,
      tenantOwnTermsOfService: tenantOwnTermsOfService
        ? {
            ...tenantOwnTermsOfService,
          }
        : undefined,
    };
  }
}

export const GetEditingTenantOwnTermsOfServiceKey =
  injectionKeyOf<GetEditingTenantOwnTermsOfService>({
    boundedContext: 'admin',
    type: 'usecase',
    name: 'GetEditingTenantOwnTermsOfService',
  });

export function useGetEditingTenantOwnTermsOfService(): GetEditingTenantOwnTermsOfService {
  return requiredInject(GetEditingTenantOwnTermsOfServiceKey);
}
