import { ContentId } from '@/base/domains';
import { AbstractUseCase, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import {
  EditingCourseContentWorkbookReference,
  EditingCourseContentWorkbookRepository,
} from '../domains';

export interface GetEditingCourseContentWorkbookRequest {
  contentId: ContentId;
}

export type GetEditingCourseContentWorkbookResponse = {
  workbook?: EditingCourseContentWorkbookReference;
};

/**
 * 編集中コースコンテントを取得する
 */
export interface GetEditingCourseContentWorkbook
  extends UseCase<GetEditingCourseContentWorkbookRequest, GetEditingCourseContentWorkbookResponse> {
  execute(
    request: GetEditingCourseContentWorkbookRequest
  ): Promise<UseCaseResponse<GetEditingCourseContentWorkbookResponse>>;
}

export class GetEditingCourseContentWorkbookImpl
  extends AbstractUseCase<
    GetEditingCourseContentWorkbookRequest,
    GetEditingCourseContentWorkbookResponse
  >
  implements GetEditingCourseContentWorkbook
{
  private editingCourseContentWorkbookRepository: EditingCourseContentWorkbookRepository;

  constructor(editingCourseContentWorkbookRepository: EditingCourseContentWorkbookRepository) {
    super('contents.GetEditingCourseContentWorkbook');
    this.editingCourseContentWorkbookRepository = editingCourseContentWorkbookRepository;
  }

  async internalExecute(
    request: GetEditingCourseContentWorkbookRequest
  ): Promise<GetEditingCourseContentWorkbookResponse> {
    const { contentId } = request;
    const workbook = await this.editingCourseContentWorkbookRepository.findById(contentId);
    return {
      workbook,
    };
  }
}

export const GetEditingCourseContentWorkbookKey = injectionKeyOf<GetEditingCourseContentWorkbook>({
  boundedContext: 'contents',
  type: 'usecase',
  name: 'GetEditingCourseContentWorkbook',
});

export function useGetEditingCourseContentWorkbook(): GetEditingCourseContentWorkbook {
  return requiredInject(GetEditingCourseContentWorkbookKey);
}
