import { v4 } from 'uuid';

import { CourseId, CourseVersion, hasId } from '@/base/domains';
import { Optional } from '@/base/types';
import { assertEntityExists } from '@/base/usecases';
import {
  CourseData,
  CourseEntity,
  CourseEntityImpl,
  CourseReference,
  CourseRepository,
} from '@/contents/domains';

function keyOf(id: CourseId, version: CourseVersion): string {
  return `${id}-${version}`;
}

export class InMemoryCourseRepository implements CourseRepository {
  private store: Map<string, CourseEntity> = new Map();

  save(entity: CourseEntity | CourseData): Promise<CourseEntity> {
    const e = hasId(entity)
      ? new CourseEntityImpl(entity)
      : new CourseEntityImpl({ ...entity, id: v4(), version: entity.version ?? 1 });
    this.store.set(keyOf(e.id, e.version), e);
    return Promise.resolve(e);
  }

  findById({
    id,
    version,
  }: {
    id: CourseId;
    version: CourseVersion;
  }): Promise<Optional<CourseEntity>> {
    return Promise.resolve(this.store.get(keyOf(id, version)));
  }

  remove({ id, version }: { id: CourseId; version: CourseVersion }): Promise<void> {
    this.store.delete(keyOf(id, version));
    return Promise.resolve();
  }

  findTenantCourses(): Promise<CourseEntity[]> {
    return Promise.resolve(Array.from(this.store.values()));
  }

  async findLatestVersion(id: CourseId): Promise<CourseVersion> {
    const list = await this.findCoursesById(id);
    if (list.length === 0) {
      assertEntityExists(undefined, 'courses');
    }
    return list.sort((a, b) => b.version - a.version)[0].version;
  }

  findCoursesById(id: CourseId): Promise<Array<CourseReference>> {
    return Promise.resolve(
      Array.from(this.store.entries())
        .filter((e) => e[0].startsWith(id))
        .map((e) => e[1])
    );
  }
}
