export const schema = {
    "models": {
        "Group": {
            "name": "Group",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "users": {
                    "name": "users",
                    "isArray": true,
                    "type": {
                        "nonModel": "GroupUser"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "extensionConfigJsonList": {
                    "name": "extensionConfigJsonList",
                    "isArray": true,
                    "type": "String",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "tenantCode": {
                    "name": "tenantCode",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "limitations": {
                    "name": "limitations",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "enabled": {
                    "name": "enabled",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "description": {
                    "name": "description",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "createdBy": {
                    "name": "createdBy",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "updatedBy": {
                    "name": "updatedBy",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "Groups",
            "attributes": [
                {
                    "type": "model",
                    "properties": {
                        "mutations": {
                            "delete": "deleteGroup"
                        },
                        "subscriptions": null
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byTenantCode",
                        "fields": [
                            "tenantCode"
                        ],
                        "queryField": "groupsByTenantCode"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "tenantCode",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "provider": "iam",
                                "allow": "private",
                                "operations": [
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "EditingCourse": {
            "name": "EditingCourse",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "tenantCode": {
                    "name": "tenantCode",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "version": {
                    "name": "version",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": {
                        "enum": "EditingStatus"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "description": {
                    "name": "description",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "contents": {
                    "name": "contents",
                    "isArray": true,
                    "type": {
                        "nonModel": "EditingCourseContentHeader"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "color": {
                    "name": "color",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "image": {
                    "name": "image",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "fontColorOnImage": {
                    "name": "fontColorOnImage",
                    "isArray": false,
                    "type": {
                        "enum": "CourseFontColorOnImage"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "createdBy": {
                    "name": "createdBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "updatedBy": {
                    "name": "updatedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "EditingCourses",
            "attributes": [
                {
                    "type": "model",
                    "properties": {
                        "mutations": {
                            "delete": "deleteEditingCourse"
                        },
                        "subscriptions": null
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byTenantCode",
                        "fields": [
                            "tenantCode"
                        ],
                        "queryField": "editingCoursesByTenantCode"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "tenantCode",
                                "operations": [
                                    "delete",
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "provider": "iam",
                                "allow": "private",
                                "operations": [
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "CourseHeader": {
            "name": "CourseHeader",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "activeVersion": {
                    "name": "activeVersion",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": {
                        "enum": "CourseStatus"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "originalCourseId": {
                    "name": "originalCourseId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "originalCourseVersion": {
                    "name": "originalCourseVersion",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "tenantCode": {
                    "name": "tenantCode",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "createdBy": {
                    "name": "createdBy",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "CourseHeaders",
            "attributes": [
                {
                    "type": "model",
                    "properties": {
                        "mutations": {
                            "update": "updateCourseHeader",
                            "delete": "deleteCourseHeader"
                        },
                        "subscriptions": null
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byName",
                        "fields": [
                            "name"
                        ],
                        "queryField": "courseHeaderByName"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byTenantCode",
                        "fields": [
                            "tenantCode"
                        ],
                        "queryField": "courseHeaderByTenantCode"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "tenantCode",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "provider": "iam",
                                "allow": "private",
                                "operations": [
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Notification": {
            "name": "Notification",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "payloadJson": {
                    "name": "payloadJson",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "userId": {
                    "name": "userId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "read": {
                    "name": "read",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "tenantCode": {
                    "name": "tenantCode",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "Notifications",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byTenantCode",
                        "fields": [
                            "tenantCode"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byUserId",
                        "fields": [
                            "userId",
                            "createdAt"
                        ],
                        "queryField": "notificationsByUserId"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "tenantCode",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "provider": "iam",
                                "allow": "private",
                                "operations": [
                                    "create"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "User": {
            "name": "User",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "role": {
                    "name": "role",
                    "isArray": false,
                    "type": {
                        "enum": "Role"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "extensionConfigJsonList": {
                    "name": "extensionConfigJsonList",
                    "isArray": true,
                    "type": "String",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "email": {
                    "name": "email",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "code": {
                    "name": "code",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "tenantCode": {
                    "name": "tenantCode",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "displaySettings": {
                    "name": "displaySettings",
                    "isArray": false,
                    "type": {
                        "nonModel": "DisplaySettings"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "locale": {
                    "name": "locale",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "avatar": {
                    "name": "avatar",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "tags": {
                    "name": "tags",
                    "isArray": true,
                    "type": {
                        "model": "UserUserTag"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "user"
                    }
                },
                "enabled": {
                    "name": "enabled",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "signedInAtLeastOnce": {
                    "name": "signedInAtLeastOnce",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "statuses": {
                    "name": "statuses",
                    "isArray": true,
                    "type": {
                        "model": "UserStatusTable"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "user"
                    }
                },
                "emailConfirmed": {
                    "name": "emailConfirmed",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "confirmedTermsOfServiceVersions": {
                    "name": "confirmedTermsOfServiceVersions",
                    "isArray": false,
                    "type": {
                        "nonModel": "ConfirmedTermsOfServiceVersions"
                    },
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "Users",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byEmail",
                        "fields": [
                            "email"
                        ],
                        "queryField": "userByEmail"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byCodeAndTenantCode",
                        "fields": [
                            "code",
                            "tenantCode"
                        ],
                        "queryField": "userByCodeAndTenantCode"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byTenantCode",
                        "fields": [
                            "tenantCode"
                        ],
                        "queryField": "usersByTenantCode"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "tenantCode",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "provider": "iam",
                                "allow": "private",
                                "operations": [
                                    "create",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "UserUserTag": {
            "name": "UserUserTag",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "user": {
                    "name": "user",
                    "isArray": false,
                    "type": {
                        "model": "User"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetName": "userId"
                    }
                },
                "userTag": {
                    "name": "userTag",
                    "isArray": false,
                    "type": {
                        "model": "UserTag"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetName": "userTagId"
                    }
                },
                "tenantCode": {
                    "name": "tenantCode",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "UserUserTags",
            "attributes": [
                {
                    "type": "model",
                    "properties": {
                        "queries": null
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byUser",
                        "fields": [
                            "userId",
                            "userTagId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byUserTag",
                        "fields": [
                            "userTagId",
                            "userId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "tenantCode",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "provider": "iam",
                                "allow": "private",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "UserTag": {
            "name": "UserTag",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "color": {
                    "name": "color",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "text": {
                    "name": "text",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "tenantCode": {
                    "name": "tenantCode",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "users": {
                    "name": "users",
                    "isArray": true,
                    "type": {
                        "model": "UserUserTag"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "userTag"
                    }
                }
            },
            "syncable": true,
            "pluralName": "UserTags",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byTenantCode",
                        "fields": [
                            "tenantCode"
                        ],
                        "queryField": "userTagsByTenantCode"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "tenantCode",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "provider": "iam",
                                "allow": "private",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "UserStatusTable": {
            "name": "UserStatusTable",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "tenantCode": {
                    "name": "tenantCode",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": {
                        "enum": "UserStatus"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "user": {
                    "name": "user",
                    "isArray": false,
                    "type": {
                        "model": "User"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetName": "userId"
                    }
                }
            },
            "syncable": true,
            "pluralName": "UserStatusTables",
            "attributes": [
                {
                    "type": "model",
                    "properties": {
                        "subscriptions": {
                            "onCreate": [
                                "onCreateUserStatusTableByTenantCode(tenantCode: String!)"
                            ],
                            "onUpdate": [
                                "onUpdateUserStatusTableByTenantCode(tenantCode: String!)"
                            ],
                            "onDelete": [
                                "onDeleteUserStatusTableByTenantCode(tenantCode: String!)"
                            ]
                        }
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byUser",
                        "fields": [
                            "userId"
                        ],
                        "queryField": "userStatusTablesByUser"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byTenantCode",
                        "fields": [
                            "tenantCode",
                            "status"
                        ],
                        "queryField": "userStatusTablesByTenantCode"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "tenantCode",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "provider": "iam",
                                "allow": "private",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "UserDevice": {
            "name": "UserDevice",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "userId": {
                    "name": "userId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "devices": {
                    "name": "devices",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": true,
                    "attributes": []
                },
                "dataVersion": {
                    "name": "dataVersion",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "tenantCode": {
                    "name": "tenantCode",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "UserDevices",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "versioned",
                    "properties": {
                        "versionField": "dataVersion",
                        "versionInput": "expectedDataVersion"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "fields": [
                            "userId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byTenantCode",
                        "fields": [
                            "tenantCode"
                        ],
                        "queryField": "userDevicesByTenantCode"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "provider": "userPools",
                                "ownerField": "userId",
                                "allow": "owner",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ],
                                "identityClaim": "cognito:username"
                            },
                            {
                                "provider": "iam",
                                "allow": "private",
                                "operations": [
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "SignUpReservation": {
            "name": "SignUpReservation",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "key": {
                    "name": "key",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "userCode": {
                    "name": "userCode",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "email": {
                    "name": "email",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "tenantCode": {
                    "name": "tenantCode",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": {
                        "enum": "SignUpType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "role": {
                    "name": "role",
                    "isArray": false,
                    "type": {
                        "enum": "Role"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "userId": {
                    "name": "userId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "signUpInput": {
                    "name": "signUpInput",
                    "isArray": false,
                    "type": {
                        "nonModel": "SignUpInput"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": {
                        "enum": "SignUpReservationStatus"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "groups": {
                    "name": "groups",
                    "isArray": true,
                    "type": {
                        "nonModel": "GroupUser"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "createdBy": {
                    "name": "createdBy",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "SignUpReservations",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byUserId",
                        "fields": [
                            "userId"
                        ],
                        "queryField": "signUpReservationByUserId"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byEmail",
                        "fields": [
                            "email"
                        ],
                        "queryField": "signUpReservationsByEmail"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byTenantCode",
                        "fields": [
                            "tenantCode"
                        ],
                        "queryField": "signUpReservationsByTenantCode"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byKey",
                        "fields": [
                            "key"
                        ],
                        "queryField": "signUpReservationByKey"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "tenantCode",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "provider": "iam",
                                "allow": "public",
                                "operations": [
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Tenant": {
            "name": "Tenant",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "code": {
                    "name": "code",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "signUpType": {
                    "name": "signUpType",
                    "isArray": false,
                    "type": {
                        "enum": "SignUpType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "playground": {
                    "name": "playground",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "userLimit": {
                    "name": "userLimit",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "limitations": {
                    "name": "limitations",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "Tenants",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byCode",
                        "fields": [
                            "code"
                        ],
                        "queryField": "tenantByCode"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "code",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "provider": "iam",
                                "allow": "private",
                                "operations": [
                                    "create",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "TenantOwnTermsOfService": {
            "name": "TenantOwnTermsOfService",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "tenantCode": {
                    "name": "tenantCode",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": {
                        "enum": "TenantOwnTermsOfServiceType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "body": {
                    "name": "body",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "createdBy": {
                    "name": "createdBy",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "updatedBy": {
                    "name": "updatedBy",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "version": {
                    "name": "version",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "TenantOwnTermsOfServices",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byTenantCode",
                        "fields": [
                            "tenantCode"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "tenantCode",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "provider": "iam",
                                "allow": "private",
                                "operations": [
                                    "create",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Event": {
            "name": "Event",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "event": {
                    "name": "event",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "version": {
                    "name": "version",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "tenantCode": {
                    "name": "tenantCode",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "occurredBy": {
                    "name": "occurredBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "Events",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byOccurredBy",
                        "fields": [
                            "occurredBy",
                            "createdAt"
                        ],
                        "queryField": "eventsByOccurredBy"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byType",
                        "fields": [
                            "type",
                            "createdAt"
                        ],
                        "queryField": "eventsByType"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "tenantCode",
                                "operations": [
                                    "create"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "provider": "iam",
                                "allow": "private",
                                "operations": [
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "TermsOfService": {
            "name": "TermsOfService",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "body": {
                    "name": "body",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "TermsOfServices",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "provider": "userPools",
                                "allow": "private",
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "provider": "iam",
                                "allow": "public",
                                "operations": [
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Live": {
            "name": "Live",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "userId": {
                    "name": "userId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "url": {
                    "name": "url",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "applicationSessionId": {
                    "name": "applicationSessionId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "tenantCode": {
                    "name": "tenantCode",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "Lives",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byUser",
                        "fields": [
                            "userId",
                            "createdAt"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byTenantCode",
                        "fields": [
                            "tenantCode",
                            "createdAt"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "tenantCode",
                                "operations": [
                                    "create"
                                ],
                                "groupField": "groups"
                            }
                        ]
                    }
                }
            ]
        },
        "GroupStatusUser": {
            "name": "GroupStatusUser",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "userId": {
                    "name": "userId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "groupId": {
                    "name": "groupId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "handStatus": {
                    "name": "handStatus",
                    "isArray": false,
                    "type": {
                        "enum": "HandStatus"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "tenantCode": {
                    "name": "tenantCode",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "GroupStatusUsers",
            "attributes": [
                {
                    "type": "model",
                    "properties": {
                        "subscriptions": {
                            "onCreate": [
                                "onCreateGroupStatusUserByGroupId(groupId: ID!)"
                            ],
                            "onUpdate": [
                                "onUpdateGroupStatusUserByGroupId(groupId: ID!)"
                            ],
                            "onDelete": [
                                "onDeleteGroupStatusUserByGroupId(groupId: ID!)"
                            ]
                        }
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byUser",
                        "fields": [
                            "userId"
                        ],
                        "queryField": "groupStatusUsersByUser"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byGroup",
                        "fields": [
                            "groupId"
                        ],
                        "queryField": "groupStatusUsersByGroup"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byTenantCode",
                        "fields": [
                            "tenantCode"
                        ],
                        "queryField": "groupStatusUsersByTenantCode"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "tenantCode",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "provider": "iam",
                                "allow": "private",
                                "operations": [
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "GroupStatus": {
            "name": "GroupStatus",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "groupId": {
                    "name": "groupId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "tenantCode": {
                    "name": "tenantCode",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "users": {
                    "name": "users",
                    "isArray": true,
                    "type": {
                        "model": "GroupStatusUser"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "groupId"
                    }
                }
            },
            "syncable": true,
            "pluralName": "GroupStatuses",
            "attributes": [
                {
                    "type": "model",
                    "properties": {
                        "subscriptions": null
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "fields": [
                            "groupId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byTenantCode",
                        "fields": [
                            "tenantCode"
                        ],
                        "queryField": "groupStatusesByTenantCode"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "tenantCode",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "provider": "iam",
                                "allow": "private",
                                "operations": [
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "InternalTask": {
            "name": "InternalTask",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "createdBy": {
                    "name": "createdBy",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "finishedAt": {
                    "name": "finishedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": {
                        "enum": "InternalTaskStatus"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "taskVersion": {
                    "name": "taskVersion",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "payload": {
                    "name": "payload",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": true,
                    "attributes": []
                },
                "tenantCode": {
                    "name": "tenantCode",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "InternalTasks",
            "attributes": [
                {
                    "type": "model",
                    "properties": {
                        "subscriptions": {
                            "onUpdate": [
                                "onUpdateInternalTaskById(id: ID!)"
                            ]
                        }
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "tenantCode",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "provider": "iam",
                                "allow": "private",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Playground": {
            "name": "Playground",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": {
                        "enum": "PlaygroundStatus"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "email": {
                    "name": "email",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "tenantCode": {
                    "name": "tenantCode",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "tenantName": {
                    "name": "tenantName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "userCode": {
                    "name": "userCode",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "temporaryPassword": {
                    "name": "temporaryPassword",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "Playgrounds",
            "attributes": [
                {
                    "type": "model",
                    "properties": {
                        "subscriptions": {
                            "onUpdate": [
                                "onUpdatePlaygroundById(id: ID!)"
                            ]
                        }
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byTenantCode",
                        "fields": [
                            "tenantCode"
                        ],
                        "queryField": "playgroundsByTenantCode"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byStatus",
                        "fields": [
                            "status"
                        ],
                        "queryField": "playgroundsByStatus"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "provider": "iam",
                                "allow": "public",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Questionnaire": {
            "name": "Questionnaire",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "groupId": {
                    "name": "groupId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "tenantCode": {
                    "name": "tenantCode",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "title": {
                    "name": "title",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "userIds": {
                    "name": "userIds",
                    "isArray": true,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "text": {
                    "name": "text",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "createdBy": {
                    "name": "createdBy",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "options": {
                    "name": "options",
                    "isArray": true,
                    "type": "String",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "respondent": {
                    "name": "respondent",
                    "isArray": true,
                    "type": {
                        "model": "QuestionnaireRespondent"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "questionnaireId"
                    }
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": {
                        "enum": "QuestionnaireStatus"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "finishedAt": {
                    "name": "finishedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "Questionnaires",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byGroup",
                        "fields": [
                            "groupId"
                        ],
                        "queryField": "questionnaireByGroup"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byTenantCode",
                        "fields": [
                            "tenantCode"
                        ],
                        "queryField": "questionnaireByTenantCode"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "tenantCode",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ],
                                "groupField": "groups"
                            }
                        ]
                    }
                }
            ]
        },
        "QuestionnaireRespondent": {
            "name": "QuestionnaireRespondent",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "questionnaireId": {
                    "name": "questionnaireId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "userId": {
                    "name": "userId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "selectedIndex": {
                    "name": "selectedIndex",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "tenantCode": {
                    "name": "tenantCode",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "QuestionnaireRespondents",
            "attributes": [
                {
                    "type": "model",
                    "properties": {
                        "subscriptions": {
                            "onCreate": [
                                "onCreateQuestionnaireRespondentByQuestionnaireId(questionnaireId: ID!)"
                            ],
                            "onUpdate": [
                                "onUpdateQuestionnaireRespondentByQuestionnaireId(questionnaireId: ID!)"
                            ],
                            "onDelete": [
                                "onDeleteQuestionnaireRespondentByQuestionnaireId(questionnaireId: ID!)"
                            ]
                        }
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byQuestionnaire",
                        "fields": [
                            "questionnaireId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byUserId",
                        "fields": [
                            "userId"
                        ],
                        "queryField": "questionnaireRespondentByUserId"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byQuestionnaireIdAndUserId",
                        "fields": [
                            "questionnaireId",
                            "userId"
                        ],
                        "queryField": "questionnaireRespondentByQuestionnaireIdAndUserId"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byTenantCode",
                        "fields": [
                            "tenantCode"
                        ],
                        "queryField": "questionnaireRespondentByTenantCode"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "tenantCode",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ],
                                "groupField": "groups"
                            }
                        ]
                    }
                }
            ]
        },
        "Content": {
            "name": "Content",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "contentId": {
                    "name": "contentId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "version": {
                    "name": "version",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "requiredTime": {
                    "name": "requiredTime",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "body": {
                    "name": "body",
                    "isArray": false,
                    "type": {
                        "nonModel": "ContentBody"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "workbook": {
                    "name": "workbook",
                    "isArray": false,
                    "type": {
                        "nonModel": "Workbook"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "courseId": {
                    "name": "courseId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "courseVersion": {
                    "name": "courseVersion",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "tenantCode": {
                    "name": "tenantCode",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "latest": {
                    "name": "latest",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "versionDescription": {
                    "name": "versionDescription",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "Contents",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byCourse",
                        "fields": [
                            "courseId",
                            "courseVersion"
                        ],
                        "queryField": "contentByCourse"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byTenantCode",
                        "fields": [
                            "tenantCode"
                        ],
                        "queryField": "contentsByTenantCode"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byContent",
                        "fields": [
                            "contentId",
                            "version"
                        ],
                        "queryField": "contentsByContent"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "tenantCode",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "provider": "iam",
                                "allow": "private",
                                "operations": [
                                    "create",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Course": {
            "name": "Course",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "version": {
                    "name": "version",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "contents": {
                    "name": "contents",
                    "isArray": true,
                    "type": {
                        "nonModel": "CourseContent"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "description": {
                    "name": "description",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "tenantCode": {
                    "name": "tenantCode",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "color": {
                    "name": "color",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "image": {
                    "name": "image",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "fontColorOnImage": {
                    "name": "fontColorOnImage",
                    "isArray": false,
                    "type": {
                        "enum": "CourseFontColorOnImage"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "confirmedBy": {
                    "name": "confirmedBy",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "versionCreatedBy": {
                    "name": "versionCreatedBy",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "versionCreatedAt": {
                    "name": "versionCreatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "contentLastUpdatedBy": {
                    "name": "contentLastUpdatedBy",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "contentLastUpdatedAt": {
                    "name": "contentLastUpdatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "Courses",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "fields": [
                            "id",
                            "version"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byName",
                        "fields": [
                            "name"
                        ],
                        "queryField": "coursesByName"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byTenantCode",
                        "fields": [
                            "tenantCode"
                        ],
                        "queryField": "coursesByTenantCode"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "tenantCode",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "provider": "iam",
                                "allow": "private",
                                "operations": [
                                    "create",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "EditingCourseContentWorkbook": {
            "name": "EditingCourseContentWorkbook",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "contentId": {
                    "name": "contentId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "problems": {
                    "name": "problems",
                    "isArray": true,
                    "type": {
                        "nonModel": "Problem"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "problemHeaders": {
                    "name": "problemHeaders",
                    "isArray": true,
                    "type": {
                        "nonModel": "ProblemHeader"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "dataVersion": {
                    "name": "dataVersion",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "createdBy": {
                    "name": "createdBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "updatedBy": {
                    "name": "updatedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "tenantCode": {
                    "name": "tenantCode",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "courseId": {
                    "name": "courseId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "EditingCourseContentWorkbooks",
            "attributes": [
                {
                    "type": "model",
                    "properties": {
                        "subscriptions": null
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "fields": [
                            "contentId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byCourse",
                        "fields": [
                            "courseId"
                        ],
                        "queryField": "editingCourseContentWorkbooksByCourse"
                    }
                },
                {
                    "type": "versioned",
                    "properties": {
                        "versionField": "dataVersion",
                        "versionInput": "expectedDataVersion"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "tenantCode",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "provider": "iam",
                                "allow": "private",
                                "operations": [
                                    "create",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "EditingCourseContentBody": {
            "name": "EditingCourseContentBody",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "contentId": {
                    "name": "contentId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "body": {
                    "name": "body",
                    "isArray": false,
                    "type": {
                        "nonModel": "ContentBody"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "dataVersion": {
                    "name": "dataVersion",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "createdBy": {
                    "name": "createdBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "updatedBy": {
                    "name": "updatedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "tenantCode": {
                    "name": "tenantCode",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "courseId": {
                    "name": "courseId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "EditingCourseContentBodies",
            "attributes": [
                {
                    "type": "model",
                    "properties": {
                        "subscriptions": null
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "fields": [
                            "contentId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byCourse",
                        "fields": [
                            "courseId"
                        ],
                        "queryField": "editingCourseContentBodiesByCourse"
                    }
                },
                {
                    "type": "versioned",
                    "properties": {
                        "versionField": "dataVersion",
                        "versionInput": "expectedDataVersion"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "tenantCode",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "provider": "iam",
                                "allow": "private",
                                "operations": [
                                    "create",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "EditingConfirmedContent": {
            "name": "EditingConfirmedContent",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "version": {
                    "name": "version",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "requiredTime": {
                    "name": "requiredTime",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "body": {
                    "name": "body",
                    "isArray": false,
                    "type": {
                        "nonModel": "ContentBody"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "workbook": {
                    "name": "workbook",
                    "isArray": false,
                    "type": {
                        "nonModel": "Workbook"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "courseId": {
                    "name": "courseId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "courseVersion": {
                    "name": "courseVersion",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": {
                        "enum": "EditingStatus"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "tenantCode": {
                    "name": "tenantCode",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "createdBy": {
                    "name": "createdBy",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "updatedBy": {
                    "name": "updatedBy",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "dataVersion": {
                    "name": "dataVersion",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "versionDescription": {
                    "name": "versionDescription",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "EditingConfirmedContents",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byTenantCode",
                        "fields": [
                            "tenantCode"
                        ],
                        "queryField": "editingConfirmedContentsByTenantCode"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byCourse",
                        "fields": [
                            "courseId"
                        ],
                        "queryField": "editingConfirmedContentsByCourse"
                    }
                },
                {
                    "type": "versioned",
                    "properties": {
                        "versionField": "dataVersion",
                        "versionInput": "expectedDataVersion"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "tenantCode",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "provider": "iam",
                                "allow": "private",
                                "operations": [
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "ExamResult": {
            "name": "ExamResult",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "groupId": {
                    "name": "groupId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "courseId": {
                    "name": "courseId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "contentId": {
                    "name": "contentId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "contentVersion": {
                    "name": "contentVersion",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "userId": {
                    "name": "userId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "start": {
                    "name": "start",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "end": {
                    "name": "end",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "score": {
                    "name": "score",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "problemCount": {
                    "name": "problemCount",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "tenantCode": {
                    "name": "tenantCode",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "answers": {
                    "name": "answers",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "visibilityLevel": {
                    "name": "visibilityLevel",
                    "isArray": false,
                    "type": {
                        "enum": "ExamResultVisibilityLevel"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "groupExamId": {
                    "name": "groupExamId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "times": {
                    "name": "times",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "passingStandard": {
                    "name": "passingStandard",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "version": {
                    "name": "version",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "ExamResults",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byUserId",
                        "fields": [
                            "groupId",
                            "userId"
                        ],
                        "queryField": "examResultsByUserId"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byGroupAndContent",
                        "fields": [
                            "groupId",
                            "contentId"
                        ],
                        "queryField": "examResultsByGroupAndContent"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byGroupAndCourse",
                        "fields": [
                            "groupId",
                            "courseId"
                        ],
                        "queryField": "examResultsByGroupAndCourse"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byGroupExam",
                        "fields": [
                            "groupExamId"
                        ],
                        "queryField": "examResultsByGroupExam"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byTenantCode",
                        "fields": [
                            "tenantCode"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "tenantCode",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "provider": "iam",
                                "allow": "private",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "GroupTraining": {
            "name": "GroupTraining",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "groupId": {
                    "name": "groupId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "tenantCode": {
                    "name": "tenantCode",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "GroupTrainings",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byGroupId",
                        "fields": [
                            "groupId"
                        ],
                        "queryField": "groupTrainingByGroupId"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byTenantCode",
                        "fields": [
                            "tenantCode"
                        ],
                        "queryField": "groupTrainingsByTenantCode"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "tenantCode",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "provider": "iam",
                                "allow": "private",
                                "operations": [
                                    "create",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "GroupTrainingCourse": {
            "name": "GroupTrainingCourse",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "groupTrainingId": {
                    "name": "groupTrainingId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "courseId": {
                    "name": "courseId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "courseVersion": {
                    "name": "courseVersion",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "courseName": {
                    "name": "courseName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "contents": {
                    "name": "contents",
                    "isArray": true,
                    "type": {
                        "nonModel": "GroupTrainingCourseContent"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "groupId": {
                    "name": "groupId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "tenantCode": {
                    "name": "tenantCode",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "color": {
                    "name": "color",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "image": {
                    "name": "image",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "fontColorOnImage": {
                    "name": "fontColorOnImage",
                    "isArray": false,
                    "type": {
                        "enum": "CourseFontColorOnImage"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "displayName": {
                    "name": "displayName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "groupExams": {
                    "name": "groupExams",
                    "isArray": true,
                    "type": {
                        "model": "GroupExam"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "groupTrainingCourseId"
                    }
                },
                "index": {
                    "name": "index",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "GroupTrainingCourses",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byGroupTraining",
                        "fields": [
                            "groupTrainingId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byGroupIdAndCourseId",
                        "fields": [
                            "groupId",
                            "courseId"
                        ],
                        "queryField": "groupTrainingCoursesByGroupIdAndCourseId"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byCourseId",
                        "fields": [
                            "courseId"
                        ],
                        "queryField": "groupTrainingCoursesByCourseId"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "tenantCode",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "provider": "iam",
                                "allow": "private",
                                "operations": [
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "GroupExam": {
            "name": "GroupExam",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "groupId": {
                    "name": "groupId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "contentId": {
                    "name": "contentId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "content": {
                    "name": "content",
                    "isArray": false,
                    "type": {
                        "nonModel": "GroupExamContent"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "problemsJson": {
                    "name": "problemsJson",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "courseId": {
                    "name": "courseId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "course": {
                    "name": "course",
                    "isArray": false,
                    "type": {
                        "nonModel": "GroupExamCourse"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "scheduledStart": {
                    "name": "scheduledStart",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "scheduledFinish": {
                    "name": "scheduledFinish",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "createdBy": {
                    "name": "createdBy",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "finishedAt": {
                    "name": "finishedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "visibilityLevel": {
                    "name": "visibilityLevel",
                    "isArray": false,
                    "type": {
                        "enum": "ExamResultVisibilityLevel"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "userExams": {
                    "name": "userExams",
                    "isArray": true,
                    "type": {
                        "model": "UserExam"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "groupExam"
                    }
                },
                "tenantCode": {
                    "name": "tenantCode",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "times": {
                    "name": "times",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "groupTrainingCourseId": {
                    "name": "groupTrainingCourseId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "problemHeaders": {
                    "name": "problemHeaders",
                    "isArray": true,
                    "type": {
                        "nonModel": "ProblemHeader"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "timeLimit": {
                    "name": "timeLimit",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "passingStandard": {
                    "name": "passingStandard",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "userIdsToBeTested": {
                    "name": "userIdsToBeTested",
                    "isArray": true,
                    "type": "String",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                }
            },
            "syncable": true,
            "pluralName": "GroupExams",
            "attributes": [
                {
                    "type": "model",
                    "properties": {
                        "subscriptions": null
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byGroup",
                        "fields": [
                            "groupId"
                        ],
                        "queryField": "groupExamsByGroup"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byGroupAndContent",
                        "fields": [
                            "groupId",
                            "contentId"
                        ],
                        "queryField": "groupExamsByGroupAndContent"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byGroupAndCourse",
                        "fields": [
                            "groupId",
                            "courseId"
                        ],
                        "queryField": "groupExamsByGroupAndCourse"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byCourse",
                        "fields": [
                            "courseId"
                        ],
                        "queryField": "groupExamsByCourse"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byGroupTrainingCourse",
                        "fields": [
                            "groupTrainingCourseId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byTenantCode",
                        "fields": [
                            "tenantCode"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "tenantCode",
                                "operations": [
                                    "create",
                                    "update",
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "provider": "iam",
                                "allow": "private",
                                "operations": [
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "UserExam": {
            "name": "UserExam",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "userId": {
                    "name": "userId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": {
                        "enum": "UserExamStatus"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "tenantCode": {
                    "name": "tenantCode",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "groupExam": {
                    "name": "groupExam",
                    "isArray": false,
                    "type": {
                        "model": "GroupExam"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetName": "groupExamId"
                    }
                },
                "groupId": {
                    "name": "groupId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "startedAt": {
                    "name": "startedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "finishedAt": {
                    "name": "finishedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "UserExams",
            "attributes": [
                {
                    "type": "model",
                    "properties": {
                        "subscriptions": null
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byGroupExam",
                        "fields": [
                            "groupExamId"
                        ],
                        "queryField": "userExamsByGroupExam"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byGroupAndUser",
                        "fields": [
                            "groupId",
                            "userId"
                        ],
                        "queryField": "userExamsByGroupAndUser"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byUser",
                        "fields": [
                            "userId",
                            "status"
                        ],
                        "queryField": "userExamsByUser"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byTenantCode",
                        "fields": [
                            "tenantCode"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "tenantCode",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "provider": "iam",
                                "allow": "private",
                                "operations": [
                                    "update",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "ScheduleTag": {
            "name": "ScheduleTag",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "color": {
                    "name": "color",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "text": {
                    "name": "text",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "groupId": {
                    "name": "groupId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "tenantCode": {
                    "name": "tenantCode",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "schedules": {
                    "name": "schedules",
                    "isArray": true,
                    "type": {
                        "model": "ScheduleScheduleTag"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "scheduleTag"
                    }
                }
            },
            "syncable": true,
            "pluralName": "ScheduleTags",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byGroupId",
                        "fields": [
                            "groupId",
                            "text"
                        ],
                        "queryField": "scheduleTagsByGroupId"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "tenantCode",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ],
                                "groupField": "groups"
                            }
                        ]
                    }
                }
            ]
        },
        "ScheduleScheduleTag": {
            "name": "ScheduleScheduleTag",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "schedule": {
                    "name": "schedule",
                    "isArray": false,
                    "type": {
                        "model": "Schedule"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetName": "scheduleId"
                    }
                },
                "scheduleTag": {
                    "name": "scheduleTag",
                    "isArray": false,
                    "type": {
                        "model": "ScheduleTag"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetName": "scheduleTagId"
                    }
                },
                "tenantCode": {
                    "name": "tenantCode",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "ScheduleScheduleTags",
            "attributes": [
                {
                    "type": "model",
                    "properties": {
                        "queries": null
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "bySchedule",
                        "fields": [
                            "scheduleId",
                            "scheduleTagId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byScheduleTag",
                        "fields": [
                            "scheduleTagId",
                            "scheduleId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "tenantCode",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "provider": "iam",
                                "allow": "private",
                                "operations": [
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Schedule": {
            "name": "Schedule",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "groupId": {
                    "name": "groupId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "start": {
                    "name": "start",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "end": {
                    "name": "end",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "title": {
                    "name": "title",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "body": {
                    "name": "body",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "tenantCode": {
                    "name": "tenantCode",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "createdBy": {
                    "name": "createdBy",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "updatedBy": {
                    "name": "updatedBy",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "tags": {
                    "name": "tags",
                    "isArray": true,
                    "type": {
                        "model": "ScheduleScheduleTag"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "schedule"
                    }
                }
            },
            "syncable": true,
            "pluralName": "Schedules",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byTenantCode",
                        "fields": [
                            "tenantCode",
                            "updatedAt"
                        ],
                        "queryField": "schedulesByTenantCode"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byGroupId",
                        "fields": [
                            "groupId",
                            "start"
                        ],
                        "queryField": "schedulesByGroupId"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byEnd",
                        "fields": [
                            "tenantCode",
                            "end"
                        ],
                        "queryField": "schedulesByEnd"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byGroupIdAndEnd",
                        "fields": [
                            "groupId",
                            "end"
                        ],
                        "queryField": "schedulesByGroupIdAndEnd"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "tenantCode",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "provider": "iam",
                                "allow": "private",
                                "operations": [
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Comment": {
            "name": "Comment",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "questionId": {
                    "name": "questionId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "body": {
                    "name": "body",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "createdBy": {
                    "name": "createdBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "editedBy": {
                    "name": "editedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "editedAt": {
                    "name": "editedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "tenantCode": {
                    "name": "tenantCode",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "groupId": {
                    "name": "groupId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "Comments",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byQuestion",
                        "fields": [
                            "questionId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byTenantCode",
                        "fields": [
                            "tenantCode"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byGroup",
                        "fields": [
                            "groupId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "tenantCode",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "provider": "iam",
                                "allow": "private",
                                "operations": [
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Question": {
            "name": "Question",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "createdBy": {
                    "name": "createdBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "comments": {
                    "name": "comments",
                    "isArray": true,
                    "type": {
                        "model": "Comment"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "questionId"
                    }
                },
                "resolved": {
                    "name": "resolved",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "resolvedAt": {
                    "name": "resolvedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "resolvedBy": {
                    "name": "resolvedBy",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "groupId": {
                    "name": "groupId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "title": {
                    "name": "title",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "referTo": {
                    "name": "referTo",
                    "isArray": false,
                    "type": {
                        "nonModel": "ContentReference"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "tenantCode": {
                    "name": "tenantCode",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "assignees": {
                    "name": "assignees",
                    "isArray": true,
                    "type": "String",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                }
            },
            "syncable": true,
            "pluralName": "Questions",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byTenantCode",
                        "fields": [
                            "tenantCode"
                        ],
                        "queryField": "questionsByTenantCode"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byGroup",
                        "fields": [
                            "groupId",
                            "createdAt"
                        ],
                        "queryField": "questionsByGroup"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "tenantCode",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "provider": "iam",
                                "allow": "private",
                                "operations": [
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "ContentLearning": {
            "name": "ContentLearning",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "groupId": {
                    "name": "groupId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "courseId": {
                    "name": "courseId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "contentId": {
                    "name": "contentId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "contentType": {
                    "name": "contentType",
                    "isArray": false,
                    "type": {
                        "enum": "ContentType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "userId": {
                    "name": "userId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": {
                        "enum": "LearningStatus"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "usageTime": {
                    "name": "usageTime",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "lastStartedAt": {
                    "name": "lastStartedAt",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "dataVersion": {
                    "name": "dataVersion",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "tenantCode": {
                    "name": "tenantCode",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "completedAt": {
                    "name": "completedAt",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "completedUsageTime": {
                    "name": "completedUsageTime",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "ContentLearnings",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "fields": [
                            "groupId",
                            "contentId",
                            "userId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byGroupIdAndCourseId",
                        "fields": [
                            "groupId",
                            "courseId"
                        ],
                        "queryField": "contentLearningsByGroupIdAndCourseId"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byGroupIdAndUserId",
                        "fields": [
                            "groupId",
                            "userId"
                        ],
                        "queryField": "contentLearningsByGroupIdAndUserId"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byUser",
                        "fields": [
                            "userId"
                        ],
                        "queryField": "contentLearningsByUser"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byTenantCode",
                        "fields": [
                            "tenantCode"
                        ]
                    }
                },
                {
                    "type": "versioned",
                    "properties": {
                        "versionField": "dataVersion",
                        "versionInput": "expectedDataVersion"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "tenantCode",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "provider": "iam",
                                "allow": "private",
                                "operations": [
                                    "create",
                                    "update",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Memo": {
            "name": "Memo",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "body": {
                    "name": "body",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "referTo": {
                    "name": "referTo",
                    "isArray": false,
                    "type": {
                        "nonModel": "ContentReference"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "scope": {
                    "name": "scope",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "groupId": {
                    "name": "groupId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "createdBy": {
                    "name": "createdBy",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "updatedBy": {
                    "name": "updatedBy",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "tenantCode": {
                    "name": "tenantCode",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "Memos",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byGroup",
                        "fields": [
                            "groupId"
                        ],
                        "queryField": "memosByGroup"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byTenantCode",
                        "fields": [
                            "tenantCode"
                        ],
                        "queryField": "memosByTenantCode"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "tenantCode",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ],
                                "groupField": "groups"
                            }
                        ]
                    }
                }
            ]
        },
        "ReviewProblem": {
            "name": "ReviewProblem",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "userId": {
                    "name": "userId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "groupId": {
                    "name": "groupId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "courseId": {
                    "name": "courseId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "problems": {
                    "name": "problems",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "tenantCode": {
                    "name": "tenantCode",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "ReviewProblems",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byGroup",
                        "fields": [
                            "groupId",
                            "userId"
                        ],
                        "queryField": "reviewProblemsByGroupAndUser"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byTenantCode",
                        "fields": [
                            "tenantCode"
                        ],
                        "queryField": "reviewProblemsByTenantCode"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "tenantCode",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ],
                                "groupField": "groups"
                            }
                        ]
                    }
                }
            ]
        }
    },
    "enums": {
        "Role": {
            "name": "Role",
            "values": [
                "GENERAL",
                "ADMIN",
                "SUPERVISOR"
            ]
        },
        "GroupRole": {
            "name": "GroupRole",
            "values": [
                "TRAINER",
                "TRAINEE",
                "MENTOR"
            ]
        },
        "EditingStatus": {
            "name": "EditingStatus",
            "values": [
                "EDITING",
                "CONFIRMED"
            ]
        },
        "ContentType": {
            "name": "ContentType",
            "values": [
                "EXAM",
                "SLIDE",
                "TEXT"
            ]
        },
        "CourseFontColorOnImage": {
            "name": "CourseFontColorOnImage",
            "values": [
                "BLACK",
                "WHITE"
            ]
        },
        "CourseStatus": {
            "name": "CourseStatus",
            "values": [
                "EDITING",
                "ENABLED",
                "DISABLED"
            ]
        },
        "UserStatus": {
            "name": "UserStatus",
            "values": [
                "ACTIVE",
                "INACTIVE"
            ]
        },
        "SignUpType": {
            "name": "SignUpType",
            "values": [
                "USER_CODE",
                "EMAIL"
            ]
        },
        "SignUpReservationStatus": {
            "name": "SignUpReservationStatus",
            "values": [
                "NOT_SIGNED_UP",
                "SIGNED_UP",
                "CONFIRMED"
            ]
        },
        "TenantOwnTermsOfServiceType": {
            "name": "TenantOwnTermsOfServiceType",
            "values": [
                "EDITING",
                "CONFIRMED"
            ]
        },
        "HandStatus": {
            "name": "HandStatus",
            "values": [
                "YES",
                "NO",
                "NONE"
            ]
        },
        "InternalTaskStatus": {
            "name": "InternalTaskStatus",
            "values": [
                "CREATED",
                "IN_PROGRESS",
                "FAILED",
                "SUCCEEDED"
            ]
        },
        "PlaygroundStatus": {
            "name": "PlaygroundStatus",
            "values": [
                "RESERVED",
                "CREATING",
                "CREATED",
                "USED",
                "REMOVED"
            ]
        },
        "QuestionnaireStatus": {
            "name": "QuestionnaireStatus",
            "values": [
                "FINISHED",
                "ACTIVE"
            ]
        },
        "ProblemType": {
            "name": "ProblemType",
            "values": [
                "CHOICE"
            ]
        },
        "ExamResultVisibilityLevel": {
            "name": "ExamResultVisibilityLevel",
            "values": [
                "INVISIBLE_TO_USER",
                "DETAILS",
                "SCORE"
            ]
        },
        "UserExamStatus": {
            "name": "UserExamStatus",
            "values": [
                "NOT_STARTED",
                "IN_PROGRESS",
                "FINISHED",
                "SKIPPED"
            ]
        },
        "LearningStatus": {
            "name": "LearningStatus",
            "values": [
                "NOT_BEGUN",
                "IN_PROGRESS",
                "COMPLETED"
            ]
        }
    },
    "nonModels": {
        "Policy": {
            "name": "Policy",
            "fields": {
                "policy": {
                    "name": "policy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "keyPairId": {
                    "name": "keyPairId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "signature": {
                    "name": "signature",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "domain": {
                    "name": "domain",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "expire": {
                    "name": "expire",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "SignedUrl": {
            "name": "SignedUrl",
            "fields": {
                "signedUrl": {
                    "name": "signedUrl",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "path": {
                    "name": "path",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "key": {
                    "name": "key",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "PrivacyPolicyVersion": {
            "name": "PrivacyPolicyVersion",
            "fields": {
                "version": {
                    "name": "version",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "InviteUserResponse": {
            "name": "InviteUserResponse",
            "fields": {
                "signUpReservationId": {
                    "name": "signUpReservationId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "UploadResponse": {
            "name": "UploadResponse",
            "fields": {
                "url": {
                    "name": "url",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "key": {
                    "name": "key",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "GroupUser": {
            "name": "GroupUser",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "role": {
                    "name": "role",
                    "isArray": false,
                    "type": {
                        "enum": "GroupRole"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "removed": {
                    "name": "removed",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "EditingCourseContentHeader": {
            "name": "EditingCourseContentHeader",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "requiredTime": {
                    "name": "requiredTime",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": {
                        "enum": "ContentType"
                    },
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "CreateUserByAdminResponse": {
            "name": "CreateUserByAdminResponse",
            "fields": {
                "userId": {
                    "name": "userId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "DisplaySettings": {
            "name": "DisplaySettings",
            "fields": {
                "theme": {
                    "name": "theme",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "color": {
                    "name": "color",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "ConfirmedTermsOfServiceVersions": {
            "name": "ConfirmedTermsOfServiceVersions",
            "fields": {
                "termsOfServiceVersion": {
                    "name": "termsOfServiceVersion",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "privacyPolicyVersion": {
                    "name": "privacyPolicyVersion",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "tenantOwnTermsOfServiceVersion": {
                    "name": "tenantOwnTermsOfServiceVersion",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "SignUpInput": {
            "name": "SignUpInput",
            "fields": {
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "tenantName": {
                    "name": "tenantName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "Problem": {
            "name": "Problem",
            "fields": {
                "index": {
                    "name": "index",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "body": {
                    "name": "body",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "commentary": {
                    "name": "commentary",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": {
                        "enum": "ProblemType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "choice": {
                    "name": "choice",
                    "isArray": false,
                    "type": {
                        "nonModel": "ChoiceProblem"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "headerId": {
                    "name": "headerId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "ChoiceProblem": {
            "name": "ChoiceProblem",
            "fields": {
                "options": {
                    "name": "options",
                    "isArray": true,
                    "type": {
                        "nonModel": "ChoiceProblemOption"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "multiple": {
                    "name": "multiple",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "answer": {
                    "name": "answer",
                    "isArray": true,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                }
            }
        },
        "ChoiceProblemOption": {
            "name": "ChoiceProblemOption",
            "fields": {
                "index": {
                    "name": "index",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "text": {
                    "name": "text",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "Workbook": {
            "name": "Workbook",
            "fields": {
                "problems": {
                    "name": "problems",
                    "isArray": true,
                    "type": {
                        "nonModel": "Problem"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "problemHeaders": {
                    "name": "problemHeaders",
                    "isArray": true,
                    "type": {
                        "nonModel": "ProblemHeader"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                }
            }
        },
        "ProblemHeader": {
            "name": "ProblemHeader",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "body": {
                    "name": "body",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "ContentBody": {
            "name": "ContentBody",
            "fields": {
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": {
                        "enum": "ContentType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "text": {
                    "name": "text",
                    "isArray": false,
                    "type": {
                        "nonModel": "TextContent"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "exam": {
                    "name": "exam",
                    "isArray": false,
                    "type": {
                        "nonModel": "ExamContent"
                    },
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "TextContent": {
            "name": "TextContent",
            "fields": {
                "body": {
                    "name": "body",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "ExamContent": {
            "name": "ExamContent",
            "fields": {
                "problems": {
                    "name": "problems",
                    "isArray": true,
                    "type": {
                        "nonModel": "Problem"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "problemHeaders": {
                    "name": "problemHeaders",
                    "isArray": true,
                    "type": {
                        "nonModel": "ProblemHeader"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "passingStandard": {
                    "name": "passingStandard",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "CourseContent": {
            "name": "CourseContent",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": {
                        "enum": "ContentType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "requiredTime": {
                    "name": "requiredTime",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "Error": {
            "name": "Error",
            "fields": {
                "message": {
                    "name": "message",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "EncodeAudioResponse": {
            "name": "EncodeAudioResponse",
            "fields": {
                "url": {
                    "name": "url",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "key": {
                    "name": "key",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "GroupTrainingCourseContent": {
            "name": "GroupTrainingCourseContent",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "requiredTime": {
                    "name": "requiredTime",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": {
                        "enum": "ContentType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "recommendedDateTime": {
                    "name": "recommendedDateTime",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "open": {
                    "name": "open",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "version": {
                    "name": "version",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "GroupExamContent": {
            "name": "GroupExamContent",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "version": {
                    "name": "version",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "timeLimit": {
                    "name": "timeLimit",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "requiredTime": {
                    "name": "requiredTime",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "indexInCourse": {
                    "name": "indexInCourse",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "passingStandard": {
                    "name": "passingStandard",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "GroupExamCourse": {
            "name": "GroupExamCourse",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "version": {
                    "name": "version",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "color": {
                    "name": "color",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "image": {
                    "name": "image",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "fontColorOnImage": {
                    "name": "fontColorOnImage",
                    "isArray": false,
                    "type": {
                        "enum": "CourseFontColorOnImage"
                    },
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "TextContentReferenceOptions": {
            "name": "TextContentReferenceOptions",
            "fields": {
                "selectionJson": {
                    "name": "selectionJson",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "WorkbookReferenceOptions": {
            "name": "WorkbookReferenceOptions",
            "fields": {
                "problemIndex": {
                    "name": "problemIndex",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "selectionJson": {
                    "name": "selectionJson",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "ContentReferenceOptions": {
            "name": "ContentReferenceOptions",
            "fields": {
                "text": {
                    "name": "text",
                    "isArray": false,
                    "type": {
                        "nonModel": "TextContentReferenceOptions"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "workbook": {
                    "name": "workbook",
                    "isArray": false,
                    "type": {
                        "nonModel": "WorkbookReferenceOptions"
                    },
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "ContentReference": {
            "name": "ContentReference",
            "fields": {
                "contentId": {
                    "name": "contentId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "contentVersion": {
                    "name": "contentVersion",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "options": {
                    "name": "options",
                    "isArray": false,
                    "type": {
                        "nonModel": "ContentReferenceOptions"
                    },
                    "isRequired": false,
                    "attributes": []
                }
            }
        }
    },
    "version": "4f481f88bf10a5f7d4efa3886b57e26f"
};