import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { LocalDateTime, Minute, Optional } from '../types';
import { ContentId, ContentType, CourseId } from './content';
import { UserId } from './Core';
import { GroupId } from './Group';

export type LearningStatus = 'not_begun' | 'in_progress' | 'completed';

export type ContentLearning = {
  groupId: GroupId;
  courseId: CourseId;
  contentId: ContentId;
  contentType: ContentType;
  userId: UserId;
  status: LearningStatus;
  usageTime: Minute;
  completedAt?: LocalDateTime;
  completedUsageTime?: Minute;
  lastLearnedAt?: LocalDateTime;
  startedAt: LocalDateTime;
};

export interface ContentLearningDataAdapter {
  findByGroupId(groupId: GroupId): Promise<Array<ContentLearning>>;
  findByGroupIdAndCourseId({
    groupId,
    courseId,
    userId,
  }: {
    groupId: GroupId;
    courseId: CourseId;
    userId?: UserId;
  }): Promise<Array<ContentLearning>>;
  findByGroupIdAndUserId(groupId: GroupId, userId: UserId): Promise<Array<ContentLearning>>;
  findByUserId(userId: UserId): Promise<Array<ContentLearning>>;
  findByKey({
    groupId,
    userId,
    contentId,
  }: {
    groupId: GroupId;
    userId: UserId;
    contentId: ContentId;
  }): Promise<Optional<ContentLearning>>;
}

export const ContentLearningDataAdapterKey = injectionKeyOf<ContentLearningDataAdapter>({
  boundedContext: 'base',
  type: 'adapter',
  name: 'ContentLearningDataAdapter',
});

export function useContentLearningDataAdapter(): ContentLearningDataAdapter {
  return requiredInject(ContentLearningDataAdapterKey);
}
