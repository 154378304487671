import { v4 } from 'uuid';

import { ContentId, hasId } from '@/base/domains';
import { Optional } from '@/base/types';
import { ContentData, ContentEntity, ContentRepository } from '@/contents/domains';
import { createContentEntity } from '@/contents/domains/ContentEntityImpl';

export class InMemoryContentRepository implements ContentRepository {
  private store: Map<ContentId, ContentEntity> = new Map();

  save(entity: ContentData): Promise<Readonly<ContentEntity>> {
    const e = hasId(entity)
      ? createContentEntity(entity)
      : createContentEntity({ ...entity, id: v4() });
    this.store.set(e.id, e);
    return Promise.resolve(e);
  }

  findById(id: ContentId): Promise<Optional<ContentEntity>> {
    return Promise.resolve(this.store.get(id));
  }

  remove(id: ContentId): Promise<void> {
    this.store.delete(id);
    return Promise.resolve();
  }

  findAll(): Promise<ContentEntity[]> {
    return Promise.resolve(Array.from(this.store.values()));
  }

  findTenantContents(): Promise<ContentEntity[]> {
    return Promise.resolve(Array.from(this.store.values()));
  }

  findByCourseId(courseId: string, courseVersion: number): Promise<ContentEntity[]> {
    return Promise.resolve(
      Array.from(this.store.values()).filter(
        (cn) => cn.courseId === courseId && cn.courseVersion === courseVersion
      )
    );
  }
}
