import { AuthorizationService, ContentId, DataVersion, DateTimeService } from '@/base/domains';
import { MarkDownString } from '@/base/types';
import {
  AbstractUseCase,
  assertDataVersion,
  assertEntityExists,
  UseCase,
  UseCaseResponse,
} from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import {
  EditingCourseContentWorkbookReference,
  EditingCourseContentWorkbookRepository,
} from '../domains';

export type AddProblemHeaderToEditingCourseContentWorkbookRequest = {
  id: ContentId;
  body: MarkDownString;
  expectedDataVersion: DataVersion;
};

export type AddProblemHeaderToEditingCourseContentWorkbookResponse = {
  workbook: EditingCourseContentWorkbookReference;
};

/**
 * 編集中コンテンツ練習問題に問題ヘッダーを追加する
 */
export interface AddProblemHeaderToEditingCourseContentWorkbook
  extends UseCase<
    AddProblemHeaderToEditingCourseContentWorkbookRequest,
    AddProblemHeaderToEditingCourseContentWorkbookResponse
  > {
  execute(
    request: AddProblemHeaderToEditingCourseContentWorkbookRequest
  ): Promise<UseCaseResponse<AddProblemHeaderToEditingCourseContentWorkbookResponse>>;
}

export class AddProblemHeaderToEditingCourseContentWorkbookImpl
  extends AbstractUseCase<
    AddProblemHeaderToEditingCourseContentWorkbookRequest,
    AddProblemHeaderToEditingCourseContentWorkbookResponse
  >
  implements AddProblemHeaderToEditingCourseContentWorkbook
{
  private authorizationService: AuthorizationService;

  private editingCourseContentWorkbookRepository: EditingCourseContentWorkbookRepository;

  private dateTimeService: DateTimeService;

  constructor(
    authorizationService: AuthorizationService,
    editingCourseContentWorkbookRepository: EditingCourseContentWorkbookRepository,
    dateTimeService: DateTimeService
  ) {
    super('contents.AddProblemHeaderToEditingCourseContentWorkbook');
    this.authorizationService = authorizationService;
    this.editingCourseContentWorkbookRepository = editingCourseContentWorkbookRepository;
    this.dateTimeService = dateTimeService;
  }

  async internalExecute(
    request: AddProblemHeaderToEditingCourseContentWorkbookRequest
  ): Promise<AddProblemHeaderToEditingCourseContentWorkbookResponse> {
    const { id, body, expectedDataVersion } = request;
    this.authorizationService.assertContentEditable();
    const [workbook, createdAt] = await Promise.all([
      this.editingCourseContentWorkbookRepository.findById(id),
      this.dateTimeService.strictLocalDateTimeNow(),
    ]);
    assertEntityExists(workbook, 'editingCourseContentWorkbook');
    assertDataVersion(workbook, expectedDataVersion, 'editingCourseContentWorkbook');
    const saved = await this.editingCourseContentWorkbookRepository.save(
      workbook.addProblemHeader({
        body,
        createdAt,
      })
    );
    return {
      workbook: saved,
    };
  }
}

export const AddProblemHeaderToEditingCourseContentWorkbookKey =
  injectionKeyOf<AddProblemHeaderToEditingCourseContentWorkbook>({
    boundedContext: 'contents',
    type: 'usecase',
    name: 'AddProblemHeaderToEditingCourseContentWorkbook',
  });

export function useAddProblemHeaderToEditingCourseContentWorkbook(): AddProblemHeaderToEditingCourseContentWorkbook {
  return requiredInject(AddProblemHeaderToEditingCourseContentWorkbookKey);
}
