import { MemoEntity, MemoEntityImpl } from '@/training/domains';
import { localDateTimeFromString } from '@/utils/DateUtils';

export const memos: Array<MemoEntity> = [
  new MemoEntityImpl({
    id: 'mId0',
    body: 'body',
    referTo: undefined,
    scope: { type: 'private' },
    groupId: 'gid',
    createdBy: 'uid',
    createdAt: localDateTimeFromString('2021-01-01T10:00:00.000Z'),
    updatedBy: 'uid',
    updatedAt: localDateTimeFromString('2021-01-01T10:00:00.000Z'),
  }),
  new MemoEntityImpl({
    id: 'mId1',
    body: 'body',
    referTo: undefined,
    scope: { type: 'private' },
    groupId: 'gid',
    createdBy: 'uid',
    createdAt: localDateTimeFromString('2021-01-01T10:00:00.000Z'),
    updatedBy: 'uid',
    updatedAt: localDateTimeFromString('2021-01-01T10:00:00.000Z'),
  }),
];
