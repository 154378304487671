import { AuthorizationService } from '@/base/domains';
import { AbstractUseCase, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import {
  EditingTenantOwnTermsOfServiceRepository,
  TenantOwnTermsOfServiceReference,
} from '../domains';

export type ConfirmEditingTenantOwnTermsOfServiceRequest = {
  version?: number;
};

export type ConfirmEditingTenantOwnTermsOfServiceResponse = {
  tenantOwnTermsOfService: TenantOwnTermsOfServiceReference;
};

export interface ConfirmEditingTenantOwnTermsOfService
  extends UseCase<
    ConfirmEditingTenantOwnTermsOfServiceRequest,
    ConfirmEditingTenantOwnTermsOfServiceResponse
  > {
  execute(
    request: ConfirmEditingTenantOwnTermsOfServiceRequest
  ): Promise<UseCaseResponse<ConfirmEditingTenantOwnTermsOfServiceResponse>>;
}

export class ConfirmEditingTenantOwnTermsOfServiceImpl
  extends AbstractUseCase<
    ConfirmEditingTenantOwnTermsOfServiceRequest,
    ConfirmEditingTenantOwnTermsOfServiceResponse
  >
  implements ConfirmEditingTenantOwnTermsOfService
{
  constructor(
    private authorizationService: AuthorizationService,
    private editingTenantOwnTermsOfServiceRepository: EditingTenantOwnTermsOfServiceRepository
  ) {
    super('admin.ConfirmEditingTenantOwnTermsOfService');
  }

  async internalExecute(
    request: ConfirmEditingTenantOwnTermsOfServiceRequest
  ): Promise<ConfirmEditingTenantOwnTermsOfServiceResponse> {
    this.authorizationService.assertRole('supervisor');
    const { version } = request;
    const res = await this.editingTenantOwnTermsOfServiceRepository.confirm(
      version ? version + 1 : 1
    );
    return { tenantOwnTermsOfService: res };
  }
}

export const ConfirmEditingTenantOwnTermsOfServiceKey =
  injectionKeyOf<ConfirmEditingTenantOwnTermsOfService>({
    boundedContext: 'admin',
    type: 'usecase',
    name: 'ConfirmEditingTenantOwnTermsOfService',
  });

export function useConfirmEditingTenantOwnTermsOfService(): ConfirmEditingTenantOwnTermsOfService {
  return requiredInject(ConfirmEditingTenantOwnTermsOfServiceKey);
}
