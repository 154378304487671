import { hasNonNullProperty, isObject } from '@/utils/TsUtils';

export class ApplicationError extends Error {
  code: string;

  payload?: {};

  constructor(args: { code: string; message: string; payload?: {} }) {
    super(args.message);
    this.name = new.target.name;
    this.code = args.code;
    this.payload = args.payload;
  }
}

export function isApplicationError(e: unknown): e is ApplicationError {
  return isObject(e) && hasNonNullProperty(e, 'code') && hasNonNullProperty(e, 'message');
}
