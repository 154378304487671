/**
 * 非同期処理結果
 */
export type RecursiveProcessResult<T, TPayload> = {
  /** 次の処理がある */
  hasNext: boolean;
  /** 処理結果 */
  result: T;
  /** ペイロード */
  payload?: TPayload;
};

/**
 * 非同期処理リクエスト
 */
export type RecursiveProcessRequest<T, TPayload = never> =
  | {
      first: true;
    }
  | {
      first: false;
      /** 前回の実行結果 */
      previousResult: RecursiveProcessResult<T, TPayload>;
    };

/**
 * 非同期処理
 */
export type AsyncRecursiveProcess<T, TPayload = never> = (
  request: RecursiveProcessRequest<T, TPayload>
) => Promise<RecursiveProcessResult<T, TPayload>>;

/**
 * 非同期処理を再起実行する
 * @param process 非同期処理
 * @returns 実行結果
 */
export async function executeAsync<T, TPayload = never>(
  process: AsyncRecursiveProcess<T, TPayload>
): Promise<T> {
  const exec: (previousResult: RecursiveProcessResult<T, TPayload>) => Promise<T> = async (
    previousResult: RecursiveProcessResult<T, TPayload>
  ) => {
    if (previousResult.hasNext) return process({ first: false, previousResult }).then(exec);
    return previousResult.result;
  };
  const previousResult = await process({ first: true });
  return exec(previousResult);
}
