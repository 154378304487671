import { FileStorage, FileStorageUploadArgs, SignedPolicy } from '@/base/domains';
import { LocalDateTime } from '@/base/types';
import { localDateTimeNow } from '@/utils/DateUtils';

export class InMemoryFileStorage implements FileStorage {
  setSignedCookie(): Promise<void> {
    return Promise.resolve();
  }

  clearSignedCookie(): void {
    // do nothing
  }

  async upload(args: FileStorageUploadArgs): Promise<string> {
    const { onProgress } = args;
    const pictureUri = 'https://images.pexels.com/photos/416160/pexels-photo-416160.jpeg';

    return new Promise<string>((resolve) => {
      if (onProgress) {
        let count = 0;
        const intervalId = setInterval(() => {
          count += 1;
          onProgress({ loaded: 500 * count, total: 2000 });
          if (count === 4) {
            clearInterval(intervalId);
            resolve(pictureUri);
          }
        }, 500);
      } else {
        resolve(pictureUri);
      }
    });
  }

  getSignedPolicy(): Promise<SignedPolicy> {
    return Promise.resolve({
      policy: 'dummy',
      signature: 'dummy',
      keyPairId: 'dummy',
      domain: 'dummy',
      expire: localDateTimeNow().add(1, 'day'),
    });
  }

  hasSignedCookie(): boolean {
    return false;
  }

  isValidSignedCookie(): boolean {
    return false;
  }

  getExpire(): LocalDateTime {
    return localDateTimeNow();
  }
}
