import { v4 } from 'uuid';

import { CourseId, EntityData, GroupId, hasId, ScheduleTagId } from '@/base/domains';
import { Optional } from '@/base/types';
import {
  ScheduleTagAttributes,
  ScheduleTagEntity,
  ScheduleTagEntityImpl,
  ScheduleTagRepository,
} from '@/training/domains';

export class InMemoryScheduleTagRepository implements ScheduleTagRepository {
  private store = new Map<CourseId, ScheduleTagEntity>();

  all(): Array<ScheduleTagEntity> {
    return Array.from(this.store.values());
  }

  save(
    args: ScheduleTagEntity | EntityData<string, ScheduleTagAttributes>
  ): Promise<ScheduleTagEntity> {
    if (hasId(args)) {
      const e = new ScheduleTagEntityImpl({
        ...args,
      });
      this.store.set(e.id, e);
      return Promise.resolve(e);
    }
    const e = new ScheduleTagEntityImpl({
      ...args,
      id: v4(),
    });
    this.store.set(e.id, e);
    return Promise.resolve(e);
  }

  findById(id: ScheduleTagId): Promise<Optional<ScheduleTagEntity>> {
    return Promise.resolve(this.store.get(id));
  }

  remove(id: ScheduleTagId): Promise<void> {
    Promise.resolve(this.store.delete(id));
    return Promise.resolve();
  }

  findByGroupId(groupId: GroupId): Promise<ScheduleTagEntity[]> {
    return Promise.resolve(Array.from(this.store.values()).filter((st) => st.groupId === groupId));
  }
}
