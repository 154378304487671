import { CourseId, CourseVersion } from '@/base/domains';
import { ContentHeader, ContentQueries } from '@/contents/domains/queries';

export class InMemoryContentQueries implements ContentQueries {
  async findContentHeadersByCourse(_: {
    courseId: CourseId;
    courseVersion: CourseVersion;
  }): Promise<ContentHeader[]> {
    return [];
  }

  async findTenantContentHeaders(): Promise<ContentHeader[]> {
    return [];
  }
}
