import { v4 } from 'uuid';

import { AuthorizationService, CourseId, CourseVersion } from '@/base/domains';
import { AbstractUseCase, assertEntityExists, UseCase, UseCaseResponse } from '@/base/usecases';
import { CourseRepository } from '@/contents/domains';
import { isDefined } from '@/utils/TsUtils';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { EditingCourseData, EditingCourseRepository } from '../domains/EditingCourse';
import { COURSE_ALREADY_STARTED_EDITING } from '../ErrorCodes';

export interface StartCourseEditingRequest {
  id: CourseId;
  originalVersion: CourseVersion;
}

export type StartCourseEditingResponse = {
  id: CourseId;
  version: CourseVersion;
};

/**
 * コースの編集を始める
 */
export interface StartCourseEditing
  extends UseCase<StartCourseEditingRequest, StartCourseEditingResponse> {
  execute(request: StartCourseEditingRequest): Promise<UseCaseResponse<StartCourseEditingResponse>>;
}

export class StartCourseEditingImpl
  extends AbstractUseCase<StartCourseEditingRequest, StartCourseEditingResponse>
  implements StartCourseEditing
{
  private authorizationService: AuthorizationService;

  private courseRepository: CourseRepository;

  private editingCourseRepository: EditingCourseRepository;

  constructor(
    authorizationService: AuthorizationService,
    courseRepository: CourseRepository,
    editingCourseRepository: EditingCourseRepository
  ) {
    super('contents.StartCourseEditing');
    this.authorizationService = authorizationService;
    this.courseRepository = courseRepository;
    this.editingCourseRepository = editingCourseRepository;
  }

  async internalExecute(request: StartCourseEditingRequest): Promise<StartCourseEditingResponse> {
    const { id, originalVersion } = request;
    this.authorizationService.assertContentEditable();
    const originalCourse = await this.courseRepository.findById({ id, version: originalVersion });
    assertEntityExists(originalCourse, 'course');
    const editingCourse = await this.editingCourseRepository.findById(id);
    if (isDefined(editingCourse)) {
      throw COURSE_ALREADY_STARTED_EDITING.toApplicationError({ payload: { courseId: id } });
    }
    const latestVersion = await this.courseRepository.findLatestVersion(id);
    const version = latestVersion + 1;
    const editingCourseData: EditingCourseData = {
      id,
      name: originalCourse.name,
      version,
      status: 'editing',
      description: originalCourse.description,
      contents: originalCourse.contents.map((cn) => ({
        type: cn.type,
        name: cn.name,
        id: v4(),
        requiredTime: cn.requiredTime,
      })),
      color: originalCourse.color,
      image: originalCourse.image,
    };
    await this.editingCourseRepository.save(editingCourseData);

    return {
      id,
      version,
    };
  }
}

export const StartCourseEditingKey = injectionKeyOf<StartCourseEditing>({
  boundedContext: 'base',
  type: 'usecase',
  name: 'StartCourseEditing',
});

export function useStartCourseEditing(): StartCourseEditing {
  return requiredInject(StartCourseEditingKey);
}
