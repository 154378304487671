import '@mdi/font/css/materialdesignicons.min.css';

import Vue from 'vue';
import Vuetify from 'vuetify/lib';

import locales from './locales';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#6600cc',
        secondary: '#6c757d',
        accent: '#1abb9c',
        error: '#e74c3c',
        info: '#3498db',
        success: '#26b99a',
        warning: '#f39c12',
      },
      dark: {
        primary: '#7986cb',
        secondary: '#cccccc',
        error: '#ff1744',
        info: '#40c4ff',
        success: '#00e676',
        warning: '#ffff00',
      },
    },
  },
  lang: { current: 'en', locales },
});
