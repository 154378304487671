import { AuthorizationService, ContentId, DataVersion } from '@/base/domains';
import {
  AbstractUseCase,
  assertDataVersion,
  assertEntityExists,
  UseCase,
  UseCaseResponse,
} from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import {
  EditingCourseContentBodyChangeBodyArgs,
  EditingCourseContentBodyReference,
  EditingCourseContentBodyRepository,
} from '../domains';

export interface UpdateEditingCourseContentBodyRequest {
  id: ContentId;
  body: EditingCourseContentBodyChangeBodyArgs;
  expectedDataVersion: DataVersion;
}

export type UpdateEditingCourseContentBodyResponse = {
  body: EditingCourseContentBodyReference;
};

/**
 * 編集中コースコンテンツボディを更新する
 */
export interface UpdateEditingCourseContentBody
  extends UseCase<UpdateEditingCourseContentBodyRequest, UpdateEditingCourseContentBodyResponse> {
  execute(
    request: UpdateEditingCourseContentBodyRequest
  ): Promise<UseCaseResponse<UpdateEditingCourseContentBodyResponse>>;
}

export class UpdateEditingCourseContentBodyImpl
  extends AbstractUseCase<
    UpdateEditingCourseContentBodyRequest,
    UpdateEditingCourseContentBodyResponse
  >
  implements UpdateEditingCourseContentBody
{
  private authorizationService: AuthorizationService;

  private editingCourseContentBodyRepository: EditingCourseContentBodyRepository;

  constructor(
    authorizationService: AuthorizationService,
    editingCourseContentBodyRepository: EditingCourseContentBodyRepository
  ) {
    super('contents.UpdateEditingCourseContentBody');
    this.authorizationService = authorizationService;
    this.editingCourseContentBodyRepository = editingCourseContentBodyRepository;
  }

  async internalExecute(
    request: UpdateEditingCourseContentBodyRequest
  ): Promise<UpdateEditingCourseContentBodyResponse> {
    const { id, body, expectedDataVersion } = request;
    this.authorizationService.assertContentEditable();
    const entity = await this.editingCourseContentBodyRepository.findById(id);
    assertEntityExists(entity, 'editingCourseContentBody');
    assertDataVersion(entity, expectedDataVersion, 'editingCourseContentBody');
    const saved = await this.editingCourseContentBodyRepository.save(entity.changeBody(body));
    return {
      body: saved,
    };
  }
}

export const UpdateEditingCourseContentBodyKey = injectionKeyOf<UpdateEditingCourseContentBody>({
  boundedContext: 'contents',
  type: 'usecase',
  name: 'UpdateEditingCourseContentBody',
});

export function useUpdateEditingCourseContentBody(): UpdateEditingCourseContentBody {
  return requiredInject(UpdateEditingCourseContentBodyKey);
}
