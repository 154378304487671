import { AuthorizationService } from '@/base/domains';
import { Memo, MemoId } from '@/base/domains/Memo';
import { MarkDownString } from '@/base/types';
import { AbstractUseCase, assertEntityExists, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { MemoRepository } from '../domains';

export type UpdateMemoBodyRequest = {
  id: MemoId;
  body: MarkDownString;
};

export type UpdateMemoBodyResponse = {
  memo: Memo;
};

export interface UpdateMemoBody extends UseCase<UpdateMemoBodyRequest, UpdateMemoBodyResponse> {
  execute(request: UpdateMemoBodyRequest): Promise<UseCaseResponse<UpdateMemoBodyResponse>>;
}

export class UpdateMemoBodyImpl
  extends AbstractUseCase<UpdateMemoBodyRequest, UpdateMemoBodyResponse>
  implements UpdateMemoBody
{
  constructor(
    private authorizationService: AuthorizationService,
    private memoRepository: MemoRepository
  ) {
    super('training.UpdateMemoBody');
  }

  async internalExecute(request: UpdateMemoBodyRequest): Promise<UpdateMemoBodyResponse> {
    const { id, body } = request;
    const memo = await this.memoRepository.findById(id);
    assertEntityExists(memo, 'memo');
    if (memo.scope.type === 'group') {
      this.authorizationService.assertTrainerOrMentorInGroup(memo.groupId);
    }
    const saved = await this.memoRepository.save(memo.updateBody(body));
    return { memo: saved };
  }
}

export const UpdateMemoBodyKey = injectionKeyOf<UpdateMemoBody>({
  boundedContext: 'training',
  type: 'usecase',
  name: 'UpdateMemoBody',
});

export function useUpdateMemoBody(): UpdateMemoBody {
  return requiredInject(UpdateMemoBodyKey);
}
