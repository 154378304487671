import accountApplicationErrorMessagesEn from '@/account/message/applicationerror_en.json';
import accountApplicationErrorMessagesId from '@/account/message/applicationerror_id.json';
import accountApplicationErrorMessagesJa from '@/account/message/applicationerror_ja.json';
import accountApplicationErrorMessagesVi from '@/account/message/applicationerror_vi.json';
import accountMessagesEn from '@/account/message/messages_en.json';
import accountMessagesId from '@/account/message/messages_id.json';
import accountMessagesJa from '@/account/message/messages_ja.json';
import accountMessagesVi from '@/account/message/messages_vi.json';
import adminApplicationErrorMessagesEn from '@/admin/message/applicationerror_en.json';
import adminApplicationErrorMessagesJa from '@/admin/message/applicationerror_id.json';
import adminApplicationErrorMessagesId from '@/admin/message/applicationerror_ja.json';
import adminApplicationErrorMessagesVi from '@/admin/message/applicationerror_vi.json';
import adminMessagesEn from '@/admin/message/messages_en.json';
import adminMessagesId from '@/admin/message/messages_id.json';
import adminMessagesJa from '@/admin/message/messages_ja.json';
import adminMessagesVi from '@/admin/message/messages_vi.json';
import { MessageService, MessageServiceImpl, MessageSource } from '@/base/message';
import baseApplicationErrorMessagesEn from '@/base/message/applicationerror_en.json';
import baseApplicationErrorMessagesId from '@/base/message/applicationerror_id.json';
import baseApplicationErrorMessagesJa from '@/base/message/applicationerror_ja.json';
import baseApplicationErrorMessagesVi from '@/base/message/applicationerror_vi.json';
import baseMessagesEn from '@/base/message/messages_en.json';
import baseMessagesId from '@/base/message/messages_id.json';
import baseMessagesJa from '@/base/message/messages_ja.json';
import baseMessagesVi from '@/base/message/messages_vi.json';
import contentsApplicationErrorMessagesEn from '@/contents/message/applicationerror_en.json';
import contentsApplicationErrorMessagesId from '@/contents/message/applicationerror_id.json';
import contentsApplicationErrorMessagesJa from '@/contents/message/applicationerror_ja.json';
import contentsApplicationErrorMessagesVi from '@/contents/message/applicationerror_vi.json';
import contentsMessagesEn from '@/contents/message/messages_en.json';
import contentsMessagesId from '@/contents/message/messages_id.json';
import contentsMessagesJa from '@/contents/message/messages_ja.json';
import contentsMessagesVi from '@/contents/message/messages_vi.json';
import homeApplicationErrorMessagesEn from '@/home/message/applicationerror_en.json';
import homeApplicationErrorMessagesId from '@/home/message/applicationerror_id.json';
import homeApplicationErrorMessagesJa from '@/home/message/applicationerror_ja.json';
import homeApplicationErrorMessagesVi from '@/home/message/applicationerror_vi.json';
import homeMessagesEn from '@/home/message/messages_en.json';
import homeMessagesId from '@/home/message/messages_id.json';
import homeMessagesJa from '@/home/message/messages_ja.json';
import homeMessagesVi from '@/home/message/messages_vi.json';
import playgroundApplicationErrorMessagesEn from '@/playground/message/applicationerror_en.json';
import playgroundApplicationErrorMessagesId from '@/playground/message/applicationerror_id.json';
import playgroundApplicationErrorMessagesJa from '@/playground/message/applicationerror_ja.json';
import playgroundApplicationErrorMessagesVi from '@/playground/message/applicationerror_vi.json';
import playgroundMessagesEn from '@/playground/message/messages_en.json';
import playgroundMessagesId from '@/playground/message/messages_id.json';
import playgroundMessagesJa from '@/playground/message/messages_ja.json';
import playgroundMessagesVi from '@/playground/message/messages_vi.json';
import reportApplicationErrorMessagesEn from '@/report/message/applicationerror_en.json';
import reportApplicationErrorMessagesId from '@/report/message/applicationerror_id.json';
import reportApplicationErrorMessagesJa from '@/report/message/applicationerror_ja.json';
import reportApplicationErrorMessagesVi from '@/report/message/applicationerror_vi.json';
import reportMessagesEn from '@/report/message/messages_en.json';
import reportMessagesId from '@/report/message/messages_id.json';
import reportMessagesJa from '@/report/message/messages_ja.json';
import reportMessagesVi from '@/report/message/messages_vi.json';
import trainingApplicationErrorMessagesEn from '@/training/message/applicationerror_en.json';
import trainingApplicationErrorMessagesId from '@/training/message/applicationerror_id.json';
import trainingApplicationErrorMessagesJa from '@/training/message/applicationerror_ja.json';
import trainingApplicationErrorMessagesVi from '@/training/message/applicationerror_vi.json';
import trainingMessagesEn from '@/training/message/messages_en.json';
import trainingMessagesId from '@/training/message/messages_id.json';
import trainingMessagesJa from '@/training/message/messages_ja.json';
import trainingMessagesVi from '@/training/message/messages_vi.json';
import { hasNonNullProperty, isObject } from '@/utils/TsUtils';

function mergeApplicationErrorMessages(applicationErrorMessages: Array<object>): object {
  const ae = applicationErrorMessages
    .map((aem) => {
      if (!hasNonNullProperty(aem, 'applicationError') || !isObject(aem.applicationError)) {
        throw new Error(
          'applicationerror.json should have an object property; key=applicationError'
        );
      }
      return aem.applicationError;
    })
    .reduce((acc, v) => ({ ...acc, ...v }));
  return {
    applicationError: ae,
  };
}

// https://github.com/citycom/knowte/issues/783
// dynamic requireで実装したところトランスパイルエラーが出るので静的importで実装する
// Module build failed (from ./node_modules/ts-loader/index.js):
//   Error: Debug Failure. Output generation failed
// また、現在の実装だとすべてのロケールのメッセージをメモリにロードしていることになる。
// ビルド時に言語ごとのメッセージファイルに整形して実行時に非同期ロードする方がよさそう。
const en = {
  ...accountMessagesEn,
  ...adminMessagesEn,
  ...baseMessagesEn,
  ...contentsMessagesEn,
  ...homeMessagesEn,
  ...playgroundMessagesEn,
  ...reportMessagesEn,
  ...trainingMessagesEn,
  ...mergeApplicationErrorMessages([
    accountApplicationErrorMessagesEn,
    adminApplicationErrorMessagesEn,
    baseApplicationErrorMessagesEn,
    contentsApplicationErrorMessagesEn,
    homeApplicationErrorMessagesEn,
    playgroundApplicationErrorMessagesEn,
    reportApplicationErrorMessagesEn,
    trainingApplicationErrorMessagesEn,
  ]),
};

const ja = {
  ...accountMessagesJa,
  ...adminMessagesJa,
  ...baseMessagesJa,
  ...contentsMessagesJa,
  ...homeMessagesJa,
  ...playgroundMessagesJa,
  ...reportMessagesJa,
  ...trainingMessagesJa,
  ...mergeApplicationErrorMessages([
    accountApplicationErrorMessagesJa,
    adminApplicationErrorMessagesJa,
    baseApplicationErrorMessagesJa,
    contentsApplicationErrorMessagesJa,
    homeApplicationErrorMessagesJa,
    playgroundApplicationErrorMessagesJa,
    reportApplicationErrorMessagesJa,
    trainingApplicationErrorMessagesJa,
  ]),
};

const vi = {
  ...accountMessagesVi,
  ...adminMessagesVi,
  ...baseMessagesVi,
  ...contentsMessagesVi,
  ...homeMessagesVi,
  ...playgroundMessagesVi,
  ...reportMessagesVi,
  ...trainingMessagesVi,
  ...mergeApplicationErrorMessages([
    accountApplicationErrorMessagesVi,
    adminApplicationErrorMessagesVi,
    baseApplicationErrorMessagesVi,
    contentsApplicationErrorMessagesVi,
    homeApplicationErrorMessagesVi,
    playgroundApplicationErrorMessagesVi,
    reportApplicationErrorMessagesVi,
    trainingApplicationErrorMessagesVi,
  ]),
};

const id = {
  ...accountMessagesId,
  ...adminMessagesId,
  ...baseMessagesId,
  ...contentsMessagesId,
  ...homeMessagesId,
  ...playgroundMessagesId,
  ...reportMessagesId,
  ...trainingMessagesId,
  ...mergeApplicationErrorMessages([
    accountApplicationErrorMessagesId,
    adminApplicationErrorMessagesId,
    baseApplicationErrorMessagesId,
    contentsApplicationErrorMessagesId,
    homeApplicationErrorMessagesId,
    playgroundApplicationErrorMessagesId,
    reportApplicationErrorMessagesId,
    trainingApplicationErrorMessagesId,
  ]),
};

export function createMessageService(): MessageService {
  const source: MessageSource = {
    en,
    ja,
    vi,
    id,
    default: en,
  };
  return new MessageServiceImpl(source);
}
