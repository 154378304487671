import { GroupId, HasId, ScheduleId, ScheduleTag, UserId } from '@/base/domains';
import { LocalDateTime, MarkDownString } from '@/base/types';

import { ScheduleData, ScheduleEntity, ScheduleEntityUpdateArgs } from './Schedule';

export class ScheduleEntityImpl implements ScheduleEntity {
  id: ScheduleId;

  groupId: GroupId;

  start: LocalDateTime;

  end?: LocalDateTime;

  title: string;

  body?: MarkDownString;

  createdBy?: UserId;

  updatedBy?: UserId;

  tags: Array<ScheduleTag>;

  constructor(data: HasId<ScheduleData, ScheduleId>) {
    this.id = data.id;
    this.groupId = data.groupId;
    this.start = data.start;
    this.end = data.end;
    this.title = data.title;
    this.body = data.body;
    this.createdBy = data.createdBy;
    this.updatedBy = data.updatedBy;
    this.tags = data.tags;
  }

  update(args: ScheduleEntityUpdateArgs): ScheduleEntity {
    return {
      ...this,
      ...args,
    };
  }
}
