import * as customQueries from '@/amplify/customQueries';
import {
  AppContextProvider,
  ContentId,
  ContentVersion,
  CourseId,
  CourseVersion,
} from '@/base/domains';
import { ContentHeader, ContentQueries } from '@/contents/domains/queries';
import { graphqlQuery, singleListGraphqlQuery } from '@/utils/AmplifyUtils';
import { requiredNonNull } from '@/utils/TsUtils';

type AmplifyContentHeader = {
  id: string;
  contentId: ContentId;
  version: ContentVersion;
  courseId: CourseId;
  courseVersion: CourseVersion;
  versionDescription?: string;
};

function toContentHeader(e: AmplifyContentHeader): ContentHeader {
  return {
    id: e.contentId,
    version: e.version,
    versionDescription: e.versionDescription,
  };
}

export class AmplifyContentQueries implements ContentQueries {
  constructor(private appContextProvider: AppContextProvider) {}

  async findContentHeadersByCourse({
    courseId,
    courseVersion,
  }: {
    courseId: CourseId;
    courseVersion: CourseVersion;
  }): Promise<Array<ContentHeader>> {
    const res = await graphqlQuery<{ contentByCourse: { items: Array<AmplifyContentHeader> } }>(
      customQueries.contentHeadersByCourse,
      {
        courseId,
        courseVersion: { eq: courseVersion },
      },
      {
        limit: 1000,
      }
    );
    return res.contentByCourse.items.map(toContentHeader) ?? [];
  }

  async findTenantContentHeaders(options?: { latest: boolean }): Promise<Array<ContentHeader>> {
    const opts = options ?? { latest: false };
    const tenantCode = requiredNonNull(
      this.appContextProvider.get().tenantCode,
      'appContext.tenantCode'
    );
    const variables = opts.latest
      ? {
          tenantCode,
          filter: {
            latest: {
              eq: true,
            },
          },
        }
      : {
          tenantCode,
        };

    const amplifyContentHeaders = await singleListGraphqlQuery<AmplifyContentHeader>({
      query: customQueries.contentHeadersByTenantCode,
      queryKey: 'contentsByTenantCode',
      variables,
    });
    return amplifyContentHeaders.map(toContentHeader);
  }
}
