import { AppContextProvider, CourseId, EntityData, hasId, ReviewProblemId } from '@/base/domains';
import { Optional } from '@/base/types';
import {
  ReviewProblemAttributes,
  ReviewProblemEntity,
  ReviewProblemEntityImpl,
  reviewProblemIdOf,
  ReviewProblemRepository,
  ReviewProblemRepositoryFindMyReviewProblemArgs,
  ReviewProblemRepositoryFindMyReviewProblemsArgs,
} from '@/training/domains';
import { requiredNonNull } from '@/utils/TsUtils';

export class InMemoryReviewProblemRepository implements ReviewProblemRepository {
  private store = new Map<CourseId, ReviewProblemEntity>();

  constructor(private appContextProvider: AppContextProvider) {}

  save(
    args: ReviewProblemEntity | EntityData<string, ReviewProblemAttributes>
  ): Promise<ReviewProblemEntity> {
    if (hasId(args)) {
      const e = new ReviewProblemEntityImpl({
        ...args,
      });
      this.store.set(e.id, e);
      return Promise.resolve(e);
    }
    const e = new ReviewProblemEntityImpl({
      ...args,
      id: reviewProblemIdOf(args),
    });
    this.store.set(e.id, e);
    return Promise.resolve(e);
  }

  findById(id: ReviewProblemId): Promise<Optional<ReviewProblemEntity>> {
    return Promise.resolve(this.store.get(id));
  }

  remove(id: ReviewProblemId): Promise<void> {
    Promise.resolve(this.store.delete(id));
    return Promise.resolve();
  }

  findMyReviewProblem(
    args: ReviewProblemRepositoryFindMyReviewProblemArgs
  ): Promise<Optional<ReviewProblemEntity>> {
    const userId = requiredNonNull(this.appContextProvider.get().user?.id, 'appContext.user.id');
    const reviewProblemId = reviewProblemIdOf({
      groupId: args.groupId,
      courseId: args.courseId,
      userId,
    });
    return this.findById(reviewProblemId);
  }

  async findMyReviewProblems(
    args: ReviewProblemRepositoryFindMyReviewProblemsArgs
  ): Promise<ReviewProblemEntity[]> {
    const userId = requiredNonNull(this.appContextProvider.get().user?.id, 'appContext.user.id');
    return Array.from(this.store.values()).filter(
      (e) => e.groupId === args.groupId && e.userId === userId
    );
  }
}
