import { CognitoUser } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';
import { v4 } from 'uuid';

import { DeleteSignUpReservationInput } from '@/API';
import {
  AppContextProvider,
  AuthService,
  AuthServiceChangePasswordArgs,
  AuthServiceReserveSignUpArgs,
  AuthServiceSignInRequest,
  AuthServiceSignUpRequest,
  ConfirmSignUpRequest,
  Email,
  GroupId,
  GroupRole,
  isAuthServiceSignInWithEmailRequest,
  isAuthServiceSignUpWithEmailRequest,
  isAuthServiceSignUpWithUserCodeRequest,
  isAuthServiceSvSignUpRequest,
  Password,
  Role,
  SignedInUser,
  SignUpReservationId,
  TenantCode,
  UserCode,
  UserId,
  UserName,
  UserRepository,
} from '@/base/domains';
import {
  CREATE_USER_BY_ADMIN_ALREADY_INVITED,
  CREATE_USER_BY_ADMIN_DUPLICATED_EMAIL,
  CREATE_USER_BY_ADMIN_DUPLICATED_USER_CODE,
  INVALID_VERIFICATION_CODE,
  INVITE_USER_ALREADY_INVITED,
  INVITE_USER_DUPLICATED_EMAIL,
  INVITE_USER_DUPLICATED_USER_CODE,
  SIGN_UP_DUPLICATED_EMAIL,
  SIGN_UP_DUPLICATED_TENANT_CODE,
  SIGN_UP_FAILED,
  USER_LIMIT_EXCEEDED,
} from '@/base/ErrorCodes';
import { Optional } from '@/base/types';
import { config } from '@/config';
import * as mutations from '@/graphql/mutations';
import { deleteSignUpReservation } from '@/graphql/mutations';
import * as queries from '@/graphql/queries';
import { graphql, graphqlQuery, isGraphQLError } from '@/utils/AmplifyUtils';
import { createLogger } from '@/utils/log';
import { hasNonNullProperty, isDefined, isObject, requiredNonNull } from '@/utils/TsUtils';

const logger = createLogger({ boundedContext: 'base', name: 'AmplifyAuthService' });

async function countSignUpReservation(tenantCode: TenantCode): Promise<number> {
  const res = await graphqlQuery<{ signUpReservationsByTenantCode: { items: object[] } }>(
    queries.signUpReservationsByTenantCode,
    {
      tenantCode,
      filter: {
        status: {
          eq: 'NOT_SIGNED_UP',
        },
      },
    }
  );
  return res.signUpReservationsByTenantCode.items.length;
}

type AuthError = {
  code: string;
  name: string;
  message: string;
};
function isAuthError(e: unknown): e is AuthError {
  return (
    isObject(e) &&
    hasNonNullProperty(e, 'code') &&
    hasNonNullProperty(e, 'name') &&
    hasNonNullProperty(e, 'message')
  );
}

export class AmplifyAuthService implements AuthService {
  private userRepository: UserRepository;

  private appContextProvider: AppContextProvider;

  constructor(userRepository: UserRepository, appContextProvider: AppContextProvider) {
    this.userRepository = userRepository;
    this.appContextProvider = appContextProvider;
  }

  async currentUser(): Promise<
    Optional<Omit<SignedInUser, 'groups'> & { signedInAtLeastOnce: boolean }>
  > {
    try {
      const cognitoUser: Optional<CognitoUser> = await Auth.currentAuthenticatedUser();
      if (cognitoUser) {
        const userId = cognitoUser.getUsername();
        const user = await this.userRepository.findById(userId);
        if (user) {
          return {
            id: user.id,
            name: user.name,
            email: user.email,
            code: user.code,
            role: user.role,
            tenantCode: user.tenantCode,
            locale: user.locale,
            displaySettings: user.displaySettings,
            avatar: user.avatar,
            enabled: user.enabled,
            signedInAtLeastOnce: user.signedInAtLeastOnce,
            confirmedTermsOfServiceVersions: user.confirmedTermsOfServiceVersions,
          };
        }
      }
      return undefined;
    } catch (e) {
      return undefined;
    }
  }

  async signIn(
    request: AuthServiceSignInRequest
  ): Promise<
    | (Omit<SignedInUser, 'groups'> & { signedInAtLeastOnce: boolean })
    | ((
        newPassword: string
      ) => Promise<Omit<SignedInUser, 'groups'> & { signedInAtLeastOnce: boolean }>)
  > {
    const username = isAuthServiceSignInWithEmailRequest(request)
      ? request.email
      : `${request.tenantCode}/${request.userCode}`;
    const userEmailConformationToken =
      isAuthServiceSignInWithEmailRequest(request) && request.userEmailConformationToken
        ? request.userEmailConformationToken
        : undefined;
    try {
      const result: CognitoUser = await Auth.signIn(
        { username, password: request.password, validationData: { userEmailConformationToken } },
        request.password
      );
      logger.debug({
        message: 'signed in',
        cognitoUser: result,
      });

      if (
        hasNonNullProperty(result, 'challengeName') &&
        result.challengeName === 'NEW_PASSWORD_REQUIRED'
      ) {
        return (newPassword: string) => {
          return new Promise<Omit<SignedInUser, 'groups'> & { signedInAtLeastOnce: boolean }>(
            (resolve, reject) =>
              result.completeNewPasswordChallenge(newPassword, null, {
                onSuccess: () =>
                  this.currentUser().then((u) => resolve(requiredNonNull(u, 'currentUser'))),
                onFailure: (error) => reject(error),
              })
          );
        };
      }
      return requiredNonNull(await this.currentUser(), 'currentUser');
    } catch (e) {
      logger.error({
        message: 'error',
        error: e,
      });
      throw e;
    }
  }

  async signOut(): Promise<void> {
    try {
      await Auth.signOut();
    } catch (e) {
      logger.debug({
        message: 'an error occurred at signing out',
        error: e,
      });
    }
  }

  async signUp(request: AuthServiceSignUpRequest): Promise<UserId> {
    const { tenantCode, password, userName } = request;
    const cognitoUserName = v4();

    const doSignUp = () => {
      if (isAuthServiceSvSignUpRequest(request)) {
        if (isDefined(request.userCode)) {
          return Auth.signUp({
            username: cognitoUserName,
            password,
            attributes: {
              email: request.email,
            },
            clientMetadata: {
              tenantCode,
              tenantName: request.tenantName,
              name: userName,
              userCode: request.userCode,
              termsOfServiceId: request.termsOfServiceId,
            },
          });
        }
        return Auth.signUp({
          username: cognitoUserName,
          password,
          attributes: {
            email: request.email,
          },
          clientMetadata: {
            tenantCode,
            tenantName: request.tenantName,
            name: userName,
            termsOfServiceId: request.termsOfServiceId,
          },
        });
      }
      if (isAuthServiceSignUpWithUserCodeRequest(request)) {
        return Auth.signUp({
          username: cognitoUserName,
          password,
          attributes: {
            email: `${cognitoUserName}@example.com`,
          },
          clientMetadata: {
            tenantCode,
            name: userName,
            userCode: request.userCode,
          },
        });
      }
      if (isAuthServiceSignUpWithEmailRequest(request)) {
        return Auth.signUp({
          username: cognitoUserName,
          password,
          attributes: {
            email: request.email,
          },
          clientMetadata: {
            tenantCode,
            name: userName,
          },
        });
      }
      throw new Error(`unsupported request\n${JSON.stringify(request)}`);
    };
    try {
      const response = await doSignUp();

      logger.debug({
        message: 'Sing up',
        request,
        response,
      });
      return response.user.getUsername();
    } catch (e) {
      if (isAuthError(e)) {
        if (e.message.startsWith('PreSignUp failed with error Email has already been used;')) {
          throw SIGN_UP_DUPLICATED_EMAIL.toApplicationError();
        }
        if (
          e.message.startsWith('PreSignUp failed with error Tenant has already been registered;')
        ) {
          throw SIGN_UP_DUPLICATED_TENANT_CODE.toApplicationError();
        }
        if (
          e.message.startsWith('PreSignUp failed with error SignUpReservation not found') ||
          e.message.startsWith('PreSignUp failed with error SignUpReservation already confirmed')
        ) {
          // エラーコードは単にサインアップが失敗したことを表す。
          // SignUpReservation.status=CONFIRMEDのデータはすぐに削除されるので、
          // 管理者により削除されたかユーザー登録がすんだため削除されたかはほぼ切り分けられないため。
          throw SIGN_UP_FAILED.toApplicationError({
            email: hasNonNullProperty(request, 'email') ? request.email : undefined,
            userCode: hasNonNullProperty(request, 'userCode') ? request.userCode : undefined,
          });
        }
      }

      logger.error({
        message: 'error',
        request,
        error: e,
      });
      throw e;
    }
  }

  async confirmSignUp(request: ConfirmSignUpRequest): Promise<void> {
    const { userId, code } = request;
    try {
      const result = await Auth.confirmSignUp(userId, code);
      logger.debug({
        message: 'confirm sign-up',
        result,
      });
    } catch (e) {
      if (isAuthError(e)) {
        if (e.name === 'CodeMismatchException' || e.name === 'UserNotFoundException') {
          throw INVALID_VERIFICATION_CODE.toApplicationError();
        }
      }
      logger.error({
        message: 'an error has been occurred at confirming sign-up',
        error: e,
      });
      throw e;
    }
  }

  async resendSignUp(userName: UserName): Promise<void> {
    try {
      const result = await Auth.resendSignUp(userName);
      logger.debug({
        message: 'Resend sign up',
        result,
      });
    } catch (e) {
      logger.error({
        message: 'error',
        error: e,
      });
      throw e;
    }
  }

  // TODO リファクタリング対象
  // Amplifyへのクエリと業務ロジックが共存しているとUTしにくい
  // ReserveSignUpへのアクセスを切り出したい
  async reserveSignUp(args: AuthServiceReserveSignUpArgs): Promise<void> {
    const createdBy = requiredNonNull(this.appContextProvider.get().user?.id, 'appContext.user');
    const input = hasNonNullProperty(args, 'email')
      ? {
          email: args.email,
          role: args.role.toUpperCase(),
          name: args.userName,
          groups: args.groups?.map((g) => ({
            id: g.id,
            role: g.role.toUpperCase(),
            removed: false,
          })),
          createdBy,
        }
      : {
          code: args.userCode,
          role: args.role.toUpperCase(),
          name: args.userName,
          groups: args.groups?.map((g) => ({
            id: g.id,
            role: g.role.toUpperCase(),
            removed: false,
          })),
          createdBy,
        };
    const tenantCode = requiredNonNull(
      this.appContextProvider.get().tenantCode,
      'appContext.tenantCode'
    );
    const [reservationCount, users] = await Promise.all([
      countSignUpReservation(tenantCode),
      this.userRepository.findTenantUsers(),
    ]);

    if (reservationCount + users.length >= config().app.userLimit) {
      throw USER_LIMIT_EXCEEDED.toApplicationError();
    }

    try {
      await graphql<{ inviteUser: { signUpReservationId: SignUpReservationId } }>(
        mutations.inviteUser,
        { input }
      );
    } catch (e) {
      if (isGraphQLError(e)) {
        if (e.message === 'Duplicated email') {
          throw INVITE_USER_DUPLICATED_EMAIL.toApplicationError();
        }

        if (e.message === 'Duplicated userCode') {
          throw INVITE_USER_DUPLICATED_USER_CODE.toApplicationError();
        }

        if (e.message === 'Already invited') {
          throw INVITE_USER_ALREADY_INVITED.toApplicationError();
        }
      }
      throw e;
    }
  }

  async removeSignUpReservation(id: SignUpReservationId): Promise<void> {
    const input: DeleteSignUpReservationInput = {
      id,
    };
    await graphql(deleteSignUpReservation, { input });
  }

  forgotPassword(email: Email): Promise<void> {
    return Auth.forgotPassword(email);
  }

  async changePassword(args: AuthServiceChangePasswordArgs): Promise<void> {
    const { email, code, password } = args;
    await Auth.forgotPasswordSubmit(email, code, password);
  }

  async changePasswordByAdmin(args: {
    id: UserId;
    password: Password;
    forcePasswordChange: boolean;
  }): Promise<void> {
    await graphql(mutations.changePasswordByAdmin, args);
  }

  async createUserByAdmin(args: {
    name: UserName;
    email?: Email;
    code?: UserCode;
    password: Password;
    role: Role;
    groups?: {
      id: GroupId;
      role: GroupRole;
    }[];
    forcePasswordChange: boolean;
  }): Promise<UserId> {
    const input = {
      name: args.name,
      email: args.email,
      code: args.code,
      password: args.password,
      role: args.role.toUpperCase(),
      groups: args.groups?.map((g) => ({ id: g.id, role: g.role.toUpperCase(), removed: false })),
      forcePasswordChange: args.forcePasswordChange,
    };
    try {
      const res = await graphql<{ createUserByAdmin: { userId: UserId } }>(
        mutations.createUserByAdmin,
        { input }
      );
      return res.createUserByAdmin.userId;
    } catch (e) {
      if (isGraphQLError(e)) {
        if (e.message === 'Duplicated email') {
          throw CREATE_USER_BY_ADMIN_DUPLICATED_EMAIL.toApplicationError({
            email: args.email ?? '',
          });
        }
        if (e.message === 'Duplicated userCode') {
          throw CREATE_USER_BY_ADMIN_DUPLICATED_USER_CODE.toApplicationError({
            userCode: args.code ?? '',
          });
        }
        if (e.message === 'Already invited') {
          throw CREATE_USER_BY_ADMIN_ALREADY_INVITED.toApplicationError({
            userCode: args.code,
            email: args.email,
          });
        }
      }
      throw e;
    }
  }
}
