import { AuthorizationService, ContentId, DataVersion, Problem } from '@/base/domains';
import {
  AbstractUseCase,
  assertDataVersion,
  assertEntityExists,
  UseCase,
  UseCaseResponse,
} from '@/base/usecases';
import { isDefined } from '@/utils/TsUtils';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import {
  EditingCourseContentBodyRepository,
  EditingCourseContentWorkbookReference,
  EditingCourseContentWorkbookRepository,
} from '../domains';

export interface CreateOrUpdateEditingCourseContentWorkbookRequest {
  id: ContentId;
  problems: Array<Problem>;
  expectedDataVersion?: DataVersion;
}

export type CreateOrUpdateEditingCourseContentWorkbookResponse = {
  workbook: EditingCourseContentWorkbookReference;
};

/**
 * 編集中コースコンテントワークブックを登録・更新する
 */
export interface CreateOrUpdateEditingCourseContentWorkbook
  extends UseCase<
    CreateOrUpdateEditingCourseContentWorkbookRequest,
    CreateOrUpdateEditingCourseContentWorkbookResponse
  > {
  execute(
    request: CreateOrUpdateEditingCourseContentWorkbookRequest
  ): Promise<UseCaseResponse<CreateOrUpdateEditingCourseContentWorkbookResponse>>;
}

export class CreateOrUpdateEditingCourseContentWorkbookImpl
  extends AbstractUseCase<
    CreateOrUpdateEditingCourseContentWorkbookRequest,
    CreateOrUpdateEditingCourseContentWorkbookResponse
  >
  implements CreateOrUpdateEditingCourseContentWorkbook
{
  private authorizationService: AuthorizationService;

  private editingCourseContentWorkbookRepository: EditingCourseContentWorkbookRepository;

  private editingCourseContentBodyRepository: EditingCourseContentBodyRepository;

  constructor(
    authorizationService: AuthorizationService,
    editingCourseContentWorkbookRepository: EditingCourseContentWorkbookRepository,
    editingCourseContentBodyRepository: EditingCourseContentBodyRepository
  ) {
    super('contents.CreateOrUpdateEditingCourseContentWorkbook');
    this.authorizationService = authorizationService;
    this.editingCourseContentWorkbookRepository = editingCourseContentWorkbookRepository;
    this.editingCourseContentBodyRepository = editingCourseContentBodyRepository;
  }

  async internalExecute(
    request: CreateOrUpdateEditingCourseContentWorkbookRequest
  ): Promise<CreateOrUpdateEditingCourseContentWorkbookResponse> {
    const { id, problems, expectedDataVersion } = request;
    this.authorizationService.assertContentEditable();
    if (isDefined(expectedDataVersion)) {
      const entity = await this.editingCourseContentWorkbookRepository.findById(id);
      assertEntityExists(entity, 'editingCourseContentWorkbook');
      assertDataVersion(entity, expectedDataVersion, 'editingCourseContentWorkbook');
      const saved = await this.editingCourseContentWorkbookRepository.save(
        entity.changeProblems(problems)
      );
      return {
        workbook: saved,
      };
    }
    const editingCourseContentBody = await this.editingCourseContentBodyRepository.findById(id);
    assertEntityExists(editingCourseContentBody, 'editingCourseContentBody');
    const saved = await this.editingCourseContentWorkbookRepository.save({
      id,
      problems,
      courseId: editingCourseContentBody.courseId,
      problemHeaders: [],
    });
    return {
      workbook: saved,
    };
  }
}

export const CreateOrUpdateEditingCourseContentWorkbookKey =
  injectionKeyOf<CreateOrUpdateEditingCourseContentWorkbook>({
    boundedContext: 'contents',
    type: 'usecase',
    name: 'CreateOrUpdateEditingCourseContentWorkbook',
  });

export function useCreateOrUpdateEditingCourseContentWorkbook(): CreateOrUpdateEditingCourseContentWorkbook {
  return requiredInject(CreateOrUpdateEditingCourseContentWorkbookKey);
}
