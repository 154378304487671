import { AuthorizationService, ContentId, DataVersion, Problem } from '@/base/domains';
import {
  AbstractUseCase,
  assertDataVersion,
  assertEntityExists,
  UseCase,
  UseCaseResponse,
} from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { EditingConfirmedContentReference, EditingConfirmedContentRepository } from '../domains';

export interface ChangeEditingConfirmedContentWorkbookProblemsRequest {
  id: ContentId;
  problems: Array<Problem>;
  expectedDataVersion: DataVersion;
}

export type ChangeEditingConfirmedContentWorkbookProblemsResponse = {
  editingConfirmedContent: EditingConfirmedContentReference;
};

/**
 * 編集中確定済みコンテンツ練習問題を変更する
 */
export interface ChangeEditingConfirmedContentWorkbookProblems
  extends UseCase<
    ChangeEditingConfirmedContentWorkbookProblemsRequest,
    ChangeEditingConfirmedContentWorkbookProblemsResponse
  > {
  execute(
    request: ChangeEditingConfirmedContentWorkbookProblemsRequest
  ): Promise<UseCaseResponse<ChangeEditingConfirmedContentWorkbookProblemsResponse>>;
}

export class ChangeEditingConfirmedContentWorkbookProblemsImpl
  extends AbstractUseCase<
    ChangeEditingConfirmedContentWorkbookProblemsRequest,
    ChangeEditingConfirmedContentWorkbookProblemsResponse
  >
  implements ChangeEditingConfirmedContentWorkbookProblems
{
  constructor(
    private authorizationService: AuthorizationService,
    private editingConfirmedContentRepository: EditingConfirmedContentRepository
  ) {
    super('contents.ChangeEditingConfirmedContentWorkbookProblems');
  }

  async internalExecute(
    request: ChangeEditingConfirmedContentWorkbookProblemsRequest
  ): Promise<ChangeEditingConfirmedContentWorkbookProblemsResponse> {
    const { id, problems, expectedDataVersion } = request;
    this.authorizationService.assertContentEditable();
    const entity = await this.editingConfirmedContentRepository.findById(id);
    assertEntityExists(entity, 'editingConfirmedContent');
    assertDataVersion(entity, expectedDataVersion, 'editingConfirmedContent');
    if (entity.type === 'text') {
      const saved = await this.editingConfirmedContentRepository.save(
        entity.changeWorkbookProblems(problems)
      );
      return {
        editingConfirmedContent: saved,
      };
    }
    throw new Error('editingConfirmedContent.type should be text');
  }
}

export const ChangeEditingConfirmedContentWorkbookProblemsKey =
  injectionKeyOf<ChangeEditingConfirmedContentWorkbookProblems>({
    boundedContext: 'contents',
    type: 'usecase',
    name: 'ChangeEditingConfirmedContentWorkbookProblems',
  });

export function useChangeEditingConfirmedContentWorkbookProblems(): ChangeEditingConfirmedContentWorkbookProblems {
  return requiredInject(ChangeEditingConfirmedContentWorkbookProblemsKey);
}
