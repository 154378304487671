import { AuthorizationService, ContentId, DataVersion, ProblemHeaderId } from '@/base/domains';
import {
  AbstractUseCase,
  assertDataVersion,
  assertEntityExists,
  UseCase,
  UseCaseResponse,
} from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { EditingConfirmedContentReference, EditingConfirmedContentRepository } from '../domains';

/**
 * 編集中確定済みコンテンツの問題ヘッダーを削除する
 */
export type RemoveProblemHeaderFromEditingConfirmedContentRequest = {
  id: ContentId;
  problemHeaderId: ProblemHeaderId;
  expectedDataVersion: DataVersion;
};

export type RemoveProblemHeaderFromEditingConfirmedContentResponse = {
  editingConfirmedContent: EditingConfirmedContentReference;
};

export interface RemoveProblemHeaderFromEditingConfirmedContent
  extends UseCase<
    RemoveProblemHeaderFromEditingConfirmedContentRequest,
    RemoveProblemHeaderFromEditingConfirmedContentResponse
  > {
  execute(
    request: RemoveProblemHeaderFromEditingConfirmedContentRequest
  ): Promise<UseCaseResponse<RemoveProblemHeaderFromEditingConfirmedContentResponse>>;
}

export class RemoveProblemHeaderFromEditingConfirmedContentImpl
  extends AbstractUseCase<
    RemoveProblemHeaderFromEditingConfirmedContentRequest,
    RemoveProblemHeaderFromEditingConfirmedContentResponse
  >
  implements RemoveProblemHeaderFromEditingConfirmedContent
{
  constructor(
    private authorizationService: AuthorizationService,
    private editingConfirmedContentRepository: EditingConfirmedContentRepository
  ) {
    super('contents.RemoveProblemHeaderFromEditingConfirmedContent');
  }

  async internalExecute(
    request: RemoveProblemHeaderFromEditingConfirmedContentRequest
  ): Promise<RemoveProblemHeaderFromEditingConfirmedContentResponse> {
    const { id, problemHeaderId, expectedDataVersion } = request;
    this.authorizationService.assertContentEditable();
    const content = await this.editingConfirmedContentRepository.findById(id);
    assertEntityExists(content, 'editingConfirmedContent');
    assertDataVersion(content, expectedDataVersion, 'editingConfirmedContent');
    if (content.type === 'exam') {
      const saved = await this.editingConfirmedContentRepository.save(
        content.removeProblemHeader(problemHeaderId)
      );
      return {
        editingConfirmedContent: saved,
      };
    }
    throw new Error('editingConfirmedContent.type should be exam');
  }
}

export const RemoveProblemHeaderFromEditingConfirmedContentKey =
  injectionKeyOf<RemoveProblemHeaderFromEditingConfirmedContent>({
    boundedContext: 'contents',
    type: 'usecase',
    name: 'RemoveProblemHeaderFromEditingConfirmedContent',
  });

export function useRemoveProblemHeaderFromEditingConfirmedContent(): RemoveProblemHeaderFromEditingConfirmedContent {
  return requiredInject(RemoveProblemHeaderFromEditingConfirmedContentKey);
}
