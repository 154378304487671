import {
  useAppContextProvider,
  useAuthorizationService,
  useDateTimeService,
  useExamDataAdapter,
  useFileStorage,
  useGroupFinder,
  useGroupTrainingFinder,
} from '@/base/domains';
import {
  useContentRepository,
  useCourseHeaderRepository,
  useCourseRepository,
  useEditingConfirmedContentRepository,
  useEditingCourseContentBodyRepository,
  useEditingCourseContentWorkbookRepository,
  useEditingCourseRepository,
} from '@/contents/domains';
import {
  useContentQueries,
  useEditingConfirmedContentQueries,
  useEditingCourseContentBodyQueries,
  useEditingCourseQueries,
} from '@/contents/domains/queries';
import {
  AddCourseContentImpl,
  AddCourseContentKey,
  AddProblemHeaderToEditingConfirmedContentImpl,
  AddProblemHeaderToEditingConfirmedContentKey,
  AddProblemHeaderToEditingConfirmedContentWorkbookImpl,
  AddProblemHeaderToEditingConfirmedContentWorkbookKey,
  AddProblemHeaderToEditingCourseContentImpl,
  AddProblemHeaderToEditingCourseContentKey,
  AddProblemHeaderToEditingCourseContentWorkbookImpl,
  AddProblemHeaderToEditingCourseContentWorkbookKey,
  ChangeConfirmedCourseColorOrImageImpl,
  ChangeConfirmedCourseColorOrImageKey,
  ChangeConfirmedCourseContentsSortImpl,
  ChangeConfirmedCourseContentsSortKey,
  ChangeConfirmedCourseDescriptionImpl,
  ChangeConfirmedCourseDescriptionKey,
  ChangeConfirmedCourseNameImpl,
  ChangeConfirmedCourseNameKey,
  ChangeConfirmedExamPassingStandardImpl,
  ChangeConfirmedExamPassingStandardKey,
  ChangeContentVersionDescriptionImpl,
  ChangeContentVersionDescriptionKey,
  ChangeEditingConfirmedContentBodyImpl,
  ChangeEditingConfirmedContentBodyKey,
  ChangeEditingConfirmedContentProblemHeaderImpl,
  ChangeEditingConfirmedContentProblemHeaderKey,
  ChangeEditingConfirmedContentWorkbookProblemHeaderImpl,
  ChangeEditingConfirmedContentWorkbookProblemHeaderKey,
  ChangeEditingConfirmedContentWorkbookProblemsImpl,
  ChangeEditingConfirmedContentWorkbookProblemsKey,
  ChangeEditingConfirmedExamProblemsImpl,
  ChangeEditingConfirmedExamProblemsKey,
  ChangeEditingCourseColorOrImageImpl,
  ChangeEditingCourseColorOrImageKey,
  ChangeEditingCourseContentProblemHeaderImpl,
  ChangeEditingCourseContentProblemHeaderKey,
  ChangeEditingCourseContentsImpl,
  ChangeEditingCourseContentsKey,
  ChangeEditingCourseContentWorkbookProblemHeaderImpl,
  ChangeEditingCourseContentWorkbookProblemHeaderKey,
  ChangeEditingCourseDescriptionImpl,
  ChangeEditingCourseDescriptionKey,
  ChangeEditingCourseExamPassingStandardImpl,
  ChangeEditingCourseExamPassingStandardKey,
  ChangeEditingCourseNameImpl,
  ChangeEditingCourseNameKey,
  ConfirmConfirmedContentEditingImpl,
  ConfirmConfirmedContentEditingKey,
  ConfirmCourseEditingImpl,
  ConfirmCourseEditingKey,
  CreateNewCourseImpl,
  CreateNewCourseKey,
  CreateOrUpdateEditingCourseContentWorkbookImpl,
  CreateOrUpdateEditingCourseContentWorkbookKey,
  DisableCourseImpl,
  DisableCourseKey,
  EnableCourseImpl,
  EnableCourseKey,
  GetContentImpl,
  GetContentKey,
  GetContentsImpl,
  GetContentsKey,
  GetCourseContentDetailsImpl,
  GetCourseContentDetailsKey,
  GetCourseForEditingImpl,
  GetCourseForEditingKey,
  GetCourseHistoriesImpl,
  GetCourseHistoriesKey,
  GetCourseHistoryImpl,
  GetCourseHistoryKey,
  GetCoursesForEditingImpl,
  GetCoursesForEditingKey,
  GetCoursesImpl,
  GetCoursesKey,
  GetEditingConfirmedContentImpl,
  GetEditingConfirmedContentKey,
  GetEditingCourseContentBodyImpl,
  GetEditingCourseContentBodyKey,
  GetEditingCourseContentWorkbookImpl,
  GetEditingCourseContentWorkbookKey,
  GetGroupsByCourseImpl,
  GetGroupsByCourseKey,
  GetGroupTrainingCourseImpl,
  GetGroupTrainingCourseKey,
  RemoveCourseContentImpl,
  RemoveCourseContentKey,
  RemoveCourseImpl,
  RemoveCourseKey,
  RemoveEditingCourseImpl,
  RemoveEditingCourseKey,
  RemoveProblemHeaderFromEditingConfirmedContentImpl,
  RemoveProblemHeaderFromEditingConfirmedContentKey,
  RemoveProblemHeaderFromEditingConfirmedContentWorkbookImpl,
  RemoveProblemHeaderFromEditingConfirmedContentWorkbookKey,
  RemoveProblemHeaderFromEditingCourseContentImpl,
  RemoveProblemHeaderFromEditingCourseContentKey,
  RemoveProblemHeaderToEditingCourseContentWorkbookImpl,
  RemoveProblemHeaderToEditingCourseContentWorkbookKey,
  StartConfirmedContentEditingImpl,
  StartConfirmedContentEditingKey,
  StartCourseEditingImpl,
  StartCourseEditingKey,
  UpdateContentImpl,
  UpdateContentKey,
  UpdateEditingCourseContentBodyImpl,
  UpdateEditingCourseContentBodyKey,
} from '@/contents/usecases';
import { provide } from '@/utils/VueUtils';

export function useContentsUsecases() {
  const contentRepository = useContentRepository();
  const courseRepository = useCourseRepository();
  const authorizationService = useAuthorizationService();
  const editingCourseRepository = useEditingCourseRepository();
  const editingCourseContentBodyRepository = useEditingCourseContentBodyRepository();
  const editingCourseContentWorkbookRepository = useEditingCourseContentWorkbookRepository();
  const courseHeaderRepository = useCourseHeaderRepository();
  const groupTrainingFinder = useGroupTrainingFinder();
  const groupFinder = useGroupFinder();
  const fileStorage = useFileStorage();
  const dateTimeService = useDateTimeService();
  const editingCourseQueries = useEditingCourseQueries();
  const examDataAdapter = useExamDataAdapter();
  const appContextProvider = useAppContextProvider();
  const editingConfirmedContentRepository = useEditingConfirmedContentRepository();
  const editingConfirmedContentQueries = useEditingConfirmedContentQueries();
  const contentQueries = useContentQueries();
  const editingCourseContentBodyQueries = useEditingCourseContentBodyQueries();

  provide(
    RemoveCourseKey,
    new RemoveCourseImpl(courseHeaderRepository, examDataAdapter, appContextProvider)
  );
  provide(UpdateContentKey, new UpdateContentImpl(authorizationService, contentRepository));
  provide(GetContentKey, new GetContentImpl(contentRepository));
  provide(GetContentsKey, new GetContentsImpl(contentRepository));
  provide(GetCoursesKey, new GetCoursesImpl(courseRepository));
  provide(RemoveCourseContentKey, new RemoveCourseContentImpl(courseRepository));
  provide(AddCourseContentKey, new AddCourseContentImpl(courseRepository, contentRepository));
  provide(
    StartCourseEditingKey,
    new StartCourseEditingImpl(authorizationService, courseRepository, editingCourseRepository)
  );
  provide(
    ChangeEditingCourseContentsKey,
    new ChangeEditingCourseContentsImpl(authorizationService, editingCourseRepository)
  );
  provide(
    GetEditingCourseContentBodyKey,
    new GetEditingCourseContentBodyImpl(editingCourseContentBodyRepository)
  );
  provide(
    GetEditingCourseContentWorkbookKey,
    new GetEditingCourseContentWorkbookImpl(editingCourseContentWorkbookRepository)
  );
  provide(
    CreateOrUpdateEditingCourseContentWorkbookKey,
    new CreateOrUpdateEditingCourseContentWorkbookImpl(
      authorizationService,
      editingCourseContentWorkbookRepository,
      editingCourseContentBodyRepository
    )
  );
  provide(
    UpdateEditingCourseContentBodyKey,
    new UpdateEditingCourseContentBodyImpl(authorizationService, editingCourseContentBodyRepository)
  );
  provide(
    ConfirmCourseEditingKey,
    new ConfirmCourseEditingImpl(
      authorizationService,
      editingCourseRepository,
      editingCourseContentBodyQueries
    )
  );
  provide(
    CreateNewCourseKey,
    new CreateNewCourseImpl(authorizationService, courseHeaderRepository, courseRepository)
  );
  provide(
    ChangeEditingCourseNameKey,
    new ChangeEditingCourseNameImpl(
      authorizationService,
      editingCourseRepository,
      courseHeaderRepository
    )
  );
  provide(
    RemoveEditingCourseKey,
    new RemoveEditingCourseImpl(authorizationService, editingCourseRepository)
  );
  provide(
    ChangeEditingCourseNameKey,
    new ChangeEditingCourseNameImpl(
      authorizationService,
      editingCourseRepository,
      courseHeaderRepository
    )
  );
  provide(
    GetCoursesForEditingKey,
    new GetCoursesForEditingImpl(
      authorizationService,
      courseHeaderRepository,
      editingConfirmedContentQueries
    )
  );
  provide(DisableCourseKey, new DisableCourseImpl(authorizationService, courseHeaderRepository));
  provide(EnableCourseKey, new EnableCourseImpl(authorizationService, courseHeaderRepository));
  provide(
    GetCourseContentDetailsKey,
    new GetCourseContentDetailsImpl(
      authorizationService,
      contentRepository,
      courseRepository,
      editingCourseContentBodyRepository,
      editingCourseContentWorkbookRepository,
      editingCourseRepository,
      editingConfirmedContentRepository
    )
  );
  provide(
    GetCourseHistoriesKey,
    new GetCourseHistoriesImpl(
      authorizationService,
      courseRepository,
      editingConfirmedContentQueries
    )
  );
  provide(GetCourseHistoryKey, new GetCourseHistoryImpl(authorizationService, courseRepository));
  provide(
    ChangeEditingCourseDescriptionKey,
    new ChangeEditingCourseDescriptionImpl(authorizationService, editingCourseRepository)
  );
  provide(
    GetCourseForEditingKey,
    new GetCourseForEditingImpl(
      authorizationService,
      courseHeaderRepository,
      editingCourseQueries,
      courseRepository,
      editingConfirmedContentQueries,
      contentQueries
    )
  );
  provide(
    GetGroupsByCourseKey,
    new GetGroupsByCourseImpl(authorizationService, groupTrainingFinder, groupFinder)
  );
  provide(
    ChangeEditingCourseColorOrImageKey,
    new ChangeEditingCourseColorOrImageImpl(
      authorizationService,
      fileStorage,
      editingCourseRepository
    )
  );
  provide(
    AddProblemHeaderToEditingCourseContentKey,
    new AddProblemHeaderToEditingCourseContentImpl(
      authorizationService,
      editingCourseContentBodyRepository,
      dateTimeService
    )
  );
  provide(
    RemoveProblemHeaderFromEditingCourseContentKey,
    new RemoveProblemHeaderFromEditingCourseContentImpl(
      authorizationService,
      editingCourseContentBodyRepository
    )
  );
  provide(
    ChangeEditingCourseContentProblemHeaderKey,
    new ChangeEditingCourseContentProblemHeaderImpl(
      authorizationService,
      editingCourseContentBodyRepository
    )
  );
  provide(
    AddProblemHeaderToEditingCourseContentWorkbookKey,
    new AddProblemHeaderToEditingCourseContentWorkbookImpl(
      authorizationService,
      editingCourseContentWorkbookRepository,
      dateTimeService
    )
  );
  provide(
    ChangeEditingCourseContentWorkbookProblemHeaderKey,
    new ChangeEditingCourseContentWorkbookProblemHeaderImpl(
      authorizationService,
      editingCourseContentWorkbookRepository
    )
  );
  provide(
    RemoveProblemHeaderToEditingCourseContentWorkbookKey,
    new RemoveProblemHeaderToEditingCourseContentWorkbookImpl(
      authorizationService,
      editingCourseContentWorkbookRepository
    )
  );
  provide(
    AddProblemHeaderToEditingConfirmedContentKey,
    new AddProblemHeaderToEditingConfirmedContentImpl(
      authorizationService,
      editingConfirmedContentRepository,
      dateTimeService
    )
  );
  provide(
    AddProblemHeaderToEditingConfirmedContentWorkbookKey,
    new AddProblemHeaderToEditingConfirmedContentWorkbookImpl(
      authorizationService,
      editingConfirmedContentRepository,
      dateTimeService
    )
  );
  provide(
    ChangeEditingConfirmedContentBodyKey,
    new ChangeEditingConfirmedContentBodyImpl(
      authorizationService,
      editingConfirmedContentRepository
    )
  );
  provide(
    ChangeEditingConfirmedContentProblemHeaderKey,
    new ChangeEditingConfirmedContentProblemHeaderImpl(
      authorizationService,
      editingConfirmedContentRepository
    )
  );
  provide(
    ChangeEditingConfirmedContentWorkbookProblemHeaderKey,
    new ChangeEditingConfirmedContentWorkbookProblemHeaderImpl(
      authorizationService,
      editingConfirmedContentRepository
    )
  );
  provide(
    ConfirmConfirmedContentEditingKey,
    new ConfirmConfirmedContentEditingImpl(authorizationService, editingConfirmedContentRepository)
  );
  provide(
    RemoveProblemHeaderFromEditingConfirmedContentKey,
    new RemoveProblemHeaderFromEditingConfirmedContentImpl(
      authorizationService,
      editingConfirmedContentRepository
    )
  );
  provide(
    RemoveProblemHeaderFromEditingConfirmedContentWorkbookKey,
    new RemoveProblemHeaderFromEditingConfirmedContentWorkbookImpl(
      authorizationService,
      editingConfirmedContentRepository
    )
  );
  provide(
    StartConfirmedContentEditingKey,
    new StartConfirmedContentEditingImpl(
      authorizationService,
      editingConfirmedContentRepository,
      contentRepository
    )
  );
  provide(
    GetEditingConfirmedContentKey,
    new GetEditingConfirmedContentImpl(authorizationService, editingConfirmedContentRepository)
  );
  provide(
    ChangeEditingConfirmedContentWorkbookProblemsKey,
    new ChangeEditingConfirmedContentWorkbookProblemsImpl(
      authorizationService,
      editingConfirmedContentRepository
    )
  );
  provide(
    ChangeConfirmedCourseNameKey,
    new ChangeConfirmedCourseNameImpl(authorizationService, courseHeaderRepository)
  );
  provide(
    ChangeContentVersionDescriptionKey,
    new ChangeContentVersionDescriptionImpl(authorizationService, contentRepository)
  );
  provide(
    ChangeConfirmedCourseColorOrImageKey,
    new ChangeConfirmedCourseColorOrImageImpl(authorizationService, fileStorage, courseRepository)
  );
  provide(
    ChangeConfirmedCourseContentsSortKey,
    new ChangeConfirmedCourseContentsSortImpl(authorizationService, courseRepository)
  );
  provide(
    ChangeConfirmedCourseDescriptionKey,
    new ChangeConfirmedCourseDescriptionImpl(authorizationService, courseRepository)
  );
  provide(
    GetGroupTrainingCourseKey,
    new GetGroupTrainingCourseImpl(authorizationService, groupTrainingFinder)
  );
  provide(
    ChangeConfirmedExamPassingStandardKey,
    new ChangeConfirmedExamPassingStandardImpl(
      authorizationService,
      editingConfirmedContentRepository
    )
  );
  provide(
    ChangeEditingCourseExamPassingStandardKey,
    new ChangeEditingCourseExamPassingStandardImpl(
      authorizationService,
      editingCourseContentBodyRepository
    )
  );
  provide(
    ChangeEditingConfirmedExamProblemsKey,
    new ChangeEditingConfirmedExamProblemsImpl(
      authorizationService,
      editingConfirmedContentRepository
    )
  );
}
