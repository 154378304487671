import {
  AuthorizationService,
  Email,
  Role,
  SignUpReservationGroup,
  SignUpReservationId,
  SignUpReservationQueries,
  UserCode,
  UserFinder,
  UserId,
  UserName,
  UserTagReference,
} from '@/base/domains';
import { AbstractUseCase, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface GetUsersIncludingSignUpReservationRequest {}

export type GetUsersIncludingSignUpReservationUser = {
  id: UserId;
  name: UserName;
  code?: UserCode;
  email?: Email;
  role: Role;
  status: 'enabled' | 'disabled';
  tags: UserTagReference[];
};

export type GetUsersIncludingSignUpReservationReservation = {
  id: SignUpReservationId;
  name?: UserName;
  code?: UserCode;
  email?: Email;
  role: Role;
  status: 'not_signed_up';
  enabled: boolean;
  groups?: SignUpReservationGroup[];
};

export function isUser(
  x: GetUsersIncludingSignUpReservationUser | GetUsersIncludingSignUpReservationReservation
): x is GetUsersIncludingSignUpReservationUser {
  return x.status === 'enabled' || x.status === 'disabled';
}

export function isReservation(
  x: GetUsersIncludingSignUpReservationUser | GetUsersIncludingSignUpReservationReservation
): x is GetUsersIncludingSignUpReservationReservation {
  return x.status === 'not_signed_up';
}

export type GetUsersIncludingSignUpReservationResponse = {
  users: Array<
    GetUsersIncludingSignUpReservationUser | GetUsersIncludingSignUpReservationReservation
  >;
};

export interface GetUsersIncludingSignUpReservation
  extends UseCase<
    GetUsersIncludingSignUpReservationRequest,
    GetUsersIncludingSignUpReservationResponse
  > {
  execute(
    request: GetUsersIncludingSignUpReservationRequest
  ): Promise<UseCaseResponse<GetUsersIncludingSignUpReservationResponse>>;
}

export class GetUsersIncludingSignUpReservationImpl
  extends AbstractUseCase<
    GetUsersIncludingSignUpReservationRequest,
    GetUsersIncludingSignUpReservationResponse
  >
  implements GetUsersIncludingSignUpReservation
{
  private authorizationService: AuthorizationService;

  private userFinder: UserFinder;

  private signUpReservationQueries: SignUpReservationQueries;

  constructor(
    authorizationService: AuthorizationService,
    userFinder: UserFinder,
    signUpReservationQueries: SignUpReservationQueries
  ) {
    super('account.GetUsersIncludingSignUpReservation');
    this.authorizationService = authorizationService;
    this.userFinder = userFinder;
    this.signUpReservationQueries = signUpReservationQueries;
  }

  async internalExecute(
    _: GetUsersIncludingSignUpReservationRequest
  ): Promise<GetUsersIncludingSignUpReservationResponse> {
    this.authorizationService.assertRole('supervisor', 'admin');
    const [users, reservations] = await Promise.all([
      this.userFinder.findTenantUsers(),
      this.signUpReservationQueries.findTenantSignUpReservations(),
    ]);

    const formattedUsers: Array<GetUsersIncludingSignUpReservationUser> = users.map((u) => ({
      id: u.id,
      name: u.name,
      code: u.code,
      email: u.email,
      role: u.role,
      status: u.enabled ? 'enabled' : 'disabled',
      tags: u.tags,
    }));

    const formattedReservation: Array<GetUsersIncludingSignUpReservationReservation> = reservations
      .filter((r) => r.status === 'not_signed_up')
      .map((r) => ({
        id: r.id,
        name: r.name,
        code: r.userCode,
        email: r.email,
        role: r.role,
        status: 'not_signed_up',
        enabled: r.enabled,
        groups: r.groups,
      }));

    return {
      users: [...formattedUsers, ...formattedReservation],
    };
  }
}

export const GetUsersIncludingSignUpReservationKey =
  injectionKeyOf<GetUsersIncludingSignUpReservation>({
    boundedContext: 'admin',
    type: 'usecase',
    name: 'GetUsersIncludingSignUpReservation',
  });

export function useGetUsersIncludingSignUpReservation(): GetUsersIncludingSignUpReservation {
  return requiredInject(GetUsersIncludingSignUpReservationKey);
}
