import { Content, ContentFinder } from '@/base/domains';
import { AbstractUseCase, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

export type GetContentsResponse = {
  contents: Array<Content>;
};

/**
 * コンテンツリスト取得
 */
export interface GetContents extends UseCase<{}, GetContentsResponse> {
  execute(): Promise<UseCaseResponse<GetContentsResponse>>;
}

export class GetContentsImpl
  extends AbstractUseCase<{}, GetContentsResponse>
  implements GetContents
{
  private contentFinder: ContentFinder;

  constructor(contentFinder: ContentFinder) {
    super('training.GetContents');
    this.contentFinder = contentFinder;
  }

  async internalExecute(): Promise<GetContentsResponse> {
    const contents = await this.contentFinder.findTenantContents();
    return {
      contents,
    };
  }
}

export const GetContentsKey = injectionKeyOf<GetContents>({
  boundedContext: 'training',
  type: 'usecase',
  name: 'GetContents',
});

export function useGetContents(): GetContents {
  return requiredInject(GetContentsKey);
}
