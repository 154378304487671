import BigNumber from 'bignumber.js';

import {
  CourseStatusRecord,
  UserCourseProgressRecord,
  UserCourseStatusRecord,
  UserExamRecord,
} from '@/report/domains';

function randomValue(min: number, max: number) {
  const n = Math.floor(Math.random() * (max - min + 1)) + min;
  return new BigNumber(n);
}

const userExamRecords: Array<UserExamRecord> = [
  {
    userId: 'user02',
    userName: 'ユーザー02',
    results: [
      { contentId: 'content03-0', contentName: '4) 模擬試験A', score: randomValue(50, 80) },
    ],
  },
  {
    userId: 'user03',
    userName: 'ユーザー03',
    results: [
      { contentId: 'content03-0', contentName: '4) 模擬試験A', score: randomValue(50, 80) },
    ],
  },
  {
    userId: 'user04',
    userName: 'ユーザー04',
    results: [
      { contentId: 'content03-0', contentName: '4) 模擬試験A', score: randomValue(50, 80) },
    ],
  },
  {
    userId: 'user05',
    userName: 'ユーザー05',
    results: [
      { contentId: 'content03-0', contentName: '4) 模擬試験A', score: randomValue(50, 80) },
    ],
  },
];

const userCourseProgressRecords: Array<UserCourseProgressRecord> = [];

const userCourseStatusRecords: Array<UserCourseStatusRecord> = [];

const courseStatusRecords: Array<CourseStatusRecord> = [];

export const reportDataAdapter = {
  userExamRecords,
  userCourseProgressRecords,
  userCourseStatusRecords,
  courseStatusRecords,
};
