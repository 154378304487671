import {
  AuthorizationService,
  ExamDataAdapter,
  Group,
  GroupId,
  GroupRepository,
  localEventBus,
} from '@/base/domains';
import { groupDisabled } from '@/base/domains/LocalEvents';
import { AbstractUseCase, assertEntityExists, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { GROUP_DISABLE_FAILED_GROUP_EXAM_IS_OPEN } from '../ErrorCodes';

export type DisableGroupRequest = {
  groupId: GroupId;
};

export type DisableGroupResponse = {
  group: Group;
};

export interface DisableGroup extends UseCase<DisableGroupRequest, DisableGroupResponse> {
  execute(request: DisableGroupRequest): Promise<UseCaseResponse<DisableGroupResponse>>;
}

export class DisableGroupImpl
  extends AbstractUseCase<DisableGroupRequest, DisableGroupResponse>
  implements DisableGroup
{
  constructor(
    private authorizationService: AuthorizationService,
    private groupRepository: GroupRepository,
    private examDataAdapter: ExamDataAdapter
  ) {
    super('admin.DisableGroup');
  }

  async internalExecute(request: DisableGroupRequest): Promise<DisableGroupResponse> {
    this.authorizationService.assertRole('supervisor', 'admin');
    const { groupId } = request;
    const openGroupExams = await this.examDataAdapter.findGroupExamsByGroupId(groupId, {
      inProgress: true,
    });
    if (openGroupExams.length > 0) {
      throw GROUP_DISABLE_FAILED_GROUP_EXAM_IS_OPEN.toApplicationError({
        exams: openGroupExams.map((ge) => ({
          groupExamId: ge.id,
          courseId: ge.course.id,
          courseName: ge.course.name,
          contentId: ge.content.id,
          contentName: ge.content.name,
        })),
      });
    }
    const group = await this.groupRepository.findById(groupId);
    assertEntityExists(group, 'group');
    const saved = await this.groupRepository.save(group.disable());
    localEventBus.publish(groupDisabled({ groupId }));
    return {
      group: saved,
    };
  }
}

export const DisableGroupKey = injectionKeyOf<DisableGroup>({
  boundedContext: 'admin',
  type: 'usecase',
  name: 'DisableGroup',
});

export function useDisableGroup(): DisableGroup {
  return requiredInject(DisableGroupKey);
}
