import { Format, LogEntry, LogLevel, Transporter } from './core';

export class ConsoleTransporter implements Transporter {
  format: Format;

  level: LogLevel;

  constructor(args: { format: Format; level: LogLevel }) {
    this.format = args.format;
    this.level = args.level;
  }

  log(entry: LogEntry): void {
    const message = this.format(entry);
    console[entry.level](message);
  }
}
