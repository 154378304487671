import * as customMutations from '@/amplify/customMutations';
import * as customQueries from '@/amplify/customQueries';
import {
  ContentType as AmplifyContentType,
  CourseFontColorOnImage as AmplifyCourseFontColorOnImage,
  CreateGroupTrainingCourseInput,
  UpdateGroupTrainingCourseInput,
} from '@/API';
import {
  AppContextProvider,
  ContentId,
  ContentType,
  ContentVersion,
  CourseColor,
  CourseDisplayName,
  CourseFontColorOnImage,
  CourseId,
  CourseName,
  CourseVersion,
  EntityData,
  GroupExamId,
  GroupExamStatus,
  GroupId,
  GroupTrainingId,
  hasId,
} from '@/base/domains';
import { EXCLUSIVE_CONTROL_ERROR } from '@/base/ErrorCodes';
import { LocalDateTime, Minute, Optional, URI } from '@/base/types';
import {
  GroupTrainingCourseAttributes,
  GroupTrainingCourseContent,
  GroupTrainingCourseEntity,
  GroupTrainingCourseEntityImpl,
  GroupTrainingCourseId,
  GroupTrainingCourseRepository,
} from '@/training/domains';
import { graphql } from '@/utils/AmplifyUtils';
import { assertIsDefined } from '@/utils/Asserts';
import { localDateTimeFromString, localDateTimeNow } from '@/utils/DateUtils';
import { ifDefined, isDefined, requiredNonNull } from '@/utils/TsUtils';

type AmplifyGroupExam = {
  id: GroupExamId;
  contentId: ContentId;
  scheduledStart: string;
  scheduledFinish?: string;
  finishedAt?: string;
  times: number;
};

type AmplifyGroupTrainingCourseContent = {
  id: ContentId;
  name: CourseName;
  requiredTime: Minute;
  type: AmplifyContentType;
  recommendedDateTime?: string;
  open: boolean;
  version: ContentVersion;
};

type AmplifyGroupTrainingCourse = {
  id: GroupTrainingCourseId;

  groupTrainingId: GroupTrainingId;

  courseId: CourseId;

  courseVersion: CourseVersion;

  courseName: CourseName;

  contents: Array<AmplifyGroupTrainingCourseContent>;

  groupId: GroupId;

  color?: CourseColor;

  image?: URI;

  fontColorOnImage?: CourseFontColorOnImage;

  displayName: CourseDisplayName;

  groupExams: { items: Array<AmplifyGroupExam> };

  index: number;
};

function toAmplifyGroupTrainingCourseContent(
  e: GroupTrainingCourseContent
): AmplifyGroupTrainingCourseContent {
  return {
    id: e.id,
    name: e.name,
    requiredTime: e.requiredTime,
    type: e.type.toUpperCase() as AmplifyContentType,
    recommendedDateTime: ifDefined(e.recommendedDateTime, (rdt) => rdt.toISOString()),
    open: e.open,
    version: e.version,
  };
}

function toGroupTrainingCourseContent(
  e: AmplifyGroupTrainingCourseContent,
  groupExams: Map<ContentId, AmplifyGroupExam>,
  groupExamsStatusOf: (
    scheduledStart?: LocalDateTime,
    scheduledFinish?: LocalDateTime,
    finishedAt?: LocalDateTime
  ) => GroupExamStatus
): GroupTrainingCourseContent {
  const type = e.type.toLowerCase() as ContentType;
  if (type === 'exam') {
    const groupExam = groupExams.get(e.id);
    const scheduledStart = ifDefined(groupExam?.scheduledStart, localDateTimeFromString);
    const scheduledFinish = ifDefined(groupExam?.scheduledFinish, localDateTimeFromString);
    const finishedAt = ifDefined(groupExam?.finishedAt, localDateTimeFromString);
    const examStatus = groupExamsStatusOf(scheduledStart, scheduledFinish, finishedAt);
    return {
      ...e,
      type: 'exam',
      recommendedDateTime: ifDefined(e.recommendedDateTime, (rdt) => localDateTimeFromString(rdt)),
      examStatus,
      latestExamId: groupExam?.id,
    };
  }

  return {
    ...e,
    type,
    recommendedDateTime: ifDefined(e.recommendedDateTime, (rdt) => localDateTimeFromString(rdt)),
  };
}

const groupExamStatusOfProvider = () => {
  const now = localDateTimeNow();
  return (
    scheduledStart?: LocalDateTime,
    scheduledFinish?: LocalDateTime,
    finishedAt?: LocalDateTime
  ) => {
    if (!isDefined(scheduledStart)) {
      return 'not_scheduled';
    }
    if (isDefined(finishedAt) || scheduledFinish?.isSameOrBefore(now)) {
      return 'finished';
    }
    if (scheduledStart.isSameOrBefore(now)) {
      return 'in_progress';
    }
    return 'announced';
  };
};

function toGroupTrainingCourse(e: AmplifyGroupTrainingCourse): GroupTrainingCourseEntity {
  const color = (() => {
    if (isDefined(e.color)) {
      return e.color;
    }
    if (isDefined(e.image)) {
      return undefined;
    }
    return 'default';
  })();

  const fontColorOnImage = ifDefined(
    e.fontColorOnImage,
    (v) => v.toLowerCase() as CourseFontColorOnImage
  );

  const groupExams: Map<ContentId, AmplifyGroupExam> = (() => {
    const l = e.groupExams.items.sort((a, b) => -(a.times - b.times));
    const m = new Map();
    l.forEach((ge) => {
      if (!m.has(ge.contentId)) {
        m.set(ge.contentId, ge);
      }
    });
    return m;
  })();
  const groupExamStatusOf = groupExamStatusOfProvider();
  return new GroupTrainingCourseEntityImpl({
    ...e,
    contents: e.contents.map((cn) =>
      toGroupTrainingCourseContent(cn, groupExams, groupExamStatusOf)
    ),
    color,
    fontColorOnImage,
  });
}

export class AmplifyGroupTrainingCourseRepository implements GroupTrainingCourseRepository {
  private appContextProvider: AppContextProvider;

  constructor(appContextProvider: AppContextProvider) {
    this.appContextProvider = appContextProvider;
  }

  async save(
    args: GroupTrainingCourseEntity | EntityData<string, GroupTrainingCourseAttributes>
  ): Promise<GroupTrainingCourseEntity> {
    const tenantCode = requiredNonNull(
      this.appContextProvider.get().tenantCode,
      'appContext.tenantCode'
    );

    const fontColorOnImage = ifDefined(
      args.fontColorOnImage,
      (v) => v.toUpperCase() as AmplifyCourseFontColorOnImage
    );
    if (hasId(args)) {
      const input: UpdateGroupTrainingCourseInput = {
        ...args,
        contents: args.contents.map(toAmplifyGroupTrainingCourseContent),
        fontColorOnImage,
        tenantCode,
      };
      const response = await graphql<{
        updateGroupTrainingCourse: AmplifyGroupTrainingCourse;
      }>(customMutations.updateGroupTrainingCourse, { input });
      return toGroupTrainingCourse(response.updateGroupTrainingCourse);
    }
    const input: CreateGroupTrainingCourseInput = {
      ...args,
      contents: args.contents.map(toAmplifyGroupTrainingCourseContent),
      fontColorOnImage,
      tenantCode,
    };
    const response = await graphql<{ createGroupTrainingCourse: AmplifyGroupTrainingCourse }>(
      customMutations.createGroupTrainingCourse,
      { input }
    );
    return toGroupTrainingCourse(response.createGroupTrainingCourse);
  }

  async findById(id: GroupTrainingCourseId): Promise<Optional<GroupTrainingCourseEntity>> {
    const response = await graphql<{
      getGroupTrainingCourse: Optional<AmplifyGroupTrainingCourse>;
    }>(customQueries.getGroupTrainingCourse, { id });
    return response.getGroupTrainingCourse
      ? toGroupTrainingCourse(response.getGroupTrainingCourse)
      : undefined;
  }

  async remove(id: GroupTrainingCourseId): Promise<void> {
    await graphql(customMutations.deleteGroupTrainingCourse, { input: { id } });
  }

  async findByGroupIdAndCourseId(
    groupId: GroupId,
    courseId: CourseId
  ): Promise<Optional<GroupTrainingCourseEntity>> {
    const response = await graphql<{
      groupTrainingCoursesByGroupIdAndCourseId: { items: Array<AmplifyGroupTrainingCourse> };
    }>(customQueries.groupTrainingCoursesByGroupIdAndCourseId, {
      groupId,
      courseId: { eq: courseId },
    });
    return ifDefined(
      response.groupTrainingCoursesByGroupIdAndCourseId.items[0],
      toGroupTrainingCourse
    );
  }

  async findByGroupIdAndCourseDisplayName(
    groupId: GroupId,
    courseDisplayName: CourseDisplayName
  ): Promise<Optional<GroupTrainingCourseEntity>> {
    const response = await graphql<{
      groupTrainingCoursesByGroupIdAndCourseId: { items: Array<AmplifyGroupTrainingCourse> };
    }>(customQueries.groupTrainingCoursesByGroupIdAndCourseId, {
      groupId,
      filter: {
        displayName: {
          eq: courseDisplayName,
        },
      },
    });
    return ifDefined(
      response.groupTrainingCoursesByGroupIdAndCourseId.items[0],
      toGroupTrainingCourse
    );
  }

  async findCoursesByGroupId(groupId: GroupId): Promise<GroupTrainingCourseEntity[]> {
    const response = await graphql<{
      groupTrainingCoursesByGroupIdAndCourseId: { items: Array<AmplifyGroupTrainingCourse> };
    }>(customQueries.groupTrainingCoursesByGroupIdAndCourseId, { groupId });
    return response.groupTrainingCoursesByGroupIdAndCourseId.items.map(toGroupTrainingCourse);
  }

  async findCoursesByCourseId(courseId: CourseId): Promise<GroupTrainingCourseEntity[]> {
    const response = await graphql<{
      groupTrainingCoursesByCourseId: { items: Array<AmplifyGroupTrainingCourse> };
    }>(customQueries.groupTrainingCoursesByCourseId, { courseId });
    return response.groupTrainingCoursesByCourseId.items.map(toGroupTrainingCourse);
  }

  async sortGroupTrainingCourses(
    groupId: GroupId,
    courseIds: CourseId[]
  ): Promise<GroupTrainingCourseEntity[]> {
    const courses = await this.findCoursesByGroupId(groupId);
    if (
      courses.length !== courseIds.length ||
      courses.find((c) => !courseIds.find((id) => id === c.courseId))
    ) {
      throw EXCLUSIVE_CONTROL_ERROR.toApplicationError({ entity: 'groupCourses' });
    }
    const sortedCourses = courseIds.map((id, index) => {
      const course = courses.find((c) => c.courseId === id);
      assertIsDefined(course);
      const res = {
        ...course,
        index,
      };
      return res;
    });
    const saved = await Promise.all(sortedCourses.map((c) => this.save(c)));
    return saved;
  }
}
