import { Optional } from '../types';
import { TenantOwnTermsOfServiceQueries } from './queries';
import { TenantOwnTermsOfService, TenantOwnTermsOfServiceFinder } from './TenantOwnTermsOfService';

export class TenantOwnTermsOfServiceFinderImpl implements TenantOwnTermsOfServiceFinder {
  constructor(private tenantOwnTermsOfServiceQuery: TenantOwnTermsOfServiceQueries) {}

  findTenantOwnTermsOfService(): Promise<Optional<TenantOwnTermsOfService>> {
    return this.tenantOwnTermsOfServiceQuery.findTenantOwnTermsOfService();
  }
}
