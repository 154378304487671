import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import {
  AuthorizationService,
  localEventBus,
  User,
  UserId,
  UserName,
  UserRepository,
} from '../domains';
import { userNameChanged } from '../domains/LocalEvents';
import { AbstractUseCase, UseCase, UseCaseResponse } from './UseCase';
import { assertEntityExists } from './UseCaseAsserts';

export interface ChangeUserNameRequest {
  id: UserId;
  name: UserName;
}

export type ChangeUserNameResponse = {
  user: User;
};

export interface ChangeUserName extends UseCase<ChangeUserNameRequest, ChangeUserNameResponse> {
  execute(request: ChangeUserNameRequest): Promise<UseCaseResponse<ChangeUserNameResponse>>;
}

export class ChangeUserNameImpl
  extends AbstractUseCase<ChangeUserNameRequest, ChangeUserNameResponse>
  implements ChangeUserName
{
  private authorizationService: AuthorizationService;

  private userRepository: UserRepository;

  constructor(authorizationService: AuthorizationService, userRepository: UserRepository) {
    super('base.ChangeUserName');
    this.authorizationService = authorizationService;
    this.userRepository = userRepository;
  }

  async internalExecute(request: ChangeUserNameRequest): Promise<ChangeUserNameResponse> {
    const { id, name } = request;
    this.authorizationService.assertOwnerAccessible(id);
    const user = await this.userRepository.findById(id);
    assertEntityExists(user, 'user');
    const saved = await this.userRepository.save(user.changeName(name));
    localEventBus.publish(userNameChanged({ userId: user.id }));
    return {
      user: saved,
    };
  }
}

export const ChangeUserNameKey = injectionKeyOf<ChangeUserName>({
  boundedContext: 'base',
  type: 'usecase',
  name: 'ChangeUserName',
});

export function useChangeUserName(): ChangeUserName {
  return requiredInject(ChangeUserNameKey);
}
