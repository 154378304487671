import { EditingTenantOwnTermsOfServiceRepositoryKey } from '@/admin/domains';
import {
  InMemoryEditingTenantOwnTermsOfServiceRepository,
  InMemoryTenantOwnTermsOfServiceQueries,
} from '@/admin/infrastructures/inmemory';
import {
  AuthServiceKey,
  ContentLearningDataAdapterKey,
  DateTimeServiceKey,
  EventStoreKey,
  ExamDataAdapterKey,
  FileStorageKey,
  GroupRepositoryKey,
  GroupStatusServiceKey,
  InternalTaskServiceKey,
  NotificationRepositoryKey,
  PrivacyPolicyDataAdapterKey,
  ScheduleTagDataAdapterKey,
  SignUpReservationQueriesKey,
  SurviverKey,
  TenantDataAdapterKey,
  TermsOfServiceDataAdapterKey,
  useAppContextProvider,
  useGroupRepository,
  useNotificationRepository,
  UserRepositoryKey,
  UserTagRepositoryKey,
  useUserRepository,
} from '@/base/domains';
import { GroupStatusQueriesKey, TenantOwnTermsOfServiceQueriesKey } from '@/base/domains/queries';
import {
  InMemoryAuthService,
  InMemoryDateTimeService,
  InMemoryEventStore,
  InMemoryExamDataAdapter,
  InMemoryFileStorage,
  InMemoryGroupRepository,
  InMemoryGroupStatusQueries,
  InMemoryGroupStatusService,
  InMemoryInternalTaskService,
  InMemoryNotificationRepository,
  InMemoryPrivacyPolicyDataAdapter,
  InMemorySignUpReservationQueries,
  InMemorySurviver,
  InMemoryTenantDataAdapter,
  InMemoryTermsOfServiceDataAdapter,
  InMemoryUserRepository,
  InMemoryUserTagRepository,
} from '@/base/infrastructures/inmemory';
import {
  ContentRepositoryKey,
  CourseHeaderRepositoryKey,
  CourseRepositoryKey,
  EditingConfirmedContentRepositoryKey,
  EditingCourseContentBodyRepositoryKey,
  EditingCourseContentWorkbookRepositoryKey,
  EditingCourseRepositoryKey,
  useCourseHeaderRepository,
  useEditingCourseContentBodyRepository,
  useEditingCourseContentWorkbookRepository,
  useEditingCourseRepository,
} from '@/contents/domains';
import {
  ContentQueriesKey,
  EditingConfirmedContentQueriesKey,
  EditingCourseContentBodyQueriesKey,
  EditingCourseQueriesKey,
} from '@/contents/domains/queries';
import {
  InMemoryContentQueries,
  InMemoryContentRepository,
  InMemoryCourseHeaderRepository,
  InMemoryCourseRepository,
  InMemoryEditingConfirmedContentQueries,
  InMemoryEditingConfirmedContentRepository,
  InMemoryEditingCourseContentBodyRepository,
  InMemoryEditingCourseContentWorkbookRepository,
  InMemoryEditingCourseQueries,
  InMemoryEditingCourseRepository,
} from '@/contents/infrastructures/inmemory';
import { InMemoryEditingCourseContentBodyQueries } from '@/contents/infrastructures/inmemory/InMemoryEditingCourseContentBodyQueries';
import { PlaygroundRepositoryKey } from '@/playground/domains';
import { InMemoryPlaygroundRepository } from '@/playground/infrastructures/inmemory';
import {
  GroupExamRepositoryKey,
  GroupTrainingCourseRepositoryKey,
  GroupTrainingRepositoryKey,
  MemoRepositoryKey,
  QuestionnaireRepositoryKey,
  QuestionRepositoryKey,
  ReviewProblemRepositoryKey,
  ScheduleRepositoryKey,
  ScheduleTagRepositoryKey,
  useGroupTrainingCourseRepository,
  useGroupTrainingRepository,
  useQuestionnaireRepository,
  useQuestionRepository,
  useReviewProblemRepository,
  useScheduleRepository,
} from '@/training/domains';
import { QuestionQueriesKey } from '@/training/domains/queries';
import {
  InMemoryContentLearningDataAdapter,
  InMemoryGroupExamRepository,
  InMemoryGroupTrainingCourseRepository,
  InMemoryGroupTrainingRepository,
  InMemoryMemoRepository,
  InMemoryQuestionnaireRepository,
  InMemoryQuestionRepository,
  InMemoryReviewProblemRepository,
  InMemoryScheduleRepository,
  InMemoryScheduleTagDataAdapter,
  InMemoryScheduleTagRepository,
} from '@/training/infrastructures/inmemory';
import { InMemoryQuestionQueries } from '@/training/infrastructures/inmemory/InMemoryQuestionQueries';
import { provide, requiredInject } from '@/utils/VueUtils';

import {
  contentLearnings,
  contents,
  courseHeaders,
  courses,
  editingCourseContentBodies,
  editingCourseContentWorkbooks,
  editingCourses,
  editingTenantOwnTermsOfService,
  examResults,
  groups,
  groupTrainingCourses,
  groupTrainings,
  notifications,
  questionnaireByAnswerer,
  questionnaireRespondent,
  questionnaires,
  questionnairesByAnswerer,
  questions,
  schedules,
  signedInUsers,
  signUpReservation,
  signUpReservations,
  tenant,
  tenantOwnTermsOfService,
  termsOfServices,
  users,
} from './mock-data';
import { memos } from './mock-data/memos';
import { playgrounds } from './mock-data/playgrounds';
import { reviewProblems } from './mock-data/reviewProblems';
import { unansweredQuestionnaires } from './mock-data/unansweredQuestionnaires';

function addMockData() {
  const userRepository = useUserRepository();
  users.forEach((e) => userRepository.save(e));
  const groupRepository = useGroupRepository();
  groups.forEach((e) => groupRepository.save(e));
}

export function addMockDataPostSignIn() {
  const contentRepository = requiredInject(ContentRepositoryKey);
  const courseRepository = requiredInject(CourseRepositoryKey);
  const groupTrainingRepository = useGroupTrainingRepository();
  const groupTrainingCourseRepository = useGroupTrainingCourseRepository();
  const notificationRepository = useNotificationRepository();
  const questionRepository = useQuestionRepository();
  const scheduleRepository = useScheduleRepository();
  const courseHeaderRepository = useCourseHeaderRepository();
  const editingCourseRepository = useEditingCourseRepository();
  const editingCourseContentBodyRepository = useEditingCourseContentBodyRepository();
  const editingCourseContentWorkbookRepository = useEditingCourseContentWorkbookRepository();
  const questionnaireRepository = useQuestionnaireRepository();
  const reviewProblemRepository = useReviewProblemRepository();

  return () => {
    contents.forEach((e) => (contentRepository as InMemoryContentRepository).save(e));
    courses.forEach((e) => courseRepository.save(e));
    groupTrainings.forEach((gt) => groupTrainingRepository.save(gt));
    groupTrainingCourses.forEach((gtc) => groupTrainingCourseRepository.save(gtc));
    notifications.forEach((n) => notificationRepository.save(n));
    questions.forEach((q) => questionRepository.save(q));
    schedules.forEach((s) => scheduleRepository.save(s));
    courseHeaders.forEach((ch) => courseHeaderRepository.save(ch));
    editingCourses.forEach((c) => editingCourseRepository.save(c));
    editingCourseContentBodies.forEach((b) => editingCourseContentBodyRepository.save(b));
    editingCourseContentWorkbooks.forEach((w) => editingCourseContentWorkbookRepository.save(w));
    questionnaires.forEach((q) => questionnaireRepository.save(q));
    reviewProblems.forEach((r) => reviewProblemRepository.save(r));
  };
}

export function inMemoryAdapters() {
  const appContextProvider = useAppContextProvider();
  // admin
  provide(
    EditingTenantOwnTermsOfServiceRepositoryKey,
    new InMemoryEditingTenantOwnTermsOfServiceRepository(
      editingTenantOwnTermsOfService,
      tenantOwnTermsOfService
    )
  );
  provide(
    TenantOwnTermsOfServiceQueriesKey,
    new InMemoryTenantOwnTermsOfServiceQueries(tenantOwnTermsOfService)
  );

  // base
  provide(EventStoreKey, new InMemoryEventStore());
  provide(ExamDataAdapterKey, new InMemoryExamDataAdapter(examResults));
  provide(FileStorageKey, new InMemoryFileStorage());
  const userRepository = new InMemoryUserRepository();
  provide(UserRepositoryKey, userRepository);
  const groupRepository = new InMemoryGroupRepository();
  provide(GroupRepositoryKey, groupRepository);
  const notificationRepository = new InMemoryNotificationRepository();
  provide(NotificationRepositoryKey, notificationRepository);
  const authService = new InMemoryAuthService(signedInUsers, appContextProvider);
  provide(AuthServiceKey, authService);
  provide(TenantDataAdapterKey, new InMemoryTenantDataAdapter(tenant));
  provide(
    EditingCourseContentBodyRepositoryKey,
    new InMemoryEditingCourseContentBodyRepository(appContextProvider)
  );
  provide(
    EditingCourseContentWorkbookRepositoryKey,
    new InMemoryEditingCourseContentWorkbookRepository(appContextProvider)
  );
  provide(EditingCourseRepositoryKey, new InMemoryEditingCourseRepository(appContextProvider));
  provide(
    SignUpReservationQueriesKey,
    new InMemorySignUpReservationQueries(signUpReservations, signUpReservation)
  );
  provide(DateTimeServiceKey, new InMemoryDateTimeService());
  provide(TermsOfServiceDataAdapterKey, new InMemoryTermsOfServiceDataAdapter(termsOfServices));
  provide(SurviverKey, new InMemorySurviver());
  const UserTagRepository = new InMemoryUserTagRepository();
  provide(UserTagRepositoryKey, UserTagRepository);
  provide(GroupStatusQueriesKey, new InMemoryGroupStatusQueries());
  provide(GroupStatusServiceKey, new InMemoryGroupStatusService());
  provide(InternalTaskServiceKey, new InMemoryInternalTaskService());
  provide(PrivacyPolicyDataAdapterKey, new InMemoryPrivacyPolicyDataAdapter());

  // contents
  const contentRepository = new InMemoryContentRepository();
  provide(ContentRepositoryKey, contentRepository);
  const courseRepository = new InMemoryCourseRepository();
  provide(CourseRepositoryKey, courseRepository);
  provide(CourseHeaderRepositoryKey, new InMemoryCourseHeaderRepository(appContextProvider));
  provide(ContentQueriesKey, new InMemoryContentQueries());
  provide(EditingCourseContentBodyQueriesKey, new InMemoryEditingCourseContentBodyQueries());

  // training
  const groupTrainingRepository = new InMemoryGroupTrainingRepository();
  provide(GroupTrainingRepositoryKey, groupTrainingRepository);
  provide(GroupTrainingCourseRepositoryKey, new InMemoryGroupTrainingCourseRepository());
  const questionRepository = new InMemoryQuestionRepository(appContextProvider);
  provide(QuestionRepositoryKey, questionRepository);
  const scheduleTagRepository = new InMemoryScheduleTagRepository();
  provide(ScheduleTagRepositoryKey, scheduleTagRepository);
  provide(ScheduleTagDataAdapterKey, new InMemoryScheduleTagDataAdapter(scheduleTagRepository));
  provide(ScheduleRepositoryKey, new InMemoryScheduleRepository(scheduleTagRepository));
  provide(ContentLearningDataAdapterKey, new InMemoryContentLearningDataAdapter(contentLearnings));
  provide(GroupExamRepositoryKey, new InMemoryGroupExamRepository());
  provide(EditingCourseQueriesKey, new InMemoryEditingCourseQueries());
  provide(EditingConfirmedContentRepositoryKey, new InMemoryEditingConfirmedContentRepository());
  provide(EditingConfirmedContentQueriesKey, new InMemoryEditingConfirmedContentQueries());
  provide(QuestionQueriesKey, new InMemoryQuestionQueries());
  provide(
    QuestionnaireRepositoryKey,
    new InMemoryQuestionnaireRepository(
      questionnaires,
      questionnaireRespondent,
      unansweredQuestionnaires,
      questionnaireByAnswerer,
      questionnairesByAnswerer
    )
  );
  provide(MemoRepositoryKey, new InMemoryMemoRepository(memos));
  provide(ReviewProblemRepositoryKey, new InMemoryReviewProblemRepository(appContextProvider));

  // playground
  provide(PlaygroundRepositoryKey, new InMemoryPlaygroundRepository(playgrounds));

  addMockData();
}
