import {
  AppContextProvider,
  GroupId,
  QuestionnaireByAnswerer,
  QuestionnaireFinder,
} from '@/base/domains';
import { AbstractUseCase, UseCase, UseCaseResponse } from '@/base/usecases';
import { assertIsDefined } from '@/utils/Asserts';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

export type GetQuestionnairesByAnswererRequest = {
  groupId: GroupId;
};

export type GetQuestionnairesByAnswererResponse = {
  questionnaires: Array<QuestionnaireByAnswerer>;
};

export interface GetQuestionnairesByAnswerer
  extends UseCase<GetQuestionnairesByAnswererRequest, GetQuestionnairesByAnswererResponse> {
  execute(
    request: GetQuestionnairesByAnswererRequest
  ): Promise<UseCaseResponse<GetQuestionnairesByAnswererResponse>>;
}

export class GetQuestionnairesByAnswererImpl
  extends AbstractUseCase<GetQuestionnairesByAnswererRequest, GetQuestionnairesByAnswererResponse>
  implements GetQuestionnairesByAnswerer
{
  constructor(
    private appContextProvider: AppContextProvider,
    private questionnaireFinder: QuestionnaireFinder
  ) {
    super('home.GetQuestionnairesByAnswerer');
  }

  async internalExecute(
    request: GetQuestionnairesByAnswererRequest
  ): Promise<GetQuestionnairesByAnswererResponse> {
    const { groupId } = request;
    const userId = this.appContextProvider.get().user?.id;
    assertIsDefined(userId, 'userId');
    const questionnaires = await this.questionnaireFinder.findQuestionnairesByAnswerer(
      groupId,
      userId
    );
    const res = questionnaires.sort((a, b) => (a.answered < b.answered ? -1 : 1));
    return { questionnaires: res };
  }
}

export const GetQuestionnairesByAnswererKey = injectionKeyOf<GetQuestionnairesByAnswerer>({
  boundedContext: 'training',
  type: 'usecase',
  name: 'GetQuestionnairesByAnswerer',
});

export function useGetQuestionnairesByAnswerer(): GetQuestionnairesByAnswerer {
  return requiredInject(GetQuestionnairesByAnswererKey);
}
