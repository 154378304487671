import { AuthorizationService, ContentId, DataVersion, DateTimeService } from '@/base/domains';
import { MarkDownString } from '@/base/types';
import {
  AbstractUseCase,
  assertDataVersion,
  assertEntityExists,
  UseCase,
  UseCaseResponse,
} from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { EditingConfirmedContentReference, EditingConfirmedContentRepository } from '../domains';

export type AddProblemHeaderToEditingConfirmedContentRequest = {
  id: ContentId;
  body: MarkDownString;
  expectedDataVersion: DataVersion;
};

export type AddProblemHeaderToEditingConfirmedContentResponse = {
  editingConfirmedContent: EditingConfirmedContentReference;
};

/**
 * 編集中確定済みコンテンツに問題ヘッダーを追加する
 */
export interface AddProblemHeaderToEditingConfirmedContent
  extends UseCase<
    AddProblemHeaderToEditingConfirmedContentRequest,
    AddProblemHeaderToEditingConfirmedContentResponse
  > {
  execute(
    request: AddProblemHeaderToEditingConfirmedContentRequest
  ): Promise<UseCaseResponse<AddProblemHeaderToEditingConfirmedContentResponse>>;
}

export class AddProblemHeaderToEditingConfirmedContentImpl
  extends AbstractUseCase<
    AddProblemHeaderToEditingConfirmedContentRequest,
    AddProblemHeaderToEditingConfirmedContentResponse
  >
  implements AddProblemHeaderToEditingConfirmedContent
{
  constructor(
    private authorizationService: AuthorizationService,
    private editingConfirmedContentRepository: EditingConfirmedContentRepository,
    private dateTimeService: DateTimeService
  ) {
    super('contents.AddProblemHeaderToEditingConfirmedContent');
  }

  async internalExecute(
    request: AddProblemHeaderToEditingConfirmedContentRequest
  ): Promise<AddProblemHeaderToEditingConfirmedContentResponse> {
    const { id, body, expectedDataVersion } = request;
    this.authorizationService.assertContentEditable();
    const [content, createdAt] = await Promise.all([
      this.editingConfirmedContentRepository.findById(id),
      this.dateTimeService.strictLocalDateTimeNow(),
    ]);
    assertEntityExists(content, 'editingConfirmedContent');
    assertDataVersion(content, expectedDataVersion, 'editingConfirmedContent');
    if (content.type === 'exam') {
      const saved = await this.editingConfirmedContentRepository.save(
        content.addProblemHeader({
          problemHeaderBody: body,
          createdAt,
        })
      );
      return {
        editingConfirmedContent: saved,
      };
    }
    throw new Error('editingConfirmedContent.type should be exam');
  }
}

export const AddProblemHeaderToEditingConfirmedContentKey =
  injectionKeyOf<AddProblemHeaderToEditingConfirmedContent>({
    boundedContext: 'contents',
    type: 'usecase',
    name: 'AddProblemHeaderToEditingConfirmedContent',
  });

export function useAddProblemHeaderToEditingConfirmedContent(): AddProblemHeaderToEditingConfirmedContent {
  return requiredInject(AddProblemHeaderToEditingConfirmedContentKey);
}
