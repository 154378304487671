import {
  AppContextProvider,
  AuthorizationService,
  ContentFinder,
  ContentId,
  CourseId,
  DateTimeService,
  Exam,
  ExamResultVisibilityLevel,
  GroupExamId,
  GroupId,
  GroupTrainingFinder,
  UserId,
} from '@/base/domains';
import { LocalDateTime, Minute } from '@/base/types';
import { AbstractUseCase, assertEntityExists, UseCase, UseCaseResponse } from '@/base/usecases';
import { assertIsDefined } from '@/utils/Asserts';
import { isDefined } from '@/utils/TsUtils';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { GroupExamData, GroupExamRepository, GroupTrainingCourseRepository } from '../domains';
import {
  GROUP_EXAM_ALREADY_STARTED,
  GROUP_EXAM_REQUEST_INCLUDES_NO_AFFILIATION_USERS,
} from '../ErrorCodes';

export interface AddGroupExamRequest {
  groupId: GroupId;
  courseId: CourseId;
  contentId: ContentId;
  visibilityLevel: ExamResultVisibilityLevel;
  timeLimit?: Minute;
  passingStandard?: number;
  userIdsToBeTested: UserId[];
  scheduledStart?: LocalDateTime;
  scheduledFinish?: LocalDateTime;
}

export interface AddGroupExamResponse {
  id: GroupExamId;
}

/**
 * グループテストを追加する
 */
export interface AddGroupExam extends UseCase<AddGroupExamRequest, AddGroupExamResponse> {
  execute(request: AddGroupExamRequest): Promise<UseCaseResponse<AddGroupExamResponse>>;
}

export class AddGroupExamImpl
  extends AbstractUseCase<AddGroupExamRequest, AddGroupExamResponse>
  implements AddGroupExam
{
  constructor(
    private authorizationService: AuthorizationService,
    private groupExamRepository: GroupExamRepository,
    private groupTrainingCourseRepository: GroupTrainingCourseRepository,
    private contentFinder: ContentFinder,
    private dateTimeService: DateTimeService,
    private groupTrainingFinder: GroupTrainingFinder,
    private appContextProvider: AppContextProvider
  ) {
    super('training.AddGroupExam');
  }

  async internalExecute(request: AddGroupExamRequest): Promise<AddGroupExamResponse> {
    const {
      groupId,
      courseId,
      contentId,
      visibilityLevel,
      timeLimit,
      userIdsToBeTested,
      passingStandard,
      scheduledStart,
      scheduledFinish,
    } = request;

    if (userIdsToBeTested.length === 0) {
      throw new Error('Request.userIdsToBeTested should not be empty');
    }

    this.authorizationService.assertTrainerAccessible(groupId);
    const [groupExams, groupTrainingCourse, now] = await Promise.all([
      this.groupExamRepository.findGroupExamsByGroupIdAndContentId(groupId, contentId),
      this.groupTrainingCourseRepository.findByGroupIdAndCourseId(groupId, courseId),
      this.dateTimeService.strictLocalDateTimeNow(),
    ]);
    assertEntityExists(groupTrainingCourse, 'groupTrainingCourse');
    const groupTrainingCourseContent = groupTrainingCourse.contents.find(
      (cn) => cn.id === contentId
    );
    assertIsDefined(groupTrainingCourseContent, 'groupTrainingCourse.content');
    const content = await this.contentFinder.findById(
      contentId,
      groupTrainingCourseContent.version
    );
    assertEntityExists(content, 'content');

    if (content.type !== 'exam') {
      throw new Error('content should be exam-type');
    }
    if (groupExams.find((ge) => ge.status !== 'finished' && !isDefined(ge.finishedAt))) {
      throw GROUP_EXAM_ALREADY_STARTED.toApplicationError({
        payload: {
          groupId,
          courseId,
          contentId,
        },
      });
    }
    groupExams.sort((a, b) => -(a.times - b.times));
    const times = groupExams.length === 0 ? 1 : groupExams[0].times + 1;
    const examContentBody = content.body as Exam;
    const course = await this.groupTrainingFinder.findCourseByGroupIdAndCourseId(groupId, courseId);
    assertIsDefined(course?.contents, 'contents');
    const indexInCourse = course.contents
      .map((cn, i) => ({ id: cn.id, index: i }))
      .find((cn) => cn.id === request.contentId)?.index;
    assertIsDefined(indexInCourse, 'indexInCourse');

    const groupUserIds = new Set(
      (this.appContextProvider.get().groupOf(groupId)?.users || []).map((u) => u.id)
    );
    if (userIdsToBeTested.find((userId) => !groupUserIds.has(userId))) {
      throw GROUP_EXAM_REQUEST_INCLUDES_NO_AFFILIATION_USERS.toApplicationError({
        groupId,
        courseId,
        courseName: groupTrainingCourse.courseName,
        courseDisplayName: groupTrainingCourse.displayName,
        contentId,
        contentName: content.name,
      });
    }

    const groupExam: GroupExamData = {
      groupId,
      content: {
        id: content.id,
        version: content.version,
        name: content.name,
        requiredTime: content.requiredTime,
        indexInCourse,
        problems: examContentBody.problems.map((p) => ({
          index: p.index,
          type: p.type,
          body: p.body,
          options: p.options,
          multiple: p.multiple,
          headerId: p.headerId,
        })),
        problemHeaders: examContentBody.problemHeaders,
        passingStandard: examContentBody.passingStandard,
      },
      course: {
        id: groupTrainingCourse.courseId,
        name: groupTrainingCourse.displayName,
        version: groupTrainingCourse.courseVersion,
        image: groupTrainingCourse.image,
        color: groupTrainingCourse.color,
        fontColorOnImage: groupTrainingCourse.fontColorOnImage,
      },
      scheduledStart: scheduledStart ?? now,
      scheduledFinish,
      finishedAt: undefined,
      status: 'announced',
      visibilityLevel,
      userExams: [],
      times,
      groupTrainingCourseId: groupTrainingCourse.id,
      timeLimit,
      passingStandard,
      userIdsToBeTested,
    };
    const saved = await this.groupExamRepository.save(groupExam);
    // groupExam.userExamsは非同期で作成されるのでidだけ返す
    return {
      id: saved.id,
    };
  }
}

export const AddGroupExamKey = injectionKeyOf<AddGroupExam>({
  boundedContext: 'training',
  type: 'usecase',
  name: 'AddGroupExam',
});

export function useAddGroupExam(): AddGroupExam {
  return requiredInject(AddGroupExamKey);
}
