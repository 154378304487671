import { AuthorizationService, GroupId, UserFinder, UserId, UserName } from '@/base/domains';
import { AbstractUseCase, assertEntityExists, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { ReportService, UserCourseProgresses } from '../domains';

export type GetUserCourseProgressesRequest = {
  userId: UserId;
  groupId: GroupId;
};

export type GetUserCourseProgressesResponse = {
  userName: UserName;
  courses: Array<UserCourseProgresses>;
};

/**
 * 受講生のコース進捗リストを取得する
 */
export interface GetUserCourseProgresses
  extends UseCase<GetUserCourseProgressesRequest, GetUserCourseProgressesResponse> {
  execute(
    request: GetUserCourseProgressesRequest
  ): Promise<UseCaseResponse<GetUserCourseProgressesResponse>>;
}

export class GetUserCourseProgressesImpl
  extends AbstractUseCase<GetUserCourseProgressesRequest, GetUserCourseProgressesResponse>
  implements GetUserCourseProgresses
{
  constructor(
    private authorizationService: AuthorizationService,
    private userFinder: UserFinder,
    private reportService: ReportService
  ) {
    super('report.GetUserCourseProgresses');
  }

  async internalExecute(
    request: GetUserCourseProgressesRequest
  ): Promise<GetUserCourseProgressesResponse> {
    const { userId, groupId } = request;
    this.authorizationService.assertGroupReportAccessible(groupId);
    const [user, courses] = await Promise.all([
      this.userFinder.findById(userId),
      this.reportService.findUserCourseProgresses(groupId, userId),
    ]);
    assertEntityExists(user, 'user');
    return { userName: user.name, courses };
  }
}

export const GetUserCourseProgressesKey = injectionKeyOf<GetUserCourseProgresses>({
  boundedContext: 'report',
  type: 'usecase',
  name: 'GetUserCourseProgresses',
});

export function useGetUserCourseProgresses(): GetUserCourseProgresses {
  return requiredInject(GetUserCourseProgressesKey);
}
