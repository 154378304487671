import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { AuthService, SignedInUser } from '../domains';
import { AbstractUseCase, UseCase, UseCaseResponse } from './UseCase';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface GetCurrentUserRequest {}

export type GetCurrentUserResponse = {
  currentUser?: Omit<SignedInUser, 'groups'>;
};

export interface GetCurrentUser extends UseCase<GetCurrentUserRequest, GetCurrentUserResponse> {
  execute(request: GetCurrentUserRequest): Promise<UseCaseResponse<GetCurrentUserResponse>>;
}

export class GetCurrentUserImpl
  extends AbstractUseCase<GetCurrentUserRequest, GetCurrentUserResponse>
  implements GetCurrentUser
{
  private authService: AuthService;

  constructor(authService: AuthService) {
    super('base.GetCurrentUser');
    this.authService = authService;
  }

  async internalExecute(_: GetCurrentUserRequest): Promise<GetCurrentUserResponse> {
    const currentUser = await this.authService.currentUser();
    if (currentUser) {
      return {
        currentUser,
      };
    }

    return {
      currentUser: undefined,
    };
  }
}

export const GetCurrentUserKey = injectionKeyOf<GetCurrentUser>({
  boundedContext: 'base',
  type: 'usecase',
  name: 'GetCurrentUser',
});

export function useGetCurrentUser(): GetCurrentUser {
  return requiredInject(GetCurrentUserKey);
}
