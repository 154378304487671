import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { Optional } from '../types';
import { UserId } from './Core';
import { GroupId, GroupLimitation, GroupName } from './Group';
import { SignedInUser } from './SignedInUser';
import { TenantCode } from './Tenant';
import { GroupRole, UserName } from './User';

/**
 * アプリケーションコンテキスト
 */
export interface AppContext {
  readonly user?: SignedInUser;
  readonly tenantCode?: TenantCode;
  readonly groupId?: GroupId;
  readonly applicationSessionId: string;
  readonly accessibleGroups: ReadonlyArray<{
    id: GroupId;
    name: GroupName;
    users: Array<{ id: UserId; name: UserName; role: GroupRole }>;
  }>;
  readonly groupsIncludeRemovedUser: ReadonlyArray<{
    id: GroupId;
    name: GroupName;
    enabled: boolean;
    users: Array<{ id: UserId; name: UserName; role: GroupRole; removed: boolean }>;
  }>;
  readonly users: Array<{ id: UserId; name: UserName; enabled: boolean }>;
  readonly playground: boolean;
  roleInGroup(groupId: GroupId): Optional<GroupRole>;
  groupNameOf(groupId: GroupId): Optional<GroupName>;
  groupLimitationOf(groupId: GroupId): GroupLimitation;
  groupOf(groupId: GroupId): Optional<{
    id: GroupId;
    name: GroupName;
    users: Array<{ id: UserId; name: UserName; role: GroupRole }>;
  }>;
}

/**
 * アプリケーションコンテキストプロバイダ
 */
export interface AppContextProvider {
  get(): AppContext;
}

export const AppContextProviderKey = injectionKeyOf<AppContextProvider>({
  boundedContext: 'base',
  type: 'component',
  name: 'AppContextProvider',
});

export function useAppContextProvider(): AppContextProvider {
  return requiredInject(AppContextProviderKey);
}
