import {
  AuthorizationService,
  ContentId,
  ExamDataAdapter,
  ExamResult,
  GroupId,
  UserId,
} from '@/base/domains';
import { AbstractUseCase, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

export type GetExamResultsByTrainerRequest =
  | {
      groupId: GroupId;
      contentId: ContentId;
    }
  | {
      groupId: GroupId;
      userId: UserId;
    };

export type GetExamResultsByTrainerResponse = {
  examResults: Array<ExamResult>;
};

/**
 * トレーナーがテスト結果を取得する
 */
export interface GetExamResultsByTrainer
  extends UseCase<GetExamResultsByTrainerRequest, GetExamResultsByTrainerResponse> {
  execute(
    request: GetExamResultsByTrainerRequest
  ): Promise<UseCaseResponse<GetExamResultsByTrainerResponse>>;
}

export class GetExamResultsByTrainerImpl
  extends AbstractUseCase<GetExamResultsByTrainerRequest, GetExamResultsByTrainerResponse>
  implements GetExamResultsByTrainer
{
  private authorizationService: AuthorizationService;

  private examDataAdapter: ExamDataAdapter;

  constructor(authorizationService: AuthorizationService, examDataAdapter: ExamDataAdapter) {
    super('report.GetExamResultsByTrainer');
    this.authorizationService = authorizationService;
    this.examDataAdapter = examDataAdapter;
  }

  async internalExecute(
    request: GetExamResultsByTrainerRequest
  ): Promise<GetExamResultsByTrainerResponse> {
    const { groupId } = request;
    this.authorizationService.assertGroupReportAccessible(groupId);
    const examResults = await this.examDataAdapter.findExamResults(request);
    return {
      examResults,
    };
  }
}

export const GetExamResultsByTrainerKey = injectionKeyOf<GetExamResultsByTrainer>({
  boundedContext: 'report',
  type: 'usecase',
  name: 'GetExamResultsByTrainer',
});

export function useGetExamResultsByTrainer(): GetExamResultsByTrainer {
  return requiredInject(GetExamResultsByTrainerKey);
}
