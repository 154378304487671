import { ExamDataAdapter, GroupId, MyExamResult } from '@/base/domains';
import { AbstractUseCase, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

export type GetMyExamResultsRequest = {
  groupId: GroupId;
};

export type GetMyExamResultsResponse = {
  examResults: Array<MyExamResult>;
};

/**
 * 自身のテスト結果を取得する
 */
export interface GetMyExamResults
  extends UseCase<GetMyExamResultsRequest, GetMyExamResultsResponse> {
  execute(request: GetMyExamResultsRequest): Promise<UseCaseResponse<GetMyExamResultsResponse>>;
}

export class GetMyExamResultsImpl
  extends AbstractUseCase<GetMyExamResultsRequest, GetMyExamResultsResponse>
  implements GetMyExamResults
{
  private examDataAdapter: ExamDataAdapter;

  constructor(examDataAdapter: ExamDataAdapter) {
    super('report.GetMyExamResults');
    this.examDataAdapter = examDataAdapter;
  }

  async internalExecute(request: GetMyExamResultsRequest): Promise<GetMyExamResultsResponse> {
    const examResults = await this.examDataAdapter.findMyExamResults(request.groupId);
    return {
      examResults,
    };
  }
}

export const GetMyExamResultsKey = injectionKeyOf<GetMyExamResults>({
  boundedContext: 'report',
  type: 'usecase',
  name: 'GetMyExamResults',
});

export function useGetMyExamResults(): GetMyExamResults {
  return requiredInject(GetMyExamResultsKey);
}
