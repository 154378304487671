import {
  EntityData,
  GroupId,
  NextToken,
  QueryResponse,
  ScheduleId,
  ScheduleTag,
  ScheduleTagId,
  StandardRepository,
  UserId,
} from '@/base/domains';
import { LocalDateTime, MarkDownString } from '@/base/types';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

export interface ScheduleAttributes {
  groupId: GroupId;
  start: LocalDateTime;
  end?: LocalDateTime;
  title: string;
  body?: MarkDownString;
  createdBy?: UserId;
  updatedBy?: UserId;
  tags: Array<ScheduleTag>;
}

export type ScheduleData = EntityData<ScheduleId, ScheduleAttributes>;

export type ScheduleEntityUpdateArgs = {
  start: LocalDateTime;
  end?: LocalDateTime;
  title: string;
  body?: MarkDownString;
};

export interface ScheduleCommands {
  update(args: ScheduleEntityUpdateArgs): ScheduleEntity;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ScheduleQueries {}

export interface ScheduleReference extends Readonly<ScheduleAttributes>, ScheduleQueries {
  id: ScheduleId;
}

export interface ScheduleEntity extends ScheduleReference, ScheduleCommands {}

export type ScheduleRepositoryFindArgs = {
  limit?: number;
  groupId: GroupId;
  startFrom: LocalDateTime;
  startTo: LocalDateTime;
  nextToken?: NextToken;
};

export type ScheduleRepositoryFindRecentlyArgs =
  | {
      limit?: number;
      nextToken?: NextToken;
    } & (
      | {
          groupId: GroupId;
        }
      | {
          groupIds: Array<GroupId>;
        }
    );

export interface ScheduleRepository
  extends StandardRepository<ScheduleId, ScheduleAttributes, ScheduleEntity> {
  find(args: ScheduleRepositoryFindArgs): Promise<QueryResponse<Array<ScheduleReference>>>;
  findRecently(
    args: ScheduleRepositoryFindRecentlyArgs
  ): Promise<QueryResponse<Array<ScheduleReference>>>;
  addTag(id: ScheduleId, tagId: ScheduleTagId): Promise<ScheduleReference>;
  removeTag(id: ScheduleId, tagId: ScheduleTagId): Promise<ScheduleReference>;
}

export const ScheduleRepositoryKey = injectionKeyOf<ScheduleRepository>({
  boundedContext: 'training',
  type: 'adapter',
  name: 'ScheduleRepository',
});

export function useScheduleRepository(): ScheduleRepository {
  return requiredInject(ScheduleRepositoryKey);
}
