import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { TenantOwnTermsOfService, TenantOwnTermsOfServiceFinder } from '../domains';
import { AbstractUseCase, UseCase, UseCaseResponse } from './UseCase';

export type GetTenantOwnTermsOfServiceRequest = {};

export type GetTenantOwnTermsOfServiceResponse = {
  tenantOwnTermsOfService?: TenantOwnTermsOfService;
};

export interface GetTenantOwnTermsOfService
  extends UseCase<GetTenantOwnTermsOfServiceRequest, GetTenantOwnTermsOfServiceResponse> {
  execute(
    request: GetTenantOwnTermsOfServiceRequest
  ): Promise<UseCaseResponse<GetTenantOwnTermsOfServiceResponse>>;
}

export class GetTenantOwnTermsOfServiceImpl
  extends AbstractUseCase<GetTenantOwnTermsOfServiceRequest, GetTenantOwnTermsOfServiceResponse>
  implements GetTenantOwnTermsOfService
{
  constructor(private tenantOwnTermsOfServiceFinder: TenantOwnTermsOfServiceFinder) {
    super('base.GetTenantOwnTermsOfService');
  }

  async internalExecute(
    _: GetTenantOwnTermsOfServiceRequest
  ): Promise<GetTenantOwnTermsOfServiceResponse> {
    const res = await this.tenantOwnTermsOfServiceFinder.findTenantOwnTermsOfService();
    return { tenantOwnTermsOfService: res };
  }
}

export const GetTenantOwnTermsOfServiceKey = injectionKeyOf<GetTenantOwnTermsOfService>({
  boundedContext: 'base',
  type: 'usecase',
  name: 'GetTenantOwnTermsOfService',
});

export function useGetTenantOwnTermsOfService(): GetTenantOwnTermsOfService {
  return requiredInject(GetTenantOwnTermsOfServiceKey);
}
