import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { AuthService, FileStorage, SignedInUser } from '../domains';
import { AbstractUseCase, UseCase, UseCaseResponse } from './UseCase';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface GetCurrentUserWhenAppStartedRequest {}

export type GetCurrentUserWhenAppStartedResponse = {
  currentUser?: Omit<SignedInUser, 'groups'>;
};

export interface GetCurrentUserWhenAppStarted
  extends UseCase<GetCurrentUserWhenAppStartedRequest, GetCurrentUserWhenAppStartedResponse> {
  execute(
    request: GetCurrentUserWhenAppStartedRequest
  ): Promise<UseCaseResponse<GetCurrentUserWhenAppStartedResponse>>;
}

export class GetCurrentUserWhenAppStartedImpl
  extends AbstractUseCase<GetCurrentUserWhenAppStartedRequest, GetCurrentUserWhenAppStartedResponse>
  implements GetCurrentUserWhenAppStarted
{
  private authService: AuthService;

  private fileStorage: FileStorage;

  constructor(authService: AuthService, fileStorage: FileStorage) {
    super('base.GetCurrentUserWhenAppStarted');
    this.authService = authService;
    this.fileStorage = fileStorage;
  }

  async internalExecute(
    _: GetCurrentUserWhenAppStartedRequest
  ): Promise<GetCurrentUserWhenAppStartedResponse> {
    const currentUser = await this.authService.currentUser();
    if (currentUser) {
      if (!this.fileStorage.hasSignedCookie()) {
        await this.fileStorage.setSignedCookie();
      }
      return {
        currentUser,
      };
    }

    return {
      currentUser: undefined,
    };
  }
}

export const GetCurrentUserWhenAppStartedKey = injectionKeyOf<GetCurrentUserWhenAppStarted>({
  boundedContext: 'base',
  type: 'usecase',
  name: 'GetCurrentUserWhenAppStarted',
});

export function useGetCurrentUserWhenAppStarted(): GetCurrentUserWhenAppStarted {
  return requiredInject(GetCurrentUserWhenAppStartedKey);
}
