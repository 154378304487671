import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

export type EventId = string;
export type EventType = string;
export type EventVersion = number;
export type Event = {};

export interface EventEnvelopFactory<E extends Event> {
  create(event: E): EventEnvelop<E>;
}

export function eventEnvelopFactoryOf<E extends Event>({
  type,
  version,
}: {
  version: EventVersion;
  type: EventType;
}): (e: E) => EventEnvelop<E> {
  return (e: E) => ({
    type,
    version,
    event: e,
  });
}

export interface EventEnvelop<E extends Event> {
  type: EventType;
  event: E;
  version: EventVersion;
}

export interface EventStore {
  save<E extends Event>(eventEnvelop: EventEnvelop<E>): Promise<EventId>;
}

export const EventStoreKey = injectionKeyOf<EventStore>({
  boundedContext: 'base',
  type: 'adapter',
  name: 'EventStore',
});

export function useEventStore(): EventStore {
  return requiredInject(EventStoreKey);
}
