import { GroupTrainingData } from '@/training/domains';

export const groupTrainings: Array<GroupTrainingData> = [
  {
    id: 'gtId0',
    groupId: 'group00',
  },
  {
    id: 'gtId1',
    groupId: 'group01',
  },
];
