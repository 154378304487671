import { ModelQuestionFilterInput } from '@/API';
import {
  AppContextProvider,
  CommentId,
  ContentId,
  GroupId,
  Question,
  QuestionId,
  UserId,
} from '@/base/domains';
import { questionsByGroup, questionsByTenantCode } from '@/graphql/queries';
import { QuestionQueries } from '@/training/domains/queries';
import { graphqlQuery } from '@/utils/AmplifyUtils';
import { requiredNonNull } from '@/utils/TsUtils';

import { AmplifyQuestion, toQuestionEntity } from './AmplifyQuestionRepository';

export class AmplifyQuestionQueries implements QuestionQueries {
  constructor(private appContextProvider: AppContextProvider) {}

  async findRelatedQuestions(userId: UserId): Promise<Array<Question>> {
    const appContext = this.appContextProvider.get();
    const tenantCode = requiredNonNull(appContext.tenantCode, 'appContext.tenantCode');
    const groupIds = appContext.user?.groups.map((g) => g.id) ?? [];
    if (groupIds.length === 0) {
      return [];
    }
    // 現状ではAmplifyでArrayに対してクエリできない
    const filter: ModelQuestionFilterInput = {
      and: [
        {
          or: groupIds.map((groupId) => ({
            groupId: { eq: groupId },
          })),
        },
        {
          resolved: { eq: false },
        },
      ],
    };
    const res = await graphqlQuery<{ questionsByTenantCode: { items: Array<AmplifyQuestion> } }>(
      questionsByTenantCode,
      {
        tenantCode,
        filter,
      }
    );
    return res.questionsByTenantCode.items
      .map(toQuestionEntity)
      .filter((q) => q.createdBy === userId || q.assignees.includes(userId));
  }

  async findCommentHeaders({ groupId }: { groupId: GroupId }): Promise<
    Array<{
      id: CommentId;
      questionId: QuestionId;
      groupId: GroupId;
      contentId?: ContentId;
      createdBy: UserId;
    }>
  > {
    const res = await graphqlQuery<{ questionsByGroup: { items: Array<AmplifyQuestion> } }>(
      questionsByGroup,
      {
        groupId,
      }
    );
    const questions = res.questionsByGroup.items.map(toQuestionEntity);
    const comments = questions.flatMap((q) =>
      q.comments.map((cm) => ({
        id: cm.id,
        questionId: q.id,
        groupId: q.groupId,
        contentId: q.referTo?.contentId,
        createdBy: cm.createdBy,
      }))
    );
    return comments;
  }
}
