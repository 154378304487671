import { createLogger } from '@/utils/log';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { AuthorizationService, GroupId, GroupStatusService, Subscription } from '../domains';
import { GroupStatusUser } from '../domains/queries';
import { AbstractUseCase, UseCase, UseCaseResponse } from './UseCase';

const logger = createLogger({ boundedContext: 'base', name: 'SubscribeGroupStatusUserChanged' });

export type SubscribeGroupStatusUserChangedRequest = {
  groupId: GroupId;
  onChange: (groupStatusUser: GroupStatusUser, removed: boolean) => void;
  onError?: (e: Error) => void;
};

export type SubscribeGroupStatusUserChangedResponse = {
  subscription: Subscription;
};

export interface SubscribeGroupStatusUserChanged
  extends UseCase<SubscribeGroupStatusUserChangedRequest, SubscribeGroupStatusUserChangedResponse> {
  execute(
    request: SubscribeGroupStatusUserChangedRequest
  ): Promise<UseCaseResponse<SubscribeGroupStatusUserChangedResponse>>;
}

export class SubscribeGroupStatusUserChangedImpl
  extends AbstractUseCase<
    SubscribeGroupStatusUserChangedRequest,
    SubscribeGroupStatusUserChangedResponse
  >
  implements SubscribeGroupStatusUserChanged
{
  constructor(
    private authorizationService: AuthorizationService,
    private groupStatusService: GroupStatusService
  ) {
    super('base.SubscribeGroupStatusUserChanged');
  }

  async internalExecute(
    request: SubscribeGroupStatusUserChangedRequest
  ): Promise<SubscribeGroupStatusUserChangedResponse> {
    const {
      groupId,
      onChange,
      onError = (error) =>
        logger.debug({
          message: 'an error occurred at subscription on GroupStatusUserChanged',
          error,
        }),
    } = request;
    this.authorizationService.assertGroupReadAccessible(groupId);
    const subscription = this.groupStatusService.subscribeGroupStatusUserChanged({
      groupId,
      onChange,
      onError,
    });
    return {
      subscription,
    };
  }
}

export const SubscribeGroupStatusUserChangedKey = injectionKeyOf<SubscribeGroupStatusUserChanged>({
  boundedContext: 'base',
  type: 'usecase',
  name: 'SubscribeGroupStatusUserChanged',
});

export function useSubscribeGroupStatusUserChanged(): SubscribeGroupStatusUserChanged {
  return requiredInject(SubscribeGroupStatusUserChangedKey);
}
