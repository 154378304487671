import { hasNonNullProperty } from '@/utils/TsUtils';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { LocalDateTime, MarkDownString } from '../types';
import { ContentId, ContentVersion, ProblemIndex } from './content';
import { UserId } from './Core';
import { GroupId } from './Group';

export type QuestionId = string;
export type CommentId = string;

export type SimpleQuestionContentReference = {
  contentId: ContentId;
  contentVersion: ContentVersion;
};

// versionが異なる場合はマーカーを表示しない
export type TextQuestionContentReference = SimpleQuestionContentReference & {
  selection: object;
};

export type QuestionWorkbookReference = {
  contentId: ContentId;
  contentVersion: ContentVersion;
  problemIndex: ProblemIndex;
  selection?: object;
};

export type QuestionContentReference =
  | TextQuestionContentReference
  | SimpleQuestionContentReference
  | QuestionWorkbookReference;

export function isQuestionWorkbookReference(
  qcr: QuestionContentReference
): qcr is QuestionWorkbookReference {
  return hasNonNullProperty(qcr, 'problemIndex');
}

export function isTextQuestionContentReference(
  qcr: QuestionContentReference
): qcr is TextQuestionContentReference {
  return hasNonNullProperty(qcr, 'selection') && !hasNonNullProperty(qcr, 'problemIndex');
}

export function isSimpleQuestionContentReference(
  qcr: QuestionContentReference
): qcr is TextQuestionContentReference {
  return !isTextQuestionContentReference(qcr);
}

export type Comment = {
  readonly id: CommentId;
  readonly body: MarkDownString;
  readonly groupId: GroupId;
  readonly createdBy: UserId;
  readonly createdAt: LocalDateTime;
  readonly editedBy?: UserId;
  readonly editedAt?: LocalDateTime;
};

export type Question = {
  readonly id: QuestionId;
  readonly comments: Array<Comment>;
  readonly resolved: boolean;
  readonly resolvedBy?: UserId;
  readonly resolvedAt?: LocalDateTime;
  readonly groupId: GroupId;
  readonly title: string;
  readonly referTo?: QuestionContentReference;
  readonly createdBy: UserId;
  readonly createdAt: LocalDateTime;
  readonly assignees: Array<UserId>;
};

export type QuestionFinderFindArgs = {
  groupId: GroupId;
  resolved?: boolean;
};

export interface QuestionFinder {
  find(
    args: QuestionFinderFindArgs
  ): Promise<{ questions: Array<Question>; limitExceeded: boolean }>;
  findRelatedQuestions(userId: UserId): Promise<Array<Question>>;
  findCommentHeaders(args: { groupId: GroupId }): Promise<
    Array<{
      id: CommentId;
      questionId: QuestionId;
      groupId: GroupId;
      contentId?: ContentId;
      createdBy: UserId;
    }>
  >;
}

export const QuestionFinderKey = injectionKeyOf<QuestionFinder>({
  boundedContext: 'base',
  type: 'service',
  name: 'QuestionFinder',
});

export function useQuestionFinder(): QuestionFinder {
  return requiredInject(QuestionFinderKey);
}
