import { v4 } from 'uuid';

import { CourseId, EntityData, GroupId, GroupTrainingId, hasId } from '@/base/domains';
import { Optional } from '@/base/types';
import {
  GroupTrainingAttributes,
  GroupTrainingEntity,
  GroupTrainingEntityImpl,
  GroupTrainingRepository,
} from '@/training/domains';

export class InMemoryGroupTrainingRepository implements GroupTrainingRepository {
  private store = new Map<CourseId, GroupTrainingEntity>();

  save(
    args: GroupTrainingEntity | EntityData<string, GroupTrainingAttributes>
  ): Promise<GroupTrainingEntity> {
    if (hasId(args)) {
      const e = new GroupTrainingEntityImpl({
        ...args,
      });
      this.store.set(e.id, e);
      return Promise.resolve(e);
    }
    const e = new GroupTrainingEntityImpl({
      ...args,
      id: v4(),
    });
    this.store.set(e.id, e);
    return Promise.resolve(e);
  }

  findById(id: GroupTrainingId): Promise<Optional<GroupTrainingEntity>> {
    return Promise.resolve(this.store.get(id));
  }

  remove(id: GroupTrainingId): Promise<void> {
    Promise.resolve(this.store.delete(id));
    return Promise.resolve();
  }

  findByGroupId(groupId: GroupId): Promise<Optional<GroupTrainingEntity>> {
    return Promise.resolve(Array.from(this.store.values()).find((gt) => gt.groupId === groupId));
  }

  findTenantGroupTrainings(): Promise<Array<GroupTrainingEntity>> {
    return Promise.resolve(Array.from(this.store.values()));
  }
}
