import { AuthorizationService, CourseId, CourseName } from '@/base/domains';
import { AbstractUseCase, assertEntityExists, UseCase, UseCaseResponse } from '@/base/usecases';
import { hasNonNullProperty } from '@/utils/TsUtils';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { CourseRepository } from '../domains';
import {
  CourseHeaderData,
  CourseHeaderReference,
  CourseHeaderRepository,
} from '../domains/CourseHeader';

export type CreateNewCourseRequest =
  | {
      name: CourseName;
    }
  | {
      name: CourseName;
      originalCourseId: CourseId;
    };

function isCreateNewCourseAsCopyRequest(request: CreateNewCourseRequest): request is {
  name: CourseName;
  originalCourseId: CourseId;
} {
  return hasNonNullProperty(request, 'originalCourseId');
}

export type CreateNewCourseResponse = {
  course: CourseHeaderReference;
};

/**
 * 新しいコースを作成する
 */
export interface CreateNewCourse extends UseCase<CreateNewCourseRequest, CreateNewCourseResponse> {
  execute(request: CreateNewCourseRequest): Promise<UseCaseResponse<CreateNewCourseResponse>>;
}

export class CreateNewCourseImpl
  extends AbstractUseCase<CreateNewCourseRequest, CreateNewCourseResponse>
  implements CreateNewCourse
{
  constructor(
    private authorizationRepository: AuthorizationService,
    private courseHeaderRepository: CourseHeaderRepository,
    private courseRepository: CourseRepository
  ) {
    super('contents.CreateNewCourse');
    this.authorizationRepository = authorizationRepository;
    this.courseHeaderRepository = courseHeaderRepository;
    this.courseRepository = courseRepository;
  }

  async internalExecute(request: CreateNewCourseRequest): Promise<CreateNewCourseResponse> {
    const { name } = request;
    this.authorizationRepository.assertContentEditable();
    if (isCreateNewCourseAsCopyRequest(request)) {
      const { originalCourseId } = request;
      const originalCourseVersion = await this.courseRepository.findLatestVersion(originalCourseId);
      assertEntityExists(originalCourseVersion, 'course');
      const entity: CourseHeaderData = {
        name,
        status: 'editing',
        activeVersion: 1,
        originalCourseId,
      };
      const course = await this.courseHeaderRepository.save(entity);
      return {
        course,
      };
    }
    const entity: CourseHeaderData = {
      name,
      status: 'editing',
      activeVersion: 1,
    };
    const course = await this.courseHeaderRepository.save(entity);
    return {
      course,
    };
  }
}

export const CreateNewCourseKey = injectionKeyOf<CreateNewCourse>({
  boundedContext: 'contents',
  type: 'usecase',
  name: 'CreateNewCourse',
});

export function useCreateNewCourse(): CreateNewCourse {
  return requiredInject(CreateNewCourseKey);
}
