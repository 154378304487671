import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { LocalDateTime, Minute, Optional, URI } from '../types';
import {
  ContentId,
  ContentName,
  ContentVersion,
  CourseDisplayName,
  CourseId,
  CourseVersion,
  Problem,
  ProblemHeader,
  ProblemIndex,
} from './content';
import { UserId } from './Core';
import { CourseColor, CourseFontColorOnImage } from './Course';
import { GroupId } from './Group';

/** 試験実施に対するID */
export type ExamId = string;
export type GroupExamId = string;

/**
 * GroupExamIdを作成する
 *
 * @param args グループID, コンテントID, 回数
 * @returns グループテストID
 */
export function createGroupExamId(args: {
  groupId: GroupId;
  contentId: ContentId;
  times: number;
}): GroupExamId {
  return `${args.groupId}_${args.contentId}_${`000${args.times}`.slice(-3)}`;
}

export type UserExamStatus = 'not_started' | 'in_progress' | 'finished' | 'skipped';
export type GroupExamStatus = 'not_scheduled' | 'announced' | 'in_progress' | 'finished';
export type ExamResultVisibilityLevel = 'invisible_to_user' | 'details' | 'score';

export type GroupExamProblem = Omit<Problem, 'answer' | 'commentary'>;

export type GroupExamContent = {
  id: ContentId;
  version: ContentVersion;
  name: ContentName;
  requiredTime?: Minute;
  indexInCourse?: number;
  problems: Array<GroupExamProblem>;
  problemHeaders: Array<ProblemHeader>;
  passingStandard?: number;
};

export type GroupExamCourse = {
  id: CourseId;
  name: CourseDisplayName;
  version: CourseVersion;
  color?: CourseColor;
  image?: URI;
  fontColorOnImage?: CourseFontColorOnImage;
};

export type UserExam = {
  id: ExamId;
  userId: UserId;
  status: UserExamStatus;
  startedAt?: LocalDateTime;
  finishedAt?: LocalDateTime;
};

export type GroupExam = {
  id: GroupExamId;
  groupId: GroupId;
  content: GroupExamContent;
  course: GroupExamCourse;
  scheduledStart: LocalDateTime;
  scheduledFinish?: LocalDateTime;
  finishedAt?: LocalDateTime;
  status: GroupExamStatus;
  visibilityLevel: ExamResultVisibilityLevel;
  userExams: Array<UserExam>;
  times: number;
  timeLimit?: Minute;
  passingStandard?: number;
  userIdsToBeTested: UserId[];
};

export type ActiveExam = {
  groupId: GroupId;
  contentId: ContentId;
  courseId: CourseId;
  examId: ExamId;
  startedAt: LocalDateTime;
  visibilityLevel: ExamResultVisibilityLevel;
};

export type ExamResultAnswer = {
  index: ProblemIndex;
  values: Array<number | string>;
  correct: boolean;
};

type IsPassedAndStandard = {
  passingStandard?: number;
  isPassed?: boolean;
};

type IsPassed = {
  isPassed?: boolean;
};

export type ExamResult =
  | {
      id: ExamId;
      start: LocalDateTime;
      end: LocalDateTime;
      contentId: ContentId;
      userId: UserId;
      groupId: GroupId;
      courseId: CourseId;
      score: number;
      problemCount: number;
      answers: Array<ExamResultAnswer>;
      visibilityLevel: 'details';
      version: 1;
    }
  | {
      id: ExamId;
      start: LocalDateTime;
      end: LocalDateTime;
      contentId: ContentId;
      userId: UserId;
      groupId: GroupId;
      courseId: CourseId;
      score: number;
      problemCount: number;
      answers: Array<ExamResultAnswer>;
      visibilityLevel: ExamResultVisibilityLevel;
      times: number;
      groupExamId: GroupExamId;
      version: 2;
    }
  | ({
      id: ExamId;
      start: LocalDateTime;
      end: LocalDateTime;
      contentId: ContentId;
      contentVersion: ContentVersion;
      userId: UserId;
      groupId: GroupId;
      courseId: CourseId;
      score: number;
      problemCount: number;
      answers: Array<ExamResultAnswer>;
      visibilityLevel: ExamResultVisibilityLevel;
      times: number;
      groupExamId: GroupExamId;
      version: 3;
    } & IsPassedAndStandard);

export type MyExamResultVersion1 = {
  id: ExamId;
  start: LocalDateTime;
  end: LocalDateTime;
  contentId: ContentId;
  userId: UserId;
  groupId: GroupId;
  courseId: CourseId;
  score: number;
  problemCount: number;
  answers: Array<ExamResultAnswer>;
  visibilityLevel: 'details';
  version: 1;
};

export type MyExamResultVersion2 =
  | {
      id: ExamId;
      start: LocalDateTime;
      end: LocalDateTime;
      contentId: ContentId;
      userId: UserId;
      groupId: GroupId;
      courseId: CourseId;
      score: number;
      problemCount: number;
      answers: Array<ExamResultAnswer>;
      visibilityLevel: 'details';
      times: number;
      groupExamId: GroupExamId;
      version: 2;
    }
  | {
      id: ExamId;
      start: LocalDateTime;
      end: LocalDateTime;
      contentId: ContentId;
      userId: UserId;
      groupId: GroupId;
      courseId: CourseId;
      problemCount: number;
      visibilityLevel: 'invisible_to_user';
      times: number;
      groupExamId: GroupExamId;
      version: 2;
    }
  | {
      id: ExamId;
      start: LocalDateTime;
      end: LocalDateTime;
      contentId: ContentId;
      userId: UserId;
      groupId: GroupId;
      courseId: CourseId;
      score: number;
      problemCount: number;
      visibilityLevel: 'score';
      times: number;
      groupExamId: GroupExamId;
      version: 2;
    };

export type MyExamResultVersion3 =
  | ({
      id: ExamId;
      start: LocalDateTime;
      end: LocalDateTime;
      contentId: ContentId;
      contentVersion: ContentVersion;
      userId: UserId;
      groupId: GroupId;
      courseId: CourseId;
      score: number;
      problemCount: number;
      answers: Array<ExamResultAnswer>;
      visibilityLevel: 'details';
      times: number;
      groupExamId: GroupExamId;
      version: 3;
    } & IsPassedAndStandard)
  | ({
      id: ExamId;
      start: LocalDateTime;
      end: LocalDateTime;
      contentId: ContentId;
      contentVersion: ContentVersion;
      userId: UserId;
      groupId: GroupId;
      courseId: CourseId;
      problemCount: number;
      visibilityLevel: 'invisible_to_user';
      times: number;
      groupExamId: GroupExamId;
      version: 3;
    } & IsPassed)
  | ({
      id: ExamId;
      start: LocalDateTime;
      end: LocalDateTime;
      contentId: ContentId;
      contentVersion: ContentVersion;
      userId: UserId;
      groupId: GroupId;
      courseId: CourseId;
      score: number;
      problemCount: number;
      visibilityLevel: 'score';
      times: number;
      groupExamId: GroupExamId;
      version: 3;
    } & IsPassedAndStandard);

export type MyExamResult = MyExamResultVersion1 | MyExamResultVersion2 | MyExamResultVersion3;

export type ExamDataAdapterFindExamResultsArgs =
  | {
      groupId: GroupId;
      contentId: ContentId;
    }
  | {
      groupId: GroupId;
      userId: UserId;
    }
  | {
      groupId: GroupId;
      courseId: CourseId;
    };

export type ExamDataAdapterFindNotFinishedUserExam = UserExam & {
  groupExam: Omit<GroupExam, 'userExams' | 'content'> & {
    content: Omit<GroupExamContent, 'problems' | 'problemHeaders'>;
  };
};
export interface ExamDataAdapter {
  findActiveExam(): Promise<Optional<ActiveExam>>;
  findExamResults(args: ExamDataAdapterFindExamResultsArgs): Promise<Array<ExamResult>>;
  findExamResultsByGroupExamId(groupExamId: GroupExamId): Promise<Array<ExamResult>>;
  findMyExamResults(groupId: GroupId): Promise<Array<MyExamResult>>;
  findExamResultById(id: ExamId): Promise<Optional<ExamResult>>;
  findMyExamResultById(id: ExamId): Promise<Optional<MyExamResult>>;
  findUserExam(
    examId: ExamId
  ): Promise<Optional<UserExam & { groupExam: Omit<GroupExam, 'userExams'> }>>;
  findNotFinishedUserExams(args: {
    groupId?: GroupId;
    userId: UserId;
  }): Promise<Array<ExamDataAdapterFindNotFinishedUserExam>>;
  findGroupExamsByGroupId(
    groupId: GroupId,
    options?: { inProgress: boolean } | { inProgressOrScheduled: boolean }
  ): Promise<Array<GroupExam>>;
  findGroupExamsByGroupIdAndCourseId(
    groupId: GroupId,
    courseId: CourseId
  ): Promise<Array<GroupExam>>;
  findGroupExamById(groupExamId: GroupExamId): Promise<Optional<GroupExam>>;
  findOpenGroupExamsByCourseId(courseId: CourseId, groupId?: GroupId): Promise<Array<GroupExam>>;
}

export const ExamDataAdapterKey = injectionKeyOf<ExamDataAdapter>({
  boundedContext: 'base',
  type: 'adapter',
  name: 'ExamDataAdapter',
});

export function useExamDataAdapter(): ExamDataAdapter {
  return requiredInject(ExamDataAdapterKey);
}
