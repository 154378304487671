import { AuthorizationService, GroupId } from '@/base/domains';
import { AbstractUseCase, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { ReportService, UserCourseProgressRecord } from '../domains';

export interface GetUserCourseProgressReportRequest {
  groupId: GroupId;
}

export type GetUserCourseProgressReportResponse = {
  records: Array<UserCourseProgressRecord>;
};

/**
 * ユーザーコース進捗レポートを取得する
 */
export interface GetUserCourseProgressReport
  extends UseCase<GetUserCourseProgressReportRequest, GetUserCourseProgressReportResponse> {
  execute(
    request: GetUserCourseProgressReportRequest
  ): Promise<UseCaseResponse<GetUserCourseProgressReportResponse>>;
}

export class GetUserCourseProgressReportImpl
  extends AbstractUseCase<GetUserCourseProgressReportRequest, GetUserCourseProgressReportResponse>
  implements GetUserCourseProgressReport
{
  private authorizationService: AuthorizationService;

  private reportService: ReportService;

  constructor(authorizationService: AuthorizationService, reportService: ReportService) {
    super('report.GetUserCourseProgressReport');
    this.authorizationService = authorizationService;
    this.reportService = reportService;
  }

  async internalExecute(
    request: GetUserCourseProgressReportRequest
  ): Promise<GetUserCourseProgressReportResponse> {
    const { groupId } = request;
    this.authorizationService.assertGroupReadAccessible(groupId);
    const records = await this.reportService.findUserCourseProgressRecords(groupId);
    return {
      records,
    };
  }
}

export const GetUserCourseProgressReportKey = injectionKeyOf<GetUserCourseProgressReport>({
  boundedContext: 'report',
  type: 'usecase',
  name: 'GetUserCourseProgressReport',
});

export function useGetUserCourseProgressReport(): GetUserCourseProgressReport {
  return requiredInject(GetUserCourseProgressReportKey);
}
