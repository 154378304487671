import { hasProperty } from '@/utils/TsUtils';

import { LocalDateTime, Optional } from '../types';
import { DataVersion } from './Base';
import { UserId } from './Core';

export type EntityLike<I, A> = A & { id: I };

// Vueで利用することを前提として全ての項目をreadonlyとする
export type Entity<I, A> = Readonly<EntityLike<I, A>>;

export type EntityData<I, A> = Omit<
  A,
  'id' | 'createdAt' | 'updatedAt' | 'dataVersion' | 'createdBy' | 'updatedBy'
> & {
  id?: I;
  createdAt?: LocalDateTime;
  updatedAt?: LocalDateTime;
  dataVersion?: DataVersion;
  createdBy?: UserId;
  updatedBy?: UserId;
};

export type EntityReference<I, A, Q> = Readonly<A> &
  Q & {
    readonly id: I;
  };

export interface Repository<I, E, D = unknown> {
  save(args: D): Promise<E>;

  findById(id: I): Promise<Optional<E>>;

  remove(id: I): Promise<void>;
}

export type StandardRepository<I, A, E extends Entity<I, A>> = Repository<
  I,
  E,
  EntityData<I, A> | E
>;

export type AggregateRootRepository<I, D, E> = Repository<I, E, D | E>;

export function isEntityLike<T extends EntityLike<unknown, unknown>>(arg: unknown): arg is T {
  return arg instanceof Object && 'id' in arg;
}

export type HasId<E, I> = Omit<E, 'id'> & { id: I };

export function hasId<X extends {}, I extends string = string>(x: X): x is X & { id: I } {
  return hasProperty(x, 'id');
}
