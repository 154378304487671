import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import {
  AuthorizationService,
  FileStorage,
  localEventBus,
  User,
  UserId,
  UserRepository,
} from '../domains';
import { userAvatarChanged } from '../domains/LocalEvents';
import { AbstractUseCase, UseCase, UseCaseResponse } from './UseCase';
import { assertEntityExists } from './UseCaseAsserts';

export interface ChangeUserAvatarRequest {
  id: UserId;
  file: Blob;
}

export type ChangeUserAvatarResponse = {
  user: User;
};

/**
 * アバターを変更する
 */
export interface ChangeUserAvatar
  extends UseCase<ChangeUserAvatarRequest, ChangeUserAvatarResponse> {
  execute(request: ChangeUserAvatarRequest): Promise<UseCaseResponse<ChangeUserAvatarResponse>>;
}

export class ChangeUserAvatarImpl
  extends AbstractUseCase<ChangeUserAvatarRequest, ChangeUserAvatarResponse>
  implements ChangeUserAvatar
{
  private authorizationService: AuthorizationService;

  private fileStorage: FileStorage;

  private userRepository: UserRepository;

  constructor(
    authorizationService: AuthorizationService,
    fileStorage: FileStorage,
    userRepository: UserRepository
  ) {
    super('base.ChangeUserAvatar');
    this.authorizationService = authorizationService;
    this.fileStorage = fileStorage;
    this.userRepository = userRepository;
  }

  async internalExecute(request: ChangeUserAvatarRequest): Promise<ChangeUserAvatarResponse> {
    const { id, file } = request;
    this.authorizationService.assertOwnerAccessible(id);
    const user = await this.userRepository.findById(id);
    assertEntityExists(user, 'user');
    const avatar = await this.fileStorage.upload({
      file,
      filename: 'avatar.jpg',
    });
    const saved = await this.userRepository.save(user.changeAvatar(avatar));
    localEventBus.publish(userAvatarChanged({ userId: user.id }));
    return {
      user: saved,
    };
  }
}

export const ChangeUserAvatarKey = injectionKeyOf<ChangeUserAvatar>({
  boundedContext: 'base',
  type: 'usecase',
  name: 'ChangeUserAvatar',
});

export function useChangeUserAvatar(): ChangeUserAvatar {
  return requiredInject(ChangeUserAvatarKey);
}
