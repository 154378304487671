import { isString } from '../TsUtils';
import { LevelLessLogEntry, LogEntry, Logger, LogLevel, LogLevels, Transporter } from './core';

type LoggerOptions = {
  transporters: Array<Transporter>;
  defaultMeta?: object;
};

function canLog(messageLogLevel: LogLevel, contextLogLevel: LogLevel): boolean {
  const m = LogLevels[messageLogLevel];
  const c = LogLevels[contextLogLevel];
  return m >= c;
}

function doLog(entry: LogEntry, transporter: Transporter) {
  if (canLog(entry.level, transporter.level)) {
    transporter.log(entry);
  }
}

export class LoggerImpl implements Logger {
  private transporters: Array<Transporter>;

  private defaultMeta: object;

  constructor(options: LoggerOptions) {
    this.transporters = options.transporters;
    this.defaultMeta = options.defaultMeta ?? {};
  }

  log(entry: LogEntry): void {
    const e = {
      ...this.defaultMeta,
      ...entry,
    };
    this.transporters.forEach((t) => doLog(e, t));
  }

  debug(entry: LevelLessLogEntry | string): void {
    const e = isString(entry) ? { message: entry } : entry;
    this.log({
      level: 'debug',
      ...e,
    });
  }

  info(entry: LevelLessLogEntry | string): void {
    const e = isString(entry) ? { message: entry } : entry;
    this.log({
      level: 'info',
      ...e,
    });
  }

  warn(entry: LevelLessLogEntry | string): void {
    const e = isString(entry) ? { message: entry } : entry;
    this.log({
      level: 'warn',
      ...e,
    });
  }

  error(entry: LevelLessLogEntry | string): void {
    const e = isString(entry) ? { message: entry } : entry;
    this.log({
      level: 'error',
      ...e,
    });
  }
}
