import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { LocalDateTime } from '../types';

export interface DateTimeService {
  strictLocalDateTimeNow(): Promise<LocalDateTime>;
}

export const DateTimeServiceKey = injectionKeyOf<DateTimeService>({
  boundedContext: 'base',
  type: 'service',
  name: 'DateTimeService',
});

export function useDateTimeService(): DateTimeService {
  return requiredInject(DateTimeServiceKey);
}
