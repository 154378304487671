import {
  useAppContextProvider,
  useAuthorizationService,
  useContentFinder,
  useContentLearningDataAdapter,
  useCourseFinder,
  useDateTimeService,
  useEventStore,
  useExamDataAdapter,
  useGroupTrainingFinder,
} from '@/base/domains';
import {
  AnswerQuestionnaireKey,
  CreateQuestionnaireKey,
  FinishGroupExamKey,
  FinishQuestionnaireKey,
  GetGroupExamsKey,
  GetQuestionnaireByAnswererKey,
  GetQuestionnaireKey,
  GetQuestionnairesKey,
  RemoveQuestionnaireKey,
  SubscribeQuestionnaireStatusChangedKey,
} from '@/base/usecases';
import {
  useGroupExamRepository,
  useGroupTrainingCourseRepository,
  useGroupTrainingRepository,
  useMemoRepository,
  useQuestionnaireRepository,
  useQuestionRepository,
  useReviewProblemRepository,
  useScheduleRepository,
  useScheduleTagRepository,
} from '@/training/domains';
import {
  AddCommentToQuestionImpl,
  AddCommentToQuestionKey,
  AddGroupCourseImpl,
  AddGroupCourseKey,
  AddGroupExamImpl,
  AddGroupExamKey,
  AddTagToScheduleImpl,
  AddTagToScheduleKey,
  AskQuestionImpl,
  AskQuestionKey,
  ChangeGroupCourseDisplayNameImpl,
  ChangeGroupCourseDisplayNameKey,
  ChangeGroupExamScheduledTimeImpl,
  ChangeGroupExamScheduledTimeKey,
  ChangeGroupTrainingCourseContentVersionImpl,
  ChangeGroupTrainingCourseContentVersionKey,
  ChangeGroupTrainingCoursesSortImpl,
  ChangeGroupTrainingCoursesSortKey,
  ChangeQuestionAssigneesImpl,
  ChangeQuestionAssigneesKey,
  ChangeQuestionTitleImpl,
  ChangeQuestionTitleKey,
  ChangeScopeRolesOfMemoImpl,
  ChangeScopeRolesOfMemoKey,
  CloseGroupContentImpl,
  CloseGroupContentKey,
  CreateMemoImpl,
  CreateMemoKey,
  CreateScheduleImpl,
  CreateScheduleKey,
  CreateScheduleTagImpl,
  CreateScheduleTagKey,
  ExitContentImpl,
  ExitContentKey,
  FinishContentImpl,
  FinishContentKey,
  FinishExamImpl,
  FinishExamKey,
  FinishGroupExamImpl,
  FinishReviewProblemImpl,
  FinishReviewProblemKey,
  GetContentImpl,
  GetContentKey,
  GetContentsImpl,
  GetContentsKey,
  GetCourseByTrainerImpl,
  GetCourseByTrainerKey,
  GetCoursesByTrainerImpl,
  GetCoursesByTrainerKey,
  GetGroupExamsImpl,
  GetGroupTrainingCourseImpl,
  GetGroupTrainingCourseKey,
  GetGroupTrainingImpl,
  GetGroupTrainingKey,
  GetMemoImpl,
  GetMemoKey,
  GetMyExamImpl,
  GetMyExamKey,
  GetQuestionImpl,
  GetQuestionKey,
  GetQuestionnairesByAnswererImpl,
  GetQuestionnairesByAnswererKey,
  GetQuestionsImpl,
  GetQuestionsKey,
  GetReviewProblemImpl,
  GetReviewProblemKey,
  GetSchedulesImpl,
  GetSchedulesKey,
  OpenContentImpl,
  OpenContentKey,
  OpenGroupContentImpl,
  OpenGroupContentKey,
  RemoveGroupCourseImpl,
  RemoveGroupCourseKey,
  RemoveGroupExamScheduleImpl,
  RemoveGroupExamScheduleKey,
  RemoveMemoImpl,
  RemoveMemoKey,
  RemoveReviewProblemProblemsImpl,
  RemoveReviewProblemProblemsKey,
  RemoveScheduleImpl,
  RemoveScheduleKey,
  RemoveScheduleTagImpl,
  RemoveScheduleTagKey,
  RemoveTagFromScheduleImpl,
  RemoveTagFromScheduleKey,
  ResolveQuestionImpl,
  ResolveQuestionKey,
  StartExamImpl,
  StartExamKey,
  SubscribeQuestionnaireStatusChangedImpl,
  UpdateMemoBodyImpl,
  UpdateMemoBodyKey,
  UpdateScheduleImpl,
  UpdateScheduleKey,
  UpdateScheduleTagImpl,
  UpdateScheduleTagKey,
} from '@/training/usecases';
import { AnswerQuestionnaireImpl } from '@/training/usecases/AnswerQuestionnaireImpl';
import {
  ChangeCommentInQuestionImpl,
  ChangeCommentInQuestionKey,
} from '@/training/usecases/ChangeCommentOnQuestion';
import { CreateQuestionnaireImpl } from '@/training/usecases/CreateQuestionnaireImpl';
import { FinishQuestionnaireImpl } from '@/training/usecases/FinishQuestionnaireImpl';
import { GetQuestionnaireByAnswererImpl } from '@/training/usecases/GetQuestionnaireByAnswererImpl';
import { GetQuestionnaireImpl } from '@/training/usecases/GetQuestionnaireImpl';
import { GetQuestionnairesImpl } from '@/training/usecases/GetQuestionnairesImpl';
import { RemoveQuestionnaireImpl } from '@/training/usecases/RemoveQuestionnaireImpl';
import { provide } from '@/utils/VueUtils';

export function useTrainingUseCase() {
  const contentFinder = useContentFinder();
  const courseFinder = useCourseFinder();
  const appContextProvider = useAppContextProvider();
  const groupTrainingRepository = useGroupTrainingRepository();
  const groupTrainingCourseRepository = useGroupTrainingCourseRepository();
  const questionRepository = useQuestionRepository();
  const questionnaireRepository = useQuestionnaireRepository();
  const scheduleRepository = useScheduleRepository();
  const eventStore = useEventStore();
  const authorizationService = useAuthorizationService();
  const groupTrainingFinder = useGroupTrainingFinder();
  const contentLearningDataAdapter = useContentLearningDataAdapter();
  const examDataAdapter = useExamDataAdapter();
  const scheduleTagRepository = useScheduleTagRepository();
  const groupExamRepository = useGroupExamRepository();
  const dateTimeService = useDateTimeService();
  const memoRepository = useMemoRepository();
  const reviewProblemRepository = useReviewProblemRepository();

  // training
  provide(OpenContentKey, new OpenContentImpl(eventStore));
  provide(ExitContentKey, new ExitContentImpl(eventStore));
  provide(
    FinishContentKey,
    new FinishContentImpl(eventStore, reviewProblemRepository, appContextProvider)
  );
  provide(AskQuestionKey, new AskQuestionImpl(appContextProvider, questionRepository));
  provide(
    AddCommentToQuestionKey,
    new AddCommentToQuestionImpl(appContextProvider, questionRepository)
  );
  provide(
    ChangeCommentInQuestionKey,
    new ChangeCommentInQuestionImpl(authorizationService, questionRepository, dateTimeService)
  );
  provide(
    ChangeQuestionAssigneesKey,
    new ChangeQuestionAssigneesImpl(questionRepository, authorizationService)
  );
  provide(
    ResolveQuestionKey,
    new ResolveQuestionImpl(appContextProvider, questionRepository, dateTimeService)
  );
  provide(GetQuestionKey, new GetQuestionImpl(questionRepository, groupTrainingFinder));
  provide(GetQuestionsKey, new GetQuestionsImpl(questionRepository, groupTrainingFinder));
  provide(
    CreateScheduleKey,
    new CreateScheduleImpl(authorizationService, scheduleRepository, scheduleTagRepository)
  );
  provide(
    UpdateScheduleKey,
    new UpdateScheduleImpl(scheduleRepository, scheduleTagRepository, authorizationService)
  );
  provide(RemoveScheduleKey, new RemoveScheduleImpl(scheduleRepository, authorizationService));
  provide(GetSchedulesKey, new GetSchedulesImpl(scheduleRepository, authorizationService));
  provide(StartExamKey, new StartExamImpl(eventStore, examDataAdapter, authorizationService));
  provide(FinishExamKey, new FinishExamImpl(eventStore, examDataAdapter, contentFinder));
  provide(
    GetContentKey,
    new GetContentImpl(
      authorizationService,
      groupTrainingFinder,
      contentFinder,
      contentLearningDataAdapter,
      appContextProvider,
      memoRepository,
      reviewProblemRepository
    )
  );
  provide(GetContentsKey, new GetContentsImpl(contentFinder));
  provide(
    GetGroupTrainingKey,
    new GetGroupTrainingImpl(groupTrainingRepository, groupTrainingCourseRepository, contentFinder)
  );
  provide(
    AddGroupCourseKey,
    new AddGroupCourseImpl(
      groupTrainingRepository,
      groupTrainingCourseRepository,
      courseFinder,
      contentFinder
    )
  );
  provide(
    RemoveGroupCourseKey,
    new RemoveGroupCourseImpl(authorizationService, groupTrainingCourseRepository, examDataAdapter)
  );
  provide(GetCoursesByTrainerKey, new GetCoursesByTrainerImpl(authorizationService, courseFinder));
  provide(
    CreateScheduleTagKey,
    new CreateScheduleTagImpl(authorizationService, scheduleTagRepository)
  );
  provide(
    UpdateScheduleTagKey,
    new UpdateScheduleTagImpl(authorizationService, scheduleTagRepository)
  );
  provide(
    RemoveScheduleTagKey,
    new RemoveScheduleTagImpl(authorizationService, scheduleTagRepository)
  );
  provide(
    AddTagToScheduleKey,
    new AddTagToScheduleImpl(authorizationService, scheduleRepository, scheduleTagRepository)
  );
  provide(
    RemoveTagFromScheduleKey,
    new RemoveTagFromScheduleImpl(authorizationService, scheduleRepository)
  );
  provide(GetCourseByTrainerKey, new GetCourseByTrainerImpl(authorizationService, courseFinder));
  provide(
    GetGroupTrainingCourseKey,
    new GetGroupTrainingCourseImpl(
      authorizationService,
      groupTrainingCourseRepository,
      contentFinder
    )
  );
  provide(GetMyExamKey, new GetMyExamImpl(examDataAdapter, dateTimeService));
  provide(
    AddGroupExamKey,
    new AddGroupExamImpl(
      authorizationService,
      groupExamRepository,
      groupTrainingCourseRepository,
      contentFinder,
      dateTimeService,
      groupTrainingFinder,
      appContextProvider
    )
  );
  provide(
    OpenGroupContentKey,
    new OpenGroupContentImpl(authorizationService, groupTrainingCourseRepository)
  );
  provide(
    CloseGroupContentKey,
    new CloseGroupContentImpl(authorizationService, groupTrainingCourseRepository)
  );
  provide(
    FinishGroupExamKey,
    new FinishGroupExamImpl(authorizationService, groupExamRepository, dateTimeService)
  );
  provide(
    GetGroupExamsKey,
    new GetGroupExamsImpl(
      authorizationService,
      groupExamRepository,
      groupTrainingFinder,
      examDataAdapter,
      contentFinder
    )
  );
  provide(
    ChangeQuestionTitleKey,
    new ChangeQuestionTitleImpl(appContextProvider, questionRepository)
  );
  provide(
    ChangeGroupCourseDisplayNameKey,
    new ChangeGroupCourseDisplayNameImpl(authorizationService, groupTrainingCourseRepository)
  );
  provide(
    ChangeGroupTrainingCourseContentVersionKey,
    new ChangeGroupTrainingCourseContentVersionImpl(
      authorizationService,
      groupTrainingCourseRepository,
      contentFinder
    )
  );
  provide(
    AnswerQuestionnaireKey,
    new AnswerQuestionnaireImpl(appContextProvider, questionnaireRepository)
  );
  provide(
    CreateQuestionnaireKey,
    new CreateQuestionnaireImpl(authorizationService, questionnaireRepository)
  );
  provide(
    FinishQuestionnaireKey,
    new FinishQuestionnaireImpl(authorizationService, questionnaireRepository, dateTimeService)
  );
  provide(
    GetQuestionnaireKey,
    new GetQuestionnaireImpl(appContextProvider, authorizationService, questionnaireRepository)
  );
  provide(
    GetQuestionnaireByAnswererKey,
    new GetQuestionnaireByAnswererImpl(
      appContextProvider,
      authorizationService,
      questionnaireRepository
    )
  );
  provide(
    GetQuestionnairesKey,
    new GetQuestionnairesImpl(appContextProvider, authorizationService, questionnaireRepository)
  );
  provide(
    RemoveQuestionnaireKey,
    new RemoveQuestionnaireImpl(authorizationService, questionnaireRepository)
  );
  provide(
    SubscribeQuestionnaireStatusChangedKey,
    new SubscribeQuestionnaireStatusChangedImpl(questionnaireRepository)
  );
  provide(CreateMemoKey, new CreateMemoImpl(authorizationService, memoRepository));
  provide(UpdateMemoBodyKey, new UpdateMemoBodyImpl(authorizationService, memoRepository));
  provide(
    ChangeScopeRolesOfMemoKey,
    new ChangeScopeRolesOfMemoImpl(authorizationService, memoRepository)
  );
  provide(GetMemoKey, new GetMemoImpl(authorizationService, memoRepository));
  provide(RemoveMemoKey, new RemoveMemoImpl(authorizationService, memoRepository));
  provide(
    ChangeGroupTrainingCoursesSortKey,
    new ChangeGroupTrainingCoursesSortImpl(authorizationService, groupTrainingCourseRepository)
  );
  provide(
    GetQuestionnairesByAnswererKey,
    new GetQuestionnairesByAnswererImpl(appContextProvider, questionnaireRepository)
  );
  provide(
    FinishReviewProblemKey,
    new FinishReviewProblemImpl(authorizationService, reviewProblemRepository)
  );
  provide(
    GetReviewProblemKey,
    new GetReviewProblemImpl(
      reviewProblemRepository,
      groupTrainingCourseRepository,
      appContextProvider
    )
  );
  provide(
    RemoveReviewProblemProblemsKey,
    new RemoveReviewProblemProblemsImpl(authorizationService, reviewProblemRepository)
  );
  provide(
    RemoveGroupExamScheduleKey,
    new RemoveGroupExamScheduleImpl(authorizationService, groupExamRepository, dateTimeService)
  );
  provide(
    ChangeGroupExamScheduledTimeKey,
    new ChangeGroupExamScheduledTimeImpl(authorizationService, groupExamRepository, dateTimeService)
  );
}
