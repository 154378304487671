import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { Optional, PlainData } from '../types';
import { UserId } from './Core';
import { Entity, StandardRepository } from './Entity';
import { GroupExtensionConfig } from './extensions/Extension';
import { GroupRole } from './User';

/** 有効なグループの上限 */
export const ENABLED_GROUP_LIMITATION = 50;

export type GroupId = string;

export type GroupName = string;

export type GroupLimitation = {
  question: Array<GroupRole>;
  schedule: Array<GroupRole>;
  questionnaire: Array<GroupRole>;
};

export interface GroupAttributes {
  name: GroupName;
  extensionConfigs: Array<GroupExtensionConfig>;
  limitations: GroupLimitation;
  users: Array<GroupUser>;
  enabled: boolean;
  description?: string;
  createdBy?: UserId;
  updatedBy?: UserId;
}

export interface GroupEntity extends Entity<GroupId, GroupAttributes> {
  addUser(user: GroupUser): GroupEntity;
  addUsers(users: Array<GroupUser>): GroupEntity;
  removeUser(userId: UserId | Array<UserId>): GroupEntity;
  changeUsers(users: Array<GroupUser>): GroupEntity;
  changeName(name: GroupName): GroupEntity;
  changeLimitations(limitations: GroupLimitation): GroupEntity;
  changeDescription(description: string): GroupEntity;
  enable(): GroupEntity;
  disable(): GroupEntity;
  saveExtensionConfig(extensionConfig: GroupExtensionConfig): GroupEntity;
}

export type GroupData = PlainData<GroupEntity>;

export type GroupUser = {
  id: UserId;
  role: GroupRole;
  removed: boolean;
};

export interface GroupRepository extends StandardRepository<GroupId, GroupAttributes, GroupEntity> {
  findById(
    id: GroupId,
    options?: { includeRemovedGroupUser?: boolean; includeDisabledGroup?: boolean }
  ): Promise<Optional<GroupEntity>>;
  findTenantGroups(options?: {
    includeRemovedGroupUser?: boolean;
    includeDisabledGroup?: boolean;
  }): Promise<Array<Group>>;
}

export const GroupRepositoryKey = injectionKeyOf<GroupRepository>({
  boundedContext: 'base',
  type: 'adapter',
  name: 'GroupRepository',
});

export function useGroupRepository(): GroupRepository {
  return requiredInject(GroupRepositoryKey);
}

export type Group = {
  readonly id: GroupId;
  readonly name: GroupName;
  readonly extensionConfigs: Array<GroupExtensionConfig>;
  readonly limitations: GroupLimitation;
  readonly users: Array<GroupUser>;
  readonly enabled: boolean;
  readonly description?: string;
  readonly createdBy?: UserId;
  readonly updatedBy?: UserId;
};

export interface GroupFinder {
  findById(
    id: GroupId,
    options?: { includeRemovedGroupUser?: boolean; includeDisabledGroup?: boolean }
  ): Promise<Optional<Group>>;
  findTenantGroups(options?: {
    includeRemovedGroupUser?: boolean;
    includeDisabledGroup?: boolean;
  }): Promise<Array<Group>>;
}

export const GroupFinderKey = injectionKeyOf<GroupFinder>({
  boundedContext: 'base',
  type: 'service',
  name: 'GroupFinder',
});

export function useGroupFinder(): GroupFinder {
  return requiredInject(GroupFinderKey);
}
