import {
  AuthorizationService,
  AuthService,
  Email,
  GroupFinder,
  GroupId,
  GroupRole,
  Role,
  SignUpReservationQueries,
  TenantDataAdapter,
  UserCode,
  UserName,
  UserRepository,
} from '@/base/domains';
import { AbstractUseCase, assertEntityExists, UseCase, UseCaseResponse } from '@/base/usecases';
import { config } from '@/config';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { INVITE_USER_ERROR_GROUP_USER_LIMIT_EXCEEDED } from '../ErrorCodes';

type InviteUserGroup = { id: GroupId; role: GroupRole };

export type InviteUserRequest =
  | {
      userCode: UserCode;
      role: Role;
      userName?: UserName;
      groups?: InviteUserGroup[];
    }
  | {
      email: Email;
      role: Role;
      userName?: UserName;
      groups?: InviteUserGroup[];
    };

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export type InviteUserResponse = {};

/**
 * 新しいユーザーを招待する。
 */
export interface InviteUser extends UseCase<InviteUserRequest, InviteUserResponse> {
  execute(request: InviteUserRequest): Promise<UseCaseResponse<InviteUserResponse>>;
}

export class InviteUserImpl
  extends AbstractUseCase<InviteUserRequest, InviteUserResponse>
  implements InviteUser
{
  constructor(
    private authorizationService: AuthorizationService,
    private authService: AuthService,
    private userRepository: UserRepository,
    private signUpReservationQueries: SignUpReservationQueries,
    private tenantDataAdapter: TenantDataAdapter,
    private groupFinder: GroupFinder
  ) {
    super('account.InviteUser');
  }

  async internalExecute(req: InviteUserRequest): Promise<InviteUserResponse> {
    this.authorizationService.assertNotPlayground();
    this.authorizationService.assertRole('supervisor', 'admin');

    const groupIds: GroupId[] = req.groups?.map((g) => g.id) ?? [];
    const groupsPromise =
      groupIds.length > 0
        ? Promise.all(
            groupIds.map(async (groupId) => {
              const group = await this.groupFinder.findById(groupId);
              assertEntityExists(group, 'group');
              return group;
            })
          )
        : Promise.resolve([]);
    const [enableUsers, signUpReservations, tenant, groups] = await Promise.all([
      this.userRepository.findTenantEnabledUsers(),
      this.signUpReservationQueries.findTenantSignUpReservations(),
      this.tenantDataAdapter.get(),
      groupsPromise,
    ]);
    if (tenant.userLimit && tenant.userLimit <= enableUsers.length + signUpReservations.length) {
      throw new Error('number of users has exceeded the limit.');
    }
    const userLimitExceededGroupIds = groups
      .filter((g) => g.users.length + 1 > config().app.userLimitInGroup)
      .map((g) => g.id);
    if (userLimitExceededGroupIds.length > 0) {
      throw INVITE_USER_ERROR_GROUP_USER_LIMIT_EXCEEDED.toApplicationError({
        groupIds: userLimitExceededGroupIds,
      });
    }
    await this.authService.reserveSignUp(req);
    return {};
  }
}

export const InviteUserKey = injectionKeyOf<InviteUser>({
  boundedContext: 'account',
  type: 'usecase',
  name: 'InviteUser',
});

export function useInviteUser(): InviteUser {
  return requiredInject(InviteUserKey);
}
