import { v4 } from 'uuid';

import { AppContextProvider, CourseId, EntityData, hasId } from '@/base/domains';
import { Optional } from '@/base/types';
import {
  EditingCourseAttributes,
  EditingCourseEntity,
  EditingCourseEntityImpl,
  EditingCourseReference,
  EditingCourseRepository,
} from '@/contents/domains';
import { localDateTimeNow } from '@/utils/DateUtils';
import { requiredNonNull } from '@/utils/TsUtils';

export class InMemoryEditingCourseRepository implements EditingCourseRepository {
  private appContextProvider: AppContextProvider;

  private store = new Map<CourseId, EditingCourseEntity>();

  constructor(appContextProvider: AppContextProvider) {
    this.appContextProvider = appContextProvider;
  }

  save(
    args: EditingCourseEntity | EntityData<string, EditingCourseAttributes>
  ): Promise<EditingCourseEntity> {
    const userId = requiredNonNull(this.appContextProvider.get().user?.id, 'appContext.user.id');
    if (hasId(args)) {
      const e = new EditingCourseEntityImpl({
        ...args,
        updatedBy: userId,
        updatedAt: localDateTimeNow(),
      });
      this.store.set(e.id, e);
      return Promise.resolve(e);
    }
    const e = new EditingCourseEntityImpl({
      ...args,
      id: v4(),
      createdBy: userId,
      createdAt: localDateTimeNow(),
      updatedBy: userId,
      updatedAt: localDateTimeNow(),
    });
    this.store.set(e.id, e);
    return Promise.resolve(e);
  }

  findById(id: CourseId): Promise<Optional<EditingCourseEntity>> {
    return Promise.resolve(this.store.get(id));
  }

  remove(id: CourseId): Promise<void> {
    this.store.delete(id);
    return Promise.resolve();
  }

  tenantEditingCourses(): Promise<EditingCourseReference[]> {
    return Promise.resolve(Array.from(this.store.values()));
  }
}
