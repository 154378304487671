/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateNotification = /* GraphQL */ `
  subscription OnCreateNotification {
    onCreateNotification {
      id
      type
      payloadJson
      userId
      read
      createdAt
      tenantCode
      updatedAt
    }
  }
`;
export const onUpdateNotification = /* GraphQL */ `
  subscription OnUpdateNotification {
    onUpdateNotification {
      id
      type
      payloadJson
      userId
      read
      createdAt
      tenantCode
      updatedAt
    }
  }
`;
export const onDeleteNotification = /* GraphQL */ `
  subscription OnDeleteNotification {
    onDeleteNotification {
      id
      type
      payloadJson
      userId
      read
      createdAt
      tenantCode
      updatedAt
    }
  }
`;
export const onCreateLive = /* GraphQL */ `
  subscription OnCreateLive {
    onCreateLive {
      id
      userId
      url
      applicationSessionId
      tenantCode
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateLive = /* GraphQL */ `
  subscription OnUpdateLive {
    onUpdateLive {
      id
      userId
      url
      applicationSessionId
      tenantCode
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteLive = /* GraphQL */ `
  subscription OnDeleteLive {
    onDeleteLive {
      id
      userId
      url
      applicationSessionId
      tenantCode
      createdAt
      updatedAt
    }
  }
`;
export const onCreateQuestionnaire = /* GraphQL */ `
  subscription OnCreateQuestionnaire {
    onCreateQuestionnaire {
      id
      groupId
      tenantCode
      title
      userIds
      text
      createdBy
      createdAt
      options
      respondent {
        items {
          id
          questionnaireId
          userId
          selectedIndex
          tenantCode
          createdAt
          updatedAt
        }
        nextToken
      }
      status
      finishedAt
      updatedAt
    }
  }
`;
export const onUpdateQuestionnaire = /* GraphQL */ `
  subscription OnUpdateQuestionnaire {
    onUpdateQuestionnaire {
      id
      groupId
      tenantCode
      title
      userIds
      text
      createdBy
      createdAt
      options
      respondent {
        items {
          id
          questionnaireId
          userId
          selectedIndex
          tenantCode
          createdAt
          updatedAt
        }
        nextToken
      }
      status
      finishedAt
      updatedAt
    }
  }
`;
export const onDeleteQuestionnaire = /* GraphQL */ `
  subscription OnDeleteQuestionnaire {
    onDeleteQuestionnaire {
      id
      groupId
      tenantCode
      title
      userIds
      text
      createdBy
      createdAt
      options
      respondent {
        items {
          id
          questionnaireId
          userId
          selectedIndex
          tenantCode
          createdAt
          updatedAt
        }
        nextToken
      }
      status
      finishedAt
      updatedAt
    }
  }
`;
export const onCreateQuestionnaireRespondentByQuestionnaireId = /* GraphQL */ `
  subscription OnCreateQuestionnaireRespondentByQuestionnaireId(
    $questionnaireId: ID!
  ) {
    onCreateQuestionnaireRespondentByQuestionnaireId(
      questionnaireId: $questionnaireId
    ) {
      id
      questionnaireId
      userId
      selectedIndex
      tenantCode
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateQuestionnaireRespondentByQuestionnaireId = /* GraphQL */ `
  subscription OnUpdateQuestionnaireRespondentByQuestionnaireId(
    $questionnaireId: ID!
  ) {
    onUpdateQuestionnaireRespondentByQuestionnaireId(
      questionnaireId: $questionnaireId
    ) {
      id
      questionnaireId
      userId
      selectedIndex
      tenantCode
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteQuestionnaireRespondentByQuestionnaireId = /* GraphQL */ `
  subscription OnDeleteQuestionnaireRespondentByQuestionnaireId(
    $questionnaireId: ID!
  ) {
    onDeleteQuestionnaireRespondentByQuestionnaireId(
      questionnaireId: $questionnaireId
    ) {
      id
      questionnaireId
      userId
      selectedIndex
      tenantCode
      createdAt
      updatedAt
    }
  }
`;
export const onCreateScheduleTag = /* GraphQL */ `
  subscription OnCreateScheduleTag {
    onCreateScheduleTag {
      id
      color
      text
      groupId
      tenantCode
      createdAt
      updatedAt
      schedules {
        items {
          id
          scheduleId
          scheduleTagId
          scheduleTag {
            id
            color
            text
            groupId
            tenantCode
            createdAt
            updatedAt
            schedules {
              items {
                id
                scheduleId
                scheduleTagId
                scheduleTag {
                  id
                  color
                  text
                  groupId
                  tenantCode
                  createdAt
                  updatedAt
                  schedules {
                    nextToken
                  }
                }
                tenantCode
                createdAt
                updatedAt
                schedule {
                  id
                  groupId
                  start
                  end
                  title
                  body
                  tenantCode
                  createdBy
                  updatedBy
                  createdAt
                  updatedAt
                  tags {
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
          tenantCode
          createdAt
          updatedAt
          schedule {
            id
            groupId
            start
            end
            title
            body
            tenantCode
            createdBy
            updatedBy
            createdAt
            updatedAt
            tags {
              items {
                id
                scheduleId
                scheduleTagId
                scheduleTag {
                  id
                  color
                  text
                  groupId
                  tenantCode
                  createdAt
                  updatedAt
                  schedules {
                    nextToken
                  }
                }
                tenantCode
                createdAt
                updatedAt
                schedule {
                  id
                  groupId
                  start
                  end
                  title
                  body
                  tenantCode
                  createdBy
                  updatedBy
                  createdAt
                  updatedAt
                  tags {
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const onUpdateScheduleTag = /* GraphQL */ `
  subscription OnUpdateScheduleTag {
    onUpdateScheduleTag {
      id
      color
      text
      groupId
      tenantCode
      createdAt
      updatedAt
      schedules {
        items {
          id
          scheduleId
          scheduleTagId
          scheduleTag {
            id
            color
            text
            groupId
            tenantCode
            createdAt
            updatedAt
            schedules {
              items {
                id
                scheduleId
                scheduleTagId
                scheduleTag {
                  id
                  color
                  text
                  groupId
                  tenantCode
                  createdAt
                  updatedAt
                  schedules {
                    nextToken
                  }
                }
                tenantCode
                createdAt
                updatedAt
                schedule {
                  id
                  groupId
                  start
                  end
                  title
                  body
                  tenantCode
                  createdBy
                  updatedBy
                  createdAt
                  updatedAt
                  tags {
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
          tenantCode
          createdAt
          updatedAt
          schedule {
            id
            groupId
            start
            end
            title
            body
            tenantCode
            createdBy
            updatedBy
            createdAt
            updatedAt
            tags {
              items {
                id
                scheduleId
                scheduleTagId
                scheduleTag {
                  id
                  color
                  text
                  groupId
                  tenantCode
                  createdAt
                  updatedAt
                  schedules {
                    nextToken
                  }
                }
                tenantCode
                createdAt
                updatedAt
                schedule {
                  id
                  groupId
                  start
                  end
                  title
                  body
                  tenantCode
                  createdBy
                  updatedBy
                  createdAt
                  updatedAt
                  tags {
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const onDeleteScheduleTag = /* GraphQL */ `
  subscription OnDeleteScheduleTag {
    onDeleteScheduleTag {
      id
      color
      text
      groupId
      tenantCode
      createdAt
      updatedAt
      schedules {
        items {
          id
          scheduleId
          scheduleTagId
          scheduleTag {
            id
            color
            text
            groupId
            tenantCode
            createdAt
            updatedAt
            schedules {
              items {
                id
                scheduleId
                scheduleTagId
                scheduleTag {
                  id
                  color
                  text
                  groupId
                  tenantCode
                  createdAt
                  updatedAt
                  schedules {
                    nextToken
                  }
                }
                tenantCode
                createdAt
                updatedAt
                schedule {
                  id
                  groupId
                  start
                  end
                  title
                  body
                  tenantCode
                  createdBy
                  updatedBy
                  createdAt
                  updatedAt
                  tags {
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
          tenantCode
          createdAt
          updatedAt
          schedule {
            id
            groupId
            start
            end
            title
            body
            tenantCode
            createdBy
            updatedBy
            createdAt
            updatedAt
            tags {
              items {
                id
                scheduleId
                scheduleTagId
                scheduleTag {
                  id
                  color
                  text
                  groupId
                  tenantCode
                  createdAt
                  updatedAt
                  schedules {
                    nextToken
                  }
                }
                tenantCode
                createdAt
                updatedAt
                schedule {
                  id
                  groupId
                  start
                  end
                  title
                  body
                  tenantCode
                  createdBy
                  updatedBy
                  createdAt
                  updatedAt
                  tags {
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const onCreateMemo = /* GraphQL */ `
  subscription OnCreateMemo {
    onCreateMemo {
      id
      body
      referTo {
        contentId
        contentVersion
        options {
          text {
            selectionJson
          }
          workbook {
            problemIndex
            selectionJson
          }
        }
      }
      scope
      groupId
      createdBy
      createdAt
      updatedBy
      updatedAt
      tenantCode
    }
  }
`;
export const onUpdateMemo = /* GraphQL */ `
  subscription OnUpdateMemo {
    onUpdateMemo {
      id
      body
      referTo {
        contentId
        contentVersion
        options {
          text {
            selectionJson
          }
          workbook {
            problemIndex
            selectionJson
          }
        }
      }
      scope
      groupId
      createdBy
      createdAt
      updatedBy
      updatedAt
      tenantCode
    }
  }
`;
export const onDeleteMemo = /* GraphQL */ `
  subscription OnDeleteMemo {
    onDeleteMemo {
      id
      body
      referTo {
        contentId
        contentVersion
        options {
          text {
            selectionJson
          }
          workbook {
            problemIndex
            selectionJson
          }
        }
      }
      scope
      groupId
      createdBy
      createdAt
      updatedBy
      updatedAt
      tenantCode
    }
  }
`;
export const onCreateReviewProblem = /* GraphQL */ `
  subscription OnCreateReviewProblem {
    onCreateReviewProblem {
      id
      userId
      groupId
      courseId
      problems
      tenantCode
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateReviewProblem = /* GraphQL */ `
  subscription OnUpdateReviewProblem {
    onUpdateReviewProblem {
      id
      userId
      groupId
      courseId
      problems
      tenantCode
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteReviewProblem = /* GraphQL */ `
  subscription OnDeleteReviewProblem {
    onDeleteReviewProblem {
      id
      userId
      groupId
      courseId
      problems
      tenantCode
      createdAt
      updatedAt
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser {
    onCreateUser {
      id
      name
      role
      extensionConfigJsonList
      email
      code
      tenantCode
      displaySettings {
        theme
        color
      }
      locale
      avatar
      enabled
      signedInAtLeastOnce
      emailConfirmed
      confirmedTermsOfServiceVersions {
        termsOfServiceVersion
        privacyPolicyVersion
        tenantOwnTermsOfServiceVersion
      }
      createdAt
      updatedAt
      tags {
        items {
          id
          userId
          userTagId
          tenantCode
          createdAt
          updatedAt
          user {
            id
            name
            role
            extensionConfigJsonList
            email
            code
            tenantCode
            displaySettings {
              theme
              color
            }
            locale
            avatar
            enabled
            signedInAtLeastOnce
            emailConfirmed
            confirmedTermsOfServiceVersions {
              termsOfServiceVersion
              privacyPolicyVersion
              tenantOwnTermsOfServiceVersion
            }
            createdAt
            updatedAt
            tags {
              items {
                id
                userId
                userTagId
                tenantCode
                createdAt
                updatedAt
                user {
                  id
                  name
                  role
                  extensionConfigJsonList
                  email
                  code
                  tenantCode
                  displaySettings {
                    theme
                    color
                  }
                  locale
                  avatar
                  enabled
                  signedInAtLeastOnce
                  emailConfirmed
                  confirmedTermsOfServiceVersions {
                    termsOfServiceVersion
                    privacyPolicyVersion
                    tenantOwnTermsOfServiceVersion
                  }
                  createdAt
                  updatedAt
                  tags {
                    nextToken
                  }
                  statuses {
                    nextToken
                  }
                }
                userTag {
                  id
                  color
                  text
                  tenantCode
                  createdAt
                  updatedAt
                  users {
                    nextToken
                  }
                }
              }
              nextToken
            }
            statuses {
              items {
                id
                tenantCode
                status
                userId
                createdAt
                updatedAt
                user {
                  id
                  name
                  role
                  extensionConfigJsonList
                  email
                  code
                  tenantCode
                  displaySettings {
                    theme
                    color
                  }
                  locale
                  avatar
                  enabled
                  signedInAtLeastOnce
                  emailConfirmed
                  confirmedTermsOfServiceVersions {
                    termsOfServiceVersion
                    privacyPolicyVersion
                    tenantOwnTermsOfServiceVersion
                  }
                  createdAt
                  updatedAt
                  tags {
                    nextToken
                  }
                  statuses {
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
          userTag {
            id
            color
            text
            tenantCode
            createdAt
            updatedAt
            users {
              items {
                id
                userId
                userTagId
                tenantCode
                createdAt
                updatedAt
                user {
                  id
                  name
                  role
                  extensionConfigJsonList
                  email
                  code
                  tenantCode
                  displaySettings {
                    theme
                    color
                  }
                  locale
                  avatar
                  enabled
                  signedInAtLeastOnce
                  emailConfirmed
                  confirmedTermsOfServiceVersions {
                    termsOfServiceVersion
                    privacyPolicyVersion
                    tenantOwnTermsOfServiceVersion
                  }
                  createdAt
                  updatedAt
                  tags {
                    nextToken
                  }
                  statuses {
                    nextToken
                  }
                }
                userTag {
                  id
                  color
                  text
                  tenantCode
                  createdAt
                  updatedAt
                  users {
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
        }
        nextToken
      }
      statuses {
        items {
          id
          tenantCode
          status
          userId
          createdAt
          updatedAt
          user {
            id
            name
            role
            extensionConfigJsonList
            email
            code
            tenantCode
            displaySettings {
              theme
              color
            }
            locale
            avatar
            enabled
            signedInAtLeastOnce
            emailConfirmed
            confirmedTermsOfServiceVersions {
              termsOfServiceVersion
              privacyPolicyVersion
              tenantOwnTermsOfServiceVersion
            }
            createdAt
            updatedAt
            tags {
              items {
                id
                userId
                userTagId
                tenantCode
                createdAt
                updatedAt
                user {
                  id
                  name
                  role
                  extensionConfigJsonList
                  email
                  code
                  tenantCode
                  displaySettings {
                    theme
                    color
                  }
                  locale
                  avatar
                  enabled
                  signedInAtLeastOnce
                  emailConfirmed
                  confirmedTermsOfServiceVersions {
                    termsOfServiceVersion
                    privacyPolicyVersion
                    tenantOwnTermsOfServiceVersion
                  }
                  createdAt
                  updatedAt
                  tags {
                    nextToken
                  }
                  statuses {
                    nextToken
                  }
                }
                userTag {
                  id
                  color
                  text
                  tenantCode
                  createdAt
                  updatedAt
                  users {
                    nextToken
                  }
                }
              }
              nextToken
            }
            statuses {
              items {
                id
                tenantCode
                status
                userId
                createdAt
                updatedAt
                user {
                  id
                  name
                  role
                  extensionConfigJsonList
                  email
                  code
                  tenantCode
                  displaySettings {
                    theme
                    color
                  }
                  locale
                  avatar
                  enabled
                  signedInAtLeastOnce
                  emailConfirmed
                  confirmedTermsOfServiceVersions {
                    termsOfServiceVersion
                    privacyPolicyVersion
                    tenantOwnTermsOfServiceVersion
                  }
                  createdAt
                  updatedAt
                  tags {
                    nextToken
                  }
                  statuses {
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser {
    onUpdateUser {
      id
      name
      role
      extensionConfigJsonList
      email
      code
      tenantCode
      displaySettings {
        theme
        color
      }
      locale
      avatar
      enabled
      signedInAtLeastOnce
      emailConfirmed
      confirmedTermsOfServiceVersions {
        termsOfServiceVersion
        privacyPolicyVersion
        tenantOwnTermsOfServiceVersion
      }
      createdAt
      updatedAt
      tags {
        items {
          id
          userId
          userTagId
          tenantCode
          createdAt
          updatedAt
          user {
            id
            name
            role
            extensionConfigJsonList
            email
            code
            tenantCode
            displaySettings {
              theme
              color
            }
            locale
            avatar
            enabled
            signedInAtLeastOnce
            emailConfirmed
            confirmedTermsOfServiceVersions {
              termsOfServiceVersion
              privacyPolicyVersion
              tenantOwnTermsOfServiceVersion
            }
            createdAt
            updatedAt
            tags {
              items {
                id
                userId
                userTagId
                tenantCode
                createdAt
                updatedAt
                user {
                  id
                  name
                  role
                  extensionConfigJsonList
                  email
                  code
                  tenantCode
                  displaySettings {
                    theme
                    color
                  }
                  locale
                  avatar
                  enabled
                  signedInAtLeastOnce
                  emailConfirmed
                  confirmedTermsOfServiceVersions {
                    termsOfServiceVersion
                    privacyPolicyVersion
                    tenantOwnTermsOfServiceVersion
                  }
                  createdAt
                  updatedAt
                  tags {
                    nextToken
                  }
                  statuses {
                    nextToken
                  }
                }
                userTag {
                  id
                  color
                  text
                  tenantCode
                  createdAt
                  updatedAt
                  users {
                    nextToken
                  }
                }
              }
              nextToken
            }
            statuses {
              items {
                id
                tenantCode
                status
                userId
                createdAt
                updatedAt
                user {
                  id
                  name
                  role
                  extensionConfigJsonList
                  email
                  code
                  tenantCode
                  displaySettings {
                    theme
                    color
                  }
                  locale
                  avatar
                  enabled
                  signedInAtLeastOnce
                  emailConfirmed
                  confirmedTermsOfServiceVersions {
                    termsOfServiceVersion
                    privacyPolicyVersion
                    tenantOwnTermsOfServiceVersion
                  }
                  createdAt
                  updatedAt
                  tags {
                    nextToken
                  }
                  statuses {
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
          userTag {
            id
            color
            text
            tenantCode
            createdAt
            updatedAt
            users {
              items {
                id
                userId
                userTagId
                tenantCode
                createdAt
                updatedAt
                user {
                  id
                  name
                  role
                  extensionConfigJsonList
                  email
                  code
                  tenantCode
                  displaySettings {
                    theme
                    color
                  }
                  locale
                  avatar
                  enabled
                  signedInAtLeastOnce
                  emailConfirmed
                  confirmedTermsOfServiceVersions {
                    termsOfServiceVersion
                    privacyPolicyVersion
                    tenantOwnTermsOfServiceVersion
                  }
                  createdAt
                  updatedAt
                  tags {
                    nextToken
                  }
                  statuses {
                    nextToken
                  }
                }
                userTag {
                  id
                  color
                  text
                  tenantCode
                  createdAt
                  updatedAt
                  users {
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
        }
        nextToken
      }
      statuses {
        items {
          id
          tenantCode
          status
          userId
          createdAt
          updatedAt
          user {
            id
            name
            role
            extensionConfigJsonList
            email
            code
            tenantCode
            displaySettings {
              theme
              color
            }
            locale
            avatar
            enabled
            signedInAtLeastOnce
            emailConfirmed
            confirmedTermsOfServiceVersions {
              termsOfServiceVersion
              privacyPolicyVersion
              tenantOwnTermsOfServiceVersion
            }
            createdAt
            updatedAt
            tags {
              items {
                id
                userId
                userTagId
                tenantCode
                createdAt
                updatedAt
                user {
                  id
                  name
                  role
                  extensionConfigJsonList
                  email
                  code
                  tenantCode
                  displaySettings {
                    theme
                    color
                  }
                  locale
                  avatar
                  enabled
                  signedInAtLeastOnce
                  emailConfirmed
                  confirmedTermsOfServiceVersions {
                    termsOfServiceVersion
                    privacyPolicyVersion
                    tenantOwnTermsOfServiceVersion
                  }
                  createdAt
                  updatedAt
                  tags {
                    nextToken
                  }
                  statuses {
                    nextToken
                  }
                }
                userTag {
                  id
                  color
                  text
                  tenantCode
                  createdAt
                  updatedAt
                  users {
                    nextToken
                  }
                }
              }
              nextToken
            }
            statuses {
              items {
                id
                tenantCode
                status
                userId
                createdAt
                updatedAt
                user {
                  id
                  name
                  role
                  extensionConfigJsonList
                  email
                  code
                  tenantCode
                  displaySettings {
                    theme
                    color
                  }
                  locale
                  avatar
                  enabled
                  signedInAtLeastOnce
                  emailConfirmed
                  confirmedTermsOfServiceVersions {
                    termsOfServiceVersion
                    privacyPolicyVersion
                    tenantOwnTermsOfServiceVersion
                  }
                  createdAt
                  updatedAt
                  tags {
                    nextToken
                  }
                  statuses {
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser {
    onDeleteUser {
      id
      name
      role
      extensionConfigJsonList
      email
      code
      tenantCode
      displaySettings {
        theme
        color
      }
      locale
      avatar
      enabled
      signedInAtLeastOnce
      emailConfirmed
      confirmedTermsOfServiceVersions {
        termsOfServiceVersion
        privacyPolicyVersion
        tenantOwnTermsOfServiceVersion
      }
      createdAt
      updatedAt
      tags {
        items {
          id
          userId
          userTagId
          tenantCode
          createdAt
          updatedAt
          user {
            id
            name
            role
            extensionConfigJsonList
            email
            code
            tenantCode
            displaySettings {
              theme
              color
            }
            locale
            avatar
            enabled
            signedInAtLeastOnce
            emailConfirmed
            confirmedTermsOfServiceVersions {
              termsOfServiceVersion
              privacyPolicyVersion
              tenantOwnTermsOfServiceVersion
            }
            createdAt
            updatedAt
            tags {
              items {
                id
                userId
                userTagId
                tenantCode
                createdAt
                updatedAt
                user {
                  id
                  name
                  role
                  extensionConfigJsonList
                  email
                  code
                  tenantCode
                  displaySettings {
                    theme
                    color
                  }
                  locale
                  avatar
                  enabled
                  signedInAtLeastOnce
                  emailConfirmed
                  confirmedTermsOfServiceVersions {
                    termsOfServiceVersion
                    privacyPolicyVersion
                    tenantOwnTermsOfServiceVersion
                  }
                  createdAt
                  updatedAt
                  tags {
                    nextToken
                  }
                  statuses {
                    nextToken
                  }
                }
                userTag {
                  id
                  color
                  text
                  tenantCode
                  createdAt
                  updatedAt
                  users {
                    nextToken
                  }
                }
              }
              nextToken
            }
            statuses {
              items {
                id
                tenantCode
                status
                userId
                createdAt
                updatedAt
                user {
                  id
                  name
                  role
                  extensionConfigJsonList
                  email
                  code
                  tenantCode
                  displaySettings {
                    theme
                    color
                  }
                  locale
                  avatar
                  enabled
                  signedInAtLeastOnce
                  emailConfirmed
                  confirmedTermsOfServiceVersions {
                    termsOfServiceVersion
                    privacyPolicyVersion
                    tenantOwnTermsOfServiceVersion
                  }
                  createdAt
                  updatedAt
                  tags {
                    nextToken
                  }
                  statuses {
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
          userTag {
            id
            color
            text
            tenantCode
            createdAt
            updatedAt
            users {
              items {
                id
                userId
                userTagId
                tenantCode
                createdAt
                updatedAt
                user {
                  id
                  name
                  role
                  extensionConfigJsonList
                  email
                  code
                  tenantCode
                  displaySettings {
                    theme
                    color
                  }
                  locale
                  avatar
                  enabled
                  signedInAtLeastOnce
                  emailConfirmed
                  confirmedTermsOfServiceVersions {
                    termsOfServiceVersion
                    privacyPolicyVersion
                    tenantOwnTermsOfServiceVersion
                  }
                  createdAt
                  updatedAt
                  tags {
                    nextToken
                  }
                  statuses {
                    nextToken
                  }
                }
                userTag {
                  id
                  color
                  text
                  tenantCode
                  createdAt
                  updatedAt
                  users {
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
        }
        nextToken
      }
      statuses {
        items {
          id
          tenantCode
          status
          userId
          createdAt
          updatedAt
          user {
            id
            name
            role
            extensionConfigJsonList
            email
            code
            tenantCode
            displaySettings {
              theme
              color
            }
            locale
            avatar
            enabled
            signedInAtLeastOnce
            emailConfirmed
            confirmedTermsOfServiceVersions {
              termsOfServiceVersion
              privacyPolicyVersion
              tenantOwnTermsOfServiceVersion
            }
            createdAt
            updatedAt
            tags {
              items {
                id
                userId
                userTagId
                tenantCode
                createdAt
                updatedAt
                user {
                  id
                  name
                  role
                  extensionConfigJsonList
                  email
                  code
                  tenantCode
                  displaySettings {
                    theme
                    color
                  }
                  locale
                  avatar
                  enabled
                  signedInAtLeastOnce
                  emailConfirmed
                  confirmedTermsOfServiceVersions {
                    termsOfServiceVersion
                    privacyPolicyVersion
                    tenantOwnTermsOfServiceVersion
                  }
                  createdAt
                  updatedAt
                  tags {
                    nextToken
                  }
                  statuses {
                    nextToken
                  }
                }
                userTag {
                  id
                  color
                  text
                  tenantCode
                  createdAt
                  updatedAt
                  users {
                    nextToken
                  }
                }
              }
              nextToken
            }
            statuses {
              items {
                id
                tenantCode
                status
                userId
                createdAt
                updatedAt
                user {
                  id
                  name
                  role
                  extensionConfigJsonList
                  email
                  code
                  tenantCode
                  displaySettings {
                    theme
                    color
                  }
                  locale
                  avatar
                  enabled
                  signedInAtLeastOnce
                  emailConfirmed
                  confirmedTermsOfServiceVersions {
                    termsOfServiceVersion
                    privacyPolicyVersion
                    tenantOwnTermsOfServiceVersion
                  }
                  createdAt
                  updatedAt
                  tags {
                    nextToken
                  }
                  statuses {
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const onCreateUserDevice = /* GraphQL */ `
  subscription OnCreateUserDevice($userId: String) {
    onCreateUserDevice(userId: $userId) {
      userId
      devices
      dataVersion
      tenantCode
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateUserDevice = /* GraphQL */ `
  subscription OnUpdateUserDevice($userId: String) {
    onUpdateUserDevice(userId: $userId) {
      userId
      devices
      dataVersion
      tenantCode
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteUserDevice = /* GraphQL */ `
  subscription OnDeleteUserDevice($userId: String) {
    onDeleteUserDevice(userId: $userId) {
      userId
      devices
      dataVersion
      tenantCode
      createdAt
      updatedAt
    }
  }
`;
export const onCreateSignUpReservation = /* GraphQL */ `
  subscription OnCreateSignUpReservation {
    onCreateSignUpReservation {
      id
      name
      key
      userCode
      email
      tenantCode
      type
      role
      userId
      signUpInput {
        name
        tenantName
      }
      status
      groups {
        id
        role
        removed
      }
      createdBy
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateSignUpReservation = /* GraphQL */ `
  subscription OnUpdateSignUpReservation {
    onUpdateSignUpReservation {
      id
      name
      key
      userCode
      email
      tenantCode
      type
      role
      userId
      signUpInput {
        name
        tenantName
      }
      status
      groups {
        id
        role
        removed
      }
      createdBy
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteSignUpReservation = /* GraphQL */ `
  subscription OnDeleteSignUpReservation {
    onDeleteSignUpReservation {
      id
      name
      key
      userCode
      email
      tenantCode
      type
      role
      userId
      signUpInput {
        name
        tenantName
      }
      status
      groups {
        id
        role
        removed
      }
      createdBy
      createdAt
      updatedAt
    }
  }
`;
export const onCreateTenant = /* GraphQL */ `
  subscription OnCreateTenant {
    onCreateTenant {
      id
      code
      name
      signUpType
      playground
      userLimit
      limitations
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateTenant = /* GraphQL */ `
  subscription OnUpdateTenant {
    onUpdateTenant {
      id
      code
      name
      signUpType
      playground
      userLimit
      limitations
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteTenant = /* GraphQL */ `
  subscription OnDeleteTenant {
    onDeleteTenant {
      id
      code
      name
      signUpType
      playground
      userLimit
      limitations
      createdAt
      updatedAt
    }
  }
`;
export const onCreateTenantOwnTermsOfService = /* GraphQL */ `
  subscription OnCreateTenantOwnTermsOfService {
    onCreateTenantOwnTermsOfService {
      id
      tenantCode
      type
      body
      createdBy
      createdAt
      updatedBy
      updatedAt
      version
    }
  }
`;
export const onUpdateTenantOwnTermsOfService = /* GraphQL */ `
  subscription OnUpdateTenantOwnTermsOfService {
    onUpdateTenantOwnTermsOfService {
      id
      tenantCode
      type
      body
      createdBy
      createdAt
      updatedBy
      updatedAt
      version
    }
  }
`;
export const onDeleteTenantOwnTermsOfService = /* GraphQL */ `
  subscription OnDeleteTenantOwnTermsOfService {
    onDeleteTenantOwnTermsOfService {
      id
      tenantCode
      type
      body
      createdBy
      createdAt
      updatedBy
      updatedAt
      version
    }
  }
`;
export const onCreateEvent = /* GraphQL */ `
  subscription OnCreateEvent {
    onCreateEvent {
      id
      type
      event
      version
      tenantCode
      occurredBy
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateEvent = /* GraphQL */ `
  subscription OnUpdateEvent {
    onUpdateEvent {
      id
      type
      event
      version
      tenantCode
      occurredBy
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteEvent = /* GraphQL */ `
  subscription OnDeleteEvent {
    onDeleteEvent {
      id
      type
      event
      version
      tenantCode
      occurredBy
      createdAt
      updatedAt
    }
  }
`;
export const onCreateTermsOfService = /* GraphQL */ `
  subscription OnCreateTermsOfService {
    onCreateTermsOfService {
      id
      body
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateTermsOfService = /* GraphQL */ `
  subscription OnUpdateTermsOfService {
    onUpdateTermsOfService {
      id
      body
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteTermsOfService = /* GraphQL */ `
  subscription OnDeleteTermsOfService {
    onDeleteTermsOfService {
      id
      body
      createdAt
      updatedAt
    }
  }
`;
export const onCreateUserTag = /* GraphQL */ `
  subscription OnCreateUserTag {
    onCreateUserTag {
      id
      color
      text
      tenantCode
      createdAt
      updatedAt
      users {
        items {
          id
          userId
          userTagId
          tenantCode
          createdAt
          updatedAt
          user {
            id
            name
            role
            extensionConfigJsonList
            email
            code
            tenantCode
            displaySettings {
              theme
              color
            }
            locale
            avatar
            enabled
            signedInAtLeastOnce
            emailConfirmed
            confirmedTermsOfServiceVersions {
              termsOfServiceVersion
              privacyPolicyVersion
              tenantOwnTermsOfServiceVersion
            }
            createdAt
            updatedAt
            tags {
              items {
                id
                userId
                userTagId
                tenantCode
                createdAt
                updatedAt
                user {
                  id
                  name
                  role
                  extensionConfigJsonList
                  email
                  code
                  tenantCode
                  displaySettings {
                    theme
                    color
                  }
                  locale
                  avatar
                  enabled
                  signedInAtLeastOnce
                  emailConfirmed
                  confirmedTermsOfServiceVersions {
                    termsOfServiceVersion
                    privacyPolicyVersion
                    tenantOwnTermsOfServiceVersion
                  }
                  createdAt
                  updatedAt
                  tags {
                    nextToken
                  }
                  statuses {
                    nextToken
                  }
                }
                userTag {
                  id
                  color
                  text
                  tenantCode
                  createdAt
                  updatedAt
                  users {
                    nextToken
                  }
                }
              }
              nextToken
            }
            statuses {
              items {
                id
                tenantCode
                status
                userId
                createdAt
                updatedAt
                user {
                  id
                  name
                  role
                  extensionConfigJsonList
                  email
                  code
                  tenantCode
                  displaySettings {
                    theme
                    color
                  }
                  locale
                  avatar
                  enabled
                  signedInAtLeastOnce
                  emailConfirmed
                  confirmedTermsOfServiceVersions {
                    termsOfServiceVersion
                    privacyPolicyVersion
                    tenantOwnTermsOfServiceVersion
                  }
                  createdAt
                  updatedAt
                  tags {
                    nextToken
                  }
                  statuses {
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
          userTag {
            id
            color
            text
            tenantCode
            createdAt
            updatedAt
            users {
              items {
                id
                userId
                userTagId
                tenantCode
                createdAt
                updatedAt
                user {
                  id
                  name
                  role
                  extensionConfigJsonList
                  email
                  code
                  tenantCode
                  displaySettings {
                    theme
                    color
                  }
                  locale
                  avatar
                  enabled
                  signedInAtLeastOnce
                  emailConfirmed
                  confirmedTermsOfServiceVersions {
                    termsOfServiceVersion
                    privacyPolicyVersion
                    tenantOwnTermsOfServiceVersion
                  }
                  createdAt
                  updatedAt
                  tags {
                    nextToken
                  }
                  statuses {
                    nextToken
                  }
                }
                userTag {
                  id
                  color
                  text
                  tenantCode
                  createdAt
                  updatedAt
                  users {
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const onUpdateUserTag = /* GraphQL */ `
  subscription OnUpdateUserTag {
    onUpdateUserTag {
      id
      color
      text
      tenantCode
      createdAt
      updatedAt
      users {
        items {
          id
          userId
          userTagId
          tenantCode
          createdAt
          updatedAt
          user {
            id
            name
            role
            extensionConfigJsonList
            email
            code
            tenantCode
            displaySettings {
              theme
              color
            }
            locale
            avatar
            enabled
            signedInAtLeastOnce
            emailConfirmed
            confirmedTermsOfServiceVersions {
              termsOfServiceVersion
              privacyPolicyVersion
              tenantOwnTermsOfServiceVersion
            }
            createdAt
            updatedAt
            tags {
              items {
                id
                userId
                userTagId
                tenantCode
                createdAt
                updatedAt
                user {
                  id
                  name
                  role
                  extensionConfigJsonList
                  email
                  code
                  tenantCode
                  displaySettings {
                    theme
                    color
                  }
                  locale
                  avatar
                  enabled
                  signedInAtLeastOnce
                  emailConfirmed
                  confirmedTermsOfServiceVersions {
                    termsOfServiceVersion
                    privacyPolicyVersion
                    tenantOwnTermsOfServiceVersion
                  }
                  createdAt
                  updatedAt
                  tags {
                    nextToken
                  }
                  statuses {
                    nextToken
                  }
                }
                userTag {
                  id
                  color
                  text
                  tenantCode
                  createdAt
                  updatedAt
                  users {
                    nextToken
                  }
                }
              }
              nextToken
            }
            statuses {
              items {
                id
                tenantCode
                status
                userId
                createdAt
                updatedAt
                user {
                  id
                  name
                  role
                  extensionConfigJsonList
                  email
                  code
                  tenantCode
                  displaySettings {
                    theme
                    color
                  }
                  locale
                  avatar
                  enabled
                  signedInAtLeastOnce
                  emailConfirmed
                  confirmedTermsOfServiceVersions {
                    termsOfServiceVersion
                    privacyPolicyVersion
                    tenantOwnTermsOfServiceVersion
                  }
                  createdAt
                  updatedAt
                  tags {
                    nextToken
                  }
                  statuses {
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
          userTag {
            id
            color
            text
            tenantCode
            createdAt
            updatedAt
            users {
              items {
                id
                userId
                userTagId
                tenantCode
                createdAt
                updatedAt
                user {
                  id
                  name
                  role
                  extensionConfigJsonList
                  email
                  code
                  tenantCode
                  displaySettings {
                    theme
                    color
                  }
                  locale
                  avatar
                  enabled
                  signedInAtLeastOnce
                  emailConfirmed
                  confirmedTermsOfServiceVersions {
                    termsOfServiceVersion
                    privacyPolicyVersion
                    tenantOwnTermsOfServiceVersion
                  }
                  createdAt
                  updatedAt
                  tags {
                    nextToken
                  }
                  statuses {
                    nextToken
                  }
                }
                userTag {
                  id
                  color
                  text
                  tenantCode
                  createdAt
                  updatedAt
                  users {
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const onDeleteUserTag = /* GraphQL */ `
  subscription OnDeleteUserTag {
    onDeleteUserTag {
      id
      color
      text
      tenantCode
      createdAt
      updatedAt
      users {
        items {
          id
          userId
          userTagId
          tenantCode
          createdAt
          updatedAt
          user {
            id
            name
            role
            extensionConfigJsonList
            email
            code
            tenantCode
            displaySettings {
              theme
              color
            }
            locale
            avatar
            enabled
            signedInAtLeastOnce
            emailConfirmed
            confirmedTermsOfServiceVersions {
              termsOfServiceVersion
              privacyPolicyVersion
              tenantOwnTermsOfServiceVersion
            }
            createdAt
            updatedAt
            tags {
              items {
                id
                userId
                userTagId
                tenantCode
                createdAt
                updatedAt
                user {
                  id
                  name
                  role
                  extensionConfigJsonList
                  email
                  code
                  tenantCode
                  displaySettings {
                    theme
                    color
                  }
                  locale
                  avatar
                  enabled
                  signedInAtLeastOnce
                  emailConfirmed
                  confirmedTermsOfServiceVersions {
                    termsOfServiceVersion
                    privacyPolicyVersion
                    tenantOwnTermsOfServiceVersion
                  }
                  createdAt
                  updatedAt
                  tags {
                    nextToken
                  }
                  statuses {
                    nextToken
                  }
                }
                userTag {
                  id
                  color
                  text
                  tenantCode
                  createdAt
                  updatedAt
                  users {
                    nextToken
                  }
                }
              }
              nextToken
            }
            statuses {
              items {
                id
                tenantCode
                status
                userId
                createdAt
                updatedAt
                user {
                  id
                  name
                  role
                  extensionConfigJsonList
                  email
                  code
                  tenantCode
                  displaySettings {
                    theme
                    color
                  }
                  locale
                  avatar
                  enabled
                  signedInAtLeastOnce
                  emailConfirmed
                  confirmedTermsOfServiceVersions {
                    termsOfServiceVersion
                    privacyPolicyVersion
                    tenantOwnTermsOfServiceVersion
                  }
                  createdAt
                  updatedAt
                  tags {
                    nextToken
                  }
                  statuses {
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
          userTag {
            id
            color
            text
            tenantCode
            createdAt
            updatedAt
            users {
              items {
                id
                userId
                userTagId
                tenantCode
                createdAt
                updatedAt
                user {
                  id
                  name
                  role
                  extensionConfigJsonList
                  email
                  code
                  tenantCode
                  displaySettings {
                    theme
                    color
                  }
                  locale
                  avatar
                  enabled
                  signedInAtLeastOnce
                  emailConfirmed
                  confirmedTermsOfServiceVersions {
                    termsOfServiceVersion
                    privacyPolicyVersion
                    tenantOwnTermsOfServiceVersion
                  }
                  createdAt
                  updatedAt
                  tags {
                    nextToken
                  }
                  statuses {
                    nextToken
                  }
                }
                userTag {
                  id
                  color
                  text
                  tenantCode
                  createdAt
                  updatedAt
                  users {
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const onCreateUserUserTag = /* GraphQL */ `
  subscription OnCreateUserUserTag {
    onCreateUserUserTag {
      id
      userId
      userTagId
      tenantCode
      createdAt
      updatedAt
      user {
        id
        name
        role
        extensionConfigJsonList
        email
        code
        tenantCode
        displaySettings {
          theme
          color
        }
        locale
        avatar
        enabled
        signedInAtLeastOnce
        emailConfirmed
        confirmedTermsOfServiceVersions {
          termsOfServiceVersion
          privacyPolicyVersion
          tenantOwnTermsOfServiceVersion
        }
        createdAt
        updatedAt
        tags {
          items {
            id
            userId
            userTagId
            tenantCode
            createdAt
            updatedAt
            user {
              id
              name
              role
              extensionConfigJsonList
              email
              code
              tenantCode
              displaySettings {
                theme
                color
              }
              locale
              avatar
              enabled
              signedInAtLeastOnce
              emailConfirmed
              confirmedTermsOfServiceVersions {
                termsOfServiceVersion
                privacyPolicyVersion
                tenantOwnTermsOfServiceVersion
              }
              createdAt
              updatedAt
              tags {
                items {
                  id
                  userId
                  userTagId
                  tenantCode
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                  userTag {
                    id
                    color
                    text
                    tenantCode
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
              statuses {
                items {
                  id
                  tenantCode
                  status
                  userId
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
            userTag {
              id
              color
              text
              tenantCode
              createdAt
              updatedAt
              users {
                items {
                  id
                  userId
                  userTagId
                  tenantCode
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                  userTag {
                    id
                    color
                    text
                    tenantCode
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
          }
          nextToken
        }
        statuses {
          items {
            id
            tenantCode
            status
            userId
            createdAt
            updatedAt
            user {
              id
              name
              role
              extensionConfigJsonList
              email
              code
              tenantCode
              displaySettings {
                theme
                color
              }
              locale
              avatar
              enabled
              signedInAtLeastOnce
              emailConfirmed
              confirmedTermsOfServiceVersions {
                termsOfServiceVersion
                privacyPolicyVersion
                tenantOwnTermsOfServiceVersion
              }
              createdAt
              updatedAt
              tags {
                items {
                  id
                  userId
                  userTagId
                  tenantCode
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                  userTag {
                    id
                    color
                    text
                    tenantCode
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
              statuses {
                items {
                  id
                  tenantCode
                  status
                  userId
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }
      userTag {
        id
        color
        text
        tenantCode
        createdAt
        updatedAt
        users {
          items {
            id
            userId
            userTagId
            tenantCode
            createdAt
            updatedAt
            user {
              id
              name
              role
              extensionConfigJsonList
              email
              code
              tenantCode
              displaySettings {
                theme
                color
              }
              locale
              avatar
              enabled
              signedInAtLeastOnce
              emailConfirmed
              confirmedTermsOfServiceVersions {
                termsOfServiceVersion
                privacyPolicyVersion
                tenantOwnTermsOfServiceVersion
              }
              createdAt
              updatedAt
              tags {
                items {
                  id
                  userId
                  userTagId
                  tenantCode
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                  userTag {
                    id
                    color
                    text
                    tenantCode
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
              statuses {
                items {
                  id
                  tenantCode
                  status
                  userId
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
            userTag {
              id
              color
              text
              tenantCode
              createdAt
              updatedAt
              users {
                items {
                  id
                  userId
                  userTagId
                  tenantCode
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                  userTag {
                    id
                    color
                    text
                    tenantCode
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const onUpdateUserUserTag = /* GraphQL */ `
  subscription OnUpdateUserUserTag {
    onUpdateUserUserTag {
      id
      userId
      userTagId
      tenantCode
      createdAt
      updatedAt
      user {
        id
        name
        role
        extensionConfigJsonList
        email
        code
        tenantCode
        displaySettings {
          theme
          color
        }
        locale
        avatar
        enabled
        signedInAtLeastOnce
        emailConfirmed
        confirmedTermsOfServiceVersions {
          termsOfServiceVersion
          privacyPolicyVersion
          tenantOwnTermsOfServiceVersion
        }
        createdAt
        updatedAt
        tags {
          items {
            id
            userId
            userTagId
            tenantCode
            createdAt
            updatedAt
            user {
              id
              name
              role
              extensionConfigJsonList
              email
              code
              tenantCode
              displaySettings {
                theme
                color
              }
              locale
              avatar
              enabled
              signedInAtLeastOnce
              emailConfirmed
              confirmedTermsOfServiceVersions {
                termsOfServiceVersion
                privacyPolicyVersion
                tenantOwnTermsOfServiceVersion
              }
              createdAt
              updatedAt
              tags {
                items {
                  id
                  userId
                  userTagId
                  tenantCode
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                  userTag {
                    id
                    color
                    text
                    tenantCode
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
              statuses {
                items {
                  id
                  tenantCode
                  status
                  userId
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
            userTag {
              id
              color
              text
              tenantCode
              createdAt
              updatedAt
              users {
                items {
                  id
                  userId
                  userTagId
                  tenantCode
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                  userTag {
                    id
                    color
                    text
                    tenantCode
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
          }
          nextToken
        }
        statuses {
          items {
            id
            tenantCode
            status
            userId
            createdAt
            updatedAt
            user {
              id
              name
              role
              extensionConfigJsonList
              email
              code
              tenantCode
              displaySettings {
                theme
                color
              }
              locale
              avatar
              enabled
              signedInAtLeastOnce
              emailConfirmed
              confirmedTermsOfServiceVersions {
                termsOfServiceVersion
                privacyPolicyVersion
                tenantOwnTermsOfServiceVersion
              }
              createdAt
              updatedAt
              tags {
                items {
                  id
                  userId
                  userTagId
                  tenantCode
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                  userTag {
                    id
                    color
                    text
                    tenantCode
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
              statuses {
                items {
                  id
                  tenantCode
                  status
                  userId
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }
      userTag {
        id
        color
        text
        tenantCode
        createdAt
        updatedAt
        users {
          items {
            id
            userId
            userTagId
            tenantCode
            createdAt
            updatedAt
            user {
              id
              name
              role
              extensionConfigJsonList
              email
              code
              tenantCode
              displaySettings {
                theme
                color
              }
              locale
              avatar
              enabled
              signedInAtLeastOnce
              emailConfirmed
              confirmedTermsOfServiceVersions {
                termsOfServiceVersion
                privacyPolicyVersion
                tenantOwnTermsOfServiceVersion
              }
              createdAt
              updatedAt
              tags {
                items {
                  id
                  userId
                  userTagId
                  tenantCode
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                  userTag {
                    id
                    color
                    text
                    tenantCode
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
              statuses {
                items {
                  id
                  tenantCode
                  status
                  userId
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
            userTag {
              id
              color
              text
              tenantCode
              createdAt
              updatedAt
              users {
                items {
                  id
                  userId
                  userTagId
                  tenantCode
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                  userTag {
                    id
                    color
                    text
                    tenantCode
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const onDeleteUserUserTag = /* GraphQL */ `
  subscription OnDeleteUserUserTag {
    onDeleteUserUserTag {
      id
      userId
      userTagId
      tenantCode
      createdAt
      updatedAt
      user {
        id
        name
        role
        extensionConfigJsonList
        email
        code
        tenantCode
        displaySettings {
          theme
          color
        }
        locale
        avatar
        enabled
        signedInAtLeastOnce
        emailConfirmed
        confirmedTermsOfServiceVersions {
          termsOfServiceVersion
          privacyPolicyVersion
          tenantOwnTermsOfServiceVersion
        }
        createdAt
        updatedAt
        tags {
          items {
            id
            userId
            userTagId
            tenantCode
            createdAt
            updatedAt
            user {
              id
              name
              role
              extensionConfigJsonList
              email
              code
              tenantCode
              displaySettings {
                theme
                color
              }
              locale
              avatar
              enabled
              signedInAtLeastOnce
              emailConfirmed
              confirmedTermsOfServiceVersions {
                termsOfServiceVersion
                privacyPolicyVersion
                tenantOwnTermsOfServiceVersion
              }
              createdAt
              updatedAt
              tags {
                items {
                  id
                  userId
                  userTagId
                  tenantCode
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                  userTag {
                    id
                    color
                    text
                    tenantCode
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
              statuses {
                items {
                  id
                  tenantCode
                  status
                  userId
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
            userTag {
              id
              color
              text
              tenantCode
              createdAt
              updatedAt
              users {
                items {
                  id
                  userId
                  userTagId
                  tenantCode
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                  userTag {
                    id
                    color
                    text
                    tenantCode
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
          }
          nextToken
        }
        statuses {
          items {
            id
            tenantCode
            status
            userId
            createdAt
            updatedAt
            user {
              id
              name
              role
              extensionConfigJsonList
              email
              code
              tenantCode
              displaySettings {
                theme
                color
              }
              locale
              avatar
              enabled
              signedInAtLeastOnce
              emailConfirmed
              confirmedTermsOfServiceVersions {
                termsOfServiceVersion
                privacyPolicyVersion
                tenantOwnTermsOfServiceVersion
              }
              createdAt
              updatedAt
              tags {
                items {
                  id
                  userId
                  userTagId
                  tenantCode
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                  userTag {
                    id
                    color
                    text
                    tenantCode
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
              statuses {
                items {
                  id
                  tenantCode
                  status
                  userId
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }
      userTag {
        id
        color
        text
        tenantCode
        createdAt
        updatedAt
        users {
          items {
            id
            userId
            userTagId
            tenantCode
            createdAt
            updatedAt
            user {
              id
              name
              role
              extensionConfigJsonList
              email
              code
              tenantCode
              displaySettings {
                theme
                color
              }
              locale
              avatar
              enabled
              signedInAtLeastOnce
              emailConfirmed
              confirmedTermsOfServiceVersions {
                termsOfServiceVersion
                privacyPolicyVersion
                tenantOwnTermsOfServiceVersion
              }
              createdAt
              updatedAt
              tags {
                items {
                  id
                  userId
                  userTagId
                  tenantCode
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                  userTag {
                    id
                    color
                    text
                    tenantCode
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
              statuses {
                items {
                  id
                  tenantCode
                  status
                  userId
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
            userTag {
              id
              color
              text
              tenantCode
              createdAt
              updatedAt
              users {
                items {
                  id
                  userId
                  userTagId
                  tenantCode
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                  userTag {
                    id
                    color
                    text
                    tenantCode
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const onCreateUserStatusTableByTenantCode = /* GraphQL */ `
  subscription OnCreateUserStatusTableByTenantCode($tenantCode: String!) {
    onCreateUserStatusTableByTenantCode(tenantCode: $tenantCode) {
      id
      tenantCode
      status
      userId
      createdAt
      updatedAt
      user {
        id
        name
        role
        extensionConfigJsonList
        email
        code
        tenantCode
        displaySettings {
          theme
          color
        }
        locale
        avatar
        enabled
        signedInAtLeastOnce
        emailConfirmed
        confirmedTermsOfServiceVersions {
          termsOfServiceVersion
          privacyPolicyVersion
          tenantOwnTermsOfServiceVersion
        }
        createdAt
        updatedAt
        tags {
          items {
            id
            userId
            userTagId
            tenantCode
            createdAt
            updatedAt
            user {
              id
              name
              role
              extensionConfigJsonList
              email
              code
              tenantCode
              displaySettings {
                theme
                color
              }
              locale
              avatar
              enabled
              signedInAtLeastOnce
              emailConfirmed
              confirmedTermsOfServiceVersions {
                termsOfServiceVersion
                privacyPolicyVersion
                tenantOwnTermsOfServiceVersion
              }
              createdAt
              updatedAt
              tags {
                items {
                  id
                  userId
                  userTagId
                  tenantCode
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                  userTag {
                    id
                    color
                    text
                    tenantCode
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
              statuses {
                items {
                  id
                  tenantCode
                  status
                  userId
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
            userTag {
              id
              color
              text
              tenantCode
              createdAt
              updatedAt
              users {
                items {
                  id
                  userId
                  userTagId
                  tenantCode
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                  userTag {
                    id
                    color
                    text
                    tenantCode
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
          }
          nextToken
        }
        statuses {
          items {
            id
            tenantCode
            status
            userId
            createdAt
            updatedAt
            user {
              id
              name
              role
              extensionConfigJsonList
              email
              code
              tenantCode
              displaySettings {
                theme
                color
              }
              locale
              avatar
              enabled
              signedInAtLeastOnce
              emailConfirmed
              confirmedTermsOfServiceVersions {
                termsOfServiceVersion
                privacyPolicyVersion
                tenantOwnTermsOfServiceVersion
              }
              createdAt
              updatedAt
              tags {
                items {
                  id
                  userId
                  userTagId
                  tenantCode
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                  userTag {
                    id
                    color
                    text
                    tenantCode
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
              statuses {
                items {
                  id
                  tenantCode
                  status
                  userId
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const onUpdateUserStatusTableByTenantCode = /* GraphQL */ `
  subscription OnUpdateUserStatusTableByTenantCode($tenantCode: String!) {
    onUpdateUserStatusTableByTenantCode(tenantCode: $tenantCode) {
      id
      tenantCode
      status
      userId
      createdAt
      updatedAt
      user {
        id
        name
        role
        extensionConfigJsonList
        email
        code
        tenantCode
        displaySettings {
          theme
          color
        }
        locale
        avatar
        enabled
        signedInAtLeastOnce
        emailConfirmed
        confirmedTermsOfServiceVersions {
          termsOfServiceVersion
          privacyPolicyVersion
          tenantOwnTermsOfServiceVersion
        }
        createdAt
        updatedAt
        tags {
          items {
            id
            userId
            userTagId
            tenantCode
            createdAt
            updatedAt
            user {
              id
              name
              role
              extensionConfigJsonList
              email
              code
              tenantCode
              displaySettings {
                theme
                color
              }
              locale
              avatar
              enabled
              signedInAtLeastOnce
              emailConfirmed
              confirmedTermsOfServiceVersions {
                termsOfServiceVersion
                privacyPolicyVersion
                tenantOwnTermsOfServiceVersion
              }
              createdAt
              updatedAt
              tags {
                items {
                  id
                  userId
                  userTagId
                  tenantCode
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                  userTag {
                    id
                    color
                    text
                    tenantCode
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
              statuses {
                items {
                  id
                  tenantCode
                  status
                  userId
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
            userTag {
              id
              color
              text
              tenantCode
              createdAt
              updatedAt
              users {
                items {
                  id
                  userId
                  userTagId
                  tenantCode
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                  userTag {
                    id
                    color
                    text
                    tenantCode
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
          }
          nextToken
        }
        statuses {
          items {
            id
            tenantCode
            status
            userId
            createdAt
            updatedAt
            user {
              id
              name
              role
              extensionConfigJsonList
              email
              code
              tenantCode
              displaySettings {
                theme
                color
              }
              locale
              avatar
              enabled
              signedInAtLeastOnce
              emailConfirmed
              confirmedTermsOfServiceVersions {
                termsOfServiceVersion
                privacyPolicyVersion
                tenantOwnTermsOfServiceVersion
              }
              createdAt
              updatedAt
              tags {
                items {
                  id
                  userId
                  userTagId
                  tenantCode
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                  userTag {
                    id
                    color
                    text
                    tenantCode
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
              statuses {
                items {
                  id
                  tenantCode
                  status
                  userId
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const onDeleteUserStatusTableByTenantCode = /* GraphQL */ `
  subscription OnDeleteUserStatusTableByTenantCode($tenantCode: String!) {
    onDeleteUserStatusTableByTenantCode(tenantCode: $tenantCode) {
      id
      tenantCode
      status
      userId
      createdAt
      updatedAt
      user {
        id
        name
        role
        extensionConfigJsonList
        email
        code
        tenantCode
        displaySettings {
          theme
          color
        }
        locale
        avatar
        enabled
        signedInAtLeastOnce
        emailConfirmed
        confirmedTermsOfServiceVersions {
          termsOfServiceVersion
          privacyPolicyVersion
          tenantOwnTermsOfServiceVersion
        }
        createdAt
        updatedAt
        tags {
          items {
            id
            userId
            userTagId
            tenantCode
            createdAt
            updatedAt
            user {
              id
              name
              role
              extensionConfigJsonList
              email
              code
              tenantCode
              displaySettings {
                theme
                color
              }
              locale
              avatar
              enabled
              signedInAtLeastOnce
              emailConfirmed
              confirmedTermsOfServiceVersions {
                termsOfServiceVersion
                privacyPolicyVersion
                tenantOwnTermsOfServiceVersion
              }
              createdAt
              updatedAt
              tags {
                items {
                  id
                  userId
                  userTagId
                  tenantCode
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                  userTag {
                    id
                    color
                    text
                    tenantCode
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
              statuses {
                items {
                  id
                  tenantCode
                  status
                  userId
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
            userTag {
              id
              color
              text
              tenantCode
              createdAt
              updatedAt
              users {
                items {
                  id
                  userId
                  userTagId
                  tenantCode
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                  userTag {
                    id
                    color
                    text
                    tenantCode
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
          }
          nextToken
        }
        statuses {
          items {
            id
            tenantCode
            status
            userId
            createdAt
            updatedAt
            user {
              id
              name
              role
              extensionConfigJsonList
              email
              code
              tenantCode
              displaySettings {
                theme
                color
              }
              locale
              avatar
              enabled
              signedInAtLeastOnce
              emailConfirmed
              confirmedTermsOfServiceVersions {
                termsOfServiceVersion
                privacyPolicyVersion
                tenantOwnTermsOfServiceVersion
              }
              createdAt
              updatedAt
              tags {
                items {
                  id
                  userId
                  userTagId
                  tenantCode
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                  userTag {
                    id
                    color
                    text
                    tenantCode
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
              statuses {
                items {
                  id
                  tenantCode
                  status
                  userId
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const onCreateGroupStatusUserByGroupId = /* GraphQL */ `
  subscription OnCreateGroupStatusUserByGroupId($groupId: ID!) {
    onCreateGroupStatusUserByGroupId(groupId: $groupId) {
      id
      userId
      groupId
      handStatus
      tenantCode
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateGroupStatusUserByGroupId = /* GraphQL */ `
  subscription OnUpdateGroupStatusUserByGroupId($groupId: ID!) {
    onUpdateGroupStatusUserByGroupId(groupId: $groupId) {
      id
      userId
      groupId
      handStatus
      tenantCode
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteGroupStatusUserByGroupId = /* GraphQL */ `
  subscription OnDeleteGroupStatusUserByGroupId($groupId: ID!) {
    onDeleteGroupStatusUserByGroupId(groupId: $groupId) {
      id
      userId
      groupId
      handStatus
      tenantCode
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateInternalTaskById = /* GraphQL */ `
  subscription OnUpdateInternalTaskById($id: ID!) {
    onUpdateInternalTaskById(id: $id) {
      id
      type
      createdAt
      createdBy
      finishedAt
      status
      taskVersion
      payload
      tenantCode
      updatedAt
    }
  }
`;
export const onUpdatePlaygroundById = /* GraphQL */ `
  subscription OnUpdatePlaygroundById($id: ID!) {
    onUpdatePlaygroundById(id: $id) {
      id
      status
      email
      tenantCode
      tenantName
      userCode
      temporaryPassword
      createdAt
      updatedAt
    }
  }
`;
export const onCreateContent = /* GraphQL */ `
  subscription OnCreateContent {
    onCreateContent {
      id
      contentId
      version
      name
      requiredTime
      body {
        type
        text {
          body
        }
        exam {
          problems {
            index
            body
            commentary
            type
            choice {
              options {
                index
                text
              }
              multiple
              answer
            }
            headerId
          }
          problemHeaders {
            id
            body
            createdAt
          }
          passingStandard
        }
      }
      workbook {
        problems {
          index
          body
          commentary
          type
          choice {
            options {
              index
              text
            }
            multiple
            answer
          }
          headerId
        }
        problemHeaders {
          id
          body
          createdAt
        }
      }
      courseId
      courseVersion
      tenantCode
      latest
      versionDescription
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateContent = /* GraphQL */ `
  subscription OnUpdateContent {
    onUpdateContent {
      id
      contentId
      version
      name
      requiredTime
      body {
        type
        text {
          body
        }
        exam {
          problems {
            index
            body
            commentary
            type
            choice {
              options {
                index
                text
              }
              multiple
              answer
            }
            headerId
          }
          problemHeaders {
            id
            body
            createdAt
          }
          passingStandard
        }
      }
      workbook {
        problems {
          index
          body
          commentary
          type
          choice {
            options {
              index
              text
            }
            multiple
            answer
          }
          headerId
        }
        problemHeaders {
          id
          body
          createdAt
        }
      }
      courseId
      courseVersion
      tenantCode
      latest
      versionDescription
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteContent = /* GraphQL */ `
  subscription OnDeleteContent {
    onDeleteContent {
      id
      contentId
      version
      name
      requiredTime
      body {
        type
        text {
          body
        }
        exam {
          problems {
            index
            body
            commentary
            type
            choice {
              options {
                index
                text
              }
              multiple
              answer
            }
            headerId
          }
          problemHeaders {
            id
            body
            createdAt
          }
          passingStandard
        }
      }
      workbook {
        problems {
          index
          body
          commentary
          type
          choice {
            options {
              index
              text
            }
            multiple
            answer
          }
          headerId
        }
        problemHeaders {
          id
          body
          createdAt
        }
      }
      courseId
      courseVersion
      tenantCode
      latest
      versionDescription
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCourse = /* GraphQL */ `
  subscription OnCreateCourse {
    onCreateCourse {
      id
      version
      name
      contents {
        id
        type
        name
        requiredTime
      }
      description
      tenantCode
      color
      image
      fontColorOnImage
      confirmedBy
      createdAt
      versionCreatedBy
      versionCreatedAt
      contentLastUpdatedBy
      contentLastUpdatedAt
      updatedAt
    }
  }
`;
export const onUpdateCourse = /* GraphQL */ `
  subscription OnUpdateCourse {
    onUpdateCourse {
      id
      version
      name
      contents {
        id
        type
        name
        requiredTime
      }
      description
      tenantCode
      color
      image
      fontColorOnImage
      confirmedBy
      createdAt
      versionCreatedBy
      versionCreatedAt
      contentLastUpdatedBy
      contentLastUpdatedAt
      updatedAt
    }
  }
`;
export const onDeleteCourse = /* GraphQL */ `
  subscription OnDeleteCourse {
    onDeleteCourse {
      id
      version
      name
      contents {
        id
        type
        name
        requiredTime
      }
      description
      tenantCode
      color
      image
      fontColorOnImage
      confirmedBy
      createdAt
      versionCreatedBy
      versionCreatedAt
      contentLastUpdatedBy
      contentLastUpdatedAt
      updatedAt
    }
  }
`;
export const onCreateEditingConfirmedContent = /* GraphQL */ `
  subscription OnCreateEditingConfirmedContent {
    onCreateEditingConfirmedContent {
      id
      version
      name
      requiredTime
      body {
        type
        text {
          body
        }
        exam {
          problems {
            index
            body
            commentary
            type
            choice {
              options {
                index
                text
              }
              multiple
              answer
            }
            headerId
          }
          problemHeaders {
            id
            body
            createdAt
          }
          passingStandard
        }
      }
      workbook {
        problems {
          index
          body
          commentary
          type
          choice {
            options {
              index
              text
            }
            multiple
            answer
          }
          headerId
        }
        problemHeaders {
          id
          body
          createdAt
        }
      }
      courseId
      courseVersion
      status
      tenantCode
      createdBy
      updatedBy
      dataVersion
      versionDescription
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateEditingConfirmedContent = /* GraphQL */ `
  subscription OnUpdateEditingConfirmedContent {
    onUpdateEditingConfirmedContent {
      id
      version
      name
      requiredTime
      body {
        type
        text {
          body
        }
        exam {
          problems {
            index
            body
            commentary
            type
            choice {
              options {
                index
                text
              }
              multiple
              answer
            }
            headerId
          }
          problemHeaders {
            id
            body
            createdAt
          }
          passingStandard
        }
      }
      workbook {
        problems {
          index
          body
          commentary
          type
          choice {
            options {
              index
              text
            }
            multiple
            answer
          }
          headerId
        }
        problemHeaders {
          id
          body
          createdAt
        }
      }
      courseId
      courseVersion
      status
      tenantCode
      createdBy
      updatedBy
      dataVersion
      versionDescription
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteEditingConfirmedContent = /* GraphQL */ `
  subscription OnDeleteEditingConfirmedContent {
    onDeleteEditingConfirmedContent {
      id
      version
      name
      requiredTime
      body {
        type
        text {
          body
        }
        exam {
          problems {
            index
            body
            commentary
            type
            choice {
              options {
                index
                text
              }
              multiple
              answer
            }
            headerId
          }
          problemHeaders {
            id
            body
            createdAt
          }
          passingStandard
        }
      }
      workbook {
        problems {
          index
          body
          commentary
          type
          choice {
            options {
              index
              text
            }
            multiple
            answer
          }
          headerId
        }
        problemHeaders {
          id
          body
          createdAt
        }
      }
      courseId
      courseVersion
      status
      tenantCode
      createdBy
      updatedBy
      dataVersion
      versionDescription
      createdAt
      updatedAt
    }
  }
`;
export const onCreateExamResult = /* GraphQL */ `
  subscription OnCreateExamResult {
    onCreateExamResult {
      id
      groupId
      courseId
      contentId
      contentVersion
      userId
      start
      end
      score
      problemCount
      tenantCode
      answers
      visibilityLevel
      groupExamId
      times
      passingStandard
      version
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateExamResult = /* GraphQL */ `
  subscription OnUpdateExamResult {
    onUpdateExamResult {
      id
      groupId
      courseId
      contentId
      contentVersion
      userId
      start
      end
      score
      problemCount
      tenantCode
      answers
      visibilityLevel
      groupExamId
      times
      passingStandard
      version
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteExamResult = /* GraphQL */ `
  subscription OnDeleteExamResult {
    onDeleteExamResult {
      id
      groupId
      courseId
      contentId
      contentVersion
      userId
      start
      end
      score
      problemCount
      tenantCode
      answers
      visibilityLevel
      groupExamId
      times
      passingStandard
      version
      createdAt
      updatedAt
    }
  }
`;
export const onCreateGroupTraining = /* GraphQL */ `
  subscription OnCreateGroupTraining {
    onCreateGroupTraining {
      id
      groupId
      tenantCode
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateGroupTraining = /* GraphQL */ `
  subscription OnUpdateGroupTraining {
    onUpdateGroupTraining {
      id
      groupId
      tenantCode
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteGroupTraining = /* GraphQL */ `
  subscription OnDeleteGroupTraining {
    onDeleteGroupTraining {
      id
      groupId
      tenantCode
      createdAt
      updatedAt
    }
  }
`;
export const onCreateGroupTrainingCourse = /* GraphQL */ `
  subscription OnCreateGroupTrainingCourse {
    onCreateGroupTrainingCourse {
      id
      groupTrainingId
      courseId
      courseVersion
      courseName
      contents {
        id
        name
        requiredTime
        type
        recommendedDateTime
        open
        version
      }
      groupId
      tenantCode
      color
      image
      fontColorOnImage
      displayName
      index
      createdAt
      updatedAt
      groupExams {
        items {
          id
          groupId
          contentId
          content {
            id
            version
            name
            timeLimit
            requiredTime
            indexInCourse
            passingStandard
          }
          problemsJson
          courseId
          course {
            id
            version
            name
            color
            image
            fontColorOnImage
          }
          scheduledStart
          scheduledFinish
          createdBy
          finishedAt
          visibilityLevel
          tenantCode
          times
          groupTrainingCourseId
          problemHeaders {
            id
            body
            createdAt
          }
          timeLimit
          passingStandard
          userIdsToBeTested
          createdAt
          updatedAt
          userExams {
            items {
              id
              userId
              status
              tenantCode
              groupExamId
              groupId
              startedAt
              finishedAt
              createdAt
              updatedAt
              groupExam {
                id
                groupId
                contentId
                content {
                  id
                  version
                  name
                  timeLimit
                  requiredTime
                  indexInCourse
                  passingStandard
                }
                problemsJson
                courseId
                course {
                  id
                  version
                  name
                  color
                  image
                  fontColorOnImage
                }
                scheduledStart
                scheduledFinish
                createdBy
                finishedAt
                visibilityLevel
                tenantCode
                times
                groupTrainingCourseId
                problemHeaders {
                  id
                  body
                  createdAt
                }
                timeLimit
                passingStandard
                userIdsToBeTested
                createdAt
                updatedAt
                userExams {
                  items {
                    id
                    userId
                    status
                    tenantCode
                    groupExamId
                    groupId
                    startedAt
                    finishedAt
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
              }
            }
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const onUpdateGroupTrainingCourse = /* GraphQL */ `
  subscription OnUpdateGroupTrainingCourse {
    onUpdateGroupTrainingCourse {
      id
      groupTrainingId
      courseId
      courseVersion
      courseName
      contents {
        id
        name
        requiredTime
        type
        recommendedDateTime
        open
        version
      }
      groupId
      tenantCode
      color
      image
      fontColorOnImage
      displayName
      index
      createdAt
      updatedAt
      groupExams {
        items {
          id
          groupId
          contentId
          content {
            id
            version
            name
            timeLimit
            requiredTime
            indexInCourse
            passingStandard
          }
          problemsJson
          courseId
          course {
            id
            version
            name
            color
            image
            fontColorOnImage
          }
          scheduledStart
          scheduledFinish
          createdBy
          finishedAt
          visibilityLevel
          tenantCode
          times
          groupTrainingCourseId
          problemHeaders {
            id
            body
            createdAt
          }
          timeLimit
          passingStandard
          userIdsToBeTested
          createdAt
          updatedAt
          userExams {
            items {
              id
              userId
              status
              tenantCode
              groupExamId
              groupId
              startedAt
              finishedAt
              createdAt
              updatedAt
              groupExam {
                id
                groupId
                contentId
                content {
                  id
                  version
                  name
                  timeLimit
                  requiredTime
                  indexInCourse
                  passingStandard
                }
                problemsJson
                courseId
                course {
                  id
                  version
                  name
                  color
                  image
                  fontColorOnImage
                }
                scheduledStart
                scheduledFinish
                createdBy
                finishedAt
                visibilityLevel
                tenantCode
                times
                groupTrainingCourseId
                problemHeaders {
                  id
                  body
                  createdAt
                }
                timeLimit
                passingStandard
                userIdsToBeTested
                createdAt
                updatedAt
                userExams {
                  items {
                    id
                    userId
                    status
                    tenantCode
                    groupExamId
                    groupId
                    startedAt
                    finishedAt
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
              }
            }
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const onDeleteGroupTrainingCourse = /* GraphQL */ `
  subscription OnDeleteGroupTrainingCourse {
    onDeleteGroupTrainingCourse {
      id
      groupTrainingId
      courseId
      courseVersion
      courseName
      contents {
        id
        name
        requiredTime
        type
        recommendedDateTime
        open
        version
      }
      groupId
      tenantCode
      color
      image
      fontColorOnImage
      displayName
      index
      createdAt
      updatedAt
      groupExams {
        items {
          id
          groupId
          contentId
          content {
            id
            version
            name
            timeLimit
            requiredTime
            indexInCourse
            passingStandard
          }
          problemsJson
          courseId
          course {
            id
            version
            name
            color
            image
            fontColorOnImage
          }
          scheduledStart
          scheduledFinish
          createdBy
          finishedAt
          visibilityLevel
          tenantCode
          times
          groupTrainingCourseId
          problemHeaders {
            id
            body
            createdAt
          }
          timeLimit
          passingStandard
          userIdsToBeTested
          createdAt
          updatedAt
          userExams {
            items {
              id
              userId
              status
              tenantCode
              groupExamId
              groupId
              startedAt
              finishedAt
              createdAt
              updatedAt
              groupExam {
                id
                groupId
                contentId
                content {
                  id
                  version
                  name
                  timeLimit
                  requiredTime
                  indexInCourse
                  passingStandard
                }
                problemsJson
                courseId
                course {
                  id
                  version
                  name
                  color
                  image
                  fontColorOnImage
                }
                scheduledStart
                scheduledFinish
                createdBy
                finishedAt
                visibilityLevel
                tenantCode
                times
                groupTrainingCourseId
                problemHeaders {
                  id
                  body
                  createdAt
                }
                timeLimit
                passingStandard
                userIdsToBeTested
                createdAt
                updatedAt
                userExams {
                  items {
                    id
                    userId
                    status
                    tenantCode
                    groupExamId
                    groupId
                    startedAt
                    finishedAt
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
              }
            }
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const onCreateScheduleScheduleTag = /* GraphQL */ `
  subscription OnCreateScheduleScheduleTag {
    onCreateScheduleScheduleTag {
      id
      scheduleId
      scheduleTagId
      scheduleTag {
        id
        color
        text
        groupId
        tenantCode
        createdAt
        updatedAt
        schedules {
          items {
            id
            scheduleId
            scheduleTagId
            scheduleTag {
              id
              color
              text
              groupId
              tenantCode
              createdAt
              updatedAt
              schedules {
                items {
                  id
                  scheduleId
                  scheduleTagId
                  scheduleTag {
                    id
                    color
                    text
                    groupId
                    tenantCode
                    createdAt
                    updatedAt
                  }
                  tenantCode
                  createdAt
                  updatedAt
                  schedule {
                    id
                    groupId
                    start
                    end
                    title
                    body
                    tenantCode
                    createdBy
                    updatedBy
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
            tenantCode
            createdAt
            updatedAt
            schedule {
              id
              groupId
              start
              end
              title
              body
              tenantCode
              createdBy
              updatedBy
              createdAt
              updatedAt
              tags {
                items {
                  id
                  scheduleId
                  scheduleTagId
                  scheduleTag {
                    id
                    color
                    text
                    groupId
                    tenantCode
                    createdAt
                    updatedAt
                  }
                  tenantCode
                  createdAt
                  updatedAt
                  schedule {
                    id
                    groupId
                    start
                    end
                    title
                    body
                    tenantCode
                    createdBy
                    updatedBy
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }
      tenantCode
      createdAt
      updatedAt
      schedule {
        id
        groupId
        start
        end
        title
        body
        tenantCode
        createdBy
        updatedBy
        createdAt
        updatedAt
        tags {
          items {
            id
            scheduleId
            scheduleTagId
            scheduleTag {
              id
              color
              text
              groupId
              tenantCode
              createdAt
              updatedAt
              schedules {
                items {
                  id
                  scheduleId
                  scheduleTagId
                  scheduleTag {
                    id
                    color
                    text
                    groupId
                    tenantCode
                    createdAt
                    updatedAt
                  }
                  tenantCode
                  createdAt
                  updatedAt
                  schedule {
                    id
                    groupId
                    start
                    end
                    title
                    body
                    tenantCode
                    createdBy
                    updatedBy
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
            tenantCode
            createdAt
            updatedAt
            schedule {
              id
              groupId
              start
              end
              title
              body
              tenantCode
              createdBy
              updatedBy
              createdAt
              updatedAt
              tags {
                items {
                  id
                  scheduleId
                  scheduleTagId
                  scheduleTag {
                    id
                    color
                    text
                    groupId
                    tenantCode
                    createdAt
                    updatedAt
                  }
                  tenantCode
                  createdAt
                  updatedAt
                  schedule {
                    id
                    groupId
                    start
                    end
                    title
                    body
                    tenantCode
                    createdBy
                    updatedBy
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const onUpdateScheduleScheduleTag = /* GraphQL */ `
  subscription OnUpdateScheduleScheduleTag {
    onUpdateScheduleScheduleTag {
      id
      scheduleId
      scheduleTagId
      scheduleTag {
        id
        color
        text
        groupId
        tenantCode
        createdAt
        updatedAt
        schedules {
          items {
            id
            scheduleId
            scheduleTagId
            scheduleTag {
              id
              color
              text
              groupId
              tenantCode
              createdAt
              updatedAt
              schedules {
                items {
                  id
                  scheduleId
                  scheduleTagId
                  scheduleTag {
                    id
                    color
                    text
                    groupId
                    tenantCode
                    createdAt
                    updatedAt
                  }
                  tenantCode
                  createdAt
                  updatedAt
                  schedule {
                    id
                    groupId
                    start
                    end
                    title
                    body
                    tenantCode
                    createdBy
                    updatedBy
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
            tenantCode
            createdAt
            updatedAt
            schedule {
              id
              groupId
              start
              end
              title
              body
              tenantCode
              createdBy
              updatedBy
              createdAt
              updatedAt
              tags {
                items {
                  id
                  scheduleId
                  scheduleTagId
                  scheduleTag {
                    id
                    color
                    text
                    groupId
                    tenantCode
                    createdAt
                    updatedAt
                  }
                  tenantCode
                  createdAt
                  updatedAt
                  schedule {
                    id
                    groupId
                    start
                    end
                    title
                    body
                    tenantCode
                    createdBy
                    updatedBy
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }
      tenantCode
      createdAt
      updatedAt
      schedule {
        id
        groupId
        start
        end
        title
        body
        tenantCode
        createdBy
        updatedBy
        createdAt
        updatedAt
        tags {
          items {
            id
            scheduleId
            scheduleTagId
            scheduleTag {
              id
              color
              text
              groupId
              tenantCode
              createdAt
              updatedAt
              schedules {
                items {
                  id
                  scheduleId
                  scheduleTagId
                  scheduleTag {
                    id
                    color
                    text
                    groupId
                    tenantCode
                    createdAt
                    updatedAt
                  }
                  tenantCode
                  createdAt
                  updatedAt
                  schedule {
                    id
                    groupId
                    start
                    end
                    title
                    body
                    tenantCode
                    createdBy
                    updatedBy
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
            tenantCode
            createdAt
            updatedAt
            schedule {
              id
              groupId
              start
              end
              title
              body
              tenantCode
              createdBy
              updatedBy
              createdAt
              updatedAt
              tags {
                items {
                  id
                  scheduleId
                  scheduleTagId
                  scheduleTag {
                    id
                    color
                    text
                    groupId
                    tenantCode
                    createdAt
                    updatedAt
                  }
                  tenantCode
                  createdAt
                  updatedAt
                  schedule {
                    id
                    groupId
                    start
                    end
                    title
                    body
                    tenantCode
                    createdBy
                    updatedBy
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const onDeleteScheduleScheduleTag = /* GraphQL */ `
  subscription OnDeleteScheduleScheduleTag {
    onDeleteScheduleScheduleTag {
      id
      scheduleId
      scheduleTagId
      scheduleTag {
        id
        color
        text
        groupId
        tenantCode
        createdAt
        updatedAt
        schedules {
          items {
            id
            scheduleId
            scheduleTagId
            scheduleTag {
              id
              color
              text
              groupId
              tenantCode
              createdAt
              updatedAt
              schedules {
                items {
                  id
                  scheduleId
                  scheduleTagId
                  scheduleTag {
                    id
                    color
                    text
                    groupId
                    tenantCode
                    createdAt
                    updatedAt
                  }
                  tenantCode
                  createdAt
                  updatedAt
                  schedule {
                    id
                    groupId
                    start
                    end
                    title
                    body
                    tenantCode
                    createdBy
                    updatedBy
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
            tenantCode
            createdAt
            updatedAt
            schedule {
              id
              groupId
              start
              end
              title
              body
              tenantCode
              createdBy
              updatedBy
              createdAt
              updatedAt
              tags {
                items {
                  id
                  scheduleId
                  scheduleTagId
                  scheduleTag {
                    id
                    color
                    text
                    groupId
                    tenantCode
                    createdAt
                    updatedAt
                  }
                  tenantCode
                  createdAt
                  updatedAt
                  schedule {
                    id
                    groupId
                    start
                    end
                    title
                    body
                    tenantCode
                    createdBy
                    updatedBy
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }
      tenantCode
      createdAt
      updatedAt
      schedule {
        id
        groupId
        start
        end
        title
        body
        tenantCode
        createdBy
        updatedBy
        createdAt
        updatedAt
        tags {
          items {
            id
            scheduleId
            scheduleTagId
            scheduleTag {
              id
              color
              text
              groupId
              tenantCode
              createdAt
              updatedAt
              schedules {
                items {
                  id
                  scheduleId
                  scheduleTagId
                  scheduleTag {
                    id
                    color
                    text
                    groupId
                    tenantCode
                    createdAt
                    updatedAt
                  }
                  tenantCode
                  createdAt
                  updatedAt
                  schedule {
                    id
                    groupId
                    start
                    end
                    title
                    body
                    tenantCode
                    createdBy
                    updatedBy
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
            tenantCode
            createdAt
            updatedAt
            schedule {
              id
              groupId
              start
              end
              title
              body
              tenantCode
              createdBy
              updatedBy
              createdAt
              updatedAt
              tags {
                items {
                  id
                  scheduleId
                  scheduleTagId
                  scheduleTag {
                    id
                    color
                    text
                    groupId
                    tenantCode
                    createdAt
                    updatedAt
                  }
                  tenantCode
                  createdAt
                  updatedAt
                  schedule {
                    id
                    groupId
                    start
                    end
                    title
                    body
                    tenantCode
                    createdBy
                    updatedBy
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const onCreateSchedule = /* GraphQL */ `
  subscription OnCreateSchedule {
    onCreateSchedule {
      id
      groupId
      start
      end
      title
      body
      tenantCode
      createdBy
      updatedBy
      createdAt
      updatedAt
      tags {
        items {
          id
          scheduleId
          scheduleTagId
          scheduleTag {
            id
            color
            text
            groupId
            tenantCode
            createdAt
            updatedAt
            schedules {
              items {
                id
                scheduleId
                scheduleTagId
                scheduleTag {
                  id
                  color
                  text
                  groupId
                  tenantCode
                  createdAt
                  updatedAt
                  schedules {
                    nextToken
                  }
                }
                tenantCode
                createdAt
                updatedAt
                schedule {
                  id
                  groupId
                  start
                  end
                  title
                  body
                  tenantCode
                  createdBy
                  updatedBy
                  createdAt
                  updatedAt
                  tags {
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
          tenantCode
          createdAt
          updatedAt
          schedule {
            id
            groupId
            start
            end
            title
            body
            tenantCode
            createdBy
            updatedBy
            createdAt
            updatedAt
            tags {
              items {
                id
                scheduleId
                scheduleTagId
                scheduleTag {
                  id
                  color
                  text
                  groupId
                  tenantCode
                  createdAt
                  updatedAt
                  schedules {
                    nextToken
                  }
                }
                tenantCode
                createdAt
                updatedAt
                schedule {
                  id
                  groupId
                  start
                  end
                  title
                  body
                  tenantCode
                  createdBy
                  updatedBy
                  createdAt
                  updatedAt
                  tags {
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const onUpdateSchedule = /* GraphQL */ `
  subscription OnUpdateSchedule {
    onUpdateSchedule {
      id
      groupId
      start
      end
      title
      body
      tenantCode
      createdBy
      updatedBy
      createdAt
      updatedAt
      tags {
        items {
          id
          scheduleId
          scheduleTagId
          scheduleTag {
            id
            color
            text
            groupId
            tenantCode
            createdAt
            updatedAt
            schedules {
              items {
                id
                scheduleId
                scheduleTagId
                scheduleTag {
                  id
                  color
                  text
                  groupId
                  tenantCode
                  createdAt
                  updatedAt
                  schedules {
                    nextToken
                  }
                }
                tenantCode
                createdAt
                updatedAt
                schedule {
                  id
                  groupId
                  start
                  end
                  title
                  body
                  tenantCode
                  createdBy
                  updatedBy
                  createdAt
                  updatedAt
                  tags {
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
          tenantCode
          createdAt
          updatedAt
          schedule {
            id
            groupId
            start
            end
            title
            body
            tenantCode
            createdBy
            updatedBy
            createdAt
            updatedAt
            tags {
              items {
                id
                scheduleId
                scheduleTagId
                scheduleTag {
                  id
                  color
                  text
                  groupId
                  tenantCode
                  createdAt
                  updatedAt
                  schedules {
                    nextToken
                  }
                }
                tenantCode
                createdAt
                updatedAt
                schedule {
                  id
                  groupId
                  start
                  end
                  title
                  body
                  tenantCode
                  createdBy
                  updatedBy
                  createdAt
                  updatedAt
                  tags {
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const onDeleteSchedule = /* GraphQL */ `
  subscription OnDeleteSchedule {
    onDeleteSchedule {
      id
      groupId
      start
      end
      title
      body
      tenantCode
      createdBy
      updatedBy
      createdAt
      updatedAt
      tags {
        items {
          id
          scheduleId
          scheduleTagId
          scheduleTag {
            id
            color
            text
            groupId
            tenantCode
            createdAt
            updatedAt
            schedules {
              items {
                id
                scheduleId
                scheduleTagId
                scheduleTag {
                  id
                  color
                  text
                  groupId
                  tenantCode
                  createdAt
                  updatedAt
                  schedules {
                    nextToken
                  }
                }
                tenantCode
                createdAt
                updatedAt
                schedule {
                  id
                  groupId
                  start
                  end
                  title
                  body
                  tenantCode
                  createdBy
                  updatedBy
                  createdAt
                  updatedAt
                  tags {
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
          tenantCode
          createdAt
          updatedAt
          schedule {
            id
            groupId
            start
            end
            title
            body
            tenantCode
            createdBy
            updatedBy
            createdAt
            updatedAt
            tags {
              items {
                id
                scheduleId
                scheduleTagId
                scheduleTag {
                  id
                  color
                  text
                  groupId
                  tenantCode
                  createdAt
                  updatedAt
                  schedules {
                    nextToken
                  }
                }
                tenantCode
                createdAt
                updatedAt
                schedule {
                  id
                  groupId
                  start
                  end
                  title
                  body
                  tenantCode
                  createdBy
                  updatedBy
                  createdAt
                  updatedAt
                  tags {
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const onCreateComment = /* GraphQL */ `
  subscription OnCreateComment {
    onCreateComment {
      id
      questionId
      body
      createdBy
      createdAt
      editedBy
      editedAt
      tenantCode
      groupId
      updatedAt
    }
  }
`;
export const onUpdateComment = /* GraphQL */ `
  subscription OnUpdateComment {
    onUpdateComment {
      id
      questionId
      body
      createdBy
      createdAt
      editedBy
      editedAt
      tenantCode
      groupId
      updatedAt
    }
  }
`;
export const onDeleteComment = /* GraphQL */ `
  subscription OnDeleteComment {
    onDeleteComment {
      id
      questionId
      body
      createdBy
      createdAt
      editedBy
      editedAt
      tenantCode
      groupId
      updatedAt
    }
  }
`;
export const onCreateQuestion = /* GraphQL */ `
  subscription OnCreateQuestion {
    onCreateQuestion {
      id
      createdBy
      resolved
      resolvedAt
      resolvedBy
      groupId
      title
      referTo {
        contentId
        contentVersion
        options {
          text {
            selectionJson
          }
          workbook {
            problemIndex
            selectionJson
          }
        }
      }
      tenantCode
      createdAt
      assignees
      updatedAt
      comments {
        items {
          id
          questionId
          body
          createdBy
          createdAt
          editedBy
          editedAt
          tenantCode
          groupId
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onUpdateQuestion = /* GraphQL */ `
  subscription OnUpdateQuestion {
    onUpdateQuestion {
      id
      createdBy
      resolved
      resolvedAt
      resolvedBy
      groupId
      title
      referTo {
        contentId
        contentVersion
        options {
          text {
            selectionJson
          }
          workbook {
            problemIndex
            selectionJson
          }
        }
      }
      tenantCode
      createdAt
      assignees
      updatedAt
      comments {
        items {
          id
          questionId
          body
          createdBy
          createdAt
          editedBy
          editedAt
          tenantCode
          groupId
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onDeleteQuestion = /* GraphQL */ `
  subscription OnDeleteQuestion {
    onDeleteQuestion {
      id
      createdBy
      resolved
      resolvedAt
      resolvedBy
      groupId
      title
      referTo {
        contentId
        contentVersion
        options {
          text {
            selectionJson
          }
          workbook {
            problemIndex
            selectionJson
          }
        }
      }
      tenantCode
      createdAt
      assignees
      updatedAt
      comments {
        items {
          id
          questionId
          body
          createdBy
          createdAt
          editedBy
          editedAt
          tenantCode
          groupId
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onCreateContentLearning = /* GraphQL */ `
  subscription OnCreateContentLearning {
    onCreateContentLearning {
      groupId
      courseId
      contentId
      contentType
      userId
      status
      usageTime
      lastStartedAt
      dataVersion
      tenantCode
      completedAt
      completedUsageTime
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateContentLearning = /* GraphQL */ `
  subscription OnUpdateContentLearning {
    onUpdateContentLearning {
      groupId
      courseId
      contentId
      contentType
      userId
      status
      usageTime
      lastStartedAt
      dataVersion
      tenantCode
      completedAt
      completedUsageTime
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteContentLearning = /* GraphQL */ `
  subscription OnDeleteContentLearning {
    onDeleteContentLearning {
      groupId
      courseId
      contentId
      contentType
      userId
      status
      usageTime
      lastStartedAt
      dataVersion
      tenantCode
      completedAt
      completedUsageTime
      createdAt
      updatedAt
    }
  }
`;
