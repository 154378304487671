import { assertIsDefined } from '@/utils/Asserts';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import {
  AppContextProvider,
  AuthorizationService,
  GroupId,
  GroupStatusService,
  GroupStatusUser,
} from '../domains';
import { AbstractUseCase, UseCase, UseCaseResponse } from './UseCase';

export type LowerHandRequest = {
  groupId: GroupId;
};

export type LowerHandResponse = {
  groupStatusUser: GroupStatusUser;
};

export interface LowerHand extends UseCase<LowerHandRequest, LowerHandResponse> {
  execute(request: LowerHandRequest): Promise<UseCaseResponse<LowerHandResponse>>;
}

export class LowerHandImpl
  extends AbstractUseCase<LowerHandRequest, LowerHandResponse>
  implements LowerHand
{
  constructor(
    private appContextProvider: AppContextProvider,
    private authorizationService: AuthorizationService,
    private groupStatusService: GroupStatusService
  ) {
    super('base.LowerHand');
  }

  async internalExecute({ groupId }: LowerHandRequest): Promise<LowerHandResponse> {
    this.authorizationService.assertGroupWriteAccessible(groupId);
    const userId = this.appContextProvider.get().user?.id;
    assertIsDefined(userId, 'appContext.user');
    const groupStatusUser = await this.groupStatusService.lowerHand({ userId, groupId });
    return {
      groupStatusUser,
    };
  }
}

export const LowerHandKey = injectionKeyOf<LowerHand>({
  boundedContext: 'base',
  type: 'usecase',
  name: 'LowerHand',
});

export function useLowerHand(): LowerHand {
  return requiredInject(LowerHandKey);
}
