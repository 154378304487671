import { EditingCourseData } from '@/contents/domains';

export const editingCourses: Array<EditingCourseData> = [
  {
    id: 'course-1',
    name: 'コンピュータ基礎',
    version: 1,
    status: 'editing',
    description: 'desc',
    contents: [
      { id: 'content-1-0', name: '作成中テキスト', type: 'text', requiredTime: 10 },
      { id: 'content-1-1', name: '作成中テスト', type: 'exam', requiredTime: 10 },
    ],
    createdBy: 'admin00',
    updatedBy: 'admin00',
  },
  {
    id: 'course-0',
    name: 'Java入門',
    version: 2,
    status: 'editing',
    description: '編集中',
    contents: [
      { id: 'content00-1', name: '1) Javaとは', type: 'text', requiredTime: 10 },
      { id: 'content01-1', name: '2) メソッドとフィールド', type: 'text', requiredTime: 10 },
      { id: 'content02-1', name: '3) リテラル', type: 'text', requiredTime: 10 },
      { id: 'content03-1', name: '4) 模擬試験A', type: 'exam', requiredTime: 10 },
    ],
    createdBy: 'admin00',
    updatedBy: 'admin00',
  },
];
