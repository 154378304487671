import { AuthorizationService, GroupId } from '@/base/domains';
import { Memo, MemoContentReference, MemoScope } from '@/base/domains/Memo';
import { MarkDownString } from '@/base/types';
import { AbstractUseCase, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { MemoRepository } from '../domains';

export type CreateMemoRequest = {
  body: MarkDownString;
  referTo?: MemoContentReference;
  scope: MemoScope;
  groupId: GroupId;
};

export type CreateMemoResponse = {
  memo: Memo;
};

export interface CreateMemo extends UseCase<CreateMemoRequest, CreateMemoResponse> {
  execute(request: CreateMemoRequest): Promise<UseCaseResponse<CreateMemoResponse>>;
}

export class CreateMemoImpl
  extends AbstractUseCase<CreateMemoRequest, CreateMemoResponse>
  implements CreateMemo
{
  constructor(
    private authorizationService: AuthorizationService,
    private memoRepository: MemoRepository
  ) {
    super('training.CreateMemo');
  }

  async internalExecute(request: CreateMemoRequest): Promise<CreateMemoResponse> {
    const { body, referTo, scope, groupId } = request;
    if (scope.type === 'group') {
      this.authorizationService.assertTrainerOrMentorInGroup(groupId);
    }
    const saved = await this.memoRepository.save({
      body,
      referTo,
      scope,
      groupId,
    });
    return { memo: saved };
  }
}

export const CreateMemoKey = injectionKeyOf<CreateMemo>({
  boundedContext: 'training',
  type: 'usecase',
  name: 'CreateMemo',
});

export function useCreateMemo(): CreateMemo {
  return requiredInject(CreateMemoKey);
}
