import { assertCheckIllegalState, assertIsDefined } from '@/utils/Asserts';

import { LocalDateTime } from '../types';
import {
  NotificationData,
  NotificationEntity,
  NotificationId,
  NotificationType,
  ReadType,
} from './Notification';

export class NotificationEntityImpl<P extends object> implements NotificationEntity<P> {
  readonly id: NotificationId;

  readonly type: NotificationType<P>;

  readonly payload: P;

  readonly userId: string;

  readonly read: ReadType;

  readonly createdAt: LocalDateTime;

  constructor(data: NotificationData<P>) {
    assertIsDefined(data.createdAt, 'createdAt');
    this.id = data.id;
    this.type = data.type;
    this.payload = data.payload;
    this.userId = data.userId;
    this.read = data.read;
    this.createdAt = data.createdAt;
  }

  toggleRead(): NotificationEntity<P> {
    const read = this.read === 'read' ? 'unread' : 'read';
    return new NotificationEntityImpl<P>({
      id: this.id,
      type: this.type,
      payload: this.payload,
      userId: this.userId,
      read,
      createdAt: this.createdAt,
    });
  }

  asTypeOf<P extends object>(type: NotificationType<P>): NotificationEntity<P> {
    assertCheckIllegalState(
      type === this.type,
      `cast to ${type.toString()} notification, but actual type is ${this.type.toString()}`
    );
    return this as unknown as NotificationEntity<P>;
  }
}
