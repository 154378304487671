import { URI } from '../types';
import { InternalTaskError, internalTaskTypeOf } from './InternalTask';

export const ENCODE_AUDIO = internalTaskTypeOf<{
  sourceKey: string;
  url?: URI;
  filename: string;
  errors: InternalTaskError[];
}>('ENCODE_AUDIO');

export const ENCODE_VIDEO = internalTaskTypeOf<{
  sourceKey: string;
  url?: URI;
  filename: string;
  errors: InternalTaskError[];
}>('ENCODE_VIDEO');
