import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import {
  AuthorizationService,
  DisplaySettings,
  localEventBus,
  User,
  UserId,
  UserRepository,
} from '../domains';
import { userDisplaySettingsChanged } from '../domains/LocalEvents';
import { AbstractUseCase, UseCase, UseCaseResponse } from './UseCase';
import { assertEntityExists } from './UseCaseAsserts';

export interface ChangeUserDisplaySettingsRequest {
  id: UserId;
  displaySettings: DisplaySettings;
}

export type ChangeUserDisplaySettingsResponse = {
  user: User;
};

export interface ChangeUserDisplaySettings
  extends UseCase<ChangeUserDisplaySettingsRequest, ChangeUserDisplaySettingsResponse> {
  execute(
    request: ChangeUserDisplaySettingsRequest
  ): Promise<UseCaseResponse<ChangeUserDisplaySettingsResponse>>;
}

export class ChangeUserDisplaySettingsImpl
  extends AbstractUseCase<ChangeUserDisplaySettingsRequest, ChangeUserDisplaySettingsResponse>
  implements ChangeUserDisplaySettings
{
  private authorizationService: AuthorizationService;

  private userRepository: UserRepository;

  constructor(authorizationService: AuthorizationService, userRepository: UserRepository) {
    super('base.ChangeUserDisplaySettings');
    this.authorizationService = authorizationService;
    this.userRepository = userRepository;
  }

  async internalExecute(
    request: ChangeUserDisplaySettingsRequest
  ): Promise<ChangeUserDisplaySettingsResponse> {
    const { id, displaySettings } = request;
    this.authorizationService.assertOwnerAccessible(id);
    const user = await this.userRepository.findById(id);
    assertEntityExists(user, 'user');
    const saved = await this.userRepository.save(user.changeDisplaySettings(displaySettings));
    localEventBus.publish(userDisplaySettingsChanged({ userId: user.id }));
    return {
      user: saved,
    };
  }
}

export const ChangeUserDisplaySettingsKey = injectionKeyOf<ChangeUserDisplaySettings>({
  boundedContext: 'base',
  type: 'usecase',
  name: 'ChangeUserDisplaySettings',
});

export function useChangeUserDisplaySettings(): ChangeUserDisplaySettings {
  return requiredInject(ChangeUserDisplaySettingsKey);
}
