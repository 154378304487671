import * as customMutations from '@/amplify/customMutations';
import * as customQueries from '@/amplify/customQueries';
import { CreateUserTagInput, UpdateUserTagInput } from '@/API';
import {
  AppContextProvider,
  EntityData,
  hasId,
  UserTagAttributes,
  UserTagColor,
  UserTagEntity,
  UserTagEntityImpl,
  UserTagId,
  UserTagReference,
  UserTagRepository,
} from '@/base/domains';
import { Optional } from '@/base/types';
import { graphql } from '@/utils/AmplifyUtils';
import { requiredNonNull } from '@/utils/TsUtils';

type AmplifyUserTag = {
  id: UserTagId;
  color: UserTagColor;
  text: string;
};

function toUserTag(e: AmplifyUserTag): UserTagEntity {
  return new UserTagEntityImpl(e);
}

export class AmplifyUserTagRepository implements UserTagRepository {
  private appContextProvider: AppContextProvider;

  constructor(appContextProvider: AppContextProvider) {
    this.appContextProvider = appContextProvider;
  }

  async save(args: UserTagEntity | EntityData<string, UserTagAttributes>): Promise<UserTagEntity> {
    const tenantCode = requiredNonNull(
      this.appContextProvider.get().tenantCode,
      'appContext.tenantCode'
    );

    if (hasId(args)) {
      const input: UpdateUserTagInput = {
        id: args.id,
        color: args.color,
        text: args.text,
        tenantCode,
      };
      const response = await graphql<{ updateUserTag: AmplifyUserTag }>(
        customMutations.updateUserTag,
        {
          input,
        }
      );
      return toUserTag(response.updateUserTag);
    }
    const input: CreateUserTagInput = {
      color: args.color,
      text: args.text,
      tenantCode,
    };
    const response = await graphql<{ createUserTag: AmplifyUserTag }>(
      customMutations.createUserTag,
      {
        input,
      }
    );
    return toUserTag(response.createUserTag);
  }

  async findById(id: UserTagId): Promise<Optional<UserTagEntity>> {
    const response = await graphql<{ getUserTag: Optional<AmplifyUserTag> }>(
      customQueries.getUserTag,
      {
        id,
      }
    );
    return response.getUserTag ? toUserTag(response.getUserTag) : undefined;
  }

  async remove(id: UserTagId): Promise<void> {
    await graphql(customMutations.deleteUserTag, { input: { id } });
  }

  async findTenantUserTags(): Promise<Array<UserTagReference>> {
    const tenantCode = requiredNonNull(
      this.appContextProvider.get().tenantCode,
      'appContext.tenantCode'
    );
    const response = await graphql<{
      userTagsByTenantCode: {
        items: Array<AmplifyUserTag>;
      };
    }>(customQueries.userTagsByTenantCode, {
      tenantCode,
    });
    return response.userTagsByTenantCode.items.map(toUserTag);
  }
}
