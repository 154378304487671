import { INVITE_USER_ERROR_GROUP_USER_LIMIT_EXCEEDED } from '@/account/ErrorCodes';
import {
  AuthorizationService,
  AuthService,
  Email,
  GroupFinder,
  GroupId,
  GroupRole,
  Password,
  Role,
  SignUpReservationQueries,
  TenantDataAdapter,
  UserCode,
  UserId,
  UserName,
  UserRepository,
} from '@/base/domains';
import { AbstractUseCase, assertEntityExists, UseCase, UseCaseResponse } from '@/base/usecases';
import { config } from '@/config';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

type CreateUserByAdminGroup = { id: GroupId; role: GroupRole };
export type CreateUserByAdminRequest =
  | {
      name: UserName;
      email: Email;
      password: Password;
      role: Role;
      groups?: CreateUserByAdminGroup[];
      forcePasswordChange: boolean;
    }
  | {
      name: UserName;
      code: UserCode;
      password: Password;
      role: Role;
      groups?: CreateUserByAdminGroup[];
      forcePasswordChange: boolean;
    };

export type CreateUserByAdminResponse = {
  userId: UserId;
};

export interface CreateUserByAdmin
  extends UseCase<CreateUserByAdminRequest, CreateUserByAdminResponse> {
  execute(request: CreateUserByAdminRequest): Promise<UseCaseResponse<CreateUserByAdminResponse>>;
}

/**
 * 管理者がユーザーを作成する
 */
export class CreateUserByAdminImpl
  extends AbstractUseCase<CreateUserByAdminRequest, CreateUserByAdminResponse>
  implements CreateUserByAdmin
{
  constructor(
    private authorizationService: AuthorizationService,
    private authService: AuthService,
    private userRepository: UserRepository,
    private signUpReservationQueries: SignUpReservationQueries,
    private tenantDataAdapter: TenantDataAdapter,
    private groupFinder: GroupFinder
  ) {
    super('admin.CreateUserByAdmin');
  }

  async internalExecute(request: CreateUserByAdminRequest): Promise<CreateUserByAdminResponse> {
    this.authorizationService.assertRole('supervisor', 'admin');
    const groupIds: GroupId[] = request.groups?.map((g) => g.id) ?? [];
    const groupsPromise =
      groupIds.length > 0
        ? Promise.all(
            groupIds.map(async (groupId) => {
              const group = await this.groupFinder.findById(groupId);
              assertEntityExists(group, 'group');
              return group;
            })
          )
        : Promise.resolve([]);

    const [enableUsers, signUpReservations, tenant, groups] = await Promise.all([
      this.userRepository.findTenantEnabledUsers(),
      this.signUpReservationQueries.findTenantSignUpReservations(),
      this.tenantDataAdapter.get(),
      groupsPromise,
    ]);
    if (tenant.userLimit && tenant.userLimit <= enableUsers.length + signUpReservations.length) {
      throw new Error('number of users has exceeded the limit.');
    }

    const userLimitExceededGroupIds = groups
      .filter((g) => g.users.length + 1 > config().app.userLimitInGroup)
      .map((g) => g.id);
    if (userLimitExceededGroupIds.length > 0) {
      throw INVITE_USER_ERROR_GROUP_USER_LIMIT_EXCEEDED.toApplicationError({
        groupIds: userLimitExceededGroupIds,
      });
    }

    const userId = await this.authService.createUserByAdmin(request);
    return { userId };
  }
}

export const CreateUserByAdminKey = injectionKeyOf<CreateUserByAdmin>({
  boundedContext: 'admin',
  type: 'usecase',
  name: 'CreateUserByAdmin',
});

export function useCreateUserByAdmin(): CreateUserByAdmin {
  return requiredInject(CreateUserByAdminKey);
}
