import { AbstractUseCase, UseCase, UseCaseResponse } from '@/base/usecases/UseCase';
import { assertIsDefined } from '@/utils/Asserts';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import {
  AppContextProvider,
  NotificationAttributes,
  NotificationReference,
  NotificationRepository,
  NotificationType,
} from '../domains';

export interface NotifyRequest<P extends object = {}> {
  type: NotificationType<P>;
  payload?: {};
}

export type NotifyResponse = {
  notification: NotificationReference;
};

/**
 * 通知する
 */
export interface Notify extends UseCase<NotifyRequest, NotifyResponse> {
  execute(request: NotifyRequest): Promise<UseCaseResponse<NotifyResponse>>;
}

export class NotifyImpl extends AbstractUseCase<NotifyRequest, NotifyResponse> implements Notify {
  private notificationRepository: NotificationRepository;

  private appContextProvider: AppContextProvider;

  constructor(
    notificationRepository: NotificationRepository,
    appContextProvider: AppContextProvider
  ) {
    super('base.Notify');
    this.notificationRepository = notificationRepository;
    this.appContextProvider = appContextProvider;
  }

  async internalExecute(request: NotifyRequest): Promise<NotifyResponse> {
    const { type, payload = {} } = request;
    const userId = this.appContextProvider.get().user?.id;
    assertIsDefined(userId, 'appContext.user.id');
    const notification: Omit<NotificationAttributes, 'createdAt'> = {
      type,
      payload,
      userId,
      read: 'unread',
    };
    const saved = await this.notificationRepository.save(notification);
    return {
      notification: saved,
    };
  }
}

export const NotifyKey = injectionKeyOf<Notify>({
  boundedContext: 'base',
  type: 'usecase',
  name: 'Notify',
});

export function useNotify(): Notify {
  return requiredInject(NotifyKey);
}
