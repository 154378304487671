import { AuthorizationService, ContentId, DataVersion } from '@/base/domains';
import {
  AbstractUseCase,
  assertDataVersion,
  assertEntityExists,
  UseCase,
  UseCaseResponse,
} from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import {
  EditingConfirmedContentReference,
  EditingConfirmedContentRepository,
  EditingCourseContentBodyChangeBodyArgs,
} from '../domains';

export interface ChangeEditingConfirmedContentBodyRequest {
  id: ContentId;
  body: EditingCourseContentBodyChangeBodyArgs;
  expectedDataVersion: DataVersion;
}

export type ChangeEditingConfirmedContentBodyResponse = {
  editingConfirmedContent: EditingConfirmedContentReference;
};

/**
 * 編集中確定済みコンテンツボディを変更する
 */
export interface ChangeEditingConfirmedContentBody
  extends UseCase<
    ChangeEditingConfirmedContentBodyRequest,
    ChangeEditingConfirmedContentBodyResponse
  > {
  execute(
    request: ChangeEditingConfirmedContentBodyRequest
  ): Promise<UseCaseResponse<ChangeEditingConfirmedContentBodyResponse>>;
}

export class ChangeEditingConfirmedContentBodyImpl
  extends AbstractUseCase<
    ChangeEditingConfirmedContentBodyRequest,
    ChangeEditingConfirmedContentBodyResponse
  >
  implements ChangeEditingConfirmedContentBody
{
  constructor(
    private authorizationService: AuthorizationService,
    private editingConfirmedContentRepository: EditingConfirmedContentRepository
  ) {
    super('contents.ChangeEditingConfirmedContentBody');
  }

  async internalExecute(
    request: ChangeEditingConfirmedContentBodyRequest
  ): Promise<ChangeEditingConfirmedContentBodyResponse> {
    const { id, expectedDataVersion } = request;
    this.authorizationService.assertContentEditable();
    const entity = await this.editingConfirmedContentRepository.findById(id);
    assertEntityExists(entity, 'editingConfirmedContent');
    assertDataVersion(entity, expectedDataVersion, 'editingConfirmedContent');
    // examのbodyは一部項目をこのユースケースでは変更しないので、取得したものを使用する
    const body = entity.type === 'exam' ? { ...entity.body, ...request.body } : request.body;
    const saved = await this.editingConfirmedContentRepository.save(entity.changeBody(body));
    return {
      editingConfirmedContent: saved,
    };
  }
}

export const ChangeEditingConfirmedContentBodyKey =
  injectionKeyOf<ChangeEditingConfirmedContentBody>({
    boundedContext: 'contents',
    type: 'usecase',
    name: 'ChangeEditingConfirmedContentBody',
  });

export function useChangeEditingConfirmedContentBody(): ChangeEditingConfirmedContentBody {
  return requiredInject(ChangeEditingConfirmedContentBodyKey);
}
