import {
  ContentId,
  ContentName,
  ContentVersion,
  CourseDisplayName,
  CourseId,
  CourseName,
  Question,
} from '../domains';

export type SimpleQuestionContentReferenceWithNames = {
  contentId: ContentId;
  contentVersion: ContentVersion;
  courseId: CourseId;
  courseName: CourseDisplayName;
  contentName: ContentName;
};

export type TextQuestionContentReferenceWithNames = SimpleQuestionContentReferenceWithNames & {
  selection: object;
};

export type QuestionWorkbookReferenceWithNames = SimpleQuestionContentReferenceWithNames & {
  selection: object;
  problemIndex: number;
};

export type QuestionContentReferenceWithNames =
  | SimpleQuestionContentReferenceWithNames
  | TextQuestionContentReferenceWithNames
  | QuestionWorkbookReferenceWithNames;

export type QuestionWithNames = Omit<Question, 'referTo'> & {
  referTo?: QuestionContentReferenceWithNames;
};

export function withNamesConverter(
  names: Map<ContentId, { courseId: CourseId; courseName: CourseName; contentName: ContentName }>
): (q: Question) => QuestionWithNames {
  return (q: Question) => {
    if (q.referTo) {
      const e = names.get(q.referTo.contentId);
      if (!e) {
        return {
          ...q,
          referTo: undefined,
        };
      }
      const { courseId, courseName, contentName } = e;
      return {
        ...q,
        referTo: {
          ...q.referTo,
          courseId,
          courseName,
          contentName,
        },
      };
    }
    return q as QuestionWithNames;
  };
}
