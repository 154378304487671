import { isFunction } from './TsUtils';

export function assertIsDefined<T>(value: T, name = 'value'): asserts value is NonNullable<T> {
  if (value === undefined || value === null) {
    throw new Error(`${name} should be defined; but ${name} is ${value}`);
  }
}

export function assertIsInteger(value?: number, name = 'value') {
  if (!Number.isInteger(value)) {
    throw new Error(`${name} should be Integer; but ${name} is ${value}`);
  }
}

export function assertCheckIllegalState(test: boolean | (() => boolean), message: string) {
  const result = isFunction(test) ? test() : test;
  if (!result) {
    throw new Error(message);
  }
}

export function assertCheckArgument(test: boolean | (() => boolean), message: string) {
  const result = isFunction(test) ? test() : test;
  if (!result) {
    throw new Error(message);
  }
}
