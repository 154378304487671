import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { Locale } from '../domains';

export type MessageList = Array<{ value: string; text: string }>;

export interface MessageService {
  messageOf(key: string, variables?: { [key: string]: string | number }, locale?: Locale): string;
  messageListOf(key: string, locale?: Locale): MessageList;
}

export const MessageServiceKey = injectionKeyOf<MessageService>({
  boundedContext: 'base',
  type: 'service',
  name: 'MessageService',
});

export function useMessageService(): MessageService {
  return requiredInject(MessageServiceKey);
}
