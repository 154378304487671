import { AuthorizationService, Comment, CommentId, DateTimeService } from '@/base/domains';
import { MarkDownString } from '@/base/types';
import { AbstractUseCase, assertEntityExists, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { QuestionRepository } from '../domains';

export type ChangeCommentInQuestionRequest = {
  id: CommentId;
  body: MarkDownString;
};

export type ChangeCommentInQuestionResponse = {
  comment: Comment;
};

export interface ChangeCommentInQuestion
  extends UseCase<ChangeCommentInQuestionRequest, ChangeCommentInQuestionResponse> {
  execute(
    request: ChangeCommentInQuestionRequest
  ): Promise<UseCaseResponse<ChangeCommentInQuestionResponse>>;
}

export class ChangeCommentInQuestionImpl
  extends AbstractUseCase<ChangeCommentInQuestionRequest, ChangeCommentInQuestionResponse>
  implements ChangeCommentInQuestion
{
  constructor(
    private authorizationService: AuthorizationService,
    private questionRepository: QuestionRepository,
    private dateTimeService: DateTimeService
  ) {
    super('training.ChangeCommentInQuestion');
  }

  async internalExecute({
    id,
    body,
  }: ChangeCommentInQuestionRequest): Promise<ChangeCommentInQuestionResponse> {
    const comment = await this.questionRepository.findCommentById(id);
    assertEntityExists(comment, 'comment');
    this.authorizationService.assertGroupWriteAccessible(comment.groupId);
    const editedAt = await this.dateTimeService.strictLocalDateTimeNow();
    const saved = await this.questionRepository.changeCommentBody({
      commentId: id,
      body,
      editedAt,
    });
    return {
      comment: saved,
    };
  }
}

export const ChangeCommentInQuestionKey = injectionKeyOf<ChangeCommentInQuestion>({
  boundedContext: 'training',
  type: 'usecase',
  name: 'ChangeCommentInQuestion',
});

export function useChangeCommentInQuestion(): ChangeCommentInQuestion {
  return requiredInject(ChangeCommentInQuestionKey);
}
