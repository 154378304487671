import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { QuestionnaireId, QuestionnaireRespondent, Subscription } from '../domains';
import { UseCase, UseCaseResponse } from './UseCase';

export type SubscribeQuestionnaireStatusChangedRequest = {
  questionnaireId: QuestionnaireId;
  onChange: (questionnaireRespondent: QuestionnaireRespondent, removed: boolean) => void;
  onError?: (e: Error) => void;
};

export type SubscribeQuestionnaireStatusChangedResponse = {
  subscription: Subscription;
};

export interface SubscribeQuestionnaireStatusChanged
  extends UseCase<
    SubscribeQuestionnaireStatusChangedRequest,
    SubscribeQuestionnaireStatusChangedResponse
  > {
  execute(
    request: SubscribeQuestionnaireStatusChangedRequest
  ): Promise<UseCaseResponse<SubscribeQuestionnaireStatusChangedResponse>>;
}

export const SubscribeQuestionnaireStatusChangedKey =
  injectionKeyOf<SubscribeQuestionnaireStatusChanged>({
    boundedContext: 'training',
    type: 'usecase',
    name: 'SubscribeQuestionnaireStatusChanged',
  });

export function useSubscribeQuestionnaireStatusChanged(): SubscribeQuestionnaireStatusChanged {
  return requiredInject(SubscribeQuestionnaireStatusChangedKey);
}
