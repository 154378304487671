import {
  ContentId,
  Entity,
  EntityReference,
  GroupId,
  GroupRole,
  StandardRepository,
  UserId,
} from '@/base/domains';
import { MemoContentReference, MemoId, MemoScope } from '@/base/domains/Memo';
import { LocalDateTime, MarkDownString, Optional, PlainData } from '@/base/types';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

export interface MemoAttributes {
  body: MarkDownString;
  referTo?: MemoContentReference;
  scope: MemoScope;
  groupId: GroupId;
  createdBy: UserId;
  createdAt: LocalDateTime;
  updatedBy: UserId;
  updatedAt: LocalDateTime;
}

/**
 * メモクエリ
 */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface MemoQueries {}

export interface MemoCommand {
  updateBody(body: MarkDownString): MemoEntity;
  updateScopeRoles(roles: GroupRole[]): MemoEntity;
}

export type MemoReference = EntityReference<MemoId, MemoAttributes, MemoQueries>;

export type MemoArgs = {
  id: MemoId;
  body: MarkDownString;
  referTo?: MemoContentReference;
  scope: MemoScope;
  groupId: GroupId;
  createdBy: UserId;
  createdAt: LocalDateTime;
  updatedBy: UserId;
  updatedAt: LocalDateTime;
};

export type MemoEntity = Entity<MemoId, MemoAttributes> & MemoCommand;

export type MemoData = PlainData<MemoEntity>;

export class MemoEntityImpl implements MemoEntity {
  id: MemoId;

  body: MarkDownString;

  referTo?: MemoContentReference;

  scope: MemoScope;

  groupId: GroupId;

  createdBy: UserId;

  createdAt: LocalDateTime;

  updatedBy: UserId;

  updatedAt: LocalDateTime;

  constructor(data: MemoArgs) {
    this.id = data.id;
    this.body = data.body;
    this.referTo = data.referTo;
    this.scope = data.scope;
    this.groupId = data.groupId;
    this.createdBy = data.createdBy;
    this.createdAt = data.createdAt;
    this.updatedBy = data.updatedBy;
    this.updatedAt = data.updatedAt;
  }

  updateBody(body: MarkDownString): MemoEntity {
    return new MemoEntityImpl({
      ...this,
      body,
    });
  }

  updateScopeRoles(roles: GroupRole[]): MemoEntity {
    return new MemoEntityImpl({
      ...this,
      scope: {
        type: 'group',
        roles,
      },
    });
  }
}

export interface MemoRepository extends StandardRepository<MemoId, MemoAttributes, MemoEntity> {
  findById(id: MemoId): Promise<Optional<MemoEntity>>;
  findByGroupId(groupId: GroupId): Promise<Array<MemoEntity>>;
  findByGroupAndContent(groupId: GroupId, contentId: ContentId): Promise<Array<MemoEntity>>;
}

export const MemoRepositoryKey = injectionKeyOf<MemoRepository>({
  boundedContext: 'training',
  type: 'adapter',
  name: 'MemoRepository',
});

export function useMemoRepository(): MemoRepository {
  return requiredInject(MemoRepositoryKey);
}
