export function shuffle<T>(array: Array<T>): Array<T> {
  const a = array.slice();
  const n = a.length;
  for (let i = n - 1; i > 0; i -= 1) {
    const j = Math.floor(Math.random() * (i + 1));
    const tmp = a[i];
    a[i] = a[j];
    a[j] = tmp;
  }
  return a;
}
