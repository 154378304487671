import {
  GroupId,
  QuestionnaireFinder,
  QuestionnaireId,
  UnansweredQuestionnaire,
  UserId,
} from '@/base/domains';
import { Optional } from '@/base/types';

import {
  QuestionnaireByAnswerer,
  QuestionnaireEntity,
  QuestionnaireReference,
  QuestionnaireRepository,
} from './Questionnaire';

export class QuestionnaireFinderImpl implements QuestionnaireFinder {
  constructor(private questionnaireRepository: QuestionnaireRepository) {}

  async findById(id: QuestionnaireId): Promise<Optional<QuestionnaireEntity>> {
    return this.questionnaireRepository.findById(id);
  }

  async findByGroupId(groupId: GroupId): Promise<QuestionnaireReference[]> {
    return this.questionnaireRepository.findByGroupId(groupId);
  }

  async findActiveQuestionnairesByGroupId(groupId: GroupId): Promise<QuestionnaireReference[]> {
    return this.questionnaireRepository.findActiveQuestionnairesByGroupId(groupId);
  }

  async findUnansweredQuestionnaires(
    groupId: GroupId,
    userId: UserId
  ): Promise<UnansweredQuestionnaire[]> {
    return this.questionnaireRepository.findUnansweredQuestionnaires(groupId, userId);
  }

  async findQuestionnairesByAnswerer(
    groupId: GroupId,
    userId: UserId
  ): Promise<QuestionnaireByAnswerer[]> {
    return this.questionnaireRepository.findQuestionnairesByAnswerer(groupId, userId);
  }
}
