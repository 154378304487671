import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import {
  GroupId,
  GroupName,
  GroupRepository,
  GroupRole,
  UserId,
  UserName,
  UserRepository,
} from '../domains';
import { AbstractUseCase, UseCase, UseCaseResponse } from './UseCase';

export interface GetGroupRequest {
  id: GroupId;
}

export type Group = {
  id: GroupId;
  name: GroupName;
  users: Array<GroupUser>;
};

export type GroupUser = {
  id: UserId;
  role: GroupRole;
  name: UserName;
};

export type GetGroupResponse = {
  group?: Group;
};

/**
 * グループ取得
 */
export interface GetGroup extends UseCase<GetGroupRequest, GetGroupResponse> {
  execute(request: GetGroupRequest): Promise<UseCaseResponse<GetGroupResponse>>;
}

export class GetGroupImpl
  extends AbstractUseCase<GetGroupRequest, GetGroupResponse>
  implements GetGroup
{
  private groupRepository: GroupRepository;

  private userRepository: UserRepository;

  constructor(groupRepository: GroupRepository, userRepository: UserRepository) {
    super('base.GetGroup');
    this.groupRepository = groupRepository;
    this.userRepository = userRepository;
  }

  async internalExecute(request: GetGroupRequest): Promise<GetGroupResponse> {
    const { id } = request;
    const group = await this.groupRepository.findById(id);
    if (!group) {
      return {};
    }
    const users = await this.userRepository.findTenantUsers();

    return {
      group: {
        id: group.id,
        name: group.name,
        users: group.users.map((gu) => {
          const originalUser = users.find((u) => u.id === gu.id);
          return {
            id: gu.id,
            role: gu.role,
            name: originalUser?.name ?? '',
          };
        }),
      },
    };
  }
}

export const GetGroupKey = injectionKeyOf<GetGroup>({
  boundedContext: 'base',
  type: 'usecase',
  name: 'GetGroup',
});

export function useGetGroup(): GetGroup {
  return requiredInject(GetGroupKey);
}
