import { AuthorizationService, ContentId } from '@/base/domains';
import { EXCLUSIVE_CONTROL_ERROR } from '@/base/ErrorCodes';
import { AbstractUseCase, assertEntityExists, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import {
  ContentRepository,
  EditingConfirmedContentData,
  EditingConfirmedContentReference,
  EditingConfirmedContentRepository,
} from '../domains';

/**
 * 確定済みコンテンツの編集を開始する
 */
export type StartConfirmedContentEditingRequest = {
  id: ContentId;
};

export type StartConfirmedContentEditingResponse = {
  editingConfirmedContent: EditingConfirmedContentReference;
};

export interface StartConfirmedContentEditing
  extends UseCase<StartConfirmedContentEditingRequest, StartConfirmedContentEditingResponse> {
  execute(
    request: StartConfirmedContentEditingRequest
  ): Promise<UseCaseResponse<StartConfirmedContentEditingResponse>>;
}

export class StartConfirmedContentEditingImpl
  extends AbstractUseCase<StartConfirmedContentEditingRequest, StartConfirmedContentEditingResponse>
  implements StartConfirmedContentEditing
{
  constructor(
    private authorizationService: AuthorizationService,
    private editingConfirmedContentRepository: EditingConfirmedContentRepository,
    private contentRepository: ContentRepository
  ) {
    super('contents.StartConfirmedContentEditing');
  }

  async internalExecute(
    request: StartConfirmedContentEditingRequest
  ): Promise<StartConfirmedContentEditingResponse> {
    const { id } = request;
    this.authorizationService.assertContentEditable();
    const content = await this.contentRepository.findById(id);
    assertEntityExists(content, 'content');
    if (await this.editingConfirmedContentRepository.findById(id)) {
      throw EXCLUSIVE_CONTROL_ERROR.toApplicationError({ entity: 'editingConfirmedContent' });
    }
    const data: EditingConfirmedContentData =
      content.type === 'text'
        ? {
            id,
            type: content.type,
            name: content.name,
            requiredTime: content.requiredTime,
            body: content.body,
            courseId: content.courseId,
            courseVersion: content.courseVersion,
            workbook: content.workbook
              ? {
                  problemHeaders: content.workbook.problemHeaders,
                  problems: content.workbook.problems,
                }
              : undefined,
            version: content.version + 1,
            status: 'editing',
          }
        : {
            id,
            type: content.type,
            name: content.name,
            requiredTime: content.requiredTime,
            body: content.body,
            courseId: content.courseId,
            courseVersion: content.courseVersion,
            version: content.version + 1,
            status: 'editing',
          };
    const saved = await this.editingConfirmedContentRepository.save(data);
    return {
      editingConfirmedContent: saved,
    };
  }
}

export const StartConfirmedContentEditingKey = injectionKeyOf<StartConfirmedContentEditing>({
  boundedContext: 'contents',
  type: 'usecase',
  name: 'StartConfirmedContentEditing',
});

export function useStartConfirmedContentEditing(): StartConfirmedContentEditing {
  return requiredInject(StartConfirmedContentEditingKey);
}
