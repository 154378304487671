import { AuthService, Email, Password, TenantCode, UserCode, UserId } from '@/base/domains';
import { AbstractUseCase, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

export type SignUpRequest =
  | {
      tenantCode: TenantCode;
      userCode: UserCode;
      userName: string;
      password: Password;
    }
  | {
      tenantCode: TenantCode;
      email: Email;
      userName: string;
      password: Password;
    };

export type SignUpResponse = {
  userId: UserId;
};

export interface SignUp extends UseCase<SignUpRequest, SignUpResponse> {
  execute(request: SignUpRequest): Promise<UseCaseResponse<SignUpResponse>>;
}

export class SignUpImpl extends AbstractUseCase<SignUpRequest, SignUpResponse> implements SignUp {
  private authService: AuthService;

  constructor(authService: AuthService) {
    super('account.SignUp');
    this.authService = authService;
  }

  async internalExecute(req: SignUpRequest): Promise<SignUpResponse> {
    const userId = await this.authService.signUp(req);
    return {
      userId,
    };
  }
}

export const SignUpKey = injectionKeyOf<SignUp>({
  boundedContext: 'account',
  type: 'usecase',
  name: 'SignUp',
});

export function useSignUp(): SignUp {
  return requiredInject(SignUpKey);
}
