import {
  QueryResponse,
  Schedule,
  ScheduleFinder,
  ScheduleFinderFindArgs,
  ScheduleFinderFindRecentlyArgs,
  ScheduleId,
} from '@/base/domains';
import { Optional } from '@/base/types';

import { ScheduleRepository } from './Schedule';

export class ScheduleFinderImpl implements ScheduleFinder {
  constructor(private scheduleRepository: ScheduleRepository) {}

  findRecently(args: ScheduleFinderFindRecentlyArgs): Promise<QueryResponse<Schedule[]>> {
    return this.scheduleRepository.findRecently(args);
  }

  find(args: ScheduleFinderFindArgs): Promise<QueryResponse<Array<Schedule>>> {
    return this.scheduleRepository.find(args);
  }

  findById(id: ScheduleId): Promise<Optional<Schedule>> {
    return this.scheduleRepository.findById(id);
  }
}
