import { GroupExam, GroupExamId } from '@/base/domains';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { UseCase, UseCaseResponse } from './UseCase';

export interface FinishGroupExamRequest {
  id: GroupExamId;
}

export interface FinishGroupExamResponse {
  groupExam: GroupExam;
}

/**
 * テストを開催終了する
 */
export interface FinishGroupExam extends UseCase<FinishGroupExamRequest, FinishGroupExamResponse> {
  execute(request: FinishGroupExamRequest): Promise<UseCaseResponse<FinishGroupExamResponse>>;
}

export const FinishGroupExamKey = injectionKeyOf<FinishGroupExam>({
  boundedContext: 'training',
  type: 'usecase',
  name: 'FinishGroupExam',
});

export function useFinishGroupExam(): FinishGroupExam {
  return requiredInject(FinishGroupExamKey);
}
