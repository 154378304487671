import { useAccountUseCases } from './account';
import { useAdminUseCases } from './admin';
import { useBaseUseCases } from './base';
import { useContentsUsecases } from './contents';
import { useHomeUseCases } from './home';
import { usePlaygroundUseCases } from './playground';
import { useReportUseCases } from './report';
import { useTrainingUseCase } from './training';

export function useUseCases() {
  useBaseUseCases();
  useAdminUseCases();
  useHomeUseCases();
  useReportUseCases();
  useTrainingUseCase();
  useAccountUseCases();
  useContentsUsecases();
  usePlaygroundUseCases();
}
