import { CourseId, Subscription, TenantCode } from '@/base/domains';
import { Optional } from '@/base/types';
import {
  PlaygroundAttributes,
  PlaygroundEntity,
  PlaygroundEntityImpl,
  PlaygroundId,
  PlaygroundRepository,
} from '@/playground/domains';
import { uuid } from '@/utils/UniqueIdGenerator';

export class InMemoryPlaygroundRepository implements PlaygroundRepository {
  private playground: Array<PlaygroundEntity>;

  constructor(playground: Array<PlaygroundEntity>) {
    this.playground = playground;
  }

  private store = new Map<CourseId, PlaygroundEntity>();

  save(args: PlaygroundAttributes): Promise<PlaygroundEntity> {
    const e = new PlaygroundEntityImpl({
      id: uuid(),
      ...args,
    });
    this.store.set(e.id, e);
    return Promise.resolve(e);
  }

  findById(id: PlaygroundId): Promise<Optional<PlaygroundEntity>> {
    return Promise.resolve(this.playground.find((pg) => pg.id === id));
  }

  findByTenantCode(tenantCode: TenantCode): Promise<Optional<PlaygroundEntity>> {
    return Promise.resolve(this.playground.find((e) => e.tenantCode === tenantCode));
  }

  onCreated(_: {
    id: string;
    onSucceeded: (playground: PlaygroundEntity) => void;
    onFailed: (e: Error) => void;
  }): Subscription {
    return {
      unsubscribe: () => {
        // do nothing
      },
      isClosed: () => false,
    };
  }
}
