import { AuthorizationService } from '@/base/domains';
import { MemoId } from '@/base/domains/Memo';
import { AbstractUseCase, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { MemoRepository } from '../domains';

export type RemoveMemoRequest = {
  id: MemoId;
};

export type RemoveMemoResponse = {};

export interface RemoveMemo extends UseCase<RemoveMemoRequest, RemoveMemoResponse> {
  execute(request: RemoveMemoRequest): Promise<UseCaseResponse<RemoveMemoResponse>>;
}

export class RemoveMemoImpl
  extends AbstractUseCase<RemoveMemoRequest, RemoveMemoResponse>
  implements RemoveMemo
{
  constructor(
    private authorizationService: AuthorizationService,
    private memoRepository: MemoRepository
  ) {
    super('training.RemoveMemo');
  }

  async internalExecute(request: RemoveMemoRequest): Promise<RemoveMemoResponse> {
    const { id } = request;
    const memo = await this.memoRepository.findById(id);
    if (!memo) return {};
    if (memo.scope.type === 'group') {
      this.authorizationService.assertTrainerOrMentorInGroup(memo.groupId);
    }
    await this.memoRepository.remove(id);
    return {};
  }
}

export const RemoveMemoKey = injectionKeyOf<RemoveMemo>({
  boundedContext: 'training',
  type: 'usecase',
  name: 'RemoveMemo',
});

export function useRemoveMemo(): RemoveMemo {
  return requiredInject(RemoveMemoKey);
}
