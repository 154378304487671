import { AuthorizationService, ContentId, DataVersion, ProblemHeaderId } from '@/base/domains';
import { MarkDownString } from '@/base/types';
import {
  AbstractUseCase,
  assertDataVersion,
  assertEntityExists,
  UseCase,
  UseCaseResponse,
} from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import {
  EditingCourseContentWorkbookReference,
  EditingCourseContentWorkbookRepository,
} from '../domains';

export type ChangeEditingCourseContentWorkbookProblemHeaderRequest = {
  id: ContentId;
  problemHeaderId: ProblemHeaderId;
  body: MarkDownString;
  expectedDataVersion: DataVersion;
};

export type ChangeEditingCourseContentWorkbookProblemHeaderResponse = {
  workbook: EditingCourseContentWorkbookReference;
};

/**
 * 編集中コンテンツ練習問題の問題ヘッダーを編集する
 */
export interface ChangeEditingCourseContentWorkbookProblemHeader
  extends UseCase<
    ChangeEditingCourseContentWorkbookProblemHeaderRequest,
    ChangeEditingCourseContentWorkbookProblemHeaderResponse
  > {
  execute(
    request: ChangeEditingCourseContentWorkbookProblemHeaderRequest
  ): Promise<UseCaseResponse<ChangeEditingCourseContentWorkbookProblemHeaderResponse>>;
}

export class ChangeEditingCourseContentWorkbookProblemHeaderImpl
  extends AbstractUseCase<
    ChangeEditingCourseContentWorkbookProblemHeaderRequest,
    ChangeEditingCourseContentWorkbookProblemHeaderResponse
  >
  implements ChangeEditingCourseContentWorkbookProblemHeader
{
  private authorizationService: AuthorizationService;

  private editingCourseContentWorkbookRepository: EditingCourseContentWorkbookRepository;

  constructor(
    authorizationService: AuthorizationService,
    editingCourseContentWorkbookRepository: EditingCourseContentWorkbookRepository
  ) {
    super('contents.ChangeEditingCourseContentWorkbookProblemHeader');
    this.authorizationService = authorizationService;
    this.editingCourseContentWorkbookRepository = editingCourseContentWorkbookRepository;
  }

  async internalExecute(
    request: ChangeEditingCourseContentWorkbookProblemHeaderRequest
  ): Promise<ChangeEditingCourseContentWorkbookProblemHeaderResponse> {
    const { id, problemHeaderId, body, expectedDataVersion } = request;
    this.authorizationService.assertContentEditable();
    const workbook = await this.editingCourseContentWorkbookRepository.findById(id);
    assertEntityExists(workbook, 'editingCourseContentWorkbook');
    assertDataVersion(workbook, expectedDataVersion, 'editingCourseContentWorkbook');
    const saved = await this.editingCourseContentWorkbookRepository.save(
      workbook.changeProblemHeader({
        id: problemHeaderId,
        body,
      })
    );
    return {
      workbook: saved,
    };
  }
}

export const ChangeEditingCourseContentWorkbookProblemHeaderKey =
  injectionKeyOf<ChangeEditingCourseContentWorkbookProblemHeader>({
    boundedContext: 'contents',
    type: 'usecase',
    name: 'ChangeEditingCourseContentWorkbookProblemHeader',
  });

export function useChangeEditingCourseContentWorkbookProblemHeader(): ChangeEditingCourseContentWorkbookProblemHeader {
  return requiredInject(ChangeEditingCourseContentWorkbookProblemHeaderKey);
}
