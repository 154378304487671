import { ContentId, ContentVersion, CourseId, EventStore, GroupId } from '@/base/domains';
import { AbstractUseCase, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { ContentExited, contentExitedFactory } from '../domains';

export interface ExitContentRequest {
  /** グループID */
  groupId: GroupId;
  /** コースID */
  courseId: CourseId;
  /** コンテンツID */
  contentId: ContentId;
  /** コンテンツバージョン */
  contentVersion: ContentVersion;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export type ExitContentResponse = {};

export interface ExitContent extends UseCase<ExitContentRequest, ExitContentResponse> {
  execute(request: ExitContentRequest): Promise<UseCaseResponse<ExitContentResponse>>;
}

export class ExitContentImpl
  extends AbstractUseCase<ExitContentRequest, ExitContentResponse>
  implements ExitContent
{
  private eventStore: EventStore;

  constructor(eventStore: EventStore) {
    super('training.ExitContent');
    this.eventStore = eventStore;
  }

  async internalExecute(request: ExitContentRequest): Promise<ExitContentResponse> {
    const event: ContentExited = request;
    await this.eventStore.save(contentExitedFactory(event));
    return Promise.resolve({});
  }
}

export const ExitContentKey = injectionKeyOf<ExitContent>({
  boundedContext: 'training',
  type: 'usecase',
  name: 'ExitContent',
});

export function useExitContent(): ExitContent {
  return requiredInject(ExitContentKey);
}
