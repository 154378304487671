import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { GroupId, Questionnaire } from '../domains';
import { UseCase, UseCaseResponse } from './UseCase';

export type GetQuestionnairesRequest = {
  groupId: GroupId;
};

export type GetQuestionnairesResponse = {
  questionnaires: Array<Questionnaire>;
};

export interface GetQuestionnaires
  extends UseCase<GetQuestionnairesRequest, GetQuestionnairesResponse> {
  execute(request: GetQuestionnairesRequest): Promise<UseCaseResponse<GetQuestionnairesResponse>>;
}

export const GetQuestionnairesKey = injectionKeyOf<GetQuestionnaires>({
  boundedContext: 'training',
  type: 'usecase',
  name: 'GetQuestionnaires',
});

export function useGetQuestionnaires(): GetQuestionnaires {
  return requiredInject(GetQuestionnairesKey);
}
