import { Optional } from '../types';
import { UserId } from './Core';
import { User, UserFinder, UserRepository } from './User';

export class UserFinderImpl implements UserFinder {
  private userRepository: UserRepository;

  constructor(userRepository: UserRepository) {
    this.userRepository = userRepository;
  }

  findTenantUsers(): Promise<Array<User>> {
    return this.userRepository.findTenantUsers();
  }

  findTenantEnabledUsers(): Promise<Array<User>> {
    return this.userRepository.findTenantEnabledUsers();
  }

  findById(id: UserId): Promise<Optional<User>> {
    return this.userRepository.findById(id);
  }
}
