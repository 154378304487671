import { Email } from '@/base/domains';
import { AbstractUseCase, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { PlaygroundReference, PlaygroundRepository } from '../domains/Playground';

export type RequestToCreatePlaygroundRequest = {
  email: Email;
};

export type RequestToCreatePlaygroundResponse = {
  playground: PlaygroundReference;
};

/**
 * プレイグラウンド作成をリクエストする
 */
export interface RequestToCreatePlayground
  extends UseCase<RequestToCreatePlaygroundRequest, RequestToCreatePlaygroundResponse> {
  execute(
    request: RequestToCreatePlaygroundRequest
  ): Promise<UseCaseResponse<RequestToCreatePlaygroundResponse>>;
}

function randomString(size: number): string {
  const C = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  return Array.from(Array(size))
    .map(() => C[Math.floor(Math.random() * C.length)])
    .join('');
}

const PLAYGROUND_TENANT_CODE_PREFIX = 'playground';

export class RequestToCreatePlaygroundImpl
  extends AbstractUseCase<RequestToCreatePlaygroundRequest, RequestToCreatePlaygroundResponse>
  implements RequestToCreatePlayground
{
  constructor(
    private playgroundRepository: PlaygroundRepository,
    private tenantCodeFactory: () => string = () =>
      `${PLAYGROUND_TENANT_CODE_PREFIX}${randomString(6)}`
  ) {
    super('playground.RequestToCreatePlayground');
  }

  async internalExecute({
    email,
  }: RequestToCreatePlaygroundRequest): Promise<RequestToCreatePlaygroundResponse> {
    // 厳密には重複するテナントコードを防げないが、6桁のランダム文字列 かつ 存在チェックしているのでよしとする。
    // 厳密にしたい場合はBackendで処理すること。
    const doProcess = async () => {
      const tenantCode = this.tenantCodeFactory();
      const x = await this.playgroundRepository.findByTenantCode(tenantCode);
      if (x) {
        return doProcess();
      }
      const playground = await this.playgroundRepository.save({
        tenantCode,
        tenantName: tenantCode,
        status: 'reserved',
        email,
      });
      return playground;
    };
    const playground = await doProcess();
    return {
      playground,
    };
  }
}

export const RequestToCreatePlaygroundKey = injectionKeyOf<RequestToCreatePlayground>({
  boundedContext: 'account',
  type: 'usecase',
  name: 'RequestToCreatePlayground',
});

export function useRequestToCreatePlayground(): RequestToCreatePlayground {
  return requiredInject(RequestToCreatePlaygroundKey);
}
