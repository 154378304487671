import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { AuthorizationService, localEventBus, User, UserId, UserRepository } from '../domains';
import { userAvatarChanged } from '../domains/LocalEvents';
import { AbstractUseCase, UseCase, UseCaseResponse } from './UseCase';
import { assertEntityExists } from './UseCaseAsserts';

export interface RemoveUserAvatarRequest {
  id: UserId;
}

export type RemoveUserAvatarResponse = {
  user: User;
};

/**
 * ユーザーのアバターを削除する
 */
export interface RemoveUserAvatar
  extends UseCase<RemoveUserAvatarRequest, RemoveUserAvatarResponse> {
  execute(request: RemoveUserAvatarRequest): Promise<UseCaseResponse<RemoveUserAvatarResponse>>;
}

export class RemoveUserAvatarImpl
  extends AbstractUseCase<RemoveUserAvatarRequest, RemoveUserAvatarResponse>
  implements RemoveUserAvatar
{
  private authorizationService: AuthorizationService;

  private userRepository: UserRepository;

  constructor(authorizationService: AuthorizationService, userRepository: UserRepository) {
    super('base.RemoveUserAvatar');
    this.authorizationService = authorizationService;
    this.userRepository = userRepository;
  }

  async internalExecute(request: RemoveUserAvatarRequest): Promise<RemoveUserAvatarResponse> {
    const { id } = request;
    this.authorizationService.assertOwnerAccessible(id);
    const user = await this.userRepository.findById(id);
    assertEntityExists(user, 'user');
    const saved = await this.userRepository.save(user.removeAvatar());
    localEventBus.publish(userAvatarChanged({ userId: user.id }));
    return {
      user: saved,
    };
  }
}

export const RemoveUserAvatarKey = injectionKeyOf<RemoveUserAvatar>({
  boundedContext: 'base',
  type: 'usecase',
  name: 'RemoveUserAvatar',
});

export function useRemoveUserAvatar(): RemoveUserAvatar {
  return requiredInject(RemoveUserAvatarKey);
}
