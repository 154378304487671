import {
  ChoiceProblem,
  ContentId,
  CourseId,
  ProblemHeader,
  ProblemHeaderId,
  UserId,
} from '@/base/domains';
import { LocalDateTime, MarkDownString } from '@/base/types';
import { uuid } from '@/utils/UniqueIdGenerator';

import { CONTENT_PROBLEM_HEADER_NOT_FOUND } from '../ErrorCodes';
import { EditingCourseContentWorkbookEntity } from './EditingCourseContentWorkbook';

type EditingCourseContentWorkbookEntityImplArgs = {
  problems: Array<ChoiceProblem>;

  problemHeaders: Array<ProblemHeader>;

  dataVersion: number;

  id: ContentId;

  createdBy: UserId;

  updatedBy: UserId;

  courseId: CourseId;
};

export class EditingCourseContentWorkbookEntityImpl implements EditingCourseContentWorkbookEntity {
  problems: Array<ChoiceProblem>;

  problemHeaders: Array<ProblemHeader>;

  dataVersion: number;

  id: ContentId;

  createdBy: UserId;

  updatedBy: UserId;

  courseId: CourseId;

  constructor(args: EditingCourseContentWorkbookEntityImplArgs) {
    this.problems = args.problems;
    this.problemHeaders = args.problemHeaders;
    this.dataVersion = args.dataVersion;
    this.id = args.id;
    this.createdBy = args.createdBy;
    this.updatedBy = args.updatedBy;
    this.courseId = args.courseId;
  }

  changeProblems(problems: Array<ChoiceProblem>): EditingCourseContentWorkbookEntity {
    return new EditingCourseContentWorkbookEntityImpl({
      ...this,
      problems,
    });
  }

  addProblemHeader({
    body,
    createdAt,
  }: {
    body: MarkDownString;
    createdAt: LocalDateTime;
  }): EditingCourseContentWorkbookEntity {
    const problemHeaders = [
      ...this.problemHeaders,
      {
        id: uuid(),
        body,
        createdAt,
      },
    ];
    return new EditingCourseContentWorkbookEntityImpl({
      ...this,
      problemHeaders,
    });
  }

  changeProblemHeader({
    id,
    body,
  }: {
    id: ProblemHeaderId;
    body: MarkDownString;
  }): EditingCourseContentWorkbookEntity {
    const problemHeader = this.problemHeaders.find((h) => h.id === id);
    if (!problemHeader) {
      throw CONTENT_PROBLEM_HEADER_NOT_FOUND.toApplicationError({
        payload: {
          id: this.id,
          problemHeaderId: id,
        },
      });
    }
    const problemHeaders = this.problemHeaders.map((h) =>
      h.id === id ? { ...problemHeader, body } : h
    );
    return new EditingCourseContentWorkbookEntityImpl({
      ...this,
      problemHeaders,
    });
  }

  removeProblemHeader(id: ProblemHeaderId): EditingCourseContentWorkbookEntity {
    const problemHeaders = this.problemHeaders.filter((h) => h.id !== id);
    return new EditingCourseContentWorkbookEntityImpl({
      ...this,
      problemHeaders,
    });
  }
}
