import { AuthorizationService, DateTimeService } from '@/base/domains';
import {
  AbstractUseCase,
  assertEntityExists,
  FinishGroupExam,
  FinishGroupExamRequest,
  FinishGroupExamResponse,
} from '@/base/usecases';

import { GroupExamRepository } from '../domains';

export class FinishGroupExamImpl
  extends AbstractUseCase<FinishGroupExamRequest, FinishGroupExamResponse>
  implements FinishGroupExam
{
  private authorizationService: AuthorizationService;

  private groupExamRepository: GroupExamRepository;

  private dateTimeService: DateTimeService;

  constructor(
    authorizationService: AuthorizationService,
    groupExamRepository: GroupExamRepository,
    dateTimeService: DateTimeService
  ) {
    super('training.FinishGroupExamImpl');
    this.authorizationService = authorizationService;
    this.groupExamRepository = groupExamRepository;
    this.dateTimeService = dateTimeService;
  }

  async internalExecute(request: FinishGroupExamRequest): Promise<FinishGroupExamResponse> {
    const { id } = request;
    const [groupExam, now] = await Promise.all([
      this.groupExamRepository.findById(id),
      this.dateTimeService.strictLocalDateTimeNow(),
    ]);
    assertEntityExists(groupExam, 'groupExam');
    this.authorizationService.assertTrainerAccessible(groupExam.groupId);
    const finished = groupExam.finish(now);
    await this.groupExamRepository.save(finished);
    return {
      groupExam: finished,
    };
  }
}
