import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import {
  AuthorizationService,
  UserTagColor,
  UserTagReference,
  UserTagRepository,
} from '../domains';
import { AbstractUseCase, UseCase, UseCaseResponse } from './UseCase';

export type CreateUserTagRequest = {
  text: string;
  color: UserTagColor;
};

export type CreateUserTagResponse = {
  userTag: UserTagReference;
};

/**
 * ユーザータグを登録する
 */
export interface CreateUserTag extends UseCase<CreateUserTagRequest, CreateUserTagResponse> {
  execute(request: CreateUserTagRequest): Promise<UseCaseResponse<CreateUserTagResponse>>;
}

export class CreateUserTagImpl
  extends AbstractUseCase<CreateUserTagRequest, CreateUserTagResponse>
  implements CreateUserTag
{
  constructor(
    private authorizationService: AuthorizationService,
    private userTagRepository: UserTagRepository
  ) {
    super('base.CreateUserTag');
  }

  async internalExecute(request: CreateUserTagRequest): Promise<CreateUserTagResponse> {
    this.authorizationService.assertRole('supervisor', 'admin');
    const saved = await this.userTagRepository.save(request);
    return {
      userTag: saved,
    };
  }
}

export const CreateUserTagKey = injectionKeyOf<CreateUserTag>({
  boundedContext: 'training',
  type: 'usecase',
  name: 'CreateUserTag',
});

export function useCreateUserTag(): CreateUserTag {
  return requiredInject(CreateUserTagKey);
}
