import { createLogger } from '@/utils/log';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { Subscription, UserReference, UserRepository } from '../domains';
import { AbstractSyncUseCase } from './AbstractSyncUseCase';
import { SyncUseCase, UseCaseResponse } from './UseCase';

const logger = createLogger({ boundedContext: 'base', name: 'SubscribeUserStatusChanged' });

export type SubscribeUserStatusChangedRequest = {
  onChange: (user: UserReference) => void;
  onError?: (error: Error) => void;
};

export type SubscribeUserStatusChangedResponse = {
  subscription: Subscription;
};

export interface SubscribeUserStatusChanged
  extends SyncUseCase<SubscribeUserStatusChangedRequest, SubscribeUserStatusChangedResponse> {
  execute(
    request: SubscribeUserStatusChangedRequest
  ): UseCaseResponse<SubscribeUserStatusChangedResponse>;
}

export class SubscribeUserStatusChangedImpl
  extends AbstractSyncUseCase<SubscribeUserStatusChangedRequest, SubscribeUserStatusChangedResponse>
  implements SubscribeUserStatusChanged
{
  constructor(private userRepository: UserRepository) {
    super('base.SubscribeUserStatusChanged');
  }

  internalExecute(args: SubscribeUserStatusChangedRequest): SubscribeUserStatusChangedResponse {
    const onError: (e: Error) => void = args.onError
      ? args.onError
      : (e: Error) => {
          logger.error({
            message: 'an error has occurred at subscription on user status',
            error: e,
          });
        };
    const subscription = this.userRepository.subscribeUserStatusChanged({
      onNext: args.onChange,
      onError,
    });
    return {
      subscription,
    };
  }
}

export const SubscribeUserStatusChangedKey = injectionKeyOf<SubscribeUserStatusChanged>({
  boundedContext: 'base',
  type: 'usecase',
  name: 'SubscribeUserStatusChanged',
});

export function useSubscribeUserStatusChanged(): SubscribeUserStatusChanged {
  return requiredInject(SubscribeUserStatusChangedKey);
}
