import { AuthorizationService } from '@/base/domains';
import { Memo, MemoId } from '@/base/domains/Memo';
import { AbstractUseCase, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { MemoRepository } from '../domains';

export type GetMemoRequest = {
  id: MemoId;
};

export type GetMemoResponse = {
  memo?: Memo;
};

export interface GetMemo extends UseCase<GetMemoRequest, GetMemoResponse> {
  execute(request: GetMemoRequest): Promise<UseCaseResponse<GetMemoResponse>>;
}

export class GetMemoImpl
  extends AbstractUseCase<GetMemoRequest, GetMemoResponse>
  implements GetMemo
{
  constructor(
    private authorizationService: AuthorizationService,
    private memoRepository: MemoRepository
  ) {
    super('training.GetMemo');
  }

  async internalExecute(request: GetMemoRequest): Promise<GetMemoResponse> {
    const { id } = request;
    const memo = await this.memoRepository.findById(id);
    if (memo) this.authorizationService.assertGroupReadAccessible(memo.groupId);
    return { memo };
  }
}

export const GetMemoKey = injectionKeyOf<GetMemo>({
  boundedContext: 'training',
  type: 'usecase',
  name: 'GetMemo',
});

export function useGetMemo(): GetMemo {
  return requiredInject(GetMemoKey);
}
