import {
  useAppContextProvider,
  useAuthorizationService,
  useAuthService,
  useExamDataAdapter,
  useFileStorage,
  useGroupFinder,
  useGroupRepository,
  useGroupStatusService,
  useGroupTrainingFinder,
  useLocaleProvider,
  useNotificationRepository,
  useScheduleTagDataAdapter,
  useSurviver,
  useTenantDataAdapter,
  useTenantOwnTermsOfServiceFinder,
  useTermsOfServiceDataAdapter,
  useUserFinder,
  useUserRepository,
  useUserTagRepository,
} from '@/base/domains';
import { useGroupStatusQueries } from '@/base/domains/queries';
import {
  AddUserDeviceImpl,
  AddUserDeviceKey,
  ChangeUserAvatarImpl,
  ChangeUserAvatarKey,
  ChangeUserDisplaySettingsImpl,
  ChangeUserDisplaySettingsKey,
  ChangeUserLocaleImpl,
  ChangeUserLocaleKey,
  ChangeUserNameByAdminImpl,
  ChangeUserNameByAdminKey,
  ChangeUserNameImpl,
  ChangeUserNameKey,
  ChangeUserPasswordByAdminImpl,
  ChangeUserPasswordByAdminKey,
  ChangeUserRoleByAdminImpl,
  ChangeUserRoleByAdminKey,
  ChangeUserTagImpl,
  ChangeUserTagKey,
  ClearGroupHandStatusImpl,
  ClearGroupHandStatusKey,
  CreateUserTagImpl,
  CreateUserTagKey,
  GetActiveExamImpl,
  GetActiveExamKey,
  GetCurrentUserImpl,
  GetCurrentUserKey,
  GetCurrentUserWhenAppStartedImpl,
  GetCurrentUserWhenAppStartedKey,
  GetGroupExamByTrainerImpl,
  GetGroupExamByTrainerKey,
  GetGroupImpl,
  GetGroupKey,
  GetGroupsImpl,
  GetGroupsKey,
  GetLatestTermsOfServiceImpl,
  GetLatestTermsOfServiceKey,
  GetNotificationsImpl,
  GetNotificationsKey,
  GetScheduleTagsImpl,
  GetScheduleTagsKey,
  GetTenantImpl,
  GetTenantKey,
  GetTenantOwnTermsOfServiceImpl,
  GetTenantOwnTermsOfServiceKey,
  GetUsersImpl,
  GetUsersKey,
  GetUserTagsImpl,
  GetUserTagsKey,
  LiveImpl,
  LiveKey,
  LowerHandImpl,
  LowerHandKey,
  NotifyImpl,
  NotifyKey,
  RaiseHandImpl,
  RaiseHandKey,
  RemoveUserTagImpl,
  RemoveUserTagKey,
  ScheduleResetSignedCookieImpl,
  ScheduleResetSignedCookieKey,
  SetTagsToUsersImpl,
  SetTagsToUsersKey,
  SignInImpl,
  SignInKey,
  SignOutImpl,
  SignOutKey,
  SubscribeGroupStatusUserChangedImpl,
  SubscribeGroupStatusUserChangedKey,
  SubscribeUserStatusChangedImpl,
  SubscribeUserStatusChangedKey,
  ToggleNotificationReadImpl,
  ToggleNotificationReadKey,
} from '@/base/usecases';
import { GetGroupStatusImpl, GetGroupStatusKey } from '@/base/usecases/GetGroupStatus';
import { RemoveUserAvatarImpl, RemoveUserAvatarKey } from '@/base/usecases/RemoveUserAvatar';
import { UploadFileImpl, UploadFileKey } from '@/base/usecases/UploadFile';
import { provide } from '@/utils/VueUtils';

export function useBaseUseCases() {
  const authService = useAuthService();
  const groupRepository = useGroupRepository();
  const userRepository = useUserRepository();
  const appContextProvider = useAppContextProvider();
  const notificationRepository = useNotificationRepository();
  const userFinder = useUserFinder();
  const groupFinder = useGroupFinder();
  const fileStorage = useFileStorage();
  const examAdapter = useExamDataAdapter();
  const tenantDataAdapter = useTenantDataAdapter();
  const authorizationService = useAuthorizationService();
  const scheduleTagDataAdapter = useScheduleTagDataAdapter();
  const termsOfServiceDataAdapter = useTermsOfServiceDataAdapter();
  const surviver = useSurviver();
  const groupTrainingFinder = useGroupTrainingFinder();
  const examDataAdapter = useExamDataAdapter();
  const userTagRepository = useUserTagRepository();
  const groupStatusService = useGroupStatusService();
  const groupStatusQueries = useGroupStatusQueries();
  const localeProvider = useLocaleProvider();
  const tenantOwnTermsOfServiceFinder = useTenantOwnTermsOfServiceFinder();

  provide(
    SignInKey,
    new SignInImpl(authService, fileStorage, userRepository, tenantDataAdapter, localeProvider)
  );
  provide(SignOutKey, new SignOutImpl(authService, fileStorage));
  provide(GetGroupKey, new GetGroupImpl(groupRepository, userRepository));
  provide(NotifyKey, new NotifyImpl(notificationRepository, appContextProvider));
  provide(
    ToggleNotificationReadKey,
    new ToggleNotificationReadImpl(notificationRepository, appContextProvider)
  );
  provide(
    GetNotificationsKey,
    new GetNotificationsImpl(appContextProvider, notificationRepository)
  );
  provide(GetActiveExamKey, new GetActiveExamImpl(examAdapter));
  provide(GetUsersKey, new GetUsersImpl(userFinder));
  provide(GetGroupsKey, new GetGroupsImpl(groupFinder));
  provide(UploadFileKey, new UploadFileImpl(fileStorage));
  provide(GetTenantKey, new GetTenantImpl(tenantDataAdapter));
  provide(
    GetCurrentUserWhenAppStartedKey,
    new GetCurrentUserWhenAppStartedImpl(authService, fileStorage)
  );
  provide(ChangeUserNameKey, new ChangeUserNameImpl(authorizationService, userRepository));
  provide(
    ChangeUserDisplaySettingsKey,
    new ChangeUserDisplaySettingsImpl(authorizationService, userRepository)
  );
  provide(ChangeUserLocaleKey, new ChangeUserLocaleImpl(authorizationService, userRepository));
  provide(
    ChangeUserRoleByAdminKey,
    new ChangeUserRoleByAdminImpl(authorizationService, userRepository)
  );
  provide(
    ChangeUserPasswordByAdminKey,
    new ChangeUserPasswordByAdminImpl(authorizationService, authService)
  );
  provide(
    ChangeUserAvatarKey,
    new ChangeUserAvatarImpl(authorizationService, fileStorage, userRepository)
  );
  provide(GetCurrentUserKey, new GetCurrentUserImpl(authService));
  provide(
    GetScheduleTagsKey,
    new GetScheduleTagsImpl(authorizationService, scheduleTagDataAdapter)
  );
  provide(RemoveUserAvatarKey, new RemoveUserAvatarImpl(authorizationService, userRepository));
  provide(ScheduleResetSignedCookieKey, new ScheduleResetSignedCookieImpl(fileStorage));
  provide(GetLatestTermsOfServiceKey, new GetLatestTermsOfServiceImpl(termsOfServiceDataAdapter));
  provide(LiveKey, new LiveImpl(surviver));
  provide(
    GetGroupExamByTrainerKey,
    new GetGroupExamByTrainerImpl(authorizationService, groupTrainingFinder, examDataAdapter)
  );
  provide(GetUserTagsKey, new GetUserTagsImpl(userTagRepository));
  provide(CreateUserTagKey, new CreateUserTagImpl(authorizationService, userTagRepository));
  provide(RemoveUserTagKey, new RemoveUserTagImpl(authorizationService, userTagRepository));
  provide(ChangeUserTagKey, new ChangeUserTagImpl(authorizationService, userTagRepository));
  provide(
    SetTagsToUsersKey,
    new SetTagsToUsersImpl(authorizationService, userRepository, userTagRepository)
  );
  provide(SubscribeUserStatusChangedKey, new SubscribeUserStatusChangedImpl(userRepository));
  provide(
    ChangeUserNameByAdminKey,
    new ChangeUserNameByAdminImpl(authorizationService, userRepository)
  );
  provide(
    ClearGroupHandStatusKey,
    new ClearGroupHandStatusImpl(authorizationService, groupStatusService)
  );
  provide(
    RaiseHandKey,
    new RaiseHandImpl(appContextProvider, authorizationService, groupStatusService)
  );
  provide(
    LowerHandKey,
    new LowerHandImpl(appContextProvider, authorizationService, groupStatusService)
  );
  provide(
    SubscribeGroupStatusUserChangedKey,
    new SubscribeGroupStatusUserChangedImpl(authorizationService, groupStatusService)
  );
  provide(GetGroupStatusKey, new GetGroupStatusImpl(authorizationService, groupStatusQueries));
  provide(AddUserDeviceKey, new AddUserDeviceImpl(userRepository));
  provide(
    GetTenantOwnTermsOfServiceKey,
    new GetTenantOwnTermsOfServiceImpl(tenantOwnTermsOfServiceFinder)
  );
}
