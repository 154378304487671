import { ContentId, ContentName, CourseId } from '@/base/domains';
import { errorCodeOf } from '@/base/error';

export const COURSE_DUPLICATED_CONTENT = errorCodeOf(
  'CN0001',
  'コンテンツがすでにコースに登録されています'
);

export const COURSE_INVALID_INDEXED_CONTENT = errorCodeOf(
  'CN0002',
  'コンテンツのインデックスが不正です'
);

export const COURSE_ALREADY_STARTED_EDITING = errorCodeOf('CN0004', 'コースはすでに編集中です');

export const COURSE_DUPLICATED_NAME = errorCodeOf('CN0005', 'コースの名前が重複しています');

export const COURSE_NAME_UNAVAILABLE_TO_CHANGE_IN_VERSION_2_OR_LATER = errorCodeOf(
  'CN0006',
  'バージョン2以降ではコース名は変更できません'
);

export const COURSE_NOT_CONFIRMED = errorCodeOf('CN0007', 'コースは確定されていません');

export const CONTENT_DUPLICATED_NAME = errorCodeOf<{
  courseId: CourseId;
  contentName: ContentName;
}>('CN0008', 'コンテンツの名前が重複しています');

export const CONTENT_PROBLEM_HEADER_NOT_FOUND = errorCodeOf('CN0009', '問題ヘッダーがありません');

export const CONTENT_TYPE_SHOULD_BE_EXAM = errorCodeOf('CN0010', 'テストではありません');

export const CORSE_CONTENT_LIMIT_EXCEEDED = errorCodeOf<{ courseId: CourseId }>(
  'CN0011',
  'これ以上コンテンツをコースに登録できません'
);

export const CORSE_CONTENT_EMPTY_PROBLEM_EXAM = errorCodeOf<{
  courseId: CourseId;
  contents: Array<{ id: ContentId; name: ContentName }>;
}>('CN0012', '問題が登録されていません');

export const COURSE_EMPTY_CONTENTS = errorCodeOf<{
  courseId: CourseId;
}>('CN0013', 'コンテンツが登録されていません');

export const CORSE_CONTENT_INVALID_PASSING_STANDARD = errorCodeOf<{
  courseId: CourseId;
  contents: Array<{ id: ContentId; name: ContentName }>;
}>('CN0014', '合格基準が問題数を超えています');
