import * as customMutations from '@/amplify/customMutations';
import { createUserUserTag, deleteUserUserTag } from '@/amplify/customMutations';
import { getUser, usersByTenantCode } from '@/amplify/customQueries';
import * as customSubscriptions from '@/amplify/customSubscriptions';
import {
  CreateUserUserTagInput,
  DeleteUserInput,
  DeleteUserUserTagInput,
  Role as AmplifyRole,
  UpdateUserInput,
  UserStatus as AmplifyUserStatus,
} from '@/API';
import {
  AppContextProvider,
  ConfirmedTermsOfServiceVersions,
  Email,
  Locale,
  Role,
  TenantCode,
  UserCode,
  UserData,
  UserDevice,
  UserEntity,
  UserEntityImpl,
  UserId,
  UserName,
  UserRepository,
  UserStatus,
  UserTagColor,
  UserTagId,
} from '@/base/domains';
import { UserExtensionConfig } from '@/base/domains/extensions/Extension';
import { Subscription } from '@/base/domains/Subscription';
import { Optional, URI } from '@/base/types';
import { assertEntityExists } from '@/base/usecases';
import * as mutations from '@/graphql/mutations';
import { graphql, graphqlSubscribe } from '@/utils/AmplifyUtils';
import { assertIsDefined } from '@/utils/Asserts';
import { ifDefined, isDefined, requiredNonNull } from '@/utils/TsUtils';

type AmplifyUser = {
  id: UserId;
  name: UserName;
  role: AmplifyRole;
  extensionConfigJsonList: Array<string>;
  email?: Email;
  code?: UserCode;
  tenantCode: TenantCode;
  locale?: Locale;
  displaySettings?: {
    theme: string;
    color: string;
  };
  avatar?: URI;
  signedInAtLeastOnce: boolean;
  tags: {
    items: Array<{
      id: string;
      userId: UserId;
      userTagId: UserTagId;
      userTag: {
        id: UserTagId;
        color: UserTagColor;
        text: string;
      };
    }>;
  };
  // Amplifyの制約によりリストになっているが実際には1件しかない
  statuses: {
    items: Array<{ status: AmplifyUserStatus }>;
  };
  enabled: boolean;
  confirmedTermsOfServiceVersions?: ConfirmedTermsOfServiceVersions;
};

function toUserEntity(user: AmplifyUser): UserEntity {
  return new UserEntityImpl({
    id: user.id,
    name: user.name,
    role: user.role.toLocaleLowerCase() as Role,
    extensionConfigs: user.extensionConfigJsonList.map((c) => JSON.parse(c) as UserExtensionConfig),
    email: user.email,
    code: user.code,
    tenantCode: user.tenantCode,
    locale: user.locale ?? 'ja',
    displaySettings: user.displaySettings,
    avatar: user.avatar,
    signedInAtLeastOnce: user.signedInAtLeastOnce,
    tags: (user.tags?.items ?? [])
      .map((e) => ({
        id: e.userTag.id,
        text: e.userTag.text,
        color: e.userTag.color,
      }))
      .sort((a, b) => a.text.localeCompare(b.text)),
    status:
      ifDefined(
        user.statuses.items[0]?.status,
        (status) => status.toLocaleLowerCase() as UserStatus
      ) ?? 'inactive',
    enabled: user.enabled,
    confirmedTermsOfServiceVersions: user.confirmedTermsOfServiceVersions ?? {},
  });
}

export class AmplifyUserRepository implements UserRepository {
  constructor(private appContextProvider: AppContextProvider) {}

  async save(entity: UserData | UserEntity): Promise<UserEntity> {
    if (isDefined(entity.id)) {
      const input: UpdateUserInput = {
        id: entity.id,
        name: entity.name,
        role: entity.role.toUpperCase() as AmplifyRole,
        extensionConfigJsonList: entity.extensionConfigs.map((c) => JSON.stringify(c)),
        email: entity.email,
        code: entity.code,
        tenantCode: entity.tenantCode,
        locale: entity.locale,
        displaySettings: entity.displaySettings,
        avatar: entity.avatar ?? null,
        enabled: entity.enabled,
        signedInAtLeastOnce: entity.signedInAtLeastOnce,
        confirmedTermsOfServiceVersions: entity.confirmedTermsOfServiceVersions,
      };
      const res = await graphql<{ updateUser: AmplifyUser }>(customMutations.updateUser, {
        input,
      });
      assertIsDefined(res);
      return toUserEntity(res.updateUser);
    }
    throw new Error('Unsupported operation');
  }

  async findById(id: UserId): Promise<Optional<UserEntity>> {
    const res = await graphql<{ getUser: AmplifyUser }>(getUser, { id });
    return res?.getUser ? toUserEntity(res.getUser) : undefined;
  }

  async remove(id: UserId): Promise<void> {
    const input: DeleteUserInput = {
      id,
    };
    await graphql<{ deleteUser: AmplifyUser }>(customMutations.deleteUser, {
      input,
    });
  }

  async findTenantEnabledUsers(): Promise<Array<UserEntity>> {
    const tenantCode = requiredNonNull(
      this.appContextProvider.get().tenantCode,
      'appContext.tenantCode'
    );
    const res = await graphql<{ usersByTenantCode: { items: Array<AmplifyUser> } }>(
      usersByTenantCode,
      {
        limit: 1000,
        tenantCode,
        filter: {
          enabled: {
            eq: true,
          },
        },
      }
    );
    return res?.usersByTenantCode.items.map(toUserEntity) ?? [];
  }

  async findTenantUsers(): Promise<Array<UserEntity>> {
    const tenantCode = requiredNonNull(
      this.appContextProvider.get().tenantCode,
      'appContext.tenantCode'
    );
    const res = await graphql<{ usersByTenantCode: { items: Array<AmplifyUser> } }>(
      usersByTenantCode,
      {
        limit: 1000,
        tenantCode,
      }
    );
    return res?.usersByTenantCode.items.map(toUserEntity) ?? [];
  }

  async replaceTags(id: UserId, tagIds: Array<UserTagId>): Promise<UserEntity> {
    const tenantCode = requiredNonNull(
      this.appContextProvider.get().tenantCode,
      'appContext.tenantCode'
    );
    const user = await graphql<{ getUser: AmplifyUser }>(getUser, { id });
    assertEntityExists(user, 'user');

    const futures: Array<Promise<unknown>> = [];

    // User.tagsにあるがargs.tagIdsにないものをUserUserTagから削除する
    const userUserTags = user.getUser.tags.items;
    const userUserTagIdsToDelete = userUserTags
      .filter((t) => !tagIds.includes(t.userTagId))
      .map((t) => t.id);

    if (userUserTagIdsToDelete.length > 0) {
      const deleteTagsFuture = Promise.all(
        userUserTagIdsToDelete.map((tagId) => {
          const input: DeleteUserUserTagInput = {
            id: tagId,
          };
          return graphql(deleteUserUserTag, { input });
        })
      );
      futures.push(deleteTagsFuture);
    }

    // User.tagsにないがargs.tagIdsにあるものをUserUserTagから追加する
    const oldTagIds = userUserTags.map((userUserTag) => userUserTag.userTagId);
    const tagIdsToAdd = tagIds.filter((tagId) => !oldTagIds.includes(tagId));

    if (tagIdsToAdd.length > 0) {
      const addTagsFuture = Promise.all(
        tagIdsToAdd.map((tagId) => {
          const input: CreateUserUserTagInput = {
            userId: id,
            userTagId: tagId,
            tenantCode,
          };
          return graphql(createUserUserTag, { input });
        })
      );
      futures.push(addTagsFuture);
    }
    await Promise.all(futures);
    const saved = await this.findById(id);
    assertEntityExists(saved, 'user');
    return saved;
  }

  subscribeUserStatusChanged(args: {
    onNext: (user: UserEntity) => void;
    onError: (e: Error) => void;
  }): Subscription {
    const tenantCode = requiredNonNull(
      this.appContextProvider.get().tenantCode,
      'appContext.tenantCode'
    );
    type SubscriptionType = {
      status: AmplifyUserStatus;
      user: AmplifyUser;
    };

    const onCreateObs = graphqlSubscribe<{
      value: {
        data: {
          onCreateUserStatusTableByTenantCode: SubscriptionType;
        };
      };
    }>(customSubscriptions.onCreateUserStatusTableByTenantCode, {
      tenantCode,
    });
    const onCreateSubscription = onCreateObs.subscribe({
      next: ({ value }) => {
        const data = value.data.onCreateUserStatusTableByTenantCode;
        const user = toUserEntity({
          ...data.user,
          statuses: {
            items: [{ status: data.status as AmplifyUserStatus }],
          },
        });
        args.onNext(user);
      },
      error: args.onError,
    });
    const onUpdateObs = graphqlSubscribe<{
      value: {
        data: {
          onUpdateUserStatusTableByTenantCode: SubscriptionType;
        };
      };
    }>(customSubscriptions.onUpdateUserStatusTableByTenantCode, {
      tenantCode,
    });
    const onUpdateSubscription = onUpdateObs.subscribe({
      next: ({ value }) => {
        const data = value.data.onUpdateUserStatusTableByTenantCode;
        const user = toUserEntity({
          ...data.user,
          statuses: {
            items: [{ status: data.status as AmplifyUserStatus }],
          },
        });
        args.onNext(user);
      },
      error: args.onError,
    });
    return {
      unsubscribe: () => {
        onCreateSubscription.unsubscribe();
        onUpdateSubscription.unsubscribe();
      },
      isClosed: () => onCreateSubscription.closed || onUpdateSubscription.closed,
    };
  }

  async addUserDevice(userDevice: UserDevice): Promise<void> {
    await graphql(mutations.addUserDevice, {
      input: {
        deviceType: userDevice.type,
        deviceToken: userDevice.token,
      },
    });
  }
}
