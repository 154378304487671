import { computed, isRef, Ref } from '@vue/composition-api';

import { useMessageService } from '../message';
import { useGlobalStore } from './store';

type MessagesOptions = {
  prefix?: string;
};

function keyOf(prefix: string, key: string): string {
  if (prefix === '') {
    return key;
  }
  if (prefix.endsWith('.')) {
    return `${prefix}${key}`;
  }
  return `${prefix}.${key}`;
}

export function useMessages(options: MessagesOptions = {}) {
  const prefix = options.prefix ?? '';
  const globalStore = useGlobalStore();
  const messageService = useMessageService();
  const { locale } = globalStore;
  const of = (
    key: string,
    variables?: { [key: string]: string | number } | Ref<{ [key: string]: string | number }>
  ) => {
    const k = keyOf(prefix, key);
    const variablesRef = isRef(variables) ? variables : computed(() => variables ?? {});
    return computed(() => messageService.messageOf(k, variablesRef.value, locale.value));
  };
  const listOf = (key: string) => {
    const k = keyOf(prefix, key);
    return computed(() => messageService.messageListOf(k, locale.value));
  };
  return {
    of,
    listOf,
    locale,
  };
}
