import { AppContextProvider, AuthorizationService } from '@/base/domains';
import { AbstractUseCase, UseCase, UseCaseResponse } from '@/base/usecases';
import { assertIsDefined } from '@/utils/Asserts';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { EditingTenantOwnTermsOfServiceRepository } from '../domains';

export type DeleteEditingTenantOwnTermsOfServiceRequest = {};

export type DeleteEditingTenantOwnTermsOfServiceResponse = {};

export interface DeleteEditingTenantOwnTermsOfService
  extends UseCase<
    DeleteEditingTenantOwnTermsOfServiceRequest,
    DeleteEditingTenantOwnTermsOfServiceResponse
  > {
  execute(
    request: DeleteEditingTenantOwnTermsOfServiceRequest
  ): Promise<UseCaseResponse<DeleteEditingTenantOwnTermsOfServiceResponse>>;
}

export class DeleteEditingTenantOwnTermsOfServiceImpl
  extends AbstractUseCase<
    DeleteEditingTenantOwnTermsOfServiceRequest,
    DeleteEditingTenantOwnTermsOfServiceResponse
  >
  implements DeleteEditingTenantOwnTermsOfService
{
  constructor(
    private authorizationService: AuthorizationService,
    private appContext: AppContextProvider,
    private editingTenantOwnTermsOfServiceRepository: EditingTenantOwnTermsOfServiceRepository
  ) {
    super('admin.DeleteEditingTenantOwnTermsOfService');
  }

  async internalExecute(
    _: DeleteEditingTenantOwnTermsOfServiceRequest
  ): Promise<DeleteEditingTenantOwnTermsOfServiceResponse> {
    this.authorizationService.assertRole('supervisor');
    const { tenantCode } = this.appContext.get();
    assertIsDefined(tenantCode, 'appContext.tenantCode');
    await this.editingTenantOwnTermsOfServiceRepository.remove(tenantCode);
    return {};
  }
}

export const DeleteEditingTenantOwnTermsOfServiceKey =
  injectionKeyOf<DeleteEditingTenantOwnTermsOfService>({
    boundedContext: 'admin',
    type: 'usecase',
    name: 'DeleteEditingTenantOwnTermsOfService',
  });

export function useDeleteEditingTenantOwnTermsOfService(): DeleteEditingTenantOwnTermsOfService {
  return requiredInject(DeleteEditingTenantOwnTermsOfServiceKey);
}
