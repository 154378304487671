import {
  AuthorizationService,
  ContentId,
  ContentName,
  ContentVersion,
  CourseColor,
  CourseFontColorOnImage,
  CourseId,
  CourseName,
  CourseVersion,
  Exam,
  Text,
  Workbook,
} from '@/base/domains';
import { Minute, URI } from '@/base/types';
import { AbstractUseCase, assertEntityExists, UseCase, UseCaseResponse } from '@/base/usecases';
import { ifDefined, isDefined } from '@/utils/TsUtils';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import {
  ContentRepository,
  CourseRepository,
  EditingConfirmedContentRepository,
  EditingCourseContentBodyRepository,
  EditingCourseContentWorkbookRepository,
  EditingCourseRepository,
  WorkbookImpl,
} from '../domains';

export interface GetCourseContentDetailsRequest {
  id: ContentId;
  version: ContentVersion;
}

export type GetCourseContentDetailsContentCourseContentType =
  | 'editing_course_content'
  | 'confirmed_course_content'
  | 'confirmed_course_content_editing';

export type GetCourseContentDetailsContent =
  | {
      id: ContentId;
      type: 'text';
      name: ContentName;
      version: ContentVersion;
      requiredTime: Minute;
      body: Text;
      workbook?: Workbook;
      courseContentType: GetCourseContentDetailsContentCourseContentType;
      courseId: CourseId;
      courseVersion: CourseVersion;
      courseName: CourseName;
      courseColor?: CourseColor;
      courseImage?: URI;
      courseFontColorOnImage?: CourseFontColorOnImage;
    }
  | {
      id: ContentId;
      type: 'exam';
      name: ContentName;
      version: ContentVersion;
      requiredTime: Minute;
      body: Exam;
      courseContentType: GetCourseContentDetailsContentCourseContentType;
      courseId: CourseId;
      courseVersion: CourseVersion;
      courseName: CourseName;
      courseColor?: CourseColor;
      courseImage?: URI;
      courseFontColorOnImage?: CourseFontColorOnImage;
    };

export interface GetCourseContentDetailsResponse {
  content?: GetCourseContentDetailsContent;
}

/**
 * コンテンツもしくは編集中コンテンツからコンテンツ詳細を取得する
 */
export interface GetCourseContentDetails
  extends UseCase<GetCourseContentDetailsRequest, GetCourseContentDetailsResponse> {
  execute(
    request: GetCourseContentDetailsRequest
  ): Promise<UseCaseResponse<GetCourseContentDetailsResponse>>;
}

export class GetCourseContentDetailsImpl
  extends AbstractUseCase<GetCourseContentDetailsRequest, GetCourseContentDetailsResponse>
  implements GetCourseContentDetails
{
  constructor(
    private authorizationService: AuthorizationService,
    private contentRepository: ContentRepository,
    private courseRepository: CourseRepository,
    private editingCourseContentBodyRepository: EditingCourseContentBodyRepository,
    private editingCourseContentWorkbookRepository: EditingCourseContentWorkbookRepository,
    private editingCourseRepository: EditingCourseRepository,
    private editingConfirmedContentRepository: EditingConfirmedContentRepository
  ) {
    super('contents.GetCourseContentDetails');
  }

  async internalExecute(
    request: GetCourseContentDetailsRequest
  ): Promise<GetCourseContentDetailsResponse> {
    const { id, version } = request;
    this.authorizationService.assertContentEditable();

    if (version > 1) {
      const { content, isEditing } = await (async () => {
        const cn = await this.contentRepository.findById(id, version);
        if (cn) {
          return { content: cn, isEditing: false };
        }
        const ecn = await this.editingConfirmedContentRepository.findById(id);
        if (ecn && ecn.version === version) {
          return { content: ecn, isEditing: true };
        }
        return { content: undefined, isEditing: false };
      })();
      if (!content) {
        return {
          content: undefined,
        };
      }
      const course = await this.courseRepository.findById({
        id: content.courseId,
        version: content.courseVersion,
      });
      assertEntityExists(course, 'course');
      const cn: GetCourseContentDetailsContent = (() => {
        const courseContentType: GetCourseContentDetailsContentCourseContentType = isEditing
          ? 'confirmed_course_content_editing'
          : 'confirmed_course_content';
        if (content.type === 'exam') {
          return {
            id: content.id,
            type: content.type,
            name: content.name,
            version: content.version,
            requiredTime: content.requiredTime,
            body: content.body,
            isEditing,
            courseContentType,
            courseId: course.id,
            courseVersion: course.version,
            courseName: course.name,
            courseColor: course.color,
            courseImage: course.image,
            courseFontColorOnImage: course.fontColorOnImage,
          };
        }

        return {
          id: content.id,
          type: content.type,
          name: content.name,
          version: content.version,
          requiredTime: content.requiredTime,
          body: content.body,
          workbook: content.workbook,
          isEditing,
          courseContentType,
          courseId: course.id,
          courseVersion: course.version,
          courseName: course.name,
          courseColor: course.color,
          courseImage: course.image,
          courseFontColorOnImage: course.fontColorOnImage,
        };
      })();

      return {
        content: cn,
      };
    }

    const body = await this.editingCourseContentBodyRepository.findById(id);
    if (isDefined(body)) {
      const [workbook, course] = await Promise.all([
        this.editingCourseContentWorkbookRepository.findById(id),
        this.editingCourseRepository.findById(body.courseId),
      ]);
      if (!course) {
        return {
          content: undefined,
        };
      }
      const editingContent = course.contents.find((cn) => cn.id === id);
      if (!editingContent) {
        return {
          content: undefined,
        };
      }
      const previousContent = await this.editingConfirmedContentRepository.findById(
        editingContent.id
      );
      const latestVersion = previousContent ? previousContent.version + 1 : 1;
      const content: GetCourseContentDetailsContent = (() => {
        if (body.type === 'exam') {
          return {
            id: body.id,
            type: body.type,
            name: editingContent.name,
            version: latestVersion,
            requiredTime: editingContent.requiredTime,
            body: body.body,
            isEditing: true,
            courseContentType:
              'editing_course_content' as GetCourseContentDetailsContentCourseContentType,
            courseId: course.id,
            courseVersion: course.version,
            courseName: course.name,
            courseColor: course.color,
            courseImage: course.image,
          };
        }

        return {
          id: body.id,
          type: body.type,
          name: editingContent.name,
          version: latestVersion,
          requiredTime: editingContent.requiredTime,
          body: body.body,
          workbook: ifDefined(
            workbook,
            (wb) => new WorkbookImpl({ problems: wb.problems, problemHeaders: wb.problemHeaders })
          ),
          isEditing: true,
          courseContentType:
            'editing_course_content' as GetCourseContentDetailsContentCourseContentType,
          courseId: course.id,
          courseVersion: course.version,
          courseName: course.name,
          courseColor: course.color,
          courseImage: course.image,
          courseFontColorOnImage: course.fontColorOnImage,
        };
      })();
      return {
        content,
      };
    }
    const content = await this.contentRepository.findById(id, version);
    if (!content) {
      return {
        content: undefined,
      };
    }
    const course = await this.courseRepository.findById({
      id: content.courseId,
      version: content.courseVersion,
    });
    assertEntityExists(course, 'course');
    const cn: GetCourseContentDetailsContent = (() => {
      if (content.type === 'exam') {
        return {
          id: content.id,
          type: content.type,
          name: content.name,
          version: content.version,
          requiredTime: content.requiredTime,
          body: content.body,
          isEditing: false,
          courseContentType:
            'confirmed_course_content' as GetCourseContentDetailsContentCourseContentType,
          courseId: course.id,
          courseVersion: course.version,
          courseName: course.name,
          courseColor: course.color,
          courseImage: course.image,
        };
      }

      return {
        id: content.id,
        type: content.type,
        name: content.name,
        version: content.version,
        requiredTime: content.requiredTime,
        body: content.body,
        workbook: content.workbook,
        isEditing: false,
        courseContentType:
          'confirmed_course_content' as GetCourseContentDetailsContentCourseContentType,
        courseId: course.id,
        courseVersion: course.version,
        courseName: course.name,
        courseColor: course.color,
        courseImage: course.image,
      };
    })();

    return {
      content: cn,
    };
  }
}

export const GetCourseContentDetailsKey = injectionKeyOf<GetCourseContentDetails>({
  boundedContext: 'contents',
  type: 'usecase',
  name: 'GetCourseContentDetails',
});

export function useGetCourseContentDetails(): GetCourseContentDetails {
  return requiredInject(GetCourseContentDetailsKey);
}
