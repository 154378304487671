import { AuthorizationService, ScheduleId, ScheduleTagId } from '@/base/domains';
import { AbstractUseCase, assertEntityExists, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { ScheduleReference, ScheduleRepository } from '../domains';

export interface RemoveTagFromScheduleRequest {
  id: ScheduleId;
  tagId: ScheduleTagId;
}

export type RemoveTagFromScheduleResponse = {
  schedule: ScheduleReference;
};

/**
 * スケジュールからタグを削除する
 */
export interface RemoveTagFromSchedule
  extends UseCase<RemoveTagFromScheduleRequest, RemoveTagFromScheduleResponse> {
  execute(
    request: RemoveTagFromScheduleRequest
  ): Promise<UseCaseResponse<RemoveTagFromScheduleResponse>>;
}

export class RemoveTagFromScheduleImpl
  extends AbstractUseCase<RemoveTagFromScheduleRequest, RemoveTagFromScheduleResponse>
  implements RemoveTagFromSchedule
{
  private authorizationService: AuthorizationService;

  private scheduleRepository: ScheduleRepository;

  constructor(authorizationService: AuthorizationService, scheduleRepository: ScheduleRepository) {
    super('training.RemoveTagFromSchedule');
    this.authorizationService = authorizationService;
    this.scheduleRepository = scheduleRepository;
  }

  async internalExecute(
    request: RemoveTagFromScheduleRequest
  ): Promise<RemoveTagFromScheduleResponse> {
    const { id, tagId } = request;
    const schedule = await this.scheduleRepository.findById(id);
    assertEntityExists(schedule, 'schedule');
    this.authorizationService.assertGroupWriteAccessible(schedule.groupId);
    if (schedule.tags.find((t) => t.id === tagId)) {
      const saved = await this.scheduleRepository.removeTag(id, tagId);
      return {
        schedule: saved,
      };
    }
    return {
      schedule,
    };
  }
}

export const RemoveTagFromScheduleKey = injectionKeyOf<RemoveTagFromSchedule>({
  boundedContext: 'training',
  type: 'usecase',
  name: 'RemoveTagFromSchedule',
});

export function useRemoveTagFromSchedule(): RemoveTagFromSchedule {
  return requiredInject(RemoveTagFromScheduleKey);
}
