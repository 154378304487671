import { config } from '@/config';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { FileStorage } from '../domains';
import { UPLOAD_FILE_SIZE_EXCEEDED } from '../ErrorCodes';
import { AbstractUseCase, UseCase, UseCaseResponse } from './UseCase';

export type UploadFileType = 'content' | 'default';

export interface UploadFileRequest {
  file: Blob;
  filename: string;
  onProgress?: (progress: { total: number; loaded: number }) => void;
  type?: UploadFileType;
}

export type UploadFileResponse = {
  uri: string;
};

/**
 * ファイルをアップロードする
 */
export interface UploadFile extends UseCase<UploadFileRequest, UploadFileResponse> {
  execute(request: UploadFileRequest): Promise<UseCaseResponse<UploadFileResponse>>;
}

function checkFileSize(file: Blob, type: UploadFileType, filename: string) {
  const c = config();
  const limit = (() => {
    const VIDEO_EXTENSIONS = ['.mov', '.mp4'];
    const lFilename = filename.toLowerCase();
    switch (type) {
      case 'content':
        if (VIDEO_EXTENSIONS.find((ext) => lFilename.endsWith(ext))) {
          return c.app.upload.videoContentFileSizeLimit;
        }
        return c.app.upload.contentFileSizeLimit;
      default:
        return c.app.upload.defaultFileSizeLimit;
    }
  })();

  if (file.size > limit) {
    throw UPLOAD_FILE_SIZE_EXCEEDED.toApplicationError();
  }
}

export class UploadFileImpl
  extends AbstractUseCase<UploadFileRequest, UploadFileResponse>
  implements UploadFile
{
  private fileStorage: FileStorage;

  constructor(fileStorage: FileStorage) {
    super('base.UploadFile');
    this.fileStorage = fileStorage;
  }

  async internalExecute(request: UploadFileRequest): Promise<UploadFileResponse> {
    const { file, filename, onProgress, type = 'default' } = request;

    checkFileSize(file, type, filename);

    const uri = await this.fileStorage.upload({
      file,
      filename,
      onProgress,
      encode: type === 'content',
    });
    return { uri };
  }
}

export const UploadFileKey = injectionKeyOf<UploadFile>({
  boundedContext: 'base',
  type: 'usecase',
  name: 'UploadFile',
});

export function useUploadFile(): UploadFile {
  return requiredInject(UploadFileKey);
}
