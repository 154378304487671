import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { QuestionnaireByAnswerer, QuestionnaireId } from '../domains';
import { UseCase, UseCaseResponse } from './UseCase';

export type GetQuestionnaireByAnswererRequest = {
  id: QuestionnaireId;
};

export type GetQuestionnaireByAnswererResponse = {
  questionnaire?: QuestionnaireByAnswerer;
};

export interface GetQuestionnaireByAnswerer
  extends UseCase<GetQuestionnaireByAnswererRequest, GetQuestionnaireByAnswererResponse> {
  execute(
    request: GetQuestionnaireByAnswererRequest
  ): Promise<UseCaseResponse<GetQuestionnaireByAnswererResponse>>;
}

export const GetQuestionnaireByAnswererKey = injectionKeyOf<GetQuestionnaireByAnswerer>({
  boundedContext: 'training',
  type: 'usecase',
  name: 'GetQuestionnaireByAnswerer',
});

export function useGetQuestionnaireByAnswerer(): GetQuestionnaireByAnswerer {
  return requiredInject(GetQuestionnaireByAnswererKey);
}
