import { AuthorizationService } from '@/base/domains';
import {
  AbstractUseCase,
  CreateQuestionnaire,
  CreateQuestionnaireRequest,
  CreateQuestionnaireResponse,
} from '@/base/usecases';

import { QuestionnaireRepository } from '../domains';

export class CreateQuestionnaireImpl
  extends AbstractUseCase<CreateQuestionnaireRequest, CreateQuestionnaireResponse>
  implements CreateQuestionnaire
{
  constructor(
    private authorizationService: AuthorizationService,
    private questionnaireRepository: QuestionnaireRepository
  ) {
    super('training.CreateQuestionnaire');
  }

  async internalExecute(request: CreateQuestionnaireRequest): Promise<CreateQuestionnaireResponse> {
    const { groupId, title, userIds, text, options } = request;
    this.authorizationService.assertTrainerOrMentorInGroup(groupId);
    const saved = await this.questionnaireRepository.save({
      groupId,
      title,
      userIds,
      text,
      options,
      respondent: [],
      status: 'active',
    });
    return { questionnaire: saved };
  }
}
