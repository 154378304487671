import { AuthorizationService, CourseId, CourseName } from '@/base/domains';
import { AbstractUseCase, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { CourseHeaderRepository } from '../domains';

export type ChangeConfirmedCourseNameRequest = {
  id: CourseId;
  name: CourseName;
};

export type ChangeConfirmedCourseNameResponse = {};

export interface ChangeConfirmedCourseName
  extends UseCase<ChangeConfirmedCourseNameRequest, ChangeConfirmedCourseNameResponse> {
  execute(
    request: ChangeConfirmedCourseNameRequest
  ): Promise<UseCaseResponse<ChangeConfirmedCourseNameResponse>>;
}

export class ChangeConfirmedCourseNameImpl
  extends AbstractUseCase<ChangeConfirmedCourseNameRequest, ChangeConfirmedCourseNameResponse>
  implements ChangeConfirmedCourseName
{
  constructor(
    private authorizationService: AuthorizationService,
    private courseHeaderRepository: CourseHeaderRepository
  ) {
    super('contents.ChangeConfirmedCourseName');
  }

  async internalExecute({
    id,
    name,
  }: ChangeConfirmedCourseNameRequest): Promise<ChangeConfirmedCourseNameResponse> {
    this.authorizationService.assertContentEditable();
    await this.courseHeaderRepository.changeName(id, name);
    return {};
  }
}

export const ChangeConfirmedCourseNameKey = injectionKeyOf<ChangeConfirmedCourseName>({
  boundedContext: 'contents',
  type: 'usecase',
  name: 'ChangeConfirmedCourseName',
});

export function useChangeConfirmedCourseName(): ChangeConfirmedCourseName {
  return requiredInject(ChangeConfirmedCourseNameKey);
}
