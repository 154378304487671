import {
  CreateMemoInput,
  DeleteMemoInput,
  GroupRole as AmplifyGroupRole,
  UpdateMemoInput,
} from '@/API';
import {
  AppContextProvider,
  ContentId,
  ContentVersion,
  GroupId,
  GroupRole,
  UserId,
} from '@/base/domains';
import {
  isMemoWorkbookReference,
  isTextMemoContentReference,
  MemoContentReference,
  MemoId,
  MemoScope,
} from '@/base/domains/Memo';
import { ISODateTimeString, JSONString, MarkDownString, Optional } from '@/base/types';
import * as mutations from '@/graphql/mutations';
import * as queries from '@/graphql/queries';
import { MemoEntity, MemoEntityImpl, MemoRepository } from '@/training/domains/Memo';
import { graphql } from '@/utils/AmplifyUtils';
import { assertIsDefined } from '@/utils/Asserts';
import { localDateTimeFromString } from '@/utils/DateUtils';
import { isDefined, requiredNonNull } from '@/utils/TsUtils';

type AmplifyTextContentReferenceOptions = {
  selectionJson: string;
};

type AmplifyWorkbookReferenceOptions = {
  selectionJson?: string;
  problemIndex: number;
};

type AmplifyContentReferenceOptions = {
  text?: AmplifyTextContentReferenceOptions;
  workbook?: AmplifyWorkbookReferenceOptions;
};

type AmplifyContentReference = {
  contentId: ContentId;
  contentVersion?: ContentVersion;
  options?: AmplifyContentReferenceOptions;
};

export type AmplifyMemo = {
  id: MemoId;
  body: MarkDownString;
  referTo?: AmplifyContentReference;
  scope: JSONString;
  groupId: GroupId;
  createdBy: UserId;
  createdAt: ISODateTimeString;
  updatedBy: UserId;
  updatedAt: ISODateTimeString;
};

type AmplifyMemoScope = {
  type: string;
  roles?: AmplifyGroupRole[];
};

function toAmplifyMemoScope(memoScope: MemoScope): AmplifyMemoScope {
  if (memoScope.type === 'group') {
    return {
      type: memoScope.type.toLocaleUpperCase(),
      roles: memoScope.roles.map((r) => r.toLocaleUpperCase() as AmplifyGroupRole),
    };
  }
  return {
    type: memoScope.type.toLocaleUpperCase(),
  };
}

function toMemoScope(memoScope: AmplifyMemoScope): MemoScope {
  if (memoScope.roles) {
    return {
      type: 'group',
      roles: memoScope.roles.map((r) => r.toLocaleLowerCase() as GroupRole),
    };
  }
  return {
    type: 'private',
  };
}

function toContentReferTo(referTo?: AmplifyContentReference): Optional<MemoContentReference> {
  if (referTo) {
    if (referTo.options) {
      if (referTo.options.text) {
        return {
          contentId: referTo.contentId,
          contentVersion: referTo.contentVersion ?? 1,
          selection: JSON.parse(referTo.options.text.selectionJson),
        };
      }
      if (referTo.options.workbook) {
        return {
          contentId: referTo.contentId,
          contentVersion: referTo.contentVersion ?? 1,
          problemIndex: referTo.options.workbook.problemIndex,
          selection: referTo.options.workbook.selectionJson
            ? JSON.parse(referTo.options.workbook.selectionJson)
            : undefined,
        };
      }
    }
    return {
      contentId: referTo.contentId,
      contentVersion: referTo.contentVersion ?? 1,
    };
  }
  return undefined;
}

export function toMemo(memo: AmplifyMemo): MemoEntity {
  return new MemoEntityImpl({
    id: memo.id,
    body: memo.body,
    referTo: toContentReferTo(memo.referTo),
    scope: toMemoScope(JSON.parse(memo.scope)),
    groupId: memo.groupId,
    createdBy: memo.createdBy,
    createdAt: localDateTimeFromString(memo.createdAt),
    updatedBy: memo.updatedBy,
    updatedAt: localDateTimeFromString(memo.updatedAt),
  });
}

function toAmplifyContentReference(
  contentReference?: MemoContentReference
): Optional<AmplifyContentReference> {
  if (contentReference) {
    if (isTextMemoContentReference(contentReference)) {
      return {
        contentId: contentReference.contentId,
        contentVersion: contentReference.contentVersion,
        options: {
          text: {
            selectionJson: JSON.stringify(contentReference.selection),
          },
        },
      };
    }
    if (isMemoWorkbookReference(contentReference)) {
      if (contentReference.selection) {
        return {
          contentId: contentReference.contentId,
          contentVersion: contentReference.contentVersion,
          options: {
            workbook: {
              selectionJson: JSON.stringify(contentReference.selection),
              problemIndex: contentReference.problemIndex,
            },
          },
        };
      }
      return {
        contentId: contentReference.contentId,
        contentVersion: contentReference.contentVersion,
        options: {
          workbook: {
            problemIndex: contentReference.problemIndex,
          },
        },
      };
    }
    return {
      contentId: contentReference.contentId,
      contentVersion: contentReference.contentVersion,
    };
  }
  return undefined;
}

export class AmplifyMemoRepository implements MemoRepository {
  constructor(private appContextProvider: AppContextProvider) {}

  async save(entity: MemoEntity): Promise<MemoEntity> {
    const tenantCode = requiredNonNull(
      this.appContextProvider.get().tenantCode,
      'appContext.tenantCode'
    );
    const userId = requiredNonNull(this.appContextProvider.get().user?.id, 'appContext.user');
    if (isDefined(entity.id)) {
      const input: UpdateMemoInput = {
        id: entity.id,
        body: entity.body,
        referTo: toAmplifyContentReference(entity.referTo),
        scope: JSON.stringify(toAmplifyMemoScope(entity.scope)),
        groupId: entity.groupId,
        createdBy: entity.createdBy,
        updatedBy: userId,
        tenantCode,
      };
      const res = await graphql<{ updateMemo: AmplifyMemo }>(mutations.updateMemo, { input });
      assertIsDefined(res);
      return toMemo(res.updateMemo);
    }
    const input: CreateMemoInput = {
      id: entity.id,
      body: entity.body,
      referTo: toAmplifyContentReference(entity.referTo),
      scope: JSON.stringify(toAmplifyMemoScope(entity.scope)),
      groupId: entity.groupId,
      createdBy: userId,
      updatedBy: userId,
      tenantCode,
    };
    const res = await graphql<{ createMemo: AmplifyMemo }>(mutations.createMemo, { input });
    assertIsDefined(res);
    return toMemo(res.createMemo);
  }

  async findById(id: MemoId): Promise<Optional<MemoEntity>> {
    const res = await graphql<{ getMemo: AmplifyMemo }>(queries.getMemo, { id });
    if (res.getMemo) {
      const appContext = this.appContextProvider.get();
      const memo = toMemo(res.getMemo);
      const userId = appContext.user?.id;
      assertIsDefined(userId, 'appContext.user');
      if (memo.scope.type === 'private' && memo.createdBy !== userId) return undefined;
      return memo;
    }
    return undefined;
  }

  async remove(id: MemoId): Promise<void> {
    const input: DeleteMemoInput = { id };
    await graphql<{ deleteMemo: AmplifyMemo }>(mutations.deleteMemo, { input });
  }

  async findByGroupId(groupId: GroupId): Promise<Array<MemoEntity>> {
    const groupRole = this.appContextProvider.get().roleInGroup(groupId);
    if (!groupRole) return [];
    const res = await graphql<{
      memosByGroup: { items: Array<AmplifyMemo> };
    }>(queries.memosByGroup, { groupId });
    const userId = this.appContextProvider.get().user?.id;
    assertIsDefined(userId, 'appContext.user');
    const memos = res.memosByGroup.items
      .map(toMemo)
      .filter((m) => !(m.scope.type === 'group' && !m.scope.roles.includes(groupRole)))
      .filter((m) => !(m.scope.type === 'private' && m.createdBy !== userId));
    return memos;
  }

  async findByGroupAndContent(groupId: GroupId, contentId: ContentId): Promise<Array<MemoEntity>> {
    const groupRole = this.appContextProvider.get().roleInGroup(groupId);
    if (!groupRole) return [];
    const res = await graphql<{
      memosByGroup: { items: Array<AmplifyMemo> };
    }>(queries.memosByGroup, { groupId });
    const userId = this.appContextProvider.get().user?.id;
    assertIsDefined(userId, 'appContext.user');
    const memos = res.memosByGroup.items
      .map(toMemo)
      .filter((m) => m.referTo?.contentId === contentId)
      .filter(
        (m) =>
          !(
            m.scope.type === 'group' &&
            !m.scope.roles.includes(groupRole) &&
            m.createdBy !== userId
          )
      )
      .filter((m) => !(m.scope.type === 'private' && m.createdBy !== userId));
    return memos;
  }
}
