import { CourseData } from '@/contents/domains';
import { localDateTimeNow } from '@/utils/DateUtils';

export const courses: CourseData[] = [
  {
    id: 'course-0',
    name: 'Java入門',
    description: '初版',
    version: 1,
    color: 'red',
    contents: [
      {
        type: 'text',
        name: '1) Javaとは',
        id: 'content00-0',
        requiredTime: 10,
      },
      {
        type: 'text',
        name: '2) メソッドとフィールド',
        id: 'content01-0',
        requiredTime: 10,
      },
      {
        type: 'text',
        name: '3) リテラル',
        id: 'content02-0',
        requiredTime: 10,
      },
      {
        type: 'exam',
        name: '4) 模擬試験A',
        id: 'content03-0',
        requiredTime: 10,
      },
      {
        type: 'text',
        name: '5) コンテンツ',
        id: 'content04-0',
        requiredTime: 10,
      },
      {
        type: 'text',
        name: '6) コンテンツ',
        id: 'content06-0',
        requiredTime: 10,
      },
      {
        type: 'exam',
        name: '7) 模擬試験B',
        id: 'content07-0',
        requiredTime: 10,
      },
    ],
    confirmedAt: localDateTimeNow(),
  },
  {
    id: 'course-11',
    name: 'SQL入門',
    description: '初版',
    version: 1,
    image: 'https://images.pexels.com/photos/416160/pexels-photo-416160.jpeg',
    fontColorOnImage: 'white',
    contents: [
      {
        type: 'text',
        name: 'コンテンツ1',
        id: 'content110-0',
        requiredTime: 10,
      },
    ],
    confirmedAt: localDateTimeNow(),
  },
  {
    id: 'course-12',
    name: 'ビジネス基礎',
    description: '二版',
    version: 2,
    contents: [
      {
        type: 'text',
        name: 'コンテンツ1',
        id: 'content120-0',
        requiredTime: 10,
      },
    ],
    confirmedAt: localDateTimeNow(),
  },
  {
    id: 'course-13',
    name: '無効化したコース',
    description: '三版',
    version: 3,
    contents: [
      {
        type: 'text',
        name: 'コンテンツ1',
        id: 'content130-0',
        requiredTime: 10,
      },
    ],
    confirmedAt: localDateTimeNow(),
  },
];
