export const LogLevels = {
  debug: 0,
  info: 1,
  warn: 2,
  error: 3,
  off: 4,
};

export type LogLevel = keyof typeof LogLevels;

export type LevelLessLogEntry = {
  message: string;
  [key: string]: unknown;
};

export type LogEntry = LevelLessLogEntry & {
  level: LogLevel;
};

export type Format = (entry: LogEntry) => unknown;

export interface Transporter {
  format: Format;
  level: LogLevel;
  log({ level: LogLevel, message: string }): void;
}

export interface Logger {
  log(entry: LogEntry): void;
  debug(entry: LevelLessLogEntry): void;
  debug(message: string): void;
  info(entry: LevelLessLogEntry): void;
  info(message: string): void;
  warn(entry: LevelLessLogEntry): void;
  warn(message: string): void;
  error(entry: LevelLessLogEntry): void;
  error(message: string): void;
}
