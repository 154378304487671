import { AuthorizationService, UserId, UserRepository } from '@/base/domains';
import { AbstractUseCase, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { REMOVE_USER_SUPERVISOR_IS_UNREMOVAL } from '../ErrorCodes';

export type RemoveUsersRequest = {
  userIds: Array<UserId>;
};

export type RemoveUsersResponse = {};

export interface RemoveUsers extends UseCase<RemoveUsersRequest, RemoveUsersResponse> {
  execute(request: RemoveUsersRequest): Promise<UseCaseResponse<RemoveUsersResponse>>;
}

export class RemoveUsersImpl
  extends AbstractUseCase<RemoveUsersRequest, RemoveUsersResponse>
  implements RemoveUsers
{
  constructor(
    private userRepository: UserRepository,
    private authorizationService: AuthorizationService
  ) {
    super('account.RemoveUsers');
  }

  async internalExecute(request: RemoveUsersRequest): Promise<RemoveUsersResponse> {
    this.authorizationService.assertNotPlayground();
    this.authorizationService.assertRole('supervisor', 'admin');
    const { userIds } = request;
    const users = await Promise.all(userIds.map((u) => this.userRepository.findById(u)));
    const supervisor = users.find((u) => u?.role === 'supervisor')?.id;
    if (supervisor) {
      throw REMOVE_USER_SUPERVISOR_IS_UNREMOVAL.toApplicationError({ userId: supervisor });
    }
    await Promise.all(userIds.map((userId) => this.userRepository.remove(userId)));
    return {};
  }
}

export const RemoveUsersKey = injectionKeyOf<RemoveUsers>({
  boundedContext: 'account',
  type: 'usecase',
  name: 'RemoveUsers',
});

export function useRemoveUsers(): RemoveUsers {
  return requiredInject(RemoveUsersKey);
}
