import { AppContextProvider, AuthorizationService } from '@/base/domains';
import { AbstractUseCase } from '@/base/usecases';
import {
  GetQuestionnaireByAnswerer,
  GetQuestionnaireByAnswererRequest,
  GetQuestionnaireByAnswererResponse,
} from '@/base/usecases/GetQuestionnaireByAnswerer';
import { assertIsDefined } from '@/utils/Asserts';

import { QuestionnaireRepository } from '../domains';

export class GetQuestionnaireByAnswererImpl
  extends AbstractUseCase<GetQuestionnaireByAnswererRequest, GetQuestionnaireByAnswererResponse>
  implements GetQuestionnaireByAnswerer
{
  constructor(
    private appContextProvider: AppContextProvider,
    private authorizationService: AuthorizationService,
    private questionnaireRepository: QuestionnaireRepository
  ) {
    super('training.GetQuestionnaireByAnswerer');
  }

  async internalExecute(
    request: GetQuestionnaireByAnswererRequest
  ): Promise<GetQuestionnaireByAnswererResponse> {
    const userId = this.appContextProvider.get().user?.id;
    assertIsDefined(userId, 'appContext.user');
    const { id } = request;
    const questionnaire = await this.questionnaireRepository.findByAnswerer(id, userId);
    if (questionnaire) {
      this.authorizationService.assertGroupReadAccessible(questionnaire.groupId);
      return { questionnaire };
    }
    return {};
  }
}
