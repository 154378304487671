import { ContentId, ContentVersion, CourseId, CourseVersion } from '@/base/domains';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

export type ContentHeader = {
  id: ContentId;
  version: ContentVersion;
  versionDescription?: string;
};

export interface ContentQueries {
  findContentHeadersByCourse(args: {
    courseId: CourseId;
    courseVersion: CourseVersion;
  }): Promise<Array<ContentHeader>>;
  findTenantContentHeaders(options?: { latest: boolean }): Promise<Array<ContentHeader>>;
}

export const ContentQueriesKey = injectionKeyOf<ContentQueries>({
  boundedContext: 'contents',
  type: 'adapter',
  name: 'ContentQueries',
});

export function useContentQueries(): ContentQueries {
  return requiredInject(ContentQueriesKey);
}
