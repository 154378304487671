import { AuthorizationService, GroupId, UserFinder, UserId, UserName } from '@/base/domains';
import { AbstractUseCase, assertEntityExists, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { ReportService, UserExamCorrections } from '../domains';

export type GetUserExamCorrectionsRequest = {
  userId: UserId;
  groupId: GroupId;
};

export type GetUserExamCorrectionsResponse = {
  userName: UserName;
  examResults: Array<UserExamCorrections>;
};

/**
 * 受講生のテスト正答率リストを取得する
 */
export interface GetUserExamCorrections
  extends UseCase<GetUserExamCorrectionsRequest, GetUserExamCorrectionsResponse> {
  execute(
    request: GetUserExamCorrectionsRequest
  ): Promise<UseCaseResponse<GetUserExamCorrectionsResponse>>;
}

export class GetUserExamCorrectionsImpl
  extends AbstractUseCase<GetUserExamCorrectionsRequest, GetUserExamCorrectionsResponse>
  implements GetUserExamCorrections
{
  constructor(
    private authorizationService: AuthorizationService,
    private userFinder: UserFinder,
    private reportService: ReportService
  ) {
    super('report.GetUserExamCorrections');
  }

  async internalExecute(
    request: GetUserExamCorrectionsRequest
  ): Promise<GetUserExamCorrectionsResponse> {
    const { userId, groupId } = request;
    this.authorizationService.assertGroupReportAccessible(groupId);
    const [user, examResults] = await Promise.all([
      this.userFinder.findById(userId),
      this.reportService.findUserExamCorrections(groupId, userId),
    ]);
    assertEntityExists(user, 'user');
    return { userName: user.name, examResults };
  }
}

export const GetUserExamCorrectionsKey = injectionKeyOf<GetUserExamCorrections>({
  boundedContext: 'report',
  type: 'usecase',
  name: 'GetUserExamCorrections',
});

export function useGetUserExamCorrections(): GetUserExamCorrections {
  return requiredInject(GetUserExamCorrectionsKey);
}
