import {
  ContentBody,
  ContentId,
  ContentName,
  ContentVersion,
  CourseId,
  CourseVersion,
  DataVersion,
  isText,
  Problem,
  ProblemHeaderId,
  Text,
  Workbook,
} from '@/base/domains';
import { EXCLUSIVE_CONTROL_ERROR } from '@/base/ErrorCodes';
import { LocalDateTime, MarkDownString, Minute } from '@/base/types';
import { ifDefined } from '@/utils/TsUtils';
import { uuid } from '@/utils/UniqueIdGenerator';

import {
  EditingConfirmedContentEntity,
  TextEditingConfirmedContentEntity,
} from './EditingConfirmedContent';
import { EditingStatus } from './EditingCourse';
import { WorkbookData, WorkbookImpl } from './Workbook';

export type TextEditingConfirmedContentEntityArgs = {
  id: ContentId;
  /** 名前 */
  name: ContentName;
  /** 所要時間 */
  requiredTime: Minute;
  /** コースID */
  courseId: CourseId;
  /** コースバージョン */
  courseVersion: CourseVersion;
  /** コンテンツタイプ */
  type: 'text';
  /** コンテンツの本体 */
  body: Text;
  /** 練習問題 */
  workbook?: WorkbookData;
  /** バージョン */
  version: ContentVersion;
  /** データバージョン */
  dataVersion: DataVersion;
  /** ステータス */
  status: EditingStatus;
  /** バージョン説明 */
  versionDescription?: string;
};

export class TextEditingConfirmedContentEntityImpl implements TextEditingConfirmedContentEntity {
  id: ContentId;

  type!: 'text';

  name: ContentName;

  requiredTime: Minute;

  courseId: CourseId;

  courseVersion: CourseVersion;

  body: Text;

  workbook?: Workbook;

  version: ContentVersion;

  dataVersion: DataVersion;

  status: EditingStatus;

  versionDescription?: string;

  constructor(args: TextEditingConfirmedContentEntityArgs) {
    this.id = args.id;
    this.type = args.type;
    this.body = args.body;
    this.name = args.name;
    this.requiredTime = args.requiredTime;
    this.body = args.body;
    this.courseId = args.courseId;
    this.courseVersion = args.courseVersion;
    this.workbook = ifDefined(args.workbook, (v) => new WorkbookImpl(v));
    this.version = args.version;
    this.dataVersion = args.dataVersion;
    this.status = args.status;
    this.versionDescription = args.versionDescription;
  }

  private checkConfirmed(): void {
    if (this.status === 'confirmed') {
      throw EXCLUSIVE_CONTROL_ERROR.toApplicationError({
        entity: 'editingConfirmedContent',
      });
    }
  }

  changeBody(body: ContentBody): EditingConfirmedContentEntity {
    this.checkConfirmed();
    if (!isText(body)) {
      throw new Error('body should be TEXT');
    }
    return new TextEditingConfirmedContentEntityImpl({
      ...this,
      body,
    });
  }

  confirm(versionDescription?: string): EditingConfirmedContentEntity {
    this.checkConfirmed();
    return new TextEditingConfirmedContentEntityImpl({
      ...this,
      status: 'confirmed',
      versionDescription,
    });
  }

  changeWorkbookProblemHeader({
    problemHeaderId,
    problemHeaderBody,
  }: {
    problemHeaderId: ProblemHeaderId;
    problemHeaderBody: MarkDownString;
  }): EditingConfirmedContentEntity {
    this.checkConfirmed();
    if (!this.workbook) {
      throw new Error('editingConfirmedContent.workbook should be exist');
    }
    const problemHeader = this.workbook.findProblemHeaderById(problemHeaderId);
    if (!problemHeader) {
      throw new Error(
        `editingConfirmedContent.workbook.problemHeader(id=${problemHeaderId}) should be exist`
      );
    }
    return new TextEditingConfirmedContentEntityImpl({
      ...this,
      workbook: new WorkbookImpl({
        problems: this.workbook.problems,
        problemHeaders: this.workbook.problemHeaders.map((ph) =>
          ph.id === problemHeader.id
            ? {
                ...problemHeader,
                body: problemHeaderBody,
              }
            : ph
        ),
      }),
    });
  }

  addProblemHeaderToWorkbook({
    problemHeaderBody,
    createdAt,
  }: {
    problemHeaderBody: MarkDownString;
    createdAt: LocalDateTime;
  }): EditingConfirmedContentEntity {
    this.checkConfirmed();
    const workbook = this.workbook ?? {
      problemHeaders: [],
      problems: [],
    };
    return new TextEditingConfirmedContentEntityImpl({
      ...this,
      workbook: new WorkbookImpl({
        problems: workbook.problems,
        problemHeaders: [
          ...workbook.problemHeaders,
          {
            id: uuid(),
            body: problemHeaderBody,
            createdAt,
          },
        ],
      }),
    });
  }

  removeWorkbookProblemHeader(problemHeaderId: ProblemHeaderId): EditingConfirmedContentEntity {
    this.checkConfirmed();
    if (!this.workbook) {
      throw new Error('editingConfirmedContent.workbook should be exist');
    }
    return new TextEditingConfirmedContentEntityImpl({
      ...this,
      workbook: {
        ...this.workbook,
        problemHeaders: this.workbook.problemHeaders.filter((ph) => ph.id !== problemHeaderId),
      },
    });
  }

  changeWorkbookProblems(problems: Array<Problem>): TextEditingConfirmedContentEntity {
    const workbook = this.workbook ?? {
      problemHeaders: [],
      problems,
    };
    return new TextEditingConfirmedContentEntityImpl({
      ...this,
      workbook: {
        ...workbook,
        problems,
      },
    });
  }
}
