import { Group, GroupRepository } from '@/base/domains';
import { UseCase, UseCaseResponse } from '@/base/usecases/UseCase';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

export interface GetGroupsResponse {
  groups: Array<Group>;
}

/**
 * グループ取得
 */
export interface GetGroups extends UseCase<{}, GetGroupsResponse> {
  execute(): Promise<UseCaseResponse<GetGroupsResponse>>;
}

export class GetGroupsImpl implements GetGroups {
  private groupRepository: GroupRepository;

  constructor(groupRepository: GroupRepository) {
    this.groupRepository = groupRepository;
  }

  async execute(): Promise<UseCaseResponse<GetGroupsResponse>> {
    const groups = await this.groupRepository.findTenantGroups({ includeDisabledGroup: true });
    return {
      useCaseName: 'admin.GetGroup',
      groups,
    };
  }
}

export const GetGroupsKey = injectionKeyOf<GetGroups>({
  boundedContext: 'admin',
  type: 'usecase',
  name: 'GetGroups',
});

export function useGetGroups(): GetGroups {
  return requiredInject(GetGroupsKey);
}
