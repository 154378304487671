import { ContentId, CourseId, Exam, Text } from '@/base/domains';
import { Optional } from '@/base/types';
import {
  createEditingConfirmedContentEntity,
  EditingConfirmedContentData,
  EditingConfirmedContentEntity,
  EditingConfirmedContentRepository,
  WorkbookData,
} from '@/contents/domains';
import { hasNonNullProperty } from '@/utils/TsUtils';

export class InMemoryEditingConfirmedContentRepository
  implements EditingConfirmedContentRepository
{
  private store = new Map<CourseId, EditingConfirmedContentEntity>();

  save(
    args: EditingConfirmedContentEntity | EditingConfirmedContentData
  ): Promise<EditingConfirmedContentEntity> {
    if (hasNonNullProperty(args, 'dataVersion')) {
      const e = (() => {
        if (args.type === 'exam') {
          return createEditingConfirmedContentEntity({
            id: args.id,
            type: 'exam',
            name: args.name,
            requiredTime: args.requiredTime,
            body: args.body,
            courseId: args.courseId,
            courseVersion: args.courseVersion,
            version: args.version,
            dataVersion: args.dataVersion + 1,
            status: args.status,
          });
        }
        if (args.type === 'text') {
          return createEditingConfirmedContentEntity({
            id: args.id,
            type: 'text',
            name: args.name,
            requiredTime: args.requiredTime,
            body: args.body,
            workbook: args.workbook,
            courseId: args.courseId,
            courseVersion: args.courseVersion,
            version: args.version,
            dataVersion: args.dataVersion + 1,
            status: args.status,
          });
        }
        throw new Error('Unsupported type');
      })();
      this.store.set(e.id, e);
      return Promise.resolve(e);
    }
    const e = (() => {
      if (args.type === 'exam') {
        return createEditingConfirmedContentEntity({
          id: args.id,
          type: 'exam',
          name: args.name,
          requiredTime: args.requiredTime,
          body: args.body as Exam,
          courseId: args.courseId,
          courseVersion: args.courseVersion,
          version: args.version,
          dataVersion: 1,
          status: 'editing',
        });
      }
      if (args.type === 'text') {
        return createEditingConfirmedContentEntity({
          id: args.id,
          type: 'text',
          name: args.name,
          requiredTime: args.requiredTime,
          body: args.body as Text,
          workbook: hasNonNullProperty(args, 'workbook')
            ? (args.workbook as WorkbookData)
            : undefined,
          courseId: args.courseId,
          courseVersion: args.courseVersion,
          version: args.version,
          dataVersion: 1,
          status: 'editing',
        });
      }
      throw new Error('Unsupported type');
    })();
    this.store.set(e.id, e);
    return Promise.resolve(e);
  }

  findById(id: ContentId): Promise<Optional<EditingConfirmedContentEntity>> {
    return Promise.resolve(this.store.get(id));
  }

  remove(id: ContentId): Promise<void> {
    Promise.resolve(this.store.delete(id));
    return Promise.resolve();
  }
}
