import { AuthorizationService, ContentId, DataVersion, ProblemHeaderId } from '@/base/domains';
import { MarkDownString } from '@/base/types';
import {
  AbstractUseCase,
  assertDataVersion,
  assertEntityExists,
  UseCase,
  UseCaseResponse,
} from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { EditingCourseContentBodyReference, EditingCourseContentBodyRepository } from '../domains';
import { CONTENT_TYPE_SHOULD_BE_EXAM } from '../ErrorCodes';

export type ChangeEditingCourseContentProblemHeaderRequest = {
  id: ContentId;
  problemHeaderId: ProblemHeaderId;
  body: MarkDownString;
  expectedDataVersion: DataVersion;
};

export type ChangeEditingCourseContentProblemHeaderResponse = {
  content: EditingCourseContentBodyReference;
};

/**
 * 編集中コンテンツの問題ヘッダーを変更する
 */
export interface ChangeEditingCourseContentProblemHeader
  extends UseCase<
    ChangeEditingCourseContentProblemHeaderRequest,
    ChangeEditingCourseContentProblemHeaderResponse
  > {
  execute(
    request: ChangeEditingCourseContentProblemHeaderRequest
  ): Promise<UseCaseResponse<ChangeEditingCourseContentProblemHeaderResponse>>;
}

export class ChangeEditingCourseContentProblemHeaderImpl
  extends AbstractUseCase<
    ChangeEditingCourseContentProblemHeaderRequest,
    ChangeEditingCourseContentProblemHeaderResponse
  >
  implements ChangeEditingCourseContentProblemHeader
{
  private authorizationService: AuthorizationService;

  private editingCourseContentBodyRepository: EditingCourseContentBodyRepository;

  constructor(
    authorizationService: AuthorizationService,
    editingCourseContentBodyRepository: EditingCourseContentBodyRepository
  ) {
    super('contents.ChangeEditingCourseContentProblemHeader');
    this.authorizationService = authorizationService;
    this.editingCourseContentBodyRepository = editingCourseContentBodyRepository;
  }

  async internalExecute(
    request: ChangeEditingCourseContentProblemHeaderRequest
  ): Promise<ChangeEditingCourseContentProblemHeaderResponse> {
    const { id, problemHeaderId, body, expectedDataVersion } = request;
    this.authorizationService.assertContentEditable();
    const content = await this.editingCourseContentBodyRepository.findById(id);
    assertEntityExists(content, 'editingCourseContentBody');
    assertDataVersion(content, expectedDataVersion, 'editingCourseContentBody');
    if (content.type !== 'exam') {
      throw CONTENT_TYPE_SHOULD_BE_EXAM.toApplicationError({
        payload: {
          id,
        },
      });
    }

    const saved = await this.editingCourseContentBodyRepository.save(
      content.changeProblemHeader({
        id: problemHeaderId,
        body,
      })
    );
    return {
      content: saved,
    };
  }
}

export const ChangeEditingCourseContentProblemHeaderKey =
  injectionKeyOf<ChangeEditingCourseContentProblemHeader>({
    boundedContext: 'contents',
    type: 'usecase',
    name: 'ChangeEditingCourseContentProblemHeader',
  });

export function useChangeEditingCourseContentProblemHeader(): ChangeEditingCourseContentProblemHeader {
  return requiredInject(ChangeEditingCourseContentProblemHeaderKey);
}
