import { AbstractUseCase, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { PlaygroundId, PlaygroundReference, PlaygroundRepository } from '../domains';

export type GetPlaygroundRequest = {
  id: PlaygroundId;
};

export type GetPlaygroundResponse = {
  playground?: PlaygroundReference;
};

/**
 * プレイグラウンドを取得する
 */
export interface GetPlayground extends UseCase<GetPlaygroundRequest, GetPlaygroundResponse> {
  execute(request: GetPlaygroundRequest): Promise<UseCaseResponse<GetPlaygroundResponse>>;
}

export class GetPlaygroundImpl
  extends AbstractUseCase<GetPlaygroundRequest, GetPlaygroundResponse>
  implements GetPlayground
{
  constructor(private playgroundRepository: PlaygroundRepository) {
    super('playground.GetPlayground');
  }

  async internalExecute(request: GetPlaygroundRequest): Promise<GetPlaygroundResponse> {
    const playground = await this.playgroundRepository.findById(request.id);
    return {
      playground,
    };
  }
}

export const GetPlaygroundKey = injectionKeyOf<GetPlayground>({
  boundedContext: 'playground',
  type: 'usecase',
  name: 'GetPlayground',
});

export function useGetPlayground(): GetPlayground {
  return requiredInject(GetPlaygroundKey);
}
