import {
  AuthorizationService,
  ENABLED_GROUP_LIMITATION,
  Group,
  GroupLimitation,
  GroupName,
  GroupRepository,
  GroupUser,
  localEventBus,
} from '@/base/domains';
import { GroupExtensionConfig } from '@/base/domains/extensions/Extension';
import { groupCreated } from '@/base/domains/LocalEvents';
import { AbstractUseCase, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { ENABLED_GROUP_LIMITATION_EXCEEDED } from '../ErrorCodes';

export interface CreateGroupRequest {
  name: GroupName;
  extensionConfigs?: Array<GroupExtensionConfig>;
  users?: Array<GroupUser>;
  limitations?: GroupLimitation;
  description?: string;
}

export type CreateGroupResponse = {
  group: Group;
};

export interface CreateGroup extends UseCase<CreateGroupRequest, CreateGroupResponse> {
  execute(request: CreateGroupRequest): Promise<UseCaseResponse<CreateGroupResponse>>;
}

export class CreateGroupImpl
  extends AbstractUseCase<CreateGroupRequest, CreateGroupResponse>
  implements CreateGroup
{
  private authorizationService: AuthorizationService;

  private groupRepository: GroupRepository;

  constructor(authorizationService: AuthorizationService, groupRepository: GroupRepository) {
    super('admin.CreateGroup');
    this.authorizationService = authorizationService;
    this.groupRepository = groupRepository;
  }

  async internalExecute(request: CreateGroupRequest): Promise<CreateGroupResponse> {
    this.authorizationService.assertRole('supervisor', 'admin');
    const {
      name,
      extensionConfigs = [],
      users = [],
      limitations = { question: [], schedule: [], questionnaire: [] },
      description,
    } = request;
    const tenantGroups = await this.groupRepository.findTenantGroups();
    const enabledGroups = tenantGroups.filter((g) => g.enabled);
    if (enabledGroups.length >= ENABLED_GROUP_LIMITATION) {
      throw ENABLED_GROUP_LIMITATION_EXCEEDED.toApplicationError({});
    }

    const group = await this.groupRepository.save({
      name,
      extensionConfigs,
      users,
      limitations,
      enabled: true,
      description,
    });

    localEventBus.publish(groupCreated({ groupId: group.id }));

    return {
      group,
    };
  }
}

export const CreateGroupKey = injectionKeyOf<CreateGroup>({
  boundedContext: 'admin',
  type: 'usecase',
  name: 'CreateGroup',
});

export function useCreateGroup(): CreateGroup {
  return requiredInject(CreateGroupKey);
}
