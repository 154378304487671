import { AppContextProvider, AuthorizationService } from '@/base/domains';
import {
  AbstractUseCase,
  GetQuestionnaires,
  GetQuestionnairesRequest,
  GetQuestionnairesResponse,
} from '@/base/usecases';

import { QuestionnaireRepository } from '../domains';

export class GetQuestionnairesImpl
  extends AbstractUseCase<GetQuestionnairesRequest, GetQuestionnairesResponse>
  implements GetQuestionnaires
{
  constructor(
    private appContextProvider: AppContextProvider,
    private authorizationService: AuthorizationService,
    private questionnaireRepository: QuestionnaireRepository
  ) {
    super('training.GetQuestionnaire');
  }

  async internalExecute(request: GetQuestionnairesRequest): Promise<GetQuestionnairesResponse> {
    const { groupId } = request;
    const questionnaires = await this.questionnaireRepository.findByGroupId(groupId);
    const role = this.appContextProvider.get().user?.role;
    if (role !== 'general') return { questionnaires };
    this.authorizationService.assertTrainerOrMentorInGroup(groupId);
    return { questionnaires };
  }
}
