/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type InviteUserInput = {
  email?: string | null,
  code?: string | null,
  role: Role,
  name?: string | null,
  groups?: Array< CreateGroupGroupUserInput > | null,
  createdBy?: string | null,
};

export enum Role {
  GENERAL = "GENERAL",
  ADMIN = "ADMIN",
  SUPERVISOR = "SUPERVISOR",
}


export type CreateGroupGroupUserInput = {
  id: string,
  role: GroupRole,
  removed: boolean,
};

export enum GroupRole {
  TRAINER = "TRAINER",
  TRAINEE = "TRAINEE",
  MENTOR = "MENTOR",
}


export type InviteUserResponse = {
  __typename: "InviteUserResponse",
  signUpReservationId: string,
};

export type UploadResponse = {
  __typename: "UploadResponse",
  url: string,
  key: string,
};

export type CreateGroupInput = {
  id?: string | null,
  name: string,
  users: Array< CreateGroupGroupUserInput >,
  extensionConfigJsonList: Array< string >,
  tenantCode: string,
  limitations: string,
  enabled: boolean,
  description?: string | null,
  createdBy: string,
  updatedBy: string,
};

export type Group = {
  __typename: "Group",
  id: string,
  name: string,
  users:  Array<GroupUser >,
  extensionConfigJsonList: Array< string >,
  tenantCode: string,
  limitations?: string | null,
  enabled?: boolean | null,
  description?: string | null,
  createdBy?: string | null,
  updatedBy?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type GroupUser = {
  __typename: "GroupUser",
  id: string,
  role: GroupRole,
  removed?: boolean | null,
};

export type UpdateGroupInput = {
  id: string,
  name: string,
  users: Array< CreateGroupGroupUserInput >,
  extensionConfigJsonList: Array< string >,
  tenantCode: string,
  limitations: string,
  enabled: boolean,
  description?: string | null,
  createdBy: string,
  updatedBy: string,
};

export type UpdateEditingCourseInput = {
  id: string,
  tenantCode: string,
  name: string,
  version: number,
  status: EditingStatus,
  description?: string | null,
  contents: Array< EditingCourseContentHeaderInput >,
  createdBy: string,
  updatedBy: string,
  color?: string | null,
  image?: string | null,
  fontColorOnImage?: CourseFontColorOnImage | null,
};

export enum EditingStatus {
  EDITING = "EDITING",
  CONFIRMED = "CONFIRMED",
}


export type EditingCourseContentHeaderInput = {
  id: string,
  name: string,
  requiredTime: number,
  type: ContentType,
};

export enum ContentType {
  EXAM = "EXAM",
  SLIDE = "SLIDE",
  TEXT = "TEXT",
}


export enum CourseFontColorOnImage {
  BLACK = "BLACK",
  WHITE = "WHITE",
}


export type EditingCourse = {
  __typename: "EditingCourse",
  id: string,
  tenantCode: string,
  name: string,
  version: number,
  status: EditingStatus,
  description?: string | null,
  contents:  Array<EditingCourseContentHeader >,
  color?: string | null,
  image?: string | null,
  fontColorOnImage?: CourseFontColorOnImage | null,
  createdBy: string,
  createdAt?: string | null,
  updatedBy: string,
  updatedAt?: string | null,
};

export type EditingCourseContentHeader = {
  __typename: "EditingCourseContentHeader",
  id: string,
  name: string,
  requiredTime: number,
  type: ContentType,
};

export type CreateCourseHeaderInput = {
  id?: string | null,
  name: string,
  activeVersion: number,
  status: CourseStatus,
  originalCourseId?: string | null,
  originalCourseVersion?: number | null,
  createdBy: string,
  tenantCode: string,
};

export enum CourseStatus {
  EDITING = "EDITING",
  ENABLED = "ENABLED",
  DISABLED = "DISABLED",
}


export type CourseHeader = {
  __typename: "CourseHeader",
  id: string,
  name: string,
  activeVersion: number,
  status: CourseStatus,
  originalCourseId?: string | null,
  originalCourseVersion?: number | null,
  tenantCode: string,
  createdBy: string,
  createdAt: string,
  updatedAt: string,
};

export type CreateUserByAdminInput = {
  name: string,
  email?: string | null,
  code?: string | null,
  password: string,
  role?: Role | null,
  groups?: Array< CreateGroupGroupUserInput > | null,
  forcePasswordChange: boolean,
};

export type CreateUserByAdminResponse = {
  __typename: "CreateUserByAdminResponse",
  userId: string,
};

export type ChangeConfirmedCourseNameInput = {
  id: string,
  name: string,
};

export type AddUserDeviceInput = {
  deviceType: string,
  deviceToken: string,
};

export type DeleteGroupInput = {
  id: string,
};

export type ModelGroupConditionInput = {
  name?: ModelStringInput | null,
  extensionConfigJsonList?: ModelStringInput | null,
  limitations?: ModelStringInput | null,
  enabled?: ModelBooleanInput | null,
  description?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  updatedBy?: ModelIDInput | null,
  and?: Array< ModelGroupConditionInput | null > | null,
  or?: Array< ModelGroupConditionInput | null > | null,
  not?: ModelGroupConditionInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type UpdateNotificationInput = {
  id: string,
  type?: string | null,
  payloadJson?: string | null,
  userId?: string | null,
  read?: boolean | null,
  createdAt?: string | null,
  tenantCode?: string | null,
};

export type ModelNotificationConditionInput = {
  type?: ModelStringInput | null,
  payloadJson?: ModelStringInput | null,
  userId?: ModelStringInput | null,
  read?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelNotificationConditionInput | null > | null,
  or?: Array< ModelNotificationConditionInput | null > | null,
  not?: ModelNotificationConditionInput | null,
};

export type Notification = {
  __typename: "Notification",
  id: string,
  type: string,
  payloadJson: string,
  userId: string,
  read: boolean,
  createdAt?: string | null,
  tenantCode: string,
  updatedAt: string,
};

export type DeleteNotificationInput = {
  id: string,
};

export type UpdateUserInput = {
  id: string,
  name?: string | null,
  role?: Role | null,
  extensionConfigJsonList?: Array< string > | null,
  email?: string | null,
  code?: string | null,
  tenantCode?: string | null,
  displaySettings?: DisplaySettingsInput | null,
  locale?: string | null,
  avatar?: string | null,
  enabled?: boolean | null,
  signedInAtLeastOnce?: boolean | null,
  emailConfirmed?: boolean | null,
  confirmedTermsOfServiceVersions?: ConfirmedTermsOfServiceVersionsInput | null,
};

export type DisplaySettingsInput = {
  theme?: string | null,
  color?: string | null,
};

export type ConfirmedTermsOfServiceVersionsInput = {
  termsOfServiceVersion?: string | null,
  privacyPolicyVersion?: string | null,
  tenantOwnTermsOfServiceVersion?: number | null,
};

export type ModelUserConditionInput = {
  name?: ModelStringInput | null,
  role?: ModelRoleInput | null,
  extensionConfigJsonList?: ModelStringInput | null,
  email?: ModelStringInput | null,
  code?: ModelStringInput | null,
  locale?: ModelStringInput | null,
  avatar?: ModelStringInput | null,
  enabled?: ModelBooleanInput | null,
  signedInAtLeastOnce?: ModelBooleanInput | null,
  emailConfirmed?: ModelBooleanInput | null,
  and?: Array< ModelUserConditionInput | null > | null,
  or?: Array< ModelUserConditionInput | null > | null,
  not?: ModelUserConditionInput | null,
};

export type ModelRoleInput = {
  eq?: Role | null,
  ne?: Role | null,
};

export type User = {
  __typename: "User",
  id: string,
  name: string,
  role: Role,
  extensionConfigJsonList: Array< string >,
  email?: string | null,
  code?: string | null,
  tenantCode: string,
  displaySettings?: DisplaySettings | null,
  locale?: string | null,
  avatar?: string | null,
  enabled: boolean,
  signedInAtLeastOnce: boolean,
  emailConfirmed?: boolean | null,
  confirmedTermsOfServiceVersions?: ConfirmedTermsOfServiceVersions | null,
  createdAt: string,
  updatedAt: string,
  tags?: ModelUserUserTagConnection | null,
  statuses?: ModelUserStatusTableConnection | null,
};

export type DisplaySettings = {
  __typename: "DisplaySettings",
  theme?: string | null,
  color?: string | null,
};

export type ConfirmedTermsOfServiceVersions = {
  __typename: "ConfirmedTermsOfServiceVersions",
  termsOfServiceVersion?: string | null,
  privacyPolicyVersion?: string | null,
  tenantOwnTermsOfServiceVersion?: number | null,
};

export type ModelUserUserTagConnection = {
  __typename: "ModelUserUserTagConnection",
  items:  Array<UserUserTag | null >,
  nextToken?: string | null,
};

export type UserUserTag = {
  __typename: "UserUserTag",
  id: string,
  userId: string,
  userTagId: string,
  tenantCode: string,
  createdAt: string,
  updatedAt: string,
  user: User,
  userTag: UserTag,
};

export type UserTag = {
  __typename: "UserTag",
  id: string,
  color: string,
  text: string,
  tenantCode: string,
  createdAt: string,
  updatedAt: string,
  users?: ModelUserUserTagConnection | null,
};

export type ModelUserStatusTableConnection = {
  __typename: "ModelUserStatusTableConnection",
  items:  Array<UserStatusTable | null >,
  nextToken?: string | null,
};

export type UserStatusTable = {
  __typename: "UserStatusTable",
  id: string,
  tenantCode: string,
  status: UserStatus,
  userId: string,
  createdAt: string,
  updatedAt: string,
  user: User,
};

export enum UserStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}


export type DeleteUserInput = {
  id: string,
};

export type CreateUserDeviceInput = {
  userId: string,
  devices: string,
  tenantCode: string,
};

export type ModelUserDeviceConditionInput = {
  devices?: ModelStringInput | null,
  dataVersion?: ModelIntInput | null,
  tenantCode?: ModelStringInput | null,
  and?: Array< ModelUserDeviceConditionInput | null > | null,
  or?: Array< ModelUserDeviceConditionInput | null > | null,
  not?: ModelUserDeviceConditionInput | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type UserDevice = {
  __typename: "UserDevice",
  userId: string,
  devices: string,
  dataVersion: number,
  tenantCode: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateUserDeviceInput = {
  userId: string,
  devices?: string | null,
  dataVersion?: number | null,
  tenantCode?: string | null,
  expectedDataVersion: number,
};

export type DeleteUserDeviceInput = {
  userId: string,
  expectedDataVersion: number,
};

export type CreateSignUpReservationInput = {
  id?: string | null,
  name?: string | null,
  key: string,
  userCode?: string | null,
  email?: string | null,
  tenantCode: string,
  type: SignUpType,
  role: Role,
  userId?: string | null,
  signUpInput?: SignUpInputInput | null,
  status: SignUpReservationStatus,
  groups?: Array< GroupUserInput > | null,
  createdBy?: string | null,
};

export enum SignUpType {
  USER_CODE = "USER_CODE",
  EMAIL = "EMAIL",
}


export type SignUpInputInput = {
  name: string,
  tenantName?: string | null,
};

export enum SignUpReservationStatus {
  NOT_SIGNED_UP = "NOT_SIGNED_UP",
  SIGNED_UP = "SIGNED_UP",
  CONFIRMED = "CONFIRMED",
}


export type GroupUserInput = {
  id: string,
  role: GroupRole,
  removed?: boolean | null,
};

export type ModelSignUpReservationConditionInput = {
  name?: ModelStringInput | null,
  key?: ModelStringInput | null,
  userCode?: ModelStringInput | null,
  email?: ModelStringInput | null,
  type?: ModelSignUpTypeInput | null,
  role?: ModelRoleInput | null,
  userId?: ModelStringInput | null,
  status?: ModelSignUpReservationStatusInput | null,
  createdBy?: ModelIDInput | null,
  and?: Array< ModelSignUpReservationConditionInput | null > | null,
  or?: Array< ModelSignUpReservationConditionInput | null > | null,
  not?: ModelSignUpReservationConditionInput | null,
};

export type ModelSignUpTypeInput = {
  eq?: SignUpType | null,
  ne?: SignUpType | null,
};

export type ModelSignUpReservationStatusInput = {
  eq?: SignUpReservationStatus | null,
  ne?: SignUpReservationStatus | null,
};

export type SignUpReservation = {
  __typename: "SignUpReservation",
  id: string,
  name?: string | null,
  key: string,
  userCode?: string | null,
  email?: string | null,
  tenantCode: string,
  type: SignUpType,
  role: Role,
  userId?: string | null,
  signUpInput?: SignUpInput | null,
  status: SignUpReservationStatus,
  groups?:  Array<GroupUser > | null,
  createdBy?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type SignUpInput = {
  __typename: "SignUpInput",
  name: string,
  tenantName?: string | null,
};

export type UpdateSignUpReservationInput = {
  id: string,
  name?: string | null,
  key?: string | null,
  userCode?: string | null,
  email?: string | null,
  tenantCode?: string | null,
  type?: SignUpType | null,
  role?: Role | null,
  userId?: string | null,
  signUpInput?: SignUpInputInput | null,
  status?: SignUpReservationStatus | null,
  groups?: Array< GroupUserInput > | null,
  createdBy?: string | null,
};

export type DeleteSignUpReservationInput = {
  id: string,
};

export type UpdateTenantInput = {
  id: string,
  code?: string | null,
  name?: string | null,
  signUpType?: SignUpType | null,
  playground?: boolean | null,
  userLimit?: number | null,
  limitations?: string | null,
};

export type ModelTenantConditionInput = {
  name?: ModelStringInput | null,
  signUpType?: ModelSignUpTypeInput | null,
  playground?: ModelBooleanInput | null,
  userLimit?: ModelIntInput | null,
  limitations?: ModelStringInput | null,
  and?: Array< ModelTenantConditionInput | null > | null,
  or?: Array< ModelTenantConditionInput | null > | null,
  not?: ModelTenantConditionInput | null,
};

export type Tenant = {
  __typename: "Tenant",
  id: string,
  code: string,
  name: string,
  signUpType: SignUpType,
  playground: boolean,
  userLimit?: number | null,
  limitations?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type DeleteTenantInput = {
  id: string,
};

export type UpdateTenantOwnTermsOfServiceInput = {
  id: string,
  tenantCode?: string | null,
  type?: TenantOwnTermsOfServiceType | null,
  body?: string | null,
  createdBy?: string | null,
  createdAt?: string | null,
  updatedBy?: string | null,
  updatedAt?: string | null,
  version?: number | null,
};

export enum TenantOwnTermsOfServiceType {
  EDITING = "EDITING",
  CONFIRMED = "CONFIRMED",
}


export type ModelTenantOwnTermsOfServiceConditionInput = {
  type?: ModelTenantOwnTermsOfServiceTypeInput | null,
  body?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedBy?: ModelIDInput | null,
  updatedAt?: ModelStringInput | null,
  version?: ModelIntInput | null,
  and?: Array< ModelTenantOwnTermsOfServiceConditionInput | null > | null,
  or?: Array< ModelTenantOwnTermsOfServiceConditionInput | null > | null,
  not?: ModelTenantOwnTermsOfServiceConditionInput | null,
};

export type ModelTenantOwnTermsOfServiceTypeInput = {
  eq?: TenantOwnTermsOfServiceType | null,
  ne?: TenantOwnTermsOfServiceType | null,
};

export type TenantOwnTermsOfService = {
  __typename: "TenantOwnTermsOfService",
  id: string,
  tenantCode: string,
  type: TenantOwnTermsOfServiceType,
  body: string,
  createdBy: string,
  createdAt: string,
  updatedBy?: string | null,
  updatedAt?: string | null,
  version?: number | null,
};

export type DeleteTenantOwnTermsOfServiceInput = {
  id: string,
};

export type CreateEventInput = {
  id?: string | null,
  type: string,
  event: string,
  version: number,
  tenantCode: string,
  occurredBy: string,
  createdAt?: string | null,
};

export type ModelEventConditionInput = {
  type?: ModelStringInput | null,
  event?: ModelStringInput | null,
  version?: ModelIntInput | null,
  occurredBy?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelEventConditionInput | null > | null,
  or?: Array< ModelEventConditionInput | null > | null,
  not?: ModelEventConditionInput | null,
};

export type Event = {
  __typename: "Event",
  id: string,
  type: string,
  event: string,
  version: number,
  tenantCode: string,
  occurredBy: string,
  createdAt?: string | null,
  updatedAt: string,
};

export type UpdateEventInput = {
  id: string,
  type?: string | null,
  event?: string | null,
  version?: number | null,
  tenantCode?: string | null,
  occurredBy?: string | null,
  createdAt?: string | null,
};

export type DeleteEventInput = {
  id: string,
};

export type CreateTermsOfServiceInput = {
  id?: string | null,
  body: string,
};

export type ModelTermsOfServiceConditionInput = {
  body?: ModelStringInput | null,
  and?: Array< ModelTermsOfServiceConditionInput | null > | null,
  or?: Array< ModelTermsOfServiceConditionInput | null > | null,
  not?: ModelTermsOfServiceConditionInput | null,
};

export type TermsOfService = {
  __typename: "TermsOfService",
  id: string,
  body: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateTermsOfServiceInput = {
  id: string,
  body?: string | null,
};

export type DeleteTermsOfServiceInput = {
  id: string,
};

export type CreateLiveInput = {
  id?: string | null,
  userId: string,
  url: string,
  applicationSessionId: string,
  tenantCode: string,
  createdAt?: string | null,
};

export type ModelLiveConditionInput = {
  userId?: ModelIDInput | null,
  url?: ModelStringInput | null,
  applicationSessionId?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelLiveConditionInput | null > | null,
  or?: Array< ModelLiveConditionInput | null > | null,
  not?: ModelLiveConditionInput | null,
};

export type Live = {
  __typename: "Live",
  id: string,
  userId: string,
  url: string,
  applicationSessionId: string,
  tenantCode: string,
  createdAt?: string | null,
  updatedAt: string,
};

export type UpdateLiveInput = {
  id: string,
  userId?: string | null,
  url?: string | null,
  applicationSessionId?: string | null,
  tenantCode?: string | null,
  createdAt?: string | null,
};

export type DeleteLiveInput = {
  id: string,
};

export type CreateGroupStatusUserInput = {
  id?: string | null,
  userId: string,
  groupId: string,
  handStatus: HandStatus,
  tenantCode: string,
};

export enum HandStatus {
  YES = "YES",
  NO = "NO",
  NONE = "NONE",
}


export type ModelGroupStatusUserConditionInput = {
  userId?: ModelIDInput | null,
  groupId?: ModelIDInput | null,
  handStatus?: ModelHandStatusInput | null,
  and?: Array< ModelGroupStatusUserConditionInput | null > | null,
  or?: Array< ModelGroupStatusUserConditionInput | null > | null,
  not?: ModelGroupStatusUserConditionInput | null,
};

export type ModelHandStatusInput = {
  eq?: HandStatus | null,
  ne?: HandStatus | null,
};

export type GroupStatusUser = {
  __typename: "GroupStatusUser",
  id: string,
  userId: string,
  groupId: string,
  handStatus: HandStatus,
  tenantCode: string,
  createdAt: string,
  updatedAt: string,
};

export type CreateGroupStatusInput = {
  groupId: string,
  tenantCode: string,
};

export type ModelGroupStatusConditionInput = {
  and?: Array< ModelGroupStatusConditionInput | null > | null,
  or?: Array< ModelGroupStatusConditionInput | null > | null,
  not?: ModelGroupStatusConditionInput | null,
};

export type GroupStatus = {
  __typename: "GroupStatus",
  groupId: string,
  tenantCode: string,
  createdAt: string,
  updatedAt: string,
  users?: ModelGroupStatusUserConnection | null,
};

export type ModelGroupStatusUserConnection = {
  __typename: "ModelGroupStatusUserConnection",
  items:  Array<GroupStatusUser | null >,
  nextToken?: string | null,
};

export type UpdateGroupStatusInput = {
  groupId: string,
  tenantCode?: string | null,
};

export type DeleteGroupStatusInput = {
  groupId: string,
};

export type CreateQuestionnaireInput = {
  id?: string | null,
  groupId: string,
  tenantCode: string,
  title: string,
  userIds: Array< string >,
  text?: string | null,
  createdBy: string,
  createdAt?: string | null,
  options: Array< string >,
  status: QuestionnaireStatus,
  finishedAt?: string | null,
};

export enum QuestionnaireStatus {
  FINISHED = "FINISHED",
  ACTIVE = "ACTIVE",
}


export type ModelQuestionnaireConditionInput = {
  groupId?: ModelIDInput | null,
  title?: ModelStringInput | null,
  userIds?: ModelIDInput | null,
  text?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  options?: ModelStringInput | null,
  status?: ModelQuestionnaireStatusInput | null,
  finishedAt?: ModelStringInput | null,
  and?: Array< ModelQuestionnaireConditionInput | null > | null,
  or?: Array< ModelQuestionnaireConditionInput | null > | null,
  not?: ModelQuestionnaireConditionInput | null,
};

export type ModelQuestionnaireStatusInput = {
  eq?: QuestionnaireStatus | null,
  ne?: QuestionnaireStatus | null,
};

export type Questionnaire = {
  __typename: "Questionnaire",
  id: string,
  groupId: string,
  tenantCode: string,
  title: string,
  userIds: Array< string >,
  text?: string | null,
  createdBy: string,
  createdAt?: string | null,
  options: Array< string >,
  respondent?: ModelQuestionnaireRespondentConnection | null,
  status: QuestionnaireStatus,
  finishedAt?: string | null,
  updatedAt: string,
};

export type ModelQuestionnaireRespondentConnection = {
  __typename: "ModelQuestionnaireRespondentConnection",
  items:  Array<QuestionnaireRespondent | null >,
  nextToken?: string | null,
};

export type QuestionnaireRespondent = {
  __typename: "QuestionnaireRespondent",
  id: string,
  questionnaireId: string,
  userId: string,
  selectedIndex: number,
  tenantCode: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateQuestionnaireInput = {
  id: string,
  groupId?: string | null,
  tenantCode?: string | null,
  title?: string | null,
  userIds?: Array< string > | null,
  text?: string | null,
  createdBy?: string | null,
  createdAt?: string | null,
  options?: Array< string > | null,
  status?: QuestionnaireStatus | null,
  finishedAt?: string | null,
};

export type DeleteQuestionnaireInput = {
  id: string,
};

export type CreateQuestionnaireRespondentInput = {
  id?: string | null,
  questionnaireId: string,
  userId: string,
  selectedIndex: number,
  tenantCode: string,
};

export type ModelQuestionnaireRespondentConditionInput = {
  questionnaireId?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  selectedIndex?: ModelIntInput | null,
  and?: Array< ModelQuestionnaireRespondentConditionInput | null > | null,
  or?: Array< ModelQuestionnaireRespondentConditionInput | null > | null,
  not?: ModelQuestionnaireRespondentConditionInput | null,
};

export type UpdateQuestionnaireRespondentInput = {
  id: string,
  questionnaireId?: string | null,
  userId?: string | null,
  selectedIndex?: number | null,
  tenantCode?: string | null,
};

export type DeleteQuestionnaireRespondentInput = {
  id: string,
};

export type UpdateContentInput = {
  id: string,
  contentId?: string | null,
  version?: number | null,
  name?: string | null,
  requiredTime?: number | null,
  body?: ContentBodyInput | null,
  workbook?: WorkbookInput | null,
  courseId?: string | null,
  courseVersion?: number | null,
  tenantCode?: string | null,
  latest?: boolean | null,
  versionDescription?: string | null,
};

export type ContentBodyInput = {
  type: ContentType,
  text?: TextContentInput | null,
  exam?: ExamContentInput | null,
};

export type TextContentInput = {
  body: string,
};

export type ExamContentInput = {
  problems: Array< ProblemInput >,
  problemHeaders?: Array< ProblemHeaderInput > | null,
  passingStandard?: number | null,
};

export type ProblemInput = {
  index: number,
  body: string,
  commentary?: string | null,
  type: ProblemType,
  choice?: ChoiceProblemInput | null,
  headerId?: string | null,
};

export enum ProblemType {
  CHOICE = "CHOICE",
}


export type ChoiceProblemInput = {
  options: Array< ChoiceProblemOptionInput >,
  multiple: boolean,
  answer: Array< number >,
};

export type ChoiceProblemOptionInput = {
  index: number,
  text: string,
};

export type ProblemHeaderInput = {
  id: string,
  body: string,
  createdAt: string,
};

export type WorkbookInput = {
  problems: Array< ProblemInput >,
  problemHeaders?: Array< ProblemHeaderInput > | null,
};

export type ModelContentConditionInput = {
  contentId?: ModelIDInput | null,
  version?: ModelIntInput | null,
  name?: ModelStringInput | null,
  requiredTime?: ModelIntInput | null,
  courseId?: ModelIDInput | null,
  courseVersion?: ModelIntInput | null,
  latest?: ModelBooleanInput | null,
  versionDescription?: ModelStringInput | null,
  and?: Array< ModelContentConditionInput | null > | null,
  or?: Array< ModelContentConditionInput | null > | null,
  not?: ModelContentConditionInput | null,
};

export type Content = {
  __typename: "Content",
  id: string,
  contentId: string,
  version: number,
  name: string,
  requiredTime: number,
  body: ContentBody,
  workbook?: Workbook | null,
  courseId: string,
  courseVersion: number,
  tenantCode: string,
  latest: boolean,
  versionDescription?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type ContentBody = {
  __typename: "ContentBody",
  type: ContentType,
  text?: TextContent | null,
  exam?: ExamContent | null,
};

export type TextContent = {
  __typename: "TextContent",
  body: string,
};

export type ExamContent = {
  __typename: "ExamContent",
  problems:  Array<Problem >,
  problemHeaders?:  Array<ProblemHeader > | null,
  passingStandard?: number | null,
};

export type Problem = {
  __typename: "Problem",
  index: number,
  body: string,
  commentary?: string | null,
  type: ProblemType,
  choice?: ChoiceProblem | null,
  headerId?: string | null,
};

export type ChoiceProblem = {
  __typename: "ChoiceProblem",
  options:  Array<ChoiceProblemOption >,
  multiple: boolean,
  answer: Array< number >,
};

export type ChoiceProblemOption = {
  __typename: "ChoiceProblemOption",
  index: number,
  text: string,
};

export type ProblemHeader = {
  __typename: "ProblemHeader",
  id: string,
  body: string,
  createdAt: string,
};

export type Workbook = {
  __typename: "Workbook",
  problems:  Array<Problem >,
  problemHeaders?:  Array<ProblemHeader > | null,
};

export type DeleteContentInput = {
  id: string,
};

export type UpdateCourseInput = {
  id: string,
  version: number,
  name?: string | null,
  contents?: Array< CourseContentInput > | null,
  description?: string | null,
  tenantCode?: string | null,
  color?: string | null,
  image?: string | null,
  fontColorOnImage?: CourseFontColorOnImage | null,
  confirmedBy?: string | null,
  createdAt?: string | null,
  versionCreatedBy?: string | null,
  versionCreatedAt?: string | null,
  contentLastUpdatedBy?: string | null,
  contentLastUpdatedAt?: string | null,
};

export type CourseContentInput = {
  id: string,
  type: ContentType,
  name: string,
  requiredTime: number,
};

export type ModelCourseConditionInput = {
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  color?: ModelStringInput | null,
  image?: ModelStringInput | null,
  fontColorOnImage?: ModelCourseFontColorOnImageInput | null,
  confirmedBy?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  versionCreatedBy?: ModelIDInput | null,
  versionCreatedAt?: ModelStringInput | null,
  contentLastUpdatedBy?: ModelIDInput | null,
  contentLastUpdatedAt?: ModelStringInput | null,
  and?: Array< ModelCourseConditionInput | null > | null,
  or?: Array< ModelCourseConditionInput | null > | null,
  not?: ModelCourseConditionInput | null,
};

export type ModelCourseFontColorOnImageInput = {
  eq?: CourseFontColorOnImage | null,
  ne?: CourseFontColorOnImage | null,
};

export type Course = {
  __typename: "Course",
  id: string,
  version: number,
  name: string,
  contents:  Array<CourseContent >,
  description?: string | null,
  tenantCode: string,
  color?: string | null,
  image?: string | null,
  fontColorOnImage?: CourseFontColorOnImage | null,
  confirmedBy?: string | null,
  createdAt?: string | null,
  versionCreatedBy?: string | null,
  versionCreatedAt?: string | null,
  contentLastUpdatedBy?: string | null,
  contentLastUpdatedAt?: string | null,
  updatedAt: string,
};

export type CourseContent = {
  __typename: "CourseContent",
  id: string,
  type: ContentType,
  name: string,
  requiredTime: number,
};

export type DeleteCourseInput = {
  id: string,
  version: number,
};

export type UpdateEditingCourseContentWorkbookInput = {
  contentId: string,
  problems?: Array< ProblemInput > | null,
  problemHeaders?: Array< ProblemHeaderInput > | null,
  dataVersion?: number | null,
  createdBy?: string | null,
  updatedBy?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  tenantCode?: string | null,
  courseId?: string | null,
  expectedDataVersion: number,
};

export type ModelEditingCourseContentWorkbookConditionInput = {
  dataVersion?: ModelIntInput | null,
  createdBy?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  courseId?: ModelIDInput | null,
  and?: Array< ModelEditingCourseContentWorkbookConditionInput | null > | null,
  or?: Array< ModelEditingCourseContentWorkbookConditionInput | null > | null,
  not?: ModelEditingCourseContentWorkbookConditionInput | null,
};

export type EditingCourseContentWorkbook = {
  __typename: "EditingCourseContentWorkbook",
  contentId: string,
  problems:  Array<Problem >,
  problemHeaders?:  Array<ProblemHeader > | null,
  dataVersion: number,
  createdBy: string,
  updatedBy: string,
  createdAt: string,
  updatedAt: string,
  tenantCode: string,
  courseId: string,
};

export type UpdateEditingCourseContentBodyInput = {
  contentId: string,
  body?: ContentBodyInput | null,
  dataVersion?: number | null,
  createdBy?: string | null,
  updatedBy?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  tenantCode?: string | null,
  courseId?: string | null,
  expectedDataVersion: number,
};

export type ModelEditingCourseContentBodyConditionInput = {
  dataVersion?: ModelIntInput | null,
  createdBy?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  courseId?: ModelIDInput | null,
  and?: Array< ModelEditingCourseContentBodyConditionInput | null > | null,
  or?: Array< ModelEditingCourseContentBodyConditionInput | null > | null,
  not?: ModelEditingCourseContentBodyConditionInput | null,
};

export type EditingCourseContentBody = {
  __typename: "EditingCourseContentBody",
  contentId: string,
  body: ContentBody,
  dataVersion: number,
  createdBy: string,
  updatedBy: string,
  createdAt: string,
  updatedAt: string,
  tenantCode: string,
  courseId: string,
};

export type CreateEditingConfirmedContentInput = {
  id?: string | null,
  version: number,
  name: string,
  requiredTime: number,
  body: ContentBodyInput,
  workbook?: WorkbookInput | null,
  courseId: string,
  courseVersion: number,
  status: EditingStatus,
  tenantCode: string,
  createdBy: string,
  updatedBy: string,
  versionDescription?: string | null,
};

export type ModelEditingConfirmedContentConditionInput = {
  version?: ModelIntInput | null,
  name?: ModelStringInput | null,
  requiredTime?: ModelIntInput | null,
  courseId?: ModelIDInput | null,
  courseVersion?: ModelIntInput | null,
  status?: ModelEditingStatusInput | null,
  createdBy?: ModelIDInput | null,
  updatedBy?: ModelIDInput | null,
  dataVersion?: ModelIntInput | null,
  versionDescription?: ModelStringInput | null,
  and?: Array< ModelEditingConfirmedContentConditionInput | null > | null,
  or?: Array< ModelEditingConfirmedContentConditionInput | null > | null,
  not?: ModelEditingConfirmedContentConditionInput | null,
};

export type ModelEditingStatusInput = {
  eq?: EditingStatus | null,
  ne?: EditingStatus | null,
};

export type EditingConfirmedContent = {
  __typename: "EditingConfirmedContent",
  id: string,
  version: number,
  name: string,
  requiredTime: number,
  body: ContentBody,
  workbook?: Workbook | null,
  courseId: string,
  courseVersion: number,
  status: EditingStatus,
  tenantCode: string,
  createdBy: string,
  updatedBy: string,
  dataVersion: number,
  versionDescription?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateEditingConfirmedContentInput = {
  id: string,
  version?: number | null,
  name?: string | null,
  requiredTime?: number | null,
  body?: ContentBodyInput | null,
  workbook?: WorkbookInput | null,
  courseId?: string | null,
  courseVersion?: number | null,
  status?: EditingStatus | null,
  tenantCode?: string | null,
  createdBy?: string | null,
  updatedBy?: string | null,
  dataVersion?: number | null,
  versionDescription?: string | null,
  expectedDataVersion: number,
};

export type UpdateGroupTrainingInput = {
  id: string,
  groupId?: string | null,
  tenantCode?: string | null,
};

export type ModelGroupTrainingConditionInput = {
  groupId?: ModelIDInput | null,
  and?: Array< ModelGroupTrainingConditionInput | null > | null,
  or?: Array< ModelGroupTrainingConditionInput | null > | null,
  not?: ModelGroupTrainingConditionInput | null,
};

export type GroupTraining = {
  __typename: "GroupTraining",
  id: string,
  groupId: string,
  tenantCode: string,
  createdAt: string,
  updatedAt: string,
};

export type DeleteGroupTrainingInput = {
  id: string,
};

export type CreateGroupTrainingCourseInput = {
  id?: string | null,
  groupTrainingId: string,
  courseId: string,
  courseVersion: number,
  courseName: string,
  contents: Array< GroupTrainingCourseContentInput >,
  groupId: string,
  tenantCode: string,
  color?: string | null,
  image?: string | null,
  fontColorOnImage?: CourseFontColorOnImage | null,
  displayName: string,
  index?: number | null,
};

export type GroupTrainingCourseContentInput = {
  id: string,
  name: string,
  requiredTime: number,
  type: ContentType,
  recommendedDateTime?: string | null,
  open: boolean,
  version: number,
};

export type ModelGroupTrainingCourseConditionInput = {
  groupTrainingId?: ModelIDInput | null,
  courseId?: ModelIDInput | null,
  courseVersion?: ModelIntInput | null,
  courseName?: ModelStringInput | null,
  groupId?: ModelIDInput | null,
  color?: ModelStringInput | null,
  image?: ModelStringInput | null,
  fontColorOnImage?: ModelCourseFontColorOnImageInput | null,
  displayName?: ModelStringInput | null,
  index?: ModelIntInput | null,
  and?: Array< ModelGroupTrainingCourseConditionInput | null > | null,
  or?: Array< ModelGroupTrainingCourseConditionInput | null > | null,
  not?: ModelGroupTrainingCourseConditionInput | null,
};

export type GroupTrainingCourse = {
  __typename: "GroupTrainingCourse",
  id: string,
  groupTrainingId: string,
  courseId: string,
  courseVersion: number,
  courseName: string,
  contents:  Array<GroupTrainingCourseContent >,
  groupId: string,
  tenantCode: string,
  color?: string | null,
  image?: string | null,
  fontColorOnImage?: CourseFontColorOnImage | null,
  displayName: string,
  index?: number | null,
  createdAt: string,
  updatedAt: string,
  groupExams?: ModelGroupExamConnection | null,
};

export type GroupTrainingCourseContent = {
  __typename: "GroupTrainingCourseContent",
  id: string,
  name: string,
  requiredTime: number,
  type: ContentType,
  recommendedDateTime?: string | null,
  open: boolean,
  version: number,
};

export type ModelGroupExamConnection = {
  __typename: "ModelGroupExamConnection",
  items:  Array<GroupExam | null >,
  nextToken?: string | null,
};

export type GroupExam = {
  __typename: "GroupExam",
  id: string,
  groupId: string,
  contentId: string,
  content: GroupExamContent,
  problemsJson: string,
  courseId: string,
  course: GroupExamCourse,
  scheduledStart: string,
  scheduledFinish?: string | null,
  createdBy?: string | null,
  finishedAt?: string | null,
  visibilityLevel?: ExamResultVisibilityLevel | null,
  tenantCode: string,
  times: number,
  groupTrainingCourseId: string,
  problemHeaders?:  Array<ProblemHeader > | null,
  timeLimit?: number | null,
  passingStandard?: number | null,
  userIdsToBeTested?: Array< string > | null,
  createdAt: string,
  updatedAt: string,
  userExams?: ModelUserExamConnection | null,
};

export type GroupExamContent = {
  __typename: "GroupExamContent",
  id: string,
  version?: number | null,
  name: string,
  timeLimit?: number | null,
  requiredTime?: number | null,
  indexInCourse?: number | null,
  passingStandard?: number | null,
};

export type GroupExamCourse = {
  __typename: "GroupExamCourse",
  id: string,
  version: number,
  name: string,
  color?: string | null,
  image?: string | null,
  fontColorOnImage?: CourseFontColorOnImage | null,
};

export enum ExamResultVisibilityLevel {
  INVISIBLE_TO_USER = "INVISIBLE_TO_USER",
  DETAILS = "DETAILS",
  SCORE = "SCORE",
}


export type ModelUserExamConnection = {
  __typename: "ModelUserExamConnection",
  items:  Array<UserExam | null >,
  nextToken?: string | null,
};

export type UserExam = {
  __typename: "UserExam",
  id: string,
  userId: string,
  status: UserExamStatus,
  tenantCode: string,
  groupExamId: string,
  groupId: string,
  startedAt?: string | null,
  finishedAt?: string | null,
  createdAt: string,
  updatedAt: string,
  groupExam?: GroupExam | null,
};

export enum UserExamStatus {
  NOT_STARTED = "NOT_STARTED",
  IN_PROGRESS = "IN_PROGRESS",
  FINISHED = "FINISHED",
  SKIPPED = "SKIPPED",
}


export type UpdateGroupTrainingCourseInput = {
  id: string,
  groupTrainingId?: string | null,
  courseId?: string | null,
  courseVersion?: number | null,
  courseName?: string | null,
  contents?: Array< GroupTrainingCourseContentInput > | null,
  groupId?: string | null,
  tenantCode?: string | null,
  color?: string | null,
  image?: string | null,
  fontColorOnImage?: CourseFontColorOnImage | null,
  displayName?: string | null,
  index?: number | null,
};

export type DeleteGroupTrainingCourseInput = {
  id: string,
};

export type CreateScheduleTagInput = {
  id?: string | null,
  color: string,
  text: string,
  groupId: string,
  tenantCode: string,
};

export type ModelScheduleTagConditionInput = {
  color?: ModelStringInput | null,
  text?: ModelStringInput | null,
  groupId?: ModelIDInput | null,
  and?: Array< ModelScheduleTagConditionInput | null > | null,
  or?: Array< ModelScheduleTagConditionInput | null > | null,
  not?: ModelScheduleTagConditionInput | null,
};

export type ScheduleTag = {
  __typename: "ScheduleTag",
  id: string,
  color: string,
  text: string,
  groupId: string,
  tenantCode: string,
  createdAt: string,
  updatedAt: string,
  schedules?: ModelScheduleScheduleTagConnection | null,
};

export type ModelScheduleScheduleTagConnection = {
  __typename: "ModelScheduleScheduleTagConnection",
  items:  Array<ScheduleScheduleTag | null >,
  nextToken?: string | null,
};

export type ScheduleScheduleTag = {
  __typename: "ScheduleScheduleTag",
  id: string,
  scheduleId: string,
  scheduleTagId: string,
  scheduleTag: ScheduleTag,
  tenantCode: string,
  createdAt: string,
  updatedAt: string,
  schedule: Schedule,
};

export type Schedule = {
  __typename: "Schedule",
  id: string,
  groupId: string,
  start: string,
  end?: string | null,
  title: string,
  body?: string | null,
  tenantCode: string,
  createdBy?: string | null,
  updatedBy?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  tags?: ModelScheduleScheduleTagConnection | null,
};

export type UpdateScheduleTagInput = {
  id: string,
  color?: string | null,
  text?: string | null,
  groupId?: string | null,
  tenantCode?: string | null,
};

export type DeleteScheduleTagInput = {
  id: string,
};

export type CreateScheduleScheduleTagInput = {
  id?: string | null,
  scheduleId: string,
  scheduleTagId: string,
  tenantCode: string,
};

export type ModelScheduleScheduleTagConditionInput = {
  scheduleId?: ModelIDInput | null,
  scheduleTagId?: ModelIDInput | null,
  and?: Array< ModelScheduleScheduleTagConditionInput | null > | null,
  or?: Array< ModelScheduleScheduleTagConditionInput | null > | null,
  not?: ModelScheduleScheduleTagConditionInput | null,
};

export type UpdateScheduleScheduleTagInput = {
  id: string,
  scheduleId?: string | null,
  scheduleTagId?: string | null,
  tenantCode?: string | null,
};

export type CreateScheduleInput = {
  id?: string | null,
  groupId: string,
  start: string,
  end?: string | null,
  title: string,
  body?: string | null,
  tenantCode: string,
  createdBy?: string | null,
  updatedBy?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelScheduleConditionInput = {
  groupId?: ModelIDInput | null,
  start?: ModelStringInput | null,
  end?: ModelStringInput | null,
  title?: ModelStringInput | null,
  body?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  updatedBy?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelScheduleConditionInput | null > | null,
  or?: Array< ModelScheduleConditionInput | null > | null,
  not?: ModelScheduleConditionInput | null,
};

export type UpdateScheduleInput = {
  id: string,
  groupId?: string | null,
  start?: string | null,
  end?: string | null,
  title?: string | null,
  body?: string | null,
  tenantCode?: string | null,
  createdBy?: string | null,
  updatedBy?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteScheduleInput = {
  id: string,
};

export type CreateCommentInput = {
  id?: string | null,
  questionId: string,
  body: string,
  createdBy: string,
  createdAt?: string | null,
  editedBy?: string | null,
  editedAt?: string | null,
  tenantCode: string,
  groupId: string,
};

export type ModelCommentConditionInput = {
  questionId?: ModelIDInput | null,
  body?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  editedBy?: ModelStringInput | null,
  editedAt?: ModelStringInput | null,
  groupId?: ModelIDInput | null,
  and?: Array< ModelCommentConditionInput | null > | null,
  or?: Array< ModelCommentConditionInput | null > | null,
  not?: ModelCommentConditionInput | null,
};

export type Comment = {
  __typename: "Comment",
  id: string,
  questionId: string,
  body: string,
  createdBy: string,
  createdAt?: string | null,
  editedBy?: string | null,
  editedAt?: string | null,
  tenantCode: string,
  groupId: string,
  updatedAt: string,
};

export type UpdateCommentInput = {
  id: string,
  questionId?: string | null,
  body?: string | null,
  createdBy?: string | null,
  createdAt?: string | null,
  editedBy?: string | null,
  editedAt?: string | null,
  tenantCode?: string | null,
  groupId?: string | null,
};

export type DeleteCommentInput = {
  id: string,
};

export type CreateQuestionInput = {
  id?: string | null,
  createdBy: string,
  resolved?: boolean | null,
  resolvedAt?: string | null,
  resolvedBy?: string | null,
  groupId: string,
  title: string,
  referTo?: ContentReferenceInput | null,
  tenantCode: string,
  createdAt?: string | null,
  assignees?: Array< string > | null,
};

export type ContentReferenceInput = {
  contentId: string,
  contentVersion?: number | null,
  options?: ContentReferenceOptionsInput | null,
};

export type ContentReferenceOptionsInput = {
  text?: TextContentReferenceOptionsInput | null,
  workbook?: WorkbookReferenceOptionsInput | null,
};

export type TextContentReferenceOptionsInput = {
  selectionJson: string,
};

export type WorkbookReferenceOptionsInput = {
  problemIndex: number,
  selectionJson?: string | null,
};

export type ModelQuestionConditionInput = {
  createdBy?: ModelStringInput | null,
  resolved?: ModelBooleanInput | null,
  resolvedAt?: ModelStringInput | null,
  resolvedBy?: ModelIDInput | null,
  groupId?: ModelStringInput | null,
  title?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  assignees?: ModelStringInput | null,
  and?: Array< ModelQuestionConditionInput | null > | null,
  or?: Array< ModelQuestionConditionInput | null > | null,
  not?: ModelQuestionConditionInput | null,
};

export type Question = {
  __typename: "Question",
  id: string,
  createdBy: string,
  resolved?: boolean | null,
  resolvedAt?: string | null,
  resolvedBy?: string | null,
  groupId: string,
  title: string,
  referTo?: ContentReference | null,
  tenantCode: string,
  createdAt?: string | null,
  assignees?: Array< string > | null,
  updatedAt: string,
  comments?: ModelCommentConnection | null,
};

export type ContentReference = {
  __typename: "ContentReference",
  contentId: string,
  contentVersion?: number | null,
  options?: ContentReferenceOptions | null,
};

export type ContentReferenceOptions = {
  __typename: "ContentReferenceOptions",
  text?: TextContentReferenceOptions | null,
  workbook?: WorkbookReferenceOptions | null,
};

export type TextContentReferenceOptions = {
  __typename: "TextContentReferenceOptions",
  selectionJson: string,
};

export type WorkbookReferenceOptions = {
  __typename: "WorkbookReferenceOptions",
  problemIndex: number,
  selectionJson?: string | null,
};

export type ModelCommentConnection = {
  __typename: "ModelCommentConnection",
  items:  Array<Comment | null >,
  nextToken?: string | null,
};

export type UpdateQuestionInput = {
  id: string,
  createdBy?: string | null,
  resolved?: boolean | null,
  resolvedAt?: string | null,
  resolvedBy?: string | null,
  groupId?: string | null,
  title?: string | null,
  referTo?: ContentReferenceInput | null,
  tenantCode?: string | null,
  createdAt?: string | null,
  assignees?: Array< string > | null,
};

export type DeleteQuestionInput = {
  id: string,
};

export type DeleteContentLearningInput = {
  groupId: string,
  contentId: string,
  userId: string,
  expectedDataVersion: number,
};

export type ModelContentLearningConditionInput = {
  courseId?: ModelIDInput | null,
  contentType?: ModelContentTypeInput | null,
  status?: ModelLearningStatusInput | null,
  usageTime?: ModelIntInput | null,
  lastStartedAt?: ModelStringInput | null,
  dataVersion?: ModelIntInput | null,
  completedAt?: ModelStringInput | null,
  completedUsageTime?: ModelIntInput | null,
  and?: Array< ModelContentLearningConditionInput | null > | null,
  or?: Array< ModelContentLearningConditionInput | null > | null,
  not?: ModelContentLearningConditionInput | null,
};

export type ModelContentTypeInput = {
  eq?: ContentType | null,
  ne?: ContentType | null,
};

export type ModelLearningStatusInput = {
  eq?: LearningStatus | null,
  ne?: LearningStatus | null,
};

export enum LearningStatus {
  NOT_BEGUN = "NOT_BEGUN",
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETED = "COMPLETED",
}


export type ContentLearning = {
  __typename: "ContentLearning",
  groupId: string,
  courseId: string,
  contentId: string,
  contentType: ContentType,
  userId: string,
  status: LearningStatus,
  usageTime: number,
  lastStartedAt?: string | null,
  dataVersion: number,
  tenantCode: string,
  completedAt?: string | null,
  completedUsageTime?: number | null,
  createdAt: string,
  updatedAt: string,
};

export type CreateGroupExamInput = {
  id?: string | null,
  groupId: string,
  contentId: string,
  content: GroupExamContentInput,
  problemsJson: string,
  courseId: string,
  course: GroupExamCourseInput,
  scheduledStart: string,
  scheduledFinish?: string | null,
  createdBy?: string | null,
  finishedAt?: string | null,
  visibilityLevel?: ExamResultVisibilityLevel | null,
  tenantCode: string,
  times: number,
  groupTrainingCourseId: string,
  problemHeaders?: Array< ProblemHeaderInput > | null,
  timeLimit?: number | null,
  passingStandard?: number | null,
  userIdsToBeTested?: Array< string > | null,
};

export type GroupExamContentInput = {
  id: string,
  version?: number | null,
  name: string,
  timeLimit?: number | null,
  requiredTime?: number | null,
  indexInCourse?: number | null,
  passingStandard?: number | null,
};

export type GroupExamCourseInput = {
  id: string,
  version: number,
  name: string,
  color?: string | null,
  image?: string | null,
  fontColorOnImage?: CourseFontColorOnImage | null,
};

export type ModelGroupExamConditionInput = {
  groupId?: ModelIDInput | null,
  contentId?: ModelIDInput | null,
  problemsJson?: ModelStringInput | null,
  courseId?: ModelIDInput | null,
  scheduledStart?: ModelStringInput | null,
  scheduledFinish?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  finishedAt?: ModelStringInput | null,
  visibilityLevel?: ModelExamResultVisibilityLevelInput | null,
  times?: ModelIntInput | null,
  groupTrainingCourseId?: ModelIDInput | null,
  timeLimit?: ModelIntInput | null,
  passingStandard?: ModelIntInput | null,
  userIdsToBeTested?: ModelStringInput | null,
  and?: Array< ModelGroupExamConditionInput | null > | null,
  or?: Array< ModelGroupExamConditionInput | null > | null,
  not?: ModelGroupExamConditionInput | null,
};

export type ModelExamResultVisibilityLevelInput = {
  eq?: ExamResultVisibilityLevel | null,
  ne?: ExamResultVisibilityLevel | null,
};

export type UpdateGroupExamInput = {
  id: string,
  groupId?: string | null,
  contentId?: string | null,
  content?: GroupExamContentInput | null,
  problemsJson?: string | null,
  courseId?: string | null,
  course?: GroupExamCourseInput | null,
  scheduledStart?: string | null,
  scheduledFinish?: string | null,
  createdBy?: string | null,
  finishedAt?: string | null,
  visibilityLevel?: ExamResultVisibilityLevel | null,
  tenantCode?: string | null,
  times?: number | null,
  groupTrainingCourseId?: string | null,
  problemHeaders?: Array< ProblemHeaderInput > | null,
  timeLimit?: number | null,
  passingStandard?: number | null,
  userIdsToBeTested?: Array< string > | null,
};

export type DeleteGroupExamInput = {
  id: string,
};

export type CreateUserExamInput = {
  id?: string | null,
  userId: string,
  status: UserExamStatus,
  tenantCode: string,
  groupExamId: string,
  groupId: string,
  startedAt?: string | null,
  finishedAt?: string | null,
};

export type ModelUserExamConditionInput = {
  userId?: ModelIDInput | null,
  status?: ModelUserExamStatusInput | null,
  groupExamId?: ModelIDInput | null,
  groupId?: ModelIDInput | null,
  startedAt?: ModelStringInput | null,
  finishedAt?: ModelStringInput | null,
  and?: Array< ModelUserExamConditionInput | null > | null,
  or?: Array< ModelUserExamConditionInput | null > | null,
  not?: ModelUserExamConditionInput | null,
};

export type ModelUserExamStatusInput = {
  eq?: UserExamStatus | null,
  ne?: UserExamStatus | null,
};

export type DeleteUserExamInput = {
  id: string,
};

export type CreateMemoInput = {
  id?: string | null,
  body?: string | null,
  referTo?: ContentReferenceInput | null,
  scope?: string | null,
  groupId: string,
  createdBy: string,
  createdAt?: string | null,
  updatedBy?: string | null,
  updatedAt?: string | null,
  tenantCode: string,
};

export type ModelMemoConditionInput = {
  body?: ModelStringInput | null,
  scope?: ModelStringInput | null,
  groupId?: ModelIDInput | null,
  createdBy?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedBy?: ModelIDInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelMemoConditionInput | null > | null,
  or?: Array< ModelMemoConditionInput | null > | null,
  not?: ModelMemoConditionInput | null,
};

export type Memo = {
  __typename: "Memo",
  id: string,
  body?: string | null,
  referTo?: ContentReference | null,
  scope?: string | null,
  groupId: string,
  createdBy: string,
  createdAt?: string | null,
  updatedBy?: string | null,
  updatedAt?: string | null,
  tenantCode: string,
};

export type UpdateMemoInput = {
  id: string,
  body?: string | null,
  referTo?: ContentReferenceInput | null,
  scope?: string | null,
  groupId?: string | null,
  createdBy?: string | null,
  createdAt?: string | null,
  updatedBy?: string | null,
  updatedAt?: string | null,
  tenantCode?: string | null,
};

export type DeleteMemoInput = {
  id: string,
};

export type CreateReviewProblemInput = {
  id?: string | null,
  userId: string,
  groupId: string,
  courseId: string,
  problems?: string | null,
  tenantCode: string,
};

export type ModelReviewProblemConditionInput = {
  userId?: ModelIDInput | null,
  groupId?: ModelIDInput | null,
  courseId?: ModelIDInput | null,
  problems?: ModelStringInput | null,
  and?: Array< ModelReviewProblemConditionInput | null > | null,
  or?: Array< ModelReviewProblemConditionInput | null > | null,
  not?: ModelReviewProblemConditionInput | null,
};

export type ReviewProblem = {
  __typename: "ReviewProblem",
  id: string,
  userId: string,
  groupId: string,
  courseId: string,
  problems?: string | null,
  tenantCode: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateReviewProblemInput = {
  id: string,
  userId?: string | null,
  groupId?: string | null,
  courseId?: string | null,
  problems?: string | null,
  tenantCode?: string | null,
};

export type DeleteReviewProblemInput = {
  id: string,
};

export type CreateNotificationInput = {
  id?: string | null,
  type: string,
  payloadJson: string,
  userId: string,
  read: boolean,
  createdAt?: string | null,
  tenantCode: string,
};

export type CreateUserInput = {
  id?: string | null,
  name: string,
  role: Role,
  extensionConfigJsonList: Array< string >,
  email?: string | null,
  code?: string | null,
  tenantCode: string,
  displaySettings?: DisplaySettingsInput | null,
  locale?: string | null,
  avatar?: string | null,
  enabled: boolean,
  signedInAtLeastOnce: boolean,
  emailConfirmed?: boolean | null,
  confirmedTermsOfServiceVersions?: ConfirmedTermsOfServiceVersionsInput | null,
};

export type CreateTenantInput = {
  id?: string | null,
  code: string,
  name: string,
  signUpType: SignUpType,
  playground: boolean,
  userLimit?: number | null,
  limitations?: string | null,
};

export type CreateTenantOwnTermsOfServiceInput = {
  id?: string | null,
  tenantCode: string,
  type: TenantOwnTermsOfServiceType,
  body: string,
  createdBy: string,
  createdAt?: string | null,
  updatedBy?: string | null,
  updatedAt?: string | null,
  version?: number | null,
};

export type CreateUserTagInput = {
  id?: string | null,
  color: string,
  text: string,
  tenantCode: string,
};

export type ModelUserTagConditionInput = {
  color?: ModelStringInput | null,
  text?: ModelStringInput | null,
  and?: Array< ModelUserTagConditionInput | null > | null,
  or?: Array< ModelUserTagConditionInput | null > | null,
  not?: ModelUserTagConditionInput | null,
};

export type UpdateUserTagInput = {
  id: string,
  color?: string | null,
  text?: string | null,
  tenantCode?: string | null,
};

export type DeleteUserTagInput = {
  id: string,
};

export type CreateUserUserTagInput = {
  id?: string | null,
  userId: string,
  userTagId: string,
  tenantCode: string,
};

export type ModelUserUserTagConditionInput = {
  userId?: ModelIDInput | null,
  userTagId?: ModelIDInput | null,
  and?: Array< ModelUserUserTagConditionInput | null > | null,
  or?: Array< ModelUserUserTagConditionInput | null > | null,
  not?: ModelUserUserTagConditionInput | null,
};

export type UpdateUserUserTagInput = {
  id: string,
  userId?: string | null,
  userTagId?: string | null,
  tenantCode?: string | null,
};

export type DeleteUserUserTagInput = {
  id: string,
};

export type CreateUserStatusTableInput = {
  id?: string | null,
  tenantCode: string,
  status: UserStatus,
  userId: string,
};

export type ModelUserStatusTableConditionInput = {
  status?: ModelUserStatusInput | null,
  userId?: ModelIDInput | null,
  and?: Array< ModelUserStatusTableConditionInput | null > | null,
  or?: Array< ModelUserStatusTableConditionInput | null > | null,
  not?: ModelUserStatusTableConditionInput | null,
};

export type ModelUserStatusInput = {
  eq?: UserStatus | null,
  ne?: UserStatus | null,
};

export type UpdateUserStatusTableInput = {
  id: string,
  tenantCode?: string | null,
  status?: UserStatus | null,
  userId?: string | null,
};

export type DeleteUserStatusTableInput = {
  id: string,
};

export type UpdateGroupStatusUserInput = {
  id: string,
  userId?: string | null,
  groupId?: string | null,
  handStatus?: HandStatus | null,
  tenantCode?: string | null,
};

export type DeleteGroupStatusUserInput = {
  id: string,
};

export type CreateInternalTaskInput = {
  id?: string | null,
  type: string,
  createdAt?: string | null,
  createdBy: string,
  finishedAt?: string | null,
  status: InternalTaskStatus,
  taskVersion: number,
  payload: string,
  tenantCode: string,
};

export enum InternalTaskStatus {
  CREATED = "CREATED",
  IN_PROGRESS = "IN_PROGRESS",
  FAILED = "FAILED",
  SUCCEEDED = "SUCCEEDED",
}


export type ModelInternalTaskConditionInput = {
  type?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  finishedAt?: ModelStringInput | null,
  status?: ModelInternalTaskStatusInput | null,
  taskVersion?: ModelIntInput | null,
  payload?: ModelStringInput | null,
  and?: Array< ModelInternalTaskConditionInput | null > | null,
  or?: Array< ModelInternalTaskConditionInput | null > | null,
  not?: ModelInternalTaskConditionInput | null,
};

export type ModelInternalTaskStatusInput = {
  eq?: InternalTaskStatus | null,
  ne?: InternalTaskStatus | null,
};

export type InternalTask = {
  __typename: "InternalTask",
  id: string,
  type: string,
  createdAt?: string | null,
  createdBy: string,
  finishedAt?: string | null,
  status: InternalTaskStatus,
  taskVersion: number,
  payload: string,
  tenantCode: string,
  updatedAt: string,
};

export type UpdateInternalTaskInput = {
  id: string,
  type?: string | null,
  createdAt?: string | null,
  createdBy?: string | null,
  finishedAt?: string | null,
  status?: InternalTaskStatus | null,
  taskVersion?: number | null,
  payload?: string | null,
  tenantCode?: string | null,
};

export type DeleteInternalTaskInput = {
  id: string,
};

export type CreatePlaygroundInput = {
  id?: string | null,
  status: PlaygroundStatus,
  email: string,
  tenantCode: string,
  tenantName: string,
  userCode?: string | null,
  temporaryPassword?: string | null,
};

export enum PlaygroundStatus {
  RESERVED = "RESERVED",
  CREATING = "CREATING",
  CREATED = "CREATED",
  USED = "USED",
  REMOVED = "REMOVED",
}


export type ModelPlaygroundConditionInput = {
  status?: ModelPlaygroundStatusInput | null,
  email?: ModelStringInput | null,
  tenantCode?: ModelStringInput | null,
  tenantName?: ModelStringInput | null,
  userCode?: ModelStringInput | null,
  temporaryPassword?: ModelStringInput | null,
  and?: Array< ModelPlaygroundConditionInput | null > | null,
  or?: Array< ModelPlaygroundConditionInput | null > | null,
  not?: ModelPlaygroundConditionInput | null,
};

export type ModelPlaygroundStatusInput = {
  eq?: PlaygroundStatus | null,
  ne?: PlaygroundStatus | null,
};

export type Playground = {
  __typename: "Playground",
  id: string,
  status: PlaygroundStatus,
  email: string,
  tenantCode: string,
  tenantName: string,
  userCode?: string | null,
  temporaryPassword?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdatePlaygroundInput = {
  id: string,
  status?: PlaygroundStatus | null,
  email?: string | null,
  tenantCode?: string | null,
  tenantName?: string | null,
  userCode?: string | null,
  temporaryPassword?: string | null,
};

export type DeletePlaygroundInput = {
  id: string,
};

export type CreateContentInput = {
  id?: string | null,
  contentId: string,
  version: number,
  name: string,
  requiredTime: number,
  body: ContentBodyInput,
  workbook?: WorkbookInput | null,
  courseId: string,
  courseVersion: number,
  tenantCode: string,
  latest: boolean,
  versionDescription?: string | null,
};

export type CreateCourseInput = {
  id?: string | null,
  version: number,
  name: string,
  contents: Array< CourseContentInput >,
  description?: string | null,
  tenantCode: string,
  color?: string | null,
  image?: string | null,
  fontColorOnImage?: CourseFontColorOnImage | null,
  confirmedBy?: string | null,
  createdAt?: string | null,
  versionCreatedBy?: string | null,
  versionCreatedAt?: string | null,
  contentLastUpdatedBy?: string | null,
  contentLastUpdatedAt?: string | null,
};

export type DeleteEditingCourseInput = {
  id: string,
};

export type ModelEditingCourseConditionInput = {
  name?: ModelStringInput | null,
  version?: ModelIntInput | null,
  status?: ModelEditingStatusInput | null,
  description?: ModelStringInput | null,
  color?: ModelStringInput | null,
  image?: ModelStringInput | null,
  fontColorOnImage?: ModelCourseFontColorOnImageInput | null,
  createdBy?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelEditingCourseConditionInput | null > | null,
  or?: Array< ModelEditingCourseConditionInput | null > | null,
  not?: ModelEditingCourseConditionInput | null,
};

export type CreateEditingCourseContentWorkbookInput = {
  contentId: string,
  problems: Array< ProblemInput >,
  problemHeaders?: Array< ProblemHeaderInput > | null,
  createdBy: string,
  updatedBy: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  tenantCode: string,
  courseId: string,
};

export type DeleteEditingCourseContentWorkbookInput = {
  contentId: string,
  expectedDataVersion: number,
};

export type CreateEditingCourseContentBodyInput = {
  contentId: string,
  body: ContentBodyInput,
  createdBy: string,
  updatedBy: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  tenantCode: string,
  courseId: string,
};

export type DeleteEditingCourseContentBodyInput = {
  contentId: string,
  expectedDataVersion: number,
};

export type UpdateCourseHeaderInput = {
  id: string,
  name?: string | null,
  activeVersion?: number | null,
  status?: CourseStatus | null,
  originalCourseId?: string | null,
  originalCourseVersion?: number | null,
  tenantCode?: string | null,
  createdBy?: string | null,
};

export type ModelCourseHeaderConditionInput = {
  name?: ModelStringInput | null,
  activeVersion?: ModelIntInput | null,
  status?: ModelCourseStatusInput | null,
  originalCourseId?: ModelIDInput | null,
  originalCourseVersion?: ModelIntInput | null,
  createdBy?: ModelIDInput | null,
  and?: Array< ModelCourseHeaderConditionInput | null > | null,
  or?: Array< ModelCourseHeaderConditionInput | null > | null,
  not?: ModelCourseHeaderConditionInput | null,
};

export type ModelCourseStatusInput = {
  eq?: CourseStatus | null,
  ne?: CourseStatus | null,
};

export type DeleteCourseHeaderInput = {
  id: string,
};

export type DeleteEditingConfirmedContentInput = {
  id: string,
  expectedDataVersion: number,
};

export type CreateEditingCourseInput = {
  id?: string | null,
  tenantCode: string,
  name: string,
  version: number,
  status: EditingStatus,
  description?: string | null,
  contents: Array< EditingCourseContentHeaderInput >,
  createdBy: string,
  updatedBy: string,
  color?: string | null,
  image?: string | null,
  fontColorOnImage?: CourseFontColorOnImage | null,
};

export type CreateExamResultInput = {
  id?: string | null,
  groupId: string,
  courseId: string,
  contentId: string,
  contentVersion?: number | null,
  userId: string,
  start: string,
  end?: string | null,
  score?: number | null,
  problemCount: number,
  tenantCode: string,
  answers?: string | null,
  visibilityLevel?: ExamResultVisibilityLevel | null,
  groupExamId?: string | null,
  times?: number | null,
  passingStandard?: number | null,
  version?: number | null,
};

export type ModelExamResultConditionInput = {
  groupId?: ModelIDInput | null,
  courseId?: ModelIDInput | null,
  contentId?: ModelIDInput | null,
  contentVersion?: ModelIntInput | null,
  userId?: ModelIDInput | null,
  start?: ModelStringInput | null,
  end?: ModelStringInput | null,
  score?: ModelIntInput | null,
  problemCount?: ModelIntInput | null,
  answers?: ModelStringInput | null,
  visibilityLevel?: ModelExamResultVisibilityLevelInput | null,
  groupExamId?: ModelIDInput | null,
  times?: ModelIntInput | null,
  passingStandard?: ModelIntInput | null,
  version?: ModelIntInput | null,
  and?: Array< ModelExamResultConditionInput | null > | null,
  or?: Array< ModelExamResultConditionInput | null > | null,
  not?: ModelExamResultConditionInput | null,
};

export type ExamResult = {
  __typename: "ExamResult",
  id: string,
  groupId: string,
  courseId: string,
  contentId: string,
  contentVersion?: number | null,
  userId: string,
  start: string,
  end?: string | null,
  score?: number | null,
  problemCount: number,
  tenantCode: string,
  answers?: string | null,
  visibilityLevel?: ExamResultVisibilityLevel | null,
  groupExamId?: string | null,
  times?: number | null,
  passingStandard?: number | null,
  version?: number | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateExamResultInput = {
  id: string,
  groupId?: string | null,
  courseId?: string | null,
  contentId?: string | null,
  contentVersion?: number | null,
  userId?: string | null,
  start?: string | null,
  end?: string | null,
  score?: number | null,
  problemCount?: number | null,
  tenantCode?: string | null,
  answers?: string | null,
  visibilityLevel?: ExamResultVisibilityLevel | null,
  groupExamId?: string | null,
  times?: number | null,
  passingStandard?: number | null,
  version?: number | null,
};

export type DeleteExamResultInput = {
  id: string,
};

export type CreateGroupTrainingInput = {
  id?: string | null,
  groupId: string,
  tenantCode: string,
};

export type DeleteScheduleScheduleTagInput = {
  id: string,
};

export type CreateContentLearningInput = {
  groupId: string,
  courseId: string,
  contentId: string,
  contentType: ContentType,
  userId: string,
  status: LearningStatus,
  usageTime: number,
  lastStartedAt?: string | null,
  tenantCode: string,
  completedAt?: string | null,
  completedUsageTime?: number | null,
};

export type UpdateContentLearningInput = {
  groupId: string,
  courseId?: string | null,
  contentId: string,
  contentType?: ContentType | null,
  userId: string,
  status?: LearningStatus | null,
  usageTime?: number | null,
  lastStartedAt?: string | null,
  dataVersion?: number | null,
  tenantCode?: string | null,
  completedAt?: string | null,
  completedUsageTime?: number | null,
  expectedDataVersion: number,
};

export type UpdateUserExamInput = {
  id: string,
  userId?: string | null,
  status?: UserExamStatus | null,
  tenantCode?: string | null,
  groupExamId?: string | null,
  groupId?: string | null,
  startedAt?: string | null,
  finishedAt?: string | null,
};

export type Policy = {
  __typename: "Policy",
  policy: string,
  keyPairId: string,
  signature: string,
  domain: string,
  expire: string,
};

export type SignedUrl = {
  __typename: "SignedUrl",
  signedUrl: string,
  path: string,
  key: string,
};

export type PrivacyPolicyVersion = {
  __typename: "PrivacyPolicyVersion",
  version: string,
};

export type ModelNotificationFilterInput = {
  id?: ModelIDInput | null,
  type?: ModelStringInput | null,
  payloadJson?: ModelStringInput | null,
  userId?: ModelStringInput | null,
  read?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  tenantCode?: ModelStringInput | null,
  and?: Array< ModelNotificationFilterInput | null > | null,
  or?: Array< ModelNotificationFilterInput | null > | null,
  not?: ModelNotificationFilterInput | null,
};

export type ModelNotificationConnection = {
  __typename: "ModelNotificationConnection",
  items:  Array<Notification | null >,
  nextToken?: string | null,
};

export type ModelLiveFilterInput = {
  id?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  url?: ModelStringInput | null,
  applicationSessionId?: ModelStringInput | null,
  tenantCode?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelLiveFilterInput | null > | null,
  or?: Array< ModelLiveFilterInput | null > | null,
  not?: ModelLiveFilterInput | null,
};

export type ModelLiveConnection = {
  __typename: "ModelLiveConnection",
  items:  Array<Live | null >,
  nextToken?: string | null,
};

export type ModelQuestionnaireFilterInput = {
  id?: ModelIDInput | null,
  groupId?: ModelIDInput | null,
  tenantCode?: ModelStringInput | null,
  title?: ModelStringInput | null,
  userIds?: ModelIDInput | null,
  text?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  options?: ModelStringInput | null,
  status?: ModelQuestionnaireStatusInput | null,
  finishedAt?: ModelStringInput | null,
  and?: Array< ModelQuestionnaireFilterInput | null > | null,
  or?: Array< ModelQuestionnaireFilterInput | null > | null,
  not?: ModelQuestionnaireFilterInput | null,
};

export type ModelQuestionnaireConnection = {
  __typename: "ModelQuestionnaireConnection",
  items:  Array<Questionnaire | null >,
  nextToken?: string | null,
};

export type ModelQuestionnaireRespondentFilterInput = {
  id?: ModelIDInput | null,
  questionnaireId?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  selectedIndex?: ModelIntInput | null,
  tenantCode?: ModelStringInput | null,
  and?: Array< ModelQuestionnaireRespondentFilterInput | null > | null,
  or?: Array< ModelQuestionnaireRespondentFilterInput | null > | null,
  not?: ModelQuestionnaireRespondentFilterInput | null,
};

export type ModelScheduleTagFilterInput = {
  id?: ModelIDInput | null,
  color?: ModelStringInput | null,
  text?: ModelStringInput | null,
  groupId?: ModelIDInput | null,
  tenantCode?: ModelStringInput | null,
  and?: Array< ModelScheduleTagFilterInput | null > | null,
  or?: Array< ModelScheduleTagFilterInput | null > | null,
  not?: ModelScheduleTagFilterInput | null,
};

export type ModelScheduleTagConnection = {
  __typename: "ModelScheduleTagConnection",
  items:  Array<ScheduleTag | null >,
  nextToken?: string | null,
};

export type ModelMemoFilterInput = {
  id?: ModelIDInput | null,
  body?: ModelStringInput | null,
  scope?: ModelStringInput | null,
  groupId?: ModelIDInput | null,
  createdBy?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedBy?: ModelIDInput | null,
  updatedAt?: ModelStringInput | null,
  tenantCode?: ModelStringInput | null,
  and?: Array< ModelMemoFilterInput | null > | null,
  or?: Array< ModelMemoFilterInput | null > | null,
  not?: ModelMemoFilterInput | null,
};

export type ModelMemoConnection = {
  __typename: "ModelMemoConnection",
  items:  Array<Memo | null >,
  nextToken?: string | null,
};

export type ModelReviewProblemFilterInput = {
  id?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  groupId?: ModelIDInput | null,
  courseId?: ModelIDInput | null,
  problems?: ModelStringInput | null,
  tenantCode?: ModelStringInput | null,
  and?: Array< ModelReviewProblemFilterInput | null > | null,
  or?: Array< ModelReviewProblemFilterInput | null > | null,
  not?: ModelReviewProblemFilterInput | null,
};

export type ModelReviewProblemConnection = {
  __typename: "ModelReviewProblemConnection",
  items:  Array<ReviewProblem | null >,
  nextToken?: string | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelIDKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelGroupFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  extensionConfigJsonList?: ModelStringInput | null,
  tenantCode?: ModelStringInput | null,
  limitations?: ModelStringInput | null,
  enabled?: ModelBooleanInput | null,
  description?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  updatedBy?: ModelIDInput | null,
  and?: Array< ModelGroupFilterInput | null > | null,
  or?: Array< ModelGroupFilterInput | null > | null,
  not?: ModelGroupFilterInput | null,
};

export type ModelGroupConnection = {
  __typename: "ModelGroupConnection",
  items:  Array<Group | null >,
  nextToken?: string | null,
};

export type ModelUserFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  role?: ModelRoleInput | null,
  extensionConfigJsonList?: ModelStringInput | null,
  email?: ModelStringInput | null,
  code?: ModelStringInput | null,
  tenantCode?: ModelStringInput | null,
  locale?: ModelStringInput | null,
  avatar?: ModelStringInput | null,
  enabled?: ModelBooleanInput | null,
  signedInAtLeastOnce?: ModelBooleanInput | null,
  emailConfirmed?: ModelBooleanInput | null,
  and?: Array< ModelUserFilterInput | null > | null,
  or?: Array< ModelUserFilterInput | null > | null,
  not?: ModelUserFilterInput | null,
};

export type ModelUserConnection = {
  __typename: "ModelUserConnection",
  items:  Array<User | null >,
  nextToken?: string | null,
};

export type ModelUserDeviceFilterInput = {
  userId?: ModelIDInput | null,
  devices?: ModelStringInput | null,
  dataVersion?: ModelIntInput | null,
  tenantCode?: ModelStringInput | null,
  and?: Array< ModelUserDeviceFilterInput | null > | null,
  or?: Array< ModelUserDeviceFilterInput | null > | null,
  not?: ModelUserDeviceFilterInput | null,
};

export type ModelUserDeviceConnection = {
  __typename: "ModelUserDeviceConnection",
  items:  Array<UserDevice | null >,
  nextToken?: string | null,
};

export type ModelSignUpReservationFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  key?: ModelStringInput | null,
  userCode?: ModelStringInput | null,
  email?: ModelStringInput | null,
  tenantCode?: ModelStringInput | null,
  type?: ModelSignUpTypeInput | null,
  role?: ModelRoleInput | null,
  userId?: ModelStringInput | null,
  status?: ModelSignUpReservationStatusInput | null,
  createdBy?: ModelIDInput | null,
  and?: Array< ModelSignUpReservationFilterInput | null > | null,
  or?: Array< ModelSignUpReservationFilterInput | null > | null,
  not?: ModelSignUpReservationFilterInput | null,
};

export type ModelSignUpReservationConnection = {
  __typename: "ModelSignUpReservationConnection",
  items:  Array<SignUpReservation | null >,
  nextToken?: string | null,
};

export type ModelTenantFilterInput = {
  id?: ModelIDInput | null,
  code?: ModelStringInput | null,
  name?: ModelStringInput | null,
  signUpType?: ModelSignUpTypeInput | null,
  playground?: ModelBooleanInput | null,
  userLimit?: ModelIntInput | null,
  limitations?: ModelStringInput | null,
  and?: Array< ModelTenantFilterInput | null > | null,
  or?: Array< ModelTenantFilterInput | null > | null,
  not?: ModelTenantFilterInput | null,
};

export type ModelTenantConnection = {
  __typename: "ModelTenantConnection",
  items:  Array<Tenant | null >,
  nextToken?: string | null,
};

export type ModelTenantOwnTermsOfServiceFilterInput = {
  id?: ModelIDInput | null,
  tenantCode?: ModelStringInput | null,
  type?: ModelTenantOwnTermsOfServiceTypeInput | null,
  body?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedBy?: ModelIDInput | null,
  updatedAt?: ModelStringInput | null,
  version?: ModelIntInput | null,
  and?: Array< ModelTenantOwnTermsOfServiceFilterInput | null > | null,
  or?: Array< ModelTenantOwnTermsOfServiceFilterInput | null > | null,
  not?: ModelTenantOwnTermsOfServiceFilterInput | null,
};

export type ModelTenantOwnTermsOfServiceConnection = {
  __typename: "ModelTenantOwnTermsOfServiceConnection",
  items:  Array<TenantOwnTermsOfService | null >,
  nextToken?: string | null,
};

export type ModelEventFilterInput = {
  id?: ModelIDInput | null,
  type?: ModelStringInput | null,
  event?: ModelStringInput | null,
  version?: ModelIntInput | null,
  tenantCode?: ModelStringInput | null,
  occurredBy?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelEventFilterInput | null > | null,
  or?: Array< ModelEventFilterInput | null > | null,
  not?: ModelEventFilterInput | null,
};

export type ModelEventConnection = {
  __typename: "ModelEventConnection",
  items:  Array<Event | null >,
  nextToken?: string | null,
};

export type ModelTermsOfServiceFilterInput = {
  id?: ModelIDInput | null,
  body?: ModelStringInput | null,
  and?: Array< ModelTermsOfServiceFilterInput | null > | null,
  or?: Array< ModelTermsOfServiceFilterInput | null > | null,
  not?: ModelTermsOfServiceFilterInput | null,
};

export type ModelTermsOfServiceConnection = {
  __typename: "ModelTermsOfServiceConnection",
  items:  Array<TermsOfService | null >,
  nextToken?: string | null,
};

export type ModelUserTagFilterInput = {
  id?: ModelIDInput | null,
  color?: ModelStringInput | null,
  text?: ModelStringInput | null,
  tenantCode?: ModelStringInput | null,
  and?: Array< ModelUserTagFilterInput | null > | null,
  or?: Array< ModelUserTagFilterInput | null > | null,
  not?: ModelUserTagFilterInput | null,
};

export type ModelUserTagConnection = {
  __typename: "ModelUserTagConnection",
  items:  Array<UserTag | null >,
  nextToken?: string | null,
};

export type ModelUserStatusTableFilterInput = {
  id?: ModelIDInput | null,
  tenantCode?: ModelStringInput | null,
  status?: ModelUserStatusInput | null,
  userId?: ModelIDInput | null,
  and?: Array< ModelUserStatusTableFilterInput | null > | null,
  or?: Array< ModelUserStatusTableFilterInput | null > | null,
  not?: ModelUserStatusTableFilterInput | null,
};

export type ModelGroupStatusUserFilterInput = {
  id?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  groupId?: ModelIDInput | null,
  handStatus?: ModelHandStatusInput | null,
  tenantCode?: ModelStringInput | null,
  and?: Array< ModelGroupStatusUserFilterInput | null > | null,
  or?: Array< ModelGroupStatusUserFilterInput | null > | null,
  not?: ModelGroupStatusUserFilterInput | null,
};

export type ModelGroupStatusFilterInput = {
  groupId?: ModelIDInput | null,
  tenantCode?: ModelStringInput | null,
  and?: Array< ModelGroupStatusFilterInput | null > | null,
  or?: Array< ModelGroupStatusFilterInput | null > | null,
  not?: ModelGroupStatusFilterInput | null,
};

export type ModelGroupStatusConnection = {
  __typename: "ModelGroupStatusConnection",
  items:  Array<GroupStatus | null >,
  nextToken?: string | null,
};

export type ModelInternalTaskFilterInput = {
  id?: ModelIDInput | null,
  type?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  finishedAt?: ModelStringInput | null,
  status?: ModelInternalTaskStatusInput | null,
  taskVersion?: ModelIntInput | null,
  payload?: ModelStringInput | null,
  tenantCode?: ModelStringInput | null,
  and?: Array< ModelInternalTaskFilterInput | null > | null,
  or?: Array< ModelInternalTaskFilterInput | null > | null,
  not?: ModelInternalTaskFilterInput | null,
};

export type ModelInternalTaskConnection = {
  __typename: "ModelInternalTaskConnection",
  items:  Array<InternalTask | null >,
  nextToken?: string | null,
};

export type ModelPlaygroundFilterInput = {
  id?: ModelIDInput | null,
  status?: ModelPlaygroundStatusInput | null,
  email?: ModelStringInput | null,
  tenantCode?: ModelStringInput | null,
  tenantName?: ModelStringInput | null,
  userCode?: ModelStringInput | null,
  temporaryPassword?: ModelStringInput | null,
  and?: Array< ModelPlaygroundFilterInput | null > | null,
  or?: Array< ModelPlaygroundFilterInput | null > | null,
  not?: ModelPlaygroundFilterInput | null,
};

export type ModelPlaygroundConnection = {
  __typename: "ModelPlaygroundConnection",
  items:  Array<Playground | null >,
  nextToken?: string | null,
};

export type ModelContentFilterInput = {
  id?: ModelIDInput | null,
  contentId?: ModelIDInput | null,
  version?: ModelIntInput | null,
  name?: ModelStringInput | null,
  requiredTime?: ModelIntInput | null,
  courseId?: ModelIDInput | null,
  courseVersion?: ModelIntInput | null,
  tenantCode?: ModelStringInput | null,
  latest?: ModelBooleanInput | null,
  versionDescription?: ModelStringInput | null,
  and?: Array< ModelContentFilterInput | null > | null,
  or?: Array< ModelContentFilterInput | null > | null,
  not?: ModelContentFilterInput | null,
};

export type ModelContentConnection = {
  __typename: "ModelContentConnection",
  items:  Array<Content | null >,
  nextToken?: string | null,
};

export type ModelIntKeyConditionInput = {
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelCourseFilterInput = {
  id?: ModelIDInput | null,
  version?: ModelIntInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  tenantCode?: ModelStringInput | null,
  color?: ModelStringInput | null,
  image?: ModelStringInput | null,
  fontColorOnImage?: ModelCourseFontColorOnImageInput | null,
  confirmedBy?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  versionCreatedBy?: ModelIDInput | null,
  versionCreatedAt?: ModelStringInput | null,
  contentLastUpdatedBy?: ModelIDInput | null,
  contentLastUpdatedAt?: ModelStringInput | null,
  and?: Array< ModelCourseFilterInput | null > | null,
  or?: Array< ModelCourseFilterInput | null > | null,
  not?: ModelCourseFilterInput | null,
};

export type ModelCourseConnection = {
  __typename: "ModelCourseConnection",
  items:  Array<Course | null >,
  nextToken?: string | null,
};

export type ModelEditingCourseFilterInput = {
  id?: ModelIDInput | null,
  tenantCode?: ModelStringInput | null,
  name?: ModelStringInput | null,
  version?: ModelIntInput | null,
  status?: ModelEditingStatusInput | null,
  description?: ModelStringInput | null,
  color?: ModelStringInput | null,
  image?: ModelStringInput | null,
  fontColorOnImage?: ModelCourseFontColorOnImageInput | null,
  createdBy?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelEditingCourseFilterInput | null > | null,
  or?: Array< ModelEditingCourseFilterInput | null > | null,
  not?: ModelEditingCourseFilterInput | null,
};

export type ModelEditingCourseConnection = {
  __typename: "ModelEditingCourseConnection",
  items:  Array<EditingCourse | null >,
  nextToken?: string | null,
};

export type ModelEditingCourseContentWorkbookFilterInput = {
  contentId?: ModelIDInput | null,
  dataVersion?: ModelIntInput | null,
  createdBy?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  tenantCode?: ModelStringInput | null,
  courseId?: ModelIDInput | null,
  and?: Array< ModelEditingCourseContentWorkbookFilterInput | null > | null,
  or?: Array< ModelEditingCourseContentWorkbookFilterInput | null > | null,
  not?: ModelEditingCourseContentWorkbookFilterInput | null,
};

export type ModelEditingCourseContentWorkbookConnection = {
  __typename: "ModelEditingCourseContentWorkbookConnection",
  items:  Array<EditingCourseContentWorkbook | null >,
  nextToken?: string | null,
};

export type ModelEditingCourseContentBodyFilterInput = {
  contentId?: ModelIDInput | null,
  dataVersion?: ModelIntInput | null,
  createdBy?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  tenantCode?: ModelStringInput | null,
  courseId?: ModelIDInput | null,
  and?: Array< ModelEditingCourseContentBodyFilterInput | null > | null,
  or?: Array< ModelEditingCourseContentBodyFilterInput | null > | null,
  not?: ModelEditingCourseContentBodyFilterInput | null,
};

export type ModelEditingCourseContentBodyConnection = {
  __typename: "ModelEditingCourseContentBodyConnection",
  items:  Array<EditingCourseContentBody | null >,
  nextToken?: string | null,
};

export type ModelCourseHeaderFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  activeVersion?: ModelIntInput | null,
  status?: ModelCourseStatusInput | null,
  originalCourseId?: ModelIDInput | null,
  originalCourseVersion?: ModelIntInput | null,
  tenantCode?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  and?: Array< ModelCourseHeaderFilterInput | null > | null,
  or?: Array< ModelCourseHeaderFilterInput | null > | null,
  not?: ModelCourseHeaderFilterInput | null,
};

export type ModelCourseHeaderConnection = {
  __typename: "ModelCourseHeaderConnection",
  items:  Array<CourseHeader | null >,
  nextToken?: string | null,
};

export type ModelEditingConfirmedContentFilterInput = {
  id?: ModelIDInput | null,
  version?: ModelIntInput | null,
  name?: ModelStringInput | null,
  requiredTime?: ModelIntInput | null,
  courseId?: ModelIDInput | null,
  courseVersion?: ModelIntInput | null,
  status?: ModelEditingStatusInput | null,
  tenantCode?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  updatedBy?: ModelIDInput | null,
  dataVersion?: ModelIntInput | null,
  versionDescription?: ModelStringInput | null,
  and?: Array< ModelEditingConfirmedContentFilterInput | null > | null,
  or?: Array< ModelEditingConfirmedContentFilterInput | null > | null,
  not?: ModelEditingConfirmedContentFilterInput | null,
};

export type ModelEditingConfirmedContentConnection = {
  __typename: "ModelEditingConfirmedContentConnection",
  items:  Array<EditingConfirmedContent | null >,
  nextToken?: string | null,
};

export type ModelExamResultFilterInput = {
  id?: ModelIDInput | null,
  groupId?: ModelIDInput | null,
  courseId?: ModelIDInput | null,
  contentId?: ModelIDInput | null,
  contentVersion?: ModelIntInput | null,
  userId?: ModelIDInput | null,
  start?: ModelStringInput | null,
  end?: ModelStringInput | null,
  score?: ModelIntInput | null,
  problemCount?: ModelIntInput | null,
  tenantCode?: ModelStringInput | null,
  answers?: ModelStringInput | null,
  visibilityLevel?: ModelExamResultVisibilityLevelInput | null,
  groupExamId?: ModelIDInput | null,
  times?: ModelIntInput | null,
  passingStandard?: ModelIntInput | null,
  version?: ModelIntInput | null,
  and?: Array< ModelExamResultFilterInput | null > | null,
  or?: Array< ModelExamResultFilterInput | null > | null,
  not?: ModelExamResultFilterInput | null,
};

export type ModelExamResultConnection = {
  __typename: "ModelExamResultConnection",
  items:  Array<ExamResult | null >,
  nextToken?: string | null,
};

export type ModelGroupTrainingFilterInput = {
  id?: ModelIDInput | null,
  groupId?: ModelIDInput | null,
  tenantCode?: ModelStringInput | null,
  and?: Array< ModelGroupTrainingFilterInput | null > | null,
  or?: Array< ModelGroupTrainingFilterInput | null > | null,
  not?: ModelGroupTrainingFilterInput | null,
};

export type ModelGroupTrainingConnection = {
  __typename: "ModelGroupTrainingConnection",
  items:  Array<GroupTraining | null >,
  nextToken?: string | null,
};

export type ModelGroupTrainingCourseFilterInput = {
  id?: ModelIDInput | null,
  groupTrainingId?: ModelIDInput | null,
  courseId?: ModelIDInput | null,
  courseVersion?: ModelIntInput | null,
  courseName?: ModelStringInput | null,
  groupId?: ModelIDInput | null,
  tenantCode?: ModelStringInput | null,
  color?: ModelStringInput | null,
  image?: ModelStringInput | null,
  fontColorOnImage?: ModelCourseFontColorOnImageInput | null,
  displayName?: ModelStringInput | null,
  index?: ModelIntInput | null,
  and?: Array< ModelGroupTrainingCourseFilterInput | null > | null,
  or?: Array< ModelGroupTrainingCourseFilterInput | null > | null,
  not?: ModelGroupTrainingCourseFilterInput | null,
};

export type ModelGroupTrainingCourseConnection = {
  __typename: "ModelGroupTrainingCourseConnection",
  items:  Array<GroupTrainingCourse | null >,
  nextToken?: string | null,
};

export type ModelScheduleFilterInput = {
  id?: ModelIDInput | null,
  groupId?: ModelIDInput | null,
  start?: ModelStringInput | null,
  end?: ModelStringInput | null,
  title?: ModelStringInput | null,
  body?: ModelStringInput | null,
  tenantCode?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  updatedBy?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelScheduleFilterInput | null > | null,
  or?: Array< ModelScheduleFilterInput | null > | null,
  not?: ModelScheduleFilterInput | null,
};

export type ModelScheduleConnection = {
  __typename: "ModelScheduleConnection",
  items:  Array<Schedule | null >,
  nextToken?: string | null,
};

export type ModelCommentFilterInput = {
  id?: ModelIDInput | null,
  questionId?: ModelIDInput | null,
  body?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  editedBy?: ModelStringInput | null,
  editedAt?: ModelStringInput | null,
  tenantCode?: ModelStringInput | null,
  groupId?: ModelIDInput | null,
  and?: Array< ModelCommentFilterInput | null > | null,
  or?: Array< ModelCommentFilterInput | null > | null,
  not?: ModelCommentFilterInput | null,
};

export type ModelQuestionFilterInput = {
  id?: ModelIDInput | null,
  createdBy?: ModelStringInput | null,
  resolved?: ModelBooleanInput | null,
  resolvedAt?: ModelStringInput | null,
  resolvedBy?: ModelIDInput | null,
  groupId?: ModelStringInput | null,
  title?: ModelStringInput | null,
  tenantCode?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  assignees?: ModelStringInput | null,
  and?: Array< ModelQuestionFilterInput | null > | null,
  or?: Array< ModelQuestionFilterInput | null > | null,
  not?: ModelQuestionFilterInput | null,
};

export type ModelQuestionConnection = {
  __typename: "ModelQuestionConnection",
  items:  Array<Question | null >,
  nextToken?: string | null,
};

export type ModelContentLearningPrimaryCompositeKeyConditionInput = {
  eq?: ModelContentLearningPrimaryCompositeKeyInput | null,
  le?: ModelContentLearningPrimaryCompositeKeyInput | null,
  lt?: ModelContentLearningPrimaryCompositeKeyInput | null,
  ge?: ModelContentLearningPrimaryCompositeKeyInput | null,
  gt?: ModelContentLearningPrimaryCompositeKeyInput | null,
  between?: Array< ModelContentLearningPrimaryCompositeKeyInput | null > | null,
  beginsWith?: ModelContentLearningPrimaryCompositeKeyInput | null,
};

export type ModelContentLearningPrimaryCompositeKeyInput = {
  contentId?: string | null,
  userId?: string | null,
};

export type ModelContentLearningFilterInput = {
  groupId?: ModelIDInput | null,
  courseId?: ModelIDInput | null,
  contentId?: ModelIDInput | null,
  contentType?: ModelContentTypeInput | null,
  userId?: ModelIDInput | null,
  status?: ModelLearningStatusInput | null,
  usageTime?: ModelIntInput | null,
  lastStartedAt?: ModelStringInput | null,
  dataVersion?: ModelIntInput | null,
  tenantCode?: ModelStringInput | null,
  completedAt?: ModelStringInput | null,
  completedUsageTime?: ModelIntInput | null,
  and?: Array< ModelContentLearningFilterInput | null > | null,
  or?: Array< ModelContentLearningFilterInput | null > | null,
  not?: ModelContentLearningFilterInput | null,
};

export type ModelContentLearningConnection = {
  __typename: "ModelContentLearningConnection",
  items:  Array<ContentLearning | null >,
  nextToken?: string | null,
};

export type ModelGroupExamFilterInput = {
  id?: ModelIDInput | null,
  groupId?: ModelIDInput | null,
  contentId?: ModelIDInput | null,
  problemsJson?: ModelStringInput | null,
  courseId?: ModelIDInput | null,
  scheduledStart?: ModelStringInput | null,
  scheduledFinish?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  finishedAt?: ModelStringInput | null,
  visibilityLevel?: ModelExamResultVisibilityLevelInput | null,
  tenantCode?: ModelStringInput | null,
  times?: ModelIntInput | null,
  groupTrainingCourseId?: ModelIDInput | null,
  timeLimit?: ModelIntInput | null,
  passingStandard?: ModelIntInput | null,
  userIdsToBeTested?: ModelStringInput | null,
  and?: Array< ModelGroupExamFilterInput | null > | null,
  or?: Array< ModelGroupExamFilterInput | null > | null,
  not?: ModelGroupExamFilterInput | null,
};

export type ModelUserExamFilterInput = {
  id?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  status?: ModelUserExamStatusInput | null,
  tenantCode?: ModelStringInput | null,
  groupExamId?: ModelIDInput | null,
  groupId?: ModelIDInput | null,
  startedAt?: ModelStringInput | null,
  finishedAt?: ModelStringInput | null,
  and?: Array< ModelUserExamFilterInput | null > | null,
  or?: Array< ModelUserExamFilterInput | null > | null,
  not?: ModelUserExamFilterInput | null,
};

export type InviteUserMutationVariables = {
  input: InviteUserInput,
};

export type InviteUserMutation = {
  inviteUser?:  {
    __typename: "InviteUserResponse",
    signUpReservationId: string,
  } | null,
};

export type UploadMutationVariables = {
  filename: string,
  body: string,
};

export type UploadMutation = {
  upload?:  {
    __typename: "UploadResponse",
    url: string,
    key: string,
  } | null,
};

export type ChangePasswordByAdminMutationVariables = {
  id: string,
  password: string,
  forcePasswordChange: boolean,
};

export type ChangePasswordByAdminMutation = {
  changePasswordByAdmin?: string | null,
};

export type CreateGroupMutationVariables = {
  input: CreateGroupInput,
};

export type CreateGroupMutation = {
  createGroup?:  {
    __typename: "Group",
    id: string,
    name: string,
    users:  Array< {
      __typename: "GroupUser",
      id: string,
      role: GroupRole,
      removed?: boolean | null,
    } >,
    extensionConfigJsonList: Array< string >,
    tenantCode: string,
    limitations?: string | null,
    enabled?: boolean | null,
    description?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateGroupMutationVariables = {
  input: UpdateGroupInput,
};

export type UpdateGroupMutation = {
  updateGroup?:  {
    __typename: "Group",
    id: string,
    name: string,
    users:  Array< {
      __typename: "GroupUser",
      id: string,
      role: GroupRole,
      removed?: boolean | null,
    } >,
    extensionConfigJsonList: Array< string >,
    tenantCode: string,
    limitations?: string | null,
    enabled?: boolean | null,
    description?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateEditingCourseMutationVariables = {
  input: UpdateEditingCourseInput,
};

export type UpdateEditingCourseMutation = {
  updateEditingCourse?:  {
    __typename: "EditingCourse",
    id: string,
    tenantCode: string,
    name: string,
    version: number,
    status: EditingStatus,
    description?: string | null,
    contents:  Array< {
      __typename: "EditingCourseContentHeader",
      id: string,
      name: string,
      requiredTime: number,
      type: ContentType,
    } >,
    color?: string | null,
    image?: string | null,
    fontColorOnImage?: CourseFontColorOnImage | null,
    createdBy: string,
    createdAt?: string | null,
    updatedBy: string,
    updatedAt?: string | null,
  } | null,
};

export type CreateCourseHeaderMutationVariables = {
  input: CreateCourseHeaderInput,
};

export type CreateCourseHeaderMutation = {
  createCourseHeader?:  {
    __typename: "CourseHeader",
    id: string,
    name: string,
    activeVersion: number,
    status: CourseStatus,
    originalCourseId?: string | null,
    originalCourseVersion?: number | null,
    tenantCode: string,
    createdBy: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateUserByAdminMutationVariables = {
  input: CreateUserByAdminInput,
};

export type CreateUserByAdminMutation = {
  createUserByAdmin?:  {
    __typename: "CreateUserByAdminResponse",
    userId: string,
  } | null,
};

export type ChangeConfirmedCourseNameMutationVariables = {
  input: ChangeConfirmedCourseNameInput,
};

export type ChangeConfirmedCourseNameMutation = {
  changeConfirmedCourseName?: string | null,
};

export type AddUserDeviceMutationVariables = {
  input: AddUserDeviceInput,
};

export type AddUserDeviceMutation = {
  addUserDevice?: string | null,
};

export type DeleteGroupMutationVariables = {
  input: DeleteGroupInput,
  condition?: ModelGroupConditionInput | null,
};

export type DeleteGroupMutation = {
  deleteGroup?:  {
    __typename: "Group",
    id: string,
    name: string,
    users:  Array< {
      __typename: "GroupUser",
      id: string,
      role: GroupRole,
      removed?: boolean | null,
    } >,
    extensionConfigJsonList: Array< string >,
    tenantCode: string,
    limitations?: string | null,
    enabled?: boolean | null,
    description?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateNotificationMutationVariables = {
  input: UpdateNotificationInput,
  condition?: ModelNotificationConditionInput | null,
};

export type UpdateNotificationMutation = {
  updateNotification?:  {
    __typename: "Notification",
    id: string,
    type: string,
    payloadJson: string,
    userId: string,
    read: boolean,
    createdAt?: string | null,
    tenantCode: string,
    updatedAt: string,
  } | null,
};

export type DeleteNotificationMutationVariables = {
  input: DeleteNotificationInput,
  condition?: ModelNotificationConditionInput | null,
};

export type DeleteNotificationMutation = {
  deleteNotification?:  {
    __typename: "Notification",
    id: string,
    type: string,
    payloadJson: string,
    userId: string,
    read: boolean,
    createdAt?: string | null,
    tenantCode: string,
    updatedAt: string,
  } | null,
};

export type UpdateUserMutationVariables = {
  input: UpdateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type UpdateUserMutation = {
  updateUser?:  {
    __typename: "User",
    id: string,
    name: string,
    role: Role,
    extensionConfigJsonList: Array< string >,
    email?: string | null,
    code?: string | null,
    tenantCode: string,
    displaySettings?:  {
      __typename: "DisplaySettings",
      theme?: string | null,
      color?: string | null,
    } | null,
    locale?: string | null,
    avatar?: string | null,
    enabled: boolean,
    signedInAtLeastOnce: boolean,
    emailConfirmed?: boolean | null,
    confirmedTermsOfServiceVersions?:  {
      __typename: "ConfirmedTermsOfServiceVersions",
      termsOfServiceVersion?: string | null,
      privacyPolicyVersion?: string | null,
      tenantOwnTermsOfServiceVersion?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    tags?:  {
      __typename: "ModelUserUserTagConnection",
      items:  Array< {
        __typename: "UserUserTag",
        id: string,
        userId: string,
        userTagId: string,
        tenantCode: string,
        createdAt: string,
        updatedAt: string,
        user:  {
          __typename: "User",
          id: string,
          name: string,
          role: Role,
          extensionConfigJsonList: Array< string >,
          email?: string | null,
          code?: string | null,
          tenantCode: string,
          displaySettings?:  {
            __typename: "DisplaySettings",
            theme?: string | null,
            color?: string | null,
          } | null,
          locale?: string | null,
          avatar?: string | null,
          enabled: boolean,
          signedInAtLeastOnce: boolean,
          emailConfirmed?: boolean | null,
          confirmedTermsOfServiceVersions?:  {
            __typename: "ConfirmedTermsOfServiceVersions",
            termsOfServiceVersion?: string | null,
            privacyPolicyVersion?: string | null,
            tenantOwnTermsOfServiceVersion?: number | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          tags?:  {
            __typename: "ModelUserUserTagConnection",
            items:  Array< {
              __typename: "UserUserTag",
              id: string,
              userId: string,
              userTagId: string,
              tenantCode: string,
              createdAt: string,
              updatedAt: string,
              user:  {
                __typename: "User",
                id: string,
                name: string,
                role: Role,
                extensionConfigJsonList: Array< string >,
                email?: string | null,
                code?: string | null,
                tenantCode: string,
                displaySettings?:  {
                  __typename: "DisplaySettings",
                  theme?: string | null,
                  color?: string | null,
                } | null,
                locale?: string | null,
                avatar?: string | null,
                enabled: boolean,
                signedInAtLeastOnce: boolean,
                emailConfirmed?: boolean | null,
                confirmedTermsOfServiceVersions?:  {
                  __typename: "ConfirmedTermsOfServiceVersions",
                  termsOfServiceVersion?: string | null,
                  privacyPolicyVersion?: string | null,
                  tenantOwnTermsOfServiceVersion?: number | null,
                } | null,
                createdAt: string,
                updatedAt: string,
                tags?:  {
                  __typename: "ModelUserUserTagConnection",
                  nextToken?: string | null,
                } | null,
                statuses?:  {
                  __typename: "ModelUserStatusTableConnection",
                  nextToken?: string | null,
                } | null,
              },
              userTag:  {
                __typename: "UserTag",
                id: string,
                color: string,
                text: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                users?:  {
                  __typename: "ModelUserUserTagConnection",
                  nextToken?: string | null,
                } | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          statuses?:  {
            __typename: "ModelUserStatusTableConnection",
            items:  Array< {
              __typename: "UserStatusTable",
              id: string,
              tenantCode: string,
              status: UserStatus,
              userId: string,
              createdAt: string,
              updatedAt: string,
              user:  {
                __typename: "User",
                id: string,
                name: string,
                role: Role,
                extensionConfigJsonList: Array< string >,
                email?: string | null,
                code?: string | null,
                tenantCode: string,
                displaySettings?:  {
                  __typename: "DisplaySettings",
                  theme?: string | null,
                  color?: string | null,
                } | null,
                locale?: string | null,
                avatar?: string | null,
                enabled: boolean,
                signedInAtLeastOnce: boolean,
                emailConfirmed?: boolean | null,
                confirmedTermsOfServiceVersions?:  {
                  __typename: "ConfirmedTermsOfServiceVersions",
                  termsOfServiceVersion?: string | null,
                  privacyPolicyVersion?: string | null,
                  tenantOwnTermsOfServiceVersion?: number | null,
                } | null,
                createdAt: string,
                updatedAt: string,
                tags?:  {
                  __typename: "ModelUserUserTagConnection",
                  nextToken?: string | null,
                } | null,
                statuses?:  {
                  __typename: "ModelUserStatusTableConnection",
                  nextToken?: string | null,
                } | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        userTag:  {
          __typename: "UserTag",
          id: string,
          color: string,
          text: string,
          tenantCode: string,
          createdAt: string,
          updatedAt: string,
          users?:  {
            __typename: "ModelUserUserTagConnection",
            items:  Array< {
              __typename: "UserUserTag",
              id: string,
              userId: string,
              userTagId: string,
              tenantCode: string,
              createdAt: string,
              updatedAt: string,
              user:  {
                __typename: "User",
                id: string,
                name: string,
                role: Role,
                extensionConfigJsonList: Array< string >,
                email?: string | null,
                code?: string | null,
                tenantCode: string,
                displaySettings?:  {
                  __typename: "DisplaySettings",
                  theme?: string | null,
                  color?: string | null,
                } | null,
                locale?: string | null,
                avatar?: string | null,
                enabled: boolean,
                signedInAtLeastOnce: boolean,
                emailConfirmed?: boolean | null,
                confirmedTermsOfServiceVersions?:  {
                  __typename: "ConfirmedTermsOfServiceVersions",
                  termsOfServiceVersion?: string | null,
                  privacyPolicyVersion?: string | null,
                  tenantOwnTermsOfServiceVersion?: number | null,
                } | null,
                createdAt: string,
                updatedAt: string,
                tags?:  {
                  __typename: "ModelUserUserTagConnection",
                  nextToken?: string | null,
                } | null,
                statuses?:  {
                  __typename: "ModelUserStatusTableConnection",
                  nextToken?: string | null,
                } | null,
              },
              userTag:  {
                __typename: "UserTag",
                id: string,
                color: string,
                text: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                users?:  {
                  __typename: "ModelUserUserTagConnection",
                  nextToken?: string | null,
                } | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    statuses?:  {
      __typename: "ModelUserStatusTableConnection",
      items:  Array< {
        __typename: "UserStatusTable",
        id: string,
        tenantCode: string,
        status: UserStatus,
        userId: string,
        createdAt: string,
        updatedAt: string,
        user:  {
          __typename: "User",
          id: string,
          name: string,
          role: Role,
          extensionConfigJsonList: Array< string >,
          email?: string | null,
          code?: string | null,
          tenantCode: string,
          displaySettings?:  {
            __typename: "DisplaySettings",
            theme?: string | null,
            color?: string | null,
          } | null,
          locale?: string | null,
          avatar?: string | null,
          enabled: boolean,
          signedInAtLeastOnce: boolean,
          emailConfirmed?: boolean | null,
          confirmedTermsOfServiceVersions?:  {
            __typename: "ConfirmedTermsOfServiceVersions",
            termsOfServiceVersion?: string | null,
            privacyPolicyVersion?: string | null,
            tenantOwnTermsOfServiceVersion?: number | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          tags?:  {
            __typename: "ModelUserUserTagConnection",
            items:  Array< {
              __typename: "UserUserTag",
              id: string,
              userId: string,
              userTagId: string,
              tenantCode: string,
              createdAt: string,
              updatedAt: string,
              user:  {
                __typename: "User",
                id: string,
                name: string,
                role: Role,
                extensionConfigJsonList: Array< string >,
                email?: string | null,
                code?: string | null,
                tenantCode: string,
                displaySettings?:  {
                  __typename: "DisplaySettings",
                  theme?: string | null,
                  color?: string | null,
                } | null,
                locale?: string | null,
                avatar?: string | null,
                enabled: boolean,
                signedInAtLeastOnce: boolean,
                emailConfirmed?: boolean | null,
                confirmedTermsOfServiceVersions?:  {
                  __typename: "ConfirmedTermsOfServiceVersions",
                  termsOfServiceVersion?: string | null,
                  privacyPolicyVersion?: string | null,
                  tenantOwnTermsOfServiceVersion?: number | null,
                } | null,
                createdAt: string,
                updatedAt: string,
                tags?:  {
                  __typename: "ModelUserUserTagConnection",
                  nextToken?: string | null,
                } | null,
                statuses?:  {
                  __typename: "ModelUserStatusTableConnection",
                  nextToken?: string | null,
                } | null,
              },
              userTag:  {
                __typename: "UserTag",
                id: string,
                color: string,
                text: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                users?:  {
                  __typename: "ModelUserUserTagConnection",
                  nextToken?: string | null,
                } | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          statuses?:  {
            __typename: "ModelUserStatusTableConnection",
            items:  Array< {
              __typename: "UserStatusTable",
              id: string,
              tenantCode: string,
              status: UserStatus,
              userId: string,
              createdAt: string,
              updatedAt: string,
              user:  {
                __typename: "User",
                id: string,
                name: string,
                role: Role,
                extensionConfigJsonList: Array< string >,
                email?: string | null,
                code?: string | null,
                tenantCode: string,
                displaySettings?:  {
                  __typename: "DisplaySettings",
                  theme?: string | null,
                  color?: string | null,
                } | null,
                locale?: string | null,
                avatar?: string | null,
                enabled: boolean,
                signedInAtLeastOnce: boolean,
                emailConfirmed?: boolean | null,
                confirmedTermsOfServiceVersions?:  {
                  __typename: "ConfirmedTermsOfServiceVersions",
                  termsOfServiceVersion?: string | null,
                  privacyPolicyVersion?: string | null,
                  tenantOwnTermsOfServiceVersion?: number | null,
                } | null,
                createdAt: string,
                updatedAt: string,
                tags?:  {
                  __typename: "ModelUserUserTagConnection",
                  nextToken?: string | null,
                } | null,
                statuses?:  {
                  __typename: "ModelUserStatusTableConnection",
                  nextToken?: string | null,
                } | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteUserMutationVariables = {
  input: DeleteUserInput,
  condition?: ModelUserConditionInput | null,
};

export type DeleteUserMutation = {
  deleteUser?:  {
    __typename: "User",
    id: string,
    name: string,
    role: Role,
    extensionConfigJsonList: Array< string >,
    email?: string | null,
    code?: string | null,
    tenantCode: string,
    displaySettings?:  {
      __typename: "DisplaySettings",
      theme?: string | null,
      color?: string | null,
    } | null,
    locale?: string | null,
    avatar?: string | null,
    enabled: boolean,
    signedInAtLeastOnce: boolean,
    emailConfirmed?: boolean | null,
    confirmedTermsOfServiceVersions?:  {
      __typename: "ConfirmedTermsOfServiceVersions",
      termsOfServiceVersion?: string | null,
      privacyPolicyVersion?: string | null,
      tenantOwnTermsOfServiceVersion?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    tags?:  {
      __typename: "ModelUserUserTagConnection",
      items:  Array< {
        __typename: "UserUserTag",
        id: string,
        userId: string,
        userTagId: string,
        tenantCode: string,
        createdAt: string,
        updatedAt: string,
        user:  {
          __typename: "User",
          id: string,
          name: string,
          role: Role,
          extensionConfigJsonList: Array< string >,
          email?: string | null,
          code?: string | null,
          tenantCode: string,
          displaySettings?:  {
            __typename: "DisplaySettings",
            theme?: string | null,
            color?: string | null,
          } | null,
          locale?: string | null,
          avatar?: string | null,
          enabled: boolean,
          signedInAtLeastOnce: boolean,
          emailConfirmed?: boolean | null,
          confirmedTermsOfServiceVersions?:  {
            __typename: "ConfirmedTermsOfServiceVersions",
            termsOfServiceVersion?: string | null,
            privacyPolicyVersion?: string | null,
            tenantOwnTermsOfServiceVersion?: number | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          tags?:  {
            __typename: "ModelUserUserTagConnection",
            items:  Array< {
              __typename: "UserUserTag",
              id: string,
              userId: string,
              userTagId: string,
              tenantCode: string,
              createdAt: string,
              updatedAt: string,
              user:  {
                __typename: "User",
                id: string,
                name: string,
                role: Role,
                extensionConfigJsonList: Array< string >,
                email?: string | null,
                code?: string | null,
                tenantCode: string,
                displaySettings?:  {
                  __typename: "DisplaySettings",
                  theme?: string | null,
                  color?: string | null,
                } | null,
                locale?: string | null,
                avatar?: string | null,
                enabled: boolean,
                signedInAtLeastOnce: boolean,
                emailConfirmed?: boolean | null,
                confirmedTermsOfServiceVersions?:  {
                  __typename: "ConfirmedTermsOfServiceVersions",
                  termsOfServiceVersion?: string | null,
                  privacyPolicyVersion?: string | null,
                  tenantOwnTermsOfServiceVersion?: number | null,
                } | null,
                createdAt: string,
                updatedAt: string,
                tags?:  {
                  __typename: "ModelUserUserTagConnection",
                  nextToken?: string | null,
                } | null,
                statuses?:  {
                  __typename: "ModelUserStatusTableConnection",
                  nextToken?: string | null,
                } | null,
              },
              userTag:  {
                __typename: "UserTag",
                id: string,
                color: string,
                text: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                users?:  {
                  __typename: "ModelUserUserTagConnection",
                  nextToken?: string | null,
                } | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          statuses?:  {
            __typename: "ModelUserStatusTableConnection",
            items:  Array< {
              __typename: "UserStatusTable",
              id: string,
              tenantCode: string,
              status: UserStatus,
              userId: string,
              createdAt: string,
              updatedAt: string,
              user:  {
                __typename: "User",
                id: string,
                name: string,
                role: Role,
                extensionConfigJsonList: Array< string >,
                email?: string | null,
                code?: string | null,
                tenantCode: string,
                displaySettings?:  {
                  __typename: "DisplaySettings",
                  theme?: string | null,
                  color?: string | null,
                } | null,
                locale?: string | null,
                avatar?: string | null,
                enabled: boolean,
                signedInAtLeastOnce: boolean,
                emailConfirmed?: boolean | null,
                confirmedTermsOfServiceVersions?:  {
                  __typename: "ConfirmedTermsOfServiceVersions",
                  termsOfServiceVersion?: string | null,
                  privacyPolicyVersion?: string | null,
                  tenantOwnTermsOfServiceVersion?: number | null,
                } | null,
                createdAt: string,
                updatedAt: string,
                tags?:  {
                  __typename: "ModelUserUserTagConnection",
                  nextToken?: string | null,
                } | null,
                statuses?:  {
                  __typename: "ModelUserStatusTableConnection",
                  nextToken?: string | null,
                } | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        userTag:  {
          __typename: "UserTag",
          id: string,
          color: string,
          text: string,
          tenantCode: string,
          createdAt: string,
          updatedAt: string,
          users?:  {
            __typename: "ModelUserUserTagConnection",
            items:  Array< {
              __typename: "UserUserTag",
              id: string,
              userId: string,
              userTagId: string,
              tenantCode: string,
              createdAt: string,
              updatedAt: string,
              user:  {
                __typename: "User",
                id: string,
                name: string,
                role: Role,
                extensionConfigJsonList: Array< string >,
                email?: string | null,
                code?: string | null,
                tenantCode: string,
                displaySettings?:  {
                  __typename: "DisplaySettings",
                  theme?: string | null,
                  color?: string | null,
                } | null,
                locale?: string | null,
                avatar?: string | null,
                enabled: boolean,
                signedInAtLeastOnce: boolean,
                emailConfirmed?: boolean | null,
                confirmedTermsOfServiceVersions?:  {
                  __typename: "ConfirmedTermsOfServiceVersions",
                  termsOfServiceVersion?: string | null,
                  privacyPolicyVersion?: string | null,
                  tenantOwnTermsOfServiceVersion?: number | null,
                } | null,
                createdAt: string,
                updatedAt: string,
                tags?:  {
                  __typename: "ModelUserUserTagConnection",
                  nextToken?: string | null,
                } | null,
                statuses?:  {
                  __typename: "ModelUserStatusTableConnection",
                  nextToken?: string | null,
                } | null,
              },
              userTag:  {
                __typename: "UserTag",
                id: string,
                color: string,
                text: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                users?:  {
                  __typename: "ModelUserUserTagConnection",
                  nextToken?: string | null,
                } | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    statuses?:  {
      __typename: "ModelUserStatusTableConnection",
      items:  Array< {
        __typename: "UserStatusTable",
        id: string,
        tenantCode: string,
        status: UserStatus,
        userId: string,
        createdAt: string,
        updatedAt: string,
        user:  {
          __typename: "User",
          id: string,
          name: string,
          role: Role,
          extensionConfigJsonList: Array< string >,
          email?: string | null,
          code?: string | null,
          tenantCode: string,
          displaySettings?:  {
            __typename: "DisplaySettings",
            theme?: string | null,
            color?: string | null,
          } | null,
          locale?: string | null,
          avatar?: string | null,
          enabled: boolean,
          signedInAtLeastOnce: boolean,
          emailConfirmed?: boolean | null,
          confirmedTermsOfServiceVersions?:  {
            __typename: "ConfirmedTermsOfServiceVersions",
            termsOfServiceVersion?: string | null,
            privacyPolicyVersion?: string | null,
            tenantOwnTermsOfServiceVersion?: number | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          tags?:  {
            __typename: "ModelUserUserTagConnection",
            items:  Array< {
              __typename: "UserUserTag",
              id: string,
              userId: string,
              userTagId: string,
              tenantCode: string,
              createdAt: string,
              updatedAt: string,
              user:  {
                __typename: "User",
                id: string,
                name: string,
                role: Role,
                extensionConfigJsonList: Array< string >,
                email?: string | null,
                code?: string | null,
                tenantCode: string,
                displaySettings?:  {
                  __typename: "DisplaySettings",
                  theme?: string | null,
                  color?: string | null,
                } | null,
                locale?: string | null,
                avatar?: string | null,
                enabled: boolean,
                signedInAtLeastOnce: boolean,
                emailConfirmed?: boolean | null,
                confirmedTermsOfServiceVersions?:  {
                  __typename: "ConfirmedTermsOfServiceVersions",
                  termsOfServiceVersion?: string | null,
                  privacyPolicyVersion?: string | null,
                  tenantOwnTermsOfServiceVersion?: number | null,
                } | null,
                createdAt: string,
                updatedAt: string,
                tags?:  {
                  __typename: "ModelUserUserTagConnection",
                  nextToken?: string | null,
                } | null,
                statuses?:  {
                  __typename: "ModelUserStatusTableConnection",
                  nextToken?: string | null,
                } | null,
              },
              userTag:  {
                __typename: "UserTag",
                id: string,
                color: string,
                text: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                users?:  {
                  __typename: "ModelUserUserTagConnection",
                  nextToken?: string | null,
                } | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          statuses?:  {
            __typename: "ModelUserStatusTableConnection",
            items:  Array< {
              __typename: "UserStatusTable",
              id: string,
              tenantCode: string,
              status: UserStatus,
              userId: string,
              createdAt: string,
              updatedAt: string,
              user:  {
                __typename: "User",
                id: string,
                name: string,
                role: Role,
                extensionConfigJsonList: Array< string >,
                email?: string | null,
                code?: string | null,
                tenantCode: string,
                displaySettings?:  {
                  __typename: "DisplaySettings",
                  theme?: string | null,
                  color?: string | null,
                } | null,
                locale?: string | null,
                avatar?: string | null,
                enabled: boolean,
                signedInAtLeastOnce: boolean,
                emailConfirmed?: boolean | null,
                confirmedTermsOfServiceVersions?:  {
                  __typename: "ConfirmedTermsOfServiceVersions",
                  termsOfServiceVersion?: string | null,
                  privacyPolicyVersion?: string | null,
                  tenantOwnTermsOfServiceVersion?: number | null,
                } | null,
                createdAt: string,
                updatedAt: string,
                tags?:  {
                  __typename: "ModelUserUserTagConnection",
                  nextToken?: string | null,
                } | null,
                statuses?:  {
                  __typename: "ModelUserStatusTableConnection",
                  nextToken?: string | null,
                } | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateUserDeviceMutationVariables = {
  input: CreateUserDeviceInput,
  condition?: ModelUserDeviceConditionInput | null,
};

export type CreateUserDeviceMutation = {
  createUserDevice?:  {
    __typename: "UserDevice",
    userId: string,
    devices: string,
    dataVersion: number,
    tenantCode: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateUserDeviceMutationVariables = {
  input: UpdateUserDeviceInput,
  condition?: ModelUserDeviceConditionInput | null,
};

export type UpdateUserDeviceMutation = {
  updateUserDevice?:  {
    __typename: "UserDevice",
    userId: string,
    devices: string,
    dataVersion: number,
    tenantCode: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteUserDeviceMutationVariables = {
  input: DeleteUserDeviceInput,
  condition?: ModelUserDeviceConditionInput | null,
};

export type DeleteUserDeviceMutation = {
  deleteUserDevice?:  {
    __typename: "UserDevice",
    userId: string,
    devices: string,
    dataVersion: number,
    tenantCode: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateSignUpReservationMutationVariables = {
  input: CreateSignUpReservationInput,
  condition?: ModelSignUpReservationConditionInput | null,
};

export type CreateSignUpReservationMutation = {
  createSignUpReservation?:  {
    __typename: "SignUpReservation",
    id: string,
    name?: string | null,
    key: string,
    userCode?: string | null,
    email?: string | null,
    tenantCode: string,
    type: SignUpType,
    role: Role,
    userId?: string | null,
    signUpInput?:  {
      __typename: "SignUpInput",
      name: string,
      tenantName?: string | null,
    } | null,
    status: SignUpReservationStatus,
    groups?:  Array< {
      __typename: "GroupUser",
      id: string,
      role: GroupRole,
      removed?: boolean | null,
    } > | null,
    createdBy?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateSignUpReservationMutationVariables = {
  input: UpdateSignUpReservationInput,
  condition?: ModelSignUpReservationConditionInput | null,
};

export type UpdateSignUpReservationMutation = {
  updateSignUpReservation?:  {
    __typename: "SignUpReservation",
    id: string,
    name?: string | null,
    key: string,
    userCode?: string | null,
    email?: string | null,
    tenantCode: string,
    type: SignUpType,
    role: Role,
    userId?: string | null,
    signUpInput?:  {
      __typename: "SignUpInput",
      name: string,
      tenantName?: string | null,
    } | null,
    status: SignUpReservationStatus,
    groups?:  Array< {
      __typename: "GroupUser",
      id: string,
      role: GroupRole,
      removed?: boolean | null,
    } > | null,
    createdBy?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteSignUpReservationMutationVariables = {
  input: DeleteSignUpReservationInput,
  condition?: ModelSignUpReservationConditionInput | null,
};

export type DeleteSignUpReservationMutation = {
  deleteSignUpReservation?:  {
    __typename: "SignUpReservation",
    id: string,
    name?: string | null,
    key: string,
    userCode?: string | null,
    email?: string | null,
    tenantCode: string,
    type: SignUpType,
    role: Role,
    userId?: string | null,
    signUpInput?:  {
      __typename: "SignUpInput",
      name: string,
      tenantName?: string | null,
    } | null,
    status: SignUpReservationStatus,
    groups?:  Array< {
      __typename: "GroupUser",
      id: string,
      role: GroupRole,
      removed?: boolean | null,
    } > | null,
    createdBy?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateTenantMutationVariables = {
  input: UpdateTenantInput,
  condition?: ModelTenantConditionInput | null,
};

export type UpdateTenantMutation = {
  updateTenant?:  {
    __typename: "Tenant",
    id: string,
    code: string,
    name: string,
    signUpType: SignUpType,
    playground: boolean,
    userLimit?: number | null,
    limitations?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteTenantMutationVariables = {
  input: DeleteTenantInput,
  condition?: ModelTenantConditionInput | null,
};

export type DeleteTenantMutation = {
  deleteTenant?:  {
    __typename: "Tenant",
    id: string,
    code: string,
    name: string,
    signUpType: SignUpType,
    playground: boolean,
    userLimit?: number | null,
    limitations?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateTenantOwnTermsOfServiceMutationVariables = {
  input: UpdateTenantOwnTermsOfServiceInput,
  condition?: ModelTenantOwnTermsOfServiceConditionInput | null,
};

export type UpdateTenantOwnTermsOfServiceMutation = {
  updateTenantOwnTermsOfService?:  {
    __typename: "TenantOwnTermsOfService",
    id: string,
    tenantCode: string,
    type: TenantOwnTermsOfServiceType,
    body: string,
    createdBy: string,
    createdAt: string,
    updatedBy?: string | null,
    updatedAt?: string | null,
    version?: number | null,
  } | null,
};

export type DeleteTenantOwnTermsOfServiceMutationVariables = {
  input: DeleteTenantOwnTermsOfServiceInput,
  condition?: ModelTenantOwnTermsOfServiceConditionInput | null,
};

export type DeleteTenantOwnTermsOfServiceMutation = {
  deleteTenantOwnTermsOfService?:  {
    __typename: "TenantOwnTermsOfService",
    id: string,
    tenantCode: string,
    type: TenantOwnTermsOfServiceType,
    body: string,
    createdBy: string,
    createdAt: string,
    updatedBy?: string | null,
    updatedAt?: string | null,
    version?: number | null,
  } | null,
};

export type CreateEventMutationVariables = {
  input: CreateEventInput,
  condition?: ModelEventConditionInput | null,
};

export type CreateEventMutation = {
  createEvent?:  {
    __typename: "Event",
    id: string,
    type: string,
    event: string,
    version: number,
    tenantCode: string,
    occurredBy: string,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type UpdateEventMutationVariables = {
  input: UpdateEventInput,
  condition?: ModelEventConditionInput | null,
};

export type UpdateEventMutation = {
  updateEvent?:  {
    __typename: "Event",
    id: string,
    type: string,
    event: string,
    version: number,
    tenantCode: string,
    occurredBy: string,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type DeleteEventMutationVariables = {
  input: DeleteEventInput,
  condition?: ModelEventConditionInput | null,
};

export type DeleteEventMutation = {
  deleteEvent?:  {
    __typename: "Event",
    id: string,
    type: string,
    event: string,
    version: number,
    tenantCode: string,
    occurredBy: string,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type CreateTermsOfServiceMutationVariables = {
  input: CreateTermsOfServiceInput,
  condition?: ModelTermsOfServiceConditionInput | null,
};

export type CreateTermsOfServiceMutation = {
  createTermsOfService?:  {
    __typename: "TermsOfService",
    id: string,
    body: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateTermsOfServiceMutationVariables = {
  input: UpdateTermsOfServiceInput,
  condition?: ModelTermsOfServiceConditionInput | null,
};

export type UpdateTermsOfServiceMutation = {
  updateTermsOfService?:  {
    __typename: "TermsOfService",
    id: string,
    body: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteTermsOfServiceMutationVariables = {
  input: DeleteTermsOfServiceInput,
  condition?: ModelTermsOfServiceConditionInput | null,
};

export type DeleteTermsOfServiceMutation = {
  deleteTermsOfService?:  {
    __typename: "TermsOfService",
    id: string,
    body: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateLiveMutationVariables = {
  input: CreateLiveInput,
  condition?: ModelLiveConditionInput | null,
};

export type CreateLiveMutation = {
  createLive?:  {
    __typename: "Live",
    id: string,
    userId: string,
    url: string,
    applicationSessionId: string,
    tenantCode: string,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type UpdateLiveMutationVariables = {
  input: UpdateLiveInput,
  condition?: ModelLiveConditionInput | null,
};

export type UpdateLiveMutation = {
  updateLive?:  {
    __typename: "Live",
    id: string,
    userId: string,
    url: string,
    applicationSessionId: string,
    tenantCode: string,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type DeleteLiveMutationVariables = {
  input: DeleteLiveInput,
  condition?: ModelLiveConditionInput | null,
};

export type DeleteLiveMutation = {
  deleteLive?:  {
    __typename: "Live",
    id: string,
    userId: string,
    url: string,
    applicationSessionId: string,
    tenantCode: string,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type CreateGroupStatusUserMutationVariables = {
  input: CreateGroupStatusUserInput,
  condition?: ModelGroupStatusUserConditionInput | null,
};

export type CreateGroupStatusUserMutation = {
  createGroupStatusUser?:  {
    __typename: "GroupStatusUser",
    id: string,
    userId: string,
    groupId: string,
    handStatus: HandStatus,
    tenantCode: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateGroupStatusMutationVariables = {
  input: CreateGroupStatusInput,
  condition?: ModelGroupStatusConditionInput | null,
};

export type CreateGroupStatusMutation = {
  createGroupStatus?:  {
    __typename: "GroupStatus",
    groupId: string,
    tenantCode: string,
    createdAt: string,
    updatedAt: string,
    users?:  {
      __typename: "ModelGroupStatusUserConnection",
      items:  Array< {
        __typename: "GroupStatusUser",
        id: string,
        userId: string,
        groupId: string,
        handStatus: HandStatus,
        tenantCode: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateGroupStatusMutationVariables = {
  input: UpdateGroupStatusInput,
  condition?: ModelGroupStatusConditionInput | null,
};

export type UpdateGroupStatusMutation = {
  updateGroupStatus?:  {
    __typename: "GroupStatus",
    groupId: string,
    tenantCode: string,
    createdAt: string,
    updatedAt: string,
    users?:  {
      __typename: "ModelGroupStatusUserConnection",
      items:  Array< {
        __typename: "GroupStatusUser",
        id: string,
        userId: string,
        groupId: string,
        handStatus: HandStatus,
        tenantCode: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteGroupStatusMutationVariables = {
  input: DeleteGroupStatusInput,
  condition?: ModelGroupStatusConditionInput | null,
};

export type DeleteGroupStatusMutation = {
  deleteGroupStatus?:  {
    __typename: "GroupStatus",
    groupId: string,
    tenantCode: string,
    createdAt: string,
    updatedAt: string,
    users?:  {
      __typename: "ModelGroupStatusUserConnection",
      items:  Array< {
        __typename: "GroupStatusUser",
        id: string,
        userId: string,
        groupId: string,
        handStatus: HandStatus,
        tenantCode: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateQuestionnaireMutationVariables = {
  input: CreateQuestionnaireInput,
  condition?: ModelQuestionnaireConditionInput | null,
};

export type CreateQuestionnaireMutation = {
  createQuestionnaire?:  {
    __typename: "Questionnaire",
    id: string,
    groupId: string,
    tenantCode: string,
    title: string,
    userIds: Array< string >,
    text?: string | null,
    createdBy: string,
    createdAt?: string | null,
    options: Array< string >,
    respondent?:  {
      __typename: "ModelQuestionnaireRespondentConnection",
      items:  Array< {
        __typename: "QuestionnaireRespondent",
        id: string,
        questionnaireId: string,
        userId: string,
        selectedIndex: number,
        tenantCode: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    status: QuestionnaireStatus,
    finishedAt?: string | null,
    updatedAt: string,
  } | null,
};

export type UpdateQuestionnaireMutationVariables = {
  input: UpdateQuestionnaireInput,
  condition?: ModelQuestionnaireConditionInput | null,
};

export type UpdateQuestionnaireMutation = {
  updateQuestionnaire?:  {
    __typename: "Questionnaire",
    id: string,
    groupId: string,
    tenantCode: string,
    title: string,
    userIds: Array< string >,
    text?: string | null,
    createdBy: string,
    createdAt?: string | null,
    options: Array< string >,
    respondent?:  {
      __typename: "ModelQuestionnaireRespondentConnection",
      items:  Array< {
        __typename: "QuestionnaireRespondent",
        id: string,
        questionnaireId: string,
        userId: string,
        selectedIndex: number,
        tenantCode: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    status: QuestionnaireStatus,
    finishedAt?: string | null,
    updatedAt: string,
  } | null,
};

export type DeleteQuestionnaireMutationVariables = {
  input: DeleteQuestionnaireInput,
  condition?: ModelQuestionnaireConditionInput | null,
};

export type DeleteQuestionnaireMutation = {
  deleteQuestionnaire?:  {
    __typename: "Questionnaire",
    id: string,
    groupId: string,
    tenantCode: string,
    title: string,
    userIds: Array< string >,
    text?: string | null,
    createdBy: string,
    createdAt?: string | null,
    options: Array< string >,
    respondent?:  {
      __typename: "ModelQuestionnaireRespondentConnection",
      items:  Array< {
        __typename: "QuestionnaireRespondent",
        id: string,
        questionnaireId: string,
        userId: string,
        selectedIndex: number,
        tenantCode: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    status: QuestionnaireStatus,
    finishedAt?: string | null,
    updatedAt: string,
  } | null,
};

export type CreateQuestionnaireRespondentMutationVariables = {
  input: CreateQuestionnaireRespondentInput,
  condition?: ModelQuestionnaireRespondentConditionInput | null,
};

export type CreateQuestionnaireRespondentMutation = {
  createQuestionnaireRespondent?:  {
    __typename: "QuestionnaireRespondent",
    id: string,
    questionnaireId: string,
    userId: string,
    selectedIndex: number,
    tenantCode: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateQuestionnaireRespondentMutationVariables = {
  input: UpdateQuestionnaireRespondentInput,
  condition?: ModelQuestionnaireRespondentConditionInput | null,
};

export type UpdateQuestionnaireRespondentMutation = {
  updateQuestionnaireRespondent?:  {
    __typename: "QuestionnaireRespondent",
    id: string,
    questionnaireId: string,
    userId: string,
    selectedIndex: number,
    tenantCode: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteQuestionnaireRespondentMutationVariables = {
  input: DeleteQuestionnaireRespondentInput,
  condition?: ModelQuestionnaireRespondentConditionInput | null,
};

export type DeleteQuestionnaireRespondentMutation = {
  deleteQuestionnaireRespondent?:  {
    __typename: "QuestionnaireRespondent",
    id: string,
    questionnaireId: string,
    userId: string,
    selectedIndex: number,
    tenantCode: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateContentMutationVariables = {
  input: UpdateContentInput,
  condition?: ModelContentConditionInput | null,
};

export type UpdateContentMutation = {
  updateContent?:  {
    __typename: "Content",
    id: string,
    contentId: string,
    version: number,
    name: string,
    requiredTime: number,
    body:  {
      __typename: "ContentBody",
      type: ContentType,
      text?:  {
        __typename: "TextContent",
        body: string,
      } | null,
      exam?:  {
        __typename: "ExamContent",
        problems:  Array< {
          __typename: "Problem",
          index: number,
          body: string,
          commentary?: string | null,
          type: ProblemType,
          choice?:  {
            __typename: "ChoiceProblem",
            options:  Array< {
              __typename: "ChoiceProblemOption",
              index: number,
              text: string,
            } >,
            multiple: boolean,
            answer: Array< number >,
          } | null,
          headerId?: string | null,
        } >,
        problemHeaders?:  Array< {
          __typename: "ProblemHeader",
          id: string,
          body: string,
          createdAt: string,
        } > | null,
        passingStandard?: number | null,
      } | null,
    },
    workbook?:  {
      __typename: "Workbook",
      problems:  Array< {
        __typename: "Problem",
        index: number,
        body: string,
        commentary?: string | null,
        type: ProblemType,
        choice?:  {
          __typename: "ChoiceProblem",
          options:  Array< {
            __typename: "ChoiceProblemOption",
            index: number,
            text: string,
          } >,
          multiple: boolean,
          answer: Array< number >,
        } | null,
        headerId?: string | null,
      } >,
      problemHeaders?:  Array< {
        __typename: "ProblemHeader",
        id: string,
        body: string,
        createdAt: string,
      } > | null,
    } | null,
    courseId: string,
    courseVersion: number,
    tenantCode: string,
    latest: boolean,
    versionDescription?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteContentMutationVariables = {
  input: DeleteContentInput,
  condition?: ModelContentConditionInput | null,
};

export type DeleteContentMutation = {
  deleteContent?:  {
    __typename: "Content",
    id: string,
    contentId: string,
    version: number,
    name: string,
    requiredTime: number,
    body:  {
      __typename: "ContentBody",
      type: ContentType,
      text?:  {
        __typename: "TextContent",
        body: string,
      } | null,
      exam?:  {
        __typename: "ExamContent",
        problems:  Array< {
          __typename: "Problem",
          index: number,
          body: string,
          commentary?: string | null,
          type: ProblemType,
          choice?:  {
            __typename: "ChoiceProblem",
            options:  Array< {
              __typename: "ChoiceProblemOption",
              index: number,
              text: string,
            } >,
            multiple: boolean,
            answer: Array< number >,
          } | null,
          headerId?: string | null,
        } >,
        problemHeaders?:  Array< {
          __typename: "ProblemHeader",
          id: string,
          body: string,
          createdAt: string,
        } > | null,
        passingStandard?: number | null,
      } | null,
    },
    workbook?:  {
      __typename: "Workbook",
      problems:  Array< {
        __typename: "Problem",
        index: number,
        body: string,
        commentary?: string | null,
        type: ProblemType,
        choice?:  {
          __typename: "ChoiceProblem",
          options:  Array< {
            __typename: "ChoiceProblemOption",
            index: number,
            text: string,
          } >,
          multiple: boolean,
          answer: Array< number >,
        } | null,
        headerId?: string | null,
      } >,
      problemHeaders?:  Array< {
        __typename: "ProblemHeader",
        id: string,
        body: string,
        createdAt: string,
      } > | null,
    } | null,
    courseId: string,
    courseVersion: number,
    tenantCode: string,
    latest: boolean,
    versionDescription?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateCourseMutationVariables = {
  input: UpdateCourseInput,
  condition?: ModelCourseConditionInput | null,
};

export type UpdateCourseMutation = {
  updateCourse?:  {
    __typename: "Course",
    id: string,
    version: number,
    name: string,
    contents:  Array< {
      __typename: "CourseContent",
      id: string,
      type: ContentType,
      name: string,
      requiredTime: number,
    } >,
    description?: string | null,
    tenantCode: string,
    color?: string | null,
    image?: string | null,
    fontColorOnImage?: CourseFontColorOnImage | null,
    confirmedBy?: string | null,
    createdAt?: string | null,
    versionCreatedBy?: string | null,
    versionCreatedAt?: string | null,
    contentLastUpdatedBy?: string | null,
    contentLastUpdatedAt?: string | null,
    updatedAt: string,
  } | null,
};

export type DeleteCourseMutationVariables = {
  input: DeleteCourseInput,
  condition?: ModelCourseConditionInput | null,
};

export type DeleteCourseMutation = {
  deleteCourse?:  {
    __typename: "Course",
    id: string,
    version: number,
    name: string,
    contents:  Array< {
      __typename: "CourseContent",
      id: string,
      type: ContentType,
      name: string,
      requiredTime: number,
    } >,
    description?: string | null,
    tenantCode: string,
    color?: string | null,
    image?: string | null,
    fontColorOnImage?: CourseFontColorOnImage | null,
    confirmedBy?: string | null,
    createdAt?: string | null,
    versionCreatedBy?: string | null,
    versionCreatedAt?: string | null,
    contentLastUpdatedBy?: string | null,
    contentLastUpdatedAt?: string | null,
    updatedAt: string,
  } | null,
};

export type UpdateEditingCourseContentWorkbookMutationVariables = {
  input: UpdateEditingCourseContentWorkbookInput,
  condition?: ModelEditingCourseContentWorkbookConditionInput | null,
};

export type UpdateEditingCourseContentWorkbookMutation = {
  updateEditingCourseContentWorkbook?:  {
    __typename: "EditingCourseContentWorkbook",
    contentId: string,
    problems:  Array< {
      __typename: "Problem",
      index: number,
      body: string,
      commentary?: string | null,
      type: ProblemType,
      choice?:  {
        __typename: "ChoiceProblem",
        options:  Array< {
          __typename: "ChoiceProblemOption",
          index: number,
          text: string,
        } >,
        multiple: boolean,
        answer: Array< number >,
      } | null,
      headerId?: string | null,
    } >,
    problemHeaders?:  Array< {
      __typename: "ProblemHeader",
      id: string,
      body: string,
      createdAt: string,
    } > | null,
    dataVersion: number,
    createdBy: string,
    updatedBy: string,
    createdAt: string,
    updatedAt: string,
    tenantCode: string,
    courseId: string,
  } | null,
};

export type UpdateEditingCourseContentBodyMutationVariables = {
  input: UpdateEditingCourseContentBodyInput,
  condition?: ModelEditingCourseContentBodyConditionInput | null,
};

export type UpdateEditingCourseContentBodyMutation = {
  updateEditingCourseContentBody?:  {
    __typename: "EditingCourseContentBody",
    contentId: string,
    body:  {
      __typename: "ContentBody",
      type: ContentType,
      text?:  {
        __typename: "TextContent",
        body: string,
      } | null,
      exam?:  {
        __typename: "ExamContent",
        problems:  Array< {
          __typename: "Problem",
          index: number,
          body: string,
          commentary?: string | null,
          type: ProblemType,
          choice?:  {
            __typename: "ChoiceProblem",
            options:  Array< {
              __typename: "ChoiceProblemOption",
              index: number,
              text: string,
            } >,
            multiple: boolean,
            answer: Array< number >,
          } | null,
          headerId?: string | null,
        } >,
        problemHeaders?:  Array< {
          __typename: "ProblemHeader",
          id: string,
          body: string,
          createdAt: string,
        } > | null,
        passingStandard?: number | null,
      } | null,
    },
    dataVersion: number,
    createdBy: string,
    updatedBy: string,
    createdAt: string,
    updatedAt: string,
    tenantCode: string,
    courseId: string,
  } | null,
};

export type CreateEditingConfirmedContentMutationVariables = {
  input: CreateEditingConfirmedContentInput,
  condition?: ModelEditingConfirmedContentConditionInput | null,
};

export type CreateEditingConfirmedContentMutation = {
  createEditingConfirmedContent?:  {
    __typename: "EditingConfirmedContent",
    id: string,
    version: number,
    name: string,
    requiredTime: number,
    body:  {
      __typename: "ContentBody",
      type: ContentType,
      text?:  {
        __typename: "TextContent",
        body: string,
      } | null,
      exam?:  {
        __typename: "ExamContent",
        problems:  Array< {
          __typename: "Problem",
          index: number,
          body: string,
          commentary?: string | null,
          type: ProblemType,
          choice?:  {
            __typename: "ChoiceProblem",
            options:  Array< {
              __typename: "ChoiceProblemOption",
              index: number,
              text: string,
            } >,
            multiple: boolean,
            answer: Array< number >,
          } | null,
          headerId?: string | null,
        } >,
        problemHeaders?:  Array< {
          __typename: "ProblemHeader",
          id: string,
          body: string,
          createdAt: string,
        } > | null,
        passingStandard?: number | null,
      } | null,
    },
    workbook?:  {
      __typename: "Workbook",
      problems:  Array< {
        __typename: "Problem",
        index: number,
        body: string,
        commentary?: string | null,
        type: ProblemType,
        choice?:  {
          __typename: "ChoiceProblem",
          options:  Array< {
            __typename: "ChoiceProblemOption",
            index: number,
            text: string,
          } >,
          multiple: boolean,
          answer: Array< number >,
        } | null,
        headerId?: string | null,
      } >,
      problemHeaders?:  Array< {
        __typename: "ProblemHeader",
        id: string,
        body: string,
        createdAt: string,
      } > | null,
    } | null,
    courseId: string,
    courseVersion: number,
    status: EditingStatus,
    tenantCode: string,
    createdBy: string,
    updatedBy: string,
    dataVersion: number,
    versionDescription?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateEditingConfirmedContentMutationVariables = {
  input: UpdateEditingConfirmedContentInput,
  condition?: ModelEditingConfirmedContentConditionInput | null,
};

export type UpdateEditingConfirmedContentMutation = {
  updateEditingConfirmedContent?:  {
    __typename: "EditingConfirmedContent",
    id: string,
    version: number,
    name: string,
    requiredTime: number,
    body:  {
      __typename: "ContentBody",
      type: ContentType,
      text?:  {
        __typename: "TextContent",
        body: string,
      } | null,
      exam?:  {
        __typename: "ExamContent",
        problems:  Array< {
          __typename: "Problem",
          index: number,
          body: string,
          commentary?: string | null,
          type: ProblemType,
          choice?:  {
            __typename: "ChoiceProblem",
            options:  Array< {
              __typename: "ChoiceProblemOption",
              index: number,
              text: string,
            } >,
            multiple: boolean,
            answer: Array< number >,
          } | null,
          headerId?: string | null,
        } >,
        problemHeaders?:  Array< {
          __typename: "ProblemHeader",
          id: string,
          body: string,
          createdAt: string,
        } > | null,
        passingStandard?: number | null,
      } | null,
    },
    workbook?:  {
      __typename: "Workbook",
      problems:  Array< {
        __typename: "Problem",
        index: number,
        body: string,
        commentary?: string | null,
        type: ProblemType,
        choice?:  {
          __typename: "ChoiceProblem",
          options:  Array< {
            __typename: "ChoiceProblemOption",
            index: number,
            text: string,
          } >,
          multiple: boolean,
          answer: Array< number >,
        } | null,
        headerId?: string | null,
      } >,
      problemHeaders?:  Array< {
        __typename: "ProblemHeader",
        id: string,
        body: string,
        createdAt: string,
      } > | null,
    } | null,
    courseId: string,
    courseVersion: number,
    status: EditingStatus,
    tenantCode: string,
    createdBy: string,
    updatedBy: string,
    dataVersion: number,
    versionDescription?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateGroupTrainingMutationVariables = {
  input: UpdateGroupTrainingInput,
  condition?: ModelGroupTrainingConditionInput | null,
};

export type UpdateGroupTrainingMutation = {
  updateGroupTraining?:  {
    __typename: "GroupTraining",
    id: string,
    groupId: string,
    tenantCode: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteGroupTrainingMutationVariables = {
  input: DeleteGroupTrainingInput,
  condition?: ModelGroupTrainingConditionInput | null,
};

export type DeleteGroupTrainingMutation = {
  deleteGroupTraining?:  {
    __typename: "GroupTraining",
    id: string,
    groupId: string,
    tenantCode: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateGroupTrainingCourseMutationVariables = {
  input: CreateGroupTrainingCourseInput,
  condition?: ModelGroupTrainingCourseConditionInput | null,
};

export type CreateGroupTrainingCourseMutation = {
  createGroupTrainingCourse?:  {
    __typename: "GroupTrainingCourse",
    id: string,
    groupTrainingId: string,
    courseId: string,
    courseVersion: number,
    courseName: string,
    contents:  Array< {
      __typename: "GroupTrainingCourseContent",
      id: string,
      name: string,
      requiredTime: number,
      type: ContentType,
      recommendedDateTime?: string | null,
      open: boolean,
      version: number,
    } >,
    groupId: string,
    tenantCode: string,
    color?: string | null,
    image?: string | null,
    fontColorOnImage?: CourseFontColorOnImage | null,
    displayName: string,
    index?: number | null,
    createdAt: string,
    updatedAt: string,
    groupExams?:  {
      __typename: "ModelGroupExamConnection",
      items:  Array< {
        __typename: "GroupExam",
        id: string,
        groupId: string,
        contentId: string,
        content:  {
          __typename: "GroupExamContent",
          id: string,
          version?: number | null,
          name: string,
          timeLimit?: number | null,
          requiredTime?: number | null,
          indexInCourse?: number | null,
          passingStandard?: number | null,
        },
        problemsJson: string,
        courseId: string,
        course:  {
          __typename: "GroupExamCourse",
          id: string,
          version: number,
          name: string,
          color?: string | null,
          image?: string | null,
          fontColorOnImage?: CourseFontColorOnImage | null,
        },
        scheduledStart: string,
        scheduledFinish?: string | null,
        createdBy?: string | null,
        finishedAt?: string | null,
        visibilityLevel?: ExamResultVisibilityLevel | null,
        tenantCode: string,
        times: number,
        groupTrainingCourseId: string,
        problemHeaders?:  Array< {
          __typename: "ProblemHeader",
          id: string,
          body: string,
          createdAt: string,
        } > | null,
        timeLimit?: number | null,
        passingStandard?: number | null,
        userIdsToBeTested?: Array< string > | null,
        createdAt: string,
        updatedAt: string,
        userExams?:  {
          __typename: "ModelUserExamConnection",
          items:  Array< {
            __typename: "UserExam",
            id: string,
            userId: string,
            status: UserExamStatus,
            tenantCode: string,
            groupExamId: string,
            groupId: string,
            startedAt?: string | null,
            finishedAt?: string | null,
            createdAt: string,
            updatedAt: string,
            groupExam?:  {
              __typename: "GroupExam",
              id: string,
              groupId: string,
              contentId: string,
              content:  {
                __typename: "GroupExamContent",
                id: string,
                version?: number | null,
                name: string,
                timeLimit?: number | null,
                requiredTime?: number | null,
                indexInCourse?: number | null,
                passingStandard?: number | null,
              },
              problemsJson: string,
              courseId: string,
              course:  {
                __typename: "GroupExamCourse",
                id: string,
                version: number,
                name: string,
                color?: string | null,
                image?: string | null,
                fontColorOnImage?: CourseFontColorOnImage | null,
              },
              scheduledStart: string,
              scheduledFinish?: string | null,
              createdBy?: string | null,
              finishedAt?: string | null,
              visibilityLevel?: ExamResultVisibilityLevel | null,
              tenantCode: string,
              times: number,
              groupTrainingCourseId: string,
              problemHeaders?:  Array< {
                __typename: "ProblemHeader",
                id: string,
                body: string,
                createdAt: string,
              } > | null,
              timeLimit?: number | null,
              passingStandard?: number | null,
              userIdsToBeTested?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
              userExams?:  {
                __typename: "ModelUserExamConnection",
                items:  Array< {
                  __typename: "UserExam",
                  id: string,
                  userId: string,
                  status: UserExamStatus,
                  tenantCode: string,
                  groupExamId: string,
                  groupId: string,
                  startedAt?: string | null,
                  finishedAt?: string | null,
                  createdAt: string,
                  updatedAt: string,
                } | null >,
                nextToken?: string | null,
              } | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateGroupTrainingCourseMutationVariables = {
  input: UpdateGroupTrainingCourseInput,
  condition?: ModelGroupTrainingCourseConditionInput | null,
};

export type UpdateGroupTrainingCourseMutation = {
  updateGroupTrainingCourse?:  {
    __typename: "GroupTrainingCourse",
    id: string,
    groupTrainingId: string,
    courseId: string,
    courseVersion: number,
    courseName: string,
    contents:  Array< {
      __typename: "GroupTrainingCourseContent",
      id: string,
      name: string,
      requiredTime: number,
      type: ContentType,
      recommendedDateTime?: string | null,
      open: boolean,
      version: number,
    } >,
    groupId: string,
    tenantCode: string,
    color?: string | null,
    image?: string | null,
    fontColorOnImage?: CourseFontColorOnImage | null,
    displayName: string,
    index?: number | null,
    createdAt: string,
    updatedAt: string,
    groupExams?:  {
      __typename: "ModelGroupExamConnection",
      items:  Array< {
        __typename: "GroupExam",
        id: string,
        groupId: string,
        contentId: string,
        content:  {
          __typename: "GroupExamContent",
          id: string,
          version?: number | null,
          name: string,
          timeLimit?: number | null,
          requiredTime?: number | null,
          indexInCourse?: number | null,
          passingStandard?: number | null,
        },
        problemsJson: string,
        courseId: string,
        course:  {
          __typename: "GroupExamCourse",
          id: string,
          version: number,
          name: string,
          color?: string | null,
          image?: string | null,
          fontColorOnImage?: CourseFontColorOnImage | null,
        },
        scheduledStart: string,
        scheduledFinish?: string | null,
        createdBy?: string | null,
        finishedAt?: string | null,
        visibilityLevel?: ExamResultVisibilityLevel | null,
        tenantCode: string,
        times: number,
        groupTrainingCourseId: string,
        problemHeaders?:  Array< {
          __typename: "ProblemHeader",
          id: string,
          body: string,
          createdAt: string,
        } > | null,
        timeLimit?: number | null,
        passingStandard?: number | null,
        userIdsToBeTested?: Array< string > | null,
        createdAt: string,
        updatedAt: string,
        userExams?:  {
          __typename: "ModelUserExamConnection",
          items:  Array< {
            __typename: "UserExam",
            id: string,
            userId: string,
            status: UserExamStatus,
            tenantCode: string,
            groupExamId: string,
            groupId: string,
            startedAt?: string | null,
            finishedAt?: string | null,
            createdAt: string,
            updatedAt: string,
            groupExam?:  {
              __typename: "GroupExam",
              id: string,
              groupId: string,
              contentId: string,
              content:  {
                __typename: "GroupExamContent",
                id: string,
                version?: number | null,
                name: string,
                timeLimit?: number | null,
                requiredTime?: number | null,
                indexInCourse?: number | null,
                passingStandard?: number | null,
              },
              problemsJson: string,
              courseId: string,
              course:  {
                __typename: "GroupExamCourse",
                id: string,
                version: number,
                name: string,
                color?: string | null,
                image?: string | null,
                fontColorOnImage?: CourseFontColorOnImage | null,
              },
              scheduledStart: string,
              scheduledFinish?: string | null,
              createdBy?: string | null,
              finishedAt?: string | null,
              visibilityLevel?: ExamResultVisibilityLevel | null,
              tenantCode: string,
              times: number,
              groupTrainingCourseId: string,
              problemHeaders?:  Array< {
                __typename: "ProblemHeader",
                id: string,
                body: string,
                createdAt: string,
              } > | null,
              timeLimit?: number | null,
              passingStandard?: number | null,
              userIdsToBeTested?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
              userExams?:  {
                __typename: "ModelUserExamConnection",
                items:  Array< {
                  __typename: "UserExam",
                  id: string,
                  userId: string,
                  status: UserExamStatus,
                  tenantCode: string,
                  groupExamId: string,
                  groupId: string,
                  startedAt?: string | null,
                  finishedAt?: string | null,
                  createdAt: string,
                  updatedAt: string,
                } | null >,
                nextToken?: string | null,
              } | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteGroupTrainingCourseMutationVariables = {
  input: DeleteGroupTrainingCourseInput,
  condition?: ModelGroupTrainingCourseConditionInput | null,
};

export type DeleteGroupTrainingCourseMutation = {
  deleteGroupTrainingCourse?:  {
    __typename: "GroupTrainingCourse",
    id: string,
    groupTrainingId: string,
    courseId: string,
    courseVersion: number,
    courseName: string,
    contents:  Array< {
      __typename: "GroupTrainingCourseContent",
      id: string,
      name: string,
      requiredTime: number,
      type: ContentType,
      recommendedDateTime?: string | null,
      open: boolean,
      version: number,
    } >,
    groupId: string,
    tenantCode: string,
    color?: string | null,
    image?: string | null,
    fontColorOnImage?: CourseFontColorOnImage | null,
    displayName: string,
    index?: number | null,
    createdAt: string,
    updatedAt: string,
    groupExams?:  {
      __typename: "ModelGroupExamConnection",
      items:  Array< {
        __typename: "GroupExam",
        id: string,
        groupId: string,
        contentId: string,
        content:  {
          __typename: "GroupExamContent",
          id: string,
          version?: number | null,
          name: string,
          timeLimit?: number | null,
          requiredTime?: number | null,
          indexInCourse?: number | null,
          passingStandard?: number | null,
        },
        problemsJson: string,
        courseId: string,
        course:  {
          __typename: "GroupExamCourse",
          id: string,
          version: number,
          name: string,
          color?: string | null,
          image?: string | null,
          fontColorOnImage?: CourseFontColorOnImage | null,
        },
        scheduledStart: string,
        scheduledFinish?: string | null,
        createdBy?: string | null,
        finishedAt?: string | null,
        visibilityLevel?: ExamResultVisibilityLevel | null,
        tenantCode: string,
        times: number,
        groupTrainingCourseId: string,
        problemHeaders?:  Array< {
          __typename: "ProblemHeader",
          id: string,
          body: string,
          createdAt: string,
        } > | null,
        timeLimit?: number | null,
        passingStandard?: number | null,
        userIdsToBeTested?: Array< string > | null,
        createdAt: string,
        updatedAt: string,
        userExams?:  {
          __typename: "ModelUserExamConnection",
          items:  Array< {
            __typename: "UserExam",
            id: string,
            userId: string,
            status: UserExamStatus,
            tenantCode: string,
            groupExamId: string,
            groupId: string,
            startedAt?: string | null,
            finishedAt?: string | null,
            createdAt: string,
            updatedAt: string,
            groupExam?:  {
              __typename: "GroupExam",
              id: string,
              groupId: string,
              contentId: string,
              content:  {
                __typename: "GroupExamContent",
                id: string,
                version?: number | null,
                name: string,
                timeLimit?: number | null,
                requiredTime?: number | null,
                indexInCourse?: number | null,
                passingStandard?: number | null,
              },
              problemsJson: string,
              courseId: string,
              course:  {
                __typename: "GroupExamCourse",
                id: string,
                version: number,
                name: string,
                color?: string | null,
                image?: string | null,
                fontColorOnImage?: CourseFontColorOnImage | null,
              },
              scheduledStart: string,
              scheduledFinish?: string | null,
              createdBy?: string | null,
              finishedAt?: string | null,
              visibilityLevel?: ExamResultVisibilityLevel | null,
              tenantCode: string,
              times: number,
              groupTrainingCourseId: string,
              problemHeaders?:  Array< {
                __typename: "ProblemHeader",
                id: string,
                body: string,
                createdAt: string,
              } > | null,
              timeLimit?: number | null,
              passingStandard?: number | null,
              userIdsToBeTested?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
              userExams?:  {
                __typename: "ModelUserExamConnection",
                items:  Array< {
                  __typename: "UserExam",
                  id: string,
                  userId: string,
                  status: UserExamStatus,
                  tenantCode: string,
                  groupExamId: string,
                  groupId: string,
                  startedAt?: string | null,
                  finishedAt?: string | null,
                  createdAt: string,
                  updatedAt: string,
                } | null >,
                nextToken?: string | null,
              } | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateScheduleTagMutationVariables = {
  input: CreateScheduleTagInput,
  condition?: ModelScheduleTagConditionInput | null,
};

export type CreateScheduleTagMutation = {
  createScheduleTag?:  {
    __typename: "ScheduleTag",
    id: string,
    color: string,
    text: string,
    groupId: string,
    tenantCode: string,
    createdAt: string,
    updatedAt: string,
    schedules?:  {
      __typename: "ModelScheduleScheduleTagConnection",
      items:  Array< {
        __typename: "ScheduleScheduleTag",
        id: string,
        scheduleId: string,
        scheduleTagId: string,
        scheduleTag:  {
          __typename: "ScheduleTag",
          id: string,
          color: string,
          text: string,
          groupId: string,
          tenantCode: string,
          createdAt: string,
          updatedAt: string,
          schedules?:  {
            __typename: "ModelScheduleScheduleTagConnection",
            items:  Array< {
              __typename: "ScheduleScheduleTag",
              id: string,
              scheduleId: string,
              scheduleTagId: string,
              scheduleTag:  {
                __typename: "ScheduleTag",
                id: string,
                color: string,
                text: string,
                groupId: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                schedules?:  {
                  __typename: "ModelScheduleScheduleTagConnection",
                  nextToken?: string | null,
                } | null,
              },
              tenantCode: string,
              createdAt: string,
              updatedAt: string,
              schedule:  {
                __typename: "Schedule",
                id: string,
                groupId: string,
                start: string,
                end?: string | null,
                title: string,
                body?: string | null,
                tenantCode: string,
                createdBy?: string | null,
                updatedBy?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                tags?:  {
                  __typename: "ModelScheduleScheduleTagConnection",
                  nextToken?: string | null,
                } | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        tenantCode: string,
        createdAt: string,
        updatedAt: string,
        schedule:  {
          __typename: "Schedule",
          id: string,
          groupId: string,
          start: string,
          end?: string | null,
          title: string,
          body?: string | null,
          tenantCode: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          tags?:  {
            __typename: "ModelScheduleScheduleTagConnection",
            items:  Array< {
              __typename: "ScheduleScheduleTag",
              id: string,
              scheduleId: string,
              scheduleTagId: string,
              scheduleTag:  {
                __typename: "ScheduleTag",
                id: string,
                color: string,
                text: string,
                groupId: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                schedules?:  {
                  __typename: "ModelScheduleScheduleTagConnection",
                  nextToken?: string | null,
                } | null,
              },
              tenantCode: string,
              createdAt: string,
              updatedAt: string,
              schedule:  {
                __typename: "Schedule",
                id: string,
                groupId: string,
                start: string,
                end?: string | null,
                title: string,
                body?: string | null,
                tenantCode: string,
                createdBy?: string | null,
                updatedBy?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                tags?:  {
                  __typename: "ModelScheduleScheduleTagConnection",
                  nextToken?: string | null,
                } | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateScheduleTagMutationVariables = {
  input: UpdateScheduleTagInput,
  condition?: ModelScheduleTagConditionInput | null,
};

export type UpdateScheduleTagMutation = {
  updateScheduleTag?:  {
    __typename: "ScheduleTag",
    id: string,
    color: string,
    text: string,
    groupId: string,
    tenantCode: string,
    createdAt: string,
    updatedAt: string,
    schedules?:  {
      __typename: "ModelScheduleScheduleTagConnection",
      items:  Array< {
        __typename: "ScheduleScheduleTag",
        id: string,
        scheduleId: string,
        scheduleTagId: string,
        scheduleTag:  {
          __typename: "ScheduleTag",
          id: string,
          color: string,
          text: string,
          groupId: string,
          tenantCode: string,
          createdAt: string,
          updatedAt: string,
          schedules?:  {
            __typename: "ModelScheduleScheduleTagConnection",
            items:  Array< {
              __typename: "ScheduleScheduleTag",
              id: string,
              scheduleId: string,
              scheduleTagId: string,
              scheduleTag:  {
                __typename: "ScheduleTag",
                id: string,
                color: string,
                text: string,
                groupId: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                schedules?:  {
                  __typename: "ModelScheduleScheduleTagConnection",
                  nextToken?: string | null,
                } | null,
              },
              tenantCode: string,
              createdAt: string,
              updatedAt: string,
              schedule:  {
                __typename: "Schedule",
                id: string,
                groupId: string,
                start: string,
                end?: string | null,
                title: string,
                body?: string | null,
                tenantCode: string,
                createdBy?: string | null,
                updatedBy?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                tags?:  {
                  __typename: "ModelScheduleScheduleTagConnection",
                  nextToken?: string | null,
                } | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        tenantCode: string,
        createdAt: string,
        updatedAt: string,
        schedule:  {
          __typename: "Schedule",
          id: string,
          groupId: string,
          start: string,
          end?: string | null,
          title: string,
          body?: string | null,
          tenantCode: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          tags?:  {
            __typename: "ModelScheduleScheduleTagConnection",
            items:  Array< {
              __typename: "ScheduleScheduleTag",
              id: string,
              scheduleId: string,
              scheduleTagId: string,
              scheduleTag:  {
                __typename: "ScheduleTag",
                id: string,
                color: string,
                text: string,
                groupId: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                schedules?:  {
                  __typename: "ModelScheduleScheduleTagConnection",
                  nextToken?: string | null,
                } | null,
              },
              tenantCode: string,
              createdAt: string,
              updatedAt: string,
              schedule:  {
                __typename: "Schedule",
                id: string,
                groupId: string,
                start: string,
                end?: string | null,
                title: string,
                body?: string | null,
                tenantCode: string,
                createdBy?: string | null,
                updatedBy?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                tags?:  {
                  __typename: "ModelScheduleScheduleTagConnection",
                  nextToken?: string | null,
                } | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteScheduleTagMutationVariables = {
  input: DeleteScheduleTagInput,
  condition?: ModelScheduleTagConditionInput | null,
};

export type DeleteScheduleTagMutation = {
  deleteScheduleTag?:  {
    __typename: "ScheduleTag",
    id: string,
    color: string,
    text: string,
    groupId: string,
    tenantCode: string,
    createdAt: string,
    updatedAt: string,
    schedules?:  {
      __typename: "ModelScheduleScheduleTagConnection",
      items:  Array< {
        __typename: "ScheduleScheduleTag",
        id: string,
        scheduleId: string,
        scheduleTagId: string,
        scheduleTag:  {
          __typename: "ScheduleTag",
          id: string,
          color: string,
          text: string,
          groupId: string,
          tenantCode: string,
          createdAt: string,
          updatedAt: string,
          schedules?:  {
            __typename: "ModelScheduleScheduleTagConnection",
            items:  Array< {
              __typename: "ScheduleScheduleTag",
              id: string,
              scheduleId: string,
              scheduleTagId: string,
              scheduleTag:  {
                __typename: "ScheduleTag",
                id: string,
                color: string,
                text: string,
                groupId: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                schedules?:  {
                  __typename: "ModelScheduleScheduleTagConnection",
                  nextToken?: string | null,
                } | null,
              },
              tenantCode: string,
              createdAt: string,
              updatedAt: string,
              schedule:  {
                __typename: "Schedule",
                id: string,
                groupId: string,
                start: string,
                end?: string | null,
                title: string,
                body?: string | null,
                tenantCode: string,
                createdBy?: string | null,
                updatedBy?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                tags?:  {
                  __typename: "ModelScheduleScheduleTagConnection",
                  nextToken?: string | null,
                } | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        tenantCode: string,
        createdAt: string,
        updatedAt: string,
        schedule:  {
          __typename: "Schedule",
          id: string,
          groupId: string,
          start: string,
          end?: string | null,
          title: string,
          body?: string | null,
          tenantCode: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          tags?:  {
            __typename: "ModelScheduleScheduleTagConnection",
            items:  Array< {
              __typename: "ScheduleScheduleTag",
              id: string,
              scheduleId: string,
              scheduleTagId: string,
              scheduleTag:  {
                __typename: "ScheduleTag",
                id: string,
                color: string,
                text: string,
                groupId: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                schedules?:  {
                  __typename: "ModelScheduleScheduleTagConnection",
                  nextToken?: string | null,
                } | null,
              },
              tenantCode: string,
              createdAt: string,
              updatedAt: string,
              schedule:  {
                __typename: "Schedule",
                id: string,
                groupId: string,
                start: string,
                end?: string | null,
                title: string,
                body?: string | null,
                tenantCode: string,
                createdBy?: string | null,
                updatedBy?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                tags?:  {
                  __typename: "ModelScheduleScheduleTagConnection",
                  nextToken?: string | null,
                } | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateScheduleScheduleTagMutationVariables = {
  input: CreateScheduleScheduleTagInput,
  condition?: ModelScheduleScheduleTagConditionInput | null,
};

export type CreateScheduleScheduleTagMutation = {
  createScheduleScheduleTag?:  {
    __typename: "ScheduleScheduleTag",
    id: string,
    scheduleId: string,
    scheduleTagId: string,
    scheduleTag:  {
      __typename: "ScheduleTag",
      id: string,
      color: string,
      text: string,
      groupId: string,
      tenantCode: string,
      createdAt: string,
      updatedAt: string,
      schedules?:  {
        __typename: "ModelScheduleScheduleTagConnection",
        items:  Array< {
          __typename: "ScheduleScheduleTag",
          id: string,
          scheduleId: string,
          scheduleTagId: string,
          scheduleTag:  {
            __typename: "ScheduleTag",
            id: string,
            color: string,
            text: string,
            groupId: string,
            tenantCode: string,
            createdAt: string,
            updatedAt: string,
            schedules?:  {
              __typename: "ModelScheduleScheduleTagConnection",
              items:  Array< {
                __typename: "ScheduleScheduleTag",
                id: string,
                scheduleId: string,
                scheduleTagId: string,
                scheduleTag:  {
                  __typename: "ScheduleTag",
                  id: string,
                  color: string,
                  text: string,
                  groupId: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                schedule:  {
                  __typename: "Schedule",
                  id: string,
                  groupId: string,
                  start: string,
                  end?: string | null,
                  title: string,
                  body?: string | null,
                  tenantCode: string,
                  createdBy?: string | null,
                  updatedBy?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          tenantCode: string,
          createdAt: string,
          updatedAt: string,
          schedule:  {
            __typename: "Schedule",
            id: string,
            groupId: string,
            start: string,
            end?: string | null,
            title: string,
            body?: string | null,
            tenantCode: string,
            createdBy?: string | null,
            updatedBy?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            tags?:  {
              __typename: "ModelScheduleScheduleTagConnection",
              items:  Array< {
                __typename: "ScheduleScheduleTag",
                id: string,
                scheduleId: string,
                scheduleTagId: string,
                scheduleTag:  {
                  __typename: "ScheduleTag",
                  id: string,
                  color: string,
                  text: string,
                  groupId: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                schedule:  {
                  __typename: "Schedule",
                  id: string,
                  groupId: string,
                  start: string,
                  end?: string | null,
                  title: string,
                  body?: string | null,
                  tenantCode: string,
                  createdBy?: string | null,
                  updatedBy?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    tenantCode: string,
    createdAt: string,
    updatedAt: string,
    schedule:  {
      __typename: "Schedule",
      id: string,
      groupId: string,
      start: string,
      end?: string | null,
      title: string,
      body?: string | null,
      tenantCode: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      tags?:  {
        __typename: "ModelScheduleScheduleTagConnection",
        items:  Array< {
          __typename: "ScheduleScheduleTag",
          id: string,
          scheduleId: string,
          scheduleTagId: string,
          scheduleTag:  {
            __typename: "ScheduleTag",
            id: string,
            color: string,
            text: string,
            groupId: string,
            tenantCode: string,
            createdAt: string,
            updatedAt: string,
            schedules?:  {
              __typename: "ModelScheduleScheduleTagConnection",
              items:  Array< {
                __typename: "ScheduleScheduleTag",
                id: string,
                scheduleId: string,
                scheduleTagId: string,
                scheduleTag:  {
                  __typename: "ScheduleTag",
                  id: string,
                  color: string,
                  text: string,
                  groupId: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                schedule:  {
                  __typename: "Schedule",
                  id: string,
                  groupId: string,
                  start: string,
                  end?: string | null,
                  title: string,
                  body?: string | null,
                  tenantCode: string,
                  createdBy?: string | null,
                  updatedBy?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          tenantCode: string,
          createdAt: string,
          updatedAt: string,
          schedule:  {
            __typename: "Schedule",
            id: string,
            groupId: string,
            start: string,
            end?: string | null,
            title: string,
            body?: string | null,
            tenantCode: string,
            createdBy?: string | null,
            updatedBy?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            tags?:  {
              __typename: "ModelScheduleScheduleTagConnection",
              items:  Array< {
                __typename: "ScheduleScheduleTag",
                id: string,
                scheduleId: string,
                scheduleTagId: string,
                scheduleTag:  {
                  __typename: "ScheduleTag",
                  id: string,
                  color: string,
                  text: string,
                  groupId: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                schedule:  {
                  __typename: "Schedule",
                  id: string,
                  groupId: string,
                  start: string,
                  end?: string | null,
                  title: string,
                  body?: string | null,
                  tenantCode: string,
                  createdBy?: string | null,
                  updatedBy?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type UpdateScheduleScheduleTagMutationVariables = {
  input: UpdateScheduleScheduleTagInput,
  condition?: ModelScheduleScheduleTagConditionInput | null,
};

export type UpdateScheduleScheduleTagMutation = {
  updateScheduleScheduleTag?:  {
    __typename: "ScheduleScheduleTag",
    id: string,
    scheduleId: string,
    scheduleTagId: string,
    scheduleTag:  {
      __typename: "ScheduleTag",
      id: string,
      color: string,
      text: string,
      groupId: string,
      tenantCode: string,
      createdAt: string,
      updatedAt: string,
      schedules?:  {
        __typename: "ModelScheduleScheduleTagConnection",
        items:  Array< {
          __typename: "ScheduleScheduleTag",
          id: string,
          scheduleId: string,
          scheduleTagId: string,
          scheduleTag:  {
            __typename: "ScheduleTag",
            id: string,
            color: string,
            text: string,
            groupId: string,
            tenantCode: string,
            createdAt: string,
            updatedAt: string,
            schedules?:  {
              __typename: "ModelScheduleScheduleTagConnection",
              items:  Array< {
                __typename: "ScheduleScheduleTag",
                id: string,
                scheduleId: string,
                scheduleTagId: string,
                scheduleTag:  {
                  __typename: "ScheduleTag",
                  id: string,
                  color: string,
                  text: string,
                  groupId: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                schedule:  {
                  __typename: "Schedule",
                  id: string,
                  groupId: string,
                  start: string,
                  end?: string | null,
                  title: string,
                  body?: string | null,
                  tenantCode: string,
                  createdBy?: string | null,
                  updatedBy?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          tenantCode: string,
          createdAt: string,
          updatedAt: string,
          schedule:  {
            __typename: "Schedule",
            id: string,
            groupId: string,
            start: string,
            end?: string | null,
            title: string,
            body?: string | null,
            tenantCode: string,
            createdBy?: string | null,
            updatedBy?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            tags?:  {
              __typename: "ModelScheduleScheduleTagConnection",
              items:  Array< {
                __typename: "ScheduleScheduleTag",
                id: string,
                scheduleId: string,
                scheduleTagId: string,
                scheduleTag:  {
                  __typename: "ScheduleTag",
                  id: string,
                  color: string,
                  text: string,
                  groupId: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                schedule:  {
                  __typename: "Schedule",
                  id: string,
                  groupId: string,
                  start: string,
                  end?: string | null,
                  title: string,
                  body?: string | null,
                  tenantCode: string,
                  createdBy?: string | null,
                  updatedBy?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    tenantCode: string,
    createdAt: string,
    updatedAt: string,
    schedule:  {
      __typename: "Schedule",
      id: string,
      groupId: string,
      start: string,
      end?: string | null,
      title: string,
      body?: string | null,
      tenantCode: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      tags?:  {
        __typename: "ModelScheduleScheduleTagConnection",
        items:  Array< {
          __typename: "ScheduleScheduleTag",
          id: string,
          scheduleId: string,
          scheduleTagId: string,
          scheduleTag:  {
            __typename: "ScheduleTag",
            id: string,
            color: string,
            text: string,
            groupId: string,
            tenantCode: string,
            createdAt: string,
            updatedAt: string,
            schedules?:  {
              __typename: "ModelScheduleScheduleTagConnection",
              items:  Array< {
                __typename: "ScheduleScheduleTag",
                id: string,
                scheduleId: string,
                scheduleTagId: string,
                scheduleTag:  {
                  __typename: "ScheduleTag",
                  id: string,
                  color: string,
                  text: string,
                  groupId: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                schedule:  {
                  __typename: "Schedule",
                  id: string,
                  groupId: string,
                  start: string,
                  end?: string | null,
                  title: string,
                  body?: string | null,
                  tenantCode: string,
                  createdBy?: string | null,
                  updatedBy?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          tenantCode: string,
          createdAt: string,
          updatedAt: string,
          schedule:  {
            __typename: "Schedule",
            id: string,
            groupId: string,
            start: string,
            end?: string | null,
            title: string,
            body?: string | null,
            tenantCode: string,
            createdBy?: string | null,
            updatedBy?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            tags?:  {
              __typename: "ModelScheduleScheduleTagConnection",
              items:  Array< {
                __typename: "ScheduleScheduleTag",
                id: string,
                scheduleId: string,
                scheduleTagId: string,
                scheduleTag:  {
                  __typename: "ScheduleTag",
                  id: string,
                  color: string,
                  text: string,
                  groupId: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                schedule:  {
                  __typename: "Schedule",
                  id: string,
                  groupId: string,
                  start: string,
                  end?: string | null,
                  title: string,
                  body?: string | null,
                  tenantCode: string,
                  createdBy?: string | null,
                  updatedBy?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type CreateScheduleMutationVariables = {
  input: CreateScheduleInput,
  condition?: ModelScheduleConditionInput | null,
};

export type CreateScheduleMutation = {
  createSchedule?:  {
    __typename: "Schedule",
    id: string,
    groupId: string,
    start: string,
    end?: string | null,
    title: string,
    body?: string | null,
    tenantCode: string,
    createdBy?: string | null,
    updatedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    tags?:  {
      __typename: "ModelScheduleScheduleTagConnection",
      items:  Array< {
        __typename: "ScheduleScheduleTag",
        id: string,
        scheduleId: string,
        scheduleTagId: string,
        scheduleTag:  {
          __typename: "ScheduleTag",
          id: string,
          color: string,
          text: string,
          groupId: string,
          tenantCode: string,
          createdAt: string,
          updatedAt: string,
          schedules?:  {
            __typename: "ModelScheduleScheduleTagConnection",
            items:  Array< {
              __typename: "ScheduleScheduleTag",
              id: string,
              scheduleId: string,
              scheduleTagId: string,
              scheduleTag:  {
                __typename: "ScheduleTag",
                id: string,
                color: string,
                text: string,
                groupId: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                schedules?:  {
                  __typename: "ModelScheduleScheduleTagConnection",
                  nextToken?: string | null,
                } | null,
              },
              tenantCode: string,
              createdAt: string,
              updatedAt: string,
              schedule:  {
                __typename: "Schedule",
                id: string,
                groupId: string,
                start: string,
                end?: string | null,
                title: string,
                body?: string | null,
                tenantCode: string,
                createdBy?: string | null,
                updatedBy?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                tags?:  {
                  __typename: "ModelScheduleScheduleTagConnection",
                  nextToken?: string | null,
                } | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        tenantCode: string,
        createdAt: string,
        updatedAt: string,
        schedule:  {
          __typename: "Schedule",
          id: string,
          groupId: string,
          start: string,
          end?: string | null,
          title: string,
          body?: string | null,
          tenantCode: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          tags?:  {
            __typename: "ModelScheduleScheduleTagConnection",
            items:  Array< {
              __typename: "ScheduleScheduleTag",
              id: string,
              scheduleId: string,
              scheduleTagId: string,
              scheduleTag:  {
                __typename: "ScheduleTag",
                id: string,
                color: string,
                text: string,
                groupId: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                schedules?:  {
                  __typename: "ModelScheduleScheduleTagConnection",
                  nextToken?: string | null,
                } | null,
              },
              tenantCode: string,
              createdAt: string,
              updatedAt: string,
              schedule:  {
                __typename: "Schedule",
                id: string,
                groupId: string,
                start: string,
                end?: string | null,
                title: string,
                body?: string | null,
                tenantCode: string,
                createdBy?: string | null,
                updatedBy?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                tags?:  {
                  __typename: "ModelScheduleScheduleTagConnection",
                  nextToken?: string | null,
                } | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateScheduleMutationVariables = {
  input: UpdateScheduleInput,
  condition?: ModelScheduleConditionInput | null,
};

export type UpdateScheduleMutation = {
  updateSchedule?:  {
    __typename: "Schedule",
    id: string,
    groupId: string,
    start: string,
    end?: string | null,
    title: string,
    body?: string | null,
    tenantCode: string,
    createdBy?: string | null,
    updatedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    tags?:  {
      __typename: "ModelScheduleScheduleTagConnection",
      items:  Array< {
        __typename: "ScheduleScheduleTag",
        id: string,
        scheduleId: string,
        scheduleTagId: string,
        scheduleTag:  {
          __typename: "ScheduleTag",
          id: string,
          color: string,
          text: string,
          groupId: string,
          tenantCode: string,
          createdAt: string,
          updatedAt: string,
          schedules?:  {
            __typename: "ModelScheduleScheduleTagConnection",
            items:  Array< {
              __typename: "ScheduleScheduleTag",
              id: string,
              scheduleId: string,
              scheduleTagId: string,
              scheduleTag:  {
                __typename: "ScheduleTag",
                id: string,
                color: string,
                text: string,
                groupId: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                schedules?:  {
                  __typename: "ModelScheduleScheduleTagConnection",
                  nextToken?: string | null,
                } | null,
              },
              tenantCode: string,
              createdAt: string,
              updatedAt: string,
              schedule:  {
                __typename: "Schedule",
                id: string,
                groupId: string,
                start: string,
                end?: string | null,
                title: string,
                body?: string | null,
                tenantCode: string,
                createdBy?: string | null,
                updatedBy?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                tags?:  {
                  __typename: "ModelScheduleScheduleTagConnection",
                  nextToken?: string | null,
                } | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        tenantCode: string,
        createdAt: string,
        updatedAt: string,
        schedule:  {
          __typename: "Schedule",
          id: string,
          groupId: string,
          start: string,
          end?: string | null,
          title: string,
          body?: string | null,
          tenantCode: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          tags?:  {
            __typename: "ModelScheduleScheduleTagConnection",
            items:  Array< {
              __typename: "ScheduleScheduleTag",
              id: string,
              scheduleId: string,
              scheduleTagId: string,
              scheduleTag:  {
                __typename: "ScheduleTag",
                id: string,
                color: string,
                text: string,
                groupId: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                schedules?:  {
                  __typename: "ModelScheduleScheduleTagConnection",
                  nextToken?: string | null,
                } | null,
              },
              tenantCode: string,
              createdAt: string,
              updatedAt: string,
              schedule:  {
                __typename: "Schedule",
                id: string,
                groupId: string,
                start: string,
                end?: string | null,
                title: string,
                body?: string | null,
                tenantCode: string,
                createdBy?: string | null,
                updatedBy?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                tags?:  {
                  __typename: "ModelScheduleScheduleTagConnection",
                  nextToken?: string | null,
                } | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteScheduleMutationVariables = {
  input: DeleteScheduleInput,
  condition?: ModelScheduleConditionInput | null,
};

export type DeleteScheduleMutation = {
  deleteSchedule?:  {
    __typename: "Schedule",
    id: string,
    groupId: string,
    start: string,
    end?: string | null,
    title: string,
    body?: string | null,
    tenantCode: string,
    createdBy?: string | null,
    updatedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    tags?:  {
      __typename: "ModelScheduleScheduleTagConnection",
      items:  Array< {
        __typename: "ScheduleScheduleTag",
        id: string,
        scheduleId: string,
        scheduleTagId: string,
        scheduleTag:  {
          __typename: "ScheduleTag",
          id: string,
          color: string,
          text: string,
          groupId: string,
          tenantCode: string,
          createdAt: string,
          updatedAt: string,
          schedules?:  {
            __typename: "ModelScheduleScheduleTagConnection",
            items:  Array< {
              __typename: "ScheduleScheduleTag",
              id: string,
              scheduleId: string,
              scheduleTagId: string,
              scheduleTag:  {
                __typename: "ScheduleTag",
                id: string,
                color: string,
                text: string,
                groupId: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                schedules?:  {
                  __typename: "ModelScheduleScheduleTagConnection",
                  nextToken?: string | null,
                } | null,
              },
              tenantCode: string,
              createdAt: string,
              updatedAt: string,
              schedule:  {
                __typename: "Schedule",
                id: string,
                groupId: string,
                start: string,
                end?: string | null,
                title: string,
                body?: string | null,
                tenantCode: string,
                createdBy?: string | null,
                updatedBy?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                tags?:  {
                  __typename: "ModelScheduleScheduleTagConnection",
                  nextToken?: string | null,
                } | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        tenantCode: string,
        createdAt: string,
        updatedAt: string,
        schedule:  {
          __typename: "Schedule",
          id: string,
          groupId: string,
          start: string,
          end?: string | null,
          title: string,
          body?: string | null,
          tenantCode: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          tags?:  {
            __typename: "ModelScheduleScheduleTagConnection",
            items:  Array< {
              __typename: "ScheduleScheduleTag",
              id: string,
              scheduleId: string,
              scheduleTagId: string,
              scheduleTag:  {
                __typename: "ScheduleTag",
                id: string,
                color: string,
                text: string,
                groupId: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                schedules?:  {
                  __typename: "ModelScheduleScheduleTagConnection",
                  nextToken?: string | null,
                } | null,
              },
              tenantCode: string,
              createdAt: string,
              updatedAt: string,
              schedule:  {
                __typename: "Schedule",
                id: string,
                groupId: string,
                start: string,
                end?: string | null,
                title: string,
                body?: string | null,
                tenantCode: string,
                createdBy?: string | null,
                updatedBy?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                tags?:  {
                  __typename: "ModelScheduleScheduleTagConnection",
                  nextToken?: string | null,
                } | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateCommentMutationVariables = {
  input: CreateCommentInput,
  condition?: ModelCommentConditionInput | null,
};

export type CreateCommentMutation = {
  createComment?:  {
    __typename: "Comment",
    id: string,
    questionId: string,
    body: string,
    createdBy: string,
    createdAt?: string | null,
    editedBy?: string | null,
    editedAt?: string | null,
    tenantCode: string,
    groupId: string,
    updatedAt: string,
  } | null,
};

export type UpdateCommentMutationVariables = {
  input: UpdateCommentInput,
  condition?: ModelCommentConditionInput | null,
};

export type UpdateCommentMutation = {
  updateComment?:  {
    __typename: "Comment",
    id: string,
    questionId: string,
    body: string,
    createdBy: string,
    createdAt?: string | null,
    editedBy?: string | null,
    editedAt?: string | null,
    tenantCode: string,
    groupId: string,
    updatedAt: string,
  } | null,
};

export type DeleteCommentMutationVariables = {
  input: DeleteCommentInput,
  condition?: ModelCommentConditionInput | null,
};

export type DeleteCommentMutation = {
  deleteComment?:  {
    __typename: "Comment",
    id: string,
    questionId: string,
    body: string,
    createdBy: string,
    createdAt?: string | null,
    editedBy?: string | null,
    editedAt?: string | null,
    tenantCode: string,
    groupId: string,
    updatedAt: string,
  } | null,
};

export type CreateQuestionMutationVariables = {
  input: CreateQuestionInput,
  condition?: ModelQuestionConditionInput | null,
};

export type CreateQuestionMutation = {
  createQuestion?:  {
    __typename: "Question",
    id: string,
    createdBy: string,
    resolved?: boolean | null,
    resolvedAt?: string | null,
    resolvedBy?: string | null,
    groupId: string,
    title: string,
    referTo?:  {
      __typename: "ContentReference",
      contentId: string,
      contentVersion?: number | null,
      options?:  {
        __typename: "ContentReferenceOptions",
        text?:  {
          __typename: "TextContentReferenceOptions",
          selectionJson: string,
        } | null,
        workbook?:  {
          __typename: "WorkbookReferenceOptions",
          problemIndex: number,
          selectionJson?: string | null,
        } | null,
      } | null,
    } | null,
    tenantCode: string,
    createdAt?: string | null,
    assignees?: Array< string > | null,
    updatedAt: string,
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        id: string,
        questionId: string,
        body: string,
        createdBy: string,
        createdAt?: string | null,
        editedBy?: string | null,
        editedAt?: string | null,
        tenantCode: string,
        groupId: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateQuestionMutationVariables = {
  input: UpdateQuestionInput,
  condition?: ModelQuestionConditionInput | null,
};

export type UpdateQuestionMutation = {
  updateQuestion?:  {
    __typename: "Question",
    id: string,
    createdBy: string,
    resolved?: boolean | null,
    resolvedAt?: string | null,
    resolvedBy?: string | null,
    groupId: string,
    title: string,
    referTo?:  {
      __typename: "ContentReference",
      contentId: string,
      contentVersion?: number | null,
      options?:  {
        __typename: "ContentReferenceOptions",
        text?:  {
          __typename: "TextContentReferenceOptions",
          selectionJson: string,
        } | null,
        workbook?:  {
          __typename: "WorkbookReferenceOptions",
          problemIndex: number,
          selectionJson?: string | null,
        } | null,
      } | null,
    } | null,
    tenantCode: string,
    createdAt?: string | null,
    assignees?: Array< string > | null,
    updatedAt: string,
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        id: string,
        questionId: string,
        body: string,
        createdBy: string,
        createdAt?: string | null,
        editedBy?: string | null,
        editedAt?: string | null,
        tenantCode: string,
        groupId: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteQuestionMutationVariables = {
  input: DeleteQuestionInput,
  condition?: ModelQuestionConditionInput | null,
};

export type DeleteQuestionMutation = {
  deleteQuestion?:  {
    __typename: "Question",
    id: string,
    createdBy: string,
    resolved?: boolean | null,
    resolvedAt?: string | null,
    resolvedBy?: string | null,
    groupId: string,
    title: string,
    referTo?:  {
      __typename: "ContentReference",
      contentId: string,
      contentVersion?: number | null,
      options?:  {
        __typename: "ContentReferenceOptions",
        text?:  {
          __typename: "TextContentReferenceOptions",
          selectionJson: string,
        } | null,
        workbook?:  {
          __typename: "WorkbookReferenceOptions",
          problemIndex: number,
          selectionJson?: string | null,
        } | null,
      } | null,
    } | null,
    tenantCode: string,
    createdAt?: string | null,
    assignees?: Array< string > | null,
    updatedAt: string,
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        id: string,
        questionId: string,
        body: string,
        createdBy: string,
        createdAt?: string | null,
        editedBy?: string | null,
        editedAt?: string | null,
        tenantCode: string,
        groupId: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteContentLearningMutationVariables = {
  input: DeleteContentLearningInput,
  condition?: ModelContentLearningConditionInput | null,
};

export type DeleteContentLearningMutation = {
  deleteContentLearning?:  {
    __typename: "ContentLearning",
    groupId: string,
    courseId: string,
    contentId: string,
    contentType: ContentType,
    userId: string,
    status: LearningStatus,
    usageTime: number,
    lastStartedAt?: string | null,
    dataVersion: number,
    tenantCode: string,
    completedAt?: string | null,
    completedUsageTime?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateGroupExamMutationVariables = {
  input: CreateGroupExamInput,
  condition?: ModelGroupExamConditionInput | null,
};

export type CreateGroupExamMutation = {
  createGroupExam?:  {
    __typename: "GroupExam",
    id: string,
    groupId: string,
    contentId: string,
    content:  {
      __typename: "GroupExamContent",
      id: string,
      version?: number | null,
      name: string,
      timeLimit?: number | null,
      requiredTime?: number | null,
      indexInCourse?: number | null,
      passingStandard?: number | null,
    },
    problemsJson: string,
    courseId: string,
    course:  {
      __typename: "GroupExamCourse",
      id: string,
      version: number,
      name: string,
      color?: string | null,
      image?: string | null,
      fontColorOnImage?: CourseFontColorOnImage | null,
    },
    scheduledStart: string,
    scheduledFinish?: string | null,
    createdBy?: string | null,
    finishedAt?: string | null,
    visibilityLevel?: ExamResultVisibilityLevel | null,
    tenantCode: string,
    times: number,
    groupTrainingCourseId: string,
    problemHeaders?:  Array< {
      __typename: "ProblemHeader",
      id: string,
      body: string,
      createdAt: string,
    } > | null,
    timeLimit?: number | null,
    passingStandard?: number | null,
    userIdsToBeTested?: Array< string > | null,
    createdAt: string,
    updatedAt: string,
    userExams?:  {
      __typename: "ModelUserExamConnection",
      items:  Array< {
        __typename: "UserExam",
        id: string,
        userId: string,
        status: UserExamStatus,
        tenantCode: string,
        groupExamId: string,
        groupId: string,
        startedAt?: string | null,
        finishedAt?: string | null,
        createdAt: string,
        updatedAt: string,
        groupExam?:  {
          __typename: "GroupExam",
          id: string,
          groupId: string,
          contentId: string,
          content:  {
            __typename: "GroupExamContent",
            id: string,
            version?: number | null,
            name: string,
            timeLimit?: number | null,
            requiredTime?: number | null,
            indexInCourse?: number | null,
            passingStandard?: number | null,
          },
          problemsJson: string,
          courseId: string,
          course:  {
            __typename: "GroupExamCourse",
            id: string,
            version: number,
            name: string,
            color?: string | null,
            image?: string | null,
            fontColorOnImage?: CourseFontColorOnImage | null,
          },
          scheduledStart: string,
          scheduledFinish?: string | null,
          createdBy?: string | null,
          finishedAt?: string | null,
          visibilityLevel?: ExamResultVisibilityLevel | null,
          tenantCode: string,
          times: number,
          groupTrainingCourseId: string,
          problemHeaders?:  Array< {
            __typename: "ProblemHeader",
            id: string,
            body: string,
            createdAt: string,
          } > | null,
          timeLimit?: number | null,
          passingStandard?: number | null,
          userIdsToBeTested?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          userExams?:  {
            __typename: "ModelUserExamConnection",
            items:  Array< {
              __typename: "UserExam",
              id: string,
              userId: string,
              status: UserExamStatus,
              tenantCode: string,
              groupExamId: string,
              groupId: string,
              startedAt?: string | null,
              finishedAt?: string | null,
              createdAt: string,
              updatedAt: string,
              groupExam?:  {
                __typename: "GroupExam",
                id: string,
                groupId: string,
                contentId: string,
                content:  {
                  __typename: "GroupExamContent",
                  id: string,
                  version?: number | null,
                  name: string,
                  timeLimit?: number | null,
                  requiredTime?: number | null,
                  indexInCourse?: number | null,
                  passingStandard?: number | null,
                },
                problemsJson: string,
                courseId: string,
                course:  {
                  __typename: "GroupExamCourse",
                  id: string,
                  version: number,
                  name: string,
                  color?: string | null,
                  image?: string | null,
                  fontColorOnImage?: CourseFontColorOnImage | null,
                },
                scheduledStart: string,
                scheduledFinish?: string | null,
                createdBy?: string | null,
                finishedAt?: string | null,
                visibilityLevel?: ExamResultVisibilityLevel | null,
                tenantCode: string,
                times: number,
                groupTrainingCourseId: string,
                problemHeaders?:  Array< {
                  __typename: "ProblemHeader",
                  id: string,
                  body: string,
                  createdAt: string,
                } > | null,
                timeLimit?: number | null,
                passingStandard?: number | null,
                userIdsToBeTested?: Array< string > | null,
                createdAt: string,
                updatedAt: string,
                userExams?:  {
                  __typename: "ModelUserExamConnection",
                  nextToken?: string | null,
                } | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateGroupExamMutationVariables = {
  input: UpdateGroupExamInput,
  condition?: ModelGroupExamConditionInput | null,
};

export type UpdateGroupExamMutation = {
  updateGroupExam?:  {
    __typename: "GroupExam",
    id: string,
    groupId: string,
    contentId: string,
    content:  {
      __typename: "GroupExamContent",
      id: string,
      version?: number | null,
      name: string,
      timeLimit?: number | null,
      requiredTime?: number | null,
      indexInCourse?: number | null,
      passingStandard?: number | null,
    },
    problemsJson: string,
    courseId: string,
    course:  {
      __typename: "GroupExamCourse",
      id: string,
      version: number,
      name: string,
      color?: string | null,
      image?: string | null,
      fontColorOnImage?: CourseFontColorOnImage | null,
    },
    scheduledStart: string,
    scheduledFinish?: string | null,
    createdBy?: string | null,
    finishedAt?: string | null,
    visibilityLevel?: ExamResultVisibilityLevel | null,
    tenantCode: string,
    times: number,
    groupTrainingCourseId: string,
    problemHeaders?:  Array< {
      __typename: "ProblemHeader",
      id: string,
      body: string,
      createdAt: string,
    } > | null,
    timeLimit?: number | null,
    passingStandard?: number | null,
    userIdsToBeTested?: Array< string > | null,
    createdAt: string,
    updatedAt: string,
    userExams?:  {
      __typename: "ModelUserExamConnection",
      items:  Array< {
        __typename: "UserExam",
        id: string,
        userId: string,
        status: UserExamStatus,
        tenantCode: string,
        groupExamId: string,
        groupId: string,
        startedAt?: string | null,
        finishedAt?: string | null,
        createdAt: string,
        updatedAt: string,
        groupExam?:  {
          __typename: "GroupExam",
          id: string,
          groupId: string,
          contentId: string,
          content:  {
            __typename: "GroupExamContent",
            id: string,
            version?: number | null,
            name: string,
            timeLimit?: number | null,
            requiredTime?: number | null,
            indexInCourse?: number | null,
            passingStandard?: number | null,
          },
          problemsJson: string,
          courseId: string,
          course:  {
            __typename: "GroupExamCourse",
            id: string,
            version: number,
            name: string,
            color?: string | null,
            image?: string | null,
            fontColorOnImage?: CourseFontColorOnImage | null,
          },
          scheduledStart: string,
          scheduledFinish?: string | null,
          createdBy?: string | null,
          finishedAt?: string | null,
          visibilityLevel?: ExamResultVisibilityLevel | null,
          tenantCode: string,
          times: number,
          groupTrainingCourseId: string,
          problemHeaders?:  Array< {
            __typename: "ProblemHeader",
            id: string,
            body: string,
            createdAt: string,
          } > | null,
          timeLimit?: number | null,
          passingStandard?: number | null,
          userIdsToBeTested?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          userExams?:  {
            __typename: "ModelUserExamConnection",
            items:  Array< {
              __typename: "UserExam",
              id: string,
              userId: string,
              status: UserExamStatus,
              tenantCode: string,
              groupExamId: string,
              groupId: string,
              startedAt?: string | null,
              finishedAt?: string | null,
              createdAt: string,
              updatedAt: string,
              groupExam?:  {
                __typename: "GroupExam",
                id: string,
                groupId: string,
                contentId: string,
                content:  {
                  __typename: "GroupExamContent",
                  id: string,
                  version?: number | null,
                  name: string,
                  timeLimit?: number | null,
                  requiredTime?: number | null,
                  indexInCourse?: number | null,
                  passingStandard?: number | null,
                },
                problemsJson: string,
                courseId: string,
                course:  {
                  __typename: "GroupExamCourse",
                  id: string,
                  version: number,
                  name: string,
                  color?: string | null,
                  image?: string | null,
                  fontColorOnImage?: CourseFontColorOnImage | null,
                },
                scheduledStart: string,
                scheduledFinish?: string | null,
                createdBy?: string | null,
                finishedAt?: string | null,
                visibilityLevel?: ExamResultVisibilityLevel | null,
                tenantCode: string,
                times: number,
                groupTrainingCourseId: string,
                problemHeaders?:  Array< {
                  __typename: "ProblemHeader",
                  id: string,
                  body: string,
                  createdAt: string,
                } > | null,
                timeLimit?: number | null,
                passingStandard?: number | null,
                userIdsToBeTested?: Array< string > | null,
                createdAt: string,
                updatedAt: string,
                userExams?:  {
                  __typename: "ModelUserExamConnection",
                  nextToken?: string | null,
                } | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteGroupExamMutationVariables = {
  input: DeleteGroupExamInput,
  condition?: ModelGroupExamConditionInput | null,
};

export type DeleteGroupExamMutation = {
  deleteGroupExam?:  {
    __typename: "GroupExam",
    id: string,
    groupId: string,
    contentId: string,
    content:  {
      __typename: "GroupExamContent",
      id: string,
      version?: number | null,
      name: string,
      timeLimit?: number | null,
      requiredTime?: number | null,
      indexInCourse?: number | null,
      passingStandard?: number | null,
    },
    problemsJson: string,
    courseId: string,
    course:  {
      __typename: "GroupExamCourse",
      id: string,
      version: number,
      name: string,
      color?: string | null,
      image?: string | null,
      fontColorOnImage?: CourseFontColorOnImage | null,
    },
    scheduledStart: string,
    scheduledFinish?: string | null,
    createdBy?: string | null,
    finishedAt?: string | null,
    visibilityLevel?: ExamResultVisibilityLevel | null,
    tenantCode: string,
    times: number,
    groupTrainingCourseId: string,
    problemHeaders?:  Array< {
      __typename: "ProblemHeader",
      id: string,
      body: string,
      createdAt: string,
    } > | null,
    timeLimit?: number | null,
    passingStandard?: number | null,
    userIdsToBeTested?: Array< string > | null,
    createdAt: string,
    updatedAt: string,
    userExams?:  {
      __typename: "ModelUserExamConnection",
      items:  Array< {
        __typename: "UserExam",
        id: string,
        userId: string,
        status: UserExamStatus,
        tenantCode: string,
        groupExamId: string,
        groupId: string,
        startedAt?: string | null,
        finishedAt?: string | null,
        createdAt: string,
        updatedAt: string,
        groupExam?:  {
          __typename: "GroupExam",
          id: string,
          groupId: string,
          contentId: string,
          content:  {
            __typename: "GroupExamContent",
            id: string,
            version?: number | null,
            name: string,
            timeLimit?: number | null,
            requiredTime?: number | null,
            indexInCourse?: number | null,
            passingStandard?: number | null,
          },
          problemsJson: string,
          courseId: string,
          course:  {
            __typename: "GroupExamCourse",
            id: string,
            version: number,
            name: string,
            color?: string | null,
            image?: string | null,
            fontColorOnImage?: CourseFontColorOnImage | null,
          },
          scheduledStart: string,
          scheduledFinish?: string | null,
          createdBy?: string | null,
          finishedAt?: string | null,
          visibilityLevel?: ExamResultVisibilityLevel | null,
          tenantCode: string,
          times: number,
          groupTrainingCourseId: string,
          problemHeaders?:  Array< {
            __typename: "ProblemHeader",
            id: string,
            body: string,
            createdAt: string,
          } > | null,
          timeLimit?: number | null,
          passingStandard?: number | null,
          userIdsToBeTested?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          userExams?:  {
            __typename: "ModelUserExamConnection",
            items:  Array< {
              __typename: "UserExam",
              id: string,
              userId: string,
              status: UserExamStatus,
              tenantCode: string,
              groupExamId: string,
              groupId: string,
              startedAt?: string | null,
              finishedAt?: string | null,
              createdAt: string,
              updatedAt: string,
              groupExam?:  {
                __typename: "GroupExam",
                id: string,
                groupId: string,
                contentId: string,
                content:  {
                  __typename: "GroupExamContent",
                  id: string,
                  version?: number | null,
                  name: string,
                  timeLimit?: number | null,
                  requiredTime?: number | null,
                  indexInCourse?: number | null,
                  passingStandard?: number | null,
                },
                problemsJson: string,
                courseId: string,
                course:  {
                  __typename: "GroupExamCourse",
                  id: string,
                  version: number,
                  name: string,
                  color?: string | null,
                  image?: string | null,
                  fontColorOnImage?: CourseFontColorOnImage | null,
                },
                scheduledStart: string,
                scheduledFinish?: string | null,
                createdBy?: string | null,
                finishedAt?: string | null,
                visibilityLevel?: ExamResultVisibilityLevel | null,
                tenantCode: string,
                times: number,
                groupTrainingCourseId: string,
                problemHeaders?:  Array< {
                  __typename: "ProblemHeader",
                  id: string,
                  body: string,
                  createdAt: string,
                } > | null,
                timeLimit?: number | null,
                passingStandard?: number | null,
                userIdsToBeTested?: Array< string > | null,
                createdAt: string,
                updatedAt: string,
                userExams?:  {
                  __typename: "ModelUserExamConnection",
                  nextToken?: string | null,
                } | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateUserExamMutationVariables = {
  input: CreateUserExamInput,
  condition?: ModelUserExamConditionInput | null,
};

export type CreateUserExamMutation = {
  createUserExam?:  {
    __typename: "UserExam",
    id: string,
    userId: string,
    status: UserExamStatus,
    tenantCode: string,
    groupExamId: string,
    groupId: string,
    startedAt?: string | null,
    finishedAt?: string | null,
    createdAt: string,
    updatedAt: string,
    groupExam?:  {
      __typename: "GroupExam",
      id: string,
      groupId: string,
      contentId: string,
      content:  {
        __typename: "GroupExamContent",
        id: string,
        version?: number | null,
        name: string,
        timeLimit?: number | null,
        requiredTime?: number | null,
        indexInCourse?: number | null,
        passingStandard?: number | null,
      },
      problemsJson: string,
      courseId: string,
      course:  {
        __typename: "GroupExamCourse",
        id: string,
        version: number,
        name: string,
        color?: string | null,
        image?: string | null,
        fontColorOnImage?: CourseFontColorOnImage | null,
      },
      scheduledStart: string,
      scheduledFinish?: string | null,
      createdBy?: string | null,
      finishedAt?: string | null,
      visibilityLevel?: ExamResultVisibilityLevel | null,
      tenantCode: string,
      times: number,
      groupTrainingCourseId: string,
      problemHeaders?:  Array< {
        __typename: "ProblemHeader",
        id: string,
        body: string,
        createdAt: string,
      } > | null,
      timeLimit?: number | null,
      passingStandard?: number | null,
      userIdsToBeTested?: Array< string > | null,
      createdAt: string,
      updatedAt: string,
      userExams?:  {
        __typename: "ModelUserExamConnection",
        items:  Array< {
          __typename: "UserExam",
          id: string,
          userId: string,
          status: UserExamStatus,
          tenantCode: string,
          groupExamId: string,
          groupId: string,
          startedAt?: string | null,
          finishedAt?: string | null,
          createdAt: string,
          updatedAt: string,
          groupExam?:  {
            __typename: "GroupExam",
            id: string,
            groupId: string,
            contentId: string,
            content:  {
              __typename: "GroupExamContent",
              id: string,
              version?: number | null,
              name: string,
              timeLimit?: number | null,
              requiredTime?: number | null,
              indexInCourse?: number | null,
              passingStandard?: number | null,
            },
            problemsJson: string,
            courseId: string,
            course:  {
              __typename: "GroupExamCourse",
              id: string,
              version: number,
              name: string,
              color?: string | null,
              image?: string | null,
              fontColorOnImage?: CourseFontColorOnImage | null,
            },
            scheduledStart: string,
            scheduledFinish?: string | null,
            createdBy?: string | null,
            finishedAt?: string | null,
            visibilityLevel?: ExamResultVisibilityLevel | null,
            tenantCode: string,
            times: number,
            groupTrainingCourseId: string,
            problemHeaders?:  Array< {
              __typename: "ProblemHeader",
              id: string,
              body: string,
              createdAt: string,
            } > | null,
            timeLimit?: number | null,
            passingStandard?: number | null,
            userIdsToBeTested?: Array< string > | null,
            createdAt: string,
            updatedAt: string,
            userExams?:  {
              __typename: "ModelUserExamConnection",
              items:  Array< {
                __typename: "UserExam",
                id: string,
                userId: string,
                status: UserExamStatus,
                tenantCode: string,
                groupExamId: string,
                groupId: string,
                startedAt?: string | null,
                finishedAt?: string | null,
                createdAt: string,
                updatedAt: string,
                groupExam?:  {
                  __typename: "GroupExam",
                  id: string,
                  groupId: string,
                  contentId: string,
                  problemsJson: string,
                  courseId: string,
                  scheduledStart: string,
                  scheduledFinish?: string | null,
                  createdBy?: string | null,
                  finishedAt?: string | null,
                  visibilityLevel?: ExamResultVisibilityLevel | null,
                  tenantCode: string,
                  times: number,
                  groupTrainingCourseId: string,
                  timeLimit?: number | null,
                  passingStandard?: number | null,
                  userIdsToBeTested?: Array< string > | null,
                  createdAt: string,
                  updatedAt: string,
                } | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteUserExamMutationVariables = {
  input: DeleteUserExamInput,
  condition?: ModelUserExamConditionInput | null,
};

export type DeleteUserExamMutation = {
  deleteUserExam?:  {
    __typename: "UserExam",
    id: string,
    userId: string,
    status: UserExamStatus,
    tenantCode: string,
    groupExamId: string,
    groupId: string,
    startedAt?: string | null,
    finishedAt?: string | null,
    createdAt: string,
    updatedAt: string,
    groupExam?:  {
      __typename: "GroupExam",
      id: string,
      groupId: string,
      contentId: string,
      content:  {
        __typename: "GroupExamContent",
        id: string,
        version?: number | null,
        name: string,
        timeLimit?: number | null,
        requiredTime?: number | null,
        indexInCourse?: number | null,
        passingStandard?: number | null,
      },
      problemsJson: string,
      courseId: string,
      course:  {
        __typename: "GroupExamCourse",
        id: string,
        version: number,
        name: string,
        color?: string | null,
        image?: string | null,
        fontColorOnImage?: CourseFontColorOnImage | null,
      },
      scheduledStart: string,
      scheduledFinish?: string | null,
      createdBy?: string | null,
      finishedAt?: string | null,
      visibilityLevel?: ExamResultVisibilityLevel | null,
      tenantCode: string,
      times: number,
      groupTrainingCourseId: string,
      problemHeaders?:  Array< {
        __typename: "ProblemHeader",
        id: string,
        body: string,
        createdAt: string,
      } > | null,
      timeLimit?: number | null,
      passingStandard?: number | null,
      userIdsToBeTested?: Array< string > | null,
      createdAt: string,
      updatedAt: string,
      userExams?:  {
        __typename: "ModelUserExamConnection",
        items:  Array< {
          __typename: "UserExam",
          id: string,
          userId: string,
          status: UserExamStatus,
          tenantCode: string,
          groupExamId: string,
          groupId: string,
          startedAt?: string | null,
          finishedAt?: string | null,
          createdAt: string,
          updatedAt: string,
          groupExam?:  {
            __typename: "GroupExam",
            id: string,
            groupId: string,
            contentId: string,
            content:  {
              __typename: "GroupExamContent",
              id: string,
              version?: number | null,
              name: string,
              timeLimit?: number | null,
              requiredTime?: number | null,
              indexInCourse?: number | null,
              passingStandard?: number | null,
            },
            problemsJson: string,
            courseId: string,
            course:  {
              __typename: "GroupExamCourse",
              id: string,
              version: number,
              name: string,
              color?: string | null,
              image?: string | null,
              fontColorOnImage?: CourseFontColorOnImage | null,
            },
            scheduledStart: string,
            scheduledFinish?: string | null,
            createdBy?: string | null,
            finishedAt?: string | null,
            visibilityLevel?: ExamResultVisibilityLevel | null,
            tenantCode: string,
            times: number,
            groupTrainingCourseId: string,
            problemHeaders?:  Array< {
              __typename: "ProblemHeader",
              id: string,
              body: string,
              createdAt: string,
            } > | null,
            timeLimit?: number | null,
            passingStandard?: number | null,
            userIdsToBeTested?: Array< string > | null,
            createdAt: string,
            updatedAt: string,
            userExams?:  {
              __typename: "ModelUserExamConnection",
              items:  Array< {
                __typename: "UserExam",
                id: string,
                userId: string,
                status: UserExamStatus,
                tenantCode: string,
                groupExamId: string,
                groupId: string,
                startedAt?: string | null,
                finishedAt?: string | null,
                createdAt: string,
                updatedAt: string,
                groupExam?:  {
                  __typename: "GroupExam",
                  id: string,
                  groupId: string,
                  contentId: string,
                  problemsJson: string,
                  courseId: string,
                  scheduledStart: string,
                  scheduledFinish?: string | null,
                  createdBy?: string | null,
                  finishedAt?: string | null,
                  visibilityLevel?: ExamResultVisibilityLevel | null,
                  tenantCode: string,
                  times: number,
                  groupTrainingCourseId: string,
                  timeLimit?: number | null,
                  passingStandard?: number | null,
                  userIdsToBeTested?: Array< string > | null,
                  createdAt: string,
                  updatedAt: string,
                } | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateMemoMutationVariables = {
  input: CreateMemoInput,
  condition?: ModelMemoConditionInput | null,
};

export type CreateMemoMutation = {
  createMemo?:  {
    __typename: "Memo",
    id: string,
    body?: string | null,
    referTo?:  {
      __typename: "ContentReference",
      contentId: string,
      contentVersion?: number | null,
      options?:  {
        __typename: "ContentReferenceOptions",
        text?:  {
          __typename: "TextContentReferenceOptions",
          selectionJson: string,
        } | null,
        workbook?:  {
          __typename: "WorkbookReferenceOptions",
          problemIndex: number,
          selectionJson?: string | null,
        } | null,
      } | null,
    } | null,
    scope?: string | null,
    groupId: string,
    createdBy: string,
    createdAt?: string | null,
    updatedBy?: string | null,
    updatedAt?: string | null,
    tenantCode: string,
  } | null,
};

export type UpdateMemoMutationVariables = {
  input: UpdateMemoInput,
  condition?: ModelMemoConditionInput | null,
};

export type UpdateMemoMutation = {
  updateMemo?:  {
    __typename: "Memo",
    id: string,
    body?: string | null,
    referTo?:  {
      __typename: "ContentReference",
      contentId: string,
      contentVersion?: number | null,
      options?:  {
        __typename: "ContentReferenceOptions",
        text?:  {
          __typename: "TextContentReferenceOptions",
          selectionJson: string,
        } | null,
        workbook?:  {
          __typename: "WorkbookReferenceOptions",
          problemIndex: number,
          selectionJson?: string | null,
        } | null,
      } | null,
    } | null,
    scope?: string | null,
    groupId: string,
    createdBy: string,
    createdAt?: string | null,
    updatedBy?: string | null,
    updatedAt?: string | null,
    tenantCode: string,
  } | null,
};

export type DeleteMemoMutationVariables = {
  input: DeleteMemoInput,
  condition?: ModelMemoConditionInput | null,
};

export type DeleteMemoMutation = {
  deleteMemo?:  {
    __typename: "Memo",
    id: string,
    body?: string | null,
    referTo?:  {
      __typename: "ContentReference",
      contentId: string,
      contentVersion?: number | null,
      options?:  {
        __typename: "ContentReferenceOptions",
        text?:  {
          __typename: "TextContentReferenceOptions",
          selectionJson: string,
        } | null,
        workbook?:  {
          __typename: "WorkbookReferenceOptions",
          problemIndex: number,
          selectionJson?: string | null,
        } | null,
      } | null,
    } | null,
    scope?: string | null,
    groupId: string,
    createdBy: string,
    createdAt?: string | null,
    updatedBy?: string | null,
    updatedAt?: string | null,
    tenantCode: string,
  } | null,
};

export type CreateReviewProblemMutationVariables = {
  input: CreateReviewProblemInput,
  condition?: ModelReviewProblemConditionInput | null,
};

export type CreateReviewProblemMutation = {
  createReviewProblem?:  {
    __typename: "ReviewProblem",
    id: string,
    userId: string,
    groupId: string,
    courseId: string,
    problems?: string | null,
    tenantCode: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateReviewProblemMutationVariables = {
  input: UpdateReviewProblemInput,
  condition?: ModelReviewProblemConditionInput | null,
};

export type UpdateReviewProblemMutation = {
  updateReviewProblem?:  {
    __typename: "ReviewProblem",
    id: string,
    userId: string,
    groupId: string,
    courseId: string,
    problems?: string | null,
    tenantCode: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteReviewProblemMutationVariables = {
  input: DeleteReviewProblemInput,
  condition?: ModelReviewProblemConditionInput | null,
};

export type DeleteReviewProblemMutation = {
  deleteReviewProblem?:  {
    __typename: "ReviewProblem",
    id: string,
    userId: string,
    groupId: string,
    courseId: string,
    problems?: string | null,
    tenantCode: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateNotificationMutationVariables = {
  input: CreateNotificationInput,
  condition?: ModelNotificationConditionInput | null,
};

export type CreateNotificationMutation = {
  createNotification?:  {
    __typename: "Notification",
    id: string,
    type: string,
    payloadJson: string,
    userId: string,
    read: boolean,
    createdAt?: string | null,
    tenantCode: string,
    updatedAt: string,
  } | null,
};

export type CreateUserMutationVariables = {
  input: CreateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type CreateUserMutation = {
  createUser?:  {
    __typename: "User",
    id: string,
    name: string,
    role: Role,
    extensionConfigJsonList: Array< string >,
    email?: string | null,
    code?: string | null,
    tenantCode: string,
    displaySettings?:  {
      __typename: "DisplaySettings",
      theme?: string | null,
      color?: string | null,
    } | null,
    locale?: string | null,
    avatar?: string | null,
    enabled: boolean,
    signedInAtLeastOnce: boolean,
    emailConfirmed?: boolean | null,
    confirmedTermsOfServiceVersions?:  {
      __typename: "ConfirmedTermsOfServiceVersions",
      termsOfServiceVersion?: string | null,
      privacyPolicyVersion?: string | null,
      tenantOwnTermsOfServiceVersion?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    tags?:  {
      __typename: "ModelUserUserTagConnection",
      items:  Array< {
        __typename: "UserUserTag",
        id: string,
        userId: string,
        userTagId: string,
        tenantCode: string,
        createdAt: string,
        updatedAt: string,
        user:  {
          __typename: "User",
          id: string,
          name: string,
          role: Role,
          extensionConfigJsonList: Array< string >,
          email?: string | null,
          code?: string | null,
          tenantCode: string,
          displaySettings?:  {
            __typename: "DisplaySettings",
            theme?: string | null,
            color?: string | null,
          } | null,
          locale?: string | null,
          avatar?: string | null,
          enabled: boolean,
          signedInAtLeastOnce: boolean,
          emailConfirmed?: boolean | null,
          confirmedTermsOfServiceVersions?:  {
            __typename: "ConfirmedTermsOfServiceVersions",
            termsOfServiceVersion?: string | null,
            privacyPolicyVersion?: string | null,
            tenantOwnTermsOfServiceVersion?: number | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          tags?:  {
            __typename: "ModelUserUserTagConnection",
            items:  Array< {
              __typename: "UserUserTag",
              id: string,
              userId: string,
              userTagId: string,
              tenantCode: string,
              createdAt: string,
              updatedAt: string,
              user:  {
                __typename: "User",
                id: string,
                name: string,
                role: Role,
                extensionConfigJsonList: Array< string >,
                email?: string | null,
                code?: string | null,
                tenantCode: string,
                displaySettings?:  {
                  __typename: "DisplaySettings",
                  theme?: string | null,
                  color?: string | null,
                } | null,
                locale?: string | null,
                avatar?: string | null,
                enabled: boolean,
                signedInAtLeastOnce: boolean,
                emailConfirmed?: boolean | null,
                confirmedTermsOfServiceVersions?:  {
                  __typename: "ConfirmedTermsOfServiceVersions",
                  termsOfServiceVersion?: string | null,
                  privacyPolicyVersion?: string | null,
                  tenantOwnTermsOfServiceVersion?: number | null,
                } | null,
                createdAt: string,
                updatedAt: string,
                tags?:  {
                  __typename: "ModelUserUserTagConnection",
                  nextToken?: string | null,
                } | null,
                statuses?:  {
                  __typename: "ModelUserStatusTableConnection",
                  nextToken?: string | null,
                } | null,
              },
              userTag:  {
                __typename: "UserTag",
                id: string,
                color: string,
                text: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                users?:  {
                  __typename: "ModelUserUserTagConnection",
                  nextToken?: string | null,
                } | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          statuses?:  {
            __typename: "ModelUserStatusTableConnection",
            items:  Array< {
              __typename: "UserStatusTable",
              id: string,
              tenantCode: string,
              status: UserStatus,
              userId: string,
              createdAt: string,
              updatedAt: string,
              user:  {
                __typename: "User",
                id: string,
                name: string,
                role: Role,
                extensionConfigJsonList: Array< string >,
                email?: string | null,
                code?: string | null,
                tenantCode: string,
                displaySettings?:  {
                  __typename: "DisplaySettings",
                  theme?: string | null,
                  color?: string | null,
                } | null,
                locale?: string | null,
                avatar?: string | null,
                enabled: boolean,
                signedInAtLeastOnce: boolean,
                emailConfirmed?: boolean | null,
                confirmedTermsOfServiceVersions?:  {
                  __typename: "ConfirmedTermsOfServiceVersions",
                  termsOfServiceVersion?: string | null,
                  privacyPolicyVersion?: string | null,
                  tenantOwnTermsOfServiceVersion?: number | null,
                } | null,
                createdAt: string,
                updatedAt: string,
                tags?:  {
                  __typename: "ModelUserUserTagConnection",
                  nextToken?: string | null,
                } | null,
                statuses?:  {
                  __typename: "ModelUserStatusTableConnection",
                  nextToken?: string | null,
                } | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        userTag:  {
          __typename: "UserTag",
          id: string,
          color: string,
          text: string,
          tenantCode: string,
          createdAt: string,
          updatedAt: string,
          users?:  {
            __typename: "ModelUserUserTagConnection",
            items:  Array< {
              __typename: "UserUserTag",
              id: string,
              userId: string,
              userTagId: string,
              tenantCode: string,
              createdAt: string,
              updatedAt: string,
              user:  {
                __typename: "User",
                id: string,
                name: string,
                role: Role,
                extensionConfigJsonList: Array< string >,
                email?: string | null,
                code?: string | null,
                tenantCode: string,
                displaySettings?:  {
                  __typename: "DisplaySettings",
                  theme?: string | null,
                  color?: string | null,
                } | null,
                locale?: string | null,
                avatar?: string | null,
                enabled: boolean,
                signedInAtLeastOnce: boolean,
                emailConfirmed?: boolean | null,
                confirmedTermsOfServiceVersions?:  {
                  __typename: "ConfirmedTermsOfServiceVersions",
                  termsOfServiceVersion?: string | null,
                  privacyPolicyVersion?: string | null,
                  tenantOwnTermsOfServiceVersion?: number | null,
                } | null,
                createdAt: string,
                updatedAt: string,
                tags?:  {
                  __typename: "ModelUserUserTagConnection",
                  nextToken?: string | null,
                } | null,
                statuses?:  {
                  __typename: "ModelUserStatusTableConnection",
                  nextToken?: string | null,
                } | null,
              },
              userTag:  {
                __typename: "UserTag",
                id: string,
                color: string,
                text: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                users?:  {
                  __typename: "ModelUserUserTagConnection",
                  nextToken?: string | null,
                } | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    statuses?:  {
      __typename: "ModelUserStatusTableConnection",
      items:  Array< {
        __typename: "UserStatusTable",
        id: string,
        tenantCode: string,
        status: UserStatus,
        userId: string,
        createdAt: string,
        updatedAt: string,
        user:  {
          __typename: "User",
          id: string,
          name: string,
          role: Role,
          extensionConfigJsonList: Array< string >,
          email?: string | null,
          code?: string | null,
          tenantCode: string,
          displaySettings?:  {
            __typename: "DisplaySettings",
            theme?: string | null,
            color?: string | null,
          } | null,
          locale?: string | null,
          avatar?: string | null,
          enabled: boolean,
          signedInAtLeastOnce: boolean,
          emailConfirmed?: boolean | null,
          confirmedTermsOfServiceVersions?:  {
            __typename: "ConfirmedTermsOfServiceVersions",
            termsOfServiceVersion?: string | null,
            privacyPolicyVersion?: string | null,
            tenantOwnTermsOfServiceVersion?: number | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          tags?:  {
            __typename: "ModelUserUserTagConnection",
            items:  Array< {
              __typename: "UserUserTag",
              id: string,
              userId: string,
              userTagId: string,
              tenantCode: string,
              createdAt: string,
              updatedAt: string,
              user:  {
                __typename: "User",
                id: string,
                name: string,
                role: Role,
                extensionConfigJsonList: Array< string >,
                email?: string | null,
                code?: string | null,
                tenantCode: string,
                displaySettings?:  {
                  __typename: "DisplaySettings",
                  theme?: string | null,
                  color?: string | null,
                } | null,
                locale?: string | null,
                avatar?: string | null,
                enabled: boolean,
                signedInAtLeastOnce: boolean,
                emailConfirmed?: boolean | null,
                confirmedTermsOfServiceVersions?:  {
                  __typename: "ConfirmedTermsOfServiceVersions",
                  termsOfServiceVersion?: string | null,
                  privacyPolicyVersion?: string | null,
                  tenantOwnTermsOfServiceVersion?: number | null,
                } | null,
                createdAt: string,
                updatedAt: string,
                tags?:  {
                  __typename: "ModelUserUserTagConnection",
                  nextToken?: string | null,
                } | null,
                statuses?:  {
                  __typename: "ModelUserStatusTableConnection",
                  nextToken?: string | null,
                } | null,
              },
              userTag:  {
                __typename: "UserTag",
                id: string,
                color: string,
                text: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                users?:  {
                  __typename: "ModelUserUserTagConnection",
                  nextToken?: string | null,
                } | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          statuses?:  {
            __typename: "ModelUserStatusTableConnection",
            items:  Array< {
              __typename: "UserStatusTable",
              id: string,
              tenantCode: string,
              status: UserStatus,
              userId: string,
              createdAt: string,
              updatedAt: string,
              user:  {
                __typename: "User",
                id: string,
                name: string,
                role: Role,
                extensionConfigJsonList: Array< string >,
                email?: string | null,
                code?: string | null,
                tenantCode: string,
                displaySettings?:  {
                  __typename: "DisplaySettings",
                  theme?: string | null,
                  color?: string | null,
                } | null,
                locale?: string | null,
                avatar?: string | null,
                enabled: boolean,
                signedInAtLeastOnce: boolean,
                emailConfirmed?: boolean | null,
                confirmedTermsOfServiceVersions?:  {
                  __typename: "ConfirmedTermsOfServiceVersions",
                  termsOfServiceVersion?: string | null,
                  privacyPolicyVersion?: string | null,
                  tenantOwnTermsOfServiceVersion?: number | null,
                } | null,
                createdAt: string,
                updatedAt: string,
                tags?:  {
                  __typename: "ModelUserUserTagConnection",
                  nextToken?: string | null,
                } | null,
                statuses?:  {
                  __typename: "ModelUserStatusTableConnection",
                  nextToken?: string | null,
                } | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateTenantMutationVariables = {
  input: CreateTenantInput,
  condition?: ModelTenantConditionInput | null,
};

export type CreateTenantMutation = {
  createTenant?:  {
    __typename: "Tenant",
    id: string,
    code: string,
    name: string,
    signUpType: SignUpType,
    playground: boolean,
    userLimit?: number | null,
    limitations?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateTenantOwnTermsOfServiceMutationVariables = {
  input: CreateTenantOwnTermsOfServiceInput,
  condition?: ModelTenantOwnTermsOfServiceConditionInput | null,
};

export type CreateTenantOwnTermsOfServiceMutation = {
  createTenantOwnTermsOfService?:  {
    __typename: "TenantOwnTermsOfService",
    id: string,
    tenantCode: string,
    type: TenantOwnTermsOfServiceType,
    body: string,
    createdBy: string,
    createdAt: string,
    updatedBy?: string | null,
    updatedAt?: string | null,
    version?: number | null,
  } | null,
};

export type CreateUserTagMutationVariables = {
  input: CreateUserTagInput,
  condition?: ModelUserTagConditionInput | null,
};

export type CreateUserTagMutation = {
  createUserTag?:  {
    __typename: "UserTag",
    id: string,
    color: string,
    text: string,
    tenantCode: string,
    createdAt: string,
    updatedAt: string,
    users?:  {
      __typename: "ModelUserUserTagConnection",
      items:  Array< {
        __typename: "UserUserTag",
        id: string,
        userId: string,
        userTagId: string,
        tenantCode: string,
        createdAt: string,
        updatedAt: string,
        user:  {
          __typename: "User",
          id: string,
          name: string,
          role: Role,
          extensionConfigJsonList: Array< string >,
          email?: string | null,
          code?: string | null,
          tenantCode: string,
          displaySettings?:  {
            __typename: "DisplaySettings",
            theme?: string | null,
            color?: string | null,
          } | null,
          locale?: string | null,
          avatar?: string | null,
          enabled: boolean,
          signedInAtLeastOnce: boolean,
          emailConfirmed?: boolean | null,
          confirmedTermsOfServiceVersions?:  {
            __typename: "ConfirmedTermsOfServiceVersions",
            termsOfServiceVersion?: string | null,
            privacyPolicyVersion?: string | null,
            tenantOwnTermsOfServiceVersion?: number | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          tags?:  {
            __typename: "ModelUserUserTagConnection",
            items:  Array< {
              __typename: "UserUserTag",
              id: string,
              userId: string,
              userTagId: string,
              tenantCode: string,
              createdAt: string,
              updatedAt: string,
              user:  {
                __typename: "User",
                id: string,
                name: string,
                role: Role,
                extensionConfigJsonList: Array< string >,
                email?: string | null,
                code?: string | null,
                tenantCode: string,
                displaySettings?:  {
                  __typename: "DisplaySettings",
                  theme?: string | null,
                  color?: string | null,
                } | null,
                locale?: string | null,
                avatar?: string | null,
                enabled: boolean,
                signedInAtLeastOnce: boolean,
                emailConfirmed?: boolean | null,
                confirmedTermsOfServiceVersions?:  {
                  __typename: "ConfirmedTermsOfServiceVersions",
                  termsOfServiceVersion?: string | null,
                  privacyPolicyVersion?: string | null,
                  tenantOwnTermsOfServiceVersion?: number | null,
                } | null,
                createdAt: string,
                updatedAt: string,
                tags?:  {
                  __typename: "ModelUserUserTagConnection",
                  nextToken?: string | null,
                } | null,
                statuses?:  {
                  __typename: "ModelUserStatusTableConnection",
                  nextToken?: string | null,
                } | null,
              },
              userTag:  {
                __typename: "UserTag",
                id: string,
                color: string,
                text: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                users?:  {
                  __typename: "ModelUserUserTagConnection",
                  nextToken?: string | null,
                } | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          statuses?:  {
            __typename: "ModelUserStatusTableConnection",
            items:  Array< {
              __typename: "UserStatusTable",
              id: string,
              tenantCode: string,
              status: UserStatus,
              userId: string,
              createdAt: string,
              updatedAt: string,
              user:  {
                __typename: "User",
                id: string,
                name: string,
                role: Role,
                extensionConfigJsonList: Array< string >,
                email?: string | null,
                code?: string | null,
                tenantCode: string,
                displaySettings?:  {
                  __typename: "DisplaySettings",
                  theme?: string | null,
                  color?: string | null,
                } | null,
                locale?: string | null,
                avatar?: string | null,
                enabled: boolean,
                signedInAtLeastOnce: boolean,
                emailConfirmed?: boolean | null,
                confirmedTermsOfServiceVersions?:  {
                  __typename: "ConfirmedTermsOfServiceVersions",
                  termsOfServiceVersion?: string | null,
                  privacyPolicyVersion?: string | null,
                  tenantOwnTermsOfServiceVersion?: number | null,
                } | null,
                createdAt: string,
                updatedAt: string,
                tags?:  {
                  __typename: "ModelUserUserTagConnection",
                  nextToken?: string | null,
                } | null,
                statuses?:  {
                  __typename: "ModelUserStatusTableConnection",
                  nextToken?: string | null,
                } | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        userTag:  {
          __typename: "UserTag",
          id: string,
          color: string,
          text: string,
          tenantCode: string,
          createdAt: string,
          updatedAt: string,
          users?:  {
            __typename: "ModelUserUserTagConnection",
            items:  Array< {
              __typename: "UserUserTag",
              id: string,
              userId: string,
              userTagId: string,
              tenantCode: string,
              createdAt: string,
              updatedAt: string,
              user:  {
                __typename: "User",
                id: string,
                name: string,
                role: Role,
                extensionConfigJsonList: Array< string >,
                email?: string | null,
                code?: string | null,
                tenantCode: string,
                displaySettings?:  {
                  __typename: "DisplaySettings",
                  theme?: string | null,
                  color?: string | null,
                } | null,
                locale?: string | null,
                avatar?: string | null,
                enabled: boolean,
                signedInAtLeastOnce: boolean,
                emailConfirmed?: boolean | null,
                confirmedTermsOfServiceVersions?:  {
                  __typename: "ConfirmedTermsOfServiceVersions",
                  termsOfServiceVersion?: string | null,
                  privacyPolicyVersion?: string | null,
                  tenantOwnTermsOfServiceVersion?: number | null,
                } | null,
                createdAt: string,
                updatedAt: string,
                tags?:  {
                  __typename: "ModelUserUserTagConnection",
                  nextToken?: string | null,
                } | null,
                statuses?:  {
                  __typename: "ModelUserStatusTableConnection",
                  nextToken?: string | null,
                } | null,
              },
              userTag:  {
                __typename: "UserTag",
                id: string,
                color: string,
                text: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                users?:  {
                  __typename: "ModelUserUserTagConnection",
                  nextToken?: string | null,
                } | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateUserTagMutationVariables = {
  input: UpdateUserTagInput,
  condition?: ModelUserTagConditionInput | null,
};

export type UpdateUserTagMutation = {
  updateUserTag?:  {
    __typename: "UserTag",
    id: string,
    color: string,
    text: string,
    tenantCode: string,
    createdAt: string,
    updatedAt: string,
    users?:  {
      __typename: "ModelUserUserTagConnection",
      items:  Array< {
        __typename: "UserUserTag",
        id: string,
        userId: string,
        userTagId: string,
        tenantCode: string,
        createdAt: string,
        updatedAt: string,
        user:  {
          __typename: "User",
          id: string,
          name: string,
          role: Role,
          extensionConfigJsonList: Array< string >,
          email?: string | null,
          code?: string | null,
          tenantCode: string,
          displaySettings?:  {
            __typename: "DisplaySettings",
            theme?: string | null,
            color?: string | null,
          } | null,
          locale?: string | null,
          avatar?: string | null,
          enabled: boolean,
          signedInAtLeastOnce: boolean,
          emailConfirmed?: boolean | null,
          confirmedTermsOfServiceVersions?:  {
            __typename: "ConfirmedTermsOfServiceVersions",
            termsOfServiceVersion?: string | null,
            privacyPolicyVersion?: string | null,
            tenantOwnTermsOfServiceVersion?: number | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          tags?:  {
            __typename: "ModelUserUserTagConnection",
            items:  Array< {
              __typename: "UserUserTag",
              id: string,
              userId: string,
              userTagId: string,
              tenantCode: string,
              createdAt: string,
              updatedAt: string,
              user:  {
                __typename: "User",
                id: string,
                name: string,
                role: Role,
                extensionConfigJsonList: Array< string >,
                email?: string | null,
                code?: string | null,
                tenantCode: string,
                displaySettings?:  {
                  __typename: "DisplaySettings",
                  theme?: string | null,
                  color?: string | null,
                } | null,
                locale?: string | null,
                avatar?: string | null,
                enabled: boolean,
                signedInAtLeastOnce: boolean,
                emailConfirmed?: boolean | null,
                confirmedTermsOfServiceVersions?:  {
                  __typename: "ConfirmedTermsOfServiceVersions",
                  termsOfServiceVersion?: string | null,
                  privacyPolicyVersion?: string | null,
                  tenantOwnTermsOfServiceVersion?: number | null,
                } | null,
                createdAt: string,
                updatedAt: string,
                tags?:  {
                  __typename: "ModelUserUserTagConnection",
                  nextToken?: string | null,
                } | null,
                statuses?:  {
                  __typename: "ModelUserStatusTableConnection",
                  nextToken?: string | null,
                } | null,
              },
              userTag:  {
                __typename: "UserTag",
                id: string,
                color: string,
                text: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                users?:  {
                  __typename: "ModelUserUserTagConnection",
                  nextToken?: string | null,
                } | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          statuses?:  {
            __typename: "ModelUserStatusTableConnection",
            items:  Array< {
              __typename: "UserStatusTable",
              id: string,
              tenantCode: string,
              status: UserStatus,
              userId: string,
              createdAt: string,
              updatedAt: string,
              user:  {
                __typename: "User",
                id: string,
                name: string,
                role: Role,
                extensionConfigJsonList: Array< string >,
                email?: string | null,
                code?: string | null,
                tenantCode: string,
                displaySettings?:  {
                  __typename: "DisplaySettings",
                  theme?: string | null,
                  color?: string | null,
                } | null,
                locale?: string | null,
                avatar?: string | null,
                enabled: boolean,
                signedInAtLeastOnce: boolean,
                emailConfirmed?: boolean | null,
                confirmedTermsOfServiceVersions?:  {
                  __typename: "ConfirmedTermsOfServiceVersions",
                  termsOfServiceVersion?: string | null,
                  privacyPolicyVersion?: string | null,
                  tenantOwnTermsOfServiceVersion?: number | null,
                } | null,
                createdAt: string,
                updatedAt: string,
                tags?:  {
                  __typename: "ModelUserUserTagConnection",
                  nextToken?: string | null,
                } | null,
                statuses?:  {
                  __typename: "ModelUserStatusTableConnection",
                  nextToken?: string | null,
                } | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        userTag:  {
          __typename: "UserTag",
          id: string,
          color: string,
          text: string,
          tenantCode: string,
          createdAt: string,
          updatedAt: string,
          users?:  {
            __typename: "ModelUserUserTagConnection",
            items:  Array< {
              __typename: "UserUserTag",
              id: string,
              userId: string,
              userTagId: string,
              tenantCode: string,
              createdAt: string,
              updatedAt: string,
              user:  {
                __typename: "User",
                id: string,
                name: string,
                role: Role,
                extensionConfigJsonList: Array< string >,
                email?: string | null,
                code?: string | null,
                tenantCode: string,
                displaySettings?:  {
                  __typename: "DisplaySettings",
                  theme?: string | null,
                  color?: string | null,
                } | null,
                locale?: string | null,
                avatar?: string | null,
                enabled: boolean,
                signedInAtLeastOnce: boolean,
                emailConfirmed?: boolean | null,
                confirmedTermsOfServiceVersions?:  {
                  __typename: "ConfirmedTermsOfServiceVersions",
                  termsOfServiceVersion?: string | null,
                  privacyPolicyVersion?: string | null,
                  tenantOwnTermsOfServiceVersion?: number | null,
                } | null,
                createdAt: string,
                updatedAt: string,
                tags?:  {
                  __typename: "ModelUserUserTagConnection",
                  nextToken?: string | null,
                } | null,
                statuses?:  {
                  __typename: "ModelUserStatusTableConnection",
                  nextToken?: string | null,
                } | null,
              },
              userTag:  {
                __typename: "UserTag",
                id: string,
                color: string,
                text: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                users?:  {
                  __typename: "ModelUserUserTagConnection",
                  nextToken?: string | null,
                } | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteUserTagMutationVariables = {
  input: DeleteUserTagInput,
  condition?: ModelUserTagConditionInput | null,
};

export type DeleteUserTagMutation = {
  deleteUserTag?:  {
    __typename: "UserTag",
    id: string,
    color: string,
    text: string,
    tenantCode: string,
    createdAt: string,
    updatedAt: string,
    users?:  {
      __typename: "ModelUserUserTagConnection",
      items:  Array< {
        __typename: "UserUserTag",
        id: string,
        userId: string,
        userTagId: string,
        tenantCode: string,
        createdAt: string,
        updatedAt: string,
        user:  {
          __typename: "User",
          id: string,
          name: string,
          role: Role,
          extensionConfigJsonList: Array< string >,
          email?: string | null,
          code?: string | null,
          tenantCode: string,
          displaySettings?:  {
            __typename: "DisplaySettings",
            theme?: string | null,
            color?: string | null,
          } | null,
          locale?: string | null,
          avatar?: string | null,
          enabled: boolean,
          signedInAtLeastOnce: boolean,
          emailConfirmed?: boolean | null,
          confirmedTermsOfServiceVersions?:  {
            __typename: "ConfirmedTermsOfServiceVersions",
            termsOfServiceVersion?: string | null,
            privacyPolicyVersion?: string | null,
            tenantOwnTermsOfServiceVersion?: number | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          tags?:  {
            __typename: "ModelUserUserTagConnection",
            items:  Array< {
              __typename: "UserUserTag",
              id: string,
              userId: string,
              userTagId: string,
              tenantCode: string,
              createdAt: string,
              updatedAt: string,
              user:  {
                __typename: "User",
                id: string,
                name: string,
                role: Role,
                extensionConfigJsonList: Array< string >,
                email?: string | null,
                code?: string | null,
                tenantCode: string,
                displaySettings?:  {
                  __typename: "DisplaySettings",
                  theme?: string | null,
                  color?: string | null,
                } | null,
                locale?: string | null,
                avatar?: string | null,
                enabled: boolean,
                signedInAtLeastOnce: boolean,
                emailConfirmed?: boolean | null,
                confirmedTermsOfServiceVersions?:  {
                  __typename: "ConfirmedTermsOfServiceVersions",
                  termsOfServiceVersion?: string | null,
                  privacyPolicyVersion?: string | null,
                  tenantOwnTermsOfServiceVersion?: number | null,
                } | null,
                createdAt: string,
                updatedAt: string,
                tags?:  {
                  __typename: "ModelUserUserTagConnection",
                  nextToken?: string | null,
                } | null,
                statuses?:  {
                  __typename: "ModelUserStatusTableConnection",
                  nextToken?: string | null,
                } | null,
              },
              userTag:  {
                __typename: "UserTag",
                id: string,
                color: string,
                text: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                users?:  {
                  __typename: "ModelUserUserTagConnection",
                  nextToken?: string | null,
                } | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          statuses?:  {
            __typename: "ModelUserStatusTableConnection",
            items:  Array< {
              __typename: "UserStatusTable",
              id: string,
              tenantCode: string,
              status: UserStatus,
              userId: string,
              createdAt: string,
              updatedAt: string,
              user:  {
                __typename: "User",
                id: string,
                name: string,
                role: Role,
                extensionConfigJsonList: Array< string >,
                email?: string | null,
                code?: string | null,
                tenantCode: string,
                displaySettings?:  {
                  __typename: "DisplaySettings",
                  theme?: string | null,
                  color?: string | null,
                } | null,
                locale?: string | null,
                avatar?: string | null,
                enabled: boolean,
                signedInAtLeastOnce: boolean,
                emailConfirmed?: boolean | null,
                confirmedTermsOfServiceVersions?:  {
                  __typename: "ConfirmedTermsOfServiceVersions",
                  termsOfServiceVersion?: string | null,
                  privacyPolicyVersion?: string | null,
                  tenantOwnTermsOfServiceVersion?: number | null,
                } | null,
                createdAt: string,
                updatedAt: string,
                tags?:  {
                  __typename: "ModelUserUserTagConnection",
                  nextToken?: string | null,
                } | null,
                statuses?:  {
                  __typename: "ModelUserStatusTableConnection",
                  nextToken?: string | null,
                } | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        userTag:  {
          __typename: "UserTag",
          id: string,
          color: string,
          text: string,
          tenantCode: string,
          createdAt: string,
          updatedAt: string,
          users?:  {
            __typename: "ModelUserUserTagConnection",
            items:  Array< {
              __typename: "UserUserTag",
              id: string,
              userId: string,
              userTagId: string,
              tenantCode: string,
              createdAt: string,
              updatedAt: string,
              user:  {
                __typename: "User",
                id: string,
                name: string,
                role: Role,
                extensionConfigJsonList: Array< string >,
                email?: string | null,
                code?: string | null,
                tenantCode: string,
                displaySettings?:  {
                  __typename: "DisplaySettings",
                  theme?: string | null,
                  color?: string | null,
                } | null,
                locale?: string | null,
                avatar?: string | null,
                enabled: boolean,
                signedInAtLeastOnce: boolean,
                emailConfirmed?: boolean | null,
                confirmedTermsOfServiceVersions?:  {
                  __typename: "ConfirmedTermsOfServiceVersions",
                  termsOfServiceVersion?: string | null,
                  privacyPolicyVersion?: string | null,
                  tenantOwnTermsOfServiceVersion?: number | null,
                } | null,
                createdAt: string,
                updatedAt: string,
                tags?:  {
                  __typename: "ModelUserUserTagConnection",
                  nextToken?: string | null,
                } | null,
                statuses?:  {
                  __typename: "ModelUserStatusTableConnection",
                  nextToken?: string | null,
                } | null,
              },
              userTag:  {
                __typename: "UserTag",
                id: string,
                color: string,
                text: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                users?:  {
                  __typename: "ModelUserUserTagConnection",
                  nextToken?: string | null,
                } | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateUserUserTagMutationVariables = {
  input: CreateUserUserTagInput,
  condition?: ModelUserUserTagConditionInput | null,
};

export type CreateUserUserTagMutation = {
  createUserUserTag?:  {
    __typename: "UserUserTag",
    id: string,
    userId: string,
    userTagId: string,
    tenantCode: string,
    createdAt: string,
    updatedAt: string,
    user:  {
      __typename: "User",
      id: string,
      name: string,
      role: Role,
      extensionConfigJsonList: Array< string >,
      email?: string | null,
      code?: string | null,
      tenantCode: string,
      displaySettings?:  {
        __typename: "DisplaySettings",
        theme?: string | null,
        color?: string | null,
      } | null,
      locale?: string | null,
      avatar?: string | null,
      enabled: boolean,
      signedInAtLeastOnce: boolean,
      emailConfirmed?: boolean | null,
      confirmedTermsOfServiceVersions?:  {
        __typename: "ConfirmedTermsOfServiceVersions",
        termsOfServiceVersion?: string | null,
        privacyPolicyVersion?: string | null,
        tenantOwnTermsOfServiceVersion?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      tags?:  {
        __typename: "ModelUserUserTagConnection",
        items:  Array< {
          __typename: "UserUserTag",
          id: string,
          userId: string,
          userTagId: string,
          tenantCode: string,
          createdAt: string,
          updatedAt: string,
          user:  {
            __typename: "User",
            id: string,
            name: string,
            role: Role,
            extensionConfigJsonList: Array< string >,
            email?: string | null,
            code?: string | null,
            tenantCode: string,
            displaySettings?:  {
              __typename: "DisplaySettings",
              theme?: string | null,
              color?: string | null,
            } | null,
            locale?: string | null,
            avatar?: string | null,
            enabled: boolean,
            signedInAtLeastOnce: boolean,
            emailConfirmed?: boolean | null,
            confirmedTermsOfServiceVersions?:  {
              __typename: "ConfirmedTermsOfServiceVersions",
              termsOfServiceVersion?: string | null,
              privacyPolicyVersion?: string | null,
              tenantOwnTermsOfServiceVersion?: number | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            tags?:  {
              __typename: "ModelUserUserTagConnection",
              items:  Array< {
                __typename: "UserUserTag",
                id: string,
                userId: string,
                userTagId: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
                userTag:  {
                  __typename: "UserTag",
                  id: string,
                  color: string,
                  text: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
            statuses?:  {
              __typename: "ModelUserStatusTableConnection",
              items:  Array< {
                __typename: "UserStatusTable",
                id: string,
                tenantCode: string,
                status: UserStatus,
                userId: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          userTag:  {
            __typename: "UserTag",
            id: string,
            color: string,
            text: string,
            tenantCode: string,
            createdAt: string,
            updatedAt: string,
            users?:  {
              __typename: "ModelUserUserTagConnection",
              items:  Array< {
                __typename: "UserUserTag",
                id: string,
                userId: string,
                userTagId: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
                userTag:  {
                  __typename: "UserTag",
                  id: string,
                  color: string,
                  text: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      statuses?:  {
        __typename: "ModelUserStatusTableConnection",
        items:  Array< {
          __typename: "UserStatusTable",
          id: string,
          tenantCode: string,
          status: UserStatus,
          userId: string,
          createdAt: string,
          updatedAt: string,
          user:  {
            __typename: "User",
            id: string,
            name: string,
            role: Role,
            extensionConfigJsonList: Array< string >,
            email?: string | null,
            code?: string | null,
            tenantCode: string,
            displaySettings?:  {
              __typename: "DisplaySettings",
              theme?: string | null,
              color?: string | null,
            } | null,
            locale?: string | null,
            avatar?: string | null,
            enabled: boolean,
            signedInAtLeastOnce: boolean,
            emailConfirmed?: boolean | null,
            confirmedTermsOfServiceVersions?:  {
              __typename: "ConfirmedTermsOfServiceVersions",
              termsOfServiceVersion?: string | null,
              privacyPolicyVersion?: string | null,
              tenantOwnTermsOfServiceVersion?: number | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            tags?:  {
              __typename: "ModelUserUserTagConnection",
              items:  Array< {
                __typename: "UserUserTag",
                id: string,
                userId: string,
                userTagId: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
                userTag:  {
                  __typename: "UserTag",
                  id: string,
                  color: string,
                  text: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
            statuses?:  {
              __typename: "ModelUserStatusTableConnection",
              items:  Array< {
                __typename: "UserStatusTable",
                id: string,
                tenantCode: string,
                status: UserStatus,
                userId: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    userTag:  {
      __typename: "UserTag",
      id: string,
      color: string,
      text: string,
      tenantCode: string,
      createdAt: string,
      updatedAt: string,
      users?:  {
        __typename: "ModelUserUserTagConnection",
        items:  Array< {
          __typename: "UserUserTag",
          id: string,
          userId: string,
          userTagId: string,
          tenantCode: string,
          createdAt: string,
          updatedAt: string,
          user:  {
            __typename: "User",
            id: string,
            name: string,
            role: Role,
            extensionConfigJsonList: Array< string >,
            email?: string | null,
            code?: string | null,
            tenantCode: string,
            displaySettings?:  {
              __typename: "DisplaySettings",
              theme?: string | null,
              color?: string | null,
            } | null,
            locale?: string | null,
            avatar?: string | null,
            enabled: boolean,
            signedInAtLeastOnce: boolean,
            emailConfirmed?: boolean | null,
            confirmedTermsOfServiceVersions?:  {
              __typename: "ConfirmedTermsOfServiceVersions",
              termsOfServiceVersion?: string | null,
              privacyPolicyVersion?: string | null,
              tenantOwnTermsOfServiceVersion?: number | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            tags?:  {
              __typename: "ModelUserUserTagConnection",
              items:  Array< {
                __typename: "UserUserTag",
                id: string,
                userId: string,
                userTagId: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
                userTag:  {
                  __typename: "UserTag",
                  id: string,
                  color: string,
                  text: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
            statuses?:  {
              __typename: "ModelUserStatusTableConnection",
              items:  Array< {
                __typename: "UserStatusTable",
                id: string,
                tenantCode: string,
                status: UserStatus,
                userId: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          userTag:  {
            __typename: "UserTag",
            id: string,
            color: string,
            text: string,
            tenantCode: string,
            createdAt: string,
            updatedAt: string,
            users?:  {
              __typename: "ModelUserUserTagConnection",
              items:  Array< {
                __typename: "UserUserTag",
                id: string,
                userId: string,
                userTagId: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
                userTag:  {
                  __typename: "UserTag",
                  id: string,
                  color: string,
                  text: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type UpdateUserUserTagMutationVariables = {
  input: UpdateUserUserTagInput,
  condition?: ModelUserUserTagConditionInput | null,
};

export type UpdateUserUserTagMutation = {
  updateUserUserTag?:  {
    __typename: "UserUserTag",
    id: string,
    userId: string,
    userTagId: string,
    tenantCode: string,
    createdAt: string,
    updatedAt: string,
    user:  {
      __typename: "User",
      id: string,
      name: string,
      role: Role,
      extensionConfigJsonList: Array< string >,
      email?: string | null,
      code?: string | null,
      tenantCode: string,
      displaySettings?:  {
        __typename: "DisplaySettings",
        theme?: string | null,
        color?: string | null,
      } | null,
      locale?: string | null,
      avatar?: string | null,
      enabled: boolean,
      signedInAtLeastOnce: boolean,
      emailConfirmed?: boolean | null,
      confirmedTermsOfServiceVersions?:  {
        __typename: "ConfirmedTermsOfServiceVersions",
        termsOfServiceVersion?: string | null,
        privacyPolicyVersion?: string | null,
        tenantOwnTermsOfServiceVersion?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      tags?:  {
        __typename: "ModelUserUserTagConnection",
        items:  Array< {
          __typename: "UserUserTag",
          id: string,
          userId: string,
          userTagId: string,
          tenantCode: string,
          createdAt: string,
          updatedAt: string,
          user:  {
            __typename: "User",
            id: string,
            name: string,
            role: Role,
            extensionConfigJsonList: Array< string >,
            email?: string | null,
            code?: string | null,
            tenantCode: string,
            displaySettings?:  {
              __typename: "DisplaySettings",
              theme?: string | null,
              color?: string | null,
            } | null,
            locale?: string | null,
            avatar?: string | null,
            enabled: boolean,
            signedInAtLeastOnce: boolean,
            emailConfirmed?: boolean | null,
            confirmedTermsOfServiceVersions?:  {
              __typename: "ConfirmedTermsOfServiceVersions",
              termsOfServiceVersion?: string | null,
              privacyPolicyVersion?: string | null,
              tenantOwnTermsOfServiceVersion?: number | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            tags?:  {
              __typename: "ModelUserUserTagConnection",
              items:  Array< {
                __typename: "UserUserTag",
                id: string,
                userId: string,
                userTagId: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
                userTag:  {
                  __typename: "UserTag",
                  id: string,
                  color: string,
                  text: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
            statuses?:  {
              __typename: "ModelUserStatusTableConnection",
              items:  Array< {
                __typename: "UserStatusTable",
                id: string,
                tenantCode: string,
                status: UserStatus,
                userId: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          userTag:  {
            __typename: "UserTag",
            id: string,
            color: string,
            text: string,
            tenantCode: string,
            createdAt: string,
            updatedAt: string,
            users?:  {
              __typename: "ModelUserUserTagConnection",
              items:  Array< {
                __typename: "UserUserTag",
                id: string,
                userId: string,
                userTagId: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
                userTag:  {
                  __typename: "UserTag",
                  id: string,
                  color: string,
                  text: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      statuses?:  {
        __typename: "ModelUserStatusTableConnection",
        items:  Array< {
          __typename: "UserStatusTable",
          id: string,
          tenantCode: string,
          status: UserStatus,
          userId: string,
          createdAt: string,
          updatedAt: string,
          user:  {
            __typename: "User",
            id: string,
            name: string,
            role: Role,
            extensionConfigJsonList: Array< string >,
            email?: string | null,
            code?: string | null,
            tenantCode: string,
            displaySettings?:  {
              __typename: "DisplaySettings",
              theme?: string | null,
              color?: string | null,
            } | null,
            locale?: string | null,
            avatar?: string | null,
            enabled: boolean,
            signedInAtLeastOnce: boolean,
            emailConfirmed?: boolean | null,
            confirmedTermsOfServiceVersions?:  {
              __typename: "ConfirmedTermsOfServiceVersions",
              termsOfServiceVersion?: string | null,
              privacyPolicyVersion?: string | null,
              tenantOwnTermsOfServiceVersion?: number | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            tags?:  {
              __typename: "ModelUserUserTagConnection",
              items:  Array< {
                __typename: "UserUserTag",
                id: string,
                userId: string,
                userTagId: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
                userTag:  {
                  __typename: "UserTag",
                  id: string,
                  color: string,
                  text: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
            statuses?:  {
              __typename: "ModelUserStatusTableConnection",
              items:  Array< {
                __typename: "UserStatusTable",
                id: string,
                tenantCode: string,
                status: UserStatus,
                userId: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    userTag:  {
      __typename: "UserTag",
      id: string,
      color: string,
      text: string,
      tenantCode: string,
      createdAt: string,
      updatedAt: string,
      users?:  {
        __typename: "ModelUserUserTagConnection",
        items:  Array< {
          __typename: "UserUserTag",
          id: string,
          userId: string,
          userTagId: string,
          tenantCode: string,
          createdAt: string,
          updatedAt: string,
          user:  {
            __typename: "User",
            id: string,
            name: string,
            role: Role,
            extensionConfigJsonList: Array< string >,
            email?: string | null,
            code?: string | null,
            tenantCode: string,
            displaySettings?:  {
              __typename: "DisplaySettings",
              theme?: string | null,
              color?: string | null,
            } | null,
            locale?: string | null,
            avatar?: string | null,
            enabled: boolean,
            signedInAtLeastOnce: boolean,
            emailConfirmed?: boolean | null,
            confirmedTermsOfServiceVersions?:  {
              __typename: "ConfirmedTermsOfServiceVersions",
              termsOfServiceVersion?: string | null,
              privacyPolicyVersion?: string | null,
              tenantOwnTermsOfServiceVersion?: number | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            tags?:  {
              __typename: "ModelUserUserTagConnection",
              items:  Array< {
                __typename: "UserUserTag",
                id: string,
                userId: string,
                userTagId: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
                userTag:  {
                  __typename: "UserTag",
                  id: string,
                  color: string,
                  text: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
            statuses?:  {
              __typename: "ModelUserStatusTableConnection",
              items:  Array< {
                __typename: "UserStatusTable",
                id: string,
                tenantCode: string,
                status: UserStatus,
                userId: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          userTag:  {
            __typename: "UserTag",
            id: string,
            color: string,
            text: string,
            tenantCode: string,
            createdAt: string,
            updatedAt: string,
            users?:  {
              __typename: "ModelUserUserTagConnection",
              items:  Array< {
                __typename: "UserUserTag",
                id: string,
                userId: string,
                userTagId: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
                userTag:  {
                  __typename: "UserTag",
                  id: string,
                  color: string,
                  text: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type DeleteUserUserTagMutationVariables = {
  input: DeleteUserUserTagInput,
  condition?: ModelUserUserTagConditionInput | null,
};

export type DeleteUserUserTagMutation = {
  deleteUserUserTag?:  {
    __typename: "UserUserTag",
    id: string,
    userId: string,
    userTagId: string,
    tenantCode: string,
    createdAt: string,
    updatedAt: string,
    user:  {
      __typename: "User",
      id: string,
      name: string,
      role: Role,
      extensionConfigJsonList: Array< string >,
      email?: string | null,
      code?: string | null,
      tenantCode: string,
      displaySettings?:  {
        __typename: "DisplaySettings",
        theme?: string | null,
        color?: string | null,
      } | null,
      locale?: string | null,
      avatar?: string | null,
      enabled: boolean,
      signedInAtLeastOnce: boolean,
      emailConfirmed?: boolean | null,
      confirmedTermsOfServiceVersions?:  {
        __typename: "ConfirmedTermsOfServiceVersions",
        termsOfServiceVersion?: string | null,
        privacyPolicyVersion?: string | null,
        tenantOwnTermsOfServiceVersion?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      tags?:  {
        __typename: "ModelUserUserTagConnection",
        items:  Array< {
          __typename: "UserUserTag",
          id: string,
          userId: string,
          userTagId: string,
          tenantCode: string,
          createdAt: string,
          updatedAt: string,
          user:  {
            __typename: "User",
            id: string,
            name: string,
            role: Role,
            extensionConfigJsonList: Array< string >,
            email?: string | null,
            code?: string | null,
            tenantCode: string,
            displaySettings?:  {
              __typename: "DisplaySettings",
              theme?: string | null,
              color?: string | null,
            } | null,
            locale?: string | null,
            avatar?: string | null,
            enabled: boolean,
            signedInAtLeastOnce: boolean,
            emailConfirmed?: boolean | null,
            confirmedTermsOfServiceVersions?:  {
              __typename: "ConfirmedTermsOfServiceVersions",
              termsOfServiceVersion?: string | null,
              privacyPolicyVersion?: string | null,
              tenantOwnTermsOfServiceVersion?: number | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            tags?:  {
              __typename: "ModelUserUserTagConnection",
              items:  Array< {
                __typename: "UserUserTag",
                id: string,
                userId: string,
                userTagId: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
                userTag:  {
                  __typename: "UserTag",
                  id: string,
                  color: string,
                  text: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
            statuses?:  {
              __typename: "ModelUserStatusTableConnection",
              items:  Array< {
                __typename: "UserStatusTable",
                id: string,
                tenantCode: string,
                status: UserStatus,
                userId: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          userTag:  {
            __typename: "UserTag",
            id: string,
            color: string,
            text: string,
            tenantCode: string,
            createdAt: string,
            updatedAt: string,
            users?:  {
              __typename: "ModelUserUserTagConnection",
              items:  Array< {
                __typename: "UserUserTag",
                id: string,
                userId: string,
                userTagId: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
                userTag:  {
                  __typename: "UserTag",
                  id: string,
                  color: string,
                  text: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      statuses?:  {
        __typename: "ModelUserStatusTableConnection",
        items:  Array< {
          __typename: "UserStatusTable",
          id: string,
          tenantCode: string,
          status: UserStatus,
          userId: string,
          createdAt: string,
          updatedAt: string,
          user:  {
            __typename: "User",
            id: string,
            name: string,
            role: Role,
            extensionConfigJsonList: Array< string >,
            email?: string | null,
            code?: string | null,
            tenantCode: string,
            displaySettings?:  {
              __typename: "DisplaySettings",
              theme?: string | null,
              color?: string | null,
            } | null,
            locale?: string | null,
            avatar?: string | null,
            enabled: boolean,
            signedInAtLeastOnce: boolean,
            emailConfirmed?: boolean | null,
            confirmedTermsOfServiceVersions?:  {
              __typename: "ConfirmedTermsOfServiceVersions",
              termsOfServiceVersion?: string | null,
              privacyPolicyVersion?: string | null,
              tenantOwnTermsOfServiceVersion?: number | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            tags?:  {
              __typename: "ModelUserUserTagConnection",
              items:  Array< {
                __typename: "UserUserTag",
                id: string,
                userId: string,
                userTagId: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
                userTag:  {
                  __typename: "UserTag",
                  id: string,
                  color: string,
                  text: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
            statuses?:  {
              __typename: "ModelUserStatusTableConnection",
              items:  Array< {
                __typename: "UserStatusTable",
                id: string,
                tenantCode: string,
                status: UserStatus,
                userId: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    userTag:  {
      __typename: "UserTag",
      id: string,
      color: string,
      text: string,
      tenantCode: string,
      createdAt: string,
      updatedAt: string,
      users?:  {
        __typename: "ModelUserUserTagConnection",
        items:  Array< {
          __typename: "UserUserTag",
          id: string,
          userId: string,
          userTagId: string,
          tenantCode: string,
          createdAt: string,
          updatedAt: string,
          user:  {
            __typename: "User",
            id: string,
            name: string,
            role: Role,
            extensionConfigJsonList: Array< string >,
            email?: string | null,
            code?: string | null,
            tenantCode: string,
            displaySettings?:  {
              __typename: "DisplaySettings",
              theme?: string | null,
              color?: string | null,
            } | null,
            locale?: string | null,
            avatar?: string | null,
            enabled: boolean,
            signedInAtLeastOnce: boolean,
            emailConfirmed?: boolean | null,
            confirmedTermsOfServiceVersions?:  {
              __typename: "ConfirmedTermsOfServiceVersions",
              termsOfServiceVersion?: string | null,
              privacyPolicyVersion?: string | null,
              tenantOwnTermsOfServiceVersion?: number | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            tags?:  {
              __typename: "ModelUserUserTagConnection",
              items:  Array< {
                __typename: "UserUserTag",
                id: string,
                userId: string,
                userTagId: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
                userTag:  {
                  __typename: "UserTag",
                  id: string,
                  color: string,
                  text: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
            statuses?:  {
              __typename: "ModelUserStatusTableConnection",
              items:  Array< {
                __typename: "UserStatusTable",
                id: string,
                tenantCode: string,
                status: UserStatus,
                userId: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          userTag:  {
            __typename: "UserTag",
            id: string,
            color: string,
            text: string,
            tenantCode: string,
            createdAt: string,
            updatedAt: string,
            users?:  {
              __typename: "ModelUserUserTagConnection",
              items:  Array< {
                __typename: "UserUserTag",
                id: string,
                userId: string,
                userTagId: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
                userTag:  {
                  __typename: "UserTag",
                  id: string,
                  color: string,
                  text: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type CreateUserStatusTableMutationVariables = {
  input: CreateUserStatusTableInput,
  condition?: ModelUserStatusTableConditionInput | null,
};

export type CreateUserStatusTableMutation = {
  createUserStatusTable?:  {
    __typename: "UserStatusTable",
    id: string,
    tenantCode: string,
    status: UserStatus,
    userId: string,
    createdAt: string,
    updatedAt: string,
    user:  {
      __typename: "User",
      id: string,
      name: string,
      role: Role,
      extensionConfigJsonList: Array< string >,
      email?: string | null,
      code?: string | null,
      tenantCode: string,
      displaySettings?:  {
        __typename: "DisplaySettings",
        theme?: string | null,
        color?: string | null,
      } | null,
      locale?: string | null,
      avatar?: string | null,
      enabled: boolean,
      signedInAtLeastOnce: boolean,
      emailConfirmed?: boolean | null,
      confirmedTermsOfServiceVersions?:  {
        __typename: "ConfirmedTermsOfServiceVersions",
        termsOfServiceVersion?: string | null,
        privacyPolicyVersion?: string | null,
        tenantOwnTermsOfServiceVersion?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      tags?:  {
        __typename: "ModelUserUserTagConnection",
        items:  Array< {
          __typename: "UserUserTag",
          id: string,
          userId: string,
          userTagId: string,
          tenantCode: string,
          createdAt: string,
          updatedAt: string,
          user:  {
            __typename: "User",
            id: string,
            name: string,
            role: Role,
            extensionConfigJsonList: Array< string >,
            email?: string | null,
            code?: string | null,
            tenantCode: string,
            displaySettings?:  {
              __typename: "DisplaySettings",
              theme?: string | null,
              color?: string | null,
            } | null,
            locale?: string | null,
            avatar?: string | null,
            enabled: boolean,
            signedInAtLeastOnce: boolean,
            emailConfirmed?: boolean | null,
            confirmedTermsOfServiceVersions?:  {
              __typename: "ConfirmedTermsOfServiceVersions",
              termsOfServiceVersion?: string | null,
              privacyPolicyVersion?: string | null,
              tenantOwnTermsOfServiceVersion?: number | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            tags?:  {
              __typename: "ModelUserUserTagConnection",
              items:  Array< {
                __typename: "UserUserTag",
                id: string,
                userId: string,
                userTagId: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
                userTag:  {
                  __typename: "UserTag",
                  id: string,
                  color: string,
                  text: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
            statuses?:  {
              __typename: "ModelUserStatusTableConnection",
              items:  Array< {
                __typename: "UserStatusTable",
                id: string,
                tenantCode: string,
                status: UserStatus,
                userId: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          userTag:  {
            __typename: "UserTag",
            id: string,
            color: string,
            text: string,
            tenantCode: string,
            createdAt: string,
            updatedAt: string,
            users?:  {
              __typename: "ModelUserUserTagConnection",
              items:  Array< {
                __typename: "UserUserTag",
                id: string,
                userId: string,
                userTagId: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
                userTag:  {
                  __typename: "UserTag",
                  id: string,
                  color: string,
                  text: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      statuses?:  {
        __typename: "ModelUserStatusTableConnection",
        items:  Array< {
          __typename: "UserStatusTable",
          id: string,
          tenantCode: string,
          status: UserStatus,
          userId: string,
          createdAt: string,
          updatedAt: string,
          user:  {
            __typename: "User",
            id: string,
            name: string,
            role: Role,
            extensionConfigJsonList: Array< string >,
            email?: string | null,
            code?: string | null,
            tenantCode: string,
            displaySettings?:  {
              __typename: "DisplaySettings",
              theme?: string | null,
              color?: string | null,
            } | null,
            locale?: string | null,
            avatar?: string | null,
            enabled: boolean,
            signedInAtLeastOnce: boolean,
            emailConfirmed?: boolean | null,
            confirmedTermsOfServiceVersions?:  {
              __typename: "ConfirmedTermsOfServiceVersions",
              termsOfServiceVersion?: string | null,
              privacyPolicyVersion?: string | null,
              tenantOwnTermsOfServiceVersion?: number | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            tags?:  {
              __typename: "ModelUserUserTagConnection",
              items:  Array< {
                __typename: "UserUserTag",
                id: string,
                userId: string,
                userTagId: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
                userTag:  {
                  __typename: "UserTag",
                  id: string,
                  color: string,
                  text: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
            statuses?:  {
              __typename: "ModelUserStatusTableConnection",
              items:  Array< {
                __typename: "UserStatusTable",
                id: string,
                tenantCode: string,
                status: UserStatus,
                userId: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type UpdateUserStatusTableMutationVariables = {
  input: UpdateUserStatusTableInput,
  condition?: ModelUserStatusTableConditionInput | null,
};

export type UpdateUserStatusTableMutation = {
  updateUserStatusTable?:  {
    __typename: "UserStatusTable",
    id: string,
    tenantCode: string,
    status: UserStatus,
    userId: string,
    createdAt: string,
    updatedAt: string,
    user:  {
      __typename: "User",
      id: string,
      name: string,
      role: Role,
      extensionConfigJsonList: Array< string >,
      email?: string | null,
      code?: string | null,
      tenantCode: string,
      displaySettings?:  {
        __typename: "DisplaySettings",
        theme?: string | null,
        color?: string | null,
      } | null,
      locale?: string | null,
      avatar?: string | null,
      enabled: boolean,
      signedInAtLeastOnce: boolean,
      emailConfirmed?: boolean | null,
      confirmedTermsOfServiceVersions?:  {
        __typename: "ConfirmedTermsOfServiceVersions",
        termsOfServiceVersion?: string | null,
        privacyPolicyVersion?: string | null,
        tenantOwnTermsOfServiceVersion?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      tags?:  {
        __typename: "ModelUserUserTagConnection",
        items:  Array< {
          __typename: "UserUserTag",
          id: string,
          userId: string,
          userTagId: string,
          tenantCode: string,
          createdAt: string,
          updatedAt: string,
          user:  {
            __typename: "User",
            id: string,
            name: string,
            role: Role,
            extensionConfigJsonList: Array< string >,
            email?: string | null,
            code?: string | null,
            tenantCode: string,
            displaySettings?:  {
              __typename: "DisplaySettings",
              theme?: string | null,
              color?: string | null,
            } | null,
            locale?: string | null,
            avatar?: string | null,
            enabled: boolean,
            signedInAtLeastOnce: boolean,
            emailConfirmed?: boolean | null,
            confirmedTermsOfServiceVersions?:  {
              __typename: "ConfirmedTermsOfServiceVersions",
              termsOfServiceVersion?: string | null,
              privacyPolicyVersion?: string | null,
              tenantOwnTermsOfServiceVersion?: number | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            tags?:  {
              __typename: "ModelUserUserTagConnection",
              items:  Array< {
                __typename: "UserUserTag",
                id: string,
                userId: string,
                userTagId: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
                userTag:  {
                  __typename: "UserTag",
                  id: string,
                  color: string,
                  text: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
            statuses?:  {
              __typename: "ModelUserStatusTableConnection",
              items:  Array< {
                __typename: "UserStatusTable",
                id: string,
                tenantCode: string,
                status: UserStatus,
                userId: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          userTag:  {
            __typename: "UserTag",
            id: string,
            color: string,
            text: string,
            tenantCode: string,
            createdAt: string,
            updatedAt: string,
            users?:  {
              __typename: "ModelUserUserTagConnection",
              items:  Array< {
                __typename: "UserUserTag",
                id: string,
                userId: string,
                userTagId: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
                userTag:  {
                  __typename: "UserTag",
                  id: string,
                  color: string,
                  text: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      statuses?:  {
        __typename: "ModelUserStatusTableConnection",
        items:  Array< {
          __typename: "UserStatusTable",
          id: string,
          tenantCode: string,
          status: UserStatus,
          userId: string,
          createdAt: string,
          updatedAt: string,
          user:  {
            __typename: "User",
            id: string,
            name: string,
            role: Role,
            extensionConfigJsonList: Array< string >,
            email?: string | null,
            code?: string | null,
            tenantCode: string,
            displaySettings?:  {
              __typename: "DisplaySettings",
              theme?: string | null,
              color?: string | null,
            } | null,
            locale?: string | null,
            avatar?: string | null,
            enabled: boolean,
            signedInAtLeastOnce: boolean,
            emailConfirmed?: boolean | null,
            confirmedTermsOfServiceVersions?:  {
              __typename: "ConfirmedTermsOfServiceVersions",
              termsOfServiceVersion?: string | null,
              privacyPolicyVersion?: string | null,
              tenantOwnTermsOfServiceVersion?: number | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            tags?:  {
              __typename: "ModelUserUserTagConnection",
              items:  Array< {
                __typename: "UserUserTag",
                id: string,
                userId: string,
                userTagId: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
                userTag:  {
                  __typename: "UserTag",
                  id: string,
                  color: string,
                  text: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
            statuses?:  {
              __typename: "ModelUserStatusTableConnection",
              items:  Array< {
                __typename: "UserStatusTable",
                id: string,
                tenantCode: string,
                status: UserStatus,
                userId: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type DeleteUserStatusTableMutationVariables = {
  input: DeleteUserStatusTableInput,
  condition?: ModelUserStatusTableConditionInput | null,
};

export type DeleteUserStatusTableMutation = {
  deleteUserStatusTable?:  {
    __typename: "UserStatusTable",
    id: string,
    tenantCode: string,
    status: UserStatus,
    userId: string,
    createdAt: string,
    updatedAt: string,
    user:  {
      __typename: "User",
      id: string,
      name: string,
      role: Role,
      extensionConfigJsonList: Array< string >,
      email?: string | null,
      code?: string | null,
      tenantCode: string,
      displaySettings?:  {
        __typename: "DisplaySettings",
        theme?: string | null,
        color?: string | null,
      } | null,
      locale?: string | null,
      avatar?: string | null,
      enabled: boolean,
      signedInAtLeastOnce: boolean,
      emailConfirmed?: boolean | null,
      confirmedTermsOfServiceVersions?:  {
        __typename: "ConfirmedTermsOfServiceVersions",
        termsOfServiceVersion?: string | null,
        privacyPolicyVersion?: string | null,
        tenantOwnTermsOfServiceVersion?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      tags?:  {
        __typename: "ModelUserUserTagConnection",
        items:  Array< {
          __typename: "UserUserTag",
          id: string,
          userId: string,
          userTagId: string,
          tenantCode: string,
          createdAt: string,
          updatedAt: string,
          user:  {
            __typename: "User",
            id: string,
            name: string,
            role: Role,
            extensionConfigJsonList: Array< string >,
            email?: string | null,
            code?: string | null,
            tenantCode: string,
            displaySettings?:  {
              __typename: "DisplaySettings",
              theme?: string | null,
              color?: string | null,
            } | null,
            locale?: string | null,
            avatar?: string | null,
            enabled: boolean,
            signedInAtLeastOnce: boolean,
            emailConfirmed?: boolean | null,
            confirmedTermsOfServiceVersions?:  {
              __typename: "ConfirmedTermsOfServiceVersions",
              termsOfServiceVersion?: string | null,
              privacyPolicyVersion?: string | null,
              tenantOwnTermsOfServiceVersion?: number | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            tags?:  {
              __typename: "ModelUserUserTagConnection",
              items:  Array< {
                __typename: "UserUserTag",
                id: string,
                userId: string,
                userTagId: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
                userTag:  {
                  __typename: "UserTag",
                  id: string,
                  color: string,
                  text: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
            statuses?:  {
              __typename: "ModelUserStatusTableConnection",
              items:  Array< {
                __typename: "UserStatusTable",
                id: string,
                tenantCode: string,
                status: UserStatus,
                userId: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          userTag:  {
            __typename: "UserTag",
            id: string,
            color: string,
            text: string,
            tenantCode: string,
            createdAt: string,
            updatedAt: string,
            users?:  {
              __typename: "ModelUserUserTagConnection",
              items:  Array< {
                __typename: "UserUserTag",
                id: string,
                userId: string,
                userTagId: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
                userTag:  {
                  __typename: "UserTag",
                  id: string,
                  color: string,
                  text: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      statuses?:  {
        __typename: "ModelUserStatusTableConnection",
        items:  Array< {
          __typename: "UserStatusTable",
          id: string,
          tenantCode: string,
          status: UserStatus,
          userId: string,
          createdAt: string,
          updatedAt: string,
          user:  {
            __typename: "User",
            id: string,
            name: string,
            role: Role,
            extensionConfigJsonList: Array< string >,
            email?: string | null,
            code?: string | null,
            tenantCode: string,
            displaySettings?:  {
              __typename: "DisplaySettings",
              theme?: string | null,
              color?: string | null,
            } | null,
            locale?: string | null,
            avatar?: string | null,
            enabled: boolean,
            signedInAtLeastOnce: boolean,
            emailConfirmed?: boolean | null,
            confirmedTermsOfServiceVersions?:  {
              __typename: "ConfirmedTermsOfServiceVersions",
              termsOfServiceVersion?: string | null,
              privacyPolicyVersion?: string | null,
              tenantOwnTermsOfServiceVersion?: number | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            tags?:  {
              __typename: "ModelUserUserTagConnection",
              items:  Array< {
                __typename: "UserUserTag",
                id: string,
                userId: string,
                userTagId: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
                userTag:  {
                  __typename: "UserTag",
                  id: string,
                  color: string,
                  text: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
            statuses?:  {
              __typename: "ModelUserStatusTableConnection",
              items:  Array< {
                __typename: "UserStatusTable",
                id: string,
                tenantCode: string,
                status: UserStatus,
                userId: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type UpdateGroupStatusUserMutationVariables = {
  input: UpdateGroupStatusUserInput,
  condition?: ModelGroupStatusUserConditionInput | null,
};

export type UpdateGroupStatusUserMutation = {
  updateGroupStatusUser?:  {
    __typename: "GroupStatusUser",
    id: string,
    userId: string,
    groupId: string,
    handStatus: HandStatus,
    tenantCode: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteGroupStatusUserMutationVariables = {
  input: DeleteGroupStatusUserInput,
  condition?: ModelGroupStatusUserConditionInput | null,
};

export type DeleteGroupStatusUserMutation = {
  deleteGroupStatusUser?:  {
    __typename: "GroupStatusUser",
    id: string,
    userId: string,
    groupId: string,
    handStatus: HandStatus,
    tenantCode: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateInternalTaskMutationVariables = {
  input: CreateInternalTaskInput,
  condition?: ModelInternalTaskConditionInput | null,
};

export type CreateInternalTaskMutation = {
  createInternalTask?:  {
    __typename: "InternalTask",
    id: string,
    type: string,
    createdAt?: string | null,
    createdBy: string,
    finishedAt?: string | null,
    status: InternalTaskStatus,
    taskVersion: number,
    payload: string,
    tenantCode: string,
    updatedAt: string,
  } | null,
};

export type UpdateInternalTaskMutationVariables = {
  input: UpdateInternalTaskInput,
  condition?: ModelInternalTaskConditionInput | null,
};

export type UpdateInternalTaskMutation = {
  updateInternalTask?:  {
    __typename: "InternalTask",
    id: string,
    type: string,
    createdAt?: string | null,
    createdBy: string,
    finishedAt?: string | null,
    status: InternalTaskStatus,
    taskVersion: number,
    payload: string,
    tenantCode: string,
    updatedAt: string,
  } | null,
};

export type DeleteInternalTaskMutationVariables = {
  input: DeleteInternalTaskInput,
  condition?: ModelInternalTaskConditionInput | null,
};

export type DeleteInternalTaskMutation = {
  deleteInternalTask?:  {
    __typename: "InternalTask",
    id: string,
    type: string,
    createdAt?: string | null,
    createdBy: string,
    finishedAt?: string | null,
    status: InternalTaskStatus,
    taskVersion: number,
    payload: string,
    tenantCode: string,
    updatedAt: string,
  } | null,
};

export type CreatePlaygroundMutationVariables = {
  input: CreatePlaygroundInput,
  condition?: ModelPlaygroundConditionInput | null,
};

export type CreatePlaygroundMutation = {
  createPlayground?:  {
    __typename: "Playground",
    id: string,
    status: PlaygroundStatus,
    email: string,
    tenantCode: string,
    tenantName: string,
    userCode?: string | null,
    temporaryPassword?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdatePlaygroundMutationVariables = {
  input: UpdatePlaygroundInput,
  condition?: ModelPlaygroundConditionInput | null,
};

export type UpdatePlaygroundMutation = {
  updatePlayground?:  {
    __typename: "Playground",
    id: string,
    status: PlaygroundStatus,
    email: string,
    tenantCode: string,
    tenantName: string,
    userCode?: string | null,
    temporaryPassword?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeletePlaygroundMutationVariables = {
  input: DeletePlaygroundInput,
  condition?: ModelPlaygroundConditionInput | null,
};

export type DeletePlaygroundMutation = {
  deletePlayground?:  {
    __typename: "Playground",
    id: string,
    status: PlaygroundStatus,
    email: string,
    tenantCode: string,
    tenantName: string,
    userCode?: string | null,
    temporaryPassword?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateContentMutationVariables = {
  input: CreateContentInput,
  condition?: ModelContentConditionInput | null,
};

export type CreateContentMutation = {
  createContent?:  {
    __typename: "Content",
    id: string,
    contentId: string,
    version: number,
    name: string,
    requiredTime: number,
    body:  {
      __typename: "ContentBody",
      type: ContentType,
      text?:  {
        __typename: "TextContent",
        body: string,
      } | null,
      exam?:  {
        __typename: "ExamContent",
        problems:  Array< {
          __typename: "Problem",
          index: number,
          body: string,
          commentary?: string | null,
          type: ProblemType,
          choice?:  {
            __typename: "ChoiceProblem",
            options:  Array< {
              __typename: "ChoiceProblemOption",
              index: number,
              text: string,
            } >,
            multiple: boolean,
            answer: Array< number >,
          } | null,
          headerId?: string | null,
        } >,
        problemHeaders?:  Array< {
          __typename: "ProblemHeader",
          id: string,
          body: string,
          createdAt: string,
        } > | null,
        passingStandard?: number | null,
      } | null,
    },
    workbook?:  {
      __typename: "Workbook",
      problems:  Array< {
        __typename: "Problem",
        index: number,
        body: string,
        commentary?: string | null,
        type: ProblemType,
        choice?:  {
          __typename: "ChoiceProblem",
          options:  Array< {
            __typename: "ChoiceProblemOption",
            index: number,
            text: string,
          } >,
          multiple: boolean,
          answer: Array< number >,
        } | null,
        headerId?: string | null,
      } >,
      problemHeaders?:  Array< {
        __typename: "ProblemHeader",
        id: string,
        body: string,
        createdAt: string,
      } > | null,
    } | null,
    courseId: string,
    courseVersion: number,
    tenantCode: string,
    latest: boolean,
    versionDescription?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateCourseMutationVariables = {
  input: CreateCourseInput,
  condition?: ModelCourseConditionInput | null,
};

export type CreateCourseMutation = {
  createCourse?:  {
    __typename: "Course",
    id: string,
    version: number,
    name: string,
    contents:  Array< {
      __typename: "CourseContent",
      id: string,
      type: ContentType,
      name: string,
      requiredTime: number,
    } >,
    description?: string | null,
    tenantCode: string,
    color?: string | null,
    image?: string | null,
    fontColorOnImage?: CourseFontColorOnImage | null,
    confirmedBy?: string | null,
    createdAt?: string | null,
    versionCreatedBy?: string | null,
    versionCreatedAt?: string | null,
    contentLastUpdatedBy?: string | null,
    contentLastUpdatedAt?: string | null,
    updatedAt: string,
  } | null,
};

export type DeleteEditingCourseMutationVariables = {
  input: DeleteEditingCourseInput,
  condition?: ModelEditingCourseConditionInput | null,
};

export type DeleteEditingCourseMutation = {
  deleteEditingCourse?:  {
    __typename: "EditingCourse",
    id: string,
    tenantCode: string,
    name: string,
    version: number,
    status: EditingStatus,
    description?: string | null,
    contents:  Array< {
      __typename: "EditingCourseContentHeader",
      id: string,
      name: string,
      requiredTime: number,
      type: ContentType,
    } >,
    color?: string | null,
    image?: string | null,
    fontColorOnImage?: CourseFontColorOnImage | null,
    createdBy: string,
    createdAt?: string | null,
    updatedBy: string,
    updatedAt?: string | null,
  } | null,
};

export type CreateEditingCourseContentWorkbookMutationVariables = {
  input: CreateEditingCourseContentWorkbookInput,
  condition?: ModelEditingCourseContentWorkbookConditionInput | null,
};

export type CreateEditingCourseContentWorkbookMutation = {
  createEditingCourseContentWorkbook?:  {
    __typename: "EditingCourseContentWorkbook",
    contentId: string,
    problems:  Array< {
      __typename: "Problem",
      index: number,
      body: string,
      commentary?: string | null,
      type: ProblemType,
      choice?:  {
        __typename: "ChoiceProblem",
        options:  Array< {
          __typename: "ChoiceProblemOption",
          index: number,
          text: string,
        } >,
        multiple: boolean,
        answer: Array< number >,
      } | null,
      headerId?: string | null,
    } >,
    problemHeaders?:  Array< {
      __typename: "ProblemHeader",
      id: string,
      body: string,
      createdAt: string,
    } > | null,
    dataVersion: number,
    createdBy: string,
    updatedBy: string,
    createdAt: string,
    updatedAt: string,
    tenantCode: string,
    courseId: string,
  } | null,
};

export type DeleteEditingCourseContentWorkbookMutationVariables = {
  input: DeleteEditingCourseContentWorkbookInput,
  condition?: ModelEditingCourseContentWorkbookConditionInput | null,
};

export type DeleteEditingCourseContentWorkbookMutation = {
  deleteEditingCourseContentWorkbook?:  {
    __typename: "EditingCourseContentWorkbook",
    contentId: string,
    problems:  Array< {
      __typename: "Problem",
      index: number,
      body: string,
      commentary?: string | null,
      type: ProblemType,
      choice?:  {
        __typename: "ChoiceProblem",
        options:  Array< {
          __typename: "ChoiceProblemOption",
          index: number,
          text: string,
        } >,
        multiple: boolean,
        answer: Array< number >,
      } | null,
      headerId?: string | null,
    } >,
    problemHeaders?:  Array< {
      __typename: "ProblemHeader",
      id: string,
      body: string,
      createdAt: string,
    } > | null,
    dataVersion: number,
    createdBy: string,
    updatedBy: string,
    createdAt: string,
    updatedAt: string,
    tenantCode: string,
    courseId: string,
  } | null,
};

export type CreateEditingCourseContentBodyMutationVariables = {
  input: CreateEditingCourseContentBodyInput,
  condition?: ModelEditingCourseContentBodyConditionInput | null,
};

export type CreateEditingCourseContentBodyMutation = {
  createEditingCourseContentBody?:  {
    __typename: "EditingCourseContentBody",
    contentId: string,
    body:  {
      __typename: "ContentBody",
      type: ContentType,
      text?:  {
        __typename: "TextContent",
        body: string,
      } | null,
      exam?:  {
        __typename: "ExamContent",
        problems:  Array< {
          __typename: "Problem",
          index: number,
          body: string,
          commentary?: string | null,
          type: ProblemType,
          choice?:  {
            __typename: "ChoiceProblem",
            options:  Array< {
              __typename: "ChoiceProblemOption",
              index: number,
              text: string,
            } >,
            multiple: boolean,
            answer: Array< number >,
          } | null,
          headerId?: string | null,
        } >,
        problemHeaders?:  Array< {
          __typename: "ProblemHeader",
          id: string,
          body: string,
          createdAt: string,
        } > | null,
        passingStandard?: number | null,
      } | null,
    },
    dataVersion: number,
    createdBy: string,
    updatedBy: string,
    createdAt: string,
    updatedAt: string,
    tenantCode: string,
    courseId: string,
  } | null,
};

export type DeleteEditingCourseContentBodyMutationVariables = {
  input: DeleteEditingCourseContentBodyInput,
  condition?: ModelEditingCourseContentBodyConditionInput | null,
};

export type DeleteEditingCourseContentBodyMutation = {
  deleteEditingCourseContentBody?:  {
    __typename: "EditingCourseContentBody",
    contentId: string,
    body:  {
      __typename: "ContentBody",
      type: ContentType,
      text?:  {
        __typename: "TextContent",
        body: string,
      } | null,
      exam?:  {
        __typename: "ExamContent",
        problems:  Array< {
          __typename: "Problem",
          index: number,
          body: string,
          commentary?: string | null,
          type: ProblemType,
          choice?:  {
            __typename: "ChoiceProblem",
            options:  Array< {
              __typename: "ChoiceProblemOption",
              index: number,
              text: string,
            } >,
            multiple: boolean,
            answer: Array< number >,
          } | null,
          headerId?: string | null,
        } >,
        problemHeaders?:  Array< {
          __typename: "ProblemHeader",
          id: string,
          body: string,
          createdAt: string,
        } > | null,
        passingStandard?: number | null,
      } | null,
    },
    dataVersion: number,
    createdBy: string,
    updatedBy: string,
    createdAt: string,
    updatedAt: string,
    tenantCode: string,
    courseId: string,
  } | null,
};

export type UpdateCourseHeaderMutationVariables = {
  input: UpdateCourseHeaderInput,
  condition?: ModelCourseHeaderConditionInput | null,
};

export type UpdateCourseHeaderMutation = {
  updateCourseHeader?:  {
    __typename: "CourseHeader",
    id: string,
    name: string,
    activeVersion: number,
    status: CourseStatus,
    originalCourseId?: string | null,
    originalCourseVersion?: number | null,
    tenantCode: string,
    createdBy: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteCourseHeaderMutationVariables = {
  input: DeleteCourseHeaderInput,
  condition?: ModelCourseHeaderConditionInput | null,
};

export type DeleteCourseHeaderMutation = {
  deleteCourseHeader?:  {
    __typename: "CourseHeader",
    id: string,
    name: string,
    activeVersion: number,
    status: CourseStatus,
    originalCourseId?: string | null,
    originalCourseVersion?: number | null,
    tenantCode: string,
    createdBy: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteEditingConfirmedContentMutationVariables = {
  input: DeleteEditingConfirmedContentInput,
  condition?: ModelEditingConfirmedContentConditionInput | null,
};

export type DeleteEditingConfirmedContentMutation = {
  deleteEditingConfirmedContent?:  {
    __typename: "EditingConfirmedContent",
    id: string,
    version: number,
    name: string,
    requiredTime: number,
    body:  {
      __typename: "ContentBody",
      type: ContentType,
      text?:  {
        __typename: "TextContent",
        body: string,
      } | null,
      exam?:  {
        __typename: "ExamContent",
        problems:  Array< {
          __typename: "Problem",
          index: number,
          body: string,
          commentary?: string | null,
          type: ProblemType,
          choice?:  {
            __typename: "ChoiceProblem",
            options:  Array< {
              __typename: "ChoiceProblemOption",
              index: number,
              text: string,
            } >,
            multiple: boolean,
            answer: Array< number >,
          } | null,
          headerId?: string | null,
        } >,
        problemHeaders?:  Array< {
          __typename: "ProblemHeader",
          id: string,
          body: string,
          createdAt: string,
        } > | null,
        passingStandard?: number | null,
      } | null,
    },
    workbook?:  {
      __typename: "Workbook",
      problems:  Array< {
        __typename: "Problem",
        index: number,
        body: string,
        commentary?: string | null,
        type: ProblemType,
        choice?:  {
          __typename: "ChoiceProblem",
          options:  Array< {
            __typename: "ChoiceProblemOption",
            index: number,
            text: string,
          } >,
          multiple: boolean,
          answer: Array< number >,
        } | null,
        headerId?: string | null,
      } >,
      problemHeaders?:  Array< {
        __typename: "ProblemHeader",
        id: string,
        body: string,
        createdAt: string,
      } > | null,
    } | null,
    courseId: string,
    courseVersion: number,
    status: EditingStatus,
    tenantCode: string,
    createdBy: string,
    updatedBy: string,
    dataVersion: number,
    versionDescription?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateEditingCourseMutationVariables = {
  input: CreateEditingCourseInput,
};

export type CreateEditingCourseMutation = {
  createEditingCourse?:  {
    __typename: "EditingCourse",
    id: string,
    tenantCode: string,
    name: string,
    version: number,
    status: EditingStatus,
    description?: string | null,
    contents:  Array< {
      __typename: "EditingCourseContentHeader",
      id: string,
      name: string,
      requiredTime: number,
      type: ContentType,
    } >,
    color?: string | null,
    image?: string | null,
    fontColorOnImage?: CourseFontColorOnImage | null,
    createdBy: string,
    createdAt?: string | null,
    updatedBy: string,
    updatedAt?: string | null,
  } | null,
};

export type CreateExamResultMutationVariables = {
  input: CreateExamResultInput,
  condition?: ModelExamResultConditionInput | null,
};

export type CreateExamResultMutation = {
  createExamResult?:  {
    __typename: "ExamResult",
    id: string,
    groupId: string,
    courseId: string,
    contentId: string,
    contentVersion?: number | null,
    userId: string,
    start: string,
    end?: string | null,
    score?: number | null,
    problemCount: number,
    tenantCode: string,
    answers?: string | null,
    visibilityLevel?: ExamResultVisibilityLevel | null,
    groupExamId?: string | null,
    times?: number | null,
    passingStandard?: number | null,
    version?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateExamResultMutationVariables = {
  input: UpdateExamResultInput,
  condition?: ModelExamResultConditionInput | null,
};

export type UpdateExamResultMutation = {
  updateExamResult?:  {
    __typename: "ExamResult",
    id: string,
    groupId: string,
    courseId: string,
    contentId: string,
    contentVersion?: number | null,
    userId: string,
    start: string,
    end?: string | null,
    score?: number | null,
    problemCount: number,
    tenantCode: string,
    answers?: string | null,
    visibilityLevel?: ExamResultVisibilityLevel | null,
    groupExamId?: string | null,
    times?: number | null,
    passingStandard?: number | null,
    version?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteExamResultMutationVariables = {
  input: DeleteExamResultInput,
  condition?: ModelExamResultConditionInput | null,
};

export type DeleteExamResultMutation = {
  deleteExamResult?:  {
    __typename: "ExamResult",
    id: string,
    groupId: string,
    courseId: string,
    contentId: string,
    contentVersion?: number | null,
    userId: string,
    start: string,
    end?: string | null,
    score?: number | null,
    problemCount: number,
    tenantCode: string,
    answers?: string | null,
    visibilityLevel?: ExamResultVisibilityLevel | null,
    groupExamId?: string | null,
    times?: number | null,
    passingStandard?: number | null,
    version?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateGroupTrainingMutationVariables = {
  input: CreateGroupTrainingInput,
  condition?: ModelGroupTrainingConditionInput | null,
};

export type CreateGroupTrainingMutation = {
  createGroupTraining?:  {
    __typename: "GroupTraining",
    id: string,
    groupId: string,
    tenantCode: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteScheduleScheduleTagMutationVariables = {
  input: DeleteScheduleScheduleTagInput,
  condition?: ModelScheduleScheduleTagConditionInput | null,
};

export type DeleteScheduleScheduleTagMutation = {
  deleteScheduleScheduleTag?:  {
    __typename: "ScheduleScheduleTag",
    id: string,
    scheduleId: string,
    scheduleTagId: string,
    scheduleTag:  {
      __typename: "ScheduleTag",
      id: string,
      color: string,
      text: string,
      groupId: string,
      tenantCode: string,
      createdAt: string,
      updatedAt: string,
      schedules?:  {
        __typename: "ModelScheduleScheduleTagConnection",
        items:  Array< {
          __typename: "ScheduleScheduleTag",
          id: string,
          scheduleId: string,
          scheduleTagId: string,
          scheduleTag:  {
            __typename: "ScheduleTag",
            id: string,
            color: string,
            text: string,
            groupId: string,
            tenantCode: string,
            createdAt: string,
            updatedAt: string,
            schedules?:  {
              __typename: "ModelScheduleScheduleTagConnection",
              items:  Array< {
                __typename: "ScheduleScheduleTag",
                id: string,
                scheduleId: string,
                scheduleTagId: string,
                scheduleTag:  {
                  __typename: "ScheduleTag",
                  id: string,
                  color: string,
                  text: string,
                  groupId: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                schedule:  {
                  __typename: "Schedule",
                  id: string,
                  groupId: string,
                  start: string,
                  end?: string | null,
                  title: string,
                  body?: string | null,
                  tenantCode: string,
                  createdBy?: string | null,
                  updatedBy?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          tenantCode: string,
          createdAt: string,
          updatedAt: string,
          schedule:  {
            __typename: "Schedule",
            id: string,
            groupId: string,
            start: string,
            end?: string | null,
            title: string,
            body?: string | null,
            tenantCode: string,
            createdBy?: string | null,
            updatedBy?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            tags?:  {
              __typename: "ModelScheduleScheduleTagConnection",
              items:  Array< {
                __typename: "ScheduleScheduleTag",
                id: string,
                scheduleId: string,
                scheduleTagId: string,
                scheduleTag:  {
                  __typename: "ScheduleTag",
                  id: string,
                  color: string,
                  text: string,
                  groupId: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                schedule:  {
                  __typename: "Schedule",
                  id: string,
                  groupId: string,
                  start: string,
                  end?: string | null,
                  title: string,
                  body?: string | null,
                  tenantCode: string,
                  createdBy?: string | null,
                  updatedBy?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    tenantCode: string,
    createdAt: string,
    updatedAt: string,
    schedule:  {
      __typename: "Schedule",
      id: string,
      groupId: string,
      start: string,
      end?: string | null,
      title: string,
      body?: string | null,
      tenantCode: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      tags?:  {
        __typename: "ModelScheduleScheduleTagConnection",
        items:  Array< {
          __typename: "ScheduleScheduleTag",
          id: string,
          scheduleId: string,
          scheduleTagId: string,
          scheduleTag:  {
            __typename: "ScheduleTag",
            id: string,
            color: string,
            text: string,
            groupId: string,
            tenantCode: string,
            createdAt: string,
            updatedAt: string,
            schedules?:  {
              __typename: "ModelScheduleScheduleTagConnection",
              items:  Array< {
                __typename: "ScheduleScheduleTag",
                id: string,
                scheduleId: string,
                scheduleTagId: string,
                scheduleTag:  {
                  __typename: "ScheduleTag",
                  id: string,
                  color: string,
                  text: string,
                  groupId: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                schedule:  {
                  __typename: "Schedule",
                  id: string,
                  groupId: string,
                  start: string,
                  end?: string | null,
                  title: string,
                  body?: string | null,
                  tenantCode: string,
                  createdBy?: string | null,
                  updatedBy?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          tenantCode: string,
          createdAt: string,
          updatedAt: string,
          schedule:  {
            __typename: "Schedule",
            id: string,
            groupId: string,
            start: string,
            end?: string | null,
            title: string,
            body?: string | null,
            tenantCode: string,
            createdBy?: string | null,
            updatedBy?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            tags?:  {
              __typename: "ModelScheduleScheduleTagConnection",
              items:  Array< {
                __typename: "ScheduleScheduleTag",
                id: string,
                scheduleId: string,
                scheduleTagId: string,
                scheduleTag:  {
                  __typename: "ScheduleTag",
                  id: string,
                  color: string,
                  text: string,
                  groupId: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                schedule:  {
                  __typename: "Schedule",
                  id: string,
                  groupId: string,
                  start: string,
                  end?: string | null,
                  title: string,
                  body?: string | null,
                  tenantCode: string,
                  createdBy?: string | null,
                  updatedBy?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type CreateContentLearningMutationVariables = {
  input: CreateContentLearningInput,
  condition?: ModelContentLearningConditionInput | null,
};

export type CreateContentLearningMutation = {
  createContentLearning?:  {
    __typename: "ContentLearning",
    groupId: string,
    courseId: string,
    contentId: string,
    contentType: ContentType,
    userId: string,
    status: LearningStatus,
    usageTime: number,
    lastStartedAt?: string | null,
    dataVersion: number,
    tenantCode: string,
    completedAt?: string | null,
    completedUsageTime?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateContentLearningMutationVariables = {
  input: UpdateContentLearningInput,
  condition?: ModelContentLearningConditionInput | null,
};

export type UpdateContentLearningMutation = {
  updateContentLearning?:  {
    __typename: "ContentLearning",
    groupId: string,
    courseId: string,
    contentId: string,
    contentType: ContentType,
    userId: string,
    status: LearningStatus,
    usageTime: number,
    lastStartedAt?: string | null,
    dataVersion: number,
    tenantCode: string,
    completedAt?: string | null,
    completedUsageTime?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateUserExamMutationVariables = {
  input: UpdateUserExamInput,
  condition?: ModelUserExamConditionInput | null,
};

export type UpdateUserExamMutation = {
  updateUserExam?:  {
    __typename: "UserExam",
    id: string,
    userId: string,
    status: UserExamStatus,
    tenantCode: string,
    groupExamId: string,
    groupId: string,
    startedAt?: string | null,
    finishedAt?: string | null,
    createdAt: string,
    updatedAt: string,
    groupExam?:  {
      __typename: "GroupExam",
      id: string,
      groupId: string,
      contentId: string,
      content:  {
        __typename: "GroupExamContent",
        id: string,
        version?: number | null,
        name: string,
        timeLimit?: number | null,
        requiredTime?: number | null,
        indexInCourse?: number | null,
        passingStandard?: number | null,
      },
      problemsJson: string,
      courseId: string,
      course:  {
        __typename: "GroupExamCourse",
        id: string,
        version: number,
        name: string,
        color?: string | null,
        image?: string | null,
        fontColorOnImage?: CourseFontColorOnImage | null,
      },
      scheduledStart: string,
      scheduledFinish?: string | null,
      createdBy?: string | null,
      finishedAt?: string | null,
      visibilityLevel?: ExamResultVisibilityLevel | null,
      tenantCode: string,
      times: number,
      groupTrainingCourseId: string,
      problemHeaders?:  Array< {
        __typename: "ProblemHeader",
        id: string,
        body: string,
        createdAt: string,
      } > | null,
      timeLimit?: number | null,
      passingStandard?: number | null,
      userIdsToBeTested?: Array< string > | null,
      createdAt: string,
      updatedAt: string,
      userExams?:  {
        __typename: "ModelUserExamConnection",
        items:  Array< {
          __typename: "UserExam",
          id: string,
          userId: string,
          status: UserExamStatus,
          tenantCode: string,
          groupExamId: string,
          groupId: string,
          startedAt?: string | null,
          finishedAt?: string | null,
          createdAt: string,
          updatedAt: string,
          groupExam?:  {
            __typename: "GroupExam",
            id: string,
            groupId: string,
            contentId: string,
            content:  {
              __typename: "GroupExamContent",
              id: string,
              version?: number | null,
              name: string,
              timeLimit?: number | null,
              requiredTime?: number | null,
              indexInCourse?: number | null,
              passingStandard?: number | null,
            },
            problemsJson: string,
            courseId: string,
            course:  {
              __typename: "GroupExamCourse",
              id: string,
              version: number,
              name: string,
              color?: string | null,
              image?: string | null,
              fontColorOnImage?: CourseFontColorOnImage | null,
            },
            scheduledStart: string,
            scheduledFinish?: string | null,
            createdBy?: string | null,
            finishedAt?: string | null,
            visibilityLevel?: ExamResultVisibilityLevel | null,
            tenantCode: string,
            times: number,
            groupTrainingCourseId: string,
            problemHeaders?:  Array< {
              __typename: "ProblemHeader",
              id: string,
              body: string,
              createdAt: string,
            } > | null,
            timeLimit?: number | null,
            passingStandard?: number | null,
            userIdsToBeTested?: Array< string > | null,
            createdAt: string,
            updatedAt: string,
            userExams?:  {
              __typename: "ModelUserExamConnection",
              items:  Array< {
                __typename: "UserExam",
                id: string,
                userId: string,
                status: UserExamStatus,
                tenantCode: string,
                groupExamId: string,
                groupId: string,
                startedAt?: string | null,
                finishedAt?: string | null,
                createdAt: string,
                updatedAt: string,
                groupExam?:  {
                  __typename: "GroupExam",
                  id: string,
                  groupId: string,
                  contentId: string,
                  problemsJson: string,
                  courseId: string,
                  scheduledStart: string,
                  scheduledFinish?: string | null,
                  createdBy?: string | null,
                  finishedAt?: string | null,
                  visibilityLevel?: ExamResultVisibilityLevel | null,
                  tenantCode: string,
                  times: number,
                  groupTrainingCourseId: string,
                  timeLimit?: number | null,
                  passingStandard?: number | null,
                  userIdsToBeTested?: Array< string > | null,
                  createdAt: string,
                  updatedAt: string,
                } | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type GetSignedPolicyQuery = {
  getSignedPolicy?:  {
    __typename: "Policy",
    policy: string,
    keyPairId: string,
    signature: string,
    domain: string,
    expire: string,
  } | null,
};

export type GetSignedUrlQueryVariables = {
  filename: string,
};

export type GetSignedUrlQuery = {
  getSignedUrl?:  {
    __typename: "SignedUrl",
    signedUrl: string,
    path: string,
    key: string,
  } | null,
};

export type NowQuery = {
  now: string,
};

export type GetPrivacyPolicyVersionQuery = {
  getPrivacyPolicyVersion:  {
    __typename: "PrivacyPolicyVersion",
    version: string,
  },
};

export type GetNotificationQueryVariables = {
  id: string,
};

export type GetNotificationQuery = {
  getNotification?:  {
    __typename: "Notification",
    id: string,
    type: string,
    payloadJson: string,
    userId: string,
    read: boolean,
    createdAt?: string | null,
    tenantCode: string,
    updatedAt: string,
  } | null,
};

export type ListNotificationsQueryVariables = {
  filter?: ModelNotificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListNotificationsQuery = {
  listNotifications?:  {
    __typename: "ModelNotificationConnection",
    items:  Array< {
      __typename: "Notification",
      id: string,
      type: string,
      payloadJson: string,
      userId: string,
      read: boolean,
      createdAt?: string | null,
      tenantCode: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetLiveQueryVariables = {
  id: string,
};

export type GetLiveQuery = {
  getLive?:  {
    __typename: "Live",
    id: string,
    userId: string,
    url: string,
    applicationSessionId: string,
    tenantCode: string,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type ListLivesQueryVariables = {
  filter?: ModelLiveFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListLivesQuery = {
  listLives?:  {
    __typename: "ModelLiveConnection",
    items:  Array< {
      __typename: "Live",
      id: string,
      userId: string,
      url: string,
      applicationSessionId: string,
      tenantCode: string,
      createdAt?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetQuestionnaireQueryVariables = {
  id: string,
};

export type GetQuestionnaireQuery = {
  getQuestionnaire?:  {
    __typename: "Questionnaire",
    id: string,
    groupId: string,
    tenantCode: string,
    title: string,
    userIds: Array< string >,
    text?: string | null,
    createdBy: string,
    createdAt?: string | null,
    options: Array< string >,
    respondent?:  {
      __typename: "ModelQuestionnaireRespondentConnection",
      items:  Array< {
        __typename: "QuestionnaireRespondent",
        id: string,
        questionnaireId: string,
        userId: string,
        selectedIndex: number,
        tenantCode: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    status: QuestionnaireStatus,
    finishedAt?: string | null,
    updatedAt: string,
  } | null,
};

export type ListQuestionnairesQueryVariables = {
  filter?: ModelQuestionnaireFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListQuestionnairesQuery = {
  listQuestionnaires?:  {
    __typename: "ModelQuestionnaireConnection",
    items:  Array< {
      __typename: "Questionnaire",
      id: string,
      groupId: string,
      tenantCode: string,
      title: string,
      userIds: Array< string >,
      text?: string | null,
      createdBy: string,
      createdAt?: string | null,
      options: Array< string >,
      respondent?:  {
        __typename: "ModelQuestionnaireRespondentConnection",
        items:  Array< {
          __typename: "QuestionnaireRespondent",
          id: string,
          questionnaireId: string,
          userId: string,
          selectedIndex: number,
          tenantCode: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      status: QuestionnaireStatus,
      finishedAt?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetQuestionnaireRespondentQueryVariables = {
  id: string,
};

export type GetQuestionnaireRespondentQuery = {
  getQuestionnaireRespondent?:  {
    __typename: "QuestionnaireRespondent",
    id: string,
    questionnaireId: string,
    userId: string,
    selectedIndex: number,
    tenantCode: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListQuestionnaireRespondentsQueryVariables = {
  filter?: ModelQuestionnaireRespondentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListQuestionnaireRespondentsQuery = {
  listQuestionnaireRespondents?:  {
    __typename: "ModelQuestionnaireRespondentConnection",
    items:  Array< {
      __typename: "QuestionnaireRespondent",
      id: string,
      questionnaireId: string,
      userId: string,
      selectedIndex: number,
      tenantCode: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetScheduleTagQueryVariables = {
  id: string,
};

export type GetScheduleTagQuery = {
  getScheduleTag?:  {
    __typename: "ScheduleTag",
    id: string,
    color: string,
    text: string,
    groupId: string,
    tenantCode: string,
    createdAt: string,
    updatedAt: string,
    schedules?:  {
      __typename: "ModelScheduleScheduleTagConnection",
      items:  Array< {
        __typename: "ScheduleScheduleTag",
        id: string,
        scheduleId: string,
        scheduleTagId: string,
        scheduleTag:  {
          __typename: "ScheduleTag",
          id: string,
          color: string,
          text: string,
          groupId: string,
          tenantCode: string,
          createdAt: string,
          updatedAt: string,
          schedules?:  {
            __typename: "ModelScheduleScheduleTagConnection",
            items:  Array< {
              __typename: "ScheduleScheduleTag",
              id: string,
              scheduleId: string,
              scheduleTagId: string,
              scheduleTag:  {
                __typename: "ScheduleTag",
                id: string,
                color: string,
                text: string,
                groupId: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                schedules?:  {
                  __typename: "ModelScheduleScheduleTagConnection",
                  nextToken?: string | null,
                } | null,
              },
              tenantCode: string,
              createdAt: string,
              updatedAt: string,
              schedule:  {
                __typename: "Schedule",
                id: string,
                groupId: string,
                start: string,
                end?: string | null,
                title: string,
                body?: string | null,
                tenantCode: string,
                createdBy?: string | null,
                updatedBy?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                tags?:  {
                  __typename: "ModelScheduleScheduleTagConnection",
                  nextToken?: string | null,
                } | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        tenantCode: string,
        createdAt: string,
        updatedAt: string,
        schedule:  {
          __typename: "Schedule",
          id: string,
          groupId: string,
          start: string,
          end?: string | null,
          title: string,
          body?: string | null,
          tenantCode: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          tags?:  {
            __typename: "ModelScheduleScheduleTagConnection",
            items:  Array< {
              __typename: "ScheduleScheduleTag",
              id: string,
              scheduleId: string,
              scheduleTagId: string,
              scheduleTag:  {
                __typename: "ScheduleTag",
                id: string,
                color: string,
                text: string,
                groupId: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                schedules?:  {
                  __typename: "ModelScheduleScheduleTagConnection",
                  nextToken?: string | null,
                } | null,
              },
              tenantCode: string,
              createdAt: string,
              updatedAt: string,
              schedule:  {
                __typename: "Schedule",
                id: string,
                groupId: string,
                start: string,
                end?: string | null,
                title: string,
                body?: string | null,
                tenantCode: string,
                createdBy?: string | null,
                updatedBy?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                tags?:  {
                  __typename: "ModelScheduleScheduleTagConnection",
                  nextToken?: string | null,
                } | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListScheduleTagsQueryVariables = {
  filter?: ModelScheduleTagFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListScheduleTagsQuery = {
  listScheduleTags?:  {
    __typename: "ModelScheduleTagConnection",
    items:  Array< {
      __typename: "ScheduleTag",
      id: string,
      color: string,
      text: string,
      groupId: string,
      tenantCode: string,
      createdAt: string,
      updatedAt: string,
      schedules?:  {
        __typename: "ModelScheduleScheduleTagConnection",
        items:  Array< {
          __typename: "ScheduleScheduleTag",
          id: string,
          scheduleId: string,
          scheduleTagId: string,
          scheduleTag:  {
            __typename: "ScheduleTag",
            id: string,
            color: string,
            text: string,
            groupId: string,
            tenantCode: string,
            createdAt: string,
            updatedAt: string,
            schedules?:  {
              __typename: "ModelScheduleScheduleTagConnection",
              items:  Array< {
                __typename: "ScheduleScheduleTag",
                id: string,
                scheduleId: string,
                scheduleTagId: string,
                scheduleTag:  {
                  __typename: "ScheduleTag",
                  id: string,
                  color: string,
                  text: string,
                  groupId: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                schedule:  {
                  __typename: "Schedule",
                  id: string,
                  groupId: string,
                  start: string,
                  end?: string | null,
                  title: string,
                  body?: string | null,
                  tenantCode: string,
                  createdBy?: string | null,
                  updatedBy?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          tenantCode: string,
          createdAt: string,
          updatedAt: string,
          schedule:  {
            __typename: "Schedule",
            id: string,
            groupId: string,
            start: string,
            end?: string | null,
            title: string,
            body?: string | null,
            tenantCode: string,
            createdBy?: string | null,
            updatedBy?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            tags?:  {
              __typename: "ModelScheduleScheduleTagConnection",
              items:  Array< {
                __typename: "ScheduleScheduleTag",
                id: string,
                scheduleId: string,
                scheduleTagId: string,
                scheduleTag:  {
                  __typename: "ScheduleTag",
                  id: string,
                  color: string,
                  text: string,
                  groupId: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                schedule:  {
                  __typename: "Schedule",
                  id: string,
                  groupId: string,
                  start: string,
                  end?: string | null,
                  title: string,
                  body?: string | null,
                  tenantCode: string,
                  createdBy?: string | null,
                  updatedBy?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetMemoQueryVariables = {
  id: string,
};

export type GetMemoQuery = {
  getMemo?:  {
    __typename: "Memo",
    id: string,
    body?: string | null,
    referTo?:  {
      __typename: "ContentReference",
      contentId: string,
      contentVersion?: number | null,
      options?:  {
        __typename: "ContentReferenceOptions",
        text?:  {
          __typename: "TextContentReferenceOptions",
          selectionJson: string,
        } | null,
        workbook?:  {
          __typename: "WorkbookReferenceOptions",
          problemIndex: number,
          selectionJson?: string | null,
        } | null,
      } | null,
    } | null,
    scope?: string | null,
    groupId: string,
    createdBy: string,
    createdAt?: string | null,
    updatedBy?: string | null,
    updatedAt?: string | null,
    tenantCode: string,
  } | null,
};

export type ListMemosQueryVariables = {
  filter?: ModelMemoFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMemosQuery = {
  listMemos?:  {
    __typename: "ModelMemoConnection",
    items:  Array< {
      __typename: "Memo",
      id: string,
      body?: string | null,
      referTo?:  {
        __typename: "ContentReference",
        contentId: string,
        contentVersion?: number | null,
        options?:  {
          __typename: "ContentReferenceOptions",
          text?:  {
            __typename: "TextContentReferenceOptions",
            selectionJson: string,
          } | null,
          workbook?:  {
            __typename: "WorkbookReferenceOptions",
            problemIndex: number,
            selectionJson?: string | null,
          } | null,
        } | null,
      } | null,
      scope?: string | null,
      groupId: string,
      createdBy: string,
      createdAt?: string | null,
      updatedBy?: string | null,
      updatedAt?: string | null,
      tenantCode: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetReviewProblemQueryVariables = {
  id: string,
};

export type GetReviewProblemQuery = {
  getReviewProblem?:  {
    __typename: "ReviewProblem",
    id: string,
    userId: string,
    groupId: string,
    courseId: string,
    problems?: string | null,
    tenantCode: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListReviewProblemsQueryVariables = {
  filter?: ModelReviewProblemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReviewProblemsQuery = {
  listReviewProblems?:  {
    __typename: "ModelReviewProblemConnection",
    items:  Array< {
      __typename: "ReviewProblem",
      id: string,
      userId: string,
      groupId: string,
      courseId: string,
      problems?: string | null,
      tenantCode: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type NotificationsByUserIdQueryVariables = {
  userId?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelNotificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type NotificationsByUserIdQuery = {
  notificationsByUserId?:  {
    __typename: "ModelNotificationConnection",
    items:  Array< {
      __typename: "Notification",
      id: string,
      type: string,
      payloadJson: string,
      userId: string,
      read: boolean,
      createdAt?: string | null,
      tenantCode: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type QuestionnaireByGroupQueryVariables = {
  groupId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelQuestionnaireFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type QuestionnaireByGroupQuery = {
  questionnaireByGroup?:  {
    __typename: "ModelQuestionnaireConnection",
    items:  Array< {
      __typename: "Questionnaire",
      id: string,
      groupId: string,
      tenantCode: string,
      title: string,
      userIds: Array< string >,
      text?: string | null,
      createdBy: string,
      createdAt?: string | null,
      options: Array< string >,
      respondent?:  {
        __typename: "ModelQuestionnaireRespondentConnection",
        items:  Array< {
          __typename: "QuestionnaireRespondent",
          id: string,
          questionnaireId: string,
          userId: string,
          selectedIndex: number,
          tenantCode: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      status: QuestionnaireStatus,
      finishedAt?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type QuestionnaireByTenantCodeQueryVariables = {
  tenantCode?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelQuestionnaireFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type QuestionnaireByTenantCodeQuery = {
  questionnaireByTenantCode?:  {
    __typename: "ModelQuestionnaireConnection",
    items:  Array< {
      __typename: "Questionnaire",
      id: string,
      groupId: string,
      tenantCode: string,
      title: string,
      userIds: Array< string >,
      text?: string | null,
      createdBy: string,
      createdAt?: string | null,
      options: Array< string >,
      respondent?:  {
        __typename: "ModelQuestionnaireRespondentConnection",
        items:  Array< {
          __typename: "QuestionnaireRespondent",
          id: string,
          questionnaireId: string,
          userId: string,
          selectedIndex: number,
          tenantCode: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      status: QuestionnaireStatus,
      finishedAt?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type QuestionnaireRespondentByUserIdQueryVariables = {
  userId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelQuestionnaireRespondentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type QuestionnaireRespondentByUserIdQuery = {
  questionnaireRespondentByUserId?:  {
    __typename: "ModelQuestionnaireRespondentConnection",
    items:  Array< {
      __typename: "QuestionnaireRespondent",
      id: string,
      questionnaireId: string,
      userId: string,
      selectedIndex: number,
      tenantCode: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type QuestionnaireRespondentByQuestionnaireIdAndUserIdQueryVariables = {
  questionnaireId?: string | null,
  userId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelQuestionnaireRespondentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type QuestionnaireRespondentByQuestionnaireIdAndUserIdQuery = {
  questionnaireRespondentByQuestionnaireIdAndUserId?:  {
    __typename: "ModelQuestionnaireRespondentConnection",
    items:  Array< {
      __typename: "QuestionnaireRespondent",
      id: string,
      questionnaireId: string,
      userId: string,
      selectedIndex: number,
      tenantCode: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type QuestionnaireRespondentByTenantCodeQueryVariables = {
  tenantCode?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelQuestionnaireRespondentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type QuestionnaireRespondentByTenantCodeQuery = {
  questionnaireRespondentByTenantCode?:  {
    __typename: "ModelQuestionnaireRespondentConnection",
    items:  Array< {
      __typename: "QuestionnaireRespondent",
      id: string,
      questionnaireId: string,
      userId: string,
      selectedIndex: number,
      tenantCode: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ScheduleTagsByGroupIdQueryVariables = {
  groupId?: string | null,
  text?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelScheduleTagFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ScheduleTagsByGroupIdQuery = {
  scheduleTagsByGroupId?:  {
    __typename: "ModelScheduleTagConnection",
    items:  Array< {
      __typename: "ScheduleTag",
      id: string,
      color: string,
      text: string,
      groupId: string,
      tenantCode: string,
      createdAt: string,
      updatedAt: string,
      schedules?:  {
        __typename: "ModelScheduleScheduleTagConnection",
        items:  Array< {
          __typename: "ScheduleScheduleTag",
          id: string,
          scheduleId: string,
          scheduleTagId: string,
          scheduleTag:  {
            __typename: "ScheduleTag",
            id: string,
            color: string,
            text: string,
            groupId: string,
            tenantCode: string,
            createdAt: string,
            updatedAt: string,
            schedules?:  {
              __typename: "ModelScheduleScheduleTagConnection",
              items:  Array< {
                __typename: "ScheduleScheduleTag",
                id: string,
                scheduleId: string,
                scheduleTagId: string,
                scheduleTag:  {
                  __typename: "ScheduleTag",
                  id: string,
                  color: string,
                  text: string,
                  groupId: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                schedule:  {
                  __typename: "Schedule",
                  id: string,
                  groupId: string,
                  start: string,
                  end?: string | null,
                  title: string,
                  body?: string | null,
                  tenantCode: string,
                  createdBy?: string | null,
                  updatedBy?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          tenantCode: string,
          createdAt: string,
          updatedAt: string,
          schedule:  {
            __typename: "Schedule",
            id: string,
            groupId: string,
            start: string,
            end?: string | null,
            title: string,
            body?: string | null,
            tenantCode: string,
            createdBy?: string | null,
            updatedBy?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            tags?:  {
              __typename: "ModelScheduleScheduleTagConnection",
              items:  Array< {
                __typename: "ScheduleScheduleTag",
                id: string,
                scheduleId: string,
                scheduleTagId: string,
                scheduleTag:  {
                  __typename: "ScheduleTag",
                  id: string,
                  color: string,
                  text: string,
                  groupId: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                schedule:  {
                  __typename: "Schedule",
                  id: string,
                  groupId: string,
                  start: string,
                  end?: string | null,
                  title: string,
                  body?: string | null,
                  tenantCode: string,
                  createdBy?: string | null,
                  updatedBy?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type MemosByGroupQueryVariables = {
  groupId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMemoFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MemosByGroupQuery = {
  memosByGroup?:  {
    __typename: "ModelMemoConnection",
    items:  Array< {
      __typename: "Memo",
      id: string,
      body?: string | null,
      referTo?:  {
        __typename: "ContentReference",
        contentId: string,
        contentVersion?: number | null,
        options?:  {
          __typename: "ContentReferenceOptions",
          text?:  {
            __typename: "TextContentReferenceOptions",
            selectionJson: string,
          } | null,
          workbook?:  {
            __typename: "WorkbookReferenceOptions",
            problemIndex: number,
            selectionJson?: string | null,
          } | null,
        } | null,
      } | null,
      scope?: string | null,
      groupId: string,
      createdBy: string,
      createdAt?: string | null,
      updatedBy?: string | null,
      updatedAt?: string | null,
      tenantCode: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type MemosByTenantCodeQueryVariables = {
  tenantCode?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMemoFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MemosByTenantCodeQuery = {
  memosByTenantCode?:  {
    __typename: "ModelMemoConnection",
    items:  Array< {
      __typename: "Memo",
      id: string,
      body?: string | null,
      referTo?:  {
        __typename: "ContentReference",
        contentId: string,
        contentVersion?: number | null,
        options?:  {
          __typename: "ContentReferenceOptions",
          text?:  {
            __typename: "TextContentReferenceOptions",
            selectionJson: string,
          } | null,
          workbook?:  {
            __typename: "WorkbookReferenceOptions",
            problemIndex: number,
            selectionJson?: string | null,
          } | null,
        } | null,
      } | null,
      scope?: string | null,
      groupId: string,
      createdBy: string,
      createdAt?: string | null,
      updatedBy?: string | null,
      updatedAt?: string | null,
      tenantCode: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ReviewProblemsByGroupAndUserQueryVariables = {
  groupId?: string | null,
  userId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReviewProblemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ReviewProblemsByGroupAndUserQuery = {
  reviewProblemsByGroupAndUser?:  {
    __typename: "ModelReviewProblemConnection",
    items:  Array< {
      __typename: "ReviewProblem",
      id: string,
      userId: string,
      groupId: string,
      courseId: string,
      problems?: string | null,
      tenantCode: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ReviewProblemsByTenantCodeQueryVariables = {
  tenantCode?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReviewProblemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ReviewProblemsByTenantCodeQuery = {
  reviewProblemsByTenantCode?:  {
    __typename: "ModelReviewProblemConnection",
    items:  Array< {
      __typename: "ReviewProblem",
      id: string,
      userId: string,
      groupId: string,
      courseId: string,
      problems?: string | null,
      tenantCode: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetGroupQueryVariables = {
  id: string,
};

export type GetGroupQuery = {
  getGroup?:  {
    __typename: "Group",
    id: string,
    name: string,
    users:  Array< {
      __typename: "GroupUser",
      id: string,
      role: GroupRole,
      removed?: boolean | null,
    } >,
    extensionConfigJsonList: Array< string >,
    tenantCode: string,
    limitations?: string | null,
    enabled?: boolean | null,
    description?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListGroupsQueryVariables = {
  filter?: ModelGroupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListGroupsQuery = {
  listGroups?:  {
    __typename: "ModelGroupConnection",
    items:  Array< {
      __typename: "Group",
      id: string,
      name: string,
      users:  Array< {
        __typename: "GroupUser",
        id: string,
        role: GroupRole,
        removed?: boolean | null,
      } >,
      extensionConfigJsonList: Array< string >,
      tenantCode: string,
      limitations?: string | null,
      enabled?: boolean | null,
      description?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GroupsByTenantCodeQueryVariables = {
  tenantCode?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelGroupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GroupsByTenantCodeQuery = {
  groupsByTenantCode?:  {
    __typename: "ModelGroupConnection",
    items:  Array< {
      __typename: "Group",
      id: string,
      name: string,
      users:  Array< {
        __typename: "GroupUser",
        id: string,
        role: GroupRole,
        removed?: boolean | null,
      } >,
      extensionConfigJsonList: Array< string >,
      tenantCode: string,
      limitations?: string | null,
      enabled?: boolean | null,
      description?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserQueryVariables = {
  id: string,
};

export type GetUserQuery = {
  getUser?:  {
    __typename: "User",
    id: string,
    name: string,
    role: Role,
    extensionConfigJsonList: Array< string >,
    email?: string | null,
    code?: string | null,
    tenantCode: string,
    displaySettings?:  {
      __typename: "DisplaySettings",
      theme?: string | null,
      color?: string | null,
    } | null,
    locale?: string | null,
    avatar?: string | null,
    enabled: boolean,
    signedInAtLeastOnce: boolean,
    emailConfirmed?: boolean | null,
    confirmedTermsOfServiceVersions?:  {
      __typename: "ConfirmedTermsOfServiceVersions",
      termsOfServiceVersion?: string | null,
      privacyPolicyVersion?: string | null,
      tenantOwnTermsOfServiceVersion?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    tags?:  {
      __typename: "ModelUserUserTagConnection",
      items:  Array< {
        __typename: "UserUserTag",
        id: string,
        userId: string,
        userTagId: string,
        tenantCode: string,
        createdAt: string,
        updatedAt: string,
        user:  {
          __typename: "User",
          id: string,
          name: string,
          role: Role,
          extensionConfigJsonList: Array< string >,
          email?: string | null,
          code?: string | null,
          tenantCode: string,
          displaySettings?:  {
            __typename: "DisplaySettings",
            theme?: string | null,
            color?: string | null,
          } | null,
          locale?: string | null,
          avatar?: string | null,
          enabled: boolean,
          signedInAtLeastOnce: boolean,
          emailConfirmed?: boolean | null,
          confirmedTermsOfServiceVersions?:  {
            __typename: "ConfirmedTermsOfServiceVersions",
            termsOfServiceVersion?: string | null,
            privacyPolicyVersion?: string | null,
            tenantOwnTermsOfServiceVersion?: number | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          tags?:  {
            __typename: "ModelUserUserTagConnection",
            items:  Array< {
              __typename: "UserUserTag",
              id: string,
              userId: string,
              userTagId: string,
              tenantCode: string,
              createdAt: string,
              updatedAt: string,
              user:  {
                __typename: "User",
                id: string,
                name: string,
                role: Role,
                extensionConfigJsonList: Array< string >,
                email?: string | null,
                code?: string | null,
                tenantCode: string,
                displaySettings?:  {
                  __typename: "DisplaySettings",
                  theme?: string | null,
                  color?: string | null,
                } | null,
                locale?: string | null,
                avatar?: string | null,
                enabled: boolean,
                signedInAtLeastOnce: boolean,
                emailConfirmed?: boolean | null,
                confirmedTermsOfServiceVersions?:  {
                  __typename: "ConfirmedTermsOfServiceVersions",
                  termsOfServiceVersion?: string | null,
                  privacyPolicyVersion?: string | null,
                  tenantOwnTermsOfServiceVersion?: number | null,
                } | null,
                createdAt: string,
                updatedAt: string,
                tags?:  {
                  __typename: "ModelUserUserTagConnection",
                  nextToken?: string | null,
                } | null,
                statuses?:  {
                  __typename: "ModelUserStatusTableConnection",
                  nextToken?: string | null,
                } | null,
              },
              userTag:  {
                __typename: "UserTag",
                id: string,
                color: string,
                text: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                users?:  {
                  __typename: "ModelUserUserTagConnection",
                  nextToken?: string | null,
                } | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          statuses?:  {
            __typename: "ModelUserStatusTableConnection",
            items:  Array< {
              __typename: "UserStatusTable",
              id: string,
              tenantCode: string,
              status: UserStatus,
              userId: string,
              createdAt: string,
              updatedAt: string,
              user:  {
                __typename: "User",
                id: string,
                name: string,
                role: Role,
                extensionConfigJsonList: Array< string >,
                email?: string | null,
                code?: string | null,
                tenantCode: string,
                displaySettings?:  {
                  __typename: "DisplaySettings",
                  theme?: string | null,
                  color?: string | null,
                } | null,
                locale?: string | null,
                avatar?: string | null,
                enabled: boolean,
                signedInAtLeastOnce: boolean,
                emailConfirmed?: boolean | null,
                confirmedTermsOfServiceVersions?:  {
                  __typename: "ConfirmedTermsOfServiceVersions",
                  termsOfServiceVersion?: string | null,
                  privacyPolicyVersion?: string | null,
                  tenantOwnTermsOfServiceVersion?: number | null,
                } | null,
                createdAt: string,
                updatedAt: string,
                tags?:  {
                  __typename: "ModelUserUserTagConnection",
                  nextToken?: string | null,
                } | null,
                statuses?:  {
                  __typename: "ModelUserStatusTableConnection",
                  nextToken?: string | null,
                } | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        userTag:  {
          __typename: "UserTag",
          id: string,
          color: string,
          text: string,
          tenantCode: string,
          createdAt: string,
          updatedAt: string,
          users?:  {
            __typename: "ModelUserUserTagConnection",
            items:  Array< {
              __typename: "UserUserTag",
              id: string,
              userId: string,
              userTagId: string,
              tenantCode: string,
              createdAt: string,
              updatedAt: string,
              user:  {
                __typename: "User",
                id: string,
                name: string,
                role: Role,
                extensionConfigJsonList: Array< string >,
                email?: string | null,
                code?: string | null,
                tenantCode: string,
                displaySettings?:  {
                  __typename: "DisplaySettings",
                  theme?: string | null,
                  color?: string | null,
                } | null,
                locale?: string | null,
                avatar?: string | null,
                enabled: boolean,
                signedInAtLeastOnce: boolean,
                emailConfirmed?: boolean | null,
                confirmedTermsOfServiceVersions?:  {
                  __typename: "ConfirmedTermsOfServiceVersions",
                  termsOfServiceVersion?: string | null,
                  privacyPolicyVersion?: string | null,
                  tenantOwnTermsOfServiceVersion?: number | null,
                } | null,
                createdAt: string,
                updatedAt: string,
                tags?:  {
                  __typename: "ModelUserUserTagConnection",
                  nextToken?: string | null,
                } | null,
                statuses?:  {
                  __typename: "ModelUserStatusTableConnection",
                  nextToken?: string | null,
                } | null,
              },
              userTag:  {
                __typename: "UserTag",
                id: string,
                color: string,
                text: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                users?:  {
                  __typename: "ModelUserUserTagConnection",
                  nextToken?: string | null,
                } | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    statuses?:  {
      __typename: "ModelUserStatusTableConnection",
      items:  Array< {
        __typename: "UserStatusTable",
        id: string,
        tenantCode: string,
        status: UserStatus,
        userId: string,
        createdAt: string,
        updatedAt: string,
        user:  {
          __typename: "User",
          id: string,
          name: string,
          role: Role,
          extensionConfigJsonList: Array< string >,
          email?: string | null,
          code?: string | null,
          tenantCode: string,
          displaySettings?:  {
            __typename: "DisplaySettings",
            theme?: string | null,
            color?: string | null,
          } | null,
          locale?: string | null,
          avatar?: string | null,
          enabled: boolean,
          signedInAtLeastOnce: boolean,
          emailConfirmed?: boolean | null,
          confirmedTermsOfServiceVersions?:  {
            __typename: "ConfirmedTermsOfServiceVersions",
            termsOfServiceVersion?: string | null,
            privacyPolicyVersion?: string | null,
            tenantOwnTermsOfServiceVersion?: number | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          tags?:  {
            __typename: "ModelUserUserTagConnection",
            items:  Array< {
              __typename: "UserUserTag",
              id: string,
              userId: string,
              userTagId: string,
              tenantCode: string,
              createdAt: string,
              updatedAt: string,
              user:  {
                __typename: "User",
                id: string,
                name: string,
                role: Role,
                extensionConfigJsonList: Array< string >,
                email?: string | null,
                code?: string | null,
                tenantCode: string,
                displaySettings?:  {
                  __typename: "DisplaySettings",
                  theme?: string | null,
                  color?: string | null,
                } | null,
                locale?: string | null,
                avatar?: string | null,
                enabled: boolean,
                signedInAtLeastOnce: boolean,
                emailConfirmed?: boolean | null,
                confirmedTermsOfServiceVersions?:  {
                  __typename: "ConfirmedTermsOfServiceVersions",
                  termsOfServiceVersion?: string | null,
                  privacyPolicyVersion?: string | null,
                  tenantOwnTermsOfServiceVersion?: number | null,
                } | null,
                createdAt: string,
                updatedAt: string,
                tags?:  {
                  __typename: "ModelUserUserTagConnection",
                  nextToken?: string | null,
                } | null,
                statuses?:  {
                  __typename: "ModelUserStatusTableConnection",
                  nextToken?: string | null,
                } | null,
              },
              userTag:  {
                __typename: "UserTag",
                id: string,
                color: string,
                text: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                users?:  {
                  __typename: "ModelUserUserTagConnection",
                  nextToken?: string | null,
                } | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          statuses?:  {
            __typename: "ModelUserStatusTableConnection",
            items:  Array< {
              __typename: "UserStatusTable",
              id: string,
              tenantCode: string,
              status: UserStatus,
              userId: string,
              createdAt: string,
              updatedAt: string,
              user:  {
                __typename: "User",
                id: string,
                name: string,
                role: Role,
                extensionConfigJsonList: Array< string >,
                email?: string | null,
                code?: string | null,
                tenantCode: string,
                displaySettings?:  {
                  __typename: "DisplaySettings",
                  theme?: string | null,
                  color?: string | null,
                } | null,
                locale?: string | null,
                avatar?: string | null,
                enabled: boolean,
                signedInAtLeastOnce: boolean,
                emailConfirmed?: boolean | null,
                confirmedTermsOfServiceVersions?:  {
                  __typename: "ConfirmedTermsOfServiceVersions",
                  termsOfServiceVersion?: string | null,
                  privacyPolicyVersion?: string | null,
                  tenantOwnTermsOfServiceVersion?: number | null,
                } | null,
                createdAt: string,
                updatedAt: string,
                tags?:  {
                  __typename: "ModelUserUserTagConnection",
                  nextToken?: string | null,
                } | null,
                statuses?:  {
                  __typename: "ModelUserStatusTableConnection",
                  nextToken?: string | null,
                } | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListUsersQueryVariables = {
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUsersQuery = {
  listUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      name: string,
      role: Role,
      extensionConfigJsonList: Array< string >,
      email?: string | null,
      code?: string | null,
      tenantCode: string,
      displaySettings?:  {
        __typename: "DisplaySettings",
        theme?: string | null,
        color?: string | null,
      } | null,
      locale?: string | null,
      avatar?: string | null,
      enabled: boolean,
      signedInAtLeastOnce: boolean,
      emailConfirmed?: boolean | null,
      confirmedTermsOfServiceVersions?:  {
        __typename: "ConfirmedTermsOfServiceVersions",
        termsOfServiceVersion?: string | null,
        privacyPolicyVersion?: string | null,
        tenantOwnTermsOfServiceVersion?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      tags?:  {
        __typename: "ModelUserUserTagConnection",
        items:  Array< {
          __typename: "UserUserTag",
          id: string,
          userId: string,
          userTagId: string,
          tenantCode: string,
          createdAt: string,
          updatedAt: string,
          user:  {
            __typename: "User",
            id: string,
            name: string,
            role: Role,
            extensionConfigJsonList: Array< string >,
            email?: string | null,
            code?: string | null,
            tenantCode: string,
            displaySettings?:  {
              __typename: "DisplaySettings",
              theme?: string | null,
              color?: string | null,
            } | null,
            locale?: string | null,
            avatar?: string | null,
            enabled: boolean,
            signedInAtLeastOnce: boolean,
            emailConfirmed?: boolean | null,
            confirmedTermsOfServiceVersions?:  {
              __typename: "ConfirmedTermsOfServiceVersions",
              termsOfServiceVersion?: string | null,
              privacyPolicyVersion?: string | null,
              tenantOwnTermsOfServiceVersion?: number | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            tags?:  {
              __typename: "ModelUserUserTagConnection",
              items:  Array< {
                __typename: "UserUserTag",
                id: string,
                userId: string,
                userTagId: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
                userTag:  {
                  __typename: "UserTag",
                  id: string,
                  color: string,
                  text: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
            statuses?:  {
              __typename: "ModelUserStatusTableConnection",
              items:  Array< {
                __typename: "UserStatusTable",
                id: string,
                tenantCode: string,
                status: UserStatus,
                userId: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          userTag:  {
            __typename: "UserTag",
            id: string,
            color: string,
            text: string,
            tenantCode: string,
            createdAt: string,
            updatedAt: string,
            users?:  {
              __typename: "ModelUserUserTagConnection",
              items:  Array< {
                __typename: "UserUserTag",
                id: string,
                userId: string,
                userTagId: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
                userTag:  {
                  __typename: "UserTag",
                  id: string,
                  color: string,
                  text: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      statuses?:  {
        __typename: "ModelUserStatusTableConnection",
        items:  Array< {
          __typename: "UserStatusTable",
          id: string,
          tenantCode: string,
          status: UserStatus,
          userId: string,
          createdAt: string,
          updatedAt: string,
          user:  {
            __typename: "User",
            id: string,
            name: string,
            role: Role,
            extensionConfigJsonList: Array< string >,
            email?: string | null,
            code?: string | null,
            tenantCode: string,
            displaySettings?:  {
              __typename: "DisplaySettings",
              theme?: string | null,
              color?: string | null,
            } | null,
            locale?: string | null,
            avatar?: string | null,
            enabled: boolean,
            signedInAtLeastOnce: boolean,
            emailConfirmed?: boolean | null,
            confirmedTermsOfServiceVersions?:  {
              __typename: "ConfirmedTermsOfServiceVersions",
              termsOfServiceVersion?: string | null,
              privacyPolicyVersion?: string | null,
              tenantOwnTermsOfServiceVersion?: number | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            tags?:  {
              __typename: "ModelUserUserTagConnection",
              items:  Array< {
                __typename: "UserUserTag",
                id: string,
                userId: string,
                userTagId: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
                userTag:  {
                  __typename: "UserTag",
                  id: string,
                  color: string,
                  text: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
            statuses?:  {
              __typename: "ModelUserStatusTableConnection",
              items:  Array< {
                __typename: "UserStatusTable",
                id: string,
                tenantCode: string,
                status: UserStatus,
                userId: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UserByEmailQueryVariables = {
  email?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UserByEmailQuery = {
  userByEmail?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      name: string,
      role: Role,
      extensionConfigJsonList: Array< string >,
      email?: string | null,
      code?: string | null,
      tenantCode: string,
      displaySettings?:  {
        __typename: "DisplaySettings",
        theme?: string | null,
        color?: string | null,
      } | null,
      locale?: string | null,
      avatar?: string | null,
      enabled: boolean,
      signedInAtLeastOnce: boolean,
      emailConfirmed?: boolean | null,
      confirmedTermsOfServiceVersions?:  {
        __typename: "ConfirmedTermsOfServiceVersions",
        termsOfServiceVersion?: string | null,
        privacyPolicyVersion?: string | null,
        tenantOwnTermsOfServiceVersion?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      tags?:  {
        __typename: "ModelUserUserTagConnection",
        items:  Array< {
          __typename: "UserUserTag",
          id: string,
          userId: string,
          userTagId: string,
          tenantCode: string,
          createdAt: string,
          updatedAt: string,
          user:  {
            __typename: "User",
            id: string,
            name: string,
            role: Role,
            extensionConfigJsonList: Array< string >,
            email?: string | null,
            code?: string | null,
            tenantCode: string,
            displaySettings?:  {
              __typename: "DisplaySettings",
              theme?: string | null,
              color?: string | null,
            } | null,
            locale?: string | null,
            avatar?: string | null,
            enabled: boolean,
            signedInAtLeastOnce: boolean,
            emailConfirmed?: boolean | null,
            confirmedTermsOfServiceVersions?:  {
              __typename: "ConfirmedTermsOfServiceVersions",
              termsOfServiceVersion?: string | null,
              privacyPolicyVersion?: string | null,
              tenantOwnTermsOfServiceVersion?: number | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            tags?:  {
              __typename: "ModelUserUserTagConnection",
              items:  Array< {
                __typename: "UserUserTag",
                id: string,
                userId: string,
                userTagId: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
                userTag:  {
                  __typename: "UserTag",
                  id: string,
                  color: string,
                  text: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
            statuses?:  {
              __typename: "ModelUserStatusTableConnection",
              items:  Array< {
                __typename: "UserStatusTable",
                id: string,
                tenantCode: string,
                status: UserStatus,
                userId: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          userTag:  {
            __typename: "UserTag",
            id: string,
            color: string,
            text: string,
            tenantCode: string,
            createdAt: string,
            updatedAt: string,
            users?:  {
              __typename: "ModelUserUserTagConnection",
              items:  Array< {
                __typename: "UserUserTag",
                id: string,
                userId: string,
                userTagId: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
                userTag:  {
                  __typename: "UserTag",
                  id: string,
                  color: string,
                  text: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      statuses?:  {
        __typename: "ModelUserStatusTableConnection",
        items:  Array< {
          __typename: "UserStatusTable",
          id: string,
          tenantCode: string,
          status: UserStatus,
          userId: string,
          createdAt: string,
          updatedAt: string,
          user:  {
            __typename: "User",
            id: string,
            name: string,
            role: Role,
            extensionConfigJsonList: Array< string >,
            email?: string | null,
            code?: string | null,
            tenantCode: string,
            displaySettings?:  {
              __typename: "DisplaySettings",
              theme?: string | null,
              color?: string | null,
            } | null,
            locale?: string | null,
            avatar?: string | null,
            enabled: boolean,
            signedInAtLeastOnce: boolean,
            emailConfirmed?: boolean | null,
            confirmedTermsOfServiceVersions?:  {
              __typename: "ConfirmedTermsOfServiceVersions",
              termsOfServiceVersion?: string | null,
              privacyPolicyVersion?: string | null,
              tenantOwnTermsOfServiceVersion?: number | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            tags?:  {
              __typename: "ModelUserUserTagConnection",
              items:  Array< {
                __typename: "UserUserTag",
                id: string,
                userId: string,
                userTagId: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
                userTag:  {
                  __typename: "UserTag",
                  id: string,
                  color: string,
                  text: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
            statuses?:  {
              __typename: "ModelUserStatusTableConnection",
              items:  Array< {
                __typename: "UserStatusTable",
                id: string,
                tenantCode: string,
                status: UserStatus,
                userId: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UserByCodeAndTenantCodeQueryVariables = {
  code?: string | null,
  tenantCode?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UserByCodeAndTenantCodeQuery = {
  userByCodeAndTenantCode?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      name: string,
      role: Role,
      extensionConfigJsonList: Array< string >,
      email?: string | null,
      code?: string | null,
      tenantCode: string,
      displaySettings?:  {
        __typename: "DisplaySettings",
        theme?: string | null,
        color?: string | null,
      } | null,
      locale?: string | null,
      avatar?: string | null,
      enabled: boolean,
      signedInAtLeastOnce: boolean,
      emailConfirmed?: boolean | null,
      confirmedTermsOfServiceVersions?:  {
        __typename: "ConfirmedTermsOfServiceVersions",
        termsOfServiceVersion?: string | null,
        privacyPolicyVersion?: string | null,
        tenantOwnTermsOfServiceVersion?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      tags?:  {
        __typename: "ModelUserUserTagConnection",
        items:  Array< {
          __typename: "UserUserTag",
          id: string,
          userId: string,
          userTagId: string,
          tenantCode: string,
          createdAt: string,
          updatedAt: string,
          user:  {
            __typename: "User",
            id: string,
            name: string,
            role: Role,
            extensionConfigJsonList: Array< string >,
            email?: string | null,
            code?: string | null,
            tenantCode: string,
            displaySettings?:  {
              __typename: "DisplaySettings",
              theme?: string | null,
              color?: string | null,
            } | null,
            locale?: string | null,
            avatar?: string | null,
            enabled: boolean,
            signedInAtLeastOnce: boolean,
            emailConfirmed?: boolean | null,
            confirmedTermsOfServiceVersions?:  {
              __typename: "ConfirmedTermsOfServiceVersions",
              termsOfServiceVersion?: string | null,
              privacyPolicyVersion?: string | null,
              tenantOwnTermsOfServiceVersion?: number | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            tags?:  {
              __typename: "ModelUserUserTagConnection",
              items:  Array< {
                __typename: "UserUserTag",
                id: string,
                userId: string,
                userTagId: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
                userTag:  {
                  __typename: "UserTag",
                  id: string,
                  color: string,
                  text: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
            statuses?:  {
              __typename: "ModelUserStatusTableConnection",
              items:  Array< {
                __typename: "UserStatusTable",
                id: string,
                tenantCode: string,
                status: UserStatus,
                userId: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          userTag:  {
            __typename: "UserTag",
            id: string,
            color: string,
            text: string,
            tenantCode: string,
            createdAt: string,
            updatedAt: string,
            users?:  {
              __typename: "ModelUserUserTagConnection",
              items:  Array< {
                __typename: "UserUserTag",
                id: string,
                userId: string,
                userTagId: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
                userTag:  {
                  __typename: "UserTag",
                  id: string,
                  color: string,
                  text: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      statuses?:  {
        __typename: "ModelUserStatusTableConnection",
        items:  Array< {
          __typename: "UserStatusTable",
          id: string,
          tenantCode: string,
          status: UserStatus,
          userId: string,
          createdAt: string,
          updatedAt: string,
          user:  {
            __typename: "User",
            id: string,
            name: string,
            role: Role,
            extensionConfigJsonList: Array< string >,
            email?: string | null,
            code?: string | null,
            tenantCode: string,
            displaySettings?:  {
              __typename: "DisplaySettings",
              theme?: string | null,
              color?: string | null,
            } | null,
            locale?: string | null,
            avatar?: string | null,
            enabled: boolean,
            signedInAtLeastOnce: boolean,
            emailConfirmed?: boolean | null,
            confirmedTermsOfServiceVersions?:  {
              __typename: "ConfirmedTermsOfServiceVersions",
              termsOfServiceVersion?: string | null,
              privacyPolicyVersion?: string | null,
              tenantOwnTermsOfServiceVersion?: number | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            tags?:  {
              __typename: "ModelUserUserTagConnection",
              items:  Array< {
                __typename: "UserUserTag",
                id: string,
                userId: string,
                userTagId: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
                userTag:  {
                  __typename: "UserTag",
                  id: string,
                  color: string,
                  text: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
            statuses?:  {
              __typename: "ModelUserStatusTableConnection",
              items:  Array< {
                __typename: "UserStatusTable",
                id: string,
                tenantCode: string,
                status: UserStatus,
                userId: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UsersByTenantCodeQueryVariables = {
  tenantCode?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UsersByTenantCodeQuery = {
  usersByTenantCode?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      name: string,
      role: Role,
      extensionConfigJsonList: Array< string >,
      email?: string | null,
      code?: string | null,
      tenantCode: string,
      displaySettings?:  {
        __typename: "DisplaySettings",
        theme?: string | null,
        color?: string | null,
      } | null,
      locale?: string | null,
      avatar?: string | null,
      enabled: boolean,
      signedInAtLeastOnce: boolean,
      emailConfirmed?: boolean | null,
      confirmedTermsOfServiceVersions?:  {
        __typename: "ConfirmedTermsOfServiceVersions",
        termsOfServiceVersion?: string | null,
        privacyPolicyVersion?: string | null,
        tenantOwnTermsOfServiceVersion?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      tags?:  {
        __typename: "ModelUserUserTagConnection",
        items:  Array< {
          __typename: "UserUserTag",
          id: string,
          userId: string,
          userTagId: string,
          tenantCode: string,
          createdAt: string,
          updatedAt: string,
          user:  {
            __typename: "User",
            id: string,
            name: string,
            role: Role,
            extensionConfigJsonList: Array< string >,
            email?: string | null,
            code?: string | null,
            tenantCode: string,
            displaySettings?:  {
              __typename: "DisplaySettings",
              theme?: string | null,
              color?: string | null,
            } | null,
            locale?: string | null,
            avatar?: string | null,
            enabled: boolean,
            signedInAtLeastOnce: boolean,
            emailConfirmed?: boolean | null,
            confirmedTermsOfServiceVersions?:  {
              __typename: "ConfirmedTermsOfServiceVersions",
              termsOfServiceVersion?: string | null,
              privacyPolicyVersion?: string | null,
              tenantOwnTermsOfServiceVersion?: number | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            tags?:  {
              __typename: "ModelUserUserTagConnection",
              items:  Array< {
                __typename: "UserUserTag",
                id: string,
                userId: string,
                userTagId: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
                userTag:  {
                  __typename: "UserTag",
                  id: string,
                  color: string,
                  text: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
            statuses?:  {
              __typename: "ModelUserStatusTableConnection",
              items:  Array< {
                __typename: "UserStatusTable",
                id: string,
                tenantCode: string,
                status: UserStatus,
                userId: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          userTag:  {
            __typename: "UserTag",
            id: string,
            color: string,
            text: string,
            tenantCode: string,
            createdAt: string,
            updatedAt: string,
            users?:  {
              __typename: "ModelUserUserTagConnection",
              items:  Array< {
                __typename: "UserUserTag",
                id: string,
                userId: string,
                userTagId: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
                userTag:  {
                  __typename: "UserTag",
                  id: string,
                  color: string,
                  text: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      statuses?:  {
        __typename: "ModelUserStatusTableConnection",
        items:  Array< {
          __typename: "UserStatusTable",
          id: string,
          tenantCode: string,
          status: UserStatus,
          userId: string,
          createdAt: string,
          updatedAt: string,
          user:  {
            __typename: "User",
            id: string,
            name: string,
            role: Role,
            extensionConfigJsonList: Array< string >,
            email?: string | null,
            code?: string | null,
            tenantCode: string,
            displaySettings?:  {
              __typename: "DisplaySettings",
              theme?: string | null,
              color?: string | null,
            } | null,
            locale?: string | null,
            avatar?: string | null,
            enabled: boolean,
            signedInAtLeastOnce: boolean,
            emailConfirmed?: boolean | null,
            confirmedTermsOfServiceVersions?:  {
              __typename: "ConfirmedTermsOfServiceVersions",
              termsOfServiceVersion?: string | null,
              privacyPolicyVersion?: string | null,
              tenantOwnTermsOfServiceVersion?: number | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            tags?:  {
              __typename: "ModelUserUserTagConnection",
              items:  Array< {
                __typename: "UserUserTag",
                id: string,
                userId: string,
                userTagId: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
                userTag:  {
                  __typename: "UserTag",
                  id: string,
                  color: string,
                  text: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
            statuses?:  {
              __typename: "ModelUserStatusTableConnection",
              items:  Array< {
                __typename: "UserStatusTable",
                id: string,
                tenantCode: string,
                status: UserStatus,
                userId: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserDeviceQueryVariables = {
  userId: string,
};

export type GetUserDeviceQuery = {
  getUserDevice?:  {
    __typename: "UserDevice",
    userId: string,
    devices: string,
    dataVersion: number,
    tenantCode: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListUserDevicesQueryVariables = {
  userId?: string | null,
  filter?: ModelUserDeviceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListUserDevicesQuery = {
  listUserDevices?:  {
    __typename: "ModelUserDeviceConnection",
    items:  Array< {
      __typename: "UserDevice",
      userId: string,
      devices: string,
      dataVersion: number,
      tenantCode: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UserDevicesByTenantCodeQueryVariables = {
  tenantCode?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserDeviceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UserDevicesByTenantCodeQuery = {
  userDevicesByTenantCode?:  {
    __typename: "ModelUserDeviceConnection",
    items:  Array< {
      __typename: "UserDevice",
      userId: string,
      devices: string,
      dataVersion: number,
      tenantCode: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSignUpReservationQueryVariables = {
  id: string,
};

export type GetSignUpReservationQuery = {
  getSignUpReservation?:  {
    __typename: "SignUpReservation",
    id: string,
    name?: string | null,
    key: string,
    userCode?: string | null,
    email?: string | null,
    tenantCode: string,
    type: SignUpType,
    role: Role,
    userId?: string | null,
    signUpInput?:  {
      __typename: "SignUpInput",
      name: string,
      tenantName?: string | null,
    } | null,
    status: SignUpReservationStatus,
    groups?:  Array< {
      __typename: "GroupUser",
      id: string,
      role: GroupRole,
      removed?: boolean | null,
    } > | null,
    createdBy?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListSignUpReservationsQueryVariables = {
  filter?: ModelSignUpReservationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSignUpReservationsQuery = {
  listSignUpReservations?:  {
    __typename: "ModelSignUpReservationConnection",
    items:  Array< {
      __typename: "SignUpReservation",
      id: string,
      name?: string | null,
      key: string,
      userCode?: string | null,
      email?: string | null,
      tenantCode: string,
      type: SignUpType,
      role: Role,
      userId?: string | null,
      signUpInput?:  {
        __typename: "SignUpInput",
        name: string,
        tenantName?: string | null,
      } | null,
      status: SignUpReservationStatus,
      groups?:  Array< {
        __typename: "GroupUser",
        id: string,
        role: GroupRole,
        removed?: boolean | null,
      } > | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SignUpReservationByUserIdQueryVariables = {
  userId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSignUpReservationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SignUpReservationByUserIdQuery = {
  signUpReservationByUserId?:  {
    __typename: "ModelSignUpReservationConnection",
    items:  Array< {
      __typename: "SignUpReservation",
      id: string,
      name?: string | null,
      key: string,
      userCode?: string | null,
      email?: string | null,
      tenantCode: string,
      type: SignUpType,
      role: Role,
      userId?: string | null,
      signUpInput?:  {
        __typename: "SignUpInput",
        name: string,
        tenantName?: string | null,
      } | null,
      status: SignUpReservationStatus,
      groups?:  Array< {
        __typename: "GroupUser",
        id: string,
        role: GroupRole,
        removed?: boolean | null,
      } > | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SignUpReservationsByEmailQueryVariables = {
  email?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSignUpReservationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SignUpReservationsByEmailQuery = {
  signUpReservationsByEmail?:  {
    __typename: "ModelSignUpReservationConnection",
    items:  Array< {
      __typename: "SignUpReservation",
      id: string,
      name?: string | null,
      key: string,
      userCode?: string | null,
      email?: string | null,
      tenantCode: string,
      type: SignUpType,
      role: Role,
      userId?: string | null,
      signUpInput?:  {
        __typename: "SignUpInput",
        name: string,
        tenantName?: string | null,
      } | null,
      status: SignUpReservationStatus,
      groups?:  Array< {
        __typename: "GroupUser",
        id: string,
        role: GroupRole,
        removed?: boolean | null,
      } > | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SignUpReservationsByTenantCodeQueryVariables = {
  tenantCode?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSignUpReservationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SignUpReservationsByTenantCodeQuery = {
  signUpReservationsByTenantCode?:  {
    __typename: "ModelSignUpReservationConnection",
    items:  Array< {
      __typename: "SignUpReservation",
      id: string,
      name?: string | null,
      key: string,
      userCode?: string | null,
      email?: string | null,
      tenantCode: string,
      type: SignUpType,
      role: Role,
      userId?: string | null,
      signUpInput?:  {
        __typename: "SignUpInput",
        name: string,
        tenantName?: string | null,
      } | null,
      status: SignUpReservationStatus,
      groups?:  Array< {
        __typename: "GroupUser",
        id: string,
        role: GroupRole,
        removed?: boolean | null,
      } > | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SignUpReservationByKeyQueryVariables = {
  key?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSignUpReservationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SignUpReservationByKeyQuery = {
  signUpReservationByKey?:  {
    __typename: "ModelSignUpReservationConnection",
    items:  Array< {
      __typename: "SignUpReservation",
      id: string,
      name?: string | null,
      key: string,
      userCode?: string | null,
      email?: string | null,
      tenantCode: string,
      type: SignUpType,
      role: Role,
      userId?: string | null,
      signUpInput?:  {
        __typename: "SignUpInput",
        name: string,
        tenantName?: string | null,
      } | null,
      status: SignUpReservationStatus,
      groups?:  Array< {
        __typename: "GroupUser",
        id: string,
        role: GroupRole,
        removed?: boolean | null,
      } > | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTenantQueryVariables = {
  id: string,
};

export type GetTenantQuery = {
  getTenant?:  {
    __typename: "Tenant",
    id: string,
    code: string,
    name: string,
    signUpType: SignUpType,
    playground: boolean,
    userLimit?: number | null,
    limitations?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListTenantsQueryVariables = {
  filter?: ModelTenantFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTenantsQuery = {
  listTenants?:  {
    __typename: "ModelTenantConnection",
    items:  Array< {
      __typename: "Tenant",
      id: string,
      code: string,
      name: string,
      signUpType: SignUpType,
      playground: boolean,
      userLimit?: number | null,
      limitations?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type TenantByCodeQueryVariables = {
  code?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTenantFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TenantByCodeQuery = {
  tenantByCode?:  {
    __typename: "ModelTenantConnection",
    items:  Array< {
      __typename: "Tenant",
      id: string,
      code: string,
      name: string,
      signUpType: SignUpType,
      playground: boolean,
      userLimit?: number | null,
      limitations?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTenantOwnTermsOfServiceQueryVariables = {
  id: string,
};

export type GetTenantOwnTermsOfServiceQuery = {
  getTenantOwnTermsOfService?:  {
    __typename: "TenantOwnTermsOfService",
    id: string,
    tenantCode: string,
    type: TenantOwnTermsOfServiceType,
    body: string,
    createdBy: string,
    createdAt: string,
    updatedBy?: string | null,
    updatedAt?: string | null,
    version?: number | null,
  } | null,
};

export type ListTenantOwnTermsOfServicesQueryVariables = {
  filter?: ModelTenantOwnTermsOfServiceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTenantOwnTermsOfServicesQuery = {
  listTenantOwnTermsOfServices?:  {
    __typename: "ModelTenantOwnTermsOfServiceConnection",
    items:  Array< {
      __typename: "TenantOwnTermsOfService",
      id: string,
      tenantCode: string,
      type: TenantOwnTermsOfServiceType,
      body: string,
      createdBy: string,
      createdAt: string,
      updatedBy?: string | null,
      updatedAt?: string | null,
      version?: number | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetEventQueryVariables = {
  id: string,
};

export type GetEventQuery = {
  getEvent?:  {
    __typename: "Event",
    id: string,
    type: string,
    event: string,
    version: number,
    tenantCode: string,
    occurredBy: string,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type ListEventsQueryVariables = {
  filter?: ModelEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEventsQuery = {
  listEvents?:  {
    __typename: "ModelEventConnection",
    items:  Array< {
      __typename: "Event",
      id: string,
      type: string,
      event: string,
      version: number,
      tenantCode: string,
      occurredBy: string,
      createdAt?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type EventsByOccurredByQueryVariables = {
  occurredBy?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type EventsByOccurredByQuery = {
  eventsByOccurredBy?:  {
    __typename: "ModelEventConnection",
    items:  Array< {
      __typename: "Event",
      id: string,
      type: string,
      event: string,
      version: number,
      tenantCode: string,
      occurredBy: string,
      createdAt?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type EventsByTypeQueryVariables = {
  type?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type EventsByTypeQuery = {
  eventsByType?:  {
    __typename: "ModelEventConnection",
    items:  Array< {
      __typename: "Event",
      id: string,
      type: string,
      event: string,
      version: number,
      tenantCode: string,
      occurredBy: string,
      createdAt?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTermsOfServiceQueryVariables = {
  id: string,
};

export type GetTermsOfServiceQuery = {
  getTermsOfService?:  {
    __typename: "TermsOfService",
    id: string,
    body: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListTermsOfServicesQueryVariables = {
  filter?: ModelTermsOfServiceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTermsOfServicesQuery = {
  listTermsOfServices?:  {
    __typename: "ModelTermsOfServiceConnection",
    items:  Array< {
      __typename: "TermsOfService",
      id: string,
      body: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserTagQueryVariables = {
  id: string,
};

export type GetUserTagQuery = {
  getUserTag?:  {
    __typename: "UserTag",
    id: string,
    color: string,
    text: string,
    tenantCode: string,
    createdAt: string,
    updatedAt: string,
    users?:  {
      __typename: "ModelUserUserTagConnection",
      items:  Array< {
        __typename: "UserUserTag",
        id: string,
        userId: string,
        userTagId: string,
        tenantCode: string,
        createdAt: string,
        updatedAt: string,
        user:  {
          __typename: "User",
          id: string,
          name: string,
          role: Role,
          extensionConfigJsonList: Array< string >,
          email?: string | null,
          code?: string | null,
          tenantCode: string,
          displaySettings?:  {
            __typename: "DisplaySettings",
            theme?: string | null,
            color?: string | null,
          } | null,
          locale?: string | null,
          avatar?: string | null,
          enabled: boolean,
          signedInAtLeastOnce: boolean,
          emailConfirmed?: boolean | null,
          confirmedTermsOfServiceVersions?:  {
            __typename: "ConfirmedTermsOfServiceVersions",
            termsOfServiceVersion?: string | null,
            privacyPolicyVersion?: string | null,
            tenantOwnTermsOfServiceVersion?: number | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          tags?:  {
            __typename: "ModelUserUserTagConnection",
            items:  Array< {
              __typename: "UserUserTag",
              id: string,
              userId: string,
              userTagId: string,
              tenantCode: string,
              createdAt: string,
              updatedAt: string,
              user:  {
                __typename: "User",
                id: string,
                name: string,
                role: Role,
                extensionConfigJsonList: Array< string >,
                email?: string | null,
                code?: string | null,
                tenantCode: string,
                displaySettings?:  {
                  __typename: "DisplaySettings",
                  theme?: string | null,
                  color?: string | null,
                } | null,
                locale?: string | null,
                avatar?: string | null,
                enabled: boolean,
                signedInAtLeastOnce: boolean,
                emailConfirmed?: boolean | null,
                confirmedTermsOfServiceVersions?:  {
                  __typename: "ConfirmedTermsOfServiceVersions",
                  termsOfServiceVersion?: string | null,
                  privacyPolicyVersion?: string | null,
                  tenantOwnTermsOfServiceVersion?: number | null,
                } | null,
                createdAt: string,
                updatedAt: string,
                tags?:  {
                  __typename: "ModelUserUserTagConnection",
                  nextToken?: string | null,
                } | null,
                statuses?:  {
                  __typename: "ModelUserStatusTableConnection",
                  nextToken?: string | null,
                } | null,
              },
              userTag:  {
                __typename: "UserTag",
                id: string,
                color: string,
                text: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                users?:  {
                  __typename: "ModelUserUserTagConnection",
                  nextToken?: string | null,
                } | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          statuses?:  {
            __typename: "ModelUserStatusTableConnection",
            items:  Array< {
              __typename: "UserStatusTable",
              id: string,
              tenantCode: string,
              status: UserStatus,
              userId: string,
              createdAt: string,
              updatedAt: string,
              user:  {
                __typename: "User",
                id: string,
                name: string,
                role: Role,
                extensionConfigJsonList: Array< string >,
                email?: string | null,
                code?: string | null,
                tenantCode: string,
                displaySettings?:  {
                  __typename: "DisplaySettings",
                  theme?: string | null,
                  color?: string | null,
                } | null,
                locale?: string | null,
                avatar?: string | null,
                enabled: boolean,
                signedInAtLeastOnce: boolean,
                emailConfirmed?: boolean | null,
                confirmedTermsOfServiceVersions?:  {
                  __typename: "ConfirmedTermsOfServiceVersions",
                  termsOfServiceVersion?: string | null,
                  privacyPolicyVersion?: string | null,
                  tenantOwnTermsOfServiceVersion?: number | null,
                } | null,
                createdAt: string,
                updatedAt: string,
                tags?:  {
                  __typename: "ModelUserUserTagConnection",
                  nextToken?: string | null,
                } | null,
                statuses?:  {
                  __typename: "ModelUserStatusTableConnection",
                  nextToken?: string | null,
                } | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        userTag:  {
          __typename: "UserTag",
          id: string,
          color: string,
          text: string,
          tenantCode: string,
          createdAt: string,
          updatedAt: string,
          users?:  {
            __typename: "ModelUserUserTagConnection",
            items:  Array< {
              __typename: "UserUserTag",
              id: string,
              userId: string,
              userTagId: string,
              tenantCode: string,
              createdAt: string,
              updatedAt: string,
              user:  {
                __typename: "User",
                id: string,
                name: string,
                role: Role,
                extensionConfigJsonList: Array< string >,
                email?: string | null,
                code?: string | null,
                tenantCode: string,
                displaySettings?:  {
                  __typename: "DisplaySettings",
                  theme?: string | null,
                  color?: string | null,
                } | null,
                locale?: string | null,
                avatar?: string | null,
                enabled: boolean,
                signedInAtLeastOnce: boolean,
                emailConfirmed?: boolean | null,
                confirmedTermsOfServiceVersions?:  {
                  __typename: "ConfirmedTermsOfServiceVersions",
                  termsOfServiceVersion?: string | null,
                  privacyPolicyVersion?: string | null,
                  tenantOwnTermsOfServiceVersion?: number | null,
                } | null,
                createdAt: string,
                updatedAt: string,
                tags?:  {
                  __typename: "ModelUserUserTagConnection",
                  nextToken?: string | null,
                } | null,
                statuses?:  {
                  __typename: "ModelUserStatusTableConnection",
                  nextToken?: string | null,
                } | null,
              },
              userTag:  {
                __typename: "UserTag",
                id: string,
                color: string,
                text: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                users?:  {
                  __typename: "ModelUserUserTagConnection",
                  nextToken?: string | null,
                } | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListUserTagsQueryVariables = {
  filter?: ModelUserTagFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserTagsQuery = {
  listUserTags?:  {
    __typename: "ModelUserTagConnection",
    items:  Array< {
      __typename: "UserTag",
      id: string,
      color: string,
      text: string,
      tenantCode: string,
      createdAt: string,
      updatedAt: string,
      users?:  {
        __typename: "ModelUserUserTagConnection",
        items:  Array< {
          __typename: "UserUserTag",
          id: string,
          userId: string,
          userTagId: string,
          tenantCode: string,
          createdAt: string,
          updatedAt: string,
          user:  {
            __typename: "User",
            id: string,
            name: string,
            role: Role,
            extensionConfigJsonList: Array< string >,
            email?: string | null,
            code?: string | null,
            tenantCode: string,
            displaySettings?:  {
              __typename: "DisplaySettings",
              theme?: string | null,
              color?: string | null,
            } | null,
            locale?: string | null,
            avatar?: string | null,
            enabled: boolean,
            signedInAtLeastOnce: boolean,
            emailConfirmed?: boolean | null,
            confirmedTermsOfServiceVersions?:  {
              __typename: "ConfirmedTermsOfServiceVersions",
              termsOfServiceVersion?: string | null,
              privacyPolicyVersion?: string | null,
              tenantOwnTermsOfServiceVersion?: number | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            tags?:  {
              __typename: "ModelUserUserTagConnection",
              items:  Array< {
                __typename: "UserUserTag",
                id: string,
                userId: string,
                userTagId: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
                userTag:  {
                  __typename: "UserTag",
                  id: string,
                  color: string,
                  text: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
            statuses?:  {
              __typename: "ModelUserStatusTableConnection",
              items:  Array< {
                __typename: "UserStatusTable",
                id: string,
                tenantCode: string,
                status: UserStatus,
                userId: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          userTag:  {
            __typename: "UserTag",
            id: string,
            color: string,
            text: string,
            tenantCode: string,
            createdAt: string,
            updatedAt: string,
            users?:  {
              __typename: "ModelUserUserTagConnection",
              items:  Array< {
                __typename: "UserUserTag",
                id: string,
                userId: string,
                userTagId: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
                userTag:  {
                  __typename: "UserTag",
                  id: string,
                  color: string,
                  text: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UserTagsByTenantCodeQueryVariables = {
  tenantCode?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserTagFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UserTagsByTenantCodeQuery = {
  userTagsByTenantCode?:  {
    __typename: "ModelUserTagConnection",
    items:  Array< {
      __typename: "UserTag",
      id: string,
      color: string,
      text: string,
      tenantCode: string,
      createdAt: string,
      updatedAt: string,
      users?:  {
        __typename: "ModelUserUserTagConnection",
        items:  Array< {
          __typename: "UserUserTag",
          id: string,
          userId: string,
          userTagId: string,
          tenantCode: string,
          createdAt: string,
          updatedAt: string,
          user:  {
            __typename: "User",
            id: string,
            name: string,
            role: Role,
            extensionConfigJsonList: Array< string >,
            email?: string | null,
            code?: string | null,
            tenantCode: string,
            displaySettings?:  {
              __typename: "DisplaySettings",
              theme?: string | null,
              color?: string | null,
            } | null,
            locale?: string | null,
            avatar?: string | null,
            enabled: boolean,
            signedInAtLeastOnce: boolean,
            emailConfirmed?: boolean | null,
            confirmedTermsOfServiceVersions?:  {
              __typename: "ConfirmedTermsOfServiceVersions",
              termsOfServiceVersion?: string | null,
              privacyPolicyVersion?: string | null,
              tenantOwnTermsOfServiceVersion?: number | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            tags?:  {
              __typename: "ModelUserUserTagConnection",
              items:  Array< {
                __typename: "UserUserTag",
                id: string,
                userId: string,
                userTagId: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
                userTag:  {
                  __typename: "UserTag",
                  id: string,
                  color: string,
                  text: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
            statuses?:  {
              __typename: "ModelUserStatusTableConnection",
              items:  Array< {
                __typename: "UserStatusTable",
                id: string,
                tenantCode: string,
                status: UserStatus,
                userId: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          userTag:  {
            __typename: "UserTag",
            id: string,
            color: string,
            text: string,
            tenantCode: string,
            createdAt: string,
            updatedAt: string,
            users?:  {
              __typename: "ModelUserUserTagConnection",
              items:  Array< {
                __typename: "UserUserTag",
                id: string,
                userId: string,
                userTagId: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
                userTag:  {
                  __typename: "UserTag",
                  id: string,
                  color: string,
                  text: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserStatusTableQueryVariables = {
  id: string,
};

export type GetUserStatusTableQuery = {
  getUserStatusTable?:  {
    __typename: "UserStatusTable",
    id: string,
    tenantCode: string,
    status: UserStatus,
    userId: string,
    createdAt: string,
    updatedAt: string,
    user:  {
      __typename: "User",
      id: string,
      name: string,
      role: Role,
      extensionConfigJsonList: Array< string >,
      email?: string | null,
      code?: string | null,
      tenantCode: string,
      displaySettings?:  {
        __typename: "DisplaySettings",
        theme?: string | null,
        color?: string | null,
      } | null,
      locale?: string | null,
      avatar?: string | null,
      enabled: boolean,
      signedInAtLeastOnce: boolean,
      emailConfirmed?: boolean | null,
      confirmedTermsOfServiceVersions?:  {
        __typename: "ConfirmedTermsOfServiceVersions",
        termsOfServiceVersion?: string | null,
        privacyPolicyVersion?: string | null,
        tenantOwnTermsOfServiceVersion?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      tags?:  {
        __typename: "ModelUserUserTagConnection",
        items:  Array< {
          __typename: "UserUserTag",
          id: string,
          userId: string,
          userTagId: string,
          tenantCode: string,
          createdAt: string,
          updatedAt: string,
          user:  {
            __typename: "User",
            id: string,
            name: string,
            role: Role,
            extensionConfigJsonList: Array< string >,
            email?: string | null,
            code?: string | null,
            tenantCode: string,
            displaySettings?:  {
              __typename: "DisplaySettings",
              theme?: string | null,
              color?: string | null,
            } | null,
            locale?: string | null,
            avatar?: string | null,
            enabled: boolean,
            signedInAtLeastOnce: boolean,
            emailConfirmed?: boolean | null,
            confirmedTermsOfServiceVersions?:  {
              __typename: "ConfirmedTermsOfServiceVersions",
              termsOfServiceVersion?: string | null,
              privacyPolicyVersion?: string | null,
              tenantOwnTermsOfServiceVersion?: number | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            tags?:  {
              __typename: "ModelUserUserTagConnection",
              items:  Array< {
                __typename: "UserUserTag",
                id: string,
                userId: string,
                userTagId: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
                userTag:  {
                  __typename: "UserTag",
                  id: string,
                  color: string,
                  text: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
            statuses?:  {
              __typename: "ModelUserStatusTableConnection",
              items:  Array< {
                __typename: "UserStatusTable",
                id: string,
                tenantCode: string,
                status: UserStatus,
                userId: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          userTag:  {
            __typename: "UserTag",
            id: string,
            color: string,
            text: string,
            tenantCode: string,
            createdAt: string,
            updatedAt: string,
            users?:  {
              __typename: "ModelUserUserTagConnection",
              items:  Array< {
                __typename: "UserUserTag",
                id: string,
                userId: string,
                userTagId: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
                userTag:  {
                  __typename: "UserTag",
                  id: string,
                  color: string,
                  text: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      statuses?:  {
        __typename: "ModelUserStatusTableConnection",
        items:  Array< {
          __typename: "UserStatusTable",
          id: string,
          tenantCode: string,
          status: UserStatus,
          userId: string,
          createdAt: string,
          updatedAt: string,
          user:  {
            __typename: "User",
            id: string,
            name: string,
            role: Role,
            extensionConfigJsonList: Array< string >,
            email?: string | null,
            code?: string | null,
            tenantCode: string,
            displaySettings?:  {
              __typename: "DisplaySettings",
              theme?: string | null,
              color?: string | null,
            } | null,
            locale?: string | null,
            avatar?: string | null,
            enabled: boolean,
            signedInAtLeastOnce: boolean,
            emailConfirmed?: boolean | null,
            confirmedTermsOfServiceVersions?:  {
              __typename: "ConfirmedTermsOfServiceVersions",
              termsOfServiceVersion?: string | null,
              privacyPolicyVersion?: string | null,
              tenantOwnTermsOfServiceVersion?: number | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            tags?:  {
              __typename: "ModelUserUserTagConnection",
              items:  Array< {
                __typename: "UserUserTag",
                id: string,
                userId: string,
                userTagId: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
                userTag:  {
                  __typename: "UserTag",
                  id: string,
                  color: string,
                  text: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
            statuses?:  {
              __typename: "ModelUserStatusTableConnection",
              items:  Array< {
                __typename: "UserStatusTable",
                id: string,
                tenantCode: string,
                status: UserStatus,
                userId: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type ListUserStatusTablesQueryVariables = {
  filter?: ModelUserStatusTableFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserStatusTablesQuery = {
  listUserStatusTables?:  {
    __typename: "ModelUserStatusTableConnection",
    items:  Array< {
      __typename: "UserStatusTable",
      id: string,
      tenantCode: string,
      status: UserStatus,
      userId: string,
      createdAt: string,
      updatedAt: string,
      user:  {
        __typename: "User",
        id: string,
        name: string,
        role: Role,
        extensionConfigJsonList: Array< string >,
        email?: string | null,
        code?: string | null,
        tenantCode: string,
        displaySettings?:  {
          __typename: "DisplaySettings",
          theme?: string | null,
          color?: string | null,
        } | null,
        locale?: string | null,
        avatar?: string | null,
        enabled: boolean,
        signedInAtLeastOnce: boolean,
        emailConfirmed?: boolean | null,
        confirmedTermsOfServiceVersions?:  {
          __typename: "ConfirmedTermsOfServiceVersions",
          termsOfServiceVersion?: string | null,
          privacyPolicyVersion?: string | null,
          tenantOwnTermsOfServiceVersion?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        tags?:  {
          __typename: "ModelUserUserTagConnection",
          items:  Array< {
            __typename: "UserUserTag",
            id: string,
            userId: string,
            userTagId: string,
            tenantCode: string,
            createdAt: string,
            updatedAt: string,
            user:  {
              __typename: "User",
              id: string,
              name: string,
              role: Role,
              extensionConfigJsonList: Array< string >,
              email?: string | null,
              code?: string | null,
              tenantCode: string,
              displaySettings?:  {
                __typename: "DisplaySettings",
                theme?: string | null,
                color?: string | null,
              } | null,
              locale?: string | null,
              avatar?: string | null,
              enabled: boolean,
              signedInAtLeastOnce: boolean,
              emailConfirmed?: boolean | null,
              confirmedTermsOfServiceVersions?:  {
                __typename: "ConfirmedTermsOfServiceVersions",
                termsOfServiceVersion?: string | null,
                privacyPolicyVersion?: string | null,
                tenantOwnTermsOfServiceVersion?: number | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              tags?:  {
                __typename: "ModelUserUserTagConnection",
                items:  Array< {
                  __typename: "UserUserTag",
                  id: string,
                  userId: string,
                  userTagId: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                } | null >,
                nextToken?: string | null,
              } | null,
              statuses?:  {
                __typename: "ModelUserStatusTableConnection",
                items:  Array< {
                  __typename: "UserStatusTable",
                  id: string,
                  tenantCode: string,
                  status: UserStatus,
                  userId: string,
                  createdAt: string,
                  updatedAt: string,
                } | null >,
                nextToken?: string | null,
              } | null,
            },
            userTag:  {
              __typename: "UserTag",
              id: string,
              color: string,
              text: string,
              tenantCode: string,
              createdAt: string,
              updatedAt: string,
              users?:  {
                __typename: "ModelUserUserTagConnection",
                items:  Array< {
                  __typename: "UserUserTag",
                  id: string,
                  userId: string,
                  userTagId: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                } | null >,
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        statuses?:  {
          __typename: "ModelUserStatusTableConnection",
          items:  Array< {
            __typename: "UserStatusTable",
            id: string,
            tenantCode: string,
            status: UserStatus,
            userId: string,
            createdAt: string,
            updatedAt: string,
            user:  {
              __typename: "User",
              id: string,
              name: string,
              role: Role,
              extensionConfigJsonList: Array< string >,
              email?: string | null,
              code?: string | null,
              tenantCode: string,
              displaySettings?:  {
                __typename: "DisplaySettings",
                theme?: string | null,
                color?: string | null,
              } | null,
              locale?: string | null,
              avatar?: string | null,
              enabled: boolean,
              signedInAtLeastOnce: boolean,
              emailConfirmed?: boolean | null,
              confirmedTermsOfServiceVersions?:  {
                __typename: "ConfirmedTermsOfServiceVersions",
                termsOfServiceVersion?: string | null,
                privacyPolicyVersion?: string | null,
                tenantOwnTermsOfServiceVersion?: number | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              tags?:  {
                __typename: "ModelUserUserTagConnection",
                items:  Array< {
                  __typename: "UserUserTag",
                  id: string,
                  userId: string,
                  userTagId: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                } | null >,
                nextToken?: string | null,
              } | null,
              statuses?:  {
                __typename: "ModelUserStatusTableConnection",
                items:  Array< {
                  __typename: "UserStatusTable",
                  id: string,
                  tenantCode: string,
                  status: UserStatus,
                  userId: string,
                  createdAt: string,
                  updatedAt: string,
                } | null >,
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      },
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UserStatusTablesByUserQueryVariables = {
  userId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserStatusTableFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UserStatusTablesByUserQuery = {
  userStatusTablesByUser?:  {
    __typename: "ModelUserStatusTableConnection",
    items:  Array< {
      __typename: "UserStatusTable",
      id: string,
      tenantCode: string,
      status: UserStatus,
      userId: string,
      createdAt: string,
      updatedAt: string,
      user:  {
        __typename: "User",
        id: string,
        name: string,
        role: Role,
        extensionConfigJsonList: Array< string >,
        email?: string | null,
        code?: string | null,
        tenantCode: string,
        displaySettings?:  {
          __typename: "DisplaySettings",
          theme?: string | null,
          color?: string | null,
        } | null,
        locale?: string | null,
        avatar?: string | null,
        enabled: boolean,
        signedInAtLeastOnce: boolean,
        emailConfirmed?: boolean | null,
        confirmedTermsOfServiceVersions?:  {
          __typename: "ConfirmedTermsOfServiceVersions",
          termsOfServiceVersion?: string | null,
          privacyPolicyVersion?: string | null,
          tenantOwnTermsOfServiceVersion?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        tags?:  {
          __typename: "ModelUserUserTagConnection",
          items:  Array< {
            __typename: "UserUserTag",
            id: string,
            userId: string,
            userTagId: string,
            tenantCode: string,
            createdAt: string,
            updatedAt: string,
            user:  {
              __typename: "User",
              id: string,
              name: string,
              role: Role,
              extensionConfigJsonList: Array< string >,
              email?: string | null,
              code?: string | null,
              tenantCode: string,
              displaySettings?:  {
                __typename: "DisplaySettings",
                theme?: string | null,
                color?: string | null,
              } | null,
              locale?: string | null,
              avatar?: string | null,
              enabled: boolean,
              signedInAtLeastOnce: boolean,
              emailConfirmed?: boolean | null,
              confirmedTermsOfServiceVersions?:  {
                __typename: "ConfirmedTermsOfServiceVersions",
                termsOfServiceVersion?: string | null,
                privacyPolicyVersion?: string | null,
                tenantOwnTermsOfServiceVersion?: number | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              tags?:  {
                __typename: "ModelUserUserTagConnection",
                items:  Array< {
                  __typename: "UserUserTag",
                  id: string,
                  userId: string,
                  userTagId: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                } | null >,
                nextToken?: string | null,
              } | null,
              statuses?:  {
                __typename: "ModelUserStatusTableConnection",
                items:  Array< {
                  __typename: "UserStatusTable",
                  id: string,
                  tenantCode: string,
                  status: UserStatus,
                  userId: string,
                  createdAt: string,
                  updatedAt: string,
                } | null >,
                nextToken?: string | null,
              } | null,
            },
            userTag:  {
              __typename: "UserTag",
              id: string,
              color: string,
              text: string,
              tenantCode: string,
              createdAt: string,
              updatedAt: string,
              users?:  {
                __typename: "ModelUserUserTagConnection",
                items:  Array< {
                  __typename: "UserUserTag",
                  id: string,
                  userId: string,
                  userTagId: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                } | null >,
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        statuses?:  {
          __typename: "ModelUserStatusTableConnection",
          items:  Array< {
            __typename: "UserStatusTable",
            id: string,
            tenantCode: string,
            status: UserStatus,
            userId: string,
            createdAt: string,
            updatedAt: string,
            user:  {
              __typename: "User",
              id: string,
              name: string,
              role: Role,
              extensionConfigJsonList: Array< string >,
              email?: string | null,
              code?: string | null,
              tenantCode: string,
              displaySettings?:  {
                __typename: "DisplaySettings",
                theme?: string | null,
                color?: string | null,
              } | null,
              locale?: string | null,
              avatar?: string | null,
              enabled: boolean,
              signedInAtLeastOnce: boolean,
              emailConfirmed?: boolean | null,
              confirmedTermsOfServiceVersions?:  {
                __typename: "ConfirmedTermsOfServiceVersions",
                termsOfServiceVersion?: string | null,
                privacyPolicyVersion?: string | null,
                tenantOwnTermsOfServiceVersion?: number | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              tags?:  {
                __typename: "ModelUserUserTagConnection",
                items:  Array< {
                  __typename: "UserUserTag",
                  id: string,
                  userId: string,
                  userTagId: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                } | null >,
                nextToken?: string | null,
              } | null,
              statuses?:  {
                __typename: "ModelUserStatusTableConnection",
                items:  Array< {
                  __typename: "UserStatusTable",
                  id: string,
                  tenantCode: string,
                  status: UserStatus,
                  userId: string,
                  createdAt: string,
                  updatedAt: string,
                } | null >,
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      },
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UserStatusTablesByTenantCodeQueryVariables = {
  tenantCode?: string | null,
  status?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserStatusTableFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UserStatusTablesByTenantCodeQuery = {
  userStatusTablesByTenantCode?:  {
    __typename: "ModelUserStatusTableConnection",
    items:  Array< {
      __typename: "UserStatusTable",
      id: string,
      tenantCode: string,
      status: UserStatus,
      userId: string,
      createdAt: string,
      updatedAt: string,
      user:  {
        __typename: "User",
        id: string,
        name: string,
        role: Role,
        extensionConfigJsonList: Array< string >,
        email?: string | null,
        code?: string | null,
        tenantCode: string,
        displaySettings?:  {
          __typename: "DisplaySettings",
          theme?: string | null,
          color?: string | null,
        } | null,
        locale?: string | null,
        avatar?: string | null,
        enabled: boolean,
        signedInAtLeastOnce: boolean,
        emailConfirmed?: boolean | null,
        confirmedTermsOfServiceVersions?:  {
          __typename: "ConfirmedTermsOfServiceVersions",
          termsOfServiceVersion?: string | null,
          privacyPolicyVersion?: string | null,
          tenantOwnTermsOfServiceVersion?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        tags?:  {
          __typename: "ModelUserUserTagConnection",
          items:  Array< {
            __typename: "UserUserTag",
            id: string,
            userId: string,
            userTagId: string,
            tenantCode: string,
            createdAt: string,
            updatedAt: string,
            user:  {
              __typename: "User",
              id: string,
              name: string,
              role: Role,
              extensionConfigJsonList: Array< string >,
              email?: string | null,
              code?: string | null,
              tenantCode: string,
              displaySettings?:  {
                __typename: "DisplaySettings",
                theme?: string | null,
                color?: string | null,
              } | null,
              locale?: string | null,
              avatar?: string | null,
              enabled: boolean,
              signedInAtLeastOnce: boolean,
              emailConfirmed?: boolean | null,
              confirmedTermsOfServiceVersions?:  {
                __typename: "ConfirmedTermsOfServiceVersions",
                termsOfServiceVersion?: string | null,
                privacyPolicyVersion?: string | null,
                tenantOwnTermsOfServiceVersion?: number | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              tags?:  {
                __typename: "ModelUserUserTagConnection",
                items:  Array< {
                  __typename: "UserUserTag",
                  id: string,
                  userId: string,
                  userTagId: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                } | null >,
                nextToken?: string | null,
              } | null,
              statuses?:  {
                __typename: "ModelUserStatusTableConnection",
                items:  Array< {
                  __typename: "UserStatusTable",
                  id: string,
                  tenantCode: string,
                  status: UserStatus,
                  userId: string,
                  createdAt: string,
                  updatedAt: string,
                } | null >,
                nextToken?: string | null,
              } | null,
            },
            userTag:  {
              __typename: "UserTag",
              id: string,
              color: string,
              text: string,
              tenantCode: string,
              createdAt: string,
              updatedAt: string,
              users?:  {
                __typename: "ModelUserUserTagConnection",
                items:  Array< {
                  __typename: "UserUserTag",
                  id: string,
                  userId: string,
                  userTagId: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                } | null >,
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        statuses?:  {
          __typename: "ModelUserStatusTableConnection",
          items:  Array< {
            __typename: "UserStatusTable",
            id: string,
            tenantCode: string,
            status: UserStatus,
            userId: string,
            createdAt: string,
            updatedAt: string,
            user:  {
              __typename: "User",
              id: string,
              name: string,
              role: Role,
              extensionConfigJsonList: Array< string >,
              email?: string | null,
              code?: string | null,
              tenantCode: string,
              displaySettings?:  {
                __typename: "DisplaySettings",
                theme?: string | null,
                color?: string | null,
              } | null,
              locale?: string | null,
              avatar?: string | null,
              enabled: boolean,
              signedInAtLeastOnce: boolean,
              emailConfirmed?: boolean | null,
              confirmedTermsOfServiceVersions?:  {
                __typename: "ConfirmedTermsOfServiceVersions",
                termsOfServiceVersion?: string | null,
                privacyPolicyVersion?: string | null,
                tenantOwnTermsOfServiceVersion?: number | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              tags?:  {
                __typename: "ModelUserUserTagConnection",
                items:  Array< {
                  __typename: "UserUserTag",
                  id: string,
                  userId: string,
                  userTagId: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                } | null >,
                nextToken?: string | null,
              } | null,
              statuses?:  {
                __typename: "ModelUserStatusTableConnection",
                items:  Array< {
                  __typename: "UserStatusTable",
                  id: string,
                  tenantCode: string,
                  status: UserStatus,
                  userId: string,
                  createdAt: string,
                  updatedAt: string,
                } | null >,
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      },
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetGroupStatusUserQueryVariables = {
  id: string,
};

export type GetGroupStatusUserQuery = {
  getGroupStatusUser?:  {
    __typename: "GroupStatusUser",
    id: string,
    userId: string,
    groupId: string,
    handStatus: HandStatus,
    tenantCode: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListGroupStatusUsersQueryVariables = {
  filter?: ModelGroupStatusUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListGroupStatusUsersQuery = {
  listGroupStatusUsers?:  {
    __typename: "ModelGroupStatusUserConnection",
    items:  Array< {
      __typename: "GroupStatusUser",
      id: string,
      userId: string,
      groupId: string,
      handStatus: HandStatus,
      tenantCode: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GroupStatusUsersByUserQueryVariables = {
  userId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelGroupStatusUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GroupStatusUsersByUserQuery = {
  groupStatusUsersByUser?:  {
    __typename: "ModelGroupStatusUserConnection",
    items:  Array< {
      __typename: "GroupStatusUser",
      id: string,
      userId: string,
      groupId: string,
      handStatus: HandStatus,
      tenantCode: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GroupStatusUsersByGroupQueryVariables = {
  groupId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelGroupStatusUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GroupStatusUsersByGroupQuery = {
  groupStatusUsersByGroup?:  {
    __typename: "ModelGroupStatusUserConnection",
    items:  Array< {
      __typename: "GroupStatusUser",
      id: string,
      userId: string,
      groupId: string,
      handStatus: HandStatus,
      tenantCode: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GroupStatusUsersByTenantCodeQueryVariables = {
  tenantCode?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelGroupStatusUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GroupStatusUsersByTenantCodeQuery = {
  groupStatusUsersByTenantCode?:  {
    __typename: "ModelGroupStatusUserConnection",
    items:  Array< {
      __typename: "GroupStatusUser",
      id: string,
      userId: string,
      groupId: string,
      handStatus: HandStatus,
      tenantCode: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetGroupStatusQueryVariables = {
  groupId: string,
};

export type GetGroupStatusQuery = {
  getGroupStatus?:  {
    __typename: "GroupStatus",
    groupId: string,
    tenantCode: string,
    createdAt: string,
    updatedAt: string,
    users?:  {
      __typename: "ModelGroupStatusUserConnection",
      items:  Array< {
        __typename: "GroupStatusUser",
        id: string,
        userId: string,
        groupId: string,
        handStatus: HandStatus,
        tenantCode: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListGroupStatussQueryVariables = {
  groupId?: string | null,
  filter?: ModelGroupStatusFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListGroupStatussQuery = {
  listGroupStatuss?:  {
    __typename: "ModelGroupStatusConnection",
    items:  Array< {
      __typename: "GroupStatus",
      groupId: string,
      tenantCode: string,
      createdAt: string,
      updatedAt: string,
      users?:  {
        __typename: "ModelGroupStatusUserConnection",
        items:  Array< {
          __typename: "GroupStatusUser",
          id: string,
          userId: string,
          groupId: string,
          handStatus: HandStatus,
          tenantCode: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GroupStatusesByTenantCodeQueryVariables = {
  tenantCode?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelGroupStatusFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GroupStatusesByTenantCodeQuery = {
  groupStatusesByTenantCode?:  {
    __typename: "ModelGroupStatusConnection",
    items:  Array< {
      __typename: "GroupStatus",
      groupId: string,
      tenantCode: string,
      createdAt: string,
      updatedAt: string,
      users?:  {
        __typename: "ModelGroupStatusUserConnection",
        items:  Array< {
          __typename: "GroupStatusUser",
          id: string,
          userId: string,
          groupId: string,
          handStatus: HandStatus,
          tenantCode: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetInternalTaskQueryVariables = {
  id: string,
};

export type GetInternalTaskQuery = {
  getInternalTask?:  {
    __typename: "InternalTask",
    id: string,
    type: string,
    createdAt?: string | null,
    createdBy: string,
    finishedAt?: string | null,
    status: InternalTaskStatus,
    taskVersion: number,
    payload: string,
    tenantCode: string,
    updatedAt: string,
  } | null,
};

export type ListInternalTasksQueryVariables = {
  filter?: ModelInternalTaskFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListInternalTasksQuery = {
  listInternalTasks?:  {
    __typename: "ModelInternalTaskConnection",
    items:  Array< {
      __typename: "InternalTask",
      id: string,
      type: string,
      createdAt?: string | null,
      createdBy: string,
      finishedAt?: string | null,
      status: InternalTaskStatus,
      taskVersion: number,
      payload: string,
      tenantCode: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPlaygroundQueryVariables = {
  id: string,
};

export type GetPlaygroundQuery = {
  getPlayground?:  {
    __typename: "Playground",
    id: string,
    status: PlaygroundStatus,
    email: string,
    tenantCode: string,
    tenantName: string,
    userCode?: string | null,
    temporaryPassword?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListPlaygroundsQueryVariables = {
  filter?: ModelPlaygroundFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPlaygroundsQuery = {
  listPlaygrounds?:  {
    __typename: "ModelPlaygroundConnection",
    items:  Array< {
      __typename: "Playground",
      id: string,
      status: PlaygroundStatus,
      email: string,
      tenantCode: string,
      tenantName: string,
      userCode?: string | null,
      temporaryPassword?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PlaygroundsByTenantCodeQueryVariables = {
  tenantCode?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPlaygroundFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PlaygroundsByTenantCodeQuery = {
  playgroundsByTenantCode?:  {
    __typename: "ModelPlaygroundConnection",
    items:  Array< {
      __typename: "Playground",
      id: string,
      status: PlaygroundStatus,
      email: string,
      tenantCode: string,
      tenantName: string,
      userCode?: string | null,
      temporaryPassword?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PlaygroundsByStatusQueryVariables = {
  status?: PlaygroundStatus | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPlaygroundFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PlaygroundsByStatusQuery = {
  playgroundsByStatus?:  {
    __typename: "ModelPlaygroundConnection",
    items:  Array< {
      __typename: "Playground",
      id: string,
      status: PlaygroundStatus,
      email: string,
      tenantCode: string,
      tenantName: string,
      userCode?: string | null,
      temporaryPassword?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetContentQueryVariables = {
  id: string,
};

export type GetContentQuery = {
  getContent?:  {
    __typename: "Content",
    id: string,
    contentId: string,
    version: number,
    name: string,
    requiredTime: number,
    body:  {
      __typename: "ContentBody",
      type: ContentType,
      text?:  {
        __typename: "TextContent",
        body: string,
      } | null,
      exam?:  {
        __typename: "ExamContent",
        problems:  Array< {
          __typename: "Problem",
          index: number,
          body: string,
          commentary?: string | null,
          type: ProblemType,
          choice?:  {
            __typename: "ChoiceProblem",
            options:  Array< {
              __typename: "ChoiceProblemOption",
              index: number,
              text: string,
            } >,
            multiple: boolean,
            answer: Array< number >,
          } | null,
          headerId?: string | null,
        } >,
        problemHeaders?:  Array< {
          __typename: "ProblemHeader",
          id: string,
          body: string,
          createdAt: string,
        } > | null,
        passingStandard?: number | null,
      } | null,
    },
    workbook?:  {
      __typename: "Workbook",
      problems:  Array< {
        __typename: "Problem",
        index: number,
        body: string,
        commentary?: string | null,
        type: ProblemType,
        choice?:  {
          __typename: "ChoiceProblem",
          options:  Array< {
            __typename: "ChoiceProblemOption",
            index: number,
            text: string,
          } >,
          multiple: boolean,
          answer: Array< number >,
        } | null,
        headerId?: string | null,
      } >,
      problemHeaders?:  Array< {
        __typename: "ProblemHeader",
        id: string,
        body: string,
        createdAt: string,
      } > | null,
    } | null,
    courseId: string,
    courseVersion: number,
    tenantCode: string,
    latest: boolean,
    versionDescription?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListContentsQueryVariables = {
  filter?: ModelContentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListContentsQuery = {
  listContents?:  {
    __typename: "ModelContentConnection",
    items:  Array< {
      __typename: "Content",
      id: string,
      contentId: string,
      version: number,
      name: string,
      requiredTime: number,
      body:  {
        __typename: "ContentBody",
        type: ContentType,
        text?:  {
          __typename: "TextContent",
          body: string,
        } | null,
        exam?:  {
          __typename: "ExamContent",
          problems:  Array< {
            __typename: "Problem",
            index: number,
            body: string,
            commentary?: string | null,
            type: ProblemType,
            choice?:  {
              __typename: "ChoiceProblem",
              options:  Array< {
                __typename: "ChoiceProblemOption",
                index: number,
                text: string,
              } >,
              multiple: boolean,
              answer: Array< number >,
            } | null,
            headerId?: string | null,
          } >,
          problemHeaders?:  Array< {
            __typename: "ProblemHeader",
            id: string,
            body: string,
            createdAt: string,
          } > | null,
          passingStandard?: number | null,
        } | null,
      },
      workbook?:  {
        __typename: "Workbook",
        problems:  Array< {
          __typename: "Problem",
          index: number,
          body: string,
          commentary?: string | null,
          type: ProblemType,
          choice?:  {
            __typename: "ChoiceProblem",
            options:  Array< {
              __typename: "ChoiceProblemOption",
              index: number,
              text: string,
            } >,
            multiple: boolean,
            answer: Array< number >,
          } | null,
          headerId?: string | null,
        } >,
        problemHeaders?:  Array< {
          __typename: "ProblemHeader",
          id: string,
          body: string,
          createdAt: string,
        } > | null,
      } | null,
      courseId: string,
      courseVersion: number,
      tenantCode: string,
      latest: boolean,
      versionDescription?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ContentByCourseQueryVariables = {
  courseId?: string | null,
  courseVersion?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelContentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ContentByCourseQuery = {
  contentByCourse?:  {
    __typename: "ModelContentConnection",
    items:  Array< {
      __typename: "Content",
      id: string,
      contentId: string,
      version: number,
      name: string,
      requiredTime: number,
      body:  {
        __typename: "ContentBody",
        type: ContentType,
        text?:  {
          __typename: "TextContent",
          body: string,
        } | null,
        exam?:  {
          __typename: "ExamContent",
          problems:  Array< {
            __typename: "Problem",
            index: number,
            body: string,
            commentary?: string | null,
            type: ProblemType,
            choice?:  {
              __typename: "ChoiceProblem",
              options:  Array< {
                __typename: "ChoiceProblemOption",
                index: number,
                text: string,
              } >,
              multiple: boolean,
              answer: Array< number >,
            } | null,
            headerId?: string | null,
          } >,
          problemHeaders?:  Array< {
            __typename: "ProblemHeader",
            id: string,
            body: string,
            createdAt: string,
          } > | null,
          passingStandard?: number | null,
        } | null,
      },
      workbook?:  {
        __typename: "Workbook",
        problems:  Array< {
          __typename: "Problem",
          index: number,
          body: string,
          commentary?: string | null,
          type: ProblemType,
          choice?:  {
            __typename: "ChoiceProblem",
            options:  Array< {
              __typename: "ChoiceProblemOption",
              index: number,
              text: string,
            } >,
            multiple: boolean,
            answer: Array< number >,
          } | null,
          headerId?: string | null,
        } >,
        problemHeaders?:  Array< {
          __typename: "ProblemHeader",
          id: string,
          body: string,
          createdAt: string,
        } > | null,
      } | null,
      courseId: string,
      courseVersion: number,
      tenantCode: string,
      latest: boolean,
      versionDescription?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ContentsByTenantCodeQueryVariables = {
  tenantCode?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelContentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ContentsByTenantCodeQuery = {
  contentsByTenantCode?:  {
    __typename: "ModelContentConnection",
    items:  Array< {
      __typename: "Content",
      id: string,
      contentId: string,
      version: number,
      name: string,
      requiredTime: number,
      body:  {
        __typename: "ContentBody",
        type: ContentType,
        text?:  {
          __typename: "TextContent",
          body: string,
        } | null,
        exam?:  {
          __typename: "ExamContent",
          problems:  Array< {
            __typename: "Problem",
            index: number,
            body: string,
            commentary?: string | null,
            type: ProblemType,
            choice?:  {
              __typename: "ChoiceProblem",
              options:  Array< {
                __typename: "ChoiceProblemOption",
                index: number,
                text: string,
              } >,
              multiple: boolean,
              answer: Array< number >,
            } | null,
            headerId?: string | null,
          } >,
          problemHeaders?:  Array< {
            __typename: "ProblemHeader",
            id: string,
            body: string,
            createdAt: string,
          } > | null,
          passingStandard?: number | null,
        } | null,
      },
      workbook?:  {
        __typename: "Workbook",
        problems:  Array< {
          __typename: "Problem",
          index: number,
          body: string,
          commentary?: string | null,
          type: ProblemType,
          choice?:  {
            __typename: "ChoiceProblem",
            options:  Array< {
              __typename: "ChoiceProblemOption",
              index: number,
              text: string,
            } >,
            multiple: boolean,
            answer: Array< number >,
          } | null,
          headerId?: string | null,
        } >,
        problemHeaders?:  Array< {
          __typename: "ProblemHeader",
          id: string,
          body: string,
          createdAt: string,
        } > | null,
      } | null,
      courseId: string,
      courseVersion: number,
      tenantCode: string,
      latest: boolean,
      versionDescription?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ContentsByContentQueryVariables = {
  contentId?: string | null,
  version?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelContentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ContentsByContentQuery = {
  contentsByContent?:  {
    __typename: "ModelContentConnection",
    items:  Array< {
      __typename: "Content",
      id: string,
      contentId: string,
      version: number,
      name: string,
      requiredTime: number,
      body:  {
        __typename: "ContentBody",
        type: ContentType,
        text?:  {
          __typename: "TextContent",
          body: string,
        } | null,
        exam?:  {
          __typename: "ExamContent",
          problems:  Array< {
            __typename: "Problem",
            index: number,
            body: string,
            commentary?: string | null,
            type: ProblemType,
            choice?:  {
              __typename: "ChoiceProblem",
              options:  Array< {
                __typename: "ChoiceProblemOption",
                index: number,
                text: string,
              } >,
              multiple: boolean,
              answer: Array< number >,
            } | null,
            headerId?: string | null,
          } >,
          problemHeaders?:  Array< {
            __typename: "ProblemHeader",
            id: string,
            body: string,
            createdAt: string,
          } > | null,
          passingStandard?: number | null,
        } | null,
      },
      workbook?:  {
        __typename: "Workbook",
        problems:  Array< {
          __typename: "Problem",
          index: number,
          body: string,
          commentary?: string | null,
          type: ProblemType,
          choice?:  {
            __typename: "ChoiceProblem",
            options:  Array< {
              __typename: "ChoiceProblemOption",
              index: number,
              text: string,
            } >,
            multiple: boolean,
            answer: Array< number >,
          } | null,
          headerId?: string | null,
        } >,
        problemHeaders?:  Array< {
          __typename: "ProblemHeader",
          id: string,
          body: string,
          createdAt: string,
        } > | null,
      } | null,
      courseId: string,
      courseVersion: number,
      tenantCode: string,
      latest: boolean,
      versionDescription?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCourseQueryVariables = {
  id: string,
  version: number,
};

export type GetCourseQuery = {
  getCourse?:  {
    __typename: "Course",
    id: string,
    version: number,
    name: string,
    contents:  Array< {
      __typename: "CourseContent",
      id: string,
      type: ContentType,
      name: string,
      requiredTime: number,
    } >,
    description?: string | null,
    tenantCode: string,
    color?: string | null,
    image?: string | null,
    fontColorOnImage?: CourseFontColorOnImage | null,
    confirmedBy?: string | null,
    createdAt?: string | null,
    versionCreatedBy?: string | null,
    versionCreatedAt?: string | null,
    contentLastUpdatedBy?: string | null,
    contentLastUpdatedAt?: string | null,
    updatedAt: string,
  } | null,
};

export type ListCoursesQueryVariables = {
  id?: string | null,
  version?: ModelIntKeyConditionInput | null,
  filter?: ModelCourseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListCoursesQuery = {
  listCourses?:  {
    __typename: "ModelCourseConnection",
    items:  Array< {
      __typename: "Course",
      id: string,
      version: number,
      name: string,
      contents:  Array< {
        __typename: "CourseContent",
        id: string,
        type: ContentType,
        name: string,
        requiredTime: number,
      } >,
      description?: string | null,
      tenantCode: string,
      color?: string | null,
      image?: string | null,
      fontColorOnImage?: CourseFontColorOnImage | null,
      confirmedBy?: string | null,
      createdAt?: string | null,
      versionCreatedBy?: string | null,
      versionCreatedAt?: string | null,
      contentLastUpdatedBy?: string | null,
      contentLastUpdatedAt?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CoursesByNameQueryVariables = {
  name?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCourseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CoursesByNameQuery = {
  coursesByName?:  {
    __typename: "ModelCourseConnection",
    items:  Array< {
      __typename: "Course",
      id: string,
      version: number,
      name: string,
      contents:  Array< {
        __typename: "CourseContent",
        id: string,
        type: ContentType,
        name: string,
        requiredTime: number,
      } >,
      description?: string | null,
      tenantCode: string,
      color?: string | null,
      image?: string | null,
      fontColorOnImage?: CourseFontColorOnImage | null,
      confirmedBy?: string | null,
      createdAt?: string | null,
      versionCreatedBy?: string | null,
      versionCreatedAt?: string | null,
      contentLastUpdatedBy?: string | null,
      contentLastUpdatedAt?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CoursesByTenantCodeQueryVariables = {
  tenantCode?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCourseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CoursesByTenantCodeQuery = {
  coursesByTenantCode?:  {
    __typename: "ModelCourseConnection",
    items:  Array< {
      __typename: "Course",
      id: string,
      version: number,
      name: string,
      contents:  Array< {
        __typename: "CourseContent",
        id: string,
        type: ContentType,
        name: string,
        requiredTime: number,
      } >,
      description?: string | null,
      tenantCode: string,
      color?: string | null,
      image?: string | null,
      fontColorOnImage?: CourseFontColorOnImage | null,
      confirmedBy?: string | null,
      createdAt?: string | null,
      versionCreatedBy?: string | null,
      versionCreatedAt?: string | null,
      contentLastUpdatedBy?: string | null,
      contentLastUpdatedAt?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetEditingCourseQueryVariables = {
  id: string,
};

export type GetEditingCourseQuery = {
  getEditingCourse?:  {
    __typename: "EditingCourse",
    id: string,
    tenantCode: string,
    name: string,
    version: number,
    status: EditingStatus,
    description?: string | null,
    contents:  Array< {
      __typename: "EditingCourseContentHeader",
      id: string,
      name: string,
      requiredTime: number,
      type: ContentType,
    } >,
    color?: string | null,
    image?: string | null,
    fontColorOnImage?: CourseFontColorOnImage | null,
    createdBy: string,
    createdAt?: string | null,
    updatedBy: string,
    updatedAt?: string | null,
  } | null,
};

export type ListEditingCoursesQueryVariables = {
  filter?: ModelEditingCourseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEditingCoursesQuery = {
  listEditingCourses?:  {
    __typename: "ModelEditingCourseConnection",
    items:  Array< {
      __typename: "EditingCourse",
      id: string,
      tenantCode: string,
      name: string,
      version: number,
      status: EditingStatus,
      description?: string | null,
      contents:  Array< {
        __typename: "EditingCourseContentHeader",
        id: string,
        name: string,
        requiredTime: number,
        type: ContentType,
      } >,
      color?: string | null,
      image?: string | null,
      fontColorOnImage?: CourseFontColorOnImage | null,
      createdBy: string,
      createdAt?: string | null,
      updatedBy: string,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type EditingCoursesByTenantCodeQueryVariables = {
  tenantCode?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEditingCourseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type EditingCoursesByTenantCodeQuery = {
  editingCoursesByTenantCode?:  {
    __typename: "ModelEditingCourseConnection",
    items:  Array< {
      __typename: "EditingCourse",
      id: string,
      tenantCode: string,
      name: string,
      version: number,
      status: EditingStatus,
      description?: string | null,
      contents:  Array< {
        __typename: "EditingCourseContentHeader",
        id: string,
        name: string,
        requiredTime: number,
        type: ContentType,
      } >,
      color?: string | null,
      image?: string | null,
      fontColorOnImage?: CourseFontColorOnImage | null,
      createdBy: string,
      createdAt?: string | null,
      updatedBy: string,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetEditingCourseContentWorkbookQueryVariables = {
  contentId: string,
};

export type GetEditingCourseContentWorkbookQuery = {
  getEditingCourseContentWorkbook?:  {
    __typename: "EditingCourseContentWorkbook",
    contentId: string,
    problems:  Array< {
      __typename: "Problem",
      index: number,
      body: string,
      commentary?: string | null,
      type: ProblemType,
      choice?:  {
        __typename: "ChoiceProblem",
        options:  Array< {
          __typename: "ChoiceProblemOption",
          index: number,
          text: string,
        } >,
        multiple: boolean,
        answer: Array< number >,
      } | null,
      headerId?: string | null,
    } >,
    problemHeaders?:  Array< {
      __typename: "ProblemHeader",
      id: string,
      body: string,
      createdAt: string,
    } > | null,
    dataVersion: number,
    createdBy: string,
    updatedBy: string,
    createdAt: string,
    updatedAt: string,
    tenantCode: string,
    courseId: string,
  } | null,
};

export type ListEditingCourseContentWorkbooksQueryVariables = {
  contentId?: string | null,
  filter?: ModelEditingCourseContentWorkbookFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListEditingCourseContentWorkbooksQuery = {
  listEditingCourseContentWorkbooks?:  {
    __typename: "ModelEditingCourseContentWorkbookConnection",
    items:  Array< {
      __typename: "EditingCourseContentWorkbook",
      contentId: string,
      problems:  Array< {
        __typename: "Problem",
        index: number,
        body: string,
        commentary?: string | null,
        type: ProblemType,
        choice?:  {
          __typename: "ChoiceProblem",
          options:  Array< {
            __typename: "ChoiceProblemOption",
            index: number,
            text: string,
          } >,
          multiple: boolean,
          answer: Array< number >,
        } | null,
        headerId?: string | null,
      } >,
      problemHeaders?:  Array< {
        __typename: "ProblemHeader",
        id: string,
        body: string,
        createdAt: string,
      } > | null,
      dataVersion: number,
      createdBy: string,
      updatedBy: string,
      createdAt: string,
      updatedAt: string,
      tenantCode: string,
      courseId: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type EditingCourseContentWorkbooksByCourseQueryVariables = {
  courseId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEditingCourseContentWorkbookFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type EditingCourseContentWorkbooksByCourseQuery = {
  editingCourseContentWorkbooksByCourse?:  {
    __typename: "ModelEditingCourseContentWorkbookConnection",
    items:  Array< {
      __typename: "EditingCourseContentWorkbook",
      contentId: string,
      problems:  Array< {
        __typename: "Problem",
        index: number,
        body: string,
        commentary?: string | null,
        type: ProblemType,
        choice?:  {
          __typename: "ChoiceProblem",
          options:  Array< {
            __typename: "ChoiceProblemOption",
            index: number,
            text: string,
          } >,
          multiple: boolean,
          answer: Array< number >,
        } | null,
        headerId?: string | null,
      } >,
      problemHeaders?:  Array< {
        __typename: "ProblemHeader",
        id: string,
        body: string,
        createdAt: string,
      } > | null,
      dataVersion: number,
      createdBy: string,
      updatedBy: string,
      createdAt: string,
      updatedAt: string,
      tenantCode: string,
      courseId: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetEditingCourseContentBodyQueryVariables = {
  contentId: string,
};

export type GetEditingCourseContentBodyQuery = {
  getEditingCourseContentBody?:  {
    __typename: "EditingCourseContentBody",
    contentId: string,
    body:  {
      __typename: "ContentBody",
      type: ContentType,
      text?:  {
        __typename: "TextContent",
        body: string,
      } | null,
      exam?:  {
        __typename: "ExamContent",
        problems:  Array< {
          __typename: "Problem",
          index: number,
          body: string,
          commentary?: string | null,
          type: ProblemType,
          choice?:  {
            __typename: "ChoiceProblem",
            options:  Array< {
              __typename: "ChoiceProblemOption",
              index: number,
              text: string,
            } >,
            multiple: boolean,
            answer: Array< number >,
          } | null,
          headerId?: string | null,
        } >,
        problemHeaders?:  Array< {
          __typename: "ProblemHeader",
          id: string,
          body: string,
          createdAt: string,
        } > | null,
        passingStandard?: number | null,
      } | null,
    },
    dataVersion: number,
    createdBy: string,
    updatedBy: string,
    createdAt: string,
    updatedAt: string,
    tenantCode: string,
    courseId: string,
  } | null,
};

export type ListEditingCourseContentBodysQueryVariables = {
  contentId?: string | null,
  filter?: ModelEditingCourseContentBodyFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListEditingCourseContentBodysQuery = {
  listEditingCourseContentBodys?:  {
    __typename: "ModelEditingCourseContentBodyConnection",
    items:  Array< {
      __typename: "EditingCourseContentBody",
      contentId: string,
      body:  {
        __typename: "ContentBody",
        type: ContentType,
        text?:  {
          __typename: "TextContent",
          body: string,
        } | null,
        exam?:  {
          __typename: "ExamContent",
          problems:  Array< {
            __typename: "Problem",
            index: number,
            body: string,
            commentary?: string | null,
            type: ProblemType,
            choice?:  {
              __typename: "ChoiceProblem",
              options:  Array< {
                __typename: "ChoiceProblemOption",
                index: number,
                text: string,
              } >,
              multiple: boolean,
              answer: Array< number >,
            } | null,
            headerId?: string | null,
          } >,
          problemHeaders?:  Array< {
            __typename: "ProblemHeader",
            id: string,
            body: string,
            createdAt: string,
          } > | null,
          passingStandard?: number | null,
        } | null,
      },
      dataVersion: number,
      createdBy: string,
      updatedBy: string,
      createdAt: string,
      updatedAt: string,
      tenantCode: string,
      courseId: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type EditingCourseContentBodiesByCourseQueryVariables = {
  courseId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEditingCourseContentBodyFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type EditingCourseContentBodiesByCourseQuery = {
  editingCourseContentBodiesByCourse?:  {
    __typename: "ModelEditingCourseContentBodyConnection",
    items:  Array< {
      __typename: "EditingCourseContentBody",
      contentId: string,
      body:  {
        __typename: "ContentBody",
        type: ContentType,
        text?:  {
          __typename: "TextContent",
          body: string,
        } | null,
        exam?:  {
          __typename: "ExamContent",
          problems:  Array< {
            __typename: "Problem",
            index: number,
            body: string,
            commentary?: string | null,
            type: ProblemType,
            choice?:  {
              __typename: "ChoiceProblem",
              options:  Array< {
                __typename: "ChoiceProblemOption",
                index: number,
                text: string,
              } >,
              multiple: boolean,
              answer: Array< number >,
            } | null,
            headerId?: string | null,
          } >,
          problemHeaders?:  Array< {
            __typename: "ProblemHeader",
            id: string,
            body: string,
            createdAt: string,
          } > | null,
          passingStandard?: number | null,
        } | null,
      },
      dataVersion: number,
      createdBy: string,
      updatedBy: string,
      createdAt: string,
      updatedAt: string,
      tenantCode: string,
      courseId: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCourseHeaderQueryVariables = {
  id: string,
};

export type GetCourseHeaderQuery = {
  getCourseHeader?:  {
    __typename: "CourseHeader",
    id: string,
    name: string,
    activeVersion: number,
    status: CourseStatus,
    originalCourseId?: string | null,
    originalCourseVersion?: number | null,
    tenantCode: string,
    createdBy: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListCourseHeadersQueryVariables = {
  filter?: ModelCourseHeaderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCourseHeadersQuery = {
  listCourseHeaders?:  {
    __typename: "ModelCourseHeaderConnection",
    items:  Array< {
      __typename: "CourseHeader",
      id: string,
      name: string,
      activeVersion: number,
      status: CourseStatus,
      originalCourseId?: string | null,
      originalCourseVersion?: number | null,
      tenantCode: string,
      createdBy: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CourseHeaderByNameQueryVariables = {
  name?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCourseHeaderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CourseHeaderByNameQuery = {
  courseHeaderByName?:  {
    __typename: "ModelCourseHeaderConnection",
    items:  Array< {
      __typename: "CourseHeader",
      id: string,
      name: string,
      activeVersion: number,
      status: CourseStatus,
      originalCourseId?: string | null,
      originalCourseVersion?: number | null,
      tenantCode: string,
      createdBy: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CourseHeaderByTenantCodeQueryVariables = {
  tenantCode?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCourseHeaderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CourseHeaderByTenantCodeQuery = {
  courseHeaderByTenantCode?:  {
    __typename: "ModelCourseHeaderConnection",
    items:  Array< {
      __typename: "CourseHeader",
      id: string,
      name: string,
      activeVersion: number,
      status: CourseStatus,
      originalCourseId?: string | null,
      originalCourseVersion?: number | null,
      tenantCode: string,
      createdBy: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetEditingConfirmedContentQueryVariables = {
  id: string,
};

export type GetEditingConfirmedContentQuery = {
  getEditingConfirmedContent?:  {
    __typename: "EditingConfirmedContent",
    id: string,
    version: number,
    name: string,
    requiredTime: number,
    body:  {
      __typename: "ContentBody",
      type: ContentType,
      text?:  {
        __typename: "TextContent",
        body: string,
      } | null,
      exam?:  {
        __typename: "ExamContent",
        problems:  Array< {
          __typename: "Problem",
          index: number,
          body: string,
          commentary?: string | null,
          type: ProblemType,
          choice?:  {
            __typename: "ChoiceProblem",
            options:  Array< {
              __typename: "ChoiceProblemOption",
              index: number,
              text: string,
            } >,
            multiple: boolean,
            answer: Array< number >,
          } | null,
          headerId?: string | null,
        } >,
        problemHeaders?:  Array< {
          __typename: "ProblemHeader",
          id: string,
          body: string,
          createdAt: string,
        } > | null,
        passingStandard?: number | null,
      } | null,
    },
    workbook?:  {
      __typename: "Workbook",
      problems:  Array< {
        __typename: "Problem",
        index: number,
        body: string,
        commentary?: string | null,
        type: ProblemType,
        choice?:  {
          __typename: "ChoiceProblem",
          options:  Array< {
            __typename: "ChoiceProblemOption",
            index: number,
            text: string,
          } >,
          multiple: boolean,
          answer: Array< number >,
        } | null,
        headerId?: string | null,
      } >,
      problemHeaders?:  Array< {
        __typename: "ProblemHeader",
        id: string,
        body: string,
        createdAt: string,
      } > | null,
    } | null,
    courseId: string,
    courseVersion: number,
    status: EditingStatus,
    tenantCode: string,
    createdBy: string,
    updatedBy: string,
    dataVersion: number,
    versionDescription?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListEditingConfirmedContentsQueryVariables = {
  filter?: ModelEditingConfirmedContentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEditingConfirmedContentsQuery = {
  listEditingConfirmedContents?:  {
    __typename: "ModelEditingConfirmedContentConnection",
    items:  Array< {
      __typename: "EditingConfirmedContent",
      id: string,
      version: number,
      name: string,
      requiredTime: number,
      body:  {
        __typename: "ContentBody",
        type: ContentType,
        text?:  {
          __typename: "TextContent",
          body: string,
        } | null,
        exam?:  {
          __typename: "ExamContent",
          problems:  Array< {
            __typename: "Problem",
            index: number,
            body: string,
            commentary?: string | null,
            type: ProblemType,
            choice?:  {
              __typename: "ChoiceProblem",
              options:  Array< {
                __typename: "ChoiceProblemOption",
                index: number,
                text: string,
              } >,
              multiple: boolean,
              answer: Array< number >,
            } | null,
            headerId?: string | null,
          } >,
          problemHeaders?:  Array< {
            __typename: "ProblemHeader",
            id: string,
            body: string,
            createdAt: string,
          } > | null,
          passingStandard?: number | null,
        } | null,
      },
      workbook?:  {
        __typename: "Workbook",
        problems:  Array< {
          __typename: "Problem",
          index: number,
          body: string,
          commentary?: string | null,
          type: ProblemType,
          choice?:  {
            __typename: "ChoiceProblem",
            options:  Array< {
              __typename: "ChoiceProblemOption",
              index: number,
              text: string,
            } >,
            multiple: boolean,
            answer: Array< number >,
          } | null,
          headerId?: string | null,
        } >,
        problemHeaders?:  Array< {
          __typename: "ProblemHeader",
          id: string,
          body: string,
          createdAt: string,
        } > | null,
      } | null,
      courseId: string,
      courseVersion: number,
      status: EditingStatus,
      tenantCode: string,
      createdBy: string,
      updatedBy: string,
      dataVersion: number,
      versionDescription?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type EditingConfirmedContentsByTenantCodeQueryVariables = {
  tenantCode?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEditingConfirmedContentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type EditingConfirmedContentsByTenantCodeQuery = {
  editingConfirmedContentsByTenantCode?:  {
    __typename: "ModelEditingConfirmedContentConnection",
    items:  Array< {
      __typename: "EditingConfirmedContent",
      id: string,
      version: number,
      name: string,
      requiredTime: number,
      body:  {
        __typename: "ContentBody",
        type: ContentType,
        text?:  {
          __typename: "TextContent",
          body: string,
        } | null,
        exam?:  {
          __typename: "ExamContent",
          problems:  Array< {
            __typename: "Problem",
            index: number,
            body: string,
            commentary?: string | null,
            type: ProblemType,
            choice?:  {
              __typename: "ChoiceProblem",
              options:  Array< {
                __typename: "ChoiceProblemOption",
                index: number,
                text: string,
              } >,
              multiple: boolean,
              answer: Array< number >,
            } | null,
            headerId?: string | null,
          } >,
          problemHeaders?:  Array< {
            __typename: "ProblemHeader",
            id: string,
            body: string,
            createdAt: string,
          } > | null,
          passingStandard?: number | null,
        } | null,
      },
      workbook?:  {
        __typename: "Workbook",
        problems:  Array< {
          __typename: "Problem",
          index: number,
          body: string,
          commentary?: string | null,
          type: ProblemType,
          choice?:  {
            __typename: "ChoiceProblem",
            options:  Array< {
              __typename: "ChoiceProblemOption",
              index: number,
              text: string,
            } >,
            multiple: boolean,
            answer: Array< number >,
          } | null,
          headerId?: string | null,
        } >,
        problemHeaders?:  Array< {
          __typename: "ProblemHeader",
          id: string,
          body: string,
          createdAt: string,
        } > | null,
      } | null,
      courseId: string,
      courseVersion: number,
      status: EditingStatus,
      tenantCode: string,
      createdBy: string,
      updatedBy: string,
      dataVersion: number,
      versionDescription?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type EditingConfirmedContentsByCourseQueryVariables = {
  courseId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEditingConfirmedContentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type EditingConfirmedContentsByCourseQuery = {
  editingConfirmedContentsByCourse?:  {
    __typename: "ModelEditingConfirmedContentConnection",
    items:  Array< {
      __typename: "EditingConfirmedContent",
      id: string,
      version: number,
      name: string,
      requiredTime: number,
      body:  {
        __typename: "ContentBody",
        type: ContentType,
        text?:  {
          __typename: "TextContent",
          body: string,
        } | null,
        exam?:  {
          __typename: "ExamContent",
          problems:  Array< {
            __typename: "Problem",
            index: number,
            body: string,
            commentary?: string | null,
            type: ProblemType,
            choice?:  {
              __typename: "ChoiceProblem",
              options:  Array< {
                __typename: "ChoiceProblemOption",
                index: number,
                text: string,
              } >,
              multiple: boolean,
              answer: Array< number >,
            } | null,
            headerId?: string | null,
          } >,
          problemHeaders?:  Array< {
            __typename: "ProblemHeader",
            id: string,
            body: string,
            createdAt: string,
          } > | null,
          passingStandard?: number | null,
        } | null,
      },
      workbook?:  {
        __typename: "Workbook",
        problems:  Array< {
          __typename: "Problem",
          index: number,
          body: string,
          commentary?: string | null,
          type: ProblemType,
          choice?:  {
            __typename: "ChoiceProblem",
            options:  Array< {
              __typename: "ChoiceProblemOption",
              index: number,
              text: string,
            } >,
            multiple: boolean,
            answer: Array< number >,
          } | null,
          headerId?: string | null,
        } >,
        problemHeaders?:  Array< {
          __typename: "ProblemHeader",
          id: string,
          body: string,
          createdAt: string,
        } > | null,
      } | null,
      courseId: string,
      courseVersion: number,
      status: EditingStatus,
      tenantCode: string,
      createdBy: string,
      updatedBy: string,
      dataVersion: number,
      versionDescription?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetExamResultQueryVariables = {
  id: string,
};

export type GetExamResultQuery = {
  getExamResult?:  {
    __typename: "ExamResult",
    id: string,
    groupId: string,
    courseId: string,
    contentId: string,
    contentVersion?: number | null,
    userId: string,
    start: string,
    end?: string | null,
    score?: number | null,
    problemCount: number,
    tenantCode: string,
    answers?: string | null,
    visibilityLevel?: ExamResultVisibilityLevel | null,
    groupExamId?: string | null,
    times?: number | null,
    passingStandard?: number | null,
    version?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListExamResultsQueryVariables = {
  filter?: ModelExamResultFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListExamResultsQuery = {
  listExamResults?:  {
    __typename: "ModelExamResultConnection",
    items:  Array< {
      __typename: "ExamResult",
      id: string,
      groupId: string,
      courseId: string,
      contentId: string,
      contentVersion?: number | null,
      userId: string,
      start: string,
      end?: string | null,
      score?: number | null,
      problemCount: number,
      tenantCode: string,
      answers?: string | null,
      visibilityLevel?: ExamResultVisibilityLevel | null,
      groupExamId?: string | null,
      times?: number | null,
      passingStandard?: number | null,
      version?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ExamResultsByUserIdQueryVariables = {
  groupId?: string | null,
  userId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelExamResultFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ExamResultsByUserIdQuery = {
  examResultsByUserId?:  {
    __typename: "ModelExamResultConnection",
    items:  Array< {
      __typename: "ExamResult",
      id: string,
      groupId: string,
      courseId: string,
      contentId: string,
      contentVersion?: number | null,
      userId: string,
      start: string,
      end?: string | null,
      score?: number | null,
      problemCount: number,
      tenantCode: string,
      answers?: string | null,
      visibilityLevel?: ExamResultVisibilityLevel | null,
      groupExamId?: string | null,
      times?: number | null,
      passingStandard?: number | null,
      version?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ExamResultsByGroupAndContentQueryVariables = {
  groupId?: string | null,
  contentId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelExamResultFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ExamResultsByGroupAndContentQuery = {
  examResultsByGroupAndContent?:  {
    __typename: "ModelExamResultConnection",
    items:  Array< {
      __typename: "ExamResult",
      id: string,
      groupId: string,
      courseId: string,
      contentId: string,
      contentVersion?: number | null,
      userId: string,
      start: string,
      end?: string | null,
      score?: number | null,
      problemCount: number,
      tenantCode: string,
      answers?: string | null,
      visibilityLevel?: ExamResultVisibilityLevel | null,
      groupExamId?: string | null,
      times?: number | null,
      passingStandard?: number | null,
      version?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ExamResultsByGroupAndCourseQueryVariables = {
  groupId?: string | null,
  courseId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelExamResultFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ExamResultsByGroupAndCourseQuery = {
  examResultsByGroupAndCourse?:  {
    __typename: "ModelExamResultConnection",
    items:  Array< {
      __typename: "ExamResult",
      id: string,
      groupId: string,
      courseId: string,
      contentId: string,
      contentVersion?: number | null,
      userId: string,
      start: string,
      end?: string | null,
      score?: number | null,
      problemCount: number,
      tenantCode: string,
      answers?: string | null,
      visibilityLevel?: ExamResultVisibilityLevel | null,
      groupExamId?: string | null,
      times?: number | null,
      passingStandard?: number | null,
      version?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ExamResultsByGroupExamQueryVariables = {
  groupExamId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelExamResultFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ExamResultsByGroupExamQuery = {
  examResultsByGroupExam?:  {
    __typename: "ModelExamResultConnection",
    items:  Array< {
      __typename: "ExamResult",
      id: string,
      groupId: string,
      courseId: string,
      contentId: string,
      contentVersion?: number | null,
      userId: string,
      start: string,
      end?: string | null,
      score?: number | null,
      problemCount: number,
      tenantCode: string,
      answers?: string | null,
      visibilityLevel?: ExamResultVisibilityLevel | null,
      groupExamId?: string | null,
      times?: number | null,
      passingStandard?: number | null,
      version?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetGroupTrainingQueryVariables = {
  id: string,
};

export type GetGroupTrainingQuery = {
  getGroupTraining?:  {
    __typename: "GroupTraining",
    id: string,
    groupId: string,
    tenantCode: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListGroupTrainingsQueryVariables = {
  filter?: ModelGroupTrainingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListGroupTrainingsQuery = {
  listGroupTrainings?:  {
    __typename: "ModelGroupTrainingConnection",
    items:  Array< {
      __typename: "GroupTraining",
      id: string,
      groupId: string,
      tenantCode: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GroupTrainingByGroupIdQueryVariables = {
  groupId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelGroupTrainingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GroupTrainingByGroupIdQuery = {
  groupTrainingByGroupId?:  {
    __typename: "ModelGroupTrainingConnection",
    items:  Array< {
      __typename: "GroupTraining",
      id: string,
      groupId: string,
      tenantCode: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GroupTrainingsByTenantCodeQueryVariables = {
  tenantCode?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelGroupTrainingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GroupTrainingsByTenantCodeQuery = {
  groupTrainingsByTenantCode?:  {
    __typename: "ModelGroupTrainingConnection",
    items:  Array< {
      __typename: "GroupTraining",
      id: string,
      groupId: string,
      tenantCode: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetGroupTrainingCourseQueryVariables = {
  id: string,
};

export type GetGroupTrainingCourseQuery = {
  getGroupTrainingCourse?:  {
    __typename: "GroupTrainingCourse",
    id: string,
    groupTrainingId: string,
    courseId: string,
    courseVersion: number,
    courseName: string,
    contents:  Array< {
      __typename: "GroupTrainingCourseContent",
      id: string,
      name: string,
      requiredTime: number,
      type: ContentType,
      recommendedDateTime?: string | null,
      open: boolean,
      version: number,
    } >,
    groupId: string,
    tenantCode: string,
    color?: string | null,
    image?: string | null,
    fontColorOnImage?: CourseFontColorOnImage | null,
    displayName: string,
    index?: number | null,
    createdAt: string,
    updatedAt: string,
    groupExams?:  {
      __typename: "ModelGroupExamConnection",
      items:  Array< {
        __typename: "GroupExam",
        id: string,
        groupId: string,
        contentId: string,
        content:  {
          __typename: "GroupExamContent",
          id: string,
          version?: number | null,
          name: string,
          timeLimit?: number | null,
          requiredTime?: number | null,
          indexInCourse?: number | null,
          passingStandard?: number | null,
        },
        problemsJson: string,
        courseId: string,
        course:  {
          __typename: "GroupExamCourse",
          id: string,
          version: number,
          name: string,
          color?: string | null,
          image?: string | null,
          fontColorOnImage?: CourseFontColorOnImage | null,
        },
        scheduledStart: string,
        scheduledFinish?: string | null,
        createdBy?: string | null,
        finishedAt?: string | null,
        visibilityLevel?: ExamResultVisibilityLevel | null,
        tenantCode: string,
        times: number,
        groupTrainingCourseId: string,
        problemHeaders?:  Array< {
          __typename: "ProblemHeader",
          id: string,
          body: string,
          createdAt: string,
        } > | null,
        timeLimit?: number | null,
        passingStandard?: number | null,
        userIdsToBeTested?: Array< string > | null,
        createdAt: string,
        updatedAt: string,
        userExams?:  {
          __typename: "ModelUserExamConnection",
          items:  Array< {
            __typename: "UserExam",
            id: string,
            userId: string,
            status: UserExamStatus,
            tenantCode: string,
            groupExamId: string,
            groupId: string,
            startedAt?: string | null,
            finishedAt?: string | null,
            createdAt: string,
            updatedAt: string,
            groupExam?:  {
              __typename: "GroupExam",
              id: string,
              groupId: string,
              contentId: string,
              content:  {
                __typename: "GroupExamContent",
                id: string,
                version?: number | null,
                name: string,
                timeLimit?: number | null,
                requiredTime?: number | null,
                indexInCourse?: number | null,
                passingStandard?: number | null,
              },
              problemsJson: string,
              courseId: string,
              course:  {
                __typename: "GroupExamCourse",
                id: string,
                version: number,
                name: string,
                color?: string | null,
                image?: string | null,
                fontColorOnImage?: CourseFontColorOnImage | null,
              },
              scheduledStart: string,
              scheduledFinish?: string | null,
              createdBy?: string | null,
              finishedAt?: string | null,
              visibilityLevel?: ExamResultVisibilityLevel | null,
              tenantCode: string,
              times: number,
              groupTrainingCourseId: string,
              problemHeaders?:  Array< {
                __typename: "ProblemHeader",
                id: string,
                body: string,
                createdAt: string,
              } > | null,
              timeLimit?: number | null,
              passingStandard?: number | null,
              userIdsToBeTested?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
              userExams?:  {
                __typename: "ModelUserExamConnection",
                items:  Array< {
                  __typename: "UserExam",
                  id: string,
                  userId: string,
                  status: UserExamStatus,
                  tenantCode: string,
                  groupExamId: string,
                  groupId: string,
                  startedAt?: string | null,
                  finishedAt?: string | null,
                  createdAt: string,
                  updatedAt: string,
                } | null >,
                nextToken?: string | null,
              } | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListGroupTrainingCoursesQueryVariables = {
  filter?: ModelGroupTrainingCourseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListGroupTrainingCoursesQuery = {
  listGroupTrainingCourses?:  {
    __typename: "ModelGroupTrainingCourseConnection",
    items:  Array< {
      __typename: "GroupTrainingCourse",
      id: string,
      groupTrainingId: string,
      courseId: string,
      courseVersion: number,
      courseName: string,
      contents:  Array< {
        __typename: "GroupTrainingCourseContent",
        id: string,
        name: string,
        requiredTime: number,
        type: ContentType,
        recommendedDateTime?: string | null,
        open: boolean,
        version: number,
      } >,
      groupId: string,
      tenantCode: string,
      color?: string | null,
      image?: string | null,
      fontColorOnImage?: CourseFontColorOnImage | null,
      displayName: string,
      index?: number | null,
      createdAt: string,
      updatedAt: string,
      groupExams?:  {
        __typename: "ModelGroupExamConnection",
        items:  Array< {
          __typename: "GroupExam",
          id: string,
          groupId: string,
          contentId: string,
          content:  {
            __typename: "GroupExamContent",
            id: string,
            version?: number | null,
            name: string,
            timeLimit?: number | null,
            requiredTime?: number | null,
            indexInCourse?: number | null,
            passingStandard?: number | null,
          },
          problemsJson: string,
          courseId: string,
          course:  {
            __typename: "GroupExamCourse",
            id: string,
            version: number,
            name: string,
            color?: string | null,
            image?: string | null,
            fontColorOnImage?: CourseFontColorOnImage | null,
          },
          scheduledStart: string,
          scheduledFinish?: string | null,
          createdBy?: string | null,
          finishedAt?: string | null,
          visibilityLevel?: ExamResultVisibilityLevel | null,
          tenantCode: string,
          times: number,
          groupTrainingCourseId: string,
          problemHeaders?:  Array< {
            __typename: "ProblemHeader",
            id: string,
            body: string,
            createdAt: string,
          } > | null,
          timeLimit?: number | null,
          passingStandard?: number | null,
          userIdsToBeTested?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          userExams?:  {
            __typename: "ModelUserExamConnection",
            items:  Array< {
              __typename: "UserExam",
              id: string,
              userId: string,
              status: UserExamStatus,
              tenantCode: string,
              groupExamId: string,
              groupId: string,
              startedAt?: string | null,
              finishedAt?: string | null,
              createdAt: string,
              updatedAt: string,
              groupExam?:  {
                __typename: "GroupExam",
                id: string,
                groupId: string,
                contentId: string,
                content:  {
                  __typename: "GroupExamContent",
                  id: string,
                  version?: number | null,
                  name: string,
                  timeLimit?: number | null,
                  requiredTime?: number | null,
                  indexInCourse?: number | null,
                  passingStandard?: number | null,
                },
                problemsJson: string,
                courseId: string,
                course:  {
                  __typename: "GroupExamCourse",
                  id: string,
                  version: number,
                  name: string,
                  color?: string | null,
                  image?: string | null,
                  fontColorOnImage?: CourseFontColorOnImage | null,
                },
                scheduledStart: string,
                scheduledFinish?: string | null,
                createdBy?: string | null,
                finishedAt?: string | null,
                visibilityLevel?: ExamResultVisibilityLevel | null,
                tenantCode: string,
                times: number,
                groupTrainingCourseId: string,
                problemHeaders?:  Array< {
                  __typename: "ProblemHeader",
                  id: string,
                  body: string,
                  createdAt: string,
                } > | null,
                timeLimit?: number | null,
                passingStandard?: number | null,
                userIdsToBeTested?: Array< string > | null,
                createdAt: string,
                updatedAt: string,
                userExams?:  {
                  __typename: "ModelUserExamConnection",
                  nextToken?: string | null,
                } | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GroupTrainingCoursesByGroupIdAndCourseIdQueryVariables = {
  groupId?: string | null,
  courseId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelGroupTrainingCourseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GroupTrainingCoursesByGroupIdAndCourseIdQuery = {
  groupTrainingCoursesByGroupIdAndCourseId?:  {
    __typename: "ModelGroupTrainingCourseConnection",
    items:  Array< {
      __typename: "GroupTrainingCourse",
      id: string,
      groupTrainingId: string,
      courseId: string,
      courseVersion: number,
      courseName: string,
      contents:  Array< {
        __typename: "GroupTrainingCourseContent",
        id: string,
        name: string,
        requiredTime: number,
        type: ContentType,
        recommendedDateTime?: string | null,
        open: boolean,
        version: number,
      } >,
      groupId: string,
      tenantCode: string,
      color?: string | null,
      image?: string | null,
      fontColorOnImage?: CourseFontColorOnImage | null,
      displayName: string,
      index?: number | null,
      createdAt: string,
      updatedAt: string,
      groupExams?:  {
        __typename: "ModelGroupExamConnection",
        items:  Array< {
          __typename: "GroupExam",
          id: string,
          groupId: string,
          contentId: string,
          content:  {
            __typename: "GroupExamContent",
            id: string,
            version?: number | null,
            name: string,
            timeLimit?: number | null,
            requiredTime?: number | null,
            indexInCourse?: number | null,
            passingStandard?: number | null,
          },
          problemsJson: string,
          courseId: string,
          course:  {
            __typename: "GroupExamCourse",
            id: string,
            version: number,
            name: string,
            color?: string | null,
            image?: string | null,
            fontColorOnImage?: CourseFontColorOnImage | null,
          },
          scheduledStart: string,
          scheduledFinish?: string | null,
          createdBy?: string | null,
          finishedAt?: string | null,
          visibilityLevel?: ExamResultVisibilityLevel | null,
          tenantCode: string,
          times: number,
          groupTrainingCourseId: string,
          problemHeaders?:  Array< {
            __typename: "ProblemHeader",
            id: string,
            body: string,
            createdAt: string,
          } > | null,
          timeLimit?: number | null,
          passingStandard?: number | null,
          userIdsToBeTested?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          userExams?:  {
            __typename: "ModelUserExamConnection",
            items:  Array< {
              __typename: "UserExam",
              id: string,
              userId: string,
              status: UserExamStatus,
              tenantCode: string,
              groupExamId: string,
              groupId: string,
              startedAt?: string | null,
              finishedAt?: string | null,
              createdAt: string,
              updatedAt: string,
              groupExam?:  {
                __typename: "GroupExam",
                id: string,
                groupId: string,
                contentId: string,
                content:  {
                  __typename: "GroupExamContent",
                  id: string,
                  version?: number | null,
                  name: string,
                  timeLimit?: number | null,
                  requiredTime?: number | null,
                  indexInCourse?: number | null,
                  passingStandard?: number | null,
                },
                problemsJson: string,
                courseId: string,
                course:  {
                  __typename: "GroupExamCourse",
                  id: string,
                  version: number,
                  name: string,
                  color?: string | null,
                  image?: string | null,
                  fontColorOnImage?: CourseFontColorOnImage | null,
                },
                scheduledStart: string,
                scheduledFinish?: string | null,
                createdBy?: string | null,
                finishedAt?: string | null,
                visibilityLevel?: ExamResultVisibilityLevel | null,
                tenantCode: string,
                times: number,
                groupTrainingCourseId: string,
                problemHeaders?:  Array< {
                  __typename: "ProblemHeader",
                  id: string,
                  body: string,
                  createdAt: string,
                } > | null,
                timeLimit?: number | null,
                passingStandard?: number | null,
                userIdsToBeTested?: Array< string > | null,
                createdAt: string,
                updatedAt: string,
                userExams?:  {
                  __typename: "ModelUserExamConnection",
                  nextToken?: string | null,
                } | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GroupTrainingCoursesByCourseIdQueryVariables = {
  courseId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelGroupTrainingCourseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GroupTrainingCoursesByCourseIdQuery = {
  groupTrainingCoursesByCourseId?:  {
    __typename: "ModelGroupTrainingCourseConnection",
    items:  Array< {
      __typename: "GroupTrainingCourse",
      id: string,
      groupTrainingId: string,
      courseId: string,
      courseVersion: number,
      courseName: string,
      contents:  Array< {
        __typename: "GroupTrainingCourseContent",
        id: string,
        name: string,
        requiredTime: number,
        type: ContentType,
        recommendedDateTime?: string | null,
        open: boolean,
        version: number,
      } >,
      groupId: string,
      tenantCode: string,
      color?: string | null,
      image?: string | null,
      fontColorOnImage?: CourseFontColorOnImage | null,
      displayName: string,
      index?: number | null,
      createdAt: string,
      updatedAt: string,
      groupExams?:  {
        __typename: "ModelGroupExamConnection",
        items:  Array< {
          __typename: "GroupExam",
          id: string,
          groupId: string,
          contentId: string,
          content:  {
            __typename: "GroupExamContent",
            id: string,
            version?: number | null,
            name: string,
            timeLimit?: number | null,
            requiredTime?: number | null,
            indexInCourse?: number | null,
            passingStandard?: number | null,
          },
          problemsJson: string,
          courseId: string,
          course:  {
            __typename: "GroupExamCourse",
            id: string,
            version: number,
            name: string,
            color?: string | null,
            image?: string | null,
            fontColorOnImage?: CourseFontColorOnImage | null,
          },
          scheduledStart: string,
          scheduledFinish?: string | null,
          createdBy?: string | null,
          finishedAt?: string | null,
          visibilityLevel?: ExamResultVisibilityLevel | null,
          tenantCode: string,
          times: number,
          groupTrainingCourseId: string,
          problemHeaders?:  Array< {
            __typename: "ProblemHeader",
            id: string,
            body: string,
            createdAt: string,
          } > | null,
          timeLimit?: number | null,
          passingStandard?: number | null,
          userIdsToBeTested?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          userExams?:  {
            __typename: "ModelUserExamConnection",
            items:  Array< {
              __typename: "UserExam",
              id: string,
              userId: string,
              status: UserExamStatus,
              tenantCode: string,
              groupExamId: string,
              groupId: string,
              startedAt?: string | null,
              finishedAt?: string | null,
              createdAt: string,
              updatedAt: string,
              groupExam?:  {
                __typename: "GroupExam",
                id: string,
                groupId: string,
                contentId: string,
                content:  {
                  __typename: "GroupExamContent",
                  id: string,
                  version?: number | null,
                  name: string,
                  timeLimit?: number | null,
                  requiredTime?: number | null,
                  indexInCourse?: number | null,
                  passingStandard?: number | null,
                },
                problemsJson: string,
                courseId: string,
                course:  {
                  __typename: "GroupExamCourse",
                  id: string,
                  version: number,
                  name: string,
                  color?: string | null,
                  image?: string | null,
                  fontColorOnImage?: CourseFontColorOnImage | null,
                },
                scheduledStart: string,
                scheduledFinish?: string | null,
                createdBy?: string | null,
                finishedAt?: string | null,
                visibilityLevel?: ExamResultVisibilityLevel | null,
                tenantCode: string,
                times: number,
                groupTrainingCourseId: string,
                problemHeaders?:  Array< {
                  __typename: "ProblemHeader",
                  id: string,
                  body: string,
                  createdAt: string,
                } > | null,
                timeLimit?: number | null,
                passingStandard?: number | null,
                userIdsToBeTested?: Array< string > | null,
                createdAt: string,
                updatedAt: string,
                userExams?:  {
                  __typename: "ModelUserExamConnection",
                  nextToken?: string | null,
                } | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetScheduleQueryVariables = {
  id: string,
};

export type GetScheduleQuery = {
  getSchedule?:  {
    __typename: "Schedule",
    id: string,
    groupId: string,
    start: string,
    end?: string | null,
    title: string,
    body?: string | null,
    tenantCode: string,
    createdBy?: string | null,
    updatedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    tags?:  {
      __typename: "ModelScheduleScheduleTagConnection",
      items:  Array< {
        __typename: "ScheduleScheduleTag",
        id: string,
        scheduleId: string,
        scheduleTagId: string,
        scheduleTag:  {
          __typename: "ScheduleTag",
          id: string,
          color: string,
          text: string,
          groupId: string,
          tenantCode: string,
          createdAt: string,
          updatedAt: string,
          schedules?:  {
            __typename: "ModelScheduleScheduleTagConnection",
            items:  Array< {
              __typename: "ScheduleScheduleTag",
              id: string,
              scheduleId: string,
              scheduleTagId: string,
              scheduleTag:  {
                __typename: "ScheduleTag",
                id: string,
                color: string,
                text: string,
                groupId: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                schedules?:  {
                  __typename: "ModelScheduleScheduleTagConnection",
                  nextToken?: string | null,
                } | null,
              },
              tenantCode: string,
              createdAt: string,
              updatedAt: string,
              schedule:  {
                __typename: "Schedule",
                id: string,
                groupId: string,
                start: string,
                end?: string | null,
                title: string,
                body?: string | null,
                tenantCode: string,
                createdBy?: string | null,
                updatedBy?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                tags?:  {
                  __typename: "ModelScheduleScheduleTagConnection",
                  nextToken?: string | null,
                } | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        tenantCode: string,
        createdAt: string,
        updatedAt: string,
        schedule:  {
          __typename: "Schedule",
          id: string,
          groupId: string,
          start: string,
          end?: string | null,
          title: string,
          body?: string | null,
          tenantCode: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          tags?:  {
            __typename: "ModelScheduleScheduleTagConnection",
            items:  Array< {
              __typename: "ScheduleScheduleTag",
              id: string,
              scheduleId: string,
              scheduleTagId: string,
              scheduleTag:  {
                __typename: "ScheduleTag",
                id: string,
                color: string,
                text: string,
                groupId: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                schedules?:  {
                  __typename: "ModelScheduleScheduleTagConnection",
                  nextToken?: string | null,
                } | null,
              },
              tenantCode: string,
              createdAt: string,
              updatedAt: string,
              schedule:  {
                __typename: "Schedule",
                id: string,
                groupId: string,
                start: string,
                end?: string | null,
                title: string,
                body?: string | null,
                tenantCode: string,
                createdBy?: string | null,
                updatedBy?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                tags?:  {
                  __typename: "ModelScheduleScheduleTagConnection",
                  nextToken?: string | null,
                } | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListSchedulesQueryVariables = {
  filter?: ModelScheduleFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSchedulesQuery = {
  listSchedules?:  {
    __typename: "ModelScheduleConnection",
    items:  Array< {
      __typename: "Schedule",
      id: string,
      groupId: string,
      start: string,
      end?: string | null,
      title: string,
      body?: string | null,
      tenantCode: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      tags?:  {
        __typename: "ModelScheduleScheduleTagConnection",
        items:  Array< {
          __typename: "ScheduleScheduleTag",
          id: string,
          scheduleId: string,
          scheduleTagId: string,
          scheduleTag:  {
            __typename: "ScheduleTag",
            id: string,
            color: string,
            text: string,
            groupId: string,
            tenantCode: string,
            createdAt: string,
            updatedAt: string,
            schedules?:  {
              __typename: "ModelScheduleScheduleTagConnection",
              items:  Array< {
                __typename: "ScheduleScheduleTag",
                id: string,
                scheduleId: string,
                scheduleTagId: string,
                scheduleTag:  {
                  __typename: "ScheduleTag",
                  id: string,
                  color: string,
                  text: string,
                  groupId: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                schedule:  {
                  __typename: "Schedule",
                  id: string,
                  groupId: string,
                  start: string,
                  end?: string | null,
                  title: string,
                  body?: string | null,
                  tenantCode: string,
                  createdBy?: string | null,
                  updatedBy?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          tenantCode: string,
          createdAt: string,
          updatedAt: string,
          schedule:  {
            __typename: "Schedule",
            id: string,
            groupId: string,
            start: string,
            end?: string | null,
            title: string,
            body?: string | null,
            tenantCode: string,
            createdBy?: string | null,
            updatedBy?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            tags?:  {
              __typename: "ModelScheduleScheduleTagConnection",
              items:  Array< {
                __typename: "ScheduleScheduleTag",
                id: string,
                scheduleId: string,
                scheduleTagId: string,
                scheduleTag:  {
                  __typename: "ScheduleTag",
                  id: string,
                  color: string,
                  text: string,
                  groupId: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                schedule:  {
                  __typename: "Schedule",
                  id: string,
                  groupId: string,
                  start: string,
                  end?: string | null,
                  title: string,
                  body?: string | null,
                  tenantCode: string,
                  createdBy?: string | null,
                  updatedBy?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SchedulesByTenantCodeQueryVariables = {
  tenantCode?: string | null,
  updatedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelScheduleFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SchedulesByTenantCodeQuery = {
  schedulesByTenantCode?:  {
    __typename: "ModelScheduleConnection",
    items:  Array< {
      __typename: "Schedule",
      id: string,
      groupId: string,
      start: string,
      end?: string | null,
      title: string,
      body?: string | null,
      tenantCode: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      tags?:  {
        __typename: "ModelScheduleScheduleTagConnection",
        items:  Array< {
          __typename: "ScheduleScheduleTag",
          id: string,
          scheduleId: string,
          scheduleTagId: string,
          scheduleTag:  {
            __typename: "ScheduleTag",
            id: string,
            color: string,
            text: string,
            groupId: string,
            tenantCode: string,
            createdAt: string,
            updatedAt: string,
            schedules?:  {
              __typename: "ModelScheduleScheduleTagConnection",
              items:  Array< {
                __typename: "ScheduleScheduleTag",
                id: string,
                scheduleId: string,
                scheduleTagId: string,
                scheduleTag:  {
                  __typename: "ScheduleTag",
                  id: string,
                  color: string,
                  text: string,
                  groupId: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                schedule:  {
                  __typename: "Schedule",
                  id: string,
                  groupId: string,
                  start: string,
                  end?: string | null,
                  title: string,
                  body?: string | null,
                  tenantCode: string,
                  createdBy?: string | null,
                  updatedBy?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          tenantCode: string,
          createdAt: string,
          updatedAt: string,
          schedule:  {
            __typename: "Schedule",
            id: string,
            groupId: string,
            start: string,
            end?: string | null,
            title: string,
            body?: string | null,
            tenantCode: string,
            createdBy?: string | null,
            updatedBy?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            tags?:  {
              __typename: "ModelScheduleScheduleTagConnection",
              items:  Array< {
                __typename: "ScheduleScheduleTag",
                id: string,
                scheduleId: string,
                scheduleTagId: string,
                scheduleTag:  {
                  __typename: "ScheduleTag",
                  id: string,
                  color: string,
                  text: string,
                  groupId: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                schedule:  {
                  __typename: "Schedule",
                  id: string,
                  groupId: string,
                  start: string,
                  end?: string | null,
                  title: string,
                  body?: string | null,
                  tenantCode: string,
                  createdBy?: string | null,
                  updatedBy?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SchedulesByGroupIdQueryVariables = {
  groupId?: string | null,
  start?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelScheduleFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SchedulesByGroupIdQuery = {
  schedulesByGroupId?:  {
    __typename: "ModelScheduleConnection",
    items:  Array< {
      __typename: "Schedule",
      id: string,
      groupId: string,
      start: string,
      end?: string | null,
      title: string,
      body?: string | null,
      tenantCode: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      tags?:  {
        __typename: "ModelScheduleScheduleTagConnection",
        items:  Array< {
          __typename: "ScheduleScheduleTag",
          id: string,
          scheduleId: string,
          scheduleTagId: string,
          scheduleTag:  {
            __typename: "ScheduleTag",
            id: string,
            color: string,
            text: string,
            groupId: string,
            tenantCode: string,
            createdAt: string,
            updatedAt: string,
            schedules?:  {
              __typename: "ModelScheduleScheduleTagConnection",
              items:  Array< {
                __typename: "ScheduleScheduleTag",
                id: string,
                scheduleId: string,
                scheduleTagId: string,
                scheduleTag:  {
                  __typename: "ScheduleTag",
                  id: string,
                  color: string,
                  text: string,
                  groupId: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                schedule:  {
                  __typename: "Schedule",
                  id: string,
                  groupId: string,
                  start: string,
                  end?: string | null,
                  title: string,
                  body?: string | null,
                  tenantCode: string,
                  createdBy?: string | null,
                  updatedBy?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          tenantCode: string,
          createdAt: string,
          updatedAt: string,
          schedule:  {
            __typename: "Schedule",
            id: string,
            groupId: string,
            start: string,
            end?: string | null,
            title: string,
            body?: string | null,
            tenantCode: string,
            createdBy?: string | null,
            updatedBy?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            tags?:  {
              __typename: "ModelScheduleScheduleTagConnection",
              items:  Array< {
                __typename: "ScheduleScheduleTag",
                id: string,
                scheduleId: string,
                scheduleTagId: string,
                scheduleTag:  {
                  __typename: "ScheduleTag",
                  id: string,
                  color: string,
                  text: string,
                  groupId: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                schedule:  {
                  __typename: "Schedule",
                  id: string,
                  groupId: string,
                  start: string,
                  end?: string | null,
                  title: string,
                  body?: string | null,
                  tenantCode: string,
                  createdBy?: string | null,
                  updatedBy?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SchedulesByEndQueryVariables = {
  tenantCode?: string | null,
  end?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelScheduleFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SchedulesByEndQuery = {
  schedulesByEnd?:  {
    __typename: "ModelScheduleConnection",
    items:  Array< {
      __typename: "Schedule",
      id: string,
      groupId: string,
      start: string,
      end?: string | null,
      title: string,
      body?: string | null,
      tenantCode: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      tags?:  {
        __typename: "ModelScheduleScheduleTagConnection",
        items:  Array< {
          __typename: "ScheduleScheduleTag",
          id: string,
          scheduleId: string,
          scheduleTagId: string,
          scheduleTag:  {
            __typename: "ScheduleTag",
            id: string,
            color: string,
            text: string,
            groupId: string,
            tenantCode: string,
            createdAt: string,
            updatedAt: string,
            schedules?:  {
              __typename: "ModelScheduleScheduleTagConnection",
              items:  Array< {
                __typename: "ScheduleScheduleTag",
                id: string,
                scheduleId: string,
                scheduleTagId: string,
                scheduleTag:  {
                  __typename: "ScheduleTag",
                  id: string,
                  color: string,
                  text: string,
                  groupId: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                schedule:  {
                  __typename: "Schedule",
                  id: string,
                  groupId: string,
                  start: string,
                  end?: string | null,
                  title: string,
                  body?: string | null,
                  tenantCode: string,
                  createdBy?: string | null,
                  updatedBy?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          tenantCode: string,
          createdAt: string,
          updatedAt: string,
          schedule:  {
            __typename: "Schedule",
            id: string,
            groupId: string,
            start: string,
            end?: string | null,
            title: string,
            body?: string | null,
            tenantCode: string,
            createdBy?: string | null,
            updatedBy?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            tags?:  {
              __typename: "ModelScheduleScheduleTagConnection",
              items:  Array< {
                __typename: "ScheduleScheduleTag",
                id: string,
                scheduleId: string,
                scheduleTagId: string,
                scheduleTag:  {
                  __typename: "ScheduleTag",
                  id: string,
                  color: string,
                  text: string,
                  groupId: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                schedule:  {
                  __typename: "Schedule",
                  id: string,
                  groupId: string,
                  start: string,
                  end?: string | null,
                  title: string,
                  body?: string | null,
                  tenantCode: string,
                  createdBy?: string | null,
                  updatedBy?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SchedulesByGroupIdAndEndQueryVariables = {
  groupId?: string | null,
  end?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelScheduleFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SchedulesByGroupIdAndEndQuery = {
  schedulesByGroupIdAndEnd?:  {
    __typename: "ModelScheduleConnection",
    items:  Array< {
      __typename: "Schedule",
      id: string,
      groupId: string,
      start: string,
      end?: string | null,
      title: string,
      body?: string | null,
      tenantCode: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      tags?:  {
        __typename: "ModelScheduleScheduleTagConnection",
        items:  Array< {
          __typename: "ScheduleScheduleTag",
          id: string,
          scheduleId: string,
          scheduleTagId: string,
          scheduleTag:  {
            __typename: "ScheduleTag",
            id: string,
            color: string,
            text: string,
            groupId: string,
            tenantCode: string,
            createdAt: string,
            updatedAt: string,
            schedules?:  {
              __typename: "ModelScheduleScheduleTagConnection",
              items:  Array< {
                __typename: "ScheduleScheduleTag",
                id: string,
                scheduleId: string,
                scheduleTagId: string,
                scheduleTag:  {
                  __typename: "ScheduleTag",
                  id: string,
                  color: string,
                  text: string,
                  groupId: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                schedule:  {
                  __typename: "Schedule",
                  id: string,
                  groupId: string,
                  start: string,
                  end?: string | null,
                  title: string,
                  body?: string | null,
                  tenantCode: string,
                  createdBy?: string | null,
                  updatedBy?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          tenantCode: string,
          createdAt: string,
          updatedAt: string,
          schedule:  {
            __typename: "Schedule",
            id: string,
            groupId: string,
            start: string,
            end?: string | null,
            title: string,
            body?: string | null,
            tenantCode: string,
            createdBy?: string | null,
            updatedBy?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            tags?:  {
              __typename: "ModelScheduleScheduleTagConnection",
              items:  Array< {
                __typename: "ScheduleScheduleTag",
                id: string,
                scheduleId: string,
                scheduleTagId: string,
                scheduleTag:  {
                  __typename: "ScheduleTag",
                  id: string,
                  color: string,
                  text: string,
                  groupId: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                schedule:  {
                  __typename: "Schedule",
                  id: string,
                  groupId: string,
                  start: string,
                  end?: string | null,
                  title: string,
                  body?: string | null,
                  tenantCode: string,
                  createdBy?: string | null,
                  updatedBy?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCommentQueryVariables = {
  id: string,
};

export type GetCommentQuery = {
  getComment?:  {
    __typename: "Comment",
    id: string,
    questionId: string,
    body: string,
    createdBy: string,
    createdAt?: string | null,
    editedBy?: string | null,
    editedAt?: string | null,
    tenantCode: string,
    groupId: string,
    updatedAt: string,
  } | null,
};

export type ListCommentsQueryVariables = {
  filter?: ModelCommentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCommentsQuery = {
  listComments?:  {
    __typename: "ModelCommentConnection",
    items:  Array< {
      __typename: "Comment",
      id: string,
      questionId: string,
      body: string,
      createdBy: string,
      createdAt?: string | null,
      editedBy?: string | null,
      editedAt?: string | null,
      tenantCode: string,
      groupId: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetQuestionQueryVariables = {
  id: string,
};

export type GetQuestionQuery = {
  getQuestion?:  {
    __typename: "Question",
    id: string,
    createdBy: string,
    resolved?: boolean | null,
    resolvedAt?: string | null,
    resolvedBy?: string | null,
    groupId: string,
    title: string,
    referTo?:  {
      __typename: "ContentReference",
      contentId: string,
      contentVersion?: number | null,
      options?:  {
        __typename: "ContentReferenceOptions",
        text?:  {
          __typename: "TextContentReferenceOptions",
          selectionJson: string,
        } | null,
        workbook?:  {
          __typename: "WorkbookReferenceOptions",
          problemIndex: number,
          selectionJson?: string | null,
        } | null,
      } | null,
    } | null,
    tenantCode: string,
    createdAt?: string | null,
    assignees?: Array< string > | null,
    updatedAt: string,
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        id: string,
        questionId: string,
        body: string,
        createdBy: string,
        createdAt?: string | null,
        editedBy?: string | null,
        editedAt?: string | null,
        tenantCode: string,
        groupId: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListQuestionsQueryVariables = {
  filter?: ModelQuestionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListQuestionsQuery = {
  listQuestions?:  {
    __typename: "ModelQuestionConnection",
    items:  Array< {
      __typename: "Question",
      id: string,
      createdBy: string,
      resolved?: boolean | null,
      resolvedAt?: string | null,
      resolvedBy?: string | null,
      groupId: string,
      title: string,
      referTo?:  {
        __typename: "ContentReference",
        contentId: string,
        contentVersion?: number | null,
        options?:  {
          __typename: "ContentReferenceOptions",
          text?:  {
            __typename: "TextContentReferenceOptions",
            selectionJson: string,
          } | null,
          workbook?:  {
            __typename: "WorkbookReferenceOptions",
            problemIndex: number,
            selectionJson?: string | null,
          } | null,
        } | null,
      } | null,
      tenantCode: string,
      createdAt?: string | null,
      assignees?: Array< string > | null,
      updatedAt: string,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          questionId: string,
          body: string,
          createdBy: string,
          createdAt?: string | null,
          editedBy?: string | null,
          editedAt?: string | null,
          tenantCode: string,
          groupId: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type QuestionsByTenantCodeQueryVariables = {
  tenantCode?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelQuestionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type QuestionsByTenantCodeQuery = {
  questionsByTenantCode?:  {
    __typename: "ModelQuestionConnection",
    items:  Array< {
      __typename: "Question",
      id: string,
      createdBy: string,
      resolved?: boolean | null,
      resolvedAt?: string | null,
      resolvedBy?: string | null,
      groupId: string,
      title: string,
      referTo?:  {
        __typename: "ContentReference",
        contentId: string,
        contentVersion?: number | null,
        options?:  {
          __typename: "ContentReferenceOptions",
          text?:  {
            __typename: "TextContentReferenceOptions",
            selectionJson: string,
          } | null,
          workbook?:  {
            __typename: "WorkbookReferenceOptions",
            problemIndex: number,
            selectionJson?: string | null,
          } | null,
        } | null,
      } | null,
      tenantCode: string,
      createdAt?: string | null,
      assignees?: Array< string > | null,
      updatedAt: string,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          questionId: string,
          body: string,
          createdBy: string,
          createdAt?: string | null,
          editedBy?: string | null,
          editedAt?: string | null,
          tenantCode: string,
          groupId: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type QuestionsByGroupQueryVariables = {
  groupId?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelQuestionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type QuestionsByGroupQuery = {
  questionsByGroup?:  {
    __typename: "ModelQuestionConnection",
    items:  Array< {
      __typename: "Question",
      id: string,
      createdBy: string,
      resolved?: boolean | null,
      resolvedAt?: string | null,
      resolvedBy?: string | null,
      groupId: string,
      title: string,
      referTo?:  {
        __typename: "ContentReference",
        contentId: string,
        contentVersion?: number | null,
        options?:  {
          __typename: "ContentReferenceOptions",
          text?:  {
            __typename: "TextContentReferenceOptions",
            selectionJson: string,
          } | null,
          workbook?:  {
            __typename: "WorkbookReferenceOptions",
            problemIndex: number,
            selectionJson?: string | null,
          } | null,
        } | null,
      } | null,
      tenantCode: string,
      createdAt?: string | null,
      assignees?: Array< string > | null,
      updatedAt: string,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          questionId: string,
          body: string,
          createdBy: string,
          createdAt?: string | null,
          editedBy?: string | null,
          editedAt?: string | null,
          tenantCode: string,
          groupId: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetContentLearningQueryVariables = {
  groupId: string,
  contentId: string,
  userId: string,
};

export type GetContentLearningQuery = {
  getContentLearning?:  {
    __typename: "ContentLearning",
    groupId: string,
    courseId: string,
    contentId: string,
    contentType: ContentType,
    userId: string,
    status: LearningStatus,
    usageTime: number,
    lastStartedAt?: string | null,
    dataVersion: number,
    tenantCode: string,
    completedAt?: string | null,
    completedUsageTime?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListContentLearningsQueryVariables = {
  groupId?: string | null,
  contentIdUserId?: ModelContentLearningPrimaryCompositeKeyConditionInput | null,
  filter?: ModelContentLearningFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListContentLearningsQuery = {
  listContentLearnings?:  {
    __typename: "ModelContentLearningConnection",
    items:  Array< {
      __typename: "ContentLearning",
      groupId: string,
      courseId: string,
      contentId: string,
      contentType: ContentType,
      userId: string,
      status: LearningStatus,
      usageTime: number,
      lastStartedAt?: string | null,
      dataVersion: number,
      tenantCode: string,
      completedAt?: string | null,
      completedUsageTime?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ContentLearningsByGroupIdAndCourseIdQueryVariables = {
  groupId?: string | null,
  courseId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelContentLearningFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ContentLearningsByGroupIdAndCourseIdQuery = {
  contentLearningsByGroupIdAndCourseId?:  {
    __typename: "ModelContentLearningConnection",
    items:  Array< {
      __typename: "ContentLearning",
      groupId: string,
      courseId: string,
      contentId: string,
      contentType: ContentType,
      userId: string,
      status: LearningStatus,
      usageTime: number,
      lastStartedAt?: string | null,
      dataVersion: number,
      tenantCode: string,
      completedAt?: string | null,
      completedUsageTime?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ContentLearningsByGroupIdAndUserIdQueryVariables = {
  groupId?: string | null,
  userId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelContentLearningFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ContentLearningsByGroupIdAndUserIdQuery = {
  contentLearningsByGroupIdAndUserId?:  {
    __typename: "ModelContentLearningConnection",
    items:  Array< {
      __typename: "ContentLearning",
      groupId: string,
      courseId: string,
      contentId: string,
      contentType: ContentType,
      userId: string,
      status: LearningStatus,
      usageTime: number,
      lastStartedAt?: string | null,
      dataVersion: number,
      tenantCode: string,
      completedAt?: string | null,
      completedUsageTime?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ContentLearningsByUserQueryVariables = {
  userId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelContentLearningFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ContentLearningsByUserQuery = {
  contentLearningsByUser?:  {
    __typename: "ModelContentLearningConnection",
    items:  Array< {
      __typename: "ContentLearning",
      groupId: string,
      courseId: string,
      contentId: string,
      contentType: ContentType,
      userId: string,
      status: LearningStatus,
      usageTime: number,
      lastStartedAt?: string | null,
      dataVersion: number,
      tenantCode: string,
      completedAt?: string | null,
      completedUsageTime?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetGroupExamQueryVariables = {
  id: string,
};

export type GetGroupExamQuery = {
  getGroupExam?:  {
    __typename: "GroupExam",
    id: string,
    groupId: string,
    contentId: string,
    content:  {
      __typename: "GroupExamContent",
      id: string,
      version?: number | null,
      name: string,
      timeLimit?: number | null,
      requiredTime?: number | null,
      indexInCourse?: number | null,
      passingStandard?: number | null,
    },
    problemsJson: string,
    courseId: string,
    course:  {
      __typename: "GroupExamCourse",
      id: string,
      version: number,
      name: string,
      color?: string | null,
      image?: string | null,
      fontColorOnImage?: CourseFontColorOnImage | null,
    },
    scheduledStart: string,
    scheduledFinish?: string | null,
    createdBy?: string | null,
    finishedAt?: string | null,
    visibilityLevel?: ExamResultVisibilityLevel | null,
    tenantCode: string,
    times: number,
    groupTrainingCourseId: string,
    problemHeaders?:  Array< {
      __typename: "ProblemHeader",
      id: string,
      body: string,
      createdAt: string,
    } > | null,
    timeLimit?: number | null,
    passingStandard?: number | null,
    userIdsToBeTested?: Array< string > | null,
    createdAt: string,
    updatedAt: string,
    userExams?:  {
      __typename: "ModelUserExamConnection",
      items:  Array< {
        __typename: "UserExam",
        id: string,
        userId: string,
        status: UserExamStatus,
        tenantCode: string,
        groupExamId: string,
        groupId: string,
        startedAt?: string | null,
        finishedAt?: string | null,
        createdAt: string,
        updatedAt: string,
        groupExam?:  {
          __typename: "GroupExam",
          id: string,
          groupId: string,
          contentId: string,
          content:  {
            __typename: "GroupExamContent",
            id: string,
            version?: number | null,
            name: string,
            timeLimit?: number | null,
            requiredTime?: number | null,
            indexInCourse?: number | null,
            passingStandard?: number | null,
          },
          problemsJson: string,
          courseId: string,
          course:  {
            __typename: "GroupExamCourse",
            id: string,
            version: number,
            name: string,
            color?: string | null,
            image?: string | null,
            fontColorOnImage?: CourseFontColorOnImage | null,
          },
          scheduledStart: string,
          scheduledFinish?: string | null,
          createdBy?: string | null,
          finishedAt?: string | null,
          visibilityLevel?: ExamResultVisibilityLevel | null,
          tenantCode: string,
          times: number,
          groupTrainingCourseId: string,
          problemHeaders?:  Array< {
            __typename: "ProblemHeader",
            id: string,
            body: string,
            createdAt: string,
          } > | null,
          timeLimit?: number | null,
          passingStandard?: number | null,
          userIdsToBeTested?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          userExams?:  {
            __typename: "ModelUserExamConnection",
            items:  Array< {
              __typename: "UserExam",
              id: string,
              userId: string,
              status: UserExamStatus,
              tenantCode: string,
              groupExamId: string,
              groupId: string,
              startedAt?: string | null,
              finishedAt?: string | null,
              createdAt: string,
              updatedAt: string,
              groupExam?:  {
                __typename: "GroupExam",
                id: string,
                groupId: string,
                contentId: string,
                content:  {
                  __typename: "GroupExamContent",
                  id: string,
                  version?: number | null,
                  name: string,
                  timeLimit?: number | null,
                  requiredTime?: number | null,
                  indexInCourse?: number | null,
                  passingStandard?: number | null,
                },
                problemsJson: string,
                courseId: string,
                course:  {
                  __typename: "GroupExamCourse",
                  id: string,
                  version: number,
                  name: string,
                  color?: string | null,
                  image?: string | null,
                  fontColorOnImage?: CourseFontColorOnImage | null,
                },
                scheduledStart: string,
                scheduledFinish?: string | null,
                createdBy?: string | null,
                finishedAt?: string | null,
                visibilityLevel?: ExamResultVisibilityLevel | null,
                tenantCode: string,
                times: number,
                groupTrainingCourseId: string,
                problemHeaders?:  Array< {
                  __typename: "ProblemHeader",
                  id: string,
                  body: string,
                  createdAt: string,
                } > | null,
                timeLimit?: number | null,
                passingStandard?: number | null,
                userIdsToBeTested?: Array< string > | null,
                createdAt: string,
                updatedAt: string,
                userExams?:  {
                  __typename: "ModelUserExamConnection",
                  nextToken?: string | null,
                } | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListGroupExamsQueryVariables = {
  filter?: ModelGroupExamFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListGroupExamsQuery = {
  listGroupExams?:  {
    __typename: "ModelGroupExamConnection",
    items:  Array< {
      __typename: "GroupExam",
      id: string,
      groupId: string,
      contentId: string,
      content:  {
        __typename: "GroupExamContent",
        id: string,
        version?: number | null,
        name: string,
        timeLimit?: number | null,
        requiredTime?: number | null,
        indexInCourse?: number | null,
        passingStandard?: number | null,
      },
      problemsJson: string,
      courseId: string,
      course:  {
        __typename: "GroupExamCourse",
        id: string,
        version: number,
        name: string,
        color?: string | null,
        image?: string | null,
        fontColorOnImage?: CourseFontColorOnImage | null,
      },
      scheduledStart: string,
      scheduledFinish?: string | null,
      createdBy?: string | null,
      finishedAt?: string | null,
      visibilityLevel?: ExamResultVisibilityLevel | null,
      tenantCode: string,
      times: number,
      groupTrainingCourseId: string,
      problemHeaders?:  Array< {
        __typename: "ProblemHeader",
        id: string,
        body: string,
        createdAt: string,
      } > | null,
      timeLimit?: number | null,
      passingStandard?: number | null,
      userIdsToBeTested?: Array< string > | null,
      createdAt: string,
      updatedAt: string,
      userExams?:  {
        __typename: "ModelUserExamConnection",
        items:  Array< {
          __typename: "UserExam",
          id: string,
          userId: string,
          status: UserExamStatus,
          tenantCode: string,
          groupExamId: string,
          groupId: string,
          startedAt?: string | null,
          finishedAt?: string | null,
          createdAt: string,
          updatedAt: string,
          groupExam?:  {
            __typename: "GroupExam",
            id: string,
            groupId: string,
            contentId: string,
            content:  {
              __typename: "GroupExamContent",
              id: string,
              version?: number | null,
              name: string,
              timeLimit?: number | null,
              requiredTime?: number | null,
              indexInCourse?: number | null,
              passingStandard?: number | null,
            },
            problemsJson: string,
            courseId: string,
            course:  {
              __typename: "GroupExamCourse",
              id: string,
              version: number,
              name: string,
              color?: string | null,
              image?: string | null,
              fontColorOnImage?: CourseFontColorOnImage | null,
            },
            scheduledStart: string,
            scheduledFinish?: string | null,
            createdBy?: string | null,
            finishedAt?: string | null,
            visibilityLevel?: ExamResultVisibilityLevel | null,
            tenantCode: string,
            times: number,
            groupTrainingCourseId: string,
            problemHeaders?:  Array< {
              __typename: "ProblemHeader",
              id: string,
              body: string,
              createdAt: string,
            } > | null,
            timeLimit?: number | null,
            passingStandard?: number | null,
            userIdsToBeTested?: Array< string > | null,
            createdAt: string,
            updatedAt: string,
            userExams?:  {
              __typename: "ModelUserExamConnection",
              items:  Array< {
                __typename: "UserExam",
                id: string,
                userId: string,
                status: UserExamStatus,
                tenantCode: string,
                groupExamId: string,
                groupId: string,
                startedAt?: string | null,
                finishedAt?: string | null,
                createdAt: string,
                updatedAt: string,
                groupExam?:  {
                  __typename: "GroupExam",
                  id: string,
                  groupId: string,
                  contentId: string,
                  problemsJson: string,
                  courseId: string,
                  scheduledStart: string,
                  scheduledFinish?: string | null,
                  createdBy?: string | null,
                  finishedAt?: string | null,
                  visibilityLevel?: ExamResultVisibilityLevel | null,
                  tenantCode: string,
                  times: number,
                  groupTrainingCourseId: string,
                  timeLimit?: number | null,
                  passingStandard?: number | null,
                  userIdsToBeTested?: Array< string > | null,
                  createdAt: string,
                  updatedAt: string,
                } | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GroupExamsByGroupQueryVariables = {
  groupId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelGroupExamFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GroupExamsByGroupQuery = {
  groupExamsByGroup?:  {
    __typename: "ModelGroupExamConnection",
    items:  Array< {
      __typename: "GroupExam",
      id: string,
      groupId: string,
      contentId: string,
      content:  {
        __typename: "GroupExamContent",
        id: string,
        version?: number | null,
        name: string,
        timeLimit?: number | null,
        requiredTime?: number | null,
        indexInCourse?: number | null,
        passingStandard?: number | null,
      },
      problemsJson: string,
      courseId: string,
      course:  {
        __typename: "GroupExamCourse",
        id: string,
        version: number,
        name: string,
        color?: string | null,
        image?: string | null,
        fontColorOnImage?: CourseFontColorOnImage | null,
      },
      scheduledStart: string,
      scheduledFinish?: string | null,
      createdBy?: string | null,
      finishedAt?: string | null,
      visibilityLevel?: ExamResultVisibilityLevel | null,
      tenantCode: string,
      times: number,
      groupTrainingCourseId: string,
      problemHeaders?:  Array< {
        __typename: "ProblemHeader",
        id: string,
        body: string,
        createdAt: string,
      } > | null,
      timeLimit?: number | null,
      passingStandard?: number | null,
      userIdsToBeTested?: Array< string > | null,
      createdAt: string,
      updatedAt: string,
      userExams?:  {
        __typename: "ModelUserExamConnection",
        items:  Array< {
          __typename: "UserExam",
          id: string,
          userId: string,
          status: UserExamStatus,
          tenantCode: string,
          groupExamId: string,
          groupId: string,
          startedAt?: string | null,
          finishedAt?: string | null,
          createdAt: string,
          updatedAt: string,
          groupExam?:  {
            __typename: "GroupExam",
            id: string,
            groupId: string,
            contentId: string,
            content:  {
              __typename: "GroupExamContent",
              id: string,
              version?: number | null,
              name: string,
              timeLimit?: number | null,
              requiredTime?: number | null,
              indexInCourse?: number | null,
              passingStandard?: number | null,
            },
            problemsJson: string,
            courseId: string,
            course:  {
              __typename: "GroupExamCourse",
              id: string,
              version: number,
              name: string,
              color?: string | null,
              image?: string | null,
              fontColorOnImage?: CourseFontColorOnImage | null,
            },
            scheduledStart: string,
            scheduledFinish?: string | null,
            createdBy?: string | null,
            finishedAt?: string | null,
            visibilityLevel?: ExamResultVisibilityLevel | null,
            tenantCode: string,
            times: number,
            groupTrainingCourseId: string,
            problemHeaders?:  Array< {
              __typename: "ProblemHeader",
              id: string,
              body: string,
              createdAt: string,
            } > | null,
            timeLimit?: number | null,
            passingStandard?: number | null,
            userIdsToBeTested?: Array< string > | null,
            createdAt: string,
            updatedAt: string,
            userExams?:  {
              __typename: "ModelUserExamConnection",
              items:  Array< {
                __typename: "UserExam",
                id: string,
                userId: string,
                status: UserExamStatus,
                tenantCode: string,
                groupExamId: string,
                groupId: string,
                startedAt?: string | null,
                finishedAt?: string | null,
                createdAt: string,
                updatedAt: string,
                groupExam?:  {
                  __typename: "GroupExam",
                  id: string,
                  groupId: string,
                  contentId: string,
                  problemsJson: string,
                  courseId: string,
                  scheduledStart: string,
                  scheduledFinish?: string | null,
                  createdBy?: string | null,
                  finishedAt?: string | null,
                  visibilityLevel?: ExamResultVisibilityLevel | null,
                  tenantCode: string,
                  times: number,
                  groupTrainingCourseId: string,
                  timeLimit?: number | null,
                  passingStandard?: number | null,
                  userIdsToBeTested?: Array< string > | null,
                  createdAt: string,
                  updatedAt: string,
                } | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GroupExamsByGroupAndContentQueryVariables = {
  groupId?: string | null,
  contentId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelGroupExamFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GroupExamsByGroupAndContentQuery = {
  groupExamsByGroupAndContent?:  {
    __typename: "ModelGroupExamConnection",
    items:  Array< {
      __typename: "GroupExam",
      id: string,
      groupId: string,
      contentId: string,
      content:  {
        __typename: "GroupExamContent",
        id: string,
        version?: number | null,
        name: string,
        timeLimit?: number | null,
        requiredTime?: number | null,
        indexInCourse?: number | null,
        passingStandard?: number | null,
      },
      problemsJson: string,
      courseId: string,
      course:  {
        __typename: "GroupExamCourse",
        id: string,
        version: number,
        name: string,
        color?: string | null,
        image?: string | null,
        fontColorOnImage?: CourseFontColorOnImage | null,
      },
      scheduledStart: string,
      scheduledFinish?: string | null,
      createdBy?: string | null,
      finishedAt?: string | null,
      visibilityLevel?: ExamResultVisibilityLevel | null,
      tenantCode: string,
      times: number,
      groupTrainingCourseId: string,
      problemHeaders?:  Array< {
        __typename: "ProblemHeader",
        id: string,
        body: string,
        createdAt: string,
      } > | null,
      timeLimit?: number | null,
      passingStandard?: number | null,
      userIdsToBeTested?: Array< string > | null,
      createdAt: string,
      updatedAt: string,
      userExams?:  {
        __typename: "ModelUserExamConnection",
        items:  Array< {
          __typename: "UserExam",
          id: string,
          userId: string,
          status: UserExamStatus,
          tenantCode: string,
          groupExamId: string,
          groupId: string,
          startedAt?: string | null,
          finishedAt?: string | null,
          createdAt: string,
          updatedAt: string,
          groupExam?:  {
            __typename: "GroupExam",
            id: string,
            groupId: string,
            contentId: string,
            content:  {
              __typename: "GroupExamContent",
              id: string,
              version?: number | null,
              name: string,
              timeLimit?: number | null,
              requiredTime?: number | null,
              indexInCourse?: number | null,
              passingStandard?: number | null,
            },
            problemsJson: string,
            courseId: string,
            course:  {
              __typename: "GroupExamCourse",
              id: string,
              version: number,
              name: string,
              color?: string | null,
              image?: string | null,
              fontColorOnImage?: CourseFontColorOnImage | null,
            },
            scheduledStart: string,
            scheduledFinish?: string | null,
            createdBy?: string | null,
            finishedAt?: string | null,
            visibilityLevel?: ExamResultVisibilityLevel | null,
            tenantCode: string,
            times: number,
            groupTrainingCourseId: string,
            problemHeaders?:  Array< {
              __typename: "ProblemHeader",
              id: string,
              body: string,
              createdAt: string,
            } > | null,
            timeLimit?: number | null,
            passingStandard?: number | null,
            userIdsToBeTested?: Array< string > | null,
            createdAt: string,
            updatedAt: string,
            userExams?:  {
              __typename: "ModelUserExamConnection",
              items:  Array< {
                __typename: "UserExam",
                id: string,
                userId: string,
                status: UserExamStatus,
                tenantCode: string,
                groupExamId: string,
                groupId: string,
                startedAt?: string | null,
                finishedAt?: string | null,
                createdAt: string,
                updatedAt: string,
                groupExam?:  {
                  __typename: "GroupExam",
                  id: string,
                  groupId: string,
                  contentId: string,
                  problemsJson: string,
                  courseId: string,
                  scheduledStart: string,
                  scheduledFinish?: string | null,
                  createdBy?: string | null,
                  finishedAt?: string | null,
                  visibilityLevel?: ExamResultVisibilityLevel | null,
                  tenantCode: string,
                  times: number,
                  groupTrainingCourseId: string,
                  timeLimit?: number | null,
                  passingStandard?: number | null,
                  userIdsToBeTested?: Array< string > | null,
                  createdAt: string,
                  updatedAt: string,
                } | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GroupExamsByGroupAndCourseQueryVariables = {
  groupId?: string | null,
  courseId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelGroupExamFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GroupExamsByGroupAndCourseQuery = {
  groupExamsByGroupAndCourse?:  {
    __typename: "ModelGroupExamConnection",
    items:  Array< {
      __typename: "GroupExam",
      id: string,
      groupId: string,
      contentId: string,
      content:  {
        __typename: "GroupExamContent",
        id: string,
        version?: number | null,
        name: string,
        timeLimit?: number | null,
        requiredTime?: number | null,
        indexInCourse?: number | null,
        passingStandard?: number | null,
      },
      problemsJson: string,
      courseId: string,
      course:  {
        __typename: "GroupExamCourse",
        id: string,
        version: number,
        name: string,
        color?: string | null,
        image?: string | null,
        fontColorOnImage?: CourseFontColorOnImage | null,
      },
      scheduledStart: string,
      scheduledFinish?: string | null,
      createdBy?: string | null,
      finishedAt?: string | null,
      visibilityLevel?: ExamResultVisibilityLevel | null,
      tenantCode: string,
      times: number,
      groupTrainingCourseId: string,
      problemHeaders?:  Array< {
        __typename: "ProblemHeader",
        id: string,
        body: string,
        createdAt: string,
      } > | null,
      timeLimit?: number | null,
      passingStandard?: number | null,
      userIdsToBeTested?: Array< string > | null,
      createdAt: string,
      updatedAt: string,
      userExams?:  {
        __typename: "ModelUserExamConnection",
        items:  Array< {
          __typename: "UserExam",
          id: string,
          userId: string,
          status: UserExamStatus,
          tenantCode: string,
          groupExamId: string,
          groupId: string,
          startedAt?: string | null,
          finishedAt?: string | null,
          createdAt: string,
          updatedAt: string,
          groupExam?:  {
            __typename: "GroupExam",
            id: string,
            groupId: string,
            contentId: string,
            content:  {
              __typename: "GroupExamContent",
              id: string,
              version?: number | null,
              name: string,
              timeLimit?: number | null,
              requiredTime?: number | null,
              indexInCourse?: number | null,
              passingStandard?: number | null,
            },
            problemsJson: string,
            courseId: string,
            course:  {
              __typename: "GroupExamCourse",
              id: string,
              version: number,
              name: string,
              color?: string | null,
              image?: string | null,
              fontColorOnImage?: CourseFontColorOnImage | null,
            },
            scheduledStart: string,
            scheduledFinish?: string | null,
            createdBy?: string | null,
            finishedAt?: string | null,
            visibilityLevel?: ExamResultVisibilityLevel | null,
            tenantCode: string,
            times: number,
            groupTrainingCourseId: string,
            problemHeaders?:  Array< {
              __typename: "ProblemHeader",
              id: string,
              body: string,
              createdAt: string,
            } > | null,
            timeLimit?: number | null,
            passingStandard?: number | null,
            userIdsToBeTested?: Array< string > | null,
            createdAt: string,
            updatedAt: string,
            userExams?:  {
              __typename: "ModelUserExamConnection",
              items:  Array< {
                __typename: "UserExam",
                id: string,
                userId: string,
                status: UserExamStatus,
                tenantCode: string,
                groupExamId: string,
                groupId: string,
                startedAt?: string | null,
                finishedAt?: string | null,
                createdAt: string,
                updatedAt: string,
                groupExam?:  {
                  __typename: "GroupExam",
                  id: string,
                  groupId: string,
                  contentId: string,
                  problemsJson: string,
                  courseId: string,
                  scheduledStart: string,
                  scheduledFinish?: string | null,
                  createdBy?: string | null,
                  finishedAt?: string | null,
                  visibilityLevel?: ExamResultVisibilityLevel | null,
                  tenantCode: string,
                  times: number,
                  groupTrainingCourseId: string,
                  timeLimit?: number | null,
                  passingStandard?: number | null,
                  userIdsToBeTested?: Array< string > | null,
                  createdAt: string,
                  updatedAt: string,
                } | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GroupExamsByCourseQueryVariables = {
  courseId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelGroupExamFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GroupExamsByCourseQuery = {
  groupExamsByCourse?:  {
    __typename: "ModelGroupExamConnection",
    items:  Array< {
      __typename: "GroupExam",
      id: string,
      groupId: string,
      contentId: string,
      content:  {
        __typename: "GroupExamContent",
        id: string,
        version?: number | null,
        name: string,
        timeLimit?: number | null,
        requiredTime?: number | null,
        indexInCourse?: number | null,
        passingStandard?: number | null,
      },
      problemsJson: string,
      courseId: string,
      course:  {
        __typename: "GroupExamCourse",
        id: string,
        version: number,
        name: string,
        color?: string | null,
        image?: string | null,
        fontColorOnImage?: CourseFontColorOnImage | null,
      },
      scheduledStart: string,
      scheduledFinish?: string | null,
      createdBy?: string | null,
      finishedAt?: string | null,
      visibilityLevel?: ExamResultVisibilityLevel | null,
      tenantCode: string,
      times: number,
      groupTrainingCourseId: string,
      problemHeaders?:  Array< {
        __typename: "ProblemHeader",
        id: string,
        body: string,
        createdAt: string,
      } > | null,
      timeLimit?: number | null,
      passingStandard?: number | null,
      userIdsToBeTested?: Array< string > | null,
      createdAt: string,
      updatedAt: string,
      userExams?:  {
        __typename: "ModelUserExamConnection",
        items:  Array< {
          __typename: "UserExam",
          id: string,
          userId: string,
          status: UserExamStatus,
          tenantCode: string,
          groupExamId: string,
          groupId: string,
          startedAt?: string | null,
          finishedAt?: string | null,
          createdAt: string,
          updatedAt: string,
          groupExam?:  {
            __typename: "GroupExam",
            id: string,
            groupId: string,
            contentId: string,
            content:  {
              __typename: "GroupExamContent",
              id: string,
              version?: number | null,
              name: string,
              timeLimit?: number | null,
              requiredTime?: number | null,
              indexInCourse?: number | null,
              passingStandard?: number | null,
            },
            problemsJson: string,
            courseId: string,
            course:  {
              __typename: "GroupExamCourse",
              id: string,
              version: number,
              name: string,
              color?: string | null,
              image?: string | null,
              fontColorOnImage?: CourseFontColorOnImage | null,
            },
            scheduledStart: string,
            scheduledFinish?: string | null,
            createdBy?: string | null,
            finishedAt?: string | null,
            visibilityLevel?: ExamResultVisibilityLevel | null,
            tenantCode: string,
            times: number,
            groupTrainingCourseId: string,
            problemHeaders?:  Array< {
              __typename: "ProblemHeader",
              id: string,
              body: string,
              createdAt: string,
            } > | null,
            timeLimit?: number | null,
            passingStandard?: number | null,
            userIdsToBeTested?: Array< string > | null,
            createdAt: string,
            updatedAt: string,
            userExams?:  {
              __typename: "ModelUserExamConnection",
              items:  Array< {
                __typename: "UserExam",
                id: string,
                userId: string,
                status: UserExamStatus,
                tenantCode: string,
                groupExamId: string,
                groupId: string,
                startedAt?: string | null,
                finishedAt?: string | null,
                createdAt: string,
                updatedAt: string,
                groupExam?:  {
                  __typename: "GroupExam",
                  id: string,
                  groupId: string,
                  contentId: string,
                  problemsJson: string,
                  courseId: string,
                  scheduledStart: string,
                  scheduledFinish?: string | null,
                  createdBy?: string | null,
                  finishedAt?: string | null,
                  visibilityLevel?: ExamResultVisibilityLevel | null,
                  tenantCode: string,
                  times: number,
                  groupTrainingCourseId: string,
                  timeLimit?: number | null,
                  passingStandard?: number | null,
                  userIdsToBeTested?: Array< string > | null,
                  createdAt: string,
                  updatedAt: string,
                } | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserExamQueryVariables = {
  id: string,
};

export type GetUserExamQuery = {
  getUserExam?:  {
    __typename: "UserExam",
    id: string,
    userId: string,
    status: UserExamStatus,
    tenantCode: string,
    groupExamId: string,
    groupId: string,
    startedAt?: string | null,
    finishedAt?: string | null,
    createdAt: string,
    updatedAt: string,
    groupExam?:  {
      __typename: "GroupExam",
      id: string,
      groupId: string,
      contentId: string,
      content:  {
        __typename: "GroupExamContent",
        id: string,
        version?: number | null,
        name: string,
        timeLimit?: number | null,
        requiredTime?: number | null,
        indexInCourse?: number | null,
        passingStandard?: number | null,
      },
      problemsJson: string,
      courseId: string,
      course:  {
        __typename: "GroupExamCourse",
        id: string,
        version: number,
        name: string,
        color?: string | null,
        image?: string | null,
        fontColorOnImage?: CourseFontColorOnImage | null,
      },
      scheduledStart: string,
      scheduledFinish?: string | null,
      createdBy?: string | null,
      finishedAt?: string | null,
      visibilityLevel?: ExamResultVisibilityLevel | null,
      tenantCode: string,
      times: number,
      groupTrainingCourseId: string,
      problemHeaders?:  Array< {
        __typename: "ProblemHeader",
        id: string,
        body: string,
        createdAt: string,
      } > | null,
      timeLimit?: number | null,
      passingStandard?: number | null,
      userIdsToBeTested?: Array< string > | null,
      createdAt: string,
      updatedAt: string,
      userExams?:  {
        __typename: "ModelUserExamConnection",
        items:  Array< {
          __typename: "UserExam",
          id: string,
          userId: string,
          status: UserExamStatus,
          tenantCode: string,
          groupExamId: string,
          groupId: string,
          startedAt?: string | null,
          finishedAt?: string | null,
          createdAt: string,
          updatedAt: string,
          groupExam?:  {
            __typename: "GroupExam",
            id: string,
            groupId: string,
            contentId: string,
            content:  {
              __typename: "GroupExamContent",
              id: string,
              version?: number | null,
              name: string,
              timeLimit?: number | null,
              requiredTime?: number | null,
              indexInCourse?: number | null,
              passingStandard?: number | null,
            },
            problemsJson: string,
            courseId: string,
            course:  {
              __typename: "GroupExamCourse",
              id: string,
              version: number,
              name: string,
              color?: string | null,
              image?: string | null,
              fontColorOnImage?: CourseFontColorOnImage | null,
            },
            scheduledStart: string,
            scheduledFinish?: string | null,
            createdBy?: string | null,
            finishedAt?: string | null,
            visibilityLevel?: ExamResultVisibilityLevel | null,
            tenantCode: string,
            times: number,
            groupTrainingCourseId: string,
            problemHeaders?:  Array< {
              __typename: "ProblemHeader",
              id: string,
              body: string,
              createdAt: string,
            } > | null,
            timeLimit?: number | null,
            passingStandard?: number | null,
            userIdsToBeTested?: Array< string > | null,
            createdAt: string,
            updatedAt: string,
            userExams?:  {
              __typename: "ModelUserExamConnection",
              items:  Array< {
                __typename: "UserExam",
                id: string,
                userId: string,
                status: UserExamStatus,
                tenantCode: string,
                groupExamId: string,
                groupId: string,
                startedAt?: string | null,
                finishedAt?: string | null,
                createdAt: string,
                updatedAt: string,
                groupExam?:  {
                  __typename: "GroupExam",
                  id: string,
                  groupId: string,
                  contentId: string,
                  problemsJson: string,
                  courseId: string,
                  scheduledStart: string,
                  scheduledFinish?: string | null,
                  createdBy?: string | null,
                  finishedAt?: string | null,
                  visibilityLevel?: ExamResultVisibilityLevel | null,
                  tenantCode: string,
                  times: number,
                  groupTrainingCourseId: string,
                  timeLimit?: number | null,
                  passingStandard?: number | null,
                  userIdsToBeTested?: Array< string > | null,
                  createdAt: string,
                  updatedAt: string,
                } | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListUserExamsQueryVariables = {
  filter?: ModelUserExamFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserExamsQuery = {
  listUserExams?:  {
    __typename: "ModelUserExamConnection",
    items:  Array< {
      __typename: "UserExam",
      id: string,
      userId: string,
      status: UserExamStatus,
      tenantCode: string,
      groupExamId: string,
      groupId: string,
      startedAt?: string | null,
      finishedAt?: string | null,
      createdAt: string,
      updatedAt: string,
      groupExam?:  {
        __typename: "GroupExam",
        id: string,
        groupId: string,
        contentId: string,
        content:  {
          __typename: "GroupExamContent",
          id: string,
          version?: number | null,
          name: string,
          timeLimit?: number | null,
          requiredTime?: number | null,
          indexInCourse?: number | null,
          passingStandard?: number | null,
        },
        problemsJson: string,
        courseId: string,
        course:  {
          __typename: "GroupExamCourse",
          id: string,
          version: number,
          name: string,
          color?: string | null,
          image?: string | null,
          fontColorOnImage?: CourseFontColorOnImage | null,
        },
        scheduledStart: string,
        scheduledFinish?: string | null,
        createdBy?: string | null,
        finishedAt?: string | null,
        visibilityLevel?: ExamResultVisibilityLevel | null,
        tenantCode: string,
        times: number,
        groupTrainingCourseId: string,
        problemHeaders?:  Array< {
          __typename: "ProblemHeader",
          id: string,
          body: string,
          createdAt: string,
        } > | null,
        timeLimit?: number | null,
        passingStandard?: number | null,
        userIdsToBeTested?: Array< string > | null,
        createdAt: string,
        updatedAt: string,
        userExams?:  {
          __typename: "ModelUserExamConnection",
          items:  Array< {
            __typename: "UserExam",
            id: string,
            userId: string,
            status: UserExamStatus,
            tenantCode: string,
            groupExamId: string,
            groupId: string,
            startedAt?: string | null,
            finishedAt?: string | null,
            createdAt: string,
            updatedAt: string,
            groupExam?:  {
              __typename: "GroupExam",
              id: string,
              groupId: string,
              contentId: string,
              content:  {
                __typename: "GroupExamContent",
                id: string,
                version?: number | null,
                name: string,
                timeLimit?: number | null,
                requiredTime?: number | null,
                indexInCourse?: number | null,
                passingStandard?: number | null,
              },
              problemsJson: string,
              courseId: string,
              course:  {
                __typename: "GroupExamCourse",
                id: string,
                version: number,
                name: string,
                color?: string | null,
                image?: string | null,
                fontColorOnImage?: CourseFontColorOnImage | null,
              },
              scheduledStart: string,
              scheduledFinish?: string | null,
              createdBy?: string | null,
              finishedAt?: string | null,
              visibilityLevel?: ExamResultVisibilityLevel | null,
              tenantCode: string,
              times: number,
              groupTrainingCourseId: string,
              problemHeaders?:  Array< {
                __typename: "ProblemHeader",
                id: string,
                body: string,
                createdAt: string,
              } > | null,
              timeLimit?: number | null,
              passingStandard?: number | null,
              userIdsToBeTested?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
              userExams?:  {
                __typename: "ModelUserExamConnection",
                items:  Array< {
                  __typename: "UserExam",
                  id: string,
                  userId: string,
                  status: UserExamStatus,
                  tenantCode: string,
                  groupExamId: string,
                  groupId: string,
                  startedAt?: string | null,
                  finishedAt?: string | null,
                  createdAt: string,
                  updatedAt: string,
                } | null >,
                nextToken?: string | null,
              } | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UserExamsByGroupExamQueryVariables = {
  groupExamId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserExamFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UserExamsByGroupExamQuery = {
  userExamsByGroupExam?:  {
    __typename: "ModelUserExamConnection",
    items:  Array< {
      __typename: "UserExam",
      id: string,
      userId: string,
      status: UserExamStatus,
      tenantCode: string,
      groupExamId: string,
      groupId: string,
      startedAt?: string | null,
      finishedAt?: string | null,
      createdAt: string,
      updatedAt: string,
      groupExam?:  {
        __typename: "GroupExam",
        id: string,
        groupId: string,
        contentId: string,
        content:  {
          __typename: "GroupExamContent",
          id: string,
          version?: number | null,
          name: string,
          timeLimit?: number | null,
          requiredTime?: number | null,
          indexInCourse?: number | null,
          passingStandard?: number | null,
        },
        problemsJson: string,
        courseId: string,
        course:  {
          __typename: "GroupExamCourse",
          id: string,
          version: number,
          name: string,
          color?: string | null,
          image?: string | null,
          fontColorOnImage?: CourseFontColorOnImage | null,
        },
        scheduledStart: string,
        scheduledFinish?: string | null,
        createdBy?: string | null,
        finishedAt?: string | null,
        visibilityLevel?: ExamResultVisibilityLevel | null,
        tenantCode: string,
        times: number,
        groupTrainingCourseId: string,
        problemHeaders?:  Array< {
          __typename: "ProblemHeader",
          id: string,
          body: string,
          createdAt: string,
        } > | null,
        timeLimit?: number | null,
        passingStandard?: number | null,
        userIdsToBeTested?: Array< string > | null,
        createdAt: string,
        updatedAt: string,
        userExams?:  {
          __typename: "ModelUserExamConnection",
          items:  Array< {
            __typename: "UserExam",
            id: string,
            userId: string,
            status: UserExamStatus,
            tenantCode: string,
            groupExamId: string,
            groupId: string,
            startedAt?: string | null,
            finishedAt?: string | null,
            createdAt: string,
            updatedAt: string,
            groupExam?:  {
              __typename: "GroupExam",
              id: string,
              groupId: string,
              contentId: string,
              content:  {
                __typename: "GroupExamContent",
                id: string,
                version?: number | null,
                name: string,
                timeLimit?: number | null,
                requiredTime?: number | null,
                indexInCourse?: number | null,
                passingStandard?: number | null,
              },
              problemsJson: string,
              courseId: string,
              course:  {
                __typename: "GroupExamCourse",
                id: string,
                version: number,
                name: string,
                color?: string | null,
                image?: string | null,
                fontColorOnImage?: CourseFontColorOnImage | null,
              },
              scheduledStart: string,
              scheduledFinish?: string | null,
              createdBy?: string | null,
              finishedAt?: string | null,
              visibilityLevel?: ExamResultVisibilityLevel | null,
              tenantCode: string,
              times: number,
              groupTrainingCourseId: string,
              problemHeaders?:  Array< {
                __typename: "ProblemHeader",
                id: string,
                body: string,
                createdAt: string,
              } > | null,
              timeLimit?: number | null,
              passingStandard?: number | null,
              userIdsToBeTested?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
              userExams?:  {
                __typename: "ModelUserExamConnection",
                items:  Array< {
                  __typename: "UserExam",
                  id: string,
                  userId: string,
                  status: UserExamStatus,
                  tenantCode: string,
                  groupExamId: string,
                  groupId: string,
                  startedAt?: string | null,
                  finishedAt?: string | null,
                  createdAt: string,
                  updatedAt: string,
                } | null >,
                nextToken?: string | null,
              } | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UserExamsByGroupAndUserQueryVariables = {
  groupId?: string | null,
  userId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserExamFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UserExamsByGroupAndUserQuery = {
  userExamsByGroupAndUser?:  {
    __typename: "ModelUserExamConnection",
    items:  Array< {
      __typename: "UserExam",
      id: string,
      userId: string,
      status: UserExamStatus,
      tenantCode: string,
      groupExamId: string,
      groupId: string,
      startedAt?: string | null,
      finishedAt?: string | null,
      createdAt: string,
      updatedAt: string,
      groupExam?:  {
        __typename: "GroupExam",
        id: string,
        groupId: string,
        contentId: string,
        content:  {
          __typename: "GroupExamContent",
          id: string,
          version?: number | null,
          name: string,
          timeLimit?: number | null,
          requiredTime?: number | null,
          indexInCourse?: number | null,
          passingStandard?: number | null,
        },
        problemsJson: string,
        courseId: string,
        course:  {
          __typename: "GroupExamCourse",
          id: string,
          version: number,
          name: string,
          color?: string | null,
          image?: string | null,
          fontColorOnImage?: CourseFontColorOnImage | null,
        },
        scheduledStart: string,
        scheduledFinish?: string | null,
        createdBy?: string | null,
        finishedAt?: string | null,
        visibilityLevel?: ExamResultVisibilityLevel | null,
        tenantCode: string,
        times: number,
        groupTrainingCourseId: string,
        problemHeaders?:  Array< {
          __typename: "ProblemHeader",
          id: string,
          body: string,
          createdAt: string,
        } > | null,
        timeLimit?: number | null,
        passingStandard?: number | null,
        userIdsToBeTested?: Array< string > | null,
        createdAt: string,
        updatedAt: string,
        userExams?:  {
          __typename: "ModelUserExamConnection",
          items:  Array< {
            __typename: "UserExam",
            id: string,
            userId: string,
            status: UserExamStatus,
            tenantCode: string,
            groupExamId: string,
            groupId: string,
            startedAt?: string | null,
            finishedAt?: string | null,
            createdAt: string,
            updatedAt: string,
            groupExam?:  {
              __typename: "GroupExam",
              id: string,
              groupId: string,
              contentId: string,
              content:  {
                __typename: "GroupExamContent",
                id: string,
                version?: number | null,
                name: string,
                timeLimit?: number | null,
                requiredTime?: number | null,
                indexInCourse?: number | null,
                passingStandard?: number | null,
              },
              problemsJson: string,
              courseId: string,
              course:  {
                __typename: "GroupExamCourse",
                id: string,
                version: number,
                name: string,
                color?: string | null,
                image?: string | null,
                fontColorOnImage?: CourseFontColorOnImage | null,
              },
              scheduledStart: string,
              scheduledFinish?: string | null,
              createdBy?: string | null,
              finishedAt?: string | null,
              visibilityLevel?: ExamResultVisibilityLevel | null,
              tenantCode: string,
              times: number,
              groupTrainingCourseId: string,
              problemHeaders?:  Array< {
                __typename: "ProblemHeader",
                id: string,
                body: string,
                createdAt: string,
              } > | null,
              timeLimit?: number | null,
              passingStandard?: number | null,
              userIdsToBeTested?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
              userExams?:  {
                __typename: "ModelUserExamConnection",
                items:  Array< {
                  __typename: "UserExam",
                  id: string,
                  userId: string,
                  status: UserExamStatus,
                  tenantCode: string,
                  groupExamId: string,
                  groupId: string,
                  startedAt?: string | null,
                  finishedAt?: string | null,
                  createdAt: string,
                  updatedAt: string,
                } | null >,
                nextToken?: string | null,
              } | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UserExamsByUserQueryVariables = {
  userId?: string | null,
  status?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserExamFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UserExamsByUserQuery = {
  userExamsByUser?:  {
    __typename: "ModelUserExamConnection",
    items:  Array< {
      __typename: "UserExam",
      id: string,
      userId: string,
      status: UserExamStatus,
      tenantCode: string,
      groupExamId: string,
      groupId: string,
      startedAt?: string | null,
      finishedAt?: string | null,
      createdAt: string,
      updatedAt: string,
      groupExam?:  {
        __typename: "GroupExam",
        id: string,
        groupId: string,
        contentId: string,
        content:  {
          __typename: "GroupExamContent",
          id: string,
          version?: number | null,
          name: string,
          timeLimit?: number | null,
          requiredTime?: number | null,
          indexInCourse?: number | null,
          passingStandard?: number | null,
        },
        problemsJson: string,
        courseId: string,
        course:  {
          __typename: "GroupExamCourse",
          id: string,
          version: number,
          name: string,
          color?: string | null,
          image?: string | null,
          fontColorOnImage?: CourseFontColorOnImage | null,
        },
        scheduledStart: string,
        scheduledFinish?: string | null,
        createdBy?: string | null,
        finishedAt?: string | null,
        visibilityLevel?: ExamResultVisibilityLevel | null,
        tenantCode: string,
        times: number,
        groupTrainingCourseId: string,
        problemHeaders?:  Array< {
          __typename: "ProblemHeader",
          id: string,
          body: string,
          createdAt: string,
        } > | null,
        timeLimit?: number | null,
        passingStandard?: number | null,
        userIdsToBeTested?: Array< string > | null,
        createdAt: string,
        updatedAt: string,
        userExams?:  {
          __typename: "ModelUserExamConnection",
          items:  Array< {
            __typename: "UserExam",
            id: string,
            userId: string,
            status: UserExamStatus,
            tenantCode: string,
            groupExamId: string,
            groupId: string,
            startedAt?: string | null,
            finishedAt?: string | null,
            createdAt: string,
            updatedAt: string,
            groupExam?:  {
              __typename: "GroupExam",
              id: string,
              groupId: string,
              contentId: string,
              content:  {
                __typename: "GroupExamContent",
                id: string,
                version?: number | null,
                name: string,
                timeLimit?: number | null,
                requiredTime?: number | null,
                indexInCourse?: number | null,
                passingStandard?: number | null,
              },
              problemsJson: string,
              courseId: string,
              course:  {
                __typename: "GroupExamCourse",
                id: string,
                version: number,
                name: string,
                color?: string | null,
                image?: string | null,
                fontColorOnImage?: CourseFontColorOnImage | null,
              },
              scheduledStart: string,
              scheduledFinish?: string | null,
              createdBy?: string | null,
              finishedAt?: string | null,
              visibilityLevel?: ExamResultVisibilityLevel | null,
              tenantCode: string,
              times: number,
              groupTrainingCourseId: string,
              problemHeaders?:  Array< {
                __typename: "ProblemHeader",
                id: string,
                body: string,
                createdAt: string,
              } > | null,
              timeLimit?: number | null,
              passingStandard?: number | null,
              userIdsToBeTested?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
              userExams?:  {
                __typename: "ModelUserExamConnection",
                items:  Array< {
                  __typename: "UserExam",
                  id: string,
                  userId: string,
                  status: UserExamStatus,
                  tenantCode: string,
                  groupExamId: string,
                  groupId: string,
                  startedAt?: string | null,
                  finishedAt?: string | null,
                  createdAt: string,
                  updatedAt: string,
                } | null >,
                nextToken?: string | null,
              } | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnCreateNotificationSubscription = {
  onCreateNotification?:  {
    __typename: "Notification",
    id: string,
    type: string,
    payloadJson: string,
    userId: string,
    read: boolean,
    createdAt?: string | null,
    tenantCode: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateNotificationSubscription = {
  onUpdateNotification?:  {
    __typename: "Notification",
    id: string,
    type: string,
    payloadJson: string,
    userId: string,
    read: boolean,
    createdAt?: string | null,
    tenantCode: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteNotificationSubscription = {
  onDeleteNotification?:  {
    __typename: "Notification",
    id: string,
    type: string,
    payloadJson: string,
    userId: string,
    read: boolean,
    createdAt?: string | null,
    tenantCode: string,
    updatedAt: string,
  } | null,
};

export type OnCreateLiveSubscription = {
  onCreateLive?:  {
    __typename: "Live",
    id: string,
    userId: string,
    url: string,
    applicationSessionId: string,
    tenantCode: string,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type OnUpdateLiveSubscription = {
  onUpdateLive?:  {
    __typename: "Live",
    id: string,
    userId: string,
    url: string,
    applicationSessionId: string,
    tenantCode: string,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type OnDeleteLiveSubscription = {
  onDeleteLive?:  {
    __typename: "Live",
    id: string,
    userId: string,
    url: string,
    applicationSessionId: string,
    tenantCode: string,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type OnCreateQuestionnaireSubscription = {
  onCreateQuestionnaire?:  {
    __typename: "Questionnaire",
    id: string,
    groupId: string,
    tenantCode: string,
    title: string,
    userIds: Array< string >,
    text?: string | null,
    createdBy: string,
    createdAt?: string | null,
    options: Array< string >,
    respondent?:  {
      __typename: "ModelQuestionnaireRespondentConnection",
      items:  Array< {
        __typename: "QuestionnaireRespondent",
        id: string,
        questionnaireId: string,
        userId: string,
        selectedIndex: number,
        tenantCode: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    status: QuestionnaireStatus,
    finishedAt?: string | null,
    updatedAt: string,
  } | null,
};

export type OnUpdateQuestionnaireSubscription = {
  onUpdateQuestionnaire?:  {
    __typename: "Questionnaire",
    id: string,
    groupId: string,
    tenantCode: string,
    title: string,
    userIds: Array< string >,
    text?: string | null,
    createdBy: string,
    createdAt?: string | null,
    options: Array< string >,
    respondent?:  {
      __typename: "ModelQuestionnaireRespondentConnection",
      items:  Array< {
        __typename: "QuestionnaireRespondent",
        id: string,
        questionnaireId: string,
        userId: string,
        selectedIndex: number,
        tenantCode: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    status: QuestionnaireStatus,
    finishedAt?: string | null,
    updatedAt: string,
  } | null,
};

export type OnDeleteQuestionnaireSubscription = {
  onDeleteQuestionnaire?:  {
    __typename: "Questionnaire",
    id: string,
    groupId: string,
    tenantCode: string,
    title: string,
    userIds: Array< string >,
    text?: string | null,
    createdBy: string,
    createdAt?: string | null,
    options: Array< string >,
    respondent?:  {
      __typename: "ModelQuestionnaireRespondentConnection",
      items:  Array< {
        __typename: "QuestionnaireRespondent",
        id: string,
        questionnaireId: string,
        userId: string,
        selectedIndex: number,
        tenantCode: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    status: QuestionnaireStatus,
    finishedAt?: string | null,
    updatedAt: string,
  } | null,
};

export type OnCreateQuestionnaireRespondentByQuestionnaireIdSubscriptionVariables = {
  questionnaireId: string,
};

export type OnCreateQuestionnaireRespondentByQuestionnaireIdSubscription = {
  onCreateQuestionnaireRespondentByQuestionnaireId?:  {
    __typename: "QuestionnaireRespondent",
    id: string,
    questionnaireId: string,
    userId: string,
    selectedIndex: number,
    tenantCode: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateQuestionnaireRespondentByQuestionnaireIdSubscriptionVariables = {
  questionnaireId: string,
};

export type OnUpdateQuestionnaireRespondentByQuestionnaireIdSubscription = {
  onUpdateQuestionnaireRespondentByQuestionnaireId?:  {
    __typename: "QuestionnaireRespondent",
    id: string,
    questionnaireId: string,
    userId: string,
    selectedIndex: number,
    tenantCode: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteQuestionnaireRespondentByQuestionnaireIdSubscriptionVariables = {
  questionnaireId: string,
};

export type OnDeleteQuestionnaireRespondentByQuestionnaireIdSubscription = {
  onDeleteQuestionnaireRespondentByQuestionnaireId?:  {
    __typename: "QuestionnaireRespondent",
    id: string,
    questionnaireId: string,
    userId: string,
    selectedIndex: number,
    tenantCode: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateScheduleTagSubscription = {
  onCreateScheduleTag?:  {
    __typename: "ScheduleTag",
    id: string,
    color: string,
    text: string,
    groupId: string,
    tenantCode: string,
    createdAt: string,
    updatedAt: string,
    schedules?:  {
      __typename: "ModelScheduleScheduleTagConnection",
      items:  Array< {
        __typename: "ScheduleScheduleTag",
        id: string,
        scheduleId: string,
        scheduleTagId: string,
        scheduleTag:  {
          __typename: "ScheduleTag",
          id: string,
          color: string,
          text: string,
          groupId: string,
          tenantCode: string,
          createdAt: string,
          updatedAt: string,
          schedules?:  {
            __typename: "ModelScheduleScheduleTagConnection",
            items:  Array< {
              __typename: "ScheduleScheduleTag",
              id: string,
              scheduleId: string,
              scheduleTagId: string,
              scheduleTag:  {
                __typename: "ScheduleTag",
                id: string,
                color: string,
                text: string,
                groupId: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                schedules?:  {
                  __typename: "ModelScheduleScheduleTagConnection",
                  nextToken?: string | null,
                } | null,
              },
              tenantCode: string,
              createdAt: string,
              updatedAt: string,
              schedule:  {
                __typename: "Schedule",
                id: string,
                groupId: string,
                start: string,
                end?: string | null,
                title: string,
                body?: string | null,
                tenantCode: string,
                createdBy?: string | null,
                updatedBy?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                tags?:  {
                  __typename: "ModelScheduleScheduleTagConnection",
                  nextToken?: string | null,
                } | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        tenantCode: string,
        createdAt: string,
        updatedAt: string,
        schedule:  {
          __typename: "Schedule",
          id: string,
          groupId: string,
          start: string,
          end?: string | null,
          title: string,
          body?: string | null,
          tenantCode: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          tags?:  {
            __typename: "ModelScheduleScheduleTagConnection",
            items:  Array< {
              __typename: "ScheduleScheduleTag",
              id: string,
              scheduleId: string,
              scheduleTagId: string,
              scheduleTag:  {
                __typename: "ScheduleTag",
                id: string,
                color: string,
                text: string,
                groupId: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                schedules?:  {
                  __typename: "ModelScheduleScheduleTagConnection",
                  nextToken?: string | null,
                } | null,
              },
              tenantCode: string,
              createdAt: string,
              updatedAt: string,
              schedule:  {
                __typename: "Schedule",
                id: string,
                groupId: string,
                start: string,
                end?: string | null,
                title: string,
                body?: string | null,
                tenantCode: string,
                createdBy?: string | null,
                updatedBy?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                tags?:  {
                  __typename: "ModelScheduleScheduleTagConnection",
                  nextToken?: string | null,
                } | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateScheduleTagSubscription = {
  onUpdateScheduleTag?:  {
    __typename: "ScheduleTag",
    id: string,
    color: string,
    text: string,
    groupId: string,
    tenantCode: string,
    createdAt: string,
    updatedAt: string,
    schedules?:  {
      __typename: "ModelScheduleScheduleTagConnection",
      items:  Array< {
        __typename: "ScheduleScheduleTag",
        id: string,
        scheduleId: string,
        scheduleTagId: string,
        scheduleTag:  {
          __typename: "ScheduleTag",
          id: string,
          color: string,
          text: string,
          groupId: string,
          tenantCode: string,
          createdAt: string,
          updatedAt: string,
          schedules?:  {
            __typename: "ModelScheduleScheduleTagConnection",
            items:  Array< {
              __typename: "ScheduleScheduleTag",
              id: string,
              scheduleId: string,
              scheduleTagId: string,
              scheduleTag:  {
                __typename: "ScheduleTag",
                id: string,
                color: string,
                text: string,
                groupId: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                schedules?:  {
                  __typename: "ModelScheduleScheduleTagConnection",
                  nextToken?: string | null,
                } | null,
              },
              tenantCode: string,
              createdAt: string,
              updatedAt: string,
              schedule:  {
                __typename: "Schedule",
                id: string,
                groupId: string,
                start: string,
                end?: string | null,
                title: string,
                body?: string | null,
                tenantCode: string,
                createdBy?: string | null,
                updatedBy?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                tags?:  {
                  __typename: "ModelScheduleScheduleTagConnection",
                  nextToken?: string | null,
                } | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        tenantCode: string,
        createdAt: string,
        updatedAt: string,
        schedule:  {
          __typename: "Schedule",
          id: string,
          groupId: string,
          start: string,
          end?: string | null,
          title: string,
          body?: string | null,
          tenantCode: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          tags?:  {
            __typename: "ModelScheduleScheduleTagConnection",
            items:  Array< {
              __typename: "ScheduleScheduleTag",
              id: string,
              scheduleId: string,
              scheduleTagId: string,
              scheduleTag:  {
                __typename: "ScheduleTag",
                id: string,
                color: string,
                text: string,
                groupId: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                schedules?:  {
                  __typename: "ModelScheduleScheduleTagConnection",
                  nextToken?: string | null,
                } | null,
              },
              tenantCode: string,
              createdAt: string,
              updatedAt: string,
              schedule:  {
                __typename: "Schedule",
                id: string,
                groupId: string,
                start: string,
                end?: string | null,
                title: string,
                body?: string | null,
                tenantCode: string,
                createdBy?: string | null,
                updatedBy?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                tags?:  {
                  __typename: "ModelScheduleScheduleTagConnection",
                  nextToken?: string | null,
                } | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeleteScheduleTagSubscription = {
  onDeleteScheduleTag?:  {
    __typename: "ScheduleTag",
    id: string,
    color: string,
    text: string,
    groupId: string,
    tenantCode: string,
    createdAt: string,
    updatedAt: string,
    schedules?:  {
      __typename: "ModelScheduleScheduleTagConnection",
      items:  Array< {
        __typename: "ScheduleScheduleTag",
        id: string,
        scheduleId: string,
        scheduleTagId: string,
        scheduleTag:  {
          __typename: "ScheduleTag",
          id: string,
          color: string,
          text: string,
          groupId: string,
          tenantCode: string,
          createdAt: string,
          updatedAt: string,
          schedules?:  {
            __typename: "ModelScheduleScheduleTagConnection",
            items:  Array< {
              __typename: "ScheduleScheduleTag",
              id: string,
              scheduleId: string,
              scheduleTagId: string,
              scheduleTag:  {
                __typename: "ScheduleTag",
                id: string,
                color: string,
                text: string,
                groupId: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                schedules?:  {
                  __typename: "ModelScheduleScheduleTagConnection",
                  nextToken?: string | null,
                } | null,
              },
              tenantCode: string,
              createdAt: string,
              updatedAt: string,
              schedule:  {
                __typename: "Schedule",
                id: string,
                groupId: string,
                start: string,
                end?: string | null,
                title: string,
                body?: string | null,
                tenantCode: string,
                createdBy?: string | null,
                updatedBy?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                tags?:  {
                  __typename: "ModelScheduleScheduleTagConnection",
                  nextToken?: string | null,
                } | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        tenantCode: string,
        createdAt: string,
        updatedAt: string,
        schedule:  {
          __typename: "Schedule",
          id: string,
          groupId: string,
          start: string,
          end?: string | null,
          title: string,
          body?: string | null,
          tenantCode: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          tags?:  {
            __typename: "ModelScheduleScheduleTagConnection",
            items:  Array< {
              __typename: "ScheduleScheduleTag",
              id: string,
              scheduleId: string,
              scheduleTagId: string,
              scheduleTag:  {
                __typename: "ScheduleTag",
                id: string,
                color: string,
                text: string,
                groupId: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                schedules?:  {
                  __typename: "ModelScheduleScheduleTagConnection",
                  nextToken?: string | null,
                } | null,
              },
              tenantCode: string,
              createdAt: string,
              updatedAt: string,
              schedule:  {
                __typename: "Schedule",
                id: string,
                groupId: string,
                start: string,
                end?: string | null,
                title: string,
                body?: string | null,
                tenantCode: string,
                createdBy?: string | null,
                updatedBy?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                tags?:  {
                  __typename: "ModelScheduleScheduleTagConnection",
                  nextToken?: string | null,
                } | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreateMemoSubscription = {
  onCreateMemo?:  {
    __typename: "Memo",
    id: string,
    body?: string | null,
    referTo?:  {
      __typename: "ContentReference",
      contentId: string,
      contentVersion?: number | null,
      options?:  {
        __typename: "ContentReferenceOptions",
        text?:  {
          __typename: "TextContentReferenceOptions",
          selectionJson: string,
        } | null,
        workbook?:  {
          __typename: "WorkbookReferenceOptions",
          problemIndex: number,
          selectionJson?: string | null,
        } | null,
      } | null,
    } | null,
    scope?: string | null,
    groupId: string,
    createdBy: string,
    createdAt?: string | null,
    updatedBy?: string | null,
    updatedAt?: string | null,
    tenantCode: string,
  } | null,
};

export type OnUpdateMemoSubscription = {
  onUpdateMemo?:  {
    __typename: "Memo",
    id: string,
    body?: string | null,
    referTo?:  {
      __typename: "ContentReference",
      contentId: string,
      contentVersion?: number | null,
      options?:  {
        __typename: "ContentReferenceOptions",
        text?:  {
          __typename: "TextContentReferenceOptions",
          selectionJson: string,
        } | null,
        workbook?:  {
          __typename: "WorkbookReferenceOptions",
          problemIndex: number,
          selectionJson?: string | null,
        } | null,
      } | null,
    } | null,
    scope?: string | null,
    groupId: string,
    createdBy: string,
    createdAt?: string | null,
    updatedBy?: string | null,
    updatedAt?: string | null,
    tenantCode: string,
  } | null,
};

export type OnDeleteMemoSubscription = {
  onDeleteMemo?:  {
    __typename: "Memo",
    id: string,
    body?: string | null,
    referTo?:  {
      __typename: "ContentReference",
      contentId: string,
      contentVersion?: number | null,
      options?:  {
        __typename: "ContentReferenceOptions",
        text?:  {
          __typename: "TextContentReferenceOptions",
          selectionJson: string,
        } | null,
        workbook?:  {
          __typename: "WorkbookReferenceOptions",
          problemIndex: number,
          selectionJson?: string | null,
        } | null,
      } | null,
    } | null,
    scope?: string | null,
    groupId: string,
    createdBy: string,
    createdAt?: string | null,
    updatedBy?: string | null,
    updatedAt?: string | null,
    tenantCode: string,
  } | null,
};

export type OnCreateReviewProblemSubscription = {
  onCreateReviewProblem?:  {
    __typename: "ReviewProblem",
    id: string,
    userId: string,
    groupId: string,
    courseId: string,
    problems?: string | null,
    tenantCode: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateReviewProblemSubscription = {
  onUpdateReviewProblem?:  {
    __typename: "ReviewProblem",
    id: string,
    userId: string,
    groupId: string,
    courseId: string,
    problems?: string | null,
    tenantCode: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteReviewProblemSubscription = {
  onDeleteReviewProblem?:  {
    __typename: "ReviewProblem",
    id: string,
    userId: string,
    groupId: string,
    courseId: string,
    problems?: string | null,
    tenantCode: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateUserSubscription = {
  onCreateUser?:  {
    __typename: "User",
    id: string,
    name: string,
    role: Role,
    extensionConfigJsonList: Array< string >,
    email?: string | null,
    code?: string | null,
    tenantCode: string,
    displaySettings?:  {
      __typename: "DisplaySettings",
      theme?: string | null,
      color?: string | null,
    } | null,
    locale?: string | null,
    avatar?: string | null,
    enabled: boolean,
    signedInAtLeastOnce: boolean,
    emailConfirmed?: boolean | null,
    confirmedTermsOfServiceVersions?:  {
      __typename: "ConfirmedTermsOfServiceVersions",
      termsOfServiceVersion?: string | null,
      privacyPolicyVersion?: string | null,
      tenantOwnTermsOfServiceVersion?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    tags?:  {
      __typename: "ModelUserUserTagConnection",
      items:  Array< {
        __typename: "UserUserTag",
        id: string,
        userId: string,
        userTagId: string,
        tenantCode: string,
        createdAt: string,
        updatedAt: string,
        user:  {
          __typename: "User",
          id: string,
          name: string,
          role: Role,
          extensionConfigJsonList: Array< string >,
          email?: string | null,
          code?: string | null,
          tenantCode: string,
          displaySettings?:  {
            __typename: "DisplaySettings",
            theme?: string | null,
            color?: string | null,
          } | null,
          locale?: string | null,
          avatar?: string | null,
          enabled: boolean,
          signedInAtLeastOnce: boolean,
          emailConfirmed?: boolean | null,
          confirmedTermsOfServiceVersions?:  {
            __typename: "ConfirmedTermsOfServiceVersions",
            termsOfServiceVersion?: string | null,
            privacyPolicyVersion?: string | null,
            tenantOwnTermsOfServiceVersion?: number | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          tags?:  {
            __typename: "ModelUserUserTagConnection",
            items:  Array< {
              __typename: "UserUserTag",
              id: string,
              userId: string,
              userTagId: string,
              tenantCode: string,
              createdAt: string,
              updatedAt: string,
              user:  {
                __typename: "User",
                id: string,
                name: string,
                role: Role,
                extensionConfigJsonList: Array< string >,
                email?: string | null,
                code?: string | null,
                tenantCode: string,
                displaySettings?:  {
                  __typename: "DisplaySettings",
                  theme?: string | null,
                  color?: string | null,
                } | null,
                locale?: string | null,
                avatar?: string | null,
                enabled: boolean,
                signedInAtLeastOnce: boolean,
                emailConfirmed?: boolean | null,
                confirmedTermsOfServiceVersions?:  {
                  __typename: "ConfirmedTermsOfServiceVersions",
                  termsOfServiceVersion?: string | null,
                  privacyPolicyVersion?: string | null,
                  tenantOwnTermsOfServiceVersion?: number | null,
                } | null,
                createdAt: string,
                updatedAt: string,
                tags?:  {
                  __typename: "ModelUserUserTagConnection",
                  nextToken?: string | null,
                } | null,
                statuses?:  {
                  __typename: "ModelUserStatusTableConnection",
                  nextToken?: string | null,
                } | null,
              },
              userTag:  {
                __typename: "UserTag",
                id: string,
                color: string,
                text: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                users?:  {
                  __typename: "ModelUserUserTagConnection",
                  nextToken?: string | null,
                } | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          statuses?:  {
            __typename: "ModelUserStatusTableConnection",
            items:  Array< {
              __typename: "UserStatusTable",
              id: string,
              tenantCode: string,
              status: UserStatus,
              userId: string,
              createdAt: string,
              updatedAt: string,
              user:  {
                __typename: "User",
                id: string,
                name: string,
                role: Role,
                extensionConfigJsonList: Array< string >,
                email?: string | null,
                code?: string | null,
                tenantCode: string,
                displaySettings?:  {
                  __typename: "DisplaySettings",
                  theme?: string | null,
                  color?: string | null,
                } | null,
                locale?: string | null,
                avatar?: string | null,
                enabled: boolean,
                signedInAtLeastOnce: boolean,
                emailConfirmed?: boolean | null,
                confirmedTermsOfServiceVersions?:  {
                  __typename: "ConfirmedTermsOfServiceVersions",
                  termsOfServiceVersion?: string | null,
                  privacyPolicyVersion?: string | null,
                  tenantOwnTermsOfServiceVersion?: number | null,
                } | null,
                createdAt: string,
                updatedAt: string,
                tags?:  {
                  __typename: "ModelUserUserTagConnection",
                  nextToken?: string | null,
                } | null,
                statuses?:  {
                  __typename: "ModelUserStatusTableConnection",
                  nextToken?: string | null,
                } | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        userTag:  {
          __typename: "UserTag",
          id: string,
          color: string,
          text: string,
          tenantCode: string,
          createdAt: string,
          updatedAt: string,
          users?:  {
            __typename: "ModelUserUserTagConnection",
            items:  Array< {
              __typename: "UserUserTag",
              id: string,
              userId: string,
              userTagId: string,
              tenantCode: string,
              createdAt: string,
              updatedAt: string,
              user:  {
                __typename: "User",
                id: string,
                name: string,
                role: Role,
                extensionConfigJsonList: Array< string >,
                email?: string | null,
                code?: string | null,
                tenantCode: string,
                displaySettings?:  {
                  __typename: "DisplaySettings",
                  theme?: string | null,
                  color?: string | null,
                } | null,
                locale?: string | null,
                avatar?: string | null,
                enabled: boolean,
                signedInAtLeastOnce: boolean,
                emailConfirmed?: boolean | null,
                confirmedTermsOfServiceVersions?:  {
                  __typename: "ConfirmedTermsOfServiceVersions",
                  termsOfServiceVersion?: string | null,
                  privacyPolicyVersion?: string | null,
                  tenantOwnTermsOfServiceVersion?: number | null,
                } | null,
                createdAt: string,
                updatedAt: string,
                tags?:  {
                  __typename: "ModelUserUserTagConnection",
                  nextToken?: string | null,
                } | null,
                statuses?:  {
                  __typename: "ModelUserStatusTableConnection",
                  nextToken?: string | null,
                } | null,
              },
              userTag:  {
                __typename: "UserTag",
                id: string,
                color: string,
                text: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                users?:  {
                  __typename: "ModelUserUserTagConnection",
                  nextToken?: string | null,
                } | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    statuses?:  {
      __typename: "ModelUserStatusTableConnection",
      items:  Array< {
        __typename: "UserStatusTable",
        id: string,
        tenantCode: string,
        status: UserStatus,
        userId: string,
        createdAt: string,
        updatedAt: string,
        user:  {
          __typename: "User",
          id: string,
          name: string,
          role: Role,
          extensionConfigJsonList: Array< string >,
          email?: string | null,
          code?: string | null,
          tenantCode: string,
          displaySettings?:  {
            __typename: "DisplaySettings",
            theme?: string | null,
            color?: string | null,
          } | null,
          locale?: string | null,
          avatar?: string | null,
          enabled: boolean,
          signedInAtLeastOnce: boolean,
          emailConfirmed?: boolean | null,
          confirmedTermsOfServiceVersions?:  {
            __typename: "ConfirmedTermsOfServiceVersions",
            termsOfServiceVersion?: string | null,
            privacyPolicyVersion?: string | null,
            tenantOwnTermsOfServiceVersion?: number | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          tags?:  {
            __typename: "ModelUserUserTagConnection",
            items:  Array< {
              __typename: "UserUserTag",
              id: string,
              userId: string,
              userTagId: string,
              tenantCode: string,
              createdAt: string,
              updatedAt: string,
              user:  {
                __typename: "User",
                id: string,
                name: string,
                role: Role,
                extensionConfigJsonList: Array< string >,
                email?: string | null,
                code?: string | null,
                tenantCode: string,
                displaySettings?:  {
                  __typename: "DisplaySettings",
                  theme?: string | null,
                  color?: string | null,
                } | null,
                locale?: string | null,
                avatar?: string | null,
                enabled: boolean,
                signedInAtLeastOnce: boolean,
                emailConfirmed?: boolean | null,
                confirmedTermsOfServiceVersions?:  {
                  __typename: "ConfirmedTermsOfServiceVersions",
                  termsOfServiceVersion?: string | null,
                  privacyPolicyVersion?: string | null,
                  tenantOwnTermsOfServiceVersion?: number | null,
                } | null,
                createdAt: string,
                updatedAt: string,
                tags?:  {
                  __typename: "ModelUserUserTagConnection",
                  nextToken?: string | null,
                } | null,
                statuses?:  {
                  __typename: "ModelUserStatusTableConnection",
                  nextToken?: string | null,
                } | null,
              },
              userTag:  {
                __typename: "UserTag",
                id: string,
                color: string,
                text: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                users?:  {
                  __typename: "ModelUserUserTagConnection",
                  nextToken?: string | null,
                } | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          statuses?:  {
            __typename: "ModelUserStatusTableConnection",
            items:  Array< {
              __typename: "UserStatusTable",
              id: string,
              tenantCode: string,
              status: UserStatus,
              userId: string,
              createdAt: string,
              updatedAt: string,
              user:  {
                __typename: "User",
                id: string,
                name: string,
                role: Role,
                extensionConfigJsonList: Array< string >,
                email?: string | null,
                code?: string | null,
                tenantCode: string,
                displaySettings?:  {
                  __typename: "DisplaySettings",
                  theme?: string | null,
                  color?: string | null,
                } | null,
                locale?: string | null,
                avatar?: string | null,
                enabled: boolean,
                signedInAtLeastOnce: boolean,
                emailConfirmed?: boolean | null,
                confirmedTermsOfServiceVersions?:  {
                  __typename: "ConfirmedTermsOfServiceVersions",
                  termsOfServiceVersion?: string | null,
                  privacyPolicyVersion?: string | null,
                  tenantOwnTermsOfServiceVersion?: number | null,
                } | null,
                createdAt: string,
                updatedAt: string,
                tags?:  {
                  __typename: "ModelUserUserTagConnection",
                  nextToken?: string | null,
                } | null,
                statuses?:  {
                  __typename: "ModelUserStatusTableConnection",
                  nextToken?: string | null,
                } | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateUserSubscription = {
  onUpdateUser?:  {
    __typename: "User",
    id: string,
    name: string,
    role: Role,
    extensionConfigJsonList: Array< string >,
    email?: string | null,
    code?: string | null,
    tenantCode: string,
    displaySettings?:  {
      __typename: "DisplaySettings",
      theme?: string | null,
      color?: string | null,
    } | null,
    locale?: string | null,
    avatar?: string | null,
    enabled: boolean,
    signedInAtLeastOnce: boolean,
    emailConfirmed?: boolean | null,
    confirmedTermsOfServiceVersions?:  {
      __typename: "ConfirmedTermsOfServiceVersions",
      termsOfServiceVersion?: string | null,
      privacyPolicyVersion?: string | null,
      tenantOwnTermsOfServiceVersion?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    tags?:  {
      __typename: "ModelUserUserTagConnection",
      items:  Array< {
        __typename: "UserUserTag",
        id: string,
        userId: string,
        userTagId: string,
        tenantCode: string,
        createdAt: string,
        updatedAt: string,
        user:  {
          __typename: "User",
          id: string,
          name: string,
          role: Role,
          extensionConfigJsonList: Array< string >,
          email?: string | null,
          code?: string | null,
          tenantCode: string,
          displaySettings?:  {
            __typename: "DisplaySettings",
            theme?: string | null,
            color?: string | null,
          } | null,
          locale?: string | null,
          avatar?: string | null,
          enabled: boolean,
          signedInAtLeastOnce: boolean,
          emailConfirmed?: boolean | null,
          confirmedTermsOfServiceVersions?:  {
            __typename: "ConfirmedTermsOfServiceVersions",
            termsOfServiceVersion?: string | null,
            privacyPolicyVersion?: string | null,
            tenantOwnTermsOfServiceVersion?: number | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          tags?:  {
            __typename: "ModelUserUserTagConnection",
            items:  Array< {
              __typename: "UserUserTag",
              id: string,
              userId: string,
              userTagId: string,
              tenantCode: string,
              createdAt: string,
              updatedAt: string,
              user:  {
                __typename: "User",
                id: string,
                name: string,
                role: Role,
                extensionConfigJsonList: Array< string >,
                email?: string | null,
                code?: string | null,
                tenantCode: string,
                displaySettings?:  {
                  __typename: "DisplaySettings",
                  theme?: string | null,
                  color?: string | null,
                } | null,
                locale?: string | null,
                avatar?: string | null,
                enabled: boolean,
                signedInAtLeastOnce: boolean,
                emailConfirmed?: boolean | null,
                confirmedTermsOfServiceVersions?:  {
                  __typename: "ConfirmedTermsOfServiceVersions",
                  termsOfServiceVersion?: string | null,
                  privacyPolicyVersion?: string | null,
                  tenantOwnTermsOfServiceVersion?: number | null,
                } | null,
                createdAt: string,
                updatedAt: string,
                tags?:  {
                  __typename: "ModelUserUserTagConnection",
                  nextToken?: string | null,
                } | null,
                statuses?:  {
                  __typename: "ModelUserStatusTableConnection",
                  nextToken?: string | null,
                } | null,
              },
              userTag:  {
                __typename: "UserTag",
                id: string,
                color: string,
                text: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                users?:  {
                  __typename: "ModelUserUserTagConnection",
                  nextToken?: string | null,
                } | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          statuses?:  {
            __typename: "ModelUserStatusTableConnection",
            items:  Array< {
              __typename: "UserStatusTable",
              id: string,
              tenantCode: string,
              status: UserStatus,
              userId: string,
              createdAt: string,
              updatedAt: string,
              user:  {
                __typename: "User",
                id: string,
                name: string,
                role: Role,
                extensionConfigJsonList: Array< string >,
                email?: string | null,
                code?: string | null,
                tenantCode: string,
                displaySettings?:  {
                  __typename: "DisplaySettings",
                  theme?: string | null,
                  color?: string | null,
                } | null,
                locale?: string | null,
                avatar?: string | null,
                enabled: boolean,
                signedInAtLeastOnce: boolean,
                emailConfirmed?: boolean | null,
                confirmedTermsOfServiceVersions?:  {
                  __typename: "ConfirmedTermsOfServiceVersions",
                  termsOfServiceVersion?: string | null,
                  privacyPolicyVersion?: string | null,
                  tenantOwnTermsOfServiceVersion?: number | null,
                } | null,
                createdAt: string,
                updatedAt: string,
                tags?:  {
                  __typename: "ModelUserUserTagConnection",
                  nextToken?: string | null,
                } | null,
                statuses?:  {
                  __typename: "ModelUserStatusTableConnection",
                  nextToken?: string | null,
                } | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        userTag:  {
          __typename: "UserTag",
          id: string,
          color: string,
          text: string,
          tenantCode: string,
          createdAt: string,
          updatedAt: string,
          users?:  {
            __typename: "ModelUserUserTagConnection",
            items:  Array< {
              __typename: "UserUserTag",
              id: string,
              userId: string,
              userTagId: string,
              tenantCode: string,
              createdAt: string,
              updatedAt: string,
              user:  {
                __typename: "User",
                id: string,
                name: string,
                role: Role,
                extensionConfigJsonList: Array< string >,
                email?: string | null,
                code?: string | null,
                tenantCode: string,
                displaySettings?:  {
                  __typename: "DisplaySettings",
                  theme?: string | null,
                  color?: string | null,
                } | null,
                locale?: string | null,
                avatar?: string | null,
                enabled: boolean,
                signedInAtLeastOnce: boolean,
                emailConfirmed?: boolean | null,
                confirmedTermsOfServiceVersions?:  {
                  __typename: "ConfirmedTermsOfServiceVersions",
                  termsOfServiceVersion?: string | null,
                  privacyPolicyVersion?: string | null,
                  tenantOwnTermsOfServiceVersion?: number | null,
                } | null,
                createdAt: string,
                updatedAt: string,
                tags?:  {
                  __typename: "ModelUserUserTagConnection",
                  nextToken?: string | null,
                } | null,
                statuses?:  {
                  __typename: "ModelUserStatusTableConnection",
                  nextToken?: string | null,
                } | null,
              },
              userTag:  {
                __typename: "UserTag",
                id: string,
                color: string,
                text: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                users?:  {
                  __typename: "ModelUserUserTagConnection",
                  nextToken?: string | null,
                } | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    statuses?:  {
      __typename: "ModelUserStatusTableConnection",
      items:  Array< {
        __typename: "UserStatusTable",
        id: string,
        tenantCode: string,
        status: UserStatus,
        userId: string,
        createdAt: string,
        updatedAt: string,
        user:  {
          __typename: "User",
          id: string,
          name: string,
          role: Role,
          extensionConfigJsonList: Array< string >,
          email?: string | null,
          code?: string | null,
          tenantCode: string,
          displaySettings?:  {
            __typename: "DisplaySettings",
            theme?: string | null,
            color?: string | null,
          } | null,
          locale?: string | null,
          avatar?: string | null,
          enabled: boolean,
          signedInAtLeastOnce: boolean,
          emailConfirmed?: boolean | null,
          confirmedTermsOfServiceVersions?:  {
            __typename: "ConfirmedTermsOfServiceVersions",
            termsOfServiceVersion?: string | null,
            privacyPolicyVersion?: string | null,
            tenantOwnTermsOfServiceVersion?: number | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          tags?:  {
            __typename: "ModelUserUserTagConnection",
            items:  Array< {
              __typename: "UserUserTag",
              id: string,
              userId: string,
              userTagId: string,
              tenantCode: string,
              createdAt: string,
              updatedAt: string,
              user:  {
                __typename: "User",
                id: string,
                name: string,
                role: Role,
                extensionConfigJsonList: Array< string >,
                email?: string | null,
                code?: string | null,
                tenantCode: string,
                displaySettings?:  {
                  __typename: "DisplaySettings",
                  theme?: string | null,
                  color?: string | null,
                } | null,
                locale?: string | null,
                avatar?: string | null,
                enabled: boolean,
                signedInAtLeastOnce: boolean,
                emailConfirmed?: boolean | null,
                confirmedTermsOfServiceVersions?:  {
                  __typename: "ConfirmedTermsOfServiceVersions",
                  termsOfServiceVersion?: string | null,
                  privacyPolicyVersion?: string | null,
                  tenantOwnTermsOfServiceVersion?: number | null,
                } | null,
                createdAt: string,
                updatedAt: string,
                tags?:  {
                  __typename: "ModelUserUserTagConnection",
                  nextToken?: string | null,
                } | null,
                statuses?:  {
                  __typename: "ModelUserStatusTableConnection",
                  nextToken?: string | null,
                } | null,
              },
              userTag:  {
                __typename: "UserTag",
                id: string,
                color: string,
                text: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                users?:  {
                  __typename: "ModelUserUserTagConnection",
                  nextToken?: string | null,
                } | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          statuses?:  {
            __typename: "ModelUserStatusTableConnection",
            items:  Array< {
              __typename: "UserStatusTable",
              id: string,
              tenantCode: string,
              status: UserStatus,
              userId: string,
              createdAt: string,
              updatedAt: string,
              user:  {
                __typename: "User",
                id: string,
                name: string,
                role: Role,
                extensionConfigJsonList: Array< string >,
                email?: string | null,
                code?: string | null,
                tenantCode: string,
                displaySettings?:  {
                  __typename: "DisplaySettings",
                  theme?: string | null,
                  color?: string | null,
                } | null,
                locale?: string | null,
                avatar?: string | null,
                enabled: boolean,
                signedInAtLeastOnce: boolean,
                emailConfirmed?: boolean | null,
                confirmedTermsOfServiceVersions?:  {
                  __typename: "ConfirmedTermsOfServiceVersions",
                  termsOfServiceVersion?: string | null,
                  privacyPolicyVersion?: string | null,
                  tenantOwnTermsOfServiceVersion?: number | null,
                } | null,
                createdAt: string,
                updatedAt: string,
                tags?:  {
                  __typename: "ModelUserUserTagConnection",
                  nextToken?: string | null,
                } | null,
                statuses?:  {
                  __typename: "ModelUserStatusTableConnection",
                  nextToken?: string | null,
                } | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeleteUserSubscription = {
  onDeleteUser?:  {
    __typename: "User",
    id: string,
    name: string,
    role: Role,
    extensionConfigJsonList: Array< string >,
    email?: string | null,
    code?: string | null,
    tenantCode: string,
    displaySettings?:  {
      __typename: "DisplaySettings",
      theme?: string | null,
      color?: string | null,
    } | null,
    locale?: string | null,
    avatar?: string | null,
    enabled: boolean,
    signedInAtLeastOnce: boolean,
    emailConfirmed?: boolean | null,
    confirmedTermsOfServiceVersions?:  {
      __typename: "ConfirmedTermsOfServiceVersions",
      termsOfServiceVersion?: string | null,
      privacyPolicyVersion?: string | null,
      tenantOwnTermsOfServiceVersion?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    tags?:  {
      __typename: "ModelUserUserTagConnection",
      items:  Array< {
        __typename: "UserUserTag",
        id: string,
        userId: string,
        userTagId: string,
        tenantCode: string,
        createdAt: string,
        updatedAt: string,
        user:  {
          __typename: "User",
          id: string,
          name: string,
          role: Role,
          extensionConfigJsonList: Array< string >,
          email?: string | null,
          code?: string | null,
          tenantCode: string,
          displaySettings?:  {
            __typename: "DisplaySettings",
            theme?: string | null,
            color?: string | null,
          } | null,
          locale?: string | null,
          avatar?: string | null,
          enabled: boolean,
          signedInAtLeastOnce: boolean,
          emailConfirmed?: boolean | null,
          confirmedTermsOfServiceVersions?:  {
            __typename: "ConfirmedTermsOfServiceVersions",
            termsOfServiceVersion?: string | null,
            privacyPolicyVersion?: string | null,
            tenantOwnTermsOfServiceVersion?: number | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          tags?:  {
            __typename: "ModelUserUserTagConnection",
            items:  Array< {
              __typename: "UserUserTag",
              id: string,
              userId: string,
              userTagId: string,
              tenantCode: string,
              createdAt: string,
              updatedAt: string,
              user:  {
                __typename: "User",
                id: string,
                name: string,
                role: Role,
                extensionConfigJsonList: Array< string >,
                email?: string | null,
                code?: string | null,
                tenantCode: string,
                displaySettings?:  {
                  __typename: "DisplaySettings",
                  theme?: string | null,
                  color?: string | null,
                } | null,
                locale?: string | null,
                avatar?: string | null,
                enabled: boolean,
                signedInAtLeastOnce: boolean,
                emailConfirmed?: boolean | null,
                confirmedTermsOfServiceVersions?:  {
                  __typename: "ConfirmedTermsOfServiceVersions",
                  termsOfServiceVersion?: string | null,
                  privacyPolicyVersion?: string | null,
                  tenantOwnTermsOfServiceVersion?: number | null,
                } | null,
                createdAt: string,
                updatedAt: string,
                tags?:  {
                  __typename: "ModelUserUserTagConnection",
                  nextToken?: string | null,
                } | null,
                statuses?:  {
                  __typename: "ModelUserStatusTableConnection",
                  nextToken?: string | null,
                } | null,
              },
              userTag:  {
                __typename: "UserTag",
                id: string,
                color: string,
                text: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                users?:  {
                  __typename: "ModelUserUserTagConnection",
                  nextToken?: string | null,
                } | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          statuses?:  {
            __typename: "ModelUserStatusTableConnection",
            items:  Array< {
              __typename: "UserStatusTable",
              id: string,
              tenantCode: string,
              status: UserStatus,
              userId: string,
              createdAt: string,
              updatedAt: string,
              user:  {
                __typename: "User",
                id: string,
                name: string,
                role: Role,
                extensionConfigJsonList: Array< string >,
                email?: string | null,
                code?: string | null,
                tenantCode: string,
                displaySettings?:  {
                  __typename: "DisplaySettings",
                  theme?: string | null,
                  color?: string | null,
                } | null,
                locale?: string | null,
                avatar?: string | null,
                enabled: boolean,
                signedInAtLeastOnce: boolean,
                emailConfirmed?: boolean | null,
                confirmedTermsOfServiceVersions?:  {
                  __typename: "ConfirmedTermsOfServiceVersions",
                  termsOfServiceVersion?: string | null,
                  privacyPolicyVersion?: string | null,
                  tenantOwnTermsOfServiceVersion?: number | null,
                } | null,
                createdAt: string,
                updatedAt: string,
                tags?:  {
                  __typename: "ModelUserUserTagConnection",
                  nextToken?: string | null,
                } | null,
                statuses?:  {
                  __typename: "ModelUserStatusTableConnection",
                  nextToken?: string | null,
                } | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        userTag:  {
          __typename: "UserTag",
          id: string,
          color: string,
          text: string,
          tenantCode: string,
          createdAt: string,
          updatedAt: string,
          users?:  {
            __typename: "ModelUserUserTagConnection",
            items:  Array< {
              __typename: "UserUserTag",
              id: string,
              userId: string,
              userTagId: string,
              tenantCode: string,
              createdAt: string,
              updatedAt: string,
              user:  {
                __typename: "User",
                id: string,
                name: string,
                role: Role,
                extensionConfigJsonList: Array< string >,
                email?: string | null,
                code?: string | null,
                tenantCode: string,
                displaySettings?:  {
                  __typename: "DisplaySettings",
                  theme?: string | null,
                  color?: string | null,
                } | null,
                locale?: string | null,
                avatar?: string | null,
                enabled: boolean,
                signedInAtLeastOnce: boolean,
                emailConfirmed?: boolean | null,
                confirmedTermsOfServiceVersions?:  {
                  __typename: "ConfirmedTermsOfServiceVersions",
                  termsOfServiceVersion?: string | null,
                  privacyPolicyVersion?: string | null,
                  tenantOwnTermsOfServiceVersion?: number | null,
                } | null,
                createdAt: string,
                updatedAt: string,
                tags?:  {
                  __typename: "ModelUserUserTagConnection",
                  nextToken?: string | null,
                } | null,
                statuses?:  {
                  __typename: "ModelUserStatusTableConnection",
                  nextToken?: string | null,
                } | null,
              },
              userTag:  {
                __typename: "UserTag",
                id: string,
                color: string,
                text: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                users?:  {
                  __typename: "ModelUserUserTagConnection",
                  nextToken?: string | null,
                } | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    statuses?:  {
      __typename: "ModelUserStatusTableConnection",
      items:  Array< {
        __typename: "UserStatusTable",
        id: string,
        tenantCode: string,
        status: UserStatus,
        userId: string,
        createdAt: string,
        updatedAt: string,
        user:  {
          __typename: "User",
          id: string,
          name: string,
          role: Role,
          extensionConfigJsonList: Array< string >,
          email?: string | null,
          code?: string | null,
          tenantCode: string,
          displaySettings?:  {
            __typename: "DisplaySettings",
            theme?: string | null,
            color?: string | null,
          } | null,
          locale?: string | null,
          avatar?: string | null,
          enabled: boolean,
          signedInAtLeastOnce: boolean,
          emailConfirmed?: boolean | null,
          confirmedTermsOfServiceVersions?:  {
            __typename: "ConfirmedTermsOfServiceVersions",
            termsOfServiceVersion?: string | null,
            privacyPolicyVersion?: string | null,
            tenantOwnTermsOfServiceVersion?: number | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          tags?:  {
            __typename: "ModelUserUserTagConnection",
            items:  Array< {
              __typename: "UserUserTag",
              id: string,
              userId: string,
              userTagId: string,
              tenantCode: string,
              createdAt: string,
              updatedAt: string,
              user:  {
                __typename: "User",
                id: string,
                name: string,
                role: Role,
                extensionConfigJsonList: Array< string >,
                email?: string | null,
                code?: string | null,
                tenantCode: string,
                displaySettings?:  {
                  __typename: "DisplaySettings",
                  theme?: string | null,
                  color?: string | null,
                } | null,
                locale?: string | null,
                avatar?: string | null,
                enabled: boolean,
                signedInAtLeastOnce: boolean,
                emailConfirmed?: boolean | null,
                confirmedTermsOfServiceVersions?:  {
                  __typename: "ConfirmedTermsOfServiceVersions",
                  termsOfServiceVersion?: string | null,
                  privacyPolicyVersion?: string | null,
                  tenantOwnTermsOfServiceVersion?: number | null,
                } | null,
                createdAt: string,
                updatedAt: string,
                tags?:  {
                  __typename: "ModelUserUserTagConnection",
                  nextToken?: string | null,
                } | null,
                statuses?:  {
                  __typename: "ModelUserStatusTableConnection",
                  nextToken?: string | null,
                } | null,
              },
              userTag:  {
                __typename: "UserTag",
                id: string,
                color: string,
                text: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                users?:  {
                  __typename: "ModelUserUserTagConnection",
                  nextToken?: string | null,
                } | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          statuses?:  {
            __typename: "ModelUserStatusTableConnection",
            items:  Array< {
              __typename: "UserStatusTable",
              id: string,
              tenantCode: string,
              status: UserStatus,
              userId: string,
              createdAt: string,
              updatedAt: string,
              user:  {
                __typename: "User",
                id: string,
                name: string,
                role: Role,
                extensionConfigJsonList: Array< string >,
                email?: string | null,
                code?: string | null,
                tenantCode: string,
                displaySettings?:  {
                  __typename: "DisplaySettings",
                  theme?: string | null,
                  color?: string | null,
                } | null,
                locale?: string | null,
                avatar?: string | null,
                enabled: boolean,
                signedInAtLeastOnce: boolean,
                emailConfirmed?: boolean | null,
                confirmedTermsOfServiceVersions?:  {
                  __typename: "ConfirmedTermsOfServiceVersions",
                  termsOfServiceVersion?: string | null,
                  privacyPolicyVersion?: string | null,
                  tenantOwnTermsOfServiceVersion?: number | null,
                } | null,
                createdAt: string,
                updatedAt: string,
                tags?:  {
                  __typename: "ModelUserUserTagConnection",
                  nextToken?: string | null,
                } | null,
                statuses?:  {
                  __typename: "ModelUserStatusTableConnection",
                  nextToken?: string | null,
                } | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreateUserDeviceSubscriptionVariables = {
  userId?: string | null,
};

export type OnCreateUserDeviceSubscription = {
  onCreateUserDevice?:  {
    __typename: "UserDevice",
    userId: string,
    devices: string,
    dataVersion: number,
    tenantCode: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateUserDeviceSubscriptionVariables = {
  userId?: string | null,
};

export type OnUpdateUserDeviceSubscription = {
  onUpdateUserDevice?:  {
    __typename: "UserDevice",
    userId: string,
    devices: string,
    dataVersion: number,
    tenantCode: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteUserDeviceSubscriptionVariables = {
  userId?: string | null,
};

export type OnDeleteUserDeviceSubscription = {
  onDeleteUserDevice?:  {
    __typename: "UserDevice",
    userId: string,
    devices: string,
    dataVersion: number,
    tenantCode: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateSignUpReservationSubscription = {
  onCreateSignUpReservation?:  {
    __typename: "SignUpReservation",
    id: string,
    name?: string | null,
    key: string,
    userCode?: string | null,
    email?: string | null,
    tenantCode: string,
    type: SignUpType,
    role: Role,
    userId?: string | null,
    signUpInput?:  {
      __typename: "SignUpInput",
      name: string,
      tenantName?: string | null,
    } | null,
    status: SignUpReservationStatus,
    groups?:  Array< {
      __typename: "GroupUser",
      id: string,
      role: GroupRole,
      removed?: boolean | null,
    } > | null,
    createdBy?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateSignUpReservationSubscription = {
  onUpdateSignUpReservation?:  {
    __typename: "SignUpReservation",
    id: string,
    name?: string | null,
    key: string,
    userCode?: string | null,
    email?: string | null,
    tenantCode: string,
    type: SignUpType,
    role: Role,
    userId?: string | null,
    signUpInput?:  {
      __typename: "SignUpInput",
      name: string,
      tenantName?: string | null,
    } | null,
    status: SignUpReservationStatus,
    groups?:  Array< {
      __typename: "GroupUser",
      id: string,
      role: GroupRole,
      removed?: boolean | null,
    } > | null,
    createdBy?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteSignUpReservationSubscription = {
  onDeleteSignUpReservation?:  {
    __typename: "SignUpReservation",
    id: string,
    name?: string | null,
    key: string,
    userCode?: string | null,
    email?: string | null,
    tenantCode: string,
    type: SignUpType,
    role: Role,
    userId?: string | null,
    signUpInput?:  {
      __typename: "SignUpInput",
      name: string,
      tenantName?: string | null,
    } | null,
    status: SignUpReservationStatus,
    groups?:  Array< {
      __typename: "GroupUser",
      id: string,
      role: GroupRole,
      removed?: boolean | null,
    } > | null,
    createdBy?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateTenantSubscription = {
  onCreateTenant?:  {
    __typename: "Tenant",
    id: string,
    code: string,
    name: string,
    signUpType: SignUpType,
    playground: boolean,
    userLimit?: number | null,
    limitations?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateTenantSubscription = {
  onUpdateTenant?:  {
    __typename: "Tenant",
    id: string,
    code: string,
    name: string,
    signUpType: SignUpType,
    playground: boolean,
    userLimit?: number | null,
    limitations?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteTenantSubscription = {
  onDeleteTenant?:  {
    __typename: "Tenant",
    id: string,
    code: string,
    name: string,
    signUpType: SignUpType,
    playground: boolean,
    userLimit?: number | null,
    limitations?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateTenantOwnTermsOfServiceSubscription = {
  onCreateTenantOwnTermsOfService?:  {
    __typename: "TenantOwnTermsOfService",
    id: string,
    tenantCode: string,
    type: TenantOwnTermsOfServiceType,
    body: string,
    createdBy: string,
    createdAt: string,
    updatedBy?: string | null,
    updatedAt?: string | null,
    version?: number | null,
  } | null,
};

export type OnUpdateTenantOwnTermsOfServiceSubscription = {
  onUpdateTenantOwnTermsOfService?:  {
    __typename: "TenantOwnTermsOfService",
    id: string,
    tenantCode: string,
    type: TenantOwnTermsOfServiceType,
    body: string,
    createdBy: string,
    createdAt: string,
    updatedBy?: string | null,
    updatedAt?: string | null,
    version?: number | null,
  } | null,
};

export type OnDeleteTenantOwnTermsOfServiceSubscription = {
  onDeleteTenantOwnTermsOfService?:  {
    __typename: "TenantOwnTermsOfService",
    id: string,
    tenantCode: string,
    type: TenantOwnTermsOfServiceType,
    body: string,
    createdBy: string,
    createdAt: string,
    updatedBy?: string | null,
    updatedAt?: string | null,
    version?: number | null,
  } | null,
};

export type OnCreateEventSubscription = {
  onCreateEvent?:  {
    __typename: "Event",
    id: string,
    type: string,
    event: string,
    version: number,
    tenantCode: string,
    occurredBy: string,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type OnUpdateEventSubscription = {
  onUpdateEvent?:  {
    __typename: "Event",
    id: string,
    type: string,
    event: string,
    version: number,
    tenantCode: string,
    occurredBy: string,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type OnDeleteEventSubscription = {
  onDeleteEvent?:  {
    __typename: "Event",
    id: string,
    type: string,
    event: string,
    version: number,
    tenantCode: string,
    occurredBy: string,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type OnCreateTermsOfServiceSubscription = {
  onCreateTermsOfService?:  {
    __typename: "TermsOfService",
    id: string,
    body: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateTermsOfServiceSubscription = {
  onUpdateTermsOfService?:  {
    __typename: "TermsOfService",
    id: string,
    body: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteTermsOfServiceSubscription = {
  onDeleteTermsOfService?:  {
    __typename: "TermsOfService",
    id: string,
    body: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateUserTagSubscription = {
  onCreateUserTag?:  {
    __typename: "UserTag",
    id: string,
    color: string,
    text: string,
    tenantCode: string,
    createdAt: string,
    updatedAt: string,
    users?:  {
      __typename: "ModelUserUserTagConnection",
      items:  Array< {
        __typename: "UserUserTag",
        id: string,
        userId: string,
        userTagId: string,
        tenantCode: string,
        createdAt: string,
        updatedAt: string,
        user:  {
          __typename: "User",
          id: string,
          name: string,
          role: Role,
          extensionConfigJsonList: Array< string >,
          email?: string | null,
          code?: string | null,
          tenantCode: string,
          displaySettings?:  {
            __typename: "DisplaySettings",
            theme?: string | null,
            color?: string | null,
          } | null,
          locale?: string | null,
          avatar?: string | null,
          enabled: boolean,
          signedInAtLeastOnce: boolean,
          emailConfirmed?: boolean | null,
          confirmedTermsOfServiceVersions?:  {
            __typename: "ConfirmedTermsOfServiceVersions",
            termsOfServiceVersion?: string | null,
            privacyPolicyVersion?: string | null,
            tenantOwnTermsOfServiceVersion?: number | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          tags?:  {
            __typename: "ModelUserUserTagConnection",
            items:  Array< {
              __typename: "UserUserTag",
              id: string,
              userId: string,
              userTagId: string,
              tenantCode: string,
              createdAt: string,
              updatedAt: string,
              user:  {
                __typename: "User",
                id: string,
                name: string,
                role: Role,
                extensionConfigJsonList: Array< string >,
                email?: string | null,
                code?: string | null,
                tenantCode: string,
                displaySettings?:  {
                  __typename: "DisplaySettings",
                  theme?: string | null,
                  color?: string | null,
                } | null,
                locale?: string | null,
                avatar?: string | null,
                enabled: boolean,
                signedInAtLeastOnce: boolean,
                emailConfirmed?: boolean | null,
                confirmedTermsOfServiceVersions?:  {
                  __typename: "ConfirmedTermsOfServiceVersions",
                  termsOfServiceVersion?: string | null,
                  privacyPolicyVersion?: string | null,
                  tenantOwnTermsOfServiceVersion?: number | null,
                } | null,
                createdAt: string,
                updatedAt: string,
                tags?:  {
                  __typename: "ModelUserUserTagConnection",
                  nextToken?: string | null,
                } | null,
                statuses?:  {
                  __typename: "ModelUserStatusTableConnection",
                  nextToken?: string | null,
                } | null,
              },
              userTag:  {
                __typename: "UserTag",
                id: string,
                color: string,
                text: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                users?:  {
                  __typename: "ModelUserUserTagConnection",
                  nextToken?: string | null,
                } | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          statuses?:  {
            __typename: "ModelUserStatusTableConnection",
            items:  Array< {
              __typename: "UserStatusTable",
              id: string,
              tenantCode: string,
              status: UserStatus,
              userId: string,
              createdAt: string,
              updatedAt: string,
              user:  {
                __typename: "User",
                id: string,
                name: string,
                role: Role,
                extensionConfigJsonList: Array< string >,
                email?: string | null,
                code?: string | null,
                tenantCode: string,
                displaySettings?:  {
                  __typename: "DisplaySettings",
                  theme?: string | null,
                  color?: string | null,
                } | null,
                locale?: string | null,
                avatar?: string | null,
                enabled: boolean,
                signedInAtLeastOnce: boolean,
                emailConfirmed?: boolean | null,
                confirmedTermsOfServiceVersions?:  {
                  __typename: "ConfirmedTermsOfServiceVersions",
                  termsOfServiceVersion?: string | null,
                  privacyPolicyVersion?: string | null,
                  tenantOwnTermsOfServiceVersion?: number | null,
                } | null,
                createdAt: string,
                updatedAt: string,
                tags?:  {
                  __typename: "ModelUserUserTagConnection",
                  nextToken?: string | null,
                } | null,
                statuses?:  {
                  __typename: "ModelUserStatusTableConnection",
                  nextToken?: string | null,
                } | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        userTag:  {
          __typename: "UserTag",
          id: string,
          color: string,
          text: string,
          tenantCode: string,
          createdAt: string,
          updatedAt: string,
          users?:  {
            __typename: "ModelUserUserTagConnection",
            items:  Array< {
              __typename: "UserUserTag",
              id: string,
              userId: string,
              userTagId: string,
              tenantCode: string,
              createdAt: string,
              updatedAt: string,
              user:  {
                __typename: "User",
                id: string,
                name: string,
                role: Role,
                extensionConfigJsonList: Array< string >,
                email?: string | null,
                code?: string | null,
                tenantCode: string,
                displaySettings?:  {
                  __typename: "DisplaySettings",
                  theme?: string | null,
                  color?: string | null,
                } | null,
                locale?: string | null,
                avatar?: string | null,
                enabled: boolean,
                signedInAtLeastOnce: boolean,
                emailConfirmed?: boolean | null,
                confirmedTermsOfServiceVersions?:  {
                  __typename: "ConfirmedTermsOfServiceVersions",
                  termsOfServiceVersion?: string | null,
                  privacyPolicyVersion?: string | null,
                  tenantOwnTermsOfServiceVersion?: number | null,
                } | null,
                createdAt: string,
                updatedAt: string,
                tags?:  {
                  __typename: "ModelUserUserTagConnection",
                  nextToken?: string | null,
                } | null,
                statuses?:  {
                  __typename: "ModelUserStatusTableConnection",
                  nextToken?: string | null,
                } | null,
              },
              userTag:  {
                __typename: "UserTag",
                id: string,
                color: string,
                text: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                users?:  {
                  __typename: "ModelUserUserTagConnection",
                  nextToken?: string | null,
                } | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateUserTagSubscription = {
  onUpdateUserTag?:  {
    __typename: "UserTag",
    id: string,
    color: string,
    text: string,
    tenantCode: string,
    createdAt: string,
    updatedAt: string,
    users?:  {
      __typename: "ModelUserUserTagConnection",
      items:  Array< {
        __typename: "UserUserTag",
        id: string,
        userId: string,
        userTagId: string,
        tenantCode: string,
        createdAt: string,
        updatedAt: string,
        user:  {
          __typename: "User",
          id: string,
          name: string,
          role: Role,
          extensionConfigJsonList: Array< string >,
          email?: string | null,
          code?: string | null,
          tenantCode: string,
          displaySettings?:  {
            __typename: "DisplaySettings",
            theme?: string | null,
            color?: string | null,
          } | null,
          locale?: string | null,
          avatar?: string | null,
          enabled: boolean,
          signedInAtLeastOnce: boolean,
          emailConfirmed?: boolean | null,
          confirmedTermsOfServiceVersions?:  {
            __typename: "ConfirmedTermsOfServiceVersions",
            termsOfServiceVersion?: string | null,
            privacyPolicyVersion?: string | null,
            tenantOwnTermsOfServiceVersion?: number | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          tags?:  {
            __typename: "ModelUserUserTagConnection",
            items:  Array< {
              __typename: "UserUserTag",
              id: string,
              userId: string,
              userTagId: string,
              tenantCode: string,
              createdAt: string,
              updatedAt: string,
              user:  {
                __typename: "User",
                id: string,
                name: string,
                role: Role,
                extensionConfigJsonList: Array< string >,
                email?: string | null,
                code?: string | null,
                tenantCode: string,
                displaySettings?:  {
                  __typename: "DisplaySettings",
                  theme?: string | null,
                  color?: string | null,
                } | null,
                locale?: string | null,
                avatar?: string | null,
                enabled: boolean,
                signedInAtLeastOnce: boolean,
                emailConfirmed?: boolean | null,
                confirmedTermsOfServiceVersions?:  {
                  __typename: "ConfirmedTermsOfServiceVersions",
                  termsOfServiceVersion?: string | null,
                  privacyPolicyVersion?: string | null,
                  tenantOwnTermsOfServiceVersion?: number | null,
                } | null,
                createdAt: string,
                updatedAt: string,
                tags?:  {
                  __typename: "ModelUserUserTagConnection",
                  nextToken?: string | null,
                } | null,
                statuses?:  {
                  __typename: "ModelUserStatusTableConnection",
                  nextToken?: string | null,
                } | null,
              },
              userTag:  {
                __typename: "UserTag",
                id: string,
                color: string,
                text: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                users?:  {
                  __typename: "ModelUserUserTagConnection",
                  nextToken?: string | null,
                } | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          statuses?:  {
            __typename: "ModelUserStatusTableConnection",
            items:  Array< {
              __typename: "UserStatusTable",
              id: string,
              tenantCode: string,
              status: UserStatus,
              userId: string,
              createdAt: string,
              updatedAt: string,
              user:  {
                __typename: "User",
                id: string,
                name: string,
                role: Role,
                extensionConfigJsonList: Array< string >,
                email?: string | null,
                code?: string | null,
                tenantCode: string,
                displaySettings?:  {
                  __typename: "DisplaySettings",
                  theme?: string | null,
                  color?: string | null,
                } | null,
                locale?: string | null,
                avatar?: string | null,
                enabled: boolean,
                signedInAtLeastOnce: boolean,
                emailConfirmed?: boolean | null,
                confirmedTermsOfServiceVersions?:  {
                  __typename: "ConfirmedTermsOfServiceVersions",
                  termsOfServiceVersion?: string | null,
                  privacyPolicyVersion?: string | null,
                  tenantOwnTermsOfServiceVersion?: number | null,
                } | null,
                createdAt: string,
                updatedAt: string,
                tags?:  {
                  __typename: "ModelUserUserTagConnection",
                  nextToken?: string | null,
                } | null,
                statuses?:  {
                  __typename: "ModelUserStatusTableConnection",
                  nextToken?: string | null,
                } | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        userTag:  {
          __typename: "UserTag",
          id: string,
          color: string,
          text: string,
          tenantCode: string,
          createdAt: string,
          updatedAt: string,
          users?:  {
            __typename: "ModelUserUserTagConnection",
            items:  Array< {
              __typename: "UserUserTag",
              id: string,
              userId: string,
              userTagId: string,
              tenantCode: string,
              createdAt: string,
              updatedAt: string,
              user:  {
                __typename: "User",
                id: string,
                name: string,
                role: Role,
                extensionConfigJsonList: Array< string >,
                email?: string | null,
                code?: string | null,
                tenantCode: string,
                displaySettings?:  {
                  __typename: "DisplaySettings",
                  theme?: string | null,
                  color?: string | null,
                } | null,
                locale?: string | null,
                avatar?: string | null,
                enabled: boolean,
                signedInAtLeastOnce: boolean,
                emailConfirmed?: boolean | null,
                confirmedTermsOfServiceVersions?:  {
                  __typename: "ConfirmedTermsOfServiceVersions",
                  termsOfServiceVersion?: string | null,
                  privacyPolicyVersion?: string | null,
                  tenantOwnTermsOfServiceVersion?: number | null,
                } | null,
                createdAt: string,
                updatedAt: string,
                tags?:  {
                  __typename: "ModelUserUserTagConnection",
                  nextToken?: string | null,
                } | null,
                statuses?:  {
                  __typename: "ModelUserStatusTableConnection",
                  nextToken?: string | null,
                } | null,
              },
              userTag:  {
                __typename: "UserTag",
                id: string,
                color: string,
                text: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                users?:  {
                  __typename: "ModelUserUserTagConnection",
                  nextToken?: string | null,
                } | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeleteUserTagSubscription = {
  onDeleteUserTag?:  {
    __typename: "UserTag",
    id: string,
    color: string,
    text: string,
    tenantCode: string,
    createdAt: string,
    updatedAt: string,
    users?:  {
      __typename: "ModelUserUserTagConnection",
      items:  Array< {
        __typename: "UserUserTag",
        id: string,
        userId: string,
        userTagId: string,
        tenantCode: string,
        createdAt: string,
        updatedAt: string,
        user:  {
          __typename: "User",
          id: string,
          name: string,
          role: Role,
          extensionConfigJsonList: Array< string >,
          email?: string | null,
          code?: string | null,
          tenantCode: string,
          displaySettings?:  {
            __typename: "DisplaySettings",
            theme?: string | null,
            color?: string | null,
          } | null,
          locale?: string | null,
          avatar?: string | null,
          enabled: boolean,
          signedInAtLeastOnce: boolean,
          emailConfirmed?: boolean | null,
          confirmedTermsOfServiceVersions?:  {
            __typename: "ConfirmedTermsOfServiceVersions",
            termsOfServiceVersion?: string | null,
            privacyPolicyVersion?: string | null,
            tenantOwnTermsOfServiceVersion?: number | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          tags?:  {
            __typename: "ModelUserUserTagConnection",
            items:  Array< {
              __typename: "UserUserTag",
              id: string,
              userId: string,
              userTagId: string,
              tenantCode: string,
              createdAt: string,
              updatedAt: string,
              user:  {
                __typename: "User",
                id: string,
                name: string,
                role: Role,
                extensionConfigJsonList: Array< string >,
                email?: string | null,
                code?: string | null,
                tenantCode: string,
                displaySettings?:  {
                  __typename: "DisplaySettings",
                  theme?: string | null,
                  color?: string | null,
                } | null,
                locale?: string | null,
                avatar?: string | null,
                enabled: boolean,
                signedInAtLeastOnce: boolean,
                emailConfirmed?: boolean | null,
                confirmedTermsOfServiceVersions?:  {
                  __typename: "ConfirmedTermsOfServiceVersions",
                  termsOfServiceVersion?: string | null,
                  privacyPolicyVersion?: string | null,
                  tenantOwnTermsOfServiceVersion?: number | null,
                } | null,
                createdAt: string,
                updatedAt: string,
                tags?:  {
                  __typename: "ModelUserUserTagConnection",
                  nextToken?: string | null,
                } | null,
                statuses?:  {
                  __typename: "ModelUserStatusTableConnection",
                  nextToken?: string | null,
                } | null,
              },
              userTag:  {
                __typename: "UserTag",
                id: string,
                color: string,
                text: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                users?:  {
                  __typename: "ModelUserUserTagConnection",
                  nextToken?: string | null,
                } | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          statuses?:  {
            __typename: "ModelUserStatusTableConnection",
            items:  Array< {
              __typename: "UserStatusTable",
              id: string,
              tenantCode: string,
              status: UserStatus,
              userId: string,
              createdAt: string,
              updatedAt: string,
              user:  {
                __typename: "User",
                id: string,
                name: string,
                role: Role,
                extensionConfigJsonList: Array< string >,
                email?: string | null,
                code?: string | null,
                tenantCode: string,
                displaySettings?:  {
                  __typename: "DisplaySettings",
                  theme?: string | null,
                  color?: string | null,
                } | null,
                locale?: string | null,
                avatar?: string | null,
                enabled: boolean,
                signedInAtLeastOnce: boolean,
                emailConfirmed?: boolean | null,
                confirmedTermsOfServiceVersions?:  {
                  __typename: "ConfirmedTermsOfServiceVersions",
                  termsOfServiceVersion?: string | null,
                  privacyPolicyVersion?: string | null,
                  tenantOwnTermsOfServiceVersion?: number | null,
                } | null,
                createdAt: string,
                updatedAt: string,
                tags?:  {
                  __typename: "ModelUserUserTagConnection",
                  nextToken?: string | null,
                } | null,
                statuses?:  {
                  __typename: "ModelUserStatusTableConnection",
                  nextToken?: string | null,
                } | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        userTag:  {
          __typename: "UserTag",
          id: string,
          color: string,
          text: string,
          tenantCode: string,
          createdAt: string,
          updatedAt: string,
          users?:  {
            __typename: "ModelUserUserTagConnection",
            items:  Array< {
              __typename: "UserUserTag",
              id: string,
              userId: string,
              userTagId: string,
              tenantCode: string,
              createdAt: string,
              updatedAt: string,
              user:  {
                __typename: "User",
                id: string,
                name: string,
                role: Role,
                extensionConfigJsonList: Array< string >,
                email?: string | null,
                code?: string | null,
                tenantCode: string,
                displaySettings?:  {
                  __typename: "DisplaySettings",
                  theme?: string | null,
                  color?: string | null,
                } | null,
                locale?: string | null,
                avatar?: string | null,
                enabled: boolean,
                signedInAtLeastOnce: boolean,
                emailConfirmed?: boolean | null,
                confirmedTermsOfServiceVersions?:  {
                  __typename: "ConfirmedTermsOfServiceVersions",
                  termsOfServiceVersion?: string | null,
                  privacyPolicyVersion?: string | null,
                  tenantOwnTermsOfServiceVersion?: number | null,
                } | null,
                createdAt: string,
                updatedAt: string,
                tags?:  {
                  __typename: "ModelUserUserTagConnection",
                  nextToken?: string | null,
                } | null,
                statuses?:  {
                  __typename: "ModelUserStatusTableConnection",
                  nextToken?: string | null,
                } | null,
              },
              userTag:  {
                __typename: "UserTag",
                id: string,
                color: string,
                text: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                users?:  {
                  __typename: "ModelUserUserTagConnection",
                  nextToken?: string | null,
                } | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreateUserUserTagSubscription = {
  onCreateUserUserTag?:  {
    __typename: "UserUserTag",
    id: string,
    userId: string,
    userTagId: string,
    tenantCode: string,
    createdAt: string,
    updatedAt: string,
    user:  {
      __typename: "User",
      id: string,
      name: string,
      role: Role,
      extensionConfigJsonList: Array< string >,
      email?: string | null,
      code?: string | null,
      tenantCode: string,
      displaySettings?:  {
        __typename: "DisplaySettings",
        theme?: string | null,
        color?: string | null,
      } | null,
      locale?: string | null,
      avatar?: string | null,
      enabled: boolean,
      signedInAtLeastOnce: boolean,
      emailConfirmed?: boolean | null,
      confirmedTermsOfServiceVersions?:  {
        __typename: "ConfirmedTermsOfServiceVersions",
        termsOfServiceVersion?: string | null,
        privacyPolicyVersion?: string | null,
        tenantOwnTermsOfServiceVersion?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      tags?:  {
        __typename: "ModelUserUserTagConnection",
        items:  Array< {
          __typename: "UserUserTag",
          id: string,
          userId: string,
          userTagId: string,
          tenantCode: string,
          createdAt: string,
          updatedAt: string,
          user:  {
            __typename: "User",
            id: string,
            name: string,
            role: Role,
            extensionConfigJsonList: Array< string >,
            email?: string | null,
            code?: string | null,
            tenantCode: string,
            displaySettings?:  {
              __typename: "DisplaySettings",
              theme?: string | null,
              color?: string | null,
            } | null,
            locale?: string | null,
            avatar?: string | null,
            enabled: boolean,
            signedInAtLeastOnce: boolean,
            emailConfirmed?: boolean | null,
            confirmedTermsOfServiceVersions?:  {
              __typename: "ConfirmedTermsOfServiceVersions",
              termsOfServiceVersion?: string | null,
              privacyPolicyVersion?: string | null,
              tenantOwnTermsOfServiceVersion?: number | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            tags?:  {
              __typename: "ModelUserUserTagConnection",
              items:  Array< {
                __typename: "UserUserTag",
                id: string,
                userId: string,
                userTagId: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
                userTag:  {
                  __typename: "UserTag",
                  id: string,
                  color: string,
                  text: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
            statuses?:  {
              __typename: "ModelUserStatusTableConnection",
              items:  Array< {
                __typename: "UserStatusTable",
                id: string,
                tenantCode: string,
                status: UserStatus,
                userId: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          userTag:  {
            __typename: "UserTag",
            id: string,
            color: string,
            text: string,
            tenantCode: string,
            createdAt: string,
            updatedAt: string,
            users?:  {
              __typename: "ModelUserUserTagConnection",
              items:  Array< {
                __typename: "UserUserTag",
                id: string,
                userId: string,
                userTagId: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
                userTag:  {
                  __typename: "UserTag",
                  id: string,
                  color: string,
                  text: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      statuses?:  {
        __typename: "ModelUserStatusTableConnection",
        items:  Array< {
          __typename: "UserStatusTable",
          id: string,
          tenantCode: string,
          status: UserStatus,
          userId: string,
          createdAt: string,
          updatedAt: string,
          user:  {
            __typename: "User",
            id: string,
            name: string,
            role: Role,
            extensionConfigJsonList: Array< string >,
            email?: string | null,
            code?: string | null,
            tenantCode: string,
            displaySettings?:  {
              __typename: "DisplaySettings",
              theme?: string | null,
              color?: string | null,
            } | null,
            locale?: string | null,
            avatar?: string | null,
            enabled: boolean,
            signedInAtLeastOnce: boolean,
            emailConfirmed?: boolean | null,
            confirmedTermsOfServiceVersions?:  {
              __typename: "ConfirmedTermsOfServiceVersions",
              termsOfServiceVersion?: string | null,
              privacyPolicyVersion?: string | null,
              tenantOwnTermsOfServiceVersion?: number | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            tags?:  {
              __typename: "ModelUserUserTagConnection",
              items:  Array< {
                __typename: "UserUserTag",
                id: string,
                userId: string,
                userTagId: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
                userTag:  {
                  __typename: "UserTag",
                  id: string,
                  color: string,
                  text: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
            statuses?:  {
              __typename: "ModelUserStatusTableConnection",
              items:  Array< {
                __typename: "UserStatusTable",
                id: string,
                tenantCode: string,
                status: UserStatus,
                userId: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    userTag:  {
      __typename: "UserTag",
      id: string,
      color: string,
      text: string,
      tenantCode: string,
      createdAt: string,
      updatedAt: string,
      users?:  {
        __typename: "ModelUserUserTagConnection",
        items:  Array< {
          __typename: "UserUserTag",
          id: string,
          userId: string,
          userTagId: string,
          tenantCode: string,
          createdAt: string,
          updatedAt: string,
          user:  {
            __typename: "User",
            id: string,
            name: string,
            role: Role,
            extensionConfigJsonList: Array< string >,
            email?: string | null,
            code?: string | null,
            tenantCode: string,
            displaySettings?:  {
              __typename: "DisplaySettings",
              theme?: string | null,
              color?: string | null,
            } | null,
            locale?: string | null,
            avatar?: string | null,
            enabled: boolean,
            signedInAtLeastOnce: boolean,
            emailConfirmed?: boolean | null,
            confirmedTermsOfServiceVersions?:  {
              __typename: "ConfirmedTermsOfServiceVersions",
              termsOfServiceVersion?: string | null,
              privacyPolicyVersion?: string | null,
              tenantOwnTermsOfServiceVersion?: number | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            tags?:  {
              __typename: "ModelUserUserTagConnection",
              items:  Array< {
                __typename: "UserUserTag",
                id: string,
                userId: string,
                userTagId: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
                userTag:  {
                  __typename: "UserTag",
                  id: string,
                  color: string,
                  text: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
            statuses?:  {
              __typename: "ModelUserStatusTableConnection",
              items:  Array< {
                __typename: "UserStatusTable",
                id: string,
                tenantCode: string,
                status: UserStatus,
                userId: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          userTag:  {
            __typename: "UserTag",
            id: string,
            color: string,
            text: string,
            tenantCode: string,
            createdAt: string,
            updatedAt: string,
            users?:  {
              __typename: "ModelUserUserTagConnection",
              items:  Array< {
                __typename: "UserUserTag",
                id: string,
                userId: string,
                userTagId: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
                userTag:  {
                  __typename: "UserTag",
                  id: string,
                  color: string,
                  text: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnUpdateUserUserTagSubscription = {
  onUpdateUserUserTag?:  {
    __typename: "UserUserTag",
    id: string,
    userId: string,
    userTagId: string,
    tenantCode: string,
    createdAt: string,
    updatedAt: string,
    user:  {
      __typename: "User",
      id: string,
      name: string,
      role: Role,
      extensionConfigJsonList: Array< string >,
      email?: string | null,
      code?: string | null,
      tenantCode: string,
      displaySettings?:  {
        __typename: "DisplaySettings",
        theme?: string | null,
        color?: string | null,
      } | null,
      locale?: string | null,
      avatar?: string | null,
      enabled: boolean,
      signedInAtLeastOnce: boolean,
      emailConfirmed?: boolean | null,
      confirmedTermsOfServiceVersions?:  {
        __typename: "ConfirmedTermsOfServiceVersions",
        termsOfServiceVersion?: string | null,
        privacyPolicyVersion?: string | null,
        tenantOwnTermsOfServiceVersion?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      tags?:  {
        __typename: "ModelUserUserTagConnection",
        items:  Array< {
          __typename: "UserUserTag",
          id: string,
          userId: string,
          userTagId: string,
          tenantCode: string,
          createdAt: string,
          updatedAt: string,
          user:  {
            __typename: "User",
            id: string,
            name: string,
            role: Role,
            extensionConfigJsonList: Array< string >,
            email?: string | null,
            code?: string | null,
            tenantCode: string,
            displaySettings?:  {
              __typename: "DisplaySettings",
              theme?: string | null,
              color?: string | null,
            } | null,
            locale?: string | null,
            avatar?: string | null,
            enabled: boolean,
            signedInAtLeastOnce: boolean,
            emailConfirmed?: boolean | null,
            confirmedTermsOfServiceVersions?:  {
              __typename: "ConfirmedTermsOfServiceVersions",
              termsOfServiceVersion?: string | null,
              privacyPolicyVersion?: string | null,
              tenantOwnTermsOfServiceVersion?: number | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            tags?:  {
              __typename: "ModelUserUserTagConnection",
              items:  Array< {
                __typename: "UserUserTag",
                id: string,
                userId: string,
                userTagId: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
                userTag:  {
                  __typename: "UserTag",
                  id: string,
                  color: string,
                  text: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
            statuses?:  {
              __typename: "ModelUserStatusTableConnection",
              items:  Array< {
                __typename: "UserStatusTable",
                id: string,
                tenantCode: string,
                status: UserStatus,
                userId: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          userTag:  {
            __typename: "UserTag",
            id: string,
            color: string,
            text: string,
            tenantCode: string,
            createdAt: string,
            updatedAt: string,
            users?:  {
              __typename: "ModelUserUserTagConnection",
              items:  Array< {
                __typename: "UserUserTag",
                id: string,
                userId: string,
                userTagId: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
                userTag:  {
                  __typename: "UserTag",
                  id: string,
                  color: string,
                  text: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      statuses?:  {
        __typename: "ModelUserStatusTableConnection",
        items:  Array< {
          __typename: "UserStatusTable",
          id: string,
          tenantCode: string,
          status: UserStatus,
          userId: string,
          createdAt: string,
          updatedAt: string,
          user:  {
            __typename: "User",
            id: string,
            name: string,
            role: Role,
            extensionConfigJsonList: Array< string >,
            email?: string | null,
            code?: string | null,
            tenantCode: string,
            displaySettings?:  {
              __typename: "DisplaySettings",
              theme?: string | null,
              color?: string | null,
            } | null,
            locale?: string | null,
            avatar?: string | null,
            enabled: boolean,
            signedInAtLeastOnce: boolean,
            emailConfirmed?: boolean | null,
            confirmedTermsOfServiceVersions?:  {
              __typename: "ConfirmedTermsOfServiceVersions",
              termsOfServiceVersion?: string | null,
              privacyPolicyVersion?: string | null,
              tenantOwnTermsOfServiceVersion?: number | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            tags?:  {
              __typename: "ModelUserUserTagConnection",
              items:  Array< {
                __typename: "UserUserTag",
                id: string,
                userId: string,
                userTagId: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
                userTag:  {
                  __typename: "UserTag",
                  id: string,
                  color: string,
                  text: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
            statuses?:  {
              __typename: "ModelUserStatusTableConnection",
              items:  Array< {
                __typename: "UserStatusTable",
                id: string,
                tenantCode: string,
                status: UserStatus,
                userId: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    userTag:  {
      __typename: "UserTag",
      id: string,
      color: string,
      text: string,
      tenantCode: string,
      createdAt: string,
      updatedAt: string,
      users?:  {
        __typename: "ModelUserUserTagConnection",
        items:  Array< {
          __typename: "UserUserTag",
          id: string,
          userId: string,
          userTagId: string,
          tenantCode: string,
          createdAt: string,
          updatedAt: string,
          user:  {
            __typename: "User",
            id: string,
            name: string,
            role: Role,
            extensionConfigJsonList: Array< string >,
            email?: string | null,
            code?: string | null,
            tenantCode: string,
            displaySettings?:  {
              __typename: "DisplaySettings",
              theme?: string | null,
              color?: string | null,
            } | null,
            locale?: string | null,
            avatar?: string | null,
            enabled: boolean,
            signedInAtLeastOnce: boolean,
            emailConfirmed?: boolean | null,
            confirmedTermsOfServiceVersions?:  {
              __typename: "ConfirmedTermsOfServiceVersions",
              termsOfServiceVersion?: string | null,
              privacyPolicyVersion?: string | null,
              tenantOwnTermsOfServiceVersion?: number | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            tags?:  {
              __typename: "ModelUserUserTagConnection",
              items:  Array< {
                __typename: "UserUserTag",
                id: string,
                userId: string,
                userTagId: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
                userTag:  {
                  __typename: "UserTag",
                  id: string,
                  color: string,
                  text: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
            statuses?:  {
              __typename: "ModelUserStatusTableConnection",
              items:  Array< {
                __typename: "UserStatusTable",
                id: string,
                tenantCode: string,
                status: UserStatus,
                userId: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          userTag:  {
            __typename: "UserTag",
            id: string,
            color: string,
            text: string,
            tenantCode: string,
            createdAt: string,
            updatedAt: string,
            users?:  {
              __typename: "ModelUserUserTagConnection",
              items:  Array< {
                __typename: "UserUserTag",
                id: string,
                userId: string,
                userTagId: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
                userTag:  {
                  __typename: "UserTag",
                  id: string,
                  color: string,
                  text: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnDeleteUserUserTagSubscription = {
  onDeleteUserUserTag?:  {
    __typename: "UserUserTag",
    id: string,
    userId: string,
    userTagId: string,
    tenantCode: string,
    createdAt: string,
    updatedAt: string,
    user:  {
      __typename: "User",
      id: string,
      name: string,
      role: Role,
      extensionConfigJsonList: Array< string >,
      email?: string | null,
      code?: string | null,
      tenantCode: string,
      displaySettings?:  {
        __typename: "DisplaySettings",
        theme?: string | null,
        color?: string | null,
      } | null,
      locale?: string | null,
      avatar?: string | null,
      enabled: boolean,
      signedInAtLeastOnce: boolean,
      emailConfirmed?: boolean | null,
      confirmedTermsOfServiceVersions?:  {
        __typename: "ConfirmedTermsOfServiceVersions",
        termsOfServiceVersion?: string | null,
        privacyPolicyVersion?: string | null,
        tenantOwnTermsOfServiceVersion?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      tags?:  {
        __typename: "ModelUserUserTagConnection",
        items:  Array< {
          __typename: "UserUserTag",
          id: string,
          userId: string,
          userTagId: string,
          tenantCode: string,
          createdAt: string,
          updatedAt: string,
          user:  {
            __typename: "User",
            id: string,
            name: string,
            role: Role,
            extensionConfigJsonList: Array< string >,
            email?: string | null,
            code?: string | null,
            tenantCode: string,
            displaySettings?:  {
              __typename: "DisplaySettings",
              theme?: string | null,
              color?: string | null,
            } | null,
            locale?: string | null,
            avatar?: string | null,
            enabled: boolean,
            signedInAtLeastOnce: boolean,
            emailConfirmed?: boolean | null,
            confirmedTermsOfServiceVersions?:  {
              __typename: "ConfirmedTermsOfServiceVersions",
              termsOfServiceVersion?: string | null,
              privacyPolicyVersion?: string | null,
              tenantOwnTermsOfServiceVersion?: number | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            tags?:  {
              __typename: "ModelUserUserTagConnection",
              items:  Array< {
                __typename: "UserUserTag",
                id: string,
                userId: string,
                userTagId: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
                userTag:  {
                  __typename: "UserTag",
                  id: string,
                  color: string,
                  text: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
            statuses?:  {
              __typename: "ModelUserStatusTableConnection",
              items:  Array< {
                __typename: "UserStatusTable",
                id: string,
                tenantCode: string,
                status: UserStatus,
                userId: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          userTag:  {
            __typename: "UserTag",
            id: string,
            color: string,
            text: string,
            tenantCode: string,
            createdAt: string,
            updatedAt: string,
            users?:  {
              __typename: "ModelUserUserTagConnection",
              items:  Array< {
                __typename: "UserUserTag",
                id: string,
                userId: string,
                userTagId: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
                userTag:  {
                  __typename: "UserTag",
                  id: string,
                  color: string,
                  text: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      statuses?:  {
        __typename: "ModelUserStatusTableConnection",
        items:  Array< {
          __typename: "UserStatusTable",
          id: string,
          tenantCode: string,
          status: UserStatus,
          userId: string,
          createdAt: string,
          updatedAt: string,
          user:  {
            __typename: "User",
            id: string,
            name: string,
            role: Role,
            extensionConfigJsonList: Array< string >,
            email?: string | null,
            code?: string | null,
            tenantCode: string,
            displaySettings?:  {
              __typename: "DisplaySettings",
              theme?: string | null,
              color?: string | null,
            } | null,
            locale?: string | null,
            avatar?: string | null,
            enabled: boolean,
            signedInAtLeastOnce: boolean,
            emailConfirmed?: boolean | null,
            confirmedTermsOfServiceVersions?:  {
              __typename: "ConfirmedTermsOfServiceVersions",
              termsOfServiceVersion?: string | null,
              privacyPolicyVersion?: string | null,
              tenantOwnTermsOfServiceVersion?: number | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            tags?:  {
              __typename: "ModelUserUserTagConnection",
              items:  Array< {
                __typename: "UserUserTag",
                id: string,
                userId: string,
                userTagId: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
                userTag:  {
                  __typename: "UserTag",
                  id: string,
                  color: string,
                  text: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
            statuses?:  {
              __typename: "ModelUserStatusTableConnection",
              items:  Array< {
                __typename: "UserStatusTable",
                id: string,
                tenantCode: string,
                status: UserStatus,
                userId: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    userTag:  {
      __typename: "UserTag",
      id: string,
      color: string,
      text: string,
      tenantCode: string,
      createdAt: string,
      updatedAt: string,
      users?:  {
        __typename: "ModelUserUserTagConnection",
        items:  Array< {
          __typename: "UserUserTag",
          id: string,
          userId: string,
          userTagId: string,
          tenantCode: string,
          createdAt: string,
          updatedAt: string,
          user:  {
            __typename: "User",
            id: string,
            name: string,
            role: Role,
            extensionConfigJsonList: Array< string >,
            email?: string | null,
            code?: string | null,
            tenantCode: string,
            displaySettings?:  {
              __typename: "DisplaySettings",
              theme?: string | null,
              color?: string | null,
            } | null,
            locale?: string | null,
            avatar?: string | null,
            enabled: boolean,
            signedInAtLeastOnce: boolean,
            emailConfirmed?: boolean | null,
            confirmedTermsOfServiceVersions?:  {
              __typename: "ConfirmedTermsOfServiceVersions",
              termsOfServiceVersion?: string | null,
              privacyPolicyVersion?: string | null,
              tenantOwnTermsOfServiceVersion?: number | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            tags?:  {
              __typename: "ModelUserUserTagConnection",
              items:  Array< {
                __typename: "UserUserTag",
                id: string,
                userId: string,
                userTagId: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
                userTag:  {
                  __typename: "UserTag",
                  id: string,
                  color: string,
                  text: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
            statuses?:  {
              __typename: "ModelUserStatusTableConnection",
              items:  Array< {
                __typename: "UserStatusTable",
                id: string,
                tenantCode: string,
                status: UserStatus,
                userId: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          userTag:  {
            __typename: "UserTag",
            id: string,
            color: string,
            text: string,
            tenantCode: string,
            createdAt: string,
            updatedAt: string,
            users?:  {
              __typename: "ModelUserUserTagConnection",
              items:  Array< {
                __typename: "UserUserTag",
                id: string,
                userId: string,
                userTagId: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
                userTag:  {
                  __typename: "UserTag",
                  id: string,
                  color: string,
                  text: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnCreateUserStatusTableByTenantCodeSubscriptionVariables = {
  tenantCode: string,
};

export type OnCreateUserStatusTableByTenantCodeSubscription = {
  onCreateUserStatusTableByTenantCode?:  {
    __typename: "UserStatusTable",
    id: string,
    tenantCode: string,
    status: UserStatus,
    userId: string,
    createdAt: string,
    updatedAt: string,
    user:  {
      __typename: "User",
      id: string,
      name: string,
      role: Role,
      extensionConfigJsonList: Array< string >,
      email?: string | null,
      code?: string | null,
      tenantCode: string,
      displaySettings?:  {
        __typename: "DisplaySettings",
        theme?: string | null,
        color?: string | null,
      } | null,
      locale?: string | null,
      avatar?: string | null,
      enabled: boolean,
      signedInAtLeastOnce: boolean,
      emailConfirmed?: boolean | null,
      confirmedTermsOfServiceVersions?:  {
        __typename: "ConfirmedTermsOfServiceVersions",
        termsOfServiceVersion?: string | null,
        privacyPolicyVersion?: string | null,
        tenantOwnTermsOfServiceVersion?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      tags?:  {
        __typename: "ModelUserUserTagConnection",
        items:  Array< {
          __typename: "UserUserTag",
          id: string,
          userId: string,
          userTagId: string,
          tenantCode: string,
          createdAt: string,
          updatedAt: string,
          user:  {
            __typename: "User",
            id: string,
            name: string,
            role: Role,
            extensionConfigJsonList: Array< string >,
            email?: string | null,
            code?: string | null,
            tenantCode: string,
            displaySettings?:  {
              __typename: "DisplaySettings",
              theme?: string | null,
              color?: string | null,
            } | null,
            locale?: string | null,
            avatar?: string | null,
            enabled: boolean,
            signedInAtLeastOnce: boolean,
            emailConfirmed?: boolean | null,
            confirmedTermsOfServiceVersions?:  {
              __typename: "ConfirmedTermsOfServiceVersions",
              termsOfServiceVersion?: string | null,
              privacyPolicyVersion?: string | null,
              tenantOwnTermsOfServiceVersion?: number | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            tags?:  {
              __typename: "ModelUserUserTagConnection",
              items:  Array< {
                __typename: "UserUserTag",
                id: string,
                userId: string,
                userTagId: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
                userTag:  {
                  __typename: "UserTag",
                  id: string,
                  color: string,
                  text: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
            statuses?:  {
              __typename: "ModelUserStatusTableConnection",
              items:  Array< {
                __typename: "UserStatusTable",
                id: string,
                tenantCode: string,
                status: UserStatus,
                userId: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          userTag:  {
            __typename: "UserTag",
            id: string,
            color: string,
            text: string,
            tenantCode: string,
            createdAt: string,
            updatedAt: string,
            users?:  {
              __typename: "ModelUserUserTagConnection",
              items:  Array< {
                __typename: "UserUserTag",
                id: string,
                userId: string,
                userTagId: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
                userTag:  {
                  __typename: "UserTag",
                  id: string,
                  color: string,
                  text: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      statuses?:  {
        __typename: "ModelUserStatusTableConnection",
        items:  Array< {
          __typename: "UserStatusTable",
          id: string,
          tenantCode: string,
          status: UserStatus,
          userId: string,
          createdAt: string,
          updatedAt: string,
          user:  {
            __typename: "User",
            id: string,
            name: string,
            role: Role,
            extensionConfigJsonList: Array< string >,
            email?: string | null,
            code?: string | null,
            tenantCode: string,
            displaySettings?:  {
              __typename: "DisplaySettings",
              theme?: string | null,
              color?: string | null,
            } | null,
            locale?: string | null,
            avatar?: string | null,
            enabled: boolean,
            signedInAtLeastOnce: boolean,
            emailConfirmed?: boolean | null,
            confirmedTermsOfServiceVersions?:  {
              __typename: "ConfirmedTermsOfServiceVersions",
              termsOfServiceVersion?: string | null,
              privacyPolicyVersion?: string | null,
              tenantOwnTermsOfServiceVersion?: number | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            tags?:  {
              __typename: "ModelUserUserTagConnection",
              items:  Array< {
                __typename: "UserUserTag",
                id: string,
                userId: string,
                userTagId: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
                userTag:  {
                  __typename: "UserTag",
                  id: string,
                  color: string,
                  text: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
            statuses?:  {
              __typename: "ModelUserStatusTableConnection",
              items:  Array< {
                __typename: "UserStatusTable",
                id: string,
                tenantCode: string,
                status: UserStatus,
                userId: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnUpdateUserStatusTableByTenantCodeSubscriptionVariables = {
  tenantCode: string,
};

export type OnUpdateUserStatusTableByTenantCodeSubscription = {
  onUpdateUserStatusTableByTenantCode?:  {
    __typename: "UserStatusTable",
    id: string,
    tenantCode: string,
    status: UserStatus,
    userId: string,
    createdAt: string,
    updatedAt: string,
    user:  {
      __typename: "User",
      id: string,
      name: string,
      role: Role,
      extensionConfigJsonList: Array< string >,
      email?: string | null,
      code?: string | null,
      tenantCode: string,
      displaySettings?:  {
        __typename: "DisplaySettings",
        theme?: string | null,
        color?: string | null,
      } | null,
      locale?: string | null,
      avatar?: string | null,
      enabled: boolean,
      signedInAtLeastOnce: boolean,
      emailConfirmed?: boolean | null,
      confirmedTermsOfServiceVersions?:  {
        __typename: "ConfirmedTermsOfServiceVersions",
        termsOfServiceVersion?: string | null,
        privacyPolicyVersion?: string | null,
        tenantOwnTermsOfServiceVersion?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      tags?:  {
        __typename: "ModelUserUserTagConnection",
        items:  Array< {
          __typename: "UserUserTag",
          id: string,
          userId: string,
          userTagId: string,
          tenantCode: string,
          createdAt: string,
          updatedAt: string,
          user:  {
            __typename: "User",
            id: string,
            name: string,
            role: Role,
            extensionConfigJsonList: Array< string >,
            email?: string | null,
            code?: string | null,
            tenantCode: string,
            displaySettings?:  {
              __typename: "DisplaySettings",
              theme?: string | null,
              color?: string | null,
            } | null,
            locale?: string | null,
            avatar?: string | null,
            enabled: boolean,
            signedInAtLeastOnce: boolean,
            emailConfirmed?: boolean | null,
            confirmedTermsOfServiceVersions?:  {
              __typename: "ConfirmedTermsOfServiceVersions",
              termsOfServiceVersion?: string | null,
              privacyPolicyVersion?: string | null,
              tenantOwnTermsOfServiceVersion?: number | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            tags?:  {
              __typename: "ModelUserUserTagConnection",
              items:  Array< {
                __typename: "UserUserTag",
                id: string,
                userId: string,
                userTagId: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
                userTag:  {
                  __typename: "UserTag",
                  id: string,
                  color: string,
                  text: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
            statuses?:  {
              __typename: "ModelUserStatusTableConnection",
              items:  Array< {
                __typename: "UserStatusTable",
                id: string,
                tenantCode: string,
                status: UserStatus,
                userId: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          userTag:  {
            __typename: "UserTag",
            id: string,
            color: string,
            text: string,
            tenantCode: string,
            createdAt: string,
            updatedAt: string,
            users?:  {
              __typename: "ModelUserUserTagConnection",
              items:  Array< {
                __typename: "UserUserTag",
                id: string,
                userId: string,
                userTagId: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
                userTag:  {
                  __typename: "UserTag",
                  id: string,
                  color: string,
                  text: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      statuses?:  {
        __typename: "ModelUserStatusTableConnection",
        items:  Array< {
          __typename: "UserStatusTable",
          id: string,
          tenantCode: string,
          status: UserStatus,
          userId: string,
          createdAt: string,
          updatedAt: string,
          user:  {
            __typename: "User",
            id: string,
            name: string,
            role: Role,
            extensionConfigJsonList: Array< string >,
            email?: string | null,
            code?: string | null,
            tenantCode: string,
            displaySettings?:  {
              __typename: "DisplaySettings",
              theme?: string | null,
              color?: string | null,
            } | null,
            locale?: string | null,
            avatar?: string | null,
            enabled: boolean,
            signedInAtLeastOnce: boolean,
            emailConfirmed?: boolean | null,
            confirmedTermsOfServiceVersions?:  {
              __typename: "ConfirmedTermsOfServiceVersions",
              termsOfServiceVersion?: string | null,
              privacyPolicyVersion?: string | null,
              tenantOwnTermsOfServiceVersion?: number | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            tags?:  {
              __typename: "ModelUserUserTagConnection",
              items:  Array< {
                __typename: "UserUserTag",
                id: string,
                userId: string,
                userTagId: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
                userTag:  {
                  __typename: "UserTag",
                  id: string,
                  color: string,
                  text: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
            statuses?:  {
              __typename: "ModelUserStatusTableConnection",
              items:  Array< {
                __typename: "UserStatusTable",
                id: string,
                tenantCode: string,
                status: UserStatus,
                userId: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnDeleteUserStatusTableByTenantCodeSubscriptionVariables = {
  tenantCode: string,
};

export type OnDeleteUserStatusTableByTenantCodeSubscription = {
  onDeleteUserStatusTableByTenantCode?:  {
    __typename: "UserStatusTable",
    id: string,
    tenantCode: string,
    status: UserStatus,
    userId: string,
    createdAt: string,
    updatedAt: string,
    user:  {
      __typename: "User",
      id: string,
      name: string,
      role: Role,
      extensionConfigJsonList: Array< string >,
      email?: string | null,
      code?: string | null,
      tenantCode: string,
      displaySettings?:  {
        __typename: "DisplaySettings",
        theme?: string | null,
        color?: string | null,
      } | null,
      locale?: string | null,
      avatar?: string | null,
      enabled: boolean,
      signedInAtLeastOnce: boolean,
      emailConfirmed?: boolean | null,
      confirmedTermsOfServiceVersions?:  {
        __typename: "ConfirmedTermsOfServiceVersions",
        termsOfServiceVersion?: string | null,
        privacyPolicyVersion?: string | null,
        tenantOwnTermsOfServiceVersion?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      tags?:  {
        __typename: "ModelUserUserTagConnection",
        items:  Array< {
          __typename: "UserUserTag",
          id: string,
          userId: string,
          userTagId: string,
          tenantCode: string,
          createdAt: string,
          updatedAt: string,
          user:  {
            __typename: "User",
            id: string,
            name: string,
            role: Role,
            extensionConfigJsonList: Array< string >,
            email?: string | null,
            code?: string | null,
            tenantCode: string,
            displaySettings?:  {
              __typename: "DisplaySettings",
              theme?: string | null,
              color?: string | null,
            } | null,
            locale?: string | null,
            avatar?: string | null,
            enabled: boolean,
            signedInAtLeastOnce: boolean,
            emailConfirmed?: boolean | null,
            confirmedTermsOfServiceVersions?:  {
              __typename: "ConfirmedTermsOfServiceVersions",
              termsOfServiceVersion?: string | null,
              privacyPolicyVersion?: string | null,
              tenantOwnTermsOfServiceVersion?: number | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            tags?:  {
              __typename: "ModelUserUserTagConnection",
              items:  Array< {
                __typename: "UserUserTag",
                id: string,
                userId: string,
                userTagId: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
                userTag:  {
                  __typename: "UserTag",
                  id: string,
                  color: string,
                  text: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
            statuses?:  {
              __typename: "ModelUserStatusTableConnection",
              items:  Array< {
                __typename: "UserStatusTable",
                id: string,
                tenantCode: string,
                status: UserStatus,
                userId: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          userTag:  {
            __typename: "UserTag",
            id: string,
            color: string,
            text: string,
            tenantCode: string,
            createdAt: string,
            updatedAt: string,
            users?:  {
              __typename: "ModelUserUserTagConnection",
              items:  Array< {
                __typename: "UserUserTag",
                id: string,
                userId: string,
                userTagId: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
                userTag:  {
                  __typename: "UserTag",
                  id: string,
                  color: string,
                  text: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      statuses?:  {
        __typename: "ModelUserStatusTableConnection",
        items:  Array< {
          __typename: "UserStatusTable",
          id: string,
          tenantCode: string,
          status: UserStatus,
          userId: string,
          createdAt: string,
          updatedAt: string,
          user:  {
            __typename: "User",
            id: string,
            name: string,
            role: Role,
            extensionConfigJsonList: Array< string >,
            email?: string | null,
            code?: string | null,
            tenantCode: string,
            displaySettings?:  {
              __typename: "DisplaySettings",
              theme?: string | null,
              color?: string | null,
            } | null,
            locale?: string | null,
            avatar?: string | null,
            enabled: boolean,
            signedInAtLeastOnce: boolean,
            emailConfirmed?: boolean | null,
            confirmedTermsOfServiceVersions?:  {
              __typename: "ConfirmedTermsOfServiceVersions",
              termsOfServiceVersion?: string | null,
              privacyPolicyVersion?: string | null,
              tenantOwnTermsOfServiceVersion?: number | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            tags?:  {
              __typename: "ModelUserUserTagConnection",
              items:  Array< {
                __typename: "UserUserTag",
                id: string,
                userId: string,
                userTagId: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
                userTag:  {
                  __typename: "UserTag",
                  id: string,
                  color: string,
                  text: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
            statuses?:  {
              __typename: "ModelUserStatusTableConnection",
              items:  Array< {
                __typename: "UserStatusTable",
                id: string,
                tenantCode: string,
                status: UserStatus,
                userId: string,
                createdAt: string,
                updatedAt: string,
                user:  {
                  __typename: "User",
                  id: string,
                  name: string,
                  role: Role,
                  extensionConfigJsonList: Array< string >,
                  email?: string | null,
                  code?: string | null,
                  tenantCode: string,
                  locale?: string | null,
                  avatar?: string | null,
                  enabled: boolean,
                  signedInAtLeastOnce: boolean,
                  emailConfirmed?: boolean | null,
                  createdAt: string,
                  updatedAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnCreateGroupStatusUserByGroupIdSubscriptionVariables = {
  groupId: string,
};

export type OnCreateGroupStatusUserByGroupIdSubscription = {
  onCreateGroupStatusUserByGroupId?:  {
    __typename: "GroupStatusUser",
    id: string,
    userId: string,
    groupId: string,
    handStatus: HandStatus,
    tenantCode: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateGroupStatusUserByGroupIdSubscriptionVariables = {
  groupId: string,
};

export type OnUpdateGroupStatusUserByGroupIdSubscription = {
  onUpdateGroupStatusUserByGroupId?:  {
    __typename: "GroupStatusUser",
    id: string,
    userId: string,
    groupId: string,
    handStatus: HandStatus,
    tenantCode: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteGroupStatusUserByGroupIdSubscriptionVariables = {
  groupId: string,
};

export type OnDeleteGroupStatusUserByGroupIdSubscription = {
  onDeleteGroupStatusUserByGroupId?:  {
    __typename: "GroupStatusUser",
    id: string,
    userId: string,
    groupId: string,
    handStatus: HandStatus,
    tenantCode: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateInternalTaskByIdSubscriptionVariables = {
  id: string,
};

export type OnUpdateInternalTaskByIdSubscription = {
  onUpdateInternalTaskById?:  {
    __typename: "InternalTask",
    id: string,
    type: string,
    createdAt?: string | null,
    createdBy: string,
    finishedAt?: string | null,
    status: InternalTaskStatus,
    taskVersion: number,
    payload: string,
    tenantCode: string,
    updatedAt: string,
  } | null,
};

export type OnUpdatePlaygroundByIdSubscriptionVariables = {
  id: string,
};

export type OnUpdatePlaygroundByIdSubscription = {
  onUpdatePlaygroundById?:  {
    __typename: "Playground",
    id: string,
    status: PlaygroundStatus,
    email: string,
    tenantCode: string,
    tenantName: string,
    userCode?: string | null,
    temporaryPassword?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateContentSubscription = {
  onCreateContent?:  {
    __typename: "Content",
    id: string,
    contentId: string,
    version: number,
    name: string,
    requiredTime: number,
    body:  {
      __typename: "ContentBody",
      type: ContentType,
      text?:  {
        __typename: "TextContent",
        body: string,
      } | null,
      exam?:  {
        __typename: "ExamContent",
        problems:  Array< {
          __typename: "Problem",
          index: number,
          body: string,
          commentary?: string | null,
          type: ProblemType,
          choice?:  {
            __typename: "ChoiceProblem",
            options:  Array< {
              __typename: "ChoiceProblemOption",
              index: number,
              text: string,
            } >,
            multiple: boolean,
            answer: Array< number >,
          } | null,
          headerId?: string | null,
        } >,
        problemHeaders?:  Array< {
          __typename: "ProblemHeader",
          id: string,
          body: string,
          createdAt: string,
        } > | null,
        passingStandard?: number | null,
      } | null,
    },
    workbook?:  {
      __typename: "Workbook",
      problems:  Array< {
        __typename: "Problem",
        index: number,
        body: string,
        commentary?: string | null,
        type: ProblemType,
        choice?:  {
          __typename: "ChoiceProblem",
          options:  Array< {
            __typename: "ChoiceProblemOption",
            index: number,
            text: string,
          } >,
          multiple: boolean,
          answer: Array< number >,
        } | null,
        headerId?: string | null,
      } >,
      problemHeaders?:  Array< {
        __typename: "ProblemHeader",
        id: string,
        body: string,
        createdAt: string,
      } > | null,
    } | null,
    courseId: string,
    courseVersion: number,
    tenantCode: string,
    latest: boolean,
    versionDescription?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateContentSubscription = {
  onUpdateContent?:  {
    __typename: "Content",
    id: string,
    contentId: string,
    version: number,
    name: string,
    requiredTime: number,
    body:  {
      __typename: "ContentBody",
      type: ContentType,
      text?:  {
        __typename: "TextContent",
        body: string,
      } | null,
      exam?:  {
        __typename: "ExamContent",
        problems:  Array< {
          __typename: "Problem",
          index: number,
          body: string,
          commentary?: string | null,
          type: ProblemType,
          choice?:  {
            __typename: "ChoiceProblem",
            options:  Array< {
              __typename: "ChoiceProblemOption",
              index: number,
              text: string,
            } >,
            multiple: boolean,
            answer: Array< number >,
          } | null,
          headerId?: string | null,
        } >,
        problemHeaders?:  Array< {
          __typename: "ProblemHeader",
          id: string,
          body: string,
          createdAt: string,
        } > | null,
        passingStandard?: number | null,
      } | null,
    },
    workbook?:  {
      __typename: "Workbook",
      problems:  Array< {
        __typename: "Problem",
        index: number,
        body: string,
        commentary?: string | null,
        type: ProblemType,
        choice?:  {
          __typename: "ChoiceProblem",
          options:  Array< {
            __typename: "ChoiceProblemOption",
            index: number,
            text: string,
          } >,
          multiple: boolean,
          answer: Array< number >,
        } | null,
        headerId?: string | null,
      } >,
      problemHeaders?:  Array< {
        __typename: "ProblemHeader",
        id: string,
        body: string,
        createdAt: string,
      } > | null,
    } | null,
    courseId: string,
    courseVersion: number,
    tenantCode: string,
    latest: boolean,
    versionDescription?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteContentSubscription = {
  onDeleteContent?:  {
    __typename: "Content",
    id: string,
    contentId: string,
    version: number,
    name: string,
    requiredTime: number,
    body:  {
      __typename: "ContentBody",
      type: ContentType,
      text?:  {
        __typename: "TextContent",
        body: string,
      } | null,
      exam?:  {
        __typename: "ExamContent",
        problems:  Array< {
          __typename: "Problem",
          index: number,
          body: string,
          commentary?: string | null,
          type: ProblemType,
          choice?:  {
            __typename: "ChoiceProblem",
            options:  Array< {
              __typename: "ChoiceProblemOption",
              index: number,
              text: string,
            } >,
            multiple: boolean,
            answer: Array< number >,
          } | null,
          headerId?: string | null,
        } >,
        problemHeaders?:  Array< {
          __typename: "ProblemHeader",
          id: string,
          body: string,
          createdAt: string,
        } > | null,
        passingStandard?: number | null,
      } | null,
    },
    workbook?:  {
      __typename: "Workbook",
      problems:  Array< {
        __typename: "Problem",
        index: number,
        body: string,
        commentary?: string | null,
        type: ProblemType,
        choice?:  {
          __typename: "ChoiceProblem",
          options:  Array< {
            __typename: "ChoiceProblemOption",
            index: number,
            text: string,
          } >,
          multiple: boolean,
          answer: Array< number >,
        } | null,
        headerId?: string | null,
      } >,
      problemHeaders?:  Array< {
        __typename: "ProblemHeader",
        id: string,
        body: string,
        createdAt: string,
      } > | null,
    } | null,
    courseId: string,
    courseVersion: number,
    tenantCode: string,
    latest: boolean,
    versionDescription?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateCourseSubscription = {
  onCreateCourse?:  {
    __typename: "Course",
    id: string,
    version: number,
    name: string,
    contents:  Array< {
      __typename: "CourseContent",
      id: string,
      type: ContentType,
      name: string,
      requiredTime: number,
    } >,
    description?: string | null,
    tenantCode: string,
    color?: string | null,
    image?: string | null,
    fontColorOnImage?: CourseFontColorOnImage | null,
    confirmedBy?: string | null,
    createdAt?: string | null,
    versionCreatedBy?: string | null,
    versionCreatedAt?: string | null,
    contentLastUpdatedBy?: string | null,
    contentLastUpdatedAt?: string | null,
    updatedAt: string,
  } | null,
};

export type OnUpdateCourseSubscription = {
  onUpdateCourse?:  {
    __typename: "Course",
    id: string,
    version: number,
    name: string,
    contents:  Array< {
      __typename: "CourseContent",
      id: string,
      type: ContentType,
      name: string,
      requiredTime: number,
    } >,
    description?: string | null,
    tenantCode: string,
    color?: string | null,
    image?: string | null,
    fontColorOnImage?: CourseFontColorOnImage | null,
    confirmedBy?: string | null,
    createdAt?: string | null,
    versionCreatedBy?: string | null,
    versionCreatedAt?: string | null,
    contentLastUpdatedBy?: string | null,
    contentLastUpdatedAt?: string | null,
    updatedAt: string,
  } | null,
};

export type OnDeleteCourseSubscription = {
  onDeleteCourse?:  {
    __typename: "Course",
    id: string,
    version: number,
    name: string,
    contents:  Array< {
      __typename: "CourseContent",
      id: string,
      type: ContentType,
      name: string,
      requiredTime: number,
    } >,
    description?: string | null,
    tenantCode: string,
    color?: string | null,
    image?: string | null,
    fontColorOnImage?: CourseFontColorOnImage | null,
    confirmedBy?: string | null,
    createdAt?: string | null,
    versionCreatedBy?: string | null,
    versionCreatedAt?: string | null,
    contentLastUpdatedBy?: string | null,
    contentLastUpdatedAt?: string | null,
    updatedAt: string,
  } | null,
};

export type OnCreateEditingConfirmedContentSubscription = {
  onCreateEditingConfirmedContent?:  {
    __typename: "EditingConfirmedContent",
    id: string,
    version: number,
    name: string,
    requiredTime: number,
    body:  {
      __typename: "ContentBody",
      type: ContentType,
      text?:  {
        __typename: "TextContent",
        body: string,
      } | null,
      exam?:  {
        __typename: "ExamContent",
        problems:  Array< {
          __typename: "Problem",
          index: number,
          body: string,
          commentary?: string | null,
          type: ProblemType,
          choice?:  {
            __typename: "ChoiceProblem",
            options:  Array< {
              __typename: "ChoiceProblemOption",
              index: number,
              text: string,
            } >,
            multiple: boolean,
            answer: Array< number >,
          } | null,
          headerId?: string | null,
        } >,
        problemHeaders?:  Array< {
          __typename: "ProblemHeader",
          id: string,
          body: string,
          createdAt: string,
        } > | null,
        passingStandard?: number | null,
      } | null,
    },
    workbook?:  {
      __typename: "Workbook",
      problems:  Array< {
        __typename: "Problem",
        index: number,
        body: string,
        commentary?: string | null,
        type: ProblemType,
        choice?:  {
          __typename: "ChoiceProblem",
          options:  Array< {
            __typename: "ChoiceProblemOption",
            index: number,
            text: string,
          } >,
          multiple: boolean,
          answer: Array< number >,
        } | null,
        headerId?: string | null,
      } >,
      problemHeaders?:  Array< {
        __typename: "ProblemHeader",
        id: string,
        body: string,
        createdAt: string,
      } > | null,
    } | null,
    courseId: string,
    courseVersion: number,
    status: EditingStatus,
    tenantCode: string,
    createdBy: string,
    updatedBy: string,
    dataVersion: number,
    versionDescription?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateEditingConfirmedContentSubscription = {
  onUpdateEditingConfirmedContent?:  {
    __typename: "EditingConfirmedContent",
    id: string,
    version: number,
    name: string,
    requiredTime: number,
    body:  {
      __typename: "ContentBody",
      type: ContentType,
      text?:  {
        __typename: "TextContent",
        body: string,
      } | null,
      exam?:  {
        __typename: "ExamContent",
        problems:  Array< {
          __typename: "Problem",
          index: number,
          body: string,
          commentary?: string | null,
          type: ProblemType,
          choice?:  {
            __typename: "ChoiceProblem",
            options:  Array< {
              __typename: "ChoiceProblemOption",
              index: number,
              text: string,
            } >,
            multiple: boolean,
            answer: Array< number >,
          } | null,
          headerId?: string | null,
        } >,
        problemHeaders?:  Array< {
          __typename: "ProblemHeader",
          id: string,
          body: string,
          createdAt: string,
        } > | null,
        passingStandard?: number | null,
      } | null,
    },
    workbook?:  {
      __typename: "Workbook",
      problems:  Array< {
        __typename: "Problem",
        index: number,
        body: string,
        commentary?: string | null,
        type: ProblemType,
        choice?:  {
          __typename: "ChoiceProblem",
          options:  Array< {
            __typename: "ChoiceProblemOption",
            index: number,
            text: string,
          } >,
          multiple: boolean,
          answer: Array< number >,
        } | null,
        headerId?: string | null,
      } >,
      problemHeaders?:  Array< {
        __typename: "ProblemHeader",
        id: string,
        body: string,
        createdAt: string,
      } > | null,
    } | null,
    courseId: string,
    courseVersion: number,
    status: EditingStatus,
    tenantCode: string,
    createdBy: string,
    updatedBy: string,
    dataVersion: number,
    versionDescription?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteEditingConfirmedContentSubscription = {
  onDeleteEditingConfirmedContent?:  {
    __typename: "EditingConfirmedContent",
    id: string,
    version: number,
    name: string,
    requiredTime: number,
    body:  {
      __typename: "ContentBody",
      type: ContentType,
      text?:  {
        __typename: "TextContent",
        body: string,
      } | null,
      exam?:  {
        __typename: "ExamContent",
        problems:  Array< {
          __typename: "Problem",
          index: number,
          body: string,
          commentary?: string | null,
          type: ProblemType,
          choice?:  {
            __typename: "ChoiceProblem",
            options:  Array< {
              __typename: "ChoiceProblemOption",
              index: number,
              text: string,
            } >,
            multiple: boolean,
            answer: Array< number >,
          } | null,
          headerId?: string | null,
        } >,
        problemHeaders?:  Array< {
          __typename: "ProblemHeader",
          id: string,
          body: string,
          createdAt: string,
        } > | null,
        passingStandard?: number | null,
      } | null,
    },
    workbook?:  {
      __typename: "Workbook",
      problems:  Array< {
        __typename: "Problem",
        index: number,
        body: string,
        commentary?: string | null,
        type: ProblemType,
        choice?:  {
          __typename: "ChoiceProblem",
          options:  Array< {
            __typename: "ChoiceProblemOption",
            index: number,
            text: string,
          } >,
          multiple: boolean,
          answer: Array< number >,
        } | null,
        headerId?: string | null,
      } >,
      problemHeaders?:  Array< {
        __typename: "ProblemHeader",
        id: string,
        body: string,
        createdAt: string,
      } > | null,
    } | null,
    courseId: string,
    courseVersion: number,
    status: EditingStatus,
    tenantCode: string,
    createdBy: string,
    updatedBy: string,
    dataVersion: number,
    versionDescription?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateExamResultSubscription = {
  onCreateExamResult?:  {
    __typename: "ExamResult",
    id: string,
    groupId: string,
    courseId: string,
    contentId: string,
    contentVersion?: number | null,
    userId: string,
    start: string,
    end?: string | null,
    score?: number | null,
    problemCount: number,
    tenantCode: string,
    answers?: string | null,
    visibilityLevel?: ExamResultVisibilityLevel | null,
    groupExamId?: string | null,
    times?: number | null,
    passingStandard?: number | null,
    version?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateExamResultSubscription = {
  onUpdateExamResult?:  {
    __typename: "ExamResult",
    id: string,
    groupId: string,
    courseId: string,
    contentId: string,
    contentVersion?: number | null,
    userId: string,
    start: string,
    end?: string | null,
    score?: number | null,
    problemCount: number,
    tenantCode: string,
    answers?: string | null,
    visibilityLevel?: ExamResultVisibilityLevel | null,
    groupExamId?: string | null,
    times?: number | null,
    passingStandard?: number | null,
    version?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteExamResultSubscription = {
  onDeleteExamResult?:  {
    __typename: "ExamResult",
    id: string,
    groupId: string,
    courseId: string,
    contentId: string,
    contentVersion?: number | null,
    userId: string,
    start: string,
    end?: string | null,
    score?: number | null,
    problemCount: number,
    tenantCode: string,
    answers?: string | null,
    visibilityLevel?: ExamResultVisibilityLevel | null,
    groupExamId?: string | null,
    times?: number | null,
    passingStandard?: number | null,
    version?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateGroupTrainingSubscription = {
  onCreateGroupTraining?:  {
    __typename: "GroupTraining",
    id: string,
    groupId: string,
    tenantCode: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateGroupTrainingSubscription = {
  onUpdateGroupTraining?:  {
    __typename: "GroupTraining",
    id: string,
    groupId: string,
    tenantCode: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteGroupTrainingSubscription = {
  onDeleteGroupTraining?:  {
    __typename: "GroupTraining",
    id: string,
    groupId: string,
    tenantCode: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateGroupTrainingCourseSubscription = {
  onCreateGroupTrainingCourse?:  {
    __typename: "GroupTrainingCourse",
    id: string,
    groupTrainingId: string,
    courseId: string,
    courseVersion: number,
    courseName: string,
    contents:  Array< {
      __typename: "GroupTrainingCourseContent",
      id: string,
      name: string,
      requiredTime: number,
      type: ContentType,
      recommendedDateTime?: string | null,
      open: boolean,
      version: number,
    } >,
    groupId: string,
    tenantCode: string,
    color?: string | null,
    image?: string | null,
    fontColorOnImage?: CourseFontColorOnImage | null,
    displayName: string,
    index?: number | null,
    createdAt: string,
    updatedAt: string,
    groupExams?:  {
      __typename: "ModelGroupExamConnection",
      items:  Array< {
        __typename: "GroupExam",
        id: string,
        groupId: string,
        contentId: string,
        content:  {
          __typename: "GroupExamContent",
          id: string,
          version?: number | null,
          name: string,
          timeLimit?: number | null,
          requiredTime?: number | null,
          indexInCourse?: number | null,
          passingStandard?: number | null,
        },
        problemsJson: string,
        courseId: string,
        course:  {
          __typename: "GroupExamCourse",
          id: string,
          version: number,
          name: string,
          color?: string | null,
          image?: string | null,
          fontColorOnImage?: CourseFontColorOnImage | null,
        },
        scheduledStart: string,
        scheduledFinish?: string | null,
        createdBy?: string | null,
        finishedAt?: string | null,
        visibilityLevel?: ExamResultVisibilityLevel | null,
        tenantCode: string,
        times: number,
        groupTrainingCourseId: string,
        problemHeaders?:  Array< {
          __typename: "ProblemHeader",
          id: string,
          body: string,
          createdAt: string,
        } > | null,
        timeLimit?: number | null,
        passingStandard?: number | null,
        userIdsToBeTested?: Array< string > | null,
        createdAt: string,
        updatedAt: string,
        userExams?:  {
          __typename: "ModelUserExamConnection",
          items:  Array< {
            __typename: "UserExam",
            id: string,
            userId: string,
            status: UserExamStatus,
            tenantCode: string,
            groupExamId: string,
            groupId: string,
            startedAt?: string | null,
            finishedAt?: string | null,
            createdAt: string,
            updatedAt: string,
            groupExam?:  {
              __typename: "GroupExam",
              id: string,
              groupId: string,
              contentId: string,
              content:  {
                __typename: "GroupExamContent",
                id: string,
                version?: number | null,
                name: string,
                timeLimit?: number | null,
                requiredTime?: number | null,
                indexInCourse?: number | null,
                passingStandard?: number | null,
              },
              problemsJson: string,
              courseId: string,
              course:  {
                __typename: "GroupExamCourse",
                id: string,
                version: number,
                name: string,
                color?: string | null,
                image?: string | null,
                fontColorOnImage?: CourseFontColorOnImage | null,
              },
              scheduledStart: string,
              scheduledFinish?: string | null,
              createdBy?: string | null,
              finishedAt?: string | null,
              visibilityLevel?: ExamResultVisibilityLevel | null,
              tenantCode: string,
              times: number,
              groupTrainingCourseId: string,
              problemHeaders?:  Array< {
                __typename: "ProblemHeader",
                id: string,
                body: string,
                createdAt: string,
              } > | null,
              timeLimit?: number | null,
              passingStandard?: number | null,
              userIdsToBeTested?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
              userExams?:  {
                __typename: "ModelUserExamConnection",
                items:  Array< {
                  __typename: "UserExam",
                  id: string,
                  userId: string,
                  status: UserExamStatus,
                  tenantCode: string,
                  groupExamId: string,
                  groupId: string,
                  startedAt?: string | null,
                  finishedAt?: string | null,
                  createdAt: string,
                  updatedAt: string,
                } | null >,
                nextToken?: string | null,
              } | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateGroupTrainingCourseSubscription = {
  onUpdateGroupTrainingCourse?:  {
    __typename: "GroupTrainingCourse",
    id: string,
    groupTrainingId: string,
    courseId: string,
    courseVersion: number,
    courseName: string,
    contents:  Array< {
      __typename: "GroupTrainingCourseContent",
      id: string,
      name: string,
      requiredTime: number,
      type: ContentType,
      recommendedDateTime?: string | null,
      open: boolean,
      version: number,
    } >,
    groupId: string,
    tenantCode: string,
    color?: string | null,
    image?: string | null,
    fontColorOnImage?: CourseFontColorOnImage | null,
    displayName: string,
    index?: number | null,
    createdAt: string,
    updatedAt: string,
    groupExams?:  {
      __typename: "ModelGroupExamConnection",
      items:  Array< {
        __typename: "GroupExam",
        id: string,
        groupId: string,
        contentId: string,
        content:  {
          __typename: "GroupExamContent",
          id: string,
          version?: number | null,
          name: string,
          timeLimit?: number | null,
          requiredTime?: number | null,
          indexInCourse?: number | null,
          passingStandard?: number | null,
        },
        problemsJson: string,
        courseId: string,
        course:  {
          __typename: "GroupExamCourse",
          id: string,
          version: number,
          name: string,
          color?: string | null,
          image?: string | null,
          fontColorOnImage?: CourseFontColorOnImage | null,
        },
        scheduledStart: string,
        scheduledFinish?: string | null,
        createdBy?: string | null,
        finishedAt?: string | null,
        visibilityLevel?: ExamResultVisibilityLevel | null,
        tenantCode: string,
        times: number,
        groupTrainingCourseId: string,
        problemHeaders?:  Array< {
          __typename: "ProblemHeader",
          id: string,
          body: string,
          createdAt: string,
        } > | null,
        timeLimit?: number | null,
        passingStandard?: number | null,
        userIdsToBeTested?: Array< string > | null,
        createdAt: string,
        updatedAt: string,
        userExams?:  {
          __typename: "ModelUserExamConnection",
          items:  Array< {
            __typename: "UserExam",
            id: string,
            userId: string,
            status: UserExamStatus,
            tenantCode: string,
            groupExamId: string,
            groupId: string,
            startedAt?: string | null,
            finishedAt?: string | null,
            createdAt: string,
            updatedAt: string,
            groupExam?:  {
              __typename: "GroupExam",
              id: string,
              groupId: string,
              contentId: string,
              content:  {
                __typename: "GroupExamContent",
                id: string,
                version?: number | null,
                name: string,
                timeLimit?: number | null,
                requiredTime?: number | null,
                indexInCourse?: number | null,
                passingStandard?: number | null,
              },
              problemsJson: string,
              courseId: string,
              course:  {
                __typename: "GroupExamCourse",
                id: string,
                version: number,
                name: string,
                color?: string | null,
                image?: string | null,
                fontColorOnImage?: CourseFontColorOnImage | null,
              },
              scheduledStart: string,
              scheduledFinish?: string | null,
              createdBy?: string | null,
              finishedAt?: string | null,
              visibilityLevel?: ExamResultVisibilityLevel | null,
              tenantCode: string,
              times: number,
              groupTrainingCourseId: string,
              problemHeaders?:  Array< {
                __typename: "ProblemHeader",
                id: string,
                body: string,
                createdAt: string,
              } > | null,
              timeLimit?: number | null,
              passingStandard?: number | null,
              userIdsToBeTested?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
              userExams?:  {
                __typename: "ModelUserExamConnection",
                items:  Array< {
                  __typename: "UserExam",
                  id: string,
                  userId: string,
                  status: UserExamStatus,
                  tenantCode: string,
                  groupExamId: string,
                  groupId: string,
                  startedAt?: string | null,
                  finishedAt?: string | null,
                  createdAt: string,
                  updatedAt: string,
                } | null >,
                nextToken?: string | null,
              } | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeleteGroupTrainingCourseSubscription = {
  onDeleteGroupTrainingCourse?:  {
    __typename: "GroupTrainingCourse",
    id: string,
    groupTrainingId: string,
    courseId: string,
    courseVersion: number,
    courseName: string,
    contents:  Array< {
      __typename: "GroupTrainingCourseContent",
      id: string,
      name: string,
      requiredTime: number,
      type: ContentType,
      recommendedDateTime?: string | null,
      open: boolean,
      version: number,
    } >,
    groupId: string,
    tenantCode: string,
    color?: string | null,
    image?: string | null,
    fontColorOnImage?: CourseFontColorOnImage | null,
    displayName: string,
    index?: number | null,
    createdAt: string,
    updatedAt: string,
    groupExams?:  {
      __typename: "ModelGroupExamConnection",
      items:  Array< {
        __typename: "GroupExam",
        id: string,
        groupId: string,
        contentId: string,
        content:  {
          __typename: "GroupExamContent",
          id: string,
          version?: number | null,
          name: string,
          timeLimit?: number | null,
          requiredTime?: number | null,
          indexInCourse?: number | null,
          passingStandard?: number | null,
        },
        problemsJson: string,
        courseId: string,
        course:  {
          __typename: "GroupExamCourse",
          id: string,
          version: number,
          name: string,
          color?: string | null,
          image?: string | null,
          fontColorOnImage?: CourseFontColorOnImage | null,
        },
        scheduledStart: string,
        scheduledFinish?: string | null,
        createdBy?: string | null,
        finishedAt?: string | null,
        visibilityLevel?: ExamResultVisibilityLevel | null,
        tenantCode: string,
        times: number,
        groupTrainingCourseId: string,
        problemHeaders?:  Array< {
          __typename: "ProblemHeader",
          id: string,
          body: string,
          createdAt: string,
        } > | null,
        timeLimit?: number | null,
        passingStandard?: number | null,
        userIdsToBeTested?: Array< string > | null,
        createdAt: string,
        updatedAt: string,
        userExams?:  {
          __typename: "ModelUserExamConnection",
          items:  Array< {
            __typename: "UserExam",
            id: string,
            userId: string,
            status: UserExamStatus,
            tenantCode: string,
            groupExamId: string,
            groupId: string,
            startedAt?: string | null,
            finishedAt?: string | null,
            createdAt: string,
            updatedAt: string,
            groupExam?:  {
              __typename: "GroupExam",
              id: string,
              groupId: string,
              contentId: string,
              content:  {
                __typename: "GroupExamContent",
                id: string,
                version?: number | null,
                name: string,
                timeLimit?: number | null,
                requiredTime?: number | null,
                indexInCourse?: number | null,
                passingStandard?: number | null,
              },
              problemsJson: string,
              courseId: string,
              course:  {
                __typename: "GroupExamCourse",
                id: string,
                version: number,
                name: string,
                color?: string | null,
                image?: string | null,
                fontColorOnImage?: CourseFontColorOnImage | null,
              },
              scheduledStart: string,
              scheduledFinish?: string | null,
              createdBy?: string | null,
              finishedAt?: string | null,
              visibilityLevel?: ExamResultVisibilityLevel | null,
              tenantCode: string,
              times: number,
              groupTrainingCourseId: string,
              problemHeaders?:  Array< {
                __typename: "ProblemHeader",
                id: string,
                body: string,
                createdAt: string,
              } > | null,
              timeLimit?: number | null,
              passingStandard?: number | null,
              userIdsToBeTested?: Array< string > | null,
              createdAt: string,
              updatedAt: string,
              userExams?:  {
                __typename: "ModelUserExamConnection",
                items:  Array< {
                  __typename: "UserExam",
                  id: string,
                  userId: string,
                  status: UserExamStatus,
                  tenantCode: string,
                  groupExamId: string,
                  groupId: string,
                  startedAt?: string | null,
                  finishedAt?: string | null,
                  createdAt: string,
                  updatedAt: string,
                } | null >,
                nextToken?: string | null,
              } | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreateScheduleScheduleTagSubscription = {
  onCreateScheduleScheduleTag?:  {
    __typename: "ScheduleScheduleTag",
    id: string,
    scheduleId: string,
    scheduleTagId: string,
    scheduleTag:  {
      __typename: "ScheduleTag",
      id: string,
      color: string,
      text: string,
      groupId: string,
      tenantCode: string,
      createdAt: string,
      updatedAt: string,
      schedules?:  {
        __typename: "ModelScheduleScheduleTagConnection",
        items:  Array< {
          __typename: "ScheduleScheduleTag",
          id: string,
          scheduleId: string,
          scheduleTagId: string,
          scheduleTag:  {
            __typename: "ScheduleTag",
            id: string,
            color: string,
            text: string,
            groupId: string,
            tenantCode: string,
            createdAt: string,
            updatedAt: string,
            schedules?:  {
              __typename: "ModelScheduleScheduleTagConnection",
              items:  Array< {
                __typename: "ScheduleScheduleTag",
                id: string,
                scheduleId: string,
                scheduleTagId: string,
                scheduleTag:  {
                  __typename: "ScheduleTag",
                  id: string,
                  color: string,
                  text: string,
                  groupId: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                schedule:  {
                  __typename: "Schedule",
                  id: string,
                  groupId: string,
                  start: string,
                  end?: string | null,
                  title: string,
                  body?: string | null,
                  tenantCode: string,
                  createdBy?: string | null,
                  updatedBy?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          tenantCode: string,
          createdAt: string,
          updatedAt: string,
          schedule:  {
            __typename: "Schedule",
            id: string,
            groupId: string,
            start: string,
            end?: string | null,
            title: string,
            body?: string | null,
            tenantCode: string,
            createdBy?: string | null,
            updatedBy?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            tags?:  {
              __typename: "ModelScheduleScheduleTagConnection",
              items:  Array< {
                __typename: "ScheduleScheduleTag",
                id: string,
                scheduleId: string,
                scheduleTagId: string,
                scheduleTag:  {
                  __typename: "ScheduleTag",
                  id: string,
                  color: string,
                  text: string,
                  groupId: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                schedule:  {
                  __typename: "Schedule",
                  id: string,
                  groupId: string,
                  start: string,
                  end?: string | null,
                  title: string,
                  body?: string | null,
                  tenantCode: string,
                  createdBy?: string | null,
                  updatedBy?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    tenantCode: string,
    createdAt: string,
    updatedAt: string,
    schedule:  {
      __typename: "Schedule",
      id: string,
      groupId: string,
      start: string,
      end?: string | null,
      title: string,
      body?: string | null,
      tenantCode: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      tags?:  {
        __typename: "ModelScheduleScheduleTagConnection",
        items:  Array< {
          __typename: "ScheduleScheduleTag",
          id: string,
          scheduleId: string,
          scheduleTagId: string,
          scheduleTag:  {
            __typename: "ScheduleTag",
            id: string,
            color: string,
            text: string,
            groupId: string,
            tenantCode: string,
            createdAt: string,
            updatedAt: string,
            schedules?:  {
              __typename: "ModelScheduleScheduleTagConnection",
              items:  Array< {
                __typename: "ScheduleScheduleTag",
                id: string,
                scheduleId: string,
                scheduleTagId: string,
                scheduleTag:  {
                  __typename: "ScheduleTag",
                  id: string,
                  color: string,
                  text: string,
                  groupId: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                schedule:  {
                  __typename: "Schedule",
                  id: string,
                  groupId: string,
                  start: string,
                  end?: string | null,
                  title: string,
                  body?: string | null,
                  tenantCode: string,
                  createdBy?: string | null,
                  updatedBy?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          tenantCode: string,
          createdAt: string,
          updatedAt: string,
          schedule:  {
            __typename: "Schedule",
            id: string,
            groupId: string,
            start: string,
            end?: string | null,
            title: string,
            body?: string | null,
            tenantCode: string,
            createdBy?: string | null,
            updatedBy?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            tags?:  {
              __typename: "ModelScheduleScheduleTagConnection",
              items:  Array< {
                __typename: "ScheduleScheduleTag",
                id: string,
                scheduleId: string,
                scheduleTagId: string,
                scheduleTag:  {
                  __typename: "ScheduleTag",
                  id: string,
                  color: string,
                  text: string,
                  groupId: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                schedule:  {
                  __typename: "Schedule",
                  id: string,
                  groupId: string,
                  start: string,
                  end?: string | null,
                  title: string,
                  body?: string | null,
                  tenantCode: string,
                  createdBy?: string | null,
                  updatedBy?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnUpdateScheduleScheduleTagSubscription = {
  onUpdateScheduleScheduleTag?:  {
    __typename: "ScheduleScheduleTag",
    id: string,
    scheduleId: string,
    scheduleTagId: string,
    scheduleTag:  {
      __typename: "ScheduleTag",
      id: string,
      color: string,
      text: string,
      groupId: string,
      tenantCode: string,
      createdAt: string,
      updatedAt: string,
      schedules?:  {
        __typename: "ModelScheduleScheduleTagConnection",
        items:  Array< {
          __typename: "ScheduleScheduleTag",
          id: string,
          scheduleId: string,
          scheduleTagId: string,
          scheduleTag:  {
            __typename: "ScheduleTag",
            id: string,
            color: string,
            text: string,
            groupId: string,
            tenantCode: string,
            createdAt: string,
            updatedAt: string,
            schedules?:  {
              __typename: "ModelScheduleScheduleTagConnection",
              items:  Array< {
                __typename: "ScheduleScheduleTag",
                id: string,
                scheduleId: string,
                scheduleTagId: string,
                scheduleTag:  {
                  __typename: "ScheduleTag",
                  id: string,
                  color: string,
                  text: string,
                  groupId: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                schedule:  {
                  __typename: "Schedule",
                  id: string,
                  groupId: string,
                  start: string,
                  end?: string | null,
                  title: string,
                  body?: string | null,
                  tenantCode: string,
                  createdBy?: string | null,
                  updatedBy?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          tenantCode: string,
          createdAt: string,
          updatedAt: string,
          schedule:  {
            __typename: "Schedule",
            id: string,
            groupId: string,
            start: string,
            end?: string | null,
            title: string,
            body?: string | null,
            tenantCode: string,
            createdBy?: string | null,
            updatedBy?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            tags?:  {
              __typename: "ModelScheduleScheduleTagConnection",
              items:  Array< {
                __typename: "ScheduleScheduleTag",
                id: string,
                scheduleId: string,
                scheduleTagId: string,
                scheduleTag:  {
                  __typename: "ScheduleTag",
                  id: string,
                  color: string,
                  text: string,
                  groupId: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                schedule:  {
                  __typename: "Schedule",
                  id: string,
                  groupId: string,
                  start: string,
                  end?: string | null,
                  title: string,
                  body?: string | null,
                  tenantCode: string,
                  createdBy?: string | null,
                  updatedBy?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    tenantCode: string,
    createdAt: string,
    updatedAt: string,
    schedule:  {
      __typename: "Schedule",
      id: string,
      groupId: string,
      start: string,
      end?: string | null,
      title: string,
      body?: string | null,
      tenantCode: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      tags?:  {
        __typename: "ModelScheduleScheduleTagConnection",
        items:  Array< {
          __typename: "ScheduleScheduleTag",
          id: string,
          scheduleId: string,
          scheduleTagId: string,
          scheduleTag:  {
            __typename: "ScheduleTag",
            id: string,
            color: string,
            text: string,
            groupId: string,
            tenantCode: string,
            createdAt: string,
            updatedAt: string,
            schedules?:  {
              __typename: "ModelScheduleScheduleTagConnection",
              items:  Array< {
                __typename: "ScheduleScheduleTag",
                id: string,
                scheduleId: string,
                scheduleTagId: string,
                scheduleTag:  {
                  __typename: "ScheduleTag",
                  id: string,
                  color: string,
                  text: string,
                  groupId: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                schedule:  {
                  __typename: "Schedule",
                  id: string,
                  groupId: string,
                  start: string,
                  end?: string | null,
                  title: string,
                  body?: string | null,
                  tenantCode: string,
                  createdBy?: string | null,
                  updatedBy?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          tenantCode: string,
          createdAt: string,
          updatedAt: string,
          schedule:  {
            __typename: "Schedule",
            id: string,
            groupId: string,
            start: string,
            end?: string | null,
            title: string,
            body?: string | null,
            tenantCode: string,
            createdBy?: string | null,
            updatedBy?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            tags?:  {
              __typename: "ModelScheduleScheduleTagConnection",
              items:  Array< {
                __typename: "ScheduleScheduleTag",
                id: string,
                scheduleId: string,
                scheduleTagId: string,
                scheduleTag:  {
                  __typename: "ScheduleTag",
                  id: string,
                  color: string,
                  text: string,
                  groupId: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                schedule:  {
                  __typename: "Schedule",
                  id: string,
                  groupId: string,
                  start: string,
                  end?: string | null,
                  title: string,
                  body?: string | null,
                  tenantCode: string,
                  createdBy?: string | null,
                  updatedBy?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnDeleteScheduleScheduleTagSubscription = {
  onDeleteScheduleScheduleTag?:  {
    __typename: "ScheduleScheduleTag",
    id: string,
    scheduleId: string,
    scheduleTagId: string,
    scheduleTag:  {
      __typename: "ScheduleTag",
      id: string,
      color: string,
      text: string,
      groupId: string,
      tenantCode: string,
      createdAt: string,
      updatedAt: string,
      schedules?:  {
        __typename: "ModelScheduleScheduleTagConnection",
        items:  Array< {
          __typename: "ScheduleScheduleTag",
          id: string,
          scheduleId: string,
          scheduleTagId: string,
          scheduleTag:  {
            __typename: "ScheduleTag",
            id: string,
            color: string,
            text: string,
            groupId: string,
            tenantCode: string,
            createdAt: string,
            updatedAt: string,
            schedules?:  {
              __typename: "ModelScheduleScheduleTagConnection",
              items:  Array< {
                __typename: "ScheduleScheduleTag",
                id: string,
                scheduleId: string,
                scheduleTagId: string,
                scheduleTag:  {
                  __typename: "ScheduleTag",
                  id: string,
                  color: string,
                  text: string,
                  groupId: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                schedule:  {
                  __typename: "Schedule",
                  id: string,
                  groupId: string,
                  start: string,
                  end?: string | null,
                  title: string,
                  body?: string | null,
                  tenantCode: string,
                  createdBy?: string | null,
                  updatedBy?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          tenantCode: string,
          createdAt: string,
          updatedAt: string,
          schedule:  {
            __typename: "Schedule",
            id: string,
            groupId: string,
            start: string,
            end?: string | null,
            title: string,
            body?: string | null,
            tenantCode: string,
            createdBy?: string | null,
            updatedBy?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            tags?:  {
              __typename: "ModelScheduleScheduleTagConnection",
              items:  Array< {
                __typename: "ScheduleScheduleTag",
                id: string,
                scheduleId: string,
                scheduleTagId: string,
                scheduleTag:  {
                  __typename: "ScheduleTag",
                  id: string,
                  color: string,
                  text: string,
                  groupId: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                schedule:  {
                  __typename: "Schedule",
                  id: string,
                  groupId: string,
                  start: string,
                  end?: string | null,
                  title: string,
                  body?: string | null,
                  tenantCode: string,
                  createdBy?: string | null,
                  updatedBy?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    tenantCode: string,
    createdAt: string,
    updatedAt: string,
    schedule:  {
      __typename: "Schedule",
      id: string,
      groupId: string,
      start: string,
      end?: string | null,
      title: string,
      body?: string | null,
      tenantCode: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      tags?:  {
        __typename: "ModelScheduleScheduleTagConnection",
        items:  Array< {
          __typename: "ScheduleScheduleTag",
          id: string,
          scheduleId: string,
          scheduleTagId: string,
          scheduleTag:  {
            __typename: "ScheduleTag",
            id: string,
            color: string,
            text: string,
            groupId: string,
            tenantCode: string,
            createdAt: string,
            updatedAt: string,
            schedules?:  {
              __typename: "ModelScheduleScheduleTagConnection",
              items:  Array< {
                __typename: "ScheduleScheduleTag",
                id: string,
                scheduleId: string,
                scheduleTagId: string,
                scheduleTag:  {
                  __typename: "ScheduleTag",
                  id: string,
                  color: string,
                  text: string,
                  groupId: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                schedule:  {
                  __typename: "Schedule",
                  id: string,
                  groupId: string,
                  start: string,
                  end?: string | null,
                  title: string,
                  body?: string | null,
                  tenantCode: string,
                  createdBy?: string | null,
                  updatedBy?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          tenantCode: string,
          createdAt: string,
          updatedAt: string,
          schedule:  {
            __typename: "Schedule",
            id: string,
            groupId: string,
            start: string,
            end?: string | null,
            title: string,
            body?: string | null,
            tenantCode: string,
            createdBy?: string | null,
            updatedBy?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            tags?:  {
              __typename: "ModelScheduleScheduleTagConnection",
              items:  Array< {
                __typename: "ScheduleScheduleTag",
                id: string,
                scheduleId: string,
                scheduleTagId: string,
                scheduleTag:  {
                  __typename: "ScheduleTag",
                  id: string,
                  color: string,
                  text: string,
                  groupId: string,
                  tenantCode: string,
                  createdAt: string,
                  updatedAt: string,
                },
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                schedule:  {
                  __typename: "Schedule",
                  id: string,
                  groupId: string,
                  start: string,
                  end?: string | null,
                  title: string,
                  body?: string | null,
                  tenantCode: string,
                  createdBy?: string | null,
                  updatedBy?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnCreateScheduleSubscription = {
  onCreateSchedule?:  {
    __typename: "Schedule",
    id: string,
    groupId: string,
    start: string,
    end?: string | null,
    title: string,
    body?: string | null,
    tenantCode: string,
    createdBy?: string | null,
    updatedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    tags?:  {
      __typename: "ModelScheduleScheduleTagConnection",
      items:  Array< {
        __typename: "ScheduleScheduleTag",
        id: string,
        scheduleId: string,
        scheduleTagId: string,
        scheduleTag:  {
          __typename: "ScheduleTag",
          id: string,
          color: string,
          text: string,
          groupId: string,
          tenantCode: string,
          createdAt: string,
          updatedAt: string,
          schedules?:  {
            __typename: "ModelScheduleScheduleTagConnection",
            items:  Array< {
              __typename: "ScheduleScheduleTag",
              id: string,
              scheduleId: string,
              scheduleTagId: string,
              scheduleTag:  {
                __typename: "ScheduleTag",
                id: string,
                color: string,
                text: string,
                groupId: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                schedules?:  {
                  __typename: "ModelScheduleScheduleTagConnection",
                  nextToken?: string | null,
                } | null,
              },
              tenantCode: string,
              createdAt: string,
              updatedAt: string,
              schedule:  {
                __typename: "Schedule",
                id: string,
                groupId: string,
                start: string,
                end?: string | null,
                title: string,
                body?: string | null,
                tenantCode: string,
                createdBy?: string | null,
                updatedBy?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                tags?:  {
                  __typename: "ModelScheduleScheduleTagConnection",
                  nextToken?: string | null,
                } | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        tenantCode: string,
        createdAt: string,
        updatedAt: string,
        schedule:  {
          __typename: "Schedule",
          id: string,
          groupId: string,
          start: string,
          end?: string | null,
          title: string,
          body?: string | null,
          tenantCode: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          tags?:  {
            __typename: "ModelScheduleScheduleTagConnection",
            items:  Array< {
              __typename: "ScheduleScheduleTag",
              id: string,
              scheduleId: string,
              scheduleTagId: string,
              scheduleTag:  {
                __typename: "ScheduleTag",
                id: string,
                color: string,
                text: string,
                groupId: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                schedules?:  {
                  __typename: "ModelScheduleScheduleTagConnection",
                  nextToken?: string | null,
                } | null,
              },
              tenantCode: string,
              createdAt: string,
              updatedAt: string,
              schedule:  {
                __typename: "Schedule",
                id: string,
                groupId: string,
                start: string,
                end?: string | null,
                title: string,
                body?: string | null,
                tenantCode: string,
                createdBy?: string | null,
                updatedBy?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                tags?:  {
                  __typename: "ModelScheduleScheduleTagConnection",
                  nextToken?: string | null,
                } | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateScheduleSubscription = {
  onUpdateSchedule?:  {
    __typename: "Schedule",
    id: string,
    groupId: string,
    start: string,
    end?: string | null,
    title: string,
    body?: string | null,
    tenantCode: string,
    createdBy?: string | null,
    updatedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    tags?:  {
      __typename: "ModelScheduleScheduleTagConnection",
      items:  Array< {
        __typename: "ScheduleScheduleTag",
        id: string,
        scheduleId: string,
        scheduleTagId: string,
        scheduleTag:  {
          __typename: "ScheduleTag",
          id: string,
          color: string,
          text: string,
          groupId: string,
          tenantCode: string,
          createdAt: string,
          updatedAt: string,
          schedules?:  {
            __typename: "ModelScheduleScheduleTagConnection",
            items:  Array< {
              __typename: "ScheduleScheduleTag",
              id: string,
              scheduleId: string,
              scheduleTagId: string,
              scheduleTag:  {
                __typename: "ScheduleTag",
                id: string,
                color: string,
                text: string,
                groupId: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                schedules?:  {
                  __typename: "ModelScheduleScheduleTagConnection",
                  nextToken?: string | null,
                } | null,
              },
              tenantCode: string,
              createdAt: string,
              updatedAt: string,
              schedule:  {
                __typename: "Schedule",
                id: string,
                groupId: string,
                start: string,
                end?: string | null,
                title: string,
                body?: string | null,
                tenantCode: string,
                createdBy?: string | null,
                updatedBy?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                tags?:  {
                  __typename: "ModelScheduleScheduleTagConnection",
                  nextToken?: string | null,
                } | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        tenantCode: string,
        createdAt: string,
        updatedAt: string,
        schedule:  {
          __typename: "Schedule",
          id: string,
          groupId: string,
          start: string,
          end?: string | null,
          title: string,
          body?: string | null,
          tenantCode: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          tags?:  {
            __typename: "ModelScheduleScheduleTagConnection",
            items:  Array< {
              __typename: "ScheduleScheduleTag",
              id: string,
              scheduleId: string,
              scheduleTagId: string,
              scheduleTag:  {
                __typename: "ScheduleTag",
                id: string,
                color: string,
                text: string,
                groupId: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                schedules?:  {
                  __typename: "ModelScheduleScheduleTagConnection",
                  nextToken?: string | null,
                } | null,
              },
              tenantCode: string,
              createdAt: string,
              updatedAt: string,
              schedule:  {
                __typename: "Schedule",
                id: string,
                groupId: string,
                start: string,
                end?: string | null,
                title: string,
                body?: string | null,
                tenantCode: string,
                createdBy?: string | null,
                updatedBy?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                tags?:  {
                  __typename: "ModelScheduleScheduleTagConnection",
                  nextToken?: string | null,
                } | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeleteScheduleSubscription = {
  onDeleteSchedule?:  {
    __typename: "Schedule",
    id: string,
    groupId: string,
    start: string,
    end?: string | null,
    title: string,
    body?: string | null,
    tenantCode: string,
    createdBy?: string | null,
    updatedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    tags?:  {
      __typename: "ModelScheduleScheduleTagConnection",
      items:  Array< {
        __typename: "ScheduleScheduleTag",
        id: string,
        scheduleId: string,
        scheduleTagId: string,
        scheduleTag:  {
          __typename: "ScheduleTag",
          id: string,
          color: string,
          text: string,
          groupId: string,
          tenantCode: string,
          createdAt: string,
          updatedAt: string,
          schedules?:  {
            __typename: "ModelScheduleScheduleTagConnection",
            items:  Array< {
              __typename: "ScheduleScheduleTag",
              id: string,
              scheduleId: string,
              scheduleTagId: string,
              scheduleTag:  {
                __typename: "ScheduleTag",
                id: string,
                color: string,
                text: string,
                groupId: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                schedules?:  {
                  __typename: "ModelScheduleScheduleTagConnection",
                  nextToken?: string | null,
                } | null,
              },
              tenantCode: string,
              createdAt: string,
              updatedAt: string,
              schedule:  {
                __typename: "Schedule",
                id: string,
                groupId: string,
                start: string,
                end?: string | null,
                title: string,
                body?: string | null,
                tenantCode: string,
                createdBy?: string | null,
                updatedBy?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                tags?:  {
                  __typename: "ModelScheduleScheduleTagConnection",
                  nextToken?: string | null,
                } | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        tenantCode: string,
        createdAt: string,
        updatedAt: string,
        schedule:  {
          __typename: "Schedule",
          id: string,
          groupId: string,
          start: string,
          end?: string | null,
          title: string,
          body?: string | null,
          tenantCode: string,
          createdBy?: string | null,
          updatedBy?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          tags?:  {
            __typename: "ModelScheduleScheduleTagConnection",
            items:  Array< {
              __typename: "ScheduleScheduleTag",
              id: string,
              scheduleId: string,
              scheduleTagId: string,
              scheduleTag:  {
                __typename: "ScheduleTag",
                id: string,
                color: string,
                text: string,
                groupId: string,
                tenantCode: string,
                createdAt: string,
                updatedAt: string,
                schedules?:  {
                  __typename: "ModelScheduleScheduleTagConnection",
                  nextToken?: string | null,
                } | null,
              },
              tenantCode: string,
              createdAt: string,
              updatedAt: string,
              schedule:  {
                __typename: "Schedule",
                id: string,
                groupId: string,
                start: string,
                end?: string | null,
                title: string,
                body?: string | null,
                tenantCode: string,
                createdBy?: string | null,
                updatedBy?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                tags?:  {
                  __typename: "ModelScheduleScheduleTagConnection",
                  nextToken?: string | null,
                } | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreateCommentSubscription = {
  onCreateComment?:  {
    __typename: "Comment",
    id: string,
    questionId: string,
    body: string,
    createdBy: string,
    createdAt?: string | null,
    editedBy?: string | null,
    editedAt?: string | null,
    tenantCode: string,
    groupId: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateCommentSubscription = {
  onUpdateComment?:  {
    __typename: "Comment",
    id: string,
    questionId: string,
    body: string,
    createdBy: string,
    createdAt?: string | null,
    editedBy?: string | null,
    editedAt?: string | null,
    tenantCode: string,
    groupId: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteCommentSubscription = {
  onDeleteComment?:  {
    __typename: "Comment",
    id: string,
    questionId: string,
    body: string,
    createdBy: string,
    createdAt?: string | null,
    editedBy?: string | null,
    editedAt?: string | null,
    tenantCode: string,
    groupId: string,
    updatedAt: string,
  } | null,
};

export type OnCreateQuestionSubscription = {
  onCreateQuestion?:  {
    __typename: "Question",
    id: string,
    createdBy: string,
    resolved?: boolean | null,
    resolvedAt?: string | null,
    resolvedBy?: string | null,
    groupId: string,
    title: string,
    referTo?:  {
      __typename: "ContentReference",
      contentId: string,
      contentVersion?: number | null,
      options?:  {
        __typename: "ContentReferenceOptions",
        text?:  {
          __typename: "TextContentReferenceOptions",
          selectionJson: string,
        } | null,
        workbook?:  {
          __typename: "WorkbookReferenceOptions",
          problemIndex: number,
          selectionJson?: string | null,
        } | null,
      } | null,
    } | null,
    tenantCode: string,
    createdAt?: string | null,
    assignees?: Array< string > | null,
    updatedAt: string,
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        id: string,
        questionId: string,
        body: string,
        createdBy: string,
        createdAt?: string | null,
        editedBy?: string | null,
        editedAt?: string | null,
        tenantCode: string,
        groupId: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateQuestionSubscription = {
  onUpdateQuestion?:  {
    __typename: "Question",
    id: string,
    createdBy: string,
    resolved?: boolean | null,
    resolvedAt?: string | null,
    resolvedBy?: string | null,
    groupId: string,
    title: string,
    referTo?:  {
      __typename: "ContentReference",
      contentId: string,
      contentVersion?: number | null,
      options?:  {
        __typename: "ContentReferenceOptions",
        text?:  {
          __typename: "TextContentReferenceOptions",
          selectionJson: string,
        } | null,
        workbook?:  {
          __typename: "WorkbookReferenceOptions",
          problemIndex: number,
          selectionJson?: string | null,
        } | null,
      } | null,
    } | null,
    tenantCode: string,
    createdAt?: string | null,
    assignees?: Array< string > | null,
    updatedAt: string,
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        id: string,
        questionId: string,
        body: string,
        createdBy: string,
        createdAt?: string | null,
        editedBy?: string | null,
        editedAt?: string | null,
        tenantCode: string,
        groupId: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeleteQuestionSubscription = {
  onDeleteQuestion?:  {
    __typename: "Question",
    id: string,
    createdBy: string,
    resolved?: boolean | null,
    resolvedAt?: string | null,
    resolvedBy?: string | null,
    groupId: string,
    title: string,
    referTo?:  {
      __typename: "ContentReference",
      contentId: string,
      contentVersion?: number | null,
      options?:  {
        __typename: "ContentReferenceOptions",
        text?:  {
          __typename: "TextContentReferenceOptions",
          selectionJson: string,
        } | null,
        workbook?:  {
          __typename: "WorkbookReferenceOptions",
          problemIndex: number,
          selectionJson?: string | null,
        } | null,
      } | null,
    } | null,
    tenantCode: string,
    createdAt?: string | null,
    assignees?: Array< string > | null,
    updatedAt: string,
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        id: string,
        questionId: string,
        body: string,
        createdBy: string,
        createdAt?: string | null,
        editedBy?: string | null,
        editedAt?: string | null,
        tenantCode: string,
        groupId: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreateContentLearningSubscription = {
  onCreateContentLearning?:  {
    __typename: "ContentLearning",
    groupId: string,
    courseId: string,
    contentId: string,
    contentType: ContentType,
    userId: string,
    status: LearningStatus,
    usageTime: number,
    lastStartedAt?: string | null,
    dataVersion: number,
    tenantCode: string,
    completedAt?: string | null,
    completedUsageTime?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateContentLearningSubscription = {
  onUpdateContentLearning?:  {
    __typename: "ContentLearning",
    groupId: string,
    courseId: string,
    contentId: string,
    contentType: ContentType,
    userId: string,
    status: LearningStatus,
    usageTime: number,
    lastStartedAt?: string | null,
    dataVersion: number,
    tenantCode: string,
    completedAt?: string | null,
    completedUsageTime?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteContentLearningSubscription = {
  onDeleteContentLearning?:  {
    __typename: "ContentLearning",
    groupId: string,
    courseId: string,
    contentId: string,
    contentType: ContentType,
    userId: string,
    status: LearningStatus,
    usageTime: number,
    lastStartedAt?: string | null,
    dataVersion: number,
    tenantCode: string,
    completedAt?: string | null,
    completedUsageTime?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};
