import moment from 'moment';

import { CreateNotificationInput, DeleteNotificationInput, UpdateNotificationInput } from '@/API';
import {
  AppContextProvider,
  NotificationData,
  NotificationEntity,
  NotificationEntityImpl,
  NotificationId,
  NotificationRepository,
  notificationTypeOf,
  UserId,
} from '@/base/domains';
import { Optional } from '@/base/types';
import { createNotification, deleteNotification, updateNotification } from '@/graphql/mutations';
import { getNotification, notificationsByUserId } from '@/graphql/queries';
import { graphql, graphqlQuery } from '@/utils/AmplifyUtils';
import { assertIsDefined } from '@/utils/Asserts';
import { isDefined, requiredNonNull } from '@/utils/TsUtils';

type AmplifyNotification = {
  id: NotificationId;
  type: string;
  payloadJson: string;
  userId: string;
  read: boolean;
  createdAt: string;
};

function toNotificationEntity(notification: AmplifyNotification): NotificationEntity {
  return new NotificationEntityImpl({
    id: notification.id,
    type: notificationTypeOf(notification.type),
    payload: JSON.parse(notification.payloadJson),
    userId: notification.userId,
    read: notification.read ? 'read' : 'unread',
    createdAt: moment(notification.createdAt),
  });
}

export class AmplifyNotificationRepository implements NotificationRepository {
  private appContextProvider: AppContextProvider;

  constructor(appContextProvider: AppContextProvider) {
    this.appContextProvider = appContextProvider;
  }

  async save(entity: NotificationData | NotificationEntity): Promise<NotificationEntity<{}>> {
    const tenantCode = requiredNonNull(
      this.appContextProvider.get().tenantCode,
      'appContext.tenantCode'
    );
    if (isDefined(entity.id)) {
      const input: UpdateNotificationInput = {
        id: entity.id,
        type: entity.type.description,
        payloadJson: JSON.stringify(entity.payload),
        userId: entity.userId,
        read: entity.read === 'read',
        tenantCode,
      };
      const res = await graphql<{ updateNotification: AmplifyNotification }>(updateNotification, {
        input,
      });
      assertIsDefined(res);
      return toNotificationEntity(res.updateNotification);
    }
    assertIsDefined(entity.type.description);
    const input: CreateNotificationInput = {
      type: entity.type.description,
      payloadJson: JSON.stringify(entity.payload),
      userId: entity.userId,
      read: entity.read === 'read',
      tenantCode,
    };
    const res = await graphql<{ createNotification: AmplifyNotification }>(createNotification, {
      input,
    });
    assertIsDefined(res);
    return toNotificationEntity(res.createNotification);
  }

  async findById(id: NotificationId): Promise<Optional<NotificationEntity<{}>>> {
    const res = await graphql<{ getNotification: AmplifyNotification }>(getNotification, { id });
    return res?.getNotification ? toNotificationEntity(res.getNotification) : undefined;
  }

  async remove(id: NotificationId): Promise<void> {
    const input: DeleteNotificationInput = {
      id,
    };
    await graphql<{ deleteNotification: AmplifyNotification }>(deleteNotification, {
      input,
    });
  }

  async findByUserId(userId: UserId): Promise<NotificationEntity<{}>[]> {
    const res = await graphqlQuery<{
      notificationsByUserId: { items: Array<AmplifyNotification> };
    }>(notificationsByUserId, { userId, sortDirection: 'DESC' }, { limit: 1000 });
    return res?.notificationsByUserId
      ? res?.notificationsByUserId.items.map((n) => toNotificationEntity(n))
      : [];
  }
}
