import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { Optional } from '../types';

export type PrivacyPolicyVersion = string;

export type PrivacyPolicyDataAdapter = {
  findPrivacyPolicyVersion(): Promise<Optional<PrivacyPolicyVersion>>;
};

export const PrivacyPolicyDataAdapterKey = injectionKeyOf<PrivacyPolicyDataAdapter>({
  boundedContext: 'base',
  type: 'adapter',
  name: 'PrivacyPolicyDataAdapter',
});

export function usePrivacyPolicyDataAdapter(): PrivacyPolicyDataAdapter {
  return requiredInject(PrivacyPolicyDataAdapterKey);
}
