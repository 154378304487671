import {
  AuthorizationService,
  CourseColor,
  CourseFontColorOnImage,
  CourseId,
  FileStorage,
} from '@/base/domains';
import { AbstractUseCase, assertEntityExists, UseCase, UseCaseResponse } from '@/base/usecases';
import { hasNonNullProperty } from '@/utils/TsUtils';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { CourseReference, CourseRepository } from '../domains';

export type ChangeConfirmedCourseColorOrImageRequest =
  | {
      id: CourseId;
      color: CourseColor;
    }
  | {
      id: CourseId;
      image: Blob;
    }
  | {
      id: CourseId;
      fontColorOnImage: CourseFontColorOnImage;
    };

export type ChangeConfirmedCourseColorOrImageResponse = {
  course: CourseReference;
};

export interface ChangeConfirmedCourseColorOrImage
  extends UseCase<
    ChangeConfirmedCourseColorOrImageRequest,
    ChangeConfirmedCourseColorOrImageResponse
  > {
  execute(
    request: ChangeConfirmedCourseColorOrImageRequest
  ): Promise<UseCaseResponse<ChangeConfirmedCourseColorOrImageResponse>>;
}

export class ChangeConfirmedCourseColorOrImageImpl
  extends AbstractUseCase<
    ChangeConfirmedCourseColorOrImageRequest,
    ChangeConfirmedCourseColorOrImageResponse
  >
  implements ChangeConfirmedCourseColorOrImage
{
  constructor(
    private authorizationService: AuthorizationService,
    private fileStorage: FileStorage,
    private courseRepository: CourseRepository
  ) {
    super('contents.ChangeConfirmedCourseColorOrImage');
  }

  async internalExecute(
    request: ChangeConfirmedCourseColorOrImageRequest
  ): Promise<ChangeConfirmedCourseColorOrImageResponse> {
    this.authorizationService.assertContentEditable();
    const { id } = request;
    const version = await this.courseRepository.findLatestVersion(id);
    const course = await this.courseRepository.findById({ id, version });
    assertEntityExists(course, 'course');

    if (hasNonNullProperty(request, 'color')) {
      const saved = await this.courseRepository.save(course.changeColor(request.color));
      return {
        course: saved,
      };
    }

    if (hasNonNullProperty(request, 'fontColorOnImage')) {
      const saved = await this.courseRepository.save(
        course.changeFontColorOnImage(request.fontColorOnImage)
      );
      return {
        course: saved,
      };
    }

    const uri = await this.fileStorage.upload({
      filename: `${course.id}-${course.version}-image`,
      file: request.image,
    });
    const saved = await this.courseRepository.save(course.changeImage(uri));
    return {
      course: saved,
    };
  }
}

export const ChangeConfirmedCourseColorOrImageKey =
  injectionKeyOf<ChangeConfirmedCourseColorOrImage>({
    boundedContext: 'contents',
    type: 'usecase',
    name: 'ChangeConfirmedCourseColorOrImage',
  });

export function useChangeConfirmedCourseColorOrImage(): ChangeConfirmedCourseColorOrImage {
  return requiredInject(ChangeConfirmedCourseColorOrImageKey);
}
