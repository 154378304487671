import { Comment, GroupId, QuestionContentReference, QuestionId, UserId } from '@/base/domains';
import { LocalDateTime } from '@/base/types';

import { AddCommentRequest, QuestionData, QuestionEntity } from './Question';

type QuestionEntityImplArgs = {
  id: QuestionId;

  comments: Array<Comment>;

  resolved: boolean;

  resolvedBy?: UserId;

  resolvedAt?: LocalDateTime;

  groupId: GroupId;

  title: string;

  createdBy: UserId;

  createdAt: LocalDateTime;

  referTo?: QuestionContentReference;

  assignees: Array<UserId>;
};

export class QuestionEntityImpl implements QuestionEntity {
  readonly id: QuestionId;

  readonly comments: Array<Comment>;

  readonly resolved: boolean;

  readonly resolvedBy?: UserId;

  readonly resolvedAt?: LocalDateTime;

  readonly groupId: GroupId;

  readonly title: string;

  readonly createdBy: UserId;

  readonly createdAt: LocalDateTime;

  readonly referTo?: QuestionContentReference;

  readonly assignees: Array<UserId>;

  constructor(args: QuestionEntityImplArgs) {
    this.id = args.id;
    this.comments = args.comments;
    this.resolved = args.resolved;
    this.resolvedBy = args.resolvedBy;
    this.resolvedAt = args.resolvedAt;
    this.groupId = args.groupId;
    this.title = args.title;
    this.createdBy = args.createdBy;
    this.createdAt = args.createdAt;
    this.referTo = args.referTo;
    this.assignees = args.assignees;
  }

  resolve({ by, at }: { by: UserId; at: LocalDateTime }): QuestionEntity {
    return new QuestionEntityImpl({
      ...this,
      resolved: true,
      resolvedBy: by,
      resolvedAt: at,
    });
  }

  addComment(request: AddCommentRequest): QuestionData {
    return {
      ...this,
      comments: [...this.comments, { body: request.body, createdBy: request.createdBy }],
    };
  }

  changeAssignees(assignees: Array<UserId>): QuestionEntity {
    return new QuestionEntityImpl({
      ...this,
      assignees,
    });
  }

  changeTitle(title: string): QuestionEntity {
    return new QuestionEntityImpl({
      ...this,
      title,
    });
  }
}
