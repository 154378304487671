/* eslint-disable @typescript-eslint/no-unused-vars */
import { Tenant, TenantCode, TenantDataAdapter } from '@/base/domains';

export class InMemoryTenantDataAdapter implements TenantDataAdapter {
  private tenant: Tenant;

  constructor(tenant: Tenant) {
    this.tenant = tenant;
  }

  get(): Promise<Tenant> {
    return Promise.resolve(this.tenant);
  }

  findByTenantCode(tenantCode: TenantCode): Promise<Tenant> {
    return Promise.resolve(this.tenant);
  }
}
