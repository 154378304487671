import { AuthorizationService, ScheduleTagId } from '@/base/domains';
import { AbstractUseCase, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { ScheduleTagRepository } from '../domains';

export interface RemoveScheduleTagRequest {
  id: ScheduleTagId;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export type RemoveScheduleTagResponse = {};

/**
 * スケジュールタグを削除する
 */
export interface RemoveScheduleTag
  extends UseCase<RemoveScheduleTagRequest, RemoveScheduleTagResponse> {
  execute(request: RemoveScheduleTagRequest): Promise<UseCaseResponse<RemoveScheduleTagResponse>>;
}

export class RemoveScheduleTagImpl
  extends AbstractUseCase<RemoveScheduleTagRequest, RemoveScheduleTagResponse>
  implements RemoveScheduleTag
{
  private authorizationService: AuthorizationService;

  private scheduleTagRepository: ScheduleTagRepository;

  constructor(
    authorizationService: AuthorizationService,
    scheduleTagRepository: ScheduleTagRepository
  ) {
    super('training.RemoveScheduleTag');
    this.authorizationService = authorizationService;
    this.scheduleTagRepository = scheduleTagRepository;
  }

  async internalExecute(request: RemoveScheduleTagRequest): Promise<RemoveScheduleTagResponse> {
    const { id } = request;
    const scheduleTag = await this.scheduleTagRepository.findById(id);
    if (!scheduleTag) {
      return {};
    }
    this.authorizationService.assertGroupWriteAccessible(scheduleTag.groupId);
    await this.scheduleTagRepository.remove(id);
    return {};
  }
}

export const RemoveScheduleTagKey = injectionKeyOf<RemoveScheduleTag>({
  boundedContext: 'training',
  type: 'usecase',
  name: 'RemoveScheduleTag',
});

export function useRemoveScheduleTag(): RemoveScheduleTag {
  return requiredInject(RemoveScheduleTagKey);
}
