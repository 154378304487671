import { RouteConfig } from 'vue-router';

const CoursesPage = import(/* webpackChunkName: "contents" */ './pages/CoursesPage.vue');
const CoursePage = import(/* webpackChunkName: "contents" */ './pages/CoursePage.vue');
const ContentPage = import(/* webpackChunkName: "contents" */ './pages/ContentPage.vue');

export const contentsRoutes: Array<RouteConfig> = [
  {
    path: '/admin/courses',
    name: 'adminCourses',
    component: () => CoursesPage,
  },
  {
    path: '/admin/courses/:id',
    name: 'adminCourse',
    component: () => CoursePage,
    props: (route) => {
      const edit = 'edit' in route.query && route.query.edit !== undefined;
      return { ...route.params, edit };
    },
  },
  {
    path: '/admin/contents/:id/v/:v',
    name: 'adminContent',
    component: () => ContentPage,
    props: (route) => {
      const edit = 'edit' in route.query && route.query.edit !== undefined;
      return { ...route.params, edit };
    },
  },
];
