import {
  AppContextProvider,
  AuthorizationService,
  ContentLearningDataAdapter,
  GroupId,
  GroupName,
  GroupRole,
  UserId,
  UserName,
} from '@/base/domains';
import { AbstractUseCase, UseCase, UseCaseResponse } from '@/base/usecases';
import { requiredNonNull, uniqueArray } from '@/utils/TsUtils';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

export type GetTrainerUserReportConditionsRequest = {
  admin: boolean;
};

export type GetTrainerUserReportConditionsUser = {
  id: UserId;
  name?: UserName;
  groupRole?: GroupRole;
  active: boolean;
};

export type GetTrainerUserReportConditionsGroup = {
  id: GroupId;
  name: GroupName;
  users: Array<GetTrainerUserReportConditionsUser>;
  enabled: boolean;
};

export type GetTrainerUserReportConditionsResponse = {
  groups: Array<GetTrainerUserReportConditionsGroup>;
};

/**
 * トレーナー用グループ検索条件を取得する
 */
export interface GetTrainerUserReportConditions
  extends UseCase<GetTrainerUserReportConditionsRequest, GetTrainerUserReportConditionsResponse> {
  execute(
    request: GetTrainerUserReportConditionsRequest
  ): Promise<UseCaseResponse<GetTrainerUserReportConditionsResponse>>;
}

export class GetTrainerUserReportConditionsImpl
  extends AbstractUseCase<
    GetTrainerUserReportConditionsRequest,
    GetTrainerUserReportConditionsResponse
  >
  implements GetTrainerUserReportConditions
{
  constructor(
    private appContextProvider: AppContextProvider,
    private contentLearningDataAdapter: ContentLearningDataAdapter,
    private authorizationService: AuthorizationService
  ) {
    super('report.GetTrainerUserReportConditions');
  }

  async internalExecute({
    admin,
  }: GetTrainerUserReportConditionsRequest): Promise<GetTrainerUserReportConditionsResponse> {
    const { groupsIncludeRemovedUser, users, user } = this.appContextProvider.get();

    const targetGroups = (() => {
      if (admin) {
        this.authorizationService.assertRole('admin', 'supervisor');
        return groupsIncludeRemovedUser;
      }
      const userId = requiredNonNull(user?.id, 'appContext.user');
      const isTrainerOrMentor = (role: GroupRole) => role === 'trainer' || role === 'mentor';
      return groupsIncludeRemovedUser.filter((g) =>
        g.users.find((u) => u.id === userId && isTrainerOrMentor(u.role))
      );
    })();

    const getGroup = async (group: {
      id: GroupId;
      name: GroupName;
      enabled: boolean;
      users: Array<{ id: UserId; name: UserName; role: GroupRole; removed: boolean }>;
    }) => {
      const contentLearnings = await this.contentLearningDataAdapter.findByGroupId(group.id);
      const userNames = new Map(users.map((u) => [u.id, u.name]));
      const userGroupRoles = new Map(group.users.map((u) => [u.id, u.role]));
      const userRemoved = new Map(group.users.map((u) => [u.id, u.removed]));
      const uniqueUserIds = uniqueArray(
        contentLearnings.flatMap((u) => u.userId).concat(group.users.map((u) => u.id))
      );
      const formattedUsers = uniqueUserIds.map((userId) => {
        const groupRole = userGroupRoles.get(userId);
        return {
          id: userId,
          name: userNames.get(userId),
          groupRole,
          active: groupRole ? !userRemoved.get(userId) : false,
        };
      });
      return {
        id: group.id,
        name: group.name,
        users: formattedUsers,
        enabled: group.enabled,
      };
    };

    const groups = await Promise.all(targetGroups.map((g) => getGroup(g)));
    return {
      groups,
    };
  }
}

export const GetTrainerUserReportConditionsKey = injectionKeyOf<GetTrainerUserReportConditions>({
  boundedContext: 'report',
  type: 'usecase',
  name: 'GetTrainerUserReportConditions',
});

export function useGetTrainerUserReportConditions(): GetTrainerUserReportConditions {
  return requiredInject(GetTrainerUserReportConditionsKey);
}
