import { EditingCourseContentBodyEntity } from './EditingCourseContentBody';
import {
  ExamEditingCourseContentBodyEntityImpl,
  ExamEditingCourseContentBodyEntityImplArgs,
} from './ExamEditingCourseContentBodyEntityImpl';
import {
  TextEditingCourseContentBodyEntityImpl,
  TextEditingCourseContentBodyEntityImplArgs,
} from './TextEditingCourseContentBodyEntityImpl';

type CreateEditingCourseContentBodyArgs =
  | TextEditingCourseContentBodyEntityImplArgs
  | ExamEditingCourseContentBodyEntityImplArgs;

export function createEditingCourseContentBody(
  args: CreateEditingCourseContentBodyArgs
): EditingCourseContentBodyEntity {
  if (args.type === 'exam') {
    return new ExamEditingCourseContentBodyEntityImpl(args);
  }
  if (args.type === 'text') {
    return new TextEditingCourseContentBodyEntityImpl(args);
  }
  throw new Error('Unsupported type');
}
