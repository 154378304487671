import { AuthorizationService, ContentId, DataVersion } from '@/base/domains';
import {
  AbstractUseCase,
  assertDataVersion,
  assertEntityExists,
  UseCase,
  UseCaseResponse,
} from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { EditingCourseContentBodyReference, EditingCourseContentBodyRepository } from '../domains';
import { CONTENT_TYPE_SHOULD_BE_EXAM } from '../ErrorCodes';

export type ChangeEditingCourseExamPassingStandardRequest = {
  id: ContentId;
  passingStandard?: number;
  expectedDataVersion: DataVersion;
};

export type ChangeEditingCourseExamPassingStandardResponse = {
  content: EditingCourseContentBodyReference;
};

/**
 * 編集中コンテンツの合格基準を変更する
 */
export interface ChangeEditingCourseExamPassingStandard
  extends UseCase<
    ChangeEditingCourseExamPassingStandardRequest,
    ChangeEditingCourseExamPassingStandardResponse
  > {
  execute(
    request: ChangeEditingCourseExamPassingStandardRequest
  ): Promise<UseCaseResponse<ChangeEditingCourseExamPassingStandardResponse>>;
}

export class ChangeEditingCourseExamPassingStandardImpl
  extends AbstractUseCase<
    ChangeEditingCourseExamPassingStandardRequest,
    ChangeEditingCourseExamPassingStandardResponse
  >
  implements ChangeEditingCourseExamPassingStandard
{
  private authorizationService: AuthorizationService;

  private editingCourseContentBodyRepository: EditingCourseContentBodyRepository;

  constructor(
    authorizationService: AuthorizationService,
    editingCourseContentBodyRepository: EditingCourseContentBodyRepository
  ) {
    super('contents.ChangeEditingCourseExamPassingStandard');
    this.authorizationService = authorizationService;
    this.editingCourseContentBodyRepository = editingCourseContentBodyRepository;
  }

  async internalExecute(
    request: ChangeEditingCourseExamPassingStandardRequest
  ): Promise<ChangeEditingCourseExamPassingStandardResponse> {
    const { id, passingStandard, expectedDataVersion } = request;
    this.authorizationService.assertContentEditable();
    const content = await this.editingCourseContentBodyRepository.findById(id);
    assertEntityExists(content, 'editingCourseContentBody');
    assertDataVersion(content, expectedDataVersion, 'editingCourseContentBody');
    if (content.type !== 'exam') {
      throw CONTENT_TYPE_SHOULD_BE_EXAM.toApplicationError({
        payload: {
          id,
        },
      });
    }
    const saved = await this.editingCourseContentBodyRepository.save(
      content.changePassingStandard(passingStandard)
    );
    return {
      content: saved,
    };
  }
}

export const ChangeEditingCourseExamPassingStandardKey =
  injectionKeyOf<ChangeEditingCourseExamPassingStandard>({
    boundedContext: 'contents',
    type: 'usecase',
    name: 'ChangeEditingCourseExamPassingStandard',
  });

export function useChangeEditingCourseExamPassingStandard(): ChangeEditingCourseExamPassingStandard {
  return requiredInject(ChangeEditingCourseExamPassingStandardKey);
}
