import { AuthorizationService, DateTimeService } from '@/base/domains';
import {
  AbstractUseCase,
  assertEntityExists,
  FinishQuestionnaire,
  FinishQuestionnaireRequest,
  FinishQuestionnaireResponse,
} from '@/base/usecases';

import { QuestionnaireRepository } from '../domains';

export class FinishQuestionnaireImpl
  extends AbstractUseCase<FinishQuestionnaireRequest, FinishQuestionnaireResponse>
  implements FinishQuestionnaire
{
  constructor(
    private authorizationService: AuthorizationService,
    private questionnaireRepository: QuestionnaireRepository,
    private dateTimeService: DateTimeService
  ) {
    super('training.FinishQuestionnaire');
  }

  async internalExecute(request: FinishQuestionnaireRequest): Promise<FinishQuestionnaireResponse> {
    const { id } = request;
    const questionnaire = await this.questionnaireRepository.findById(id);
    assertEntityExists(questionnaire, 'questionnaire');
    const now = await this.dateTimeService.strictLocalDateTimeNow();
    this.authorizationService.assertTrainerOrMentorInGroup(questionnaire.groupId);
    const finished = questionnaire.finish(now);
    const saved = await this.questionnaireRepository.save(finished);
    return { questionnaire: saved };
  }
}
