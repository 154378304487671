import { AuthorizationService, CourseId } from '@/base/domains';
import { AbstractUseCase, assertEntityExists, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { CourseHeaderReference, CourseHeaderRepository } from '../domains';

export interface DisableCourseRequest {
  id: CourseId;
}

export type DisableCourseResponse = {
  course: CourseHeaderReference;
};

/**
 * コースを無効化する
 */
export interface DisableCourse extends UseCase<DisableCourseRequest, DisableCourseResponse> {
  execute(request: DisableCourseRequest): Promise<UseCaseResponse<DisableCourseResponse>>;
}

export class DisableCourseImpl
  extends AbstractUseCase<DisableCourseRequest, DisableCourseResponse>
  implements DisableCourse
{
  private authorizationService: AuthorizationService;

  private courseHeaderRepository: CourseHeaderRepository;

  constructor(
    authorizationService: AuthorizationService,
    courseHeaderRepository: CourseHeaderRepository
  ) {
    super('contents.DisableCourse');
    this.authorizationService = authorizationService;
    this.courseHeaderRepository = courseHeaderRepository;
  }

  async internalExecute(request: DisableCourseRequest): Promise<DisableCourseResponse> {
    const { id } = request;
    this.authorizationService.assertContentEditable();
    const course = await this.courseHeaderRepository.findById(id);
    assertEntityExists(course, 'courseHeader');
    const saved = await this.courseHeaderRepository.save(course.disable());
    return {
      course: saved,
    };
  }
}

export const DisableCourseKey = injectionKeyOf<DisableCourse>({
  boundedContext: 'contents',
  type: 'usecase',
  name: 'DisableCourse',
});

export function useDisableCourse(): DisableCourse {
  return requiredInject(DisableCourseKey);
}
