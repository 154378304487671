import {
  ContentId,
  CourseId,
  DataVersion,
  Entity,
  EntityReference,
  Problem,
  ProblemHeader,
  ProblemHeaderId,
  Repository,
  UserId,
} from '@/base/domains';
import { LocalDateTime, MarkDownString } from '@/base/types';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

/**
 * 編集中コースコンテンツワークブック属性
 */
export interface EditingCourseContentWorkbookAttributes {
  problems: Array<Problem>;
  problemHeaders: Array<ProblemHeader>;
  dataVersion: DataVersion;
  createdBy: UserId;
  updatedBy: UserId;
  courseId: CourseId;
}

/**
 * 編集中コースコンテンツワークブックデータ
 */
export type EditingCourseContentWorkbookData =
  | {
      id: ContentId;
      problems: Array<Problem>;
      problemHeaders: Array<ProblemHeader>;
      courseId: CourseId;
    }
  | {
      id: ContentId;
      problems: Array<Problem>;
      problemHeaders: Array<ProblemHeader>;
      dataVersion: DataVersion;
      createdBy: UserId;
      updatedBy: UserId;
      courseId: CourseId;
    };

/**
 * 編集中コースコンテンツワークブックコマンド
 */
export interface EditingCourseContentWorkbookCommand {
  changeProblems(problems: Array<Problem>): EditingCourseContentWorkbookEntity;
  addProblemHeader({
    body,
    createdAt,
  }: {
    body: MarkDownString;
    createdAt: LocalDateTime;
  }): EditingCourseContentWorkbookEntity;
  changeProblemHeader({
    id,
    body,
  }: {
    id: ProblemHeaderId;
    body: MarkDownString;
  }): EditingCourseContentWorkbookEntity;
  removeProblemHeader(id: ProblemHeaderId): EditingCourseContentWorkbookEntity;
}

/**
 * 編集中コースコンテンツワークブッククエリ
 */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface EditingCourseContentWorkbookQueries {
  // TODO
}

/**
 * 編集中コースコンテンツワークブックリファレンス
 */
export type EditingCourseContentWorkbookReference = EntityReference<
  ContentId,
  EditingCourseContentWorkbookAttributes,
  EditingCourseContentWorkbookQueries
>;

/**
 * 編集中コースコンテンツワークブックエンティティ
 */
export interface EditingCourseContentWorkbookEntity
  extends Entity<ContentId, EditingCourseContentWorkbookAttributes>,
    EditingCourseContentWorkbookQueries,
    EditingCourseContentWorkbookCommand {}

/**
 * 編集中コースコンテンツワークブックリポジトリ
 */
export type EditingCourseContentWorkbookRepository = Repository<
  ContentId,
  EditingCourseContentWorkbookEntity,
  EditingCourseContentWorkbookData
>;

export const EditingCourseContentWorkbookRepositoryKey =
  injectionKeyOf<EditingCourseContentWorkbookRepository>({
    boundedContext: 'contents',
    type: 'adapter',
    name: 'EditingCourseContentWorkbookRepositoryKey',
  });

export function useEditingCourseContentWorkbookRepository(): EditingCourseContentWorkbookRepository {
  return requiredInject(EditingCourseContentWorkbookRepositoryKey);
}
