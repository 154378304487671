import {
  ContentFinder,
  ContentId,
  ContentVersion,
  CourseColor,
  CourseDisplayName,
  CourseId,
  CourseName,
  CourseVersion,
  GroupCourseContent,
  GroupId,
  GroupTrainingId,
} from '@/base/domains';
import { URI } from '@/base/types';
import { AbstractUseCase, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import {
  GroupTrainingCourseContent,
  GroupTrainingCourseRepository,
  GroupTrainingReference,
  GroupTrainingRepository,
} from '../domains';

export interface GetGroupTrainingRequest {
  groupId: GroupId;
}

export type GetGroupTrainingGroupTrainingCourse = {
  groupTrainingId: GroupTrainingId;
  courseId: CourseId;
  courseVersion: CourseVersion;
  courseName: CourseName;
  contents: Array<GroupTrainingCourseContent>;
  groupId: GroupId;
  color?: CourseColor;
  image?: URI;
  displayName: CourseDisplayName;
  hasNewContentVersion: boolean;
  index?: number;
};

export type GetGroupTrainingResponse = {
  groupTraining?: GroupTrainingReference;
  groupTrainingCourses: Array<GetGroupTrainingGroupTrainingCourse>;
};

export interface GetGroupTraining
  extends UseCase<GetGroupTrainingRequest, GetGroupTrainingResponse> {
  execute(request: GetGroupTrainingRequest): Promise<UseCaseResponse<GetGroupTrainingResponse>>;
}

export class GetGroupTrainingImpl
  extends AbstractUseCase<GetGroupTrainingRequest, GetGroupTrainingResponse>
  implements GetGroupTraining
{
  constructor(
    private groupTrainingRepository: GroupTrainingRepository,
    private groupTrainingCourseRepository: GroupTrainingCourseRepository,
    private contentFinder: ContentFinder
  ) {
    super('training.GetGroupTraining');
  }

  async internalExecute(request: GetGroupTrainingRequest): Promise<GetGroupTrainingResponse> {
    const { groupId } = request;
    const [groupTraining, groupTrainingCourses, contentHeaders] = await Promise.all([
      this.groupTrainingRepository.findByGroupId(groupId),
      this.groupTrainingCourseRepository.findCoursesByGroupId(groupId),
      this.contentFinder.findTenantContentHeaders({ latest: true }),
    ]);
    const latestContentVersions = contentHeaders.reduce((acc, v) => {
      const contentVersion = acc.get(v.id) ?? 0;
      if (v.version > contentVersion) {
        acc.set(v.id, v.version);
      }
      return acc;
    }, new Map<ContentId, ContentVersion>());

    const checkContentUpdate = (contents: Array<GroupCourseContent>) =>
      !!contents.find((cn) => {
        const latestContentVersion = latestContentVersions.get(cn.id) ?? 0;
        return latestContentVersion > cn.version;
      });

    return {
      groupTraining,
      groupTrainingCourses: groupTrainingCourses
        .map((gtc) => ({
          id: gtc.id,
          groupTrainingId: gtc.groupTrainingId,
          courseId: gtc.courseId,
          courseVersion: gtc.courseVersion,
          courseName: gtc.courseName,
          contents: gtc.contents,
          groupId: gtc.groupId,
          color: gtc.color,
          image: gtc.image,
          displayName: gtc.displayName,
          hasNewContentVersion: checkContentUpdate(gtc.contents),
          index: gtc.index,
        }))
        .sort((a, b) => {
          if (a.index !== undefined && b.index !== undefined) return a.index - b.index;
          return a.courseName < b.courseName ? -1 : 1;
        }),
    };
  }
}

export const GetGroupTrainingKey = injectionKeyOf<GetGroupTraining>({
  boundedContext: 'contents',
  type: 'usecase',
  name: 'GetGroupTraining',
});

export function useGetGroupTraining(): GetGroupTraining {
  return requiredInject(GetGroupTrainingKey);
}
