import {
  ContentId,
  Entity,
  EntityData,
  EntityReference,
  ExamResultVisibilityLevel,
  GroupExamContent,
  GroupExamCourse,
  GroupExamId,
  GroupExamStatus,
  GroupId,
  StandardRepository,
  UserExam,
  UserId,
} from '@/base/domains';
import { LocalDateTime, Minute } from '@/base/types';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { GroupTrainingCourseId } from './GroupTrainingCourse';

/**
 * テストユーザーモード
 */
export type TestUserMode =
  /** 全員 */
  | 'all'
  /** 不合格者 */
  | 'failed';

/**
 * 属性
 */
export interface GroupExamAttributes {
  groupId: GroupId;
  content: GroupExamContent;
  course: GroupExamCourse;
  scheduledStart: LocalDateTime;
  scheduledFinish?: LocalDateTime;
  finishedAt?: LocalDateTime;
  status: GroupExamStatus;
  visibilityLevel: ExamResultVisibilityLevel;
  userExams: Array<UserExam>;
  times: number;
  groupTrainingCourseId: GroupTrainingCourseId;
  timeLimit?: Minute;
  passingStandard?: number;
  userIdsToBeTested: UserId[];
}

/**
 * データ
 */
export type GroupExamData = EntityData<GroupExamId, GroupExamAttributes>;

/**
 * コマンド
 */
export interface GroupExamCommand {
  restart(): GroupExamEntity;
  finish(now: LocalDateTime): GroupExamEntity;
  changeScheduledTime(args: {
    scheduledStart: LocalDateTime;
    scheduledFinish?: LocalDateTime;
    now: LocalDateTime;
  }): GroupExamEntity;
}

/**
 * クエリ
 */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface GroupExamQueries {}

/**
 * リファレンス
 */
export type GroupExamReference = EntityReference<
  GroupExamId,
  GroupExamAttributes,
  GroupExamQueries
>;

/**
 * エンティティ
 */
export interface GroupExamEntity
  extends Entity<GroupExamId, GroupExamAttributes>,
    GroupExamQueries,
    GroupExamCommand {}

/**
 * リポジトリ
 */
export interface GroupExamRepository
  extends StandardRepository<GroupExamId, GroupExamAttributes, GroupExamEntity> {
  findGroupExamsByGroupId(groupId: string): Promise<Array<GroupExamEntity>>;
  findGroupExamsByGroupIdAndContentId(
    groupId: GroupId,
    contentId: ContentId
  ): Promise<Array<GroupExamEntity>>;
}

export const GroupExamRepositoryKey = injectionKeyOf<GroupExamRepository>({
  boundedContext: 'training',
  type: 'adapter',
  name: 'GroupExamRepositoryKey',
});

export function useGroupExamRepository(): GroupExamRepository {
  return requiredInject(GroupExamRepositoryKey);
}
