/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getSignedPolicy = /* GraphQL */ `
  query GetSignedPolicy {
    getSignedPolicy {
      policy
      keyPairId
      signature
      domain
      expire
    }
  }
`;
export const getSignedUrl = /* GraphQL */ `
  query GetSignedUrl($filename: String!) {
    getSignedUrl(filename: $filename) {
      signedUrl
      path
      key
    }
  }
`;
export const now = /* GraphQL */ `
  query Now {
    now
  }
`;
export const getPrivacyPolicyVersion = /* GraphQL */ `
  query GetPrivacyPolicyVersion {
    getPrivacyPolicyVersion {
      version
    }
  }
`;
export const getNotification = /* GraphQL */ `
  query GetNotification($id: ID!) {
    getNotification(id: $id) {
      id
      type
      payloadJson
      userId
      read
      createdAt
      tenantCode
      updatedAt
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        payloadJson
        userId
        read
        createdAt
        tenantCode
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLive = /* GraphQL */ `
  query GetLive($id: ID!) {
    getLive(id: $id) {
      id
      userId
      url
      applicationSessionId
      tenantCode
      createdAt
      updatedAt
    }
  }
`;
export const listLives = /* GraphQL */ `
  query ListLives(
    $filter: ModelLiveFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLives(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        url
        applicationSessionId
        tenantCode
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getQuestionnaire = /* GraphQL */ `
  query GetQuestionnaire($id: ID!) {
    getQuestionnaire(id: $id) {
      id
      groupId
      tenantCode
      title
      userIds
      text
      createdBy
      createdAt
      options
      respondent {
        items {
          id
          questionnaireId
          userId
          selectedIndex
          tenantCode
          createdAt
          updatedAt
        }
        nextToken
      }
      status
      finishedAt
      updatedAt
    }
  }
`;
export const listQuestionnaires = /* GraphQL */ `
  query ListQuestionnaires(
    $filter: ModelQuestionnaireFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestionnaires(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        groupId
        tenantCode
        title
        userIds
        text
        createdBy
        createdAt
        options
        respondent {
          items {
            id
            questionnaireId
            userId
            selectedIndex
            tenantCode
            createdAt
            updatedAt
          }
          nextToken
        }
        status
        finishedAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getQuestionnaireRespondent = /* GraphQL */ `
  query GetQuestionnaireRespondent($id: ID!) {
    getQuestionnaireRespondent(id: $id) {
      id
      questionnaireId
      userId
      selectedIndex
      tenantCode
      createdAt
      updatedAt
    }
  }
`;
export const listQuestionnaireRespondents = /* GraphQL */ `
  query ListQuestionnaireRespondents(
    $filter: ModelQuestionnaireRespondentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestionnaireRespondents(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        questionnaireId
        userId
        selectedIndex
        tenantCode
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getScheduleTag = /* GraphQL */ `
  query GetScheduleTag($id: ID!) {
    getScheduleTag(id: $id) {
      id
      color
      text
      groupId
      tenantCode
      createdAt
      updatedAt
      schedules {
        items {
          id
          scheduleId
          scheduleTagId
          scheduleTag {
            id
            color
            text
            groupId
            tenantCode
            createdAt
            updatedAt
            schedules {
              items {
                id
                scheduleId
                scheduleTagId
                scheduleTag {
                  id
                  color
                  text
                  groupId
                  tenantCode
                  createdAt
                  updatedAt
                  schedules {
                    nextToken
                  }
                }
                tenantCode
                createdAt
                updatedAt
                schedule {
                  id
                  groupId
                  start
                  end
                  title
                  body
                  tenantCode
                  createdBy
                  updatedBy
                  createdAt
                  updatedAt
                  tags {
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
          tenantCode
          createdAt
          updatedAt
          schedule {
            id
            groupId
            start
            end
            title
            body
            tenantCode
            createdBy
            updatedBy
            createdAt
            updatedAt
            tags {
              items {
                id
                scheduleId
                scheduleTagId
                scheduleTag {
                  id
                  color
                  text
                  groupId
                  tenantCode
                  createdAt
                  updatedAt
                  schedules {
                    nextToken
                  }
                }
                tenantCode
                createdAt
                updatedAt
                schedule {
                  id
                  groupId
                  start
                  end
                  title
                  body
                  tenantCode
                  createdBy
                  updatedBy
                  createdAt
                  updatedAt
                  tags {
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const listScheduleTags = /* GraphQL */ `
  query ListScheduleTags(
    $filter: ModelScheduleTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listScheduleTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        color
        text
        groupId
        tenantCode
        createdAt
        updatedAt
        schedules {
          items {
            id
            scheduleId
            scheduleTagId
            scheduleTag {
              id
              color
              text
              groupId
              tenantCode
              createdAt
              updatedAt
              schedules {
                items {
                  id
                  scheduleId
                  scheduleTagId
                  scheduleTag {
                    id
                    color
                    text
                    groupId
                    tenantCode
                    createdAt
                    updatedAt
                  }
                  tenantCode
                  createdAt
                  updatedAt
                  schedule {
                    id
                    groupId
                    start
                    end
                    title
                    body
                    tenantCode
                    createdBy
                    updatedBy
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
            tenantCode
            createdAt
            updatedAt
            schedule {
              id
              groupId
              start
              end
              title
              body
              tenantCode
              createdBy
              updatedBy
              createdAt
              updatedAt
              tags {
                items {
                  id
                  scheduleId
                  scheduleTagId
                  scheduleTag {
                    id
                    color
                    text
                    groupId
                    tenantCode
                    createdAt
                    updatedAt
                  }
                  tenantCode
                  createdAt
                  updatedAt
                  schedule {
                    id
                    groupId
                    start
                    end
                    title
                    body
                    tenantCode
                    createdBy
                    updatedBy
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getMemo = /* GraphQL */ `
  query GetMemo($id: ID!) {
    getMemo(id: $id) {
      id
      body
      referTo {
        contentId
        contentVersion
        options {
          text {
            selectionJson
          }
          workbook {
            problemIndex
            selectionJson
          }
        }
      }
      scope
      groupId
      createdBy
      createdAt
      updatedBy
      updatedAt
      tenantCode
    }
  }
`;
export const listMemos = /* GraphQL */ `
  query ListMemos(
    $filter: ModelMemoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMemos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        body
        referTo {
          contentId
          contentVersion
          options {
            text {
              selectionJson
            }
            workbook {
              problemIndex
              selectionJson
            }
          }
        }
        scope
        groupId
        createdBy
        createdAt
        updatedBy
        updatedAt
        tenantCode
      }
      nextToken
    }
  }
`;
export const getReviewProblem = /* GraphQL */ `
  query GetReviewProblem($id: ID!) {
    getReviewProblem(id: $id) {
      id
      userId
      groupId
      courseId
      problems
      tenantCode
      createdAt
      updatedAt
    }
  }
`;
export const listReviewProblems = /* GraphQL */ `
  query ListReviewProblems(
    $filter: ModelReviewProblemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReviewProblems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        groupId
        courseId
        problems
        tenantCode
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const notificationsByUserId = /* GraphQL */ `
  query NotificationsByUserId(
    $userId: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    notificationsByUserId(
      userId: $userId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        payloadJson
        userId
        read
        createdAt
        tenantCode
        updatedAt
      }
      nextToken
    }
  }
`;
export const questionnaireByGroup = /* GraphQL */ `
  query QuestionnaireByGroup(
    $groupId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionnaireFilterInput
    $limit: Int
    $nextToken: String
  ) {
    questionnaireByGroup(
      groupId: $groupId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupId
        tenantCode
        title
        userIds
        text
        createdBy
        createdAt
        options
        respondent {
          items {
            id
            questionnaireId
            userId
            selectedIndex
            tenantCode
            createdAt
            updatedAt
          }
          nextToken
        }
        status
        finishedAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const questionnaireByTenantCode = /* GraphQL */ `
  query QuestionnaireByTenantCode(
    $tenantCode: String
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionnaireFilterInput
    $limit: Int
    $nextToken: String
  ) {
    questionnaireByTenantCode(
      tenantCode: $tenantCode
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupId
        tenantCode
        title
        userIds
        text
        createdBy
        createdAt
        options
        respondent {
          items {
            id
            questionnaireId
            userId
            selectedIndex
            tenantCode
            createdAt
            updatedAt
          }
          nextToken
        }
        status
        finishedAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const questionnaireRespondentByUserId = /* GraphQL */ `
  query QuestionnaireRespondentByUserId(
    $userId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionnaireRespondentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    questionnaireRespondentByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        questionnaireId
        userId
        selectedIndex
        tenantCode
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const questionnaireRespondentByQuestionnaireIdAndUserId = /* GraphQL */ `
  query QuestionnaireRespondentByQuestionnaireIdAndUserId(
    $questionnaireId: ID
    $userId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionnaireRespondentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    questionnaireRespondentByQuestionnaireIdAndUserId(
      questionnaireId: $questionnaireId
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        questionnaireId
        userId
        selectedIndex
        tenantCode
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const questionnaireRespondentByTenantCode = /* GraphQL */ `
  query QuestionnaireRespondentByTenantCode(
    $tenantCode: String
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionnaireRespondentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    questionnaireRespondentByTenantCode(
      tenantCode: $tenantCode
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        questionnaireId
        userId
        selectedIndex
        tenantCode
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const scheduleTagsByGroupId = /* GraphQL */ `
  query ScheduleTagsByGroupId(
    $groupId: ID
    $text: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelScheduleTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    scheduleTagsByGroupId(
      groupId: $groupId
      text: $text
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        color
        text
        groupId
        tenantCode
        createdAt
        updatedAt
        schedules {
          items {
            id
            scheduleId
            scheduleTagId
            scheduleTag {
              id
              color
              text
              groupId
              tenantCode
              createdAt
              updatedAt
              schedules {
                items {
                  id
                  scheduleId
                  scheduleTagId
                  scheduleTag {
                    id
                    color
                    text
                    groupId
                    tenantCode
                    createdAt
                    updatedAt
                  }
                  tenantCode
                  createdAt
                  updatedAt
                  schedule {
                    id
                    groupId
                    start
                    end
                    title
                    body
                    tenantCode
                    createdBy
                    updatedBy
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
            tenantCode
            createdAt
            updatedAt
            schedule {
              id
              groupId
              start
              end
              title
              body
              tenantCode
              createdBy
              updatedBy
              createdAt
              updatedAt
              tags {
                items {
                  id
                  scheduleId
                  scheduleTagId
                  scheduleTag {
                    id
                    color
                    text
                    groupId
                    tenantCode
                    createdAt
                    updatedAt
                  }
                  tenantCode
                  createdAt
                  updatedAt
                  schedule {
                    id
                    groupId
                    start
                    end
                    title
                    body
                    tenantCode
                    createdBy
                    updatedBy
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const memosByGroup = /* GraphQL */ `
  query MemosByGroup(
    $groupId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelMemoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    memosByGroup(
      groupId: $groupId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        body
        referTo {
          contentId
          contentVersion
          options {
            text {
              selectionJson
            }
            workbook {
              problemIndex
              selectionJson
            }
          }
        }
        scope
        groupId
        createdBy
        createdAt
        updatedBy
        updatedAt
        tenantCode
      }
      nextToken
    }
  }
`;
export const memosByTenantCode = /* GraphQL */ `
  query MemosByTenantCode(
    $tenantCode: String
    $sortDirection: ModelSortDirection
    $filter: ModelMemoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    memosByTenantCode(
      tenantCode: $tenantCode
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        body
        referTo {
          contentId
          contentVersion
          options {
            text {
              selectionJson
            }
            workbook {
              problemIndex
              selectionJson
            }
          }
        }
        scope
        groupId
        createdBy
        createdAt
        updatedBy
        updatedAt
        tenantCode
      }
      nextToken
    }
  }
`;
export const reviewProblemsByGroupAndUser = /* GraphQL */ `
  query ReviewProblemsByGroupAndUser(
    $groupId: ID
    $userId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReviewProblemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reviewProblemsByGroupAndUser(
      groupId: $groupId
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        groupId
        courseId
        problems
        tenantCode
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const reviewProblemsByTenantCode = /* GraphQL */ `
  query ReviewProblemsByTenantCode(
    $tenantCode: String
    $sortDirection: ModelSortDirection
    $filter: ModelReviewProblemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reviewProblemsByTenantCode(
      tenantCode: $tenantCode
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        groupId
        courseId
        problems
        tenantCode
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGroup = /* GraphQL */ `
  query GetGroup($id: ID!) {
    getGroup(id: $id) {
      id
      name
      users {
        id
        role
        removed
      }
      extensionConfigJsonList
      tenantCode
      limitations
      enabled
      description
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
`;
export const listGroups = /* GraphQL */ `
  query ListGroups(
    $filter: ModelGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        users {
          id
          role
          removed
        }
        extensionConfigJsonList
        tenantCode
        limitations
        enabled
        description
        createdBy
        updatedBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const groupsByTenantCode = /* GraphQL */ `
  query GroupsByTenantCode(
    $tenantCode: String
    $sortDirection: ModelSortDirection
    $filter: ModelGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    groupsByTenantCode(
      tenantCode: $tenantCode
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        users {
          id
          role
          removed
        }
        extensionConfigJsonList
        tenantCode
        limitations
        enabled
        description
        createdBy
        updatedBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      name
      role
      extensionConfigJsonList
      email
      code
      tenantCode
      displaySettings {
        theme
        color
      }
      locale
      avatar
      enabled
      signedInAtLeastOnce
      emailConfirmed
      confirmedTermsOfServiceVersions {
        termsOfServiceVersion
        privacyPolicyVersion
        tenantOwnTermsOfServiceVersion
      }
      createdAt
      updatedAt
      tags {
        items {
          id
          userId
          userTagId
          tenantCode
          createdAt
          updatedAt
          user {
            id
            name
            role
            extensionConfigJsonList
            email
            code
            tenantCode
            displaySettings {
              theme
              color
            }
            locale
            avatar
            enabled
            signedInAtLeastOnce
            emailConfirmed
            confirmedTermsOfServiceVersions {
              termsOfServiceVersion
              privacyPolicyVersion
              tenantOwnTermsOfServiceVersion
            }
            createdAt
            updatedAt
            tags {
              items {
                id
                userId
                userTagId
                tenantCode
                createdAt
                updatedAt
                user {
                  id
                  name
                  role
                  extensionConfigJsonList
                  email
                  code
                  tenantCode
                  displaySettings {
                    theme
                    color
                  }
                  locale
                  avatar
                  enabled
                  signedInAtLeastOnce
                  emailConfirmed
                  confirmedTermsOfServiceVersions {
                    termsOfServiceVersion
                    privacyPolicyVersion
                    tenantOwnTermsOfServiceVersion
                  }
                  createdAt
                  updatedAt
                  tags {
                    nextToken
                  }
                  statuses {
                    nextToken
                  }
                }
                userTag {
                  id
                  color
                  text
                  tenantCode
                  createdAt
                  updatedAt
                  users {
                    nextToken
                  }
                }
              }
              nextToken
            }
            statuses {
              items {
                id
                tenantCode
                status
                userId
                createdAt
                updatedAt
                user {
                  id
                  name
                  role
                  extensionConfigJsonList
                  email
                  code
                  tenantCode
                  displaySettings {
                    theme
                    color
                  }
                  locale
                  avatar
                  enabled
                  signedInAtLeastOnce
                  emailConfirmed
                  confirmedTermsOfServiceVersions {
                    termsOfServiceVersion
                    privacyPolicyVersion
                    tenantOwnTermsOfServiceVersion
                  }
                  createdAt
                  updatedAt
                  tags {
                    nextToken
                  }
                  statuses {
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
          userTag {
            id
            color
            text
            tenantCode
            createdAt
            updatedAt
            users {
              items {
                id
                userId
                userTagId
                tenantCode
                createdAt
                updatedAt
                user {
                  id
                  name
                  role
                  extensionConfigJsonList
                  email
                  code
                  tenantCode
                  displaySettings {
                    theme
                    color
                  }
                  locale
                  avatar
                  enabled
                  signedInAtLeastOnce
                  emailConfirmed
                  confirmedTermsOfServiceVersions {
                    termsOfServiceVersion
                    privacyPolicyVersion
                    tenantOwnTermsOfServiceVersion
                  }
                  createdAt
                  updatedAt
                  tags {
                    nextToken
                  }
                  statuses {
                    nextToken
                  }
                }
                userTag {
                  id
                  color
                  text
                  tenantCode
                  createdAt
                  updatedAt
                  users {
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
        }
        nextToken
      }
      statuses {
        items {
          id
          tenantCode
          status
          userId
          createdAt
          updatedAt
          user {
            id
            name
            role
            extensionConfigJsonList
            email
            code
            tenantCode
            displaySettings {
              theme
              color
            }
            locale
            avatar
            enabled
            signedInAtLeastOnce
            emailConfirmed
            confirmedTermsOfServiceVersions {
              termsOfServiceVersion
              privacyPolicyVersion
              tenantOwnTermsOfServiceVersion
            }
            createdAt
            updatedAt
            tags {
              items {
                id
                userId
                userTagId
                tenantCode
                createdAt
                updatedAt
                user {
                  id
                  name
                  role
                  extensionConfigJsonList
                  email
                  code
                  tenantCode
                  displaySettings {
                    theme
                    color
                  }
                  locale
                  avatar
                  enabled
                  signedInAtLeastOnce
                  emailConfirmed
                  confirmedTermsOfServiceVersions {
                    termsOfServiceVersion
                    privacyPolicyVersion
                    tenantOwnTermsOfServiceVersion
                  }
                  createdAt
                  updatedAt
                  tags {
                    nextToken
                  }
                  statuses {
                    nextToken
                  }
                }
                userTag {
                  id
                  color
                  text
                  tenantCode
                  createdAt
                  updatedAt
                  users {
                    nextToken
                  }
                }
              }
              nextToken
            }
            statuses {
              items {
                id
                tenantCode
                status
                userId
                createdAt
                updatedAt
                user {
                  id
                  name
                  role
                  extensionConfigJsonList
                  email
                  code
                  tenantCode
                  displaySettings {
                    theme
                    color
                  }
                  locale
                  avatar
                  enabled
                  signedInAtLeastOnce
                  emailConfirmed
                  confirmedTermsOfServiceVersions {
                    termsOfServiceVersion
                    privacyPolicyVersion
                    tenantOwnTermsOfServiceVersion
                  }
                  createdAt
                  updatedAt
                  tags {
                    nextToken
                  }
                  statuses {
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        role
        extensionConfigJsonList
        email
        code
        tenantCode
        displaySettings {
          theme
          color
        }
        locale
        avatar
        enabled
        signedInAtLeastOnce
        emailConfirmed
        confirmedTermsOfServiceVersions {
          termsOfServiceVersion
          privacyPolicyVersion
          tenantOwnTermsOfServiceVersion
        }
        createdAt
        updatedAt
        tags {
          items {
            id
            userId
            userTagId
            tenantCode
            createdAt
            updatedAt
            user {
              id
              name
              role
              extensionConfigJsonList
              email
              code
              tenantCode
              displaySettings {
                theme
                color
              }
              locale
              avatar
              enabled
              signedInAtLeastOnce
              emailConfirmed
              confirmedTermsOfServiceVersions {
                termsOfServiceVersion
                privacyPolicyVersion
                tenantOwnTermsOfServiceVersion
              }
              createdAt
              updatedAt
              tags {
                items {
                  id
                  userId
                  userTagId
                  tenantCode
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                  userTag {
                    id
                    color
                    text
                    tenantCode
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
              statuses {
                items {
                  id
                  tenantCode
                  status
                  userId
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
            userTag {
              id
              color
              text
              tenantCode
              createdAt
              updatedAt
              users {
                items {
                  id
                  userId
                  userTagId
                  tenantCode
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                  userTag {
                    id
                    color
                    text
                    tenantCode
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
          }
          nextToken
        }
        statuses {
          items {
            id
            tenantCode
            status
            userId
            createdAt
            updatedAt
            user {
              id
              name
              role
              extensionConfigJsonList
              email
              code
              tenantCode
              displaySettings {
                theme
                color
              }
              locale
              avatar
              enabled
              signedInAtLeastOnce
              emailConfirmed
              confirmedTermsOfServiceVersions {
                termsOfServiceVersion
                privacyPolicyVersion
                tenantOwnTermsOfServiceVersion
              }
              createdAt
              updatedAt
              tags {
                items {
                  id
                  userId
                  userTagId
                  tenantCode
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                  userTag {
                    id
                    color
                    text
                    tenantCode
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
              statuses {
                items {
                  id
                  tenantCode
                  status
                  userId
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const userByEmail = /* GraphQL */ `
  query UserByEmail(
    $email: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        role
        extensionConfigJsonList
        email
        code
        tenantCode
        displaySettings {
          theme
          color
        }
        locale
        avatar
        enabled
        signedInAtLeastOnce
        emailConfirmed
        confirmedTermsOfServiceVersions {
          termsOfServiceVersion
          privacyPolicyVersion
          tenantOwnTermsOfServiceVersion
        }
        createdAt
        updatedAt
        tags {
          items {
            id
            userId
            userTagId
            tenantCode
            createdAt
            updatedAt
            user {
              id
              name
              role
              extensionConfigJsonList
              email
              code
              tenantCode
              displaySettings {
                theme
                color
              }
              locale
              avatar
              enabled
              signedInAtLeastOnce
              emailConfirmed
              confirmedTermsOfServiceVersions {
                termsOfServiceVersion
                privacyPolicyVersion
                tenantOwnTermsOfServiceVersion
              }
              createdAt
              updatedAt
              tags {
                items {
                  id
                  userId
                  userTagId
                  tenantCode
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                  userTag {
                    id
                    color
                    text
                    tenantCode
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
              statuses {
                items {
                  id
                  tenantCode
                  status
                  userId
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
            userTag {
              id
              color
              text
              tenantCode
              createdAt
              updatedAt
              users {
                items {
                  id
                  userId
                  userTagId
                  tenantCode
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                  userTag {
                    id
                    color
                    text
                    tenantCode
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
          }
          nextToken
        }
        statuses {
          items {
            id
            tenantCode
            status
            userId
            createdAt
            updatedAt
            user {
              id
              name
              role
              extensionConfigJsonList
              email
              code
              tenantCode
              displaySettings {
                theme
                color
              }
              locale
              avatar
              enabled
              signedInAtLeastOnce
              emailConfirmed
              confirmedTermsOfServiceVersions {
                termsOfServiceVersion
                privacyPolicyVersion
                tenantOwnTermsOfServiceVersion
              }
              createdAt
              updatedAt
              tags {
                items {
                  id
                  userId
                  userTagId
                  tenantCode
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                  userTag {
                    id
                    color
                    text
                    tenantCode
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
              statuses {
                items {
                  id
                  tenantCode
                  status
                  userId
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const userByCodeAndTenantCode = /* GraphQL */ `
  query UserByCodeAndTenantCode(
    $code: String
    $tenantCode: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByCodeAndTenantCode(
      code: $code
      tenantCode: $tenantCode
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        role
        extensionConfigJsonList
        email
        code
        tenantCode
        displaySettings {
          theme
          color
        }
        locale
        avatar
        enabled
        signedInAtLeastOnce
        emailConfirmed
        confirmedTermsOfServiceVersions {
          termsOfServiceVersion
          privacyPolicyVersion
          tenantOwnTermsOfServiceVersion
        }
        createdAt
        updatedAt
        tags {
          items {
            id
            userId
            userTagId
            tenantCode
            createdAt
            updatedAt
            user {
              id
              name
              role
              extensionConfigJsonList
              email
              code
              tenantCode
              displaySettings {
                theme
                color
              }
              locale
              avatar
              enabled
              signedInAtLeastOnce
              emailConfirmed
              confirmedTermsOfServiceVersions {
                termsOfServiceVersion
                privacyPolicyVersion
                tenantOwnTermsOfServiceVersion
              }
              createdAt
              updatedAt
              tags {
                items {
                  id
                  userId
                  userTagId
                  tenantCode
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                  userTag {
                    id
                    color
                    text
                    tenantCode
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
              statuses {
                items {
                  id
                  tenantCode
                  status
                  userId
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
            userTag {
              id
              color
              text
              tenantCode
              createdAt
              updatedAt
              users {
                items {
                  id
                  userId
                  userTagId
                  tenantCode
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                  userTag {
                    id
                    color
                    text
                    tenantCode
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
          }
          nextToken
        }
        statuses {
          items {
            id
            tenantCode
            status
            userId
            createdAt
            updatedAt
            user {
              id
              name
              role
              extensionConfigJsonList
              email
              code
              tenantCode
              displaySettings {
                theme
                color
              }
              locale
              avatar
              enabled
              signedInAtLeastOnce
              emailConfirmed
              confirmedTermsOfServiceVersions {
                termsOfServiceVersion
                privacyPolicyVersion
                tenantOwnTermsOfServiceVersion
              }
              createdAt
              updatedAt
              tags {
                items {
                  id
                  userId
                  userTagId
                  tenantCode
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                  userTag {
                    id
                    color
                    text
                    tenantCode
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
              statuses {
                items {
                  id
                  tenantCode
                  status
                  userId
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const usersByTenantCode = /* GraphQL */ `
  query UsersByTenantCode(
    $tenantCode: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByTenantCode(
      tenantCode: $tenantCode
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        role
        extensionConfigJsonList
        email
        code
        tenantCode
        displaySettings {
          theme
          color
        }
        locale
        avatar
        enabled
        signedInAtLeastOnce
        emailConfirmed
        confirmedTermsOfServiceVersions {
          termsOfServiceVersion
          privacyPolicyVersion
          tenantOwnTermsOfServiceVersion
        }
        createdAt
        updatedAt
        tags {
          items {
            id
            userId
            userTagId
            tenantCode
            createdAt
            updatedAt
            user {
              id
              name
              role
              extensionConfigJsonList
              email
              code
              tenantCode
              displaySettings {
                theme
                color
              }
              locale
              avatar
              enabled
              signedInAtLeastOnce
              emailConfirmed
              confirmedTermsOfServiceVersions {
                termsOfServiceVersion
                privacyPolicyVersion
                tenantOwnTermsOfServiceVersion
              }
              createdAt
              updatedAt
              tags {
                items {
                  id
                  userId
                  userTagId
                  tenantCode
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                  userTag {
                    id
                    color
                    text
                    tenantCode
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
              statuses {
                items {
                  id
                  tenantCode
                  status
                  userId
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
            userTag {
              id
              color
              text
              tenantCode
              createdAt
              updatedAt
              users {
                items {
                  id
                  userId
                  userTagId
                  tenantCode
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                  userTag {
                    id
                    color
                    text
                    tenantCode
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
          }
          nextToken
        }
        statuses {
          items {
            id
            tenantCode
            status
            userId
            createdAt
            updatedAt
            user {
              id
              name
              role
              extensionConfigJsonList
              email
              code
              tenantCode
              displaySettings {
                theme
                color
              }
              locale
              avatar
              enabled
              signedInAtLeastOnce
              emailConfirmed
              confirmedTermsOfServiceVersions {
                termsOfServiceVersion
                privacyPolicyVersion
                tenantOwnTermsOfServiceVersion
              }
              createdAt
              updatedAt
              tags {
                items {
                  id
                  userId
                  userTagId
                  tenantCode
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                  userTag {
                    id
                    color
                    text
                    tenantCode
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
              statuses {
                items {
                  id
                  tenantCode
                  status
                  userId
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getUserDevice = /* GraphQL */ `
  query GetUserDevice($userId: ID!) {
    getUserDevice(userId: $userId) {
      userId
      devices
      dataVersion
      tenantCode
      createdAt
      updatedAt
    }
  }
`;
export const listUserDevices = /* GraphQL */ `
  query ListUserDevices(
    $userId: ID
    $filter: ModelUserDeviceFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUserDevices(
      userId: $userId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        userId
        devices
        dataVersion
        tenantCode
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const userDevicesByTenantCode = /* GraphQL */ `
  query UserDevicesByTenantCode(
    $tenantCode: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserDeviceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userDevicesByTenantCode(
      tenantCode: $tenantCode
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        userId
        devices
        dataVersion
        tenantCode
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSignUpReservation = /* GraphQL */ `
  query GetSignUpReservation($id: ID!) {
    getSignUpReservation(id: $id) {
      id
      name
      key
      userCode
      email
      tenantCode
      type
      role
      userId
      signUpInput {
        name
        tenantName
      }
      status
      groups {
        id
        role
        removed
      }
      createdBy
      createdAt
      updatedAt
    }
  }
`;
export const listSignUpReservations = /* GraphQL */ `
  query ListSignUpReservations(
    $filter: ModelSignUpReservationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSignUpReservations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        key
        userCode
        email
        tenantCode
        type
        role
        userId
        signUpInput {
          name
          tenantName
        }
        status
        groups {
          id
          role
          removed
        }
        createdBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const signUpReservationByUserId = /* GraphQL */ `
  query SignUpReservationByUserId(
    $userId: String
    $sortDirection: ModelSortDirection
    $filter: ModelSignUpReservationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    signUpReservationByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        key
        userCode
        email
        tenantCode
        type
        role
        userId
        signUpInput {
          name
          tenantName
        }
        status
        groups {
          id
          role
          removed
        }
        createdBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const signUpReservationsByEmail = /* GraphQL */ `
  query SignUpReservationsByEmail(
    $email: String
    $sortDirection: ModelSortDirection
    $filter: ModelSignUpReservationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    signUpReservationsByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        key
        userCode
        email
        tenantCode
        type
        role
        userId
        signUpInput {
          name
          tenantName
        }
        status
        groups {
          id
          role
          removed
        }
        createdBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const signUpReservationsByTenantCode = /* GraphQL */ `
  query SignUpReservationsByTenantCode(
    $tenantCode: String
    $sortDirection: ModelSortDirection
    $filter: ModelSignUpReservationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    signUpReservationsByTenantCode(
      tenantCode: $tenantCode
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        key
        userCode
        email
        tenantCode
        type
        role
        userId
        signUpInput {
          name
          tenantName
        }
        status
        groups {
          id
          role
          removed
        }
        createdBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const signUpReservationByKey = /* GraphQL */ `
  query SignUpReservationByKey(
    $key: String
    $sortDirection: ModelSortDirection
    $filter: ModelSignUpReservationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    signUpReservationByKey(
      key: $key
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        key
        userCode
        email
        tenantCode
        type
        role
        userId
        signUpInput {
          name
          tenantName
        }
        status
        groups {
          id
          role
          removed
        }
        createdBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTenant = /* GraphQL */ `
  query GetTenant($id: ID!) {
    getTenant(id: $id) {
      id
      code
      name
      signUpType
      playground
      userLimit
      limitations
      createdAt
      updatedAt
    }
  }
`;
export const listTenants = /* GraphQL */ `
  query ListTenants(
    $filter: ModelTenantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTenants(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        code
        name
        signUpType
        playground
        userLimit
        limitations
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const tenantByCode = /* GraphQL */ `
  query TenantByCode(
    $code: String
    $sortDirection: ModelSortDirection
    $filter: ModelTenantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tenantByCode(
      code: $code
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        code
        name
        signUpType
        playground
        userLimit
        limitations
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTenantOwnTermsOfService = /* GraphQL */ `
  query GetTenantOwnTermsOfService($id: ID!) {
    getTenantOwnTermsOfService(id: $id) {
      id
      tenantCode
      type
      body
      createdBy
      createdAt
      updatedBy
      updatedAt
      version
    }
  }
`;
export const listTenantOwnTermsOfServices = /* GraphQL */ `
  query ListTenantOwnTermsOfServices(
    $filter: ModelTenantOwnTermsOfServiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTenantOwnTermsOfServices(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantCode
        type
        body
        createdBy
        createdAt
        updatedBy
        updatedAt
        version
      }
      nextToken
    }
  }
`;
export const getEvent = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      type
      event
      version
      tenantCode
      occurredBy
      createdAt
      updatedAt
    }
  }
`;
export const listEvents = /* GraphQL */ `
  query ListEvents(
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        event
        version
        tenantCode
        occurredBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const eventsByOccurredBy = /* GraphQL */ `
  query EventsByOccurredBy(
    $occurredBy: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventsByOccurredBy(
      occurredBy: $occurredBy
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        event
        version
        tenantCode
        occurredBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const eventsByType = /* GraphQL */ `
  query EventsByType(
    $type: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventsByType(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        event
        version
        tenantCode
        occurredBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTermsOfService = /* GraphQL */ `
  query GetTermsOfService($id: ID!) {
    getTermsOfService(id: $id) {
      id
      body
      createdAt
      updatedAt
    }
  }
`;
export const listTermsOfServices = /* GraphQL */ `
  query ListTermsOfServices(
    $filter: ModelTermsOfServiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTermsOfServices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        body
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserTag = /* GraphQL */ `
  query GetUserTag($id: ID!) {
    getUserTag(id: $id) {
      id
      color
      text
      tenantCode
      createdAt
      updatedAt
      users {
        items {
          id
          userId
          userTagId
          tenantCode
          createdAt
          updatedAt
          user {
            id
            name
            role
            extensionConfigJsonList
            email
            code
            tenantCode
            displaySettings {
              theme
              color
            }
            locale
            avatar
            enabled
            signedInAtLeastOnce
            emailConfirmed
            confirmedTermsOfServiceVersions {
              termsOfServiceVersion
              privacyPolicyVersion
              tenantOwnTermsOfServiceVersion
            }
            createdAt
            updatedAt
            tags {
              items {
                id
                userId
                userTagId
                tenantCode
                createdAt
                updatedAt
                user {
                  id
                  name
                  role
                  extensionConfigJsonList
                  email
                  code
                  tenantCode
                  displaySettings {
                    theme
                    color
                  }
                  locale
                  avatar
                  enabled
                  signedInAtLeastOnce
                  emailConfirmed
                  confirmedTermsOfServiceVersions {
                    termsOfServiceVersion
                    privacyPolicyVersion
                    tenantOwnTermsOfServiceVersion
                  }
                  createdAt
                  updatedAt
                  tags {
                    nextToken
                  }
                  statuses {
                    nextToken
                  }
                }
                userTag {
                  id
                  color
                  text
                  tenantCode
                  createdAt
                  updatedAt
                  users {
                    nextToken
                  }
                }
              }
              nextToken
            }
            statuses {
              items {
                id
                tenantCode
                status
                userId
                createdAt
                updatedAt
                user {
                  id
                  name
                  role
                  extensionConfigJsonList
                  email
                  code
                  tenantCode
                  displaySettings {
                    theme
                    color
                  }
                  locale
                  avatar
                  enabled
                  signedInAtLeastOnce
                  emailConfirmed
                  confirmedTermsOfServiceVersions {
                    termsOfServiceVersion
                    privacyPolicyVersion
                    tenantOwnTermsOfServiceVersion
                  }
                  createdAt
                  updatedAt
                  tags {
                    nextToken
                  }
                  statuses {
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
          userTag {
            id
            color
            text
            tenantCode
            createdAt
            updatedAt
            users {
              items {
                id
                userId
                userTagId
                tenantCode
                createdAt
                updatedAt
                user {
                  id
                  name
                  role
                  extensionConfigJsonList
                  email
                  code
                  tenantCode
                  displaySettings {
                    theme
                    color
                  }
                  locale
                  avatar
                  enabled
                  signedInAtLeastOnce
                  emailConfirmed
                  confirmedTermsOfServiceVersions {
                    termsOfServiceVersion
                    privacyPolicyVersion
                    tenantOwnTermsOfServiceVersion
                  }
                  createdAt
                  updatedAt
                  tags {
                    nextToken
                  }
                  statuses {
                    nextToken
                  }
                }
                userTag {
                  id
                  color
                  text
                  tenantCode
                  createdAt
                  updatedAt
                  users {
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const listUserTags = /* GraphQL */ `
  query ListUserTags(
    $filter: ModelUserTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        color
        text
        tenantCode
        createdAt
        updatedAt
        users {
          items {
            id
            userId
            userTagId
            tenantCode
            createdAt
            updatedAt
            user {
              id
              name
              role
              extensionConfigJsonList
              email
              code
              tenantCode
              displaySettings {
                theme
                color
              }
              locale
              avatar
              enabled
              signedInAtLeastOnce
              emailConfirmed
              confirmedTermsOfServiceVersions {
                termsOfServiceVersion
                privacyPolicyVersion
                tenantOwnTermsOfServiceVersion
              }
              createdAt
              updatedAt
              tags {
                items {
                  id
                  userId
                  userTagId
                  tenantCode
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                  userTag {
                    id
                    color
                    text
                    tenantCode
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
              statuses {
                items {
                  id
                  tenantCode
                  status
                  userId
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
            userTag {
              id
              color
              text
              tenantCode
              createdAt
              updatedAt
              users {
                items {
                  id
                  userId
                  userTagId
                  tenantCode
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                  userTag {
                    id
                    color
                    text
                    tenantCode
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const userTagsByTenantCode = /* GraphQL */ `
  query UserTagsByTenantCode(
    $tenantCode: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userTagsByTenantCode(
      tenantCode: $tenantCode
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        color
        text
        tenantCode
        createdAt
        updatedAt
        users {
          items {
            id
            userId
            userTagId
            tenantCode
            createdAt
            updatedAt
            user {
              id
              name
              role
              extensionConfigJsonList
              email
              code
              tenantCode
              displaySettings {
                theme
                color
              }
              locale
              avatar
              enabled
              signedInAtLeastOnce
              emailConfirmed
              confirmedTermsOfServiceVersions {
                termsOfServiceVersion
                privacyPolicyVersion
                tenantOwnTermsOfServiceVersion
              }
              createdAt
              updatedAt
              tags {
                items {
                  id
                  userId
                  userTagId
                  tenantCode
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                  userTag {
                    id
                    color
                    text
                    tenantCode
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
              statuses {
                items {
                  id
                  tenantCode
                  status
                  userId
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
            userTag {
              id
              color
              text
              tenantCode
              createdAt
              updatedAt
              users {
                items {
                  id
                  userId
                  userTagId
                  tenantCode
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                  userTag {
                    id
                    color
                    text
                    tenantCode
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getUserStatusTable = /* GraphQL */ `
  query GetUserStatusTable($id: ID!) {
    getUserStatusTable(id: $id) {
      id
      tenantCode
      status
      userId
      createdAt
      updatedAt
      user {
        id
        name
        role
        extensionConfigJsonList
        email
        code
        tenantCode
        displaySettings {
          theme
          color
        }
        locale
        avatar
        enabled
        signedInAtLeastOnce
        emailConfirmed
        confirmedTermsOfServiceVersions {
          termsOfServiceVersion
          privacyPolicyVersion
          tenantOwnTermsOfServiceVersion
        }
        createdAt
        updatedAt
        tags {
          items {
            id
            userId
            userTagId
            tenantCode
            createdAt
            updatedAt
            user {
              id
              name
              role
              extensionConfigJsonList
              email
              code
              tenantCode
              displaySettings {
                theme
                color
              }
              locale
              avatar
              enabled
              signedInAtLeastOnce
              emailConfirmed
              confirmedTermsOfServiceVersions {
                termsOfServiceVersion
                privacyPolicyVersion
                tenantOwnTermsOfServiceVersion
              }
              createdAt
              updatedAt
              tags {
                items {
                  id
                  userId
                  userTagId
                  tenantCode
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                  userTag {
                    id
                    color
                    text
                    tenantCode
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
              statuses {
                items {
                  id
                  tenantCode
                  status
                  userId
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
            userTag {
              id
              color
              text
              tenantCode
              createdAt
              updatedAt
              users {
                items {
                  id
                  userId
                  userTagId
                  tenantCode
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                  userTag {
                    id
                    color
                    text
                    tenantCode
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
          }
          nextToken
        }
        statuses {
          items {
            id
            tenantCode
            status
            userId
            createdAt
            updatedAt
            user {
              id
              name
              role
              extensionConfigJsonList
              email
              code
              tenantCode
              displaySettings {
                theme
                color
              }
              locale
              avatar
              enabled
              signedInAtLeastOnce
              emailConfirmed
              confirmedTermsOfServiceVersions {
                termsOfServiceVersion
                privacyPolicyVersion
                tenantOwnTermsOfServiceVersion
              }
              createdAt
              updatedAt
              tags {
                items {
                  id
                  userId
                  userTagId
                  tenantCode
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                  userTag {
                    id
                    color
                    text
                    tenantCode
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
              statuses {
                items {
                  id
                  tenantCode
                  status
                  userId
                  createdAt
                  updatedAt
                  user {
                    id
                    name
                    role
                    extensionConfigJsonList
                    email
                    code
                    tenantCode
                    locale
                    avatar
                    enabled
                    signedInAtLeastOnce
                    emailConfirmed
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const listUserStatusTables = /* GraphQL */ `
  query ListUserStatusTables(
    $filter: ModelUserStatusTableFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserStatusTables(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantCode
        status
        userId
        createdAt
        updatedAt
        user {
          id
          name
          role
          extensionConfigJsonList
          email
          code
          tenantCode
          displaySettings {
            theme
            color
          }
          locale
          avatar
          enabled
          signedInAtLeastOnce
          emailConfirmed
          confirmedTermsOfServiceVersions {
            termsOfServiceVersion
            privacyPolicyVersion
            tenantOwnTermsOfServiceVersion
          }
          createdAt
          updatedAt
          tags {
            items {
              id
              userId
              userTagId
              tenantCode
              createdAt
              updatedAt
              user {
                id
                name
                role
                extensionConfigJsonList
                email
                code
                tenantCode
                displaySettings {
                  theme
                  color
                }
                locale
                avatar
                enabled
                signedInAtLeastOnce
                emailConfirmed
                confirmedTermsOfServiceVersions {
                  termsOfServiceVersion
                  privacyPolicyVersion
                  tenantOwnTermsOfServiceVersion
                }
                createdAt
                updatedAt
                tags {
                  items {
                    id
                    userId
                    userTagId
                    tenantCode
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                statuses {
                  items {
                    id
                    tenantCode
                    status
                    userId
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
              }
              userTag {
                id
                color
                text
                tenantCode
                createdAt
                updatedAt
                users {
                  items {
                    id
                    userId
                    userTagId
                    tenantCode
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
              }
            }
            nextToken
          }
          statuses {
            items {
              id
              tenantCode
              status
              userId
              createdAt
              updatedAt
              user {
                id
                name
                role
                extensionConfigJsonList
                email
                code
                tenantCode
                displaySettings {
                  theme
                  color
                }
                locale
                avatar
                enabled
                signedInAtLeastOnce
                emailConfirmed
                confirmedTermsOfServiceVersions {
                  termsOfServiceVersion
                  privacyPolicyVersion
                  tenantOwnTermsOfServiceVersion
                }
                createdAt
                updatedAt
                tags {
                  items {
                    id
                    userId
                    userTagId
                    tenantCode
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                statuses {
                  items {
                    id
                    tenantCode
                    status
                    userId
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
              }
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const userStatusTablesByUser = /* GraphQL */ `
  query UserStatusTablesByUser(
    $userId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelUserStatusTableFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userStatusTablesByUser(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantCode
        status
        userId
        createdAt
        updatedAt
        user {
          id
          name
          role
          extensionConfigJsonList
          email
          code
          tenantCode
          displaySettings {
            theme
            color
          }
          locale
          avatar
          enabled
          signedInAtLeastOnce
          emailConfirmed
          confirmedTermsOfServiceVersions {
            termsOfServiceVersion
            privacyPolicyVersion
            tenantOwnTermsOfServiceVersion
          }
          createdAt
          updatedAt
          tags {
            items {
              id
              userId
              userTagId
              tenantCode
              createdAt
              updatedAt
              user {
                id
                name
                role
                extensionConfigJsonList
                email
                code
                tenantCode
                displaySettings {
                  theme
                  color
                }
                locale
                avatar
                enabled
                signedInAtLeastOnce
                emailConfirmed
                confirmedTermsOfServiceVersions {
                  termsOfServiceVersion
                  privacyPolicyVersion
                  tenantOwnTermsOfServiceVersion
                }
                createdAt
                updatedAt
                tags {
                  items {
                    id
                    userId
                    userTagId
                    tenantCode
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                statuses {
                  items {
                    id
                    tenantCode
                    status
                    userId
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
              }
              userTag {
                id
                color
                text
                tenantCode
                createdAt
                updatedAt
                users {
                  items {
                    id
                    userId
                    userTagId
                    tenantCode
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
              }
            }
            nextToken
          }
          statuses {
            items {
              id
              tenantCode
              status
              userId
              createdAt
              updatedAt
              user {
                id
                name
                role
                extensionConfigJsonList
                email
                code
                tenantCode
                displaySettings {
                  theme
                  color
                }
                locale
                avatar
                enabled
                signedInAtLeastOnce
                emailConfirmed
                confirmedTermsOfServiceVersions {
                  termsOfServiceVersion
                  privacyPolicyVersion
                  tenantOwnTermsOfServiceVersion
                }
                createdAt
                updatedAt
                tags {
                  items {
                    id
                    userId
                    userTagId
                    tenantCode
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                statuses {
                  items {
                    id
                    tenantCode
                    status
                    userId
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
              }
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const userStatusTablesByTenantCode = /* GraphQL */ `
  query UserStatusTablesByTenantCode(
    $tenantCode: String
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserStatusTableFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userStatusTablesByTenantCode(
      tenantCode: $tenantCode
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantCode
        status
        userId
        createdAt
        updatedAt
        user {
          id
          name
          role
          extensionConfigJsonList
          email
          code
          tenantCode
          displaySettings {
            theme
            color
          }
          locale
          avatar
          enabled
          signedInAtLeastOnce
          emailConfirmed
          confirmedTermsOfServiceVersions {
            termsOfServiceVersion
            privacyPolicyVersion
            tenantOwnTermsOfServiceVersion
          }
          createdAt
          updatedAt
          tags {
            items {
              id
              userId
              userTagId
              tenantCode
              createdAt
              updatedAt
              user {
                id
                name
                role
                extensionConfigJsonList
                email
                code
                tenantCode
                displaySettings {
                  theme
                  color
                }
                locale
                avatar
                enabled
                signedInAtLeastOnce
                emailConfirmed
                confirmedTermsOfServiceVersions {
                  termsOfServiceVersion
                  privacyPolicyVersion
                  tenantOwnTermsOfServiceVersion
                }
                createdAt
                updatedAt
                tags {
                  items {
                    id
                    userId
                    userTagId
                    tenantCode
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                statuses {
                  items {
                    id
                    tenantCode
                    status
                    userId
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
              }
              userTag {
                id
                color
                text
                tenantCode
                createdAt
                updatedAt
                users {
                  items {
                    id
                    userId
                    userTagId
                    tenantCode
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
              }
            }
            nextToken
          }
          statuses {
            items {
              id
              tenantCode
              status
              userId
              createdAt
              updatedAt
              user {
                id
                name
                role
                extensionConfigJsonList
                email
                code
                tenantCode
                displaySettings {
                  theme
                  color
                }
                locale
                avatar
                enabled
                signedInAtLeastOnce
                emailConfirmed
                confirmedTermsOfServiceVersions {
                  termsOfServiceVersion
                  privacyPolicyVersion
                  tenantOwnTermsOfServiceVersion
                }
                createdAt
                updatedAt
                tags {
                  items {
                    id
                    userId
                    userTagId
                    tenantCode
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                statuses {
                  items {
                    id
                    tenantCode
                    status
                    userId
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
              }
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getGroupStatusUser = /* GraphQL */ `
  query GetGroupStatusUser($id: ID!) {
    getGroupStatusUser(id: $id) {
      id
      userId
      groupId
      handStatus
      tenantCode
      createdAt
      updatedAt
    }
  }
`;
export const listGroupStatusUsers = /* GraphQL */ `
  query ListGroupStatusUsers(
    $filter: ModelGroupStatusUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroupStatusUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        groupId
        handStatus
        tenantCode
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const groupStatusUsersByUser = /* GraphQL */ `
  query GroupStatusUsersByUser(
    $userId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelGroupStatusUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    groupStatusUsersByUser(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        groupId
        handStatus
        tenantCode
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const groupStatusUsersByGroup = /* GraphQL */ `
  query GroupStatusUsersByGroup(
    $groupId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelGroupStatusUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    groupStatusUsersByGroup(
      groupId: $groupId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        groupId
        handStatus
        tenantCode
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const groupStatusUsersByTenantCode = /* GraphQL */ `
  query GroupStatusUsersByTenantCode(
    $tenantCode: String
    $sortDirection: ModelSortDirection
    $filter: ModelGroupStatusUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    groupStatusUsersByTenantCode(
      tenantCode: $tenantCode
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        groupId
        handStatus
        tenantCode
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGroupStatus = /* GraphQL */ `
  query GetGroupStatus($groupId: ID!) {
    getGroupStatus(groupId: $groupId) {
      groupId
      tenantCode
      createdAt
      updatedAt
      users {
        items {
          id
          userId
          groupId
          handStatus
          tenantCode
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listGroupStatuss = /* GraphQL */ `
  query ListGroupStatuss(
    $groupId: ID
    $filter: ModelGroupStatusFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listGroupStatuss(
      groupId: $groupId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        groupId
        tenantCode
        createdAt
        updatedAt
        users {
          items {
            id
            userId
            groupId
            handStatus
            tenantCode
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const groupStatusesByTenantCode = /* GraphQL */ `
  query GroupStatusesByTenantCode(
    $tenantCode: String
    $sortDirection: ModelSortDirection
    $filter: ModelGroupStatusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    groupStatusesByTenantCode(
      tenantCode: $tenantCode
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        groupId
        tenantCode
        createdAt
        updatedAt
        users {
          items {
            id
            userId
            groupId
            handStatus
            tenantCode
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getInternalTask = /* GraphQL */ `
  query GetInternalTask($id: ID!) {
    getInternalTask(id: $id) {
      id
      type
      createdAt
      createdBy
      finishedAt
      status
      taskVersion
      payload
      tenantCode
      updatedAt
    }
  }
`;
export const listInternalTasks = /* GraphQL */ `
  query ListInternalTasks(
    $filter: ModelInternalTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInternalTasks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        createdAt
        createdBy
        finishedAt
        status
        taskVersion
        payload
        tenantCode
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPlayground = /* GraphQL */ `
  query GetPlayground($id: ID!) {
    getPlayground(id: $id) {
      id
      status
      email
      tenantCode
      tenantName
      userCode
      temporaryPassword
      createdAt
      updatedAt
    }
  }
`;
export const listPlaygrounds = /* GraphQL */ `
  query ListPlaygrounds(
    $filter: ModelPlaygroundFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPlaygrounds(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        status
        email
        tenantCode
        tenantName
        userCode
        temporaryPassword
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const playgroundsByTenantCode = /* GraphQL */ `
  query PlaygroundsByTenantCode(
    $tenantCode: String
    $sortDirection: ModelSortDirection
    $filter: ModelPlaygroundFilterInput
    $limit: Int
    $nextToken: String
  ) {
    playgroundsByTenantCode(
      tenantCode: $tenantCode
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        status
        email
        tenantCode
        tenantName
        userCode
        temporaryPassword
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const playgroundsByStatus = /* GraphQL */ `
  query PlaygroundsByStatus(
    $status: PlaygroundStatus
    $sortDirection: ModelSortDirection
    $filter: ModelPlaygroundFilterInput
    $limit: Int
    $nextToken: String
  ) {
    playgroundsByStatus(
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        status
        email
        tenantCode
        tenantName
        userCode
        temporaryPassword
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getContent = /* GraphQL */ `
  query GetContent($id: ID!) {
    getContent(id: $id) {
      id
      contentId
      version
      name
      requiredTime
      body {
        type
        text {
          body
        }
        exam {
          problems {
            index
            body
            commentary
            type
            choice {
              options {
                index
                text
              }
              multiple
              answer
            }
            headerId
          }
          problemHeaders {
            id
            body
            createdAt
          }
          passingStandard
        }
      }
      workbook {
        problems {
          index
          body
          commentary
          type
          choice {
            options {
              index
              text
            }
            multiple
            answer
          }
          headerId
        }
        problemHeaders {
          id
          body
          createdAt
        }
      }
      courseId
      courseVersion
      tenantCode
      latest
      versionDescription
      createdAt
      updatedAt
    }
  }
`;
export const listContents = /* GraphQL */ `
  query ListContents(
    $filter: ModelContentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        contentId
        version
        name
        requiredTime
        body {
          type
          text {
            body
          }
          exam {
            problems {
              index
              body
              commentary
              type
              choice {
                options {
                  index
                  text
                }
                multiple
                answer
              }
              headerId
            }
            problemHeaders {
              id
              body
              createdAt
            }
            passingStandard
          }
        }
        workbook {
          problems {
            index
            body
            commentary
            type
            choice {
              options {
                index
                text
              }
              multiple
              answer
            }
            headerId
          }
          problemHeaders {
            id
            body
            createdAt
          }
        }
        courseId
        courseVersion
        tenantCode
        latest
        versionDescription
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const contentByCourse = /* GraphQL */ `
  query ContentByCourse(
    $courseId: ID
    $courseVersion: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelContentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    contentByCourse(
      courseId: $courseId
      courseVersion: $courseVersion
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        contentId
        version
        name
        requiredTime
        body {
          type
          text {
            body
          }
          exam {
            problems {
              index
              body
              commentary
              type
              choice {
                options {
                  index
                  text
                }
                multiple
                answer
              }
              headerId
            }
            problemHeaders {
              id
              body
              createdAt
            }
            passingStandard
          }
        }
        workbook {
          problems {
            index
            body
            commentary
            type
            choice {
              options {
                index
                text
              }
              multiple
              answer
            }
            headerId
          }
          problemHeaders {
            id
            body
            createdAt
          }
        }
        courseId
        courseVersion
        tenantCode
        latest
        versionDescription
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const contentsByTenantCode = /* GraphQL */ `
  query ContentsByTenantCode(
    $tenantCode: String
    $sortDirection: ModelSortDirection
    $filter: ModelContentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    contentsByTenantCode(
      tenantCode: $tenantCode
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        contentId
        version
        name
        requiredTime
        body {
          type
          text {
            body
          }
          exam {
            problems {
              index
              body
              commentary
              type
              choice {
                options {
                  index
                  text
                }
                multiple
                answer
              }
              headerId
            }
            problemHeaders {
              id
              body
              createdAt
            }
            passingStandard
          }
        }
        workbook {
          problems {
            index
            body
            commentary
            type
            choice {
              options {
                index
                text
              }
              multiple
              answer
            }
            headerId
          }
          problemHeaders {
            id
            body
            createdAt
          }
        }
        courseId
        courseVersion
        tenantCode
        latest
        versionDescription
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const contentsByContent = /* GraphQL */ `
  query ContentsByContent(
    $contentId: ID
    $version: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelContentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    contentsByContent(
      contentId: $contentId
      version: $version
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        contentId
        version
        name
        requiredTime
        body {
          type
          text {
            body
          }
          exam {
            problems {
              index
              body
              commentary
              type
              choice {
                options {
                  index
                  text
                }
                multiple
                answer
              }
              headerId
            }
            problemHeaders {
              id
              body
              createdAt
            }
            passingStandard
          }
        }
        workbook {
          problems {
            index
            body
            commentary
            type
            choice {
              options {
                index
                text
              }
              multiple
              answer
            }
            headerId
          }
          problemHeaders {
            id
            body
            createdAt
          }
        }
        courseId
        courseVersion
        tenantCode
        latest
        versionDescription
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCourse = /* GraphQL */ `
  query GetCourse($id: ID!, $version: Int!) {
    getCourse(id: $id, version: $version) {
      id
      version
      name
      contents {
        id
        type
        name
        requiredTime
      }
      description
      tenantCode
      color
      image
      fontColorOnImage
      confirmedBy
      createdAt
      versionCreatedBy
      versionCreatedAt
      contentLastUpdatedBy
      contentLastUpdatedAt
      updatedAt
    }
  }
`;
export const listCourses = /* GraphQL */ `
  query ListCourses(
    $id: ID
    $version: ModelIntKeyConditionInput
    $filter: ModelCourseFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCourses(
      id: $id
      version: $version
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        version
        name
        contents {
          id
          type
          name
          requiredTime
        }
        description
        tenantCode
        color
        image
        fontColorOnImage
        confirmedBy
        createdAt
        versionCreatedBy
        versionCreatedAt
        contentLastUpdatedBy
        contentLastUpdatedAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const coursesByName = /* GraphQL */ `
  query CoursesByName(
    $name: String
    $sortDirection: ModelSortDirection
    $filter: ModelCourseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    coursesByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        version
        name
        contents {
          id
          type
          name
          requiredTime
        }
        description
        tenantCode
        color
        image
        fontColorOnImage
        confirmedBy
        createdAt
        versionCreatedBy
        versionCreatedAt
        contentLastUpdatedBy
        contentLastUpdatedAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const coursesByTenantCode = /* GraphQL */ `
  query CoursesByTenantCode(
    $tenantCode: String
    $sortDirection: ModelSortDirection
    $filter: ModelCourseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    coursesByTenantCode(
      tenantCode: $tenantCode
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        version
        name
        contents {
          id
          type
          name
          requiredTime
        }
        description
        tenantCode
        color
        image
        fontColorOnImage
        confirmedBy
        createdAt
        versionCreatedBy
        versionCreatedAt
        contentLastUpdatedBy
        contentLastUpdatedAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEditingCourse = /* GraphQL */ `
  query GetEditingCourse($id: ID!) {
    getEditingCourse(id: $id) {
      id
      tenantCode
      name
      version
      status
      description
      contents {
        id
        name
        requiredTime
        type
      }
      color
      image
      fontColorOnImage
      createdBy
      createdAt
      updatedBy
      updatedAt
    }
  }
`;
export const listEditingCourses = /* GraphQL */ `
  query ListEditingCourses(
    $filter: ModelEditingCourseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEditingCourses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantCode
        name
        version
        status
        description
        contents {
          id
          name
          requiredTime
          type
        }
        color
        image
        fontColorOnImage
        createdBy
        createdAt
        updatedBy
        updatedAt
      }
      nextToken
    }
  }
`;
export const editingCoursesByTenantCode = /* GraphQL */ `
  query EditingCoursesByTenantCode(
    $tenantCode: String
    $sortDirection: ModelSortDirection
    $filter: ModelEditingCourseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    editingCoursesByTenantCode(
      tenantCode: $tenantCode
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantCode
        name
        version
        status
        description
        contents {
          id
          name
          requiredTime
          type
        }
        color
        image
        fontColorOnImage
        createdBy
        createdAt
        updatedBy
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEditingCourseContentWorkbook = /* GraphQL */ `
  query GetEditingCourseContentWorkbook($contentId: ID!) {
    getEditingCourseContentWorkbook(contentId: $contentId) {
      contentId
      problems {
        index
        body
        commentary
        type
        choice {
          options {
            index
            text
          }
          multiple
          answer
        }
        headerId
      }
      problemHeaders {
        id
        body
        createdAt
      }
      dataVersion
      createdBy
      updatedBy
      createdAt
      updatedAt
      tenantCode
      courseId
    }
  }
`;
export const listEditingCourseContentWorkbooks = /* GraphQL */ `
  query ListEditingCourseContentWorkbooks(
    $contentId: ID
    $filter: ModelEditingCourseContentWorkbookFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEditingCourseContentWorkbooks(
      contentId: $contentId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        contentId
        problems {
          index
          body
          commentary
          type
          choice {
            options {
              index
              text
            }
            multiple
            answer
          }
          headerId
        }
        problemHeaders {
          id
          body
          createdAt
        }
        dataVersion
        createdBy
        updatedBy
        createdAt
        updatedAt
        tenantCode
        courseId
      }
      nextToken
    }
  }
`;
export const editingCourseContentWorkbooksByCourse = /* GraphQL */ `
  query EditingCourseContentWorkbooksByCourse(
    $courseId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelEditingCourseContentWorkbookFilterInput
    $limit: Int
    $nextToken: String
  ) {
    editingCourseContentWorkbooksByCourse(
      courseId: $courseId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        contentId
        problems {
          index
          body
          commentary
          type
          choice {
            options {
              index
              text
            }
            multiple
            answer
          }
          headerId
        }
        problemHeaders {
          id
          body
          createdAt
        }
        dataVersion
        createdBy
        updatedBy
        createdAt
        updatedAt
        tenantCode
        courseId
      }
      nextToken
    }
  }
`;
export const getEditingCourseContentBody = /* GraphQL */ `
  query GetEditingCourseContentBody($contentId: ID!) {
    getEditingCourseContentBody(contentId: $contentId) {
      contentId
      body {
        type
        text {
          body
        }
        exam {
          problems {
            index
            body
            commentary
            type
            choice {
              options {
                index
                text
              }
              multiple
              answer
            }
            headerId
          }
          problemHeaders {
            id
            body
            createdAt
          }
          passingStandard
        }
      }
      dataVersion
      createdBy
      updatedBy
      createdAt
      updatedAt
      tenantCode
      courseId
    }
  }
`;
export const listEditingCourseContentBodys = /* GraphQL */ `
  query ListEditingCourseContentBodys(
    $contentId: ID
    $filter: ModelEditingCourseContentBodyFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEditingCourseContentBodys(
      contentId: $contentId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        contentId
        body {
          type
          text {
            body
          }
          exam {
            problems {
              index
              body
              commentary
              type
              choice {
                options {
                  index
                  text
                }
                multiple
                answer
              }
              headerId
            }
            problemHeaders {
              id
              body
              createdAt
            }
            passingStandard
          }
        }
        dataVersion
        createdBy
        updatedBy
        createdAt
        updatedAt
        tenantCode
        courseId
      }
      nextToken
    }
  }
`;
export const editingCourseContentBodiesByCourse = /* GraphQL */ `
  query EditingCourseContentBodiesByCourse(
    $courseId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelEditingCourseContentBodyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    editingCourseContentBodiesByCourse(
      courseId: $courseId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        contentId
        body {
          type
          text {
            body
          }
          exam {
            problems {
              index
              body
              commentary
              type
              choice {
                options {
                  index
                  text
                }
                multiple
                answer
              }
              headerId
            }
            problemHeaders {
              id
              body
              createdAt
            }
            passingStandard
          }
        }
        dataVersion
        createdBy
        updatedBy
        createdAt
        updatedAt
        tenantCode
        courseId
      }
      nextToken
    }
  }
`;
export const getCourseHeader = /* GraphQL */ `
  query GetCourseHeader($id: ID!) {
    getCourseHeader(id: $id) {
      id
      name
      activeVersion
      status
      originalCourseId
      originalCourseVersion
      tenantCode
      createdBy
      createdAt
      updatedAt
    }
  }
`;
export const listCourseHeaders = /* GraphQL */ `
  query ListCourseHeaders(
    $filter: ModelCourseHeaderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCourseHeaders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        activeVersion
        status
        originalCourseId
        originalCourseVersion
        tenantCode
        createdBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const courseHeaderByName = /* GraphQL */ `
  query CourseHeaderByName(
    $name: String
    $sortDirection: ModelSortDirection
    $filter: ModelCourseHeaderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    courseHeaderByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        activeVersion
        status
        originalCourseId
        originalCourseVersion
        tenantCode
        createdBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const courseHeaderByTenantCode = /* GraphQL */ `
  query CourseHeaderByTenantCode(
    $tenantCode: String
    $sortDirection: ModelSortDirection
    $filter: ModelCourseHeaderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    courseHeaderByTenantCode(
      tenantCode: $tenantCode
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        activeVersion
        status
        originalCourseId
        originalCourseVersion
        tenantCode
        createdBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEditingConfirmedContent = /* GraphQL */ `
  query GetEditingConfirmedContent($id: ID!) {
    getEditingConfirmedContent(id: $id) {
      id
      version
      name
      requiredTime
      body {
        type
        text {
          body
        }
        exam {
          problems {
            index
            body
            commentary
            type
            choice {
              options {
                index
                text
              }
              multiple
              answer
            }
            headerId
          }
          problemHeaders {
            id
            body
            createdAt
          }
          passingStandard
        }
      }
      workbook {
        problems {
          index
          body
          commentary
          type
          choice {
            options {
              index
              text
            }
            multiple
            answer
          }
          headerId
        }
        problemHeaders {
          id
          body
          createdAt
        }
      }
      courseId
      courseVersion
      status
      tenantCode
      createdBy
      updatedBy
      dataVersion
      versionDescription
      createdAt
      updatedAt
    }
  }
`;
export const listEditingConfirmedContents = /* GraphQL */ `
  query ListEditingConfirmedContents(
    $filter: ModelEditingConfirmedContentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEditingConfirmedContents(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        version
        name
        requiredTime
        body {
          type
          text {
            body
          }
          exam {
            problems {
              index
              body
              commentary
              type
              choice {
                options {
                  index
                  text
                }
                multiple
                answer
              }
              headerId
            }
            problemHeaders {
              id
              body
              createdAt
            }
            passingStandard
          }
        }
        workbook {
          problems {
            index
            body
            commentary
            type
            choice {
              options {
                index
                text
              }
              multiple
              answer
            }
            headerId
          }
          problemHeaders {
            id
            body
            createdAt
          }
        }
        courseId
        courseVersion
        status
        tenantCode
        createdBy
        updatedBy
        dataVersion
        versionDescription
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const editingConfirmedContentsByTenantCode = /* GraphQL */ `
  query EditingConfirmedContentsByTenantCode(
    $tenantCode: String
    $sortDirection: ModelSortDirection
    $filter: ModelEditingConfirmedContentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    editingConfirmedContentsByTenantCode(
      tenantCode: $tenantCode
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        version
        name
        requiredTime
        body {
          type
          text {
            body
          }
          exam {
            problems {
              index
              body
              commentary
              type
              choice {
                options {
                  index
                  text
                }
                multiple
                answer
              }
              headerId
            }
            problemHeaders {
              id
              body
              createdAt
            }
            passingStandard
          }
        }
        workbook {
          problems {
            index
            body
            commentary
            type
            choice {
              options {
                index
                text
              }
              multiple
              answer
            }
            headerId
          }
          problemHeaders {
            id
            body
            createdAt
          }
        }
        courseId
        courseVersion
        status
        tenantCode
        createdBy
        updatedBy
        dataVersion
        versionDescription
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const editingConfirmedContentsByCourse = /* GraphQL */ `
  query EditingConfirmedContentsByCourse(
    $courseId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelEditingConfirmedContentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    editingConfirmedContentsByCourse(
      courseId: $courseId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        version
        name
        requiredTime
        body {
          type
          text {
            body
          }
          exam {
            problems {
              index
              body
              commentary
              type
              choice {
                options {
                  index
                  text
                }
                multiple
                answer
              }
              headerId
            }
            problemHeaders {
              id
              body
              createdAt
            }
            passingStandard
          }
        }
        workbook {
          problems {
            index
            body
            commentary
            type
            choice {
              options {
                index
                text
              }
              multiple
              answer
            }
            headerId
          }
          problemHeaders {
            id
            body
            createdAt
          }
        }
        courseId
        courseVersion
        status
        tenantCode
        createdBy
        updatedBy
        dataVersion
        versionDescription
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getExamResult = /* GraphQL */ `
  query GetExamResult($id: ID!) {
    getExamResult(id: $id) {
      id
      groupId
      courseId
      contentId
      contentVersion
      userId
      start
      end
      score
      problemCount
      tenantCode
      answers
      visibilityLevel
      groupExamId
      times
      passingStandard
      version
      createdAt
      updatedAt
    }
  }
`;
export const listExamResults = /* GraphQL */ `
  query ListExamResults(
    $filter: ModelExamResultFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExamResults(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        groupId
        courseId
        contentId
        contentVersion
        userId
        start
        end
        score
        problemCount
        tenantCode
        answers
        visibilityLevel
        groupExamId
        times
        passingStandard
        version
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const examResultsByUserId = /* GraphQL */ `
  query ExamResultsByUserId(
    $groupId: ID
    $userId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelExamResultFilterInput
    $limit: Int
    $nextToken: String
  ) {
    examResultsByUserId(
      groupId: $groupId
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupId
        courseId
        contentId
        contentVersion
        userId
        start
        end
        score
        problemCount
        tenantCode
        answers
        visibilityLevel
        groupExamId
        times
        passingStandard
        version
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const examResultsByGroupAndContent = /* GraphQL */ `
  query ExamResultsByGroupAndContent(
    $groupId: ID
    $contentId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelExamResultFilterInput
    $limit: Int
    $nextToken: String
  ) {
    examResultsByGroupAndContent(
      groupId: $groupId
      contentId: $contentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupId
        courseId
        contentId
        contentVersion
        userId
        start
        end
        score
        problemCount
        tenantCode
        answers
        visibilityLevel
        groupExamId
        times
        passingStandard
        version
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const examResultsByGroupAndCourse = /* GraphQL */ `
  query ExamResultsByGroupAndCourse(
    $groupId: ID
    $courseId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelExamResultFilterInput
    $limit: Int
    $nextToken: String
  ) {
    examResultsByGroupAndCourse(
      groupId: $groupId
      courseId: $courseId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupId
        courseId
        contentId
        contentVersion
        userId
        start
        end
        score
        problemCount
        tenantCode
        answers
        visibilityLevel
        groupExamId
        times
        passingStandard
        version
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const examResultsByGroupExam = /* GraphQL */ `
  query ExamResultsByGroupExam(
    $groupExamId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelExamResultFilterInput
    $limit: Int
    $nextToken: String
  ) {
    examResultsByGroupExam(
      groupExamId: $groupExamId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupId
        courseId
        contentId
        contentVersion
        userId
        start
        end
        score
        problemCount
        tenantCode
        answers
        visibilityLevel
        groupExamId
        times
        passingStandard
        version
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGroupTraining = /* GraphQL */ `
  query GetGroupTraining($id: ID!) {
    getGroupTraining(id: $id) {
      id
      groupId
      tenantCode
      createdAt
      updatedAt
    }
  }
`;
export const listGroupTrainings = /* GraphQL */ `
  query ListGroupTrainings(
    $filter: ModelGroupTrainingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroupTrainings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        groupId
        tenantCode
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const groupTrainingByGroupId = /* GraphQL */ `
  query GroupTrainingByGroupId(
    $groupId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelGroupTrainingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    groupTrainingByGroupId(
      groupId: $groupId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupId
        tenantCode
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const groupTrainingsByTenantCode = /* GraphQL */ `
  query GroupTrainingsByTenantCode(
    $tenantCode: String
    $sortDirection: ModelSortDirection
    $filter: ModelGroupTrainingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    groupTrainingsByTenantCode(
      tenantCode: $tenantCode
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupId
        tenantCode
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGroupTrainingCourse = /* GraphQL */ `
  query GetGroupTrainingCourse($id: ID!) {
    getGroupTrainingCourse(id: $id) {
      id
      groupTrainingId
      courseId
      courseVersion
      courseName
      contents {
        id
        name
        requiredTime
        type
        recommendedDateTime
        open
        version
      }
      groupId
      tenantCode
      color
      image
      fontColorOnImage
      displayName
      index
      createdAt
      updatedAt
      groupExams {
        items {
          id
          groupId
          contentId
          content {
            id
            version
            name
            timeLimit
            requiredTime
            indexInCourse
            passingStandard
          }
          problemsJson
          courseId
          course {
            id
            version
            name
            color
            image
            fontColorOnImage
          }
          scheduledStart
          scheduledFinish
          createdBy
          finishedAt
          visibilityLevel
          tenantCode
          times
          groupTrainingCourseId
          problemHeaders {
            id
            body
            createdAt
          }
          timeLimit
          passingStandard
          userIdsToBeTested
          createdAt
          updatedAt
          userExams {
            items {
              id
              userId
              status
              tenantCode
              groupExamId
              groupId
              startedAt
              finishedAt
              createdAt
              updatedAt
              groupExam {
                id
                groupId
                contentId
                content {
                  id
                  version
                  name
                  timeLimit
                  requiredTime
                  indexInCourse
                  passingStandard
                }
                problemsJson
                courseId
                course {
                  id
                  version
                  name
                  color
                  image
                  fontColorOnImage
                }
                scheduledStart
                scheduledFinish
                createdBy
                finishedAt
                visibilityLevel
                tenantCode
                times
                groupTrainingCourseId
                problemHeaders {
                  id
                  body
                  createdAt
                }
                timeLimit
                passingStandard
                userIdsToBeTested
                createdAt
                updatedAt
                userExams {
                  items {
                    id
                    userId
                    status
                    tenantCode
                    groupExamId
                    groupId
                    startedAt
                    finishedAt
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
              }
            }
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const listGroupTrainingCourses = /* GraphQL */ `
  query ListGroupTrainingCourses(
    $filter: ModelGroupTrainingCourseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroupTrainingCourses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupTrainingId
        courseId
        courseVersion
        courseName
        contents {
          id
          name
          requiredTime
          type
          recommendedDateTime
          open
          version
        }
        groupId
        tenantCode
        color
        image
        fontColorOnImage
        displayName
        index
        createdAt
        updatedAt
        groupExams {
          items {
            id
            groupId
            contentId
            content {
              id
              version
              name
              timeLimit
              requiredTime
              indexInCourse
              passingStandard
            }
            problemsJson
            courseId
            course {
              id
              version
              name
              color
              image
              fontColorOnImage
            }
            scheduledStart
            scheduledFinish
            createdBy
            finishedAt
            visibilityLevel
            tenantCode
            times
            groupTrainingCourseId
            problemHeaders {
              id
              body
              createdAt
            }
            timeLimit
            passingStandard
            userIdsToBeTested
            createdAt
            updatedAt
            userExams {
              items {
                id
                userId
                status
                tenantCode
                groupExamId
                groupId
                startedAt
                finishedAt
                createdAt
                updatedAt
                groupExam {
                  id
                  groupId
                  contentId
                  content {
                    id
                    version
                    name
                    timeLimit
                    requiredTime
                    indexInCourse
                    passingStandard
                  }
                  problemsJson
                  courseId
                  course {
                    id
                    version
                    name
                    color
                    image
                    fontColorOnImage
                  }
                  scheduledStart
                  scheduledFinish
                  createdBy
                  finishedAt
                  visibilityLevel
                  tenantCode
                  times
                  groupTrainingCourseId
                  problemHeaders {
                    id
                    body
                    createdAt
                  }
                  timeLimit
                  passingStandard
                  userIdsToBeTested
                  createdAt
                  updatedAt
                  userExams {
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const groupTrainingCoursesByGroupIdAndCourseId = /* GraphQL */ `
  query GroupTrainingCoursesByGroupIdAndCourseId(
    $groupId: ID
    $courseId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGroupTrainingCourseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    groupTrainingCoursesByGroupIdAndCourseId(
      groupId: $groupId
      courseId: $courseId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupTrainingId
        courseId
        courseVersion
        courseName
        contents {
          id
          name
          requiredTime
          type
          recommendedDateTime
          open
          version
        }
        groupId
        tenantCode
        color
        image
        fontColorOnImage
        displayName
        index
        createdAt
        updatedAt
        groupExams {
          items {
            id
            groupId
            contentId
            content {
              id
              version
              name
              timeLimit
              requiredTime
              indexInCourse
              passingStandard
            }
            problemsJson
            courseId
            course {
              id
              version
              name
              color
              image
              fontColorOnImage
            }
            scheduledStart
            scheduledFinish
            createdBy
            finishedAt
            visibilityLevel
            tenantCode
            times
            groupTrainingCourseId
            problemHeaders {
              id
              body
              createdAt
            }
            timeLimit
            passingStandard
            userIdsToBeTested
            createdAt
            updatedAt
            userExams {
              items {
                id
                userId
                status
                tenantCode
                groupExamId
                groupId
                startedAt
                finishedAt
                createdAt
                updatedAt
                groupExam {
                  id
                  groupId
                  contentId
                  content {
                    id
                    version
                    name
                    timeLimit
                    requiredTime
                    indexInCourse
                    passingStandard
                  }
                  problemsJson
                  courseId
                  course {
                    id
                    version
                    name
                    color
                    image
                    fontColorOnImage
                  }
                  scheduledStart
                  scheduledFinish
                  createdBy
                  finishedAt
                  visibilityLevel
                  tenantCode
                  times
                  groupTrainingCourseId
                  problemHeaders {
                    id
                    body
                    createdAt
                  }
                  timeLimit
                  passingStandard
                  userIdsToBeTested
                  createdAt
                  updatedAt
                  userExams {
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const groupTrainingCoursesByCourseId = /* GraphQL */ `
  query GroupTrainingCoursesByCourseId(
    $courseId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelGroupTrainingCourseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    groupTrainingCoursesByCourseId(
      courseId: $courseId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupTrainingId
        courseId
        courseVersion
        courseName
        contents {
          id
          name
          requiredTime
          type
          recommendedDateTime
          open
          version
        }
        groupId
        tenantCode
        color
        image
        fontColorOnImage
        displayName
        index
        createdAt
        updatedAt
        groupExams {
          items {
            id
            groupId
            contentId
            content {
              id
              version
              name
              timeLimit
              requiredTime
              indexInCourse
              passingStandard
            }
            problemsJson
            courseId
            course {
              id
              version
              name
              color
              image
              fontColorOnImage
            }
            scheduledStart
            scheduledFinish
            createdBy
            finishedAt
            visibilityLevel
            tenantCode
            times
            groupTrainingCourseId
            problemHeaders {
              id
              body
              createdAt
            }
            timeLimit
            passingStandard
            userIdsToBeTested
            createdAt
            updatedAt
            userExams {
              items {
                id
                userId
                status
                tenantCode
                groupExamId
                groupId
                startedAt
                finishedAt
                createdAt
                updatedAt
                groupExam {
                  id
                  groupId
                  contentId
                  content {
                    id
                    version
                    name
                    timeLimit
                    requiredTime
                    indexInCourse
                    passingStandard
                  }
                  problemsJson
                  courseId
                  course {
                    id
                    version
                    name
                    color
                    image
                    fontColorOnImage
                  }
                  scheduledStart
                  scheduledFinish
                  createdBy
                  finishedAt
                  visibilityLevel
                  tenantCode
                  times
                  groupTrainingCourseId
                  problemHeaders {
                    id
                    body
                    createdAt
                  }
                  timeLimit
                  passingStandard
                  userIdsToBeTested
                  createdAt
                  updatedAt
                  userExams {
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getSchedule = /* GraphQL */ `
  query GetSchedule($id: ID!) {
    getSchedule(id: $id) {
      id
      groupId
      start
      end
      title
      body
      tenantCode
      createdBy
      updatedBy
      createdAt
      updatedAt
      tags {
        items {
          id
          scheduleId
          scheduleTagId
          scheduleTag {
            id
            color
            text
            groupId
            tenantCode
            createdAt
            updatedAt
            schedules {
              items {
                id
                scheduleId
                scheduleTagId
                scheduleTag {
                  id
                  color
                  text
                  groupId
                  tenantCode
                  createdAt
                  updatedAt
                  schedules {
                    nextToken
                  }
                }
                tenantCode
                createdAt
                updatedAt
                schedule {
                  id
                  groupId
                  start
                  end
                  title
                  body
                  tenantCode
                  createdBy
                  updatedBy
                  createdAt
                  updatedAt
                  tags {
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
          tenantCode
          createdAt
          updatedAt
          schedule {
            id
            groupId
            start
            end
            title
            body
            tenantCode
            createdBy
            updatedBy
            createdAt
            updatedAt
            tags {
              items {
                id
                scheduleId
                scheduleTagId
                scheduleTag {
                  id
                  color
                  text
                  groupId
                  tenantCode
                  createdAt
                  updatedAt
                  schedules {
                    nextToken
                  }
                }
                tenantCode
                createdAt
                updatedAt
                schedule {
                  id
                  groupId
                  start
                  end
                  title
                  body
                  tenantCode
                  createdBy
                  updatedBy
                  createdAt
                  updatedAt
                  tags {
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const listSchedules = /* GraphQL */ `
  query ListSchedules(
    $filter: ModelScheduleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSchedules(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        groupId
        start
        end
        title
        body
        tenantCode
        createdBy
        updatedBy
        createdAt
        updatedAt
        tags {
          items {
            id
            scheduleId
            scheduleTagId
            scheduleTag {
              id
              color
              text
              groupId
              tenantCode
              createdAt
              updatedAt
              schedules {
                items {
                  id
                  scheduleId
                  scheduleTagId
                  scheduleTag {
                    id
                    color
                    text
                    groupId
                    tenantCode
                    createdAt
                    updatedAt
                  }
                  tenantCode
                  createdAt
                  updatedAt
                  schedule {
                    id
                    groupId
                    start
                    end
                    title
                    body
                    tenantCode
                    createdBy
                    updatedBy
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
            tenantCode
            createdAt
            updatedAt
            schedule {
              id
              groupId
              start
              end
              title
              body
              tenantCode
              createdBy
              updatedBy
              createdAt
              updatedAt
              tags {
                items {
                  id
                  scheduleId
                  scheduleTagId
                  scheduleTag {
                    id
                    color
                    text
                    groupId
                    tenantCode
                    createdAt
                    updatedAt
                  }
                  tenantCode
                  createdAt
                  updatedAt
                  schedule {
                    id
                    groupId
                    start
                    end
                    title
                    body
                    tenantCode
                    createdBy
                    updatedBy
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const schedulesByTenantCode = /* GraphQL */ `
  query SchedulesByTenantCode(
    $tenantCode: String
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelScheduleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    schedulesByTenantCode(
      tenantCode: $tenantCode
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupId
        start
        end
        title
        body
        tenantCode
        createdBy
        updatedBy
        createdAt
        updatedAt
        tags {
          items {
            id
            scheduleId
            scheduleTagId
            scheduleTag {
              id
              color
              text
              groupId
              tenantCode
              createdAt
              updatedAt
              schedules {
                items {
                  id
                  scheduleId
                  scheduleTagId
                  scheduleTag {
                    id
                    color
                    text
                    groupId
                    tenantCode
                    createdAt
                    updatedAt
                  }
                  tenantCode
                  createdAt
                  updatedAt
                  schedule {
                    id
                    groupId
                    start
                    end
                    title
                    body
                    tenantCode
                    createdBy
                    updatedBy
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
            tenantCode
            createdAt
            updatedAt
            schedule {
              id
              groupId
              start
              end
              title
              body
              tenantCode
              createdBy
              updatedBy
              createdAt
              updatedAt
              tags {
                items {
                  id
                  scheduleId
                  scheduleTagId
                  scheduleTag {
                    id
                    color
                    text
                    groupId
                    tenantCode
                    createdAt
                    updatedAt
                  }
                  tenantCode
                  createdAt
                  updatedAt
                  schedule {
                    id
                    groupId
                    start
                    end
                    title
                    body
                    tenantCode
                    createdBy
                    updatedBy
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const schedulesByGroupId = /* GraphQL */ `
  query SchedulesByGroupId(
    $groupId: ID
    $start: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelScheduleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    schedulesByGroupId(
      groupId: $groupId
      start: $start
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupId
        start
        end
        title
        body
        tenantCode
        createdBy
        updatedBy
        createdAt
        updatedAt
        tags {
          items {
            id
            scheduleId
            scheduleTagId
            scheduleTag {
              id
              color
              text
              groupId
              tenantCode
              createdAt
              updatedAt
              schedules {
                items {
                  id
                  scheduleId
                  scheduleTagId
                  scheduleTag {
                    id
                    color
                    text
                    groupId
                    tenantCode
                    createdAt
                    updatedAt
                  }
                  tenantCode
                  createdAt
                  updatedAt
                  schedule {
                    id
                    groupId
                    start
                    end
                    title
                    body
                    tenantCode
                    createdBy
                    updatedBy
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
            tenantCode
            createdAt
            updatedAt
            schedule {
              id
              groupId
              start
              end
              title
              body
              tenantCode
              createdBy
              updatedBy
              createdAt
              updatedAt
              tags {
                items {
                  id
                  scheduleId
                  scheduleTagId
                  scheduleTag {
                    id
                    color
                    text
                    groupId
                    tenantCode
                    createdAt
                    updatedAt
                  }
                  tenantCode
                  createdAt
                  updatedAt
                  schedule {
                    id
                    groupId
                    start
                    end
                    title
                    body
                    tenantCode
                    createdBy
                    updatedBy
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const schedulesByEnd = /* GraphQL */ `
  query SchedulesByEnd(
    $tenantCode: String
    $end: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelScheduleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    schedulesByEnd(
      tenantCode: $tenantCode
      end: $end
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupId
        start
        end
        title
        body
        tenantCode
        createdBy
        updatedBy
        createdAt
        updatedAt
        tags {
          items {
            id
            scheduleId
            scheduleTagId
            scheduleTag {
              id
              color
              text
              groupId
              tenantCode
              createdAt
              updatedAt
              schedules {
                items {
                  id
                  scheduleId
                  scheduleTagId
                  scheduleTag {
                    id
                    color
                    text
                    groupId
                    tenantCode
                    createdAt
                    updatedAt
                  }
                  tenantCode
                  createdAt
                  updatedAt
                  schedule {
                    id
                    groupId
                    start
                    end
                    title
                    body
                    tenantCode
                    createdBy
                    updatedBy
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
            tenantCode
            createdAt
            updatedAt
            schedule {
              id
              groupId
              start
              end
              title
              body
              tenantCode
              createdBy
              updatedBy
              createdAt
              updatedAt
              tags {
                items {
                  id
                  scheduleId
                  scheduleTagId
                  scheduleTag {
                    id
                    color
                    text
                    groupId
                    tenantCode
                    createdAt
                    updatedAt
                  }
                  tenantCode
                  createdAt
                  updatedAt
                  schedule {
                    id
                    groupId
                    start
                    end
                    title
                    body
                    tenantCode
                    createdBy
                    updatedBy
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const schedulesByGroupIdAndEnd = /* GraphQL */ `
  query SchedulesByGroupIdAndEnd(
    $groupId: ID
    $end: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelScheduleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    schedulesByGroupIdAndEnd(
      groupId: $groupId
      end: $end
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupId
        start
        end
        title
        body
        tenantCode
        createdBy
        updatedBy
        createdAt
        updatedAt
        tags {
          items {
            id
            scheduleId
            scheduleTagId
            scheduleTag {
              id
              color
              text
              groupId
              tenantCode
              createdAt
              updatedAt
              schedules {
                items {
                  id
                  scheduleId
                  scheduleTagId
                  scheduleTag {
                    id
                    color
                    text
                    groupId
                    tenantCode
                    createdAt
                    updatedAt
                  }
                  tenantCode
                  createdAt
                  updatedAt
                  schedule {
                    id
                    groupId
                    start
                    end
                    title
                    body
                    tenantCode
                    createdBy
                    updatedBy
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
            tenantCode
            createdAt
            updatedAt
            schedule {
              id
              groupId
              start
              end
              title
              body
              tenantCode
              createdBy
              updatedBy
              createdAt
              updatedAt
              tags {
                items {
                  id
                  scheduleId
                  scheduleTagId
                  scheduleTag {
                    id
                    color
                    text
                    groupId
                    tenantCode
                    createdAt
                    updatedAt
                  }
                  tenantCode
                  createdAt
                  updatedAt
                  schedule {
                    id
                    groupId
                    start
                    end
                    title
                    body
                    tenantCode
                    createdBy
                    updatedBy
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getComment = /* GraphQL */ `
  query GetComment($id: ID!) {
    getComment(id: $id) {
      id
      questionId
      body
      createdBy
      createdAt
      editedBy
      editedAt
      tenantCode
      groupId
      updatedAt
    }
  }
`;
export const listComments = /* GraphQL */ `
  query ListComments(
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        questionId
        body
        createdBy
        createdAt
        editedBy
        editedAt
        tenantCode
        groupId
        updatedAt
      }
      nextToken
    }
  }
`;
export const getQuestion = /* GraphQL */ `
  query GetQuestion($id: ID!) {
    getQuestion(id: $id) {
      id
      createdBy
      resolved
      resolvedAt
      resolvedBy
      groupId
      title
      referTo {
        contentId
        contentVersion
        options {
          text {
            selectionJson
          }
          workbook {
            problemIndex
            selectionJson
          }
        }
      }
      tenantCode
      createdAt
      assignees
      updatedAt
      comments {
        items {
          id
          questionId
          body
          createdBy
          createdAt
          editedBy
          editedAt
          tenantCode
          groupId
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listQuestions = /* GraphQL */ `
  query ListQuestions(
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdBy
        resolved
        resolvedAt
        resolvedBy
        groupId
        title
        referTo {
          contentId
          contentVersion
          options {
            text {
              selectionJson
            }
            workbook {
              problemIndex
              selectionJson
            }
          }
        }
        tenantCode
        createdAt
        assignees
        updatedAt
        comments {
          items {
            id
            questionId
            body
            createdBy
            createdAt
            editedBy
            editedAt
            tenantCode
            groupId
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const questionsByTenantCode = /* GraphQL */ `
  query QuestionsByTenantCode(
    $tenantCode: String
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    questionsByTenantCode(
      tenantCode: $tenantCode
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdBy
        resolved
        resolvedAt
        resolvedBy
        groupId
        title
        referTo {
          contentId
          contentVersion
          options {
            text {
              selectionJson
            }
            workbook {
              problemIndex
              selectionJson
            }
          }
        }
        tenantCode
        createdAt
        assignees
        updatedAt
        comments {
          items {
            id
            questionId
            body
            createdBy
            createdAt
            editedBy
            editedAt
            tenantCode
            groupId
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const questionsByGroup = /* GraphQL */ `
  query QuestionsByGroup(
    $groupId: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    questionsByGroup(
      groupId: $groupId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdBy
        resolved
        resolvedAt
        resolvedBy
        groupId
        title
        referTo {
          contentId
          contentVersion
          options {
            text {
              selectionJson
            }
            workbook {
              problemIndex
              selectionJson
            }
          }
        }
        tenantCode
        createdAt
        assignees
        updatedAt
        comments {
          items {
            id
            questionId
            body
            createdBy
            createdAt
            editedBy
            editedAt
            tenantCode
            groupId
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getContentLearning = /* GraphQL */ `
  query GetContentLearning($groupId: ID!, $contentId: ID!, $userId: ID!) {
    getContentLearning(
      groupId: $groupId
      contentId: $contentId
      userId: $userId
    ) {
      groupId
      courseId
      contentId
      contentType
      userId
      status
      usageTime
      lastStartedAt
      dataVersion
      tenantCode
      completedAt
      completedUsageTime
      createdAt
      updatedAt
    }
  }
`;
export const listContentLearnings = /* GraphQL */ `
  query ListContentLearnings(
    $groupId: ID
    $contentIdUserId: ModelContentLearningPrimaryCompositeKeyConditionInput
    $filter: ModelContentLearningFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listContentLearnings(
      groupId: $groupId
      contentIdUserId: $contentIdUserId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        groupId
        courseId
        contentId
        contentType
        userId
        status
        usageTime
        lastStartedAt
        dataVersion
        tenantCode
        completedAt
        completedUsageTime
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const contentLearningsByGroupIdAndCourseId = /* GraphQL */ `
  query ContentLearningsByGroupIdAndCourseId(
    $groupId: ID
    $courseId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelContentLearningFilterInput
    $limit: Int
    $nextToken: String
  ) {
    contentLearningsByGroupIdAndCourseId(
      groupId: $groupId
      courseId: $courseId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        groupId
        courseId
        contentId
        contentType
        userId
        status
        usageTime
        lastStartedAt
        dataVersion
        tenantCode
        completedAt
        completedUsageTime
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const contentLearningsByGroupIdAndUserId = /* GraphQL */ `
  query ContentLearningsByGroupIdAndUserId(
    $groupId: ID
    $userId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelContentLearningFilterInput
    $limit: Int
    $nextToken: String
  ) {
    contentLearningsByGroupIdAndUserId(
      groupId: $groupId
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        groupId
        courseId
        contentId
        contentType
        userId
        status
        usageTime
        lastStartedAt
        dataVersion
        tenantCode
        completedAt
        completedUsageTime
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const contentLearningsByUser = /* GraphQL */ `
  query ContentLearningsByUser(
    $userId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelContentLearningFilterInput
    $limit: Int
    $nextToken: String
  ) {
    contentLearningsByUser(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        groupId
        courseId
        contentId
        contentType
        userId
        status
        usageTime
        lastStartedAt
        dataVersion
        tenantCode
        completedAt
        completedUsageTime
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGroupExam = /* GraphQL */ `
  query GetGroupExam($id: ID!) {
    getGroupExam(id: $id) {
      id
      groupId
      contentId
      content {
        id
        version
        name
        timeLimit
        requiredTime
        indexInCourse
        passingStandard
      }
      problemsJson
      courseId
      course {
        id
        version
        name
        color
        image
        fontColorOnImage
      }
      scheduledStart
      scheduledFinish
      createdBy
      finishedAt
      visibilityLevel
      tenantCode
      times
      groupTrainingCourseId
      problemHeaders {
        id
        body
        createdAt
      }
      timeLimit
      passingStandard
      userIdsToBeTested
      createdAt
      updatedAt
      userExams {
        items {
          id
          userId
          status
          tenantCode
          groupExamId
          groupId
          startedAt
          finishedAt
          createdAt
          updatedAt
          groupExam {
            id
            groupId
            contentId
            content {
              id
              version
              name
              timeLimit
              requiredTime
              indexInCourse
              passingStandard
            }
            problemsJson
            courseId
            course {
              id
              version
              name
              color
              image
              fontColorOnImage
            }
            scheduledStart
            scheduledFinish
            createdBy
            finishedAt
            visibilityLevel
            tenantCode
            times
            groupTrainingCourseId
            problemHeaders {
              id
              body
              createdAt
            }
            timeLimit
            passingStandard
            userIdsToBeTested
            createdAt
            updatedAt
            userExams {
              items {
                id
                userId
                status
                tenantCode
                groupExamId
                groupId
                startedAt
                finishedAt
                createdAt
                updatedAt
                groupExam {
                  id
                  groupId
                  contentId
                  content {
                    id
                    version
                    name
                    timeLimit
                    requiredTime
                    indexInCourse
                    passingStandard
                  }
                  problemsJson
                  courseId
                  course {
                    id
                    version
                    name
                    color
                    image
                    fontColorOnImage
                  }
                  scheduledStart
                  scheduledFinish
                  createdBy
                  finishedAt
                  visibilityLevel
                  tenantCode
                  times
                  groupTrainingCourseId
                  problemHeaders {
                    id
                    body
                    createdAt
                  }
                  timeLimit
                  passingStandard
                  userIdsToBeTested
                  createdAt
                  updatedAt
                  userExams {
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const listGroupExams = /* GraphQL */ `
  query ListGroupExams(
    $filter: ModelGroupExamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroupExams(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        groupId
        contentId
        content {
          id
          version
          name
          timeLimit
          requiredTime
          indexInCourse
          passingStandard
        }
        problemsJson
        courseId
        course {
          id
          version
          name
          color
          image
          fontColorOnImage
        }
        scheduledStart
        scheduledFinish
        createdBy
        finishedAt
        visibilityLevel
        tenantCode
        times
        groupTrainingCourseId
        problemHeaders {
          id
          body
          createdAt
        }
        timeLimit
        passingStandard
        userIdsToBeTested
        createdAt
        updatedAt
        userExams {
          items {
            id
            userId
            status
            tenantCode
            groupExamId
            groupId
            startedAt
            finishedAt
            createdAt
            updatedAt
            groupExam {
              id
              groupId
              contentId
              content {
                id
                version
                name
                timeLimit
                requiredTime
                indexInCourse
                passingStandard
              }
              problemsJson
              courseId
              course {
                id
                version
                name
                color
                image
                fontColorOnImage
              }
              scheduledStart
              scheduledFinish
              createdBy
              finishedAt
              visibilityLevel
              tenantCode
              times
              groupTrainingCourseId
              problemHeaders {
                id
                body
                createdAt
              }
              timeLimit
              passingStandard
              userIdsToBeTested
              createdAt
              updatedAt
              userExams {
                items {
                  id
                  userId
                  status
                  tenantCode
                  groupExamId
                  groupId
                  startedAt
                  finishedAt
                  createdAt
                  updatedAt
                  groupExam {
                    id
                    groupId
                    contentId
                    problemsJson
                    courseId
                    scheduledStart
                    scheduledFinish
                    createdBy
                    finishedAt
                    visibilityLevel
                    tenantCode
                    times
                    groupTrainingCourseId
                    timeLimit
                    passingStandard
                    userIdsToBeTested
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const groupExamsByGroup = /* GraphQL */ `
  query GroupExamsByGroup(
    $groupId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelGroupExamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    groupExamsByGroup(
      groupId: $groupId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupId
        contentId
        content {
          id
          version
          name
          timeLimit
          requiredTime
          indexInCourse
          passingStandard
        }
        problemsJson
        courseId
        course {
          id
          version
          name
          color
          image
          fontColorOnImage
        }
        scheduledStart
        scheduledFinish
        createdBy
        finishedAt
        visibilityLevel
        tenantCode
        times
        groupTrainingCourseId
        problemHeaders {
          id
          body
          createdAt
        }
        timeLimit
        passingStandard
        userIdsToBeTested
        createdAt
        updatedAt
        userExams {
          items {
            id
            userId
            status
            tenantCode
            groupExamId
            groupId
            startedAt
            finishedAt
            createdAt
            updatedAt
            groupExam {
              id
              groupId
              contentId
              content {
                id
                version
                name
                timeLimit
                requiredTime
                indexInCourse
                passingStandard
              }
              problemsJson
              courseId
              course {
                id
                version
                name
                color
                image
                fontColorOnImage
              }
              scheduledStart
              scheduledFinish
              createdBy
              finishedAt
              visibilityLevel
              tenantCode
              times
              groupTrainingCourseId
              problemHeaders {
                id
                body
                createdAt
              }
              timeLimit
              passingStandard
              userIdsToBeTested
              createdAt
              updatedAt
              userExams {
                items {
                  id
                  userId
                  status
                  tenantCode
                  groupExamId
                  groupId
                  startedAt
                  finishedAt
                  createdAt
                  updatedAt
                  groupExam {
                    id
                    groupId
                    contentId
                    problemsJson
                    courseId
                    scheduledStart
                    scheduledFinish
                    createdBy
                    finishedAt
                    visibilityLevel
                    tenantCode
                    times
                    groupTrainingCourseId
                    timeLimit
                    passingStandard
                    userIdsToBeTested
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const groupExamsByGroupAndContent = /* GraphQL */ `
  query GroupExamsByGroupAndContent(
    $groupId: ID
    $contentId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGroupExamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    groupExamsByGroupAndContent(
      groupId: $groupId
      contentId: $contentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupId
        contentId
        content {
          id
          version
          name
          timeLimit
          requiredTime
          indexInCourse
          passingStandard
        }
        problemsJson
        courseId
        course {
          id
          version
          name
          color
          image
          fontColorOnImage
        }
        scheduledStart
        scheduledFinish
        createdBy
        finishedAt
        visibilityLevel
        tenantCode
        times
        groupTrainingCourseId
        problemHeaders {
          id
          body
          createdAt
        }
        timeLimit
        passingStandard
        userIdsToBeTested
        createdAt
        updatedAt
        userExams {
          items {
            id
            userId
            status
            tenantCode
            groupExamId
            groupId
            startedAt
            finishedAt
            createdAt
            updatedAt
            groupExam {
              id
              groupId
              contentId
              content {
                id
                version
                name
                timeLimit
                requiredTime
                indexInCourse
                passingStandard
              }
              problemsJson
              courseId
              course {
                id
                version
                name
                color
                image
                fontColorOnImage
              }
              scheduledStart
              scheduledFinish
              createdBy
              finishedAt
              visibilityLevel
              tenantCode
              times
              groupTrainingCourseId
              problemHeaders {
                id
                body
                createdAt
              }
              timeLimit
              passingStandard
              userIdsToBeTested
              createdAt
              updatedAt
              userExams {
                items {
                  id
                  userId
                  status
                  tenantCode
                  groupExamId
                  groupId
                  startedAt
                  finishedAt
                  createdAt
                  updatedAt
                  groupExam {
                    id
                    groupId
                    contentId
                    problemsJson
                    courseId
                    scheduledStart
                    scheduledFinish
                    createdBy
                    finishedAt
                    visibilityLevel
                    tenantCode
                    times
                    groupTrainingCourseId
                    timeLimit
                    passingStandard
                    userIdsToBeTested
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const groupExamsByGroupAndCourse = /* GraphQL */ `
  query GroupExamsByGroupAndCourse(
    $groupId: ID
    $courseId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGroupExamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    groupExamsByGroupAndCourse(
      groupId: $groupId
      courseId: $courseId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupId
        contentId
        content {
          id
          version
          name
          timeLimit
          requiredTime
          indexInCourse
          passingStandard
        }
        problemsJson
        courseId
        course {
          id
          version
          name
          color
          image
          fontColorOnImage
        }
        scheduledStart
        scheduledFinish
        createdBy
        finishedAt
        visibilityLevel
        tenantCode
        times
        groupTrainingCourseId
        problemHeaders {
          id
          body
          createdAt
        }
        timeLimit
        passingStandard
        userIdsToBeTested
        createdAt
        updatedAt
        userExams {
          items {
            id
            userId
            status
            tenantCode
            groupExamId
            groupId
            startedAt
            finishedAt
            createdAt
            updatedAt
            groupExam {
              id
              groupId
              contentId
              content {
                id
                version
                name
                timeLimit
                requiredTime
                indexInCourse
                passingStandard
              }
              problemsJson
              courseId
              course {
                id
                version
                name
                color
                image
                fontColorOnImage
              }
              scheduledStart
              scheduledFinish
              createdBy
              finishedAt
              visibilityLevel
              tenantCode
              times
              groupTrainingCourseId
              problemHeaders {
                id
                body
                createdAt
              }
              timeLimit
              passingStandard
              userIdsToBeTested
              createdAt
              updatedAt
              userExams {
                items {
                  id
                  userId
                  status
                  tenantCode
                  groupExamId
                  groupId
                  startedAt
                  finishedAt
                  createdAt
                  updatedAt
                  groupExam {
                    id
                    groupId
                    contentId
                    problemsJson
                    courseId
                    scheduledStart
                    scheduledFinish
                    createdBy
                    finishedAt
                    visibilityLevel
                    tenantCode
                    times
                    groupTrainingCourseId
                    timeLimit
                    passingStandard
                    userIdsToBeTested
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const groupExamsByCourse = /* GraphQL */ `
  query GroupExamsByCourse(
    $courseId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelGroupExamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    groupExamsByCourse(
      courseId: $courseId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupId
        contentId
        content {
          id
          version
          name
          timeLimit
          requiredTime
          indexInCourse
          passingStandard
        }
        problemsJson
        courseId
        course {
          id
          version
          name
          color
          image
          fontColorOnImage
        }
        scheduledStart
        scheduledFinish
        createdBy
        finishedAt
        visibilityLevel
        tenantCode
        times
        groupTrainingCourseId
        problemHeaders {
          id
          body
          createdAt
        }
        timeLimit
        passingStandard
        userIdsToBeTested
        createdAt
        updatedAt
        userExams {
          items {
            id
            userId
            status
            tenantCode
            groupExamId
            groupId
            startedAt
            finishedAt
            createdAt
            updatedAt
            groupExam {
              id
              groupId
              contentId
              content {
                id
                version
                name
                timeLimit
                requiredTime
                indexInCourse
                passingStandard
              }
              problemsJson
              courseId
              course {
                id
                version
                name
                color
                image
                fontColorOnImage
              }
              scheduledStart
              scheduledFinish
              createdBy
              finishedAt
              visibilityLevel
              tenantCode
              times
              groupTrainingCourseId
              problemHeaders {
                id
                body
                createdAt
              }
              timeLimit
              passingStandard
              userIdsToBeTested
              createdAt
              updatedAt
              userExams {
                items {
                  id
                  userId
                  status
                  tenantCode
                  groupExamId
                  groupId
                  startedAt
                  finishedAt
                  createdAt
                  updatedAt
                  groupExam {
                    id
                    groupId
                    contentId
                    problemsJson
                    courseId
                    scheduledStart
                    scheduledFinish
                    createdBy
                    finishedAt
                    visibilityLevel
                    tenantCode
                    times
                    groupTrainingCourseId
                    timeLimit
                    passingStandard
                    userIdsToBeTested
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getUserExam = /* GraphQL */ `
  query GetUserExam($id: ID!) {
    getUserExam(id: $id) {
      id
      userId
      status
      tenantCode
      groupExamId
      groupId
      startedAt
      finishedAt
      createdAt
      updatedAt
      groupExam {
        id
        groupId
        contentId
        content {
          id
          version
          name
          timeLimit
          requiredTime
          indexInCourse
          passingStandard
        }
        problemsJson
        courseId
        course {
          id
          version
          name
          color
          image
          fontColorOnImage
        }
        scheduledStart
        scheduledFinish
        createdBy
        finishedAt
        visibilityLevel
        tenantCode
        times
        groupTrainingCourseId
        problemHeaders {
          id
          body
          createdAt
        }
        timeLimit
        passingStandard
        userIdsToBeTested
        createdAt
        updatedAt
        userExams {
          items {
            id
            userId
            status
            tenantCode
            groupExamId
            groupId
            startedAt
            finishedAt
            createdAt
            updatedAt
            groupExam {
              id
              groupId
              contentId
              content {
                id
                version
                name
                timeLimit
                requiredTime
                indexInCourse
                passingStandard
              }
              problemsJson
              courseId
              course {
                id
                version
                name
                color
                image
                fontColorOnImage
              }
              scheduledStart
              scheduledFinish
              createdBy
              finishedAt
              visibilityLevel
              tenantCode
              times
              groupTrainingCourseId
              problemHeaders {
                id
                body
                createdAt
              }
              timeLimit
              passingStandard
              userIdsToBeTested
              createdAt
              updatedAt
              userExams {
                items {
                  id
                  userId
                  status
                  tenantCode
                  groupExamId
                  groupId
                  startedAt
                  finishedAt
                  createdAt
                  updatedAt
                  groupExam {
                    id
                    groupId
                    contentId
                    problemsJson
                    courseId
                    scheduledStart
                    scheduledFinish
                    createdBy
                    finishedAt
                    visibilityLevel
                    tenantCode
                    times
                    groupTrainingCourseId
                    timeLimit
                    passingStandard
                    userIdsToBeTested
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const listUserExams = /* GraphQL */ `
  query ListUserExams(
    $filter: ModelUserExamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserExams(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        status
        tenantCode
        groupExamId
        groupId
        startedAt
        finishedAt
        createdAt
        updatedAt
        groupExam {
          id
          groupId
          contentId
          content {
            id
            version
            name
            timeLimit
            requiredTime
            indexInCourse
            passingStandard
          }
          problemsJson
          courseId
          course {
            id
            version
            name
            color
            image
            fontColorOnImage
          }
          scheduledStart
          scheduledFinish
          createdBy
          finishedAt
          visibilityLevel
          tenantCode
          times
          groupTrainingCourseId
          problemHeaders {
            id
            body
            createdAt
          }
          timeLimit
          passingStandard
          userIdsToBeTested
          createdAt
          updatedAt
          userExams {
            items {
              id
              userId
              status
              tenantCode
              groupExamId
              groupId
              startedAt
              finishedAt
              createdAt
              updatedAt
              groupExam {
                id
                groupId
                contentId
                content {
                  id
                  version
                  name
                  timeLimit
                  requiredTime
                  indexInCourse
                  passingStandard
                }
                problemsJson
                courseId
                course {
                  id
                  version
                  name
                  color
                  image
                  fontColorOnImage
                }
                scheduledStart
                scheduledFinish
                createdBy
                finishedAt
                visibilityLevel
                tenantCode
                times
                groupTrainingCourseId
                problemHeaders {
                  id
                  body
                  createdAt
                }
                timeLimit
                passingStandard
                userIdsToBeTested
                createdAt
                updatedAt
                userExams {
                  items {
                    id
                    userId
                    status
                    tenantCode
                    groupExamId
                    groupId
                    startedAt
                    finishedAt
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
              }
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const userExamsByGroupExam = /* GraphQL */ `
  query UserExamsByGroupExam(
    $groupExamId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelUserExamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userExamsByGroupExam(
      groupExamId: $groupExamId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        status
        tenantCode
        groupExamId
        groupId
        startedAt
        finishedAt
        createdAt
        updatedAt
        groupExam {
          id
          groupId
          contentId
          content {
            id
            version
            name
            timeLimit
            requiredTime
            indexInCourse
            passingStandard
          }
          problemsJson
          courseId
          course {
            id
            version
            name
            color
            image
            fontColorOnImage
          }
          scheduledStart
          scheduledFinish
          createdBy
          finishedAt
          visibilityLevel
          tenantCode
          times
          groupTrainingCourseId
          problemHeaders {
            id
            body
            createdAt
          }
          timeLimit
          passingStandard
          userIdsToBeTested
          createdAt
          updatedAt
          userExams {
            items {
              id
              userId
              status
              tenantCode
              groupExamId
              groupId
              startedAt
              finishedAt
              createdAt
              updatedAt
              groupExam {
                id
                groupId
                contentId
                content {
                  id
                  version
                  name
                  timeLimit
                  requiredTime
                  indexInCourse
                  passingStandard
                }
                problemsJson
                courseId
                course {
                  id
                  version
                  name
                  color
                  image
                  fontColorOnImage
                }
                scheduledStart
                scheduledFinish
                createdBy
                finishedAt
                visibilityLevel
                tenantCode
                times
                groupTrainingCourseId
                problemHeaders {
                  id
                  body
                  createdAt
                }
                timeLimit
                passingStandard
                userIdsToBeTested
                createdAt
                updatedAt
                userExams {
                  items {
                    id
                    userId
                    status
                    tenantCode
                    groupExamId
                    groupId
                    startedAt
                    finishedAt
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
              }
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const userExamsByGroupAndUser = /* GraphQL */ `
  query UserExamsByGroupAndUser(
    $groupId: ID
    $userId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserExamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userExamsByGroupAndUser(
      groupId: $groupId
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        status
        tenantCode
        groupExamId
        groupId
        startedAt
        finishedAt
        createdAt
        updatedAt
        groupExam {
          id
          groupId
          contentId
          content {
            id
            version
            name
            timeLimit
            requiredTime
            indexInCourse
            passingStandard
          }
          problemsJson
          courseId
          course {
            id
            version
            name
            color
            image
            fontColorOnImage
          }
          scheduledStart
          scheduledFinish
          createdBy
          finishedAt
          visibilityLevel
          tenantCode
          times
          groupTrainingCourseId
          problemHeaders {
            id
            body
            createdAt
          }
          timeLimit
          passingStandard
          userIdsToBeTested
          createdAt
          updatedAt
          userExams {
            items {
              id
              userId
              status
              tenantCode
              groupExamId
              groupId
              startedAt
              finishedAt
              createdAt
              updatedAt
              groupExam {
                id
                groupId
                contentId
                content {
                  id
                  version
                  name
                  timeLimit
                  requiredTime
                  indexInCourse
                  passingStandard
                }
                problemsJson
                courseId
                course {
                  id
                  version
                  name
                  color
                  image
                  fontColorOnImage
                }
                scheduledStart
                scheduledFinish
                createdBy
                finishedAt
                visibilityLevel
                tenantCode
                times
                groupTrainingCourseId
                problemHeaders {
                  id
                  body
                  createdAt
                }
                timeLimit
                passingStandard
                userIdsToBeTested
                createdAt
                updatedAt
                userExams {
                  items {
                    id
                    userId
                    status
                    tenantCode
                    groupExamId
                    groupId
                    startedAt
                    finishedAt
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
              }
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const userExamsByUser = /* GraphQL */ `
  query UserExamsByUser(
    $userId: ID
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserExamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userExamsByUser(
      userId: $userId
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        status
        tenantCode
        groupExamId
        groupId
        startedAt
        finishedAt
        createdAt
        updatedAt
        groupExam {
          id
          groupId
          contentId
          content {
            id
            version
            name
            timeLimit
            requiredTime
            indexInCourse
            passingStandard
          }
          problemsJson
          courseId
          course {
            id
            version
            name
            color
            image
            fontColorOnImage
          }
          scheduledStart
          scheduledFinish
          createdBy
          finishedAt
          visibilityLevel
          tenantCode
          times
          groupTrainingCourseId
          problemHeaders {
            id
            body
            createdAt
          }
          timeLimit
          passingStandard
          userIdsToBeTested
          createdAt
          updatedAt
          userExams {
            items {
              id
              userId
              status
              tenantCode
              groupExamId
              groupId
              startedAt
              finishedAt
              createdAt
              updatedAt
              groupExam {
                id
                groupId
                contentId
                content {
                  id
                  version
                  name
                  timeLimit
                  requiredTime
                  indexInCourse
                  passingStandard
                }
                problemsJson
                courseId
                course {
                  id
                  version
                  name
                  color
                  image
                  fontColorOnImage
                }
                scheduledStart
                scheduledFinish
                createdBy
                finishedAt
                visibilityLevel
                tenantCode
                times
                groupTrainingCourseId
                problemHeaders {
                  id
                  body
                  createdAt
                }
                timeLimit
                passingStandard
                userIdsToBeTested
                createdAt
                updatedAt
                userExams {
                  items {
                    id
                    userId
                    status
                    tenantCode
                    groupExamId
                    groupId
                    startedAt
                    finishedAt
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
              }
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
