import {
  AuthorizationService,
  ContentFinder,
  ContentId,
  ContentName,
  CourseDisplayName,
  CourseId,
  Exam,
  GroupId,
  GroupTrainingFinder,
} from '@/base/domains';
import { AbstractUseCase, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

export type Course = {
  id: CourseId;
  name: CourseDisplayName;
  contents: Array<GetExamContentsGroupedByCourseContent>;
};

export type GetExamContentsGroupedByCourseContent = {
  id: ContentId;
  name: ContentName;
  problemCount: number;
};

export interface GetExamContentsGroupedByCourseRequest {
  groupId: GroupId;
}

export type GetExamContentsGroupedByCourseResponse = {
  courses: Array<Course>;
};

export interface GetExamContentsGroupedByCourse
  extends UseCase<GetExamContentsGroupedByCourseRequest, GetExamContentsGroupedByCourseResponse> {
  execute(
    request: GetExamContentsGroupedByCourseRequest
  ): Promise<UseCaseResponse<GetExamContentsGroupedByCourseResponse>>;
}

export class GetExamContentsGroupedByCourseImpl
  extends AbstractUseCase<
    GetExamContentsGroupedByCourseRequest,
    GetExamContentsGroupedByCourseResponse
  >
  implements GetExamContentsGroupedByCourse
{
  constructor(
    private groupTrainingFinder: GroupTrainingFinder,
    private authorizationService: AuthorizationService,
    private contentFinder: ContentFinder
  ) {
    super('report.GetExamContentsGroupedByCourse');
  }

  async internalExecute(
    request: GetExamContentsGroupedByCourseRequest
  ): Promise<GetExamContentsGroupedByCourseResponse> {
    const { groupId } = request;
    this.authorizationService.assertGroupReadAccessible(groupId);
    const courses = await this.groupTrainingFinder.findCoursesByGroupId(groupId);
    return {
      courses: await Promise.all(
        courses.map(async (cr) => {
          const contents = await this.contentFinder.findByCourseId(cr.id, cr.version);
          return {
            id: cr.id,
            name: cr.displayName,
            version: cr.version,
            contents: contents
              .filter((cn) => cn.type === 'exam')
              .map((cn) => ({
                id: cn.id,
                name: cn.name,
                problemCount: (cn.body as Exam).problems.length,
              })),
          };
        })
      ),
    };
  }
}

export const GetExamContentsGroupedByCourseKey = injectionKeyOf<GetExamContentsGroupedByCourse>({
  boundedContext: 'report',
  type: 'usecase',
  name: 'GetExamContentsGroupedByCourse',
});

export function useGetExamContentsGroupedByCourse(): GetExamContentsGroupedByCourse {
  return requiredInject(GetExamContentsGroupedByCourseKey);
}
