import { AppContextProvider, DateTimeService, QuestionId } from '@/base/domains';
import { AUTHORIZATION_ERROR } from '@/base/ErrorCodes';
import { AbstractUseCase, assertEntityExists, UseCase, UseCaseResponse } from '@/base/usecases';
import { assertIsDefined } from '@/utils/Asserts';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { QuestionReference, QuestionRepository } from '../domains';

export interface ResolveQuestionRequest {
  id: QuestionId;
}

export type ResolveQuestionResponse = {
  question: QuestionReference;
};

/**
 * 質問を解決済みにする
 */
export interface ResolveQuestion extends UseCase<ResolveQuestionRequest, ResolveQuestionResponse> {
  execute(request: ResolveQuestionRequest): Promise<UseCaseResponse<ResolveQuestionResponse>>;
}

export class ResolveQuestionImpl
  extends AbstractUseCase<ResolveQuestionRequest, ResolveQuestionResponse>
  implements ResolveQuestion
{
  constructor(
    private appContextProvider: AppContextProvider,
    private questionRepository: QuestionRepository,
    private dateTimeService: DateTimeService
  ) {
    super('training.ResolveQuestion');
  }

  async internalExecute(request: ResolveQuestionRequest): Promise<ResolveQuestionResponse> {
    const { id } = request;
    const question = await this.questionRepository.findById(id);
    assertEntityExists(question, 'question');
    if (question.resolved) {
      return {
        question,
      };
    }
    const appContext = this.appContextProvider.get();
    const { user, groupId } = appContext;
    assertIsDefined(user, 'appContext.user');
    assertIsDefined(groupId, 'appContext.groupId');
    const groupRole = appContext.roleInGroup(groupId);
    const isTrainer = groupRole === 'trainer' || groupRole === 'mentor';
    if (!isTrainer && question.createdBy !== user.id) {
      throw AUTHORIZATION_ERROR.toApplicationError({
        message: 'should be a trainer, a mentor or a creator',
      });
    }
    const userId = user.id;
    const now = await this.dateTimeService.strictLocalDateTimeNow();
    const saved = await this.questionRepository.save(question.resolve({ by: userId, at: now }));
    return {
      question: saved,
    };
  }
}

export const ResolveQuestionKey = injectionKeyOf<ResolveQuestion>({
  boundedContext: 'training',
  type: 'usecase',
  name: 'ResolveQuestion',
});

export function useResolveQuestion(): ResolveQuestion {
  return requiredInject(ResolveQuestionKey);
}
