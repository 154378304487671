import { AuthorizationService, ScheduleId } from '@/base/domains';
import { AbstractUseCase, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { ScheduleRepository } from '../domains/Schedule';

export interface RemoveScheduleRequest {
  id: ScheduleId;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export type RemoveScheduleResponse = {};

/**
 * スケジュールを削除する
 */
export interface RemoveSchedule extends UseCase<RemoveScheduleRequest, RemoveScheduleResponse> {
  execute(request: RemoveScheduleRequest): Promise<UseCaseResponse<RemoveScheduleResponse>>;
}

export class RemoveScheduleImpl
  extends AbstractUseCase<RemoveScheduleRequest, RemoveScheduleResponse>
  implements RemoveSchedule
{
  constructor(
    private scheduleRepository: ScheduleRepository,
    private authorizationService: AuthorizationService
  ) {
    super('training.RemoveSchedule');
  }

  async internalExecute(request: RemoveScheduleRequest): Promise<RemoveScheduleResponse> {
    const { id } = request;
    const schedule = await this.scheduleRepository.findById(id);
    if (schedule) {
      this.authorizationService.assertTrainerOrMentorInGroup(schedule.groupId);
      await this.scheduleRepository.remove(id);
    }
    return {};
  }
}

export const RemoveScheduleKey = injectionKeyOf<RemoveSchedule>({
  boundedContext: 'training',
  type: 'usecase',
  name: 'RemoveSchedule',
});

export function useRemoveSchedule(): RemoveSchedule {
  return requiredInject(RemoveScheduleKey);
}
