import { SignedInUser } from '@/base/domains';

export const signedInUsers: Array<SignedInUser> = [
  {
    id: 'sv',
    code: 'sv',
    email: 'sv@knowte.com',
    role: 'supervisor',
    name: 'SV',
    groups: [],
    tenantCode: 'dev',
    locale: 'ja',
    enabled: true,
    confirmedTermsOfServiceVersions: {},
  },
  {
    id: 'admin00',
    code: 'admin00',
    email: 'admin00@knowte.com',
    role: 'admin',
    name: '管理者00',
    groups: [{ id: 'group00', role: 'trainer', name: 'グループ00' }],
    tenantCode: 'dev',
    locale: 'ja',
    avatar: 'https://picsum.photos/id/1081/48/48',
    enabled: true,
    confirmedTermsOfServiceVersions: {},
  },
  {
    id: 'admin01',
    code: 'admin-ja',
    email: 'admin-ja@knowte.com',
    role: 'admin',
    name: '管理者(JA)',
    groups: [{ id: 'group00', role: 'trainer', name: 'グループ00' }],
    tenantCode: 'dev',
    locale: 'ja',
    enabled: true,
    confirmedTermsOfServiceVersions: {},
  },
  {
    id: 'admin02',
    code: 'admin-en',
    email: 'admin-en@knowte.com',
    role: 'admin',
    name: 'Administrator(EN)',
    groups: [{ id: 'group00', role: 'trainer', name: 'グループ00' }],
    tenantCode: 'dev',
    locale: 'en',
    enabled: true,
    confirmedTermsOfServiceVersions: {},
  },
  {
    id: 'admin03',
    code: 'admin-vi',
    email: 'admin-vi@knowte.com',
    role: 'admin',
    name: 'Administrator(VI)',
    groups: [{ id: 'group00', role: 'trainer', name: 'グループ00' }],
    tenantCode: 'dev',
    locale: 'vi',
    enabled: true,
    confirmedTermsOfServiceVersions: {},
  },
  {
    id: 'admin04',
    code: 'admin-id',
    email: 'admin-id@knowte.com',
    role: 'admin',
    name: 'Administrator(ID)',
    groups: [{ id: 'group00', role: 'trainer', name: 'グループ00' }],
    tenantCode: 'dev',
    locale: 'id',
    enabled: true,
    confirmedTermsOfServiceVersions: {},
  },
  {
    id: 'user00',
    code: 'user00',
    email: 'user00@knowte.com',
    role: 'general',
    name: 'ユーザー00',
    groups: [{ id: 'group00', role: 'trainer', name: 'グループ00' }],
    tenantCode: 'dev',
    locale: 'ja',
    avatar: 'https://picsum.photos/id/1074/48/48',
    enabled: true,
    confirmedTermsOfServiceVersions: {},
  },
  {
    id: 'user01',
    code: 'user01',
    email: 'user01@knowte.com',
    role: 'general',
    name: 'ユーザー01',
    groups: [
      { id: 'group00', role: 'trainer', name: 'グループ00' },
      { id: 'group01', role: 'trainer', name: 'グループ01' },
    ],
    tenantCode: 'dev',
    locale: 'ja',
    avatar: 'https://picsum.photos/id/1075/48/48',
    enabled: true,
    confirmedTermsOfServiceVersions: {},
  },
  {
    id: 'user02',
    code: 'user02',
    email: 'user02@knowte.com',
    role: 'general',
    name: 'ユーザー02',
    groups: [
      { id: 'group00', role: 'trainee', name: 'グループ00' },
      { id: 'group01', role: 'trainee', name: 'グループ01' },
    ],
    tenantCode: 'dev',
    locale: 'ja',
    avatar: 'https://picsum.photos/id/1070/48/48',
    enabled: true,
    confirmedTermsOfServiceVersions: {},
  },
  {
    id: 'mentor01',
    code: 'mentor01',
    email: 'mentor01@knowte.com',
    role: 'general',
    name: 'メンター01',
    groups: [
      { id: 'group00', role: 'mentor', name: 'グループ00' },
      { id: 'group01', role: 'mentor', name: 'グループ01' },
    ],
    tenantCode: 'dev',
    locale: 'ja',
    enabled: true,
    confirmedTermsOfServiceVersions: {},
  },
];
