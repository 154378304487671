import en from 'vee-validate/dist/locale/en.json';
import id from 'vee-validate/dist/locale/id.json';
import ja from 'vee-validate/dist/locale/ja.json';
import vi from 'vee-validate/dist/locale/vi.json';

import customEn from './en.json';
import customId from './id.json';
import customJa from './ja.json';
import customVi from './vi.json';

export const locales = {
  en: { messages: { ...en.messages, ...customEn.messages } },
  id: { messages: { ...id.messages }, ...customId.messages },
  ja: { messages: { ...ja.messages, ...customJa.messages } },
  vi: { messages: { ...vi.messages, ...customVi.messages } },
};
