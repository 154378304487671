import { AuthorizationService, CourseId, CourseName } from '@/base/domains';
import { AbstractUseCase, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { CourseHeaderRepository, CourseStatus } from '../domains';
import { EditingConfirmedContentQueries } from '../domains/queries';

export type GetCoursesForEditingRequest = {};

export type GetCoursesForEditingCourse = {
  id: CourseId;
  name: CourseName;
  status: CourseStatus;
  hasEditingContent: boolean;
};

export type GetCoursesForEditingResponse = {
  courses: Array<GetCoursesForEditingCourse>;
};

/**
 * 編集用コースリストを取得する
 */
export interface GetCoursesForEditing
  extends UseCase<GetCoursesForEditingRequest, GetCoursesForEditingResponse> {
  execute(
    request: GetCoursesForEditingRequest
  ): Promise<UseCaseResponse<GetCoursesForEditingResponse>>;
}

export class GetCoursesForEditingImpl
  extends AbstractUseCase<GetCoursesForEditingRequest, GetCoursesForEditingResponse>
  implements GetCoursesForEditing
{
  constructor(
    private authorizationService: AuthorizationService,
    private courseHeaderRepository: CourseHeaderRepository,
    private editingConfirmedContentQueries: EditingConfirmedContentQueries
  ) {
    super('contents.GetCoursesForEditing');
  }

  async internalExecute(_: GetCoursesForEditingRequest): Promise<GetCoursesForEditingResponse> {
    this.authorizationService.assertContentEditable();
    const [courses, editingConfirmedContents] = await Promise.all([
      this.courseHeaderRepository.tenantCourses(),
      this.editingConfirmedContentQueries.findTenantEditingConfirmedContents(),
    ]);
    const hasEditingContentCourseIds = editingConfirmedContents.reduce((acc, v) => {
      acc.add(v.courseId);
      return acc;
    }, new Set<CourseId>());
    return {
      courses: courses.map((cr) => ({
        id: cr.id,
        name: cr.name,
        status: cr.status,
        hasEditingContent: hasEditingContentCourseIds.has(cr.id),
      })),
    };
  }
}

export const GetCoursesForEditingKey = injectionKeyOf<GetCoursesForEditing>({
  boundedContext: 'contents',
  type: 'usecase',
  name: 'GetCoursesForEditing',
});

export function useGetCoursesForEditing(): GetCoursesForEditing {
  return requiredInject(GetCoursesForEditingKey);
}
