





import { defineComponent, onMounted, provide, SetupContext, watch } from '@vue/composition-api';

import { config } from '@/config';
import { addMockDataPostSignIn, configureDi } from '@/di';
import { VueRouteKey, VueRouterKey } from '@/utils/VueUtils';

import { useNetworkErrorHandler } from '../composable/useNetworkErrorHandler';
import { useGlobalStore } from '../store';

export default defineComponent({
  name: 'BaseTheGlobalProvider',
  props: {},
  setup(_, context: SetupContext) {
    // Vue3では次のコードはmain.tsに移動する（App.provide）
    configureDi();
    // Vue3では以下のコードは不要（vue.useRouter, vue.useRouteが提供される）
    provide(VueRouterKey, context.root.$router);
    provide(VueRouteKey, context.root.$route);
    // domain層からも参照の必要があるため実装を変更した
    // ここより前にuseGlobalStoreが実行されないようにしなければならない
    // provide(GlobalStoreKey, createGlobalStore());
    const globalStore = useGlobalStore();
    useNetworkErrorHandler();

    if (config().repositoryType === 'inMemory') {
      const f = addMockDataPostSignIn();
      const stop = watch(globalStore.isSignedIn, (v) => {
        if (v) {
          stop();
          f();
        }
      });
      onMounted(() => {
        // TODO Term 1では固定のグループIDを使用する
        globalStore.changeGroupId('group00');
        if (globalStore.isSignedIn.value) {
          stop();
          f();
        }
      });
    }
  },
});
