import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { UserTagReference, UserTagRepository } from '../domains';
import { AbstractUseCase, UseCase, UseCaseResponse } from './UseCase';

export type GetUserTagsRequest = {};

export type GetUserTagsResponse = {
  userTags: Array<UserTagReference>;
};

/**
 * ユーザータグリストを取得する
 */
export interface GetUserTags extends UseCase<GetUserTagsRequest, GetUserTagsResponse> {
  execute(request: GetUserTagsRequest): Promise<UseCaseResponse<GetUserTagsResponse>>;
}

export class GetUserTagsImpl
  extends AbstractUseCase<GetUserTagsRequest, GetUserTagsResponse>
  implements GetUserTags
{
  constructor(private userTagRepository: UserTagRepository) {
    super('base.GetUserTags');
  }

  async internalExecute(): Promise<GetUserTagsResponse> {
    const userTags = await this.userTagRepository.findTenantUserTags();
    return {
      userTags,
    };
  }
}

export const GetUserTagsKey = injectionKeyOf<GetUserTags>({
  boundedContext: 'base',
  type: 'usecase',
  name: 'GetUserTags',
});

export function useGetUserTags(): GetUserTags {
  return requiredInject(GetUserTagsKey);
}
