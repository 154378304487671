import { QuestionId, UserId } from '@/base/domains';
import { AuthorizationService } from '@/base/domains/AuthorizationService';
import { AbstractUseCase, assertEntityExists, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { QuestionReference, QuestionRepository } from '../domains';

export interface ChangeQuestionAssigneesRequest {
  id: QuestionId;
  assignees: Array<UserId>;
}

export type ChangeQuestionAssigneesResponse = {
  question: QuestionReference;
};

/**
 * 質問の担当を変更する
 */
export interface ChangeQuestionAssignees
  extends UseCase<ChangeQuestionAssigneesRequest, ChangeQuestionAssigneesResponse> {
  execute(
    request: ChangeQuestionAssigneesRequest
  ): Promise<UseCaseResponse<ChangeQuestionAssigneesResponse>>;
}

export class ChangeQuestionAssigneesImpl
  extends AbstractUseCase<ChangeQuestionAssigneesRequest, ChangeQuestionAssigneesResponse>
  implements ChangeQuestionAssignees
{
  constructor(
    private questionRepository: QuestionRepository,
    private authorizationService: AuthorizationService
  ) {
    super('training.ChangeQuestionAssignees');
  }

  async internalExecute(
    request: ChangeQuestionAssigneesRequest
  ): Promise<ChangeQuestionAssigneesResponse> {
    const { id, assignees } = request;
    const question = await this.questionRepository.findById(id);
    assertEntityExists(question, 'question');
    this.authorizationService.assertGroupWriteAccessible(question.groupId);
    const saved = await this.questionRepository.save(question.changeAssignees(assignees));

    return {
      question: saved,
    };
  }
}

export const ChangeQuestionAssigneesKey = injectionKeyOf<ChangeQuestionAssignees>({
  boundedContext: 'training',
  type: 'usecase',
  name: 'ChangeQuestionAssignees',
});

export function useChangeQuestionAssignees(): ChangeQuestionAssignees {
  return requiredInject(ChangeQuestionAssigneesKey);
}
