import { AuthorizationService, CourseId } from '@/base/domains';
import { AbstractUseCase, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { EditingCourseRepository } from '../domains';

export interface RemoveEditingCourseRequest {
  id: CourseId;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export type RemoveEditingCourseResponse = {};

/**
 * 編集中コースを削除する
 */
export interface RemoveEditingCourse
  extends UseCase<RemoveEditingCourseRequest, RemoveEditingCourseResponse> {
  execute(
    request: RemoveEditingCourseRequest
  ): Promise<UseCaseResponse<RemoveEditingCourseResponse>>;
}

export class RemoveEditingCourseImpl
  extends AbstractUseCase<RemoveEditingCourseRequest, RemoveEditingCourseResponse>
  implements RemoveEditingCourse
{
  private authorizationService: AuthorizationService;

  private editingCourseRepository: EditingCourseRepository;

  constructor(
    authorizationService: AuthorizationService,
    editingCourseRepository: EditingCourseRepository
  ) {
    super('contents.RemoveEditingCourse');
    this.authorizationService = authorizationService;
    this.editingCourseRepository = editingCourseRepository;
  }

  async internalExecute(request: RemoveEditingCourseRequest): Promise<RemoveEditingCourseResponse> {
    const { id } = request;
    this.authorizationService.assertContentEditable();
    await this.editingCourseRepository.remove(id);
    return {};
  }
}

export const RemoveEditingCourseKey = injectionKeyOf<RemoveEditingCourse>({
  boundedContext: 'contents',
  type: 'usecase',
  name: 'RemoveEditingCourse',
});

export function useRemoveEditingCourse(): RemoveEditingCourse {
  return requiredInject(RemoveEditingCourseKey);
}
