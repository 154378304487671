import { AuthorizationService } from '@/base/domains';
import { MarkDownString } from '@/base/types';
import { AbstractUseCase, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import {
  EditingTenantOwnTermsOfServiceEntity,
  EditingTenantOwnTermsOfServiceRepository,
} from '../domains';
import { TENANT_OWN_DUPLICATED_TERMS_OF_SERVICE } from '../ErrorCodes';

export type UpdateEditingTenantOwnTermsOfServiceRequest = {
  body: MarkDownString;
  version?: number;
};

export type UpdateEditingTenantOwnTermsOfServiceResponse = {
  editingTenantOwnTermsOfService: EditingTenantOwnTermsOfServiceEntity;
};

export interface UpdateEditingTenantOwnTermsOfService
  extends UseCase<
    UpdateEditingTenantOwnTermsOfServiceRequest,
    UpdateEditingTenantOwnTermsOfServiceResponse
  > {
  execute(
    request: UpdateEditingTenantOwnTermsOfServiceRequest
  ): Promise<UseCaseResponse<UpdateEditingTenantOwnTermsOfServiceResponse>>;
}

export class UpdateEditingTenantOwnTermsOfServiceImpl
  extends AbstractUseCase<
    UpdateEditingTenantOwnTermsOfServiceRequest,
    UpdateEditingTenantOwnTermsOfServiceResponse
  >
  implements UpdateEditingTenantOwnTermsOfService
{
  constructor(
    private authorizationService: AuthorizationService,
    private editingTenantOwnTermsOfServiceRepository: EditingTenantOwnTermsOfServiceRepository
  ) {
    super('admin.UpdateEditingTenantOwnTermsOfService');
  }

  async internalExecute(
    request: UpdateEditingTenantOwnTermsOfServiceRequest
  ): Promise<UpdateEditingTenantOwnTermsOfServiceResponse> {
    this.authorizationService.assertRole('supervisor');
    const { body, version } = request;
    const editingTenantOwnTermsOfService =
      await this.editingTenantOwnTermsOfServiceRepository.findEditingTenantOwnTermsOfService();
    if (
      (!version && editingTenantOwnTermsOfService?.version !== 1) ||
      (version && editingTenantOwnTermsOfService?.version !== version + 1)
    ) {
      throw TENANT_OWN_DUPLICATED_TERMS_OF_SERVICE.toApplicationError();
    }
    const saved = await this.editingTenantOwnTermsOfServiceRepository.save(body);
    return { editingTenantOwnTermsOfService: saved };
  }
}

export const UpdateEditingTenantOwnTermsOfServiceKey =
  injectionKeyOf<UpdateEditingTenantOwnTermsOfService>({
    boundedContext: 'admin',
    type: 'usecase',
    name: 'UpdateEditingTenantOwnTermsOfService',
  });

export function useUpdateEditingTenantOwnTermsOfService(): UpdateEditingTenantOwnTermsOfService {
  return requiredInject(UpdateEditingTenantOwnTermsOfServiceKey);
}
