import { v4 } from 'uuid';

import {
  CourseId,
  EntityData,
  hasId,
  UserTagAttributes,
  UserTagEntity,
  UserTagEntityImpl,
  UserTagId,
  UserTagReference,
  UserTagRepository,
} from '@/base/domains';
import { Optional } from '@/base/types';

export class InMemoryUserTagRepository implements UserTagRepository {
  private store = new Map<CourseId, UserTagEntity>();

  save(args: UserTagEntity | EntityData<string, UserTagAttributes>): Promise<UserTagEntity> {
    if (hasId(args)) {
      const e = new UserTagEntityImpl({
        ...args,
      });
      this.store.set(e.id, e);
      return Promise.resolve(e);
    }
    const e = new UserTagEntityImpl({
      ...args,
      id: v4(),
    });
    this.store.set(e.id, e);
    return Promise.resolve(e);
  }

  findById(id: UserTagId): Promise<Optional<UserTagEntity>> {
    return Promise.resolve(this.store.get(id));
  }

  remove(id: UserTagId): Promise<void> {
    Promise.resolve(this.store.delete(id));
    return Promise.resolve();
  }

  findTenantUserTags(): Promise<Array<UserTagReference>> {
    return Promise.resolve(Array.from(this.store.values()));
  }
}
