import { computed, ref, watch } from '@vue/composition-api';

import { Group, GroupId, Tenant, User, UserId } from '@/base/domains';
import { Optional } from '@/base/types';
import { assertIsSucceeded, useGetGroups, useGetTenant, useGetUsers } from '@/base/usecases';
import { requiredNonNull } from '@/utils/TsUtils';

import { GlobalStoreGroup, GlobalStoreUser, UserAndGroupRole } from './types';

export function useTenantData() {
  const getUsers = useGetUsers();
  const getGroups = useGetGroups();
  const getTenant = useGetTenant();

  const _tenant = ref<Tenant>();
  const tenant = computed<Optional<Tenant>>(() => _tenant.value);
  const _groups = ref<Array<Group>>([]);
  const _users = ref<Array<GlobalStoreUser>>([]);
  const users = computed<Array<GlobalStoreUser>>(() => _users.value || []);
  const enabledUsers = users.value.filter((u) => u.enabled === true);
  const groupsIncludeRemovedGroupUser = computed<Array<GlobalStoreGroup>>(() => {
    const usersMap = new Map(users.value.map((u) => [u.id, u]));
    return (_groups.value ?? []).map((gr) => ({
      ...gr,
      users: gr.users
        .map((u) => {
          const originalUser = usersMap.get(u.id);
          return {
            ...u,
            name: originalUser?.name ?? '',
            avatar: originalUser?.avatar,
            status: originalUser?.status,
            enabled: originalUser?.enabled,
            removed: u.removed,
          };
        })
        .filter((u) => u.enabled),
    }));
  });
  const groups = computed<Array<GlobalStoreGroup>>(() => {
    return groupsIncludeRemovedGroupUser.value
      .filter((g) => g.enabled)
      .map((gr) => ({
        ...gr,
        users: gr.users.filter((g) => !g.removed),
      }));
  });

  function findGroup(groupId: GroupId): Optional<GlobalStoreGroup> {
    return groups.value.find((g) => g.id === groupId);
  }

  function findUser(userId: UserId): Optional<GlobalStoreUser> {
    return users.value.find((u) => u.id === userId);
  }

  function findUserAndGroupRole(userId: UserId, gId: GroupId): Optional<UserAndGroupRole> {
    const u = findUser(userId);
    if (u) {
      const gr = u.groups.find((g) => g.id === gId);
      if (gr) {
        const g = requiredNonNull(findGroup(gr.id), 'group');
        return {
          id: u.id,
          groupId: gr.id,
          name: u.name,
          groupRole: gr.role,
          role: u.role,
          groupName: g.name,
        };
      }
    }
    return undefined;
  }

  const tenantDataFetchedCount = ref(0);
  const tenantDataLoading = ref(false);
  const tenantDataLoaded = computed(() => tenantDataFetchedCount.value > 0);

  const userGroups = (userId: UserId) =>
    _groups.value
      .map((gr) => ({
        groupUser: gr.users.find((u1) => u1.id === userId),
        group: gr,
      }))
      .filter((a) => a.groupUser)
      .map((a) => ({
        id: a.group.id,
        role: requiredNonNull(a.groupUser).role,
      }));

  async function fetchTenantData(): Promise<void> {
    tenantDataLoading.value = true;
    const results = await Promise.all([
      getTenant.execute({}),
      getGroups.execute({}),
      getUsers.execute({}),
    ]).finally(() => {
      tenantDataLoading.value = false;
    });
    assertIsSucceeded(results[0]);
    assertIsSucceeded(results[1]);
    assertIsSucceeded(results[2]);
    const tnt = results[0].tenant;
    const grs = results[1].groups;
    const uss = results[2].users;
    _tenant.value = tnt;
    _groups.value = grs;
    _users.value = uss.map((u) => ({
      id: u.id,
      name: u.name,
      role: u.role,
      avatar: u.avatar,
      groups: userGroups(u.id),
      status: u.status,
      enabled: u.enabled,
    }));
    tenantDataFetchedCount.value += 1;
  }

  function onTenantDataFirstFetched(
    f: (_: {
      tenant: Optional<Tenant>;
      users: Array<GlobalStoreUser>;
      groups: Array<GlobalStoreGroup>;
    }) => void
  ) {
    if (tenantDataFetchedCount.value > 0) {
      f({
        tenant: tenant.value,
        users: users.value,
        groups: groups.value,
      });
    }
    watch(tenantDataFetchedCount, (_, oldValue) => {
      if (oldValue === 0) {
        f({
          tenant: tenant.value,
          users: users.value,
          groups: groups.value,
        });
      }
    });
  }

  function putUser(user: User): void {
    if (tenantDataLoading.value) {
      return;
    }
    if (_users.value.find((u) => u.id === user.id)) {
      _users.value = _users.value.map((u) => {
        if (u.id === user.id) {
          return {
            id: user.id,
            name: user.name,
            role: user.role,
            avatar: user.avatar,
            groups: userGroups(user.id),
            status: user.status,
            enabled: u.enabled,
          };
        }
        return u;
      });
      return;
    }
    _users.value = [
      ..._users.value,
      {
        id: user.id,
        name: user.name,
        role: user.role,
        avatar: user.avatar,
        groups: userGroups(user.id),
        status: user.status,
        enabled: user.enabled,
      },
    ];
  }

  return {
    tenant,
    users,
    enabledUsers,
    groups,
    groupsIncludeRemovedGroupUser,
    tenantDataLoaded,
    findGroup,
    findUser,
    findUserAndGroupRole,
    fetchTenantData,
    onTenantDataFirstFetched,
    putUser,
  };
}
