import {
  AuthorizationServiceKey,
  ContentFinderKey,
  CourseFinderKey,
  GroupFinderImpl,
  GroupFinderKey,
  GroupTrainingFinderKey,
  QuestionFinderKey,
  ReviewProblemFinderKey,
  ScheduleFinderKey,
  TenantOwnTermsOfServiceFinderImpl,
  TenantOwnTermsOfServiceFinderKey,
  useAppContextProvider,
  useContentLearningDataAdapter,
  useExamDataAdapter,
  useGroupRepository,
  UserFinderImpl,
  UserFinderKey,
  useUserRepository,
} from '@/base/domains';
import { AuthorizationServiceImpl } from '@/base/domains/AuthorizationServiceImpl';
import { ExtensionServiceKey } from '@/base/domains/extensions/ExtensionService';
import { ExtensionServiceImpl } from '@/base/domains/extensions/ExtensionServiceImpl';
import {
  name as slackExtensionName,
  SlackAppExtensionFactory,
  SlackGroupExtensionFactory,
  SlackUserExtensionFactory,
} from '@/base/domains/extensions/slack';
import { useTenantOwnTermsOfServiceQueries } from '@/base/domains/queries';
import { MessageServiceKey } from '@/base/message';
import {
  ContentFinderImpl,
  CourseFinderImpl,
  useContentRepository,
  useCourseHeaderRepository,
  useCourseRepository,
} from '@/contents/domains';
import { useContentQueries } from '@/contents/domains/queries';
import { ReportServiceImpl, ReportServiceKey } from '@/report/domains';
import {
  GroupTrainingFinderImpl,
  ScheduleFinderImpl,
  useGroupTrainingCourseRepository,
  useGroupTrainingRepository,
  useQuestionRepository,
  useReviewProblemRepository,
  useScheduleRepository,
} from '@/training/domains';
import { useQuestionQueries } from '@/training/domains/queries';
import { QuestionFinderImpl } from '@/training/domains/QuestionFinderImpl';
import { ReviewProblemFinderImpl } from '@/training/domains/ReviewProblemFinderImpl';
import { provide } from '@/utils/VueUtils';

import { createMessageService } from './createMessageService';

export function services() {
  const appContextProvider = useAppContextProvider();
  const courseRepository = useCourseRepository();
  const courseHeaderRepository = useCourseHeaderRepository();
  const contentRepository = useContentRepository();
  const groupTrainingRepository = useGroupTrainingRepository();
  const groupTrainingCourseRepository = useGroupTrainingCourseRepository();
  const scheduleRepository = useScheduleRepository();
  const userRepository = useUserRepository();
  const groupRepository = useGroupRepository();
  const questionRepository = useQuestionRepository();
  const examDataAdapter = useExamDataAdapter();
  const contentLearningDataAdapter = useContentLearningDataAdapter();
  const questionQueries = useQuestionQueries();
  const contentQueries = useContentQueries();
  const tenantOwnTermsOfServiceQueries = useTenantOwnTermsOfServiceQueries();
  const reviewProblemRepository = useReviewProblemRepository();

  const extensionService = new ExtensionServiceImpl();
  // TODO アプリケーション設定で有効なものだけインストール
  extensionService.install({
    name: slackExtensionName,
    appExtensionFactory: new SlackAppExtensionFactory(),
    groupExtensionFactory: new SlackGroupExtensionFactory(),
    userExtensionFactory: new SlackUserExtensionFactory(),
  });
  provide(ExtensionServiceKey, extensionService);
  provide(CourseFinderKey, new CourseFinderImpl(courseRepository, courseHeaderRepository));
  provide(ContentFinderKey, new ContentFinderImpl(contentRepository, contentQueries));
  const groupTrainingFinder = new GroupTrainingFinderImpl(
    groupTrainingRepository,
    groupTrainingCourseRepository
  );
  provide(GroupTrainingFinderKey, groupTrainingFinder);
  // base
  provide(ScheduleFinderKey, new ScheduleFinderImpl(scheduleRepository));
  const userFinder = new UserFinderImpl(userRepository);
  provide(UserFinderKey, userFinder);
  const groupFinder = new GroupFinderImpl(groupRepository);
  provide(GroupFinderKey, groupFinder);
  const questionFinder = new QuestionFinderImpl(questionRepository, questionQueries);
  provide(QuestionFinderKey, questionFinder);
  provide(AuthorizationServiceKey, new AuthorizationServiceImpl(appContextProvider));
  provide(MessageServiceKey, createMessageService());
  provide(
    TenantOwnTermsOfServiceFinderKey,
    new TenantOwnTermsOfServiceFinderImpl(tenantOwnTermsOfServiceQueries)
  );
  provide(ReviewProblemFinderKey, new ReviewProblemFinderImpl(reviewProblemRepository));

  // report
  provide(
    ReportServiceKey,
    new ReportServiceImpl(
      examDataAdapter,
      groupTrainingFinder,
      groupFinder,
      userFinder,
      contentLearningDataAdapter,
      questionFinder,
      appContextProvider
    )
  );
}
