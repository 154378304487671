





import { defineComponent } from '@vue/composition-api';

import TheGlobalProvider from '@/base/app/components/TheGlobalProvider.vue';

export default defineComponent({
  name: 'App',
  components: {
    TheGlobalProvider,
  },
});
