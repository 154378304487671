import {
  ContentId,
  ContentName,
  ContentVersion,
  CourseDisplayName,
  CourseId,
  ExamId,
  GroupId,
  GroupName,
  MemoId,
  NotificationType,
  notificationTypeOf,
  QuestionId,
  QuestionnaireId,
  UserId,
} from './domains';
import { MarkDownString } from './types';

// TODO 作成したら削除する
export const SampleNotification: NotificationType<{
  body: string;
}> = notificationTypeOf('SAMPLE_NOTIFICATION');

export type AskQuestionNotificationPayload = {
  /** 質問のId */
  questionId: QuestionId;
  /** 質問のタイトル */
  title: string;
  /** 質問の作成者 */
  createdBy: UserId;
  /** コメント本文 */
  commentBody: MarkDownString;
  /** グループId */
  groupId: GroupId;
};
export const AskQuestionNotification: NotificationType<AskQuestionNotificationPayload> =
  notificationTypeOf('ASK_QUESTION_NOTIFICATION');

export type AddCommentToQuestionNotificationPayload = {
  /** 質問のId */
  questionId: QuestionId;
  /** 質問のタイトル */
  title: string;
  /** コメントのId */
  commentId: string;
  /** コメント本文 */
  commentBody: MarkDownString;
  /** コメントの作成者 */
  commentCreatedBy: UserId;
  /** グループId */
  groupId: GroupId;
};
export const AddCommentToQuestionNotification: NotificationType<AddCommentToQuestionNotificationPayload> =
  notificationTypeOf('ADD_COMMENT_TO_QUESTION_NOTIFICATION');

export type EditCommentOnQuestionNotificationPayload = {
  /** 質問のId */
  questionId: QuestionId;
  /** 質問のタイトル */
  title: string;
  /** コメントのId */
  commentId: string;
  /** コメント本文 */
  commentBody: MarkDownString;
  /** コメントの作成者 */
  commentCreatedBy: UserId;
  /** グループId */
  groupId: GroupId;
  /** コメントの編集者 */
  editedBy: UserId;
};
export const EditCommentOnQuestionNotification: NotificationType<EditCommentOnQuestionNotificationPayload> =
  notificationTypeOf('EDIT_COMMENT_ON_QUESTION_NOTIFICATION');

export type CreateQuestionnaireNotificationPayload = {
  /** アンケートのId */
  questionnaireId: QuestionnaireId;
  /** アンケートのタイトル */
  title: string;
  /** アンケートの作成者 */
  createdBy: UserId;
  /** アンケートの質問内容 */
  text: MarkDownString;
  /** グループId */
  groupId: GroupId;
};
export const CreateQuestionnaireNotification: NotificationType<CreateQuestionnaireNotificationPayload> =
  notificationTypeOf('CREATE_QUESTIONNAIRE_NOTIFICATION');

export type CreateGroupMemoPayload = {
  /** メモのID */
  memoId: MemoId;
  /** メモの内容 */
  body: MarkDownString;
  /** メモの作成者 */
  createdBy: UserId;
  /** グループId */
  groupId: GroupId;
  /** コースId */
  courseId?: CourseId;
  /** コース名 */
  courseName?: CourseDisplayName;
  /** コンテンツId */
  contentId?: ContentId;
  /** コンテンツ名 */
  contentName?: ContentName;
  /** コンテンツバージョン */
  contentVersion?: ContentVersion;
};
export const CreateGroupMemoNotification: NotificationType<CreateGroupMemoPayload> =
  notificationTypeOf('CREATE_GROUP_MEMO_NOTIFICATION');

export type UpdateGroupMemoPayload = {
  /** メモのID */
  memoId: MemoId;
  /** メモの内容 */
  body: MarkDownString;
  /** メモの作成者 */
  updatedBy: UserId;
  /** グループId */
  groupId: GroupId;
  /** コースId */
  courseId?: CourseId;
  /** コース名 */
  courseName?: CourseDisplayName;
  /** コンテンツId */
  contentId?: ContentId;
  /** コンテンツ名 */
  contentName?: ContentName;
  /** コンテンツバージョン */
  contentVersion?: ContentVersion;
};
export const UpdateGroupMemoNotification: NotificationType<UpdateGroupMemoPayload> =
  notificationTypeOf('UPDATE_GROUP_MEMO_NOTIFICATION');

export type StartExamNotificationPayload = {
  /** テストのId */
  examId: ExamId;
  /** コースId */
  courseId: CourseId;
  /** コース名 */
  courseName: string;
  /** コンテンツId */
  contentId: ContentId;
  /** コンテンツ名 */
  contentName: string;
  /** テストの開催者 */
  createdBy: UserId;
  /** グループId */
  groupId: GroupId;
};
export const StartExamNotification: NotificationType<StartExamNotificationPayload> =
  notificationTypeOf('START_EXAM_NOTIFICATION');

export type ResolveQuestionNotificationPayload = {
  /** 質問のId */
  questionId: QuestionId;
  /** 質問のタイトル */
  title: string;
  /** 質問の解決者 */
  resolvedBy: UserId;
  /** グループId */
  groupId: GroupId;
};
export const ResolveQuestionNotification: NotificationType<ResolveQuestionNotificationPayload> =
  notificationTypeOf('RESOLVE_QUESTION_NOTIFICATION');

export type AddGroupUserNotificationPayload = {
  /** グループId */
  groupId: GroupId;
  /** グループ名 */
  groupName: GroupName;
  /** ユーザーの追加者ID */
  addedBy: UserId;
};
export const AddGroupUserNotification: NotificationType<AddGroupUserNotificationPayload> =
  notificationTypeOf('ADD_GROUP_USER_NOTIFICATION');

export type ExamReminderNotificationPayload = {
  /** テストのId */
  examId: ExamId;
  /** コースId */
  courseId: CourseId;
  /** コース名 */
  courseName: string;
  /** コンテンツId */
  contentId: ContentId;
  /** コンテンツ名 */
  contentName: string;
  /** テストの開催者 */
  createdBy: UserId;
  /** 開始日時 */
  scheduledStart: string;
  /** グループId */
  groupId: GroupId;
};
export const ExamReminderNotification: NotificationType<ExamReminderNotificationPayload> =
  notificationTypeOf('EXAM_REMINDER_NOTIFICATION');
