import { AuthorizationService, ContentId, DataVersion, DateTimeService } from '@/base/domains';
import { MarkDownString } from '@/base/types';
import {
  AbstractUseCase,
  assertDataVersion,
  assertEntityExists,
  UseCase,
  UseCaseResponse,
} from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { EditingConfirmedContentReference, EditingConfirmedContentRepository } from '../domains';

export type AddProblemHeaderToEditingConfirmedContentWorkbookRequest = {
  id: ContentId;
  body: MarkDownString;
  expectedDataVersion: DataVersion;
};

export type AddProblemHeaderToEditingConfirmedContentWorkbookResponse = {
  editingConfirmedContent: EditingConfirmedContentReference;
};

/**
 * 編集中確定済みコンテンツ練習問題に問題ヘッダーを追加する
 */
export interface AddProblemHeaderToEditingConfirmedContentWorkbook
  extends UseCase<
    AddProblemHeaderToEditingConfirmedContentWorkbookRequest,
    AddProblemHeaderToEditingConfirmedContentWorkbookResponse
  > {
  execute(
    request: AddProblemHeaderToEditingConfirmedContentWorkbookRequest
  ): Promise<UseCaseResponse<AddProblemHeaderToEditingConfirmedContentWorkbookResponse>>;
}

export class AddProblemHeaderToEditingConfirmedContentWorkbookImpl
  extends AbstractUseCase<
    AddProblemHeaderToEditingConfirmedContentWorkbookRequest,
    AddProblemHeaderToEditingConfirmedContentWorkbookResponse
  >
  implements AddProblemHeaderToEditingConfirmedContentWorkbook
{
  constructor(
    private authorizationService: AuthorizationService,
    private editingConfirmedContentRepository: EditingConfirmedContentRepository,
    private dateTimeService: DateTimeService
  ) {
    super('contents.AddProblemHeaderToEditingConfirmedContentWorkbook');
  }

  async internalExecute(
    request: AddProblemHeaderToEditingConfirmedContentWorkbookRequest
  ): Promise<AddProblemHeaderToEditingConfirmedContentWorkbookResponse> {
    const { id, body, expectedDataVersion } = request;
    this.authorizationService.assertContentEditable();
    const [content, createdAt] = await Promise.all([
      this.editingConfirmedContentRepository.findById(id),
      this.dateTimeService.strictLocalDateTimeNow(),
    ]);
    assertEntityExists(content, 'editingConfirmedContent');
    assertDataVersion(content, expectedDataVersion, 'editingConfirmedContent');
    if (content.type === 'text') {
      const saved = await this.editingConfirmedContentRepository.save(
        content.addProblemHeaderToWorkbook({
          problemHeaderBody: body,
          createdAt,
        })
      );
      return {
        editingConfirmedContent: saved,
      };
    }
    throw new Error('editingConfirmedContent.type should be text');
  }
}

export const AddProblemHeaderToEditingConfirmedContentWorkbookKey =
  injectionKeyOf<AddProblemHeaderToEditingConfirmedContentWorkbook>({
    boundedContext: 'contents',
    type: 'usecase',
    name: 'AddProblemHeaderToEditingConfirmedContentWorkbook',
  });

export function useAddProblemHeaderToEditingConfirmedContentWorkbook(): AddProblemHeaderToEditingConfirmedContentWorkbook {
  return requiredInject(AddProblemHeaderToEditingConfirmedContentWorkbookKey);
}
