import { ContentId, ContentName, CourseDisplayName, CourseId, GroupExamId } from '@/base/domains';
import { errorCodeOf } from '@/base/error';

export const EDITING_TENANT_OWN_DUPLICATED_TERMS_OF_SERVICE = errorCodeOf(
  'AD0001',
  '下書きが既に作成されています'
);

export const TENANT_OWN_DUPLICATED_TERMS_OF_SERVICE = errorCodeOf(
  'AD0002',
  'テナント利用規約が既に更新されています'
);

export const GROUP_DISABLE_FAILED_GROUP_EXAM_IS_OPEN = errorCodeOf<{
  exams: {
    groupExamId: GroupExamId;
    courseId: CourseId;
    courseName: CourseDisplayName;
    contentId: ContentId;
    contentName: ContentName;
  }[];
}>('AD0003', '開催中のテストがあるためグループを無効化できません');

export const GROUP_REMOVE_FAILED_GROUP_EXAM_IS_OPEN = errorCodeOf<{
  exams: {
    groupExamId: GroupExamId;
    courseId: CourseId;
    courseName: CourseDisplayName;
    contentId: ContentId;
    contentName: ContentName;
  }[];
}>('AD0004', '開催中のテストがあるためグループを削除できません');

export const ENABLED_GROUP_LIMITATION_EXCEEDED = errorCodeOf<{}>(
  'AD0005',
  '有効なグループの上限を超えています'
);
