import { RouteConfig } from 'vue-router';

const SignInPage = import(/* webpackChunkName: "account" */ './pages/SignInPage.vue');
const SignedOutPage = import(/* webpackChunkName: "account" */ './pages/SignedOutPage.vue');
const SignUpPage = import(/* webpackChunkName: "account" */ './pages/SignUpPage.vue');
const ActivateAccountPage = import(
  /* webpackChunkName: "account" */ './pages/ActivateAccountPage.vue'
);
const RequestResetPasswordPage = import(
  /* webpackChunkName: "account" */ './pages/RequestResetPasswordPage.vue'
);
const ResetPasswordPage = import(/* webpackChunkName: "account" */ './pages/ResetPasswordPage.vue');
const AccountsPage = import(/* webpackChunkName: "account" */ './pages/AccountsPage.vue');

export const accountRoutes: RouteConfig[] = [
  {
    path: '/signin',
    name: 'signIn',
    component: () => SignInPage,
    props: (route) => route.query,
  },
  {
    path: '/signed-out',
    name: 'signedOut',
    component: () => SignedOutPage,
  },
  {
    path: '/signup',
    name: 'signUp',
    component: () => SignUpPage,
    props: (route) => route.query,
  },
  {
    path: '/account/activate',
    name: 'activateAccount',
    component: () => ActivateAccountPage,
    props: (route) => route.query,
  },
  {
    path: '/account/reset-password',
    name: 'resetPassword',
    component: () => ResetPasswordPage,
    props: (route) => route.query,
  },
  {
    path: '/account/request-reset-password',
    name: 'requestResetPassword',
    component: () => RequestResetPasswordPage,
    props: (route) => route.query,
  },
  {
    path: '/admin/accounts',
    name: 'adminAccounts',
    component: () => AccountsPage,
  },
];
