import { AuthorizationService, ContentId, ContentVersion } from '@/base/domains';
import { AbstractUseCase, assertEntityExists, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

import { ContentReference, ContentRepository } from '../domains';

export type ChangeContentVersionDescriptionRequest = {
  id: ContentId;
  version: ContentVersion;
  versionDescription: string;
};

export type ChangeContentVersionDescriptionResponse = {
  content: ContentReference;
};

/**
 * コンテンツのバージョン説明を変更する
 */
export interface ChangeContentVersionDescription
  extends UseCase<ChangeContentVersionDescriptionRequest, ChangeContentVersionDescriptionResponse> {
  execute(
    request: ChangeContentVersionDescriptionRequest
  ): Promise<UseCaseResponse<ChangeContentVersionDescriptionResponse>>;
}

export class ChangeContentVersionDescriptionImpl
  extends AbstractUseCase<
    ChangeContentVersionDescriptionRequest,
    ChangeContentVersionDescriptionResponse
  >
  implements ChangeContentVersionDescription
{
  constructor(
    private authorizationService: AuthorizationService,
    private contentRepository: ContentRepository
  ) {
    super('contents.ChangeContentVersionDescription');
  }

  async internalExecute({
    id,
    version,
    versionDescription,
  }: ChangeContentVersionDescriptionRequest): Promise<ChangeContentVersionDescriptionResponse> {
    this.authorizationService.assertContentEditable();
    const content = await this.contentRepository.findById(id, version);
    assertEntityExists(content, 'content');
    const saved = await this.contentRepository.save(
      content.changeVersionDescription(versionDescription)
    );
    return {
      content: saved,
    };
  }
}

export const ChangeContentVersionDescriptionKey = injectionKeyOf<ChangeContentVersionDescription>({
  boundedContext: 'contents',
  type: 'usecase',
  name: 'ChangeContentVersionDescription',
});

export function useChangeContentVersionDescription(): ChangeContentVersionDescription {
  return requiredInject(ChangeContentVersionDescriptionKey);
}
