import { useEditingTenantOwnTermsOfServiceRepository } from '@/admin/domains';
import {
  AddGroupUserImpl,
  AddGroupUserKey,
  ChangeGroupDescriptionImpl,
  ChangeGroupDescriptionKey,
  ChangeGroupLimitationsImpl,
  ChangeGroupLimitationsKey,
  ChangeGroupNameImpl,
  ChangeGroupNameKey,
  ConfirmEditingTenantOwnTermsOfServiceImpl,
  ConfirmEditingTenantOwnTermsOfServiceKey,
  CreateEditingTenantOwnTermsOfServiceImpl,
  CreateEditingTenantOwnTermsOfServiceKey,
  CreateGroupImpl,
  CreateGroupKey,
  DeleteEditingTenantOwnTermsOfServiceImpl,
  DeleteEditingTenantOwnTermsOfServiceKey,
  DisableGroupImpl,
  DisableGroupKey,
  EnableGroupImpl,
  EnableGroupKey,
  GetEditingTenantOwnTermsOfServiceImpl,
  GetEditingTenantOwnTermsOfServiceKey,
  GetGroupByAdminImpl,
  GetGroupByAdminKey,
  GetGroupsImpl,
  GetGroupsKey,
  GetUsersImpl,
  GetUsersKey,
  RemoveGroupImpl,
  RemoveGroupKey,
  RemoveGroupUserImpl,
  RemoveGroupUserKey,
  SaveGroupExtensionConfigImpl,
  SaveGroupExtensionConfigKey,
  UpdateEditingTenantOwnTermsOfServiceImpl,
  UpdateEditingTenantOwnTermsOfServiceKey,
} from '@/admin/usecases';
import {
  useAppContextProvider,
  useAuthorizationService,
  useExamDataAdapter,
  useGroupRepository,
  useTenantOwnTermsOfServiceFinder,
  useUserRepository,
} from '@/base/domains';
import { provide } from '@/utils/VueUtils';

export function useAdminUseCases() {
  const groupRepository = useGroupRepository();
  const userRepository = useUserRepository();
  const authorizationService = useAuthorizationService();
  const appContextProvider = useAppContextProvider();
  const tenantOwnTermsOfServiceFinder = useTenantOwnTermsOfServiceFinder();
  const editingTenantOwnTermsOfServiceRepository = useEditingTenantOwnTermsOfServiceRepository();
  const examDataAdapter = useExamDataAdapter();

  provide(GetGroupsKey, new GetGroupsImpl(groupRepository));
  provide(
    ChangeGroupDescriptionKey,
    new ChangeGroupDescriptionImpl(authorizationService, groupRepository)
  );
  provide(
    DisableGroupKey,
    new DisableGroupImpl(authorizationService, groupRepository, examDataAdapter)
  );
  provide(EnableGroupKey, new EnableGroupImpl(authorizationService, groupRepository));
  provide(GetGroupByAdminKey, new GetGroupByAdminImpl(authorizationService, groupRepository));
  provide(CreateGroupKey, new CreateGroupImpl(authorizationService, groupRepository));
  provide(ChangeGroupNameKey, new ChangeGroupNameImpl(groupRepository));
  provide(ChangeGroupLimitationsKey, new ChangeGroupLimitationsImpl(groupRepository));
  provide(
    RemoveGroupKey,
    new RemoveGroupImpl(authorizationService, groupRepository, examDataAdapter)
  );
  provide(AddGroupUserKey, new AddGroupUserImpl(groupRepository));
  provide(RemoveGroupUserKey, new RemoveGroupUserImpl(authorizationService, groupRepository));
  provide(
    SaveGroupExtensionConfigKey,
    new SaveGroupExtensionConfigImpl(authorizationService, groupRepository)
  );
  provide(GetUsersKey, new GetUsersImpl(userRepository));
  provide(
    ConfirmEditingTenantOwnTermsOfServiceKey,
    new ConfirmEditingTenantOwnTermsOfServiceImpl(
      authorizationService,
      editingTenantOwnTermsOfServiceRepository
    )
  );
  provide(
    DeleteEditingTenantOwnTermsOfServiceKey,
    new DeleteEditingTenantOwnTermsOfServiceImpl(
      authorizationService,
      appContextProvider,
      editingTenantOwnTermsOfServiceRepository
    )
  );
  provide(
    CreateEditingTenantOwnTermsOfServiceKey,
    new CreateEditingTenantOwnTermsOfServiceImpl(
      authorizationService,
      tenantOwnTermsOfServiceFinder,
      editingTenantOwnTermsOfServiceRepository
    )
  );
  provide(
    UpdateEditingTenantOwnTermsOfServiceKey,
    new UpdateEditingTenantOwnTermsOfServiceImpl(
      authorizationService,
      editingTenantOwnTermsOfServiceRepository
    )
  );
  provide(
    GetEditingTenantOwnTermsOfServiceKey,
    new GetEditingTenantOwnTermsOfServiceImpl(
      authorizationService,
      tenantOwnTermsOfServiceFinder,
      editingTenantOwnTermsOfServiceRepository
    )
  );
}
