import BigNumber from 'bignumber.js';

import {
  ContentId,
  ContentName,
  CourseDisplayName,
  CourseId,
  CourseName,
  GroupExamId,
  GroupId,
  UserId,
  UserName,
} from '@/base/domains';
import { LocalDate, LocalDateTime, Minute, Percentage } from '@/base/types';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

export type UserExamRecord = {
  userId: UserId;
  userName: UserName;
  results: Array<{
    contentId: ContentId;
    contentName: ContentName;
    /** スコア。テストが開催されていない場合は入らない */
    score?: BigNumber;
    /** 問題数。テストが開催されていない場合は入らない */
    problemCount?: number;
  }>;
};

export type UserCourseProgressRecord = {
  userId: UserId;
  userName: UserName;
  results: Array<{ courseId: ContentId; courseName: ContentName; progress: Percentage }>;
};

export type UserCourseStatusRecord = {
  userId: UserId;
  userName: UserName;
  progress: Percentage;
  usageTime: number;
  questionCount: number;
};

export type CourseStatusRecord = {
  courseId: CourseId;
  courseName: CourseName;
  progress: Percentage;
  usageTime: number;
  questionCount: number;
};

export type UserCourseReport = {
  /** コースID */
  id: CourseId;
  /** コース名 */
  name: CourseDisplayName;
  /** 利用開始日時 */
  startOfUse?: LocalDateTime;
  /** 直近利用日時 */
  recentUsedAt?: LocalDateTime;
  /** 利用時間 */
  usageTime: Minute;
  /** グループ利用時間 */
  groupUsageTime: Minute;
  /** グループ平均利用時間 */
  groupAverageUsageTime: BigNumber;
  /** コメント数 */
  commentCount: number;
  /** グループ平均コメント数 */
  groupAverageCommentCount: BigNumber;
  /** コンテンツ数 */
  contentCount: number;
  /** 未着手コンテンツ数 */
  notBegunContentCount: number;
  /** 実施中コンテンツ数 */
  inProgressContentCount: number;
  /** 完了コンテンツ数 */
  completedContentCount: number;
  /** 進捗率 */
  progressRate: Percentage;
  /** 未実施テスト数 */
  notBegunExamCount: number;
  /** 完了テスト数 */
  completedExamCount: number;
  /** 正答数 */
  correctAnswerCount: number;
  /** テスト問題数 */
  examProblemCount: number;
  /** 正答率 */
  correctAnswerRate: Percentage;
};

export type GroupCourseReport = {
  /** コースID */
  courseId: CourseId;
  /** コース名 */
  courseName: CourseDisplayName;
  /** 利用開始日時 */
  startOfUse?: LocalDateTime;
  /** 直近利用日時 */
  recentUsedAt?: LocalDateTime;
  /** グループ利用時間 */
  groupUsageTime: BigNumber;
  /** コメント数 */
  commentCount: BigNumber;
  /** 未着手コンテンツ数 */
  notBegunContentCount: BigNumber;
  /** 実施中コンテンツ数 */
  inProgressContentCount: BigNumber;
  /** 完了コンテンツ数 */
  completedContentCount: BigNumber;
  /** 未実施テスト数 */
  notBegunExamCount: BigNumber;
  /** 完了テスト数 */
  completedExamCount: BigNumber;
  /** 正答率 */
  correctAnswerRate: Percentage;
};

export type GroupExamResultReport = {
  contentId: ContentId;
  courseName: CourseDisplayName;
  examName: ContentName;
  times: number;
  correctAnswerRate: Percentage;
  /** 実施者数 */
  examCount: number;
  /** 合格基準 */
  passingStandard?: number;
  /** 合格者数 */
  passedUserCount?: number;
};

export type GroupCourseAverage = {
  /** コース平均利用開始日 */
  courseAverageStartOfUse?: LocalDate;
  /** コース平均直近利用日時 */
  courseAverageRecentUsedAt?: LocalDateTime;
  /** コース平均利用時間 */
  courseAverageUsageTime: BigNumber;
  /** コース平均コメント数 */
  courseAverageCommentCount: BigNumber;
  /** コース平均未着手コンテンツ数 */
  courseAverageNotBegunContentCount: BigNumber;
  /** コース平均実施中コンテンツ数 */
  courseAverageInProgressContentCount: BigNumber;
  /** コース平均完了コンテンツ数 */
  courseAverageCompletedContentCount: BigNumber;
  /** コース平均完了テスト正答率 */
  courseAverageCorrectAnswerRate: BigNumber;
  /** コース平均未完了テスト数 */
  courseAverageNotBegunExamCount: BigNumber;
  /** コース平均完了テスト数 */
  courseAverageCompletedExamCount: BigNumber;
};

export type TrainerUserReportRecord = {
  userId: UserId;
  userName: UserName;
  /** 利用開始日 */
  startOfUse?: LocalDate;
  /** 直近利用日時 */
  recentUsedAt?: LocalDateTime;
  /** 利用時間 */
  usageTime: Minute;
  /** 未着手コ―ス数 */
  notBegunCourseCount: number;
  /** 実施中コース数 */
  inProgressCourseCount: number;
  /** 完了コース数 */
  completedCourseCount: number;
  /** コース進捗率 */
  courseProgressRate: Percentage;
  /** コメント数 */
  commentCount: number;
  /** 未実施テスト数 */
  notBegunExamCount: number;
  /** 完了テスト数 */
  completedExamCount: number;
  /** テスト問題数 */
  examProblemCount: number;
  /** 正答率 */
  correctAnswerRate: Percentage;
  /** ユーザー削除済み */
  removed: boolean;
};

export type TrainerUserReportRecords = {
  records: Array<TrainerUserReportRecord>;
  /** コースリスト */
  courses: Array<GroupCourseReport>;
  /** コース平均リスト */
  courseAverages: GroupCourseAverage;
  /** テスト結果リスト */
  examResults: Array<GroupExamResultReport>;
  /** グループ平均利用開始日 */
  groupAverageStartOfUse?: LocalDate;
  /** グループ平均直近利用日時 */
  groupAverageRecentUsedAt?: LocalDateTime;
  /** グループ平均利用時間 */
  groupAverageUsageTime: BigNumber;
  /** グループ平均コメント数 */
  groupAverageCommentCount: BigNumber;
  /** グループ平均未着手コース数 */
  groupAverageNotBegunCourseCount: BigNumber;
  /** グループ平均実施中コース数 */
  groupAverageInProgressCourseCount: BigNumber;
  /** グループ平均完了コース数 */
  groupAverageCompletedCourseCount: BigNumber;
  /** グループ平均コース進捗率 */
  groupAverageCourseProgressRate: BigNumber;
  /** グループ平均完了テスト正答率 */
  groupAverageCorrectAnswerRate: BigNumber;
  /** グループ平均未完了テスト数 */
  groupAverageNotBegunExamCount: BigNumber;
  /** グループ平均完了テスト数 */
  groupAverageCompletedExamCount: BigNumber;
};

export type UserCourseProgresses = {
  id: CourseId;
  name: CourseDisplayName;
  contentCount: number;
  notBegunContentCount: number;
  inProgressContentCount: number;
  completedContentCount: number;
  progressRate: Percentage;
};

export type GroupCourseProgresses = {
  userId: UserId;
  userName: UserName;
  notBegunContentCount: number;
  inProgressContentCount: number;
  completedContentCount: number;
  progressRate: Percentage;
};

export type UserExamCorrections = {
  id: ContentId;
  name: CourseDisplayName;
  examName: ContentName;
  times: number;
  correctAnswerRate: Percentage;
};

export type UserCorrectAnswerRate = {
  userId: UserId;
  userName: UserName;
  correctAnswerRate?: Percentage;
  isPassed?: boolean;
};

export type GroupExamCorrections = {
  groupExamId: GroupExamId;
  courseName: CourseDisplayName;
  contentName: ContentName;
  passingStandardRate?: Percentage;
  userCorrectAnswerRateList: UserCorrectAnswerRate[];
};

export interface ReportService {
  /**
   * ユーザーテスト結果レポートを取得する
   */
  findUserExamRecords(groupId: GroupId, courseId: CourseId): Promise<Array<UserExamRecord>>;
  /**
   * ユーザーコース進捗レポートを取得する
   */
  findUserCourseProgressRecords(groupId: GroupId): Promise<Array<UserCourseProgressRecord>>;
  /**
   * ユーザーコースステータスを取得する
   */
  findUserCourseStatusRecords(
    groupId: GroupId,
    courseId: CourseId
  ): Promise<Array<UserCourseStatusRecord>>;
  findCourseStatusRecords(groupId: GroupId, userId: UserId): Promise<Array<CourseStatusRecord>>;
  /**
   * グループ内受講生の利用状況リストを取得する
   */
  findTraineeRecords(groupId: GroupId): Promise<TrainerUserReportRecords>;
  /**
   * 受講生のコース進捗を取得する
   */
  findUserCourseProgresses(groupId: GroupId, userId: UserId): Promise<Array<UserCourseProgresses>>;
  /**
   * 受講生のテスト正答率を取得する
   */
  findUserExamCorrections(groupId: GroupId, userId: UserId): Promise<Array<UserExamCorrections>>;
  /**
   * グループ内のコース進捗
   */
  findGroupCourseProgresses(
    groupId: GroupId,
    courseId: CourseId
  ): Promise<Array<GroupCourseProgresses>>;
  /**
   * グループ内のテスト正答率
   */
  getGroupExamCorrections(
    groupId: GroupId,
    contentId: ContentId,
    times: number
  ): Promise<GroupExamCorrections>;
}

export const ReportServiceKey = injectionKeyOf<ReportService>({
  boundedContext: 'report',
  type: 'service',
  name: 'ReportService',
});

export function useReportService(): ReportService {
  return requiredInject(ReportServiceKey);
}
