import { UserId } from '../..';
import { UserExtensionConfig } from '../Extension';

export const name = 'slack';

export const version = '0.0.1';

export type SlackAppType = 'web' | 'native';

/**
 * Slack拡張 ユーザー設定
 */
export interface SlackUserExtensionConfig extends UserExtensionConfig {
  /**
   * SlackのユーザーID
   */
  userId: UserId;

  /**
   * Slackのアプリのタイプ
   */
  appType: SlackAppType;
}
