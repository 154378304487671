import { AuthService, UserId } from '@/base/domains';
import { AbstractUseCase, UseCase, UseCaseResponse } from '@/base/usecases';
import { injectionKeyOf, requiredInject } from '@/utils/VueUtils';

export interface ConfirmUserRequest {
  userId: UserId;
  code: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export type ConfirmUserResponse = {};

/**
 * ユーザーを確認する
 */
export interface ConfirmUser extends UseCase<ConfirmUserRequest, ConfirmUserResponse> {
  execute(request: ConfirmUserRequest): Promise<UseCaseResponse<ConfirmUserResponse>>;
}

export class ConfirmUserImpl
  extends AbstractUseCase<ConfirmUserRequest, ConfirmUserResponse>
  implements ConfirmUser
{
  private authService: AuthService;

  constructor(authService: AuthService) {
    super('account.ConfirmUser');
    this.authService = authService;
  }

  async internalExecute(request: ConfirmUserRequest): Promise<ConfirmUserResponse> {
    await this.authService.confirmSignUp(request);
    return {};
  }
}

export const ConfirmUserKey = injectionKeyOf<ConfirmUser>({
  boundedContext: 'account',
  type: 'usecase',
  name: 'ConfirmUser',
});

export function useConfirmUser(): ConfirmUser {
  return requiredInject(ConfirmUserKey);
}
