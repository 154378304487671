import { ContentId, CourseId } from '@/base/domains';
import { ExamEditingCourseContentBodyReference } from '@/contents/domains';
import { EditingCourseContentBodyQueries } from '@/contents/domains/queries';

export class InMemoryEditingCourseContentBodyQueries implements EditingCourseContentBodyQueries {
  async findExamContentsByCourse(_: CourseId): Promise<ExamEditingCourseContentBodyReference[]> {
    return [];
  }

  async getEmptyProblemExamContentIdByCourse(_: CourseId): Promise<Array<ContentId>> {
    return [];
  }
}
