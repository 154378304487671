import moment from 'moment';
import {
  extend,
  localize,
  setInteractionMode,
  ValidationObserver,
  ValidationProvider,
} from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import Vue from 'vue';

import { locales } from './locales';

localize(locales);
localize('en');
setInteractionMode('eager');

Object.keys(rules).forEach((rule) => {
  // vee-validate@3.4.9からのalphaルール(ja)にはひらがな等も含まれる
  if (rule.startsWith('alpha')) return;
  extend(rule, rules[rule]);
});

const ALPHA_DASH = /^[0-9A-Z_-]*$/i;
extend('alpha_dash', (value) => {
  if (!value) return true;
  return ALPHA_DASH.test(value);
});

const PWD_REGEXP1 = /[^a-zA-Z0-9=+\-^$*.[\]{}()?"!@#%&/\\,><':;|_~`]/g;
const PWD_REGEXP2 = /^(?=.*[a-zA-Z])(?=.*[0-9])\S{8,99}$/;
extend('pwd_char', (value) => {
  if (!value) return true;
  if (PWD_REGEXP1.test(value)) return false;
  return true;
});
extend('pwd_regexp', (value) => {
  if (!value) return true;
  return PWD_REGEXP2.test(value);
});

const TENANT_CODE_REGEXP = /^(playground).*$/;
extend('tenant_regexp', (value) => {
  if (!value) return true;
  return !TENANT_CODE_REGEXP.test(value);
});
extend('tenant_test', (value) => {
  if (!value) return true;
  return value !== 'test';
});

const DATE_FORMAT = 'YYYY/MM/DD';
const DATETIME_FORMAT = 'YYYY/MM/DD HH:mm';
const TIME_FORMAT = 'HH:mm';
extend('date', (value) => {
  if (!value) return true;
  const m = moment(value, DATE_FORMAT);
  if (m.isValid()) return true;
  return false;
});
extend('datetime', (value) => {
  if (!value) return true;
  const m = moment(value, DATETIME_FORMAT);
  if (m.isValid()) return true;
  return false;
});
extend('min_datetime', {
  validate: (value, args) => {
    const from = 'from' in args ? args.from : undefined;
    if (!from) return true;
    const min = moment(from, DATETIME_FORMAT);
    const m = moment(value, DATETIME_FORMAT);
    if (!min.isValid() || !m.isValid()) return true;
    const ret = min.isSameOrBefore(m, 'minutes');
    if (ret) return true;
    return false;
  },
  params: ['from', 'label'],
});
extend('time', (value) => {
  if (!value) return true;
  const m = moment(value, TIME_FORMAT);
  if (m.isValid()) return true;
  return false;
});
extend('min_time', {
  validate: (value, args) => {
    const from = 'from' in args ? args.from : undefined;
    if (!from) return true;
    const min = moment(from, TIME_FORMAT);
    const m = moment(value, TIME_FORMAT);
    if (!min.isValid() || !m.isValid()) return true;
    const ret = min.isSameOrBefore(m, 'minutes');
    if (ret) return true;
    return false;
  },
  params: ['from', 'label'],
});
extend('later_than_now', {
  validate: (value) => {
    const m = moment(value, DATETIME_FORMAT);
    if (!m.isValid()) return true;
    const ret = m.isAfter(undefined, 'minutes');
    if (ret) return true;
    return false;
  },
});

extend('exclude-chars', {
  validate: (value, args) => {
    if (!value) return true;
    if (typeof value !== 'string') return true;
    const chars = 'chars' in args ? args.chars : undefined;
    if (!chars || !Array.isArray(chars)) return true;
    const ret = chars.some((c) => value.includes(c));
    return !ret;
  },
  params: ['chars'],
});

Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);
