import { ApplicationError } from './ApplicationError';

export interface ErrorCode<P extends {} | undefined> {
  readonly code: string;

  readonly message: string;
  toApplicationError(payload?: P): ApplicationError;
}

export class ErrorCodeImpl<P = {}> implements ErrorCode<P> {
  readonly code: string;

  readonly message: string;

  constructor(code: string, message: string) {
    this.code = code;
    this.message = message;
  }

  toApplicationError(payload?: P): ApplicationError {
    return new ApplicationError({
      code: this.code,
      message: this.message,
      payload: payload ?? {},
    });
  }
}

export function errorCodeOf<P extends {}>(code: string, message: string) {
  return new ErrorCodeImpl<P>(code, message);
}
